// @flow
import type { PropsWithChildren, ReactNode } from 'react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import CardActions from '@material-ui/core/CardActions';
import FPHint from 'UI/components/atoms/FPHint';

import { ImgContainer, StatsCardContent, StatsCardHeader, StyledCard } from './StatsCard.styled';

type StatsCardProps = PropsWithChildren & {
  actionElement?: ReactNode,
  header?: string | ReactNode,
  headerAction?: ReactNode,
  hintText?: string,
  subheader?: ReactNode,
  paddingContent?: number
};

export const StatsCard = ({
  actionElement,
  header,
  headerAction,
  footer,
  hintText,
  subheader,
  children,
  paddingContent,
  detailsUrl
}: StatsCardProps) => {
  const router = useHistory();

  const handleClick = event => {
    if (!detailsUrl) return;
    if (event.ctrlKey || event.metaKey) {
      window.open(detailsUrl, '_blank');
      return;
    }

    router.push(detailsUrl);
  };

  return (
    <StyledCard>
      {header &&
        (typeof header !== 'string' ? (
          <ImgContainer onClick={handleClick}>{header}</ImgContainer>
        ) : (
          <StatsCardHeader
            action={headerAction}
            subheader={subheader}
            title={
              <>
                {header} {hintText && <FPHint hint={hintText} position="top" />}
              </>
            }
            titleTypographyProps={{ variant: 'h6' }}
          />
        ))}
      <StatsCardContent padding={paddingContent}>{children}</StatsCardContent>
      {actionElement && <CardActions>{actionElement}</CardActions>}
      {footer && footer}
    </StyledCard>
  );
};
