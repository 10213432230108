import * as React from 'react';

const SvgContentPaperEdit = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <g fill={fill}>
        <path d="M12.75 5h-6a.75.75 0 000 1.5h6a.75.75 0 000-1.5zM13.5 9.25a.76.76 0 00-.75-.75h-8a.75.75 0 000 1.5h8a.76.76 0 00.75-.75zM4.75 12a.75.75 0 000 1.5h5.5a.75.75 0 000-1.5z" />
        <path d="M11.3 17.65a.25.25 0 00-.23-.15H2.5A.5.5 0 012 17V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v10.07a.25.25 0 00.15.23.26.26 0 00.28-.05l1.28-1.28a1.05 1.05 0 00.29-.71V2a2 2 0 00-2-2H2a2 2 0 00-2 2v15.5a2 2 0 002 2h7.87a.5.5 0 00.48-.35 1.86 1.86 0 01.5-.82l.4-.4a.26.26 0 00.05-.28zM12.06 20.13a.25.25 0 00-.42.13L11 23.4a.51.51 0 00.14.45.47.47 0 00.35.15h.1l3.14-.63a.24.24 0 00.19-.17.23.23 0 00-.06-.25zM23.23 11.77a2.61 2.61 0 00-3.7 0L13 18.27a.48.48 0 000 .7l3 3a.48.48 0 00.7 0l6.51-6.5a2.61 2.61 0 00.02-3.7z" />
      </g>
    </svg>
  );
};

export default SvgContentPaperEdit;
