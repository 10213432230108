export const FiltersGroups = [
  { id: 'keyword', name: '', filters: ['keyword'] },
  {
    id: 'industries',
    name: 'Industries',
    filters: ['industries', 'specialties', 'subspecialties', 'positions']
  },
  { id: 'team', name: 'Team', filters: ['coaches', 'recruiters'] },
  {
    id: 'location',
    name: 'Location',
    filters: ['states', 'searchSpecificPILStates', 'cities', 'searchSpecificPILCities']
  }
];

export const VirtualProps = [
  {
    propName: 'title',
    attributes: ['id', 'title']
  },
  {
    propName: 'company_title',
    copyFrom: 'company'
  }
];
