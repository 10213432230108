import { styled } from '@material-ui/core';

export const SearchResultWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 0),
  '& > div:nth-child(2)': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}));
