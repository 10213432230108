// @flow
import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Text from 'UI/components/atoms/Text';

import { useStyles } from './styles';

type TemplatePreviewProps = {
  template: any
};

const TemplatePreview = ({ template }: TemplatePreviewProps) => {
  const classes = useStyles();

  const templateMeta = [{ key: 'Subject', value: template?.emailBody?.subject }];

  return (
    <Grid container direction="column" className={classes.main}>
      {templateMeta.map(temp => (
        <Grid key={temp.key} container item wrap="nowrap">
          <Grid item className={classes.subtitle}>
            {temp.key}:
          </Grid>
          <Grid item className={classes.value}>
            <Text text={temp.value} cropped />
          </Grid>
        </Grid>
      ))}
      <Divider className={classes.separation} />
      <div
        className={classes.bodyContainer}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: template?.emailBody?.html
        }}
      />
    </Grid>
  );
};

TemplatePreview.defaultProps = {
  template: null
};

export default TemplatePreview;
