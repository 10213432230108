// @flow

import React, { useContext } from 'react';
import { useGlobalStyles } from 'GlobalStyles';
import { buildRowActionsRendererDefinition, getColumnsToRender } from 'hooks/datatable';
import { rowClickBuilder } from 'hooks/profileActions';
import { DraftsProviderContext } from 'providers/DraftsProvider';
import { EntityType } from 'UI/constants/entityTypes';
import { nestTernary } from 'UI/utils';

import { CandidateColumns, JobOrderColumns } from './columns';
import DraftsTable from './DraftsTable';

const DraftsManager = ({ onDraftSelected, type }) => {
  const globalClasses = useGlobalStyles();

  const { drafts, deleteDraft, deleteAllDrafts, setDraft } = useContext(DraftsProviderContext);

  const handleSelectDraft = id => {
    const draft = drafts.find(drft => drft.id === id);
    setDraft(draft);
    onDraftSelected && onDraftSelected(draft);
  };

  const entityColumns = nestTernary(
    type === EntityType.Candidate,
    CandidateColumns,
    JobOrderColumns
  );

  const draftsActions = buildRowActionsRendererDefinition({
    actions: [
      {
        name: 'edit',
        onClick: rowClickBuilder({
          columns: entityColumns,
          data: drafts,
          pickId: true,
          callback: handleSelectDraft
        })
      },
      {
        name: 'delete',
        onClick: rowClickBuilder({
          columns: entityColumns,
          data: drafts,
          pickId: true,
          callback: deleteDraft
        })
      }
    ]
  });

  const finalColumns = getColumnsToRender([...entityColumns, draftsActions]);

  return (
    !!drafts &&
    drafts.length > 0 && (
      <div className={globalClasses.newItemsSection}>
        <DraftsTable drafts={drafts} columns={finalColumns} handleDeleteAll={deleteAllDrafts} />
      </div>
    )
  );
};

export default DraftsManager;
