import { makeStyles } from '@material-ui/core';
import { colors } from 'UI/res/colors';

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.borderColor,
    borderRadius: 10,
    padding: theme.spacing(0.5, 1.5),
    width: ({ showDownloadButton }) => (showDownloadButton ? 230 : 185)
  },
  fileName: {
    color: colors.black,
    maxWidth: 170
  },
  fileSize: {
    color: colors.darkGrey
  }
}));
