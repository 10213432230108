import { makeStyles } from '@material-ui/core';

const buttonsWrapperHeight = 40;

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    maxWidth: '100%',
    overflowX: 'hidden'
  },
  actionButton: {
    height: buttonsWrapperHeight,
    border: 'none !important',
    width: '100% !important',
    borderRight: `1px solid ${theme.palette.customColors.lightgrey} !important`,
    borderRadius: 0,
    color: theme.palette.customColors.black,
    fontSize: 14,
    fontWeight: '400 !important'
  },
  buttonsWrapper: {
    height: buttonsWrapperHeight,
    borderBottom: `1px solid ${theme.palette.customColors.lightgrey}`
  },
  templatesTreeWrapper: {
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: `calc(100% - ${buttonsWrapperHeight}px)`
  }
}));
