import axios from 'axios';
import { parsePhoneNumber } from 'libphonenumber-js';
import API from 'services/API';
import { getCurrentUser } from 'services/Authentication';
import { ActivityLogTypes } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getFeatureFlags, loadFromSessionStorage } from 'UI/utils';

const CAN_USE_RC_EVENT_FILTER = getFeatureFlags().includes(FeatureFlags.RcEventFilter);

const RING_CENTRAL_EVENT_TYPES = {
  rcActiveCall: 'rc-active-call-notify',
  rcEndCall: 'rc-call-end-notify',
  rcMessageUpdate: 'rc-message-updated-notify',
  rcTelephonySession: 'rc-telephony-session-notify'
};

const RC_CALL_CONNECTED_SESSION_IDS_KEY = 'rcCallConnectedSessionIds';
const RC_CALL_CONNECTED_RESULTS = ['Answered', 'CallConnected'];

const RC_EVENT_FILTER_FULL_PATH = `${
  window.GPAC_ENV?.SECONDARY_API_URL || process.env.REACT_APP_SECONDARY_API_URL
}/${Endpoints.RcEventFilter}`;

const RC_EVENT_FILTER_API_KEY = {
  'x-api-key': window.GPAC_ENV?.SECONDARY_API_KEY || process.env.REACT_APP_SECONDARY_API_KEY
};
export class RingCentralEventHandler {
  static async handleEvent(e, contacts = [], removeContactFunc = null) {
    const { data } = e;
    if (!data) return;

    switch (data.type) {
      case RING_CENTRAL_EVENT_TYPES.rcActiveCall:
        this.handleCallConnection(data.call);
        break;
      case RING_CENTRAL_EVENT_TYPES.rcTelephonySession:
        this.handleSessionCallConnection(data.telephonySession);
        break;
      case RING_CENTRAL_EVENT_TYPES.rcEndCall: {
        const recipientPhoneNumber = this.formatPhoneNumber(data.call?.to);
        const contactFound = recipientPhoneNumber
          ? this.findContact(recipientPhoneNumber, contacts)
          : null;

        const isCallConnected = this.handleConnectedCallVerification(data.call);

        // get call on call end event
        await this.processEvent(
          { ...data.call, isCallConnected, isByWidget: true },
          ActivityLogTypes.Call,
          contactFound,
          removeContactFunc
        );
        break;
      }
      case RING_CENTRAL_EVENT_TYPES.rcMessageUpdate: {
        const recipientPhoneNumber = this.formatPhoneNumber(data.message?.to[0].phoneNumber);
        const contactFound = recipientPhoneNumber
          ? this.findContact(recipientPhoneNumber, contacts)
          : null;

        // get message from widget event
        await this.processEvent(
          data.message,
          ActivityLogTypes.SMS,
          contactFound,
          removeContactFunc
        );
        break;
      }
      default:
        break;
    }
  }

  static handleSessionCallConnection(telephonySession) {
    if (!telephonySession.parties || telephonySession.parties.length <= 0) return;

    const isCallConnected = !!telephonySession.parties.find(item =>
      RC_CALL_CONNECTED_RESULTS.find(
        callConnectedResult => callConnectedResult === item?.status?.code
      )
    );

    if (!isCallConnected) return;

    const currentCallConnectedSessionIds = loadFromSessionStorage(
      RC_CALL_CONNECTED_SESSION_IDS_KEY,
      []
    );

    const isDuplicated = !!currentCallConnectedSessionIds.find(
      sessionId => sessionId === telephonySession.id
    );

    if (isDuplicated) return;

    const callConnectedSessionIds = JSON.stringify([
      ...currentCallConnectedSessionIds,
      telephonySession.id
    ]);
    sessionStorage.setItem(RC_CALL_CONNECTED_SESSION_IDS_KEY, callConnectedSessionIds);
  }

  static handleCallConnection(call) {
    if (!call) return;

    const isCallConnected = RC_CALL_CONNECTED_RESULTS.find(
      callConnectedResult => callConnectedResult === call.telephonyStatus
    );

    if (!isCallConnected) return;

    const currentCallConnectedSessionIds = loadFromSessionStorage(
      RC_CALL_CONNECTED_SESSION_IDS_KEY,
      []
    );

    const isDuplicated = !!currentCallConnectedSessionIds.find(
      sessionId => sessionId === call.telephonySessionId
    );

    if (isDuplicated) return;

    const callConnectedSessionIds = JSON.stringify([
      ...currentCallConnectedSessionIds,
      call.telephonySessionId
    ]);
    sessionStorage.setItem(RC_CALL_CONNECTED_SESSION_IDS_KEY, callConnectedSessionIds);
  }

  static handleConnectedCallVerification(call) {
    const currentCallConnectedSessionIds = loadFromSessionStorage(
      RC_CALL_CONNECTED_SESSION_IDS_KEY,
      []
    );

    const callConnectedSessionIdIndex = currentCallConnectedSessionIds.indexOf(
      call?.partyData?.sessionId
    );

    const isCallConnected = callConnectedSessionIdIndex > -1;

    if (isCallConnected) {
      currentCallConnectedSessionIds.splice(callConnectedSessionIdIndex, 1);
      const callConnectedSessionIds = JSON.stringify(currentCallConnectedSessionIds);
      sessionStorage.setItem(RC_CALL_CONNECTED_SESSION_IDS_KEY, callConnectedSessionIds);
    }

    return isCallConnected;
  }

  static async processEvent(dataEvent, type, contactFound, removeContactFunc) {
    if (dataEvent.direction !== 'Outbound') return;

    const { id: currentUserId } = getCurrentUser();

    const body = {
      dataEvent,
      activityLogTypeId: type,
      contactFound,
      userId: currentUserId
    };

    // NOT TRACKING EVENT PHONE IF IS MADE ON ROSTER LISTING
    if (CAN_USE_RC_EVENT_FILTER) {
      window.phoneActionName !== EntityType.Roster &&
        (await axios.post(RC_EVENT_FILTER_FULL_PATH, body, { headers: RC_EVENT_FILTER_API_KEY }));
    } else {
      // Backwards compatibility
      window.phoneActionName !== EntityType.Roster &&
        (await API.post(`${Endpoints.Phone}/track-phone-event`, body));
    }

    window.phoneActionName = '';
    contactFound && removeContactFunc && removeContactFunc(contactFound);
  }

  static findContact = (phone, contacts) =>
    contacts.find(
      contact => this.formatPhoneNumber(this.getOnlyPhoneNumber(contact.phone)) === phone
    );

  static getOnlyPhoneNumber = phone => phone && phone.replace(/[^0-9]/g, '');

  static formatPhoneNumber = phone => {
    if (!phone) return null;

    try {
      const fullPhoneNumber = parsePhoneNumber(phone);
      return fullPhoneNumber ? fullPhoneNumber.nationalNumber : phone;
    } catch {
      return phone;
    }
  };
}

export default new RingCentralEventHandler();
