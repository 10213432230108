/* eslint-disable jsx-a11y/label-has-associated-control */
// @flow
import React from 'react';
import clsx from 'clsx';

import { useStyles } from './styles';

type FileSelectorButtonProps = {
  children?: any,
  className?: string,
  multiple?: boolean,
  disabled: Boolean,
  onFileChange: (fileEvent: any) => void
};
const acceptFiles = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  '.pdf',
  '.docx',
  '.doc',
  '.pptx',
  '.ppt',
  '.xlsx',
  '.xls',
  '.csv',
  '.zip'
];
const strFilesAccepted = acceptFiles.join(',');

const FileSelectorButton = (props: FileSelectorButtonProps) => {
  const { className, children, multiple, disabled, onFileChange } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <input
        id="contained-button-file"
        className={classes.input}
        type="file"
        disabled={disabled}
        multiple={multiple}
        accept={strFilesAccepted}
        onChange={onFileChange}
      />
      <label htmlFor="contained-button-file" className={classes.label}>
        {children}
      </label>
    </div>
  );
};

FileSelectorButton.defaultProps = {
  children: undefined,
  className: null,
  multiple: false,
  disabled: false
};

export default FileSelectorButton;
