const { Roles } = require('UI/constants/roles');

export const ALL_COMPANY_VISIBILITY_ROLES = [
  Roles.ProductionDirector,
  Roles.Operations,
  Roles.DataCoordinator,
  Roles.Admin,
  Roles.Leadership,
  Roles.Finance
];

export const OPTION_ALL = 'All';
export const PREPEND_OPTIONS_ALL = [{ full_name: OPTION_ALL }];
export const PAGE_REGIONAL_KEY = 'dominate-sheet-selector-regional';
export const PAGE_COACH_KEY = 'dominate-sheet-selector-coach';
