// @flow
import { useState } from 'react';

const usePaginationByItem = (data = [], currentId) => {
  const [selectedProfileId, setSelectedProfileId] = useState(currentId);

  const currentIndex = data?.findIndex(current => current?.id === selectedProfileId);

  const hasNextItem = !!data[currentIndex + 1]?.id;
  const hasPreviousItem = !!data[currentIndex - 1]?.id;

  const goToNext = () => hasNextItem && setSelectedProfileId(data[currentIndex + 1].id);
  const goToPrevious = () => hasPreviousItem && setSelectedProfileId(data[currentIndex - 1].id);

  return {
    currentId,
    goToNext,
    goToPrevious,
    selectedProfileId,
    setSelectedProfileId,
    quickViewData: data,
    hasNextItem,
    hasPreviousItem,
    currentIndex
  };
};
export default usePaginationByItem;
