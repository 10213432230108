// @flow

import { ADD_CONTACT, REMOVE_CONTACT } from 'actions/ringCentral';
import type { Action } from 'types/redux';
import type { RingCentralState } from 'types/ringCentral';

const buildContactsState = (state, payload) => {
  const contacts = state.trackedContacts.filter(
    contact =>
      contact !==
      state.trackedContacts.find(
        trackedContact =>
          trackedContact.id === payload.id &&
          trackedContact.role === payload.role &&
          trackedContact.phone === payload.phone
      )
  );

  return [...contacts, payload];
};

const domainReducer = (
  state: RingCentralState = {},
  action: Action = { type: '', payload: {} }
): RingCentralState => {
  const { type, payload } = action;
  switch (type) {
    case ADD_CONTACT:
      return { ...state, trackedContacts: buildContactsState(state, payload) };
    case REMOVE_CONTACT:
      return { ...state, trackedContacts: state.trackedContacts.filter(item => item !== payload) };
    default:
      return state;
  }
};

/** Reducer that combines domain and app reducer */
export default domainReducer;
