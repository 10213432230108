// @flow
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {
  confirm as confirmAction,
  showAlert as showAlertAction,
  showFile as showFileAction
} from 'actions/app';
import { globalStyles } from 'GlobalStyles';
import moment from 'moment';
import { getCurrentUser } from 'services/Authentication';
import { FeeAgreementRoleHierarchy, getHighestUserRoleInHierarchy } from 'services/Authorization';
import {
  cancelServiceAgreementRequest,
  declineByOps,
  sendRevalidationToOps,
  validateByOpsForUnmanaged
} from 'services/FeeAgreement';
import strings from 'strings';
import type { DisplayedFile } from 'types/app';
import SaveButton from 'UI/components/atoms/SaveButton';
import UserComments from 'UI/components/molecules/UserComments';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { DateFormats, FeeAgreementDefaultValues } from 'UI/constants/defaults';
import { EntityType, PaymentMode } from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { FeeAgreementStatus } from 'UI/constants/status';
import { extractFilenameFromUrl } from 'UI/utils';
import { getActions } from 'UI/utils/actions-engine';

import FeeAgreementDeclineForm from '../FeeAgreementDeclineForm';
import FeeAgreementLogs from '../FeeAgreementLogs';
import { useStyles } from '../styles';
import UnmanagedFeeAgreementBaseFields from '../UnmanagedFeeAgreementBaseFields';
import UnmanagedFeeAgreementExtendedFields from '../UnmanagedFeeAgreementExtendedFields';

import { rules } from './rules-unmanaged';

type FeeAgreementSummaryDrawerProps = {
  feeAgreement: any,
  onClose: () => void,
  onUpdate: () => any,
  onTemplateUploaded: any => any,
  onPreviewCreated: any => any,
  showConfirm: any => void,
  showAlert: any => void,
  showFile: DisplayedFile => void
};

const FeeAgreementSummaryDrawer = (props: FeeAgreementSummaryDrawerProps) => {
  const { feeAgreement, onClose, onUpdate, showConfirm, showAlert, showFile } = props;
  const classes = useStyles(props);

  const defaultValues = {
    fee_agreement_payment_scheme_id: PaymentMode.Standard,
    fee_percentage: FeeAgreementDefaultValues.FeePercent,
    guarantee_days: FeeAgreementDefaultValues.GuaranteeDays,
    flat_fee_amount: null,
    signed_date: null
  };

  const currentUser = getCurrentUser();
  const highestRole = getHighestUserRoleInHierarchy(currentUser, FeeAgreementRoleHierarchy);
  const {
    feeAgreementStatus,
    company,
    creator: {
      id: creatorId,
      initials: recruiterInitials,
      personalInformation: { full_name: recruiterName }
    },
    created_at,
    notes,
    currentDeclinator,
    declination_details: declinationDetails,
    updated_at: updatedAt
  } = feeAgreement || {};

  const userOwnsItem = creatorId === currentUser.id;

  const agreementFile = feeAgreement?.pdf_url
    ? {
        id: feeAgreement.id,
        entity: EntityType.FeeAgreement,
        file_name: extractFilenameFromUrl(feeAgreement.pdf_url),
        url: feeAgreement.pdf_url
      }
    : null;

  const form = useForm({ defaultValues: { ...feeAgreement, fee_agreement_file: agreementFile } });

  const { handleSubmit } = form;

  const performAsyncMethod = async (
    method,
    formData = null,
    shouldClose = true,
    shouldNotify = true
  ) => {
    setUiState(prevState => ({ ...prevState, isSaving: true }));

    const result = await method(feeAgreement.id, formData);
    setUiState(prevState => ({ ...prevState, isSaving: false }));
    result.alert && result.alert.body && showAlert(result.alert);
    result.success && shouldNotify && onUpdate && onUpdate();
    result.success && shouldClose && onClose();
    return result;
  };

  const validateAndUpdate = async formData => {
    await performAsyncMethod(validateByOpsForUnmanaged, {
      ...formData,
      fee_agreement_file:
        formData.fee_agreement_file?.id === agreementFile?.id &&
        formData.fee_agreement_file?.entity === agreementFile?.entity
          ? null
          : {
              id: formData.fee_agreement_file.id,
              entity: formData.fee_agreement_file.entity
            }
    });
  };

  const actions = {
    default: { title: strings.shared.ui.ok, action: onClose },
    view: {
      title: strings.feeAgreements.details.viewPdf,
      action: () => {
        feeAgreement.pdf_url && showFile({ url: feeAgreement.pdf_url, useProxy: true });
        onClose();
      }
    },
    approve: {
      title: strings.feeAgreements.details.validate,
      type: 'submit',
      action: async formData => {
        showConfirm({
          severity: 'warning',
          title: strings.shared.ui.confirm,
          message: strings.feeAgreements.details.confirmApprove,
          confirmButtonText: strings.feeAgreements.details.approve,
          cancelButtonText: strings.shared.ui.cancel,
          onConfirm: async ok => {
            if (!ok) return;
            await validateAndUpdate(formData);
          }
        });
      }
    },
    update: {
      title: strings.shared.ui.save,
      type: 'submit',
      action: async formData => {
        await validateAndUpdate(formData);
      }
    },
    decline: {
      title: strings.feeAgreements.details.declineChange,
      action: () =>
        setUiState(prevState => ({ ...prevState, isDeclining: true, showBackNavigation: true }))
    },
    declining: {
      title: strings.feeAgreements.details.decline,
      type: 'submit',
      action: async formData => {
        await performAsyncMethod(declineByOps, formData);
      },
      backAction: () => {
        setUiState(prevState => ({
          ...prevState,
          isDeclining: false,
          showBackNavigation: false
        }));
      }
    },
    cancel: {
      title: strings.feeAgreements.details.cancelRequest,
      action: async () => {
        showConfirm({
          severity: 'warning',
          title: strings.shared.ui.confirm,
          message: strings.feeAgreements.details.confirmCancel,
          onConfirm: async ok => {
            if (!ok) return;
            await performAsyncMethod(cancelServiceAgreementRequest);
          }
        });
      }
    },
    revalidate: {
      title: strings.feeAgreements.details.revalidate,
      type: 'submit',
      action: async formData => {
        await performAsyncMethod(sendRevalidationToOps, formData);
      }
    }
  };

  const statusForOpsEdition = [FeeAgreementStatus.PendingOpsValidation, FeeAgreementStatus.Signed];
  const isFormEnabled =
    (statusForOpsEdition.includes(feeAgreement?.fee_agreement_status_id) &&
      highestRole?.id === Roles.Operations) ||
    (feeAgreement?.fee_agreement_status_id === FeeAgreementStatus.DeclinedByOperations &&
      userOwnsItem);

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false,
    isFormDisabled: !isFormEnabled,
    isReadOnly: false,
    isDeclining: false,
    isRevalidating: false
  });

  const onSubmit = async formData => {
    if (primaryAction.type !== 'submit') return;
    await primaryAction.action(formData);
  };

  const availableActions = getActions(feeAgreementStatus?.id, highestRole?.id, rules, {
    agreement: feeAgreement,
    user: currentUser,
    uiState
  });
  const primaryAction = actions[availableActions.primary];

  const handleBack = () => primaryAction.backAction && primaryAction.backAction();
  const renderForm = () => {
    if (availableActions.primary === 'declining') {
      return <FeeAgreementDeclineForm feeAgreement={feeAgreement} fieldsRequired={false} />;
    }
    return (
      <>
        {!uiState.isFormDisabled && (
          <Typography variant="subtitle1" paragraph>
            {strings.feeAgreements.validation.confirmInformation}
          </Typography>
        )}
        <UnmanagedFeeAgreementExtendedFields
          companyFiles={company?.files || []}
          agreementFile={agreementFile}
          readOnly={uiState.isFormDisabled}
          required={!uiState.isFormDisabled}
        />
        <Divider className={classes.divider} />
        <UnmanagedFeeAgreementBaseFields
          defaultValues={defaultValues}
          readOnly={uiState.isFormDisabled}
          required={!uiState.isFormDisabled && highestRole?.id === Roles.Operations}
        />
      </>
    );
  };

  return (
    <FormContext {...form}>
      <DrawerContentLayout
        onSubmit={handleSubmit(onSubmit)}
        onClose={onClose}
        onBack={handleBack}
        variant="borderless"
        uiState={uiState}
        title={strings.feeAgreements.details.title}
        isSaveButtonMode={false}
        isBottomToolbarNeeded
        isTopToolbarNeeded
        customPrimaryButton={({ classes: flClasses }) => (
          <SaveButton
            initialText={primaryAction.title}
            onProgressText={strings.shared.ui.working}
            type={primaryAction.type === 'submit' ? 'submit' : 'button'}
            isSaving={uiState.isSaving}
            isSuccess={uiState.isSuccess}
            disabled={uiState.isSaving}
            onClick={primaryAction.type !== 'submit' ? primaryAction.action : undefined}
            className={flClasses.primaryButton}
          />
        )}
        moreActions={availableActions.moreActions.map(each => ({
          title: actions[each].title,
          action: actions[each].action,
          visible: true
        }))}
      >
        {feeAgreement && (
          <>
            {feeAgreementStatus?.id === FeeAgreementStatus.DeclinedByOperations &&
              declinationDetails?.declination_notes && (
                <>
                  <Typography variant="subtitle1" paragraph>
                    {strings.feeAgreements.validation.opsDeclined}
                  </Typography>
                  <UserComments
                    avatarInitials={currentDeclinator?.initials}
                    author={currentDeclinator?.personalInformation?.full_name}
                    date={moment(updatedAt).format(DateFormats.DetailDate)}
                    note={declinationDetails?.declination_notes}
                  />
                  <Divider style={globalStyles.mediumDivider} />
                </>
              )}
            {feeAgreementStatus?.id === FeeAgreementStatus.PendingOpsValidation &&
              feeAgreement.notes && (
                <>
                  <Typography variant="subtitle1" paragraph>
                    {strings.feeAgreements.fields.notes}:
                  </Typography>
                  <UserComments
                    avatarInitials={recruiterInitials}
                    author={recruiterName}
                    date={moment(created_at).format(DateFormats.DetailDate)}
                    note={notes}
                  />
                  <Divider style={globalStyles.mediumDivider} />
                </>
              )}
            {renderForm()}
            <Divider style={globalStyles.mediumDivider} />
            <FeeAgreementLogs items={feeAgreement?.eventLogs} />
          </>
        )}
      </DrawerContentLayout>
    </FormContext>
  );
};

FeeAgreementSummaryDrawer.defaultProps = {};

const mapDispatchToProps = dispatch => {
  return {
    showAlert: alert => dispatch(showAlertAction(alert)),
    showConfirm: confirmation => dispatch(confirmAction(confirmation)),
    showFile: pdfUrl => dispatch(showFileAction(pdfUrl))
  };
};

export default connect(null, mapDispatchToProps)(FeeAgreementSummaryDrawer);
