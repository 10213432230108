// @flow
import { useMemo, useState } from 'react';

import { DrawerMode } from '../ReferenceCheckTabLayout.constants';

type ReturnValue = [
  {
    isSaving: boolean,
    isSaved: boolean,
    isDrawerOpen: boolean,
    mode: Mode
  },
  {
    onSave: () => void,
    onSaveSuccess: () => void,
    onSaveError: () => void,
    resetUI: () => void,
    toggleDataRefresh: () => void,
    openDrawer: () => void,
    closeDrawer: () => void,
    onAddNewItemClick: () => void,
    onEditItemClick: () => void,
    onViewItemClick: () => void
  }
];

const useReferenceCheck = (): ReturnValue => {
  const [state, setState] = useState({
    mode: '',
    isDrawerOpen: false,
    isSaving: false,
    isSaved: false
  });

  const actions = useMemo(() => {
    const updateDrawerState = (mode: Mode) => {
      setState(prevState => ({
        ...prevState,
        mode,
        isDrawerOpen: true
      }));
    };

    return {
      onSave: () => {
        setState(prevState => ({ ...prevState, isSaving: true }));
      },
      onSaveSuccess: (callback: Function | undefined) => {
        setState(prevState => ({
          ...prevState,
          isDrawerOpen: false,
          mode: '',
          activeReferenceId: null,
          isSaved: false,
          isSaving: false
        }));

        callback && callback();
      },
      onSaveError: () => {
        setState(prevState => ({
          ...prevState,
          isSaving: false
        }));
      },
      resetUI: () => {
        setState(prevState => ({
          ...prevState,
          activeReferenceId: null,
          isSaved: false,
          isSaving: false
        }));
      },
      openDrawer: () => setState(prev => ({ ...prev, isDrawerOpen: true })),
      closeDrawer: () => {
        setState(prev => ({
          ...prev,
          activeReferenceId: null,
          isDrawerOpen: false,
          isSaved: false,
          isSaving: false,
          mode: ''
        }));
      },
      onAddNewItemClick: () => updateDrawerState(DrawerMode.Create),
      onEditItemClick: () => updateDrawerState(DrawerMode.Edit),
      onViewItemClick: () => updateDrawerState(DrawerMode.View)
    };
  }, []);

  return [state, actions];
};

export default useReferenceCheck;
