// @flow

// UTILS
import qs from 'query-string';
// SERVICES
import API from 'services/API';
// TYPES
import type { OperationResult } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';
import { getErrorMessage } from 'UI/utils';

// ENDPOINTS
const { BulkEmailsOptOuts, BulkEmailsOptOutReasons } = Endpoints;

export const getOpOutList = async (params): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const queryParams = qs.stringify(params);
    const { data } = await API.get(`${Endpoints.BulkEmailsOptOuts}?${queryParams}`);
    result.data = data;
    result.success = true;
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: 'Error getting Opt Out List',
      body: getErrorMessage(error)
    };
  }
  return result;
};

export const loadReasons = async (): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const response = await API.get(BulkEmailsOptOutReasons);
    if (response.status === 200) {
      result.success = true;
      result.data = response.data;
    }
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: 'Error While Loading reasons',
      body: getErrorMessage(error)
    };
  }
  return result;
};

/**
 *
 * @param {Object} formData
 * @param {number} formData.item_id
 * @param {number} formData.email_opt_out_type_id
 * @param {number} formData.unsubscribe_reason_id
 * @param {string} formData.custom_reason
 * @param {string} formData.notes
 * @returns {Promise}
 */
export const addToOptOut = async (formData): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const response = await API.post(BulkEmailsOptOuts, formData);
    if (response.status === 201) {
      result.success = true;
      result.data = response.data;
    }
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: 'Error While Create Opt In',
      body: getErrorMessage(error)
    };
  }
  return result;
};

/**
 * Service removes selected item from opt out list adding item to opt in database
 * again. Contacts removed from opt out list will receive emails again
 *
 * @param {number} id
 * @param {boolean} isUnsubscribe
 * @returns {Promise}
 */
export const removeFromOptOut = async (id, isUnsubscribe): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const response = await API.delete(`${BulkEmailsOptOuts}/${id}?is_unsubscribe=${isUnsubscribe}`);
    if (response.status === 200) {
      result.success = true;
      result.data = response.data;
    }
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: 'Error While Remove Opt In',
      body: getErrorMessage(error)
    };
  }
  return result;
};
