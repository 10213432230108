import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 8),
    '& > *': {
      margin: theme.spacing(1, 0)
    }
  },
  title: {
    maxWidth: 100,
    minWidth: 100,
    marginRight: theme.spacing(2),
    fontWeight: 500,
    fontSize: 16
  }
}));
