import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  fields: {
    marginBottom: theme.spacing(2)
  },
  writeupButton: {
    marginBottom: theme.spacing(2)
  },
  referenceButton: {
    marginBottom: theme.spacing(0.5)
  },
  referenceDate: {
    paddingLeft: theme.spacing(2)
  },
  emailHeader: {
    marginBottom: theme.spacing(2)
  },
  emailBody: {
    border: `1px solid ${theme.palette.grey.A200}`,
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.brandColors.FPWhite,
    padding: theme.spacing(2)
  },
  hiringAuthorityFormContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(1, 2)
  },
  tightMargin: {
    margin: theme.spacing(0.25, 0)
  },
  details: {
    margin: theme.spacing(0.5, 0, 0.5, 0)
  },
  link: {
    marginTop: theme.spacing(1)
  }
}));
