// @flow
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import API from 'services/API';
import {
  AvgHandleTimeCallIcon,
  CandidatesIcon,
  colors,
  CompaniesIcon,
  ForbiddenIcon,
  GeneralBulkEmailIcon,
  InBoundCall,
  JobOrdersIcon,
  MarketingBulkEmailIcon,
  MissedCall,
  NotSentMailIcon,
  OutBoundCall,
  ReadSMSIcon,
  ReceivedSMSIcon,
  RecipientsBulkEmailIcon,
  RecruitingBulkEmailIcon,
  SentIcon,
  SentSMSIcon,
  SvgNoConnectedCallFilled,
  TotalCallsIcon,
  TotalCallTimeIcon,
  UnreadSMSIcon
} from 'UI/res';
import { nestTernary, numberFormatter } from 'UI/utils';

import { useStyles } from './styles';

type SummaryTotalProps = {
  data?: Array,
  fetching?: boolean,
  itemsToShow?: Array,
  lastUpdate?: string,
  url?: string
};

const IconsMap = {
  avgHandleTimeCall: AvgHandleTimeCallIcon,
  bouncedBulk: props => <ForbiddenIcon fill={colors.oxford} {...props} />,
  candidate: CandidatesIcon,
  company: CompaniesIcon,
  generalBulk: GeneralBulkEmailIcon,
  inboundCall: InBoundCall,
  jobOrder: JobOrdersIcon,
  marketingBulk: MarketingBulkEmailIcon,
  missedCall: MissedCall,
  noConnectedCall: SvgNoConnectedCallFilled,
  outboundCall: OutBoundCall,
  reachedBulk: RecipientsBulkEmailIcon,
  readSMS: ReadSMSIcon,
  receivedSMS: ReceivedSMSIcon,
  recruitingBulk: RecruitingBulkEmailIcon,
  sentBulk: props => <SentIcon fill={colors.oxford} {...props} />,
  sentSMS: SentSMSIcon,
  spamBulk: props => <NotSentMailIcon fill={colors.oxford} {...props} />,
  totalCalls: TotalCallsIcon,
  totalTime: TotalCallTimeIcon,
  unreadSMS: UnreadSMSIcon
};

const renderIcon = (iconKey: string, size: number, fill: string) => {
  if (!iconKey || !IconsMap[iconKey]) return null;
  const Icon = IconsMap[iconKey];
  return <Icon size={size} fill={fill || colors.oxford} />;
};

const SummaryItem = withStyles({
  root: {
    '&:not(:last-child) > *': {
      borderRight: `1px solid ${colors.lightgrey}`
    }
  }
})(Grid);

const SummaryTotals = ({ data, fetching, itemsToShow, lastUpdate, url }: SummaryTotalProps) => {
  const minHeight = 60;
  const [isLoading, setIsLoading] = useState(false);
  const [summaryItems, setSummaryItems] = useState([]);
  const defaultNumberOfItems = 3;
  const NumberOfItemsByLength = {
    2: 6,
    3: 4,
    5: 4,
    6: 4
  };
  const numberOfItemsToShow =
    NumberOfItemsByLength[itemsToShow?.length || summaryItems.length] || defaultNumberOfItems;
  const itemsToMultiline = 5;
  const classes = useStyles({
    manyItems: itemsToMultiline > (itemsToShow ? itemsToShow.length : summaryItems.length)
  });

  useEffect(() => {
    typeof fetching === 'boolean' && setIsLoading(fetching);
  }, [fetching]);

  useEffect(() => {
    !data &&
      url &&
      (async () => {
        setIsLoading(true);
        try {
          const response = await API.get(url);
          if (response.data && response.status === 200) {
            setSummaryItems(response.data);
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        }
        setIsLoading(false);
      })();
  }, [url, data, lastUpdate]);

  return isLoading ? (
    <Box display="flex" alignItems="center" justifyContent="center" style={{ minHeight }}>
      <CircularProgress />
    </Box>
  ) : (
    nestTernary(
      (Array.isArray(data) && data.length > 0) || summaryItems.length > 0,
      <Grid container className={classes.summaryContainer}>
        {(data || summaryItems).map(
          (item, i) =>
            (itemsToShow?.includes(i) || !itemsToShow) && (
              <SummaryItem
                key={`stic-${item.title}`}
                item
                xs={numberOfItemsToShow}
                className={summaryItems.length >= itemsToMultiline ? classes.itemMultiline : null}
              >
                <Box className={classes.summaryItem}>
                  {item.icon && renderIcon(item.icon, 50)}
                  <Box>
                    <Typography variant="body1">{item.title}</Typography>
                    <Typography variant="h1">
                      {item.format === 'none'
                        ? item.total
                        : numberFormatter(item.total, item?.format)}
                    </Typography>
                  </Box>
                </Box>
              </SummaryItem>
            )
        )}
      </Grid>,
      <>There is no data with the selected filters</>
    )
  );
};

SummaryTotals.defaultProps = {
  data: undefined,
  fetching: undefined,
  itemsToShow: undefined,
  lastUpdate: '',
  url: ''
};

export default SummaryTotals;
