import strings from 'strings';

import { NamesDrawerContent } from './NamesDrawerContent';

const { names } = strings.commandCenter.drawers;

export const NamesConfig = {
  title: names.title,
  component: NamesDrawerContent
};
