import { THEME as theme } from 'GlobalStyles';

const {
  palette: { error, secondary, success, warning }
} = theme;

export const PERFORMANCE_INDICATOR_COLORS = {
  default: secondary.dark,
  high: success.FP300,
  low: error.FP300,
  regular: warning.FP300
};

export const PERFORMANCE_INDICATOR_BACKGROUND_COLORS = {
  default: secondary.light,
  high: success.FP100,
  low: error.FP200,
  regular: warning.FP200
};

export const PERFORMANCE_INDICATOR_BACKGROUND_CARD_COLORS = {
  default: secondary.light,
  high: success.light,
  low: error.light,
  regular: warning.light
};
