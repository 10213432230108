import * as React from 'react';

const SvgPhoneFilled = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M22.67 17l-2.45-2.45a2.8 2.8 0 00-4 0l-.49.49a54 54 0 01-6.81-6.8l.5-.5a2.83 2.83 0 000-4L7 1.32a2.87 2.87 0 00-4 0L1.66 2.66a4 4 0 00-.5 5 54.24 54.24 0 0015.17 15.17 4 4 0 005-.5L22.67 21a2.8 2.8 0 000-4z" />
    </svg>
  );
};

export default SvgPhoneFilled;
