import React from 'react';
import { COMMAND_CENTER_BASE_URL, ENDPOINTS } from 'features/command-center/constants';
import { getFilterDefinitionByName } from 'UI/components/templates/SideFiltersLayout/filters';
import { ActivityLogTypes, FilterType } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { ContactRole } from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { CollectionDateFilters } from 'UI/pages/Collections/Collections.columns';
import { INITIAL_DATE_FILTER } from 'UI/pages/Collections/hooks/useCollectionsListing';
import { Groupers, OptionRenderers, Selectors } from 'UI/utils/renderers';

import { LabelMultipleTags } from './InlineFilters.styled';

const renderMultipleTags = (selected, displayKey = 'title') => {
  if (!selected) return null;

  const tagsValue = selected.map(item => item[displayKey]).join(', ');

  return (
    <LabelMultipleTags variant="body1" noWrap>
      {tagsValue}
    </LabelMultipleTags>
  );
};

export const InlineFiltersConfig = [
  {
    type: 'dateRange',
    name: 'dateRange',
    placeholder: 'Date Range'
  },
  {
    type: 'date',
    name: 'startDate',
    title: 'Start Date'
  },
  {
    name: 'state',
    paramName: 'stateIds',
    title: 'State',
    type: FilterType.Autocomplete,
    url: Endpoints.States,
    groupBy: Groupers.byCountry,
    getOptionSelected: Selectors.byId,
    multiple: true,
    renderTags: renderMultipleTags,
    disableListWrap: true
  },
  {
    name: 'industries',
    paramName: 'industryIds',
    title: 'Industry',
    type: FilterType.Autocomplete,
    url: `${Endpoints.Industries}?standalone=true`,
    disableListWrap: true,
    renderTags: renderMultipleTags,
    multiple: true
  },
  {
    ...getFilterDefinitionByName('specialties'),
    disableListWrap: true,
    renderTags: renderMultipleTags
  },
  {
    ...getFilterDefinitionByName('subspecialties'),
    disableListWrap: true,
    renderTags: renderMultipleTags
  },
  {
    name: 'recruiters',
    paramName: 'recruiterIds',
    title: 'Recruiter',
    type: FilterType.Autocomplete,
    displayKey: 'full_name',
    url: `${Endpoints.Users}?role_id=${Roles.Recruiter}`,
    disableListWrap: true,
    renderTags: renderMultipleTags,
    multiple: true
  },
  {
    name: 'tenures',
    paramName: 'tenures',
    title: 'Tenure',
    type: FilterType.Autocomplete,
    displayKey: 'title',
    url: ENDPOINTS.tenures,
    disableListWrap: true,
    renderTags: renderMultipleTags,
    multiple: true,
    idKey: 'title'
  },
  {
    name: 'healthStatus',
    paramName: 'status',
    title: 'Health Status',
    type: FilterType.Autocomplete,
    displayKey: 'title',
    api: 'secondary',
    url: `${COMMAND_CENTER_BASE_URL}/catalog/health-statuses`,
    disableListWrap: true,
    renderTags: renderMultipleTags,
    multiple: false,
    idKey: 'title'
  },
  {
    name: 'dueCommitCollectionsDate',
    type: FilterType.Autocomplete,
    options: CollectionDateFilters,
    title: '',
    defaultValue: INITIAL_DATE_FILTER,
    disableClearable: true,
    hideActiveFilter: true
  },
  {
    name: 'activityType',
    paramName: 'activityLogTypeIds',
    title: 'Activity Type',
    type: FilterType.Autocomplete,
    getOptionLabel: OptionRenderers.title,
    options: [
      {
        id: 1,
        title: 'Email',
        value: ActivityLogTypes.Email
      },
      {
        id: 2,
        title: 'Text',
        value: ActivityLogTypes.SMS
      },
      {
        id: 3,
        title: 'Call',
        value: ActivityLogTypes.Call
      }
    ],
    multiple: true,
    renderTags: renderMultipleTags,
    disableListWrap: true
  },
  {
    ...getFilterDefinitionByName('collectionsCurrentTeam'),
    disableListWrap: true,
    renderTags: renderMultipleTags
  },
  {
    ...getFilterDefinitionByName('collectionsTeam'),
    disableListWrap: true,
    renderTags: renderMultipleTags
  },
  {
    ...getFilterDefinitionByName('emailVerdicts'),
    disableListWrap: true,
    renderTags: renderMultipleTags
  },
  {
    ...getFilterDefinitionByName('cities'),
    disableListWrap: true,
    renderTags: renderMultipleTags
  },
  {
    ...getFilterDefinitionByName('countries'),
    disableListWrap: true,
    renderTags: renderMultipleTags
  },
  {
    ...getFilterDefinitionByName('companyTypes'),
    disableListWrap: true,
    renderTags: renderMultipleTags
  },
  {
    name: 'candidateTypes',
    paramName: 'candidateTypeIds',
    title: 'Candidate Type',
    type: FilterType.Autocomplete,
    url: Endpoints.CandidateTypes,
    renderTags: renderMultipleTags,
    disableListWrap: true,
    multiple: true
  },
  {
    name: 'candidateStatuses',
    paramName: 'candidateStatusIds',
    title: 'Candidate Status',
    type: FilterType.Autocomplete,
    url: Endpoints.CandidateStatuses,
    renderTags: renderMultipleTags,
    disableListWrap: true,
    multiple: true
  },
  {
    name: 'outcomes',
    paramName: 'activityOutcomeTypeIds',
    title: 'Outcome',
    idKey: 'typeId',
    type: FilterType.Autocomplete,
    url: Endpoints.ActivityOutcomes,
    getOptionLabel: option => option.typeTitle,
    groupBy: option => option.categoryTitle,
    getOptionSelected: (option, value) => option.typeId === value?.typeId,
    renderTags: selected => renderMultipleTags(selected, 'typeTitle'),
    multiple: true,
    disableListWrap: true
  },
  {
    name: 'hiringAuthorityStatuses',
    paramName: 'hiringAuthorityStatusIds',
    title: 'Hiring Authority Status',
    type: FilterType.Autocomplete,
    url: Endpoints.HiringAuthoritiesStatuses,
    renderTags: renderMultipleTags,
    disableListWrap: true,
    multiple: true
  },
  {
    name: 'prospectStatuses',
    paramName: 'contactStatusIds',
    title: 'Prospect Status',
    type: FilterType.Autocomplete,
    url: Endpoints.SimpleStatuses.replace(':nameTypeId', ContactRole.Name),
    renderTags: renderMultipleTags,
    disableListWrap: true,
    multiple: true
  },
  {
    name: 'numberOfContacts',
    paramName: 'companyNumberOfContacts',
    title: 'Number of Contacts',
    type: FilterType.Autocomplete,
    getOptionLabel: ({ from, to, title }) => {
      if (title) return title;
      if (!to) return `+${from}`;
      return `${from} - ${to}`;
    },
    options: [
      {
        id: 1,
        title: '0 - 100',
        value: {
          from: 0,
          to: 100
        }
      },
      {
        id: 2,
        title: '100 - 1500',
        value: {
          from: 100,
          to: 1500
        }
      },
      {
        id: 3,
        title: '1500 - 3000',
        value: {
          from: 1500,
          to: 3000
        }
      },
      {
        id: 4,
        title: '+3000',
        value: {
          from: 3000
        }
      }
    ]
  }
];
