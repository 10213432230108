import { makeStyles } from '@material-ui/core/styles';
import { input } from 'UI/constants/dimensions';

const iconButtonWidth = 48;
const endAdornmentMargin = 12;

export const useStyles = makeStyles(
  theme => ({
    root: {
      ...theme.input,
      height: 'unset',
      '& div': {
        height: input.height
      },
      '& span.MuiIconButton-label': {
        width: 24
      },
      '& span.MuiTouchRipple-root, & div.MuiInputAdornment-root, & button.MuiButtonBase-root': {
        width: iconButtonWidth
      },
      '& button.MuiButtonBase-root': {
        marginLeft: endAdornmentMargin
      }
    }
  }),
  { name: 'MuiCustomDatePicker' }
);
