import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  emailValidationWarning: {
    margin: theme.spacing(2, 0)
  },
  learnMoreBtn: {
    minWidth: '105px'
  },
  secondaryText: {
    fontWeight: theme.typography.fontWeightBold
  },
  primaryText: {
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(2)
  }
}));
