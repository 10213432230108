import { makeStyles } from '@material-ui/core/styles';
import { iconSizes } from 'UI/constants/dimensions';

export const useStyles = makeStyles({
  root: ({ size, color }) => ({
    width: iconSizes.streamline[size] || size,
    height: iconSizes.streamline[size] || size,
    color
  })
});
