// @flow
import React from 'react';
import { connect } from 'react-redux';
import Badge from '@material-ui/core/Badge';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { SvgAlarmBell } from 'UI/res';

import { useBadgeStyles } from './styles';

type NotificationButtonProps = {
  onDrawerOpen: () => any,
  totalUnread: number
};

const NotificationButton = ({ onDrawerOpen, totalUnread }: NotificationButtonProps) => {
  const classes = useBadgeStyles();

  return (
    <Badge
      badgeContent={totalUnread || null}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      className={classes.badge}
      color="primary"
      overlap="circle"
    >
      <FPIconButton
        icon={SvgAlarmBell}
        onClick={onDrawerOpen}
        tooltipProps={{ title: 'Notifications ' }}
      />
    </Badge>
  );
};

const mapStateToProps = ({ notification }) => {
  return {
    totalUnread: notification.domain.totalUnread
  };
};

export default connect(mapStateToProps)(NotificationButton);
