// @flow

import React from 'react';
import { FormContext } from 'react-hook-form';
import Alert from '@material-ui/lab/Alert';
import strings from 'strings';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import Loader from 'UI/components/templates/DrawerContentLayout/LoaderContent';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag } from 'UI/utils';
import { LabelRenderers, OptionRenderers, Selectors } from 'UI/utils/renderers';

import CreateForm from './CreateForm';
import CreateSendoutCallToAction from './CreateSendoutCallToAction';
import { usePlacementCreateManager } from './hooks';
import ReferenceReleaseCallToAction from './ReferenceReleaseCallToAction';

type PlacementCreateManagerProps = {
  companyId?: number,
  sendoutId?: number,
  jobOrderId: number,
  onBeforeSubmit: any => void,
  onCompleted: any => any,
  onClose: () => void,
  onNewSendoutClick: () => void
};

const PlacementCreateManager = ({
  companyId,
  sendoutId: preselectedSendoutId,
  jobOrderId,
  onBeforeSubmit,
  onCompleted,
  onClose,
  onNewSendoutClick
}: PlacementCreateManagerProps) => {
  const isNoHireLetterActive = hasFeatureFlag(FeatureFlags.NoHireLetter);
  const {
    agreementFiles,
    canSendReferenceRelease,
    currentView,
    feeAgreements,
    fileCategories,
    fileExplorerSections,
    form,
    groupedFiles,
    handleAgreementChange,
    handleComposeReferenceRelease,
    handleContinueToForm,
    handleSendoutChange,
    handleSplitsChange,
    initialSplits,
    isReferenceReleasedValid,
    newestReferenceReleaseEmail,
    assignmentDataSheet,
    candidateDataSheet,
    onSubmit,
    sendoutsForNoHireLetter,
    showBottomToolbar,
    selectedAgreement,
    selectedSendout,
    sendout: detailedSendout,
    splits,
    uiState,
    validSendouts
  } = usePlacementCreateManager({
    companyId,
    sendoutId: preselectedSendoutId,
    jobOrderId,
    onBeforeSubmit,
    onCompleted
  });

  const { handleSubmit } = form;

  const views = {
    loading: <Loader />,
    noSendouts: <CreateSendoutCallToAction onNewSendoutClick={onNewSendoutClick} />,
    default: (
      <>
        {validSendouts.length > 1 && !preselectedSendoutId && (
          <AutocompleteSelect
            width="100%"
            name="sendout"
            value={selectedSendout}
            placeholder={strings.placements.fields.sendout}
            disableClearable
            defaultOptions={validSendouts}
            getOptionLabel={LabelRenderers.sendoutCandidate}
            getOptionSelected={Selectors.byId}
            renderOption={OptionRenderers.sendoutCandidate}
            onSelect={handleSendoutChange}
          />
        )}
        {isReferenceReleasedValid ? (
          <FormContext {...form}>
            {isNoHireLetterActive && sendoutsForNoHireLetter.length > 0 && (
              <Alert severity="info">{strings.placements.sections.create.noHireLetterNotice}</Alert>
            )}
            <CreateForm
              sendout={detailedSendout}
              feeAgreements={feeAgreements}
              initialSplits={initialSplits}
              splits={splits}
              fileCategories={fileCategories}
              fileExplorerSections={fileExplorerSections}
              groupedFiles={groupedFiles}
              agreementFile={selectedAgreement}
              agreementFiles={agreementFiles}
              newestReferenceReleaseEmail={newestReferenceReleaseEmail}
              assignmentDataSheet={assignmentDataSheet}
              candidateDataSheet={candidateDataSheet}
              onAgreementChange={handleAgreementChange}
              onSplitsChange={handleSplitsChange}
            />
          </FormContext>
        ) : (
          <>
            {detailedSendout && (
              <ReferenceReleaseCallToAction
                canSend={canSendReferenceRelease}
                onSendClick={handleComposeReferenceRelease}
                onContinueClick={handleContinueToForm}
              />
            )}
          </>
        )}
      </>
    )
  };

  return (
    <DrawerContentLayout
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      variant="borderless"
      uiState={uiState}
      title={strings.placements.sections.create.title}
      isTopToolbarNeeded
      isBottomToolbarNeeded={showBottomToolbar}
      onSecondaryButtonClick={onClose}
    >
      {views[currentView]}
    </DrawerContentLayout>
  );
};

PlacementCreateManager.defaultProps = {
  onNewSendoutClick: () => {},
  companyId: null,
  sendoutId: null
};

export default PlacementCreateManager;
