// @flow
import React from 'react';
import TotalSummaryCard from 'UI/components/molecules/home/TotalSummaryCard';
import { getId } from 'UI/utils';

import { useStyles } from './styles';

type StatCardsRowProps = {
  statItems: Array<Object>
};

const StatCardsRow = ({ statItems }: StatCardsRowProps) => {
  const itemWidth = (100 - statItems.length) / statItems.length;
  const classes = useStyles({ itemWidth });

  return (
    <div className={classes.container}>
      {statItems.map(({ Icon, title, value, variant }) => (
        <div data-testid="stat-card" key={getId()} className={classes.item}>
          <TotalSummaryCard Icon={Icon} title={title} value={value} variant={variant} />
        </div>
      ))}
    </div>
  );
};

StatCardsRow.defaultProps = {
  statItems: []
};

export default StatCardsRow;
