// @flow
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import queryString from 'query-string';
import { filtersToParams } from 'selectors/app';
import strings from 'strings';
import { FPCard, FPCardContent, FPCardHeader } from 'UI/components/atoms/FPCard';
import { When } from 'UI/components/atoms/When';
import { Endpoints } from 'UI/constants/endpoints';
import { UIStatus } from 'UI/constants/status';

import Industries from './Industries';
import type { NodeSelection } from './pilIndustries.types';
import { useTreeItemStyles } from './styles';

type PilIndustriesProps = {
  filters?: Record<string, object>,
  onNodeSelected: (node: NodeSelection | null) => void,
  searchInPositions?: boolean,
  showSearchFilter?: boolean
};
const PilIndustries = ({
  onNodeSelected,
  showSearchFilter,
  filters = {},
  searchInPositions
}: PilIndustriesProps) => {
  const filtersParams = filtersToParams(filters);
  const queryParams = queryString.stringify(filtersParams, { arrayFormat: 'comma' });
  const classes = useTreeItemStyles();

  const { state: industriesState, Status: IndustriesStatus } = useFetchWithStatus(
    `${Endpoints.Industries}?${queryParams}`
  );

  return (
    <FPCard>
      <FPCardHeader title={strings.shared.fields.industries} />
      <FPCardContent>
        <div className={classes.statusContainer}>
          <IndustriesStatus
            loading={<CircularProgress size={32} />}
            empty={
              <Typography paragraph gutterBottom>
                {strings.shared.emptyState.title}
              </Typography>
            }
            error={error => (
              <Typography paragraph gutterBottom>
                {error}
              </Typography>
            )}
            success={() => null}
          />
        </div>

        <When condition={industriesState.status === UIStatus.Success}>
          <Industries
            onNodeSelected={onNodeSelected}
            showSearchFilter={showSearchFilter}
            results={industriesState.results}
            searchInPositions={searchInPositions}
          />
        </When>
      </FPCardContent>
    </FPCard>
  );
};

export default PilIndustries;
