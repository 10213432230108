import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import qs from 'query-string';
import { filtersToParams } from 'selectors/app';
import API from 'services/API';
import strings from 'strings';
import { FPCard as Card, FPCardContent as CardContent } from 'UI/components/atoms/FPCard';
import LineChart from 'UI/components/molecules/Dashboard/LineChart';
import SummaryTable from 'UI/components/molecules/Dashboard/SummaryTable';
import SummaryTotals from 'UI/components/molecules/Dashboard/SummaryTotals';
import DailyStatsFooter from 'UI/components/molecules/home/DailyStats/DailyStatsFooter';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { backNavigateListConfig } from 'UI/constants/config';
import { DateFormats, PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { downloadFile, getRangePeriod } from 'UI/utils';

import { getScope } from '../helpers';
import { useStyles } from '../styles';

const FiltersGroups = [{ id: 'team', name: 'Team', filters: ['coach', 'recruiter'] }];
const {
  title: strTitle,
  section: { name: strSectionName, titles }
} = strings.bulkEmailDashboard;

const DashboardBulkEmail = () => {
  const classes = useStyles();
  const defaultPeriod = getRangePeriod([1, 'week']);
  const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod);
  const [filters, setFilters] = useState({});
  const [uiState, setUiState] = useState({
    isSideMenuOpen: false,
    fetching: false
  });
  const [dashboardData, setDashboardData] = useState({
    lastUpdate: '',
    sent: [],
    trend: {},
    stats: {
      open: [],
      total: []
    },
    activity: {
      withBulk: [],
      withoutBulks: []
    }
  });
  const CustomFooter = (
    <DailyStatsFooter bulksLastUpdate={dashboardData.lastUpdate} type="dashboard" />
  );
  const filtersParams = useMemo(() => filtersToParams(filters), [filters]);
  const getEndpoint = useCallback(
    url =>
      qs.stringifyUrl({
        url,
        query: {
          startDate: moment(selectedPeriod.startDate).format(DateFormats.QueryFormat),
          endDate: moment(selectedPeriod.endDate).format(DateFormats.QueryFormat),
          ...filtersParams
        }
      }),
    [selectedPeriod, filtersParams]
  );

  const scope = getScope(filters);
  const activityUrl = getEndpoint(Endpoints.DashboardBulkEmailDownloadCSV);

  useEffect(() => {
    document.title = PageTitles.DashboardBulk;
  }, []);

  useEffect(() => {
    const setFetching = fetching =>
      setUiState(prev => ({
        ...prev,
        fetching
      }));
    setFetching(true);
    (async () => {
      const endpoint = getEndpoint(Endpoints.DashboardBulkEmail);
      const response = await API.get(endpoint, {
        apiVersion: 2
      });
      if (response.status === 200) {
        const {
          formattedBulkStats: stats,
          formattedSentBulks: sent,
          formattedTrendBulks: trend,
          formattedTableBulkSents: activity,
          latestCutoffDate: lastUpdate
        } = response.data;
        setDashboardData({
          lastUpdate,
          sent,
          trend,
          activity,
          stats: {
            open: stats.slice(0, 3),
            total: stats.slice(3, 6)
          }
        });
      }
      setFetching(false);
    })();
  }, [getEndpoint]);

  const handlePeriodChange = period => setSelectedPeriod({ ...period });
  const handleFiltersReset = () => setFilters({});
  const handleFiltersChange = newFilters => setFilters(newFilters);
  const handleFiltersSearch = (_, newFilters) => setFilters(newFilters);
  const handleRowClick = filter => setFilters(prev => ({ ...prev, ...filter }));
  const handleMenuToggle = () =>
    setUiState(prev => ({ ...prev, isSideMenuOpen: !prev.isSideMenuOpen }));
  const handleDownloadAll = async ({ url, fileName }) => {
    const response = await API.get(`${url}&allRecruiters=true&format=csv`, {
      responseType: 'blob',
      apiVersion: 2
    });
    downloadFile(response.data, 'text/csv', `${fileName}-allRecruiters`);
  };

  return (
    <FiltersLayout
      title={strTitle}
      section={strSectionName}
      isSideMenuOpen={uiState.isSideMenuOpen}
      defaultRange={selectedPeriod}
      onPeriodChange={handlePeriodChange}
      onFiltersReset={handleFiltersReset}
      onFiltersChange={handleFiltersChange}
      onSearch={handleFiltersSearch}
      onMenuToggle={handleMenuToggle}
      groups={FiltersGroups}
      disableTimeRange
      titleLabelProps={backNavigateListConfig}
      filters={filters}
      enableStore={false}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent
              headerProps={{
                title: titles.totalSent,
                subheader: scope.option1,
                className: classes.headerAction
              }}
              footerProps={{
                CustomFooter
              }}
            >
              <SummaryTotals fetching={uiState.fetching} data={dashboardData.sent} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent
              headerProps={{
                title: titles.trends,
                subheader: scope.option1,
                className: classes.headerAction
              }}
              footerProps={{
                CustomFooter
              }}
            >
              <LineChart data={dashboardData.trend} columns={[]} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent
              headerProps={{
                title: titles.ratio,
                subheader: scope.option1,
                className: classes.headerAction
              }}
              footerProps={{
                CustomFooter
              }}
            >
              <SummaryTotals fetching={uiState.fetching} data={dashboardData.stats.open} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent
              headerProps={{
                title: titles.totalStats,
                subheader: scope.option1,
                className: classes.headerAction
              }}
              footerProps={{
                CustomFooter
              }}
            >
              <SummaryTotals fetching={uiState.fetching} data={dashboardData.stats.total} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent
              variant="tight"
              headerProps={{
                title: titles.withActivity,
                subheader: scope.option2,
                className: classes.headerAction
              }}
              footerProps={{
                CustomFooter: (
                  <DailyStatsFooter bulksLastUpdate={dashboardData.lastUpdate} type="table" />
                )
              }}
            >
              <SummaryTable
                data={dashboardData.activity?.withBulk}
                url={activityUrl}
                fetching={uiState.fetching}
                downloadFileName={`Bulk-Overview-${scope.option2}`}
                section={strSectionName}
                onRowClick={handleRowClick}
                onDownloadAll={handleDownloadAll}
                showDownloadAllButton={!filters?.recruiter}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </FiltersLayout>
  );
};

export default DashboardBulkEmail;
