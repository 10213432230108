// @flow
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import usePageTitle from 'hooks/usePageTitle';
import strings from 'strings';
import { PilIndustries } from 'UI/components/organisms/PilIndustries';
import type { NodeSelection } from 'UI/components/organisms/PilIndustries/pilIndustries.types';
import { PilInformation } from 'UI/components/organisms/PilInformation';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import { PageTitles } from 'UI/constants/defaults';

import { getNodeSelectedParams } from './pil.utils';

const DEFAULT_SELECTION_PARAMS = {
  id: null,
  title: '',
  type: 'industry',
  informationEndpoint: '',
  functionalTitlesEndpoint: '',
  showFunctionalTitles: false
};

const Pil = () => {
  usePageTitle({ title: PageTitles.Industries });
  const [selectionParams, setSelectionParams] = useState(DEFAULT_SELECTION_PARAMS);

  const handleNodeSelected = (nodeSelected: NodeSelection | null) => {
    if (!nodeSelected) {
      setSelectionParams(DEFAULT_SELECTION_PARAMS);
      return;
    }

    const nodeSelectedParams = getNodeSelectedParams(nodeSelected);
    setSelectionParams(nodeSelectedParams);
  };

  return (
    <ContentPageLayout text={strings.pil.title} titleLabelProps={{ backNavigation: true }}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <PilIndustries onNodeSelected={handleNodeSelected} showSearchFilter searchInPositions />
        </Grid>

        <Grid item sm={6} xs={12}>
          <PilInformation selectionParams={selectionParams} />
        </Grid>
      </Grid>
    </ContentPageLayout>
  );
};

export default Pil;
