// @flow
import React, { useEffect, useMemo, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import TextBox from 'UI/components/atoms/TextBox';

import { useStyles } from './styles';

/** Styles components */

type CustomFooterProps = {
  count?: number,
  page?: number,
  rowsPerPage?: number,
  textLabels?: any,
  changeRowsPerPage?: (perPage: number) => void,
  changePage?: (page: number) => void
};

const FooterActions = props => {
  const { count, rowsPerPage, page, onChangePage, className } = props;
  const [pageValue, setPageValue] = useState(page);
  const classes = useStyles();
  const totalPages = useMemo(() => Math.ceil(count / rowsPerPage), [count, rowsPerPage]);

  const handleInputPageChange = ({ target, key }) => {
    if (key !== 'Enter') return;

    const isNotNumber = new RegExp(/\D/).test(target.value);
    const selectedPage = parseInt(target.value, 10);
    let pageToGo;

    if (isNotNumber || Number.isNaN(selectedPage)) {
      setPageValue(page + 1);
      return;
    }

    if (selectedPage > totalPages) {
      pageToGo = totalPages - 1;
    } else if (selectedPage < 1) {
      pageToGo = 0;
    } else {
      pageToGo = selectedPage - 1;
    }
    setPageValue(pageToGo + 1);
    onChangePage && onChangePage(null, pageToGo);
  };

  useEffect(() => {
    setPageValue(page + 1);
  }, [page]);

  const handleChange = (_, val) => setPageValue(val);
  const handleBlur = () => setPageValue(page + 1);

  return (
    <>
      <div className={classes.actionsRoot}>
        <div className={classes.inputTitle}>Go to Page:</div>
        <TextBox
          name="page"
          value={pageValue}
          onChange={handleChange}
          onKeyPress={handleInputPageChange}
          onBlur={handleBlur}
          className={classes.input}
        />
        <div className={classes.totalPages}>of {totalPages}</div>
      </div>
      <TablePaginationActions {...props} className={clsx(className, classes.pageBtns)} />
    </>
  );
};

const CustomFooter = ({
  count,
  textLabels,
  rowsPerPage,
  page,
  changeRowsPerPage,
  changePage
}: CustomFooterProps) => {
  const classes = useStyles();

  const handleRowChange = event => changeRowsPerPage && changeRowsPerPage(event.target.value);

  const handlePageChange = (_, newPage) => changePage && changePage(newPage);

  return (
    <TableFooter>
      <TableRow>
        <TableCell className={classes.root} colSpan={1000}>
          {!!count && (
            <p className={classes.totalResults}>
              Total Results: <strong>{count}</strong>
            </p>
          )}
          <TablePagination
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Items per Page:"
            labelDisplayedRows={({ from, to }) =>
              `Result(s): ${from}-${to} ${'of'} ${Number(count)}`
            }
            backIconButtonProps={{
              'aria-label': textLabels && textLabels.previous
            }}
            nextIconButtonProps={{
              'aria-label': textLabels && textLabels.next
            }}
            rowsPerPageOptions={[10, 20, 50, 100]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowChange}
            ActionsComponent={FooterActions}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

CustomFooter.defaultProps = {
  count: 0,
  page: 0,
  rowsPerPage: 10,
  textLabels: undefined,
  changeRowsPerPage: undefined,
  changePage: undefined
};

export default CustomFooter;
