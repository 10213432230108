import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { DateFormats } from 'UI/constants/defaults';
import { localTimeFormatter } from 'UI/utils';

import { formatInternationalPhone } from '../messageChat/chat.utils';

import { useStyles } from './styles';

export const Message = ({ message, onClickMessage, isSelected }) => {
  const styles = useStyles();

  const { fullName = '', externalNumber, date, message: messageText } = message;

  const dateFormatted = localTimeFormatter(date, DateFormats.SimpleDateTime);

  const phoneNumber = formatInternationalPhone(externalNumber);

  const handleClickMessage = () => {
    onClickMessage(message);
  };

  return (
    <ListItem
      aria-label="message"
      button
      className={styles.messageContainer}
      onClick={handleClickMessage}
      selected={isSelected}
    >
      <ListItemText
        primary={
          <div className={styles.header}>
            <Typography variant="body2">
              <span className={styles.boldText}>{fullName}</span>
              {phoneNumber}
            </Typography>

            <Typography variant="body2">{dateFormatted}</Typography>
          </div>
        }
        secondary={<Typography className={styles.messageText}>{messageText}</Typography>}
      />
    </ListItem>
  );
};
