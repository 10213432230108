// @flow
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import strings from 'strings';
import FPIcon from 'UI/components/atoms/FPIcon';
import { SvgUploadButton } from 'UI/res';
import { formatBytes } from 'UI/utils';

import { useStyles } from './styles';

type DropzoneProps = {
  maxFileSize: number,
  onDropFinished: () => void
};

const Dropzone = ({ onDropFinished, maxFileSize }: DropzoneProps) => {
  const classes = useStyles();

  const onDrop = useCallback(acceptedFiles => onDropFinished(acceptedFiles), [onDropFinished]);
  const { getRootProps, getInputProps, isDragAccept } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps({
        className: clsx(classes.baseStyle, isDragAccept && classes.acceptStyle)
      })}
    >
      <input {...getInputProps()} />
      <span className={classes.textIconContainer}>
        <FPIcon component={SvgUploadButton} />
        <span className={classes.iconText}>
          {strings.formatString(strings.fileManager.dropzone.enabled, {
            maxFileSize: formatBytes(maxFileSize)
          })}
        </span>
      </span>
    </div>
  );
};

export default Dropzone;
