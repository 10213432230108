// @flow
import { getFeatureFlags } from 'UI/utils';

export const validateSamePositionAndSorting = (results: Object) => {
  const hasDuplicatedPositions = results.some((item, index, array) => {
    if (index > 0) {
      const hasSamePosition =
        item.sectionPosition.position === array[index - 1].sectionPosition.position;

      const hasDifferentAreaName =
        item.sectionPosition.areaName !== array[index - 1].sectionPosition.areaName;

      return hasSamePosition && !hasDifferentAreaName;
    }
    return false;
  });

  const hasPositionValue = results.every(item => !!item.sectionPosition.position);

  const shouldSortItems = hasDuplicatedPositions === false && hasPositionValue === true;

  return { shouldSortItems };
};

export const filterItemsByAreaName = (sections: Array, grid: Object) =>
  sections?.filter(
    section =>
      !!section.sectionPosition.areaName &&
      grid.sectionPosition.areaName &&
      section.sectionPosition.areaName === grid.sectionPosition.areaName
  );

export const sortElementsByPosition = (elements: Array<Object>, shouldSortItems: boolean) =>
  elements?.sort((a, b) =>
    shouldSortItems ? a.sectionPosition.position - b.sectionPosition.position : 0
  );

type SortRenderersAndAreas = {
  results: Object,
  isPrivateView: boolean
};

export const sortRenderersAndAreas = ({ results, isPrivateView }: SortRenderersAndAreas) => {
  const { shouldSortItems: shouldSortAreas } = validateSamePositionAndSorting(results.layout);

  const filteredItemsByPrivateView = results?.sections.filter(item =>
    isPrivateView ? item : item.isPrivateView === false
  );

  const { shouldSortItems: shouldSortRecruiterItems } = validateSamePositionAndSorting(
    filteredItemsByPrivateView
  );

  const sortedAreas = sortElementsByPosition(results?.layout, shouldSortAreas);

  return { sortedAreas, shouldSortRecruiterItems, filteredItemsByPrivateView };
};

type FilterDataByFeatureFlag = {
  results: Object
};

export const filterDataByFeatureFlag = ({ results }: FilterDataByFeatureFlag) => {
  const allowedItemsByFeatureFlag = results.sections.filter(item =>
    item.featureFlag ? getFeatureFlags().includes(item.featureFlag) : item
  );

  const data = { ...results, sections: allowedItemsByFeatureFlag };
  return { data };
};
