import { styled } from '@material-ui/core';

export const DataSheetSection = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  alignItems: 'center',

  '& h4': {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(22),
    display: 'flex',
    margin: 'unset'
  },

  '& .dataSheetButton': {
    display: 'block',
    whiteSpace: 'nowrap',
    margin: 'unset'
  },

  '& .MuiTypography-subtitle1': {
    fontSize: `${theme.typography.pxToRem(16)} !important`,
    whiteSpace: 'break-spaces'
  },

  '& [class*="hintContainer"]': {
    alignItems: 'start',

    '& svg': {
      marginTop: theme.spacing(0.5)
    }
  }
}));

export const DataSheetCardHeading = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1)
}));
