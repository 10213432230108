import React from 'react';
import { SimplifiedTable } from 'features/command-center/components/shared';
import { TABLES_KEYS } from 'features/command-center/constants';
import { SECONDARY_API_CLIENT } from 'services/API';

import { COLUMNS, ORDER_BY_OPTIONS, VIRTUAL_PROPS } from './CandidatesDrawerContent.constants';
import { formatResponse } from './CandidatesDrawerContent.utils';

export const CandidatesDrawerContent = ({ endpoint = '' }) => {
  return (
    <SimplifiedTable
      apiInstance={SECONDARY_API_CLIENT}
      columns={COLUMNS}
      endpoint={endpoint}
      formatResponse={formatResponse}
      orderByOptions={ORDER_BY_OPTIONS}
      pageKey={TABLES_KEYS.drawerCandidates}
      shouldUseSavedPagination={false}
      showWrapper={false}
      virtualProps={VIRTUAL_PROPS}
    />
  );
};
