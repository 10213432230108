// @flow
import React from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import FPIcon from 'UI/components/atoms/FPIcon';
import FPLink from 'UI/components/atoms/FPLink';
import ProfileAvatar from 'UI/components/molecules/ProfileAvatar';
import { PhoneIcon, SvgEmailFilled, SvgJobOrders, SvgMap } from 'UI/res';
import { phoneNumberFormatter } from 'UI/utils';

import { useStyles } from './styles';

const SHARED_GRID_SIZE = 2;

const profileInfoConfig = data => [
  {
    gridSize: { md: 'auto' },
    icon: SvgMap,
    text: data?.location
  },
  {
    gridSize: { md: 'auto' },
    icon: SvgJobOrders,
    text: `${moment(data?.start_date).fromNow(true)} at the pac`
  },
  {
    gridSize: { md: 'auto' },
    icon: PhoneIcon,
    linkProps: {
      url: phoneNumberFormatter(data?.phone),
      type: 'ringCentral'
    }
  },
  {
    gridSize: { md: 'auto' },
    icon: SvgEmailFilled,
    href: `mailto:${data?.email}`,
    linkProps: {
      url: data?.email,
      type: 'external'
    }
  }
];

const RecruiterHeaderContent = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        className={classes.content}
        container
        alignItems="center"
        justify="flex-start"
        spacing={SHARED_GRID_SIZE}
      >
        {profileInfoConfig(data).map(item => (
          <Grid
            alignItems="center"
            className={classes.iconInfoContainer}
            container
            item
            key={item.icon}
            spacing={SHARED_GRID_SIZE}
            {...item?.gridSize}
          >
            <Grid item container alignItems="center" className={classes.iconInfoContainer}>
              <FPIcon component={item?.icon} size="sm" />
            </Grid>
            <Grid item>
              {item?.linkProps ? (
                <FPLink linkProps={{ ...item?.linkProps }} href={item?.href} variant="body2">
                  {item?.url}
                </FPLink>
              ) : (
                <Typography>{item?.text}</Typography>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        className={classes.avatarContainer}
        container
        justify="flex-start"
        spacing={SHARED_GRID_SIZE}
      >
        <Grid item>
          <ProfileAvatar
            title={data?.coach?.full_name}
            initials={data?.coach?.initials}
            detail="Team Lead"
            showDetails
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RecruiterHeaderContent;
