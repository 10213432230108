import React from 'react';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import strings from 'strings';

import { EmptyRender, LoadingRender } from '../utils';

import SendoutsDataTable from './SendoutsDataTable';

const SendoutsLoader = ({ endpoint, week, month, year }) => {
  const { Status } = useFetchWithStatus(
    `${endpoint}?year=${year?.date ? year.date : ''}&month=${month?.date ? month.date : ''}&week=${
      week?.date ? week.date : ''
    }`
  );
  return (
    <Status
      loading={<LoadingRender />}
      success={data => <SendoutsDataTable items={data} year={year} month={month} week={week} />}
      empty={
        <EmptyRender
          title={strings.feeAgreements.dashboard.title}
          subtitle={strings.feeAgreements.dashboard.subtitle}
        />
      }
    />
  );
};

export default SendoutsLoader;
