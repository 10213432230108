// @flow
import React, { useEffect, useRef, useState } from 'react';

const LazyRenderWrapper = ({ children, options, noVisibleHeight = 200 }) => {
  const [loaded, setLoaded] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const { isIntersecting } = entry;
        if (isIntersecting) {
          setLoaded(true);
          observer.disconnect();
        } else {
          setLoaded(false);
        }
      });
    }, options);

    observer.observe(ref.current);
  }, [options]);

  if (loaded) {
    return (
      <div className="visible-item" ref={ref}>
        {children}
      </div>
    );
  }

  return <div className="not-visible-item" style={{ height: `${noVisibleHeight}px` }} ref={ref} />;
};

export default LazyRenderWrapper;
