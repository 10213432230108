import React, { useCallback } from 'react';
import { DRAWER_KEYS, StatsItemsCard } from 'features/command-center/components/shared';
import { COMMAND_CENTER_BASE_URL } from 'features/command-center/constants';
import { useDrawerManager } from 'features/command-center/contexts';
import { getWeekActivity } from 'features/command-center/services';
import strings from 'strings';

import { formatStats } from './WeeklyActivityStatsSummary.utils';

export const WeeklyActivityStatsSummary = ({ requestParams, hasDrillDown }) => {
  const { openDrawer } = useDrawerManager();

  const cachedPromises = useCallback(() => {
    const drawerHandlers = hasDrillDown
      ? {
          calls: ({ url }) => {
            openDrawer({
              activeDrawer: DRAWER_KEYS.Calls,
              contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
            });
          },
          phoneTime: ({ url }) => {
            openDrawer({
              activeDrawer: DRAWER_KEYS.PhoneTime,
              contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
            });
          }
        }
      : null;

    const { recruiter } = requestParams;
    const groupBy = recruiter ? 'recruiter' : 'team';
    return getWeekActivity({ ...requestParams, groupBy }).then(({ data }) => {
      return formatStats({ data, drawerHandlers });
    });
  }, [hasDrillDown, openDrawer, requestParams]);

  return (
    <StatsItemsCard
      header={strings.commandCenter.activity.weeklyActivityStatsSummary.title}
      promise={cachedPromises}
      textAlign="center"
    />
  );
};
