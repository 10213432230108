import { FeeAgreementDefaultValues } from 'UI/constants/defaults';
import { PaymentMode } from 'UI/constants/entityTypes';
import {
  EXT_PHONE_VALIDATION,
  PHONE_VALIDATION,
  REGULAR_PERCENT_VALIDATION,
  REQUIRED_VALIDATION
} from 'UI/utils';

import { FormFieldsMap } from '../fields';

const oneThird = 1 / 3;

export const calculateFeeAmounts = ({ percent, compensation, paymentMode }) => {
  const amounts = {
    total: null,
    monthly: null
  };

  if (!percent || !compensation) return amounts;

  const feePercent = Number.parseFloat(percent);
  const firstYearComp = Number.parseFloat(compensation);
  const deltaMargin = 0.04;
  const isDefaultPercent =
    Math.abs(feePercent - FeeAgreementDefaultValues.FeePercent) <= deltaMargin;
  const calculatedFeeAmount = Number.parseFloat(
    isDefaultPercent ? firstYearComp * oneThird : (firstYearComp * feePercent) / 100
  ).toFixed(2);
  const isValidAmount = !Number.isNaN(calculatedFeeAmount);

  if (isValidAmount) {
    amounts.total = calculatedFeeAmount;
    if (paymentMode === PaymentMode.Conversion) {
      const monthlyConversion = calculatedFeeAmount / 12;
      amounts.monthly = monthlyConversion.toFixed(2);
    }
  }

  return amounts;
};

export const buildFieldsConfig = ({ getFormValues }) => {
  return [
    {
      name: FormFieldsMap.PaymentScheme.key,
      validation: REQUIRED_VALIDATION
    },
    {
      name: FormFieldsMap.FeeAgreement.key
    },
    {
      name: FormFieldsMap.StartDate.key,
      validation: REQUIRED_VALIDATION
    },
    {
      name: FormFieldsMap.GuaranteeDays.key,
      validation: REQUIRED_VALIDATION
    },
    {
      name: FormFieldsMap.FeePercentage.key,
      validation: {
        validate(val) {
          const payMode = getFormValues(FormFieldsMap.PaymentScheme.key);
          const percentage = Number.parseFloat(val);
          const isPercentage = percentage > 0 && percentage <= 100;
          return (
            payMode === PaymentMode.Flat ||
            (payMode !== PaymentMode.Flat &&
              isPercentage &&
              percentage >= REGULAR_PERCENT_VALIDATION.min.value) ||
            `Must be between ${REGULAR_PERCENT_VALIDATION.min.value}% and ${REGULAR_PERCENT_VALIDATION.max.value}%`
          );
        }
      }
    },
    {
      name: FormFieldsMap.FirstYearCompensation.key,
      validation: {
        validate(val) {
          const compensation = Number.parseFloat(val);
          const payMode = getFormValues(FormFieldsMap.PaymentScheme.key);

          return (
            (payMode !== PaymentMode.Flat && compensation > 0) ||
            payMode === PaymentMode.Flat ||
            REQUIRED_VALIDATION.required
          );
        }
      }
    },
    {
      name: FormFieldsMap.ServiceMonths.key
    },
    {
      name: FormFieldsMap.MonthlyAmount.key,
      validation: {
        validate(val) {
          const monthlyAmount = Number.parseFloat(val);
          const payMode = getFormValues(FormFieldsMap.PaymentScheme.key);
          return (
            monthlyAmount > 0 || payMode !== PaymentMode.Conversion || REQUIRED_VALIDATION.required
          );
        }
      }
    },
    {
      name: FormFieldsMap.FeeAmount.key,
      validation: REQUIRED_VALIDATION
    },
    {
      name: FormFieldsMap.CandidateSourceType.key,
      validation: REQUIRED_VALIDATION
    },
    {
      name: FormFieldsMap.JobOrderSourceType.key,
      validation: REQUIRED_VALIDATION
    },
    {
      name: FormFieldsMap.ShouldBillToOtherCompany.key
    },
    {
      name: FormFieldsMap.BillToCompany.key
    },
    {
      name: FormFieldsMap.HiringAuthority.key,
      validation: REQUIRED_VALIDATION
    },
    {
      name: FormFieldsMap.CompanyPhone.key,
      validation: { ...REQUIRED_VALIDATION, ...PHONE_VALIDATION }
    },
    {
      name: FormFieldsMap.CompanyPhoneExtension.key,
      validation: EXT_PHONE_VALIDATION
    },
    {
      name: FormFieldsMap.ShouldRequestFeeChange.key
    },
    {
      name: FormFieldsMap.ShouldRequestGuaranteePeriodChange.key
    },
    {
      name: FormFieldsMap.ReasonForRequestChange.key,
      validation: {
        validate(val) {
          const isFeeRequestChangeEnabled = getFormValues(FormFieldsMap.ShouldRequestFeeChange.key);
          const isGuaranteeDaysRequestChangeEnabled = getFormValues(
            FormFieldsMap.ShouldRequestGuaranteePeriodChange.key
          );
          const isValEmpty = typeof val !== 'string' || val.trim().length === 0;

          if ((isFeeRequestChangeEnabled || isGuaranteeDaysRequestChangeEnabled) && isValEmpty) {
            return REQUIRED_VALIDATION.required;
          }
          return null;
        }
      }
    }
  ];
};
