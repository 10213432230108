import * as React from "react";

const SvgFileXls = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M17.5 6h3.9a.25.25 0 00.17-.43L16.43.42A.26.26 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z" />
      <path d="M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM9.06 18.19a.63.63 0 01-.28.84.72.72 0 01-.28.07.63.63 0 01-.56-.35L7.06 17a.07.07 0 00-.12 0l-.88 1.76a.63.63 0 01-.56.35.68.68 0 01-.28-.11.63.63 0 01-.28-.84l1.3-2.61a.22.22 0 000-.22l-1.3-2.61a.63.63 0 111.12-.56l.87 1.75a.08.08 0 00.14 0l.87-1.75a.63.63 0 011.12.56l-1.31 2.64a.28.28 0 000 .22zm4.44.91h-2a1.62 1.62 0 01-1.62-1.63v-5a.62.62 0 011.24 0v5a.38.38 0 00.38.38h2a.63.63 0 010 1.25zm2.93-4.76l1.84 1.23a1.93 1.93 0 01-1.07 3.53h-1.7a.63.63 0 010-1.25h1.7a.69.69 0 00.68-.68.66.66 0 00-.31-.56l-1.84-1.23a1.93 1.93 0 011.07-3.53h1.7a.63.63 0 010 1.25h-1.7a.68.68 0 00-.68.68.69.69 0 00.31.56z" />
    </svg>
  );
};

export default SvgFileXls;
