import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  UserContainer: {
    overflowY: 'auto',
    paddingTop: theme.spacing(2),
    height: 'max-content',
    position: 'relative'
  },
  spActionWrapper: {
    textAlign: 'end'
  }
}));
