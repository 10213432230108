import React, { useCallback } from 'react';
import { DRAWER_KEYS, StatsItemsCard } from 'features/command-center/components/shared';
import { COMMAND_CENTER_BASE_URL } from 'features/command-center/constants';
import { useDrawerManager } from 'features/command-center/contexts';
import { getActiveInventory } from 'features/command-center/services';
import strings from 'strings';
import { getCurrentWeekRange } from 'UI/utils';

import { formatDate, formatStats } from './WeeklyInventoryGrowthStatsSummary.utils';

export const WeeklyInventoryGrowthStatsSummary = ({ requestParams, hasDrillDown }) => {
  const week = getCurrentWeekRange();
  const { openDrawer } = useDrawerManager();

  const startDate = formatDate(week.startDate);
  const endDate = formatDate(week.endDate);
  const activeInventoryMetrics = 'candidates,jobOrders,companies,names,hiringAuthorities';
  const cachedPromises = useCallback(() => {
    const drawerHandlers = hasDrillDown
      ? {
          candidates: {
            overall: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.Candidates.Overall,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            }
          },
          companies: {
            overall: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.Companies.Total,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            }
          },
          hiringAuthorities: {
            overall: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.HiringAuthorities.Total,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            }
          },
          jobOrders: {
            overall: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.JobOrders.Overall,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            }
          },
          names: {
            overall: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.Names.Overall,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            }
          }
        }
      : null;

    return Promise.all([
      getActiveInventory({
        ...requestParams,
        startDate,
        endDate,
        metrics: activeInventoryMetrics
      })
    ]).then(([data]) => {
      return formatStats({ data, drawerHandlers });
    });
  }, [endDate, hasDrillDown, openDrawer, requestParams, startDate]);

  return (
    <StatsItemsCard
      header={strings.commandCenter.activity.weeklyInventoryGrowthStatsSummary.title}
      promise={cachedPromises}
      subheader={strings.commandCenter.activity.weeklyInventoryGrowthStatsSummary.subtitle}
      textAlign="center"
    />
  );
};
