import strings from 'strings';

import { JobOrdersDrawerContent } from './JobOrdersDrawerContent';

const { jobOrders } = strings.commandCenter.drawers;

export const JobOrdersConfig = {
  title: jobOrders.title,
  component: JobOrdersDrawerContent
};
