// @flow
import { useState } from 'react';
import { rowClickBuilder } from 'hooks/profileActions';
import { handleAssignEntityRole } from 'UI/components/organisms/inventoryProfiles/FPQuickView/utils';
import { getProfileQuickViewByEntity } from 'UI/constants/LogRocketCustomEvents';

export const getItemsToNavigate = (data, quickViewEntityRoleProps) =>
  data.map(tableItem =>
    quickViewEntityRoleProps
      ? {
          id: tableItem.id,
          [quickViewEntityRoleProps.roleKey]: tableItem[quickViewEntityRoleProps.roleKey]
        }
      : { id: tableItem.id }
  );

export const useQuickViewPreview = ({
  columns,
  data,
  quickViewEntityRoleProps = {},
  entityType
}) => {
  const [entityId, setEntityId] = useState();
  const [quickViewEntity, setQuickViewEntity] = useState(entityType ?? null);

  const [uiState, setUiState] = useState({
    isQuickViewOpen: false
  });

  const handleQuickViewPreview = item => {
    if (item.id === undefined) return;

    setEntityId(item.id);
    !entityType &&
      handleAssignEntityRole({
        item,
        quickViewEntityRoleProps,
        onSetCurrentEntity: setQuickViewEntity
      });

    toggleQuickViewPreview();
  };

  const listData = getItemsToNavigate(data, quickViewEntityRoleProps);

  const toggleQuickViewPreview = () =>
    setUiState(prev => ({ ...prev, isQuickViewOpen: !uiState.isQuickViewOpen }));

  const listViewPreset = {
    name: 'view',
    onClick: rowClickBuilder({
      columns,
      data: listData,
      callback: handleQuickViewPreview,
      enableTracking: true,
      logRocketEvent: getProfileQuickViewByEntity(quickViewEntity)
    })
  };

  return {
    handleQuickViewPreview,
    toggleQuickViewPreview,
    entityId,
    setEntityId,
    quickViewEntity,
    listViewPreset,
    setUiState,
    quickViewState: uiState
  };
};
