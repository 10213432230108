// @flow
import React from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import { FPCardContent } from 'UI/components/atoms/FPCard';
import FPLinearProgress from 'UI/components/atoms/FPLinearProgress';
import Text from 'UI/components/atoms/Text';

import { useStyles } from './styles';

type WeeklyStatsProps = {
  bulksValues: Object,
  newCandidates: Number,
  newJobOrders: Number,
  sendoutsValues: Object,
  sendoversValues: Object
};

const WeeklyStats = ({
  bulksValues,
  newCandidates,
  newJobOrders,
  sendoutsValues,
  sendoversValues
}: WeeklyStatsProps) => {
  const history = useHistory();
  const theme = useTheme();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles({ isSmallViewPort });

  const { primary, warning } = theme.palette;

  const linearProgressItems = [
    {
      title: strings.home.sections.weeklyStats.sendouts.title,
      backgroundColor: primary.FP100,
      color: primary.FP300,
      current: sendoutsValues.current,
      goal: sendoutsValues.goal
    },
    {
      title: strings.home.sections.weeklyStats.convertedSendovers.title,
      backgroundColor: warning.FP200,
      color: warning.light,
      current: sendoversValues.current,
      goal: sendoversValues.goal
    },
    {
      title: strings.home.sections.weeklyStats.bulks.title,
      backgroundColor: primary.FP100,
      color: primary.light,
      current: bulksValues.current,
      goal: bulksValues.goal
    }
  ];

  const cardItems = [
    {
      className: 'candidatesCard',
      redirectTo: `${EntityRoutes.Candidates}?orderBy=created_at&direction=desc`,
      title: strings.home.sections.weeklyStats.candidatesCard.title,
      value: newCandidates
    },
    {
      className: 'jobOrdersCard',
      redirectTo: `${EntityRoutes.JobOrders}?orderBy=created_at&direction=desc`,
      title: strings.home.sections.weeklyStats.jobOrdersCard.title,
      value: newJobOrders
    }
  ];

  const handleActionClick = action => () => history.push(action);
  return (
    <div className={classes.container}>
      <Grid container>
        {linearProgressItems.map(progressItem => (
          <Grid role="grid" key={progressItem.title} item xs={12} className={classes.content}>
            <Text className={classes.title} cropped text={progressItem.title} />

            <Grid className={classes.progressBarContainer}>
              <Grid item sm={10} xs={11} className={classes.progressBar}>
                <FPLinearProgress
                  backgroundColor={progressItem.backgroundColor}
                  color={progressItem.color}
                  value={
                    progressItem.current <= progressItem.goal
                      ? progressItem.current
                      : progressItem.goal
                  }
                  max={progressItem.goal}
                />
              </Grid>
              <Grid item sm={2} xs={1}>
                <Text
                  className={classes.progressLabel}
                  cropped
                  text={`${progressItem.current}/${progressItem.goal}`}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}

        <Grid role="group" item xs={12} className={classes.cardContainer}>
          {cardItems.map(cardItem => (
            <div key={cardItem.title} className={classes.cardContent}>
              <FPCardContent
                role="term"
                className={classes[cardItem.className]}
                onClick={handleActionClick(cardItem.redirectTo)}
              >
                <div className={classes.card}>
                  <Text className={classes.titleCard} cropped text={cardItem.title} />
                </div>

                <div className={classes.card}>
                  <Text className={classes.valueCard} cropped text={cardItem.value} />
                </div>
              </FPCardContent>
            </div>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

const initialState = {
  current: 0,
  goal: 0
};

WeeklyStats.defaultProps = {
  bulksValues: { ...initialState },
  newCandidates: 0,
  newJobOrders: 0,
  sendoutsValues: { ...initialState },
  sendoversValues: { ...initialState }
};

export default WeeklyStats;
