// @flow
import React from 'react';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import { InfoLabelModes } from 'UI/components/molecules/InfoLabel';
import InventoryOverviewLayout from 'UI/components/organisms/inventoryProfiles//InventoryOverviewLayout';
import InfoLabelsGrid from 'UI/components/organisms/inventoryProfiles/InfoLabelsGrid';
import { AutoWidthGrid } from 'UI/constants/dimensions';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getFeatureFlags, nestTernary, phoneNumberFormatter } from 'UI/utils';

import ProfileAlert from '../CandidateOverview/ProfileAlert/ProfileAlert';
import {
  buildEmailLabels,
  extractFullName,
  getEmailValidationAlerts,
  getPhonesLabelsForProfile
} from '../utils';

const {
  shared: {
    fields: { currentCompany: currentCompanyString }
  }
} = strings;

type OverviewSectionsProps = {
  activityLogs: Array<Object>,
  notes: Array<Object>
};

type OverviewActionsProps = {
  onNotesClick: (item: number) => (e: number) => void,
  onActivitiesClick: (item: number) => (e: number) => void
};
type HiringAuthorityOverviewProps = {
  profile?: Object,
  loading: boolean,
  overviewSections: OverviewSectionsProps,
  overviewActions: OverviewActionsProps
};

const HiringAuthorityOverview = ({ loading, profile }: HiringAuthorityOverviewProps) => {
  const hasMultiplePhones = !!profile.phone_id;
  const isMultiplePhonesEnabled = getFeatureFlags().includes(
    FeatureFlags.HiringAuthoritiesMultiplePhones
  );

  const fullName = extractFullName(profile, EntityType.HiringAuthority);

  const legacyPhoneLabels = [
    {
      title: 'Phone',
      mode: 'ringCentral',
      linkProps: {
        url: phoneNumberFormatter(profile?.work_phone),
        id: profile?.id,
        role: EntityType.HiringAuthority,
        customLinkProps: {
          profileName: fullName
        }
      }
    },
    {
      title: 'EXT',
      description: profile?.ext
    },

    {
      title: 'Other Phone ',
      mode: 'ringCentral',
      linkProps: {
        url: phoneNumberFormatter(profile?.personal_phone),
        id: profile?.id,
        role: EntityType.HiringAuthority,
        customLinkProps: {
          profileName: fullName
        }
      }
    },
    {
      title: 'Other EXT',
      description: profile?.other_ext
    }
  ];

  const generalLabels = [
    {
      title: 'Nickname',
      description: profile?.nickname
    },
    {
      title: 'Title',
      description: profile?.title
    },
    {
      title: 'Functional Title',
      description: profile?.position?.title
    },
    { title: 'Industry: Specialty', description: profile?.specialty?.title },
    { title: 'Subspecialty', description: profile?.subspecialty?.title },
    {
      title: currentCompanyString,
      mode: profile?.company_id ? 'localLink' : 'text',
      description: profile?.company?.name,
      itemId: profile?.company_id,
      linkProps: profile?.company_id
        ? {
            url: EntityRoutes.CompanyProfile
          }
        : ''
    },
    ...buildEmailLabels({ profile, entityType: EntityType.HiringAuthority }),
    {
      title: 'Linkedin Profile',
      mode: InfoLabelModes.EXTERNAL_LINK,
      linkProps: {
        url: profile?.link_profile ?? ''
      },
      config: AutoWidthGrid
    }
  ];

  const phoneLabels = !isMultiplePhonesEnabled
    ? legacyPhoneLabels
    : nestTernary(
        hasMultiplePhones,
        getPhonesLabelsForProfile(profile, EntityType.HiringAuthority),
        legacyPhoneLabels
      );

  const alerts = getEmailValidationAlerts({
    profile,
    loading
  }).map(item => ({
    componentProps: item,
    Component: ProfileAlert
  }));

  const cards = [
    ...alerts,
    {
      cardProps: { headerProps: { title: 'General Information' } },
      Component: InfoLabelsGrid,
      componentProps: { labels: [...generalLabels, ...phoneLabels] },
      className: 'generalCard'
    }
  ];

  return <InventoryOverviewLayout loading={loading} items={cards} />;
};

HiringAuthorityOverview.defaultProps = {
  profile: {}
};

export default HiringAuthorityOverview;
