// alignProperty
export const flex = {
  display: 'flex'
};

export const flexAlignCenter = {
  ...flex,
  alignItems: 'center'
};

export const flexAlignStart = {
  ...flex,
  alignItems: 'flex-start'
};

export const flexAlignEnd = {
  ...flex,
  alignItems: 'flex-end'
};

export const flexAndWrap = {
  ...flex,
  flexWrap: 'wrap'
};

export const flexJustifyEnd = {
  ...flex,
  justifyContent: 'flex-end'
};

// alignCenter + custom justifyProperty

export const flexAlignCenterJustifyCenter = {
  ...flexAlignCenter,
  justifyContent: 'center'
};

export const flexAlignCenterSpaceBetween = {
  ...flexAlignCenter,
  justifyContent: 'space-between'
};

export const flexAlignCenterSpaceAround = {
  ...flexAlignCenter,
  justifyContent: 'space-around'
};

export const flexAlignCenterFlexStart = {
  ...flexAlignCenter,
  justifyContent: 'flex-start'
};

export const flexAlignCenterFlexEnd = {
  ...flexAlignCenter,
  justifyContent: 'flex-end'
};

// styles with Flex alignStart + custom justifyProperty

export const flexAlignStartCenter = {
  ...flex,
  alignItems: 'flex-start',
  justifyContent: 'center'
};

export const flexAlignStartJustifyStart = {
  ...flexAlignStart,
  justifyContent: 'flex-start'
};

export const flexAlignStartSpaceBetween = {
  ...flexAlignStart,
  justifyContent: 'space-between'
};

export const whiteSpace = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

// Transition Values //////////////////////////////

export const linearSlowIn = time => `${time}s cubic-bezier(0, 0, 0.2, 1)`;

export const outBack = time => `${time}s cubic-bezier(0.18, 0.89, 0.32, 1.28)`;

export const inOutBack = time => `${time}s cubic-bezier(0.68, -0.55, 0.27, 1.55)`;

export const easeInOut = time => `${time}s ease-in-out`;

// Make Outline Text

export const outLineText = (width, color) =>
  `-${width}px -${width}px 0 ${color}, ${width}px -${width}px 0 ${color}, -${width}px ${width}px 0 ${color}, ${width}px ${width}px 0 ${color} `;

// item to center in y axis when it's absolute, please provide a height value from Parent Container

export const itemToCenter = height => `calc(100% / 2 - ${height / 2}px)`;

/* 
🛸 🛸 🛸 🛸 👽 👽 👽 👽 

 C U S T O M    R I P P L E   E F F E C T

This functions exists in order to solve anchors ripple effect when buttons not work as expected specifically on ringcentral's anchors
used on tables and profiles

Order to appply colors
1.- define your container background
2.- add a darker version of your parent background on backgroundOnHover position
3.- finally add your color effect when the item is clicked on rippleColor value
 */

export const rippleEffect = (backgroundOnHover, rippleColor) => {
  return {
    backgroundPosition: 'center',
    transition: 'background 0.8s',
    '&:hover': {
      background: `${backgroundOnHover} radial-gradient(circle, transparent 1%, ${backgroundOnHover} 1%) center/15000%`
    },
    '&:active': {
      backgroundColor: rippleColor,
      backgroundSize: '100%',
      transition: 'background 0s'
    }
  };
};
