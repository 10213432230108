import * as React from 'react';

const SvgJobOrders = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M6 22.38a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-16a.5.5 0 00-.5-.5h-.25a.5.5 0 01-.5-.5V4.12a3 3 0 00-3-3h-3.5a3 3 0 00-3 3v1.26a.5.5 0 01-.5.5H6.5a.5.5 0 00-.5.5zM9.25 4.12a1 1 0 011-1h3.5a1 1 0 011 1v1.26a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5zM4 6.38a.5.5 0 00-.5-.5A3.5 3.5 0 000 9.38v10a3.51 3.51 0 003.5 3.5.5.5 0 00.5-.5zM20.5 5.88a.5.5 0 00-.5.5v16a.5.5 0 00.5.5 3.51 3.51 0 003.5-3.5v-10a3.5 3.5 0 00-3.5-3.5z" />
    </svg>
  );
};

export default SvgJobOrders;
