// @flow
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import FileItem from 'UI/components/molecules/FileItem';
import type { FileDraggableProps } from 'UI/components/organisms/FileManager';

import { useStyles } from './styles';

const FileDraggable = ({
  file,
  FileItemProps,
  fileNameField,
  index,
  onFileDelete,
  onFileMove,
  readOnly,
  showFileSize,
  showItemDate,
  showItemMenu,
  viewItemOnClick
}: FileDraggableProps) => {
  const classes = useStyles();

  return (
    <Draggable
      draggableId={file.id.toString()}
      index={index}
      isDragDisabled={readOnly || file.isLoading}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? classes.isDragging : null}
        >
          <FileItem
            error={file?.hasError}
            file={file}
            fileName={file[fileNameField]}
            loading={file.isLoading}
            message={file?.message}
            onFileDelete={onFileDelete}
            onFileMove={onFileMove}
            readOnly={readOnly}
            showDate={showItemDate}
            showFileSize={showFileSize}
            showMenu={showItemMenu}
            viewOnClick={viewItemOnClick}
            {...FileItemProps}
          />
        </div>
      )}
    </Draggable>
  );
};

FileDraggable.defaultProps = {
  FileItemProps: {},
  readOnly: false,
  showFileSize: false,
  showItemDate: false,
  showItemMenu: false,
  viewItemOnClick: false
};

export default FileDraggable;
