import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  wrapperMarker: {
    height: 275,
    border: `1px solid ${theme.palette.text.disabled}`,
    borderRadius: theme.shape.borderRadius,
    overflowY: 'auto',
    padding: theme.spacing(2),

    '& i': {
      backgroundColor: '#FFFF17',
      fontStyle: 'normal'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: `transparent !important`
    },
    '& .ck.ck-reset_all': {
      opacity: 0
    }
  }
}));
