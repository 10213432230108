// @flow
import { makeStyles } from '@material-ui/core';
import { colors } from 'UI/res/colors';
import { flexAlignCenter, flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const stylesClasses = makeStyles(theme => ({
  rowSchedule: {
    margin: theme.spacing(0, 1),
    ...flexAlignCenter
  },
  row: {
    ...flexAlignCenter,
    '& > label': {
      marginBottom: 0,
      marginRight: theme.spacing(1)
    }
  },
  marginHorizontal: {
    margin: theme.spacing(0, 1)
  },
  textSchedule: {
    margin: 0
  },
  wrapperRadioButtons: {
    margin: theme.spacing(0, 0, 1, 0)
  },
  marginHr: {
    margin: theme.spacing(4, 0)
  },
  btnRemove: {
    color: colors.error,
    marginLeft: 'auto'
  },
  btnAddSchedule: {
    border: 'none',
    backgroundColor: 'unset',
    color: colors.success,
    textDecoration: 'underline',
    fontWeight: 'bolder',
    margin: 0
  },
  interviewActionsContainer: {
    display: 'flex'
  },
  editInterviewBtnContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end'
  },
  editInterviewBtn: {
    border: 'none',
    backgroundColor: 'unset',
    color: colors.success,
    textTransform: 'capitalize',
    fontWeight: 'bolder',
    margin: 0
  },
  btnUnderlineDisable: {
    color: colors.grey,
    cusor: 'default !important',
    textDecoration: 'none'
  },
  marginZero: {
    margin: 0
  },
  hint: {
    height: 20,
    width: 20,
    backgroundColor: colors.success,
    borderRadius: 25,
    cursor: 'pointer',
    margin: theme.spacing(0, 1),
    ...flexAlignCenterJustifyCenter
  },
  hintDisabled: {
    backgroundColor: colors.grey
  }
}));
