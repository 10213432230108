import { makeStyles } from '@material-ui/core/styles';

const contentHeaderHeight = '115px';

export const useStyles = makeStyles(theme => ({
  listContainer: {
    height: `calc(100% - ${contentHeaderHeight})`,
    borderTop: `5px solid ${theme.palette.grey[200]}`
  }
}));
