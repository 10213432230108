// @flow
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { showAlert } from 'actions/app';
import usePageTitle from 'hooks/usePageTitle';
import { UserProviderContext } from 'providers/UserProvider';
import queryString from 'query-string';
import { EntityRoutes } from 'routes/constants';
import {
  deleteCrossDomainAuthCookie,
  getRedirectPage,
  loginWithAuthCode
} from 'services/Authentication';
import LoginButton from 'UI/components/atoms/LoginButton';
import Text from 'UI/components/atoms/Text';
import TitleLabel from 'UI/components/atoms/TitleLabel';
import { PageTitles } from 'UI/constants/defaults';
import { colors, DotsPattern, GpacLogo, WolfBackground } from 'UI/res';
import { getErrorMessage } from 'UI/utils';

import { styles, useStyles } from './styles';

const loginConfig = {
  clientId: `${(window.GPAC_ENV && window.GPAC_ENV.CLIENT_ID) || process.env.REACT_APP_CLIENT_ID}`,
  microsoftUrl: process.env.REACT_APP_MICROSOFT_URL,
  tenantId: process.env.REACT_APP_TENANT_URL,
  redirectUri: `${
    (window.GPAC_ENV && window.GPAC_ENV.LOGIN_REDIRECT_URL) || process.env.REACT_APP_REDIRECT_URL
  }`,
  scopes: process.env.REACT_APP_LOGIN_SCOPES,
  responseType: 'code'
};

type LoginProps = {
  onShowAlert: any => void,
  history: any
};

const Login = (props: LoginProps) => {
  const { onShowAlert, history } = props;

  const location = useLocation();
  const { code: authCode } = queryString.parse(location.search);
  const [, setUser] = useContext(UserProviderContext);

  usePageTitle({ title: PageTitles.Login, isLoading: false });

  useEffect(() => {
    deleteCrossDomainAuthCookie();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const signIn = useCallback(async () => {
    if (!authCode) return false;
    try {
      setIsLoading(true);

      const user = await loginWithAuthCode(authCode);
      setUser(user);

      setIsLoading(false);
      const redirectPage = getRedirectPage();
      history.replace(redirectPage || EntityRoutes.Home);
    } catch (error) {
      setUser(null);
      setIsLoading(false);
      onShowAlert({
        severity: 'error',
        title: 'Login',
        body: getErrorMessage(error),
        autoHideDuration: 5000
      });
      history.replace(EntityRoutes.Login); // To remove query strings
    }
    return true;
  }, [authCode, history, onShowAlert, setUser]);

  useEffect(() => {
    signIn();
  }, [signIn]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <DotsPattern
            width="20vh"
            fill={colors.red}
            style={styles.pattern1}
            className={classes.pattern}
          />
          <DotsPattern
            width="20vh"
            fill={colors.red}
            style={styles.pattern2}
            className={classes.pattern}
          />
          <GpacLogo style={{ paddingTop: '10vh' }} width="50vh" />
          <div className={classes.label}>
            <TitleLabel fontSize={36} text="Growing People And Companies" />
            <Typography variant="h2" className={classes.poweredBrand}>
              Powered by<span className={classes.fortPacHightLight}> FortPac</span>
            </Typography>
          </div>
          <Text
            variant="body2"
            fontSize={20}
            text="Please, log in with your Microsoft account by clicking the button below"
          />
          <center style={{ paddingBottom: '10vh' }}>
            {!isLoading && (
              <LoginButton
                tenantUrl={`${loginConfig.microsoftUrl}${loginConfig.tenantId}`}
                clientId={loginConfig.clientId}
                responseType={loginConfig.responseType}
                redirectUri={loginConfig.redirectUri}
                scopes={loginConfig.scopes}
              />
            )}
            {isLoading && <CircularProgress />}
          </center>
        </div>
      </div>
      <WolfBackground fill={colors.wolfImage} className={classes.backgroundImg} />
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onShowAlert: alert => dispatch(showAlert(alert))
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Login));
