// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';
import InfoLabel from 'UI/components/molecules/InfoLabel';
import { COMPANY_RELATIONSHIP_KEY } from 'UI/constants/defaults';
import { getId } from 'UI/utils';

import { useStyles } from './styles';

type RelationshipOverviewProps = {
  direction: string,
  data: Record[],
  title: string,
  type: string
};

const RelationshipOverview = ({ direction, data, title, type }: RelationshipOverviewProps) => {
  const isParent = type === COMPANY_RELATIONSHIP_KEY.parentCompany;

  const firstCompany = data && data[0];

  const company = isParent
    ? {
        id: firstCompany?.id,
        name: firstCompany?.name,
        industry: `${firstCompany?.industry}: ${firstCompany?.specialty}`,
        location: `${firstCompany?.country_slug}: ${firstCompany?.state}`
      }
    : {
        id: firstCompany?.id,
        name: firstCompany?.primaryText,
        industry: firstCompany?.industry,
        location: firstCompany?.location
      };

  const isColumn = direction === 'column';
  const classes = useStyles({ isColumn });
  const colSize = isColumn ? 6 : 3;

  const relationshipCount = data?.length;

  const relationshipCountTitle = `Number of ${title}`;

  const infoLabels = [{ key: 'name' }, { key: 'industry' }, { key: 'location' }];

  return (
    <Grid container direction={direction} className={classes.mainContainer}>
      <Grid item className={classes.overviewContainer}>
        <Typography variant="subtitle1" className={classes.boldText}>
          {title}
        </Typography>
        {company?.name &&
          (relationshipCount <= 1 ? (
            <Grid container spacing={2}>
              {infoLabels.map(item => (
                <Grid key={getId()} item xs={colSize}>
                  <InfoLabel
                    title={strings.companies.creation.associated.overview[item.key]}
                    description={company[item.key]}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <InfoLabel title={relationshipCountTitle} description={relationshipCount} />
          ))}
      </Grid>
    </Grid>
  );
};

RelationshipOverview.defaultProps = {
  direction: 'row',
  data: null,
  title: null,
  type: COMPANY_RELATIONSHIP_KEY.parentCompany
};

export default RelationshipOverview;
