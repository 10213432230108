import { TREE_VIEW_ENTITY_TYPES } from 'UI/constants/defaults';

export const isFolder = entity => entity === TREE_VIEW_ENTITY_TYPES.folder;
export const parseNodeId = nodeId => nodeId.split('-');

export const nodeObjectMapper = ({ id, ...restProps }) =>
  id
    ? {
        id: parseNodeId(id)[1],
        ...restProps
      }
    : {};

export const buildTreeViewItemId = (entity, id) => (!entity || !id ? '' : `${entity}-${id}`);
