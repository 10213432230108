import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { FeeAgreementTabs } from 'UI/constants/status';
import { getFeatureFlags } from 'UI/utils/index';

export const ColumnsDefinitions = [
  {
    name: 'status',
    label: strings.shared.fields.status,
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'statusBuilder',
        config: {
          labelKey: 'status',
          baseClass: 'fa-status'
        }
      }
    }
  },
  {
    name: 'company_name',
    label: strings.shared.fields.company,
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: `${EntityRoutes.CompanyProfile}?tab=feeagreements`,
          idKey: 'company_id',
          textKey: 'company_name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'id',
    label: 'Fee Nº',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'industry_title',
    label: strings.shared.fields.industry,
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'specialty_title',
    label: strings.shared.fields.specialty,
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'subspecialty_title',
    label: strings.shared.fields.subspecialty,
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'fee_percentage',
    label: strings.feeAgreements.fields.fee,
    options: {
      sort: true,
      display: true,
      renderer: 'fee'
    }
  },
  {
    name: 'flat_fee_amount',
    label: strings.feeAgreements.fields.flatAmount,
    options: {
      sort: true,
      display: false,
      renderer: 'currency'
    }
  },
  {
    name: 'guarantee_days',
    label: strings.feeAgreements.fields.guaranteeDays,
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'created_at',
    label: strings.shared.fields.createdAt,
    options: {
      sort: true,
      display: ({ activeSection }) => activeSection === FeeAgreementTabs.PendingValidations,
      renderer: 'date'
    }
  },
  {
    name: 'validated_date',
    label: strings.feeAgreements.fields.sentAt,
    options: {
      sort: true,
      display: ({ activeSection }) => activeSection === FeeAgreementTabs.PendingSignatures,
      renderer: 'date'
    }
  },
  {
    name: 'hiring_authority_sign_date',
    label: strings.feeAgreements.fields.haSignedAt,
    options: {
      sort: true,
      display: ({ activeSection }) => activeSection === FeeAgreementTabs.Signed,
      renderer: 'date'
    }
  },
  {
    name: 'signed_date',
    label: strings.feeAgreements.fields.gpacSignedAt,
    options: {
      sort: true,
      display: ({ activeSection }) => activeSection === FeeAgreementTabs.Signed,
      renderer: 'date'
    }
  },
  {
    name: 'production_director_validator',
    label: strings.feeAgreements.fields.validatedBy,
    options: {
      sort: true,
      display: ({ activeSection }) => activeSection === FeeAgreementTabs.Signed
    }
  },
  {
    name: 'coach_name',
    label: strings.shared.fields.coach,
    options: {
      sort: true
    }
  },
  {
    name: 'creator_name',
    label: strings.shared.fields.recruiter,
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'creator_id',
    options: {
      sort: false,
      display: 'excluded'
    }
  },
  {
    name: 'pdf_url',
    options: {
      sort: false,
      display: 'excluded'
    }
  },
  {
    name: 'sign_url',
    options: {
      sort: false,
      display: 'excluded'
    }
  },
  {
    name: 'fee_agreement_payment_scheme_id',
    label: strings.shared.fields.type,
    options: {
      sort: true
    }
  },
  {
    name: 'country',
    label: 'Country',
    options: {
      display: false
    }
  },
  {
    name: 'state',
    label: 'State',
    options: {
      display: false
    }
  },
  {
    name: 'city',
    label: 'City',
    options: {
      display: false
    }
  },
  {
    name: 'zip',
    label: 'Zip Code',
    options: {
      display: false
    }
  }
];

const featureFlags = getFeatureFlags();
const filters = ['feeStatus'];
featureFlags.includes(FeatureFlags.ExternalFeeAgreement) && filters.push('feeProcess');

export const FiltersGroups = [
  { id: 'general', name: strings.feeAgreements.singular, filters },
  {
    id: 'industries',
    name: strings.shared.filters.industries,
    filters: ['industries', 'specialties', 'subspecialties']
  },
  {
    id: 'team',
    name: strings.shared.filters.team,
    filters: ['coaches', 'recruiters']
  },
  {
    id: 'location',
    name: strings.shared.filters.location,
    filters: ['countries', 'states', 'cities', 'zips']
  }
];

export const VirtualProps = [
  {
    propName: 'company_name',
    attributes: ['company_id', 'company_name']
  },
  {
    propName: 'fee_percentage',
    attributes: ['flat_fee_amount', 'fee_agreement_payment_scheme_id', 'fee_percentage']
  }
];
