import { makeStyles } from '@material-ui/core';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  baseStyle: {
    ...flexAlignCenterJustifyCenter,
    padding: theme.spacing(2),
    border: `1px dashed ${theme.palette.grey[600]}`,
    borderRadius: 8,
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[600],
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer'
  },
  acceptStyle: {
    border: `3px dashed ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  rejectStyle: {
    border: `3px dashed ${theme.palette.error.main}`,
    color: theme.palette.error.main
  },
  iconText: {
    marginLeft: theme.spacing(1)
  },
  textIconContainer: flexAlignCenterJustifyCenter
}));
