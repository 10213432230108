import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import AttachFile from '@material-ui/icons/AttachFile';
import useFileUpload from 'hooks/fileUpload';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';

import { LoaderContainer } from './Message.styled';

export const AttachmentUploader = ({
  name = 'attachment',
  category = {
    module: EntityType.Contact,
    required: false,
    title: 'Select File'
  },
  acceptFiles = ['image/png', 'image/jpg', 'image/jpeg', '.pdf', '.docx', '.doc'],
  endpoint = Endpoints.Files,
  defaultEntityType = EntityType.Contact,
  onClick,
  disabled
}) => {
  const { fileRef, file, handleFileChange, selectOrUploadFile } = useFileUpload({
    initialValue: null,
    category,
    endpoint,
    fieldName: name,
    onChange: onClick,
    softDeleteForExistingItems: true,
    defaultEntityType
  });

  if (file?.isUploading)
    return (
      <LoaderContainer>
        <CircularProgress size={26} />
      </LoaderContainer>
    );

  return (
    <>
      <FPIconButton
        disabled={disabled}
        onClick={selectOrUploadFile}
        tooltipProps={{
          title: strings.messageCenter.messageComposer.attachment.tooltip.upload
        }}
      >
        <AttachFile color="primary" />
      </FPIconButton>
      <input
        ref={fileRef}
        type="file"
        accept={acceptFiles.join(',')}
        hidden
        onChange={handleFileChange}
      />
    </>
  );
};
