// @flow
import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useTabsHandler from 'hooks/tabsHandler';
import TabPanel from 'UI/components/atoms/TabPanel';
import { colors } from 'UI/res';

import { useStyles, useTabContentStyles, useTabStyles } from './styles';

export const ComponentStateMode = {
  Controlled: 0,
  Uncontrolled: 1
};

type TabsViewProps = {
  content: 'start' | 'center' | 'block',
  enableUrlNavigation?: boolean,
  initialTab?: number | string,
  onChangeTabIndex?: any => any,
  padding?: number | string | null,
  panelHeight?: number | string | null,
  paramKey?: string,
  selectedTab?: number,
  tabPanelProps: Object,
  tabProps: Object,
  tabs: Array<Object>,
  tabsContainerProps: Object,
  useIndexes: boolean // To change default behavior of using the selected tab value as an index, and instead use any given value. Useful when tabs order needs to be dynamic and logic depends on the current tab
};

const a11yProps = ({ id, index, label }) => {
  const tabId = `tab-${id || index}`;
  return {
    id: tabId,
    'aria-controls': tabId,
    'data-testid': tabId,
    'aria-label': label
  };
};

const getModeFromProps = (props: TabsViewProps) => {
  const isControlled = props.selectedTab !== undefined && props.onChangeTabIndex !== undefined;
  const isUncontrolled = props.initialTab !== undefined;

  if (isControlled && isUncontrolled)
    throw new Error(
      'TabsView cannot operate in Controlled and Uncontrolled mode at the same time.'
    );

  const mode = isControlled ? ComponentStateMode.Controlled : ComponentStateMode.Uncontrolled;

  return mode;
};

const checkForIllegalModeChange = (props: TabsViewProps, mode) => {
  if (process.env.NODE_ENV === 'production') return;

  if (mode === ComponentStateMode.Controlled && props.enableUrlNavigation) {
    throw new Error('URL navigation in `TabsView` could only be used in uncontrolled mode');
  }

  if (mode !== undefined && mode !== getModeFromProps(props)) {
    throw new Error(
      `Switching between controlled and uncontrolled mode is not supported in \`TabsView\`. For controlled mode, you need to pass \`selectedTab\` and \`onChangeTabIndex\` from parent component. 
       For uncontrolled mode, you can pass only \`initialTab\`.`
    );
  }
};

const TabsView = (props: TabsViewProps) => {
  const {
    content,
    enableUrlNavigation,
    initialTab,
    onChangeTabIndex,
    padding,
    panelHeight,
    paramKey,
    selectedTab: selectedTabProp,
    tabPanelProps,
    tabProps,
    tabs,
    tabsContainerProps,
    useIndexes,
    ...rest
  } = props;

  /** Styles settings */
  const classes = useStyles();
  const tabClasses = useTabStyles();
  const tabContentClasses = useTabContentStyles({ panelHeight, padding });

  const [stateMode] = useState(getModeFromProps(props));
  checkForIllegalModeChange(props, stateMode);

  const firstTab = !useIndexes && tabs.length > 0 ? tabs[0].id : 0;
  const initialValue =
    stateMode === ComponentStateMode.Uncontrolled ? initialTab || firstTab : null;
  const { handleTabChange: setInternalValue, selectedTab: internalValue } = useTabsHandler(tabs, {
    enableUrlNavigation,
    useIndexes,
    initialValue,
    paramKey
  });

  const currentValue =
    stateMode === ComponentStateMode.Uncontrolled ? internalValue : selectedTabProp;

  const selectedTab = useIndexes ? Number(currentValue) : currentValue;
  const visibleTabs = tabs.filter(({ visible = true }) => visible);

  const handleTabChange = (event, newTab = 0) => {
    if (stateMode === ComponentStateMode.Controlled) {
      onChangeTabIndex && onChangeTabIndex(event, newTab);
    } else {
      setInternalValue(event, newTab);
    }
  };

  return (
    <div className={tabClasses.wrapper} {...rest}>
      <AppBar classes={classes} position="static" color="default">
        <Tabs
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          onChange={handleTabChange}
          value={selectedTab}
          {...tabsContainerProps}
        >
          {visibleTabs.map((tab, index) => {
            const { icon, id, label } = tab;
            const isTabSelected = selectedTab === (useIndexes ? index : id);
            const styledIcon = {
              ...icon,
              props: { fill: isTabSelected ? colors.success : colors.iconInactive }
            };

            return (
              <Tab
                className={tabClasses.tab}
                icon={icon ? styledIcon : null}
                key={label}
                label={label}
                value={useIndexes ? index : id}
                {...a11yProps({ id, index, label })}
                {...tab.tabProps}
                {...tabProps}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {visibleTabs.map(({ id, tab, label, view }, index) => (
        <TabPanel
          classes={tabContentClasses}
          content={content}
          index={useIndexes ? index : id}
          key={label}
          value={selectedTab}
          {...tab?.tabPanelProps}
          {...tabPanelProps}
        >
          {view}
        </TabPanel>
      ))}
    </div>
  );
};

TabsView.defaultProps = {
  content: 'center',
  enableUrlNavigation: false,
  initialTab: undefined,
  onChangeTabIndex: undefined,
  padding: undefined,
  panelHeight: undefined,
  paramKey: 'tab',
  selectedTab: undefined,
  tabPanelProps: undefined,
  tabProps: undefined,
  tabsContainerProps: undefined,
  useIndexes: true
};

export default TabsView;
