// @flow
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Checkbox, Divider, IconButton, Menu, MenuItem } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@material-ui/icons';
import { useGlobalStyles } from 'GlobalStyles';
import { When } from 'UI/components/atoms/When';

type SelectionMenuPropTypes = {
  all: number,
  disableSelectAllCheckbox?: boolean,
  itemType: string,
  onSelectionChange: (type: string) => void,
  someSelected: boolean,
  totalInPage: number
};

const SelectionMenu = ({
  all,
  disableSelectAllCheckbox = false,
  itemType,
  onSelectionChange,
  someSelected,
  totalInPage
}: SelectionMenuPropTypes) => {
  const globalClasses = useGlobalStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = e => setAnchorEl(e.currentTarget);
  const handleClick = select => () => {
    handleClose();
    onSelectionChange && onSelectionChange(select);
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton aria-label="open-select-menu" onClick={handleOpen}>
        {someSelected ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
      </IconButton>
      <Menu
        id="check-menu"
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClick('page')}>
          Select&nbsp;
          <NumberFormat displayType="text" thousandSeparator value={totalInPage} />
          &nbsp;{itemType} on this page
        </MenuItem>
        <When condition={!disableSelectAllCheckbox}>
          <MenuItem onClick={handleClick('all')}>
            Select all&nbsp;
            <NumberFormat displayType="text" thousandSeparator value={all} />
            &nbsp;{itemType}
          </MenuItem>
        </When>
        <Divider className={globalClasses.tightDivider} />
        <MenuItem onClick={handleClick('clear-all')}>Unselect All</MenuItem>
      </Menu>
    </>
  );
};

type CustomDataTableCheckboxPropTypes = {
  SelectionMenuProps: SelectionMenuPropTypes
};

const CustomDataTableCheckbox = ({
  SelectionMenuProps,
  ...rest
}: CustomDataTableCheckboxPropTypes) =>
  rest['data-description'] !== 'row-select-header' ? (
    <Checkbox {...rest} />
  ) : (
    <SelectionMenu {...SelectionMenuProps} />
  );

export default CustomDataTableCheckbox;
