// @flow
import { replaceSmartTags } from 'UI/components/organisms/sendouts/utils';
import { currencyFormatter } from 'UI/utils';

/** Method to replace custom smartags comming from event logs */
export const renderSmartTags = log => {
  const { event_details, eventType, user } = log || {};

  // support to old values of event details structure
  const eventDetailsWithTags = event_details?.feeTags || event_details?.tags;

  if (!eventDetailsWithTags) {
    return replaceSmartTags(eventType?.name, user?.personalInformation?.full_name);
  }

  let replacedText = eventType?.name || '';
  Object.entries(eventDetailsWithTags).forEach(([key, value]) => {
    let tagValue = event_details?.format ? currencyFormatter(value) : value;

    // support to old values of event details strcuture
    tagValue = event_details?.feeTags ? currencyFormatter(value) : tagValue;
    replacedText = replacedText.replaceAll(`{{${key}}}`, tagValue);
  });

  return replaceSmartTags(replacedText, user?.personalInformation?.full_name);
};
