import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  labelWrapper: {
    margin: theme.spacing(1.5, 0)
  },
  topSeparation: {
    marginTop: theme.spacing(3)
  }
}));
