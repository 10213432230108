// @flow
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Tooltip } from '@material-ui/core';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import { EntityType } from 'UI/constants/entityTypes';

import AttachmentChip from '../AttachmentChip';

import { useClickableStyles } from './styles';

const { mailMeta: MAIL_META_STRINGS } = strings.bulkEmails.preview;

export const ENTITIES_KEYS_MAP = {
  [EntityType.Candidate]: {
    displayKey: 'full_name',
    route: 'CandidateProfile',
    tooltipEntity: EntityType.Candidate
  },
  [EntityType.Joborder]: {
    displayKey: 'title',
    route: 'JoborderProfile',
    tooltipEntity: EntityType.Joborder
  }
};

const DEFAULT_VALUES = {
  [EntityType.Candidate]: MAIL_META_STRINGS.candidatesToMarket.defaultValue,
  [EntityType.Joborder]: MAIL_META_STRINGS.jobOrdersToRecruit.defaultValue
};

type EntitiesTypes = {
  id: number,
  title?: string,
  full_name?: string
};

type BulkTypeEntitiesTypes = {
  entityType: string,
  entities?: Array<EntitiesTypes>
};

export const PREVIEW_BULK_RENDERERS = {
  Attachments: ({ attachments = [] }) => (
    <Grid container spacing={2}>
      {attachments.map(attachment => (
        <Grid key={attachment.id} item>
          <AttachmentChip name={attachment.name} fileSize={attachment.size} url={attachment.url} />
        </Grid>
      ))}
    </Grid>
  ),
  BulkTypeEntities: ({ entityType, entities = [] }: BulkTypeEntitiesTypes) => {
    const history = useHistory();
    const classes = useClickableStyles();

    const mappedEntities = entities.map(entity => {
      const { displayKey, route, tooltipEntity } = ENTITIES_KEYS_MAP[entityType];
      return {
        id: entity.id,
        displayKey: entity[displayKey],
        route,
        tooltip: strings.formatString(MAIL_META_STRINGS.bulktTypeEntities.tooltip, {
          entity: MAIL_META_STRINGS.bulktTypeEntities.entities[tooltipEntity]
        })
      };
    });

    const handleEntityClick = (entityId, route) => () =>
      history.push(EntityRoutes[route].replace(/:id/, entityId));

    return entities.length !== 0 ? (
      <Grid container>
        {mappedEntities.map(({ id, displayKey, route, tooltip }, index) => (
          <Tooltip key={id} title={tooltip} arrow placement="top">
            <Grid item className={classes.clickable} onClick={handleEntityClick(id, route)}>
              {displayKey}
              {index !== entities.length - 1 && ','}
            </Grid>
          </Tooltip>
        ))}
      </Grid>
    ) : (
      DEFAULT_VALUES[entityType]
    );
  },
  SearchProjects: ({ searchProject = {} }) => {
    const history = useHistory();
    const classes = useClickableStyles();

    return searchProject?.id ? (
      <Tooltip title={MAIL_META_STRINGS.to.renderValueTitle}>
        <Grid
          className={classes.clickable}
          onClick={() =>
            history.push(EntityRoutes.SearchProjectPreview.replace(/:id/, searchProject.id))
          }
        >
          {`${searchProject.name} (${searchProject.totalItems})`}
        </Grid>
      </Tooltip>
    ) : (
      MAIL_META_STRINGS.to.defaultValue
    );
  }
};
