// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { userHasRole } from 'services/Authorization';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import { Endpoints } from 'UI/constants/endpoints';
import { Roles } from 'UI/constants/roles';
import { REQUIRED_VALIDATION } from 'UI/utils/index';

import UnmanagedFeeAgreementBaseFields from '../UnmanagedFeeAgreementBaseFields';
import UnmanagedFeeAgreementExtendedFields from '../UnmanagedFeeAgreementExtendedFields';

import { useStyles } from './style';

type UnmanagedFeeAgreementProps = {
  companyFiles: any[]
};

const onBehalfUserKey = 'overridden_user_id';

const UnmanagedFeeAgreement = (props: UnmanagedFeeAgreementProps) => {
  const { companyFiles = [] } = props;
  const classes = useStyles(props);

  const form = useFormContext();
  const { register, errors, setValue, watch } = form;
  const isUserOps = userHasRole(Roles.Operations);

  useEffect(() => {
    isUserOps && register({ name: onBehalfUserKey }, REQUIRED_VALIDATION);
  }, [register, isUserOps]);

  const onBehalfUser = watch(onBehalfUserKey, null);

  const handleFieldChange = (field: string, value: any) => setValue(field, value, true);

  return (
    <>
      {isUserOps ? (
        <>
          <Typography>{strings.feeAgreements.creation.onBehalfExternal}</Typography>
          <AutocompleteSelect
            width="100%"
            name={onBehalfUserKey}
            displayKey="full_name"
            placeholder={`${strings.feeAgreements.creation.selectRecruiter} *`}
            url={Endpoints.FeeAgreementOverridableUsers}
            selectedValue={onBehalfUser}
            error={!!errors[onBehalfUserKey]}
            errorText={errors[onBehalfUserKey] && errors[onBehalfUserKey].message}
            onSelect={handleFieldChange}
          />
        </>
      ) : (
        <p>{strings.feeAgreements.creation.externalIntro}</p>
      )}
      <UnmanagedFeeAgreementExtendedFields companyFiles={companyFiles} required={isUserOps} />
      <Divider className={classes.divider} />
      <UnmanagedFeeAgreementBaseFields required={isUserOps} />
      <Divider className={classes.divider} />
      <Box className={classes.notesContainer}>
        <Typography variant="subtitle1">
          {strings.feeAgreements.creation.notesFromRecruiter}
        </Typography>
        <TextBox
          name="notes"
          label={strings.feeAgreements.creation.notesToOps}
          error={!!errors.notes}
          errorText={errors.notes?.message}
          multiline
          inputRef={register()}
        />
      </Box>
    </>
  );
};

export default UnmanagedFeeAgreement;
