// @flow
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import moment from 'moment';
import strings from 'strings';
import {
  FPCard as Card,
  FPCardContent as CardContent,
  FPCardHeader as CardHeader
} from 'UI/components/atoms/FPCard';
import SummaryTable from 'UI/components/molecules/Dashboard/SummaryTable';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { backNavigateListConfig } from 'UI/constants/config';
import { DateFormats, PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { SvgEmptyDayLeader, SvgEmptyWeekLeader } from 'UI/res/icons/milano';

import DatePicker from './DatePicker';
import { useStyles } from './styles';

const Content = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.only('md'));

  const [selectedDate, setSelectedDate] = useState(moment().format(DateFormats.SimpleDateHyphen));

  useEffect(() => {
    document.title = PageTitles.DashboardSendoutLeaders;
  }, []);

  const { state: headerState } = useFetchWithStatus(
    `${Endpoints.SendoutDashboardLeadersHeaders}?date=${selectedDate}`
  );
  const header = headerState?.results;

  const handleApplyCalendar = date => setSelectedDate(date);

  return (
    <ContentPageLayout
      text="Leaderboard"
      titleLabelProps={backNavigateListConfig}
      actionsBarConfig={{
        xs: 12,
        sm: 3,
        lg: 3
      }}
      actionsBar={<DatePicker defaultDate={selectedDate} onApplyClick={handleApplyCalendar} />}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title={header?.day?.title} subheader={header?.day?.subheader} />
            <CardContent variant="tight">
              <SummaryTable
                emptyMessage={
                  <EmptyPlaceholder
                    title={strings.leaderboard.noDailyLeaders.title}
                    subtitle={strings.leaderboard.noDailyLeaders.subtitle}
                    subtitleClass={isSmallViewPort ? classes.minSubtitle : classes.maxSubtitle}
                    customEmptyState={
                      <div className={classes.defaultEmptyState}>
                        <SvgEmptyDayLeader width="100%" />
                      </div>
                    }
                  />
                }
                section="leaders"
                showToolbar
                url={`${Endpoints.SendoutDashboardLeadersDays}?date=${selectedDate}`}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title={header?.week?.title} subheader={header?.week?.subheader} />
            <CardContent variant="tight">
              <SummaryTable
                emptyMessage={
                  <EmptyPlaceholder
                    title={strings.leaderboard.noWeeklyLeaders.title}
                    subtitle={strings.leaderboard.noWeeklyLeaders.subtitle}
                    subtitleClass={isSmallViewPort ? classes.minSubtitle : classes.maxSubtitle}
                    customEmptyState={
                      <div className={classes.defaultEmptyState}>
                        <SvgEmptyWeekLeader width="100%" />
                      </div>
                    }
                  />
                }
                section="leaders"
                showToolbar
                url={`${Endpoints.SendoutDashboardLeadersWeeks}?date=${selectedDate}`}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ContentPageLayout>
  );
};

export default Content;
