import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';
import { colors } from 'UI/res';

const iconMeasures = {
  radius: 4
};

const sharedStyles = {
  width: ({ size }) => size,
  height: ({ size }) => size,
  borderRadius: iconMeasures.radius
};

const { primary, error, secondary, brandColors, grey } = THEME.palette;

export const iconDefinitions = {
  mode: {
    custom: 'custom',
    streamline: 'streamline'
  },
  variants: {
    default: 'default',
    primary: 'primary',
    secondary: 'secondary',
    outlinePrimary: 'outlinePrimary',
    outlineDefault: 'outlineDefault',
    destructive: 'destructive'
  },
  color: {
    default: grey[600],
    primary: brandColors.FPWhite,
    secondary: primary.main,
    outlinePrimary: primary.main,
    outlineDefault: '',
    destructive: brandColors.FPWhite
  }
};

export const useStyles = makeStyles({
  default: {
    ...sharedStyles,
    borderRadius: '50%'
  },
  primary: {
    ...sharedStyles,
    backgroundColor: primary.main,
    color: colors.white,
    '&:hover': {
      backgroundColor: primary.light
    }
  },
  secondary: {
    ...sharedStyles,
    backgroundColor: secondary.main,
    color: primary.main,
    '&:hover': {
      backgroundColor: secondary.light
    }
  },
  outlinePrimary: {
    ...sharedStyles,
    border: `1px solid ${primary.main} `,
    backgroundColor: 'unset',
    '&:hover': {
      border: `1px solid ${primary.light} `,
      backgroundColor: 'unset',
      borderRadius: iconMeasures.radius
    }
  },
  outlineDefault: {
    ...sharedStyles,
    border: `1px solid ${grey[900]} `,
    '&, &:hover': {
      backgroundColor: 'unset'
    },
    '&:hover': {
      border: `1px solid ${grey[700]} `,
      borderRadius: iconMeasures.radius
    }
  },
  destructive: {
    ...sharedStyles,
    backgroundColor: error.main,
    color: colors.white,
    '&:hover': {
      backgroundColor: error.light
    }
  },
  buttonWrapper: {
    width: 'fit-content',
    height: 'fit-content'
  }
});

const iconButtonBackgroudModes = {
  default: 'unset',
  primary: `${colors.inactiveSideBarTab} !important`,
  secondary: `${colors.inactiveSideBarTab} !important`,
  destructive: `${colors.inactiveSideBarTab} !important`,
  outline: 'unset'
};

export const defaultStyles = makeStyles({
  disabled: {
    ...sharedStyles,
    borderRadius: ({ variant }) => (variant === 'default' ? '50%' : iconMeasures.radius),
    backgroundColor: ({ variant }) => variant[iconButtonBackgroudModes],
    border: ({ variant }) => variant === 'outline' && `1px solid ${colors.inactiveSideBarTab} `
  }
});
