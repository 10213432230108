import * as React from 'react';

const SvgCommonFileTextStar = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      fill={fill}
      {...props}
    >
      <g fill={fill}>
        <path d="M20 7.5a1 1 0 00-1-1h-7a1 1 0 000 2h7a1 1 0 001-1zM12.5 11a1 1 0 000 2h4a1 1 0 000-2z" />
        <path d="M23.41 3L21 .59A2 2 0 0019.58 0H8a2 2 0 00-2 2v6.79a.25.25 0 00.23.21 8.31 8.31 0 011.45.21A.25.25 0 008 9V2.5a.5.5 0 01.5-.5h10.88a.47.47 0 01.35.15l2.12 2.12a.47.47 0 01.15.35V18a.5.5 0 01-.5.5h-7.24a.46.46 0 00-.33.13c-.35.32-1.14.95-1.23 1.08s0 .12 0 .17l.19.49a.24.24 0 00.2.13H22a2 2 0 002-2V4.41A2 2 0 0023.41 3z" />
        <path d="M12.61 17.14a1.28 1.28 0 00.3-1.38 1.23 1.23 0 00-1.17-.76h-2.5a.25.25 0 01-.24-.15l-1.34-3.12A1.29 1.29 0 006.5 11a1.31 1.31 0 00-1.17.73L4 14.85a.25.25 0 01-.23.15H1.26a1.23 1.23 0 00-1.17.76 1.27 1.27 0 00.32 1.39l2.2 2a.26.26 0 01.06.29l-1.22 2.77a1.24 1.24 0 00.31 1.45 1.36 1.36 0 001.51.17l3.11-1.75a.21.21 0 01.24 0l3.12 1.75a1.29 1.29 0 001.82-1.62l-1.23-2.81a.26.26 0 01.07-.29z" />
      </g>
    </svg>
  );
};

export default SvgCommonFileTextStar;
