import React from 'react';
import strings from 'strings';
import { BulkMenuItems, BulkScopes } from 'UI/constants/defaults';
import { EntityType } from 'UI/constants/entityTypes';
import { dateFormatter } from 'UI/utils';

import { PREVIEW_BULK_RENDERERS } from './PreviewBulkRenderers';

const { mailMeta: MAIL_META_STRINGS } = strings.bulkEmails.preview;

export const buildDateToRender = (selectedMenuItem, createdAt, sendDate) => {
  const date = selectedMenuItem === BulkMenuItems.Drafts ? createdAt : sendDate;
  return dateFormatter(date, ({ MonthDayYearTime }) => MonthDayYearTime);
};
export const booleanCopyRenderer = active =>
  active ? MAIL_META_STRINGS.booleanOptions.yes : MAIL_META_STRINGS.booleanOptions.no;

const getBlockResendCopy = (blockResend, blockDays) => {
  const blockResendCopy = booleanCopyRenderer(blockResend);
  if (!blockResend) return blockResendCopy;

  const blockDaysCopy = blockDays ? `(${blockDays} Days)` : `(no interval selected)`;

  return `${blockResendCopy} ${blockDaysCopy}`;
};

export const getMailMeta = ({ bulkInPreviewData, selectedMenuItem }) => {
  const {
    createdAt,
    emailBody: { attachments },
    emailConfig: {
      blockResend,
      blockDays,
      bulkType,
      generalCategory,
      isScheduled,
      template: { name: templateName },
      marketing: { marketingCandidates, ...marketingConfig },
      recruiting: { recruitingJobOrders, ...recruitingConfig }
    },
    userInfo,
    searchProject,
    sendDate
  } = bulkInPreviewData;

  const mainMailMeta = [
    {
      title: MAIL_META_STRINGS.from.title,
      render: `${userInfo.name} - ${userInfo.email}`,
      enabled: true
    },
    {
      title: MAIL_META_STRINGS.to.title,
      render: <PREVIEW_BULK_RENDERERS.SearchProjects searchProject={searchProject} />,
      enabled: true
    },
    {
      title: MAIL_META_STRINGS.bulkType.title,
      render: BulkScopes.ById[bulkType?.id] || MAIL_META_STRINGS.bulkType.defaultValue,
      enabled: true
    },
    {
      title: MAIL_META_STRINGS.generalCategory.title,
      render: generalCategory?.title || MAIL_META_STRINGS.generalCategory.defaultValue,
      enabled: bulkType?.id === BulkScopes.General
    },
    {
      title: MAIL_META_STRINGS.candidatesToMarket.title,
      render: (
        <PREVIEW_BULK_RENDERERS.BulkTypeEntities
          entityType={EntityType.Candidate}
          entities={marketingCandidates}
        />
      ),
      enabled: bulkType?.id === BulkScopes.Marketing && marketingCandidates?.length > 0
    },
    {
      title: MAIL_META_STRINGS.jobOrdersToRecruit.title,
      render: (
        <PREVIEW_BULK_RENDERERS.BulkTypeEntities
          entityType={EntityType.Joborder}
          entities={recruitingJobOrders}
        />
      ),
      enabled: bulkType?.id === BulkScopes.Recruiting && recruitingJobOrders?.length > 0
    },
    {
      title: MAIL_META_STRINGS.isSchedule.title,
      render: booleanCopyRenderer(isScheduled),
      enabled: selectedMenuItem === BulkMenuItems.Sent
    },
    {
      title: MAIL_META_STRINGS.date.title,
      render: buildDateToRender(selectedMenuItem, createdAt, sendDate),
      enabled: true
    }
  ];

  const marketingConfigMeta = [
    {
      title: MAIL_META_STRINGS.candidatesToMarket.config.blockHirings,
      value: marketingConfig.blockCompanies
    },
    {
      title: MAIL_META_STRINGS.candidatesToMarket.config.blockSignedClientCompanies,
      value: marketingConfig.blockClientCompanies
    },
    {
      title: MAIL_META_STRINGS.candidatesToMarket.config.allowReachingCandidates,
      value: marketingConfig.allowMarketingCandidates
    }
  ];

  const secondaryMailMeta = [
    ...marketingConfigMeta.map(meta => ({
      ...meta,
      render: booleanCopyRenderer(meta.value),
      enabled: bulkType?.id === BulkScopes.Marketing
    })),
    {
      title: MAIL_META_STRINGS.jobOrdersToRecruit.config.allowReachingHirings,
      render: booleanCopyRenderer(recruitingConfig.allowRecruitHirings),
      enabled: bulkType?.id === BulkScopes.Recruiting
    },
    {
      title: MAIL_META_STRINGS.template.title,
      render: templateName,
      enabled: !!templateName
    },
    {
      title: MAIL_META_STRINGS.blockResend.title,
      render: getBlockResendCopy(blockResend, blockDays),
      enabled: true
    },
    {
      title: MAIL_META_STRINGS.attachments.title,
      render: <PREVIEW_BULK_RENDERERS.Attachments attachments={attachments} />,
      RowProps: {
        alignItems: 'flex-start'
      },
      enabled: attachments.length > 0
    }
  ];

  return { mainMailMeta, secondaryMailMeta };
};
