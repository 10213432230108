import * as React from 'react';

const SvgBuilding1 = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      fill={fill}
      {...props}
    >
      <g fill={fill}>
        <path d="M3.5 3.5h17a.5.5 0 00.5-.28.51.51 0 00-.06-.53L19.19.56A1.51 1.51 0 0018 0H6a1.51 1.51 0 00-1.19.56l-1.7 2.13a.51.51 0 00-.06.53.5.5 0 00.45.28zM20.5 5h-17a.5.5 0 00-.5.5v17A1.5 1.5 0 004.5 24h5a.5.5 0 00.5-.5v-2a2 2 0 014 0v2a.5.5 0 00.5.5h5a1.5 1.5 0 001.5-1.5v-17a.5.5 0 00-.5-.5zM8 16.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 013 0zm0-6a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 013 0zm5.5 6a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 013 0zm0-6a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 013 0zm5.5 6a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 013 0zm0-6a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 013 0z" />
      </g>
    </svg>
  );
};

export default SvgBuilding1;
