import * as React from "react";

const SvgNoUpdatedSendoutsFilled = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M13.64 18.22a6.45 6.45 0 01-.83-1.5H2.43a.94.94 0 01-.9-1V4.35a.94.94 0 01.9-1h9.3a5.74 5.74 0 01.6-1.5h-9.9A2.44 2.44 0 000 4.35v11.39a2.45 2.45 0 002.4 2.48h6.92l-.56 1.85H7.22a.75.75 0 100 1.5h5a7.59 7.59 0 011.86-1.93zM24 24a5.86 5.86 0 00-11.3 0z" />
      <path d="M10.91 14a3.93 3.93 0 00-7.66 0h7.66z" />
      <circle cx={7.08} cy={7.65} r={2.4} />
      <path d="M18.54 0c-3 0-5.47 2.09-5.47 4.66a4.12 4.12 0 00.7 2.24 8.84 8.84 0 01-1.55 2.37 5.45 5.45 0 004.2-.3 6.37 6.37 0 002.12.36c3 0 5.47-2.09 5.47-4.67S21.56 0 18.54 0zM18.34 10.37a4.17 4.17 0 104.16 4.16 4.16 4.16 0 00-4.16-4.16zm0 6.83a2.67 2.67 0 01-2.66-2.67 2.72 2.72 0 01.45-1.53 6.26 6.26 0 004.77 2.22 2.67 2.67 0 01-2.56 1.98z" />
    </svg>
  );
};

export default SvgNoUpdatedSendoutsFilled;
