// @flow
import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { THEME } from 'GlobalStyles';

import { useStyles } from './styles';

/**
 * Custom Linear Progress Bar
 * @param backgroundColor string of the background color.
 * @param color string of the bar color.
 * @param max maximum number that the FPLinearProgress bar can have.
 * @param size number of pixels in height and border radius.
 * @param value current value of the bar (progress).
 * @param variant string with the material variant of the LinearProgress. It could be 'buffer' | 'determinate' | 'indeterminate' | 'query'
 */
type FPLinearProgressProps = {
  backgroundColor: string,
  borderColor: string,
  color: string,
  max: number,
  size: number,
  value: number,
  variant: string
};

const FPLinearProgress = ({
  backgroundColor,
  borderColor,
  color,
  max,
  size,
  value,
  variant
}: FPLinearProgressProps) => {
  const classes = useStyles({ backgroundColor, borderColor, color, size });

  const { container: containerClass, ...overridedClasses } = classes;

  const progress = (value * 100) / max;

  return (
    <div className={containerClass}>
      <LinearProgress
        role="progressbar"
        classes={overridedClasses}
        variant={variant}
        value={progress}
      />
    </div>
  );
};

FPLinearProgress.defaultProps = {
  backgroundColor: THEME.palette.primary.FP100,
  color: THEME.palette.primary.FP300,
  max: 100,
  size: 12,
  value: 0,
  variant: 'determinate'
};

export default FPLinearProgress;
