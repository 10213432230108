export const FiltersGroups = [
  {
    id: 'entityType',
    name: 'Type',
    filters: ['entityType', 'keyword', 'mapStatuses', 'types', 'workTypes']
  },
  {
    id: 'industries',
    name: 'Industries',
    filters: ['industries', 'specialties', 'subspecialties', 'positions']
  },
  { id: 'team', name: 'Team', filters: ['coaches', 'recruiters'] },
  {
    id: 'location',
    name: 'Location',
    filters: ['countries', 'states', 'cities', 'cityRadius', 'zips', 'radius']
  }
];
