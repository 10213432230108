// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import orderBy from 'lodash/orderBy';
import strings from 'strings';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { UIStatus } from 'UI/constants/status';

import { useStyles } from '../styles';

const FunctionalTitlesList = ({ endpoint }) => {
  const { Status } = useFetchWithStatus(endpoint, null, UIStatus.Default);
  const classes = useStyles();

  const skeletonProps = {
    'aria-label': 'loading-skeleton',
    width: 150,
    height: 16,
    className: classes.skeleton
  };

  return (
    <Status
      loading={
        <>
          <Skeleton {...skeletonProps} height={28} />
          <ul>
            <li>
              <Skeleton {...skeletonProps} />
            </li>
            <li>
              <Skeleton {...skeletonProps} />
            </li>
            <li>
              <Skeleton {...skeletonProps} />
            </li>
            <li>
              <Skeleton {...skeletonProps} />
            </li>
            <li>
              <Skeleton {...skeletonProps} />
            </li>
          </ul>
        </>
      }
      default={<EmptyPlaceholder subtitle={strings.pil.functionalTitles.instructions} />}
      success={results => {
        const sortedResults = orderBy(results, [result => result.title.toLowerCase()]);

        return (
          <>
            <Typography variant="h6" gutterBottom>
              {strings.pil.functionalTitles.title}
            </Typography>
            <ul>
              {sortedResults.map(({ id, title }) => (
                <li key={id}>
                  <Typography paragraph>{title}</Typography>
                </li>
              ))}
            </ul>
          </>
        );
      }}
      error={error => (
        <Typography paragraph gutterBottom>
          {error}
        </Typography>
      )}
      empty={
        <Typography paragraph gutterBottom>
          {strings.pil.functionalTitles.empty}
        </Typography>
      }
    />
  );
};

export default FunctionalTitlesList;
