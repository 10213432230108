import { makeStyles } from '@material-ui/core';
import { THEME } from 'GlobalStyles';
import { colors } from 'UI/res';

export const hintColors = {
  info: THEME.palette.primary.main,
  help: THEME.palette.primary.main,
  danger: THEME.palette.warning.FP200,
  success: THEME.palette.success.FP200
};

export const hintDefinitions = {
  hint: {
    sm: 14,
    md: 18,
    lg: 22
  },
  text: {
    sm: 11,
    md: 12,
    lg: 14
  },
  colors: {
    info: THEME.palette.primary.main,
    help: THEME.palette.primary.main,
    danger: THEME.palette.warning.main,
    warning: THEME.palette.warning.main,
    success: THEME.palette.success.light
  }
};

export const useStyles = makeStyles(theme => ({
  hintWrapper: {
    display: 'inline-flex',
    verticalAlign: 'middle'
  },
  text: ({ size }) => ({
    fontSize: hintDefinitions.text[size],
    color: colors.oxford
  }),
  hintContainer: ({ size }) => ({
    display: 'grid',
    margin: theme.spacing(1, 0),
    gridGap: theme.spacing(1),
    gridTemplateColumns: `${hintDefinitions.hint[size]}px 1fr`,
    alignItems: 'center'
  })
}));
