import { useEffect, useState } from 'react';
import { RequestStatus } from 'UI/constants/status';

import { getDataSheetByCandidateId } from '../EditCandidateDataSheet.services';

const useFetchCandidateDataSheet = candidateId => {
  const [status, setStatus] = useState(RequestStatus.Idle);
  const [dataSheet, setDataSheet] = useState(null);

  useEffect(() => {
    if (!candidateId) return;

    setStatus(RequestStatus.Loading);
    getDataSheetByCandidateId(candidateId)
      .then(response => {
        setDataSheet(response.data);
        setStatus(RequestStatus.Success);
      })
      .catch(() => {
        setStatus(RequestStatus.Error);
      });
  }, [candidateId]);

  const updateDataSheet = newDataSheet => {
    setDataSheet(newDataSheet);
  };

  return {
    status,
    dataSheet,
    updateDataSheet
  };
};

export default useFetchCandidateDataSheet;
