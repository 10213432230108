import strings from 'strings';

import { HiringAuthoritiesDrawerContent } from './HiringAuthoritiesDrawerContent';

const { hiringAuthorities } = strings.commandCenter.drawers;

export const HiringAuthoritiesConfig = {
  title: hiringAuthorities.title,
  component: HiringAuthoritiesDrawerContent
};
