// @flow
import { useCallback, useEffect } from 'react';
import { FieldErrors } from 'react-hook-form';

/**
 * Only need pass the errors references from form
 * And set the prop name to all inputs that you want to scroll
 * <input name="_nameOfFormValueRegistered_" />
 * */
interface useErrorScrollProps {
  errors: FieldErrors;
}

const useScrollToError = ({ errors }: useErrorScrollProps) => {
  const handleErrorScroll = useCallback(() => {
    const errorsWithElement = Object.entries(errors).find(([value]) => {
      const domTarget = document.getElementsByName(value);
      return domTarget.length > 0;
    });

    if (!errorsWithElement) return;

    const [errorName] = errorsWithElement;
    const domErrorTarget = document.getElementsByName(errorName)[0];
    domErrorTarget.scrollIntoView(true);
  }, [errors]);

  useEffect(() => {
    handleErrorScroll();
  }, [handleErrorScroll]);
};

export default useScrollToError;
