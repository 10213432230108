import React from 'react';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';

import ChangeLogsUpdates from './ChangeLogsUpdates';
import { ChangeLogLabelValue } from './components';
import { Operations } from './utils';

export const ChangeLogs = ({ logs }) => {
  if (!logs) return null;

  const payloadLabels = logs?.payloadLabels || [];

  if (logs?.operation.includes(Operations.Update)) {
    return (
      <>
        <Typography variant="h6" gutterBottom>
          {strings.inventoryProfiles.sections.tabs.changeLogs.drawer.update}
        </Typography>

        <ChangeLogsUpdates logs={logs} />
      </>
    );
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {strings.inventoryProfiles.sections.tabs.changeLogs.drawer.changes}
      </Typography>

      {payloadLabels.length === 0 ? (
        <Typography gutterBottom>
          {strings.inventoryProfiles.sections.tabs.changeLogs.drawer.empty}
        </Typography>
      ) : (
        payloadLabels.map(log => (
          <ChangeLogLabelValue
            key={log.key}
            label={log?.label || log.key}
            value={log.value}
            type={log?.type || 'text'}
          />
        ))
      )}
    </>
  );
};

export default ChangeLogs;
