// @flow
import {
  DISPLAY_VERSION_BAR,
  HIDE_ALERT,
  HIDE_CONFIRMATION,
  HIDE_FILE,
  HIDE_FILE_EXPLORER,
  SHOW_ALERT,
  SHOW_CONFIRMATION,
  SHOW_FILE,
  SHOW_FILE_EXPLORER,
  SIGNIN_USER,
  TOGGLE_EXPAND_TABLE,
  TOGGLE_SIDEBAR,
  UPDATE_VERSION
} from 'actions/app';
import { combineReducers } from 'redux';
import type { AppDomainState, AppUiState } from 'types/app';
import type { Action } from 'types/redux';

const uiReducer = (
  state: AppUiState = {},
  action: Action = { type: '', payload: {} }
): AppUiState => {
  const { type, payload } = action;
  switch (type) {
    case SHOW_ALERT:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            key: new Date().getTime() + Math.random(),
            ...payload
          }
        ]
      };
    case HIDE_ALERT:
      return {
        ...state,
        alerts: state.alerts.filter(alert => alert.key !== payload)
      };
    case SHOW_CONFIRMATION:
      return {
        ...state,
        confirmation: payload
      };
    case HIDE_CONFIRMATION:
      return {
        ...state,
        confirmation: null
      };
    case SHOW_FILE:
      return {
        ...state,
        activePdf: payload
      };
    case HIDE_FILE:
      return {
        ...state,
        activePdf: null
      };
    case SHOW_FILE_EXPLORER:
      return {
        ...state,
        fileExplorer: payload
      };
    case HIDE_FILE_EXPLORER:
      return {
        ...state,
        fileExplorer: null
      };
    case DISPLAY_VERSION_BAR:
      return {
        ...state,
        showVersionBar: true,
        version: payload
      };
    case UPDATE_VERSION:
      return {
        ...state,
        version: payload
      };
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: payload
      };
    case TOGGLE_EXPAND_TABLE:
      return {
        ...state,
        isTableExpanded: payload
      };
    default:
      return state;
  }
};

const domainReducer = (
  state: AppDomainState = {},
  action: Action = { type: '', payload: {} }
): AppDomainState => {
  const { type, payload } = action;
  switch (type) {
    case SIGNIN_USER:
      return { ...state, currentUser: payload };
    default:
      return state;
  }
};

const appReducer = combineReducers({ domain: domainReducer, ui: uiReducer });

export default appReducer;
