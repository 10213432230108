// @flow
import React from 'react';
import { THEME } from 'GlobalStyles';
import FPChip from 'UI/components/atoms/FPChip';

interface HealthStatusChipProps {
  healthStatus: string;
}

const COLORS_BY_HEALTH_STATUS = {
  'in training': THEME.palette.info.FP200,
  'on track': THEME.palette.success.FP200,
  'under 3 months': THEME.palette.grey[300],
  'at risk': THEME.palette.error.FP200,
  behind: THEME.palette.warning.FP200
};

export const HealthStatusChip = ({ healthStatus }: HealthStatusChipProps) => {
  const normalizedHealthStatus = healthStatus.toLowerCase();
  const chipColor = COLORS_BY_HEALTH_STATUS[normalizedHealthStatus] ?? THEME.palette.grey[300];
  return (
    <FPChip
      color={chipColor}
      label={healthStatus}
      textColor={THEME.palette.grey[700]}
      size="small"
    />
  );
};
