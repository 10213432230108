// @flow
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import qs from 'query-string';
import strings from 'strings';
import type { BulkEmailMode, OperationResult } from 'types/app';
import { BulkMenuItems } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { getErrorMessage, getRemainingTimeToDate } from 'UI/utils';

import API from '../API';

const { BulkEmails, BulkEmailsDrafts, BulkEmailById, BulkEmailScheduleById, BulkEmailSchedules } =
  Endpoints;
const {
  bulkEmails: {
    errors: bulkErrors,
    schedule: { errors: scheduleErrors }
  }
} = strings;

/**
 * @typedef {Object} Endpoints
 * @property {string} getAll
 * @property {string} getById
 */

/**
 * @param {'sent' | 'drafts' | 'schedule'} mode
 * @returns {{endpoints: Endpoints, statusAlert, catchAlert}}
 */
const getModeValues = mode => {
  const modesParams = {
    [BulkMenuItems.Sent]: {
      endpoints: {
        getAll: BulkEmails,
        getById: BulkEmailById
      },
      statusAlert: bulkErrors.gettingSentEmails.not200,
      catchAlert: bulkErrors.gettingSentEmails.unhandled
    },
    [BulkMenuItems.Drafts]: {
      endpoints: {
        getAll: BulkEmailsDrafts,
        getById: BulkEmailById
      },
      statusAlert: bulkErrors.gettingDrafts.not200,
      catchAlert: bulkErrors.gettingDrafts.unhandled
    },
    [BulkMenuItems.Schedule]: {
      endpoints: {
        getAll: BulkEmailSchedules,
        getById: BulkEmailScheduleById
      },
      statusAlert: bulkErrors.gettingSchedules.not200,
      catchAlert: bulkErrors.gettingSchedules.unhandled
    }
  };

  return modesParams[mode] || false;
};

/**
 * Function that retrieves a list of sent emails
 * @param {Object} [queryParams]
 * @param {string} [queryParams.keyword]
 * @param {number} [queryParams.page]
 * @param {number} [queryParams.perPage]
 * @param {string} [queryParams.orderBy]
 * @param {'asc' | 'desc'} [queryParams.direction]
 * @param {Array} [queryParams.recruiterIds]
 * @param {Array} [queryParams.coachIds]
 * @param {Array} [queryParams.regionalIds]
 * @param {Array} [queryParams.scopeIds]
 * @param {Array} [queryParams.templateIds]
 * @param {number} [queryParams.userFilter]
 * @param {'sent' | 'drafts'} mode
 * @returns {Promise}
 */
export const getBulks = async (queryParams = {}, mode: BulkEmailMode): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  const { endpoints, statusAlert, catchAlert } = getModeValues(mode);
  try {
    const { keyword, page, perPage, orderBy, direction, ...restFilters } = queryParams;
    const stringyfiedUrl = qs.stringifyUrl(
      {
        url: endpoints.getAll,
        query: {
          keyword: keyword || '',
          page: page || 1,
          perPage: perPage || 10,
          orderBy: orderBy || '',
          direction: direction || '',
          ...restFilters
        }
      },
      { arrayFormat: 'comma' }
    );
    const response = await API.get(stringyfiedUrl);
    if (response.status === HTTPStatusCodes.Ok) {
      result.success = true;
      result.data = response.data.data.map(item => ({
        ...item,
        warning:
          mode === BulkMenuItems.Schedule && !getRemainingTimeToDate(item?.send_date)
            ? scheduleErrors.emailCouldntBeSent
            : null
      }));
    } else {
      result.alert = {
        ...statusAlert,
        severity: 'error'
      };
    }
  } catch (error) {
    result.alert = {
      ...catchAlert,
      severity: 'error',
      body: getErrorMessage(error)
    };
  }
  return result;
};

export const getBulkById = async (
  emailId: number,
  bulkMode: BulkEmailMode
): Promise<OperationResult> => {
  const { endpoints } = getModeValues(bulkMode);
  const result: OperationResult = { success: false };
  try {
    const response = await API.get(endpoints.getById.replace(/:id/, emailId));
    if (response.status === HTTPStatusCodes.Ok) {
      const {
        allow_candidates_in_marketing: allowMarketingCandidates,
        allow_hirings_in_recruiting: allowRecruitHirings,
        block_client_companies: blockClientCompanies,
        block_duration_days: blockDays,
        block_resend: blockResend,
        block_similar_companies: blockCompanies,
        bulkType,
        created_at,
        created_by,
        emailBody,
        emailTemplate,
        marketingCandidates,
        recruitingJobOrders,
        search_project_selection_params: searchProjectSelectionParams,
        searchProject,
        send_date,
        signature,
        subcategory,
        use_outlook,
        user_email,
        user_name,
        wasScheduledEmail
      } = response.data;
      result.success = true;
      result.data = {
        createdAt: created_at,
        emailConfig: {
          bulkType,
          generalCategory: subcategory,
          isScheduled: wasScheduledEmail,
          template: emailTemplate || {},
          blockResend,
          blockDays,
          marketing: {
            blockCompanies,
            blockClientCompanies,
            allowMarketingCandidates,
            marketingCandidates
          },
          recruiting: {
            allowRecruitHirings,
            recruitingJobOrders
          },
          searchProjectSelectionParams,
          sendViaOutlook: use_outlook
        },
        emailBody: {
          ...emailBody,
          signature,
          attachments: emailBody.attachments.map(({ file_size, ...rest }) => ({
            size: file_size,
            ...rest
          }))
        },
        userInfo: {
          id: created_by,
          email: user_email,
          name: user_name
        },
        searchProject: {
          id: searchProject?.id,
          name: searchProject?.name,
          totalItems: searchProject?.total_items
        },
        sendDate: send_date
      };
    }
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: 'Error on email preview',
      body: 'Error while getting email preview data'
    };
  }
  return result;
};
