// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Text from 'UI/components/atoms/Text';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { NotificationNotFoundImg } from 'UI/res';

const NotificationError = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      style={{ position: 'relative', minHeight: '80vh' }}
    >
      <EmptyPlaceholder
        title="Notifications Not Found"
        subtitle="We experienced some issues while receiving your notifications."
        customEmptyState={<NotificationNotFoundImg />}
      >
        <Text text="Please check back later" variant="body2" />
      </EmptyPlaceholder>
    </Grid>
  );
};

export default NotificationError;
