// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonWrapper = ({
  children,
  gridProps = null,
  loading = true,
  skeletonsCount,
  skeletonProps,
  spacing
}) =>
  loading ? (
    <Grid container spacing={spacing} {...gridProps}>
      {Array.from(Array(skeletonsCount).keys()).map(each => (
        <Grid key={each} item>
          <Skeleton {...skeletonProps} />
        </Grid>
      ))}
    </Grid>
  ) : (
    children
  );

export default SkeletonWrapper;
