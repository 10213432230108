import * as React from 'react';

const SvgCollapse = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <g fill="#6A7381" clipPath="url(#a)">
        <path d="M19.587 4.413a.828.828 0 0 0-1.174 0l-2.28 2.254a.172.172 0 0 1-.133.066.193.193 0 0 1-.1-.066l-.88-.867a.667.667 0 0 0-1.14.473v3.18a.666.666 0 0 0 .667.667h3.18a.666.666 0 0 0 .613-.413.666.666 0 0 0-.14-.727l-.867-.88a.167.167 0 0 1 0-.24l2.274-2.273a.826.826 0 0 0-.02-1.174ZM4.413 19.587a.807.807 0 0 0 .587.247.807.807 0 0 0 .587-.247l2.28-2.253A.146.146 0 0 1 8 17.267a.16.16 0 0 1 .12.047l.86.886a.667.667 0 0 0 1.026-.104.666.666 0 0 0 .114-.37v-3.18a.667.667 0 0 0-.667-.666h-3.18a.667.667 0 0 0-.473 1.133l.867.887a.161.161 0 0 1 .037.18.161.161 0 0 1-.037.054l-2.254 2.28a.826.826 0 0 0 0 1.173ZM13.88 17.727a.667.667 0 0 0 .413.613.666.666 0 0 0 .727-.14l.88-.866a.16.16 0 0 1 .1-.067.147.147 0 0 1 .113.047l2.28 2.273a.82.82 0 0 0 1.174 0 .826.826 0 0 0 0-1.174l-2.234-2.28a.162.162 0 0 1-.037-.18.162.162 0 0 1 .037-.053l.887-.886a.667.667 0 0 0-.473-1.134h-3.2a.667.667 0 0 0-.667.667v3.18ZM10.12 6.273A.666.666 0 0 0 8.98 5.8l-.88.867a.193.193 0 0 1-.1.066.173.173 0 0 1-.133-.066l-2.28-2.254a.83.83 0 1 0-1.174 1.174L6.667 7.86a.167.167 0 0 1 0 .24l-.867.88a.667.667 0 0 0-.14.727.667.667 0 0 0 .613.413h3.18a.667.667 0 0 0 .667-.667v-3.18Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M4 4h16v16H4z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgCollapse;
