import * as React from 'react';

const SvgDelete = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M24 12a12 12 0 10-12 12 12 12 0 0012-12zm-7.29 3.28a1 1 0 010 1.41 1 1 0 01-1.42 0l-3.11-3.11a.26.26 0 00-.35 0l-3.11 3.11a1 1 0 01-1.41-1.41l3.11-3.11a.26.26 0 000-.35L7.31 8.71a1 1 0 010-1.42 1 1 0 011.41 0l3.11 3.11a.24.24 0 00.35 0l3.11-3.11a1.004 1.004 0 111.42 1.42l-3.11 3.11a.24.24 0 000 .35z" />
    </svg>
  );
};

export default SvgDelete;
