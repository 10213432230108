// @flow
import React from 'react';
import { StatsItemsCard } from 'features/command-center/components/shared';
import { getFinanceRecruiterSummary } from 'features/command-center/services';
import strings from 'strings';

export const FinancialStatsSummary = ({ requestParams }) => {
  return (
    <StatsItemsCard
      header={strings.commandCenter.activity.financialStatsSummary.title}
      promise={() => getFinanceRecruiterSummary(requestParams)}
      subheader={strings.commandCenter.activity.financialStatsSummary.subtitle}
      textAlign="center"
    />
  );
};
