import { METRICS } from 'features/command-center/components/activity/OverallPerformance/OverallPerformance.constants';

export const generateFinalEndpoint = endpoint => {
  const [baseUrl, queryString] = endpoint.split('?');
  const params = new URLSearchParams(queryString);
  const originalMetrics = params.get('metrics') || '';
  const originalMetricsArray = originalMetrics ? originalMetrics.split(',') : [];
  const customMetrics = METRICS.split(',').filter(metric => !originalMetricsArray.includes(metric));
  const finalMetrics = originalMetricsArray.concat(customMetrics).join(',');

  params.set('metrics', finalMetrics);
  return `${baseUrl}?${params.toString()}`.replace(/%2C/g, ',');
};
