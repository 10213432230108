import { makeStyles } from '@material-ui/core';
import { flexAlignCenterSpaceBetween } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  formCollabs: flexAlignCenterSpaceBetween,
  formCollabsContainer: {
    marginTop: theme.spacing(4)
  },
  saveChangesAvatars: {
    padding: theme.spacing(0, 8),
    overflowY: 'auto',
    '& > div': {
      marginBottom: theme.spacing(2)
    },
    '&:last-child': {
      marginBottom: 0
    }
  }
}));

