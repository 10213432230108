import React from 'react';
import { styled } from '@material-ui/core';
import strings from 'strings';
import { hintDefinitions } from 'UI/components/atoms/FPHint/styles';
import FPIcon from 'UI/components/atoms/FPIcon';
import { SvgInformationCircle } from 'UI/res';

const INFO_TEXT = strings.inventoryProfiles.sections.tabs.referenceCheck.infoText;

const InfoLabelWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flex: 1,
  textAlign: 'right'
});

const InfoLabelText = styled('p')(({ theme }) => ({
  margin: '0',
  color: theme.palette.grey[700]
}));

const InfoLabel = () => (
  <InfoLabelWrapper>
    <FPIcon component={SvgInformationCircle} color={hintDefinitions.colors.info} size="sm" />
    <InfoLabelText>&nbsp;{INFO_TEXT}</InfoLabelText>
  </InfoLabelWrapper>
);

export default InfoLabel;
