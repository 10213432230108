// @flow
import React from 'react';
import { useHistory } from 'react-router';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import {
  SvgNoCandidates,
  SvgNoCompanies,
  SvgNoHiringAuthority,
  SvgNoJobOrders
} from 'UI/res/icons/milano';

import ContentPageLayout from '../ContentPageLayout';
import EmptyPlaceholder from '../EmptyPlaceholder';

type ProfileStatusWrapperProps = {
  profileStatusMode: { loading: React.Node, success: React.Node },
  responseStatusCode: number,
  status: Object,
  onRefreshProfileClick: () => {},
  entityRoute: string,
  entity: { id: string, title: string }
};
const emptyStateHeight = 400;

const {
  shared: {
    errors: { serverError, profiles: profileEmptyStates }
  }
} = strings;

const ProfileUiStateManager = ({
  profileStatusMode,
  status,
  responseStatusCode,
  onRefreshProfileClick,
  entityRoute,
  entity
}: ProfileStatusWrapperProps) => {
  const { loading, success } = profileStatusMode;
  const { id, title } = entity;

  const defaultTextButton = `Go to ${title} list`;
  const history = useHistory();
  const handleProfileClik = () => history.push(entityRoute);

  // To do add company profiles

  const profileIcons = {
    candidate: {
      Icon: SvgNoCandidates
    },
    joborder: {
      Icon: SvgNoJobOrders
    },
    hiringAuthority: {
      Icon: SvgNoHiringAuthority
    },
    company: {
      Icon: SvgNoCompanies
    },
    name: {
      Icon: SvgNoCandidates
    }
  };

  const profileIcon = profileIcons[id].Icon;

  const renderEmptyState = ({ Icon, textButton, emptyStrings, onClick }) => {
    return (
      <ContentPageLayout>
        <EmptyPlaceholder {...emptyStrings} customEmptyState={<Icon height={emptyStateHeight} />}>
          <FPActionButton text={textButton} onClick={onClick} />
        </EmptyPlaceholder>
      </ContentPageLayout>
    );
  };

  const emptyStates = {
    [HTTPStatusCodes.BadRequest]: renderEmptyState({
      Icon: profileIcon,
      textButton: defaultTextButton,
      emptyStrings: { ...profileEmptyStates[id].notFound },
      onClick: handleProfileClik
    }),
    [HTTPStatusCodes.NotFound]: renderEmptyState({
      Icon: profileIcon,
      textButton: defaultTextButton,
      emptyStrings: { ...profileEmptyStates[id].notFound },
      onClick: handleProfileClik
    }),
    [HTTPStatusCodes.InternalServerError]: renderEmptyState({
      Icon: profileIcon,
      textButton: 'Try again',
      emptyStrings: serverError,
      onClick: onRefreshProfileClick
    })
  };

  const profileStatus = {
    empty: emptyStates[responseStatusCode],
    loading,
    success,
    error: emptyStates[responseStatusCode] ?? emptyStates[500]
  };

  return profileStatus[status];
};

export default ProfileUiStateManager;
