import { makeStyles } from '@material-ui/core/styles';

const WIDGET_HEIGHT = 390;

export const useStyles = makeStyles(theme => ({
  containerFPTreeView: {
    borderRight: `2px solid ${theme.palette.grey[200]}`,
    maxHeight: WIDGET_HEIGHT,
    overflow: 'auto'
  }
}));
