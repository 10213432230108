// @flow
import React from 'react';

import Content from './Content';

const DashboardOverview = props => {
  return <Content {...props} />;
};

export default DashboardOverview;
