import * as React from "react";

const SvgFilePpt = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M17.5 6h3.9a.25.25 0 00.17-.43L16.43.42A.26.26 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z" />
      <path d="M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM7 16.12h-.62a.26.26 0 00-.26.25v2.12a.62.62 0 11-1.24 0v-6a.62.62 0 01.62-.62H7a2.13 2.13 0 010 4.25zm5 0h-.62a.26.26 0 00-.26.25v2.12a.62.62 0 11-1.24 0v-6a.62.62 0 01.62-.62H12a2.13 2.13 0 010 4.25zm7.5-3h-1.13a.25.25 0 00-.25.25v5.13a.62.62 0 01-1.24 0v-5.13a.25.25 0 00-.25-.25H15.5a.63.63 0 010-1.25h4a.63.63 0 010 1.25z" />
      <path d="M7 13.12h-.62a.26.26 0 00-.26.26v1.24a.26.26 0 00.26.25H7a.88.88 0 100-1.75zM12 13.12h-.62a.26.26 0 00-.26.26v1.24a.26.26 0 00.26.25H12a.88.88 0 100-1.75z" />
    </svg>
  );
};

export default SvgFilePpt;
