import { makeStyles } from '@material-ui/core';
import { flexAlignCenter, flexAlignStartSpaceBetween } from 'UI/utils/styles';

export const useStyles = makeStyles(
  theme => ({
    similarCandidateContainer: {
      ...flexAlignStartSpaceBetween,
      marginTop: theme.spacing(2),
      overflowX: 'auto',
      overflowY: 'hidden'
    },
    similarCandidateInfo: {
      ...flexAlignCenter,
      flexWrap: 'nowrap'
    },
    infoSeparator: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      padding: 0
    },
    container: {
      marginBottom: theme.spacing(1)
    }
  }),
  { classNamePrefix: 'duplicateCandidateOverview' }
);
