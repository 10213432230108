// @flow
import * as React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import strings from 'strings';

const useStyles = makeStyles(() => ({
  diagnostics: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all'
  }
}));

const ErrorDiagnosticsPanel = ({ error }) => {
  const uiStrings = strings.shared.ui;
  const classes = useStyles();
  const packageVersion = useSelector(state => state.app.ui.version);

  return (
    <>
      <p>
        {uiStrings.unknownError.body}{' '}
        <a href={process.env.REACT_APP_SUPPORT_GLIP_URL} title={uiStrings.unknownError.targetGlip}>
          {uiStrings.unknownError.targetGlip}
        </a>
        .
      </p>
      <pre className={classes.diagnostics}>
        <p>
          <strong>{uiStrings.unknownError.diagnosticsUrl}:</strong> {window.location.href} <br />
          <strong>{uiStrings.unknownError.version}:</strong> {packageVersion} <br />
          {error && (
            <>
              <strong>{uiStrings.unknownError.diagnosticsError}:</strong> {error}
            </>
          )}
        </p>
      </pre>
    </>
  );
};

export default ErrorDiagnosticsPanel;
