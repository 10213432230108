import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  formContainer: {
    margin: theme.spacing(0, 0, 3)
  },
  formInputContainer: {
    marginTop: theme.spacing(1)
  }
}));
