// @flow
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import {
  changeNotificationsType as changeNotificationTypeAction,
  changeNotificationsVisibility as changeNotificationVisibilityAction,
  getNotifications,
  resetNotifications as resetNotificationsAction
} from 'actions/notification';
import useNotification from 'hooks/useNotification';
import strings from 'strings';
import type { Notification } from 'types/notification';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import NotificationsSkeleton from 'UI/components/molecules/NotificationsSkeleton';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { NotificationsVisibilityOptions, NotificationType } from 'UI/constants/defaults';
import { getId, nestTernary, NOTIFICATIONS_PERMISSION_TYPES } from 'UI/utils';

import NotificationCard from './card';
import NotificationEmpty from './empty';
import NotificationError from './error';
import { styles, useStyles } from './styles';

const SKELETON_MAX_ITEMS = Math.round(window.innerHeight / 80) + 1;

const { denied: deniedString, granted: grantedString } = strings.notifications;

type NotificationPreviewProps = {
  onClose: () => any,
  notifications: Notification[],
  onGetNotifications: any => void,
  resetNotifications: any => void,
  changeNotificationsVisibility: any => void,
  changeNotificationsType: any => void,
  totalFilterUnread: number,
  filterTotal: number,
  activeVisibility: string,
  activeType: string,
  isLoading: boolean,
  hasError: boolean,
  hasMore: boolean
};

const NotificationPreview = (props: NotificationPreviewProps) => {
  const {
    onClose,
    notifications,
    onGetNotifications,
    resetNotifications,
    changeNotificationsVisibility,
    changeNotificationsType,
    totalFilterUnread,
    filterTotal,
    activeVisibility,
    activeType,
    isLoading,
    hasError,
    hasMore
  } = props;

  const { verifyNotificationPermissions, getCurrentNotificationPermission } = useNotification();

  const filtersNotification = [
    { id: 1, type: NotificationType.Inventory, title: 'Inventory' },
    { id: 3, type: NotificationType.FeeAgreement, title: 'Fee Agreement' }
  ];

  const visibilityButtons = [
    {
      id: NotificationsVisibilityOptions.Unread,
      label: 'Unread',
      total: totalFilterUnread
    },
    {
      id: NotificationsVisibilityOptions.Read,
      label: 'Read'
    },
    {
      id: NotificationsVisibilityOptions.All,
      label: 'All'
    }
  ];

  const observer = useRef();
  const [params, setParams] = useState({
    page: 1,
    perPage: SKELETON_MAX_ITEMS,
    visibility: activeVisibility || NotificationsVisibilityOptions.Unread,
    type: activeType?.type || filtersNotification[0].type
  });
  const [type, setType] = useState(activeType || filtersNotification[0]);

  const handleVisibilityChange = visibility => {
    changeNotificationsVisibility(visibility);
    setParams(prevState => ({
      ...prevState,
      page: 1,
      perPage: SKELETON_MAX_ITEMS,
      visibility
    }));
  };

  const handleFilterChange = (name, value) => {
    changeNotificationsType(value);
    setType(value);
    setParams(prevState => ({
      page: 1,
      perPage: prevState.perPage,
      visibility: prevState.visibility,
      type: value?.type
    }));
  };

  const lastNotificationElementRef = useCallback(
    node => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setParams(prevState => ({
            ...prevState,
            page: prevState.page + 1
          }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  useEffect(() => {
    resetNotifications();
  }, [resetNotifications]);

  useEffect(() => {
    onGetNotifications(params);
  }, [onGetNotifications, params]);

  const classes = useStyles();

  return (
    <div role="presentation">
      <DrawerContentLayout
        containerComponent="div"
        title="Notifications"
        variant="borderless"
        isBottomToolbarNeeded={false}
        onClose={onClose}
        contentProps={{ style: styles.drawerContainer }}
        headerProps={{
          subheaderTypographyProps: { style: styles.cardSubheader },
          subheader: (
            <>
              <div>
                <AutocompleteSelect
                  name="type"
                  placeholder="Select type"
                  selectedValue={type}
                  onSelect={handleFilterChange}
                  defaultOptions={filtersNotification}
                  disabled={isLoading}
                  disableClearable
                />
              </div>
              <div className={classes.visibilityNotifications}>
                {visibilityButtons.map(item => (
                  <Badge key={getId()} badgeContent={item?.total} color="primary">
                    <Chip
                      clickable
                      key={getId()}
                      label={item.label}
                      onClick={() => handleVisibilityChange(item.id)}
                      style={
                        params.visibility === item.id
                          ? styles.visibilityNotificationActive
                          : styles.visibilityNotificationNoActive
                      }
                      disabled={isLoading}
                    />
                  </Badge>
                ))}
              </div>
              <div>
                {(NOTIFICATIONS_PERMISSION_TYPES.DENIED === getCurrentNotificationPermission() ||
                  NOTIFICATIONS_PERMISSION_TYPES.DEFAULT ===
                    getCurrentNotificationPermission()) && (
                  <Typography variant="body1" className={classes.deniedTitle}>
                    {deniedString}
                  </Typography>
                )}
                {NOTIFICATIONS_PERMISSION_TYPES.GRANTED === getCurrentNotificationPermission() && (
                  <Typography variant="body1" className={classes.grantedTitle}>
                    {grantedString}
                    <Button color="primary" onClick={() => verifyNotificationPermissions()}>
                      click here
                    </Button>
                    to request permission.
                  </Typography>
                )}
              </div>
            </>
          )
        }}
      >
        <>
          {notifications.length > 0
            ? notifications.map((item, index) => {
                if (notifications.length === index + 1 && notifications.length < filterTotal) {
                  return (
                    <div ref={lastNotificationElementRef} key={`${item.id}-${getId()}`}>
                      <NotificationCard notification={item} onCloseClick={onClose} />
                    </div>
                  );
                }
                return (
                  <NotificationCard
                    key={`${item.id}-${getId()}`}
                    notification={item}
                    onCloseClick={onClose}
                  />
                );
              })
            : nestTernary(
                isLoading,
                <NotificationsSkeleton rows={SKELETON_MAX_ITEMS} />,
                nestTernary(hasError, <NotificationError />, <NotificationEmpty />)
              )}
          {isLoading && <NotificationsSkeleton rows={2} />}
        </>
      </DrawerContentLayout>
    </div>
  );
};

const mapStateToProps = ({ notification }) => {
  return {
    isLoading: notification.ui.isLoading,
    hasError: notification.ui.hasError,
    hasMore: notification.ui.hasMore,
    totalFilterUnread: notification.domain.totalFilterUnread,
    filterTotal: notification.domain.filterTotal,
    notifications: notification.domain.notifications,
    activeVisibility: notification.domain.activeVisibility,
    activeType: notification.domain.activeType
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetNotifications: params => dispatch(getNotifications(params)),
    resetNotifications: () => dispatch(resetNotificationsAction()),
    changeNotificationsVisibility: visibility =>
      dispatch(changeNotificationVisibilityAction(visibility)),
    changeNotificationsType: type => dispatch(changeNotificationTypeAction(type))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPreview);
