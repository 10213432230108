import { makeStyles } from '@material-ui/core/styles';

const iconSize = 12;

export const useStyles = makeStyles(theme => ({
  icon: {
    width: iconSize,
    height: iconSize,
    color: theme.palette.error.dark,
    marginLeft: `${theme.spacing(1)}px !important`
  },
  optOut: {
    border: `1px solid ${theme.palette.error.dark}`
  }
}));
