// @flow
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useGlobalStyles } from 'GlobalStyles';
import find from 'lodash/find';
import intersection from 'lodash/intersection';
import uniqBy from 'lodash/uniqBy';
import { TableToolbar } from 'mui-datatables';
import CustomDataTableCheckbox from 'UI/components/molecules/CustomDataTableCheckbox';
import { SelectionType } from 'UI/constants/defaults';
import { useMultiselectTable } from 'UI/globalStyles/DataTableStyles';

const SelectionAction = {
  All: 'all',
  Page: 'page',
  ClearAll: 'clear-all'
};

export const CustomTableToolbarSelect = ({ total, children }) => {
  const globalClasses = useGlobalStyles();

  return total > 0 ? (
    <Grid
      container
      alignItems="center"
      justify="space-between"
      className={globalClasses.customTableToolbarSelect}
    >
      <Grid item>
        <b>{total} Row(s) selected</b>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  ) : null;
};

const idExtractorFunction = ({ id }) => id;

const useMultipleSelection = ({ count, data, disableSelectAllCheckbox }) => {
  const classes = useMultiselectTable();
  const allSelectedItemsRef = useRef([]);
  const allExcludedItemsRef = useRef([]);
  const selectionTypeRef = useRef(null);

  const [selectedIndexesInPage, setSelectedIndexesInPage] = useState([]);

  const dataIndexes = useMemo(() => data.map((_, index) => index), [data]);
  const totalInPage = dataIndexes.length;

  useEffect(() => {
    const selectedIds = allSelectedItemsRef.current.map(idExtractorFunction);
    const excludedIds = allExcludedItemsRef.current.map(idExtractorFunction);
    const dataIds = data.map(idExtractorFunction);

    if ((selectedIds.length === 0 && excludedIds.length === 0) || !dataIds.length) return;

    if (selectionTypeRef.current !== 'all') {
      const visibleSelectedIds = intersection(dataIds, selectedIds);
      const selectedIndexes = visibleSelectedIds.map(id =>
        dataIds.findIndex(visibleId => visibleId === id)
      );
      setSelectedIndexesInPage(selectedIndexes);
    } else {
      const visibleExcludedIds = intersection(dataIds, excludedIds);
      const selectedIndexes = dataIds
        .map((_, index) => index)
        .filter(idx => !visibleExcludedIds.includes(dataIds[idx]));
      setSelectedIndexesInPage(selectedIndexes);
    }
  }, [data]);

  const handleSelectAll = useCallback(() => {
    allExcludedItemsRef.current = [];
    allSelectedItemsRef.current = [];
    selectionTypeRef.current = SelectionAction.All;
    setSelectedIndexesInPage([...dataIndexes]);
  }, [dataIndexes]);

  const handleUnselectAll = useCallback(() => {
    allSelectedItemsRef.current = [];
    allExcludedItemsRef.current = [];
    selectionTypeRef.current = null;
    setSelectedIndexesInPage([]);
  }, []);

  const handleSelectPage = useCallback(() => {
    const selectedItems = allSelectedItemsRef.current;
    const excludedItems = allExcludedItemsRef.current;

    allExcludedItemsRef.current = excludedItems.filter(
      ({ id }) => !find(data, ({ id: itemId }) => itemId === id)
    );
    allSelectedItemsRef.current = uniqBy([...selectedItems, ...data], 'id');
    selectionTypeRef.current = null;

    setSelectedIndexesInPage(dataIndexes);
  }, [data, dataIndexes]);

  const handleRowSelection = (newSelectedRows, _, rowIndexes) => {
    const selectedItems = allSelectedItemsRef.current;
    const excludedItems = allExcludedItemsRef.current;

    const isAllSelection = selectionTypeRef.current === SelectionAction.All;
    setSelectedIndexesInPage(rowIndexes);

    if (newSelectedRows.length !== 1) return;

    const selectedIndex = newSelectedRows[0].index;
    const selectedId = data[selectedIndex].id;
    const isAdding = rowIndexes.includes(selectedIndex);
    if (isAllSelection) {
      allExcludedItemsRef.current = isAdding
        ? excludedItems.filter(({ id }) => id !== selectedId)
        : [...excludedItems, data[selectedIndex]];
    } else {
      allSelectedItemsRef.current = isAdding
        ? [...selectedItems, data[selectedIndex]]
        : selectedItems.filter(({ id }) => id !== selectedId);
    }
  };

  const handleOnSelectionChange = useCallback(
    selectionType => {
      const selectionActionMap = {
        [SelectionAction.All]: handleSelectAll,
        [SelectionAction.Page]: handleSelectPage,
        [SelectionAction.ClearAll]: handleUnselectAll
      };
      const action = selectionActionMap[selectionType];
      action && action();
    },
    [handleSelectAll, handleSelectPage, handleUnselectAll]
  );

  const getSelectionData = useCallback(() => {
    const isAllSelection = selectionTypeRef.current === SelectionAction.All;
    const type = isAllSelection ? SelectionType.Exclude : SelectionType.Include;

    const items = isAllSelection ? allExcludedItemsRef.current : allSelectedItemsRef.current;
    return {
      type,
      data: items,
      count
    };
  }, [count]);

  const totalSelected =
    selectionTypeRef.current !== SelectionAction.All
      ? allSelectedItemsRef.current.length
      : count - allExcludedItemsRef.current.length;

  const someSelected = totalSelected > 0;

  const getCustomCheckbox = useCallback(
    props => (
      <CustomDataTableCheckbox
        SelectionMenuProps={{
          all: count,
          disableSelectAllCheckbox,
          itemType: 'items',
          onSelectionChange: handleOnSelectionChange,
          someSelected,
          totalInPage
        }}
        {...props}
      />
    ),
    [disableSelectAllCheckbox, someSelected, count, totalInPage, handleOnSelectionChange]
  );

  const getCustomToolbar = useCallback(
    props => (
      <TableToolbar
        classes={
          someSelected
            ? {
                root: classes.toolBarRoot,
                left: classes.toolBarLeft,
                actions: classes.toolBarActions
              }
            : null
        }
        {...props}
      />
    ),
    [someSelected, classes.toolBarRoot, classes.toolBarLeft, classes.toolBarActions]
  );

  const multiSelectComponents = {
    Checkbox: getCustomCheckbox,
    TableToolbar: getCustomToolbar
  };

  return {
    allSelectedIds: allSelectedItemsRef.current,
    allExcludedIds: allExcludedItemsRef.current,
    selectionType: selectionTypeRef.current,
    handleRowSelection,
    selectedIndexesInPage,
    getCustomCheckbox,
    someSelected,
    totalSelected,
    getSelectionData,
    filteredItems: getSelectionData(),
    setToInitial: handleUnselectAll,
    multiSelectComponents
  };
};

export default useMultipleSelection;
