// @flow
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { showAlert } from 'actions/app';
import axios from 'axios';
import useDebounce from 'hooks/debounce';
import queryString from 'query-string';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import strings from 'strings';
import SelectBox from 'UI/components/atoms/SelectBox';
import { CandidateEntity, EntityType, entityTypes } from 'UI/constants/entityTypes';
import { colors, SearchBarIcon } from 'UI/res';
import { getErrorMessage, nestTernary } from 'UI/utils';
import { OptionRenderers } from 'UI/utils/renderers';

import { themeOverride, useAutocompleteStyles, useStyles } from './styles';

type GlobalSearchbarProps = {
  isSmallViewPort: boolean,
  onSelect?: (name?: string, value: any) => void,
  placeholder?: string,
  searchBarRef: any,
  width?: string
};

const { globalSearchDefault, globalSearchPerson, globalCompanySearch } = OptionRenderers;
const defaultEntityTypeFilter = CandidateEntity;
const limit = 25;
const globalSearchPersonParams = {
  fallback: null,
  chipPropsBuilder: null,
  showOtherEmail: true
};

const searchOptions = {
  [EntityType.Candidate]: {
    id: EntityType.Candidate,
    inColumns: ['pst.title', 'can.email'],
    placeholder: strings.shared.searchGlobal.candidate,
    renderOption: globalSearchPerson(
      'title',
      globalSearchPersonParams.fallback,
      globalSearchPersonParams.chipPropsBuilder,
      globalSearchPersonParams.showOtherEmail
    ),
    url: EntityRoutes.CandidateProfile
  },
  [EntityType.Joborder]: {
    id: EntityType.Joborder,
    inColumns: [],
    placeholder: strings.shared.searchGlobal.jobOrder,
    renderOption: globalSearchDefault('title'),
    url: EntityRoutes.JobOrderProfile
  },
  [EntityType.Company]: {
    id: EntityType.Company,
    inColumns: [],
    placeholder: strings.shared.searchGlobal.company,
    renderOption: globalCompanySearch({
      displayKey: 'name',
      chipBaseClass: 'chip-company-type'
    }),
    url: EntityRoutes.CompanyProfile
  },
  [EntityType.Contact]: {
    id: EntityType.Contact,
    inColumns: [],
    placeholder: strings.shared.searchGlobal.contact,
    renderOption: globalSearchPerson(
      'full_name',
      globalSearchPersonParams.fallback,
      globalSearchPersonParams.chipPropsBuilder,
      globalSearchPersonParams.showOtherEmail
    ),
    url: EntityRoutes.ContactProfile
  }
};

const GlobalSearchbar = ({
  width,
  onSelect,
  isSmallViewPort,
  searchBarRef
}: GlobalSearchbarProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cancelTokenRef = useRef();
  const [entityType, setEntityType] = useState(defaultEntityTypeFilter);
  const [keyword, setKeyword] = useState('');
  const [results, setResults] = useState([]);
  const [searchOption, setSearchOption] = useState(searchOptions[defaultEntityTypeFilter.id]);
  const [uiState, setUiState] = useState({ isFetching: false });

  const autoCompleteClasses = useAutocompleteStyles();
  const classes = useStyles({ isSmallViewPort });
  const debouncedKeyword = useDebounce(keyword, 500);

  useEffect(() => {
    (async () => {
      if (!debouncedKeyword) {
        setResults([]);
        return;
      }

      try {
        cancelTokenRef.current &&
          cancelTokenRef.current.cancel(strings.shared.requests.cancelTokenMessage);
        cancelTokenRef.current = axios.CancelToken.source();

        const inColumns = searchOption.inColumns.join(',');
        const params = queryString.stringify({
          keyword: debouncedKeyword.trim(),
          entityType: entityType.searchParam
        });

        setUiState(prev => ({ ...prev, isFetching: true }));
        const response = await API.get(
          `search?${params}&inColumns=${encodeURI(inColumns)}&limit=${limit}`,
          {
            cancelToken: cancelTokenRef.current.token
          }
        );
        response?.data && setResults(response.data);
      } catch (error) {
        if (!axios.isCancel(error)) {
          dispatch(
            showAlert({
              severity: 'error',
              title: 'Unexpected error while searching',
              body: getErrorMessage(error)
            })
          );
        }
      } finally {
        setUiState(prev => ({ ...prev, isFetching: false }));
      }
    })();
  }, [entityType, debouncedKeyword, searchOption, dispatch]);

  const handleSearch = (_, newInputValue, reason) => {
    if (reason === 'reset') return;
    setKeyword(newInputValue);
  };

  const handleChange = (_, value, reason) => {
    const auxValue = reason === 'create-option' && results.length ? results[0] : value;
    resetSearch();
    onSelect && onSelect(auxValue);
    const itemId = auxValue?.id;
    if (!itemId) return;
    const redirectUrl =
      searchOption?.id !== EntityType.Contact
        ? searchOption.url
        : nestTernary(
            auxValue?.type === 'inventory',
            EntityRoutes.HiringAuthorityProfile,
            EntityRoutes.ContactProfile
          );

    history.push(redirectUrl.replace(':id', itemId));
  };

  const resetSearch = () => {
    setKeyword('');
    setResults([]);
  };

  const handleSelectBox = (_, value) => {
    setSearchOption(searchOptions[value.id]);
    setEntityType(value);
  };

  return (
    <Paper component="form" className={classes.root} elevation={0} variant="outlined">
      <Autocomplete
        freeSolo
        autoComplete
        openOnFocus
        id="global-search-bar"
        noOptionsText="No results found, type to find something."
        placeholder={searchOption.placeholder}
        style={{ width }}
        filterOptions={opts => opts}
        getOptionLabel={option => option.title || option.name || ''}
        options={results}
        groupBy={option => option.firstLetter}
        onChange={handleChange} // TODO: implement the action after selecting the item.
        inputValue={keyword}
        onInputChange={handleSearch}
        classes={autoCompleteClasses}
        ref={searchBarRef}
        renderInput={params => (
          <ThemeProvider theme={themeOverride}>
            <TextField
              {...params}
              label={searchOption.placeholder}
              focused={isSmallViewPort}
              variant="outlined"
            />
          </ThemeProvider>
        )}
        renderOption={searchOption.renderOption}
      />
      <div className={classes.wrapper}>
        <div className={classes.searchEndAdorment}>
          {debouncedKeyword && !uiState.isFetching && (
            <Typography className={classes.resultsLabel}>
              {`(${results.length === limit ? '+' : ''}${results.length} result${
                results.length > 1 ? 's' : ''
              })`}
            </Typography>
          )}
          {uiState.isFetching ? (
            <CircularProgress className={classes.iconButton} />
          ) : (
            nestTernary(
              !debouncedKeyword,
              <IconButton className={classes.iconButton} disabled type="submit" aria-label="search">
                <SearchBarIcon fill={colors.inactiveSideBarTab} />
              </IconButton>,
              <IconButton className={classes.iconButton} onClick={resetSearch}>
                <CloseIcon fill={colors.inactiveSideBarTab} />
              </IconButton>
            )
          )}
        </div>
        <div className={classes.selectBox}>
          <SelectBox
            isGlobal
            onSelect={handleSelectBox}
            selectedValue={entityType}
            options={entityTypes.filter(({ showInGlobalSearch }) => showInGlobalSearch)}
          />
        </div>
      </div>
    </Paper>
  );
};

GlobalSearchbar.defaultProps = {
  placeholder: 'Search',
  width: '100%',
  onSelect: undefined,
  isSmallViewPort: false
};

export default GlobalSearchbar;
