// @flow
export const renderAvatarTitle = ({ title, initials }) =>
  `${title} ${initials ? `- ${initials}` : ''}`;

export const collabsMapper = ({
  collaboration_id,
  ext,
  initials,
  is_owner,
  isCurrentOwner,
  phone,
  role_title,
  team,
  user_id,
  user_email,
  user_name,
  ...restCollabsProps
}) => {
  const cardDetails = [
    { text: `Team ${team}` },
    { text: user_email }
  ];
  const cardActions = [
    { name: 'email', value: 'email', darkMode: true }
  ];

  ext && cardDetails.push({ text: `EXT • ${ext}` });
  phone && cardActions.unshift({ name: 'call', value: 'phone', darkMode: true });
  
  return {
    collaborationId: collaboration_id,
    id: user_id,
    isOwner: is_owner,
    title: user_name,
    detail: role_title,
    email: user_email,
    initials,
    profileAvatarProps: {
      showInfoCard: true,
      infoCardProps: {
        type: role_title,
        title: renderAvatarTitle({ title: user_name, initials }),
        details: cardDetails,
        actionsProps: !isCurrentOwner ? {
          actions: cardActions,
          contactValues: { email: user_email, phone }
        } : null
      }
    },
    ...restCollabsProps
  }
};