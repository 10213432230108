// @flow
import { useFeatureFlags as useRootFeatureFlags } from 'providers/FeatureFlagsProvider';
import { FeatureFlags } from 'UI/constants/featureFlags';

type UseFeatureFlagsReturnType = {
  canSaveAsDraft: boolean,
  canExecuteCandidateAssignmentSheet: boolean
};
export const useFeatureFlags = (): UseFeatureFlagsReturnType => {
  const { checkIfFeatureFlagEnabled } = useRootFeatureFlags();

  return {
    canSaveAsDraft: checkIfFeatureFlagEnabled(FeatureFlags.Drafts),
    canExecuteCandidateAssignmentSheet: checkIfFeatureFlagEnabled(
      FeatureFlags.CandidateDataSheetFlow
    )
  };
};
