import isNil from 'lodash/isNil';
import strings from 'strings';
import { formatRatio } from 'UI/utils';

export const formatStats = ({ response, drawerHandlers }) => {
  const finalData = response?.data ? response.data : response;

  const { goals, stats } = Object.keys(finalData).reduce(
    (acc, key) => {
      if (key.includes('Goal')) {
        acc.goals[key] = finalData[key];
      } else {
        acc.stats[key] = finalData[key];
      }
      return acc;
    },
    { goals: {}, stats: {} }
  );

  const results = Object.keys(stats).map(key => {
    const value = Number(stats[key]);
    const goal = goals[`${key}Goal`];
    return {
      title: strings.commandCenter.metricsTitles[key],
      value: !isNil(goal) ? formatRatio(value, goal, 'scalar') : value,
      ...(drawerHandlers &&
        finalData[`${key}Url`] && {
          onClick: () => drawerHandlers[key]({ url: finalData[`${key}Url`] })
        })
    };
  });

  return results.filter(item => item?.title);
};
