// @flow
import React from 'react';

const TitleSubtitle = ({ title, subtitle }) => (
  <div>
    <strong>{title}</strong>
    <br />
    <span>{subtitle}</span>
  </div>
);

export default TitleSubtitle;
