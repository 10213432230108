export const FORM_FIELDS_MAP = {
  Html: {
    key: 'html'
  },
  Name: {
    key: 'name'
  },
  ParentFolder: {
    key: 'parentFolder'
  },
  SmartTags: {
    key: 'smartTags'
  },
  Subject: {
    key: 'subject'
  }
};
