// @flow

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { showAlert } from 'actions/app';
import { globalStyles } from 'GlobalStyles';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import type { Map } from 'types';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import HiringAuthorityForm from 'UI/components/organisms/HiringAuthorityForm';
import HiringAuthoritySpecialtyForm from 'UI/components/organisms/HiringAuthoritySpecialtyForm';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getErrorMessage, getFeatureFlags } from 'UI/utils';
import { OptionRenderers, Renderers, Selectors } from 'UI/utils/renderers';

import { getPhonesSortedByDefaultPhone } from '../PhoneNumbersForm/utils';

type HiringAuthorityCreateFormProps = {
  joborderId: number,
  type: 'company' | 'joborder',
  isFromProfile?: boolean
};

const HiringAuthoritySelectForm = ({ joborderId, type }: HiringAuthorityCreateFormProps) => {
  const [comboValues, setComboValues] = useState<Map>({});
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const isMultiplePhonesEnabled = getFeatureFlags().includes(
    FeatureFlags.HiringAuthoritiesMultiplePhones
  );

  const { register, errors, setValue, getValues, reset } = useFormContext();

  useEffect(() => {
    register({ name: 'hiring_authority_id' }, { required: 'Hiring Authority is required' });
    register({ name: 'existingHiringAuthority' });
    register({ name: 'isContact' });
    register({ name: 'hasMultiplePhones' });
  }, [register]);

  const handleComboChange = (name?: string, value: any) => {
    setComboValues((prevState: Map): Map => ({ ...prevState, [name]: value }));
    setValue(name, value ? value.id : value, true);
  };

  const preloadForm = (nameInfo: any) => {
    const {
      id,
      email,
      title,
      personalInformation: {
        first_name,
        last_name,
        nickname,
        contact: { phone, ext, mobile, personal_email } = {}
      } = {},
      specialty = {},
      subspecialty,
      position,
      link_profile: linkProfile
    } = nameInfo || {};

    const phones = getPhonesSortedByDefaultPhone(nameInfo, EntityType.Contact);

    reset({
      ...getValues(),
      id,
      first_name,
      last_name,
      nickname,
      specialty_id: specialty && specialty.id,
      subspecialty_id: subspecialty && subspecialty.id,
      position_id: position && position.id,
      specialty,
      subspecialty,
      position,
      title,
      work_email: email,
      work_phone: phone,
      ext,
      personal_email,
      personal_phone: mobile,
      phones,
      phone,
      link_profile: linkProfile
    });

    setComboValues((prevState: Map): Map => ({
      ...prevState,
      specialty_id: specialty,
      subspecialty_id: subspecialty,
      position_id: position
    }));
  };

  const getName = async (url, name) => {
    setIsLoading(true);
    try {
      const apiVersion = isMultiplePhonesEnabled ? 2 : 1;
      const response = await API.get(`${url}/${name.id}`, { apiVersion });
      const hasPhones = !!response.data.phone_id;
      response.data.hasMultiplePhones = !!hasPhones;

      if (name.type === EntityType.ContactType.Contact) {
        response.data && preloadForm(response.data);
      }
      setValue('hasMultiplePhones', !!hasPhones);
      setValue('existingHiringAuthority', response.data);
      setIsLoading(false);
    } catch (error) {
      dispatch(
        showAlert({
          severity: 'error',
          title: 'Error',
          body: getErrorMessage(error)
        })
      );
      setIsLoading(false);
    }
  };

  const handleHASelectContact = (name?: string, value: any) => {
    if (!value) {
      setComboValues({});
      reset({});
    } else {
      getName(
        value.type === EntityType.ContactType.Inventory
          ? Endpoints.HiringAuthorities
          : Endpoints.Names,
        value
      );
      setValue('isContact', value.type === EntityType.ContactType.Contact);
    }

    handleComboChange(name, value);
  };

  const hiringAuthorityComboProps =
    type === EntityType.Joborder
      ? {
          placeholder: 'Hiring Authority *',
          url: `${Endpoints.JobOrders}/${joborderId}/${Endpoints.AvailableHiringAuthorities}`,
          noOptionsText: 'No Hiring Authorities found for this Job Order',
          onSelect: handleComboChange,
          getOptionLabel: option => option.full_name,
          getOptionSelected: Selectors.byId,
          renderOption: OptionRenderers.displayHiringAuthority
        }
      : {
          placeholder: 'Search a Hiring Authority by name or email *',
          url: `${Endpoints.Search}`,
          displayKey: 'full_name',
          typeahead: true,
          typeaheadLimit: 25,
          typeaheadParams: {
            entityType: 'hiringAuthorityOrName'
          },
          groupBy: option => option.typeTitle,
          onSelect: handleHASelectContact,
          renderOption: OptionRenderers.displayHiringAuthority
        };

  const hasSpecialtyId = comboValues?.hiring_authority_id?.specialty_id;
  const isTypeInventoryOrHiringAuthority =
    comboValues.hiring_authority_id?.type === EntityType.ContactType.Inventory;
  const isTypeContact = comboValues.hiring_authority_id?.type === EntityType.ContactType.Contact;

  return (
    <Box style={globalStyles.inputMinSpacing}>
      <AutocompleteSelect
        name="hiring_authority_id"
        selectedValue={comboValues.hiring_authority_id}
        error={!!errors.hiring_authority_id}
        errorText={errors.hiring_authority_id && errors.hiring_authority_id.message}
        {...hiringAuthorityComboProps}
      />
      {isLoading && <CircularProgress size={24} />}
      {!isLoading && !isTypeContact && hasSpecialtyId && (
        <>
          {Renderers.displayBasicInfoEntity(comboValues.hiring_authority_id)}
          {Renderers.externalLink(
            EntityRoutes.HiringAuthorityProfile.replace(':id', comboValues.hiring_authority_id.id),
            'View Hiring Authority'
          )}
        </>
      )}
      {!isLoading && isTypeInventoryOrHiringAuthority && !hasSpecialtyId && (
        <HiringAuthoritySpecialtyForm specialtyId={hasSpecialtyId} />
      )}
      {!isLoading && isTypeContact && (
        <>
          <p>Register the required data to add this Hiring Authority</p>
          <HiringAuthorityForm initialValues={comboValues} />
        </>
      )}
    </Box>
  );
};

HiringAuthoritySelectForm.defaultProps = {
  joborderId: null,
  isFromProfile: false
};

export default HiringAuthoritySelectForm;
