import { EntityRoutes } from 'routes/constants';
import { OPT_OUT_COLUMN } from 'UI/constants/sharedColumns';

export const PROSPECTS_COLUMNS = [
  {
    name: 'activityLogTypeTitle',
    label: 'Type',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'createdAt',
    label: 'Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'nameName',
    label: 'Prospect Reached',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'nameStatusTitle',
    label: 'Status',
    options: {
      sort: true,
      display: true
    }
  },
  OPT_OUT_COLUMN,
  {
    name: 'nameEmailVerdict',
    label: 'Email Validation',
    options: {
      sort: false,
      display: true,
      renderer: 'emailStatus'
    }
  },
  {
    name: 'nameEmail',
    label: 'Email',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'nameTitle',
    label: 'Title',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'companyName',
    label: 'Company',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'industryTitle',
    label: 'Industry',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'specialtyTitle',
    label: 'Specialty',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'subspecialtyTitle',
    label: 'Subspecialty',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'countrySlug',
    label: 'Country',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'stateTitle',
    label: 'State',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'cityTitle',
    label: 'City',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'activityOutcome',
    label: 'Outcome',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'activityOutcomeComments',
    label: 'Comments',
    options: {
      sort: true,
      display: true
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'createdAt',
  direction: 'desc'
};
