import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  referenceName: {
    marginRight: theme.spacing(1)
  },
  moreButton: {
    right: 0
  }
}));
