// @flow
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { useGlobalStyles } from 'GlobalStyles';
import useDebounce from 'hooks/debounce';
import useUnload from 'hooks/unload';
import { useCheckEmail } from 'hooks/useCheckEmail';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import { DraftsProviderContext, InitialDraft, milisecondsToSave } from 'providers/DraftsProvider';
import queryString from 'query-string';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import { userHasRole, userHasRoles } from 'services/Authorization';
import { ContactIncludes } from 'services/includes';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { FPCard, FPCardContent } from 'UI/components/atoms/FPCard';
import FPIcon from 'UI/components/atoms/FPIcon';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import {
  DuplicateCandidateOverview,
  DuplicateCandidateSkeleton
} from 'UI/components/molecules/DuplicateCandidateOverview';
import FooterActionsControlsLegacy from 'UI/components/molecules/FooterActionsControlsLegacy';
import CandidateForm from 'UI/components/organisms/CandidateForm';
import { FormFieldsMap, FormFieldsMapForHA } from 'UI/components/organisms/CandidateForm/fields';
import CandidateSheet from 'UI/components/organisms/CandidateSheet';
import { FormFieldsMap as SheetFormFieldsMap } from 'UI/components/organisms/CandidateSheet/fields';
import FileManager from 'UI/components/organisms/FileManager';
import {
  formatPhoneNumbers,
  PHONE_FORMAT_MODES
} from 'UI/components/organisms/PhoneNumbersForm/utils';
import {
  DefaultNewCandidateFileCategories,
  DELAY_BOUNCE_TIME,
  drawerAnchor,
  FORTPAC_SUPPORT_LINK,
  waitingTimeBeforeRedirect
} from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import {
  CandidateEntity,
  ContactEntity,
  ContactRole,
  EntityType,
  HiringAuthorityEntity
} from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { SeverityBySimilarity } from 'UI/constants/status';
import { SvgContentPaperEdit, SvgPaperWrite, SvgWarning } from 'UI/res';
import { getErrorMessage, nestTernary, REQUIRED_VALIDATION } from 'UI/utils';
import { decryptId } from 'UI/utils/encrypt';
import { preloadFromBackend, prepareFormToSubmit, selectValidation } from 'UI/utils/forms';
import { OptionRenderers, Selectors } from 'UI/utils/renderers';

import { CandidateDataSheetDrawer } from './CandidateDataSheetDrawer';
import type { NewCandidateProps } from './index';
import { useFeatureFlags } from './NewCandidateContent.hooks';
import { useStyles } from './styles';

const DELETE_API_VERSION = 1;
const { globalSearchPerson } = OptionRenderers;

type Props = NewCandidateProps & {
  isFromProfile: boolean,
  hiringId?: string,
  contactId?: string,
  setSelectedDraft: () => void
};

const NewCandidateContent = ({
  history,
  location,
  isFromProfile,
  hiringId,
  contactId,
  setSelectedDraft
}: Props) => {
  const { canSaveAsDraft, canExecuteCandidateAssignmentSheet } = useFeatureFlags();
  const {
    deleteDraftById,
    draft,
    drafts,
    hasRequiredFieldsForDraft,
    saveDraft,
    saveOnCancel,
    setDraft,
    updateDraft,
    updateLocalStorageDrafts
  } = useContext(DraftsProviderContext);
  const dispatch = useDispatch();
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const isUserCoach = userHasRole(Roles.Coach);
  const isOpsOrDc = userHasRoles([Roles.Operations, Roles.DataCoordinator]);
  const dataSheet = useRef(null);

  const [isFromDirectory, setIsFromDirectory] = useState(isFromProfile);
  const [attachments, setAttachments] = useState([]);
  const [isBluesheetOpen, setIsBluesheetOpen] = useState(false);

  const [uiState, setUiState] = useState({
    hasLoaded: !isFromProfile,
    isLoading: false,
    isReseting: false,
    isSaving: false,
    isSuccess: false
  });

  const preselectedCompany = useMemo(() => {
    try {
      const { company: encryptedId, cName } = queryString.parse(location.search);
      const companyId = encryptedId && decryptId(encryptedId, true)[0];
      return companyId
        ? {
            id: companyId,
            name: cName
          }
        : null;
    } catch (error) {
      dispatch(
        showAlert({
          severity: 'error',
          title: 'Invalid Company Reference.',
          body: error.message
        })
      );
      return null;
    }
  }, [dispatch, location.search]);

  const buildProfileData = useMemo(() => {
    if (!isFromProfile) return null;

    return hiringId
      ? { id: hiringId, type: HiringAuthorityEntity.id, role_id: ContactRole.HiringAuthority }
      : { id: contactId, type: ContactEntity.id, role_id: ContactRole.Name };
  }, [contactId, hiringId, isFromProfile]);

  const defaultValues = draft?.id
    ? {
        ...draft.formData,
        phones: formatPhoneNumbers(draft.formData, PHONE_FORMAT_MODES.preload)
      }
    : {
        [FormFieldsMap.FromContact.key]: buildProfileData,
        [FormFieldsMap.Company.key]: preselectedCompany,
        [FormFieldsMap.Email.key]: ''
      };
  const form = useForm({
    defaultValues
  });

  const { register, reset, setValue, handleSubmit, watch, getValues } = form;
  const { email, personalEmail } = watch([
    FormFieldsMap.Email.key,
    FormFieldsMap.PersonalEmail.key
  ]);
  const debouncedEmail = useDebounce(email ?? '', DELAY_BOUNCE_TIME).trim();
  const debouncedOtherEmail = useDebounce(personalEmail ?? '', DELAY_BOUNCE_TIME).trim();

  const selectedRecruiter = watch(FormFieldsMap.Recruiter.key);
  const selectedContactOrHA = watch(FormFieldsMap.FromContact.key);

  const [bluesheet, setBluesheet] = useState(
    draft?.id && !canExecuteCandidateAssignmentSheet ? draft.sheetData : null
  );
  const initialFiles = draft?.id ? draft.attachments : attachments;

  const shouldCheckContacts = !isFromDirectory && !draft.isFromDirectory;

  const { validation: emailVerification, initialData } = useCheckEmail({
    email: debouncedEmail,
    shouldCheckContacts,
    hasEmail: !!email
  });

  const { validation: otherEmailVerification } = useCheckEmail({
    email: debouncedOtherEmail,
    shouldCheckContacts,
    hasEmail: !!personalEmail
  });

  const emailValidation = selectValidation(
    { ...emailVerification, email: debouncedEmail },
    { ...otherEmailVerification, email: debouncedOtherEmail },
    initialData
  );
  const { registeredData: registeredEmail } = emailValidation;

  const canBeSavedAsDraft =
    hasRequiredFieldsForDraft && !emailValidation.isRegistered && !emailValidation.isValidating;

  const shouldDisableSaveButton =
    uiState.isLoading || uiState.isSaving || uiState.isSuccess || emailValidation.isValidating;
  const isSaveDisabled = isOpsOrDc
    ? shouldDisableSaveButton
    : emailValidation.isRegistered || shouldDisableSaveButton;

  useEffect(() => {
    isUserCoach && register({ name: FormFieldsMap.Recruiter.key });
  }, [register, isUserCoach]);

  useEffect(() => {
    register({ name: FormFieldsMap.FromContact.key });
  }, [register]);

  const handleUpdateDraft = useCallback(
    () => updateDraft({ ...draft, formData: getValues() }),
    [draft, getValues, updateDraft]
  );

  const handleSetDraftBluesheet = useCallback(
    sheetData => {
      setDraft(prev => ({ ...prev, sheetData }));
      setBluesheet(sheetData);
    },
    [setDraft, setBluesheet]
  );

  const handleValuesChanged = useCallback(
    values => setDraft(prev => ({ ...prev, formData: values })),
    [setDraft]
  );

  const saveOrUpdate = useCallback(() => {
    if (canBeSavedAsDraft && !uiState.isSuccess) {
      const currentFormValues = getValues();
      draft.id
        ? handleUpdateDraft(draft)
        : saveDraft({ shouldConfirm: false, values: currentFormValues });
    }
  }, [canBeSavedAsDraft, uiState.isSuccess, getValues, draft, handleUpdateDraft, saveDraft]);

  useUnload(e => {
    e.preventDefault();
    saveOrUpdate();
  });

  useEffect(() => {
    const interval = setInterval(() => {
      saveOrUpdate();
    }, milisecondsToSave);
    return () => {
      clearInterval(interval);
    };
  }, [saveOrUpdate]);

  const preloadContactInfo = useCallback(
    (nameInfo: any, formFieldsMap) => {
      const preloadedValues = preloadFromBackend(nameInfo, formFieldsMap);
      reset(preloadedValues);

      setValue(FormFieldsMap.Specialty.key, preloadedValues[FormFieldsMap.Specialty.key]);
      setValue(FormFieldsMap.Subspecialty.key, preloadedValues[FormFieldsMap.Subspecialty.key]);
      setValue(
        FormFieldsMap.FunctionalTitle.key,
        preloadedValues[FormFieldsMap.FunctionalTitle.key]
      );
      setValue(FormFieldsMap.State.key, preloadedValues[FormFieldsMap.State.key]);
      setValue(FormFieldsMap.City.key, preloadedValues[FormFieldsMap.City.key]);
      setValue(FormFieldsMap.Source.key, preloadedValues[FormFieldsMap.Source.key]);
      setValue(FormFieldsMap.Phone.key, preloadedValues[FormFieldsMap.Phone.key]);
      setValue(FormFieldsMap.Mobile.key, preloadedValues[FormFieldsMap.Mobile.key]);
      setValue(FormFieldsMap.FromContact.key, preloadedValues[FormFieldsMap.FromContact.key]);
      setValue(FormFieldsMap.Phones.key, preloadedValues[FormFieldsMap.Phones.key]);

      setUiState(prevState => ({
        ...prevState,
        isLoading: false,
        hasLoaded: true
      }));
    },
    [reset, setValue]
  );

  const fetchSelectedContact = useCallback(
    async contact => {
      if (!contact) return;
      try {
        const isFromContact = contact.role_id === ContactRole.Name;
        setUiState(prevState => ({
          ...prevState,
          isLoading: true,
          hasLoaded: false
        }));
        const url = `${isFromContact ? Endpoints.Names : Endpoints.HiringAuthorities}/${
          contact.id
        }`;

        const endpoint = isFromContact
          ? queryString.stringifyUrl({
              url,
              query: { includes: ContactIncludes }
            })
          : url;
        const config = { apiVersion: 2 };
        const response = await API.get(endpoint, config);
        const { data } = response;

        if (isFromContact) {
          const companyFromContact =
            !Number.isNaN(data.company_id) && data.current_company
              ? { id: Number(data.company_id), name: data.current_company }
              : null;
          data &&
            preloadContactInfo(
              {
                ...data,
                company: preselectedCompany || companyFromContact,
                [FormFieldsMap.FromContact.key]: contact
              },
              FormFieldsMap
            );
        } else {
          data &&
            preloadContactInfo(
              {
                ...data,
                company: preselectedCompany || data.company,
                [FormFieldsMap.FromContact.key]: contact
              },
              FormFieldsMapForHA
            );
        }
      } catch (error) {
        dispatch(
          showAlert({
            severity: 'error',
            title: 'Error',
            body: getErrorMessage(error)
          })
        );
      } finally {
        setUiState(prevState => ({ ...prevState, isLoading: false }));
      }
    },
    [dispatch, preloadContactInfo, preselectedCompany]
  );

  useEffect(() => {
    if (!isFromProfile) return;

    fetchSelectedContact(buildProfileData);
    setDraft(prev => ({ ...prev, isFromDirectory: true }));
  }, [fetchSelectedContact, buildProfileData, isFromProfile, setDraft]);

  const toggleDrawer = (anchor, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
    setIsBluesheetOpen(open);
  };

  const handleBluesheetCompleted = bs => {
    setIsBluesheetOpen(false);
    setValue('bluesheet_completed', true, true);
    setBluesheet(bs);
  };

  useEffect(() => {
    if (canExecuteCandidateAssignmentSheet && draft?.dataSheet?.id) {
      if (!draft?.dataSheet?.progress) {
        setValue('bluesheet_completed', undefined, true);
        return;
      }

      if (draft?.dataSheet?.progress.answered !== draft?.dataSheet?.progress.total) {
        setValue('bluesheet_completed', undefined, true);
        return;
      }

      setValue('bluesheet_completed', true, true);
    }
  }, [draft, canExecuteCandidateAssignmentSheet, setValue]);

  const handleBluesheetClosed = () => {
    isBluesheetOpen && setIsBluesheetOpen(false);

    if (canExecuteCandidateAssignmentSheet && dataSheet.current) {
      setDraft(prev => ({ ...prev, dataSheet: dataSheet.current }));
    }
  };

  const handleFilesChanged = useCallback(
    data => {
      const attachmentsChanged = flatMap(map(data, 'files'));
      setAttachments(attachmentsChanged);
      setDraft(prev => ({ ...prev, attachments: attachmentsChanged }));
    },
    [setDraft]
  );

  const handleCancelClick = () => {
    canBeSavedAsDraft ? saveOnCancel() : history.goBack();
  };

  const onSubmit = async formData => {
    try {
      setUiState(prevState => ({ ...prevState, isSaving: true }));
      const preparedCandidate = prepareFormToSubmit(formData, FormFieldsMap);
      const preparedSheet = canExecuteCandidateAssignmentSheet
        ? { dataSheetId: draft.dataSheet.id }
        : { blueSheet: prepareFormToSubmit(bluesheet, SheetFormFieldsMap) };
      const preparePhoneNumbers = formatPhoneNumbers(formData, PHONE_FORMAT_MODES.submit);
      const candidateData = {
        ...preparedCandidate,
        ...preparedSheet,
        files: attachments.map(att => att.id),
        phones: preparePhoneNumbers
      };

      const contactOrHiringAuthorityId = selectedContactOrHA?.id;
      const isFromContact = selectedContactOrHA?.role_id === ContactRole.Name;
      isFromContact && (candidateData.hiringId = null);

      const urlString = isFromContact
        ? `${Endpoints.Candidates}/fromName/:id`.replace(':id', contactOrHiringAuthorityId)
        : `${Endpoints.Candidates}`;

      const response = await API.post(urlString, candidateData);

      if (response.data && response.status !== HTTPStatusCodes.Created) {
        dispatch(
          showAlert({
            severity: 'error',
            title: 'Candidate',
            autoHideDuration: 8000,
            body: getErrorMessage(response)
          })
        );

        setUiState(prevState => ({
          ...prevState,
          isSaving: false
        }));

        return;
      }

      setUiState(prevState => ({
        ...prevState,
        isSuccess: true
      }));

      dispatch(
        showAlert({
          severity: 'success',
          title: strings.candidates.creation.success.title,
          body: strings.candidates.creation.success.body
        })
      );

      if (draft?.id) deleteDraftById(draft?.id);

      setTimeout(() => {
        history.push(EntityRoutes.CandidateProfile.replace(':id', response.data.data.id));
      }, waitingTimeBeforeRedirect);
    } catch (err) {
      setUiState(prevState => ({
        ...prevState,
        isSuccess: false
      }));
      dispatch(
        showAlert({
          severity: 'error',
          title: 'Candidates',
          autoHideDuration: 5000,
          body: getErrorMessage(err)
        })
      );
    } finally {
      setUiState(prevState => ({
        ...prevState,
        isSaving: false
      }));
    }
  };
  const handleValueChange = (name: string, value: any) => setValue(name, value, true);

  const toggleDirectoryOption = () => {
    reset({});
    setIsFromDirectory(!isFromDirectory);
    setDraft({ ...InitialDraft, type: EntityType.Candidate, isFromDirectory: !isFromDirectory });
  };

  const handleContactSelect = async (name: string, value: any) => {
    if (!value) {
      const fromDirectory = true;
      resetForm(fromDirectory);
      return;
    }
    setValue(name, value, true);
    await fetchSelectedContact(value);
  };

  const saveAsDraftButton = draft?.id ? (
    <FPActionButton
      disabled={!canBeSavedAsDraft}
      text={strings.drafts.button.update}
      variant="outlined"
      onClick={handleUpdateDraft}
    />
  ) : (
    <FPActionButton
      disabled={!canBeSavedAsDraft}
      text={strings.drafts.button.save}
      className={classes.saveDraftBtn}
      variant="outlined"
      onClick={() => {
        const currentFormValues = getValues();
        saveDraft({ shouldConfirm: true, values: currentFormValues });
      }}
    />
  );

  const isCandidateFormReady =
    !isFromDirectory || (isFromDirectory && selectedContactOrHA?.id && uiState.hasLoaded);

  const createFromContact = () => {
    const contact = {
      id: registeredEmail.id,
      company: registeredEmail.company,
      email: registeredEmail.email,
      full_name: registeredEmail.fullName,
      role_id: registeredEmail.entity,
      subtitle: registeredEmail.subtitle
    };
    setIsFromDirectory(true);
    setDraft({ ...InitialDraft, type: EntityType.Candidate, isFromDirectory: true });
    setValue(FormFieldsMap.FromContact.key, contact);
    fetchSelectedContact(contact);
  };

  const alertText = {
    [SeverityBySimilarity.Exact]: {
      alert: {
        text: strings.formatString(strings.candidates.creation.duplicateEmail.alert, {
          email: `"${emailValidation.email}"`,
          entity: `as a ${CandidateEntity.singular}`
        }),
        action: (
          <Button
            color="inherit"
            href={FORTPAC_SUPPORT_LINK}
            rel="noopener noreferrer"
            target="_blank"
            variant="outlined"
            classes={{ root: globalClasses.alertActionButton }}
          >
            {strings.shared.app.support}
          </Button>
        )
      }
    },
    [SeverityBySimilarity.Similar]: {
      alert: {
        text: strings.formatString(strings.candidates.creation.similarEmail.alert, {
          email: `"${emailValidation.email}"`
        }),
        action: (
          <Button
            type="button"
            variant="outlined"
            color="inherit"
            disabled={emailValidation.isValidating || false}
            classes={{ root: globalClasses.alertActionButton }}
            onClick={createFromContact}
          >
            {strings.candidates.creation.similarEmail.alertButton}
          </Button>
        )
      }
    }
  };

  const resetForm = (fromDirectory = false) => {
    setUiState(prev => ({ ...prev, isReseting: true }));
    setIsFromDirectory(fromDirectory);
    setDraft({ ...InitialDraft, type: EntityType.Candidate, isFromDirectory: fromDirectory });
    reset({ [FormFieldsMap.Email.key]: '' });
    setAttachments([]);
    setBluesheet(null);
    setSelectedDraft(null);
    setTimeout(() => {
      setUiState(prev => ({ ...prev, isReseting: false }));
    }, 500);
  };

  const handleClickReset = () => resetForm(false);

  const finalHeaderProps =
    strings.candidates.create.candidateDataSheet[
      canExecuteCandidateAssignmentSheet ? 'new' : 'old'
    ];

  const handleSaveCandidateDataSheet = (
    candidateDataSheetID: string,
    progress: {
      answered: number,
      total: number
    }
  ) => {
    dataSheet.current = { id: candidateDataSheetID, progress };

    const draftToUpdate = { ...draft, dataSheet: { id: candidateDataSheetID, progress } };

    const newDrafts = drafts.map(draftItem =>
      draftItem.id === draftToUpdate.id ? draftToUpdate : draftItem
    );
    updateLocalStorageDrafts(newDrafts);
  };

  return (
    <>
      <Prompt
        when={canBeSavedAsDraft}
        message={locationPath => {
          saveOrUpdate();
          locationPath.pathname === EntityRoutes.CandidateCreate && resetForm();
        }}
      />
      <React.Fragment key={drawerAnchor}>
        <FormContext {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FPCard className={globalClasses.newItemsSection}>
              <FPCardContent
                variant="relaxed"
                headerProps={{
                  title: strings.candidates.creation.mainSection,
                  subheader: strings.shared.fields.required,
                  variant: 'section'
                }}
              >
                {!isFromProfile && (
                  <div className={classes.selectContactContainer}>
                    <div>
                      {isFromDirectory ? (
                        <Typography variant="subtitle1">
                          {strings.candidates.creation.selectExistingFromDirectory.message}
                          <FPActionButton
                            text={strings.candidates.creation.selectExistingFromDirectory.link}
                            className={classes.button}
                            onClick={toggleDirectoryOption}
                            variant="text"
                          />
                        </Typography>
                      ) : (
                        <Typography variant="subtitle1">
                          {strings.candidates.creation.selectExisting.message}
                          <FPActionButton
                            text={strings.candidates.creation.selectExisting.link}
                            className={classes.button}
                            onClick={toggleDirectoryOption}
                            variant="text"
                          />
                          {strings.candidates.creation.selectExisting.continueMessage}
                        </Typography>
                      )}
                    </div>
                    <FPActionButton
                      text={strings.formatString(strings.shared.ui.reset, {
                        item: 'Form'
                      })}
                      className={classes.resetButton}
                      onClick={handleClickReset}
                      variant="text"
                    />
                  </div>
                )}

                {emailValidation.severity !== SeverityBySimilarity.None && (
                  <>
                    <Alert
                      data-testid="duplicate-alert"
                      severity={emailValidation.severity}
                      action={alertText[emailValidation.severity].alert.action}
                      icon={
                        <FPIcon
                          component={SvgWarning}
                          onSetColor={palette => palette[emailValidation.severity].light}
                        />
                      }
                    >
                      {alertText[emailValidation.severity].alert.text}
                    </Alert>
                    {emailValidation.isValidating ? (
                      <DuplicateCandidateSkeleton />
                    ) : (
                      <DuplicateCandidateOverview
                        key={registeredEmail.id}
                        data={registeredEmail}
                        handleClickButton={createFromContact}
                      />
                    )}
                    <Divider />
                  </>
                )}

                {isFromDirectory && !isFromProfile && (
                  <div className={classes.chooseExistingContact}>
                    <AutocompleteSelect
                      displayKey="full_name"
                      getOptionSelected={Selectors.byId}
                      groupBy={option => option.typeTitle}
                      name={FormFieldsMap.FromContact.key}
                      onSelect={handleContactSelect}
                      placeholder={
                        strings.candidates.creation.selectExistingFromDirectory.placeholder
                      }
                      renderOption={globalSearchPerson('full_name')}
                      selectedValue={selectedContactOrHA}
                      typeahead
                      typeaheadLimit={20}
                      typeaheadParams={{ entityType: 'hiringAuthorityOrName' }}
                      url={Endpoints.Search}
                    />
                    <Divider />
                  </div>
                )}

                {isCandidateFormReady ? (
                  <CandidateForm
                    onValuesChanged={handleValuesChanged}
                    disabled={!isOpsOrDc && emailValidation.isRegistered}
                    isFromProfile={isFromDirectory}
                  />
                ) : (
                  nestTernary(
                    !uiState.hasLoaded,
                    <Grid container justify="center">
                      <CircularProgress size={30} />
                    </Grid>,
                    null
                  )
                )}
              </FPCardContent>
            </FPCard>

            {!emailValidation.isRegistered && (
              <>
                <FPCard className={globalClasses.newItemsSection}>
                  <FPCardContent
                    variant="relaxed"
                    headerProps={{
                      title: finalHeaderProps.title,
                      subheader: finalHeaderProps.subtitle,
                      variant: 'section'
                    }}
                  >
                    {!uiState.isReseting ? (
                      <>
                        <FPActionButton
                          onClick={toggleDrawer(drawerAnchor, true)}
                          text={bluesheet ? 'Edit' : 'Fill In'}
                          icon={bluesheet ? SvgContentPaperEdit : SvgPaperWrite}
                        />
                        <input
                          ref={register(REQUIRED_VALIDATION)}
                          type="hidden"
                          name="bluesheet_completed"
                        />
                        {!!form.errors.bluesheet_completed && (
                          <div>
                            <FormControl
                              component="fieldset"
                              error={!!form.errors.bluesheet_completed}
                            >
                              <FormHelperText>
                                {form.errors.bluesheet_completed &&
                                  form.errors.bluesheet_completed.message}
                              </FormHelperText>
                            </FormControl>
                          </div>
                        )}
                      </>
                    ) : (
                      <Grid container justify="center">
                        <CircularProgress size={30} />
                      </Grid>
                    )}
                  </FPCardContent>
                </FPCard>

                <FPCard className={globalClasses.newItemsSection}>
                  <FPCardContent
                    variant="relaxed"
                    headerProps={{
                      title: strings.fileManager.newProfile.sectionTitle,
                      subheader: strings.formatString(
                        strings.fileManager.newProfile.sectionSubtitle,
                        {
                          categorySuggested: 'resume'
                        }
                      ),
                      variant: 'section'
                    }}
                  >
                    {!uiState.isReseting ? (
                      <FileManager
                        apiVersionForDeleting={DELETE_API_VERSION}
                        defaultCategories={DefaultNewCandidateFileCategories}
                        filesEndpoint={Endpoints.Files}
                        initialFiles={initialFiles}
                        isNewEntity
                        module={CandidateEntity.module}
                        onAttachmentsChanged={handleFilesChanged}
                        showItemDate
                        showItemMenu
                        viewItemOnClick
                      />
                    ) : (
                      <Grid container justify="center">
                        <CircularProgress size={30} />
                      </Grid>
                    )}
                  </FPCardContent>
                </FPCard>
              </>
            )}

            {isUserCoach && (
              <FPCard className={globalClasses.newItemsSection}>
                <FPCardContent
                  variant="relaxed"
                  headerProps={{
                    title: strings.candidates.creation.assignRecruiter.title,
                    subheader: strings.candidates.creation.assignRecruiter.subtitle,
                    variant: 'section'
                  }}
                >
                  <div className="inputContainer">
                    <AutocompleteSelect
                      name={FormFieldsMap.Recruiter.key}
                      selectedValue={selectedRecruiter}
                      placeholder={strings.candidates.creation.assignRecruiter.placeholder}
                      displayKey="full_name"
                      url={`${Endpoints.Recruiters}/myTeam`}
                      onSelect={handleValueChange}
                    />
                  </div>
                </FPCardContent>
              </FPCard>
            )}
            <div className={globalClasses.newItemsActions}>
              <FooterActionsControlsLegacy
                secondaryAction={handleCancelClick}
                primaryProps={{
                  isSaving: uiState.isSaving,
                  isSuccess: uiState.isSuccess,
                  disabled: isSaveDisabled
                }}
                customThirdButton={canSaveAsDraft && saveAsDraftButton}
              />
            </div>
          </form>
          {(uiState.isLoading || emailValidation.isValidating) && (
            <Grid container justify="flex-end" data-testid="loading-and-checking">
              <CircularProgress size={30} />
            </Grid>
          )}
        </FormContext>
        <Drawer open={isBluesheetOpen} onClose={toggleDrawer(drawerAnchor, false)}>
          {!canExecuteCandidateAssignmentSheet && (
            <div role="presentation">
              <CandidateSheet
                drawerTitle={finalHeaderProps.title}
                onBluesheetCompleted={handleBluesheetCompleted}
                onBluesheetClosed={handleBluesheetClosed}
                isReadOnly={false}
                bluesheet={bluesheet}
                onBluesheetChanged={handleSetDraftBluesheet}
              />
            </div>
          )}
          {canExecuteCandidateAssignmentSheet && (
            <CandidateDataSheetDrawer
              drawerTitle={finalHeaderProps.title}
              onClose={handleBluesheetClosed}
              onSaveCandidateDataSheet={handleSaveCandidateDataSheet}
              candidateDataSheetId={draft?.dataSheet?.id}
              draftData={draft?.sheetData}
            />
          )}
        </Drawer>
      </React.Fragment>
    </>
  );
};

export default NewCandidateContent;
