// @flow
import React from 'react';
import { SimplifiedTable } from 'features/command-center/';
import strings from 'strings';
import { Endpoints } from 'UI/constants/endpoints';

import {
  COLUMNS,
  INLINE_FILTERS,
  ORDER_BY_OPTIONS,
  VIRTUAL_PROPS
} from './PlacementsWithTodayStartDate.constants';

const COPIES = strings.commandCenter.activity.placementsWithTodayStartDate;
const ENDPOINT = Endpoints.Candidates; // TODO: adjust this endpoint

export const PlacementsWithTodayStartDate = () => {
  return (
    <SimplifiedTable
      columns={COLUMNS}
      endpoint={ENDPOINT}
      inlineFilters={INLINE_FILTERS}
      header={COPIES.title}
      orderByOptions={ORDER_BY_OPTIONS}
      virtualProps={VIRTUAL_PROPS}
    />
  );
};
