// @flow
import React from 'react';
import { Tooltip } from '@material-ui/core';
import ColorIndicator from 'UI/components/atoms/ColorIndicator';
/** Assets */
import MapGroupedMarker from 'UI/components/atoms/MapGroupedMarker';
import { MarkerCompanyClass, MarkerTypeClass } from 'UI/constants/defaults';
import { EntityType } from 'UI/constants/entityTypes';
import { nestTernary } from 'UI/utils';

import { useStyles } from './styles';

type MapMarkerProps = {
  isDigActive?: boolean,
  onClick?: (info: any) => void,
  entityType?: string,
  info: any
};

const MapMarker = ({ isDigActive, onClick, info, entityType }: MapMarkerProps) => {
  const classes = useStyles();

  const mapMarkerClass =
    entityType !== EntityType.Company
      ? MarkerTypeClass[info.type_detail?.id]
      : MarkerCompanyClass[info.type_detail?.id];

  const handleClick = () => onClick && onClick(info);

  const mapMarkerNode = {
    inventory: (
      <Tooltip title={getTooltipText(info, entityType)} arrow placement="top">
        <div>
          <ColorIndicator
            size={18}
            onClick={handleClick}
            customClass={`map-marker ${mapMarkerClass} ${classes.hvrPulse}`}
          />
        </div>
      </Tooltip>
    ),
    digGroup: (
      <MapGroupedMarker groupedData={info.values} onClick={onClick} titleField="full_name" />
    ),
    digSingle: info.values && (
      <ColorIndicator
        color={info.values[0].color}
        size={42}
        onClick={() => onClick && onClick(info.values[0])}
      />
    )
  };

  const singleItem = info?.values?.length === 1;

  return isDigActive
    ? nestTernary(singleItem, mapMarkerNode.digSingle, mapMarkerNode.digGroup)
    : mapMarkerNode.inventory;
};

const getTooltipText = (info, entityType) => {
  const tooltipText = entityType !== EntityType.Company ? info.title : info.name;

  return tooltipText;
};

MapMarker.defaultProps = {
  isDigActive: true,
  onClick: undefined,
  entityType: undefined
};

export default MapMarker;
