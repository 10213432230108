// @flow
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Skeleton from '@material-ui/lab/Skeleton';
import type { FPAvatarProps } from 'types/app';
import { avatarMeasures, iconSizes } from 'UI/constants/dimensions';
import { getAvatarColor } from 'UI/utils';

import { avatarDefinitions } from './definitions';
import { AvatarUserColors, defaulAvatarClasses } from './styles';

/** SINGLE AVATAR CUSTOMIZATIONS */

const FPAvatar = ({
  badgeProps,
  children,
  loading,
  mode,
  size,
  userEmail,
  ...rest
}: FPAvatarProps) => {
  const avatarIconMeasures = {
    small: 'sm',
    medium: 'md',
    large: 'lg'
  };

  const skeletonWidth = avatarMeasures[size];
  const avatarColor = getAvatarColor(userEmail);
  const userColorValue = AvatarUserColors[avatarColor];
  const userMode = mode === avatarDefinitions.types.user;
  const avatarIconSize = iconSizes.streamline[avatarIconMeasures[size]];

  const defaultModeClasses = defaulAvatarClasses({ mode })();

  const avatarItem = (
    <Avatar
      classes={{
        root: `avatar-${size}`,
        colorDefault: userMode ? `avatar-${userColorValue}` : defaultModeClasses.colorDefault
      }}
      {...rest}
    >
      {userMode ? children ?? 'N/A' : avatarDefinitions.icons[mode](avatarIconSize)}
    </Avatar>
  );

  const avatarWithBadge = badgeProps ? <Badge {...badgeProps}>{avatarItem} </Badge> : avatarItem;

  return loading ? (
    <Skeleton variant="circle" width={skeletonWidth} height={skeletonWidth} />
  ) : (
    avatarWithBadge
  );
};

FPAvatar.defaultProps = {
  badgeProps: null,
  children: 'N/A',
  loading: false,
  mode: 'user',
  size: 'medium',
  userEmail: ''
};

export default FPAvatar;
