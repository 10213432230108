// @flow
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import { confirm, showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import API from 'services/API';
import { getCurrentUser } from 'services/Authentication';
import { userHasRole } from 'services/Authorization';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import FPTextEditor from 'UI/components/molecules/FPTextEditor';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { tinymceConfig } from 'UI/constants/config';
import { ActivityLogTypes, DateFormats } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { Roles } from 'UI/constants/roles';
import { getErrorMessage, nestTernary, toLocalTime } from 'UI/utils';

import { useStyles } from './styles';

type ActivityNoteType = 'activity' | 'note';

type ActivityNoteFormProps = {
  type: ActivityNoteType,
  endpoint: string,
  selectedItem: any,
  onNoteCompleted: (type: ActivityNoteType, note: any, wasEditing: boolean) => void,
  onNoteClosed: () => void,
  itemName: string,
  initialValues: Object,
  isQuickViewMode: boolean
};

const DRAWER_MODES = {
  activity: 'Activity',
  note: 'Note'
};

const DRAWER_ACTIONS = {
  update: 'Updated',
  create: 'Created'
};

const ActivityNoteForm = ({
  type = 'activity',
  endpoint,
  selectedItem,
  onNoteCompleted,
  onNoteClosed,
  itemName,
  initialValues,
  isQuickViewMode
}: ActivityNoteFormProps) => {
  const editorRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = getCurrentUser();
  const isOperationsUser = userHasRole(Roles.Operations, currentUser);

  const [finalItem, setFinalItem] = useState(selectedItem);
  const [activityType, setActivityType] = useState(finalItem?.activityLogType);
  const isActivityMode = type === 'activity';
  const drawerMode = isActivityMode ? DRAWER_MODES.activity : DRAWER_MODES.note;
  const isEditing = !!finalItem?.id;

  const canEdit = isActivityMode
    ? nestTernary(isEditing, !finalItem?.created_by_system && isOperationsUser, true)
    : true;

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false,
    isEditing,
    isFormDisabled: !canEdit,
    isReadOnly: false
  });

  const localTime = finalItem?.created_at && toLocalTime(finalItem.created_at);
  const onlyCreationDate = localTime && localTime.format(DateFormats.SimpleDate);
  const onlyCreationTime = localTime && localTime.format(DateFormats.SimpleTime);

  const loadCustomEmailRenderer = useMemo(() => {
    const { id } = activityType || {};
    return id === ActivityLogTypes.Email || id === ActivityLogTypes.BulkEmail;
  }, [activityType]);

  const getFinalEndpoint = useCallback(
    () => `${endpoint}/${isActivityMode ? Endpoints.Activities : Endpoints.Notes}`,
    [endpoint, isActivityMode]
  );

  const { register, errors, handleSubmit, setValue, watch } = useForm(
    selectedItem ? { defaultValues: { ...selectedItem } } : { defaultValues: initialValues }
  );
  const watchers = watch(['body']);

  useEffect(() => {
    if (isActivityMode) {
      register({ name: 'activity_log_type_id' }, { required: 'The type is required' });
    }
    register({ name: 'body' }, { required: 'The note is required' });
  }, [isActivityMode, register, type]);

  useEffect(() => {
    loadCustomEmailRenderer &&
      selectedItem?.id &&
      (async () => {
        const finalEndpoint = getFinalEndpoint();
        const response = await API.get(`${finalEndpoint}/${selectedItem.id}`);
        if (response.status === HTTPStatusCodes.Ok) {
          setFinalItem(prev => ({ ...prev, ...response.data }));
        }
      })();
  }, [getFinalEndpoint, endpoint, selectedItem, loadCustomEmailRenderer]);

  const handleTypeChange = (name?: string, value: any) => {
    setActivityType(value);
    setValue('activity_log_type_id', value?.id, true);
  };

  const handleEditorChange = editorValue => setValue('body', editorValue, true);

  const saveForm = async formData => {
    setUiState(prevState => ({ ...prevState, isSaving: true }));
    await saveActivityNote(formData);
  };

  const onSubmit = async (formData: any) => {
    if (!selectedItem && !isOperationsUser && isActivityMode) {
      dispatch(
        confirm({
          severity: 'warning',
          title: strings.inventoryProfiles.sections.tabs.activityNote.activityActionText,
          message: strings.inventoryProfiles.sections.tabs.activityNote.drawer.alert,
          cancelButtonText: strings.shared.ui.cancel,
          confirmButtonText:
            strings.inventoryProfiles.sections.tabs.activityNote.drawer.confirmButton,
          onConfirm: async ok => {
            if (!ok) return;
            saveForm(formData);
          }
        })
      );
    } else {
      saveForm(formData);
    }
  };

  const saveActivityNote = async (activityNote: any) => {
    try {
      const finalEndpoint = getFinalEndpoint();
      const response = !uiState.isEditing
        ? await API.post(finalEndpoint, activityNote)
        : await API.put(`${finalEndpoint}/${finalItem.id}`, activityNote);

      if (response.data) {
        dispatch(
          showAlert({
            severity: 'success',
            title: strings.formatString(
              strings.inventoryProfiles.sections.tabs.activityNote.creationAction,
              {
                section: drawerMode,
                action: uiState.isEditing ? DRAWER_ACTIONS.update : DRAWER_ACTIONS.create
              }
            ),
            body: itemName
          })
        );

        onNoteCompleted(
          type,
          !uiState.isEditing ? response.data.data : response.data,
          uiState.isEditing
        );
      }
    } catch (error) {
      dispatch(
        showAlert({
          severity: 'error',
          title: 'Error',
          body: getErrorMessage(error)
        })
      );
      setUiState(prevState => ({
        ...prevState,
        isSuccess: false,
        isSaving: false
      }));
    }
  };

  const previewRenderers = {
    [ActivityLogTypes.BulkEmail]: (
      <div
        className={classes.emailBodyRenderer}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: finalItem?.contextualData?.bulkEmailPreview || finalItem?.body
        }}
      />
    ),
    [ActivityLogTypes.Email]: (
      <iframe
        data-testid="bulk-email-frame"
        title="htmlFrame"
        className={classes.emailBodyRenderer}
        srcDoc={finalItem?.contextualData?.mailBody || finalItem?.body}
      />
    )
  };

  const initEditor = (e, editor) => {
    editorRef.current = editor;
  };

  const drawerCopies = {
    button: isQuickViewMode
      ? strings.inventoryProfiles.sections.tabs.activityNote.drawer.backAction
      : strings.shared.ui.close,
    title: isActivityMode
      ? strings.inventoryProfiles.sections.tabs.activityNote.drawer.title.activity
      : strings.inventoryProfiles.sections.tabs.activityNote.drawer.title.note
  };

  return (
    <DrawerContentLayout
      onSubmit={handleSubmit(onSubmit)}
      onClose={onNoteClosed}
      uiState={uiState}
      secondaryButtonText={drawerCopies.button}
      title={drawerCopies.title}
    >
      <div className={classes.root}>
        <Box mt={2} mb={3}>
          {isActivityMode ? (
            <AutocompleteSelect
              name="type"
              placeholder="Type *"
              error={!!errors.activity_log_type_id}
              errorText={errors.activity_log_type_id && errors.activity_log_type_id.message}
              value={activityType}
              onSelect={handleTypeChange}
              url={Endpoints.ActivityTypes}
              disabled={uiState.isFormDisabled}
            />
          ) : (
            <TextBox
              name="title"
              label="Title *"
              error={!!errors.title}
              errorText={errors.title && errors.title.message}
              inputRef={register({ required: 'The title is required' })}
              disabled={uiState.isFormDisabled}
            />
          )}
        </Box>
        {(uiState.isFormDisabled &&
          loadCustomEmailRenderer &&
          previewRenderers[activityType?.id]) || (
          <FPTextEditor
            onInit={initEditor}
            config={tinymceConfig}
            value={watchers.body}
            onChange={handleEditorChange}
            disabled={uiState.isFormDisabled}
            error={!!errors.body}
            errorText={errors.body && errors.body.message}
          />
        )}
        {finalItem?.user && (
          <FormHelperText className={classes.helper}>
            Created by{' '}
            <b>
              {finalItem?.user.initials} - {finalItem?.user.email}
            </b>
            {onlyCreationDate && (
              <>
                on <b>{onlyCreationDate}</b> at <b>{onlyCreationTime}</b>
              </>
            )}
          </FormHelperText>
        )}
      </div>
    </DrawerContentLayout>
  );
};

export default ActivityNoteForm;
