// @flow

/** Custom hook to set state with a callback function */

import { useCallback, useEffect, useRef, useState } from 'react';

const useStateWithCallback = initialState => {
  const [state, setState] = useState(initialState);
  const callbackRef = useRef(null);

  const setStateCallback = useCallback((newState, callback) => {
    callbackRef.current = callback;
    setState(newState);
  }, []);

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(state);
      callbackRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
};

export default useStateWithCallback;
