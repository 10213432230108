import { Permissions, userHasPermission } from 'services/Authorization';
import CompaniesTab from 'UI/components/organisms/inventoryProfiles/CompaniesTab';
import {
  CompaniesVirtualProps,
  CompanyColumns
} from 'UI/components/organisms/inventoryProfiles/CompaniesTab/columns';
import FeeAgreementTab from 'UI/components/organisms/inventoryProfiles/FeeAgreementTab';
import { feeAgreementHiringAuthorityProfileColumns } from 'UI/components/organisms/inventoryProfiles/FeeAgreementTab/columns';
import JobOrdersTab from 'UI/components/organisms/inventoryProfiles/JobOrdersTab';
import { HiringAuthorityProfileJobOrderColumns } from 'UI/components/organisms/inventoryProfiles/JobOrdersTab/hiringAuthoritiesJOcolumns';
import PlacementsTab from 'UI/components/organisms/inventoryProfiles/PlacementsTab';
import { hiringAuthorityProfilePlacementsColumns } from 'UI/components/organisms/inventoryProfiles/PlacementsTab/columns';
import SendoutsTab from 'UI/components/organisms/inventoryProfiles/SendoutsTab';
import {
  HIRING_AUTHORITY_SENDOUTS_COLUMNS,
  SENDOUTS_RENDERERS,
  SENDOUTS_VIRTUAL_PROPS
} from 'UI/components/organisms/inventoryProfiles/SendoutsTab/columns';
import { Endpoints } from 'UI/constants/endpoints';
import { CompanyEntity, EntityType, PaymentMode, TabKeys } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { currencyFormatter, filterColumnsByFeatureFlag, hasFeatureFlag } from 'UI/utils';
import { formatResponseBuilder } from 'UI/utils/inventory';

const extraRenderers = {
  fee: value => {
    const feePercentage = `${value?.percentage ? `${value?.percentage}%` : ''}`;
    const isFlatPayment = value?.payment?.scheme?.id === PaymentMode.Flat;
    const feeAgreementValue = isFlatPayment ? currencyFormatter(value?.flatAmount) : feePercentage;

    return feeAgreementValue;
  }
};
const JOB_ORDER_KEYWORDS_DEFINITION = [
  {
    entry: 'title',
    valuesToAttach: [
      {
        name: 'title',
        value: 'title'
      },
      { name: 'id', value: 'id' }
    ]
  },
  {
    entry: 'specialty',
    valuesToAttach: [
      { name: 'industry', value: 'industry' },
      { name: 'title', path: 'specialty.title' }
    ]
  },
  {
    entry: 'jobOrderTypeTitle',
    valuesToAttach: [
      {
        name: 'title',
        path: 'jobOrderType.title'
      },
      {
        name: 'id',
        path: 'jobOrderType.id'
      }
    ]
  },
  {
    entry: 'compensation',
    valuesToAttach: [
      { name: 'maximum_compensation', value: 'maximumCompensation' },
      { name: 'minimum_compensation', value: 'minimumCompensation' },
      { name: 'good_compensation', value: 'goodCompensation' }
    ]
  },
  {
    entry: 'address',
    valuesToAttach: [
      { name: 'city', value: 'city' },
      { name: 'state', value: 'state' }
    ]
  },
  {
    entry: 'statusTitle',
    valuesToAttach: [
      {
        name: 'title',
        path: 'status.title'
      },
      {
        name: 'id',
        path: 'status.id'
      }
    ]
  },
  {
    entry: 'recruiterName',
    valuesToAttach: [
      {
        path: 'recruiter.name'
      }
    ]
  },
  {
    entry: 'companyName',
    valuesToAttach: [
      { name: 'id', path: 'company.id' },
      { name: 'name', path: 'company.name' }
    ]
  }
];

const jobOrderColumnsWithFeatureFlag = [
  {
    featureFlag: FeatureFlags.JobOrderColumnsHAProfile,
    columns: ['recruiterName', 'lastActivityDate', 'jobOrderTypeTitle', 'statusTitle']
  }
];

const jobOrderColumns = filterColumnsByFeatureFlag(
  HiringAuthorityProfileJobOrderColumns,
  jobOrderColumnsWithFeatureFlag
);

const handleJobOrderResponseChange = response =>
  formatResponseBuilder(response)(JOB_ORDER_KEYWORDS_DEFINITION);

const PLACEMENTS_KEYWORDS_DEFINITION = [
  {
    entry: 'start_date',
    valuesToAttach: [
      {
        path: 'startDate'
      }
    ]
  },
  {
    entry: 'fee_amount',
    valuesToAttach: [{ path: 'feeAmount' }]
  },
  {
    entry: 'company_recruiter',
    valuesToAttach: [
      {
        path: 'companyRecruiters.fullNames'
      }
    ]
  },
  {
    entry: 'candidate_recruiter',
    valuesToAttach: [
      {
        path: 'candidateRecruiters.fullNames'
      }
    ]
  }
];

const handlePlacementsResponseChange = response =>
  formatResponseBuilder(response)(PLACEMENTS_KEYWORDS_DEFINITION);

export const hiringAuthorityTabs = [
  {
    id: TabKeys.Companies,
    label: CompanyEntity.title,
    endpoint: Endpoints.Companies,
    Component: CompaniesTab,
    props: {
      initialColumns: CompanyColumns,
      DataTableProps: {
        apiVersion: 2,
        virtualProps: CompaniesVirtualProps
      }
    }
  },
  {
    id: TabKeys.Sendouts,
    label: 'Sendouts',
    endpoint: Endpoints.Sendouts,
    Component: SendoutsTab,
    visible: hasFeatureFlag(FeatureFlags.NewHiringAuthorityTabs),
    props: {
      extraRenderers: SENDOUTS_RENDERERS,
      DataTableProps: {
        apiVersion: 1,
        includes: 'interviews',
        virtualProps: SENDOUTS_VIRTUAL_PROPS
      },
      initialColumns: HIRING_AUTHORITY_SENDOUTS_COLUMNS,
      paramsBuilder: [
        {
          name: 'fields',
          value:
            'id,feeAmount,sentOn,trackingDate,interviewDate,statusId,statusTitle,statusStyle,statusLabel,joId,joTitle,companyId,companyName,caId,caFullName,caAccountableId,caAccountableInitials,caAccountableUserName,joAccountableId,joAccountableInitials,joAccountableUserName,typeId,typeTitle,typeStyle'
        }
      ]
    }
  },
  {
    id: TabKeys.JobOrders,
    label: 'Job Orders',
    endpoint: Endpoints.JobOrders,
    Component: JobOrdersTab,
    visible: hasFeatureFlag(FeatureFlags.HaJobOrderTab),
    props: {
      DataTableProps: {
        apiVersion: 1,
        formatResponse: handleJobOrderResponseChange,
        orderByOptions: { column: 'created_at', direction: 'desc' }
      },
      initialColumns: jobOrderColumns,
      mode: EntityType.HiringAuthority,
      paramsBuilder: [
        {
          name: 'fields',
          value:
            'id,title,lastActivityDate,statusId,statusTitle,statusStyle,statusIsSelectable,positionId,positionTitle,recruiterId,recruiterEmail,recruiterInitials,recruiterName,recruiterCoachId,recruiterCoachEmail,recruiterCoachName,cityId,cityTitle,stateId,stateTitle,stateTitle,specialtyId,specialtyTitle,industryId,industryTitle,maximumCompensation,minimumCompensation,goodCompensation,workTypeId,workTypeTitle,companyId,companyName,createdAt,statusId,statusTitle,recruiterName,jobOrderTypeTitle,jobOrderTypeId'
        }
      ]
    }
  },
  {
    id: TabKeys.Placements,
    label: 'Placements',
    Component: PlacementsTab,
    visible: () =>
      userHasPermission(Permissions.Placements.CanUseModule) &&
      hasFeatureFlag(FeatureFlags.HaPlacementsTab),
    endpoint: Endpoints.Placements,
    props: {
      initialColumns: hiringAuthorityProfilePlacementsColumns,
      profileModule: EntityType.HiringAuthority,
      DataTableProps: {
        formatResponse: handlePlacementsResponseChange
      },
      paramsBuilder: [
        {
          name: 'fields',
          value:
            'id,feeAmount,startDate,statusId,statusTitle,statusStyle,jobOrderId,jobOrderTitle,companyId,companyName,candidateRecruiters,companyRecruiters'
        }
      ]
    }
  },
  {
    id: TabKeys.FeeAgreements,
    label: 'Fee Agreements',
    endpoint: Endpoints.FeeAgreement,
    Component: FeeAgreementTab,
    visible: hasFeatureFlag(FeatureFlags.HaFeeAgreementTab),
    props: {
      initialColumns: feeAgreementHiringAuthorityProfileColumns,
      DataTableProps: {},
      profileModule: EntityType.HiringAuthority,
      extraRenderers,
      paramsBuilder: [
        {
          name: 'fields',
          value:
            'id,feePercentage,guaranteeDays,validatedDate,signedDate,haSignDate,createdAt,validatedDate,statusId,statusName,statusTitle,statusStyle,companyId,companyName,creatorId,creatorUserName,coachId,coachUserName,directorId,directorUserName,regionalId,regionalUserName,paymentSchemeTitle,paymentSchemeId,flatFeeAmount'
        }
      ]
    }
  }
];
