import { EntityRoutes } from 'routes/constants';
import { EntityType } from 'UI/constants/entityTypes';

export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'recruitersName',
    label: 'Recruiters',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'interviewDate',
    label: 'Interview Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'candidateName',
    label: 'Candidate',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'candidateId',
          textKey: 'candidateName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'candidateInterviewDetails',
    label: 'Candidate Debriefing Call Status',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'debriefingCallStatus',
        config: {
          entity: EntityType.Candidate
        }
      }
    }
  },
  {
    name: 'candidateCallTime',
    label: 'Candidate Call',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'companyId',
          textKey: 'companyName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'hiringAuthorityName',
    label: 'Hiring Authority',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.HiringAuthorityProfile,
          idKey: 'hiringAuthorityId',
          textKey: 'hiringAuthorityName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'hiringAuthorityInterviewDetails',
    label: 'Hiring A. Debriefing Call Status',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'debriefingCallStatus',
        config: {
          entity: EntityType.HiringAuthority
        }
      }
    }
  },
  {
    name: 'hiringAuthorityCallTime',
    label: 'Hiring A. Call',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'jobOrderTitle',
    label: 'Job Order',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'jobOrderId',
          textKey: 'jobOrderTitle',
          enableInTabRedirect: true
        }
      }
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'interviewDate',
  direction: 'desc'
};

export const VIRTUAL_PROPS = [
  {
    propName: 'candidateName',
    attributes: ['candidateId', 'candidateName']
  },
  {
    propName: 'hiringAuthorityName',
    attributes: ['hiringAuthorityId', 'hiringAuthorityName']
  },
  {
    propName: 'jobOrderTitle',
    attributes: ['jobOrderId', 'jobOrderTitle']
  },
  {
    propName: 'candidatePhones',
    attributes: ['candidatePhones', 'candidatePhone']
  },
  {
    propName: 'hiringAuthorityPhones',
    attributes: ['hiringAuthorityPhones', 'hiringAuthorityPhone']
  },
  {
    propName: 'candidateInterviewDetails',
    attributes: ['candidateCallStatus', 'candidateCallFiles']
  },
  {
    propName: 'hiringAuthorityInterviewDetails',
    attributes: ['hiringAuthorityCallStatus', 'hiringAuthorityCallFiles']
  },
  {
    propName: 'company',
    attributes: ['companyId', 'companyName']
  }
];
