export const LABEL_COLORS_AVAILABLE = {
  black: 'black',
  blue: 'blue',
  green: 'green',
  purple: 'purple',
  red: 'red',
  yellow: 'yellow'
};

export const POSITIONS = {
  bottom: 'bottom',
  top: 'top'
};

export const SIZES = {
  large: 'large',
  medium: 'medium',
  small: 'small'
};
