export const SETTINGS_GRID_CONFIG = {
  actionBar: {
    headerActions: {
      lg: 6,
      sm: 6,
      xs: 12,
      alignItems: 'center',
      container: true,
      justify: 'flex-end'
    },
    headerWithoutActions: {
      lg: 3,
      sm: 3,
      xs: 12,
      alignItems: 'center',
      container: true,
      justify: 'flex-end'
    }
  },
  titleBar: {
    headerActions: {
      xs: 12,
      sm: 6,
      lg: 6
    },
    headerWithoutActions: {
      xs: 12,
      sm: 9,
      lg: 9
    }
  }
};

export const LAYOUT_BREAKPOINT = `(max-width: 1024px)`;
