import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(1, 2)
  },
  phoneNumbersContainer: {
    display: 'flex',
    flexDirection: 'row'
  }
}));
