// @flow
import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import { useStyles } from './styles';

const LINKS_DIMENSIONS = {
  height: 40,
  width: 135
};
const FIELD_DIMENSION = {
  height: 25,
  width: 90
};
const STATUS_DIMENSION = {
  height: 25,
  width: 60
};
const VIEW_DETAILS_DIMENSION = {
  height: 45,
  width: 45
};

const DuplicateCandidateSkeleton = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.similarCandidateContainer}>
        <div>
          <div className={classes.similarCandidateInfo}>
            <Skeleton
              variant="text"
              height={LINKS_DIMENSIONS.height}
              width={LINKS_DIMENSIONS.width}
            />
            <span className={classes.infoSeparator}>{' / '}</span>
            <Skeleton
              variant="text"
              height={LINKS_DIMENSIONS.height}
              width={LINKS_DIMENSIONS.width}
            />
            <span className={classes.infoSeparator}>{' / '}</span>
            <Skeleton
              variant="text"
              height={FIELD_DIMENSION.height}
              width={FIELD_DIMENSION.width}
            />
            <span className={classes.infoSeparator}>{' / '}</span>
            <Skeleton
              variant="text"
              height={FIELD_DIMENSION.height}
              width={FIELD_DIMENSION.width}
            />
            <span className={classes.infoSeparator}>{' / '}</span>
            <Skeleton
              variant="text"
              height={FIELD_DIMENSION.height}
              width={FIELD_DIMENSION.width}
            />
            <span className={classes.infoSeparator}>{' / '}</span>
            <Skeleton
              variant="text"
              height={FIELD_DIMENSION.height}
              width={FIELD_DIMENSION.width}
            />
          </div>
        </div>
        <Skeleton
          className="mt2"
          variant="circle"
          height={VIEW_DETAILS_DIMENSION.height}
          width={VIEW_DETAILS_DIMENSION.width}
        />
      </div>
      <div className={classes.similarCandidatesInfo}>
        <Skeleton variant="rect" height={STATUS_DIMENSION.height} width={STATUS_DIMENSION.width} />
      </div>
    </>
  );
};

export default DuplicateCandidateSkeleton;
