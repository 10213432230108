// @flow
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import API from 'services/API';
import { sendRequest } from 'services/utils';
import strings from 'strings';
import type { OperationResult } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';

type UrgencyVerificationPayload = {
  verified: boolean,
  details: string,
  activityLogIds: Array<number>
};

export const getActivityLogTypes = async () => {
  const allowedActivityTypesIds = {
    Email: 1,
    Text: 2,
    Call: 3
  };

  try {
    const response = await API.get(Endpoints.ActivityTypes);

    if (response.status !== HTTPStatusCodes.Ok) return [];

    return response.data.filter(({ id }) => Object.values(allowedActivityTypesIds).includes(id));
  } catch (error) {
    return [];
  }
};

export const saveCandidateUrgencyVerification = async (
  candidateId: number,
  payload: UrgencyVerificationPayload
): Promise<OperationResult> =>
  sendRequest(
    Endpoints.UrgencyVerificationCandidates.replace(':id', candidateId),
    'put',
    payload,
    strings.inventory.urgencyVerification.results.candidate
  );

export const saveJobOrderUrgencyVerification = async (
  jobOrderId: number,
  payload: UrgencyVerificationPayload
): Promise<OperationResult> =>
  sendRequest(
    Endpoints.UrgencyVerificationJobOrders.replace(':id', jobOrderId),
    'put',
    payload,
    strings.inventory.urgencyVerification.results.jobOrder
  );

export const getCandidateUrgencyVerification = async (candidateId: string) => {
  try {
    const results = await API.get(
      Endpoints.UrgencyVerificationCandidates.replace(':id', candidateId)
    );
    return results?.data;
  } catch (error) {
    return null;
  }
};

export const getJobOrderUrgencyVerification = async (jobOrderId: string) => {
  try {
    const results = await API.get(
      Endpoints.UrgencyVerificationJobOrders.replace(':id', jobOrderId)
    );
    return results?.data;
  } catch (error) {
    return null;
  }
};
