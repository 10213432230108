import { makeStyles } from '@material-ui/core/styles';
import { componentDimensions } from 'UI/constants/dimensions';
import { flexJustifyEnd } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  datePickerContainer: {
    position: 'relative'
  },
  calendarContainer: {
    position: 'absolute',
    right: 0,
    top: -20,
    zIndex: 101,
    boxShadow: '1px 1px 81px rgba(41,60,74,.18)',
    backgroundColor: theme.palette.brandColors.FPWhite,
    borderRadius: 5
  },
  calendarToolbar: {
    ...flexJustifyEnd,
    borderTop: '1px solid #eff2f7',
    padding: theme.spacing(2)
  },
  defaultEmptyState: {
    maxWidth: componentDimensions.emptyState.width
  },
  maxSubtitle: {
    maxWidth: 370
  },
  minSubtitle: {
    maxWidth: 300
  }
}));

export const styles = {
  datePickerField: {
    cursor: 'pointer'
  }
};
