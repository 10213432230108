import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'UI/res/colors';

const filterstitleHeight = 95; /** TODO Check layout */

export const useStyles = makeStyles(theme => ({
  headerContainer: {
    marginBottom: theme.spacing(2)
  },
  filtersTitleContainer: {
    padding: theme.spacing(7, 2, 0)
  },
  filtersContainer: {
    height: '100%',
    overflow: 'auto'
  },
  mainContainer: {
    ...theme.mainLayout,
    width: '100%'
  },
  activeFiltersContainer: {
    marginBottom: theme.spacing(2)
  },
  datePickerContainer: {
    position: 'relative'
  },
  datePickerCancelButton: {
    marginRight: theme.spacing(2)
  },
  wrapper: {
    display: 'block'
  },
  h2: {
    color: colors.inactive,
    fontSize: 32,
    marginLeft: theme.spacing(1),
    fontWeight: '300 !important'
  },
  dateRangeContainer: {
    position: 'absolute',
    right: 0,
    top: -20,
    zIndex: theme.zIndex.tableDateRangeFilter,
    boxShadow: '1px 1px 81px rgba(41,60,74,.18)',
    backgroundColor: 'white',
    borderRadius: 5
  },
  dateRangeToolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: '1px solid #eff2f7',
    padding: 15
  }
}));

export const styles = {
  datePickerField: {
    cursor: 'pointer'
  },
  sectionContent: {
    display: 'grid',
    gridTemplateRows: `${filterstitleHeight}px 1fr `
  }
};
