import { EntityRoutes } from 'routes/constants';

export const ColumnsDefinitions = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-joborder-status'
        }
      }
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-joborder-type'
        }
      }
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'id',
          textKey: 'title',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'functional_title',
    label: 'Functional Title',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'company_title',
    label: 'Company',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'company_type',
    label: 'Company Type',
    options: {
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-company-type'
        }
      }
    }
  },
  {
    name: 'specialty_title',
    label: 'Specialty: Subspecialty',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'specialty',
    label: 'Specialty',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'industry',
    label: 'Industry',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'subspecialty',
    label: 'Subspecialty',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'location',
    label: 'Location',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'state_name',
    label: 'State',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'city_name',
    label: 'City',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'created_at',
    label: 'Added Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'last_activity_date',
    label: 'Last Activity',
    options: {
      sort: true,
      display: false,
      renderer: 'date'
    }
  },
  {
    name: 'recruiter',
    label: 'Recruiter',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'coach',
    label: 'Team',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'good_compensation',
    label: 'Ideal comp.',
    options: {
      sort: true,
      display: true,
      renderer: 'currency'
    }
  },
  {
    name: 'maximum_compensation',
    label: 'High comp.',
    options: {
      sort: true,
      display: true,
      renderer: 'currency'
    }
  },
  {
    name: 'minimum_compensation',
    label: 'Low comp.',
    options: {
      sort: true,
      display: true,
      renderer: 'currency'
    }
  },
  {
    name: 'work_type',
    label: 'Work preference',
    options: {
      sort: true,
      display: false
    }
  }
];

export const FiltersGroups = [
  {
    id: 'general',
    name: 'Job Order',
    filters: ['statuses', 'types', 'workTypes', 'companyTypes', 'textSearch']
  },
  {
    id: 'industries',
    name: 'Industries',
    filters: ['industries', 'specialties', 'subspecialties', 'positions']
  },
  { id: 'team', name: 'Team', filters: ['coaches', 'recruiters'] },
  {
    id: 'location',
    name: 'Location',
    filters: ['countries', 'states', 'cities', 'cityRadius', 'zips', 'radius']
  }
];

export const VirtualProps = [
  {
    propName: 'title',
    attributes: ['id', 'title']
  },
  {
    propName: 'company_title',
    copyFrom: 'company'
  }
];

export const PeriodColumnBySection = {
  0: 'created_at',
  1: 'last_activity_date'
};

export const CreatedAtFilter = { id: 0, title: 'Added Date', column: 'created_at' };
export const LastActivityDateFilter = {
  id: 1,
  title: 'Last Activity Date',
  column: 'last_activity_date'
};

export const DateFilters = [CreatedAtFilter, LastActivityDateFilter];
