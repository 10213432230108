import { makeStyles } from '@material-ui/core/styles';
import {
  navBarHeight,
  searchBarWrapper,
  sideBarWidth,
  sideBarWidthCollapsed
} from 'UI/constants/dimensions';
import { colors } from 'UI/res';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const styles = {
  menuPaperStyles: {
    width: 150,
    padding: 0,
    right: 10,
    top: 75
  },
  menuStyle: {
    padding: 0
  }
};

const sharedStyles = {
  display: 'flex',
  height: navBarHeight,
  backgroundColor: colors.white,
  alignItems: 'center'
};

export const useStyles = makeStyles(theme => ({
  searchbarWrapper: {
    maxWidth: searchBarWrapper,
    width: '100%'
  },
  wrapper: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
    top: 0,
    left: 0,
    right: 0,
    height: navBarHeight,
    backgroundColor: theme.palette.brandColors.FPWhite,
    alignItems: 'center',
    fontSize: 18,
    justifyContent: 'space-between',
    zIndex: 1001,
    width: '100%',
    margin: 'auto',
    boxShadow: '0px 5px 4px #00000017'
  },
  leftContainer: {
    minWidth: sideBarWidth,
    ...sharedStyles
  },
  logoContainer: {
    ...flexAlignCenterJustifyCenter
  },
  middleContainer: {
    justifyContent: 'flex-start',
    width: '100%',
    paddingLeft: ({ isSmallViewPort }) => (isSmallViewPort ? 'unset' : theme.spacing(8.5)),
    ...sharedStyles
  },
  rightContainer: {
    justifyContent: 'flex-end',
    ...sharedStyles
  },
  userCardWrapper: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  name: {
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamilyContent,
    margin: theme.spacing(0, 1),
    color: theme.palette.text.primary,
    fontSize: 16,
    textTransform: 'capitalize',
    whiteSpace: 'nowrap'
  },
  userCard: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0.5, 1),

    borderRadius: theme.spacing(3),
    margin: theme.spacing(0, 1.5, 0, 0)
  },
  userInfoContainer: {
    display: 'flex',
    position: 'relative',
    marginLeft: theme.spacing(1)
  },
  menu: {
    width: 148,
    height: 48,
    position: 'absolute',
    color: theme.palette.error.main,
    borderRadius: '4px',
    backgroundColor: theme.palette.brandColors.FPWhite,
    bottom: -67,
    padding: '5px 20px',
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: '""',
      backgroundColor: theme.palette.brandColors.FPWhite,
      width: 20,
      height: 20,
      clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
      position: 'absolute',
      top: -11,
      right: 27
    }
  },
  menuLink: {
    color: theme.palette.error.dark,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.error.main,
      cursor: 'pointer'
    }
  },
  gpacUIcon: {
    marginRight: theme.spacing(1)
  },
  fortpacVersion: {
    textAlign: 'center',
    borderTop: `1px solid ${colors.borderColor}`,
    fontSize: 12,
    display: 'block',
    color: '#6A7381',
    cursor: 'unset',
    fontWeight: 400,
    minHeight: 'unset',
    '&:hover': {
      backgroundColor: 'unset'
    },
    '& >span': {
      fontWeight: 300
    }
  },
  burgerMenu: {
    ...flexAlignCenterJustifyCenter,
    width: sideBarWidthCollapsed,
    marginRight: theme.spacing(1)
  },

  fortPacExtension__dotBadge: {
    marginRight: theme.spacing(1.5),
    marginTop: 4
  }
}));
