// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { THEME } from 'GlobalStyles';
import { AttachmentClipIcon, SvgWarning } from 'UI/res/';
import { colors } from 'UI/res/colors';

import FPIcon from '../FPIcon';

import { useStyles } from './styles';

type EmailItemCardProps = {
  bulkId: string,
  formattedDate: string,
  hasAttachments: boolean,
  isActiveCard: boolean,
  onSelectEmail: () => Number,
  previewText: string,
  searchProjectName: string,
  subject: string,
  warning?: string
};

const EmailItemCard = ({
  bulkId,
  formattedDate,
  hasAttachments,
  isActiveCard,
  onSelectEmail,
  previewText,
  searchProjectName,
  subject,
  warning
}: EmailItemCardProps) => {
  const classes = useStyles({ hasAttachments });
  const handleActiveCard = selectedEmailId => () => onSelectEmail && onSelectEmail(selectedEmailId);

  return bulkId ? (
    <Grid
      data-testid="email-item"
      container
      justify="center"
      direction="column"
      wrap="nowrap"
      className={clsx(classes.emailItem, isActiveCard && classes.activeCard)}
      onClick={handleActiveCard(bulkId)}
    >
      <Grid item container justify="space-between">
        <Typography noWrap variant="subtitle1" className={classes.emailSearchProjectName}>
          {searchProjectName}
        </Typography>
        <Typography variant="caption" className={classes.emailDate}>
          {formattedDate}
        </Typography>
      </Grid>
      <Grid item container justify="space-between">
        <Typography noWrap className={classes.previewText} variant="body2">
          {subject}
        </Typography>
        {hasAttachments && <AttachmentClipIcon size={15} fill={colors.darkGrey} />}
      </Grid>
      {!warning && (
        <Typography noWrap className={classes.previewText} variant="body2">
          {previewText}
        </Typography>
      )}
      {warning && (
        <Grid item container alignItems="center" wrap="nowrap" className={classes.warningContainer}>
          <FPIcon size={18} color={THEME.palette.error.main} className={classes.warningIcon}>
            <SvgWarning />
          </FPIcon>
          <Typography>{warning}</Typography>
        </Grid>
      )}
    </Grid>
  ) : null;
};

EmailItemCard.defaultProps = {
  warning: ''
};

export default EmailItemCard;
