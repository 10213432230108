import * as React from 'react';

const SvgWarning = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M23.77 20.57l-10-19A2 2 0 0012 .5a2 2 0 00-1.77 1.07l-10 19a2 2 0 00.06 2A2 2 0 002 23.5h20a2 2 0 001.77-2.93zM11 8.5a1 1 0 012 0v6a1 1 0 01-2 0zM12.05 20a1.53 1.53 0 01-1.52-1.47A1.48 1.48 0 0112 17a1.53 1.53 0 011.52 1.47A1.48 1.48 0 0112.05 20z" />
    </svg>
  );
};

export default SvgWarning;
