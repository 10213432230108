// @flow
import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { capitalizeFirstLetter } from 'UI/utils';

import type {
  IndustryType,
  SpecialtyType,
  SubspecialtyType
} from '../PilIndustries/pilIndustries.types';

const SKELETON_PROPS = {
  'aria-label': 'loading-skeleton',
  height: 16
};

type PilBreadcrumbProps = {
  isLoading: boolean,
  pilResults: IndustryType | SpecialtyType | SubspecialtyType | null,
  type: 'industry' | 'specialty' | 'subspecialty' | null
};

const PilBreadcrumb = ({ isLoading, pilResults, type }: PilBreadcrumbProps) => {
  const {
    industry_title: industry = '',
    specialty_title: specialty = '',
    title = ''
  } = pilResults ?? {};

  const pilBreadcrumb = {
    industry: {
      industry: capitalizeFirstLetter(type ?? ''),
      specialty: null,
      subspecialty: null
    },
    specialty: {
      industry,
      specialty: title,
      subspecialty: null
    },
    subspecialty: {
      industry,
      specialty,
      subspecialty: title
    }
  };

  return (
    <div aria-label="pil breadcrumb">
      {type && (
        <>
          {isLoading ? (
            <Skeleton {...SKELETON_PROPS} width={250} />
          ) : (
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="textPrimary">{pilBreadcrumb[type].industry}</Typography>

              {pilBreadcrumb[type].specialty && (
                <Typography color="textPrimary">{pilBreadcrumb[type].specialty}</Typography>
              )}

              {pilBreadcrumb[type].subspecialty && (
                <Typography color="textPrimary">{pilBreadcrumb[type].subspecialty}</Typography>
              )}
            </Breadcrumbs>
          )}
        </>
      )}
    </div>
  );
};

export default PilBreadcrumb;
