// @flow
import React, { useCallback, useContext } from 'react';
import { UserProviderContext } from 'providers/UserProvider';
import strings from 'strings';
import { TabKeys } from 'UI/constants/entityTypes';
import { SvgNoReferenceChecks } from 'UI/res';

import ProfileTableLayout from '../ProfileTableLayout';

import { useReferenceCheck, useReferenceCheckTable } from './hooks';
import InfoLabel from './InfoLabel';
import ReferenceCheckDrawer from './ReferenceCheckDrawer';

const NO_REFERENCE_CHECKS_MESSAGES = strings.inventoryProfiles.emptyStates.referenceCheck;
const SECTION_NAME = strings.inventoryProfiles.sections.tabs.referenceCheck.sectionName;
const ORDER_BY_OPTIONS = { column: 'created', direction: 'desc' };

type ReferenceCheckTabLayoutProps = {
  candidateId: string,
  candidateName: string,
  recruiter: Object,
  additionalRecruiters: Array<Object>,
  DataTableProps: Object,
  initialColumns: Array<Object>
};

const ReferenceCheckTabLayout = ({
  candidateId,
  candidateName,
  recruiter,
  additionalRecruiters = [],
  DataTableProps,
  initialColumns
}: ReferenceCheckTabLayoutProps) => {
  const [uiState, dispatch] = useReferenceCheck();
  const [currentUser] = useContext(UserProviderContext);
  const {
    tableActions,
    activeItem,
    customDataTableProps,
    canEdit,
    toggleDataRefresh,
    shouldRefreshData,
    isAddNewButtonEnabled
  } = useReferenceCheckTable({
    additionalRecruiters,
    candidateId,
    currentUser,
    recruiterId: recruiter?.id,
    onEditItemClick: dispatch.onEditItemClick,
    onViewItemClick: dispatch.onViewItemClick
  });

  const handleOnSuccess = useCallback(() => {
    dispatch.onSaveSuccess(toggleDataRefresh(true));
  }, [dispatch, toggleDataRefresh]);

  return (
    <>
      <ProfileTableLayout
        actionText={SECTION_NAME}
        finalActions={tableActions}
        hasProfileLoaded={isAddNewButtonEnabled}
        DataTableProps={{ ...DataTableProps, ...customDataTableProps }}
        tabKey={TabKeys.ReferenceCheck}
        initialColumns={initialColumns}
        shouldRefresh={shouldRefreshData}
        onNewItemClick={dispatch.onAddNewItemClick}
        customEmptyState={<SvgNoReferenceChecks />}
        infoLabel={<InfoLabel />}
        orderByOptions={ORDER_BY_OPTIONS}
        {...NO_REFERENCE_CHECKS_MESSAGES}
      />
      <ReferenceCheckDrawer
        mode={uiState.mode}
        referenceId={activeItem.id}
        isOpen={uiState.isDrawerOpen}
        candidateId={candidateId}
        candidateName={candidateName}
        recruiterName={recruiter?.personalInformation?.full_name ?? ''}
        status={activeItem.status}
        isSaving={uiState.isSaving}
        isSaved={uiState.isSaved}
        onSave={dispatch.onSave}
        onEditItemClick={canEdit ? dispatch.onEditItemClick : null}
        onSaveSuccess={handleOnSuccess}
        onSaveError={dispatch.onSaveError}
        onClose={dispatch.closeDrawer}
      />
    </>
  );
};

export default ReferenceCheckTabLayout;
