import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1, 2),
    textTransform: 'none'
  },
  label: {
    '& svg': {
      marginRight: theme.spacing(0.5),
      verticalAlign: 'middle'
    }
  }
}));
