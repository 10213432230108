import React from 'react';
import { COMMAND_CENTER_BASE_URL, ENDPOINTS } from 'features/command-center/constants';
import { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';

import { SimplifiedTable } from '../../shared';

import {
  TEAM_MEMBERS_HEALTH_COLUMNS,
  TEAM_MEMBERS_HEALTH_ORDER_BY_OPTIONS,
  teamMembersHealthExtraRenderers
} from './TeamMembersHealth.constants';
import { TeamMembersHealthWrapper } from './TeamMembersHealth.styled';
import {
  formatTeamMembersHealthResponse,
  teamMembersHealthParamsExtender
} from './TeamMembersHealth.utils';

const STRINGS = strings.commandCenter.finance.teamMembersHealth;

const PAGE_KEY = 'command-center-finance-team-members-health';

export const TeamMembersHealth = () => {
  return (
    <TeamMembersHealthWrapper>
      <SimplifiedTable
        pageKey={PAGE_KEY}
        header={STRINGS.title}
        subheader={STRINGS.subtitle}
        endpoint={`${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.financeHealthSummary}`}
        apiInstance={SECONDARY_API_CLIENT}
        extendParams={teamMembersHealthParamsExtender}
        apiVersion={2}
        columns={TEAM_MEMBERS_HEALTH_COLUMNS}
        formatResponse={formatTeamMembersHealthResponse}
        extraRenderers={teamMembersHealthExtraRenderers}
        orderByOptions={TEAM_MEMBERS_HEALTH_ORDER_BY_OPTIONS}
      />
    </TeamMembersHealthWrapper>
  );
};
