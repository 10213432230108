import { makeStyles } from '@material-ui/core/styles';

const previewMaxWidth = 1360;

export const contentIcon = {
  float: 'right',
  marginLeft: 'auto',
  marginRight: '0',
  marginTop: '0'
};

export const useStyles = makeStyles(theme => ({
  hiringAuthorityPreview: {
    display: 'grid',
    gap: theme.spacing(1, 2),
    maxWidth: previewMaxWidth,
    width: '100%'
  },
  actionsContainer: {
    width: '100%',
    display: 'flex',
    gridColumn: '1 / 4'
  },
  formContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: theme.spacing(1, 2),
    paddingBottom: theme.spacing(1)
  },
  phonesContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 0.5fr 1fr 1fr',
    gap: theme.spacing(1, 2)
  }
}));
