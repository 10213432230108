// @flow
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { showAlert } from 'actions/app';
import { setSelectedTemplate } from 'actions/bulkEmail';
import { createFolder, editFolder, getFolderData } from 'services/bulkEmail';
import type { TemplateFolderDrawerPropTypes } from 'types/app';
import Text from 'UI/components/atoms/Text';
import TextBox from 'UI/components/atoms/TextBox';
import TemplateDropDown from 'UI/components/molecules/TemplateDropDown';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { TEMPLATE_ENTITY_TYPES } from 'UI/constants/defaults';

import { FORM_FIELDS_MAP } from './fields';
import { useStyles } from './style';

const TemplateFolderDrawer = ({
  templatesTreeItem,
  isFolderBeingEdited = false,
  onClose,
  onSubmitFolder
}: TemplateFolderDrawerPropTypes) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false
  });

  const form = useForm({
    defaultValues: isFolderBeingEdited
      ? {
          [FORM_FIELDS_MAP.FolderName.key]: templatesTreeItem.name
        }
      : {
          [FORM_FIELDS_MAP.DestinationParentFolder.key]:
            templatesTreeItem.entity === TEMPLATE_ENTITY_TYPES.Folder
              ? {
                  id: templatesTreeItem.id,
                  name: templatesTreeItem.name
                }
              : templatesTreeItem.parentFolder
        }
  });
  const { register, errors, handleSubmit, setValue, watch } = form;
  const formFields = watch();

  useEffect(() => {
    register({ name: FORM_FIELDS_MAP.FolderName.key }, { required: 'Folder Name is required' });
    register(
      { name: FORM_FIELDS_MAP.DestinationParentFolder.key },
      { required: 'Directory folder is required' }
    );
  }, [register]);

  useEffect(() => {
    isFolderBeingEdited &&
      (async () => {
        setUiState(prev => ({ ...prev, isFormLoading: true }));
        const { success, data, alert } = await getFolderData(templatesTreeItem.id);
        if (success) {
          const parentFolder = { id: data.parentFolder.id, name: data.parentFolder.name };
          dispatch(setSelectedTemplate({ parentFolder }));
          setValue(FORM_FIELDS_MAP.DestinationParentFolder.key, parentFolder);
        } else {
          dispatch(showAlert(alert));
        }
        setUiState(prev => ({ ...prev, isFormLoading: false }));
      })();
  }, [isFolderBeingEdited, templatesTreeItem.id, dispatch, setValue, register]);

  const onSubmit = async ({ folderName, destinationParentFolder }) => {
    setUiState(prevState => ({ ...prevState, isSaving: true }));
    const mappedFormData = {
      name: folderName,
      parentFolderId: destinationParentFolder.id
    };
    const { success, alert, data } = isFolderBeingEdited
      ? await editFolder(templatesTreeItem.id, mappedFormData)
      : await createFolder(mappedFormData);

    if (success) {
      setUiState(prevState => ({
        ...prevState,
        isSaving: false,
        isSuccess: true
      }));

      const newFolderData = {
        name: folderName,
        idsToRefresh: { destinationFolderId: destinationParentFolder.id },
        id: isFolderBeingEdited ? templatesTreeItem.id : data.id
      };
      const parentFolderDidChange =
        isFolderBeingEdited && destinationParentFolder.id !== templatesTreeItem.parentFolder.id;

      if (parentFolderDidChange)
        newFolderData.idsToRefresh.previousFolderId = templatesTreeItem.parentFolder.id;

      onSubmitFolder && onSubmitFolder(newFolderData);
    } else {
      setUiState(prevState => ({
        ...prevState,
        isSaving: false,
        isSuccess: false
      }));
    }

    dispatch(showAlert(alert));
  };

  return (
    <DrawerContentLayout
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      uiState={uiState}
      title={isFolderBeingEdited ? 'Edit Folder' : 'New Folder'}
      drawerProps={{
        open: true
      }}
    >
      <FormContext {...form}>
        <Box className={classes.formContainer}>
          <Box className={classes.formInputContainer}>
            <Text variant="h2" text="1.- Folder Name *" />
            <TextBox
              name={FORM_FIELDS_MAP.FolderName.key}
              label="Name *"
              width="100%"
              onChange={setValue}
              error={!!errors.folderName}
              errorText={errors.folderName?.message}
              value={formFields.folderName}
            />
          </Box>
          <Box className={classes.formInputContainer}>
            {isFolderBeingEdited ? (
              <>
                <Text variant="h2" text="2.- Select Directory Folder *" />
                <TemplateDropDown
                  folderIdToExclude={templatesTreeItem?.id}
                  formFieldName={FORM_FIELDS_MAP.DestinationParentFolder.key}
                  preselectedFolderId="Mine"
                  placeholder="Choose folder"
                  onlyShowFolders
                  enableIconClick
                />
              </>
            ) : (
              <>
                <Text variant="h2" text="2.- Directory Folder" />
                <TextBox
                  name={FORM_FIELDS_MAP.DestinationParentFolder.key}
                  label="Parent Folder *"
                  width="100%"
                  error={!!errors[FORM_FIELDS_MAP.DestinationParentFolder.key]}
                  errorText={errors[FORM_FIELDS_MAP.DestinationParentFolder.key]?.message}
                  value={formFields[FORM_FIELDS_MAP.DestinationParentFolder.key]?.name}
                  disabled
                />
              </>
            )}
          </Box>
        </Box>
      </FormContext>
    </DrawerContentLayout>
  );
};

export default TemplateFolderDrawer;
