// @flow
import { useState } from 'react';

export const useDrawer = (location: any, history: Object) => {
  const [uiState, setUiState] = useState({
    isDrawerOpen: false
  });

  const toggleDrawer = (drawer: string, open: boolean, additionalUi: any = {}) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setUiState(prevState => ({ ...prevState, [drawer]: open, ...additionalUi }));
    !open && history.replace(location.pathname);
  };

  return { toggleDrawer, uiState };
};

export default useDrawer;
