// @flow
import React from 'react';
import { SchemaStatuses } from 'constants/schemaStatuses';
import FPChip from 'UI/components/atoms/FPChip';

import { FPFormStatusType, STATUSES_INFO } from './FPFormStatus.constants';

type FPFormStatusProps = {
  shouldShowTotal?: boolean,
  status: FPFormStatusType,
  totalAnsweredQuestions?: number,
  totalQuestions: number
};

const FPFormStatus = ({
  status,
  totalQuestions,
  totalAnsweredQuestions,
  shouldShowTotal
}: FPFormStatusProps) => {
  const total =
    shouldShowTotal && status === SchemaStatuses.InProgress
      ? ` ${totalAnsweredQuestions}/${totalQuestions}`
      : '';

  const statusLabel = `${STATUSES_INFO[status].label}${total}`;
  const statusColor = STATUSES_INFO[status].color;
  return (
    <FPChip
      label={statusLabel}
      variant="outlined"
      color={statusColor}
      textColor={statusColor}
      size="small"
    />
  );
};

FPFormStatus.defaultProps = {
  totalAnsweredQuestions: 0,
  shouldShowTotal: true
};

export default FPFormStatus;
