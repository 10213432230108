export const formatAssociatedCompaniesResponse = response => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    association_type_class:
      item.association_type === 'ultimateParent' ? 'parent' : item.association_type,
    association_label:
      item.association_type === 'ultimateParent' ? 'HQ / Main' : item.association_type
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};
