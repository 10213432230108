// @flow
import React from 'react';

import Content from './Content';

const SendoutLeadersBoard = props => {
  return <Content {...props} />;
};

export default SendoutLeadersBoard;
