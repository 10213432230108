// @flow
import { showAlert } from 'actions/app';
import {
  deleteBulkEmailDraft,
  deleteScheduledBulkEmail,
  deleteTemporaryAttachment,
  getBulkById,
  scheduleBulkEmail,
  sendBulkEmail,
  sendScheduledBulkNow,
  updateBulkEmailDraft,
  updateScheduleDate,
  updateScheduledBulkEmail,
  uploadTemporaryAttachment
} from 'services/bulkEmail';
import strings from 'strings';
import type { Dispatch, GetState } from 'types/redux';
import { mapRestrictionId } from 'UI/components/organisms/BulkEmailDrawer/utils';
import { createDateTime, dateFormatter } from 'UI/utils';
import { getMappedBulkData } from 'UI/utils/bulkEmail';

import { makeActionCreator } from '.';

const {
  errors: {
    removeAttachments: { noType: alertAttachmentHasNoType },
    loadBulkPreview
  },
  success: {
    removeAttachments: { template: alertSuccessRemovingTemplateAttachment }
  }
} = strings.bulkEmails;

// ==============================
// Domain types
// ==============================

export const DomainTypes = {
  REMOVE_ALL_ATTACHMENTS: 'REMOVE_ALL_ATTACHMENTS',
  REMOVE_ATTACHMENT: 'REMOVE_ATTACHMENT',
  REMOVE_EMAIL_BODY: 'REMOVE_EMAIL_BODY',
  REMOVE_TEMPLATE: 'REMOVE_TEMPLATE',
  SET_ATTACHMENT: 'SET_ATTACHMENT',
  SET_BULK_EMAIL_IN_PREVIEW_ID: 'SET_BULK_EMAIL_IN_PREVIEW_ID',
  SET_BULK_EMAIL_IN_PREVIEW: 'SET_BULK_EMAIL_IN_PREVIEW',
  SET_BULK_PREVIEW_TO_INITIAL: 'SET_BULK_PREVIEW_TO_INITIAL',
  SET_BULK_PREVIEW: 'SET_BULK_PREVIEW',
  SET_BULK_TO_INITIAL: 'SET_BULK_TO_INITIAL',
  SET_EMAIL_BODY: 'SET_EMAIL_BODY',
  SET_EXCLUDED_TEMPLATE_ATTACHMENTS: 'SET_EXCLUDED_TEMPLATE_ATTACHMENTS',
  SET_TEMPLATE_ATTACHMENT: 'SET_TEMPLATE_ATTACHMENT',
  SET_TEMPLATE_TO_INITIAL: 'SET_TEMPLATE_TO_INITIAL',
  SET_TEMPLATE: 'SET_TEMPLATE'
};

// ==============================
// UI types
// ==============================

export const UiTypes = {
  SET_ATTACHMENT_LOADING: 'SET_ATTACHMENT_LOADING',
  SET_BULK_EMAIL_PREVIEW_LOADING: 'SET_BULK_EMAIL_PREVIEW_LOADING',
  SET_BULK_EMAIL_STATUS_TO_INITIAL: 'SET_BULK_EMAIL_STATUS_TO_INITIAL',
  SET_BULK_EMAIL_STATUS: 'SET_BULK_EMAIL_STATUS',
  SET_BULK_RESTRICTION_TO_INITIAL: 'SET_BULK_RESTRICTION_TO_INITIAL',
  SET_SELECTED_MENU_ITEM: 'SET_SELECTED_MENU_ITEM',
  SET_STATS_OPEN: 'SET_STATS_OPEN'
};

// ==============================
// Domain actions
// ==============================

/**
 * Resets bulk to initial domain state
 *
 * @param {string[]} [payload.dontReset] - You can pass this prop to avoid resetting a specific prop
 */
export const setBulkEmailToInitial = makeActionCreator(DomainTypes.SET_BULK_TO_INITIAL, 'payload');

/**
 * Action sets bulk attachments ids uploaded to temp folder.
 *
 * @param {Object} attachment - Attachments that's going to be added.
 * @param {number} attachment.id
 * @param {string} attachment.name
 * @param {string} attachment.type - temporal | template, to know if attachment is uploaded or is
 * previously loaded.
 */
export const setAttachments = makeActionCreator(DomainTypes.SET_ATTACHMENT, 'payload');

/**
 * Action removes an id from the array.
 *
 * @param {object} filter - Object with params required to filter attachments
 * @param {string} filter.type - String that accepts id | type as possible values
 * @param {string | number} filter.value - Value to filter, if type is set then a string must be provided
 * string = temporal | template. If type is equal to id then a number must be provided
 */
export const removeAttachmentIds = makeActionCreator(DomainTypes.REMOVE_ATTACHMENT, 'payload');

/**
 * Action to remove all items in attachments list
 */
const removeAllAttachments = makeActionCreator(DomainTypes.REMOVE_ALL_ATTACHMENTS, 'payload');

export const setSelectedTemplate = makeActionCreator(DomainTypes.SET_TEMPLATE, 'payload');
export const setSelectedTemplateToInitial = makeActionCreator(
  DomainTypes.SET_TEMPLATE_TO_INITIAL,
  'payload'
);

export const setExcludedTemplateAttachments = makeActionCreator(
  DomainTypes.SET_EXCLUDED_TEMPLATE_ATTACHMENTS,
  'payload'
);

const removeSelectedTemplate = makeActionCreator(DomainTypes.REMOVE_TEMPLATE, 'payload');

const setEmailBody = makeActionCreator(DomainTypes.SET_EMAIL_BODY, 'payload');

export const removeEmailBody = makeActionCreator(DomainTypes.REMOVE_EMAIL_BODY, 'payload');

/**
 * Action to set sent preview data
 *
 * @param {Object} Preview - contains email body, attachments and search project name data.ç
 * @param {Number} Preview.id - Bulk Email id
 * @param {string} Preview.to - Search project name to know to which SP was sent
 * @param {string} Preview.sent_date - The date in which the bulk was sent in MM/DD/YYYY - hh:MM A format
 * @param {string} Preview.subject - Email subject
 * @param {string} Preview.html - Email body in html format
 * @param {string} Preview.text - Email body in plain text format
 * @param {Array} Preview.attachments - List of attachments that contains literal objects with these properties
 * name, url, size (kb or mb)
 */
const setBulkPreview = makeActionCreator(DomainTypes.SET_BULK_PREVIEW, 'payload');

export const setBulkPreviewToInitial = makeActionCreator(DomainTypes.SET_BULK_PREVIEW_TO_INITIAL);

export const setBulkEmailInPreview = makeActionCreator(
  DomainTypes.SET_BULK_EMAIL_IN_PREVIEW,
  'payload'
);
export const setBulkEmailInPreviewId = makeActionCreator(
  DomainTypes.SET_BULK_EMAIL_IN_PREVIEW_ID,
  'payload'
);

// ==============================
// Domain async
// ==============================

export const startUploadingAttachment = (formData: FormData, name: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(setAttachmentLoading(true));
    const response = await uploadTemporaryAttachment(formData);
    response.success
      ? dispatch(setAttachments({ id: response.data.id, name, type: 'temporal' }))
      : dispatch(showAlert(response.alert));
    dispatch(setAttachmentLoading(false));
  };
};

/**
 * Function expression to remove uploaded temporal attachments.
 *
 * This FE deletes an uploaded temporal file sending a request and returns
 * a message response to let the user know if the file was removed successfully
 * or not. It also filters the existing state to return an array without the
 * removed id. At the end it sets uploading to false to let user know that this
 * process was finished.
 *
 * @param {number} idToRemove
 */
export const removeAttachment = (idToRemove: number) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const {
      bulkEmail: {
        domain: { attachments }
      }
    } = getState();
    dispatch(setAttachmentLoading(true));
    const indexToRemove = attachments.findIndex(attachment => attachment.id === idToRemove);
    switch (attachments[indexToRemove].type) {
      case 'temporal':
        deleteTemporaryAttachment(idToRemove)
          .then(res => {
            dispatch(removeAttachmentIds({ type: 'id', value: idToRemove }));
            dispatch(showAlert(res.alert));
          })
          .catch(error => dispatch(showAlert(error.alert)))
          .finally(() => dispatch(setAttachmentLoading(false)));
        break;
      case 'template':
        dispatch(removeAttachmentIds({ type: 'id', value: idToRemove }));
        dispatch(setExcludedTemplateAttachments(idToRemove));
        dispatch(
          showAlert({
            severity: 'success',
            ...alertSuccessRemovingTemplateAttachment
          })
        );
        setTimeout(() => dispatch(setAttachmentLoading(false)), 1000);
        break;
      default:
        dispatch(
          showAlert({
            severity: 'error',
            ...alertAttachmentHasNoType,
            autoHideduration: 10000
          })
        );
    }
  };
};

/**
 * Function Expression to remove all the uploaded attachments.
 */
export const startRemovingAllAttachments = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    const {
      bulkEmail: {
        domain: { attachments, template }
      }
    } = getState();
    if (template.id) dispatch(startRemovingSelectedTemplate());
    attachments.length > 0 &&
      attachments.forEach(async attachment => {
        if (attachment.type === 'temporal') {
          const response = await deleteTemporaryAttachment(attachment.id);
          !response.success && dispatch(showAlert(response.alert));
        }
      });
    dispatch(removeAllAttachments());
  };
};

/**
 * @param {Object} template
 * @param {number} template.id
 * @param {Object} [template.data]
 * @param {string} template.data.name
 * @param {Object} template.data.emailBody
 * @param {string} template.data.emailBody.subject
 * @param {string} template.data.emailBody.text
 * @param {string} template.data.emailBody.html
 * @param {Array} template.data.emailBody.attachments
 * @returns {Promise}
 */
export const startSettingSelectedTemplateData = ({ id, data }) => {
  return async (dispatch: Dispatch) => {
    const {
      name,
      emailBody: { subject, text, html, attachments: emailAttachments },
      parentFolder
    } = data;
    dispatch(
      setSelectedTemplate({
        id,
        name,
        emailExcludedAttachments: [],
        parentFolder
      })
    );
    dispatch(
      setEmailBody({
        id,
        subject,
        text,
        html
      })
    );
    dispatch(removeAllAttachments());
    emailAttachments.length > 0 &&
      emailAttachments.forEach(attachment =>
        dispatch(
          setAttachments({
            id: attachment.id,
            name: attachment.name,
            type: 'template',
            size: parseInt(attachment.file_size, 10)
          })
        )
      );
  };
};

export const startRemovingSelectedTemplate = () => {
  return (dispatch: Dispatch) => {
    dispatch(removeAttachmentIds({ type: 'type', value: 'template' }));
    dispatch(removeSelectedTemplate());
    dispatch(removeEmailBody());
  };
};

export const startSendingBulkEmail = (bulkParams, options) => {
  return async (dispatch: Dispatch) => {
    dispatch(
      setBulkEmailStatus({
        loading: true,
        completed: false,
        open: !bulkParams.is_draft,
        sending: true
      })
    );

    const { success, alert, error } = await sendBulkEmail(bulkParams);

    if (error?.businessRuleId) {
      const bulkEmailStatus = {
        completed: true,
        sending: false,
        loadLast: false,
        loading: false,
        open: false
      };
      const restrictionRule = mapRestrictionId(error.businessRuleId);
      bulkEmailStatus.bulkRestriction = { rule: restrictionRule, reason: alert.body };
      dispatch(setBulkEmailStatus(bulkEmailStatus));
      return;
    }

    if (success) {
      dispatch(setBulkEmailToInitial());
      if (options.closeBulkDrawer) {
        options.closeBulkDrawer();
        options.setSelectionToInitial();
      }
    }

    alert && dispatch(showAlert(alert));

    bulkParams.draft_id && dispatch(setBulkPreviewToInitial());
    dispatch(
      setBulkEmailStatus({
        completed: true,
        loadLast: success && !!options?.loadLast,
        loading: false,
        reloadEmailList: success && !!bulkParams.draft_id,
        sending: false
      })
    );
  };
};

export const startSchedulingBulkEmail = (bulkParams, options) => {
  return async (dispatch: Dispatch) => {
    dispatch(setBulkEmailStatus({ completed: false, loading: true }));
    const { success, alert, error } = await scheduleBulkEmail(bulkParams);
    if (error?.businessRuleId) {
      const bulkEmailStatus = {
        completed: true,
        loading: false
      };
      const restrictionRule = mapRestrictionId(error.businessRuleId);
      bulkEmailStatus.bulkRestriction = { rule: restrictionRule, reason: alert.body };
      dispatch(setBulkEmailStatus(bulkEmailStatus));
      return;
    }

    if (!success) {
      dispatch(showAlert(alert));
      setBulkEmailStatus({
        loading: false
      });
      return;
    }

    const formattedDate = dateFormatter(bulkParams.send_date, ({ MonthDayTime }) => MonthDayTime);
    bulkParams.is_draft && dispatch(setBulkPreviewToInitial());
    dispatch(
      showAlert({
        ...alert,
        body: strings.formatString(alert.body, { formattedDate })
      })
    );
    dispatch(
      setBulkEmailStatus({
        completed: true,
        loading: false,
        loadLast: !!options.loadLast,
        reloadEmailList: true
      })
    );
    dispatch(
      setBulkEmailToInitial({
        dontReset: options?.dontReset
      })
    );
    if (options.closeBulkDrawer) options.closeBulkDrawer();
    if (options.closeBulkDrawer) options.setSelectionToInitial();
  };
};

export const startUpdatingBulkEmailDraft = (bulkParams, options) => {
  return async (dispatch: Dispatch) => {
    const { success, alert } = await updateBulkEmailDraft(bulkParams);
    if (success) {
      dispatch(
        setBulkEmailStatus({
          completed: true,
          loading: false,
          loadLast: !!options.loadLast,
          reloadEmailList: true
        })
      );
      dispatch(
        setBulkEmailToInitial({
          dontReset: options?.dontReset
        })
      );
      options.closeBulkDrawer();
    }
    dispatch(showAlert(alert));
    setBulkEmailStatus({ loading: false });
  };
};

export const startUpdatingScheduledBulkEmail = (bulkParams, options) => {
  return async (dispatch: Dispatch) => {
    const { bulk_id: bulkId, ...restParams } = bulkParams;
    const { success, alert, error } = await updateScheduledBulkEmail(bulkId, restParams);

    if (error?.businessRuleId) {
      const bulkEmailStatus = {
        completed: true,
        loading: false
      };
      const restrictionRule = mapRestrictionId(error.businessRuleId);
      bulkEmailStatus.bulkRestriction = { rule: restrictionRule, reason: alert.body };
      dispatch(setBulkEmailStatus(bulkEmailStatus));
      return;
    }

    dispatch(showAlert(alert));
    dispatch(
      setBulkEmailStatus({
        completed: true,
        loading: false,
        loadLast: !!options.loadLast && success,
        reloadEmailList: success
      })
    );
    if (success) {
      dispatch(
        setBulkEmailToInitial({
          dontReset: options?.dontReset
        })
      );
    }
    options.closeBulkDrawer();
  };
};

/**
 * @param {number} scheduleId
 * @param {Object} options
 * @param {Object} [options.data={}]
 * @param {moment.Moment} options.data.scheduleDate
 * @param {moment.Moment} options.data.scheduleTime
 * @param {boolean} [options.sendNow=false]
 * @returns {Function}
 */
export const startReschedulingBulkEmail = (scheduleId, options) => {
  return async (dispatch: Dispatch) => {
    dispatch(setBulkEmailStatus({ loading: true }));
    const { scheduleDate, scheduleTime } = options.data;
    const dateTime = createDateTime(scheduleDate, scheduleTime);
    const { success, alert, error } = await updateScheduleDate(scheduleId, dateTime);

    if (error?.businessRuleId) {
      const bulkEmailStatus = {
        completed: true,
        loading: false
      };
      const restrictionRule = mapRestrictionId(error.businessRuleId);
      bulkEmailStatus.bulkRestriction = { rule: restrictionRule, reason: alert.body };
      dispatch(setBulkEmailStatus(bulkEmailStatus));
      return;
    }

    if (success) {
      dispatch(setBulkEmailStatus({ completed: true, loading: false, reloadEmailList: success }));
      dispatch(setBulkEmailInPreview({ edit: false }));
      dispatch(showAlert(alert));
    }
  };
};

export const startSendingNowScheduledBulkEmail = (bulkParams, options) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    dispatch(setBulkEmailStatus({ loading: true }));
    const { bulk_id: scheduleId } = bulkParams;

    const bulkData = {};
    if (options?.sendNowScheduleBulk) {
      bulkData.raw = { ...getState().bulkEmail.domain.bulkEmailInPreview.data };
      bulkData.mapped = getMappedBulkData(bulkData.raw);
      bulkData?.mapped?.alert && dispatch(showAlert(bulkData?.mapped?.alert));

      if (!bulkData?.mapped?.success) return;
    } else {
      bulkData.mapped = { data: bulkParams };
    }

    const { success, alert, error } = await sendScheduledBulkNow(
      scheduleId,
      bulkData?.mapped?.data
    );

    if (error?.businessRuleId) {
      const bulkEmailStatus = {
        completed: true,
        loading: false
      };
      const restrictionRule = mapRestrictionId(error?.businessRuleId);
      bulkEmailStatus.bulkRestriction = { rule: restrictionRule, reason: alert.body };
      dispatch(setBulkEmailStatus(bulkEmailStatus));
      return;
    }

    if (success) {
      dispatch(setBulkPreviewToInitial());
      dispatch(setBulkEmailStatus({ reloadEmailList: true }));
      options?.closeBulkDrawer && options.closeBulkDrawer();
    }

    alert && dispatch(showAlert(alert));
    dispatch(setBulkEmailStatus({ completed: true, loading: false }));
  };
};

export const startDeletingScheduledBulkEmail = scheduleId => {
  return async (dispatch: Dispatch) => {
    dispatch(setBulkEmailStatus({ loading: true }));
    const { success, alert } = await deleteScheduledBulkEmail(scheduleId);

    success && dispatch(setBulkPreviewToInitial());
    dispatch(showAlert(alert));
    dispatch(setBulkEmailStatus({ completed: true, loading: false, reloadEmailList: success }));
  };
};

export const startDeletingBulkDraft = draftId => {
  return async (dispatch: Dispatch) => {
    dispatch(setBulkEmailStatus({ loading: true }));
    const { success, alert } = await deleteBulkEmailDraft(draftId);

    success && dispatch(setBulkPreviewToInitial());
    dispatch(showAlert(alert));
    dispatch(setBulkEmailStatus({ completed: true, loading: false, reloadEmailList: success }));
  };
};

/**
 * @param {Number} bulkId
 * @returns {function}
 */
export const startSettingBulkPreview = bulkId => {
  return async (dispatch: Dispatch, getState: GetState) => {
    try {
      const { selectedMenuItem } = getState().bulkEmail.ui;
      dispatch(setBulkPreviewLoading(true));
      dispatch(setBulkPreview({ id: bulkId }));
      dispatch(setBulkEmailInPreviewId(bulkId));
      const response = await getBulkById(bulkId, selectedMenuItem);
      if (response.success) {
        const {
          searchProject,
          emailBody,
          sendDate,
          createdAt,
          emailConfig: {
            bulkType,
            template,
            marketing: { marketingCandidates: candidates },
            recruiting: { recruitingJobOrders: jobOrders }
          }
        } = response.data;
        /**
         * TODO: get rid of the old way to set bulk email in preview and start
         * using the more recent bulkEmailInPreview store state
         */
        dispatch(
          setBulkPreview({
            id: bulkId,
            subject: emailBody?.subject || '',
            emailBody: emailBody?.html || '',
            to: searchProject ? `${searchProject?.name} (${searchProject?.totalItems})` : '',
            text: emailBody?.text || '',
            // TODO: change date to render depending on bulk type
            date: dateFormatter(sendDate || createdAt, ({ MonthDayYearTime }) => MonthDayYearTime),
            attachments: emailBody?.attachments,
            config: {
              searchProjectId: searchProject?.id,
              bulkTypeId: bulkType?.id,
              template: template?.name || '',
              marketing: { candidates },
              recruiting: { jobOrders }
            }
          })
        );
        dispatch(
          setBulkEmailInPreview({
            edit: false,
            data: {
              id: bulkId,
              ...response.data
            }
          })
        );
      } else {
        dispatch(showAlert(response.alert));
      }
    } catch (error) {
      dispatch(
        showAlert({
          severity: 'error',
          title: loadBulkPreview.title,
          body: error.message
        })
      );
    } finally {
      dispatch(setBulkPreviewLoading(false));
    }
  };
};

// ==============================
// UI actions
// ==============================

/**
 * @param {'sent' | 'template' | 'drafts' | 'schedule' | 'email' } payload - selected menu item in bulk emails page
 */
export const setSelectedBulkMenuItem = makeActionCreator(UiTypes.SET_SELECTED_MENU_ITEM, 'payload');
export const setAttachmentLoading = makeActionCreator(UiTypes.SET_ATTACHMENT_LOADING, 'payload');
/**
 * @param {Boolean} payload - Sets stats modal open/close, payload must be either true or false
 */
export const setBulkStatsOpen = makeActionCreator(UiTypes.SET_STATS_OPEN, 'payload');
const setBulkPreviewLoading = makeActionCreator(UiTypes.SET_BULK_EMAIL_PREVIEW_LOADING, 'payload');
export const setBulkEmailStatus = makeActionCreator(UiTypes.SET_BULK_EMAIL_STATUS, 'payload');
export const setBulkRestrictionToInitial = makeActionCreator(
  UiTypes.SET_BULK_RESTRICTION_TO_INITIAL
);
export const setBulkEmailStatusToInitial = makeActionCreator(
  UiTypes.SET_BULK_EMAIL_STATUS_TO_INITIAL,
  'payload'
);
