import { makeStyles } from '@material-ui/core';
import { componentDimensions } from 'UI/constants/dimensions';

export const useStyles = makeStyles(theme => ({
  isDragging: {
    backgroundColor: theme.palette.brandColors.FPWhite,
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey[900],
    maxWidth: componentDimensions.fileDraggable.width
  }
}));
