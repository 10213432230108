import React from 'react';
import { useSelector } from 'react-redux';
import strings from 'strings';
import { When } from 'UI/components/atoms/When';

import { ActionButton, Footer } from './DataSheetFooter.styled';

const { buttons: copies } = strings.shared.dataSheet.footer;
const DataSheetFooter = ({
  onClose,
  onSave,
  onSaveDraft,
  isSavingDraft,
  isSaving,
  canSave,
  canSaveDraft
}) => {
  const { isSidebarOpen } = useSelector(state => state.app.ui);
  return (
    <Footer needsExtraWidth={isSidebarOpen}>
      <ActionButton variant="outlined" initialText={strings.shared.ui.cancel} onClick={onClose} />
      <When condition={canSaveDraft}>
        <ActionButton
          variant="outlined"
          initialText={copies.saveDraft}
          onProgressText={copies.savingDraft}
          isSaving={isSavingDraft}
          onClick={onSaveDraft}
        />
      </When>
      <ActionButton isSaving={isSaving} onClick={onSave} disabled={!canSave || isSaving} />
    </Footer>
  );
};

export default DataSheetFooter;
