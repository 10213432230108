// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { EntityRoutes } from 'routes/constants';
import InfoLabel from 'UI/components/molecules/InfoLabel';
import { DateFormats } from 'UI/constants/defaults';
import { toLocalTime } from 'UI/utils';

import { useStyles } from './DetailsOverview.styles';

interface ContentProps {
  detailsOverview: {
    id: number,
    companyId: number,
    companyName: string,
    jobOrderTitle: string,
    jobOrderId: number,
    hiringAuthorityName: string,
    hiringAuthorityId: number,
    candidateId: number,
    candidateName: string,
    interviewDate: string
  };
}

export const Content = ({ detailsOverview }: ContentProps) => {
  const classes = useStyles();
  const localTime = toLocalTime(detailsOverview?.interviewDate);
  const formattedDate = localTime && localTime.format(DateFormats.SimpleDateTime);

  return (
    <Grid container>
      <Grid item xs={6}>
        <InfoLabel
          title="Company"
          description={detailsOverview?.companyName}
          mode="localLink"
          linkProps={{
            target: '_blank',
            url: EntityRoutes.CompanyProfile.replace(':id', detailsOverview?.companyId)
          }}
          className={classes.infoLabel}
        />
      </Grid>
      <Grid item xs={6}>
        <InfoLabel
          title="Job Order"
          description={detailsOverview?.jobOrderTitle}
          mode="localLink"
          linkProps={{
            target: '_blank',
            url: EntityRoutes.JoborderProfile.replace(':id', detailsOverview?.jobOrderId)
          }}
          className={classes.infoLabel}
        />
      </Grid>
      <Grid item xs={6}>
        <InfoLabel
          title="Hiring Authority"
          description={detailsOverview?.hiringAuthorityName}
          mode="localLink"
          linkProps={{
            target: '_blank',
            url: EntityRoutes.HiringAuthorityProfile.replace(
              ':id',
              detailsOverview?.hiringAuthorityId
            )
          }}
          className={classes.infoLabel}
        />
      </Grid>
      <Grid item xs={6}>
        <InfoLabel
          title="Candidate"
          description={detailsOverview?.candidateName}
          mode="localLink"
          linkProps={{
            target: '_blank',
            url: EntityRoutes.CandidateProfile.replace(':id', detailsOverview?.candidateId)
          }}
          className={classes.infoLabel}
        />
      </Grid>
      <Grid item xs={6}>
        <InfoLabel
          title="Interview Date"
          description={formattedDate}
          mode="text"
          className={classes.infoLabel}
        />
      </Grid>
    </Grid>
  );
};
