// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import type { FileExplorerSection } from 'types/app';
import FileUploader from 'UI/components/molecules/FileUploader';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import { FeeAgreementFileCategoryId } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { REQUIRED_VALIDATION } from 'UI/utils';

import { FileKeyPrefix } from '../utils';

export type PlacementFilesSectionProps = {
  readOnly?: boolean,
  fileCategories: any[],
  files?: any[],
  fileExplorerSections?: FileExplorerSection[],
  /** Wheter to only remove a local file reference or completely remove the file from the server   */
  softDeleteForExistingItems?: boolean,
  agreement?: any
};

const maxNumberOfFiles = 10;

const PlacementFilesSection = (props: PlacementFilesSectionProps) => {
  const {
    readOnly,
    fileCategories,
    files,
    fileExplorerSections,
    softDeleteForExistingItems,
    agreement
  } = props;
  const { register, unregister, errors, setValue, clearError } = useFormContext();

  useEffect(() => {
    fileCategories.forEach(({ id, required }) =>
      required
        ? register(
            { name: `${FileKeyPrefix}${id}` },
            {
              validate(selectedFiles) {
                return selectedFiles?.length > 0 || REQUIRED_VALIDATION.required;
              }
            }
          )
        : register({ name: `${FileKeyPrefix}${id}` })
    );
    return () =>
      fileCategories.forEach(({ id }) => {
        unregister(`${FileKeyPrefix}${id}`);
        unregister(`${FileKeyPrefix}${id}`);
      });
  }, [register, unregister, fileCategories]);

  useEffect(() => {
    agreement && setValue(`${FileKeyPrefix}${agreement.file_type_id}`, [agreement]);
  }, [setValue, agreement]);

  const handleFileChange = (value: any, name?: string) => setValue(name, value, true);

  const handleUploadStarted = (_, name: string) => clearError(name);

  return (
    <NumberedForm.Item title="Attachments">
      {fileCategories.map(category => {
        const name = `${FileKeyPrefix}${category.id}`;
        const key =
          agreement && category.id === FeeAgreementFileCategoryId ? agreement.id : category.id; // To force a re-render when agreement was selected from parent
        return (
          <FileUploader
            key={key}
            name={name}
            category={category}
            defaultEntityType={EntityType.Placement}
            endpoint={Endpoints.Files}
            errorText={errors[name]?.message}
            files={files[category.id]}
            mode="categoryField"
            onAttachmentsChanged={handleFileChange}
            onFileUploadStarted={handleUploadStarted}
            softDeleteForExistingItems={softDeleteForExistingItems}
            disabled={readOnly}
            fileExplorerSections={fileExplorerSections}
            multiple={category.multiple}
            maxNumberOfFiles={category.multiple ? maxNumberOfFiles : 1}
          />
        );
      })}
    </NumberedForm.Item>
  );
};

PlacementFilesSection.defaultProps = {
  readOnly: false,
  files: [],
  fileExplorerSections: [],
  /** Wheter to only remove a local file reference or completely remove the file from the server   */
  softDeleteForExistingItems: false,
  agreement: null
};

export default PlacementFilesSection;
