import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import strings from 'strings';
import { FPTreeView } from 'UI/components/templates/RecruiterProfile/FPTreeView';
import PILMap from 'UI/components/templates/RecruiterProfile/PILMap/PILMap';

import { useStyles } from './styles';

const COPIES = strings.commandCenter.activity.pil;
const PilMapContent = ({ data }) => {
  const styles = useStyles();
  const [pil, setPil] = useState(data);

  const handleClickPil = selectedNode => {
    setPil(selectedNode);
  };

  return (
    <Grid container spacing={3} wrap="nowrap">
      <Grid item xs="auto" className={styles.containerFPTreeView}>
        <FPTreeView
          tree={pil.tree}
          title={COPIES.industries}
          onClickPil={handleClickPil} // TODO: adjust when pil is clicked
        />
      </Grid>

      <Grid item xs>
        <PILMap countries={pil.countries} markers={pil.markers} />
      </Grid>
    </Grid>
  );
};

export default PilMapContent;
