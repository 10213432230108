import { CandidateEntity, JobOrderEntity } from 'UI/constants/entityTypes';
import { currencyFormatter, numberFormatter } from 'UI/utils';

const ORDER = {
  jobOrder: {
    title: JobOrderEntity.title
  },
  candidate: {
    title: CandidateEntity.title
  },
  total: {
    title: 'Total'
  }
};

export const formatResponseData = response => {
  return Object.keys(ORDER).map(key => {
    const data = response[key] || {};
    return {
      title: ORDER[key].title,
      value: currencyFormatter(data.sum || 0, 0, {
        forceCompactNotation: true,
        notation: 'compact'
      }),
      valuePositions: 'bottom',
      subtitle: `${numberFormatter(data.count || 0)} items`
    };
  });
};
