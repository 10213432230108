// @flow
import { JSON_SCHEMA_CLIENT } from 'services/API';
import { Endpoints } from 'UI/constants/endpoints';

export const saveReference = (data: Object, options) => {
  return JSON_SCHEMA_CLIENT.post(`/${Endpoints.ReferenceCheck}`, data, {
    ...options
  });
};

export const getSchemasByReferenceId = (referenceId: string, options) => {
  return JSON_SCHEMA_CLIENT.get(`/${Endpoints.ReferenceCheck}`, {
    params: {
      id: referenceId
    },
    ...options
  });
};

export const updateReference = (data: Object, referenceId, options) => {
  return JSON_SCHEMA_CLIENT.patch(`/${Endpoints.ReferenceCheck}?id=${referenceId}`, data, {
    ...options
  });
};

export const deleteReference = (referenceId: string) => {
  return JSON_SCHEMA_CLIENT.delete(`/${Endpoints.ReferenceCheck}?id=${referenceId}`);
};

export const getReferencesByCandidateId = (candidateId: string, options) => {
  return JSON_SCHEMA_CLIENT.get(`/${Endpoints.ReferenceCheckByCandidate}`, {
    params: {
      id: candidateId,
      direction: 'desc',
      orderBy: 'created',
      page: 1,
      perPage: 10,
      cache: false
    },
    ...options
  });
};
