// @flow

import React, { useState } from 'react';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import strings from 'strings';
import HistoryItem from 'UI/components/molecules/HistoryItem';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { TypeHistoryItem } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EmptySendouts } from 'UI/res';
import { getId } from 'UI/utils';
import { renderSmartTags } from 'UI/utils/sendouts';

import { prepareArrayToDeclinationDetails } from '../utils';

const { shared: sharedCopies } = strings;

type SendoutLogsSectionProps = {
  id: number,
  title: string,
  onClose: () => void,
  onBack: () => void
};

const SendoutEventLogsSection = ({ id, title, onClose, onBack }: SendoutLogsSectionProps) => {
  const endpoint = `${Endpoints.SendoutsLogs.replace(':id', id)}`;
  const { Status } = useFetchWithStatus(endpoint);

  const [uiState] = useState({
    showBackNavigation: true
  });

  return (
    <DrawerContentLayout
      title={title}
      onBack={onBack}
      onClose={onClose}
      uiState={uiState}
      isBottomToolbarNeeded
      isSaveButtonMode={false}
      footerActionsProps={{
        primaryProps: {
          text: sharedCopies.ui.goBack,
          onClick: onBack
        },
        secondaryProps: {
          text: sharedCopies.ui.close,
          onClick: onClose
        }
      }}
    >
      <>
        <Typography variant="subtitle1" gutterBottom>
          Activity log:
        </Typography>
        <Status
          loading={
            <List dense>
              <HistoryItem isLoading />
            </List>
          }
          empty={
            <Typography paragraph gutterBottom>
              No activity yet
            </Typography>
          }
          error={error => (
            <EmptyPlaceholder title="Oops" subtitle={error} customEmptyState={<EmptySendouts />} />
          )}
          success={results => (
            <List dense>
              {results.map(log => (
                <HistoryItem
                  key={log.id || getId()}
                  date={log.real_date}
                  type={TypeHistoryItem.Sendout}
                  action={renderSmartTags(log)}
                  extraData={prepareArrayToDeclinationDetails(log?.event_details)}
                />
              ))}
            </List>
          )}
        />
      </>
    </DrawerContentLayout>
  );
};

export default SendoutEventLogsSection;
