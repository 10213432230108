// @flow
import React, { useCallback, useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { useGlobalStyles } from 'GlobalStyles';
import useDatatable, {
  buildButtonRendererDefinition,
  extractObjectFromDataTable,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import { useUrlParamsUpdater } from 'hooks/urlParamsUpdater';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import DataTable from 'UI/components/organisms/DataTable';
import ListingSummary from 'UI/components/organisms/ListingSummary';
import SendoutManager from 'UI/components/organisms/sendouts';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { backNavigateListConfig } from 'UI/constants/config';
import { PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { navigateToSendout } from 'UI/pages/Sendouts/utils';
import { createCustomStaticRanges } from 'UI/utils';

import {
  columnsSendouts,
  FiltersGroup,
  FiltersInclude,
  SendoutDateFilters,
  SendoutPeriodColumnBySection,
  SendoutSortColumnBySection,
  SendoutTrackingDateFilter
} from './columns';

const SENDOUT_KEY = 'sendoutChannelPartner';
const ORDER_BY_OPTIONS = {
  column: SendoutSortColumnBySection,
  direction: 'desc'
};

const Sendouts = (props: RouterProps) => {
  const { history, location } = props;
  const globalClasses = useGlobalStyles();
  const initialDateFilter = SendoutTrackingDateFilter.id;

  const initialPreferences = getColumnPreferences(
    SENDOUT_KEY,
    0,
    ORDER_BY_OPTIONS,
    columnsSendouts,
    0,
    location.search
  );

  const [dateTypeFilter, setDateTypeFilter] = useState(
    SendoutDateFilters.find(type => type.id === initialDateFilter)
  );

  const {
    data,
    getData,
    listState,
    count,
    columnPreferences,
    filters,
    queryParams,
    handleFiltersApply,
    handleFiltersChange,
    handleFiltersToggle,
    handleKeywordChange,
    handleColumnSortChange,
    handlePerPageChange,
    handlePageChange,
    handlePeriodChange,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleSectionChange
  } = useDatatable({
    key: SENDOUT_KEY,
    endpoint: Endpoints.SendoutsFromChannelPartner,
    columnsDefinitions: columnsSendouts,
    ORDER_BY_OPTIONS,
    initialPreferences,
    initialSection: initialDateFilter,
    periodFilterColumn: SendoutPeriodColumnBySection,
    entityType: EntityType.Sendouts,
    entityName: 'Sendouts',
    sharePreferencesBetweenSections: true
  });

  const { showWholeSkeleton, isLoading, isSideMenuOpen, page, perPage, keyword } = listState;
  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  useUrlParamsUpdater({ history, location, orderBy });

  const [uiState, setUiState] = useState({
    shouldRefresh: false,
    sendoutId: null
  });

  useEffect(() => {
    document.title = PageTitles.SendoutsFromChannelPartner;
  }, []);

  const handleViewSendout = async ({ rowData }) => {
    if (!rowData) return;
    const item = extractObjectFromDataTable(columnsSendouts, ['id'], rowData);

    setUiState(prev => ({
      ...prev,
      sendoutId: item.id
    }));
  };

  const handleCloseSendout = () =>
    setUiState(prev => ({
      ...prev,
      sendoutId: null
    }));

  const handleSendoutUpdated = async () => {
    setUiState(prev => ({
      ...prev,
      shouldRefresh: true
    }));
    await getData();
  };

  const handleSummaryLoaded = useCallback(() => {
    setUiState(prev => ({
      ...prev,
      shouldRefresh: false
    }));
  }, []);

  const handleDateTypeFilterChange = (name, value) => {
    setDateTypeFilter(value);
    handleSectionChange && handleSectionChange(name, value.id);
  };

  const actionButton = buildButtonRendererDefinition(
    globalClasses.smallRowActionButton,
    'View',
    handleViewSendout
  );

  const finalColumns = getColumnsToRender([...columns, actionButton]);
  const handleSendoutNavigation = navigateToSendout(history);

  return (
    <FiltersLayout
      filters={filters}
      title="Channel Partner Sendouts"
      section="sendouts"
      isSideMenuOpen={isSideMenuOpen}
      includeFilters={FiltersInclude}
      groups={FiltersGroup}
      extraSelector={
        <AutocompleteSelect
          name="dateTypeFilter"
          placeholder="Filter By"
          selectedValue={dateTypeFilter}
          onSelect={handleDateTypeFilterChange}
          defaultOptions={SendoutDateFilters}
          disableClearable
        />
      }
      dateRangerPickerProps={{
        staticRanges: createCustomStaticRanges(additionalPeriodRanges)
      }}
      onSearch={handleFiltersApply}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      onPeriodChange={handlePeriodChange}
      enableStore={false}
      titleLabelProps={backNavigateListConfig}
    >
      <ListingSummary
        endpoint={Endpoints.SendoutsFromChannelPartnerSummary}
        queryParams={queryParams.params}
        shouldRefresh={uiState.shouldRefresh}
        onDataLoaded={handleSummaryLoaded}
        numberOfColumns={2}
        grid="x2"
      />
      <DataTable
        data={data}
        columns={finalColumns}
        sortOrder={{ name: orderBy, direction }}
        columnOrder={columnOrder?.length ? columnOrder : undefined}
        loading={showWholeSkeleton}
        refreshing={isLoading}
        page={page}
        count={count}
        rowsPerPage={perPage}
        onToggleFilters={handleFiltersToggle}
        enableCustomFilters
        filter={false}
        searchText={keyword}
        selectableRows="none"
        onSearchTextChange={handleKeywordChange}
        onColumnSortChange={handleColumnSortChange}
        onPerPageClick={handlePerPageChange}
        onPageClick={handlePageChange}
        onColumnDisplayClick={handleColumnDisplayChange}
        onColumnOrderChange={handleColumnOrderChange}
      />

      <Drawer open={!!uiState.sendoutId} anchor="right" onClose={handleCloseSendout}>
        {uiState.sendoutId && (
          <SendoutManager
            onEdit={handleSendoutUpdated}
            onDelete={handleSendoutUpdated}
            onClose={handleCloseSendout}
            id={uiState.sendoutId}
            onNavigate={handleSendoutNavigation}
          />
        )}
      </Drawer>
    </FiltersLayout>
  );
};

export default Sendouts;
