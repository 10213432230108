import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  banner: {
    height: '100%',
    width: '100%'
  },
  container: {
    cursor: 'pointer',
    width: '100%'
  },
  divider: {
    height: '1px',
    margin: 0
  },
  link: {
    textDecoration: 'none !important'
  },
  mainCard: {
    backgroundColor: 'transparent',
    boxShadow: 'unset'
  },
  menuItem: {
    lineHeight: '14px',
    letterSpacing: '0.25px',
    padding: theme.spacing(2)
  },
  popover: {
    marginTop: theme.spacing(0.5)
  }
}));
