// @flow
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import qs from 'query-string';
import FPCardWrapper from 'UI/components/molecules/cardComponents/FPCardWrapper';
import { CustomFooterRenderers } from 'UI/components/templates/HomeItemsCreation/renderers';
import { TabWrapper } from 'UI/components/templates/RecruiterProfile/componentRenderers';
import { RecruiterProfileRenderers } from 'UI/components/templates/RecruiterProfile/renderers';
import { UIStatus } from 'UI/constants/status';

const RecruiterFooter = ({ item }) => {
  const { state } = useFetchWithStatus(item.extraDataUrl, [], null);

  const { status, results } = state;
  const isLoading = status === UIStatus.Loading;

  const footerCustomRenderer =
    (!isLoading &&
      CustomFooterRenderers[item.customFooterRender]?.({ data: results.data, section: item })) ||
    null;

  return footerCustomRenderer;
};

const RecruiterElementsBuilder = ({ item, endpoint }) => {
  const isElementATab = item?.wrappedOptions?.length;
  const [selectedTab, setSelectedTab] = useState(0);
  const itemUrl = isElementATab ? item?.wrappedOptions?.[selectedTab]?.url : item.url;
  const elementEndpoint = `llll${endpoint}${itemUrl}`;

  const [footerActionsState, setFooterActionsState] = useState({
    isVisible: true,
    showMore: false
  });

  const [limit, setLimit] = useState(item?.limit?.initial);

  const customEndpoint = qs.stringifyUrl({
    url: elementEndpoint,
    query: { limit }
  });

  const { state } = useFetchWithStatus(customEndpoint, [], null);

  const { status, results } = state;
  const isLoading = status === UIStatus.Loading;

  const handleTabChange = (event, newValue) => setSelectedTab(newValue);

  const getContentData = () => {
    const component = RecruiterProfileRenderers[item?.renderer];
    const isRenderer = typeof component === 'function';

    const columns = item?.wrappedOptions
      ? item?.wrappedOptions?.[selectedTab]?.columns
      : item?.columns;

    const componentBuilder =
      isRenderer &&
      component({
        ...results,
        columns: item?.renderer === 'table' ? columns : null
      });

    const isWrappedElement = item?.wrappedOptions?.length ? (
      <TabWrapper onChangeTabIndex={handleTabChange} selectedTab={selectedTab} data={item}>
        {componentBuilder}
      </TabWrapper>
    ) : (
      componentBuilder
    );

    return isWrappedElement;
  };

  const footerActionText = footerActionsState.showMore ? 'Show Less' : 'Show More';

  const handleActionClick = () => {
    setLimit(footerActionsState.showMore ? item.limit?.initial : item.limit?.max);
    setFooterActionsState(prev => ({ ...prev, showMore: !footerActionsState.showMore }));
  };

  return (
    <Grid item key={item.title} {...item.gridConfig}>
      <FPCardWrapper
        contentConfig={{ type: item.type, title: item.title, hintText: item.hintText }}
        content={!isLoading && getContentData()}
        canBeExpanded={false}
        headerProps={{ actionMode: 'noAction' }}
        footerActionsState={footerActionsState}
        isLoading={isLoading}
        hasRedirect={item.hasMoreAction}
        footerProps={{
          actionButtonProps: {
            onClick: () => handleActionClick()
          },
          footerActionText: !isLoading && results?.data?.length ? footerActionText : null,
          CustomFooter: item.customFooterRender && <RecruiterFooter item={item} />
        }}
      />
    </Grid>
  );
};

export default RecruiterElementsBuilder;
