import * as React from 'react';

const SvgAlertCircle = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      fill={fill}
      {...props}
    >
      <path
        d="M12 0a12.24 12.24 0 00-8.53 3.65A11.85 11.85 0 000 12.21 11.78 11.78 0 0011.8 24h.2a12.11 12.11 0 0012-12.21A11.77 11.77 0 0012 0zm-1.5 16.54A1.48 1.48 0 0112 15a1.53 1.53 0 011.52 1.47A1.47 1.47 0 0112.05 18a1.53 1.53 0 01-1.55-1.46zm.5-4v-6a1 1 0 012 0v6a1 1 0 01-2 0z"
        fill={fill}
      />
    </svg>
  );
};

export default SvgAlertCircle;
