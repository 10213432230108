import { makeStyles } from '@material-ui/core/styles';
import { globalStyles } from 'GlobalStyles';
import { addMenu } from 'UI/constants/dimensions';
import { flexAlignCenterFlexStart, flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  button: {
    ...flexAlignCenterJustifyCenter,
    borderRadius: theme.spacing(1),
    borderStyle: 'none',
    cursor: 'pointer',
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    transition: `all ${globalStyles.transition}`,
    width: ({ isSidebarOpen }) =>
      isSidebarOpen ? addMenu.addButton.width : addMenu.addButton.widthCollapsed
  },
  label: {
    display: ({ isSidebarOpen }) => (isSidebarOpen ? 'block' : 'none'),
    fontSize: theme.typography.fontSizeLarge,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(1),
    transition: `all ${globalStyles.transition}`
  },
  menuPopper: {
    borderRadius: theme.spacing(1),
    zIndex: 1
  },
  menuItem: {
    ...flexAlignCenterFlexStart,
    ...addMenu.menuItem
  },
  menuLabel: {
    marginLeft: theme.spacing(1.5)
  }
}));
