// @flow
import React, { useState } from 'react';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { FPCard, FPCardContent } from 'UI/components/atoms/FPCard';
import DataTable from 'UI/components/organisms/DataTable';
import { useDataTableStyles } from 'UI/globalStyles/DataTableStyles';
import { SvgArrowDown, SvgArrowUp, SvgDeleteFilled } from 'UI/res';

import { useStyles } from './styles';

type Props = {
  drafts: any[],
  columns: any[],
  handleDeleteAll: () => void
};

const DraftsTable = ({ drafts, handleDeleteAll, columns }: Props) => {
  const classes = useStyles();
  const dataTableClasses = useDataTableStyles();

  const maxNumberOfDrafts = 5;

  const [showAllDrafts, setShowAllDrafts] = useState(false);

  const draftsList = showAllDrafts ? drafts : drafts.slice(0, maxNumberOfDrafts);

  const toggleShowAll = () => setShowAllDrafts(prevShowAll => !prevShowAll);

  const footerBtnProps = {
    footerActionText: showAllDrafts ? 'Show Less' : 'Show More',
    actionButtonProps: {
      onClick: toggleShowAll,
      icon: showAllDrafts ? SvgArrowUp : SvgArrowDown
    }
  };

  const shouldDisplayFooter = drafts.length > maxNumberOfDrafts;

  return (
    <FPCard>
      <FPCardContent
        variant="relaxed"
        canBeExpanded
        className={classes.draftsCard}
        headerProps={{
          title: strings.drafts.cardTitle,
          variant: 'section',
          actionMode: 'default'
        }}
        footerProps={shouldDisplayFooter && footerBtnProps}
      >
        <DataTable
          columns={columns}
          data={draftsList}
          sort
          isServerSide={false}
          filter={false}
          search={false}
          elevation={0}
          pagination={false}
          selectableRowsHeader={false}
          selectableRows="none"
          enableNestedDataAccess="."
          className={clsx(classes.tableClasses, dataTableClasses.withoutToolbar)}
        />
        {!!drafts.length && (
          <div className={classes.deleteBtnContainer}>
            <FPActionButton
              text={strings.drafts.deleteAll}
              icon={SvgDeleteFilled}
              onClick={handleDeleteAll}
              classes={{ containedPrimary: classes.deleteButton }}
            />
          </div>
        )}
        {shouldDisplayFooter && <Divider />}
      </FPCardContent>
    </FPCard>
  );
};

export default DraftsTable;
