// @flow
import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'UI/res/colors';

export const useStyles = makeStyles(theme => ({
  statusRow: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      marginLeft: theme.spacing(1)
    }
  },
  boxCopyWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2)
  },
  boxWrapper: {
    margin: theme.spacing(0, 0, 4, 0),
    '& > ul': {
      backgroundColor: colors.white,
      boxShadow: '0px 3px 6px #0000001A',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      borderRadius: 4
    },
    '& > div, & > ul': {
      padding: theme.spacing(1, 3)
    },
    '& > ul > li': {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #E2E4EA',
      height: '100%'
    },
    '& > ul > li:last-child': {
      border: 'none'
    },

    '& .row button': {
      marginRight: 8
    }
  }
}));
