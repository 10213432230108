// @flow

export const notAvailableAbbr = 'NA';

export const getUserInitials = recruiter => recruiter.initials || notAvailableAbbr;

export const getSingleSplitInitials = ({ channelPartners, recruiter }) => {
  const cpRecruiters = channelPartners ? channelPartners.map(cp => cp.recruiter) : [];
  const allUsers = [recruiter, ...cpRecruiters];
  return allUsers.map(user => getUserInitials(user)).join('/');
};

export const getSideSplitInitials = splits =>
  splits?.map(split => getSingleSplitInitials(split))?.join(' ');

export const buildSplitInitials = (companySplits: any[], candidateSplits) => {
  const companySideSplits = getSideSplitInitials(companySplits);
  const candidateSideSplits = getSideSplitInitials(candidateSplits);
  const finalSplits = [];
  companySideSplits && finalSplits.push(companySideSplits);
  candidateSideSplits && finalSplits.push(candidateSideSplits);

  return finalSplits.join(', ');
};

export const formatAddressForCompany = company => {
  const { address, city, zip } = company || {};
  const addressTokens = [];
  address && addressTokens.push(address);
  city?.title && addressTokens.push(city.title);
  city?.state?.slug && addressTokens.push(`${city.state.slug} ${zip || ''}`);
  return addressTokens.join(', ');
};
