// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { addFilter } from 'actions/dashboard';
import { addFilter as addFilterPhone } from 'actions/dashboardPhone';
import clsx from 'clsx';
import { useGlobalStyles } from 'GlobalStyles';
import { getColumnsToRender } from 'hooks/datatable';
import { TableToolbar } from 'mui-datatables';
import API from 'services/API';
import strings from 'strings';
import type { Filters, FiltersSection } from 'types/app';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import DataTable from 'UI/components/organisms/DataTable';
import { useDataTableStyles, useTableCard } from 'UI/globalStyles/DataTableStyles';
import { SvgDownloadThickBottom } from 'UI/res';
import { nestTernary } from 'UI/utils';

import { useStyles } from './styles';
import { formatRows } from './SummaryTable.utils';

const { actions: actionsCopies } = strings.dashboard.summaryTable;

type SummaryTableProps = {
  data?: Array,
  downloadFileName: string,
  emptyMessage: string,
  fetching?: boolean,
  lastUpdate?: string,
  onDownloadAll?: () => any,
  onFilterAdd: Filters => any,
  onRowClick: Filters => any,
  showDownloadAllButton?: boolean,
  showToolbar: boolean,
  url: string
};

const filtersConfig = {
  Recruiter: { name: 'recruiter', paramName: 'recruiterId' },
  Coach: { name: 'coach', paramName: 'coachId' }
};

const SummaryTable = ({
  data,
  downloadFileName,
  emptyMessage,
  fetching,
  lastUpdate,
  onDownloadAll,
  onFilterAdd,
  onRowClick,
  showDownloadAllButton,
  showToolbar,
  url
}: SummaryTableProps) => {
  const classes = useStyles();
  const tableClasses = useTableCard();
  const globalClasses = useGlobalStyles();
  const dataTableClasses = useDataTableStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [type, setType] = useState('');

  useEffect(() => {
    typeof fetching === 'boolean' && setIsLoading(fetching);
  }, [fetching]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = !data && url && (await API.get(url));
        if ((response.status === 200 && response.data) || data) {
          const auxData = response ? response.data : data;
          const mappedColumns = auxData?.columns.map(col => {
            const { align: alignDirection, ...restOptions } = col.options;
            const formatOptions = {
              align: {
                right: {
                  setCellProps: () => ({ align: alignDirection }),
                  setCellHeaderProps: () => ({ className: classes.numberHeader })
                }
              }
            };
            return {
              ...col,
              options: {
                ...restOptions,
                ...(formatOptions.align[alignDirection] || {})
              }
            };
          });
          const finalColumns = getColumnsToRender(mappedColumns);
          setColumns(finalColumns);
          setRows(formatRows(auxData.rows));
          if (auxData?.columns?.length > 0) {
            setType(auxData.columns[1].label);
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
      setIsLoading(false);
    })();
  }, [url, data, lastUpdate, classes.numberHeader]);

  const handleRowClick = rowMeta => {
    const row = rows[rowMeta.rowIndex];
    const filterConfig = type && filtersConfig[type];
    if (filterConfig) {
      const filter = { [filterConfig.name]: { paramName: filterConfig.paramName, value: row } };
      onFilterAdd && onFilterAdd(filter);
      onRowClick && onRowClick(filter);
    }
  };

  const handleDownloadAll = () =>
    onDownloadAll && onDownloadAll({ url, fileName: downloadFileName });

  const customTableToolbar = props => (
    <TableToolbar
      classes={{
        root: clsx(tableClasses.toolbar, globalClasses.tableToolbarCard),
        actions: tableClasses.actions
      }}
      {...props}
    />
  );

  const className = clsx(showToolbar && dataTableClasses.withoutToolbar);

  const hasRows = rows?.length > 0;
  const defaultEmptyMessage = strings.dashboard.emptyMessage;

  return isLoading ? (
    <div className={classes.circularProgressContainer}>
      <CircularProgress />
    </div>
  ) : (
    nestTernary(
      hasRows,
      <DataTable
        className={className}
        columns={columns}
        data={rows}
        sort
        isServerSide={false}
        filter={false}
        search={false}
        download
        elevation={0}
        downloadOptions={{
          filename: `${downloadFileName}.csv`
        }}
        textLabels={{
          toolbar: {
            downloadCsv: actionsCopies.downloadCsv
          }
        }}
        components={{ TableToolbar: customTableToolbar }}
        CustomToolbar={
          showDownloadAllButton && (
            <div>
              <FPIconButton
                icon={SvgDownloadThickBottom}
                size="sm"
                className={classes.downloadAll}
                onClick={handleDownloadAll}
                tooltipProps={{ title: actionsCopies.downloadAll, arrow: false }}
              />
            </div>
          )
        }
        viewColumns
        pagination={false}
        selectableRowsHeader={false}
        selectableRows="none"
        onRowClick={handleRowClick}
      />,
      <div className={classes.emptyMessage}>{emptyMessage || defaultEmptyMessage}</div>
    )
  );
};

SummaryTable.defaultProps = {
  data: undefined,
  emptyMessage: undefined,
  fetching: undefined,
  lastUpdate: '',
  onDownloadAll: undefined,
  showDownloadAllButton: false,
  showToolbar: false
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { section } = ownProps;
  return {
    onFilterAdd: filter => dispatch(determineOnSearch(section, filter))
  };
};

const determineOnSearch = (section: FiltersSection, filter: Filters) =>
  section === 'dashboardPhone' ? addFilterPhone(filter) : addFilter(filter);

const SummaryTableConnected = connect(null, mapDispatchToProps)(SummaryTable);

export default SummaryTableConnected;
