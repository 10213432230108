import moment from 'moment';
import { DateFormats } from 'UI/constants/defaults';

const SUBHEADER_OPTIONS = {
  day: 'day',
  week: 'week',
  lastUpdate: 'lastUpdate'
};

export const getSubheader = (item, data) => {
  const { subtitle, subtitleOptions } = item;
  switch (subtitle) {
    case SUBHEADER_OPTIONS.day:
      return moment().format(DateFormats.MonthNumberDay);
    case SUBHEADER_OPTIONS.week: {
      const startWeek = data?.dateRange ? moment(data?.dateRange?.startDate) : moment().weekday(1);
      const endWeek = data?.dateRange ? moment(data?.dateRange?.endDate) : moment().weekday(7);
      return `${startWeek.format(DateFormats.SimpleMonthNumberDay)} - ${endWeek.format(
        DateFormats.SimpleMonthNumberDay
      )}`;
    }
    case SUBHEADER_OPTIONS.lastUpdate:
      return subtitleOptions?.text.replace(
        ':date',
        moment(data[subtitleOptions?.key]).format(DateFormats.SimpleTime)
      );
    default:
      return subtitle;
  }
};

export const separateUrl = url => {
  if (!url) return null;
  const indexParams = url.indexOf('?');
  return {
    baseUrl: indexParams !== -1 ? url.substr(0, indexParams) : url,
    queryParams: indexParams !== -1 ? url.substr(indexParams) : ''
  };
};
