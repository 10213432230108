import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';
import { toolbarAndContentArea } from 'UI/constants/dimensions';
import { colors } from 'UI/res';
import { flexAlignCenterSpaceAround } from 'UI/utils/styles';

export const styles = {
  backgroundImg: {
    zIndex: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    height: `100%`
  },
  button: {
    margin: THEME.spacing(1.5)
  },
  emptyState: {
    whiteSpace: 'pre-line'
  },
  iconSize: {
    size: 16,
    fill: colors.white
  },
  mainTitleOriginal: {
    marginBottom: THEME.spacing(2.5),
    letterSpacing: 0
  },
  gridContainer: {
    marginTop: THEME.spacing(3.5)
  }
};

export const useStyles = makeStyles(
  theme => ({
    backgroundImg: {
      ...styles.backgroundImg
    },
    circularProgress: {
      marginTop: THEME.spacing(5)
    },
    container: {
      width: '100%'
    },
    containerBody: {
      marginTop: theme.spacing(5),
      paddingBottom: theme.spacing(8)
    },
    containerOriginal: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      width: '100%'
    },
    containerQuickActions: {
      margin: theme.spacing(0, 4)
    },
    loadingStateContainer: {
      flex: 1,
      height: `calc(100vh - ${toolbarAndContentArea}px)`,
      flexDirection: 'column',
      textAlign: 'center',
      ...flexAlignCenterSpaceAround
    },
    rootOriginal: {
      zIndex: 10,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%'
    },
    selectorInfoContainer: {
      marginBottom: theme.spacing(2)
    },
    selectorInfoLabel: {
      color: theme.palette.grey[800],
      fontSize: 14,
      opacity: 0.6
    },
    subtitle: {
      fontSize: 18,
      fontWeight: 400,
      letterSpacing: '0.5px',
      lineHeight: `${THEME.spacing(3.5)}px`,
      marginBottom: THEME.spacing(3.5)
    },
    tab: {
      backgroundColor: 'unset',
      overflow: 'hidden',
      padding: theme.spacing(3, 0, 0, 0)
    },
    tabContainer: {
      backgroundColor: 'unset',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    },
    teamSelectorContainer: {
      marginBottom: theme.spacing(3)
    },
    teamTitleContainer: {
      marginBottom: theme.spacing(2.5)
    },
    teamTitleLabel: {
      fontSize: 20,
      fontWeight: '600 !important'
    },
    topSpacing: {
      marginTop: theme.spacing(2)
    }
  }),
  { classNamePrefix: 'homeStyles' }
);
