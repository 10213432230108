// @flow
import React from 'react';
import { Typography } from '@material-ui/core';
import ColorIndicator from 'UI/components/atoms/ColorIndicator';
import { currencyFormatter, numberFormatter } from 'UI/utils';

export type ListBoxesProps = {
  endpoint: string,
  queryParams: string,
  data: Object,
  isLoading: Boolean,
  numberOfColumns: number
};

const conditionalNumberFormatter = value =>
  typeof value === 'number' ? numberFormatter(value) : value;

export const renderer = {
  bold: value => <strong>{conditionalNumberFormatter(value)}</strong>,
  normal: value => <Typography variant="body2">{conditionalNumberFormatter(value)}</Typography>,
  currency: value => currencyFormatter(value, 2),
  currency_bold: value => <strong>{currencyFormatter(value, 2)}</strong>,
  status: (value, style, classes) => (
    <div className={classes.statusRow}>
      {style?.statusColor && <ColorIndicator color={style.statusColor} size={16} />}
      <Typography variant="body2">{value}</Typography>
    </div>
  )
};
