import React, { useState } from 'react';
import { LineSteppedAreaChart, Widget } from 'features/command-center';
import { getActivitySendoutsHistory } from 'features/command-center/services';
import moment from 'moment';
import strings from 'strings';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { DateFormats } from 'UI/constants/defaults';
import { createCustomStaticRanges, getYearToDate } from 'UI/utils';

import { DateRange } from '../../shared/InlineFilters/components/DateRange';

import { formatResponse } from './SendoutsTrends.utils';

const COPIES = strings.sendouts;
const defaultRange = getYearToDate();

export const SendoutsTrends = ({ requestParams = {} }) => {
  const [dateRange, setDateRange] = useState(defaultRange);
  const queryDateRange = {
    startDate: moment(dateRange.startDate).format(DateFormats.QueryFormat),
    endDate: moment(dateRange.endDate).format(DateFormats.QueryFormat)
  };

  const handleDateRangeChange = selectedDateRange => setDateRange(selectedDateRange);

  return (
    <Widget
      header={COPIES.title}
      promises={[
        () =>
          getActivitySendoutsHistory({
            groupBy: 'recruiter',
            ...queryDateRange,
            ...requestParams
          })
      ]}
    >
      {([response]) => {
        const data = formatResponse(response);

        return (
          <>
            <DateRange
              DateRangerPickerProps={{
                staticRanges: createCustomStaticRanges(additionalPeriodRanges)
              }}
              onPeriodChange={handleDateRangeChange}
              defaultRange={dateRange}
            />
            <LineSteppedAreaChart data={data} />
          </>
        );
      }}
    </Widget>
  );
};
