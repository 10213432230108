// @flow
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Alert from '@material-ui/lab/Alert';
import { confirm as confirmAction, showAlert as showAlertAction } from 'actions/app';
import clsx from 'clsx';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { useFetch } from 'hooks/fetch';
import { useSmartTag } from 'hooks/smartTag';
import useScrollToError from 'hooks/useErrorScroll';
import groupBy from 'lodash/groupBy';
import LogRocket from 'logrocket';
import moment from 'moment';
import qs from 'query-string';
import API from 'services/API';
import { getCurrentUser } from 'services/Authentication';
import { canUserEditEntity, userHasRoles } from 'services/Authorization';
import { deleteSendoutById, fetchDuplicates } from 'services/Sendouts';
import strings from 'strings';
/** Tracking */
import FPHint from 'UI/components/atoms/FPHint';
import FPHtmlViewer from 'UI/components/atoms/FPHtmlViewer';
import FPIcon from 'UI/components/atoms/FPIcon';
import TextBox from 'UI/components/atoms/TextBox';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelectConnected, {
  statusRenderOption,
  statusStartAdornment
} from 'UI/components/molecules/AutocompleteSelect';
import EmailPicker from 'UI/components/molecules/EmailPicker';
import FPButtonMenu from 'UI/components/molecules/FPButtonMenu';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import FPTextEditor from 'UI/components/molecules/FPTextEditor';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import InterviewSchedule from 'UI/components/organisms/sendouts/SendoutsInterviews';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { tinymceConfig } from 'UI/constants/config';
import { DateFormats } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { CandidateEntity, EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { LogRocketCustomEvents } from 'UI/constants/LogRocketCustomEvents';
import { Roles } from 'UI/constants/roles';
import { SendoutStatus, SendoutsTypes, SendoverStatus } from 'UI/constants/status';
import { SvgWarning } from 'UI/res';
import {
  getErrorMessage,
  getFeatureFlags,
  mergeHourToDate,
  nestTernary,
  phoneNumberFormatter
} from 'UI/utils';
import { OptionRenderers } from 'UI/utils/renderers';

import ReasonsForm from '../../OptionalSelectorForm';
import SendoutAccountableSection from '../SendoutAccountable';
import SendoutDetails from '../SendoutDetails';
import SendoutDialogs from '../SendoutDialogs';
import SendoutDuplicates from '../SendoutDuplicates';
import { errorSendoutLoad } from '../SendoutErrors';
import SendoutEventLogsSection from '../SendoutEventLogs';
import SendoutFileManageSection from '../SendoutFileManager';
import CandidateFilesSection from '../SendoutFilesSection/candidate';
import DebriefFilesSection from '../SendoutFilesSection/debrief';
import SendoutsDeclineForm from '../SendoutsDeclineForm';
import { SendoutFormStyle } from '../styles';
import {
  applyRestrictionToStatuses,
  applyValueToTemplate,
  buildFileExplorerSections,
  buildRegisterSchema,
  ButtonStates,
  CANDIDATE_INCLUDES,
  CandidateDebrief,
  CandidateFileCategoriesWithSendout,
  candidateTeamHasPermission,
  EmployerDebrief,
  ExcludeOtherFilesCandidate,
  FileKeyPrefix,
  filesForCandidate,
  filesForSendout,
  findAccountableOnJobOrder,
  formatAttachmentstoSaveSendout,
  formattedTagsWithValues,
  formNames,
  getAdditionalsRecruitersFormated,
  getCategoriesFiles,
  getCurrentJobOrderOwner,
  getFullDisplayHA,
  getMergedDateTimeTemplate,
  initialStatus,
  OfferLetter,
  OriginalCandidateFileCategories,
  OtherFileCategoryId,
  PortfolioFileCategoryId,
  ReasonsSendover,
  ResumeFileCategoryId,
  SendoutActiveStatuses,
  SendoutConfig,
  SendoutFileCategoriesWithSendout,
  SendoutPlacementStatuses,
  SendoutRolesFullPermissions,
  SendoutsStatusesFullEdit,
  SendoutState,
  SendoverSwitchTypes,
  STATUS_DISPLAY_SECTIONS,
  StatusToDeclinate,
  SwitchSendoverOptions,
  TYPE_DISPLAY_SECTIONS,
  ValidCategoriesForCandidateFiles,
  ValidCategoriesForSendoutFiles
} from '../utils';

const {
  shared: {
    ui: { keepRemoveButtonsCopies }
  },
  sendouts: {
    switchingToSendEmailCopies: {
      isWithoutMailCopies: { isWithoutMailTitleCopy, sendEmailCopy, notSendEmailCopy }
    },
    noEmailSendCopies,
    duplicates: {
      alert: { title: sendoutDuplicateCopy },
      drawer: { title: drawerTitleDuplicateCopy, subtitle: drawerSubtitleDuplicateCopy },
      fetch: { title: fetchTitleDuplicateCopy, message: fetchMessageDuplicateCopy }
    },
    showConfirmWithEmailCopies,
    showConfirmWithoutEmailCopies,
    showConfirmEditCopies,
    showConfirmNoFilesWarningSendoutCopies,
    showConfirmNoFilesWarningSendoverCopies,
    showConfirmDeleteCopies,
    tagsAndSignatureCopy,
    emailTemplateCopy,
    createdAlert,
    updatedAlert,
    deletedAlert,
    canNotDeletedAlert,
    showConfirmResendEmail
  }
} = strings;

type SendoutsFormProps = {
  profile: any,
  sendoutData: any,
  showAlert: any => void,
  onCreate: any => void,
  onEdit: any => void,
  onClose: any => void,
  onDelete: any => void,
  onDuplicateSendoutSelected: any => void,
  readOnly: boolean
};

const endpointSendoverStatus = `${Endpoints.SendoutsStatus}?type=${SendoutsTypes.Sendover}`;
const endpointSendoutStatus = `${Endpoints.SendoutsStatus}?type=${SendoutsTypes.Sendout}`;

const getSaveEndpointBySendoutType = (type, jobOrderId) => {
  return type === SendoutsTypes.Sendout
    ? Endpoints.JobOrderSendouts.replace(':id', jobOrderId)
    : Endpoints.JobOrderSendovers.replace(':id', jobOrderId);
};

const getSendoutTypeTitle = (type, sendoutsOptions) =>
  sendoutsOptions && type
    ? sendoutsOptions.find(each => each.id === type).title
    : SendoutConfig.defaultTypeOfSendout;

const shouldUseCategoryFilesSendout = getFeatureFlags().includes(FeatureFlags.CategoryFilesSendout);
const shouldUseCategoryFilesSendoutDebrief = getFeatureFlags().includes(
  FeatureFlags.CategoryFilesSendoutDebrief
);

const SendoutsForm = ({
  profile,
  sendoutData,
  showAlert,
  showConfirm,
  onCreate,
  onEdit,
  onClose,
  onDelete,
  onNavigate,
  onDuplicateSendoutSelected,
  readOnly
}: SendoutsFormProps) => {
  const editorRef = useRef(null);
  const classes = SendoutFormStyle();
  const currentUser = getCurrentUser();
  const shouldSendEmailToHiringAuthority = userHasRoles([Roles.Operations]);

  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [candidateFiles, setCandidateFiles] = useState([]);

  const [typeOfSendout, setTypeOfSendout] = useState(sendoutData?.type?.id || null);
  const [allBccs, setAllBccs] = useState({
    defaultBccs: [],
    JOBccs: [],
    candidateBcc: [],
    candidateOwnerBcc: []
  });

  const [additionalRecruiters, setAdditionalRecruiters] = useState([]);
  const [templateTags, setTemplateTags] = useState({});
  const [mailTemplate, setMailTemplate] = useState({});
  const [duplicates, setDuplicates] = useState([]);

  const [sendoutStatuses, sendoutStatusError] = useFetch(endpointSendoutStatus, []);
  const [sendoverStatuses, sendoverStatusError] = useFetch(endpointSendoverStatus, []);
  const [sendoutsOptions, sendoutsOptionsError] = useFetch(Endpoints.SendoutsTypes);
  const [sendoutTemplates, sendoutTemplatesError] = useFetch(
    `${Endpoints.SendoutsTemplates}?version=2`,
    []
  );

  const [allFileCategories] = useFetch(
    profile ? `${Endpoints.FileCategories}?module=sendout&sort=order:asc` : '',
    []
  );

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false,
    isEditing: false,
    isModalOpen: false,
    isError: false,
    isFormDisabled: true,
    isFormLoading: true,
    isEditingMail: false,
    isSendoverToSendout: false,
    isNotInterview: false,
    showDeclinedSection: false,
    showDuplicateSection: false,
    showViewLogSection: false
  });

  const [updateSendoverStatus, setUpdateSendoverStatus] = useState(false);

  const defaultFormValues = { ...sendoutData, attachments: [] } || {};
  const form = useForm({
    defaultValues: defaultFormValues
  });
  const { register, handleSubmit, errors, setValue, getValues, unregister, watch } = form;
  const { sendover_decline, sendover_notes } = watch(['sendover_decline', 'sendover_notes']);
  const watcher = watch();
  const { hiring_authority, template, cc_emails } = watcher;

  useScrollToError({ errors });

  const availableSendoutsStatuses = applyRestrictionToStatuses(sendoutStatuses);
  const selectedStatuses =
    typeOfSendout === SendoutsTypes.Sendout ? availableSendoutsStatuses : sendoverStatuses;

  const typeOfTags =
    typeOfSendout === SendoutsTypes.Sendout
      ? SendoutConfig.sendoutTemplateTags
      : SendoutConfig.sendoverTemplateTags;

  const handleChangeMailValue = useCallback(
    newTemplate => {
      setValue(formNames.emailBody.name, newTemplate);
    },
    [setValue]
  );

  const { setTemplate } = useSmartTag(mailTemplate?.html || '', typeOfTags, handleChangeMailValue);

  const handleChangeValue = handleChangeValueProto(setValue, setUiState);

  const loadCandidateData = useCallback(
    async id => {
      try {
        setUiState(prev => ({
          ...prev,
          isLoadingCandidate: true,
          isFormDisabled: true
        }));

        const queryParams = qs.stringify(
          {
            includes: CANDIDATE_INCLUDES
          },
          { arrayFormat: 'comma' }
        );

        const response = await API(`${Endpoints.Candidates}/${id}?${queryParams}`, {
          apiVersion: 2
        });

        if (response.status !== 200) {
          setCandidateFiles([]);
          return null;
        }
        const allRecruiters = getAdditionalsRecruitersFormated({
          candidate: response.data
        });

        setValue(formNames.candidateAccountable.name, null);
        setAdditionalRecruiters(allRecruiters);
        setCandidateFiles(response.data.files);

        return response;
      } catch (ex) {
        showAlert({
          severity: 'error',
          title: 'Error',
          body: getErrorMessage(ex)
        });
        setSelectedCandidate(null);
        setValue(formNames.candidate.name, null);
        return null;
      } finally {
        setUiState(prev => ({
          ...prev,
          isLoadingCandidate: false,
          isFormDisabled: false
        }));
      }
    },
    [setValue, showAlert]
  );

  useEffect(() => {
    const allCc = getValues(formNames.cc.name);
    const everyBcc = [
      ...allBccs.defaultBccs,
      ...allBccs.JOBccs,
      ...allBccs.candidateBcc,
      ...allBccs.candidateOwnerBcc
    ]
      .map(item => (typeof item === 'string' ? item : item?.work_email))
      .filter(
        item => allCc && !allCc.some(each => each.work_email.toLowerCase() === item.toLowerCase())
      )
      .filter(item => item);
    const clearedBcc = [...new Set(everyBcc)].map(item => ({
      work_email: item
    }));
    setValue(formNames.bcc.name, clearedBcc);
  }, [allBccs, setValue, getValues, cc_emails]);

  /**
   * Effect to handle loading form.
   * Here I check if has permisions to edit sendout.
   */
  const canUserEdit =
    canUserEditEntity(currentUser, profile, { includeCollaborator: true }) ||
    userHasRoles(SendoutRolesFullPermissions) ||
    candidateTeamHasPermission(
      currentUser?.id,
      sendoutData?.candidate,
      sendoutData?.candidateAccountable?.id
    );

  useEffect(() => {
    const isDisabled = !canUserEdit || readOnly;
    if (sendoutStatuses && sendoverStatuses && sendoutsOptions) {
      setUiState(prev => ({
        ...prev,
        isFormLoading: false,
        isFormDisabled: isDisabled,
        isError: false
      }));
    }
  }, [
    canUserEdit,
    profile,
    readOnly,
    sendoutData,
    sendoutStatuses,
    sendoutsOptions,
    sendoverStatuses
  ]);

  useEffect(() => {
    sendoutStatusError || sendoutsOptionsError || sendoverStatusError || sendoutTemplatesError
      ? setUiState(prev => ({ ...prev, isError: true, isFormDisabled: true }))
      : setUiState(prev => ({
          ...prev,
          isError: false,
          isFormDisabled: false
        }));
  }, [sendoutStatusError, sendoutsOptionsError, sendoverStatusError, sendoutTemplatesError]);

  const initializeFormRules = useCallback(() => {
    Object.entries(formNames).forEach(([, propValue]) => {
      if (propValue.desactiveIf) return;
      const { ref, validation } = buildRegisterSchema(propValue);
      register(ref, validation);
    });
  }, [register]);

  /**
   * Effect change the rules of the form in creation.
   */
  useEffect(() => {
    Object.entries(formNames).forEach(([, propValue]) => {
      if (!propValue.desactiveIf) return;

      if (propValue.desactiveIf === typeOfSendout) {
        unregister(propValue.name);
        return;
      }
      const { ref, validation } = buildRegisterSchema(propValue);
      register(ref, validation);

      if (shouldUseCategoryFilesSendout) {
        register(`${FileKeyPrefix}${ResumeFileCategoryId}`);
        register(`${FileKeyPrefix}${PortfolioFileCategoryId}`);
        register(`${FileKeyPrefix}${OtherFileCategoryId}`);
      }

      if (shouldUseCategoryFilesSendoutDebrief) {
        register(`${FileKeyPrefix}${CandidateDebrief}`);
        register(`${FileKeyPrefix}${EmployerDebrief}`);
        register(`${FileKeyPrefix}${OfferLetter}`);
      }
    });
  }, [register, setValue, typeOfSendout, unregister]);

  /**
   * Initialize the principal form
   */
  useEffect(() => {
    initializeFormRules();

    return () => {
      Object.entries(formNames).forEach(([, propValue]) => {
        unregister(propValue.name);
      });
    };
  }, [initializeFormRules, register, unregister]);

  /** handleChanges between typeOfSendouts */
  useEffect(() => {
    const scopedChangeValue = handleChangeValueProto(setValue, setUiState);

    if (shouldUseCategoryFilesSendout && sendoutData) {
      const groupedFiles = groupBy(sendoutData?.attachments, 'file_type_id');
      ValidCategoriesForCandidateFiles.forEach(categoryId => {
        const fieldName = `${FileKeyPrefix}${categoryId}`;
        setValue(fieldName, groupedFiles[CandidateFileCategoriesWithSendout[categoryId]]);
      });
    }

    if (shouldUseCategoryFilesSendoutDebrief && sendoutData) {
      const groupedFiles = groupBy(sendoutData?.attachments, 'file_type_id');
      ValidCategoriesForSendoutFiles.forEach(categoryId => {
        const fieldName = `${FileKeyPrefix}${categoryId}`;
        setValue(fieldName, groupedFiles[SendoutFileCategoriesWithSendout[categoryId]]);
      });
    }

    if (sendoutData) return;
    if (typeOfSendout)
      setMailTemplate(sendoutTemplates.find(item => item.sendout_type_id === typeOfSendout));

    const selectedDefaultStatus =
      typeOfSendout === SendoutsTypes.Sendout ? sendoutStatuses[0] : sendoverStatuses[0];

    scopedChangeValue(selectedDefaultStatus || initialStatus, formNames.status);
    scopedChangeValue(null, formNames.emailSubject, false, null, false);
    scopedChangeValue(null, formNames.candidate, false, setSelectedCandidate, false);
    scopedChangeValue([], formNames.cc, false, null, false);
  }, [
    profile,
    sendoutData,
    sendoutStatuses,
    sendoverStatuses,
    setValue,
    typeOfSendout,
    sendoutTemplates,
    register
  ]);

  const setCandidateTags = useCallback(
    candidate => {
      const {
        email,
        personalInformation: {
          full_name,
          first_name,
          contact: { phone, mobile }
        },
        phone_id,
        phones
      } = candidate;

      const defaultPhone =
        phone_id && phones && phones.find(item => item.id === phone_id)?.phone?.phone;
      const candidatePhone = phoneNumberFormatter(defaultPhone || phone || mobile);

      setTemplateTags(prev => ({
        ...prev,
        candidateName: full_name,
        candidatePhone,
        candidateFirstName: first_name,
        candidateEmail: email
      }));
    },
    [setTemplateTags]
  );

  const checkForDuplicates = useCallback(async () => {
    const getDuplicates = async (params, isConvertSendout) => {
      const result = await fetchDuplicates(params, {
        successBody: strings.formatString(fetchTitleDuplicateCopy),
        successTitle: strings.formatString(fetchMessageDuplicateCopy)
      });

      if (result.success) {
        const { data } = result.data;
        const dataResult = isConvertSendout
          ? data.filter(item => item.sendout_type_id === SendoutsTypes.Sendout)
          : data;

        setDuplicates(dataResult);
        setUiState(prev => ({
          ...prev,
          isFormDisabled: dataResult.length > 0
        }));
      }
    };

    const params = {
      job_order_id: profile.id,
      candidate_id: selectedCandidate.id
    };

    await getDuplicates(params, uiState.isSendoverToSendout);
  }, [uiState.isSendoverToSendout, profile, selectedCandidate]);

  /**
   * Fetch change of candidate to update UI
   */
  useEffect(() => {
    /** Handle the change of candidate on form */
    const handleCandidateChange = async () => {
      const candidateData = await loadCandidateData(selectedCandidate.id);
      if (!candidateData) return;

      if (shouldUseCategoryFilesSendout && !uiState.isEditing && !uiState.isSendoverToSendout) {
        const groupedFiles = groupBy(
          ExcludeOtherFilesCandidate(candidateData.data.files),
          'file_type_id'
        );
        ValidCategoriesForCandidateFiles.forEach(categoryId => {
          const fieldName = `${FileKeyPrefix}${categoryId}`;
          setValue(fieldName, groupedFiles[CandidateFileCategoriesWithSendout[categoryId]]);
        });
      }

      if (candidateData.data) setCandidateTags(candidateData.data);

      const {
        personalInformation,
        recruiter,
        additionalRecruiters: candidateAdditionalRecruiters,
        coach,
        specialty
      } = candidateData.data;

      const emailSubject = `${mailTemplate?.subject || ''} ${personalInformation?.full_name || ''}`;
      setValue(formNames.emailSubject.name, emailSubject, true);

      const recruiterAccountable = {
        ...recruiter?.personalInformation,
        ...recruiter
      };

      const isMultipleRecruiters = candidateAdditionalRecruiters.length > 0;
      if (!isMultipleRecruiters)
        setValue(formNames.candidateAccountable.name, recruiterAccountable);

      const currentBcc = [];
      const emailTeam = coach?.email_team;
      emailTeam && currentBcc.push({ work_email: emailTeam });

      const emailSpecialty = specialty?.industry?.email;
      emailSpecialty && currentBcc.push({ work_email: emailSpecialty });

      const emailValuesStr = currentBcc.map(item => item.work_email);
      const emailValuesUniques = [...new Set(emailValuesStr)];
      const emailValuesFormatted = emailValuesUniques.map(item => ({
        work_email: item
      }));

      const candidateOwnerEmail = [];
      !isMultipleRecruiters &&
        candidateOwnerEmail.push({
          work_email: recruiter?.email
        });

      setAllBccs(prev => ({
        ...prev,
        candidateBcc: emailValuesFormatted,
        candidateOwnerBcc: candidateOwnerEmail
      }));

      const enabledDuplicates = getFeatureFlags().includes(FeatureFlags.SendoutDuplicates);
      if (enabledDuplicates && !uiState.isEditing) await checkForDuplicates();
    };

    const isEditingMailToSend = !uiState.isEditing || uiState.isEditingMail;
    if (mailTemplate?.id && isEditingMailToSend && selectedCandidate) handleCandidateChange();

    if (!selectedCandidate) {
      setAllBccs(prev => ({
        ...prev,
        candidateBcc: [],
        candidateOwnerBcc: []
      }));

      setDuplicates([]);
      setUiState(prev => ({
        ...prev,
        isFormDisabled: false
      }));
    }
  }, [
    selectedCandidate,
    setValue,
    loadCandidateData,
    mailTemplate,
    uiState.isEditing,
    uiState.isEditingMail,
    uiState.isSendoverToSendout,
    setCandidateTags,
    checkForDuplicates
  ]);

  // Change form state between edit or create
  useEffect(() => {
    const updateChanges = () => {
      if (!sendoutData) return;
      setSelectedCandidate(sendoutData.candidate_id);
    };
    updateChanges();
    setUiState(prev => ({ ...prev, isEditing: !!sendoutData }));
  }, [register, sendoutData, setValue]);

  useEffect(() => {
    const handleCloseWindow = () => {
      return 'You will lose the changes';
    };

    window.onbeforeunload = handleCloseWindow;
    return () => {
      window.onbeforeunload = null;
      window.removeEventListener('close', handleCloseWindow);
    };
  }, []);

  // Filling default Bcc's from joborder and defaults templates
  useEffect(() => {
    (() => {
      if (!mailTemplate) return;
      // check if edit or create to add template normal or current template
      setTemplate(mailTemplate?.html || sendoutData?.template);
      setTemplateTags(prev => ({
        ...prev,
        companyName: profile?.company?.name,
        jobOrderTitle: profile?.title
      }));

      const currentRecruiter = getCurrentJobOrderOwner(
        profile?.recruiter,
        profile?.additionalRecruiters
      );
      const { recruiter, coach, specialty } = currentRecruiter;
      const selectedRecruiter = recruiter || currentRecruiter;
      selectedRecruiter.coach = profile?.coach;
      let defaultBcc = [];

      const JOIndustryMail = specialty
        ? specialty?.industry?.email
        : profile?.specialty?.industry?.email;
      JOIndustryMail && defaultBcc.push(JOIndustryMail);

      defaultBcc = [...new Set(defaultBcc)]; // Remove duplicates
      defaultBcc = defaultBcc.map(item => ({ work_email: item }));

      setAllBccs(prev => ({
        ...prev,
        defaultBccs: defaultBcc,
        JOBccs: sendoutData?.emailDetails?.bcc_emails || []
      }));

      if (!sendoutData) {
        const accountableBcc = [];

        const joRecruiterMail = selectedRecruiter?.email;
        joRecruiterMail && accountableBcc.push(joRecruiterMail);

        const joTeamMail = coach ? coach?.email_team : profile?.coach?.email_team;
        joTeamMail && accountableBcc.push(joTeamMail);

        setAllBccs(prev => ({
          ...prev,
          defaultBccs: defaultBcc,
          JOBccs: accountableBcc || []
        }));

        setValue(formNames.jobOrderAccountable.name, selectedRecruiter);
      } else {
        /** Support to old's Sendouts without Accountable Section */
        const accountableExist = findAccountableOnJobOrder(
          profile,
          sendoutData?.jobOrderAccountable?.id
        );
        const currentAccountable = accountableExist || sendoutData?.jobOrderAccountable;
        setValue(formNames.jobOrderAccountable.name, currentAccountable);

        /** Fill Additional Recruiter box */
        const allAdditionalRecruiters = getAdditionalsRecruitersFormated(sendoutData);

        const selectedAdRecruiter = allAdditionalRecruiters.find(
          rcr => rcr?.id === sendoutData?.candidateAccountable?.id
        );
        setAdditionalRecruiters(allAdditionalRecruiters);

        const currentCandidateAccountable =
          selectedAdRecruiter || sendoutData?.candidateAccountable;

        setValue(formNames.candidateAccountable.name, currentCandidateAccountable);

        const emailSubject = `${mailTemplate?.subject || ''} ${
          sendoutData?.candidate?.personalInformation?.full_name || ''
        }`;
        const currentSubject = mailTemplate?.subject
          ? emailSubject
          : sendoutData?.emailDetails?.subject;
        setValue(formNames.emailSubject.name, currentSubject, true);

        if (sendoutData.candidate) setCandidateTags(sendoutData.candidate);

        const hiringAuthority =
          sendoutData?.hiringAuthorithies?.[0]?.hiringAuthority || sendoutData?.hiringAuthority;
        setTemplateTags(prev => ({
          ...prev,
          HAName: hiringAuthority?.first_name,
          HAFullName: hiringAuthority?.full_name,
          HAEmail: hiringAuthority?.work_email
        }));
      }
    })();
  }, [mailTemplate, profile, sendoutData, setValue, setTemplate, setCandidateTags]);

  const getMaxInterviews = () =>
    sendoutData
      ? nestTernary(
          sendoutData.status?.id !== 1,
          sendoutData.interviews?.length,
          SendoutConfig.maxInterviews
        )
      : 1;
  const isInterviewDisabled = () =>
    !!(
      sendoutData &&
      sendoutData?.status?.id !== SendoutStatus.Active &&
      sendoutData?.type?.id !== SendoutsTypes.Sendover
    ) || uiState.isNotInterview;
  const isWithoutMail =
    uiState.isSendoverToSendout && uiState.typeOfSendover === SendoverSwitchTypes.NoSendMail;

  /** Email conditions */
  const onlyOnCreation = !sendoutData && typeOfSendout !== SendoutsTypes.Sendover;
  const isEditingEmail = sendoutData?.send_email_hiring === false || !uiState.isEditing;
  const shouldEditEmail =
    sendoutData?.send_email_hiring === false && !uiState.isEditingMail && !!sendoutData;

  const replaceInterviewTag = (data: any) => {
    if (!data) return;

    const firstInterview = { ...data[data.length - 1] };
    if (!firstInterview) return;

    if (
      !firstInterview.interview_time_zone ||
      !firstInterview.interview_date ||
      !firstInterview.interview_type_id
    )
      return;

    const dateTemplate = getMergedDateTimeTemplate(
      firstInterview,
      uiState.isEditing,
      uiState.isSendoverToSendout
    );

    setTemplateTags(prev => ({
      ...prev,
      interviews: dateTemplate
    }));
  };

  const getAttachments = data => {
    if (uiState.isSendoverToSendout) return data.attachments;
    if (uiState.isEditingMail) return sendoutData?.attachments;
    return data.attachments;
  };

  const getAttachmentsV2 = data => {
    return [
      ...(data[`${FileKeyPrefix}${ResumeFileCategoryId}`] || []),
      ...(data[`${FileKeyPrefix}${PortfolioFileCategoryId}`] || []),
      ...(data[`${FileKeyPrefix}${OtherFileCategoryId}`] || [])
    ];
  };

  const getAttachmentsDebrief = data => {
    return [
      ...(data[`${FileKeyPrefix}${CandidateDebrief}`] || []),
      ...(data[`${FileKeyPrefix}${EmployerDebrief}`] || []),
      ...(data[`${FileKeyPrefix}${OfferLetter}`] || [])
    ];
  };

  /**
   * Method where the form data is arrive.
   */
  const onSubmitForm = async data => {
    let isCreation = true;
    const isSendout = typeOfSendout === SendoutsTypes.Sendout;

    try {
      setUiState(prev => ({ ...prev, isSaving: true }));

      const attachments = shouldUseCategoryFilesSendout
        ? getAttachmentsV2(data)
        : formatAttachmentstoSaveSendout(getAttachments(data), sendoutData?.attachments);

      const attachmentsDebrief = shouldUseCategoryFilesSendoutDebrief
        ? getAttachmentsDebrief(data)
        : [];

      // Specific schema needed to create Sendout or Sendover.
      let dataToSend = {
        ...data,
        [formNames.candidateAccountable.name]: data[formNames.candidateAccountable.name]?.id,
        [formNames.jobOrderAccountable.name]: data[formNames.jobOrderAccountable.name]?.id,
        job_order_id: profile.id,
        job_order_title: profile.title,
        candidate_email: data.candidate_id.email,
        candidate_id: sendoutData?.candidate?.id || data.candidate_id.id,
        candidate_full_name: data.candidate_id.title,
        company_name: profile?.company?.name,
        company_id: profile?.company?.id,
        coach_name: profile?.coach?.full_name,
        hiring_authorities: [data.hiring_authority],
        template_id: mailTemplate?.id,
        cc_emails: data.cc_emails.map(item => item.work_email),
        bcc_emails: data.bcc_emails.map(item => item.work_email),
        status_id: parseInt(data.status_id.id, 10),
        interviews: data.interviews?.map(item => ({
          ...item,
          interview_date: item.interview_range
            ? item.interview_date
            : mergeHourToDate(item.interview_date, moment(item.hour_fixed))
        })),
        type_id: typeOfSendout,
        attachments,
        attachmentsDebrief
      };

      if (uiState.typeOfSendover !== null)
        dataToSend = { ...dataToSend, send_email: uiState.typeOfSendover };

      if (typeOfSendout === SendoutsTypes.Sendout) {
        /** Formating Interview schema and data to send backend. */
        const sendoutInterviews = data.interviews?.map(item => ({
          ...item,
          interview_time_zone: item?.interview_time_zone?.name,
          interview_range: item?.interview_range || null,
          interview_date: moment(item?.interview_date).format(DateFormats.QueryFormat),
          interview_type_id: parseInt(item?.interview_type_id, 10)
        }));

        dataToSend = {
          ...dataToSend,
          fee_amount: parseInt(data.fee_amount, 10),
          interviews: sendoutInterviews,
          send_email: !data.send_email
        };
      } else if (typeOfSendout === SendoutsTypes.Sendover) {
        dataToSend = {
          ...dataToSend,
          declination_details: {
            declination_notes: sendover_notes,
            declined_fields: sendover_decline
          },
          fee_amount: parseInt(data.fee_amount, 10),
          status_id: data.status_id?.id || SendoverStatus.Active,
          send_email: true
        };
      }

      if (sendoutData) {
        await handleConfirmEditData(dataToSend);
        isCreation = false;
      } else {
        const selectDialog = dataToSend.send_email ? showConfirmWithEmail : showConfirmWithoutEmail;

        const hasFilesToSend = shouldUseCategoryFilesSendout
          ? dataToSend.attachments.length > 0
          : dataToSend.attachments.toSend.length > 0;
        const displayDialog = isSendout
          ? nestTernary(hasFilesToSend, selectDialog, showNoFilesWarningSendout)
          : nestTernary(hasFilesToSend, showConfirmWithEmail, showNoFilesWarningSendover);

        displayDialog(dataToSend, handlePostData);
      }
    } catch (ex) {
      getErrorAlert(ex);
      if (isCreation) {
        LogRocket.track(
          isSendout
            ? LogRocketCustomEvents.Sendout.FailedCreation
            : LogRocketCustomEvents.Sendover.FailedCreation
        );
      } else {
        const failedEditionEvent = isSendout
          ? LogRocketCustomEvents.Sendout.FailedEdition
          : LogRocketCustomEvents.Sendover.FailedEdition;

        LogRocket.track(
          uiState.isSendoverToSendout
            ? LogRocketCustomEvents.Sendover.FailedConvertion
            : failedEditionEvent
        );
      }
    } finally {
      setUiState(prev => ({ ...prev, isSaving: false, isSuccess: false }));
    }
  };

  const handleConfirmEditData = async (data: any) => {
    const { interviews: interviewsToEdit, status_id, declined_fields, declination_notes } = data;
    const isSendout = typeOfSendout === SendoutsTypes.Sendout;

    const updateData = {
      ...data,
      status_id,
      send_email: uiState.isEditingMail,
      declination_details: {
        declined_fields,
        declination_notes
      }
    };

    const isSavedDataPlacement = SendoutPlacementStatuses.some(each => each === status_id);
    const isCurrentSendoutPlaced = SendoutPlacementStatuses.some(
      each => each === sendoutData?.status?.id
    );
    if (isSavedDataPlacement && !isCurrentSendoutPlaced) {
      showConfirm({
        ...showConfirmEditCopies,
        mustComplete: true,
        onClose: () => setValue(formNames.status.name, sendoverStatuses[0]),
        onConfirm: async ok => {
          try {
            ok && (await handlePutData(updateData));
          } catch (ex) {
            getErrorAlert(ex);
          }
        }
      });
      return;
    }

    if (status_id === SendoutStatus.Active) {
      const { candidate_full_name, candidate_email, company_name, coach_name, attachments } = data;
      const newInterviews = interviewsToEdit.filter(record => !record.id);
      const updatedInterviews = interviewsToEdit.filter(record => record.id && record.edited);
      const formattedDataToUpdate = {
        ...updateData,
        candidate_full_name,
        candidate_email,
        company_name,
        interviews: newInterviews,
        updated_interviews: updatedInterviews,
        coach_name,
        hiring_authorities: [getValues(formNames.hiringAuthority.name)]
      };

      if (uiState.isEditing && !uiState.isEditingMail) {
        await handlePutData(formattedDataToUpdate);
        return;
      }
      const hasFilesToSend = shouldUseCategoryFilesSendout
        ? attachments.length > 0
        : attachments?.candidate.length > 0 || attachments?.toSend.length > 0;
      const displayFilesDialog = isSendout ? showNoFilesWarningSendout : showNoFilesWarningSendover;
      const displayDialog = uiState.isEditingMail
        ? nestTernary(hasFilesToSend, showConfirmWithEmail, displayFilesDialog)
        : showConfirmWithoutEmail;
      displayDialog(formattedDataToUpdate, handlePutData);
      return;
    }

    await handlePutData(updateData);
  };

  const showNoFilesWarningSendout = (data, callback) => {
    const displayDialog = data.send_email ? showConfirmWithEmail : showConfirmWithoutEmail;

    showConfirm({
      cancelButtonText: strings.shared.ui.cancel,
      ...showConfirmNoFilesWarningSendoutCopies,
      closeDialogOnConfirm: false,
      onConfirm: () => displayDialog(data, callback),
      onError: ex => getErrorAlert(ex)
    });
  };

  const showNoFilesWarningSendover = (data, callback) =>
    showConfirm({
      cancelButtonText: strings.shared.ui.cancel,
      ...showConfirmNoFilesWarningSendoverCopies,
      closeDialogOnConfirm: false,
      onConfirm: () => showConfirmWithEmail(data, callback),
      onError: ex => getErrorAlert(ex)
    });

  const previewFormattedTemplate = previewTemplate => {
    const tagsWithValues = formattedTagsWithValues(typeOfTags, templateTags);
    return applyValueToTemplate(previewTemplate, tagsWithValues);
  };

  const getFormattedTemplateAndTags = data => {
    const tagsOfTemplateWithValues = {
      HAName: data?.hiring_authority?.first_name,
      HAFullName: data?.hiring_authority?.full_name,
      HAEmail: data?.hiring_authority?.work_email,
      jobOrderTitle: data?.job_order_title,
      companyName: data?.company_name,
      candidateName: data?.candidate_full_name,
      ...templateTags
    };

    const tagsWithValues = formattedTagsWithValues(typeOfTags, tagsOfTemplateWithValues);
    const templateWithValues = applyValueToTemplate(data.template, tagsWithValues);
    const tagValues = Object.entries(tagsWithValues).map(([, propValue]) => propValue);

    return { templateWithValues, tagValues };
  };

  const showConfirmWithEmail = (dataToPost, callback) => {
    const { templateWithValues, tagValues } = getFormattedTemplateAndTags(dataToPost);

    return showConfirm({
      ...showConfirmWithEmailCopies,
      mustComplete: true,
      closeDialogOnConfirm: true,
      body: <SendoutDialogs.Verification body={templateWithValues} tags={[...tagValues]} />,
      onConfirm: async ok => {
        if (!ok) return;
        setUiState(prev => ({ ...prev, isSaving: true }));
        callback(dataToPost);
      },
      onError: ex => getErrorAlert(ex)
    });
  };

  const showConfirmWithoutEmail = (dataToPost, callback) => {
    return showConfirm({
      ...showConfirmWithoutEmailCopies,
      mustComplete: true,
      closeDialogOnConfirm: true,
      body: (
        <SendoutDialogs.Confirmation
          company={profile?.company?.name}
          jobOrder={profile?.title}
          accountable={watcher[formNames.jobOrderAccountable.name]?.personalInformation?.full_name}
          candidate={selectedCandidate.title}
          candidateRecruiter={
            watcher[formNames.candidateAccountable.name]?.personalInformation?.full_name
          }
        />
      ),
      onConfirm: async ok => {
        if (!ok) return;
        setUiState(prev => ({ ...prev, isSaving: true }));
        callback(dataToPost);
      },
      onError: ex => getErrorAlert(ex)
    });
  };

  const getErrorAlert = ex => {
    showAlert({
      severity: 'error',
      title: ex?.response?.data?.title || getSendoutTypeTitle(typeOfSendout, sendoutsOptions),
      body: getErrorMessage(ex),
      autoHideDuration: 8000
    });
    showConfirm(null);
    setUiState(prev => ({ ...prev, isFormDisabled: true }));
  };

  const handlePutData = async (data: any) => {
    const endpointToUpdate = uiState.isSendoverToSendout
      ? Endpoints.SendoverConvert.replace(':id', sendoutData.id)
      : `${Endpoints.Sendouts}/${sendoutData.id}`;

    const { templateWithValues } = getFormattedTemplateAndTags(data);
    const formData = { ...data, template: templateWithValues };

    try {
      const updateService = await API.put(endpointToUpdate, formData);
      if (updateService?.data) {
        const isSendout = typeOfSendout === SendoutsTypes.Sendout;
        const successfulEditionEvent = isSendout
          ? LogRocketCustomEvents.Sendout.SuccessfulEdition
          : LogRocketCustomEvents.Sendover.SuccessfulEdition;

        LogRocket.track(
          uiState.isSendoverToSendout
            ? LogRocketCustomEvents.Sendover.SuccessfulConvertion
            : successfulEditionEvent
        );
        showAlert({
          severity: 'success',
          title: strings.formatString(updatedAlert.title, {
            type: getSendoutTypeTitle(typeOfSendout, sendoutsOptions)
          })
        });
      }

      onEdit(updateService.data);
      setSelectedCandidate(null);
      onClose();
    } catch (error) {
      setUiState(prev => ({ ...prev, isSaving: false }));
      getErrorAlert(error);
    }
  };

  const handlePostData = async (data: any) => {
    const { templateWithValues } = getFormattedTemplateAndTags(data);
    const formData = { ...data, template: templateWithValues };

    const endpointToSave = getSaveEndpointBySendoutType(typeOfSendout, profile.id);

    try {
      const saveService = await API.post(endpointToSave, formData);
      if (saveService?.data) {
        setUiState(prev => ({ ...prev, isSaving: false, isSuccess: true }));
        showAlert({
          severity: 'success',
          title: strings.formatString(createdAlert.title, {
            type: getSendoutTypeTitle(typeOfSendout, sendoutsOptions)
          })
        });

        LogRocket.track(
          typeOfSendout === SendoutsTypes.Sendout
            ? LogRocketCustomEvents.Sendout.SuccessfulCreation
            : LogRocketCustomEvents.Sendover.SuccessfulCreation
        );

        onCreate(saveService.data.data);
        onClose();
      }
    } catch (error) {
      setUiState(prev => ({ ...prev, isSaving: false }));
      getErrorAlert(error);
    }
  };

  const handleDeleteData = async () => {
    if (!sendoutData) return;

    showConfirm({
      ...showConfirmDeleteCopies,
      ...keepRemoveButtonsCopies,
      mustComplete: true,
      onConfirm: async ok => {
        if (!ok) return;
        const request = await deleteSendoutById(sendoutData.id);

        showAlert(
          request.success
            ? {
                severity: 'success',
                title: strings.formatString(deletedAlert.title, {
                  type: getSendoutTypeTitle(typeOfSendout, sendoutsOptions)
                })
              }
            : {
                ...request.alert,
                title: strings.formatString(canNotDeletedAlert.title, {
                  type: getSendoutTypeTitle(typeOfSendout, sendoutsOptions)
                }),
                body: request.alert.body || request.data.message
              }
        );

        if (request.success) onDelete(sendoutData.id);
      }
    });
  };

  const handleConvertToSendout = async (event, params) => {
    event.preventDefault();
    setTemplate('');
    setValue(formNames.emailSubject.name, null, false);
    setTypeOfSendout(SendoutsTypes.Sendout);
    setUiState(prev => ({
      ...prev,
      isEditing: false,
      isSendoverToSendout: true,
      typeOfSendover: params,
      isEditingMail: !!params
    }));

    if (sendoutData) {
      const {
        emailDetails: { bcc_emails }
      } = sendoutData;

      setAllBccs(prev => ({
        ...prev,
        JOBccs: bcc_emails || []
      }));
    }

    Object.entries(formNames).forEach(([, propValue]) => {
      if (!propValue.desactiveIf) return;
      const { ref, validation } = buildRegisterSchema(propValue);
      register(ref, validation);
    });

    setValue(formNames.status.name, sendoutStatuses[0]);
    setMailTemplate(sendoutTemplates.find(item => item.sendout_type_id === SendoutsTypes.Sendout));
  };

  const handleResendEmailClick = () => {
    if (!sendoutData) return;
    showConfirm({
      cancelButtonText: strings.shared.ui.cancel,
      severity: 'warning',
      confirmButtonText: 'Resend',
      title: showConfirmResendEmail.title,
      message: strings.formatString(showConfirmResendEmail.message, {
        type: getSendoutTypeTitle(typeOfSendout, sendoutsOptions)
      }),
      mustComplete: true,
      closeDialogOnConfirm: false,
      onConfirm: async ok => {
        ok && (await resendEmailToHiringAuthority(sendoutData?.id));
      },
      onError: ex => getErrorAlert(ex)
    });
  };

  const resendEmailToHiringAuthority = async sendoutId => {
    try {
      const endPointToResendEmail = Endpoints.SendoutResendEmail.replace(':id', sendoutId);
      const response = await API.post(endPointToResendEmail);
      if (response.status === HTTPStatusCodes.Created || response.status === HTTPStatusCodes.Ok) {
        showAlert({
          severity: 'success',
          title: strings.formatString(showConfirmResendEmail.success, {
            type: getSendoutTypeTitle(typeOfSendout, sendoutsOptions)
          })
        });
      }
    } catch (error) {
      getErrorAlert(error);
    }
  };

  const getTitles = (): {
    buttonTitle: String,
    title: string,
    idState: number
  } => {
    if (!sendoutsOptions || typeOfSendout === null) {
      return {
        buttonTitle: ButtonStates.PreviewAndSend,
        title: 'New Sendout',
        idState: SendoutState.Choosing
      };
    }

    const selectedOption = sendoutsOptions.find(each => each.id === typeOfSendout);
    const titleDetails = `${selectedOption?.title} Details`;
    const titleCreation = `New ${selectedOption.title}`;
    const willSendEmail = watcher[formNames.sendEmail.name];
    const currentStatus = watcher[formNames.status.name];

    const isSendoverEdit = sendoutData && sendoutData?.type_id?.id === SendoutsTypes.Sendover;
    if (isSendoverEdit) {
      if (uiState.isSendoverToSendout) {
        return {
          buttonTitle: isWithoutMail ? ButtonStates.Save : ButtonStates.PreviewAndSend,
          title: 'Switch to Sendout',
          idState: SendoutState.SwitchToSendout
        };
      }

      const isSendoverActive =
        typeOfSendout === SendoutsTypes.Sendover && currentStatus?.id === SendoverStatus.Active;
      if (isSendoverActive) {
        return {
          buttonTitle: ButtonStates.Update,
          title: titleDetails,
          idState: SendoutState.DetailSendover
        };
      }

      return {
        buttonTitle: ButtonStates.ChangeToSendover,
        title: titleDetails,
        idState: SendoutState.DetailSendover
      };
    }

    return sendoutData
      ? {
          buttonTitle: uiState.isEditingMail ? ButtonStates.PreviewAndSend : ButtonStates.Update,
          title: titleDetails,
          idState: SendoutState.DetailSendout
        }
      : {
          buttonTitle: willSendEmail ? ButtonStates.Save : ButtonStates.PreviewAndSend,
          title: titleCreation,
          idState: SendoutState.Create
        };
  };

  const { buttonTitle, title, idState } = getTitles();

  const handleChangeOfStatus = status => {
    setValue(formNames.status.name, status);

    const isStatusToDeclinate = StatusToDeclinate.some(each => status?.id === each);
    if (isStatusToDeclinate && sendoutData) {
      handleNavigationSection(TYPE_DISPLAY_SECTIONS.DECLINED);
      return;
    }

    const isSendoverActive =
      typeOfSendout === SendoutsTypes.Sendover && status?.id === SendoverStatus.Active;
    if (isSendoverActive) {
      setUpdateSendoverStatus(true);
      return;
    }

    const isSendoverPlaced =
      typeOfSendout === SendoutsTypes.Sendover && status?.id === SendoverStatus.Placement;
    if (isSendoverPlaced) {
      handleSubmit(onSubmitForm)();
    }

    if (!status) {
      setUpdateSendoverStatus(false);
    }
  };

  const handleBackDrawer = action => {
    handleChangeValue(sendoutData?.status, formNames.status);
    if (STATUS_DISPLAY_SECTIONS[action])
      setUiState(prev => ({ ...prev, ...STATUS_DISPLAY_SECTIONS[action] }));
  };

  const handleChangeOfBcc = accountable =>
    accountable && setAllBccs(prev => ({ ...prev, candidateOwnerBcc: [accountable?.email] }));

  const isFullPermissionDisabled =
    sendoutData && !SendoutsStatusesFullEdit.some(each => sendoutData?.status?.id === each);
  const isFormDisabled =
    idState === SendoutState.Choosing || uiState.isFormDisabled || isFullPermissionDisabled;

  const shouldShowSOForm = !sendoutData || uiState.isSendoverToSendout;
  const shouldShowStatus = !(!sendoutData && typeOfSendout === SendoutsTypes.Sendover);
  const shouldAccountableEdit = sendoutData
    ? SendoutActiveStatuses.includes(sendoutData?.status?.id) || uiState.isSendoverToSendout
    : true;

  const isSendoverDetail = idState === SendoutState.DetailSendover;
  const isSwitchingToSendout = idState === SendoutState.SwitchToSendout;
  const isSecondaryOptionsVisible = !!sendoutData && !isSwitchingToSendout;
  const isStatusNotAvailable = !sendoutData || isSwitchingToSendout;
  const canSendEmailWithStatus = SendoutActiveStatuses.includes(sendoutData?.status?.id);
  const isAvailableCandidate = selectedCandidate && !uiState.isLoadingCandidate;
  const shouldShowAdditionalRecruiter = isAvailableCandidate && !isSendoverDetail;

  const shouldShowLogs = uiState.isEditing && !uiState.isSendoverToSendout;

  const editingHiringAuthority =
    uiState.isEditing &&
    sendoutData?.hiring_authority?.id !== hiring_authority?.id &&
    sendoutData?.send_email_hiring &&
    !isSendoverDetail;

  const shouldResendEmailToHiringAuthority =
    (sendoutData?.status?.id === SendoutStatus.Active ||
      sendoutData?.status?.id === SendoverStatus.Active) &&
    sendoutData?.send_email_hiring &&
    getFeatureFlags().includes(FeatureFlags.ResendEmailHiringAuthoritySendout);

  const isAdditionalRecruiterDisabled =
    additionalRecruiters.length === 1 || !shouldAccountableEdit || isFormDisabled;

  const fileCategoriesByCandidate = allFileCategories.filter(fileCategory =>
    ValidCategoriesForCandidateFiles.includes(fileCategory.id)
  );
  const fileCategoriesBySendout = allFileCategories.filter(fileCategory =>
    ValidCategoriesForSendoutFiles.includes(fileCategory.id)
  );

  const filesFileSection =
    uiState.isEditing || uiState.isEditingMail || uiState.isSendoverToSendout
      ? sendoutData?.attachments
      : candidateFiles;

  const filesDebriefSection = filesForSendout(sendoutData?.attachments);

  const handleChangeAccountable = accountable => {
    const { coach, email } = accountable || {};
    const defaultBccs = [];
    const accountableEmail = [];
    coach?.email_team && accountableEmail.push(coach?.email_team);
    email && accountableEmail.push(email);

    if (sendoutData) {
      const {
        candidate: {
          coach: coachCandidate,
          specialty: { industry }
        },
        candidateAccountable: { email: emailCandidate }
      } = sendoutData;
      industry?.email && defaultBccs.push(industry?.email);
      coachCandidate?.email_team && accountableEmail.push(coachCandidate?.email_team);
      emailCandidate && accountableEmail.push(emailCandidate);
    }

    setAllBccs(prev => ({
      ...prev,
      defaultBccs: [...prev.defaultBccs, ...defaultBccs],
      JOBccs: accountableEmail || []
    }));
  };

  const handleCandidateClick = (e, value) => {
    if (value === null) {
      if (shouldUseCategoryFilesSendout) {
        fileCategoriesByCandidate.forEach(({ id }) => {
          setValue(`${FileKeyPrefix}${id}`, null);
        });
      }
      setCandidateFiles([]);
    }

    handleChangeValue(value, formNames.candidate, false, setSelectedCandidate);
  };

  const handleHiringAuthorityChange = (e, value) => {
    handleChangeValue(value, formNames.hiringAuthority, true);
    if (value) {
      setTemplateTags(prev => ({
        ...prev,
        HAName: value?.first_name,
        HAFullName: value?.full_name,
        HAEmail: value?.work_email
      }));
    }
  };

  const toggleSwitchType = () =>
    setUiState(prev => ({
      ...prev,
      typeOfSendover: isWithoutMail ? SendoverSwitchTypes.SendMail : SendoverSwitchTypes.NoSendMail,
      isEditingMail: isWithoutMail
    }));

  const handleSendEmail = () => {
    setUiState(prev => ({ ...prev, isEditingMail: true }));
    setMailTemplate(sendoutTemplates.find(item => item.sendout_type_id === typeOfSendout));
  };

  const initEditor = (e, editor) => {
    editorRef.current = editor;
  };
  const isEnabledEditor = isFormDisabled || watcher[formNames.sendEmail.name] || !isEditingEmail;

  const handleEditorChange = value => {
    setValue(formNames.emailBody.name, value);
  };

  const handleOnNavigation = itemId => {
    onClose && onClose();
    onDuplicateSendoutSelected && onDuplicateSendoutSelected({ id: itemId });
  };

  const handleNavigationSection = identity => {
    setUiState(prev => ({
      ...prev,
      [identity]: true
    }));
  };

  const handleSelectedFiles = (name, value) => setValue(name, value, true);

  const onSubmit =
    isSendoverDetail && !updateSendoverStatus ? handleConvertToSendout : handleSubmit(onSubmitForm);

  const moreActions = [
    {
      title: 'View Logs',
      action: () => handleNavigationSection(TYPE_DISPLAY_SECTIONS.VIEW_LOGS),
      visible: true
    },
    {
      title: 'Resend Email',
      action: handleResendEmailClick,
      visible: shouldResendEmailToHiringAuthority
    },
    {
      title: 'Delete',
      action: handleDeleteData,
      visible: userHasRoles([Roles.Operations])
    }
  ];

  if (uiState.showDeclinedSection) {
    return (
      <SendoutsDeclineForm
        onSubmitData={onSubmitForm}
        type={watch(formNames.status.name)}
        onClose={onClose}
        onBack={() => handleBackDrawer(TYPE_DISPLAY_SECTIONS.DECLINED)}
        form={form}
      />
    );
  }

  if (uiState.showDuplicateSection) {
    const titleDuplicate = strings.formatString(drawerTitleDuplicateCopy);
    const subtitleDuplicate = strings.formatString(drawerSubtitleDuplicateCopy, {
      jobOrderTitle: profile?.title,
      candidateName: selectedCandidate?.title
    });

    return (
      <SendoutDuplicates
        data={duplicates}
        title={titleDuplicate}
        subtitle={subtitleDuplicate}
        onNavigation={handleOnNavigation}
        onClose={onClose}
        onBack={() => handleBackDrawer(TYPE_DISPLAY_SECTIONS.DUPLICATE)}
      />
    );
  }

  if (uiState.showViewLogSection) {
    return (
      shouldShowLogs && (
        <SendoutEventLogsSection
          id={sendoutData?.id}
          title={title}
          onClose={onClose}
          onBack={() => handleBackDrawer(TYPE_DISPLAY_SECTIONS.VIEW_LOGS)}
        />
      )
    );
  }

  const attachments = uiState.isEditing ? sendoutData?.attachments : candidateFiles;
  const displayCategoriesFileManage = getCategoriesFiles(attachments).filter(fileCategory =>
    OriginalCandidateFileCategories.includes(fileCategory.id)
  );

  const shouldShowCategoryFiles =
    shouldUseCategoryFilesSendout && fileCategoriesByCandidate.length > 0;

  const hasSendEmail = !isWithoutMail && !uiState.isEditingMail;

  const displayFileCategories =
    (shouldShowCategoryFiles && hasSendEmail && isAvailableCandidate) ||
    (shouldShowCategoryFiles && uiState.isSendoverToSendout && !isWithoutMail);

  const displayFileCategoriesWhenSendEmail =
    shouldUseCategoryFilesSendout &&
    uiState.isEditingMail &&
    !uiState.isSendoverToSendout &&
    isAvailableCandidate;

  const displayFileCategoriesDebrief =
    shouldUseCategoryFilesSendoutDebrief &&
    fileCategoriesBySendout.length > 0 &&
    uiState.isEditing &&
    sendoutData?.type?.id !== SendoutsTypes.Sendover;

  const canSendEmail = !uiState.isFormDisabled && shouldEditEmail && canSendEmailWithStatus;
  const hasDuplicates = !isSendoverDetail && selectedCandidate?.id && duplicates.length > 0;

  return (
    <DrawerContentLayout
      title={title}
      uiState={uiState}
      onClose={onClose}
      errorRender={errorSendoutLoad}
      onSubmit={onSubmit}
      isSaveButtonMode={!isSendoverDetail || updateSendoverStatus}
      customPrimaryButton={({ disabled, classes: flClasses }) =>
        isSendoverDetail &&
        !updateSendoverStatus && (
          <FPButtonMenu
            data-testid="menu-switch-to-sendout"
            text="Switch to Sendout"
            icon={() => <ArrowDropDown />}
            iconPosition="right"
            disabled={disabled}
            menuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              },
              classes: { paper: null }
            }}
            className={flClasses.primaryButton}
            menuItems={SwitchSendoverOptions.map(each => ({
              ...each,
              action: event => handleConvertToSendout(event, each.value)
            }))}
          />
        )
      }
      moreActions={isSecondaryOptionsVisible && moreActions}
      primaryProps={{
        initialText: buttonTitle,
        onProgressText: 'Working...',
        type: 'submit',
        isSaving: uiState.isSaving,
        isSuccess: uiState.isSuccess,
        disabled: uiState.isSaving || duplicates.length > 0
      }}
      isBottomToolbarNeeded={!uiState.isFormLoading}
    >
      <FormContext {...form}>
        <NumberedForm>
          <SendoutDetails
            profile={profile}
            sendout={sendoutData}
            onNavigate={onNavigate}
            disabled={isFormDisabled}
            showConfirm={showConfirm}
          />

          <When condition={!sendoutData}>
            <NumberedForm.Item className="columns-radiogroup" title="Choose an Option">
              <Typography variant="body1">Select if it’s a Sendout or a Sendover</Typography>
              <div data-testid="radio-sendout-or-sendover">
                <FPRadioGroup
                  disabled={!!sendoutData}
                  value={typeOfSendout && typeOfSendout.toString()}
                  name={formNames.typeSendout.name}
                  options={sendoutsOptions || []}
                  onChange={e =>
                    handleChangeValue(
                      parseInt(e.target.value, 10),
                      formNames.typeSendout,
                      true,
                      setTypeOfSendout
                    )
                  }
                />
                <When condition={typeOfSendout === SendoutsTypes.Sendover}>
                  <ReasonsForm form={form} schema={ReasonsSendover} />
                </When>
              </div>
            </NumberedForm.Item>
          </When>

          <When condition={isSwitchingToSendout}>
            <NumberedForm.Container>
              <Typography variant="subtitle1">
                {strings.formatString(isWithoutMailTitleCopy, {
                  auxiliary: isWithoutMail ? `WON'T` : 'WILL'
                })}
                <button
                  onClick={toggleSwitchType}
                  className={clsx(classes.btnUnderline, classes.marginZero, classes.paddingZero)}
                  type="button"
                >
                  {isWithoutMail ? sendEmailCopy.button : notSendEmailCopy.button}
                </button>
                <FPHint hint={isWithoutMail ? sendEmailCopy.hint : notSendEmailCopy.hint} />
              </Typography>
            </NumberedForm.Container>
          </When>

          <When condition={!isSendoverDetail}>
            <SendoutAccountableSection
              sendoutData={sendoutData}
              profile={profile}
              onAccountableChange={handleChangeAccountable}
              disabled={isFormDisabled || !shouldAccountableEdit}
            />
          </When>

          <NumberedForm.Item
            title={`${getSendoutTypeTitle(typeOfSendout, sendoutsOptions)} Information`}
          >
            <div className="grid-2x">
              <When condition={shouldShowStatus}>
                <AutocompleteSelectConnected
                  data-testid="autocomplete-sendout-status"
                  name={formNames.status.name}
                  placeholder={`${formNames.status.formalName} *`}
                  selectedValue={getValues(formNames.status.name)}
                  options={selectedStatuses}
                  disabled={isFormDisabled || isStatusNotAvailable}
                  error={!!errors[formNames.status.name]}
                  errorText={errors[formNames.status.name]?.message}
                  onChange={(e, value) =>
                    handleChangeValue(value, formNames.status, true, handleChangeOfStatus)
                  }
                  renderOption={statusRenderOption}
                  startAdornment={
                    getValues(formNames.status.name) &&
                    statusStartAdornment(getValues(formNames.status.name).color)
                  }
                />
              </When>

              <When condition={typeOfSendout !== SendoutsTypes.Sendover}>
                <TextBox
                  data-testid="autocomplete-estimated-fee-amount"
                  error={!!errors[formNames.feeAmount.name]}
                  errorText={errors[formNames.feeAmount.name]?.message}
                  name={formNames.feeAmount.name}
                  label={`${formNames.feeAmount.formalName} *`}
                  inputType="currency"
                  disabled={!isFullPermissionDisabled && isFormDisabled}
                  onChange={(e, value) => handleChangeValue(value, formNames.feeAmount, true)}
                  value={watch(formNames.feeAmount.name)}
                  placeholder={`${formNames.feeAmount.formalName} *`}
                />
              </When>

              <When condition={shouldShowSOForm && !sendoutData?.candidate}>
                <AutocompleteSelectConnected
                  data-testid="autocomplete-candidate-by-name"
                  url={Endpoints.Search}
                  name={formNames.candidate.name}
                  loading={uiState.isLoadingCandidate}
                  disabled={
                    !(selectedCandidate?.id || duplicates.length > 0) &&
                    (uiState.isEditing ||
                      uiState.isSendoverToSendout ||
                      uiState.isLoadingCandidate ||
                      isFormDisabled)
                  }
                  placeholder="Search by Name, or Email *"
                  label={`${formNames.candidate.formalName} *`}
                  selectedValue={getValues(formNames.candidate.name)}
                  typeahead
                  typeaheadLimit={50}
                  typeaheadParams={{
                    entityType: CandidateEntity.id
                  }}
                  error={!!errors[formNames.candidate.name]}
                  errorText={errors[formNames.candidate.name]?.message}
                  onChange={handleCandidateClick}
                  renderOption={OptionRenderers.globalSearchPerson('title')}
                />
              </When>

              <When condition={shouldShowAdditionalRecruiter}>
                <AutocompleteSelectConnected
                  name={formNames.candidateAccountable.name}
                  placeholder={`${formNames.candidateAccountable.formalName} *`}
                  selectedValue={watcher[formNames.candidateAccountable.name]}
                  options={additionalRecruiters}
                  disabled={isAdditionalRecruiterDisabled}
                  displayKey="full_name"
                  error={!!errors[formNames.candidateAccountable.name]}
                  errorText={errors[formNames.candidateAccountable.name]?.message}
                  onChange={(e, value) =>
                    handleChangeValue(
                      value,
                      formNames.candidateAccountable,
                      false,
                      handleChangeOfBcc
                    )
                  }
                  renderOption={OptionRenderers.recruiterWithInitials}
                />
              </When>
            </div>
          </NumberedForm.Item>

          <When condition={hasDuplicates}>
            <NumberedForm.Container>
              <Alert
                data-testid="duplicate-alert"
                severity="warning"
                icon={
                  <FPIcon component={SvgWarning} onSetColor={palette => palette.warning.light} />
                }
                action={
                  <Button
                    color="inherit"
                    size="small"
                    variant="outlined"
                    onClick={() => handleNavigationSection(TYPE_DISPLAY_SECTIONS.DUPLICATE)}
                  >
                    View
                  </Button>
                }
              >
                {strings.formatString(sendoutDuplicateCopy)}
              </Alert>
            </NumberedForm.Container>
          </When>

          <When condition={typeOfSendout !== SendoutsTypes.Sendover}>
            <NumberedForm.Item title="Interview Details">
              <InterviewSchedule
                data-testid="wrapper-interview-details"
                onReplaceTagChange={replaceInterviewTag}
                disabled={isFormDisabled || isInterviewDisabled()}
                maxOfInterviews={getMaxInterviews()}
                dataSource={sendoutData?.interviews}
                sendoutId={sendoutData?.id}
              />
            </NumberedForm.Item>
          </When>

          <When condition={displayFileCategoriesDebrief}>
            <NumberedForm.Item title="Debriefs & Offer Letter">
              <DebriefFilesSection
                categories={fileCategoriesBySendout}
                disabled={sendoutData?.status?.id !== SendoutStatus.Active}
                endpoint={Endpoints.Files}
                fileExplorerSections={buildFileExplorerSections(
                  sendoutData?.candidate?.files,
                  sendoutData?.joborder?.files
                )}
                files={filesDebriefSection}
                onSelectedFiles={handleSelectedFiles}
              />
            </NumberedForm.Item>
          </When>

          <When condition={displayFileCategories}>
            <NumberedForm.Item title="Attachments">
              <CandidateFilesSection
                categories={fileCategoriesByCandidate}
                endpoint={Endpoints.Files}
                fileExplorerSections={buildFileExplorerSections(candidateFiles)}
                files={filesForCandidate(filesFileSection)}
                isEdition={uiState.isEditing}
                onSelectedFiles={handleSelectedFiles}
                softDeleteForExistingItems
              />
            </NumberedForm.Item>
          </When>

          <When condition={displayFileCategoriesWhenSendEmail}>
            <NumberedForm.Item title="Attachments">
              <CandidateFilesSection
                categories={fileCategoriesByCandidate}
                endpoint={Endpoints.Files}
                fileExplorerSections={buildFileExplorerSections(candidateFiles)}
                files={filesForCandidate(filesFileSection)}
                onSelectedFiles={handleSelectedFiles}
                softDeleteForExistingItems
              />
            </NumberedForm.Item>
          </When>

          {!isWithoutMail && isAvailableCandidate && (
            <>
              <When condition={!shouldUseCategoryFilesSendout}>
                <SendoutFileManageSection
                  categories={displayCategoriesFileManage}
                  files={attachments}
                  loading={uiState.isLoadingCandidate}
                  readOnly={uiState.isEditing}
                  selectedCandidate={selectedCandidate}
                  disabled={isFormDisabled || !selectedCandidate}
                />
              </When>

              <NumberedForm.Item title="Email Details" customClass={classes.flexColumn}>
                <div className={classes.flexColumn}>
                  <AutocompleteSelectConnected
                    id="autocomplete-email-details"
                    disabled={isFormDisabled || isSendoverDetail}
                    label={formNames.hiringAuthority.formalName}
                    placeholder="Hiring Authority *"
                    name={formNames.hiringAuthority.name}
                    error={!!errors[formNames.hiringAuthority.name]}
                    errorText={errors[formNames.hiringAuthority.name]?.message}
                    displayKey="full_display"
                    canRefresh
                    url={`${Endpoints.JobOrders}/${profile?.id}/${Endpoints.HiringAuthorities}`}
                    onChange={handleHiringAuthorityChange}
                    disabledItemsFocusable={false}
                    selectedValue={getFullDisplayHA(watcher[formNames.hiringAuthority.name])}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderOption={OptionRenderers.displayHiringAuthority}
                  />
                  <When condition={editingHiringAuthority}>
                    <Typography variant="body2">
                      <b>IMPORTANT:</b> {strings.sendouts.hiringAuthority}
                    </Typography>
                  </When>

                  <When condition={canSendEmail}>
                    <div className={classes.row}>
                      <Typography variant="body2">
                        <b>IMPORTANT:</b> {noEmailSendCopies.title}
                        <button
                          onClick={handleSendEmail}
                          className={clsx(
                            classes.btnUnderline,
                            classes.marginZero,
                            classes.paddingZero
                          )}
                          type="button"
                        >
                          {noEmailSendCopies.button}
                        </button>
                      </Typography>
                    </div>
                  </When>

                  <When condition={!shouldEditEmail}>
                    <When condition={onlyOnCreation && shouldSendEmailToHiringAuthority}>
                      <div className={classes.row}>
                        <FormControlLabel
                          data-testid="check-dont-send-email"
                          onChange={(event, checked) =>
                            handleChangeValue(checked, formNames.sendEmail)
                          }
                          disabled={isFormDisabled}
                          value={watcher[formNames.sendEmail.name]}
                          label={formNames.sendEmail.formalName}
                          labelPlacement="end"
                          control={<Checkbox color="primary" />}
                        />
                        <FPHint
                          variant="info"
                          size="sm"
                          placement="bottom"
                          disabled={isFormDisabled}
                          hint={noEmailSendCopies.hint}
                        />
                      </div>
                    </When>
                    <EmailPicker
                      disabled={
                        isFormDisabled || watcher[formNames.sendEmail.name] || !isEditingEmail
                      }
                      name={formNames.cc.name}
                      placeholder={formNames.cc.formalName}
                      label={formNames.cc.formalName}
                      selectedValue={watcher[formNames.cc.name]}
                      error={!!errors[formNames.cc.name]}
                      errorText={errors[formNames.cc.name]?.message}
                      endpoint={Endpoints.Search}
                      typeaheadParams={{
                        entityType: EntityType.HiringAuthority,
                        inColumns: ['ha.work_email']
                      }}
                      maxItems={10}
                      allowNewItems
                      onSelect={(name, values) => handleChangeValue(values, formNames.cc)}
                    />

                    <EmailPicker
                      disabled
                      loading={!mailTemplate}
                      name={formNames.bcc.name}
                      label={`${formNames.bcc.formalName} *`}
                      placeholder={`${formNames.bcc.formalName} *`}
                      selectedValue={watcher[formNames.bcc.name]}
                      error={!!errors[formNames.bcc.name]}
                      errorText={errors[formNames.bcc.name]?.message}
                      endpoint={Endpoints.Search}
                      typeaheadParams={{
                        entityType: EntityType.HiringAuthority,
                        inColumns: ['ha.work_email']
                      }}
                      maxItems={5}
                      allowNewItems
                      onSelect={(name, values) => handleChangeValue(values, formNames.bcc)}
                    />

                    <TextBox
                      disabled={
                        isFormDisabled ||
                        !selectedCandidate ||
                        watcher[formNames.sendEmail.name] ||
                        !isEditingEmail
                      }
                      name={formNames.emailSubject.name}
                      placeholder={`${formNames.emailSubject.formalName} *`}
                      error={!!errors[formNames.emailSubject.name]}
                      errorText={errors[formNames.emailSubject.name]?.message}
                      label={`${formNames.emailSubject.formalName} *`}
                      onChange={(e, value) => handleChangeValue(value, formNames.emailSubject)}
                      value={watcher[formNames.emailSubject.name] || ''}
                    />

                    <When condition={mailTemplate && isEditingEmail}>
                      <>
                        <Alert severity="info">{emailTemplateCopy}</Alert>
                        <FPTextEditor
                          onInit={initEditor}
                          config={tinymceConfig}
                          disabled={isEnabledEditor}
                          value={template}
                          onChange={handleEditorChange}
                        />
                      </>
                    </When>
                    <When condition={!isEditingEmail}>
                      <FPHtmlViewer
                        className={classes.previewEmailContent}
                        content={
                          sendoutData?.emailDetails?.template || previewFormattedTemplate(template)
                        }
                      />
                    </When>
                  </When>
                </div>
              </NumberedForm.Item>

              <When condition={template && isEditingEmail}>
                <NumberedForm.Container>
                  <Typography variant="subtitle1">
                    <b>Preview:</b>
                  </Typography>
                  <When condition={template}>
                    <div className={classes.previewEmailContainer}>
                      <FPHtmlViewer
                        className={classes.previewEmailContent}
                        content={previewFormattedTemplate(template)}
                      />
                    </div>
                  </When>
                  <Typography variant="body2">
                    <b>IMPORTANT:</b> {tagsAndSignatureCopy}
                  </Typography>
                </NumberedForm.Container>
              </When>
            </>
          )}
        </NumberedForm>
      </FormContext>
    </DrawerContentLayout>
  );
};

/**
 * Method to changes the value of form data
 */
const handleChangeValueProto =
  (setFormValue, setFormState) =>
  (data, formData, rerender = false, callback = undefined, shouldValidate = true) => {
    if (callback) {
      callback(data);
    }
    if (formData && data !== undefined) {
      setFormValue(formData.name, data, shouldValidate);
    }
    if (rerender && !callback) {
      setFormState(prev => ({ ...prev })); // Force Render
    }
  };

SendoutsForm.defaultProps = {
  profile: undefined,
  onEdit: () => {},
  onCreate: () => {},
  sendoutData: undefined,
  readOnly: false
};

const mapToDispatchProps = dispatch => {
  return {
    showAlert: alert => dispatch(showAlertAction(alert)),
    showConfirm: confirmation => dispatch(confirmAction(confirmation))
  };
};

const SendoutsFormConnected = connect(null, mapToDispatchProps)(SendoutsForm);

export default SendoutsFormConnected;
