// @flow

export const dynamicExpandTreeView = parents => parents.map(id => `${id}_F`);

export const convertHtmlToPlainText = html => {
  return html
    .replace(/<style([\s\S]*?)<\/style>/gi, '')
    .replace(/<script([\s\S]*?)<\/script>/gi, '')
    .replace(/<\/div>/gi, '\n')
    .replace(/<\/li>/gi, '\n')
    .replace(/<li>/gi, '  *  ')
    .replace(/<\/ul>/gi, '\n')
    .replace(/<\/p>/gi, '\n')
    .replace(/<br\s*[/]?>/gi, '\n')
    .replace(/<[^>]+>/gi, '')
    .trimEnd();
};

const getFolderChildIds = data => {
  const folders = data.filter(child => child.entity === 'folder');
  const ids = [];

  if (!folders) return [];

  folders.forEach(key => {
    if (key.entity === 'folder') {
      ids.push(...getFolderChildIds(key.children));
    }
    ids.push(`${key.id}_F`);
  });

  return ids;
};

export const getFolderParentIds = data => {
  return data.flatMap(parent => {
    if (parent.children.length < 1) return [];
    const ids = getFolderChildIds(parent.children);
    return [parent.id, ...ids];
  });
};
