export const countElements = data => {
  let count = 0;
  const stack = [data];

  while (stack.length) {
    const node = stack.pop();
    if (node.children && node.children.length > 0) {
      count += node.children.length;
      stack.push(...node.children);
    }
  }

  return count;
};

export const getAllNodeIds = node => {
  let ids = [node.id.toString()];
  if (node.children) {
    node.children.forEach(child => {
      ids = [...ids, ...getAllNodeIds(child)];
    });
  }
  return ids;
};
