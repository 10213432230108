import * as React from 'react';

const SvgAlarmBell = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M21 17.5a1.5 1.5 0 01-1.5-1.5v-4.86A8 8 0 0013 3.07V1a1 1 0 00-2 0v2.07a8 8 0 00-6.5 8.07V16A1.5 1.5 0 013 17.5a1 1 0 000 2h18a1 1 0 000-2zM14.24 21H9.76a.25.25 0 00-.24.22 2.64 2.64 0 000 .28 2.5 2.5 0 005 0 2.64 2.64 0 000-.28.25.25 0 00-.28-.22z" />
    </svg>
  );
};

export default SvgAlarmBell;
