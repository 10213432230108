import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  nameInput: {
    margin: theme.spacing(4, 0)
  },
  radioGroup: {
    margin: 0
  }
}));

export const useInformationRendererStyles = makeStyles(theme => ({
  infoLabelSpacing: {
    marginBottom: theme.spacing(1)
  }
}));
