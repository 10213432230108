// @flow

import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { getCancelToken } from 'services/API';

import { StatsCard } from '../StatsCard';

import { ErrorTemplate } from './ErrorTemplate';
import { Loader } from './Loader';

interface WidgetProps {
  actionElement?: ReactNode;
  children?: (data?: any) => ReactNode;
  header?: ReactNode;
  promises: any[];
  subheader?: ReactNode;
}

export const Widget = ({
  actionElement,
  children,
  promises,
  subheader,
  header,
  footer
}: WidgetProps) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(
    async cancelTokens => {
      if (!promises || promises.length === 0) return;

      try {
        setIsLoading(true);
        const response = await Promise.all(
          promises.map((promise, index) =>
            promise({
              cancelToken: !isEmpty(cancelTokens) ? cancelTokens[index].token : null
            })
          )
        );
        setData(response?.data ?? response);
      } catch (e) {
        setError(e.message);
      } finally {
        setIsLoading(false);
      }
    },
    [promises]
  );

  useEffect(() => {
    const cancelTokens = promises.map(() => getCancelToken());
    getData(cancelTokens);

    return () => {
      cancelTokens.map(token => token.cancel());
    };
  }, [getData, promises]);

  const handleResetClick = async () => {
    setError(null);
    await getData();
  };

  return (
    <StatsCard actionElement={actionElement} header={header} subheader={subheader} footer={footer}>
      {isLoading && <Loader />}
      {error && <ErrorTemplate onReset={handleResetClick} />}
      {!isLoading && !error && data && children?.(data)}
    </StatsCard>
  );
};
