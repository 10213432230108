import { EntityRoutes } from 'routes/constants';

import { JobOrderQueryActions } from '../JobOrderProfile/utils';

export const navigateToSendout = history => (event, options) => {
  const { sendoutId, placementId, sendout } = options || {};
  let queryParam = '';
  if (sendoutId) queryParam = `form=${JobOrderQueryActions.PlacementCreation}&id=${sendoutId}`;

  if (placementId) queryParam = `form=${JobOrderQueryActions.PlacementDetail}&id=${placementId}`;

  const queryPath = `tab=${JobOrderQueryActions.PlacementTab}&${queryParam}`;
  const JOEndpoint = EntityRoutes.JobOrderProfile.replace(':id', sendout?.joborder?.id);
  history.push(`${JOEndpoint}?${queryPath}`);
};
