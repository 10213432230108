// @flow
import { fillSearchProjectSelectionParams } from 'services/searchProjects/utils';
import strings from 'strings';
import { BulkMenuItems } from 'UI/constants/defaults';

import { createDateTime } from '.';

const {
  utils: {
    alert: {
      error: {
        getMappedBulkData: getMappedBulkDataStrings,
        updateByBulkType: updateByBulkTypeStrings
      }
    }
  }
} = strings.bulkEmails;

const getSearchParams = selectionParams => {
  const searchParams = {};
  const selectionKeys = ['candidateIds', 'hiringAuthorityIds', 'nameIds'];
  const isIncludeSelectionSet = selectionKeys.some(key => selectionParams[key].length > 0);
  selectionParams?.params?.query && (searchParams.search_params = selectionParams.params);
  if (isIncludeSelectionSet) {
    searchParams.search_project_selected_items = [];
    selectionKeys.forEach(key => {
      selectionParams[key].length > 0 &&
        searchParams.search_project_selected_items.push(...selectionParams[key]);
    });
  }
  return searchParams;
};

export const getMappedBulkData = bulkData => {
  const { emailConfig, emailBody, searchProject } = bulkData || {};
  const areAllRequiredFieldsValid =
    emailBody?.text &&
    emailBody?.html &&
    emailBody?.subject &&
    Number.isInteger(searchProject?.id) &&
    Number.isInteger(emailConfig?.bulkType?.id);

  if (!emailConfig?.saveAsDraft && !areAllRequiredFieldsValid) {
    return {
      success: false,
      alert: { ...getMappedBulkDataStrings, severity: 'error' }
    };
  }
  const optionalParams = {};
  const getIdCallback = ({ id }) => id;

  if (Number.isInteger(bulkData.id)) {
    optionalParams.bulk_id = bulkData.id;
  }

  if (emailConfig?.template?.id) {
    optionalParams.email_template_id = emailConfig.template.id;
  }

  if (emailConfig?.marketing?.marketingCandidates?.length > 0) {
    optionalParams.candidate_ids = emailConfig.marketing.marketingCandidates.map(getIdCallback);
  }

  if (emailConfig?.recruiting?.recruitingJobOrders?.length > 0) {
    optionalParams.job_order_ids = emailConfig.recruiting.recruitingJobOrders.map(getIdCallback);
  }

  if (emailConfig?.blockResend && emailConfig?.blockDays) {
    const blockDays =
      typeof emailConfig?.blockDays === 'object'
        ? emailConfig.blockDays?.id.split(',')[0]
        : emailConfig.blockDays;
    optionalParams.block_duration_days = Number(blockDays);
  }

  if (emailBody?.attachments?.length > 0) {
    optionalParams.files = emailBody.attachments.map(getIdCallback);
  }

  if (emailConfig?.searchProjectSelectionParams) {
    const searchParams = getSearchParams(emailConfig.searchProjectSelectionParams);
    Object.assign(optionalParams, searchParams);
  }

  if (emailConfig?.sendViaOutlook) {
    optionalParams.use_outlook = true;
  }

  if (emailConfig?.generalCategory?.id) {
    optionalParams.bulk_email_subcategory_id = emailConfig?.generalCategory?.id;
  }

  return {
    success: true,
    data: {
      allow_candidates_in_marketing: !!emailConfig.marketing.allowMarketingCandidates,
      allow_hirings_in_recruiting: !!emailConfig.recruiting.allowRecruitHirings,
      block_client_companies: !!emailConfig.marketing.blockClientCompanies,
      block_resend: !!emailConfig.blockResend,
      block_similar_companies: !!emailConfig.marketing.blockCompanies,
      bulk_email_scope_type_id: emailConfig.bulkType.id,
      html: emailBody.html,
      is_draft: !!emailConfig?.saveAsDraft,
      search_project_id: searchProject.id,
      subject: emailBody.subject,
      text: emailBody.text,
      ...optionalParams
    }
  };
};
export const getMappedFormData = formData => {
  const {
    allowMarketingCandidates,
    allowRecruitHirings,
    blockCompanies,
    blockResend,
    blockSigned,
    bulkId,
    bulkScope,
    emailBody,
    generalBulkCategory,
    jobOrderRecruitment: jobOrders,
    marketCandidates: candidates,
    queryParams,
    removedAttachments,
    resendTime,
    saveAsDraft,
    saveAsSchedule,
    scheduleDate,
    scheduleTime,
    selectedItems,
    sendViaOutlook,
    subject,
    text,
    to
  } = formData;

  return {
    id: bulkId,
    emailBody: {
      html: emailBody,
      subject,
      text
    },
    emailConfig: {
      blockResend,
      blockDays: resendTime,
      bulkType: {
        id: bulkScope?.id
      },
      generalCategory: {
        id: generalBulkCategory?.id
      },
      marketing: {
        allowMarketingCandidates,
        blockClientCompanies: blockSigned,
        blockCompanies,
        marketingCandidates: candidates
      },
      recruiting: {
        allowRecruitHirings,
        recruitingJobOrders: jobOrders
      },
      saveAsDraft: !!saveAsDraft,
      sendViaOutlook
    },
    optionalParams: {
      queryParams,
      removedAttachments,
      saveAsSchedule,
      scheduleDate,
      scheduleTime,
      selectedItems
    },
    searchProject: {
      id: to?.id
    }
  };
};

export const BULK_DRAWER_ACTIONS = {
  scheduleBulk: 'startSchedulingBulkEmail',
  sendBulk: 'startSendingBulkEmail',
  sendNowScheduleBulk: 'startSendingNowScheduledBulkEmail',
  updateBulkDraft: 'startUpdatingBulkEmailDraft',
  updateBulkSchedule: 'startUpdatingScheduledBulkEmail'
};

/**
 * @param {Object} formData
 * @param {moment.Moment} formData.scheduleDate
 * @param {moment.Moment} formData.scheduleDateTime
 * @param {moment.Moment} formData.scheduleTime
 * @param {Object} bulkState
 * @param {number} bulkState.templateId
 * @param {Array} bulkState.emailExcludedAttachments
 * @param {Array} bulkState.attachments
 * @param {Object} bulkState.bulkEmailInPreview
 * @param {boolean} bulkState.bulkEmailInPreview.edit
 * @param {Object} bulkState.bulkEmailInPreview.data
 * @param {string} bulkState.selectedMenuItem
 * @returns {{
 *  name: string,
 *  bulkParams: Object,
 *  alert: Object
 * }}
 */
export const getActionDataToDispatch = (formData, bulkState) => {
  const {
    attachments,
    bulkEmailInPreview,
    emailExcludedAttachments,
    selectedMenuItem,
    sendScheduledBulk,
    templateId
  } = bulkState;

  const {
    id: bulkId,
    emailBody,
    emailConfig,
    optionalParams: formDataOptionalParams,
    searchProject
  } = getMappedFormData(formData);
  const {
    queryParams,
    removedAttachments,
    saveAsSchedule,
    scheduleDate,
    scheduleTime,
    selectedItems
  } = formDataOptionalParams;
  const optionalParams = {};
  const mappedBulkData = getMappedBulkData({
    id: bulkId,
    emailBody: {
      ...emailBody,
      attachments
    },
    emailConfig: {
      ...emailConfig,
      template: {
        id: templateId
      },
      searchProjectSelectionParams: bulkEmailInPreview?.edit
        ? bulkEmailInPreview.data?.emailConfig?.searchProjectSelectionParams
        : null
    },
    searchProject
  });

  if (!mappedBulkData.success) return { alert: mappedBulkData.alert };

  if (emailExcludedAttachments.length > 0) {
    optionalParams.attachment_template_block_list = emailExcludedAttachments;
  }

  if (removedAttachments.length > 0 && emailConfig.saveAsDraft) {
    optionalParams.removed_attachment_ids = removedAttachments;
  }

  if (selectedItems && queryParams) {
    const filledItems = fillSearchProjectSelectionParams(selectedItems, queryParams);
    optionalParams[filledItems?.key] = filledItems?.data;
  }

  if (scheduleDate && scheduleTime && saveAsSchedule) {
    const dateTime = createDateTime(scheduleDate, scheduleTime);
    optionalParams.send_date = dateTime.format();
  }

  const bulkParams = {
    ...mappedBulkData.data,
    ...optionalParams
  };

  if (sendScheduledBulk && bulkId)
    return {
      name: BULK_DRAWER_ACTIONS.sendNowScheduleBulk,
      bulkParams
    };

  if (bulkEmailInPreview.edit && bulkId) {
    const updateByBulkType = {
      [BulkMenuItems.Drafts]: () => {
        if (saveAsSchedule) {
          return {
            name: BULK_DRAWER_ACTIONS.scheduleBulk,
            bulkParams: {
              ...bulkParams,
              draft_id: bulkId,
              is_draft: true
            }
          };
        }

        return emailConfig.saveAsDraft
          ? {
              name: BULK_DRAWER_ACTIONS.updateBulkDraft,
              bulkParams
            }
          : {
              name: BULK_DRAWER_ACTIONS.sendBulk,
              bulkParams: {
                ...bulkParams,
                draft_id: bulkId
              }
            };
      },
      [BulkMenuItems.Schedule]: () => ({
        name: BULK_DRAWER_ACTIONS.updateBulkSchedule,
        bulkParams
      })
    };
    return updateByBulkType[selectedMenuItem]
      ? updateByBulkType[selectedMenuItem]()
      : { alert: { ...updateByBulkTypeStrings, severity: 'error' } };
  }

  return bulkParams.send_date && saveAsSchedule
    ? {
        name: BULK_DRAWER_ACTIONS.scheduleBulk,
        bulkParams
      }
    : {
        name: BULK_DRAWER_ACTIONS.sendBulk,
        bulkParams
      };
};
