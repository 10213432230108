// @flow
const getAnsweredQuestionsCount = (formData: Object, questionsPaths: Object) => {
  return Object.entries(questionsPaths).reduce((count, [property, nestedProperties]) => {
    const value = property.split('.').reduce((acc, part) => acc?.[part], formData);
    if (
      value &&
      nestedProperties.every(
        key =>
          Object.prototype.hasOwnProperty.call(value, key) &&
          value[key] !== undefined &&
          value[key] !== ''
      )
    ) {
      return count + 1;
    }
    return count;
  }, 0);
};

export default getAnsweredQuestionsCount;
