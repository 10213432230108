import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  labelHeaderActions: {
    color: theme.palette.grey[800],
    fontSize: 14,
    fontWeight: 400,
    opacity: 0.6
  }
}));
