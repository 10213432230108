// @flow
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './styles';

const LoaderContent = ({ color, height }) => {
  const classes = useStyles({ height });

  return (
    <div className={classes.container}>
      <CircularProgress color={color ?? 'inherit'} size={40} />
    </div>
  );
};

export default LoaderContent;
