import React from 'react';

import { CustomLevelTreeItem, DefaultLevelTreeItem } from './FPTreeView.styled';

const FPTreeItemList = ({ tree = [], level = 1 }) => {
  const nextLevel = level + 1;
  const LevelComponent = level === 1 ? DefaultLevelTreeItem : CustomLevelTreeItem;

  return (
    <>
      {tree.map(({ id, name, children }) => (
        <LevelComponent key={id} nodeId={id} label={name}>
          {children.length > 0 && <FPTreeItemList tree={children} level={nextLevel} />}
        </LevelComponent>
      ))}
    </>
  );
};

export default FPTreeItemList;
