// @flow
import React from 'react';
import { Typography } from '@material-ui/core';
import ItemChip from 'UI/components/molecules/ItemChip';

import { useStyles } from '../styles';

type LabelsProps = {
  text: string,
  value: number
};

type StatsListItemProps = {
  labels: Array<LabelsProps>,
  initials: string,
  userEmail: string,
  statsTitle: string
};

const StatsListItem = ({ labels, initials, userEmail, statsTitle }: StatsListItemProps) => {
  const classes = useStyles();

  return (
    <>
      <Typography classes={{ root: classes.text }} variant="body2">
        {statsTitle}
      </Typography>
      {labels.map(item => (
        <div key={item.text} aria-label={item.text} className={classes.statsWrapper}>
          <ItemChip
            initials={initials ?? ''}
            userEmail={userEmail}
            mainText={item.text}
            secondaryText={item.value}
          />
        </div>
      ))}
    </>
  );
};

export default StatsListItem;
