// @flow
import React from 'react';
import { useFormContext } from 'react-hook-form';
import strings from 'strings';
import DecisionDialog from 'UI/components/organisms/DecisionDialog';
import { BULK_RESTRICTION_RULE } from 'UI/constants/defaults';

type RestrictionBulk = {
  rule: string,
  reason: string
};

type RestrictionDialogProps = {
  onOpenScheduleDialog: () => void,
  onClose: () => void,
  onSaveAsDraft?: () => void,
  onSubmitBulkEmail?: () => void,
  bulkRestriction: RestrictionBulk,
  isSendScheduleNow?: boolean
};

const {
  dialogs: { restrictions }
} = strings.bulkEmails;

const MAPPED_RESTRICTION_TITLES = {
  defaultRestriction: restrictions.titles.defaultRestriction,
  noQuotaAvailable: restrictions.titles.noQuotaAvailable,
  quotaNearlyReached: restrictions.titles.quotaNearlyReached,
  scheduleCloseNearScheduled: restrictions.titles.schedule,
  scheduleCloseNearSent: restrictions.titles.schedule,
  sendNearSent: restrictions.titles.send,
  sendNearScheduled: restrictions.titles.send
};

const RestrictionDialog = ({
  onOpenScheduleDialog,
  onClose,
  onSaveAsDraft,
  onSubmitBulkEmail,
  bulkRestriction,
  isSendScheduleNow
}: RestrictionDialogProps) => {
  const handleOnClose = () => onClose && onClose();

  const handleOnConfirm = async ok => ok && onOpenScheduleDialog();

  const form = useFormContext();

  const CustomDialogActions = ({ defaultButtonsProps, ActionsMapper }) => {
    const { onClose: closeDialog, onConfirm: scheduleAction } = defaultButtonsProps;
    const restrictionActions = {
      getCancelButton: (text, variant = 'outlined') => ({
        variant,
        text,
        onClick: closeDialog,
        show: true
      }),
      getScheduleButton: text => ({
        variant: 'contained',
        text,
        onClick: scheduleAction,
        show: true
      }),
      getContinueButton: text => ({
        variant: 'contained',
        text,
        onClick: () => {
          if (isSendScheduleNow) {
            onSubmitBulkEmail();
            return;
          }
          const { watch } = form;
          const formValues = watch();
          handleOnClose();
          onSubmitBulkEmail(formValues);
        },
        show: true
      }),
      getSaveAsDraftButton: (shouldShow? = true) => ({
        variant: 'outlined',
        text: restrictions.actions.saveAsDraft,
        onClick: onSaveAsDraft,
        show: shouldShow
      })
    };
    const {
      close: closeCopy,
      cancel: cancelCopy,
      schedule: scheduleCopy,
      setDifferentTime: setDifferentTimeCopy,
      continueAnyway: continueAnywayCopy
    } = restrictions.actions;
    const mappedActionsByRestrictionRule = {
      [BULK_RESTRICTION_RULE.defaultRestriction]: [
        restrictionActions.getCancelButton(closeCopy, 'contained')
      ],
      [BULK_RESTRICTION_RULE.noQuotaAvailable]: [
        restrictionActions.getCancelButton(cancelCopy),
        restrictionActions.getSaveAsDraftButton(!isSendScheduleNow),
        restrictionActions.getScheduleButton(scheduleCopy)
      ],
      [BULK_RESTRICTION_RULE.quotaNearlyReached]: [
        restrictionActions.getCancelButton(cancelCopy),
        restrictionActions.getSaveAsDraftButton(!isSendScheduleNow),
        restrictionActions.getContinueButton(continueAnywayCopy)
      ],
      [BULK_RESTRICTION_RULE.sendNearSent]: [
        isSendScheduleNow
          ? restrictionActions.getCancelButton(cancelCopy)
          : restrictionActions.getSaveAsDraftButton(),
        restrictionActions.getScheduleButton(
          isSendScheduleNow ? setDifferentTimeCopy : scheduleCopy
        )
      ],
      [BULK_RESTRICTION_RULE.sendNearScheduled]: [
        restrictionActions.getSaveAsDraftButton(),
        restrictionActions.getScheduleButton(scheduleCopy)
      ],
      [BULK_RESTRICTION_RULE.scheduleCloseNearSent]: [
        restrictionActions.getCancelButton(cancelCopy),
        restrictionActions.getScheduleButton(setDifferentTimeCopy)
      ],
      [BULK_RESTRICTION_RULE.scheduleCloseNearScheduled]: [
        restrictionActions.getCancelButton(cancelCopy),
        restrictionActions.getScheduleButton(setDifferentTimeCopy)
      ]
    };

    return <ActionsMapper buttons={mappedActionsByRestrictionRule[bulkRestriction.rule]} />;
  };

  return (
    <DecisionDialog
      title={MAPPED_RESTRICTION_TITLES[bulkRestriction.rule]}
      message={bulkRestriction.reason}
      isOpened
      CustomActions={CustomDialogActions}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      severity="warning"
      showSeverityIcon
    />
  );
};

RestrictionDialog.defaultProps = {
  onSaveAsDraft: () => {},
  isSendScheduleNow: false
};

export default RestrictionDialog;
