// @flow
import { useEffect, useRef } from 'react';

export const useUrlParamsUpdater = ({ history, location, orderBy }) => {
  const firstRender = useRef(false);
  useEffect(() => {
    if (firstRender.current) history.replace(location.pathname);
    firstRender.current = true;
  }, [history, location.pathname, orderBy]);
};
