// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { toggleSidebar } from 'actions/app';
import clsx from 'clsx';
import { THEME } from 'GlobalStyles';
import { EntityRoutes } from 'routes/constants';
import { userHasPermission, userHasRoles } from 'services/Authorization';
import ConditionalActionWrapper from 'UI/components/atoms/ConditionalWrapper';
import { getFeatureFlags } from 'UI/utils';

import SidebarMenu from './SidebarMenu';
import { useSidebarStyles, useStyles, useTooltipStyles } from './styles';

type SidebarProps = {
  children?: any
};

type SidebarIcon = {
  inactive: any,
  opened: any,
  active: any
};

type SidebarItemProps = {
  title: string,
  route: string,
  icon: SidebarIcon,
  subItems: Array<Object>
};

const allFeatureFlags = getFeatureFlags();

const isActiveItem = (item: SidebarItemProps, route) =>
  route === item.route || (item.route !== EntityRoutes.Home && route.startsWith(item.route));

const isActiveParent = (item: SidebarItemProps, route) =>
  item.subItems && item.subItems.some(subitem => isActiveItem(subitem, route));

const checkIsVisible = ({ featureFlag, roles, permission }) => {
  const hasFeaturePermission = !featureFlag || allFeatureFlags.includes(featureFlag);
  const hasRolePermission = !roles || userHasRoles(roles);
  const hasModulePermission = !permission || (permission && userHasPermission(permission));
  return hasRolePermission && hasModulePermission && hasFeaturePermission;
};

const getParent = route =>
  SidebarMenu.filter((menuItem: Object) => menuItem.subItems).find((parent: Object) =>
    isActiveParent(parent, route)
  );

const Sidebar = ({ children }: SidebarProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isSidebarOpen } = useSelector(({ app }) => app.ui);
  const isSmallViewPort = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'));
  const [selectedRoute, setSelectedRoute] = useState(location.pathname);
  const [expandedMenu, setExpandedMenu] = useState(getParent(location.pathname)?.route);

  const classes = useStyles();
  const sidebarClasses = useSidebarStyles({ isSidebarOpen });
  const tooltipClasses = useTooltipStyles({ isSidebarOpen });

  useEffect(() => {
    setSelectedRoute(location.pathname);

    location.pathname.includes('create')
      ? setExpandedMenu(null)
      : setExpandedMenu(getParent(location.pathname)?.route);
  }, [location.pathname]);

  const handleExpand = route => isExpanded => {
    if (expandedMenu === route) return setExpandedMenu(null);

    const expand = isExpanded ? route : null;
    return setExpandedMenu(expand);
  };

  const isActiveItemOrParent = (menu, route) =>
    isActiveItem(menu, route) || isActiveParent(menu, route);

  const isActiveStyleMenu = (menu, route) => isActiveItemOrParent(menu, route) && !menu.subItems;

  const handleCollapseMenu = () => isSmallViewPort && dispatch(toggleSidebar(false));

  return (
    <>
      <div className={classes.childrenWrapper}>{children}</div>
      <nav className={classes.nav}>
        {SidebarMenu.map(menu =>
          checkIsVisible(menu) ? (
            <ConditionalActionWrapper
              key={menu.route}
              condition={!menu.subItems}
              wrapper={(c = children) => (
                <Link to={menu.route} onClick={handleCollapseMenu} className="nav-link router-link">
                  {c}
                </Link>
              )}
            >
              <Accordion
                expanded={expandedMenu === menu.route}
                onChange={handleExpand(menu.route)}
                elevation={0}
                classes={{
                  root: sidebarClasses.accordionRoot,
                  expanded: sidebarClasses.accordionExpanded
                }}
              >
                <Tooltip classes={tooltipClasses} title={menu.title} placement="right" arrow>
                  <AccordionSummary
                    expandIcon={menu.subItems && <ExpandMoreIcon />}
                    id={`panel${menu.title}-header`}
                    classes={{
                      root: sidebarClasses.menuBox,
                      expanded: menu.subItems && sidebarClasses.accordionSummaryExpanded
                    }}
                  >
                    <div
                      className={clsx(
                        isActiveStyleMenu(menu, selectedRoute) && sidebarClasses.itemSelected,
                        sidebarClasses.menuContent
                      )}
                    >
                      <SvgIcon
                        component={menu.icon}
                        fontSize="small"
                        style={{
                          color: isActiveStyleMenu(menu, selectedRoute)
                            ? THEME.palette.primary.main
                            : THEME.palette.grey[900]
                        }}
                      />

                      <Typography
                        className={clsx(
                          sidebarClasses.label,
                          isActiveItemOrParent(menu, selectedRoute) &&
                            sidebarClasses.collapseMenuSelected
                        )}
                        variant="inherit"
                        noWrap
                      >
                        {menu.title}
                      </Typography>
                    </div>
                  </AccordionSummary>
                </Tooltip>

                {menu.subItems && (
                  <AccordionDetails classes={{ root: sidebarClasses.accordionDetails }}>
                    {menu.subItems.map(
                      subMenu =>
                        checkIsVisible(subMenu) && (
                          <ConditionalActionWrapper
                            key={subMenu.route}
                            condition={subMenu.route !== selectedRoute}
                            wrapper={(c = children) => (
                              <Link
                                to={subMenu.route}
                                onClick={handleCollapseMenu}
                                className="nav-link router-link"
                              >
                                {c}
                              </Link>
                            )}
                          >
                            <Tooltip
                              classes={tooltipClasses}
                              title={subMenu.title}
                              placement="right"
                              arrow
                            >
                              <div
                                className={clsx(
                                  sidebarClasses.subMenuBox,
                                  sidebarClasses.menuContent,
                                  sidebarClasses.subMenu,
                                  isActiveItemOrParent(subMenu, selectedRoute) &&
                                    sidebarClasses.itemSelected
                                )}
                              >
                                <SvgIcon
                                  component={subMenu.icon}
                                  fontSize="small"
                                  style={{
                                    color: isActiveStyleMenu(menu, selectedRoute)
                                      ? THEME.palette.primary.main
                                      : THEME.palette.grey[600]
                                  }}
                                />

                                <Typography
                                  className={sidebarClasses.label}
                                  variant="inherit"
                                  noWrap
                                >
                                  {subMenu.title}
                                </Typography>
                              </div>
                            </Tooltip>
                          </ConditionalActionWrapper>
                        )
                    )}
                  </AccordionDetails>
                )}
              </Accordion>
            </ConditionalActionWrapper>
          ) : null
        )}
      </nav>
    </>
  );
};

Sidebar.defaultProps = {
  children: undefined
};

export default Sidebar;
