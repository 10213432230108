// @flow
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { THEME as theme } from 'GlobalStyles';
import type { IconProps } from 'types/app';

import { useStyles } from './style';

const FPIcon = ({
  color = theme.palette.grey[600],
  onSetColor = null,
  size = 'md',
  ...rest
}: IconProps) => {
  const iconColor = (onSetColor && onSetColor(theme.palette)) || color;
  const classes = useStyles({ color: iconColor, size });

  /** Please read notes on UI/res/icons/streamline to know the difference between viewBox aspect Ratio   */

  return <SvgIcon classes={classes} htmlColor={iconColor} fill={iconColor} {...rest} />;
};

export default FPIcon;
