// @flow

import {
  HasExceededSizeParams,
  HasInvalidFileExtensionParams,
  HasReachedMaxFilesParams,
  RenameDuplicatedFilesParams,
  SelectedFile
} from './fileDropzone.types';

const getFileExtension = (file: string) => {
  if (!file) return '';

  const lastDotIndex = file.lastIndexOf('.');
  return file.substring(lastDotIndex + 1).toLowerCase();
};

export const hasReachedMaxFiles = ({
  files,
  filesCount,
  maxFiles
}: HasReachedMaxFilesParams): boolean => {
  const availableFilesCount = maxFiles - filesCount;
  return files.length > availableFilesCount;
};

export const hasInvalidFileExtension = ({
  allowedExt,
  files
}: HasInvalidFileExtensionParams): boolean => {
  if (!files || files?.length === 0) {
    return true;
  }

  return files.some(({ path }) => !allowedExt.includes(getFileExtension(path)));
};

export const hasExceededSize = ({ files, maxFileSize }: HasExceededSizeParams): boolean => {
  if (!files || files.length === 0) {
    return false;
  }

  return files.some(file => file.size > maxFileSize);
};

export const renameDuplicatedFiles = ({
  attachments,
  fileNameField,
  selectedFiles
}: RenameDuplicatedFilesParams): Array<SelectedFile> => {
  const uniqueFileNames = new Set(
    attachments.flatMap(({ files }) => files.map(file => file[fileNameField]))
  );

  return selectedFiles.map(selectedFile => {
    const { name, type, lastModified } = selectedFile;

    if (!uniqueFileNames.has(name)) {
      return selectedFile;
    }

    const lastDotIndex = name.lastIndexOf('.');
    const fileExtension = getFileExtension(name);
    const baseFileName = name.substring(0, lastDotIndex);

    const newFileName = renameFile(baseFileName, fileExtension, uniqueFileNames);

    const updatedFile = new File([selectedFile], newFileName, {
      type,
      lastModified,
      path: newFileName
    });

    return updatedFile;
  });
};

const renameFile = (baseName, extension, uniqueFileNames, version = 2) => {
  const newFileName = `${baseName} (${version}).${extension}`;

  if (!uniqueFileNames.has(newFileName)) {
    return newFileName;
  }

  return renameFile(baseName, extension, uniqueFileNames, version + 1);
};

export const isValidFileName = (fileName: string): boolean => {
  if (typeof fileName !== 'string' || fileName.length === 0) {
    return false;
  }

  // eslint-disable-next-line no-useless-escape
  const forbiddenCharacters = /[\/\\^<>~"\[\]:\+÷;,?'*@|$#%]/;

  return !forbiddenCharacters.test(fileName);
};

export const areFileNamesValid = (files: Array<SelectedFile>): boolean => {
  return files.every(({ name }) => isValidFileName(name));
};
