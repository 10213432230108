import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

const listWidths = {
  largeScreens: 500,
  smallScreens: 350
};

export const useStyles = makeStyles(theme => ({
  mainContainer: {
    height: '100%'
  },
  listContainer: {
    width: listWidths.smallScreens,
    minHeight: '100%',
    maxHeight: '100%',
    backgroundColor: theme.palette.brandColors.FPWhite,
    borderRight: '1px solid rgb(234, 234, 234)',
    [theme.breakpoints.up('lg')]: {
      width: listWidths.largeScreens
    }
  },
  previewContainer: {
    height: '100%',
    width: `calc(100% - ${listWidths.smallScreens}px)`,
    backgroundColor: theme.palette.brandColors.FPWhite,
    overflowX: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${listWidths.largeScreens}px)`
    }
  },
  emptyStateBox: {
    height: '100%'
  },
  emptyStateRoot: {
    '& > *:first-child': {
      [theme.breakpoints.down(1020)]: {
        padding: theme.spacing(4),
        justifyContent: 'unset !important'
      }
    }
  },
  emptyStateButton: {
    ...flexAlignCenterJustifyCenter,
    margin: theme.spacing(1, 'auto', 0)
  },
  emptyImage: {
    margin: '6% 0'
  },
  noContent: {
    ...flexAlignCenterJustifyCenter,
    width: '100%',
    height: '100%'
  }
}));
