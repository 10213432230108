// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import strings from 'strings';

import { ChangeLogLabelValue, ChangeLogValue } from './components';
import { useStyles } from './styles';
import { LogType } from './utils';

const ChangeLogsUpdates = ({ logs }) => {
  const classes = useStyles();

  if (!Array.isArray(logs?.differences?.updated) || logs?.differences?.updated.length === 0)
    return (
      <Typography gutterBottom>
        {strings.inventoryProfiles.sections.tabs.changeLogs.drawer.empty}
      </Typography>
    );

  return logs?.differences?.updated.map(log => {
    if (log?.type === LogType.DateList) {
      return <DateListComponent key={log.key} log={log} />;
    }

    if (log?.type === LogType.Html) {
      return (
        <div key={log.key} className={classes.container}>
          <Typography className={clsx(classes.textContainer, classes.textBold)}>
            {log?.label}
          </Typography>

          {log?.from && <ChangeLogLabelValue label="From" value={log?.from} type={log?.type} />}
          {log?.to && <ChangeLogLabelValue label="To" value={log?.to} type={log?.type} />}
        </div>
      );
    }

    return (
      <div key={log.key}>
        <div className={classes.rowLabel}>
          <Typography className={clsx(classes.textContainer, classes.textBold)}>
            {log?.label}
          </Typography>
          {log?.from !== undefined && (
            <>
              <Typography className={classes.textContainer}>changed from</Typography>
              <ChangeLogValue type={log?.type} value={log?.from} />

              <Typography className={classes.textContainer}>to</Typography>
              <ChangeLogValue type={log?.type} value={log?.to} />
            </>
          )}
        </div>
      </div>
    );
  });
};

const DateListComponent = ({ log }) => {
  const classes = useStyles();
  if (!Array.isArray(log?.from) || !Array.isArray(log?.to)) return null;

  return (
    <div className={classes.container}>
      <Typography className={clsx(classes.textContainer, classes.textBold)}>
        {log?.label}
      </Typography>

      <div className={classes.mlContainer}>
        <Typography>from:</Typography>

        <div className={classes.mlContainer}>
          {log.from.map((fromDate, index) => (
            <ChangeLogLabelValue
              key={fromDate}
              label={`${index + 1}`}
              value={fromDate}
              type={LogType.Date}
            />
          ))}
        </div>
        <Typography>to:</Typography>
        <div className={classes.mlContainer}>
          {log.to.map((toDate, index) => (
            <ChangeLogLabelValue
              key={toDate}
              label={`${index + 1}`}
              value={toDate}
              type={LogType.Date}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChangeLogsUpdates;
