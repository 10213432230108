import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenter } from 'UI/utils/styles';

const SIZE = 20;

export const useStyles = makeStyles(theme => ({
  flexAlignCenter,

  legendContainer: {
    ...flexAlignCenter,
    padding: theme.spacing(0, 2)
  },
  legendColor: {
    height: SIZE,
    width: SIZE,
    borderRadius: '50%',
    marginRight: theme.spacing(1)
  },

  mainColor: {
    backgroundColor: ({ mainColor }) => mainColor
  },
  secondaryColor: {
    backgroundColor: ({ secondaryColor }) => secondaryColor
  }
}));
