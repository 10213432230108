// @flow
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { IdTitle } from 'UI/constants/entityTypes';

import { useGlobalSelectSyles, useOtherClasses, useStyles } from './styles';

type SelectBoxProps = {
  error?: boolean,
  options: IdTitle[],
  placeholder?: string,
  onSelect?: (name?: string, value: any) => void,
  name?: string,
  isGlobal: boolean,
  selectedValue: Object
};

const SelectBox = (props: SelectBoxProps) => {
  const { error, options, placeholder, onSelect, name, isGlobal, selectedValue, ...rest } = props;
  const selectClasses = useStyles();
  const globalSelectClasses = useGlobalSelectSyles();
  const classes = useOtherClasses({ isGlobal });

  const handleChange = event => {
    const { value } = event.target;
    onSelect && onSelect(name, value);
  };

  return (
    <FormControl style={{ width: '100%' }} variant="outlined" error={!!error}>
      {!isGlobal && (
        <InputLabel htmlFor="outlined-select-native-simple" className={classes.label} error={false}>
          {placeholder}
        </InputLabel>
      )}
      <Select
        name={name}
        labelWidth={isGlobal ? 0 : undefined}
        value={selectedValue}
        onChange={handleChange}
        classes={isGlobal ? globalSelectClasses : selectClasses}
        className={isGlobal ? classes.selectGlobal : classes.select}
        label={isGlobal ? undefined : placeholder}
        {...rest}
      >
        {options.map(option => (
          <MenuItem className={classes.selectMenu} key={option.id || option} value={option}>
            {option.title || option}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
};

SelectBox.defaultProps = {
  error: false,
  placeholder: '',
  isGlobal: false,
  onSelect: undefined,
  name: 'select-box'
};

export default SelectBox;
