// @flow

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import isNil from 'lodash/isNil';
import { useFetchGuaranteeOptions } from 'services/FeeAgreement';
import strings from 'strings';
import Text from 'UI/components/atoms/Text';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import FeeAgreementPaymentTerms from 'UI/components/organisms/feeagreements/FeeAgreementPaymentTerms';
import { FeeAgreementFields } from 'UI/constants/status';
import {
  REGULAR_PERCENT_VALIDATION,
  REQUIRED_VALIDATION,
  WARRANTY_VALIDATION
} from 'UI/utils/index';

type FeeAgreementValidationInputsProps = {
  fields: string[],
  isWithLabel: boolean,
  outPutValue: boolean,
  paymentMode: string
};

const FieldsConfig = {
  [FeeAgreementFields.Percentage]: {
    registration: { name: FeeAgreementFields.Percentage },
    validations: { ...REQUIRED_VALIDATION, ...REGULAR_PERCENT_VALIDATION }
  },
  [FeeAgreementFields.GuaranteeDays]: {
    registration: { name: FeeAgreementFields.GuaranteeDays },
    validations: {
      ...REQUIRED_VALIDATION,
      ...WARRANTY_VALIDATION
    }
  },
  [FeeAgreementFields.Verbiage]: {
    registration: { name: FeeAgreementFields.Verbiage },
    validations: REQUIRED_VALIDATION
  },
  [FeeAgreementFields.FlatAmount]: {
    registration: { name: FeeAgreementFields.FlatAmount },
    validations: REQUIRED_VALIDATION
  },
  [FeeAgreementFields.PaymentTerms]: {
    registration: { name: FeeAgreementFields.PaymentDays }
  }
};

const FeeAgreementValidationInputs = (props: FeeAgreementValidationInputsProps) => {
  const { fields, isWithLabel, outPutValue, paymentMode } = props;
  const { register, errors, getValues, setValue } = useFormContext();
  const [shouldRender, setShouldRender] = useState(false);
  const guaranteeOptions = useFetchGuaranteeOptions(
    fields.includes(FeeAgreementFields.GuaranteeDays)
  );
  const handleInputChange = (name?: string, value: any) => {
    setValue(name, value, true);
    setShouldRender(!shouldRender);
  };

  const handleComboChange = (name?: string, value: any) => {
    setValue(name, value, true);
    setShouldRender(!shouldRender);
  };
  useEffect(() => {
    fields.forEach(field => {
      FieldsConfig[field] &&
        register(
          FieldsConfig[field].registration,
          !outPutValue ? FieldsConfig[field].validations : undefined
        );
    });
  }, [register, outPutValue, fields]);
  const guaranteeDays = getValues(FeeAgreementFields.GuaranteeDays);
  return (
    <>
      {fields.includes(FeeAgreementFields.Percentage) && (
        <TextBox
          name="fee_percentage"
          label={`${strings.feeAgreements.creation.feePercent} *`}
          inputType="percentage"
          value={getValues(FeeAgreementFields.Percentage) || ''}
          onChange={handleInputChange}
          error={!!errors.fee_percentage}
          errorText={errors.fee_percentage && errors.fee_percentage.message}
          disabled={outPutValue}
        />
      )}
      {fields.includes(FeeAgreementFields.FlatAmount) && (
        <TextBox
          name="flat_fee_amount"
          label={`${strings.feeAgreements.creation.flatAmount} *`}
          inputType="currency"
          value={getValues(FeeAgreementFields.FlatAmount) || ''}
          error={!!errors.flat_fee_amount}
          errorText={errors.flat_fee_amount && errors.flat_fee_amount.message}
          onChange={handleInputChange}
          disabled={outPutValue}
        />
      )}

      {fields.includes(FeeAgreementFields.GuaranteeDays) && (
        <AutocompleteSelect
          width="100%"
          name="guarantee_days"
          placeholder={`${strings.feeAgreements.creation.guaranteeDays} *`}
          disableClearable
          selectedValue={!isNil(guaranteeDays) ? guaranteeDays : null}
          error={!!errors.guarantee_days}
          errorText={errors.guarantee_days && errors.guarantee_days.message}
          defaultOptions={guaranteeOptions[paymentMode] || []}
          getOptionLabel={option => `${option}`}
          getOptionSelected={(option, value) => option === value}
          onSelect={handleComboChange}
          disabled={outPutValue}
        />
      )}

      {fields.includes(FeeAgreementFields.PaymentTerms) && (
        <FeeAgreementPaymentTerms readOnly={outPutValue} />
      )}
      {fields.includes(FeeAgreementFields.Verbiage) && (
        <>
          {isWithLabel && (
            <Text
              outPutValue={outPutValue}
              variant="subtitle1"
              text={strings.feeAgreements.validation.modifyVerbiage}
              fontSize={16}
            />
          )}
          <TextBox
            name="verbiage_changes"
            label={strings.feeAgreements.creation.verbiage}
            value={getValues(FeeAgreementFields.Verbiage) || ''}
            onChange={handleInputChange}
            outPutValue={outPutValue}
            multiline
            error={!!errors.verbiage_changes}
            errorText={errors.verbiage_changes && errors.verbiage_changes.message}
          />
        </>
      )}
    </>
  );
};

FeeAgreementValidationInputs.defaultProps = {
  isWithLabel: false,
  outPutValue: false
};

export default FeeAgreementValidationInputs;
