// @flow
import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import useUploadStatus from 'hooks/uploadStatus';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import Text from 'UI/components/atoms/Text';
import { CloseIcon, colors, SuccessIcon } from 'UI/res';

import { styles, useProgressStyles, useStyles } from './styles';

type FileChipProps = {
  loading: boolean,
  error?: boolean,
  file: any,
  fileName: string,
  message: string,
  onFileDelete?: (file: any) => any
};

const FileChip = (props: FileChipProps) => {
  const { loading, error, message, file, fileName, onFileDelete, ...rest } = props;
  const classes = useStyles();
  const circularProgressClasses = useProgressStyles();
  const status = useUploadStatus('finished', loading, error);

  const handleDeleteClick = () => {
    onFileDelete && onFileDelete(file);
  };

  const design = {
    loading: {
      style: styles.loading,
      adornment: (
        <FPIconButton style={styles.button} disabled tooltipProps={{ title: 'Loading' }}>
          <CircularProgress classes={circularProgressClasses} size={24} thickness={4} />
        </FPIconButton>
      )
    },
    success: {
      style: styles.success,
      adornment: (
        <FPIconButton style={styles.button} disabled tooltipProps={{ title: 'Success' }}>
          <SuccessIcon fill={colors.white} />
        </FPIconButton>
      )
    },
    finished: {
      style: styles.finished,
      adornment: (
        <FPIconButton
          style={styles.button}
          onClick={handleDeleteClick}
          tooltipProps={{ title: 'Delete' }}
        >
          <CloseIcon fill={colors.grey} />
        </FPIconButton>
      )
    },
    error: {
      style: styles.error,
      adornment: (
        <FPIconButton
          style={styles.button}
          onClick={handleDeleteClick}
          tooltipProps={{ title: 'Delete' }}
        >
          <CloseIcon fill={colors.error} />
        </FPIconButton>
      )
    }
  };

  return (
    <Fade in timeout={1000}>
      <Card classes={classes} elevation={0} style={design[status].style}>
        <CardActionArea style={styles.cardAction} href={file?.url} {...rest}>
          <Text variant="body1" text={fileName} cropped />
        </CardActionArea>
        {design[status].adornment}
        {error && (
          <Text variant="body1" text={message} fontSize={12} customStyle={styles.errorLabel} />
        )}
      </Card>
    </Fade>
  );
};

FileChip.defaultProps = {
  error: false,
  onFileDelete: undefined,
  message: ''
};

export default FileChip;
