import { useEffect } from 'react';
import { PageTitles } from 'UI/constants/defaults';

const usePageTitle = ({ title, isLoading, shouldLoadTitle = true }) => {
  useEffect(() => {
    if (shouldLoadTitle) {
      document.title = isLoading || !title ? PageTitles.Default : title;
    }
  }, [title, isLoading, shouldLoadTitle]);
};

export default usePageTitle;
