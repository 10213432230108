// @flow
import { getToken } from 'services/Authentication';
import { EnvApiUrls } from 'UI/constants/defaults';
import { FormSchema } from 'UI/constants/entityTypes';

import useFetchCandidateDataSheet from './useFetchCandidateDataSheetSchema';

const useCandidateDataSheetSchema = () => {
  const [state, actions] = useFetchCandidateDataSheet({
    schemaName: FormSchema.CandidateDataSheetFull
  });

  return [
    {
      ...state,
      httpOptions: {
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      },
      baseUrl: `${EnvApiUrls.MAIN}/`
    },
    {
      actions
    }
  ];
};

export default useCandidateDataSheetSchema;
