import React, { useCallback, useState } from 'react';
import { SimplifiedTable } from 'features/command-center/';
import { InterviewDetailsDrawer } from 'UI/components/organisms/InterviewDetailsDrawer';
import { interviewDetailsSubmitHandlers } from 'UI/components/organisms/InterviewDetailsDrawer/utils';
import { RowActions } from 'UI/constants/defaults';

import { createPhonesColumns } from '../../utils';

const API_VERSION = 2;

export const InterviewsTable = ({
  tableKey,
  endpoint,
  columns,
  orderByOptions,
  virtualProps,
  filters,
  title,
  variant
}) => {
  const [interviewId, setInterviewId] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  const openDetailsDrawer = id => setInterviewId(id);

  const handleSubmitSucceeded = useCallback(
    async (formData, currentInterviewId, existingItemId) => {
      setShouldRefresh(false);
      const result = await interviewDetailsSubmitHandlers[variant](
        formData,
        currentInterviewId,
        existingItemId
      );
      setShouldRefresh(true);
      return result;
    },
    [variant]
  );

  return (
    <>
      <SimplifiedTable
        apiVersion={API_VERSION}
        columns={columns}
        draggableColumnsEnabled
        endpoint={endpoint}
        filters={filters}
        header={title}
        orderByOptions={orderByOptions}
        pageKey={tableKey}
        rowActions={[
          {
            name: RowActions.View,
            customAction: 'rowClickBuilder',
            onClick: openDetailsDrawer
          },
          {
            action: 'viewContact',
            name: 'viewContact',
            value: 'contactData'
          }
        ]}
        virtualProps={virtualProps}
        createExtraColumns={createPhonesColumns}
        shouldRefresh={shouldRefresh}
      />
      {interviewId && (
        <InterviewDetailsDrawer
          drawerVariant={variant}
          setDrawerVariant={setInterviewId}
          interviewId={interviewId}
          open={interviewId}
          onClose={() => setInterviewId(null)}
          onSubmit={handleSubmitSucceeded}
        />
      )}
    </>
  );
};
