import isNil from 'lodash/isNil';
import strings from 'strings';
import { currencyFormatter, formatPercentage } from 'UI/utils';

const FORMATTERS = {
  avgFee: value =>
    currencyFormatter(value, 0, { notation: 'standard', forceCompactNotation: false }),
  fallOffRatePercent: value => formatPercentage(value)
};

export const formatStats = ({ data, drawerHandlers }) => {
  const keyOrder = [
    'sendouts',
    'totalPlacements',
    'placedRatio',
    'totalFallOffs',
    'fallOffRatePercent',
    'avgFee',
    'feeAgreementsSigned'
  ];

  const finalData = data?.data ?? data;

  const orderedData = keyOrder.reduce((acc, key) => {
    if (!isNil(finalData[key])) {
      acc[key] = finalData[key];
    }
    return acc;
  }, {});

  return Object.keys(orderedData).map(key => {
    const value = FORMATTERS[key] ? FORMATTERS[key](orderedData[key]) : finalData[key];
    return {
      title: strings.commandCenter.metricsTitles[key],
      value,
      ...(drawerHandlers &&
        finalData[`${key}Url`] && {
          onClick: () => drawerHandlers[key]({ url: finalData[`${key}Url`] })
        })
    };
  });
};
