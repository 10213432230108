// @flow
import React, { useMemo } from 'react';
import { getFeatureFlags } from 'UI/utils';

const FeatureFlagsContext = React.createContext(null);

type FeatureFlagsProviderProps = {
  value?: string[],
  children: React.ReactNode
};

const FeatureFlagsProvider = ({ value, children }: FeatureFlagsProviderProps) => {
  const featureFlags = useMemo(() => value ?? getFeatureFlags(), [value]);

  const context = useMemo(
    () => ({
      checkIfFeatureFlagEnabled: (flagName: string): boolean => featureFlags.includes(flagName)
    }),
    [featureFlags]
  );

  return <FeatureFlagsContext.Provider value={context}>{children}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlags = () => {
  const context = React.useContext(FeatureFlagsContext);
  if (!context) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagsProvider');
  }
  return context;
};

export default FeatureFlagsProvider;
