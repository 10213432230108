// @flow
import type { NodeSelection } from 'UI/components/organisms/PilIndustries/pilIndustries.types';
import { Endpoints } from 'UI/constants/endpoints';

export const getNodeSelectedParams = (nodeSelected: NodeSelection) => {
  const hasSpecialtyId = !!nodeSelected?.specialtyId;

  const pilTypeInfo = {
    industry: {
      ...nodeSelected,
      informationEndpoint: `${Endpoints.Industries}/${nodeSelected.id}`,
      functionalTitlesEndpoint: null,
      showFunctionalTitles: false
    },
    specialty: {
      ...nodeSelected,
      informationEndpoint: `${Endpoints.Specialties}/${nodeSelected.id}`,
      functionalTitlesEndpoint: `${Endpoints.Positions}?specialtyId=${nodeSelected.id}`,
      showFunctionalTitles: true
    },
    subspecialty: {
      ...nodeSelected,
      informationEndpoint: `${Endpoints.SubspecialtiesSearch}/${nodeSelected.id}`,
      functionalTitlesEndpoint: hasSpecialtyId
        ? `${Endpoints.Positions}?specialtyId=${nodeSelected.specialtyId}`
        : null,
      showFunctionalTitles: true
    }
  };

  return pilTypeInfo[nodeSelected.type];
};
