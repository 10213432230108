import { Endpoints } from 'UI/constants/endpoints';

export const DRAWER_TITLES_MAP = {
  editInterview: 'Edit Interview',
  preparation: 'Preparation',
  debriefing: 'Debriefing',
  closingCall: 'Closing Call',
  notes: 'Notes'
};

export const DRAWER_VARIANTS_MAP = {
  editInterview: 'editInterview',
  preparation: 'preparation',
  debriefing: 'debriefing',
  closingCall: 'closingCall',
  notes: 'notes'
};

export const OptionsVariantsKeys = {
  InterviewDetails: 'interviewDetails',
  FollowUp: 'followUp'
};

export const ENDPOINT_BY_VARIANT_MAP = {
  [DRAWER_VARIANTS_MAP.debriefing]: Endpoints.SendoutDebriefingCalls,
  [DRAWER_VARIANTS_MAP.preparation]: Endpoints.SendoutPreparationCalls
};
