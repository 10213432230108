// @flow
import React from 'react';
import clsx from 'clsx';
import FPAvatar from 'UI/components/atoms/FPAvatar';
import { FPCard, FPCardContent } from 'UI/components/atoms/FPCard';
import Text from 'UI/components/atoms/Text';
import { getId } from 'UI/utils';

import { useStyles } from './styles';

type InfoCardProps = {
  ActionsComponent?: node,
  avatarProps?: FPAvatarProps,
  customClasses: string,
  detail?: string,
  email?: string,
  infoCardProps?: any,
  initials: string,
  title?: string
};

const InfoCard = ({
  ActionsComponent,
  avatarProps,
  customClasses,
  detail,
  email,
  infoCardProps,
  initials,
  title
}: InfoCardProps) => {
  const classes = useStyles();

  return (
    <FPCard className={clsx(classes.infoCard, customClasses)} data-testid="info-card">
      <FPCardContent
        classes={{ root: classes.infoCardContent }}
        footerProps={infoCardProps?.footerProps || {}}
      >
        <div className={classes.infoCardContainer}>
          <Text
            data-testid="info-card-type"
            className={classes.infoCardType}
            variant="overline"
            text={infoCardProps?.type || detail}
          />
          <FPAvatar
            data-testid="info-card-avatar"
            className={classes.infoCardAvatar}
            size="small"
            userEmail={email}
            {...avatarProps}
          >
            {initials}
          </FPAvatar>
          <Text
            data-testid="info-card-title"
            className={classes.infoCardTitle}
            text={infoCardProps?.title || title}
            variant="subtitle2"
            cropped
          />
          <div className={classes.infoCardDetailsContainer}>
            <div data-testid="info-card-details" className={classes.infoCardDetails}>
              {Array.isArray(infoCardProps?.details) &&
                infoCardProps.details.map(props => (
                  <Text key={getId()} variant="body2" cropped {...props} />
                ))}
              {infoCardProps?.actionsProps && (
                <div className={classes.infoCardActions}>
                  <ActionsComponent {...infoCardProps.actionsProps} />
                </div>
              )}
            </div>
          </div>
        </div>
      </FPCardContent>
    </FPCard>
  );
};

InfoCard.defaultProps = {
  ActionsComponent: null,
  avatarProps: {},
  customClasses: '',
  detail: '',
  email: '',
  infoCardProps: undefined,
  title: ''
};

export default InfoCard;
