import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  overviewContainer: {
    padding: theme.spacing(2.5)
  },
  overviewRow: {
    paddingTop: theme.spacing(2)
  },
  boldText: {
    fontWeight: theme.typography.fontWeightMedium
  }
}));
