import React from 'react';
import Grid from '@material-ui/core/Grid';
import { InventoryAdded } from 'features/command-center/components/activity/InventoryAdded';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import {
  CandidateEntity,
  CompanyEntity,
  ContactEntity,
  HiringAuthorityEntity,
  JobOrderEntity
} from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';

export const PilGrowth = ({ weeks, recruiterId, teamId }) => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const canDoDrillDown = checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterDrillDown);
  const requestParams = {
    recruiter: recruiterId.toUpperCase(),
    teamId,
    ...(canDoDrillDown && { version: 1 })
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <InventoryAdded
          hasDrillDown={canDoDrillDown}
          requestParams={requestParams}
          weeks={weeks}
          entity={CompanyEntity}
        />
      </Grid>
      <Grid item xs={12}>
        <InventoryAdded
          hasDrillDown={canDoDrillDown}
          requestParams={requestParams}
          weeks={weeks}
          entity={HiringAuthorityEntity}
        />
      </Grid>
      <Grid item xs={12}>
        <InventoryAdded
          hasDrillDown={canDoDrillDown}
          requestParams={requestParams}
          weeks={weeks}
          entity={JobOrderEntity}
        />
      </Grid>
      <Grid item xs={12}>
        <InventoryAdded
          hasDrillDown={canDoDrillDown}
          requestParams={requestParams}
          weeks={weeks}
          entity={CandidateEntity}
        />
      </Grid>
      <Grid item xs={12}>
        <InventoryAdded
          hasDrillDown={canDoDrillDown}
          requestParams={requestParams}
          weeks={weeks}
          entity={ContactEntity}
        />
      </Grid>
    </Grid>
  );
};
