// @flow
import React from 'react';
import MomentUtils from '@date-io/moment';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Clear as ClearIcon, InsertInvitation as CalendarIcon } from '@material-ui/icons';
import {
  DatePicker,
  DateTimePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
  TimePicker
} from '@material-ui/pickers';
import type { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import moment from 'moment';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { DateFormats } from 'UI/constants/defaults';
import { nestTernary } from 'UI/utils';

import { useStyles } from './styles';

type CustomDatePickerProps = {
  disabled: boolean,
  isClearable?: boolean,
  label?: string,
  name: string,
  onDateChange: (string, any) => any,
  timeFormat: string,
  value: ParsableDate,
  variant?: 'default' | 'time' | 'keyboardTime',
  withTime?: boolean
};

const CustomDatePicker = ({
  disabled,
  isClearable,
  label,
  name,
  onDateChange,
  timeFormat,
  value,
  variant,
  withTime,
  ...rest
}: CustomDatePickerProps) => {
  moment.locale('en');
  const classes = useStyles();

  const onDateClear = e => {
    e.stopPropagation();
    onDateChange(name, null);
  };

  const onChange = date => onDateChange && onDateChange(name, date?.isValid() ? date : null);

  const PickerComponents = {
    time: TimePicker,
    keyboardTime: KeyboardTimePicker,
    default: DateTimePicker
  };

  const Picker = withTime ? PickerComponents[variant] : DatePicker;
  const pickerFormat =
    timeFormat || nestTernary(withTime, DateFormats.DetailDateTime, DateFormats.DetailDate);

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <Picker
        allowKeyboardControl
        ampm
        aria-label={label}
        aria-labelledby={label}
        autoOk
        classes={classes}
        disabled={disabled}
        format={pickerFormat}
        id="custom-date-picker"
        inputVariant="outlined"
        label={label}
        onChange={onChange}
        value={value}
        variant="inline"
        {...rest}
        InputProps={{
          'data-testid': 'custom-date-picker',
          endAdornment: (
            <InputAdornment position="end">
              <FPIconButton disabled={disabled} onClick={value && isClearable ? onDateClear : null}>
                {value && isClearable ? <ClearIcon /> : <CalendarIcon />}
              </FPIconButton>
            </InputAdornment>
          )
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

CustomDatePicker.defaultProps = {
  disabled: false,
  isClearable: true,
  label: '',
  timeFormat: null,
  variant: 'default',
  withTime: false
};

export default CustomDatePicker;
