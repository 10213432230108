import { makeStyles } from '@material-ui/core/styles';
import { globalStyles } from 'GlobalStyles';
import { layout, mainLayoutPadding } from 'UI/constants/dimensions';
import { nestTernary } from 'UI/utils';

export const useStyles = makeStyles({
  content: ({ contentStyles }) => ({
    height: '100%',
    ...contentStyles
  })
});

const filterSize = 48;

const filtersContainerLeftPosition = -500;

export const useCollapsableStyles = makeStyles(theme => ({
  container: {
    width: layout.sideFiltersWidth,
    position: ({ isTableExpanded }) => (isTableExpanded ? 'fixed' : 'absolute'),
    zIndex: 4,
    top: 0,
    bottom: 0,
    margin: 0,
    transition: `all ${globalStyles.transition}`
  },

  inlineContainerClosed: {
    left: -layout.sideFiltersWidth
  },
  inlineContainerOpened: {
    left: 0
  },

  overlayContainerClosed: {
    left: ({ isSidebarOpen, isTableExpanded }) =>
      isSidebarOpen
        ? nestTernary(
            isTableExpanded,
            filtersContainerLeftPosition,
            layout.sideBarWidth - layout.sideFiltersWidth
          )
        : nestTernary(
            isTableExpanded,
            filtersContainerLeftPosition,
            layout.sideBarWidthCollapsed - layout.sideFiltersWidth
          ),
    zIndex: ({ isTableExpanded }) => {
      const zIndex = isTableExpanded
        ? theme.zIndex.expandedTableFilters
        : theme.zIndex.collapsedTableFilters;
      return zIndex;
    }
  },
  overlayContainerOpened: {
    left: ({ isSidebarOpen, isTableExpanded }) => {
      const position = isSidebarOpen
        ? nestTernary(isTableExpanded, '1px', layout.sideBarWidth)
        : nestTernary(isTableExpanded, '1px', layout.sideBarWidthCollapsed);
      return position;
    },
    zIndex: ({ isTableExpanded }) => {
      const zIndex = isTableExpanded
        ? theme.zIndex.expandedTableFilters
        : theme.zIndex.collapsedTableFilters;
      return zIndex;
    }
  },

  togglerButton: {
    position: 'absolute',
    right: -filterSize,
    top: theme.spacing(mainLayoutPadding),
    background: theme.palette.primary.main,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    cursor: 'pointer',
    height: filterSize
  }
}));
