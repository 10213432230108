// @flow
import React, { useCallback } from 'react';
import { SchemaStatuses } from 'constants/schemaStatuses';
import strings from 'strings';
import FPFormStatus from 'UI/components/molecules/FPFormStatus';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { EnvApiUrls } from 'UI/constants/defaults';

import useReferenceCheckForm from './hooks/useReferenceCheckForm';
import { StyledForm } from './ReferenceCheckDrawer.styled';
import { Mode } from './ReferenceCheckDrawer.types';
import { DrawerMode } from './ReferenceCheckTabLayout.constants';
import { validateReferenceCheck } from './utils';

type ReferenceCheckDrawerProps = {
  isOpen: boolean,
  isSaving: boolean,
  isSaved: boolean,
  candidateId: string,
  status: string,
  mode: Mode,
  candidateName: string,
  recruiterName: string,
  referenceId: string,
  onClose: () => void,
  onEditItemClick: () => void,
  onSaveSuccess: () => void,
  onSave: () => void,
  onSaveError: () => void
};
const BASE_URL = `${EnvApiUrls.API_URL_AWS}/`;
const HTTP_OPTIONS = { headers: { 'x-api-key': EnvApiUrls.API_KEY_AWS } };
const MODAL_TITLE = strings.inventoryProfiles.sections.tabs.referenceCheck.modalTitle;

const ReferenceCheckDrawer = ({
  isOpen,
  isSaving,
  isSaved,
  status,
  candidateId,
  candidateName,
  recruiterName,
  referenceId,
  mode,
  onClose,
  onEditItemClick,
  onSave,
  onSaveSuccess,
  onSaveError
}: ReferenceCheckDrawerProps) => {
  const [formState, dispatch] = useReferenceCheckForm({
    referenceId,
    candidateId,
    candidateName: candidateName || '',
    recruiterName: recruiterName || '',
    mode,
    onSaveSuccess,
    onSave,
    onSaveError
  });

  const onCloseModal = useCallback(() => {
    onClose();
    dispatch.handleResetForm();
  }, [dispatch, onClose]);

  const commonStyledFormProps = {
    showErrorList: false,
    baseUrl: BASE_URL,
    noHtml5Validate: true,
    httpOptions: HTTP_OPTIONS,
    submitRef: formState.saveButtonRef,
    formData: formState.formData,
    placeholders: formState.placeHolders
  };

  return (
    <DrawerContentLayout
      containerComponent="div"
      drawerProps={{
        open: isOpen,
        onClose: onCloseModal
      }}
      primaryProps={{
        type: 'button',
        onClick: () => {
          mode === DrawerMode.View && onEditItemClick
            ? onEditItemClick()
            : formState.saveButtonRef.current.click();
        },
        isSaving,
        isSuccess: isSaved && mode === DrawerMode.Create,
        disabled:
          isSaving ||
          formState.isFetching ||
          (mode === DrawerMode.View && !onEditItemClick) ||
          (isSaved && mode === DrawerMode.Create),
        initialText: mode === DrawerMode.View ? strings.shared.ui.edit : strings.shared.ui.save
      }}
      uiState={{
        isFormLoading: formState.isFetching
      }}
      isTopToolbarNeeded
      title={MODAL_TITLE}
      subheader={
        <FPFormStatus
          status={mode !== DrawerMode.Create && mode ? status : SchemaStatuses.Pending}
          shouldShowTotal={false}
        />
      }
      onClose={onCloseModal}
    >
      {mode === DrawerMode.Create && (
        <StyledForm
          {...commonStyledFormProps}
          schema={formState.fetchedSchemas?.structure}
          uiSchema={formState.fetchedSchemas?.ui}
          onChange={dispatch.handleFormChange}
          customValidate={validateReferenceCheck}
          onSubmit={dispatch.handleSubmit}
        />
      )}
      {mode === DrawerMode.Edit && (
        <StyledForm
          {...commonStyledFormProps}
          schema={formState.fetchedSchemas?.structure}
          uiSchema={formState.fetchedSchemas?.ui}
          onChange={dispatch.handleFormChange}
          customValidate={validateReferenceCheck}
          noHTML5Validate
          onSubmit={dispatch.handleUpdate}
        />
      )}
      {mode === DrawerMode.View && (
        <StyledForm
          {...commonStyledFormProps}
          schema={formState.fetchedSchemas?.structure}
          uiSchema={formState.fetchedSchemas?.ui}
          readonly
        />
      )}
    </DrawerContentLayout>
  );
};

export default ReferenceCheckDrawer;
