import { makeStyles } from '@material-ui/core/styles';

export const useStyles = ({ textColor, color, iconStyles }) =>
  makeStyles({
    root: {
      backgroundColor: color,
      color: textColor
    },
    outlined: {
      backgroundColor: 'transparent',
      border: `1px solid ${color}`,
      color
    },
    icon: {
      ...iconStyles
    }
  });
