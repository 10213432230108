// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import type { DecisionDialogProps } from 'types/app';
import Text from 'UI/components/atoms/Text';
import { When } from 'UI/components/atoms/When';

import DecisionDialog from '../DecisionDialog';

import { useStyles } from './styles';

type DialogDetailsType = {
  detail: string,
  title: string
};

type DetailedConfirmationDialogProps = {
  decisionDialogProps?: DecisionDialogProps,
  details?: Array<DialogDetailsType>
};

const DetailedConfirmationDialog = ({
  decisionDialogProps,
  details
}: DetailedConfirmationDialogProps) => {
  const classes = useStyles();

  return (
    <DecisionDialog
      isOpened
      withButtons="YesNo"
      {...decisionDialogProps}
      body={
        <Grid container justify="space-between" className={classes.dialogSections}>
          {details.map(({ title, detail }) => (
            <Grid key={title} container item sm={6} direction="column" wrap="nowrap">
              <Text text={title} variant="subtitle2" />
              <When condition={!!detail}>
                <Text text={detail} variant="h6" cropped />
              </When>
            </Grid>
          ))}
        </Grid>
      }
    />
  );
};

DetailedConfirmationDialog.defaultProps = {
  decisionDialogProps: {},
  details: []
};

export default DetailedConfirmationDialog;
