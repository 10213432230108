// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { InlineFiltersProvider } from 'features/command-center/components/shared/InlineFilters/InlineFiltersProvider';
import strings from 'strings';
import TabsView from 'UI/components/templates/TabsView';

import { Layout } from '../Layout';

import { SelectTeamCard } from './components';
import { useStyles } from './styles';

interface DominateSheetLayoutProps {
  tabs: {
    id: string | number,
    label: string,
    view: React.ReactNode,
    visible?: boolean
  };
}

export const DominateSheetLayout = ({ tabs }: DominateSheetLayoutProps) => {
  const classes = useStyles();

  return (
    <Layout title={strings.commandCenter.dominateSheet.title} path={strings.commandCenter.title}>
      <InlineFiltersProvider>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SelectTeamCard />
          </Grid>

          <Grid item xs={12}>
            <TabsView
              enableUrlNavigation
              initialTab="hotInventory"
              panelHeight="unset"
              tabs={tabs ?? []}
              tabPanelProps={{
                className: classes.tab
              }}
              useIndexes={false}
            />
          </Grid>
        </Grid>
      </InlineFiltersProvider>
    </Layout>
  );
};
