// @flow
import React, { useCallback, useEffect, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import API from 'services/API';
import { getSendoutById } from 'services/Sendouts';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { Endpoints } from 'UI/constants/endpoints';
import { sendoutDataToForm } from 'UI/utils/inventory';

import { errorSendoutLoad } from './SendoutErrors';
import SendoutsFormConnected from './SendoutForm';

interface SendoutManagerProps {
  id?: number;
  jobOrder?: any;
  onEdit?: any => void;
  onCreate?: any => void;
  onDelete?: any => void;
  onClose?: any => void;
  onNavigate?: any => void;
}

const SendoutManager = ({
  id,
  jobOrder,
  onClose,
  onCreate,
  onEdit,
  onDelete,
  onNavigate
}: SendoutManagerProps) => {
  const [uiState, setUiState] = useState({
    isLoading: true,
    isError: false
  });

  const [sendoutData, setSendoutData] = useState({
    data: null,
    jobOrder
  });

  const handleLoadSendoutData = useCallback(async () => {
    if (!id) {
      setSendoutData({
        data: null,
        jobOrder: jobOrder || null
      });
      setUiState(prev => ({ ...prev, isLoading: false, isError: !jobOrder }));
      return;
    }

    const sendout = await getSendoutById(id);
    if (!sendout.data) {
      setUiState(prev => ({ ...prev, isError: true }));
      return;
    }

    const jobOrderData = await API(
      `${Endpoints.JobOrders}/${sendout.data.job_order_id}/${Endpoints.HiringAuthorities}`,
      {
        apiVersion: 1
      }
    );

    if (!jobOrderData.data) {
      setUiState(prev => ({ ...prev, isError: true }));
      return;
    }

    const currentHiringAuthority = sendout.data.hiringAuthorithies.map(
      item => item.hiringAuthority
    );
    const hiringAuthorities = uniqBy([...jobOrderData.data, ...currentHiringAuthority], 'id');

    const sendoutJobOrder = {
      ...sendout.data.joborder,
      hiringAuthorities: hiringAuthorities || [],
      id: sendout.data.job_order_id
    };

    const formatedData = sendoutDataToForm(sendout.data, sendout.data.joborder);

    setSendoutData({ data: formatedData, jobOrder: sendoutJobOrder });
    setUiState({ isError: false, isLoading: false });
  }, [id, jobOrder]);

  useEffect(() => {
    handleLoadSendoutData();
  }, [handleLoadSendoutData]);

  return uiState.isLoading || uiState.isError ? (
    <DrawerContentLayout
      errorRender={errorSendoutLoad}
      onClose={onClose}
      uiState={{
        isFormLoading: !uiState.isError,
        isError: uiState.isError
      }}
    />
  ) : (
    <SendoutsFormConnected
      sendoutData={sendoutData.data}
      profile={sendoutData.jobOrder}
      onClose={onClose}
      onEdit={onEdit}
      onDelete={onDelete}
      onNavigate={onNavigate}
      onCreate={onCreate}
    />
  );
};

SendoutManager.defaultProps = {
  onEdit: () => {},
  onClose: () => {},
  onDelete: () => {},
  onCreate: () => {},
  onNavigate: () => {},
  jobOrder: {},
  id: null
};

export default SendoutManager;
