import { makeStyles } from '@material-ui/core/styles';
import { THEME as theme } from 'GlobalStyles';

const buttonSpacing = {
  small: theme.spacing(2),
  medium: theme.spacing(4),
  large: theme.spacing(4.5)
};

export const useStyles = makeStyles({
  cancelBox: {
    marginRight: ({ primaryProps }) =>
      primaryProps.size ? buttonSpacing[primaryProps.size] : buttonSpacing.medium
  },
  actionsContainer: {
    display: 'flex'
  }
});
