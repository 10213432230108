import { container } from 'UI/constants/dimensions';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles(theme => ({
  recruiterLayoutContainer: {
    padding: theme.spacing(4, 2)
  },
  circularProgressContainer: {
    width: '100%',
    ...flexAlignCenterJustifyCenter,
    padding: theme.spacing(2)
  },
  title: {
    width: '100%',
    fontWeight: '700 !important'
  },
  content: {
    flexDirection: 'column'
  },
  summary: {
    backgroundColor: theme.palette.grey[100]
  },
  accordionContainer: {
    padding: theme.spacing(1, 0)
  },
  accordionPaper: {
    boxShadow: container.boxShadow
  },
  skeletonItems: {
    margin: theme.spacing(2, 0)
  },
  drawerContent: {
    padding: theme.spacing(4, 4, 0, 4)
  },
  paper: {
    overflow: 'hidden',
    width: 800
  },
  notesTitle: {
    padding: theme.spacing(0, 4)
  },
  noteContainer: {
    padding: theme.spacing(4)
  },
  formContainer: {
    height: 350
  }
}));
