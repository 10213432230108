import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  formRoot: {
    display: 'flex',
    flexDirection: 'column'
  },
  radioGroupRoot: {
    marginBottom: 8
  },
  radioGroupRadio: {
    padding: theme.spacing(0.5)
  },
  findCompanyRoot: {
    flexGrow: 1,
    marginTop: theme.spacing(4)
  },
  entityDetailsRoot: {
    margin: theme.spacing(1, 0)
  },
  entityDetails: {
    '& > *': {
      width: '50%',
      margin: theme.spacing(1, 0)
    }
  },
  suggestionsText: {
    marginTop: theme.spacing(2)
  },
  descriptionText: {
    fontWeight: 700
  }
}));
