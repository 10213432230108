// @flow
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { resetFilters, saveFilters, toggleMenu } from 'actions/dashboard';
import useEffectOnlyOnce from 'hooks/effectOnlyOnce';
import pick from 'lodash/pick';
import moment from 'moment';
import queryString from 'query-string';
import { EntityRoutes } from 'routes/constants';
import { filtersToParams } from 'selectors/app';
import { getCurrentUser } from 'services/Authentication';
import type { DatePeriod, Filters } from 'types/app';
import {
  FPCard as Card,
  FPCardContent as CardContent,
  FPCardHeader as CardHeader
} from 'UI/components/atoms/FPCard';
import ColumnChart from 'UI/components/molecules/Dashboard/ColumnChart';
import SummaryTable from 'UI/components/molecules/Dashboard/SummaryTable';
import SummaryTotals from 'UI/components/molecules/Dashboard/SummaryTotals';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { backNavigateListConfig } from 'UI/constants/config';
import { DateFormats, PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { getCurrentPeriod } from 'UI/utils';

import { determineScope, getDefaultFiltersForUser, TeamFilters } from '../helpers';

const statusChartColumns = ['Status', 'Total', { role: 'style' }];
const includeFilters = [...TeamFilters, 'industry', 'specialty', 'state'];

type DashboardOverviewProps = {
  filters: Filters,
  isSideMenuOpen: boolean,
  onFiltersReset: () => any,
  onFiltersChange: filters => any,
  onMenuToggle: () => any
};

const DashboardOverview = ({
  filters,
  isSideMenuOpen,
  onFiltersReset,
  onFiltersChange,
  onMenuToggle
}: DashboardOverviewProps) => {
  const userRef = useRef(getCurrentUser());
  const areFiltersReadyRef = useRef(false);

  const defaultPeriod = getCurrentPeriod('week');
  const [selectedPeriod, setSelectedPeriod] = useState<DashboardPeriod>(defaultPeriod);

  useEffect(() => {
    document.title = PageTitles.Dashboard;
  }, []);

  useEffectOnlyOnce(() => {
    const defaultFiltersForUser = getDefaultFiltersForUser(userRef.current, filters);
    onFiltersChange({ ...defaultFiltersForUser, ...filters });
    areFiltersReadyRef.current = true;
  }, [onFiltersChange, filters]);

  const handlePeriodChange = (period: DatePeriod) => setSelectedPeriod(period);

  const filtersParams = filtersToParams(pick(filters, includeFilters));

  const queryParams = queryString.stringify({
    startDate: moment(selectedPeriod.startDate).format(DateFormats.QueryFormat),
    endDate: moment(selectedPeriod.endDate).format(DateFormats.QueryFormat),
    ...filtersParams
  });

  const scope = determineScope(filters);

  return (
    areFiltersReadyRef.current && (
      <FiltersLayout
        title="Inventory"
        section="dashboard"
        isSideMenuOpen={isSideMenuOpen}
        onPeriodChange={handlePeriodChange}
        onFiltersReset={onFiltersReset}
        onFiltersChange={onFiltersChange}
        onMenuToggle={onMenuToggle}
        includeFilters={includeFilters}
        titleLabelProps={backNavigateListConfig}
        defaultRange={selectedPeriod}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Inventory" subheader={scope.option1} />
              <CardContent>
                <SummaryTotals url={`${Endpoints.Dashboard}/totalInventory?${queryParams}`} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Candidates by Type" subheader={scope.option1} />
              <CardContent>
                <ColumnChart
                  url={`${Endpoints.Dashboard}/totalTypesOrStatus?identifier=types&entityType=${EntityType.Candidate}&${queryParams}`}
                  columns={statusChartColumns}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Candidates by Status" subheader={scope.option1} />
              <CardContent>
                <ColumnChart
                  url={`${Endpoints.Dashboard}/totalTypesOrStatus?identifier=status&entityType=${EntityType.Candidate}&${queryParams}`}
                  columns={statusChartColumns}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Job Orders by Type" subheader={scope.option1} />
              <CardContent>
                <ColumnChart
                  url={`${Endpoints.Dashboard}/totalTypesOrStatus?identifier=types&entityType=${EntityType.Joborder}&${queryParams}`}
                  columns={statusChartColumns}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title="Job Orders by Status" subheader={scope.option1} />
              <CardContent>
                <ColumnChart
                  url={`${Endpoints.Dashboard}/totalTypesOrStatus?identifier=status&entityType=${EntityType.Joborder}&${queryParams}`}
                  columns={statusChartColumns}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Inventory" subheader={scope.option2} />
              <CardContent variant="tight">
                <SummaryTable
                  url={`${Endpoints.Dashboard}/inventoryList?${queryParams}`}
                  downloadFileName={`Inventory-${scope.option2}`}
                  section="dashboard"
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Candidates by Type" subheader={scope.option2} />
              <CardContent style={{ padding: 0 }}>
                <SummaryTable
                  url={`${Endpoints.Dashboard}/activityListTypeOrStatus?identifier=types&${queryParams}&entityType=candidate`}
                  downloadFileName={`Candidates-${scope.option2}`}
                  section="dashboard"
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Candidates by Status" subheader={scope.option2} />
              <CardContent style={{ padding: 0 }}>
                <SummaryTable
                  url={`${Endpoints.Dashboard}/activityListTypeOrStatus?identifier=status&${queryParams}&entityType=candidate`}
                  downloadFileName={`Candidates-${scope.option2}`}
                  section="dashboard"
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Job Orders by Type" subheader={scope.option2} />
              <CardContent variant="tight">
                <SummaryTable
                  url={`${Endpoints.Dashboard}/activityListTypeOrStatus?identifier=types&${queryParams}&entityType=joborder`}
                  downloadFileName={`JobOrders-${scope.option2}`}
                  section="dashboard"
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Job Orders by Status" subheader={scope.option2} />
              <CardContent variant="tight">
                <SummaryTable
                  url={`${Endpoints.Dashboard}/activityListTypeOrStatus?identifier=status&${queryParams}&entityType=joborder`}
                  downloadFileName={`JobOrders-${scope.option2}`}
                  section="dashboard"
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Activities" subheader={scope.option2} />
              <CardContent>
                <p>
                  This section has been deprecated. If you have any comment please send it to{' '}
                  <a href="mailto:dev@gogpac.com">dev@gogpac.com </a>.
                </p>
                <p>You can still use other Dashboard sections like:</p>
                <ul>
                  <li>
                    <Link to={EntityRoutes.DashboardPhone}>Calls and Texts</Link>
                  </li>
                  <li>
                    <Link to={EntityRoutes.DashboardFeeAgreements}>Bulk Email</Link>
                  </li>
                  <li>
                    <Link to={EntityRoutes.DashboardSendout}>Sendouts</Link>
                  </li>
                  <li>
                    <Link to={EntityRoutes.DashboardFeeAgreements}>Fee Agreements</Link>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </FiltersLayout>
    )
  );
};

const mapStateToProps = ({ dashboard }) => {
  return {
    filters: dashboard.domain.filters,
    isSideMenuOpen: dashboard.ui.isSideMenuOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFiltersReset: () => dispatch(resetFilters()),
    onFiltersChange: filters => dispatch(saveFilters(filters)),
    onMenuToggle: () => dispatch(toggleMenu())
  };
};

const DashboardOverviewConnected = connect(mapStateToProps, mapDispatchToProps)(DashboardOverview);

export default DashboardOverviewConnected;
