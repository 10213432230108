// @flow
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { resetFilters, saveFilters, toggleMenu } from 'actions/dashboard';
import useEffectOnlyOnce from 'hooks/effectOnlyOnce';
import pick from 'lodash/pick';
import moment from 'moment';
import queryString from 'query-string';
import { filtersToParams } from 'selectors/app';
import { getCurrentUser } from 'services/Authentication';
import type { DatePeriod, Filters } from 'types/app';
import {
  FPCard as Card,
  FPCardContent as CardContent,
  FPCardHeader as CardHeader
} from 'UI/components/atoms/FPCard';
import LineChart from 'UI/components/molecules/Dashboard/LineChart';
import SummaryTable from 'UI/components/molecules/Dashboard/SummaryTable';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import TabsView from 'UI/components/templates/TabsView';
import { backNavigateListConfig } from 'UI/constants/config';
import { DateFormats, PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import {
  AllActivitiesIcon,
  CandidatesIcon,
  CompanyIcon,
  JobOrdersIcon,
  NoActivityIcon,
  NoInventoryIcon
} from 'UI/res/icons/custom';
import { getCurrentPeriod } from 'UI/utils';

import { determineScope, getDefaultFiltersForUser, TeamFilters } from '../helpers';

type DashboardActivityProps = {
  filters: Filters,
  isSideMenuOpen: boolean,
  onFiltersReset: () => any,
  onFiltersChange: filters => any,
  onMenuToggle: () => any
};

const DashboardActivity = (props: DashboardActivityProps) => {
  const { filters, isSideMenuOpen, onFiltersReset, onFiltersChange, onMenuToggle } = props;
  const userRef = useRef(getCurrentUser());
  const areFiltersReadyRef = useRef(false);

  const defaultPeriod = getCurrentPeriod('week');
  const [selectedPeriod, setSelectedPeriod] = useState<DashboardPeriod>(defaultPeriod);

  useEffect(() => {
    document.title = PageTitles.Dashboard;
  }, []);

  useEffectOnlyOnce(() => {
    const defaultFiltersForUser = getDefaultFiltersForUser(userRef.current, filters);
    onFiltersChange({ ...defaultFiltersForUser, ...filters });
    areFiltersReadyRef.current = true;
  }, [onFiltersChange, filters]);

  const handlePeriodChange = (period: DatePeriod) => setSelectedPeriod(period);

  const filtersParams = filtersToParams(pick(filters, TeamFilters));

  const queryParams = queryString.stringify({
    startDate: moment(selectedPeriod.startDate).format(DateFormats.QueryFormat),
    endDate: moment(selectedPeriod.endDate).format(DateFormats.QueryFormat),
    ...filtersParams
  });

  const scope = determineScope(filters);

  const tabsProp = [
    {
      label: 'Totals',
      icon: <AllActivitiesIcon />,
      view: (
        <SummaryTable
          url={`${Endpoints.Dashboard}/activityList?${queryParams}`}
          downloadFileName={`Activities-${scope.option2}`}
          section="dashboard"
        />
      )
    },
    {
      label: 'Candidates',
      icon: <CandidatesIcon />,
      view: (
        <SummaryTable
          url={`${Endpoints.Dashboard}/activityList?${queryParams}&entityType=candidate`}
          downloadFileName={`Activities-Candidates-${scope.option2}`}
          section="dashboard"
        />
      )
    },
    {
      label: 'Job Orders',
      icon: <JobOrdersIcon />,
      view: (
        <SummaryTable
          url={`${Endpoints.Dashboard}/activityList?${queryParams}&entityType=joborder`}
          downloadFileName={`Activities-JobOrders-${scope.option2}`}
          section="dashboard"
        />
      )
    },
    {
      label: 'Companies',
      icon: <CompanyIcon />,
      view: (
        <SummaryTable
          url={`${Endpoints.Dashboard}/activityList?${queryParams}&entityType=company`}
          downloadFileName={`Activities-Companies-${scope.option2}`}
          section="dashboard"
        />
      )
    }
  ];

  const noActivityTabsProp = [
    {
      label: 'Without new Inventory',
      icon: <NoActivityIcon />,
      view: (
        <SummaryTable
          url={`${Endpoints.Dashboard}/inventoryList?${queryParams}&withoutItems=true`}
          downloadFileName={`No-Inventory-${scope.option2}`}
          section="dashboard"
        />
      )
    },
    {
      label: 'Without new Activity',
      icon: <NoInventoryIcon />,
      view: (
        <SummaryTable
          url={`${Endpoints.Dashboard}/activityList?${queryParams}&withoutItems=true`}
          downloadFileName={`No-Activity-${scope.option2}`}
          section="dashboard"
        />
      )
    }
  ];

  return (
    areFiltersReadyRef.current && (
      <FiltersLayout
        title="Activity"
        section="dashboard"
        isSideMenuOpen={isSideMenuOpen}
        onPeriodChange={handlePeriodChange}
        onFiltersReset={onFiltersReset}
        onFiltersChange={onFiltersChange}
        onMenuToggle={onMenuToggle}
        includeFilters={TeamFilters}
        titleLabelProps={backNavigateListConfig}
        defaultRange={selectedPeriod}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Activities" subheader={scope.option2} />
              <CardContent variant="tight">
                <TabsView tabs={tabsProp} panelHeight="unset" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="No activity" subheader={scope.option2} />
              <CardContent variant="tight">
                <TabsView tabs={noActivityTabsProp} panelHeight="unset" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Activity Trend" subheader={scope.option2} />
              <CardContent>
                <LineChart
                  url={`${Endpoints.Dashboard}/activityNoteInTime?${queryParams}`}
                  columns={[]}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </FiltersLayout>
    )
  );
};

const mapStateToProps = ({ dashboard }) => {
  return {
    filters: dashboard.domain.filters,
    isSideMenuOpen: dashboard.ui.isSideMenuOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFiltersReset: () => dispatch(resetFilters()),
    onFiltersChange: filters => dispatch(saveFilters(filters)),
    onMenuToggle: () => dispatch(toggleMenu())
  };
};

const DashboardActivityConnected = connect(mapStateToProps, mapDispatchToProps)(DashboardActivity);

export default DashboardActivityConnected;
