import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  recruiterInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline'
  },
  specialtiesContainer: {
    marginTop: theme.spacing(1.5)
  },
  specialtiesSubcontainer: {
    marginTop: theme.spacing(1)
  },
  titleName: {
    marginLeft: theme.spacing(1.5),
    fontSize: 18,
    fontWeight: '700 !important'
  },
  coach: {
    fontSize: 14,
    fontWeight: 400
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    fontSize: 14
  },
  copyButton: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    display: 'inline',
    verticalAlign: 'middle'
  },
  emailContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  emailText: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  }
}));
