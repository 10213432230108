// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { getCurrentUser } from 'services/Authentication';
import { getHighestUserRoleInHierarchy } from 'services/Authorization';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { When } from 'UI/components/atoms/When';
import FPFormStatus from 'UI/components/molecules/FPFormStatus';
import {
  ExpectedCompletedReferences,
  FileKeyPrefix,
  MinimumRequiredCompletedReferences,
  PlacementRoleHierarchy,
  ReferenceCheckFileId
} from 'UI/components/organisms/placements/utils';
import { DateFormats } from 'UI/constants/defaults';
import { Roles } from 'UI/constants/roles';
import { PlacementStatus } from 'UI/constants/status';
import { SvgViewFilled } from 'UI/res';
import { toLocalTime } from 'UI/utils';

import { FormFieldsMap } from '../fields';

import { useStyles } from './styles';

type ReferenceCheckListProps = {
  references: Object[],
  onViewClick: () => void,
  isReadOnly: boolean,
  preSelectedReferences: Array,
  placementStatusId: number
};

const PlacementReferenceChecksList = ({
  references,
  preSelectedReferences,
  onViewClick,
  isReadOnly = false,
  placementStatusId
}: ReferenceCheckListProps) => {
  const classes = useStyles();
  const currentUser = getCurrentUser();
  const { register, unregister, setValue, watch, getValues, errors } = useFormContext();

  const highestRole = getHighestUserRoleInHierarchy(currentUser, PlacementRoleHierarchy);
  const userHasALeadRole = [Roles.RegionalDirector, Roles.Coach, Roles.Leadership].includes(
    highestRole?.id
  );

  const selectedReferences = watch(FormFieldsMap.References.key) || [];
  const referenceFiles = getValues(`${FileKeyPrefix}${ReferenceCheckFileId}`) || [];
  const totalReferences = selectedReferences.length + referenceFiles.length;

  const shouldPlacementBeApprovedByLeads =
    placementStatusId === PlacementStatus.PendingRegionalValidation ||
    placementStatusId === PlacementStatus.PendingValidation;
  const shouldShowRecommendationForLeads =
    shouldPlacementBeApprovedByLeads &&
    userHasALeadRole &&
    totalReferences === MinimumRequiredCompletedReferences;

  useEffect(() => {
    register({ name: FormFieldsMap.References.key });
    register(
      { name: FormFieldsMap.AreReferencesValid.key },
      {
        validate() {
          const referencesIds = getValues(FormFieldsMap.References.key) || [];
          const referencesFiles = getValues(`${FileKeyPrefix}${ReferenceCheckFileId}`) || [];
          const areReferencesComplete =
            referencesIds.length + referencesFiles.length >= MinimumRequiredCompletedReferences;

          return (
            areReferencesComplete || strings.placements.sections.referenceChecks.requiredValidation
          );
        }
      }
    );

    return () => {
      unregister(FormFieldsMap.References.key);
      unregister(FormFieldsMap.AreReferencesValid.key);
    };
  }, [register, unregister, getValues]);

  useEffect(() => {
    setValue(FormFieldsMap.References.key, preSelectedReferences || []);
  }, [setValue, preSelectedReferences]);

  const handleCheckToggle = (event: any) => {
    const { checked, value } = event.target;

    const newSelectedReferences = checked
      ? [...selectedReferences, value]
      : selectedReferences.filter(ref => ref !== value);

    setValue(FormFieldsMap.References.key, newSelectedReferences);
  };

  return (
    <>
      {references && references.length > 0 ? (
        <>
          <When condition={!isReadOnly}>
            <Alert severity="warning">
              {shouldShowRecommendationForLeads
                ? strings.formatString(
                    strings.placements.sections.referenceChecks.recommendationForLeads,
                    {
                      total: ExpectedCompletedReferences
                    }
                  )
                : strings.formatString(strings.placements.sections.referenceChecks.recommendation, {
                    total: ExpectedCompletedReferences
                  })}
            </Alert>
          </When>
          <List dense>
            {references.map(reference => (
              <ListItem key={reference.id} disableGutters dense>
                <ListItemIcon>
                  <Checkbox
                    checked={selectedReferences.includes(reference.id)}
                    color="primary"
                    edge="start"
                    inputProps={{
                      'aria-label': strings.formatString(
                        strings.placements.sections.referenceChecks.label,
                        { name: reference.name || '' }
                      )
                    }}
                    onChange={handleCheckToggle}
                    tabIndex={-1}
                    value={reference.id}
                    disabled={isReadOnly}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      <Typography component="span" className={classes.referenceName}>
                        {reference.name}
                      </Typography>
                      <FPFormStatus
                        status={reference.progress.key}
                        totalQuestions={reference.progress.total}
                        totalAnsweredQuestions={reference.progress.current}
                        shouldShowTotal
                      />
                    </>
                  }
                  secondary={`
                    ${toLocalTime(reference.updated).format(DateFormats.SimpleDateTime)}
                    /
                    ${reference.trace?.user?.fullName}
                    ${reference.trace?.user?.initials ? `(${reference.trace?.user?.initials})` : ''}
                  `}
                />
                <ListItemSecondaryAction className={classes.moreButton}>
                  <FPIconButton
                    icon={SvgViewFilled}
                    onClick={() => onViewClick(reference)}
                    tooltipProps={{ title: `View ${reference.name || 'info'}` }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </>
      ) : (
        <Alert severity="warning">
          <p>
            {strings.formatString(strings.placements.sections.referenceChecks.recommendation, {
              total: ExpectedCompletedReferences
            })}
          </p>
        </Alert>
      )}
      {!!errors[FormFieldsMap.AreReferencesValid.key] && (
        <FormControl component="fieldset" error>
          <FormHelperText>{errors[FormFieldsMap.AreReferencesValid.key].message}</FormHelperText>
        </FormControl>
      )}
    </>
  );
};

export default PlacementReferenceChecksList;
