import React from 'react';
import Typography from '@material-ui/core/Typography';

import { CountryName, StatesNames } from './PILMap.styled';

const PILMapCountry = ({ country }) => {
  return (
    <Typography key={country.name}>
      <CountryName>{country?.name?.toUpperCase() || ''}</CountryName>:&nbsp;
      <StatesNames>{Array.isArray(country?.states) ? country.states.join(', ') : ''}</StatesNames>
    </Typography>
  );
};

export default PILMapCountry;
