import * as React from "react";

const SvgViewFilled = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <g>
        <path d="M23.43 10.52c-2.64-2.91-7-6-11.43-5.92S3.21 7.61.57 10.52a2.22 2.22 0 000 3C2.33 15.42 6.74 19.4 12 19.4s9.66-4 11.43-5.93a2.22 2.22 0 000-2.95zM7.4 12a4.6 4.6 0 114.6 4.6A4.6 4.6 0 017.4 12z" />
        <circle cx={12} cy={12} r={2} />
      </g>
    </svg>
  );
};

export default SvgViewFilled;
