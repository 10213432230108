// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FPCardHeader } from 'UI/components/atoms/FPCard';
import FPIcon from 'UI/components/atoms/FPIcon';

import { useStyles } from './styles';

type TotalSummaryCardProps = {
  Icon: any,
  title: string,
  value: any
};

const TotalSummaryCard = ({ Icon, title, value }: TotalSummaryCardProps) => {
  const classes = useStyles();

  return (
    <FPCardHeader
      actionMode="noAction"
      avatar={
        Icon && (
          <div className={classes.iconContainer}>
            <FPIcon role="img" aria-label="icon" component={Icon} size={28} color="white" />
          </div>
        )
      }
      classes={{ root: classes.root, content: Icon && classes.cardContent }}
      subheader={
        <Typography className={Icon ? classes.iconTitle : classes.defaultTitle}>{title}</Typography>
      }
      title={<Typography className={classes.value}>{value}</Typography>}
    />
  );
};

TotalSummaryCard.defaultProps = {
  Icon: null,
  title: 'Title',
  value: 0
};

export default TotalSummaryCard;
