// @flow

import {
  ADD_FILTER_PHONE,
  RESET_FILTERS_PHONE,
  SAVE_FILTERS_PHONE,
  TOGGLE_MENU_PHONE
} from 'actions/dashboardPhone';
import { combineReducers } from 'redux';
import type { DashboardDomainState, DashboardUiState } from 'types/dashboard';
import type { Action } from 'types/redux';

const uiReducer = (
  state: DashboardUiState = {},
  action: Action = { type: '', payload: {} }
): DashboardUiState => {
  const { type } = action;
  switch (type) {
    case TOGGLE_MENU_PHONE:
      return { ...state, isSideMenuOpen: !state.isSideMenuOpen };
    case SAVE_FILTERS_PHONE:
      return { ...state, isSideMenuOpen: false };
    default:
      return state;
  }
};

const domainReducer = (
  state: DashboardDomainState = {},
  action: Action = { type: '', payload: {} }
): DashboardDomainState => {
  const { type, payload } = action;
  switch (type) {
    case RESET_FILTERS_PHONE:
      return { ...state, filters: {} };
    case SAVE_FILTERS_PHONE:
      return { ...state, filters: payload };
    case ADD_FILTER_PHONE:
      return { ...state, filters: { ...state.filters, ...payload } };
    default:
      return state;
  }
};

const dashboardPhoneReducer = combineReducers({ domain: domainReducer, ui: uiReducer });

/** Reducer that combines domain and app reducer */
export default dashboardPhoneReducer;
