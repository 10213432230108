// @flow

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { confirm, showAlert } from 'actions/app';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import { deletePayment, updatePayment } from 'services/Placements';
import TextBox from 'UI/components/atoms/TextBox';
import HistoryItem from 'UI/components/molecules/HistoryItem';
import InlineEditField from 'UI/components/molecules/InlineEditField';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { TypeHistoryItem } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { UIStatus } from 'UI/constants/status';
import { EmptyPlacements } from 'UI/res';
import { currencyFormatter, REQUIRED_VALIDATION } from 'UI/utils';

export type PlacementPaymentsFormProps = {
  id: number,
  readOnly: boolean,
  onPaymentsChanged: (remainOpen: boolean) => any
};

const PlacementPaymentsLegacy = ({
  id,
  readOnly,
  onPaymentsChanged
}: PlacementPaymentsFormProps) => {
  const { register, unregister, setValue, watch, errors } = useFormContext();
  const endpoint = `${Endpoints.PlacementPayments.replace(':id', id)}`;
  const dispatch = useDispatch();
  const { Status, setState } = useFetchWithStatus(endpoint);

  useEffect(() => {
    register({ name: 'amount' }, REQUIRED_VALIDATION);
    return () => unregister('amount');
  }, [register, unregister]);

  const handleInputChange = (name?: string, value: any) => {
    setValue(name, value, true);
  };

  const removePaymentLocally = (paymentId: number) =>
    setState(prevState => ({
      ...prevState,
      results: prevState.results.filter(each => each.id !== paymentId)
    }));

  const updatePaymentLocally = (paymentId: number, newProps: any) =>
    setState(prevState => ({
      ...prevState,
      results: prevState.results.map(each =>
        each.id !== paymentId ? each : { ...each, ...newProps }
      )
    }));

  const handleDeleteClick = item => () => {
    dispatch(
      confirm({
        severity: 'warning',
        title: 'Please confirm',
        confirmButtonText: 'Delete Payment',
        message: 'Are you sure you want to delete this payment?',
        onConfirm: async ok => {
          if (!ok) return;

          updatePaymentLocally(item.id, { status: UIStatus.Loading });
          const result = await deletePayment(id, item.id);
          if (result.success) {
            removePaymentLocally(item.id);
            onPaymentsChanged(true);
          }
          result.alert && result.alert.body && dispatch(showAlert(result.alert));
        }
      })
    );
  };

  const update = async (itemId, data) => {
    const result = await updatePayment(id, itemId, data);
    result.alert && result.alert.body && dispatch(showAlert(result.alert));
    return result;
  };

  const handleEditClick = item => () => updatePaymentLocally(item.id, { status: UIStatus.Editing });

  const handleEditionCanceled = itemId =>
    updatePaymentLocally(itemId, { status: UIStatus.Default });

  const handleEditionCompleted = (itemId, value) => {
    updatePaymentLocally(itemId, { status: UIStatus.Default, amount: value });
    onPaymentsChanged(true);
  };

  const getMenuOptions = innerId => [
    {
      title: 'Edit',
      action: handleEditClick({ id: innerId }),
      visible: !readOnly
    },
    {
      title: 'Delete',
      action: handleDeleteClick({ id: innerId }),
      visible: !readOnly
    }
  ];

  const watchAmount = watch('amount', '');

  return (
    <>
      {!readOnly && (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Register a new payment
          </Typography>
          <Box mb={2}>
            <TextBox
              name="amount"
              label="Payment amount *"
              value={watchAmount}
              onChange={handleInputChange}
              inputType="currency"
              error={!!errors.amount}
              errorText={errors?.amount?.message}
            />
          </Box>
        </>
      )}

      <Typography variant="subtitle1" gutterBottom>
        Payment history:
      </Typography>
      <Status
        loading={
          <List dense>
            <HistoryItem isLoading />
          </List>
        }
        empty={
          <Typography paragraph gutterBottom>
            No payments yet
          </Typography>
        }
        error={error => (
          <EmptyPlaceholder title="Oops" subtitle={error} customEmptyState={<EmptyPlacements />} />
        )}
        success={results => (
          <List dense>
            {results.map(
              ({
                id: innerId,
                user,
                amount,
                created_at: createdAt,
                payment_date: paymentDate,
                status
              }) =>
                status !== UIStatus.Editing ? (
                  <HistoryItem
                    key={innerId}
                    date={paymentDate || createdAt}
                    type={TypeHistoryItem.Custom}
                    action={
                      <>
                        <b>{user?.personalInformation?.full_name || user?.email}</b> registered a{' '}
                        <b>{currencyFormatter(amount)}USD</b> payment
                      </>
                    }
                    menuOptions={getMenuOptions(innerId)}
                    isLoading={status === UIStatus.Loading}
                  />
                ) : (
                  <InlineEditField
                    itemId={innerId}
                    name="amount"
                    label="Edit amount"
                    defaultValue={amount}
                    onCancel={handleEditionCanceled}
                    onUpdate={handleEditionCompleted}
                    updateAction={update}
                  />
                )
            )}
          </List>
        )}
      />
    </>
  );
};

export default PlacementPaymentsLegacy;
