const validateReferenceCheck = (formData, errors) => {
  const { startDate, endDate } = formData.section2.employmentDate;

  if (!startDate && !endDate) {
    return errors;
  }

  if (!startDate) {
    errors.section2.employmentDate.startDate.addError('Start date is required');
    return errors;
  }

  if (!endDate) {
    errors.section2.employmentDate.endDate.addError('End date is required');
    return errors;
  }

  const start = new Date(startDate);
  const end = new Date(endDate);
  if (start > end) {
    errors.section2.employmentDate.endDate.addError('End date must be after start date');
    return errors;
  }

  return errors;
};

export default validateReferenceCheck;
