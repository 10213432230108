// @flow

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { When } from 'UI/components/atoms/When';
import { OptionsVariantsKeys } from 'UI/components/organisms/InterviewDetailsDrawer/InterviewDetailsDrawer.constants';

import { OptionButton } from './components/OptionButton';
import { useStyles } from './InterviewDetails.styles';

type Options = {
  id: string,
  title: string,
  onClick: () => void,
  isVisible: boolean
};

type OptionsVariant = 'interviewDetails' | 'followUpActions';

interface InterviewDetailsOptionsProps {
  variant: OptionsVariant;
  options: Options[];
}

const InterviewDetailsOptions = ({
  variant = OptionsVariantsKeys.InterviewDetails,
  options
}: InterviewDetailsOptionsProps) => {
  const classes = useStyles();
  const isInterviewDetails = variant === OptionsVariantsKeys.InterviewDetails;
  const justifyGrid = isInterviewDetails ? 'center' : 'space-between';

  return (
    <>
      <When condition={!isInterviewDetails}>
        <Typography component="h2" className={classes.followUpTitle}>
          Follow up actions
        </Typography>
      </When>
      <Grid container justify={justifyGrid}>
        {options.map(option => (
          <When key={option.id} condition={option.isVisible}>
            <Grid item>
              <OptionButton option={option} variant={variant} />
            </Grid>
          </When>
        ))}
      </Grid>
    </>
  );
};

export default InterviewDetailsOptions;
