import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenter, flexAlignCenterJustifyCenter } from 'UI/utils/styles';

const ICON_WIDTH = 24;
const LOADER_WIDTH = 30;
const CONTAINER_MIN_WIDTH = 350;
const getIconMargin = theme => theme.spacing(2);

export const useStyles = makeStyles(theme => ({
  actionButton: {
    width: '100% !important',
    border: 'none !important',
    borderRadius: 0
  },
  buttonsContainer: {
    height: 40,
    borderBottom: `2px solid ${theme.palette.grey[400]}`
  },
  treeViewContainer: {
    width: ({ containerWidth }) => containerWidth || CONTAINER_MIN_WIDTH,
    borderRight: `1px solid ${theme.palette.grey[400]}`
  },
  treeViewLoader: {
    height: 'calc(100% - 40px)',
    overflowY: 'scroll'
  }
}));

export const useTreeViewLoaderStyles = makeStyles(theme => ({
  treeView: {
    width: '100%',
    padding: theme.spacing(0, 0.5)
  },
  initialLoader: {
    ...flexAlignCenterJustifyCenter,
    alignSelf: 'center',
    marginTop: theme.spacing(5),
    width: '100%',
    height: '100%'
  }
}));

export const useTreeRendererStyles = makeStyles(theme => ({
  labelContainer: {
    ...flexAlignCenter,
    padding: theme.spacing(0.25, 1),
    maxWidth: `calc(100% - ${getIconMargin(theme)}px)`
  },
  labelIcon: {
    minWidth: ICON_WIDTH,
    marginRight: getIconMargin(theme)
  },
  labelText: {
    width: `calc(100% - ${ICON_WIDTH + getIconMargin(theme) + LOADER_WIDTH}px)`,
    wordWrap: 'break-word !important',
    overflowWrap: 'break-word !important'
  },
  treeItem: {
    '&:focus > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent'
    }
  },
  treeItemContent: {
    padding: theme.spacing(0.25, 0),
    '& .MuiSvgIcon-root': {
      height: '32px'
    }
  },
  treeItemLabel: {
    color: 'inherit',
    padding: 0
  }
}));
