// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Refresh } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import TitleLabel from 'UI/components/atoms/TitleLabel';
import { When } from 'UI/components/atoms/When';

import { ActionsContainer, HeaderContainer, RefreshButton, Title } from './Header.styled';
import { useStyles } from './styles';

interface HeaderProps {
  path: React.ReactNode;
  title: string | React.ReactNode;
  children?: React.ReactNode;
  subtitle?: React.ReactNode;
  extraActions?: React.ReactNode;
  isBackNavigationEnabled?: boolean;
  isLoading?: boolean;
  onRefresh?: () => void;
}

export const Header = ({
  path,
  title,
  subtitle,
  extraActions = [],
  isBackNavigationEnabled,
  isLoading = false,
  onRefresh
}: HeaderProps) => {
  const classes = useStyles({ isBackNavigationEnabled });
  return (
    <HeaderContainer>
      {isLoading ? (
        <Grid container direction="column">
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton variant="text" width={400} height={80} />
        </Grid>
      ) : (
        <>
          <Grid container item direction="column">
            <div className={classes.additionalLeftPadding}>
              <Title color="textSecondary" variant="body1">
                {path}
              </Title>
            </div>
            {typeof title === 'string' ? (
              <TitleLabel
                text={title}
                textTransform="uppercase"
                backNavigation={isBackNavigationEnabled}
              />
            ) : (
              title
            )}
            <div className={classes.additionalLeftPadding}>
              <When condition={subtitle}>{subtitle}</When>
            </div>
          </Grid>
          <ActionsContainer container item>
            <When condition={!!onRefresh}>
              <Grid item>
                <RefreshButton
                  type="button"
                  startIcon={<Refresh width={20} height={20} />}
                  onClick={onRefresh}
                >
                  Refresh Stats
                </RefreshButton>
              </Grid>
            </When>
            <When condition={extraActions}>{extraActions}</When>
          </ActionsContainer>
        </>
      )}
    </HeaderContainer>
  );
};
