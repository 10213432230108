// @flow
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {
  setBulkEmailInPreview,
  setBulkEmailStatus,
  setBulkStatsOpen,
  startDeletingBulkDraft,
  startDeletingScheduledBulkEmail,
  startReschedulingBulkEmail,
  startSendingNowScheduledBulkEmail,
  startSettingBulkPreview,
  startSettingSelectedTemplateData
} from 'actions/bulkEmail';
import { useBulkTemplates } from 'hooks/BulkEmails/templates';
import { useBulkQuotaRestriction } from 'hooks/bulkQuotaRestriction';
import { UserProviderContext } from 'providers/UserProvider';
import strings from 'strings';
import type { BulkEmailContentFilters, EmailItemsListPropTypes } from 'types/app';
import { When } from 'UI/components/atoms/When';
import BulkEmailContentHeader from 'UI/components/molecules/BulkEmailContentHeader';
import PreviewBulkEmail from 'UI/components/molecules/PreviewBulkEmail';
import BulkEmailContentBody from 'UI/components/organisms/BulkEmail/BulkEmailContentBody';
import BulkEmailsList from 'UI/components/organisms/BulkEmail/EmailsList';
import ScheduleDialog from 'UI/components/organisms/BulkEmail/ScheduleDialog';
import DecisionDialog from 'UI/components/organisms/DecisionDialog';
import DetailedConfirmationDialog from 'UI/components/organisms/DetailedConfirmationDialog';
import {
  BulkMenuItems,
  MY_TEMPLATES_FOLDER_ID,
  TEMPLATE_ENTITY_TYPES
} from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag } from 'UI/utils';

import BulkTemplatesDrawer from '../BulkEmailTemplates/BulkTemplatesDrawer';
import TemplateFolderDrawer from '../BulkEmailTemplates/TemplateFolderDrawer';
import TemplatePreview from '../BulkEmailTemplates/TemplatePreview';
import TemplateRestoreForm from '../BulkEmailTemplates/TemplateRestoreForm';
import TemplateTreeViewList from '../BulkEmailTemplates/TemplateTreeViewList';
import OptInOptOutList from '../OptInOptOutList';
import RestrictionDialog from '../RestrictionDialog/RestrictionDialog';

import { useStyles } from './styles';

const {
  content: {
    drafts: draftsCopies,
    schedule: scheduleCopies,
    sent: sentCopies,
    template: templateCopies
  },
  dialogs: dialogsCopies
} = strings.bulkEmails;

const BULK_DIALOGS = {
  deleteDraft: 'isDeleteDraftDialogOpen',
  deleteSchedule: 'isDeleteScheduleDialogOpen',
  reschedule: 'isRescheduleDialogOpen',
  sendNow: 'isSendNowDialogOpen'
};

const ARCHIVE_DIALOG_TYPE_ID = 3;

type BulkEmailContentProps = {
  BulkEmailsListProps: EmailItemsListPropTypes,
  filters: BulkEmailContentFilters,
  isResponsive: boolean,
  onAddEmailToOptOut: () => any,
  onKeywordSearch: () => any,
  onOpenBulkForm: () => any
};

const BulkEmailContent = ({
  BulkEmailsListProps,
  isResponsive,
  onAddEmailToOptOut,
  onKeywordSearch,
  onOpenBulkForm
}: BulkEmailContentProps) => {
  const classes = useStyles();
  const [currentUser] = useContext(UserProviderContext);

  const {
    foldersIdToRefresh,
    getTemplatesActions,
    templateHandlers,
    templatesTreeItem,
    templatesUiState,
    templateTogglers,
    templateArchiveDialogProps,
    templateRestoreDialogProps,
    selectedTreeItemId
  } = useBulkTemplates();

  const isAllowedToCreate =
    templatesTreeItem.belongsToUser &&
    templatesTreeItem?.id !== MY_TEMPLATES_FOLDER_ID &&
    !templatesTreeItem?.isArchived &&
    templatesTreeItem?.folderTypeId !== ARCHIVE_DIALOG_TYPE_ID;

  const [uiState, setUiState] = useState({
    [BULK_DIALOGS.archiveTemplate]: false,
    [BULK_DIALOGS.deleteDraft]: false,
    [BULK_DIALOGS.deleteSchedule]: false,
    [BULK_DIALOGS.reschedule]: false,
    [BULK_DIALOGS.sendNow]: false
  });

  const form = useForm();
  const { bulkEmailInPreview } = useSelector(store => store.bulkEmail.domain);
  const {
    id: bulkEmailPreviewId,
    userInfo,
    searchProject: searchProjectInPreview
  } = bulkEmailInPreview.data || {};
  const {
    preview: { loading: loadingBulkPreview },
    selectedMenuItem,
    status: bulkEmailStatus
  } = useSelector(store => store.bulkEmail.ui);

  const dispatch = useDispatch();

  const shouldShowHeaderActions = currentUser.id === userInfo?.id;
  const shouldShowTemplatesActions =
    templatesTreeItem?.belongsToUser && !templatesTreeItem?.folderTypeId;
  const isBulkPreviewActive = bulkEmailPreviewId && bulkEmailInPreview?.data?.emailBody;
  const bulkRestrictionRule = bulkEmailStatus.bulkRestriction?.rule;
  const searchProjectSize = searchProjectInPreview?.totalItems;

  const { bulkQuotaRestriction, remainingQuota, notifyBulkQuotaRestriction } =
    useBulkQuotaRestriction({ searchProjectSize });

  // TODO: extract schedule dialogs to its own component
  const handleToggleBulkDialogs = useCallback(
    /**
     * @param {boolean} open
     * @param {string} key
     * @returns {Function}
     */
    (open, key) => () => {
      dispatch(setBulkEmailInPreview({ edit: open }));
      setUiState(prev => ({ ...prev, [key]: open }));
    },
    [dispatch]
  );

  const openSendNowConfirmDialog = () => {
    dispatch(setBulkEmailInPreview({ edit: true }));
    dispatch(setBulkRestrictionToInitial());
    setUiState(prev => ({ ...prev, isSendNowDialogOpen: true }));
  };

  useEffect(() => {
    if (bulkRestrictionRule) {
      setUiState(prev => ({
        ...prev,
        isRescheduleDialogOpen: false,
        isSendNowDialogOpen: false
      }));
    }
  }, [bulkRestrictionRule]);

  useEffect(() => {
    if (bulkEmailStatus.completed && !bulkEmailStatus.loading && !bulkEmailInPreview.edit) {
      dispatch(setBulkEmailStatus({ completed: false }));
      uiState.isSendNowDialogOpen && handleToggleBulkDialogs(false, BULK_DIALOGS.sendNow)();
      uiState.isRescheduleDialogOpen && handleToggleBulkDialogs(false, BULK_DIALOGS.reschedule)();
      uiState.isDeleteScheduleDialogOpen &&
        handleToggleBulkDialogs(false, BULK_DIALOGS.deleteSchedule)();
      uiState.isDeleteDraftDialogOpen && handleToggleBulkDialogs(false, BULK_DIALOGS.deleteDraft)();
    }
  }, [
    bulkEmailInPreview.edit,
    bulkEmailStatus.completed,
    bulkEmailStatus.loading,
    dispatch,
    handleToggleBulkDialogs,
    uiState
  ]);

  const handleStatsPreviewClick = () => dispatch(setBulkStatsOpen(true));
  const handleDeleteSchedule = canDeleteSchedule =>
    canDeleteSchedule && dispatch(startDeletingScheduledBulkEmail(bulkEmailPreviewId));
  const handleRescheduleBulk = formData =>
    dispatch(
      startReschedulingBulkEmail(bulkEmailPreviewId, {
        data: formData
      })
    );
  const handleSendNowSchedule = canSendScheduleNow =>
    canSendScheduleNow &&
    dispatch(
      startSendingNowScheduledBulkEmail(
        { bulk_id: bulkEmailPreviewId },
        { sendNowScheduleBulk: true }
      )
    );
  const handleDeleteBulkDraft = canDeleteBulkDraft =>
    canDeleteBulkDraft && dispatch(startDeletingBulkDraft(bulkEmailPreviewId));

  const handleKeywordSearch = search => onKeywordSearch && onKeywordSearch(search);

  const setBulkFormPreloadState = () => {
    const { emailConfig: config, emailBody: body } = bulkEmailInPreview.data;
    dispatch(
      startSettingSelectedTemplateData({
        id: config.template?.id,
        data: {
          name: config.template?.name,
          emailBody: {
            subject: body?.subject,
            html: body?.html,
            text: body?.text,
            attachments: body?.attachments
          }
        }
      })
    );
    dispatch(setBulkEmailInPreview({ edit: true }));
    onOpenBulkForm();
  };

  const handleSelectEmail = selectedMailId => dispatch(startSettingBulkPreview(selectedMailId));

  const emailsListsContentBodyProps = {
    loadingPreview: loadingBulkPreview,
    loadingList: BulkEmailsListProps.loading,
    isListWithContent: BulkEmailsListProps.emailsList.length > 0,
    isPreviewActive: isBulkPreviewActive,
    ListComponent: (
      <BulkEmailsList
        emailInPreviewId={bulkEmailPreviewId}
        onSelectEmail={handleSelectEmail}
        selectedMenuItem={selectedMenuItem}
        {...BulkEmailsListProps}
      />
    ),
    PreviewComponent: <PreviewBulkEmail />
  };

  const sendNowHandler = () => {
    if (bulkQuotaRestriction) {
      notifyBulkQuotaRestriction();
      return;
    }
    openSendNowConfirmDialog();
  };

  const containerProps = {
    [BulkMenuItems.Sent]: {
      ContentHeaderProps: {
        onChange: handleKeywordSearch,
        shouldShowActions: true,
        actionButtonProps: {
          text: sentCopies.actionButton,
          onClick: handleStatsPreviewClick
        },
        loading: !bulkEmailInPreview?.data?.createdAt,
        title: sentCopies.title
      },
      ContentBodyProps: {
        onNewItemClick: () => onOpenBulkForm(),
        ...emailsListsContentBodyProps
      }
    },
    [BulkMenuItems.Drafts]: {
      ContentHeaderProps: {
        onChange: handleKeywordSearch,
        shouldShowActions: shouldShowHeaderActions,
        menuItems: [
          {
            title: draftsCopies.menuItemsTitles.edit,
            action: setBulkFormPreloadState,
            visible: true
          },
          {
            title: draftsCopies.menuItemsTitles.delete,
            action: handleToggleBulkDialogs(true, BULK_DIALOGS.deleteDraft),
            visible: true
          }
        ],
        title: draftsCopies.title
      },
      ContentBodyProps: emailsListsContentBodyProps
    },
    [BulkMenuItems.Schedule]: {
      ContentHeaderProps: {
        onChange: handleKeywordSearch,
        shouldShowActions: shouldShowHeaderActions,
        menuItems: [
          {
            title: scheduleCopies.menuItemsTitles.edit,
            action: setBulkFormPreloadState,
            visible: true
          },
          {
            title: scheduleCopies.menuItemsTitles.sendNow,
            action: sendNowHandler,
            visible: true
          },
          {
            title: scheduleCopies.menuItemsTitles.reschedule,
            action: handleToggleBulkDialogs(true, BULK_DIALOGS.reschedule),
            visible: true
          },
          {
            title: scheduleCopies.menuItemsTitles.delete,
            action: handleToggleBulkDialogs(true, BULK_DIALOGS.deleteSchedule),
            visible: true
          }
        ],
        title: scheduleCopies.title
      },
      ContentBodyProps: emailsListsContentBodyProps
    },
    [BulkMenuItems.Template]: {
      ContentHeaderProps: {
        title:
          Object.keys(templatesTreeItem).length > 0
            ? templatesTreeItem?.name
            : templateCopies.title,
        searchbarPlaceHolder: templateCopies.searchBarPlaceholder,
        onSelect: templateHandlers.selectTreeItem,
        shouldShowActions: shouldShowTemplatesActions,
        ...getTemplatesActions()
      },
      ContentBodyProps: {
        loadingList: false,
        loadingPreview: templatesUiState.loadingPreview,
        isListWithContent: true,
        isPreviewActive:
          templatesTreeItem.id && templatesTreeItem.entity === TEMPLATE_ENTITY_TYPES.Template,
        ListComponent: (
          <TemplateTreeViewList
            TemplatesTreeViewProps={{
              onClickItem: templateHandlers.selectTreeItem,
              onFolderRefresh: templateHandlers.folderRefresh,
              foldersIdToRefresh,
              selectedTreeItemId
            }}
            isAllowedToCreate={isAllowedToCreate}
            onOpenFolderDrawer={templateTogglers.folderDrawer({ open: true, edit: false })}
            onOpenTemplateDrawer={templateTogglers.templateDrawer({ open: true, edit: false })}
          />
        ),
        PreviewComponent: <TemplatePreview template={templatesTreeItem} />
      }
    }
  };

  const content = {
    [BulkMenuItems.OptOut]: <OptInOptOutList onAddEmailToOptOut={onAddEmailToOptOut} />
  };

  const scheduleSendNowDetails = [
    {
      title: 'Search Project Name',
      detail: `${bulkEmailInPreview?.data?.searchProject?.name} (${bulkEmailInPreview?.data?.searchProject?.totalItems})`
    },
    { title: 'Bulk Type', detail: bulkEmailInPreview?.data?.emailConfig?.bulkType?.title },
    { title: 'Template', detail: bulkEmailInPreview?.data?.emailConfig?.template?.name },
    { title: 'Subject', detail: bulkEmailInPreview?.data?.emailBody?.subject }
  ];

  const setBulkRestrictionToInitial = () =>
    dispatch(setBulkEmailStatus({ bulkRestriction: { rule: null, reason: '' } }));

  const handelReschedule = () => {
    setBulkRestrictionToInitial();
    setUiState(prev => ({ ...prev, isRescheduleDialogOpen: true }));
  };

  const closeTemplateDrawer = () => {
    templateTogglers.templateDrawer({ open: false, edit: false })();
    templateTogglers.discardTemplateDialog({ open: false })();
  };

  const handleOnCloseTemplateDrawer = (hasChanges: boolean) =>
    hasChanges ? templateTogglers.discardTemplateDialog({ open: true })() : closeTemplateDrawer();

  return (
    content[selectedMenuItem] || (
      <>
        <BulkEmailContentHeader
          searchbarPlaceHolder={sentCopies.searchBarPlaceholder}
          {...containerProps[selectedMenuItem]?.ContentHeaderProps}
        />
        <Grid data-testid="bulk-lists-default-layout" container className={classes.listContainer}>
          <BulkEmailContentBody
            isResponsive={isResponsive}
            selectedMenuItem={selectedMenuItem}
            {...(containerProps[selectedMenuItem]?.ContentBodyProps || {})}
          />
        </Grid>
        <When condition={bulkRestrictionRule}>
          <RestrictionDialog
            onOpenScheduleDialog={handelReschedule}
            onClose={setBulkRestrictionToInitial}
            bulkRestriction={bulkEmailStatus.bulkRestriction}
            onSubmitBulkEmail={openSendNowConfirmDialog}
            isSendScheduleNow
          />
        </When>
        <When condition={uiState.isRescheduleDialogOpen}>
          <FormContext {...form}>
            <ScheduleDialog
              shouldUnregisterFieldsOnClose
              onClose={handleToggleBulkDialogs(false, BULK_DIALOGS.reschedule)}
              onSubmitBulkEmail={handleRescheduleBulk}
              notifyBulkQuotaRestriction={notifyBulkQuotaRestriction}
              remainingQuota={remainingQuota}
              searchProjectSize={searchProjectSize}
            />
          </FormContext>
        </When>
        <When condition={uiState.isDeleteScheduleDialogOpen}>
          <DecisionDialog
            {...dialogsCopies.scheduleDelete}
            fetching={bulkEmailStatus.loading}
            isOpened
            onClose={handleToggleBulkDialogs(false, BULK_DIALOGS.deleteSchedule)}
            onConfirm={handleDeleteSchedule}
            withButtons="YesNo"
          />
        </When>
        <When condition={uiState.isSendNowDialogOpen}>
          <DetailedConfirmationDialog
            decisionDialogProps={{
              ...dialogsCopies.scheduleSendNow,
              closeDialogOnConfirm: false,
              mustComplete: true,
              taskCompleted: bulkEmailStatus.completed,
              fetching: bulkEmailStatus.loading,
              onClose: handleToggleBulkDialogs(false, BULK_DIALOGS.sendNow),
              onConfirm: handleSendNowSchedule
            }}
            details={scheduleSendNowDetails}
          />
        </When>
        <When condition={uiState.isDeleteDraftDialogOpen}>
          <DecisionDialog
            {...dialogsCopies.draftDelete}
            fetching={bulkEmailStatus.loading}
            isOpened
            onClose={handleToggleBulkDialogs(false, BULK_DIALOGS.deleteDraft)}
            onConfirm={handleDeleteBulkDraft}
            withButtons="YesNo"
          />
        </When>
        <When condition={templatesUiState.isArchiveDialogOpen}>
          <DecisionDialog
            {...templateArchiveDialogProps}
            withButtons="YesNo"
            mustComplete
            fetching={templatesUiState.loading}
            onClose={templateTogglers.archiveDialog({ open: false })}
          />
        </When>
        <When
          condition={
            templatesUiState.isRestoreDialogOpen &&
            !hasFeatureFlag(FeatureFlags.BulkTemplatesRestoreSelectingFolder)
          }
        >
          <DecisionDialog
            {...templateRestoreDialogProps}
            withButtons="YesNo"
            mustComplete
            fetching={templatesUiState.loading}
            onClose={templateTogglers.restoreDialog({ open: false })}
          />
        </When>
        <When
          condition={
            templatesUiState.isRestoreDrawerOpen &&
            hasFeatureFlag(FeatureFlags.BulkTemplatesRestoreSelectingFolder)
          }
        >
          <TemplateRestoreForm
            itemToRestore={templatesTreeItem}
            onClose={templateTogglers.restoreDrawer({ open: false })}
            onRestore={templateHandlers.restoreTemplateTreeItem}
          />
        </When>
        <When condition={templatesUiState.isFolderDrawerOpen}>
          <TemplateFolderDrawer
            templatesTreeItem={templatesTreeItem}
            isFolderBeingEdited={templatesUiState.isFolderBeingEdited}
            onClose={templateTogglers.folderDrawer({ open: false, edit: false })}
            onSubmitFolder={templateHandlers.submitFolder}
          />
        </When>
        <When condition={templatesUiState.isTemplateDrawerOpen}>
          <BulkTemplatesDrawer
            isTemplateBeingEdited={templatesUiState.isTemplateBeingEdited}
            onCloseDrawer={handleOnCloseTemplateDrawer}
            onSubmitTemplate={templateHandlers.submitTemplate}
            templatesTreeItem={templatesTreeItem}
          />
          <DecisionDialog
            isOpened={templatesUiState.isDiscardTemplateDialogOpen}
            title={templateCopies.dialogs.discard.title}
            message={templateCopies.dialogs.discard.message}
            confirmButtonText={dialogsCopies.cancel.copies.confirmButtonText}
            cancelButtonText={dialogsCopies.cancel.copies.cancelButtonText}
            isHighLightActionOnLeft
            withButtons="YesNo"
            onConfirm={closeTemplateDrawer}
            onClose={templateTogglers.discardTemplateDialog({ open: false })}
          />
        </When>
      </>
    )
  );
};

BulkEmailContent.defaultProps = {
  onAddEmailToOptOut: () => {}
};

export default BulkEmailContent;
