const sharedColumns = {
  id: {
    name: 'id',
    label: 'Id',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  date: {
    label: 'Last Saved On',
    name: 'savedOn',
    options: {
      display: true,
      renderer: 'date'
    }
  }
};

export const CandidateColumns = [
  {
    name: 'formData.firstName',
    label: 'Name',
    options: {
      display: true,
      renderer: 'default'
    }
  },
  {
    name: 'formData.lastName',
    label: 'Last Name',
    options: {
      display: true,
      renderer: 'default'
    }
  },
  {
    label: 'Mail',
    name: 'formData.email',
    options: {
      display: true
    }
  },
  {
    label: 'Company',
    name: 'formData.company.name',
    options: {
      display: true,
      renderer: 'safeDefault'
    }
  },
  sharedColumns.date,
  sharedColumns.id
];

export const JobOrderColumns = [
  {
    name: 'formData.title',
    label: 'Title',
    options: {
      display: true,
      renderer: 'default'
    }
  },
  {
    label: 'Company',
    name: 'formData.company.name',
    options: {
      display: true
    }
  },
  {
    label: 'Functional Title',
    name: 'formData.functionalTitle.title',
    options: {
      display: true
    }
  },
  sharedColumns.date,
  sharedColumns.id
];
