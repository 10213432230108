import React, { useCallback, useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import qs from 'query-string';
import API from 'services/API';
import strings from 'strings';
import Text from 'UI/components/atoms/Text';
import HistoryItem from 'UI/components/molecules/HistoryItem';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { DateFormats } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { getId } from 'UI/utils';

import { useStyles } from './styles';

const { drawer: drawerCopies } = strings.searchProjects.activityLog;
const firstPage = 1;

const ActivityLogDrawer = ({ open, onClose, searchProjectId }) => {
  const classes = useStyles();

  const [activityLogs, setActivityLogs] = useState({
    logs: [],
    pagination: {
      page: 1,
      perPage: 20,
      limitReached: false
    }
  });
  const [uiState, setUiState] = useState({
    lastScrollTop: 0,
    isFormLoading: true
  });

  const getActivities = useCallback(
    async page => {
      const endpoint = qs.stringifyUrl({
        url: Endpoints.SearchProjectHistoryEvents.replace(/:id/, searchProjectId),
        query: {
          page,
          perPage: activityLogs.pagination.perPage
        }
      });
      const response = await API.get(endpoint);
      if (response.status === 200) {
        const { data } = response;
        const formattedData = data.map(log => ({ ...log, id: getId() }));
        setActivityLogs(prev => ({
          logs: [...prev.logs, ...formattedData],
          pagination: {
            ...prev.pagination,
            page: prev.pagination.page + 1,
            limitReached: data.length < 20
          }
        }));
      }
    },
    [activityLogs.pagination.perPage, searchProjectId]
  );

  useEffect(() => {
    (async () => {
      setUiState(prev => ({ ...prev, isFormLoading: true }));
      await getActivities(firstPage);
      setUiState(prev => ({ ...prev, isFormLoading: false }));
    })();
  }, [getActivities]);

  const footerActionsProps = {
    hideSecondaryButton: true,
    primaryProps: {
      text: 'Close',
      onClick: onClose
    }
  };

  return (
    <DrawerContentLayout
      {...drawerCopies}
      drawerProps={{ open }}
      uiState={uiState}
      onClose={onClose}
      containerComponent="div"
      footerActionsProps={footerActionsProps}
    >
      <div className={classes.root}>
        <Text text="History Log" variant="h6" className={classes.title} />
        <Grid container direction="column" justify="center">
          {activityLogs.logs.map(log => (
            <HistoryItem
              key={log.id}
              action={log.description}
              date={log.date}
              dateFormat={DateFormats.SimpleDateTime}
              type="custom"
            />
          ))}
          {!activityLogs.pagination.limitReached &&
            !uiState.isFormLoading &&
            activityLogs.logs.length > 0 && (
              <VisibilitySensor
                onChange={visible => visible && getActivities(activityLogs.pagination.page)}
                delayedCall
              >
                <CircularProgress size={24} className={classes.loader} />
              </VisibilitySensor>
            )}
          {activityLogs.pagination.limitReached && (
            <Text variant="caption" text="No more results to show" />
          )}
        </Grid>
      </div>
    </DrawerContentLayout>
  );
};

export default ActivityLogDrawer;
