// @flow
import AxiosRequestConfig from 'axios';
import { JSON_SCHEMA_CLIENT } from 'services/API';
import { Endpoints } from 'UI/constants/endpoints';

export const saveCandidateDataSheet = async (dataSheet: Object, config?: AxiosRequestConfig) => {
  return JSON_SCHEMA_CLIENT.put(`/${Endpoints.CandidateDataSheetSave}`, dataSheet, config);
};

export const saveCandidateDataSheetDraft = async (
  dataSheet: Object,
  config?: AxiosRequestConfig
) => {
  return JSON_SCHEMA_CLIENT.put(`/${Endpoints.CandidateDataSheetSaveDraft}`, dataSheet, config);
};

export const getCandidateDataSheetById = async candidateDataSheetId => {
  return JSON_SCHEMA_CLIENT.get(
    `/${Endpoints.CandidateDataSheetById.replace(':id', candidateDataSheetId)}`
  );
};
