import qs from 'query-string';
import { SelectionType } from 'UI/constants/defaults';

const PARAMS_KEY_NAMES = {
  exclude: 'search_params',
  include: 'search_project_selected_items'
};

const SPACED_ENTITIES_KEYS = {
  HiringAuthority: 'Hiring Authority',
  JobOrder: 'Job Order'
};

const fillItems = (items, key) =>
  items.map(({ id, type_id }) => ({ id, [key]: parseInt(type_id, 10) }));

export const fillSearchProjectSelectionParams = (selectedItems, queryParams) => {
  const { data, type } = selectedItems;
  const dataHasItems = data.length > 0;
  const filledData = dataHasItems ? fillItems(data, 'item_search_project_type') : [];
  if (type === SelectionType.Include && dataHasItems)
    return { key: PARAMS_KEY_NAMES.include, data: filledData };
  if (type === SelectionType.Exclude || queryParams)
    return {
      key: PARAMS_KEY_NAMES.exclude,
      data: {
        query: qs.parse(queryParams.params),
        exclude: filledData
      }
    };
  return {};
};

/**
 * @typedef FilteredItemsData
 * @property {Number} id
 * @property {String} type
 * @property {Number} type_id
 */

/**
 * @param {FilteredItemsData[]} filteredItems - Object containing items to add, type and count
 * that will be transformed to an object containing items ordered by their type
 *
 * @returns {{
 *  candidates: number[],
 *  hiring_authorities: number[],
 *  names: number[],
 *  job_orders: number[]
 * }} Object containig multiple entities arrays, data inside filteredItems
 * is ordered by item's type
 */
export const fillIncludedItems = filteredItems => {
  const searchProjectEntitiesList = {
    Candidate: [],
    Name: [],
    [SPACED_ENTITIES_KEYS.HiringAuthority]: [],
    [SPACED_ENTITIES_KEYS.JobOrder]: [],
    Company: []
  };
  filteredItems.forEach(item => {
    searchProjectEntitiesList[item.type] = [...searchProjectEntitiesList[item.type], item.id];
  });
  return {
    candidates: [...searchProjectEntitiesList.Candidate],
    hiring_authorities: [...searchProjectEntitiesList[SPACED_ENTITIES_KEYS.HiringAuthority]],
    names: [...searchProjectEntitiesList.Name],
    job_orders: [...searchProjectEntitiesList[SPACED_ENTITIES_KEYS.JobOrder]],
    companies: [...searchProjectEntitiesList.Company]
  };
};
