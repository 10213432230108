// @flow
import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent, { CardContentProps } from '@material-ui/core/CardContent';
import CardHeader, { CardHeaderProps } from '@material-ui/core/CardHeader';
import Collapse, { CollapseProps } from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import type { FPActionButtonProps, HintProps } from 'types/app';
import ConditionalActionWrapper from 'UI/components/atoms/ConditionalWrapper';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import FPHint from 'UI/components/atoms/FPHint';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { SvgArrowDown, SvgArrowRight, SvgArrowUp } from 'UI/res/icons/filled';

import {
  cardStyles,
  useActionStyles,
  useContentStyles,
  useFooterStyles,
  useHeaderStyles
} from './styles';

/** CARD STYLE CUSTOMIZATIONS */
export const FPCard = withStyles(cardStyles)(Card);

/** CARD HEADER CUSTOMIZATIONS */
type FPCardHeaderProps = CardHeaderProps & {
  variant: 'regular' | 'hero' | 'section',
  actionMode: 'default' | 'custom' | 'noAction' | 'actionAndExpand',
  hintProps: HintProps,
  expanded: boolean
};

export const FPCardHeader = ({
  action,
  actionMode,
  expanded,
  hintProps,
  title,
  variant,
  ...rest
}: FPCardHeaderProps) => {
  const classes = useHeaderStyles({ variant })();
  const classesAction = useActionStyles();

  const ExpandAction = (
    <FPIconButton
      icon={expanded ? SvgArrowDown : SvgArrowUp}
      iconProps={{ family: 'mini', size: 'sm' }}
    />
  );

  const actionTypes = {
    actionAndExpand: (
      <Grid container className={classesAction.actionAndExpandContainer}>
        {action && <Grid item>{action}</Grid>}
        <Grid item>{ExpandAction}</Grid>
      </Grid>
    ),
    default: ExpandAction,
    custom: action,
    noAction: ''
  };

  const isCustomAction = action && actionMode !== 'actionAndExpand';

  return (
    <CardHeader
      classes={classes}
      action={isCustomAction ? actionTypes.custom : actionTypes[actionMode]}
      title={
        <>
          {title} {hintProps ? <FPHint position="top" {...hintProps} /> : null}
        </>
      }
      {...rest}
    />
  );
};

FPCardHeader.defaultProps = {
  variant: 'hero',
  actionMode: 'noAction',
  hintProps: undefined,
  expanded: true
};

/** CARD CONTENT CUSTOMIZATIONS */

type FooterProps = {
  actionButtonProps: FPActionButtonProps,
  footerActionIcon: React.Node,
  footerActionText: string,
  CustomFooter: React.Node
};

type FPCardContentProps = CardContentProps & {
  variant: 'tight' | 'regular' | 'relaxed',
  collapseProps: CollapseProps,
  canBeExpanded: boolean,
  footerProps: FooterProps,
  headerProps: FPCardHeaderProps
};

export const FPCardContent = ({
  canBeExpanded,
  children,
  collapseProps,
  footerProps: { actionButtonProps, footerActionIcon, footerActionText, customClass, CustomFooter },
  headerProps,
  variant,
  ...rest
}: FPCardContentProps) => {
  const classes = useContentStyles({ variant });
  const footerClasses = useFooterStyles();

  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    if (!canBeExpanded) return;
    setExpanded(!expanded);
  };

  return (
    <>
      {headerProps && (
        <FPCardHeader expanded={expanded} onClick={handleExpandClick} {...headerProps} />
      )}
      <ConditionalActionWrapper
        condition={canBeExpanded}
        wrapper={(c = children) => (
          <Collapse in={expanded} {...collapseProps}>
            {c}
          </Collapse>
        )}
      >
        <CardContent classes={classes} {...rest}>
          {children}
          {footerActionText && (
            <div className={clsx(footerClasses.buttonContainer, customClass)}>
              <FPActionButton
                variant="text"
                size="small"
                icon={footerActionIcon || SvgArrowRight}
                family="mini"
                iconPosition="right"
                iconProps={{ family: 'mini', size: 12 }}
                text={footerActionText}
                {...actionButtonProps}
              />
            </div>
          )}
          {CustomFooter}
        </CardContent>
      </ConditionalActionWrapper>
    </>
  );
};

FPCardContent.defaultProps = {
  variant: 'regular',
  canBeExpanded: false,
  headerProps: undefined,
  footerProps: {
    footerActionText: '',
    actionButtonProps: {}
  }
};
