import GpacGeneratorForm from '@gogpac/form-generator';
import { styled, Typography } from '@material-ui/core';
import { FPCardContent } from 'UI/components/atoms/FPCard';

export const MainContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(6)
}));

export const AnimationContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%'
});

export const EditDataSheetCardContent = styled(FPCardContent)(({ theme }) => ({
  maxWidth: theme.typography.pxToRem(720)
}));

export const ProgressLabel = styled(Typography)({
  fontWeight: 600
});

export const StyledForm = styled(GpacGeneratorForm)(({ theme }) => ({
  '& div > div > div': {
    marginTop: `${theme.spacing(1)} !important`
  },
  '& .MuiLink-root.MuiLink-button': {
    fontWeight: theme.typography.fontWeightMedium
  },
  '& [id$="__description"]': {
    fontSize: theme.typography.caption.fontSize
  }
}));
