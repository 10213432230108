import * as React from 'react';

const SvgNewFolderIcon = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <g fill={fill}>
        <path d="M9.5 17.5a8 8 0 018-8 7.86 7.86 0 013.15.66.25.25 0 00.35-.22V4.5A1.5 1.5 0 0019.5 3H9.62a.51.51 0 01-.45-.28L8.22.83A1.49 1.49 0 006.88 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h7.64c.25 0 .36-.33.36-.5z" />
        <path d="M17.5 11a6.5 6.5 0 106.5 6.5 6.51 6.51 0 00-6.5-6.5zm3.25 6.5a.76.76 0 01-.75.75h-1.5a.25.25 0 00-.25.25V20a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25H15a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25H20a.76.76 0 01.75.75z" />
      </g>
    </svg>
  );
};

export default SvgNewFolderIcon;
