// @flow

import Handlebars from 'handlebars';
import strings from 'strings';
import { BULK_RESTRICTION_IDS, BULK_RESTRICTION_RULE } from 'UI/constants/defaults';
import { getFeatureFlags } from 'UI/utils';

const { validations: validationsCopies } = strings.bulkEmails.forms.smartTags;
const { customSmartTagsValidations } = strings.bulkEmails.forms.errors;
const RECIPIENT_TYPE = 'recipient';

export const mapRestrictionId = (restrictionId: string) => {
  switch (restrictionId) {
    case BULK_RESTRICTION_IDS.scheduleCloseNearScheduled:
      return BULK_RESTRICTION_RULE.scheduleCloseNearScheduled;
    case BULK_RESTRICTION_IDS.scheduleCloseNearSent:
      return BULK_RESTRICTION_RULE.scheduleCloseNearSent;
    case BULK_RESTRICTION_IDS.sendNearSent:
      return BULK_RESTRICTION_RULE.sendNearSent;
    case BULK_RESTRICTION_IDS.sendNearScheduled:
      return BULK_RESTRICTION_RULE.sendNearScheduled;
    case BULK_RESTRICTION_IDS.noQuotaAvailable:
      return BULK_RESTRICTION_RULE.noQuotaAvailable;
    default:
      return BULK_RESTRICTION_RULE.defaultRestriction;
  }
};

/**
 * @typedef RuleReasonType
 * @property {string} rule
 * @property {string} reason
 */

/**
 * @typedef QuotaOptionsType
 * @property {number} remainingQuota
 * @property {number} searchProjectSize
 */

/**
 * @param {QuotaOptionsType} quotaOptions
 * @returns {RuleReasonType}
 */
export const getQuotaRestriction = (quotaOptions = {}) => {
  const { remainingQuota, searchProjectSize } = quotaOptions;
  if (remainingQuota < 0 || typeof remainingQuota !== 'number') return null;

  if (remainingQuota === 0) {
    return {
      rule: BULK_RESTRICTION_RULE.noQuotaAvailable,
      reason: strings.bulkEmails.dialogs.restrictions.bodyMessages.noQuotaAvailable
    };
  }

  return remainingQuota < searchProjectSize
    ? {
        rule: BULK_RESTRICTION_RULE.quotaNearlyReached,
        reason: strings.formatString(
          strings.bulkEmails.dialogs.restrictions.bodyMessages.quotaNearlyReached,
          { remainingQuota, searchProjectSize }
        )
      }
    : null;
};

/**
 * Exported only for test purposes
 */

/**
 * @param {string} content
 * @param {Array} smartTags
 */
export const ensureTemplateIsValid = (content, smartTags = []) => {
  try {
    const data = {};
    const template = Handlebars.compile(content, { noEscape: true, strict: true });

    smartTags.length > 0 &&
      smartTags.forEach(tag => {
        const tagName = tag.value.match(/[a-z_]+/);
        data[tagName[0]] = '';
      });
    template(data);

    return {
      success: true
    };
  } catch (error) {
    const incorrectSmartTag = error.message.match(/"\w+"/gi);
    return {
      success: false,
      error: incorrectSmartTag && incorrectSmartTag[0]
    };
  }
};

const formatErrorCopy = (copy, values) => strings.formatString(copy, values);

export const checkWellWrittenSmartTags = (field, smartTags) => content => {
  const templateIsValid = ensureTemplateIsValid(content, smartTags);

  if (!templateIsValid.success) {
    return templateIsValid.error
      ? formatErrorCopy(validationsCopies.unsupportedSmarTag, {
          field,
          smartTag: templateIsValid.error
        })
      : formatErrorCopy(validationsCopies.wellWritten, { field });
  }

  return templateIsValid.success;
};

/**
 * Exported only for test purposes
 */
export const checkAtLeastOneSmartTag =
  ({ featureFlag, smartTags }) =>
  content => {
    if (!getFeatureFlags().includes(featureFlag) || !smartTags) return true;

    const recipientSmartTags = smartTags.filter(({ type }) => type === RECIPIENT_TYPE);
    const includesRecipientSmartTag = recipientSmartTags.some(
      ({ value: smartTag }) => !!content.match(smartTag)
    );

    return includesRecipientSmartTag || validationsCopies.atLeastOne;
  };
/**
 * @typedef CustomValidationsOptions
 * @property {String} fieldName
 * @property {String} featureFlag
 * @property {Array} smartTags
 */

/**
 * @param {CustomValidationsOptions} options
 * @returns {Object}
 */
export const getBulkEmailCustomValidations = (options = {}) => {
  const { fieldName, featureFlag, smartTags } = options;

  if (!fieldName || !featureFlag || !smartTags) throw new Error(customSmartTagsValidations);

  const validations = {
    validate: {
      wellWrittenSmartTags: checkWellWrittenSmartTags(fieldName, smartTags),
      atLeastOneSmartTag: checkAtLeastOneSmartTag({
        featureFlag,
        smartTags
      })
    }
  };

  return validations;
};
