import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    ...flexAlignCenterJustifyCenter
  },
  container: {
    backgroundColor: ({ hasCustomClass }) =>
      !hasCustomClass ? theme.palette.secondary.FP300 : 'inherit',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5, 1, 1, 1)
  },
  customLabel: {
    color: `${theme.palette.customColors.white} !important`
  },
  inline: {
    marginBottom: theme.spacing(2)
  },
  leftStart: {
    justifyContent: 'flex-start'
  },
  spacingBottom: {
    marginBottom: theme.spacing(4)
  },
  subtitle: {
    color: theme.palette.grey[800],
    fontSize: 'inherit',
    opacity: '0.60',
    textAlign: 'center'
  },
  titleCard: {
    color: theme.palette.primary.light,
    fontSize: 'inherit',
    fontWeight: '500 !important',
    lineHeight: '16px',
    textAlign: 'center'
  },
  valueCard: {
    color: theme.palette.primary.light,
    fontFamily: '"Kanit", sans-serif',
    fontSize: 24,
    fontWeight: '500 !important',
    margin: theme.spacing(0.5, 0)
  },
  valueLeftStart: {
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2)
  }
}));
