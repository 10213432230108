// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';

import { LogType } from '../utils';

import Label from './Label';
import Value from './Value';

type ChangeLogLabelValueProps = {
  value: string | number,
  label?: string,
  split?: boolean,
  type?: 'text' | 'date' | 'percentage' | 'currency' | 'phone'
};

const ChangeLogLabelValue = ({ value, label, split, type = 'text' }: ChangeLogLabelValueProps) => (
  <Grid container direction={type === LogType.Html ? 'column' : 'row'}>
    <Label label={label} split={split} />
    <Value type={type} value={value} />
  </Grid>
);

export default ChangeLogLabelValue;
