// @flow
import React from 'react';
import {
  PROFILE_ENTITIES_PROPS,
  tabDefinitionResolver
} from 'UI/components/organisms/inventoryProfiles/commons';
import TabsView from 'UI/components/templates/TabsView';
import { Endpoints } from 'UI/constants/endpoints';
import { JobOrderEntity, TabKeys } from 'UI/constants/entityTypes';
import { getProfileFileDraggableProps } from 'UI/utils';

import { useStyles } from './styles';

type CreateTabViewProps = {
  tabs: Array<Object>,
  profileTabProps: Array<Object>,
  profileMenuProps: Object,
  results: Object
};

export const createTabViewProps = ({
  tabs,
  profileTabProps,
  profileMenuProps
}: CreateTabViewProps) =>
  tabs.map(({ Component, visible = true, props = {}, id, label }) => {
    const currentTab = profileTabProps?.find(item => item.id === id);

    const profileProps = currentTab?.tabProps;

    return {
      id,
      label,
      visible: typeof visible === 'function' ? visible() : visible,
      view: Component && <Component {...props} {...profileProps} {...profileMenuProps} />
    };
  });

type ProfileTabsWrapperProps = {
  endpoint: string,
  entityType: string,
  isChangeLogsTabEnabled: boolean,
  isLoading: boolean,
  profileTabProps: Array<Object>,
  results: Object,
  enableUrlNavigation: boolean,
  profileMenuProps: {
    onEditClick: () => void
  }
};

const ProfileTabsWrapper = ({
  endpoint,
  entityType,
  isChangeLogsTabEnabled,
  isLoading,
  profileTabProps,
  results,
  enableUrlNavigation,
  profileMenuProps
}: ProfileTabsWrapperProps) => {
  const classes = useStyles();

  const entityId = entityType?.id;

  const currentEntityProps = PROFILE_ENTITIES_PROPS[entityId];
  const currentTabsProps = currentEntityProps?.defaultTabsProps || {};
  const defaultTabsProps = {
    ...currentTabsProps,
    changeLogProps: {
      entityId: results?.id,
      profileModule: entityType.title
    }
  };

  const tabs = [
    ...createTabViewProps({
      tabs: tabDefinitionResolver({
        defaultTabsProps,
        enableSearchProjects: entityId !== JobOrderEntity.id,
        endpoint,
        isChangeLogsTabEnabled,
        loading: isLoading,
        overviewComponent: currentEntityProps?.overviewComponent,
        overviewProps: currentEntityProps?.overviewProps,
        profile: results,
        tabsDefinition: currentEntityProps?.tabsDefinition,
        fileManagerProps: {
          filesEndpoint: `${endpoint}/${Endpoints.Files}`,
          module: currentEntityProps?.fileManagerModule,
          readOnly: false,
          FileDraggableProps: getProfileFileDraggableProps()
        }
      }),
      profileTabProps,
      profileMenuProps,
      results
    })
  ];

  return (
    <TabsView
      className={classes.unsetBackground}
      enableUrlNavigation={enableUrlNavigation}
      initialTab={TabKeys.Overview}
      tabPanelProps={{ className: classes.overviewTab }}
      tabs={tabs}
      useIndexes={false}
    />
  );
};

ProfileTabsWrapper.defaultProps = {
  enableUrlNavigation: true
};

export default ProfileTabsWrapper;
