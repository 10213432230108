import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'UI/res';

/** Tab Styles ------------------------------------------------- */

export const useTabStyles = makeStyles(
  {
    tab: {
      color: colors.iconInactive,
      flex: 1,
      fontSize: 14,
      fontWeight: 500,
      maxWidth: 'none',
      textTransform: 'unset'
    },
    wrapper: {
      width: '100%',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: '100%'
    }
  },
  { classNamePrefix: 'tabStyles' }
);

export const useTabContentStyles = makeStyles(
  {
    root: {
      position: 'relative',
      overflow: 'auto',
      padding: props => props.padding || 0,
      height: props => props.panelHeight || '100%' /** TODO Layout check */
    }
  },
  { classNamePrefix: 'tabContentStyles' }
);

export const useStyles = makeStyles(
  {
    root: {
      backgroundColor: colors.sideBar,
      borderBottom: `1px solid ${colors.inactiveSideBarTab}`,
      boxShadow: 'none',
      display: 'flex',
      padding: 0,
      width: '100%',
      zIndex: 1
    }
  },
  { classNamePrefix: 'tabBarStyles' }
);
