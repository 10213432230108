import * as React from 'react';

const SvgDownloadThickBottom = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      fill={fill}
      {...props}
    >
      <g fill={fill}>
        <path d="M8.62 1.74A1.45 1.45 0 0110.07.29h3.39a1.46 1.46 0 011.46 1.45V10a.25.25 0 00.25.25h2.89a1.14 1.14 0 01.83 2l-6.1 6.1a1.5 1.5 0 01-2 0l-6.1-6.1a1.14 1.14 0 01.83-2h2.9a.26.26 0 00.17-.07.25.25 0 00.03-.18z" />
        <path d="M0 19.68a4 4 0 004 4h16a4 4 0 004-4V17.8a1.23 1.23 0 00-2.45 0v1.88A1.59 1.59 0 0120 21.26H4a1.59 1.59 0 01-1.59-1.58V17.8a1.23 1.23 0 00-2.41 0z" />
      </g>
    </svg>
  );
};

export default SvgDownloadThickBottom;
