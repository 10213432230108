// @flow
import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { TreeView } from '@material-ui/lab';
import type { TreeViewLoaderPropTypes } from 'types/app';
import { When } from 'UI/components/atoms/When';
import { TREE_VIEW_ENTITY_TYPES } from 'UI/constants/defaults';

import { useTreeViewLoader } from './hooks/TreeViewLoader.hook';
import { useTreeViewLoaderStyles } from './styles';
import TreeViewChildrenRenderer from './TreeViewChildrenRenderer';
import { buildTreeViewItemId } from './utils';

const TreeViewLoader = ({
  enableIconClick = false,
  foldersIdToRefresh = null,
  foldersIdToShrink = null,
  onClickTreeItem = null,
  onFoldersRefresh = null,
  onFoldersShrink = null,
  onLoadTreeView = null,
  selectedTreeItemId = null,
  service = {}
}: TreeViewLoaderPropTypes) => {
  const classes = useTreeViewLoaderStyles();
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const {
    expandedIds,
    nodeToggler,
    refreshFoldersById,
    renderTreeRoot,
    shrinkFolders,
    tree,
    uiState,
    updateTree
  } = useTreeViewLoader({
    foldersIdToRefresh,
    foldersIdToShrink,
    onFoldersRefresh,
    onFoldersShrink,
    onLoadTreeView,
    service
  });

  useEffect(() => {
    renderTreeRoot();
  }, [renderTreeRoot]);

  useEffect(() => {
    refreshFoldersById();
  }, [refreshFoldersById]);

  useEffect(() => {
    shrinkFolders();
  }, [shrinkFolders]);

  const currentSelectedNodeId = selectedTreeItemId ?? selectedNodeId;

  return (
    <>
      <When condition={uiState.hasLoadedCorrectly}>
        <TreeView
          className={classes.treeView}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          expanded={expandedIds}
          onNodeToggle={nodeToggler}
          selected={buildTreeViewItemId(TREE_VIEW_ENTITY_TYPES.folder, currentSelectedNodeId)}
        >
          <TreeViewChildrenRenderer
            branchIdToRender="root"
            currentSelectedNodeId={currentSelectedNodeId}
            enableIconClick={enableIconClick}
            expandedIds={expandedIds}
            onClickTreeItem={onClickTreeItem}
            onSelectNode={setSelectedNodeId}
            onUpdateTreeItems={updateTree}
            queryParams={service.childrenQueryParams}
            serviceToExecute={service.method}
            tree={tree}
          />
        </TreeView>
      </When>
      <When condition={uiState.isLoading}>
        <Grid container className={classes.initialLoader}>
          <CircularProgress size={32} />
        </Grid>
      </When>
    </>
  );
};

export default TreeViewLoader;
