import { Roles } from 'UI/constants/roles';

export const ALL_COMPANY_VISIBILITY_ROLES = [
  Roles.ProductionDirector,
  Roles.Operations,
  Roles.DataCoordinator,
  Roles.Admin,
  Roles.Leadership,
  Roles.Finance
];
