// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { confirm, showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import useDrawer from 'hooks/drawerHandler';
import { useProfileQuickView } from 'hooks/useProfileQuickView';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import strings from 'strings';
import type { ProfileDataTableProps } from 'types/app';
import { When } from 'UI/components/atoms/When';
import { componentDimensions } from 'UI/constants/dimensions';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType, entityTypes, TabKeys } from 'UI/constants/entityTypes';
import { SvgNoJobOrders } from 'UI/res/icons/milano';
import { getErrorMessage } from 'UI/utils';
import { Renderers } from 'UI/utils/renderers';
import { updateUiState } from 'UI/utils/uiHelpers';

import LinkEntitiesForm from '../../LinkEntitiesForm';
import FPQuickView from '../FPQuickView';
import ProfileTableLayout from '../ProfileTableLayout';

const {
  shared: {
    ui: { keepRemoveButtonsCopies }
  },
  inventoryProfiles: {
    emptyStates: { joborders: jobOrdersEmptyStrings },
    sections: {
      candidates: { confirmJobOrderDeletetion, onJobOrderDeleteSuccess },
      tabs: {
        jobOrders: { actions: actionsCopies, drawer: drawerCopies }
      }
    }
  }
} = strings;

const { emptyState } = componentDimensions;

type JobOrdersTabProps = {
  mode: 'candidate' | 'company' | 'hiringAuthority',
  endpoint: string,
  profileId: number,
  itemName: string,
  DataTableProps: ProfileDataTableProps,
  initialColumns: Array<Object>,
  paramsBuilder: Array<Object>
};

const JobOrdersTab = ({
  mode,
  endpoint,
  profileId,
  itemName,
  DataTableProps,
  initialColumns,
  paramsBuilder,
  ...rest
}: JobOrdersTabProps) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { JobOrderCreate, JobOrderProfile } = EntityRoutes;
  const isCompanyProfileMode = mode === EntityType.Company;
  const isHiringAuthorityMode = mode === EntityType.HiringAuthority;
  const editionDrawer = useDrawer(location, history);

  const [uiState, setUiState] = useState({
    isJobOrderOpen: false,
    shouldRefreshTableData: false
  });

  const handleNewJobOrderClick = () =>
    isCompanyProfileMode
      ? history.push(`${JobOrderCreate}?companyId=${profileId}`)
      : updateUiState({ isJobOrderOpen: true }, setUiState);

  const handleEditJobOrderClick = (item: any) =>
    history.push(JobOrderProfile.replace(':id', item.id));

  const handleJobOrderClose = () =>
    uiState.isJobOrderOpen &&
    updateUiState({ isJobOrderOpen: false, shouldRefreshTableData: false }, setUiState);

  const handleJobOrderAssignment = async () => {
    await updateUiState({ shouldRefreshTableData: true }, setUiState);
    handleJobOrderClose();
  };

  const handleDeleteJobOrderClick = (item: any, getData) =>
    dispatch(
      confirm({
        severity: 'warning',
        title: strings.inventoryProfiles.sections.tabs.jobOrders.actions.remove,
        message: `${confirmJobOrderDeletetion} ${itemName}?`,
        ...keepRemoveButtonsCopies,
        onConfirm: async ok => {
          if (!ok) return;

          try {
            const response = await API.delete(`${endpoint}/remove-job-order/${item?.id}`);

            if (response.status === HTTPStatusCodes.Ok) {
              await getData();
              dispatch(
                showAlert({
                  severity: 'success',
                  title: 'Job Order removed Successfully',
                  body: `${onJobOrderDeleteSuccess} ${itemName}`
                })
              );
            }
          } catch (error) {
            dispatch(
              showAlert({
                severity: 'error',
                title: 'Error',
                body: getErrorMessage(error)
              })
            );
          }
        }
      })
    );

  const jobOrderActions = {
    candidate: [
      {
        name: 'delete',
        callback: handleDeleteJobOrderClick
      }
    ],
    company: [
      {
        name: 'edit',
        callback: handleEditJobOrderClick
      }
    ]
  };

  const finalActions = jobOrderActions[mode];

  const {
    handleQuickViewPreview,
    itemsToNavigate,
    quickViewEntity,
    entityId,
    quickViewState,
    toggleQuickViewPreview
  } = useProfileQuickView({ quickViewEntityRoleProps: null });

  const handleRefreshTab = async () => {
    await updateUiState({ shouldRefreshTableData: true }, setUiState);
  };

  const handleTableDataLoaded = () => async () => {
    await updateUiState({ shouldRefreshTableData: false }, setUiState);
  };
  return (
    <>
      <ProfileTableLayout
        actionText={isCompanyProfileMode ? actionsCopies.create : actionsCopies.assign}
        customEmptyState={<SvgNoJobOrders size={emptyState.width} />}
        DataTableProps={{
          onFetchedData: uiState.shouldRefreshTableData && handleTableDataLoaded,
          ...DataTableProps
        }}
        finalActions={finalActions}
        hasProfileLoaded={profileId}
        initialColumns={initialColumns}
        onNewItemClick={!isHiringAuthorityMode && handleNewJobOrderClick}
        onQuickViewClick={handleQuickViewPreview}
        paramsBuilder={paramsBuilder}
        profileModule={EntityType.Joborder}
        shouldRefresh={uiState.shouldRefreshTableData}
        tabKey={TabKeys.JobOrders}
        {...jobOrdersEmptyStrings}
        {...rest}
      />

      <When condition={entityId && quickViewState.isQuickViewOpen}>
        <FPQuickView
          drawerProps={{
            open: quickViewState.isQuickViewOpen
          }}
          onClose={toggleQuickViewPreview}
          entityType={quickViewEntity}
          itemsToNavigate={itemsToNavigate}
          onEditionCompleted={handleRefreshTab}
          id={entityId}
        />
      </When>

      {uiState.isJobOrderOpen && (
        <LinkEntitiesForm
          displayTemplate={Renderers.displayBasicInfoEntity}
          drawerProps={{
            open: true,
            onClose: editionDrawer.toggleDrawer('isJobOrderOpen', false)
          }}
          idFieldName="jobOrderId"
          onClosed={handleJobOrderClose}
          onCompleted={handleJobOrderAssignment}
          placeholder={drawerCopies.placeholder}
          sourceEntity={entityTypes.find(each => each.id === EntityType.Joborder)}
          endpointToSaveEntity={`${endpoint}/${Endpoints.JobOrderAssigned}`}
          title={actionsCopies.assign}
          profileName={itemName}
          externalLink={option =>
            Renderers.externalLink(
              EntityRoutes.JobOrderProfile.replace(':id', option.id),
              'View Job Order'
            )
          }
        />
      )}
    </>
  );
};

JobOrdersTab.defaultProps = {
  profileId: null
};

export default JobOrdersTab;
