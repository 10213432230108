import { METRICS, METRICS_MAP } from 'features/command-center/constants';
import { capitalizeName } from 'features/command-center/utils';

import { MAP_METRIC_MEDIUM_COLORS } from './TeamMembersHealth.constants';

export const formatTeamMembersHealthResponse = response => {
  const initialMinMaxValues = {
    atRisk: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    behind: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    onTrack: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    inTraining: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    under3Months: { min: Number.MAX_VALUE, max: Number.MIN_VALUE }
  };

  const getUpdatedMinMax = (current, total) => ({
    min: Math.min(current.min, total),
    max: Math.max(current.max, total)
  });

  const minMaxValuesResult = response.data.reduce((acc, item) => {
    const { atRisk, behind, onTrack, inTraining, under3Months } = item;

    return {
      atRisk: getUpdatedMinMax(acc.atRisk, atRisk.total),
      behind: getUpdatedMinMax(acc.behind, behind.total),
      onTrack: getUpdatedMinMax(acc.onTrack, onTrack.total),
      inTraining: getUpdatedMinMax(acc.inTraining, inTraining.total),
      under3Months: getUpdatedMinMax(acc.under3Months, under3Months.total)
    };
  }, initialMinMaxValues);

  const formattedData = response.data.map(item => ({
    ...item,
    minMaxValues: minMaxValuesResult,
    teamName: {
      id: item.managerEmpNo,
      managerName: capitalizeName(item.managerName)
    },
    atRisk: {
      ...item.atRisk,
      color: createColorVariants(
        MAP_METRIC_MEDIUM_COLORS[METRICS_MAP.AtRisk],
        item.atRisk.total,
        minMaxValuesResult[METRICS_MAP.AtRisk].min,
        minMaxValuesResult[METRICS_MAP.AtRisk].max
      )
    },
    behind: {
      ...item.behind,
      color: createColorVariants(
        MAP_METRIC_MEDIUM_COLORS[METRICS_MAP.Behind],
        item.behind.total,
        minMaxValuesResult[METRICS_MAP.Behind].min,
        minMaxValuesResult[METRICS_MAP.Behind].max
      )
    },
    onTrack: {
      ...item.onTrack,
      color: createColorVariants(
        MAP_METRIC_MEDIUM_COLORS[METRICS_MAP.OnTrack],
        item.onTrack.total,
        minMaxValuesResult[METRICS_MAP.OnTrack].min,
        minMaxValuesResult[METRICS_MAP.OnTrack].max
      )
    },
    inTraining: {
      ...item.inTraining,
      color: createColorVariants(
        MAP_METRIC_MEDIUM_COLORS[METRICS_MAP.InTraining],
        item.inTraining.total,
        minMaxValuesResult[METRICS_MAP.InTraining].min,
        minMaxValuesResult[METRICS_MAP.InTraining].max
      )
    },
    under3Months: {
      ...item.under3Months,
      color: createColorVariants(
        MAP_METRIC_MEDIUM_COLORS[METRICS_MAP.Under3Months],
        item.under3Months.total,
        minMaxValuesResult[METRICS_MAP.Under3Months].min,
        minMaxValuesResult[METRICS_MAP.Under3Months].max
      )
    }
  }));

  return {
    ...response,
    data: formattedData
  };
};

export const teamMembersHealthParamsExtender = () => ({
  groupBy: 'team',
  metrics: Object.values(METRICS)
    .map(({ paramName }) => paramName)
    .join(',')
});

const calculateHue = (max, r, g, b, d) => {
  const hueMap = {
    [r]: (g - b) / d + (g < b ? 6 : 0),
    [g]: (b - r) / d + 2,
    [b]: (r - g) / d + 4,
    default: 0
  };

  return ((hueMap[max] || hueMap.default) / 6) * 360;
};

const hexToHsl = hex => {
  const hexCleaned = hex.replace(/^#/, '');

  const hexFull = hexCleaned.length === 3 ? [...hexCleaned].map(x => x + x).join('') : hexCleaned;

  const r = Number.parseInt(hexFull.substring(0, 2), 16) / 255;
  const g = Number.parseInt(hexFull.substring(2, 4), 16) / 255;
  const b = Number.parseInt(hexFull.substring(4, 6), 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const l = (max + min) / 2;

  if (max === min) {
    return {
      h: 0,
      s: 0,
      l: l * 100
    };
  }

  const d = max - min;
  const s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

  return {
    h: calculateHue(max, r, g, b, d),
    s: s * 100,
    l: l * 100
  };
};

const hslToHex = (hue, saturation, luminance) => {
  const adjustedS = saturation / 100;
  const adjustedL = luminance / 100;

  const k = n => (n + hue / 30) % 12;
  const a = adjustedS * Math.min(adjustedL, 1 - adjustedL);
  const f = n => adjustedL - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));

  const toHex = x => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };

  return `#${toHex(f(0))}${toHex(f(8))}${toHex(f(4))}`;
};

export const createColorVariants = (color, value, minValue, maxValue) => {
  const { h, s, l } = hexToHsl(color);

  const step = (maxValue - minValue) / 3;

  const getVariant = val => {
    if (val <= minValue + step) return 'light';
    if (val <= minValue + 2 * step) return 'medium';
    return 'dark';
  };

  const variant = getVariant(value);

  const getAdjustedLightness = (variantBase, lightness) => {
    const lightnessMap = {
      dark: lightness,
      medium: Math.min(l + (100 - lightness) * 0.5, 100),
      light: Math.min(l + (100 - lightness) * 0.75, 100),
      default: lightness
    };

    return lightnessMap[variantBase] || lightnessMap.default;
  };

  const adjustedLightness = getAdjustedLightness(variant, l);

  return hslToHex(h, s, adjustedLightness);
};
