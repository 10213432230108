import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  item: {
    width: ({ itemWidth }) => `${itemWidth}%`
  }
}));
