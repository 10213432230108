// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';
import CashStatsContent from 'UI/components/molecules/CashStatsContent/CashStatsContent';

import { useStyles } from './styles';

type DrawBalanceItemProps = {
  drawBalanceAmount: number,
  link: string
};

const DRAW_BALANCE_TEXTS = strings.home.sections.drawBalance;

const DrawBalanceItem = ({ drawBalanceAmount, link }: DrawBalanceItemProps) => {
  const classes = useStyles();
  const formattedDrawBalance = drawBalanceAmount
    ? Number(-drawBalanceAmount)
    : Number(drawBalanceAmount);

  return (
    <CashStatsContent title={DRAW_BALANCE_TEXTS.period} cashAmount={formattedDrawBalance}>
      {link && (
        <Grid item xs={12} className={classes.linkContainer}>
          <Link href={link} target="_blank" rel="noopener noreferrer">
            <Typography className={classes.detailsText}>{DRAW_BALANCE_TEXTS.details}</Typography>
          </Link>
        </Grid>
      )}
    </CashStatsContent>
  );
};

DrawBalanceItem.defaultProps = {
  drawBalanceAmount: 0
};

export default DrawBalanceItem;
