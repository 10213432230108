import * as React from "react";

const SvgFileCsv = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M17.5 6h3.9a.25.25 0 00.17-.43L16.43.43A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z" />
      <path d="M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM8.5 18.88a.63.63 0 010 1.25 3.63 3.63 0 010-7.25.63.63 0 010 1.25 2.38 2.38 0 100 4.75zm2.93-3.51l1.84 1.22a1.94 1.94 0 01-1.07 3.54h-1.7a.63.63 0 010-1.25h1.7a.68.68 0 00.37-1.25l-1.84-1.22a1.94 1.94 0 01-.85-1.61 1.92 1.92 0 011.92-1.92h1.7a.63.63 0 010 1.25h-1.7a.68.68 0 00-.68.67.69.69 0 00.31.57zm7.69-.37a8.17 8.17 0 01-1.62 4.88.63.63 0 01-1 0A8.17 8.17 0 0114.88 15v-1.5a.62.62 0 111.24 0V15a7 7 0 00.7 3 .23.23 0 00.36 0 7 7 0 00.7-3v-1.5a.62.62 0 111.24 0z" />
    </svg>
  );
};

export default SvgFileCsv;
