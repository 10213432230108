// @flow

import moment from 'moment';
import API from 'services/API';
import { Endpoints } from 'UI/constants/endpoints';

const handleDebriefingSubmit = async (formValues, interviewId, debriefingId) => {
  let endpoint;
  let request;

  endpoint = Endpoints.SendoutDebriefingCalls.replace(':id', interviewId);

  if (debriefingId) {
    request = API.put;
    endpoint = `${endpoint}/${debriefingId}`;
  } else {
    request = API.post;
  }

  const formattedData = transformInterviewData(formValues);

  const response = await request(endpoint, formattedData, { apiVersion: 2 });

  return response;
};

const handlePreparationSubmit = async (formValues, interviewId, preparationId) => {
  let endpoint;
  let request;

  endpoint = Endpoints.SendoutPreparationCalls.replace(':id', interviewId);

  if (preparationId) {
    request = API.put;
    endpoint = `${endpoint}/${preparationId}`;
  } else {
    request = API.post;
  }

  const formattedData = transformInterviewData(formValues);

  const response = await request(endpoint, formattedData, { apiVersion: 2 });

  return response;
};

export const interviewDetailsSubmitHandlers = {
  debriefing: handleDebriefingSubmit,
  preparation: handlePreparationSubmit
};

const transformInterviewData = inputData => {
  const combineDateTime = (date, time) => {
    if (!date || !time) return null;
    const combined = new Date(date);
    const timeDate = new Date(time);
    combined.setUTCHours(timeDate.getUTCHours());
    combined.setUTCMinutes(timeDate.getUTCMinutes());
    combined.setUTCSeconds(timeDate.getUTCSeconds());
    combined.setUTCMilliseconds(timeDate.getUTCMilliseconds());
    return combined.toISOString();
  };

  const result = {
    hiringAuthorityCallTime: combineDateTime(
      inputData.hiringauthorityInterviewDate,
      inputData.hiringauthorityInterviewTime
    ),
    candidateCallTime: combineDateTime(
      inputData.candidateInterviewDate,
      inputData.candidateInterviewTime
    ),
    addCalendarReminderForHiringAuthorityCallTime: inputData.hiringauthorityOutlookReminder,
    addCalendarReminderForCandidateCallTime: inputData.candidateOutlookReminder,
    candidateInsights: inputData.candidateInsights,
    hiringAuthorityInsights: inputData.hiringAuthorityInsights
  };

  const transformDebriefFile = (debrief, existingFileId) => {
    if (!debrief && !existingFileId) return null;
    if (!debrief && existingFileId) return [{ fileId: existingFileId, action: 'delete' }];
    if (debrief && !existingFileId) return [{ tempId: debrief.id, action: 'create' }];
    if (debrief && existingFileId) {
      if (debrief.id !== existingFileId) {
        return [
          { tempId: debrief.id, action: 'create' },
          { fileId: existingFileId, action: 'delete' }
        ];
      }
      return null;
    }
    return null;
  };

  const employerDebriefFiles = transformDebriefFile(
    inputData.employerDebrief,
    inputData.existingEmployerDebriefId
  );
  const candidateDebriefFiles = transformDebriefFile(
    inputData.candidateDebrief,
    inputData.existingCandidateDebriefId
  );
  if (employerDebriefFiles) result.employerDebriefFiles = employerDebriefFiles;
  if (candidateDebriefFiles) result.candidateDebriefFiles = candidateDebriefFiles;

  Object.keys(result).forEach(
    key => (result[key] === null || result[key] === undefined) && delete result[key]
  );

  return result;
};

interface FormatDefaulValuesArgs {
  data: object;
  drawerVariant?: string;
}
export const formatDefaultValues = ({ data }: FormatDefaulValuesArgs) => {
  const {
    hiringAuthorityCallTime,
    candidateCallTime,
    addCalendarReminderForHiringAuthorityCallTime,
    addCalendarReminderForCandidateCallTime,
    employerDebriefFiles,
    candidateDebriefFiles,
    candidateInsights,
    hiringAuthorityInsights
  } = data;

  const transformSingleCallTime = (callTime, addReminder) => {
    if (!callTime) return { date: null, time: null, outlookReminder: false };

    const momentDate = moment(callTime);
    return {
      date: momentDate.toDate(),
      time: momentDate.toDate(),
      outlookReminder: addReminder
    };
  };

  const hiringAuthorityData = transformSingleCallTime(
    hiringAuthorityCallTime,
    addCalendarReminderForHiringAuthorityCallTime
  );
  const candidateData = transformSingleCallTime(
    candidateCallTime,
    addCalendarReminderForCandidateCallTime
  );

  return {
    hiringauthorityInterviewDate: hiringAuthorityData.date,
    hiringauthorityInterviewTime: hiringAuthorityData.time,
    hiringauthorityOutlookReminder: hiringAuthorityData.outlookReminder,
    candidateInterviewDate: candidateData.date,
    candidateInterviewTime: candidateData.time,
    candidateOutlookReminder: candidateData.outlookReminder,
    employerDebrief:
      employerDebriefFiles && employerDebriefFiles.length > 0 ? employerDebriefFiles[0] : null,
    candidateDebrief:
      candidateDebriefFiles && candidateDebriefFiles.length > 0 ? candidateDebriefFiles[0] : null,
    existingEmployerDebriefId:
      employerDebriefFiles && employerDebriefFiles.length > 0 ? employerDebriefFiles[0].id : null,
    existingCandidateDebriefId:
      candidateDebriefFiles && candidateDebriefFiles.length > 0
        ? candidateDebriefFiles[0].id
        : null,
    candidateInsights,
    hiringAuthorityInsights
  };
};
