import strings from 'strings';

import { AgreementsDrawerContent } from './AgreementsDrawerContent';

const { sentAgreements, signedAgreements } = strings.commandCenter.drawers;

export const SentAgreementsConfig = {
  title: sentAgreements.title,
  component: AgreementsDrawerContent
};

export const SignedAgreementsConfig = {
  title: signedAgreements.title,
  component: AgreementsDrawerContent
};
