import { makeStyles } from '@material-ui/core/styles';
import { ExpandedDrawerWidths } from 'UI/constants/dimensions';

export const useStyles = makeStyles(
  theme => ({
    activityNoteTable: {
      '& .MuiTableRow-root': {
        verticalAlign: 'top'
      }
    },
    activityDrawerInQuickView: {
      width: ExpandedDrawerWidths.largeViewports,
      [theme.breakpoints.down(ExpandedDrawerWidths.largeViewports)]: {
        width: ExpandedDrawerWidths.smallViewports
      }
    }
  }),
  { classNamePrefix: 'activityNoteTable' }
);
