import { makeStyles } from '@material-ui/core';

const textColors = {
  opaque: theme => theme.palette.text.disabled,
  normal: theme => theme.palette.text.primary
};

export const useStyles = makeStyles(theme => ({
  editorContainer: {
    width: '100%',
    margin: theme.spacing(1.5, 0),
    color: ({ color }) =>
      (textColors[color] && textColors[color](theme)) || theme.palette.text.primary
  },
  errorText: {
    margin: theme.spacing(0, 0, 1)
  }
}));
