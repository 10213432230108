// @flow
import React from 'react';
import Chip, { ChipProps } from '@material-ui/core/Chip';

import { useStyles } from './styles';

type FPChipProps = ChipProps & {
  color: string,
  textColor: string,
  classes: Object,
  iconStyles: Object
};

const FPChip = ({ color, textColor, classes, iconStyles, ...rest }: FPChipProps) => {
  const chipClasses = useStyles({ color, textColor, iconStyles })();

  return <Chip classes={{ ...chipClasses, ...classes }} {...rest} />;
};

FPChip.defaultProps = {
  textColor: 'white',
  variant: 'default',
  classes: {},
  iconStyles: null
};

export default FPChip;
