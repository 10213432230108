// @flow
import React, { useState } from 'react';
import strings from 'strings';
import type { ProfileDataTableProps } from 'types/app';
import ProfileTableLayout from 'UI/components/organisms/inventoryProfiles/ProfileTableLayout';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { TabKeys } from 'UI/constants/entityTypes';

import { ChangeLogsContent } from './ChangeLogsContent';
import { ChangeLogsColumns } from './columns';

type ChangeLogsTabProps = {
  entityId: number,
  DataTableProps: ProfileDataTableProps,
  profileModule: string
};

const ChangeLogsTab = ({ DataTableProps, profileModule, entityId }: ChangeLogsTabProps) => {
  const [uiState, setUiState] = useState({
    isChangeLogOpen: false,
    selectedChangeLogId: null
  });

  const handleChangeLogClick = (changeLogId: number) => {
    setUiState({
      isChangeLogOpen: true,
      selectedChangeLogId: changeLogId
    });
  };

  const handleCloseDrawer = () =>
    setUiState({
      isChangeLogOpen: false,
      selectedChangeLogId: null
    });

  const finalActions = [
    {
      name: 'view',
      callback: handleChangeLogClick,
      pickId: true
    }
  ];

  const changeLogContentEndpoint =
    DataTableProps?.endpoint &&
    `${DataTableProps?.endpoint}/${uiState.selectedChangeLogId}?includes=vCreatedBy`;

  return (
    <>
      <ProfileTableLayout
        DataTableProps={DataTableProps}
        finalActions={finalActions}
        initialColumns={ChangeLogsColumns}
        orderByOptions={{ column: 'created_at', direction: 'desc' }}
        profileModule={profileModule}
        search={false}
        tabKey={TabKeys.ChangeLogs}
      />
      {entityId && (
        <DrawerContentLayout
          drawerProps={{
            open: uiState.isChangeLogOpen
          }}
          footerActionsProps={{ hidePrimaryButton: true }}
          isTopToolbarNeeded
          onClose={handleCloseDrawer}
          onSecondaryButtonClick={handleCloseDrawer}
          title={strings.inventoryProfiles.sections.tabs.changeLogs.sectionName}
          uiState={uiState}
          variant="borderless"
        >
          <div role="presentation">
            {uiState.selectedChangeLogId && (
              <ChangeLogsContent endpoint={changeLogContentEndpoint} />
            )}
          </div>
        </DrawerContentLayout>
      )}
    </>
  );
};

export default ChangeLogsTab;
