import * as React from 'react';

const SvgCircleArrowRight = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm5 13.36L9.62 19a1.26 1.26 0 11-1.53-2l6.28-4.81a.27.27 0 000-.4L8.09 7a1.26 1.26 0 111.53-2L17 10.64a1.72 1.72 0 010 2.72z" />
    </svg>
  );
};

export default SvgCircleArrowRight;
