// @flow
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Skeleton from '@material-ui/lab/Skeleton';
import FPIcon from 'UI/components/atoms/FPIcon';
import FPButtonMenu from 'UI/components/molecules/FPButtonMenu';
import { DateFormats } from 'UI/constants/defaults';
import { AdditionalRecruiterType } from 'UI/constants/status';
import { SvgCalendar } from 'UI/res';
import { getId, nestTernary, toLocalTime } from 'UI/utils';

import { useStyles } from './styles';

type HistoryItemProps = {
  date: string,
  dateFormat?: any,
  creator: string,
  recruiter: string | null,
  otherRecruiter: string | null,
  isLoading: boolean,
  action: any,
  type?: string,
  menuOptions?: any,
  extraData?: any
};

const HistoryItem = ({
  action,
  creator,
  date,
  dateFormat,
  extraData,
  isLoading,
  menuOptions,
  otherRecruiter,
  recruiter,
  type
}: HistoryItemProps) => {
  const classes = useStyles();
  const localTime = toLocalTime(date);
  const formattedDate = localTime && localTime.format(dateFormat);

  return (
    <ListItem disableGutters>
      <ListItemIcon className={classes.fileIcon}>
        {isLoading ? (
          <Skeleton variant="circle" width={24} height={24} />
        ) : (
          <FPIcon component={SvgCalendar} size="sm" onSetColor={palette => palette.grey[600]} />
        )}
      </ListItemIcon>
      <ListItemText
        primary={isLoading ? <Skeleton width="40%" /> : formattedDate}
        secondary={
          isLoading ? (
            <Skeleton width="60%" />
          ) : (
            nestTernary(
              type === 'custom',
              action,
              getHistoryDescription(type, action, creator, recruiter, otherRecruiter, extraData)
            )
          )
        }
        className={classes.root}
      />
      {menuOptions && (
        <ListItemSecondaryAction>
          <FPButtonMenu isIconButton menuItems={menuOptions} className={classes.moreButton} />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

const getHistoryDescription = (type, action, creator, recruiter, otherRecruiter, extraData) => {
  const actions = {
    [AdditionalRecruiterType.Accountable]: getDescriptionForAccountable(action, creator, recruiter),
    [AdditionalRecruiterType.Collaborator]: getDescriptionForCollaborator(
      action,
      creator,
      recruiter,
      otherRecruiter
    ),
    sendout: getDescriptionForSendout(action, extraData)
  };

  return type === AdditionalRecruiterType.Main ||
    (type === AdditionalRecruiterType.Accountable && action === 'assign' && creator !== recruiter)
    ? getDescriptionForAssignment(creator, recruiter)
    : actions[type] || getDescriptionDefault(creator);
};

const getDescriptionDefault = creator => {
  return (
    <>
      <b>{creator}</b> created this item
    </>
  );
};

const getDescriptionForAssignment = (creator, recruiter) => {
  return (
    <>
      <b>{creator}</b> assigned this item to <b>{recruiter}</b>
    </>
  );
};

const getDescriptionForAccountable = (action, creator, recruiter) => {
  return action === 'assign' ? (
    <>
      <b>{recruiter}</b> is now working this item
    </>
  ) : (
    <>
      <b>{creator}</b> removed assignment to <b>{recruiter}</b>
    </>
  );
};

const getDescriptionForCollaborator = (action, creator, recruiter, otherRecruiter) => {
  if (creator !== otherRecruiter) {
    return action === 'assign' ? (
      <>
        <b>{creator}</b> assigned <b>{recruiter}</b> to collaborate with <b>{otherRecruiter}</b>
      </>
    ) : (
      <>
        <b>{creator}</b> removed <b>{recruiter}</b> from collaborating with <b>{otherRecruiter}</b>
      </>
    );
  }
  return action === 'assign' ? (
    <>
      <b>{creator}</b> is now collaborating with <b>{recruiter}</b>
    </>
  ) : (
    <>
      <b>{creator}</b> is no longer collaborating with <b>{recruiter}</b>
    </>
  );
};

const getDescriptionForSendout = (action, extraData) => {
  return (
    <>
      <b>{action}</b>
      {extraData && (
        <ul>
          {extraData.map(item => (
            <li key={getId()}>{item}</li>
          ))}
        </ul>
      )}
    </>
  );
};

HistoryItem.defaultProps = {
  date: '',
  dateFormat: DateFormats.SimpleDateTime,
  action: 'assign',
  type: 'main',
  creator: '',
  recruiter: '',
  otherRecruiter: '',
  isLoading: false,
  menuOptions: undefined,
  extraData: undefined
};

export default HistoryItem;
