import * as React from "react";

const SvgInfoCardFilled = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M24 6.25a2 2 0 00-2-2h-6a.5.5 0 00-.5.5v1.5a.5.5 0 01-1 0v-2.5a3 3 0 00-6 0v2.5a.5.5 0 01-1 0v-1.5a.5.5 0 00-.5-.5H2a2 2 0 00-2 2v15a2 2 0 002 2h20a2 2 0 002-2zM10.5 3.5a1 1 0 111 1 1 1 0 01-1-1zm-8 17.75a.5.5 0 01-.5-.5v-10.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v10.5a.5.5 0 01-.5.5z" />
      <path d="M19.25 13.5H14.5a.75.75 0 000 1.5h4.75a.75.75 0 000-1.5zM18 16.5h-3.5a.75.75 0 000 1.5H18a.75.75 0 000-1.5zM9.46 16.62a.89.89 0 010-.39 4 4 0 00.86-2.77 2.29 2.29 0 10-4.58 0 4 4 0 00.83 2.73c0 .09 0 .35.07.38-1.54.58-2.39 1-2.73 1.71a4.08 4.08 0 00-.37 1.15.47.47 0 00.11.41A.49.49 0 004 20h8a.49.49 0 00.38-.18.47.47 0 00.11-.41 4.08 4.08 0 00-.37-1.15c-.34-.69-1.19-1.11-2.66-1.64z" />
    </svg>
  );
};

export default SvgInfoCardFilled;
