import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormHelperText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import strings from 'strings';
import CustomDatePicker from 'UI/components/atoms/CustomDatePicker';
import { SharedKeyboardTimePickerProps } from 'UI/components/organisms/sendouts/SendoutsInterviews/utils';

import { INTERVIEW_TYPE, INTERVIEW_TYPES } from './ScheduleCalls.constants';
import { useStyles } from './ScheduleCalls.styles';

const FIELD_PRFIXES = {
  InterviewDate: 'InterviewDate',
  InterviewTime: 'InterviewTime',
  DateValidation: 'DateValidation'
};

const FormFieldsMap = Object.fromEntries(
  INTERVIEW_TYPES.flatMap(type => [
    [
      `${type}${FIELD_PRFIXES.InterviewDate}`,
      { key: `${type.toLowerCase()}${FIELD_PRFIXES.InterviewDate}` }
    ],
    [
      `${type}${FIELD_PRFIXES.InterviewTime}`,
      { key: `${type.toLowerCase()}${FIELD_PRFIXES.InterviewTime}` }
    ]
  ])
);

export const ScheduleCalls = () => {
  const classes = useStyles();
  const { errors, register, unregister, watch, setValue, getValues, triggerValidation } =
    useFormContext();

  const formValues = watch();

  useEffect(() => {
    INTERVIEW_TYPES.forEach(type => {
      register(FormFieldsMap[`${type}${FIELD_PRFIXES.InterviewDate}`].key, {
        validate(date) {
          const time = getValues()[FormFieldsMap[`${type}${FIELD_PRFIXES.InterviewTime}`].key];
          const incompleteSelection = (date && !time) || (time && !date);
          return !incompleteSelection || 'Select a date and time for the interview';
        }
      });
      register(FormFieldsMap[`${type}${FIELD_PRFIXES.InterviewTime}`].key);
      register(FIELD_PRFIXES.DateValidation, {
        validate() {
          const {
            hiringauthorityInterviewDate,
            hiringauthorityInterviewTime,
            candidateInterviewDate,
            candidateInterviewTime
          } = getValues();

          const atLeastOneTimestamp =
            (hiringauthorityInterviewDate && hiringauthorityInterviewTime) ||
            (candidateInterviewDate && candidateInterviewTime);

          return atLeastOneTimestamp || 'Select at least one interview date and time';
        }
      });
    });

    return () => {
      INTERVIEW_TYPES.forEach(type => {
        unregister(FormFieldsMap[`${type}${FIELD_PRFIXES.InterviewDate}`].key);
        unregister(FormFieldsMap[`${type}${FIELD_PRFIXES.InterviewTime}`].key);
      });
    };
  }, [register, unregister, getValues]);

  const handleDateChange = (name, value) => {
    setValue(name, value ? moment(value) : null, { shouldValidate: true });
    triggerValidation();
  };

  return (
    <div>
      <Typography className={classes.formSectionTitle}>Schedule Calls</Typography>
      <Grid container className={classes.fieldsContainer}>
        {INTERVIEW_TYPES.map(type => (
          <div key={type} className={classes.fieldsSubcontainer}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {type === INTERVIEW_TYPE.HiringAuthority
                  ? strings.shared.fields.hiringAuthority
                  : type}
              </Typography>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <CustomDatePicker
                  disablePast
                  isClearable
                  error={!!errors[FormFieldsMap[`${type}${FIELD_PRFIXES.InterviewDate}`].key]}
                  helperText={
                    errors[FormFieldsMap[`${type}${FIELD_PRFIXES.InterviewDate}`].key]?.message
                  }
                  label="Date *"
                  name={FormFieldsMap[`${type}${FIELD_PRFIXES.InterviewDate}`].key}
                  value={
                    formValues[FormFieldsMap[`${type}${FIELD_PRFIXES.InterviewDate}`].key] || null
                  }
                  onDateChange={handleDateChange}
                  placeholder="Date *"
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDatePicker
                  disablePast
                  isClearable
                  error={!!errors[FormFieldsMap[`${type}${FIELD_PRFIXES.InterviewTime}`].key]}
                  helperText={
                    errors[FormFieldsMap[`${type}${FIELD_PRFIXES.InterviewTime}`].key]?.message
                  }
                  label="Time *"
                  name={FormFieldsMap[`${type}${FIELD_PRFIXES.InterviewTime}`].key}
                  value={
                    formValues[FormFieldsMap[`${type}${FIELD_PRFIXES.InterviewTime}`].key] || null
                  }
                  onDateChange={handleDateChange}
                  variant="keyboardTime"
                  withTime
                  {...SharedKeyboardTimePickerProps}
                />
              </Grid>
            </Grid>
          </div>
        ))}
        <Grid item xs={12}>
          {errors[FIELD_PRFIXES.DateValidation]?.message && (
            <FormHelperText error>{errors[FIELD_PRFIXES.DateValidation]?.message}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
