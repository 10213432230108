import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative',
      flexGrow: 1
    },
    title: {
      marginBottom: theme.spacing(2)
    },
    loader: {
      margin: 'auto'
    }
  }),
  { classNamePrefix: 'spActivityLogs' }
);
