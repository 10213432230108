// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import EmailPicker from 'UI/components/molecules/EmailPicker';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import { Endpoints } from 'UI/constants/endpoints';
import { PaymentMode } from 'UI/constants/entityTypes';
import { REQUIRED_VALIDATION, VALIDATION_REGEXS } from 'UI/utils/index';

import { useStyles } from '../styles';

const accountsPayableEmailKey = 'accounts_payable_email';
const additionalRecipientsKey = 'additional_invoice_recipients';
const paymentTermsKey = 'payment_terms_id';
const showCompensationKey = 'show_compensation_invoice';
const paymentSchemeKey = 'fee_agreement_payment_scheme_id';

const fields = [additionalRecipientsKey, showCompensationKey];

export type PlacementAdditionalDetailsProps = {
  readOnly?: boolean,
  fixedEmails?: string
};

const PlacementAdditionalDetails = ({ readOnly, fixedEmails, company }: company) => {
  const classes = useStyles();

  const { register, unregister, setValue, watch, getValues, errors } = useFormContext();
  const {
    [accountsPayableEmailKey]: accountsPayableEmail,
    [additionalRecipientsKey]: additionalInvoiceRecipients,
    [paymentTermsKey]: paymentTerms,
    [paymentSchemeKey]: paymentMode,
    [showCompensationKey]: showCompensation
  } = watch();

  useEffect(() => {
    fields.forEach(field => register(field));

    return () => {
      fields.forEach(field => unregister(field));
    };
  }, [register, unregister]);

  useEffect(() => {
    register(
      { name: paymentTermsKey },
      {
        validate(value) {
          const payMode = getValues(paymentSchemeKey);

          return (
            (payMode !== PaymentMode.Conversion && value) ||
            payMode === PaymentMode.Conversion ||
            REQUIRED_VALIDATION.required
          );
        }
      }
    );
    return () => unregister(paymentTermsKey);
  }, [register, unregister, getValues]);

  useEffect(() => {
    company?.accounts_payable_email &&
      setValue(accountsPayableEmailKey, company.accounts_payable_email);
  }, [company, setValue]);

  const handleFieldChange = (name?: string, value: any) => setValue(name, value, true);
  const handleToggleCheck = (event: any, value?: any) => {
    const {
      target: { name }
    } = event;

    handleFieldChange(name, value);
  };

  return (
    <NumberedForm.Item title="Additional Details">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextBox
            name={accountsPayableEmailKey}
            value={accountsPayableEmail}
            label="Account payable email *"
            inputRef={register({
              ...REQUIRED_VALIDATION,
              pattern: {
                value: VALIDATION_REGEXS.EMAIL,
                message: strings.shared.validations.invalid
              }
            })}
            error={!!errors[accountsPayableEmailKey]}
            errorText={errors[accountsPayableEmailKey] && errors[accountsPayableEmailKey].message}
            noMargin
          />
        </Grid>
        <Grid item xs={12}>
          <EmailPicker
            name={additionalRecipientsKey}
            placeholder="Additional Invoice Recipients"
            emailKey=""
            selectedValue={additionalInvoiceRecipients || []}
            allowNewItems
            onSelect={handleFieldChange}
            maxItems={-1}
            className={classes.input}
            disabled={readOnly}
          />
          {fixedEmails && (
            <FormHelperText data-testid="added-fixed-email-label">
              Automatic copies will be added for <b>{fixedEmails}</b>
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <AutocompleteSelect
            name={paymentTermsKey}
            selectedValue={paymentTerms}
            placeholder={`Payment Terms ${paymentMode !== PaymentMode.Conversion ? '*' : ''}`}
            onSelect={handleFieldChange}
            className={classes.input}
            disabled={readOnly || paymentMode === PaymentMode.Conversion}
            url={Endpoints.PaymentTerms}
            error={!!errors[paymentTermsKey]}
            errorText={errors[paymentTermsKey] && errors[paymentTermsKey].message}
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <FormControlLabel
              value="end"
              control={
                <Switch
                  id={showCompensationKey}
                  name={showCompensationKey}
                  onChange={handleToggleCheck}
                  checked={showCompensation || false}
                  color="primary"
                />
              }
              label="Show Compensation on Invoice"
              labelPlacement="end"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <TextBox
            name="payment_details"
            label="Payment details"
            inputRef={register()}
            multiline
            disabled={readOnly}
            className={classes.input}
          />
        </Grid>
        <Grid item xs={12}>
          <TextBox
            name="notes"
            label="Notes"
            inputRef={register()}
            multiline
            disabled={readOnly}
            className={classes.input}
          />
        </Grid>
      </Grid>
      <></>
    </NumberedForm.Item>
  );
};

PlacementAdditionalDetails.defaultProps = {
  readOnly: false,
  fixedEmails: null
};

export default PlacementAdditionalDetails;
