import React from 'react';
import { useDrawerManager } from 'features/command-center/contexts';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';

import { DRAWERS_CONFIG } from './Drawers.constants';
import { useDrawerStyles } from './Drawers.styled';

export const Drawers = ({ prefixTitle = '', suffixTitle = '' }) => {
  const drawerManager = useDrawerManager();
  const { drawerLarge, drawerContent } = useDrawerStyles();

  if (!Object.keys(DRAWERS_CONFIG).includes(drawerManager.drawerState.activeDrawer)) {
    return null;
  }

  const drawerConfig = DRAWERS_CONFIG[drawerManager.drawerState.activeDrawer];
  const Component = drawerConfig?.component;
  const title = `${prefixTitle ? `${prefixTitle} ` : ''}${
    drawerConfig?.title || ''
  } ${suffixTitle}`;

  return (
    <DrawerContentLayout
      title={title}
      subheader={drawerConfig?.subHeader}
      footerActionsProps={{
        hidePrimaryButton: true
      }}
      drawerProps={{
        open: drawerManager.drawerState.isOpen,
        classes: { paper: drawerLarge }
      }}
      contentProps={{ className: drawerContent }}
      onClose={drawerManager.closeDrawer}
    >
      <Component {...drawerManager.drawerState.contentProps} />
    </DrawerContentLayout>
  );
};
