// @flow
import Hashids from 'hashids';

const hashids = new Hashids(
  process.env.REACT_APP_ENCRYPT_SALT,
  Number(process.env.REACT_APP_ENCRYPT_PADDING)
);

export const encryptId = (id: number) => hashids.encode(id);

export const decryptId = (hash: string, throwErr: boolean = false) => {
  const decoded = hashids.decode(hash);
  if (throwErr && decoded.length === 0) {
    const error = new Error('Invalid reference passed: The reference was changed or modified.');
    error.name = 'Invalid hash';
    throw error;
  } else {
    return decoded;
  }
};
