const radius = 24;
export const SHARED_SKELETONS_PROPS = {
  text: {
    width: 150,
    variant: 'text',
    radius: 20
  },
  buttons: {
    width: 125,
    radius
  },
  chips: {
    width: 100,
    radius
  }
};
