// @flow
import React from 'react';

import Content from './Content';

const FeeAgreementsBoard = props => {
  return <Content {...props} />;
};

export default FeeAgreementsBoard;
