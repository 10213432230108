import * as React from 'react';

const SvgQuestionCircle = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path
        d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm0 19a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0112 19zm1.6-6.08a1 1 0 00-.6.92 1 1 0 01-2 0 3 3 0 011.8-2.75A2 2 0 1010 9.25a1 1 0 01-2 0 4 4 0 115.6 3.67z"
        fill={fill}
      />
    </svg>
  );
};

export default SvgQuestionCircle;
