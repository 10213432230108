import React, { useCallback, useMemo, useState } from 'react';
import { Chart } from 'react-google-charts';
import { DRAWER_KEYS, Widget } from 'features/command-center';
import { COMMAND_CENTER_BASE_URL } from 'features/command-center/constants';
import { useDrawerManager } from 'features/command-center/contexts';
import { getActivityHistory } from 'features/command-center/services';
import moment from 'moment';
import strings from 'strings';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { DateFormats } from 'UI/constants/defaults';
import { createCustomStaticRanges, getYearToDate } from 'UI/utils';

import { DateRange } from '../../shared/InlineFilters/components/DateRange';

import { DEFAULT_OPTIONS, formatResponse } from './GoalsProgressInTime.utils';

const STRINGS = strings.commandCenter.activity.goalsProgressInTime;
const defaultRange = getYearToDate();
const URL_INDEX_ACCORDING_VALUE = 1;

export const GoalsProgressInTime = ({ requestParams = {}, hasDrillDown }) => {
  const { openDrawer } = useDrawerManager();
  const [dateRange, setDateRange] = useState(defaultRange);

  const queryDateRange = useMemo(
    () => ({
      startDate: moment(dateRange.startDate).format(DateFormats.QueryShortFormat),
      endDate: moment(dateRange.endDate).format(DateFormats.QueryShortFormat)
    }),
    [dateRange.startDate, dateRange.endDate]
  );

  const handleDateRangeChange = selectedDateRange => setDateRange(selectedDateRange);

  const promises = useCallback(() => {
    return Promise.all([
      getActivityHistory({
        groupBy: 'month',
        metrics: 'inMails,bulks,peopleReached,calls',
        resultType: 'percentage',
        ...queryDateRange,
        ...requestParams
      })
    ]).then(([data]) => data);
  }, [queryDateRange, requestParams]);

  const cachedPromises = useMemo(() => [promises], [promises]);

  return (
    <Widget header={STRINGS.title} promises={cachedPromises}>
      {([response]) => {
        const data = formatResponse(response);

        const handlePointClick = (clickedData, row, column) => {
          const url = clickedData[column + URL_INDEX_ACCORDING_VALUE];
          url &&
            typeof url === 'string' &&
            openDrawer({
              activeDrawer: DRAWER_KEYS.Calls,
              contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
            });
        };

        const chartEvents = [
          {
            eventName: 'select',
            callback: ({ chartWrapper }) => {
              const selection = chartWrapper.getChart().getSelection();
              if (selection.length === 0) return;

              const [selectedItem] = selection;
              const { row, column } = selectedItem;

              if (row !== undefined && column !== undefined) {
                const clickedData = data[row + 1]; // +1 beacause the first row is headers
                handlePointClick(clickedData, row, column);
              }
            }
          }
        ];

        return (
          <>
            <DateRange
              DateRangerPickerProps={{
                staticRanges: createCustomStaticRanges(additionalPeriodRanges)
              }}
              onPeriodChange={handleDateRangeChange}
              defaultRange={dateRange}
            />

            <Chart
              chartEvents={hasDrillDown && chartEvents}
              chartType="LineChart"
              width="100%"
              height="400px"
              data={data}
              options={DEFAULT_OPTIONS}
            />
          </>
        );
      }}
    </Widget>
  );
};
