import { makeStyles } from '@material-ui/core/styles';
import { globalStyles } from 'GlobalStyles';
import {
  mainLayoutPadding,
  sidebarItem,
  sideBarWidth,
  sideBarWidthCollapsed
} from 'UI/constants/dimensions';
import {
  flexAlignCenter,
  flexAlignCenterFlexStart,
  flexAlignCenterJustifyCenter
} from 'UI/utils/styles';

const sidebarActionButtonWrapperHeight = 120;

export const useSidebarStyles = makeStyles(theme => ({
  accordionRoot: {
    width: ({ isSidebarOpen }) => (isSidebarOpen ? sideBarWidth : sideBarWidthCollapsed),
    transition: `all ${globalStyles.transition}`,

    '&:before': {
      top: 0
    }
  },
  accordionExpanded: {
    margin: '0 !important'
  },
  menuBox: {
    ...theme.sidebarItem,
    ...flexAlignCenterFlexStart,
    flexWrap: 'nowrap',
    backgroundColor: theme.sidebarMenu.backgroundColor,
    transition: 'all 0.5s',
    '&:hover': {
      backgroundColor: theme.palette.grey[300]
    }
  },
  accordionSummaryExpanded: {
    backgroundColor: theme.palette.grey[300],
    fontWeight: 700
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0)
  },
  menuContent: {
    ...flexAlignCenter,
    cursor: 'pointer',
    flexWrap: 'nowrap',
    overflow: 'hidden'
  },
  subMenuBox: {
    ...theme.sidebarItem,
    ...flexAlignCenterFlexStart,
    flexWrap: 'nowrap',
    backgroundColor: theme.palette.grey[50],
    transition: 'all 0.5s',
    '&:hover': {
      backgroundColor: theme.palette.grey[300]
    }
  },
  subMenu: {
    backgroundColor: theme.palette.grey[200],
    paddingLeft: ({ isSidebarOpen }) =>
      isSidebarOpen ? sidebarItem.paddingSubmenu : sidebarItem.padding,
    transition: `padding ${globalStyles.transition}, background-color 0.5s`
  },
  itemSelected: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: 700,
    '& > svg': {
      color: `${theme.palette.primary.main} !important`
    }
  },
  collapseMenuSelected: {
    fontWeight: 700
  },
  label: {
    marginLeft: theme.spacing(2),
    opacity: ({ isSidebarOpen }) => (isSidebarOpen ? 1 : 0),
    transition: `opacity ${globalStyles.transition}`
  }
}));

export const useTooltipStyles = makeStyles({
  popper: {
    display: ({ isSidebarOpen }) => (isSidebarOpen ? 'none' : 'block')
  }
});

export const useStyles = makeStyles(theme => ({
  childrenWrapper: {
    ...flexAlignCenterJustifyCenter,
    padding: theme.spacing(mainLayoutPadding, 0, 4)
  },
  nav: {
    overflow: 'auto',
    overflowX: 'hidden',
    width: '100%',
    height: `calc(100% - ${sidebarActionButtonWrapperHeight}px)`,
    fontWeight: theme.typography.fontWeightLight,
    fontFamily: theme.typography.fontFamilyContent,
    paddingBottom: theme.spacing(2),

    '&::-webkit-scrollbar': {
      width: theme.spacing(1)
    }
  },
  nested: {
    padding: theme.spacing(4)
  }
}));
