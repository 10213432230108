import React from 'react';
import { Marker } from 'react-map-gl';

const MarkerList = ({ markers, isDigActive, renderMapMarker }) => (
  <>
    {markers.map(marker => {
      if (!marker.longitude || !marker.latitude) return null;

      return (
        <div key={`marker-${isDigActive ? marker.state ?? marker?.title : marker.id}`}>
          <Marker
            longitude={marker.longitude}
            latitude={marker.latitude}
            offsetLeft={-20}
            offsetTop={-20}
            className={isDigActive ? '' : 'mapboxgl-marker-inventory'}
          >
            {renderMapMarker(marker)}
          </Marker>
        </div>
      );
    })}
  </>
);

export default MarkerList;
