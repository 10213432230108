// @flow
import { makeActionCreator } from './index';

export const SHOW_REFERENCE_RELEASE_COMPOSER = 'SHOW_REFERENCE_COMPOSER';
export const HIDE_REFERENCE_RELEASE_COMPOSER = 'HIDE_REFERENCE_RELEASE_COMPOSER';

export const showReferenceReleaseComposer = makeActionCreator(
  SHOW_REFERENCE_RELEASE_COMPOSER,
  'payload'
);
export const hideReferenceReleaseComposer = makeActionCreator(HIDE_REFERENCE_RELEASE_COMPOSER);
