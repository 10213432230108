import { styled } from '@material-ui/core';

const LiItem = styled('li')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  paddingLeft: '1rem',
  paddingBottom: theme.typography.pxToRem(20),
  gap: '0.25rem',
  borderLeft: `1px solid ${theme.palette.primary.light}`,
  color: theme.palette.grey[600],

  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '-4px',
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.light
  },

  '& .MuiTypography-subtitle1': {
    marginTop: theme.typography.pxToRem(-6)
  }
}));

export default LiItem;
