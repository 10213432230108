import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SendIcon from '@material-ui/icons/Send';
import { useUserContext } from 'providers/UserProvider';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { When } from 'UI/components/atoms/When';

import { useChatContext } from '../ChatDrawerProvider';

import { AttachmentUploader } from './AttachmentUploader';
import {
  AttachmentActionButtonContainer,
  AttachmentChip,
  ComposerContainer,
  MessageComposerContainer,
  MessageInput,
  MessageInputWrapper,
  SendActionButtonContainer
} from './Message.styled';

export const MessageComposer = ({
  disabled,
  entityId,
  entityType,
  externalNumber,
  onClickSend,
  recipientFullName
}) => {
  const [message, setMessage] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [currentUser] = useUserContext();

  const senderPhone = currentUser?.personalInformation?.contact?.phone
    ? `+1${currentUser?.personalInformation?.contact?.phone}`
    : null;

  const { recipient, recipientPhoneWithCode } = useChatContext();

  const recipientPhone = externalNumber ?? recipientPhoneWithCode;

  const contact = {
    entityId: entityId ?? recipient?.entityId,
    entityType: entityType ?? recipient?.entityType,
    fullName: recipientFullName ?? recipient?.name
  };

  const handleAttachmentClick = (_name, value) => {
    setAttachment(value);
  };

  const handleChangeText = (name, text) => {
    setMessage(text);
  };

  const handleClickSend = () => {
    const finalMessage = message.trim();
    if (!finalMessage && !attachment) return;
    onClickSend({
      message: finalMessage,
      attachment: { url: attachment?.url, size: attachment?.size_in_bytes },
      from: senderPhone,
      to: recipientPhone,
      contact
    });
    setMessage('');
    setAttachment(null);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleClickSend();
    }
  };

  return (
    <MessageComposerContainer>
      <ComposerContainer>
        <AttachmentActionButtonContainer>
          <AttachmentUploader disabled={disabled} onClick={handleAttachmentClick} />
        </AttachmentActionButtonContainer>

        <MessageInputWrapper>
          <MessageInput
            disabled={disabled}
            fullWidth
            multiline
            onChange={handleChangeText}
            onKeyDown={handleKeyDown}
            placeholder={strings.messageCenter.messageComposer.placeholder}
            rows={1}
            rowsMax={5}
            value={message}
          />
        </MessageInputWrapper>

        <SendActionButtonContainer>
          <FPIconButton
            onClick={handleClickSend}
            tooltipProps={{
              title: strings.messageCenter.messageComposer.tooltip
            }}
          >
            <SendIcon color="primary" />
          </FPIconButton>
        </SendActionButtonContainer>
      </ComposerContainer>

      <When condition={attachment}>
        <div>
          <AttachmentChip
            icon={<InsertDriveFileIcon />}
            label={attachment?.file_name}
            onDelete={() => setAttachment(null)}
            color="secondary"
            clickable={false}
            deleteIcon={<CloseIcon />}
          />
        </div>
      </When>
    </MessageComposerContainer>
  );
};
