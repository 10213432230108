import qs from 'query-string';
import API from 'services/API';
import {
  ARCHIVE_TEMPLATES_FOLDER_NAME,
  MY_TEMPLATES_FOLDER_ID,
  TEMPLATE_ENTITY_TYPES
} from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag } from 'UI/utils';

export const isFolder = entity =>
  entity === TEMPLATE_ENTITY_TYPES.Folder || entity === TEMPLATE_ENTITY_TYPES.Root;
export const parseNodeId = nodeId => nodeId.split('-');

/**
 *
 * @param {Object} response
 * @param {boolean} [shouldHideArchiveFolder=false]
 * @returns
 */
export const filterArchiveFromTemplatesResponse = (response, shouldHideArchiveFolder) =>
  hasFeatureFlag(FeatureFlags.BulkEmailArchiveTemplates) && !shouldHideArchiveFolder
    ? response
    : {
        ...response,
        data: response.data.filter(
          item =>
            item.parentFolderId !== MY_TEMPLATES_FOLDER_ID ||
            item.name !== ARCHIVE_TEMPLATES_FOLDER_NAME
        )
      };

export const nodeObjectMapper = ({ id, ...restProps }) => ({
  id: parseNodeId(id)[1],
  ...restProps
});

/**
 * @typedef {Object} GetTemplatesQueryTypeDef
 * @property {number|string} parentFolderId
 * @property {boolean} [excludeAllTemplates]
 */

/**
 * @param {GetTemplatesQueryTypeDef} query
 * @returns {String}
 */
export const buildGetTemplatesEndpoint = query =>
  qs.stringifyUrl({ url: Endpoints.BulkEmailsTemplates, query });

/**
 * @typedef GetChildrenTypeDef
 * @property {boolean} shouldHideArchiveFolder
 * @property {GetTemplatesQueryTypeDef} queryParams
 */

/**
 *
 * @param {GetChildrenTypeDef} param0
 * @returns {Object}
 */
export const getChildren = async ({ shouldHideArchiveFolder, queryParams }) => {
  const response = await API.get(buildGetTemplatesEndpoint(queryParams), {
    apiVersion: 2
  });

  return filterArchiveFromTemplatesResponse(response, shouldHideArchiveFolder);
};

export const buildTreeViewItemId = (entity, id) => (!entity || !id ? '' : `${entity}-${id}`);
