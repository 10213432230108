// @flow

import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import { globalStyles } from 'GlobalStyles';
import type { Map } from 'types';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import { Endpoints } from 'UI/constants/endpoints';

type HiringAuthorityCreateFormProps = {
  specialtyId: number
};

const chainedSelects = {
  hiring_authority_id: ['specialty_id', 'subspecialty_id', 'position_id'],
  specialty_id: ['subspecialty_id', 'position_id']
};

const fields = [
  'specialty',
  'subspecialty',
  'position',
  'specialty_id',
  'subspecialty_id',
  'position_id'
];

const HiringAuthoritySpecialtyForm = ({ specialtyId }: HiringAuthorityCreateFormProps) => {
  const [comboValues, setComboValues] = useState<Map>({});
  const [hasSubspecialties, setHasSubspecialties] = useState(false);
  const { register, unregister, errors, setValue, clearError } = useFormContext();

  useEffect(() => {
    setValue('specialty', comboValues.specialty_id);
    setValue('subspecialty', comboValues.subspecialty_id);
    setValue('position', comboValues.position_id);
  }, [comboValues, setValue]);

  useEffect(() => {
    if (hasSubspecialties) {
      register({ name: 'subspecialty_id' }, { required: 'Subspecialty is required' });
    } else {
      register({ name: 'subspecialty_id' });
    }
  }, [register, hasSubspecialties]);

  useEffect(() => {
    if (!specialtyId) {
      register({ name: 'specialty' });
      register({ name: 'subspecialty' });
      register({ name: 'position' });
      register({ name: 'specialty_id' }, { required: 'Industry: Specialty is required' });
      register({ name: 'position_id' }, { required: 'Functional title is required' });
    } else {
      unregister('specialty_id');
      unregister('subspecialty_id');
      unregister('position_id');
    }
    return () => {
      fields.forEach(field => unregister(field));
    };
  }, [register, unregister, specialtyId]);

  const handleComboChange = (name?: string, value: any) => {
    setComboValues((prevState: Map): Map => ({ ...prevState, [name]: value }));
    setValue(name, value ? value.id : value, true);

    if (name && chainedSelects[name]) {
      chainedSelects[name].forEach(chainedSelect => {
        setComboValues((prevState: Map): Map => ({
          ...prevState,
          [chainedSelect]: null
        }));
        setValue(chainedSelect, null);
        clearError(chainedSelect);
      });
    }
  };

  const handleSubspecialtiesLoaded = useCallback((options?: any[]) => {
    setHasSubspecialties(options && options.length);
  }, []);

  return (
    <div style={globalStyles.inputMinSpacing}>
      <p>Register the required data to add this Hiring Authority</p>
      <Box mt={4}>
        <AutocompleteSelect
          name="specialty_id"
          selectedValue={comboValues.specialty_id}
          placeholder="Industry: Specialty *"
          error={!!errors.specialty_id}
          errorText={errors.specialty_id && errors.specialty_id.message}
          url={Endpoints.Specialties}
          groupBy={option => option.industry_title}
          onSelect={handleComboChange}
        />
        <AutocompleteSelect
          name="subspecialty_id"
          selectedValue={comboValues.subspecialty_id}
          placeholder={`Subspecialty ${hasSubspecialties ? '*' : ''}`}
          error={!!errors.subspecialty_id}
          errorText={errors.subspecialty_id && errors.subspecialty_id.message}
          url={
            comboValues.specialty_id
              ? `${Endpoints.Specialties}/${comboValues.specialty_id.id}/subspecialties`
              : ''
          }
          onSelect={handleComboChange}
          onOptionsLoaded={handleSubspecialtiesLoaded}
        />
        <AutocompleteSelect
          name="position_id"
          selectedValue={comboValues.position_id}
          placeholder="Functional title *"
          error={!!errors.position_id}
          errorText={errors.position_id && errors.position_id.message}
          url={
            comboValues.specialty_id
              ? `${Endpoints.Positions}?specialtyId=${comboValues.specialty_id.id}`
              : ''
          }
          onSelect={handleComboChange}
        />
      </Box>
    </div>
  );
};

HiringAuthoritySpecialtyForm.defaultProps = {
  specialtyId: null
};

export default HiringAuthoritySpecialtyForm;
