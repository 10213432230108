import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';
import { container, navBarHeight } from 'UI/constants/dimensions';
import { colors } from 'UI/res';

const HEIGHT = THEME.spacing(10);

export const useStyles = makeStyles(theme => ({
  contentContainer: {
    backgroundColor: colors.sideBar,
    height: `calc(100vh - ${navBarHeight}px)`,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  headerContainer: {
    backgroundColor: colors.sideBar,
    borderRadius: 0,
    boxShadow: container.boxShadow,
    height: HEIGHT,
    padding: theme.spacing(2),
    position: 'sticky',
    top: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    marginLeft: theme.spacing(2)
  },
  chatContainer: {
    flexGrow: 1,
    overflowY: 'auto',
    position: 'relative'
  },
  messageComposerContainer: {
    boxShadow: container.boxShadow,
    position: 'sticky',
    bottom: 0
  }
}));
