// @flow
import React, { useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import clsx from 'clsx';
import API from 'services/API';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import FPAvatar from 'UI/components/atoms/FPAvatar';
import InventoryInfo from 'UI/components/molecules/MapInventoryItem/InventoryInfo';
import { Endpoints } from 'UI/constants/endpoints';
import { colors, CopyIcon } from 'UI/res';
import { getInitials } from 'UI/utils/getinitials';

import { useStyles } from './styles';

type LastActivity = {
  title: string,
  date: string
};

type InventoryPopoverProps = {
  description: string,
  entityType: string,
  id: number,
  initials: string,
  markerType: number,
  onQuickViewClick: () => void,
  onCopyClick: (text: string) => void,
  recruiterEmail?: string,
  recruiterName?: string,
  SearchProjectAction: any,
  subtitle: string,
  title: string,
  type: string
};

const InventoryPopover = ({
  id,
  recruiterName,
  type,
  title,
  subtitle,
  description,
  recruiterEmail,
  entityType,
  markerType,
  initials,
  onQuickViewClick,
  onCopyClick,
  SearchProjectAction
}: InventoryPopoverProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [lastActivity, setLastActivity] = useState<LastActivity | null>(null);

  const classes = useStyles();

  const initialsOrDefault = initials || getInitials(recruiterName);

  useEffect(() => {
    const source = axios.CancelToken.source();
    (async () => {
      try {
        setIsLoading(true);
        setLastActivity(null);
        const response = await API.get(
          `${Endpoints.Inventories}/getLastActivity?entityType=${entityType}&entityId=${id}`,
          {
            cancelToken: source.token
          }
        );
        if (response?.data) {
          setLastActivity(response.data);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
      setIsLoading(false);
    })();
    return () => {
      if (source) {
        source.cancel();
      }
    };
  }, [id, entityType]);

  const entityProps = { title, subtitle, description, type };

  const lastActivityProp = {
    ...lastActivity,
    isLoading
  };

  return (
    <>
      <InventoryInfo
        markerType={markerType}
        entity={entityType}
        entityProps={entityProps}
        lastActivity={lastActivityProp}
      />
      <Divider className={classes.divider} />
      <div className={classes.recruiterInfo}>
        <FPAvatar>{initialsOrDefault}</FPAvatar>
        <div className={classes.recruiterName}>
          <div>Recruiter: {recruiterName}</div>
          <div className={classes.emailContainer}>
            <Typography className={classes.emailText} onClick={() => onCopyClick(recruiterEmail)}>
              {recruiterEmail}
            </Typography>
            <CopyIcon
              fill={colors.navLink}
              size={14}
              className={clsx(classes.copyButton, 'copyButton')}
              title="Copy to Clipboard"
              onClick={() => onCopyClick(recruiterEmail)}
            />
          </div>
        </div>
      </div>
      <div className={classes.linkContainer}>
        <SearchProjectAction text="Add to Search Project" />
        <FPActionButton
          text="View Details"
          size="small"
          className={classes.previewButton}
          id={id}
          onClick={onQuickViewClick}
        />
      </div>
    </>
  );
};

InventoryPopover.defaultProps = {
  type: '',
  recruiterName: '',
  recruiterEmail: '',
  description: '',
  entityType: '',
  id: null,
  initials: '',
  subtitle: '',
  title: ''
};

export default InventoryPopover;
