import * as React from "react";

const SvgToolsUsage = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M21 .62A2 2 0 0019.59 0H8a2 2 0 00-2 2v6c0 .35.08.5.25.5a3.21 3.21 0 011.38.31.25.25 0 00.37-.19V2.53A.5.5 0 018.5 2h10.88a.47.47 0 01.35.15l2.12 2.15a.47.47 0 01.15.35V18a.5.5 0 01-.5.5h-7.36a.26.26 0 00-.24.32 3.17 3.17 0 010 1.37.24.24 0 00.24.31H22a2 2 0 002-2V4.45A2 2 0 0023.42 3z" />
      <path d="M12.4 11.6a.25.25 0 00.32-.08l1.22-1.72a.24.24 0 01.33-.07l1.51.9a1 1 0 001.22-.15l.1-.12 2.69-3.74a1 1 0 10-1.62-1.16l-2 2.8a.26.26 0 01-.33.07l-1.54-.91a1 1 0 00-1.3.28l-2.12 3a.24.24 0 00.12.38 9.22 9.22 0 011.4.52z" />
      <circle cx={6.25} cy={17} r={1.5} />
      <path d="M8.91 13a.47.47 0 01-.54-.31L8 11.29a1.78 1.78 0 00-3.4 0l-.42 1.4a.47.47 0 01-.54.31l-1.41-.33a1.74 1.74 0 00-1.93.84 1.75 1.75 0 00.23 2.11l1 1.07a.46.46 0 010 .62l-1 1.07a1.75 1.75 0 00-.23 2.11 1.74 1.74 0 001.93.84L3.59 21a.47.47 0 01.54.31l.42 1.4a1.78 1.78 0 003.4 0l.42-1.4a.47.47 0 01.54-.31l1.41.33A1.78 1.78 0 0012 18.38l-1-1.07a.46.46 0 010-.62l1-1.07a1.78 1.78 0 00-1.7-2.95zm-2.66 7a3 3 0 113-3 3 3 0 01-3 3z" />
    </svg>
  );
};

export default SvgToolsUsage;
