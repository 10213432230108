export const DEFAULT_OPTIONS = {
  chartArea: { height: '94%', width: '94%' },
  is3D: false,
  legend: {
    position: 'right',
    alignment: 'center'
  },
  pieHole: 0.5,
  pieSliceTextStyle: {
    color: 'white',
    fontSize: 14,
    bold: true
  },
  pieStartAngle: 250
};
