import * as React from 'react';

const SvgArrowUp = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M12.1 4.5c.7 0 1.3.3 1.7.8l9.7 11c.7.7.6 1.9-.1 2.6s-1.9.6-2.6-.1l-.1-.1-8.5-9.6c-.1-.1-.2-.1-.4 0l-8.5 9.6c-.7.8-1.8.9-2.6.2s-.9-1.8-.2-2.6l.1-.1 9.7-11c.5-.4 1.1-.7 1.8-.7z" />
    </svg>
  );
};

export default SvgArrowUp;
