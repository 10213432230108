import isNil from 'lodash/isNil';
import moment from 'moment';
import strings from 'strings';
import { DateFormats } from 'UI/constants/defaults';

export const formatDate = date => moment(date).format(DateFormats.QueryFormat);

export const transformProps = params => {
  if (params.team) {
    return { teams: params.team };
  }
  if (params.recruiter) {
    return { employeeIds: params.recruiter };
  }
  return params;
};

export const formatStats = ({ data, drawerHandlers }) => {
  const candidates = Object.keys(data?.candidates ?? {}).map(key => {
    const value = data.candidates[key]?.total;
    if (isNil(value)) return null;
    return {
      title: strings.commandCenter.metricsTitles.candidates[key],
      value,
      ...(drawerHandlers &&
        data.candidates[key]?.url && {
          onClick: () => drawerHandlers.candidates[key]({ url: data.candidates[key]?.url })
        })
    };
  });

  const jobOrders = Object.keys(data?.jobOrders ?? {}).map(key => {
    const value = data.jobOrders[key]?.total;
    if (isNil(value)) return null;
    return {
      title: strings.commandCenter.metricsTitles.jobOrders[key],
      value,
      ...(drawerHandlers &&
        data.jobOrders[key]?.url && {
          onClick: () => drawerHandlers.jobOrders[key]({ url: data.jobOrders[key]?.url })
        })
    };
  });

  const names = Object.keys(data?.names ?? {}).map(key => {
    const value = data.names[key]?.total;
    if (isNil(value)) return null;
    return {
      title: strings.commandCenter.metricsTitles.names[key],
      value,
      ...(drawerHandlers &&
        data.names[key]?.url && {
          onClick: () => drawerHandlers.names[key]({ url: data.names[key]?.url })
        })
    };
  });

  const companies = Object.keys(data?.companies ?? {}).map(key => {
    const value = data.companies[key]?.total;
    if (isNil(value)) return null;
    return {
      title: strings.commandCenter.metricsTitles.companies[key],
      value,
      ...(drawerHandlers &&
        data.companies[key]?.url && {
          onClick: () => drawerHandlers.companies[key]({ url: data.companies[key]?.url })
        })
    };
  });

  const hiringAuthorities = Object.keys(data?.hiringAuthorities ?? {}).map(key => {
    const value = data.hiringAuthorities[key]?.total;
    if (isNil(value)) return null;
    return {
      title: strings.commandCenter.metricsTitles.hiringAuthorities[key],
      value,
      ...(drawerHandlers &&
        data.hiringAuthorities[key]?.url && {
          onClick: () =>
            drawerHandlers.hiringAuthorities[key]({ url: data.hiringAuthorities[key]?.url })
        })
    };
  });

  return [...names, ...candidates, ...hiringAuthorities, ...jobOrders, ...companies].filter(
    item => item?.title
  );
};
