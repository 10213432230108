// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import GpacGeneratorForm from '@gogpac/form-generator';
import { Typography } from '@material-ui/core';
import useFetchCatalogs from 'hooks/useFetchCatalogs';
import moment from 'moment';
import { useUserContext } from 'providers/UserProvider';
import { EntityRoutes } from 'routes/constants';
import { canUserOrStaffEditEntity } from 'services/Authorization';
import strings from 'strings';
import FPHint from 'UI/components/atoms/FPHint';
import FPFormStatus from 'UI/components/molecules/FPFormStatus';
import InfoLabelsGrid from 'UI/components/organisms/inventoryProfiles/InfoLabelsGrid';
import InventoryOverviewLayout from 'UI/components/organisms/inventoryProfiles/InventoryOverviewLayout';
import { DateFormats } from 'UI/constants/defaults';
import { AutoWidthGrid, SharedFullGridLayout } from 'UI/constants/dimensions';
import { EntityType, PaymentMode } from 'UI/constants/entityTypes';
import { RequestStatus } from 'UI/constants/status';
import { useFetchDataSheet } from 'UI/pages/EditDataSheet/hooks';
import { currencyFormatter, localTimeFormatter, phoneNumberFormatter } from 'UI/utils';
import { buildLocationInfoLabel } from 'UI/utils/inventory';

import EditButton from './EditButton';
import { DataSheetCardHeading, DataSheetSection } from './JobOrderOverview.styled';
import { createReadOnlyFormData, createReadOnlySchema } from './JobOrderOverview.utils';

type JobOrderOverviewProps = {
  profile?: Object,
  loading: boolean,
  onWriteUpClick: () => {}
};

const { dataSheetDescription, cards: cardTitles, editButton } = strings.inventory.jobOrders.profile;

const JobOrderOverview = ({ loading, profile, onWriteUpClick }: JobOrderOverviewProps) => {
  const [currentUser] = useUserContext();
  const questionHelper = condition => (condition === true ? 'Yes' : 'No');
  const { status: dataSheetFormStatus, dataSheet } = useFetchDataSheet(profile?.id);
  const { catalogs, uiState } = useFetchCatalogs({ companyId: profile?.company?.id });

  const locationValue = buildLocationInfoLabel({
    city: profile?.company?.address?.city?.title,
    state: profile?.company?.address?.city?.state?.slug,
    country: profile?.company?.address?.city?.state?.country?.slug
  });

  const companyLink = EntityRoutes.CompanyProfile.replace(':id', profile?.company?.id);

  const canUserEdit = canUserOrStaffEditEntity(currentUser, profile) || !profile;

  const getEditButton = () => {
    if (!loading && !!onWriteUpClick) {
      const size = canUserEdit ? 'medium' : 'small';
      return canUserEdit ? (
        <EditButton text={editButton} title={editButton} size={size} onClick={onWriteUpClick} />
      ) : null;
    }

    return null;
  };

  const DataSheetWrapper = () => {
    return (
      <DataSheetSection>
        <div>
          <DataSheetCardHeading>
            <Typography title={strings.inventory.jobOrders.editDataSheet.title} variant="h4">
              {strings.inventory.jobOrders.editDataSheet.title}
            </Typography>
            {dataSheet && (
              <FPFormStatus
                status={dataSheet.progress.key}
                totalQuestions={dataSheet.progress.total}
                totalAnsweredQuestions={dataSheet.progress.current}
              />
            )}
          </DataSheetCardHeading>
          <FPHint
            typographyProps={{ variant: 'subtitle1' }}
            hint={dataSheetDescription}
            description={dataSheetDescription}
          />
        </div>
        {getEditButton()}
      </DataSheetSection>
    );
  };

  const generalDataLabels = [
    {
      title: 'Industry · Specialty',
      description: `${profile?.specialty?.industry?.title} · ${profile?.specialty?.title}`
    },
    {
      title: 'Subspecialty',
      description: profile?.subspecialty?.title
    },
    {
      title: 'Functional Title',
      description: profile?.position?.title
    },
    buildLocationInfoLabel({
      city: profile?.address?.city?.title,
      state: profile?.address?.city?.state?.slug,
      country: profile?.address?.city?.state?.country?.slug
    }),
    {
      title: 'Address',
      description: profile?.company?.address?.address
    },
    {
      title: 'Zip Code',
      description: profile?.address?.zip
    },
    {
      title: 'Job Board Source',
      description: profile?.sourceType?.title
    },
    {
      title: 'LinkedIn Url',
      description: profile?.source,
      mode: loading ? 'text' : 'externalLink',
      linkProps: {
        url: profile?.source ?? ''
      }
    },
    {
      title: 'Work Email',
      mode: 'externalLink',
      linkProps: {
        url: profile?.hiringAuthorities?.[0]?.work_email ?? ''
      },
      config: AutoWidthGrid
    }
  ];

  const companyLabels = [
    {
      title: 'Name',
      description: profile?.company?.name,
      mode: 'localLink',
      itemId: profile?.company?.id,
      linkProps: { url: EntityRoutes.CompanyProfile }
    },
    {
      title: 'Company type',
      mode: 'chip',
      colorIndicator: profile?.company?.type?.color,
      description: profile?.company?.type?.title
    },
    {
      title: 'Industry · Specialty ',
      description: `${profile?.company?.specialty?.industry?.title} · ${profile?.company?.specialty?.title}`
    },
    {
      title: 'Subspecialty',
      description: profile?.company?.subspecialty?.title ?? ''
    },
    locationValue,
    { title: 'Address', description: profile?.company?.address?.address },
    { title: 'ZIP Code', description: profile?.company?.address?.zip },
    {
      title: 'Phone',
      mode: 'ringCentral',
      linkProps: {
        url: phoneNumberFormatter(profile?.company?.contact?.phone) ?? '',
        id: profile?.id,
        role: EntityType.Company
      }
    },
    {
      title: 'Email',
      mode: 'externalLink',
      linkProps: {
        url: profile?.company?.email ?? ''
      },
      config: AutoWidthGrid
    },
    {
      title: 'Website',
      description: profile?.company?.website,
      mode: 'externalLink',
      linkProps: {
        url: profile?.company?.website ?? ''
      },
      config: AutoWidthGrid
    },
    {
      title: 'LinkedIn Url',
      description: profile?.company?.link_profile,
      mode: 'externalLink',
      linkProps: {
        url: profile?.company?.link_profile ?? ''
      },
      config: AutoWidthGrid
    }
  ];

  const whiteSheet = profile?.whiteSheet;

  const feePercentage = `${
    whiteSheet?.companyFeeAgreement?.fee_percentage
      ? `${whiteSheet?.companyFeeAgreement?.fee_percentage}%`
      : ''
  }`;

  const isFlatPayment =
    whiteSheet?.companyFeeAgreement?.fee_agreement_payment_scheme_id === PaymentMode.Flat;
  const feeAgreementValue = isFlatPayment
    ? currencyFormatter(whiteSheet?.companyFeeAgreement?.flat_fee_amount)
    : feePercentage;

  const feeAgreementLabels = [
    {
      title: 'Signed',
      description: whiteSheet?.discussing_agreement_complete === 1 ? 'Yes' : 'No'
    },
    {
      title: 'Company ready to sign',
      description: questionHelper(whiteSheet?.company_prepared_to_sign_service_agreement)
    },
    {
      title: 'Ready to hire',
      description: questionHelper(whiteSheet?.company_prepared_to_interview_asap)
    },
    { title: 'Fee Agreement', description: feeAgreementValue },
    { title: 'Guarantee Days', description: whiteSheet?.warranty_time_in_days }
  ];

  const lookingFor =
    whiteSheet?.time_position_open && moment(whiteSheet?.time_position_open).fromNow(true);

  const positionLabels = [
    { title: 'Time Open', description: lookingFor },
    {
      title: 'Need to be filled by',
      description: localTimeFormatter(whiteSheet?.position_filled, DateFormats.DayDate)
    },
    { title: 'Work type', description: whiteSheet?.workTypeOption?.title },
    buildLocationInfoLabel({
      city: profile?.address?.city?.title,
      state: profile?.address?.city?.state?.slug,
      country: profile?.address?.city?.state?.country?.slug
    }),
    {
      title: 'High Compensation',
      description: currencyFormatter(whiteSheet?.maximum_compensation)
    },
    {
      title: 'Ideal Compensation',
      description: currencyFormatter(whiteSheet?.good_compensation)
    },
    {
      title: 'Low Compensation',
      description: currencyFormatter(whiteSheet?.minimum_compensation)
    },
    {
      title: 'Other Compensations',
      description: whiteSheet?.benefits,
      config: SharedFullGridLayout
    },
    {
      title: 'Minimum background requirements',
      description: whiteSheet?.background_requirements,
      config: SharedFullGridLayout
    }
  ];

  const dateInterviews = [
    {
      title: 'Option',
      description: localTimeFormatter(
        whiteSheet?.preset_interview_dates?.[0] ?? '',
        DateFormats.SendoutTemplateDate
      )
    },
    {
      title: 'Option 2',
      description: localTimeFormatter(
        whiteSheet?.preset_interview_dates[1] ?? '',
        DateFormats.SendoutTemplateDate
      )
    },
    {
      title: 'Option 3',
      description: localTimeFormatter(
        whiteSheet?.preset_interview_dates[2] ?? '',
        DateFormats.SendoutTemplateDate
      )
    }
  ];

  const extraCards = [
    {
      cardProps: {
        headerProps: {
          title: cardTitles.feeAgreement.title
        }
      },
      Component: InfoLabelsGrid,
      componentProps: {
        loading,
        isFlexibleGrid: true,
        labels: feeAgreementLabels
      }
    },
    {
      cardProps: {
        headerProps: {
          title: cardTitles.position.title
        }
      },
      Component: InfoLabelsGrid,
      componentProps: {
        loading,
        isFlexibleGrid: true,
        labels: positionLabels
      }
    },
    {
      cardProps: {
        headerProps: {
          title: cardTitles.availableDateInterviews.title
        }
      },
      Component: InfoLabelsGrid,
      componentProps: {
        loading,
        isFlexibleGrid: true,
        labels: dateInterviews
      }
    },
    whiteSheet?.notes
      ? {
          cardProps: {
            headerProps: {
              title: cardTitles.notes.title
            }
          },
          Component: InfoLabelsGrid,
          componentProps: {
            loading,
            isFlexibleGrid: true,
            labels: [
              {
                description: whiteSheet?.notes,
                config: { sm: 12, md: 12, style: { whiteSpace: 'pre-line' } }
              }
            ]
          }
        }
      : {
          cardProps: {
            headerProps: {
              title: cardTitles.notes.title
            }
          },
          Component: InfoLabelsGrid,
          componentProps: {
            loading,
            labels: [
              {
                description: '',
                config: { xs: 12, md: 12 }
              }
            ]
          }
        }
  ];

  const dataSheetCard = [
    {
      cardProps: {
        headerProps: {
          title: cardTitles.dataSheet.title
        }
      },
      Component: () => {
        if (!dataSheet) return null;

        const finalSchemaUI = {
          ...dataSheet.schema.ui,
          section6: {
            ...dataSheet.schema.ui.section6,
            peopleInvolvedList: {
              ...dataSheet.schema.ui.section6.peopleInvolvedList,
              'ui:options': {
                addable: false,
                orderable: false,
                removable: false
              }
            }
          }
        };

        const finalSchema = createReadOnlySchema(dataSheet.schema.structure, dataSheet.data);
        const finalFormData = createReadOnlyFormData(dataSheet.data, catalogs);

        return (
          dataSheet &&
          catalogs && (
            <GpacGeneratorForm
              schema={finalSchema}
              uiSchema={finalSchemaUI}
              readonly
              formData={finalFormData}
            />
          )
        );
      }
    }
  ];

  const cards = [
    {
      cardProps: {
        headerProps: { title: cardTitles.generalData.title }
      },
      Component: InfoLabelsGrid,
      componentProps: {
        loading,
        labels: generalDataLabels
      },
      className: 'generalCard'
    },
    {
      cardProps: {
        headerProps: { title: cardTitles.companyData.title },
        footerProps: !loading && {
          footerActionText: 'View Profile',
          actionButtonProps: {
            component: Link,
            to: companyLink
          }
        }
      },
      Component: InfoLabelsGrid,
      componentProps: {
        loading,
        labels: companyLabels
      }
    },
    { Component: DataSheetWrapper },
    ...(dataSheet ? dataSheetCard : extraCards)
  ];

  return (
    <InventoryOverviewLayout
      loading={
        loading ||
        dataSheetFormStatus === RequestStatus.Loading ||
        uiState === RequestStatus.Loading
      }
      items={cards}
    />
  );
};
JobOrderOverview.defaultProps = {
  profile: undefined
};

export default JobOrderOverview;
