import { makeStyles } from '@material-ui/core/styles';
import { layout } from 'UI/constants/dimensions';

const sharedLayoutStyles = {
  width: '100%',
  maxWidth: layout.maxWidth
};

export const useStyles = makeStyles(theme => ({
  paddingTemplate: {
    ...theme.mainLayout,
    width: '100%',
    height: 'max-content'
  },
  paddingContained: {
    ...sharedLayoutStyles,
    height: '100%',
    overflowY: 'auto',
    flexGrow: 1,
    '& > div': {
      width: '100%',
      height: '100%',
      margin: 0,
      '& > div': {
        padding: '0 !important',
        '& > div': {
          boxShadow: 'unset !important'
        }
      }
    }
  },
  paddingOnPage: {
    ...sharedLayoutStyles,
    padding: 0,
    height: 'calc(100% - 100px)',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  selectorContainer: {
    maxWidth: 238
  },
  containerOnPage: {
    height: '100%',
    width: '100%',
    margin: 0
  },
  filtersTemplate: {
    minWidth: 'min-content',
    minHeight: '100%'
  },
  filtersContained: {
    padding: 0,
    height: '100%',
    overflowY: 'auto'
  },
  filtersOnPage: {
    height: '100%',
    padding: 0,
    position: 'relative',
    top: 0
  }
}));

export const styles = {
  tableContainer: {
    minWidth: 'min-content',
    minHeight: '100%'
  },

  paddingGrid: {
    padding: 0,
    maxHeight: '48vh',
    overflowY: 'auto'
  },
  gridOnPage: {
    height: '100%',
    padding: 0,
    position: 'relative',
    top: 0
  }
};
