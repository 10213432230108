// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import strings from 'strings';
import FPLinearProgress from 'UI/components/atoms/FPLinearProgress';
import Text from 'UI/components/atoms/Text';

import { usePhoneStatsStyles } from './styles';
import { PERFORMANCE_INDICATOR_BACKGROUND_COLORS, PERFORMANCE_INDICATOR_COLORS } from './utils';

type PhonePerformanceStatsProps = {
  phonePerformancePercentage: Object,
  totalCalls: Object,
  totalCallTime: Object
};

const TYPE_PERCENTAGE = 'percentage';

const PhonePerformanceStats = ({
  phonePerformancePercentage,
  totalCalls,
  totalCallTime
}: PhonePerformanceStatsProps) => {
  const theme = useTheme();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = usePhoneStatsStyles({ isSmallViewPort });

  const linearProgressItems = [
    {
      title: strings.home.sections.phonePerformanceStats.phonePerformancePercentage.title,
      backgroundColor:
        PERFORMANCE_INDICATOR_BACKGROUND_COLORS[phonePerformancePercentage.performance] ||
        PERFORMANCE_INDICATOR_BACKGROUND_COLORS.default,
      color:
        PERFORMANCE_INDICATOR_COLORS[phonePerformancePercentage.performance] ||
        PERFORMANCE_INDICATOR_COLORS.default,
      current: phonePerformancePercentage.value,
      goal: 100,
      type: TYPE_PERCENTAGE
    },
    {
      title: strings.home.sections.phonePerformanceStats.calls.title,
      backgroundColor:
        PERFORMANCE_INDICATOR_BACKGROUND_COLORS[totalCalls.performance] ||
        PERFORMANCE_INDICATOR_BACKGROUND_COLORS.default,
      color:
        PERFORMANCE_INDICATOR_COLORS[totalCalls.performance] ||
        PERFORMANCE_INDICATOR_COLORS.default,
      current: totalCalls.value,
      goal: totalCalls.goal
    },
    {
      title: strings.home.sections.phonePerformanceStats.time.title,
      backgroundColor:
        PERFORMANCE_INDICATOR_BACKGROUND_COLORS[totalCallTime.performance] ||
        PERFORMANCE_INDICATOR_BACKGROUND_COLORS.default,
      color:
        PERFORMANCE_INDICATOR_COLORS[totalCallTime.performance] ||
        PERFORMANCE_INDICATOR_COLORS.default,
      current: totalCallTime.value,
      goal: totalCallTime.goal
    }
  ];

  return (
    <Grid container className={classes.container}>
      {linearProgressItems.map(progressItem => (
        <Grid role="grid" key={progressItem.title} item xs={12} className={classes.content}>
          <Text className={classes.title} cropped text={progressItem.title} />

          <Grid className={classes.progressBarContainer}>
            <Grid item sm={10} xs={11} className={classes.progressBar}>
              <FPLinearProgress
                backgroundColor={progressItem.backgroundColor}
                color={progressItem.color}
                value={
                  progressItem.current <= progressItem.goal
                    ? progressItem.current
                    : progressItem.goal
                }
                max={progressItem.goal}
              />
            </Grid>
            <Grid item sm={2} xs={1}>
              <Text
                className={classes.progressLabel}
                cropped
                text={
                  progressItem?.type === TYPE_PERCENTAGE
                    ? `${progressItem.current}%`
                    : `${progressItem.current}/${progressItem.goal}`
                }
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const initialState = {
  value: 0,
  goal: 0
};

PhonePerformanceStats.defaultProps = {
  phonePerformancePercentage: { ...initialState },
  totalCalls: { ...initialState },
  totalCallTime: { ...initialState }
};

export default PhonePerformanceStats;
