import { useCallback, useEffect, useRef, useState } from 'react';
import { getChats, searchChats } from 'features/message-center';
import { useUserContext } from 'providers/UserProvider';
import { UIStatus } from 'UI/constants/status';
import { getErrorMessage } from 'UI/utils';

const CONVERSATIONS_LIMIT = 20;

export const useTextMessages = () => {
  const [user] = useUserContext();
  const lastItem = useRef(null);
  const [messages, setMessages] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [uiState, setUiState] = useState({
    error: null,
    isSearchContactsDrawerOpen: false,
    isSearching: false,
    showList: true,
    status: UIStatus.Default
  });

  const internalNumber = `+1${user?.personalInformation?.contact?.phone}`;

  const fetchMessages = useCallback(
    async ({ searchKeyword = '' } = {}) => {
      try {
        setUiState(prev => ({ ...prev, status: UIStatus.Loading }));

        const fetchFunction = searchKeyword ? searchChats : getChats;
        const params = {
          limit: CONVERSATIONS_LIMIT,
          internalNumber,
          ...(searchKeyword && { search: searchKeyword })
        };

        const response = await fetchFunction(params);
        lastItem.current = response?.lastItem ?? null;

        if (searchKeyword) {
          setSearchResults(response?.chats ?? []);
        } else {
          setMessages(response?.chats ?? []);
        }
        setUiState(prev => ({ ...prev, status: UIStatus.Success, error: null }));
      } catch (err) {
        const error = getErrorMessage(err);
        setUiState(prev => ({ ...prev, status: UIStatus.Error, error }));
      }
    },
    [internalNumber]
  );

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  const handleClickMessage = message => {
    setSelectedMessage(message);
    setUiState(prev => ({ ...prev, error: null }));
  };

  const handleSearch = async (searchKeyword = '') => {
    setSearchResults([]);
    if (!searchKeyword) {
      setUiState(prev => ({ ...prev, isSearching: false }));
      return;
    }

    setUiState(prev => ({ ...prev, isSearching: true }));
    fetchMessages({ searchKeyword });
  };

  const handleRefresh = async externalNumer => {
    lastItem.current = null;
    setMessages([]);
    setSearchResults([]);
    setUiState(prev => ({ ...prev, isSearching: false }));
    await fetchMessages();

    const updatedSelectedMessage = messages?.find(
      message => message.externalNumber === externalNumer
    );
    setSelectedMessage(updatedSelectedMessage || null);
  };

  const handleClickToggleShowList = () =>
    setUiState(prev => ({ ...prev, showList: !prev.showList }));

  const handleFetchMoreMessages = async () => {
    if (!lastItem.current || uiState.status === UIStatus.Loading) return;

    try {
      setUiState(prev => ({ ...prev, status: UIStatus.Loading }));

      const newParams = {
        internalNumber,
        lastItemDate: lastItem.current.date,
        lastItemInternalNumber: lastItem.current.internalNumber,
        lastItemExternalNumber: lastItem.current.externalNumber
      };

      const response = await getChats(newParams);
      lastItem.current = response?.lastItem ?? null;
      setMessages(prev => [...prev, ...(response?.chats ?? [])]);
      setUiState(prev => ({ ...prev, status: UIStatus.Success }));
    } catch (e) {
      setUiState(prev => ({ ...prev, status: UIStatus.Error }));
    }
  };

  const handleCloseSearchContactsDrawer = () => {
    setUiState(prev => ({ ...prev, isSearchContactsDrawerOpen: false }));
  };

  const handleOpenSearchContactsDrawer = () => {
    setUiState(prev => ({ ...prev, isSearchContactsDrawerOpen: true }));
  };

  const displayedMessages = uiState.isSearching ? searchResults : messages;

  return {
    handleClickMessage,
    handleClickToggleShowList,
    handleFetchMoreMessages,
    handleRefresh,
    handleSearch,
    handleCloseSearchContactsDrawer,
    handleOpenSearchContactsDrawer,
    messages: displayedMessages,
    selectedMessage,
    uiState
  };
};
