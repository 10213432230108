// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { showAlert } from 'actions/app';
import { EntityRoutes } from 'routes/constants';
import {
  CreatedFromVariants,
  createSearchProject,
  SEARCH_PARAMS_KEYS,
  updateSearchProject
} from 'services/searchProjects';
import { getAllSearchProjectsFolders } from 'services/searchProjects/Folders';
import strings from 'strings';
import type { SearchProjectFormDrawerPropTypes } from 'types/app';
import TextBox from 'UI/components/atoms/TextBox';
import { When } from 'UI/components/atoms/When';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import TreeViewSelector from 'UI/components/molecules/TreeViewSelector/TreeViewSelector';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import {
  DEFAULT_SEARCH_PROJECT_FOLDERS_EXCLUDE_IDS,
  SearchProjectPrivacyScopes
} from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag } from 'UI/utils';

import { FORM_FIELDS_MAP } from './fields';
import SearchProjectExtractOptions from './SearchProjectExtractOptions';

const { searchProject: formCopies } = strings.searchProjects.forms;

const PRIVACY_RADIO_OPTIONS = [
  SearchProjectPrivacyScopes.private,
  SearchProjectPrivacyScopes.public
];

const getFinalTitle = ({ isSearchProjectBeingEdited, shouldExtractCompanyContacts }) => {
  if (shouldExtractCompanyContacts) return formCopies.titles.extractCompaniesContacts;

  return isSearchProjectBeingEdited ? formCopies.titles.edit : formCopies.titles.create;
};

const SearchProjectFormDrawer = ({
  bulkId = null,
  createdFrom = null,
  isSearchProjectBeingEdited = false,
  itemsToAdd = null,
  onClose,
  onSubmitSearchProject = null,
  queryParams = null,
  searchProject = null,
  shouldExtractCompanyContacts = false,
  shouldMoveCopiedItems = false
}: SearchProjectFormDrawerPropTypes) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isFoldersFeatureActive = hasFeatureFlag(FeatureFlags.SearchProjectsFolders);
  const finalTitle = getFinalTitle({ isSearchProjectBeingEdited, shouldExtractCompanyContacts });
  const searchProjectName = useMemo(() => {
    if (!searchProject) return '';
    if (shouldExtractCompanyContacts)
      return `${searchProject.name} - ${formCopies.sections.extractCompaniesContacts.nameSufix}`;
    return searchProject.name;
  }, [searchProject, shouldExtractCompanyContacts]);

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false
  });

  const form = useForm({
    defaultValues:
      searchProject && typeof searchProject === 'object'
        ? {
            [FORM_FIELDS_MAP.name.key]: searchProjectName,
            [FORM_FIELDS_MAP.privacyScope.key]: SearchProjectPrivacyScopes.getPrivacyState(
              searchProject.isPrivate
            ).id
          }
        : {
            [FORM_FIELDS_MAP.privacyScope.key]: SearchProjectPrivacyScopes.private.id
          }
  });
  const { handleSubmit, register, setValue, watch, errors } = form;
  const scopeWatcher = watch(FORM_FIELDS_MAP.privacyScope.key);

  useEffect(() => {
    register({ name: FORM_FIELDS_MAP.privacyScope.key });
  }, [register]);

  const handleRadioChange = event => setValue(FORM_FIELDS_MAP.privacyScope.key, event.target.value);
  const searchParamsKey =
    createdFrom === CreatedFromVariants.BulkMetrics
      ? SEARCH_PARAMS_KEYS.MetricsParams
      : SEARCH_PARAMS_KEYS.SearchParams;

  const onSubmit = handleSubmit(async formData => {
    setUiState({ isSaving: true, isSuccess: false });

    const optionalParams = {};
    const parentFolder = formData[FORM_FIELDS_MAP.parentFolder.key];

    if (searchProject?.id) optionalParams.searchProjectId = searchProject?.id;

    const { alert, data, success } = await (isSearchProjectBeingEdited
      ? updateSearchProject({ ...formData, ...optionalParams })
      : createSearchProject({
          ...formData,
          ...optionalParams,
          bulkId,
          itemsToAdd,
          queryParams,
          searchParamsKey,
          shouldExtractCompanyContacts,
          shouldMoveCopiedItems
        }));

    dispatch(showAlert(alert));

    if (success) {
      const newData = isSearchProjectBeingEdited ? data : null;
      if (isFoldersFeatureActive && newData) {
        newData.folder = { id: parentFolder.id, name: parentFolder.name };
      }
      onSubmitSearchProject && onSubmitSearchProject(success, newData);
      !isSearchProjectBeingEdited &&
        setTimeout(() => {
          history.push(EntityRoutes.SearchProjectPreview.replace(':id', data.id), {
            createdFrom: createdFrom || 'default'
          });
        }, 1);
    }

    setUiState({ isSaving: false, isSuccess: success });
  });

  return (
    <DrawerContentLayout
      title={finalTitle}
      drawerProps={{ open: true }}
      onClose={onClose}
      onSubmit={onSubmit}
      uiState={uiState}
    >
      <FormContext {...form}>
        <NumberedForm>
          <NumberedForm.Item title={formCopies.sections.titles.details}>
            <TextBox
              label={formCopies.sections.name.label}
              name={FORM_FIELDS_MAP.name.key}
              inputRef={register({
                required: formCopies.sections.name.requiredMessage,
                minLength: {
                  value: 5,
                  message: formCopies.sections.name.minLengthMessage
                }
              })}
              error={!!errors[FORM_FIELDS_MAP.name.key]}
              errorText={errors[FORM_FIELDS_MAP.name.key]?.message}
            />
            <FPRadioGroup
              options={PRIVACY_RADIO_OPTIONS}
              onChange={handleRadioChange}
              value={scopeWatcher}
              adviceText={
                formCopies.sections.visibility[SearchProjectPrivacyScopes[scopeWatcher].id]
              }
            />
          </NumberedForm.Item>
          <When condition={shouldExtractCompanyContacts}>
            <NumberedForm.Item title={formCopies.sections.titles.extractCompaniesContacts}>
              <SearchProjectExtractOptions />
            </NumberedForm.Item>
          </When>
          <When condition={isFoldersFeatureActive}>
            <NumberedForm.Item title={formCopies.sections.titles.parentFolder}>
              <TreeViewSelector
                defaultValue={searchProject?.folder || null}
                formFieldName={FORM_FIELDS_MAP.parentFolder.key}
                placeholder={formCopies.sections.parentFolder.placeholder}
                shouldRegisterWithin
                requiredMessage={formCopies.sections.parentFolder.requiredMessage}
                TreeViewLoaderProps={{
                  enableIconClick: true,
                  onlyShowFolders: true,
                  service: {
                    method: getAllSearchProjectsFolders,
                    queryParams: { excludeIds: DEFAULT_SEARCH_PROJECT_FOLDERS_EXCLUDE_IDS }
                  }
                }}
              />
            </NumberedForm.Item>
          </When>
        </NumberedForm>
      </FormContext>
    </DrawerContentLayout>
  );
};

export default SearchProjectFormDrawer;
