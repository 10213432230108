import strings from 'strings';

import { CallsDrawerContent } from './CallsDrawerContent';

const { calls } = strings.commandCenter.drawers;

export const CallsConfig = {
  title: calls.title,
  component: CallsDrawerContent
};
