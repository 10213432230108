import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  iconInfoContainer: {
    width: 'unset'
  },
  content: {
    padding: theme.spacing(1, 0, 0, 5)
  },
  avatarContainer: {
    padding: theme.spacing(0, 0, 0, 5)
  }
}));
