// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import strings from 'strings';
import CustomDatePicker from 'UI/components/atoms/CustomDatePicker';
import { isDatetimeInTimezoneAfter, mergeHourToDate } from 'UI/utils';

import 'moment-timezone';

import { SharedKeyboardTimePickerProps } from '../utils';

type InterviewFixedHourProps = {
  index: number,
  isNew: boolean,
  disabled: boolean,
  defaultDate: any,
  interviewName: string,
  interviewTimeFieldName: string,
  interviewDateFieldName: string,
  interviewTimezoneFieldName: string,
  onFixedHourChange: any => void
};

const InterviewFixedHour = ({
  index,
  isNew,
  disabled,
  defaultDate,
  interviewName,
  interviewTimeFieldName,
  interviewDateFieldName,
  interviewTimezoneFieldName,
  onFixedHourChange
}: InterviewFixedHourProps) => {
  const { clearError, errors, getValues, register, setError, setValue, unregister, watch } =
    useFormContext();

  const timeFieldValue = watch(interviewTimeFieldName);
  const timeFieldError = errors[interviewName]?.[index]?.hour_fixed;

  useEffect(() => {
    register({ name: interviewTimeFieldName }, { required: 'Hour is required' });
    return () => unregister(interviewTimeFieldName);
  }, [interviewTimeFieldName, register, unregister]);

  useEffect(() => {
    let mergedValue;

    if (isNew) return;

    if (timeFieldValue) {
      mergedValue = mergeHourToDate(defaultDate || timeFieldValue, moment(timeFieldValue));
    } else {
      mergedValue = defaultDate ? defaultDate.utc() : null;
    }

    setValue(interviewTimeFieldName, mergedValue, true);
  }, [defaultDate, setValue, interviewTimeFieldName, isNew, timeFieldValue]);

  useEffect(() => {
    const interviewValue = getValues(interviewDateFieldName);
    if (interviewValue && timeFieldValue) {
      const mergedValue = mergeHourToDate(interviewValue, moment(timeFieldValue));
      setValue(interviewDateFieldName, mergedValue.utc(true));
    }
  }, [getValues, interviewDateFieldName, setValue, timeFieldValue]);

  const setTimeInputErrors = (isValid, name) => {
    if (isValid) return errors.interviews?.[index].hour_fixed && clearError(name);

    setValue(name, null);
    return setError(
      name,
      strings.sendouts.interviews.timeRequired.type,
      strings.sendouts.interviews.timeRequired.message
    );
  };

  const handleTimeChange = (name, time) => {
    const interviewDateValue = getValues(interviewDateFieldName);
    const interviewTimezoneValue = getValues(interviewTimezoneFieldName);

    if (!interviewDateValue || !interviewTimezoneValue) {
      setValue(name, time, true);
      return;
    }

    const isValidDateWithTimezone = isDatetimeInTimezoneAfter(
      interviewTimezoneValue?.name,
      interviewDateValue,
      time
    );

    setTimeInputErrors(isValidDateWithTimezone, name);

    if (isValidDateWithTimezone) {
      setValue(name, time, true);
      onFixedHourChange && onFixedHourChange(time);
    }
  };

  const defaultProps = SharedKeyboardTimePickerProps;

  return (
    <CustomDatePicker
      {...defaultProps}
      label="Select Hour *"
      name={interviewTimeFieldName}
      disabled={disabled}
      value={timeFieldValue || null}
      error={!!timeFieldError}
      helperText={!!timeFieldError && timeFieldError?.message}
      onDateChange={handleTimeChange}
      autoComplete="off"
    />
  );
};

InterviewFixedHour.defaultProps = {};

export default InterviewFixedHour;
