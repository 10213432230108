// @flow
import React, { useMemo } from 'react';
import qs from 'query-string';
import BulkEmailPreview from 'UI/components/organisms/BulkEmailDrawer/BulkEmailPreview';
import { PreviewTypes } from 'UI/constants/defaults';

const Preview = ({ history, location }) => {
  const { search } = location;
  const params = useMemo(() => qs.parse(search), [search]);

  // TODO: substitute default (exception) return with a no preview available component
  const redirectTo404 = () => {
    history.push('/404');
    return null;
  };

  if (localStorage.getItem(params.type)) {
    switch (params.type) {
      case PreviewTypes.BulkEmail:
        return <BulkEmailPreview />;
      default:
        return redirectTo404();
    }
  } else {
    return redirectTo404();
  }
};

export default Preview;
