import { YesNo } from 'UI/constants/defaults';

export const InterviewDatePrefix = 'interview_dates';
/**
 * This map is used to transform data before submission to the API and also to preload data from the API into a form
 * Entries can have the following props:
 * If only key is present no transformatios is applied in neither direction (to/from the API)
 * If paramKey is present, then paramKey is sent to the API
 * outboundAccesor is a key to extract a value from an object. For instance, an outboundAccesor = 'id' would evaluate to object['id'] and this value would be sent to the API
 * inboundAccesor is a property path used to preload data coming from the API into the form. It's useful for nested objects, for instance: 'city.state' or 'address.zip'. If a more custom object is needed, then inboundAccessor could be a function.
 */

export const FormFieldsMap = {
  Id: {
    key: 'id'
  },
  ReasonLiving: {
    key: 'reasonLiving',
    paramKey: 'reason_leaving'
  },
  Relocation: {
    key: 'relocation',
    inboundAccesor: ({ relocation }) => (relocation === 1 ? YesNo.Yes : YesNo.No)
  },
  Relocations: {
    key: 'relocations',
    inboundAccesor: ({ relocations }) =>
      relocations ? relocations.map(({ city }) => ({ ...city, slug: city.state.slug })) : [],
    outboundAccesor: relocations =>
      relocations && relocations.length ? relocations.map(({ id }) => id) : null
  },
  WorkType: {
    key: 'workTypeOption',
    paramKey: 'work_type_option_id',
    outboundAccesor: 'id'
  },
  Achievement1: {
    key: 'achievement_one'
  },
  Achievement2: {
    key: 'achievement_two'
  },
  Achievement3: {
    key: 'achievement_three'
  },
  Experience: {
    key: 'experience'
  },
  TimeLooking: {
    key: 'timeLooking',
    paramKey: 'time_looking'
  },
  TimeToStart: {
    key: 'timeToStart',
    paramKey: 'time_start_type_id',
    outboundAccesor: 'id'
  },
  GoodSalary: {
    key: 'goodSalary',
    paramKey: 'good_salary'
  },
  MinimumSalary: {
    key: 'minimumSalary',
    paramKey: 'minimum_salary'
  },
  NoBrainerSalary: {
    key: 'noBrainerSalary',
    paramKey: 'no_brainer_salary'
  },
  Type: {
    key: 'candidateType',
    paramKey: 'candidate_type_id',
    outboundAccesor: 'id'
  },
  Status: {
    key: 'status',
    paramKey: 'status_id',
    outboundAccesor: 'id',
    inboundAccesor: (object, context) => context.status
  },
  Notes: {
    key: 'notes'
  },
  Interview: {
    key: InterviewDatePrefix
  }
};
