export const IndustryFormFieldsMap = {
  Specialty: {
    key: 'specialty',
    paramKey: 'specialty_id',
    inboundAccesor: 'specialty',
    outboundAccesor: 'id'
  },
  Subspecialty: {
    key: 'subspecialty',
    paramKey: 'subspecialty_id',
    inboundAccesor: 'subspecialty',
    outboundAccesor: 'id'
  },
  FunctionalTitle: {
    key: 'functionalTitle',
    paramKey: 'position_id',
    inboundAccesor: 'position',
    outboundAccesor: 'id'
  },
  HasSubSpecialties: {
    key: 'has_sub_specialties'
  }
};
