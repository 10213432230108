import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';

const { primary, grey, secondary } = THEME.palette;

const sharedCardStyles = {
  backgroundColor: primary.FP300,
  borderRadius: '3px',
  color: 'white',
  cursor: 'pointer',
  height: '86px',
  '&:hover': {
    backgroundColor: primary.light
  }
};

const sharedTitleStyles = {
  letterSpacing: '1.5px',
  lineHeight: '16px',
  textTransform: 'uppercase'
};

export const useStyles = makeStyles(theme => ({
  candidatesCard: {
    marginRight: theme.spacing(1),
    ...sharedCardStyles
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  cardContainer: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'space-between'
  },
  cardContent: {
    width: '50%'
  },
  container: {
    paddingTop: theme.spacing(1),
    width: '100%'
  },
  content: {
    marginBottom: theme.spacing(1.5)
  },
  jobOrdersCard: {
    marginLeft: theme.spacing(1),
    ...sharedCardStyles
  },
  progressBar: {
    paddingRight: ({ isSmallViewPort }) => isSmallViewPort && THEME.spacing(2.5)
  },
  progressBarContainer: {
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.spacing(0.5)
  },
  progressLabel: {
    color: secondary.FP700,
    fontSize: 10,
    fontWeight: '500 !important',
    marginLeft: ({ isSmallViewPort }) => !isSmallViewPort && THEME.spacing(2.5)
  },
  title: {
    color: grey[800],
    fontSize: 10,
    fontWeight: '500 !important',
    opacity: '0.60',
    ...sharedTitleStyles
  },
  titleCard: {
    fontSize: 10,
    fontWeight: '500 !important',
    ...sharedTitleStyles
  },
  valueCard: {
    fontSize: 24,
    fontWeight: '500 !important',
    marginTop: theme.spacing(1)
  }
}));
