import * as React from 'react';

const SvgAwardTrophy = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <g fill={fill}>
        <path d="M22 3h-1.76l.2-1.35a1.54 1.54 0 00-.38-1.16A1.51 1.51 0 0019 0H5.05a1.51 1.51 0 00-1.11.49 1.55 1.55 0 00-.37 1.21L3.76 3H2a2 2 0 00-2 2v3a5 5 0 002.52 4.34 1 1 0 101-1.73A3 3 0 012 8V5h2.06l.63 4.21c.18 1.16 1.66 6.29 7.31 6.29s7.13-5.13 7.31-6.29L19.94 5H22v3a3 3 0 01-1.51 2.61 1 1 0 001 1.73A5 5 0 0024 8V5a2 2 0 00-2-2zm-6.92 7.27a.53.53 0 01-.75.66l-2.2-1.24a.25.25 0 00-.24 0l-2.21 1.24a.52.52 0 01-.74-.66l.89-2a.27.27 0 00-.05-.28L8.16 6.36a.49.49 0 01.35-.86h1.84a.25.25 0 00.23-.14l1-2.07a.53.53 0 01.94 0l1 2.07a.24.24 0 00.23.14h1.84a.49.49 0 01.34.86l-1.69 1.58a.25.25 0 00-.06.28zM10.78 16.91a.22.22 0 00-.19.06.25.25 0 00-.09.19v2.43C7.14 20 5 21.78 5 23a1 1 0 001 1h12a1 1 0 001-1c0-1.22-2.14-3-5.5-3.41v-2.43a.25.25 0 00-.09-.19.22.22 0 00-.19-.06 8.31 8.31 0 01-2.44 0z" />
      </g>
    </svg>
  );
};

export default SvgAwardTrophy;
