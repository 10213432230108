// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { showFile } from 'actions/app';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import FPLink from 'UI/components/atoms/FPLink';
import { When } from 'UI/components/atoms/When';
import FileItem from 'UI/components/molecules/FileItem';
import { InfoLabelModes } from 'UI/components/molecules/InfoLabel';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import { DateFormats } from 'UI/constants/defaults';
import { EntityType, PaymentMode, SplitType } from 'UI/constants/entityTypes';
import { CANDIDATES_PROFILE_HASHES } from 'UI/constants/hashIds';
import { FileStatus } from 'UI/constants/status';
import { currencyFormatter, phoneNumberFormatter, toLocalTime } from 'UI/utils';

import { FormFieldsMap } from '../fields';
import PlacementDataSheetView from '../PlacementDataSheetView';
import PlacementReferenceChecks from '../PlacementReferenceChecks';
import PlacementReferenceReleaseView from '../PlacementReferenceReleaseView';
import { useStyles } from '../styles';

import LabelValue from './LabelValue';
import Splits from './Splits';
import { buildSplitInitials, formatAddressForCompany, notAvailableAbbr } from './utils';

type PlacementDetailProps = {
  placement: any,
  splits: any[],
  fileCategories: any[],
  files: any[],
  referenceReleases: any[],
  assignmentDataSheet?: Object,
  candidateDataSheet?: Object
};

const DefaultNumberOfDecimals = 2;

const PlacementDetail = ({
  placement,
  splits,
  fileCategories,
  files,
  referenceReleases,
  assignmentDataSheet,
  candidateDataSheet
}: PlacementDetailProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    paymentScheme,
    fee_amount: feeAmount,
    sendout,
    monthly_amount: monthlyAmount,
    packetUrl,
    estimateUrl,
    billingCompany: billToCompany,
    hiringAuthority
  } = placement || {};
  const { joborder, candidate } = sendout || {};
  const company = joborder?.company;
  const companyContact = company?.contact;
  const companyPhone = phoneNumberFormatter(companyContact?.phone) ?? '';

  const address = formatAddressForCompany(company);
  const billingAddress = formatAddressForCompany(billToCompany);

  const referenceReleaseExists = referenceReleases && referenceReleases.length > 0;
  const newestReferenceReleaseEmail = referenceReleaseExists && referenceReleases[0];

  const companySplits = splits?.filter(({ type }) => type === SplitType.Company);
  const candidateSplits = splits?.filter(({ type }) => type === SplitType.Candidate);
  const mainRecruiter =
    companySplits?.length > 0 ? companySplits[0].recruiter?.personalInformation?.full_name : '';

  const invoiceAmount =
    paymentScheme?.id !== PaymentMode.Conversion ? feeAmount : monthlyAmount || feeAmount;
  const fullFeeAmount = currencyFormatter(feeAmount, 2);
  const formattedInvoiceAmount = currencyFormatter(invoiceAmount, DefaultNumberOfDecimals);

  const industry = company?.specialty
    ? `${company.specialty.title}/ ${company.specialty.industry?.title}`
    : '';
  const splitInitials = buildSplitInitials(companySplits, candidateSplits);
  const coach = placement?.recruiter?.teamRelation?.coach || placement?.coach;

  const coachFirstName = coach?.personalInformation?.first_name || '';
  const estimateCopy = `${splitInitials}\n${
    placement?.approved_date
      ? toLocalTime(placement?.approved_date)?.format(DateFormats.SimpleDate)
      : ''
  }\nCandidate Source: ${candidate?.sourceType?.title || notAvailableAbbr}\nJob Order Source: ${
    joborder?.sourceType?.title || notAvailableAbbr
  }`;
  const statementCopy = `${splitInitials} ${
    coachFirstName.length > 0 ? `(${coachFirstName})` : ''
  }`;

  const invoiceAmountCopy = `${formattedInvoiceAmount}${
    paymentScheme?.id === PaymentMode.Conversion ? ' Conversion' : ''
  } `;

  const glipCopy = `${splitInitials} ${invoiceAmountCopy} ${industry}`;

  const isBilledToDifferentCompany = company?.id !== billToCompany?.id;

  const handleEstimateClick = () =>
    dispatch(
      showFile({
        url: estimateUrl,
        useProxy: true
      })
    );
  const shouldShowReasonForchange =
    placement?.requested_fee_change || placement?.requested_guarantee_days_change;

  const assignmentDataSheetURL =
    assignmentDataSheet?.jobOrder?.id &&
    EntityRoutes.JobOrderEdit.replace(':id', assignmentDataSheet.jobOrder.id);

  const candidateDataSheetURL =
    candidateDataSheet?.candidate?.id &&
    EntityRoutes.CandidateProfile.replace(':id', candidateDataSheet.candidate.id);

  return (
    <NumberedForm>
      <NumberedForm.Item title={strings.placements.sections.details.title}>
        <Grid container spacing={1}>
          <LabelValue
            label={strings.placements.fields.agreementType}
            value={paymentScheme?.title}
          />
          {paymentScheme?.id !== PaymentMode.Flat && (
            <>
              <LabelValue
                label={`${strings.placements.fields.firstYear} ${strings.placements.fields.compensation}`}
                value={currencyFormatter(placement?.first_year_value, DefaultNumberOfDecimals)}
              />
              <LabelValue
                label={strings.placements.fields.feePercent}
                value={`${placement?.fee_percentage}%`}
              />
            </>
          )}
          <LabelValue label={strings.placements.fields.fullFeeAmount} value={fullFeeAmount} />
          <LabelValue
            label={strings.placements.fields.guaranteeDays}
            value={placement?.guarantee_days}
          />
          {shouldShowReasonForchange && (
            <LabelValue
              label={strings.placements.fields.reasonForRequestChange}
              value={placement?.reason_for_change}
              isFullWidth
              cropped={false}
            />
          )}
          {paymentScheme?.id === PaymentMode.Conversion && (
            <>
              <LabelValue
                label={strings.placements.fields.monthlyAmount}
                value={currencyFormatter(monthlyAmount, DefaultNumberOfDecimals)}
              />
              <LabelValue
                label={strings.placements.fields.serviceMonths}
                value={placement?.service_months}
              />
            </>
          )}
          <LabelValue
            label={strings.placements.fields.startDate}
            value={toLocalTime(placement?.start_date)?.format(DateFormats.SimpleDate)}
          />
        </Grid>
      </NumberedForm.Item>
      <NumberedForm.Item title={strings.placements.sections.split.title}>
        {companySplits?.length > 0 && (
          <>
            <div className={classes.infoLabel}>
              <b>
                {`${strings.placements.sections.split.companySide}${
                  companySplits?.length > 1 ? 's' : ''
                }`}
                :
              </b>
            </div>
            <Splits splits={companySplits} invoiceAmount={invoiceAmount} />
          </>
        )}
        {candidateSplits?.length > 0 && (
          <>
            <div className={classes.separator}>
              <b>
                {`${strings.placements.sections.split.candidateSide}${
                  candidateSplits?.length > 1 ? 's' : ''
                }`}
                :
              </b>
            </div>
            <Splits splits={candidateSplits} invoiceAmount={invoiceAmount} />
          </>
        )}
      </NumberedForm.Item>
      <PlacementReferenceChecks
        candidateId={candidate.id}
        candidateName={candidate?.personalInformation?.full_name}
        isReadOnly
        preSelectedReferences={placement?.[FormFieldsMap.References.key]}
      />
      <NumberedForm.Item title={strings.placements.sections.company.title}>
        <Grid container spacing={1}>
          <LabelValue
            label={strings.placements.sections.company.name}
            value={company?.name}
            mode="localLink"
            linkProps={{
              url: EntityRoutes.CompanyProfile.replace(':id', company.id),
              target: '_blank'
            }}
          />
          <LabelValue
            label={strings.placements.sections.company.address}
            value={address}
            cropped={false}
          />
          <LabelValue
            label={strings.placements.sections.company.phoneWithExt}
            mode={InfoLabelModes.RING_CENTRAL}
            linkProps={{
              url: companyPhone,
              id: company?.id,
              role: EntityType.Company
            }}
            description={companyContact?.ext ? ` Ext: ${companyContact.ext}` : ''}
          />
          <LabelValue
            label={strings.placements.sections.company.contactName}
            value={hiringAuthority?.full_name}
          />
          <LabelValue
            label={strings.placements.sections.company.contactEmail}
            value={hiringAuthority?.work_email}
          />
          {isBilledToDifferentCompany && billToCompany && (
            <>
              <LabelValue
                label={strings.placements.sections.company.billToCompany}
                value={billToCompany?.name}
                mode="localLink"
                linkProps={{
                  url: EntityRoutes.CompanyProfile.replace(':id', billToCompany.id),
                  target: '_blank'
                }}
              />
              <LabelValue label="Address" value={billingAddress} cropped={false} />
            </>
          )}
          <LabelValue label={strings.placements.sections.company.industry} value={industry} />
        </Grid>
      </NumberedForm.Item>

      <NumberedForm.Item title={strings.placements.sections.joborder.title}>
        <Grid container spacing={1}>
          <LabelValue
            label={strings.placements.sections.joborder.position}
            value={joborder?.title}
            mode="localLink"
            linkProps={{
              url: EntityRoutes.JobOrderProfile.replace(':id', joborder.id),
              target: '_blank'
            }}
          />

          <LabelValue
            label={strings.placements.sections.joborder.source}
            value={joborder?.sourceType?.title || notAvailableAbbr}
          />
        </Grid>
      </NumberedForm.Item>
      <NumberedForm.Item title={strings.placements.sections.candidate.title}>
        <Grid container spacing={1}>
          <LabelValue
            label={strings.placements.sections.candidate.name}
            value={candidate?.personalInformation?.full_name}
            mode="localLink"
            linkProps={{
              url: EntityRoutes.CandidateProfile.replace(':id', candidate.id),
              target: '_blank'
            }}
          />
          <LabelValue
            label={strings.placements.sections.candidate.source}
            value={candidate?.sourceType?.title || notAvailableAbbr}
          />
        </Grid>
      </NumberedForm.Item>
      <NumberedForm.Item title={strings.placements.sections.estimate.title}>
        <Grid container>
          <LabelValue
            label={strings.placements.sections.estimate.description}
            value={strings.formatString(strings.placements.sections.estimate.descriptionTemplate, {
              recruiter: mainRecruiter,
              candidate: candidate?.personalInformation?.full_name
            })}
            isFullWidth
            cropped={false}
          />
          {placement?.paymentTerms && (
            <LabelValue
              label={strings.placements.sections.estimate.terms}
              value={placement.paymentTerms.title}
              isFullWidth
              cropped={false}
            />
          )}
          <LabelValue
            label={strings.placements.sections.estimate.showCompensation}
            value={placement.show_compensation_invoice ? 'Yes' : 'No'}
            isFullWidth
          />
          <LabelValue
            label={strings.placements.sections.estimate.message}
            value={estimateCopy}
            isFullWidth
            cropped={false}
            className={classes.estimate}
          />
          <LabelValue
            label={strings.placements.sections.estimate.memoNotes}
            value={statementCopy}
            isFullWidth
            cropped={false}
          />
          <LabelValue
            label={strings.placements.sections.estimate.glipMessage}
            value={glipCopy}
            isFullWidth
            cropped={false}
          />
          <LabelValue
            label={strings.placements.sections.estimate.accountPayableEmail}
            value={company?.accounts_payable_email}
            isFullWidth
            cropped={false}
          />
          {placement?.payment_details && (
            <LabelValue
              label={strings.placements.sections.estimate.paymentDetails}
              value={placement?.payment_details}
              fullWidth
              cropped={false}
            />
          )}
          {placement?.additional_invoice_recipients?.length > 0 && (
            <LabelValue
              label={strings.placements.sections.estimate.additionalRecipients}
              value={placement?.additional_invoice_recipients?.join(', ')}
              isFullWidth
              cropped={false}
            />
          )}
          {placement?.notes && (
            <LabelValue
              label={strings.placements.sections.estimate.notes}
              value={placement?.notes}
              isFullWidth
              cropped={false}
            />
          )}
          {estimateUrl && (
            <FPActionButton
              variant="text"
              text={strings.placements.sections.estimate.view}
              onClick={handleEstimateClick}
            />
          )}
        </Grid>
      </NumberedForm.Item>
      {newestReferenceReleaseEmail && (
        <PlacementReferenceReleaseView
          email={newestReferenceReleaseEmail}
          candidateId={candidate?.id}
        />
      )}
      <When condition={assignmentDataSheetURL}>
        <PlacementDataSheetView
          registeredDate={assignmentDataSheet?.updated_at}
          title={strings.placements.sections.assignmentDataSheet.title}
          url={assignmentDataSheetURL}
        />
      </When>
      <When condition={candidateDataSheetURL}>
        <PlacementDataSheetView
          registeredDate={candidateDataSheet?.updated_at}
          title={strings.candidates.profile.dataSheet.title}
          url={`${candidateDataSheetURL}#${CANDIDATES_PROFILE_HASHES.EstablishingUrgency}`}
        />
      </When>
      <NumberedForm.Item
        title={strings.placements.sections.attachments.title}
        showTitleGutter={false}
        action={
          packetUrl ? (
            <FPLink
              linkProps={{
                type: 'local',
                isRoute: false,
                url: packetUrl,
                target: '_self'
              }}
            >
              Download All Files
            </FPLink>
          ) : null
        }
      >
        <List>
          {fileCategories.map(category => (
            <React.Fragment key={category.id}>
              <ListSubheader key={category.id} className={classes.subheader} disableSticky>
                {category.title}
              </ListSubheader>
              {files[category.id]
                ? files[category.id].map(file => (
                    <FileItem
                      key={file.id}
                      dense
                      file={{ ...file, entity: category.id, type: FileStatus.Existing }}
                      fileName={file.file_name}
                      loading={false}
                      readOnly
                      showMenu
                    />
                  ))
                : null}
            </React.Fragment>
          ))}
        </List>
      </NumberedForm.Item>
    </NumberedForm>
  );
};

PlacementDetail.defaultProps = {};

export default PlacementDetail;
