// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { showFile } from 'actions/app';
import clsx from 'clsx';
import strings from 'strings';
import type { FileItemProps } from 'types/app';
import FPIcon from 'UI/components/atoms/FPIcon';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import FPButtonMenu from 'UI/components/molecules/FPButtonMenu';
import { DateFormats } from 'UI/constants/defaults';
import { RequestStatus } from 'UI/constants/status';
import {
  colors,
  DeleteIcon,
  DownloadIcon,
  FileIcon,
  SvgFileCsv,
  SvgFileDoc,
  SvgFileJpg,
  SvgFilePdf,
  SvgFilePng,
  SvgFilePpt,
  SvgFileXls
} from 'UI/res';
import { formatBytes, toLocalTime, trimFileNameWithExtension } from 'UI/utils';

import FooterActionsControlsLegacy from '../FooterActionsControlsLegacy';

import { styles, useFooterActions, useProgressStyles, useStyles } from './styles';

const { menuItemsTitles } = strings.fileManager.fileItem;

const defaultExtIcons = {
  csv: SvgFileCsv,
  doc: SvgFileDoc,
  docx: SvgFileDoc,
  jpeg: SvgFileJpg,
  jpg: SvgFileJpg,
  pdf: SvgFilePdf,
  png: SvgFilePng,
  ppt: SvgFilePpt,
  pptx: SvgFilePpt,
  xls: SvgFileXls,
  xlsx: SvgFileXls
};

const FileItem = ({
  error,
  file,
  fileName,
  loading,
  maxFileNameLength,
  menuItemsToDisable,
  message,
  onFileDelete,
  onFileMove,
  onFileSelect,
  readOnly,
  selectorProps,
  showDate,
  showFileSize,
  showIconButtons,
  showMenu,
  showSelector,
  showTextButtons,
  viewOnClick,
  ...rest
}: FileItemProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const footerActionsClasses = useFooterActions();
  const circularProgressClasses = useProgressStyles();
  const status = loading ? RequestStatus.Loading : RequestStatus.Finished;
  const createdTime = toLocalTime(file.created_at);
  const updatedTime = toLocalTime(file.updated_at);

  const handleDownload = () => {
    if (!file || !file.url) return;

    window.open(file.url);
  };

  const handleViewClick = () =>
    file?.url &&
    dispatch(
      showFile({
        url: file.url,
        useProxy: true,
        explicitFileName: file.file_name
      })
    );

  const handleDeleteClick = () => onFileDelete && onFileDelete(file);
  const handleMoveClick = () => onFileMove && onFileMove(file);
  const handleSelectClick = () => onFileSelect && onFileSelect(file);

  const menuItems = [
    {
      title: menuItemsTitles.download,
      action: handleDownload,
      visible: !file.hasError && !readOnly
    },
    {
      title: menuItemsTitles.view,
      action: handleViewClick,
      visible: true
    },
    {
      title: menuItemsTitles.delete,
      action: handleDeleteClick,
      visible: !readOnly
    },
    {
      title: menuItemsTitles.moveTo,
      action: handleMoveClick,
      visible: !!onFileMove && !readOnly
    }
  ].filter(item => !menuItemsToDisable.includes(item.title));

  const actions = (
    <>
      {showMenu && (
        <FPButtonMenu title="More actions" isIconButton menuItems={menuItems} edge="end" />
      )}
      {showIconButtons && (
        <>
          <FPIconButton href={file?.url} tooltipProps={{ title: 'Download' }}>
            <DownloadIcon fill={colors.black} />
          </FPIconButton>
          {!readOnly && (
            <FPIconButton onClick={handleDeleteClick} edge="end" tooltipProps={{ title: 'Delete' }}>
              <DeleteIcon fill={colors.black} />
            </FPIconButton>
          )}
        </>
      )}
      {showTextButtons && (
        <FooterActionsControlsLegacy
          secondaryAction={handleViewClick}
          saveAction={false}
          secondaryProps={{
            text: 'View',
            size: 'small',
            className: footerActionsClasses.label,
            status: 'success'
          }}
          primaryProps={{
            text: 'Select',
            onClick: handleSelectClick,
            size: 'small',
            className: footerActionsClasses.label,
            disabled: readOnly
          }}
        />
      )}
    </>
  );

  const design = {
    loading: {
      style: styles.loading,
      fileIcon: colors.darkGrey,
      adornment: <CircularProgress classes={circularProgressClasses} size={24} thickness={4} />
    },
    finished: {
      style: styles.finished,
      fileIcon: colors.black,
      adornment: actions
    }
  };

  const getSecondaryDescription = () => {
    const authorName = file?.vCreatedBy?.user_name ?? '';
    const format = DateFormats.SimpleDateTime;
    const fileSize = (showFileSize && file.size && formatBytes(file.size)) || '';
    const fileCreatedDate = (createdTime && showDate && createdTime.format(format)) || '';
    const fileUpdatedDate = (updatedTime && showDate && updatedTime.format(format)) || '';

    return loading
      ? message
      : `${authorName}${fileCreatedDate && ` • Created at: ${fileCreatedDate}`}${
          fileUpdatedDate && ` • Updated at: ${fileUpdatedDate}`
        }${fileSize && ` • ${fileSize}`}`;
  };

  const secondaryText = error ? message : getSecondaryDescription();

  const finalFileName =
    maxFileNameLength === -1 ? fileName : trimFileNameWithExtension(fileName, maxFileNameLength);

  const assignIcon = () =>
    file.ext && defaultExtIcons[file.ext.toLowerCase()] ? (
      <FPIcon component={defaultExtIcons[file.ext.toLowerCase()]} />
    ) : (
      <FileIcon fill={design[status].fileIcon} />
    );

  return (
    <ListItem button={viewOnClick} onClick={viewOnClick ? handleViewClick : undefined} {...rest}>
      <ListItemIcon className={classes.fileIcon}>
        {showSelector && (
          <FormControlLabel
            key={file}
            onClick={e => e.stopPropagation()}
            control={
              <Checkbox name={fileName} color="primary" disabled={loading} {...selectorProps} />
            }
          />
        )}
        {assignIcon()}
      </ListItemIcon>
      <ListItemText
        primary={finalFileName}
        secondary={secondaryText}
        className={clsx(classes.root, classes[status])}
      />
      <ListItemSecondaryAction>{design[status].adornment}</ListItemSecondaryAction>
    </ListItem>
  );
};

FileItem.defaultProps = {
  error: false,
  maxFileNameLength: -1,
  menuItemsToDisable: [],
  message: '',
  onFileDelete: undefined,
  onFileMove: undefined,
  onFileSelect: undefined,
  readOnly: false,
  selectorProps: {},
  showDate: false,
  showFileSize: false,
  showIconButtons: false,
  showMenu: false,
  showSelector: false,
  showTextButtons: false,
  viewOnClick: true
};

export default FileItem;
