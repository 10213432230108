// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import FPHtmlViewer from 'UI/components/atoms/FPHtmlViewer';

import { useStyles } from '../styles';
import { formatText, LogType } from '../utils';

type ValueProps = {
  type?: 'currency' | 'date' | 'dateList' | 'html' | 'percentage' | 'phone' | 'text',
  value?: string | number
};

const Value = ({ type = 'text', value = '' }: ValueProps) => {
  const classes = useStyles();

  if (!value || value.toString().trim() === '')
    return (
      <Typography variant="body1" classes={{ body1: classes.textBold }} gutterBottom>
        &quot; &quot;
      </Typography>
    );

  if (type === LogType.Html)
    return (
      <div className={classes.cardContent}>
        <FPHtmlViewer content={value} />
      </div>
    );

  const textFormatted = formatText[type] ? formatText[type](value) : value;

  return (
    <Typography variant="body1" classes={{ body1: classes.textBold }} gutterBottom>
      {textFormatted}
    </Typography>
  );
};

export default Value;
