import { makeStyles } from '@material-ui/core/styles';
import { PERFORMANCE_INDICATOR_BACKGROUND_CARD_COLORS } from 'UI/components/molecules/home/PhonePerformance/utils';
import { flexAlignCenter, flexAlignCenterSpaceBetween } from 'UI/utils/styles';

const DEFAULT_CARD_WIDTH_PERCENTAGE = 15;
const DEFAULT_LAYOUT_WIDTH_PERCENTAGE = 95;

const calculateWidthPercentage = totalCards => {
  if (!totalCards) return DEFAULT_CARD_WIDTH_PERCENTAGE;
  return DEFAULT_LAYOUT_WIDTH_PERCENTAGE / totalCards;
};

export const useStyles = makeStyles(theme => ({
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1)
  },
  cardContent: {
    backgroundColor: theme.palette.secondary.FP300,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5, 1, 1, 1),
    marginBottom: 0,
    width: ({ totalCards }) => `${calculateWidthPercentage(totalCards)}%`,
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(1.5),
      width: '32%'
    }
  },
  checkBoxLowPerformance: {
    color: `${theme.palette.error.FP200} !important`
  },
  container: {
    fontSize: 14,
    ...flexAlignCenterSpaceBetween
  },
  customFooterActionContainer: {
    padding: theme.spacing(0, 2, 4, 2)
  },
  customFooterActionText: {
    color: theme.palette.grey[800],
    fontSize: 'inherit',
    fontWeight: 400,
    opacity: 0.6
  },
  customFooterListItem: {
    paddingLeft: theme.spacing(1.5)
  },
  customFooterTextContiner: {
    fontSize: 14,
    paddingLeft: theme.spacing(2)
  },
  divider: {
    height: '1px',
    margin: theme.spacing(3, 0)
  },
  footerLink: {
    fontSize: 'inherit',
    fontWeight: '700 !important'
  },
  footerLinkContainer: {
    marginTop: theme.spacing(3)
  },
  labelCurrentMembers: {
    color: theme.palette.primary.light,
    fontSize: 'inherit',
    fontWeight: '500 !important',
    textAlign: 'start',
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center'
    }
  },
  labelHeaderActions: {
    color: theme.palette.grey[800],
    fontSize: 14,
    fontWeight: 400,
    opacity: 0.6
  },
  labelNewMembers: {
    color: theme.palette.secondary.FP700,
    fontSize: 'inherit',
    fontWeight: '700 !important'
  },
  labelNewMembersContainer: {
    justifyContent: 'right',
    paddingRight: theme.spacing(1),
    ...flexAlignCenter,
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'center'
    }
  },
  teamInfoContainer: {
    display: 'flex',
    marginTop: theme.spacing(1),
    padding: theme.spacing(0, 1, 0, 3)
  },
  totalCurrentMembers: {
    color: theme.palette.secondary.FP700,
    display: 'flex',
    fontSize: 32,
    fontWeight: '500 !important',
    justifyContent: 'start',
    marginTop: 0,
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'center',
      marginTop: theme.spacing(6)
    }
  },
  totalNewMembers: {
    fontSize: 16
  },
  totalNewMembersContainer: {
    display: 'flex',
    justifyContent: 'start',
    marginTop: 0,
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'center',
      marginTop: theme.spacing(1.5)
    }
  },
  totalNewMembersAvatar: {
    backgroundColor: theme.palette.primary.FP300,
    color: theme.palette.customColors.white
  },
  low: {
    backgroundColor: PERFORMANCE_INDICATOR_BACKGROUND_CARD_COLORS.low
  },
  regular: {
    backgroundColor: PERFORMANCE_INDICATOR_BACKGROUND_CARD_COLORS.regular
  },
  high: {
    backgroundColor: PERFORMANCE_INDICATOR_BACKGROUND_CARD_COLORS.high
  }
}));
