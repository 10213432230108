// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { THEME as theme } from 'GlobalStyles';
import FPLinearProgress from 'UI/components/atoms/FPLinearProgress';

import { useStyles } from './styles';
import { PERFORMANCE_INDICATOR_COLORS } from './utils';

const {
  palette: { customColors, grey, error }
} = theme;

type PhonePerformanceBarProps = {
  goal: number,
  value: number,
  performance: string
};

const PhonePerformanceBar = ({ goal, value, performance }: PhonePerformanceBarProps) => {
  const classes = useStyles();
  const borderColor = performance && value === 0 ? error.light : grey[400];

  return (
    <Grid className={classes.phonePerformanceBarContainer}>
      <Grid item xs={10} className={classes.progressBarContainer}>
        <FPLinearProgress
          backgroundColor={customColors.white}
          borderColor={borderColor}
          color={PERFORMANCE_INDICATOR_COLORS[performance] || PERFORMANCE_INDICATOR_COLORS.default}
          value={value <= goal ? value : goal}
          max={goal}
        />
      </Grid>
      <Grid item xs={2} className={classes.progressLabelContainer}>
        <Typography className={classes.progressLabel}>{`${value}/${goal}`}</Typography>
      </Grid>
    </Grid>
  );
};

PhonePerformanceBar.defaultProps = {
  goal: 0,
  value: 0
};

export default PhonePerformanceBar;
