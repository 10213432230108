// @flow

import type { OperationResult } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';

import { sendRequest } from './utils';

const title = 'Candidates';

export const sendReferenceReleaseEmail = async (
  candidateId: number,
  data: any,
  options: any
): Promise<OperationResult> =>
  sendRequest(
    `${Endpoints.Candidates}/${candidateId}/${Endpoints.ReferenceReleases}`,
    'post',
    data,
    options?.successBody || 'The email was sent successfully',
    options?.successTitle || title
  );

export const addHiringAuthority = async (
  companyId: number,
  data: any,
  options: any
): Promise<OperationResult> =>
  sendRequest(
    `${Endpoints.Companies}/${companyId}/${Endpoints.HiringAuthorities}`,
    'post',
    data,
    options?.successBody || 'Hiring Authority added successfully',
    options?.successTitle || title
  );

export const LinkHiringAuthorityOrCandidate = async (
  url: string,
  data: any,
  options: any
): Promise<OperationResult> =>
  sendRequest(url, 'post', data, options?.successBody, options?.successTitle);
