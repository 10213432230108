import * as React from 'react';

const SvgMap = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M12 0a8 8 0 00-8 8c0 3.51 5 12 7.15 15.52a1 1 0 001.7 0C15 20 20 11.51 20 8a8 8 0 00-8-8zm0 11.5A3.5 3.5 0 1115.5 8a3.5 3.5 0 01-3.5 3.5z" />
    </svg>
  );
};

export default SvgMap;
