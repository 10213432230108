import * as React from 'react';

const SvgCompanies = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M20.5 0h-8A1.51 1.51 0 0011 1.2a.27.27 0 00.05.21.25.25 0 00.2.09h6.25a.75.75 0 010 1.5h-6.25a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h4.25a.75.75 0 010 1.5h-4.25a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h.25a3 3 0 013 3v13.22a.25.25 0 00.25.28h1a.25.25 0 00.25-.25V21a1 1 0 011-1h2a1 1 0 011 1v2.75a.25.25 0 00.25.25H21a1 1 0 001-1V1.5A1.5 1.5 0 0020.5 0z" />
      <path d="M11.5 9h-8A1.5 1.5 0 002 10.5v.25a.25.25 0 00.25.25H6.5a.75.75 0 010 1.5H2.25a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H4.5a.75.75 0 010 1.5H2.25a.25.25 0 00-.25.25V23a1 1 0 001 1h.75a.25.25 0 00.25-.25V21a1 1 0 011-1h2a1 1 0 011 1v2.75a.25.25 0 00.25.25h4.25a.5.5 0 00.5-.5v-13A1.5 1.5 0 0011.5 9z" />
    </svg>
  );
};

export default SvgCompanies;
