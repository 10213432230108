// @flow

import { ADD_OPTIN, FAILURE_OPTIN, PARAMS_OPTIN, SUCCESS_OPTIN } from 'actions/optin';
import { combineReducers } from 'redux';
import type { OptInDomainState, OptInUiState } from 'types/optin';
import type { Action } from 'types/redux';

const uiReducer = (
  state: OptInUiState = {},
  action: Action = { type: '', payload: null }
): OptInUiState => {
  const { type } = action;

  switch (type) {
    case FAILURE_OPTIN:
      return { ...state, isLoading: false, hasError: true };
    case SUCCESS_OPTIN:
      return { ...state, isLoading: false, hasError: false };
    default:
      return state;
  }
};

const domainReducer = (
  state: OptInDomainState = {},
  action: Action = { type: '', payload: {} }
): OptInDomainState => {
  const { type, payload } = action;

  switch (type) {
    case ADD_OPTIN:
      return {
        ...state,
        optins: payload,
        total: state.total + 1
      };
    case PARAMS_OPTIN:
      return { ...state, params: payload };
    case SUCCESS_OPTIN:
      return {
        ...state,
        optins: payload.data,
        total: Number(payload.total)
      };
    default:
      return state;
  }
};

const optInReducer = combineReducers({ domain: domainReducer, ui: uiReducer });

export default optInReducer;
