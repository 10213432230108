// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { When } from 'UI/components/atoms/When';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { formatPhoneNumber, hasFeatureFlag } from 'UI/utils';

import { useStyles } from './styles';

type PhoneItemProps = {
  phone: string,
  use: string,
  type: string
};

const PhoneItem = ({ phone, use, type }: PhoneItemProps) => {
  const classes = useStyles();

  if (!phone) return null;

  return (
    <div className={classes.phoneItem}>
      <Typography variant="body2" className={classes.mainPhoneLabel}>
        {formatPhoneNumber(phone)}
      </Typography>

      <When condition={use && type && hasFeatureFlag(FeatureFlags.MutiplePhonesListingTags)}>
        <Typography className={classes.phoneItemInfo}>
          {use} / {type}
        </Typography>
      </When>
    </div>
  );
};

export default PhoneItem;
