import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'UI/res';
import { flexAlignCenter, rippleEffect } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    backgroundColor: theme.palette.info.contrastText,
    border: `1px solid ${theme.palette.grey[500]}`,
    overflow: 'unset'
  }
}));

export const useDividerClasses = makeStyles({
  root: {
    margin: 0
  }
});

const arrowSize = 10;

export const useRingCentralLinkStyles = makeStyles(theme => ({
  ringCentralContainer: {
    ...flexAlignCenter,
    backgroundColor: theme.palette.customColors.ringCentral,
    padding: theme.spacing(0.5),
    height: 34,
    position: 'relative',
    '&::before': {
      content: `''`,
      width: arrowSize,
      height: arrowSize,
      background: theme.palette.customColors.ringCentral,
      clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
      position: 'absolute',
      transform: 'rotate(267deg)',
      left: -arrowSize
    }
  },
  iconsContainer: {
    ...flexAlignCenter,
    position: 'relative'
  },
  externalIcon: {
    marginRight: theme.spacing(0.5)
  },
  ringCentralButton: {
    fontSize: 'inherit',
    fontFamily: theme.typography.fontFamilyContent,
    whiteSpace: 'nowrap'
  }
}));

export const useFPLinkStyles = makeStyles(
  theme => ({
    local: {
      fontWeight: 500,
      backgroundColor: theme.palette.secondary.light,
      padding: theme.spacing(0.625, 1),
      borderRadius: 5,
      boxDecorationBreak: 'clone',
      '&:hover': {
        color: colors.navLink
      }
    },
    external: {
      display: 'flex',
      fontWeight: 400
    }
  }),
  { classNamePrefix: 'link' }
);

export const linkStyles = makeStyles(
  theme => ({
    root: {
      ...flexAlignCenter,
      borderRadius: 0,
      padding: theme.spacing(0.5, 1.2),
      height: '100%',
      ...rippleEffect('#f2f2f2', '#717172'),
      cursor: 'pointer'
    }
  }),
  { classNamePrefix: 'linkStyles' }
);
