import { createContext } from 'react';
import { getToken } from 'services/Authentication';
import io from 'socket.io-client';
import { EnvApiUrls } from 'UI/constants/defaults';

export class Socket {
  constructor() {
    this.socketNamespaces = [];
    this.maxTries = 5;
    this.retryTime = 1500;
  }

  /**
   * Add a new namespace connection to the socket object
   *
   * @param {String} namespace - The namespace string, e.g.: '/bulk-email'; The value passed should be in a enum, since these values shouldn't be dynamic
   * @param {Number} [tryNumber=1] - The try number, don't required to be passed at call level outside this scope
   *
   * @summary The socket object can have inside the instance multiple namespace connections, as each of them will have their own logic depending on the namespace passed
   *
   * @return {Object} this
   */
  connect(namespace, tryNumber = 1) {
    if (!this.socketNamespaces[namespace]) this.socketNamespaces[namespace] = {};

    const socketNamespace = this.socketNamespaces[namespace];
    const authToken = getToken();

    if (!authToken) return null;
    if (EnvApiUrls.WS === 'undefined') return null;
    if (tryNumber > this.maxTries) return null;
    if (socketNamespace?.isConnected) return this;

    const body = {
      auth: {
        token: authToken
      },
      transports: ['websocket']
    };

    socketNamespace.ws = io(`${EnvApiUrls.WS}${namespace}`, body);
    socketNamespace.ws.connect();

    if (tryNumber === 1) socketNamespace.eventHandlers = [];

    socketNamespace.ws.on('connect', () => {
      socketNamespace.isConnected = true;
      if (tryNumber > 1) this.reSetEventHandlers(namespace);
    });

    socketNamespace.ws.on('error', () => {
      socketNamespace.isConnected = false;
      socketNamespace.ws.disconnect();
    });

    socketNamespace.ws.on('disconnect', () => {
      socketNamespace.isConnected = false;
    });

    socketNamespace.ws.on('connect_error', () => {
      socketNamespace.isConnected = false;
      socketNamespace.ws.disconnect();

      setTimeout(() => {
        this.connect(namespace, tryNumber + 1);
      }, this.retryTime);
    });

    return this;
  }

  /**
   * Close a namespace connection from the ws server
   *
   * @param {String} namespace - The namespace string, e.g.: '/bulk-email'; The value passed should be in a enum, since these values shouldn't be dynamic
   *
   * @summary This method only closes the conection for the aforementioned namespace
   */
  close(namespace) {
    const socketNamespace = this.socketNamespaces[namespace];

    if (!socketNamespace) return;

    if (socketNamespace.isConnected) socketNamespace.ws.disconnect();
  }

  /**
   * Sets up the event function handler for a given socke event
   *
   * @param {String} namespace - The namespace string, e.g.: '/bulk-email'; The value passed should be in a enum, since these values shouldn't be dynamic
   * @param {Function} handler - The function that will be executed when the socket event happens, callback-esque
   * @param {String} [event='message'] - The event that is expected the handler to be executed on. By default the ws event 'message' is used
   * @param {Function} [resolve] - Resolve function passed by promise to use in handler
   *
   * @summary Setting up the handler allows the outside implementantion to pass a callback & respond properly on the ws eventHandlers
   */
  setEventHandler(namespace, handler, event = 'message', resolve) {
    const socketNamespace = this.socketNamespaces[namespace];
    if (socketNamespace.ws) {
      socketNamespace.eventHandlers.push({ handler, event });
      socketNamespace.ws.on(event, message => handler(message, resolve));
    }
  }

  /**
   * Sets up again the event handlers, in case the first try didn't work
   *
   * @param {String} namespace - The namespace string, e.g.: '/bulk-email'; The value passed should be in a enum, since these values shouldn't be dynamic
   *
   * @summary When the try logic is executed due to the socket failing the first time, the event handlers were left over on the first connection object,
   * meanning that the new connection wont have them setup up, so this method will try to remedy
   */
  reSetEventHandlers(namespace) {
    const socketNamespace = this.socketNamespaces[namespace];
    socketNamespace.eventHandlers.forEach(({ event, handler }) => {
      if (socketNamespace.ws) {
        socketNamespace.ws.on(event, message => handler(message));
      }
    });
  }
}

export const SocketContext = createContext();
