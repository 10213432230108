// @flow
import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Text from 'UI/components/atoms/Text';

import { useStyles } from './styles';

type SearchProjectProps = {
  isPrivate: Boolean,
  mainText: String,
  totalCandidates: Number,
  totalHiringAuthorities: Number,
  searchProjectId: Number,
  onSelectItem: () => Void
};

const ACCESS_LABEL = {
  private: 'PRIVATE',
  public: 'PUBLIC'
};

const SearchProjectRow = ({
  isPrivate,
  mainText,
  totalCandidates,
  totalHiringAuthorities,
  searchProjectId,
  onSelectItem
}: SearchProjectProps) => {
  const theme = useTheme();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles({ isSmallViewPort });

  const handleClickItem = () => onSelectItem && onSelectItem(searchProjectId);
  return (
    <div className={classes.container}>
      <Grid role="button" container className={classes.content} onClick={handleClickItem}>
        <Grid item sm={9} xs={10}>
          <div className={classes.primaryContent}>
            <div>
              <Text className={classes.mainText} cropped text={mainText} />
            </div>

            <div className={classes.subtitleContent}>
              <Text
                className={classes.subtitle}
                cropped
                text={`${totalCandidates} Candidates · ${totalHiringAuthorities} Hiring Authorities`}
              />
            </div>
          </div>
        </Grid>
        <Grid item sm={3} xs={2}>
          <div className={classes.secondaryContent}>
            <Text
              className={classes.secondaryText}
              cropped
              text={isPrivate ? ACCESS_LABEL.private : ACCESS_LABEL.public}
            />
          </div>
        </Grid>
      </Grid>
      <Divider role="separator" className={classes.divider} />
    </div>
  );
};

SearchProjectRow.defaultProps = {
  isPrivate: false,
  mainText: '',
  totalCandidates: 0,
  totalHiringAuthorities: 0,
  searchProjectId: null
};

export default SearchProjectRow;
