import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  barColorPrimary: {
    backgroundColor: ({ color }) => color
  },
  container: {
    width: '100%'
  },
  root: ({ size, backgroundColor, borderColor }) => ({
    borderRadius: size,
    height: size,
    backgroundColor,
    border: borderColor && `1px solid ${borderColor}`
  })
}));
