// @flow

import React, { useState } from 'react';
import strings from 'strings';
import { COMPANY_RELATIONSHIP_KEY, COMPANY_RELATIONSHIP_TYPES } from 'UI/constants/defaults';
import { CompanyEntity, EntityType } from 'UI/constants/entityTypes';

import AssociatedCompaniesDrawer from './AssociatedCompaniesDrawer';
import AssociatedCompaniesOptions from './AssociatedCompaniesOptions';

type AssociatedCompaniesActions = {
  associateParent: boolean,
  associateSibling: boolean,
  associateChild: boolean
};

type CurrentCompany = {
  name: string,
  parentCompanyId: number
};

type AssociatedCompaniesProps = {
  associatedCompaniesActions?: AssociatedCompaniesActions,
  direction: 'column' | 'row',
  onSubmit: () => void,
  companyId?: number,
  company: CurrentCompany,
  associatedCompanies: Record,
  isLoading?: boolean
};

type Relationship = 'PARENT' | 'SIBLING' | 'CHILD';

const getActionText = condition => (condition ? 'Edit' : 'Add');

const AssociatedCompanies = ({
  associatedCompaniesActions,
  direction,
  onSubmit,
  companyId,
  associatedCompanies,
  company,
  isLoading
}: AssociatedCompaniesProps) => {
  const [associatedCompaniesRelationship, setAssociatedCompaniesRelationship] =
    useState<Relationship | null>(null);

  const handleOpenAssociatedCompaniesDrawer = relationship =>
    setAssociatedCompaniesRelationship(relationship);
  const handleCloseAssociatedCompanies = () => setAssociatedCompaniesRelationship(null);

  const openDrawer = relationship => () => handleOpenAssociatedCompaniesDrawer(relationship);

  const options = [
    {
      id: COMPANY_RELATIONSHIP_KEY.parentCompany,
      text: strings.companies.creation.associated.relationship.parent,
      actionText: getActionText(associatedCompanies?.parentCompany?.id),
      onClick: openDrawer(COMPANY_RELATIONSHIP_TYPES.PARENT),
      enabled: associatedCompaniesActions.associateParent
    },
    {
      id: COMPANY_RELATIONSHIP_KEY.childCompanies,
      text: strings.companies.creation.associated.relationship.child,
      actionText: getActionText(associatedCompanies?.childCompanies?.length > 0),
      onClick: openDrawer(COMPANY_RELATIONSHIP_TYPES.CHILD),
      enabled: associatedCompaniesActions.associateChild
    },
    {
      id: COMPANY_RELATIONSHIP_KEY.siblingCompanies,
      text: strings.companies.creation.associated.relationship.sibling,
      actionText: getActionText(associatedCompanies?.siblingCompanies?.length > 0),
      onClick: openDrawer(COMPANY_RELATIONSHIP_TYPES.SIBLING),
      enabled: associatedCompaniesActions.associateSibling
    }
  ];

  const isParent = associatedCompaniesRelationship === COMPANY_RELATIONSHIP_TYPES.PARENT;
  const drawerTitle = `${associatedCompaniesRelationship?.toLowerCase()} ${
    isParent ? EntityType.Company : CompanyEntity.plural.toLowerCase()
  }`;

  const isDrawerOpen = !!associatedCompaniesRelationship;

  return (
    <>
      <AssociatedCompaniesOptions
        direction={direction}
        options={options}
        associatedCompanies={associatedCompanies}
        isLoading={isLoading}
      />
      {isDrawerOpen && (
        <AssociatedCompaniesDrawer
          title={drawerTitle}
          open={isDrawerOpen}
          associatedCompaniesRelationship={associatedCompaniesRelationship}
          onClose={handleCloseAssociatedCompanies}
          onSubmit={onSubmit}
          companyId={companyId}
          currentCompany={company}
          associatedCompanies={associatedCompanies}
        />
      )}
    </>
  );
};

AssociatedCompanies.defaultProps = {
  direction: 'row',
  associatedCompaniesActions: {
    associateParent: false,
    associateSibling: false,
    associateChild: false
  },
  companyId: null,
  associatedCompanies: {
    parentCompany: null,
    siblingCompanies: null,
    childCompanies: null
  },
  isLoading: false
};

export default AssociatedCompanies;
