import * as React from 'react';

const SvgUploadButton = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      fill={fill}
      {...props}
    >
      <g fill={fill}>
        <path d="M20.5 9a2 2 0 00-2-2H16a1 1 0 00-1 1 1 1 0 001 1h2.25a.25.25 0 01.25.25v12.5a.25.25 0 01-.25.25H5.75a.25.25 0 01-.25-.25V9.25A.25.25 0 015.75 9H8a1 1 0 001-1 1 1 0 00-1-1H5.5a2 2 0 00-2 2v13a2 2 0 002 2h13a2 2 0 002-2z" />
        <path d="M10.5 13.5a1.5 1.5 0 003 0V5.75a.25.25 0 01.25-.25h1.75a1 1 0 00.92-.62 1 1 0 00-.21-1.09l-3.5-3.5a1 1 0 00-1.42 0l-3.5 3.5a1 1 0 00-.21 1.09 1 1 0 00.92.62h1.75a.25.25 0 01.25.25z" />
      </g>
    </svg>
  );
};

export default SvgUploadButton;
