// @flow
import React from 'react';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import { AutoWidthGrid } from 'UI/constants/dimensions';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getFeatureFlags, nestTernary, phoneNumberFormatter } from 'UI/utils';
import { buildLocationInfoLabel } from 'UI/utils/inventory';

import ProfileAlert from '../CandidateOverview/ProfileAlert/ProfileAlert';
import InfoLabelsGrid from '../InfoLabelsGrid';
import InventoryOverviewLayout from '../InventoryOverviewLayout';
import {
  buildEmailLabels,
  extractFullName,
  getEmailValidationAlerts,
  getPhonesLabelsForProfile
} from '../utils';

const {
  shared: {
    fields: { currentCompany: currentCompanyString }
  }
} = strings;

type ContactOverviewProps = {
  profile: Object,
  loading: boolean
};

const ContactOverview = ({ loading, profile }: ContactOverviewProps) => {
  const country = profile?.personalInformation?.address?.city?.state?.country?.slug;
  const state = profile?.personalInformation?.address?.city?.state?.slug;

  const hasMultiplePhones = !!profile.phone_id;
  const isMultiplePhonesEnabled = getFeatureFlags().includes(FeatureFlags.ContactsMultiplePhones);

  const fullName = extractFullName(profile, EntityType.Contact);

  const legacyPhoneLabels = [
    {
      title: 'Phone',
      mode: 'ringCentral',
      linkProps: {
        url: phoneNumberFormatter(profile?.personalInformation?.contact?.phone) ?? '',
        id: profile?.id,
        role: EntityType.Contact,
        customLinkProps: {
          profileName: fullName
        }
      }
    },
    {
      title: 'Ext',
      description: profile?.personalInformation?.contact?.ext
    },
    {
      title: 'Other Phone',
      mode: 'ringCentral',
      linkProps: {
        url: phoneNumberFormatter(profile?.personalInformation?.contact?.mobile) ?? '',
        id: profile?.id,
        role: EntityType.Contact,
        customLinkProps: {
          profileName: fullName
        }
      }
    }
  ];
  const generalLabels = [
    {
      title: 'Nickname',
      description: profile?.personalInformation?.nickname
    },
    {
      title: 'Title',
      description: profile?.title
    },
    {
      title: 'Functional title',
      description: profile?.position?.title
    },
    {
      title: 'Industry: Specialty',
      description: `${profile?.specialty?.industry?.title}: ${profile?.specialty?.title}`
    },
    { title: 'Subspecialty', description: profile?.subspecialty?.title },
    {
      title: currentCompanyString,
      mode: profile?.company_id ? 'localLink' : 'text',
      description: profile?.current_company,
      itemId: profile?.company_id,
      linkProps: profile?.company_id
        ? {
            url: EntityRoutes.CompanyProfile
          }
        : ''
    },
    buildLocationInfoLabel({
      city: profile?.personalInformation?.address?.city?.title,
      state,
      country
    }),
    {
      title: 'Zip Code',
      description: profile?.personalInformation?.address?.zip
    },
    {
      title: 'Source',
      description: profile?.sourceType?.title
    },
    {
      title: 'Linkedin Profile',
      mode: 'externalLink',
      linkProps: {
        url: profile?.link_profile ?? ''
      },
      config: AutoWidthGrid
    },
    ...buildEmailLabels({ profile, entityType: EntityType.Contact })
  ];

  const phonesLabels = !isMultiplePhonesEnabled
    ? legacyPhoneLabels
    : nestTernary(
        hasMultiplePhones,
        getPhonesLabelsForProfile(profile, EntityType.Contact),
        legacyPhoneLabels
      );

  const alerts = getEmailValidationAlerts({
    profile,
    loading
  }).map(item => ({
    componentProps: item,
    Component: ProfileAlert
  }));

  const cards = [
    ...alerts,
    {
      cardProps: { headerProps: { title: 'General Information' } },
      Component: InfoLabelsGrid,
      componentProps: { labels: [...generalLabels, ...phonesLabels] },
      className: 'generalCard'
    }
  ];

  return <InventoryOverviewLayout loading={loading} items={cards} />;
};

export default ContactOverview;
