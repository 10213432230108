import { THEME } from 'GlobalStyles';

export const COLORS_BY_LABEL = {
  black: THEME.palette.grey[700],
  blue: THEME.palette.info.main,
  green: THEME.palette.success.main,
  purple: '#A38FF4',
  red: THEME.palette.error.main,
  yellow: THEME.palette.warning.main
};

export const LIGHT_COLORS_BY_LABEL = {
  grey: THEME.palette.grey[100],
  blue: THEME.palette.info.FP100,
  green: THEME.palette.success.FP100,
  red: THEME.palette.error.FP100,
  yellow: THEME.palette.warning.FP100
};

export const MEDIUM_COLORS_BY_LABEL = {
  grey: THEME.palette.grey[400],
  blue: THEME.palette.info.light,
  green: THEME.palette.success.light,
  red: THEME.palette.error.light,
  yellow: THEME.palette.warning.light
};
