import * as React from 'react';

const SvgSubtractCircle = props => {
  const { size, fill } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size} width={size}>
      <path
        d="M24 12a12 12 0 1 0 -12 12 12 12 0 0 0 12 -12Zm-7.29 3.28a1 1 0 0 1 0 1.41 1 1 0 0 1 -1.42 0l-3.11 -3.11a0.26 0.26 0 0 0 -0.35 0l-3.11 3.11a1 1 0 0 1 -1.41 -1.41l3.11 -3.11a0.26 0.26 0 0 0 0 -0.35L7.31 8.71a1 1 0 0 1 0 -1.42 1 1 0 0 1 1.41 0l3.11 3.11a0.24 0.24 0 0 0 0.35 0l3.11 -3.11a1 1 0 1 1 1.42 1.42l-3.11 3.11a0.24 0.24 0 0 0 0 0.35Z"
        fill={fill}
        strokeWidth={1}
      />
    </svg>
  );
};

export default SvgSubtractCircle;
