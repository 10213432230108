// @flow
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import queryString from 'query-string';
import strings from 'strings';
import type { ProfileDataTableProps } from 'types/app';
import ProfileTableLayout from 'UI/components/organisms/inventoryProfiles/ProfileTableLayout';
import PlacementEditForm from 'UI/components/organisms/placements/PlacementEditForm';
import { componentDimensions } from 'UI/constants/dimensions';
import { JobOrderEntity, TabKeys } from 'UI/constants/entityTypes';
import { JobOrderQueryActions } from 'UI/pages/JobOrderProfile/utils';
import { SvgNoPlacements } from 'UI/res/icons/milano';
import { updateUiState } from 'UI/utils/uiHelpers';

import { getPlacementActions } from '../../placements/utils';

const {
  inventoryProfiles: {
    emptyStates: { placements: placementEmptyStrings }
  }
} = strings;

const { emptyState } = componentDimensions;

type PlacementsTabProps = {
  profileId: boolean,
  profileModule: 'joborder' | 'candidate' | 'company' | 'hiringAuthority',
  isLoading: boolean,
  onTriggerTabRefresh: () => void,
  onNewPlacementClick: () => void,
  DataTableProps: ProfileDataTableProps,
  initialColumns: Array<Object>,
  paramsBuilder: Array<Object>
};

const PlacementsTab = ({
  profileId,
  profileModule,
  isLoading,
  onTriggerTabRefresh,
  onNewPlacementClick,
  DataTableProps,
  initialColumns,
  paramsBuilder,
  ...rest
}: PlacementsTabProps) => {
  const location = useLocation();
  const history = useHistory();

  const [uiState, setUiState] = useState({
    isPlacementOpen: false,
    selectedPlacementId: null,
    shouldRefreshTableData: false
  });
  const isJobOrderMode = profileModule === JobOrderEntity.id;

  const handleRefreshTable = () => {
    setUiState(prev => ({
      ...prev,
      shouldRefreshTableData: true
    }));
  };

  const { handlePlacementClosed, handlePlacementCompleted } = getPlacementActions({
    location,
    history,
    onRefreshPlacementTable: handleRefreshTable,
    onTriggerTabRefresh,
    setUiState
  });

  const handlePlacementClick = useCallback(
    id => updateUiState({ isPlacementOpen: true, selectedPlacementId: id }, setUiState),
    [setUiState]
  );

  const formActions = useMemo(
    () => ({
      [JobOrderQueryActions.PlacementDetail]: {
        action: params => handlePlacementClick(params.id)
      },
      [JobOrderQueryActions.PlacementCreation]: {
        action: params => onNewPlacementClick({ sendoutId: params.id })
      }
    }),
    [handlePlacementClick, onNewPlacementClick]
  );

  useEffect(() => {
    const { form } = queryString.parse(location.search);
    if (!isLoading && form) {
      const incomingAction = formActions[form];
      incomingAction && incomingAction.action(queryString.parse(location.search));
    }
  }, [formActions, location.search, isLoading]);

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   P L A C E M E N T   S E T T I N G S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const finalActions = [{ name: 'view', callback: handlePlacementClick, pickId: true }];

  return (
    <>
      <ProfileTableLayout
        actionText={strings.inventoryProfiles.sections.tabs.placements.action}
        DataTableProps={DataTableProps}
        finalActions={finalActions}
        initialColumns={initialColumns}
        onNewItemClick={isJobOrderMode && onNewPlacementClick}
        customEmptyState={<SvgNoPlacements size={emptyState.width} />}
        tabKey={TabKeys.Placements}
        hasProfileLoaded={profileId}
        profileModule={profileModule}
        shouldRefresh={uiState.shouldRefreshTableData}
        paramsBuilder={paramsBuilder}
        {...placementEmptyStrings}
        {...rest}
      />

      {uiState.isPlacementOpen && uiState.selectedPlacementId && (
        <Drawer open onClose={handlePlacementClosed}>
          <PlacementEditForm
            placementId={uiState.selectedPlacementId}
            onClose={handlePlacementClosed}
            onCompleted={handlePlacementCompleted}
            onInvoicesChanged={handleRefreshTable}
            onPaymentsChanged={handleRefreshTable}
          />
        </Drawer>
      )}
    </>
  );
};

export default PlacementsTab;
