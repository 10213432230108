import { makeStyles } from '@material-ui/core/styles';

const materialGridColumns = 12;
const totalColumnsPercent = 100;
export const gridValues = {
  md: 3,
  sm: 3,
  xs: 6
};

const gridItemRatio = breakpoint => {
  const calcFlexBasisPercentage =
    (gridValues[breakpoint] * totalColumnsPercent) / materialGridColumns;

  return {
    flexBasis: `${calcFlexBasisPercentage}%`
  };
};

export const useInfoLabelClasses = makeStyles(theme => ({
  infoLabel: {
    [theme.breakpoints.up('md')]: {
      ...gridItemRatio('md')
    },
    [theme.breakpoints.down('md')]: {
      ...gridItemRatio('md')
    },
    [theme.breakpoints.down('sm')]: {
      ...gridItemRatio('sm')
    },
    [theme.breakpoints.down('xs')]: {
      ...gridItemRatio('xs')
    }
  }
}));
