import { FeatureFlags } from 'UI/constants/featureFlags';
import {
  EMAIL_WITH_VALIDATION_COLUMN,
  getEmailValidationStatusColumn,
  LEGACY_EMAIL_COLUMN,
  OPT_OUT_COLUMN
} from 'UI/constants/sharedColumns';
import { hasFeatureFlag } from 'UI/utils';

const sharedOptions = {
  display: true,
  filter: true,
  sort: true
};

const LEGACY_PERSONAL_EMAIL_COLUMN = {
  name: 'personal_email',
  label: 'Personal Email',
  options: {
    sort: true,
    display: false
  }
};

const PERSONAL_EMAIL_WITH_VALIDATION_COLUMN = {
  name: 'secondaryEmail',
  label: 'Personal Email',
  options: {
    sort: true,
    display: true,
    renderer: {
      id: 'secondaryEmail',
      config: {
        prefix: 'personal'
      }
    }
  }
};

const EMAIL_VALIDATION_STATUS_COLUMN = getEmailValidationStatusColumn({
  featureFlagToHideColumn: null
});

const FINAL_EMAIL_COLUMNS = hasFeatureFlag(
  FeatureFlags.EmailWithValidationColumnSearchProjectPreviews
)
  ? [EMAIL_WITH_VALIDATION_COLUMN, PERSONAL_EMAIL_WITH_VALIDATION_COLUMN]
  : [LEGACY_EMAIL_COLUMN, LEGACY_PERSONAL_EMAIL_COLUMN, EMAIL_VALIDATION_STATUS_COLUMN];

export const ColumnsDefinitions = [
  {
    name: 'id',
    options: {
      filter: false,
      sort: false,
      print: false,
      display: 'excluded'
    }
  },
  {
    name: 'full_name',
    label: 'Full Name',
    options: {
      ...sharedOptions,
      renderer: 'spItemLink'
    }
  },
  {
    name: 'company_type',
    label: 'Company Type',
    options: {
      ...sharedOptions,
      renderer: 'highLevelChipBuilder'
    }
  },
  {
    name: 'current_company',
    label: 'Company',
    options: {
      ...sharedOptions
    }
  },
  OPT_OUT_COLUMN,
  ...FINAL_EMAIL_COLUMNS,
  {
    name: 'opt_out_date',
    label: 'Opt Out Date',
    options: {
      renderer: 'date'
    }
  },
  {
    name: 'opt_out_reason',
    label: 'Opt Out Reason',
    options: {
      renderer: 'default'
    }
  },
  {
    name: 'recruiter_name',
    label: 'Recruiter Name',
    options: sharedOptions
  },
  {
    name: 'last_bulk_activity_date',
    label: 'Last Bulk On',
    options: {
      ...sharedOptions,
      renderer: 'date'
    }
  },
  {
    name: 'last_bulk_activity_recruiter',
    label: 'Last Bulk By',
    options: {
      ...sharedOptions
    }
  },
  {
    name: 'item_search_project_title',
    label: 'Item type',
    options: {
      ...sharedOptions
    }
  },
  {
    name: 'status',
    label: 'Item status',
    options: {
      ...sharedOptions
    }
  },
  {
    name: 'position_title',
    label: 'Functional Title',
    options: {
      ...sharedOptions
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      display: false,
      sort: true,
      filter: false
    }
  },
  {
    name: 'industry',
    label: ' Industry',
    options: {
      ...sharedOptions
    }
  },
  {
    name: 'specialty',
    label: ' Specialty',
    options: {
      ...sharedOptions
    }
  },
  {
    name: 'subspecialty',
    label: 'Subspecialty',
    options: {
      ...sharedOptions
    }
  },
  {
    name: 'location',
    label: 'Location',
    options: {
      ...sharedOptions
    }
  },
  {
    name: 'state',
    label: 'State',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'city',
    label: 'City',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'created_at',
    label: 'Added to Search Project on',
    options: {
      ...sharedOptions,
      renderer: 'date'
    }
  },
  {
    name: 'item_created_at',
    label: 'Added to FortPac on',
    options: {
      ...sharedOptions,
      renderer: 'date',
      display: false
    }
  },
  {
    name: 'last_activity_title',
    label: 'Last Activity',
    options: {
      ...sharedOptions
    }
  },
  {
    name: 'last_activity_recruiter',
    label: 'Last Activity By',
    options: {
      ...sharedOptions
    }
  },
  {
    name: 'last_activity_date',
    label: 'Last Activity Date',
    options: {
      ...sharedOptions,
      renderer: 'date'
    }
  },
  {
    name: 'website',
    label: 'Website',
    options: {
      sort: false,
      display: false,
      filter: false
    }
  },
  {
    name: 'phone',
    label: 'Phone',
    hideWhen: () => hasFeatureFlag(FeatureFlags.SearchProjectPreviewMultiplePhones),
    options: {
      sort: false,
      display: false,
      renderer: 'phone'
    }
  },
  {
    name: 'mobile',
    label: 'Other phone',
    hideWhen: () => hasFeatureFlag(FeatureFlags.SearchProjectPreviewMultiplePhones),
    options: {
      sort: false,
      display: false,
      renderer: 'phone'
    }
  },
  {
    name: 'item_search_project_type',
    options: {
      display: 'excluded',
      sort: false
    }
  }
];

const isPersonalEmailValidationEnabled = hasFeatureFlag(
  FeatureFlags.EmailWithValidationColumnSearchProjectPreviews
);

export const FiltersGroups = [
  {
    id: 'general',
    name: 'Search Projects',
    filters: [
      'industries',
      'specialties',
      'subspecialties',
      'searchProjectTypes',
      'contactStatuses',
      'companyTypes',
      'recruitersAndCoaches',
      'positions',
      'optOut',
      'emailVerdicts',
      ...(isPersonalEmailValidationEnabled ? ['personalEmailVerdicts'] : []),
      'title'
    ]
  },
  {
    id: 'location',
    name: 'Location',
    filters: ['states', 'cities', 'cityRadius', 'zips', 'radius']
  }
];

export const VirtualProps = [
  {
    propName: 'full_name',
    attributes: ['id', 'full_name', 'item_search_project_type']
  },
  {
    propName: 'company_type',
    attributes: ['company_type_id', 'company_type', 'company_type_color']
  },
  {
    propName: 'phoneNumbers',
    attributes: ['phone', 'phones']
  },
  {
    propName: 'mainEmail',
    attributes: ['email', 'email_verdict']
  },
  {
    propName: 'secondaryEmail',
    attributes: ['personal_email', 'personal_email_verdict']
  }
];
