// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { useGlobalStyles } from 'GlobalStyles';
import useDatatable, {
  buildButtonRendererDefinition,
  extractObjectFromDataTable,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import queryString from 'query-string';
import { getCurrentUser } from 'services/Authentication';
import { FeeAgreementRoleHierarchy, getHighestUserRoleInHierarchy } from 'services/Authorization';
import { useHelloSign } from 'services/FeeAgreement';
import strings from 'strings';
import DataTable, { CustomToolbar } from 'UI/components/organisms/DataTable';
import FeeAgreementDetails from 'UI/components/organisms/feeagreements/FeeAgreementDetails';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import TabsView from 'UI/components/templates/TabsView';
import { backNavigateListConfig } from 'UI/constants/config';
import { PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { Roles } from 'UI/constants/roles';
import {
  FeeAgreementPeriodColumnBySection,
  FeeAgreementSortColumnBySection,
  FeeAgreementStatusBySection,
  FeeAgreementStatusBySectionForFilters,
  FeeAgreementTabs
} from 'UI/constants/status';
import { useTableCard } from 'UI/globalStyles/DataTableStyles';
import { PendingFeeTabIcon, SignaturesIcon, SignedFeeTabIcon } from 'UI/res';
import { createCustomStaticRanges } from 'UI/utils';

import { ColumnsDefinitions, FiltersGroups, VirtualProps } from './columns';

import 'UI/res/dateRangePicker.css';

type FeeAgreementsListProps = {
  location: any
};

const pageKey = 'fee-agreements-list';

const FeeAgreementsList = (props: FeeAgreementsListProps) => {
  const { location } = props;
  const globalClasses = useGlobalStyles();
  const tableClasses = useTableCard();

  const history = useHistory();
  const currentUser = getCurrentUser();
  const highestRole = getHighestUserRoleInHierarchy(currentUser, FeeAgreementRoleHierarchy);

  const queryParameters = queryString.parse(location.search);
  const { id: feeAgreementIdToLoad, tab } = queryParameters;
  const tabToSelect = parseInt(tab, 10);

  const isUserProductionDirector = highestRole?.id === Roles.ProductionDirector;
  const defaultTab = isUserProductionDirector
    ? FeeAgreementTabs.PendingSignatures
    : FeeAgreementTabs.PendingValidations;
  const initialTab = Number.isNaN(tabToSelect) ? defaultTab : tabToSelect;

  // As each tab has specific statuses, we need to make sure
  // we're filtering  the right status for the right tab
  const extendParams = useCallback(({ activeSection: section }, { feeStatus }) => {
    let statusIds = [];
    let statusIdsForFilters = [];

    switch (section) {
      case FeeAgreementTabs.PendingValidations:
        statusIds = FeeAgreementStatusBySection.PendingValidations;
        statusIdsForFilters = FeeAgreementStatusBySectionForFilters.PendingValidations;
        break;
      case FeeAgreementTabs.PendingSignatures:
        statusIds = FeeAgreementStatusBySection.PendingSignatures;
        statusIdsForFilters = FeeAgreementStatusBySectionForFilters.PendingSignatures;
        break;
      case FeeAgreementTabs.Signed:
        statusIds = FeeAgreementStatusBySection.Signed;
        statusIdsForFilters = FeeAgreementStatusBySectionForFilters.Signed;
        break;
      default:
        break;
    }

    const status = feeStatus?.value;
    statusIds =
      status?.id && statusIdsForFilters.some(each => each === status.id) ? [status.id] : statusIds;
    return {
      status_ids: statusIds
    };
  }, []);

  const orderByOptions = {
    column: FeeAgreementSortColumnBySection,
    direction: 'desc'
  };
  const initialPreferences = getColumnPreferences(
    pageKey,
    initialTab,
    orderByOptions,
    ColumnsDefinitions
  );

  const {
    handleFiltersApply,
    handleFiltersChange,
    handleKeywordChange,
    handleColumnSortChange,
    handlePerPageChange,
    handlePageChange,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleFiltersToggle,
    handlePeriodChange,
    handleSectionChange,
    listState,
    columnPreferences,
    filters,
    data,
    count,
    getData,
    activeSection
  } = useDatatable({
    key: pageKey,
    endpoint: Endpoints.FeeAgreement,
    entityName: strings.feeAgreements.plural,
    initialSection: initialTab,
    initialPreferences,
    columnsDefinitions: ColumnsDefinitions,
    orderByOptions,
    periodFilterColumn: FeeAgreementPeriodColumnBySection,
    paramsExtender: extendParams,
    virtualProps: VirtualProps,
    shouldScrollOnNavigate: true
  });

  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  const { isLoading, showWholeSkeleton, isSideMenuOpen, page, perPage, keyword } = listState;

  const defaultUiState = {
    selectedRowId: 0,
    isFeeAgreementValidationOpen: false,
    isFeeAgreementSummaryOpen: false,
    additionalData: null
  };

  const [uiState, setUiState] = useState(defaultUiState);

  useEffect(() => {
    document.title = PageTitles.FeeAgreements;
  }, []);

  const handleAgreementSend = () => handleSectionChange(null, FeeAgreementTabs.PendingSignatures);

  const handleAgreementUpdated = async () => {
    closeDrawer();
    await getData();
  };

  const handleTemplateCreated = templateData =>
    showOrHideDrawer('isFeeAgreementSummaryOpen', true, {
      additionalData: { template: templateData.templateId }
    });

  const handleTemplateCanceled = () =>
    setUiState(prevState => ({ ...prevState, additionalData: null }));

  const { handleTemplateUploaded, handlePreviewCreated } = useHelloSign({
    onSend: handleAgreementSend,
    onTemplateCreated: handleTemplateCreated,
    onTemplateCanceled: handleTemplateCanceled
  });

  const closeDrawer = () => showOrHideDrawer('isFeeAgreementSummaryOpen', false);

  const showOrHideDrawer = (drawer: string, open: boolean, additionalUi: any = {}) => {
    setUiState(prevState => ({ ...prevState, [drawer]: open, ...additionalUi }));
    !open && history.replace(location.pathname);
    !open && setUiState(prevState => ({ ...prevState, additionalData: null }));
  };

  const handleButtonClick = ({ rowData }) => {
    const item = extractObjectFromDataTable(ColumnsDefinitions, ['id'], rowData);
    showOrHideDrawer('isFeeAgreementSummaryOpen', true, { selectedRowId: item.id });
  };

  useEffect(() => {
    const openFeeAgreement = () => {
      setUiState(prevState => ({
        ...prevState,
        isFeeAgreementSummaryOpen: true,
        selectedRowId: feeAgreementIdToLoad,
        additionalData: null
      }));
    };

    feeAgreementIdToLoad && openFeeAgreement();
  }, [feeAgreementIdToLoad]);

  const actionButton = buildButtonRendererDefinition(
    globalClasses.smallRowActionButton,
    strings.shared.ui.view,
    handleButtonClick
  );

  const finalColumns = getColumnsToRender([...columns, actionButton]);

  const tableProps = {
    isExpandable: true,
    loading: showWholeSkeleton,
    refreshing: isLoading,
    filter: false,
    data,
    columns: finalColumns,
    selectableRows: 'none',
    count,
    page,
    rowsPerPage: perPage,
    searchText: keyword,
    sortOrder: { name: orderBy, direction },
    columnOrder: columnOrder?.length ? columnOrder : undefined,
    draggableColumns: {
      enabled: true
    },
    enableCustomFilters: true,
    viewColumns: true,
    onToggleFilters: handleFiltersToggle,
    onSearchTextChange: handleKeywordChange,
    onColumnSortChange: handleColumnSortChange,
    onPerPageClick: handlePerPageChange,
    onPageClick: handlePageChange,
    onColumnDisplayClick: handleColumnDisplayChange,
    onColumnOrderChange: handleColumnOrderChange,
    className: tableClasses.tableRoot,
    components: {
      TableToolbar: CustomToolbar
    }
  };

  const tabsProp = [
    {
      id: FeeAgreementTabs.PendingValidations,
      label: strings.feeAgreements.sections.validations.title,
      icon: <PendingFeeTabIcon />,
      view: (
        <DataTable
          title={strings.feeAgreements.sections.validations.empty}
          subtitle={strings.feeAgreements.sections.validations.emptySubtitle}
          {...tableProps}
        />
      )
    },
    {
      id: FeeAgreementTabs.PendingSignatures,
      label: strings.feeAgreements.sections.sent.title,
      icon: <SignaturesIcon />,
      view: (
        <DataTable
          title={strings.feeAgreements.sections.sent.empty}
          subtitle={strings.feeAgreements.sections.sent.emptySubtitle}
          {...tableProps}
        />
      )
    },
    {
      id: FeeAgreementTabs.Signed,
      label: strings.feeAgreements.sections.signed.title,
      icon: <SignedFeeTabIcon />,
      view: (
        <DataTable
          title={strings.feeAgreements.sections.signed.empty}
          subtitle={strings.feeAgreements.sections.signed.emptySubtitle}
          {...tableProps}
        />
      )
    }
  ];

  return (
    <FiltersLayout
      title={strings.feeAgreements.plural}
      section="fee"
      filters={filters}
      isSideMenuOpen={isSideMenuOpen}
      isLoading={showWholeSkeleton}
      groups={FiltersGroups}
      enableStore={false}
      onSearch={handleFiltersApply}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      onPeriodChange={handlePeriodChange}
      titleLabelProps={backNavigateListConfig}
      dateRangerPickerProps={{
        staticRanges: createCustomStaticRanges(additionalPeriodRanges)
      }}
    >
      <TabsView
        content="start"
        useIndexes={false}
        selectedTab={activeSection}
        onChangeTabIndex={handleSectionChange}
        tabs={tabsProp}
        panelHeight="100%"
      />
      {uiState.isFeeAgreementSummaryOpen && uiState.selectedRowId && (
        <Drawer open onClose={closeDrawer}>
          <div role="presentation">
            <FeeAgreementDetails
              feeAgreementId={uiState.selectedRowId}
              onTemplateUploaded={handleTemplateUploaded}
              onPreviewCreated={handlePreviewCreated}
              onUpdate={handleAgreementUpdated}
              onClose={closeDrawer}
              additionalData={uiState.additionalData}
            />
          </div>
        </Drawer>
      )}
    </FiltersLayout>
  );
};

export default FeeAgreementsList;
