import { makeStyles } from '@material-ui/core';

const panelWidth = 400;

export const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  button: {
    margin: theme.spacing(0.5, 0),
    border: '1px solid',
    borderRadius: '8px',
    color: theme.palette.primary.main
  },
  panel: {
    width: panelWidth,
    padding: '0px !important'
  }
}));
