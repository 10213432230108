import * as React from "react";

const SvgFilePdf = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M17.5 6h3.9a.25.25 0 00.17-.43L16.43.42A.26.26 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM12 13.82c-.1 0-.33-.05-.33.17v4a.24.24 0 00.33.21 2.36 2.36 0 001.38-2.2A2.39 2.39 0 0012 13.82zM7.5 13.62h-.62a.26.26 0 00-.26.26v1.24a.26.26 0 00.26.25h.62a.88.88 0 100-1.75z" />
      <path d="M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM7.5 16.62h-.62a.26.26 0 00-.26.26V19a.62.62 0 01-1.24 0v-6a.63.63 0 01.62-.63h1.5a2.13 2.13 0 010 4.25zm3.5 3a.62.62 0 01-.62-.62v-6a.63.63 0 01.62-.63 3.63 3.63 0 010 7.25zm8-6h-2a.38.38 0 00-.38.38v1.12a.26.26 0 00.26.25H18a.63.63 0 010 1.25h-1.12a.26.26 0 00-.26.26V19a.62.62 0 01-1.24 0v-5A1.62 1.62 0 0117 12.37h2a.63.63 0 010 1.25z" />
    </svg>
  );
};

export default SvgFilePdf;
