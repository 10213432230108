// @flow
import React from 'react';

import Content from './Content';

const Roster = props => {
  return <Content {...props} />;
};

export default Roster;
