import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';

export const MainLayoutContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2, 3)
}));

export const HeaderContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(4)
}));

export const GridItem = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(3, 0)
}));
