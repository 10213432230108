import { DateFormats } from 'UI/constants/defaults';

// Waiting the endpoint of types of interviews
export const optionsTypeInterview = [
  {
    id: '1',
    title: 'Telephone / Video Interview'
  },
  {
    id: '2',
    title: 'Face to Face Interview'
  }
];

export const optionsTypeTimes = [
  {
    id: 1,
    title: 'Fixed Hour'
  },
  {
    id: 2,
    title: 'Time Range'
  }
];

export const optionsRenderTimes = {
  fixedHour: 1,
  rangeHour: 2
};

export const interviewScheduleStates = {
  editing: 1,
  creating: 2
};

export const interviewItemsStates = {
  firstInterview: 0,
  editableComplete: 1
};

export const SharedKeyboardTimePickerProps = {
  isClearable: false,
  withTime: true,
  autoOk: true,
  timeFormat: DateFormats.SimpleTime,
  mask: '__:__ _M',
  placeholder: 'HH:MM AM',
  variant: 'keyboardTime'
};
