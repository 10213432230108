import * as React from "react";

const SvgPendingAgreementsFilled = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M20.12 7.38a1 1 0 00-1-1h-7a1 1 0 100 2h7a1 1 0 001-1z" />
      <path d="M23.54 2.88L21.12.46a2 2 0 00-1.41-.58H8.12a2 2 0 00-2 2v7.27a.25.25 0 00.25.25c.36 0 1 0 1.48.08a.24.24 0 00.27-.25V2.38a.5.5 0 01.5-.5H19.5a.52.52 0 01.36.14L22 4.14a.52.52 0 01.14.36v13.38a.5.5 0 01-.5.5h-6.87a.23.23 0 00-.24.21 7.71 7.71 0 01-.37 1.41.25.25 0 00.23.34h7.73a2 2 0 002-2V4.29a2 2 0 00-.58-1.41z" />
      <path d="M12.62 10.88a1 1 0 00-.63.24.25.25 0 000 .35 7.87 7.87 0 011.15 1.3.24.24 0 00.2.11h3.26a1 1 0 000-2zM6.62 10.62a6.75 6.75 0 106.76 6.76 6.75 6.75 0 00-6.76-6.76zm0 11.5a4.75 4.75 0 114.76-4.74 4.75 4.75 0 01-4.76 4.74z" />
      <path d="M8.62 16.38h-.81a.18.18 0 01-.19-.2v-1.3a1 1 0 00-2 0v2.5a1 1 0 001 1h2a1 1 0 100-2z" />
    </svg>
  );
};

export default SvgPendingAgreementsFilled;
