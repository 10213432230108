// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import { selectEntity } from 'actions/map';

import InventoryInfo from './InventoryInfo';
import { useStyles } from './styles';

type EntityItemProps = {
  title: string,
  subtitle: string,
  description: string,
  type: string,
  color: string
};

type MapInventoryItemProps = {
  entityProps: EntityItemProps,
  info: any,
  entity: string
};

const MapInventoryItem = ({ info, entity, entityProps }: MapInventoryItemProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(selectEntity(info));
  };

  return (
    <ListItem onClick={handleClick} className={classes.itemContainer}>
      <InventoryInfo entity={entity} entityProps={entityProps} markerType={info.type_detail?.id} />
    </ListItem>
  );
};

export default MapInventoryItem;
