import * as React from 'react';

const SvgPencilWriteAlternate = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <g fill={fill}>
        <path d="M20 11.49a1 1 0 00-1 1V21a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1h10a1 1 0 000-2H3a3 3 0 00-3 3v17a3 3 0 003 3h15a3 3 0 003-3v-8.5a1 1 0 00-1-1.01z" />
        <path d="M18.82 3.05a.51.51 0 00-.71 0l-7.81 7.81a.45.45 0 00-.11.17l-1.42 3.54a.51.51 0 00.11.54.54.54 0 00.54.11L13 13.8a.45.45 0 00.17-.11l7.81-7.81a.51.51 0 00.15-.35.53.53 0 00-.15-.36zM23.41.58a2 2 0 00-2.82 0l-1.06 1.06a.48.48 0 000 .7l2.12 2.13a.51.51 0 00.7 0l1.06-1.07a2 2 0 000-2.82z" />
      </g>
    </svg>
  );
};

export default SvgPencilWriteAlternate;
