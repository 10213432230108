// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';
import { THEME } from 'GlobalStyles';
import { useBulkQuotaRestriction } from 'hooks/bulkQuotaRestriction';
import moment from 'moment-timezone';
import strings from 'strings';
import CustomDatePicker from 'UI/components/atoms/CustomDatePicker';
import FPIcon from 'UI/components/atoms/FPIcon';
import Text from 'UI/components/atoms/Text';
import { When } from 'UI/components/atoms/When';
import { FormFieldsMap } from 'UI/components/organisms/BulkEmailForm/fields';
import DecisionDialog from 'UI/components/organisms/DecisionDialog';
import { BulkMenuItems, DateFormats } from 'UI/constants/defaults';
import { SvgInformationCircle } from 'UI/res';

import { useScheduleBulkDialog } from './ScheduleDialog.hooks';
import { useScheduleDialogStyles } from './styles';

const popoverProps = {
  style: {
    zIndex: 1500
  }
};

const { dialogs: dialogsCopies } = strings.bulkEmails;

type ScheduleDialogProps = {
  notifyBulkQuotaRestriction: () => void,
  onClose: () => void,
  onSubmitBulkEmail: () => void,
  remainingQuota: number,
  searchProjectSize: number,
  shouldUnregisterFieldsOnClose: boolean
};

const ScheduleDialog = ({
  onClose,
  onSubmitBulkEmail,
  shouldUnregisterFieldsOnClose = false,
  notifyBulkQuotaRestriction,
  remainingQuota,
  searchProjectSize
}: ScheduleDialogProps) => {
  const classes = useScheduleDialogStyles();
  const currentTimeZone = moment.tz(moment.tz.guess()).format('z');
  const {
    edit: editBulkInPreview,
    data: { sendDate }
  } = useSelector(store => store.bulkEmail.domain.bulkEmailInPreview);
  const { selectedMenuItem, status: bulkEmailStatus } = useSelector(store => store.bulkEmail.ui);

  const { formValues, errors, handleDateChange, handleTimeChange, submitHandler } =
    useScheduleBulkDialog({
      notifyBulkQuotaRestriction,
      isBulkInPreviewBeingEdited: editBulkInPreview,
      onSubmitBulkEmail,
      remainingQuota,
      searchProjectSize,
      sendDate,
      shouldUnregisterFieldsOnClose
    });

  const { bulkQuotaRestriction } = useBulkQuotaRestriction({ searchProjectSize });

  const isCurrentDay = formValues[FormFieldsMap.ScheduleDate.key]?.isSame(moment(), 'day');

  const formMode =
    editBulkInPreview && selectedMenuItem === BulkMenuItems.Schedule ? 'edit' : 'create';

  return (
    <DecisionDialog
      {...dialogsCopies.scheduleForm[formMode].copies}
      isOpened
      fetching={bulkEmailStatus.loading}
      onClose={onClose}
      onConfirm={submitHandler}
      severity="info"
      showCloseButton={false}
      showSeverityIcon={false}
      withButtons="YesNo"
    >
      <Text variant="subtitle1" text={dialogsCopies.scheduleForm[formMode].body} />
      <CustomDatePicker
        autoOk
        disablePast
        error={!!errors?.scheduleDate}
        helperText={errors?.scheduleDate?.message}
        label="Date"
        name={FormFieldsMap.ScheduleDate.key}
        onDateChange={handleDateChange}
        PopoverProps={popoverProps}
        value={formValues[FormFieldsMap.ScheduleDate.key] || null}
      />
      <CustomDatePicker
        autoOk
        error={!!errors?.scheduleTime}
        helperText={errors?.scheduleTime?.message}
        label="Time"
        mask="__:__ _M"
        name={FormFieldsMap.ScheduleTime.key}
        onDateChange={handleTimeChange}
        placeholder="HH:MM AM"
        PopoverProps={popoverProps}
        timeFormat={DateFormats.SimpleTime}
        value={formValues[FormFieldsMap.ScheduleTime.key] || null}
        variant="keyboardTime"
        withTime
        autoComplete="off"
      />
      <Grid container alignItems="center" wrap="nowrap" className={classes.timezoneInfoContainer}>
        <FPIcon size={18} color={THEME.palette.primary.main} className={classes.infoIcon}>
          <SvgInformationCircle />
        </FPIcon>
        <Text
          text={strings.formatString(dialogsCopies.scheduleForm.timezoneInfo, { currentTimeZone })}
        />
      </Grid>
      <When condition={isCurrentDay && bulkQuotaRestriction && remainingQuota}>
        <br />
        <Alert severity="warning">
          {strings.formatString(
            strings.bulkEmails.dialogs.restrictions.bodyMessages.quotaNearlyReached,
            { remainingQuota, searchProjectSize }
          )}
        </Alert>
      </When>
    </DecisionDialog>
  );
};

export default ScheduleDialog;
