import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { extractFilenameFromUrl, formatBytes } from 'UI/utils';

import {
  AttachmentMessageChip,
  AttachmentMessageWrapper,
  AttachmentSizeLabel
} from './Message.styled';

export const AttachmentMessage = ({ attachment, isOutbound }) => {
  const { url, size } = attachment;

  const fileName = extractFilenameFromUrl(url);

  const finalSize = size && Number(size) > 0 ? Number(size) : null;

  return (
    <AttachmentMessageWrapper>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <AttachmentMessageChip
          icon={<GetAppIcon />}
          label={fileName}
          color="primary"
          isOutbound={isOutbound}
          clickable
        />
      </a>
      {finalSize && (
        <AttachmentSizeLabel variant="body2" isOutbound={isOutbound}>
          {formatBytes(finalSize, 1)}
        </AttachmentSizeLabel>
      )}
    </AttachmentMessageWrapper>
  );
};
