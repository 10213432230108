// @flow

import { useCallback } from 'react';
import { getPropertyByPath } from 'UI/utils/inventory';

export const useExtendedParams = (paramsBuilder, results) => {
  const extendParams = useCallback(
    () =>
      Object.fromEntries(
        paramsBuilder.map(item => {
          const itemPropData = item.value
            ? item.value
            : results && getPropertyByPath(results, item.pathToValue);

          return [item.name, itemPropData];
        })
      ),
    [paramsBuilder, results]
  );

  return { extendParams };
};
