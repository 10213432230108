// @flow
import API from 'services/API';
import { OptionRenderers } from 'UI/utils/renderers';

import { NODE_CONFIGURATION, NODE_NAMES } from './pilIndustries.constants';
import { IndustryType, PositionType, SpecialtyType, SubspecialtyType } from './pilIndustries.types';

export const parseNodeId = (nodeId: string) => nodeId.split('-');

export const findNodeInTree = (tree: object, nodeId: string) => {
  const visibleNodes = Object.keys(tree)
    .map(key => tree[key])
    .flat();

  const item = visibleNodes.find(({ id }) => id === nodeId);
  const [, id] = parseNodeId(nodeId);

  return {
    ...item,
    id
  };
};

export const getTreeViewFormat = (results: Array<IndustryType>) =>
  results.map(({ id, title }) => ({
    id: `${NODE_CONFIGURATION[NODE_NAMES.Industry].Key}-${id}`,
    title,
    type: NODE_NAMES.Industry
  }));

export const getRenderOption = (
  optionType: 'industry' | 'specialty' | 'subspecialty' | 'position',
  option: SubspecialtyType | SpecialtyType | IndustryType | PositionType
): string => {
  const {
    title,
    industry_title: industryTitle = '',
    specialty_title: specialtyTitle = ''
  } = option;

  const mapRenderOptions = {
    industry: title,
    specialty: OptionRenderers.specialty({ industry: industryTitle, title }),
    subspecialty: OptionRenderers.subspecialty({
      industry: industryTitle,
      specialty: specialtyTitle,
      title
    }),
    position: OptionRenderers.position({
      industry: industryTitle,
      specialty: specialtyTitle,
      position: title
    })
  };

  return mapRenderOptions[optionType];
};

export const getOptionLabel = (
  optionType: 'industry' | 'specialty' | 'subspecialty' | 'position',
  option: SubspecialtyType | SpecialtyType | IndustryType | PositionType
): string => {
  const {
    title,
    industry_title: industryTitle = '',
    specialty_title: specialtyTitle = ''
  } = option;

  const mapOptionLabels = {
    industry: title,
    specialty: `${industryTitle} › ${title}`,
    subspecialty: `${industryTitle} › ${specialtyTitle} › ${title}`,
    position: `${industryTitle} › ${specialtyTitle}`
  };

  return mapOptionLabels[optionType];
};

const getIndustryIdBySpecialtyId = (
  industries: IndustryType[],
  specialtyId: number
): number | null => {
  const industry = industries.find(ind =>
    ind.specialties.some(specialty => specialty.id === specialtyId)
  );
  return industry ? industry.id : null;
};

export const getSelectionIndustryId = (
  {
    id,
    industry_id: industryId,
    specialty_id: specialtyId,
    type
  }: SubspecialtyType | SpecialtyType | IndustryType | PositionType,
  results: Array<IndustryType>
) => {
  if (type === 'position') {
    const hasIndustryId = !!industryId;
    return hasIndustryId ? industryId : getIndustryIdBySpecialtyId(results, specialtyId);
  }

  return type === 'industry' ? id : industryId;
};

export const getSelectionSpecialtyId = ({
  id,
  specialty_id: specialtyId,
  type
}: SubspecialtyType | SpecialtyType | IndustryType | PositionType) => {
  if (type === 'industry') return null;
  if (type === 'position') return specialtyId;

  return type === 'specialty' ? id : specialtyId;
};

export const getExpandedIds = (
  {
    id,
    industry_id: industryId,
    specialty_id: specialtyId,
    type
  }: SubspecialtyType | SpecialtyType | IndustryType | PositionType,
  optionalIndustryId?: number
) => {
  const mapIds = {
    industry: [`industry-${id}`],
    specialty: [`industry-${industryId}`, `specialty-${id}`],
    subspecialty: [`industry-${industryId}`, `specialty-${specialtyId}`],
    position: [`industry-${industryId ?? optionalIndustryId}`, `specialty-${specialtyId}`]
  };

  return mapIds[type];
};

export const getPilOptions = async ({
  id: selectedId,
  type
}: SubspecialtyType | SpecialtyType | IndustryType) => {
  if (!selectedId) return null;

  try {
    const nodeConfig = NODE_CONFIGURATION[type];
    if (!nodeConfig || !nodeConfig.ChildrenEndpoint) return null;

    const endpoint = nodeConfig.ChildrenEndpoint.replace(':id', selectedId);
    const response = await API.get(endpoint);

    return response.data.map(({ id, title }) => ({
      id: `${nodeConfig.ChildrenKey}-${id}`,
      title,
      type: nodeConfig.ChildrenKey
    }));
  } catch (error) {
    return null;
  }
};
