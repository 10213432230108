import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1, 2)
  },
  label: {
    marginLeft: theme.spacing(1),
    fontWeight: 700
  }
}));
