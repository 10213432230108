// @flow
import React from 'react';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import { Endpoints } from 'UI/constants/endpoints';

interface AutocompleteByWeekProps {
  onSelectWeek: (event: any, week: object) => void;
  defaultOptions: object[];
  value: any;
  onOptionsLoaded: (items: object[]) => void;
}

export const AutocompleteByWeek = ({
  onSelectWeek,
  value,
  defaultOptions,
  onOptionsLoaded
}: AutocompleteByWeekProps) => {
  const dataProps = defaultOptions
    ? {
        defaultOptions
      }
    : {
        url: Endpoints.ToolsUsageReportWeeks
      };

  return (
    <AutocompleteSelect
      disableClearable
      groupBy={option => option.month}
      name="weekFilter"
      onSelect={onSelectWeek}
      onOptionsLoaded={onOptionsLoaded}
      placeholder="Week"
      value={value}
      {...dataProps}
    />
  );
};
