// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import FPAvatar from 'UI/components/atoms/FPAvatar';
import { When } from 'UI/components/atoms/When';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { colors, CopyIcon } from 'UI/res';
import { getInitials } from 'UI/utils/getinitials';

import { useStyles } from './styles';

type Teams = {
  id: number,
  coach: string,
  industry: string,
  specialty?: string,
  subspecialty?: string
};

type DigPopoverProps = {
  titleName?: string,
  initials?: string,
  coach?: string,
  onCopyClick: (text: string) => void,
  backgroundColor?: string,
  recruiterExtension?: string,
  recruiterEmail?: string,
  teams: Teams
};

const DigPopover = ({
  titleName,
  initials,
  coach,
  recruiterExtension,
  recruiterEmail,
  backgroundColor,
  teams,
  onCopyClick
}: DigPopoverProps) => {
  const classes = useStyles();

  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const shouldDisplayIndustryInfo = checkIfFeatureFlagEnabled(FeatureFlags.SpecificPILLocation);

  const initialsOrDefault = initials || getInitials(titleName);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.recruiterInfoContainer}>
        <FPAvatar backgroundColor={backgroundColor}>{initialsOrDefault}</FPAvatar>
        <div className={classes.nameContainer}>
          <Typography className={classes.titleName}>{titleName}</Typography>
          <Typography>Team: {coach}</Typography>
        </div>
      </div>
      <When condition={shouldDisplayIndustryInfo}>
        <div className={classes.specialtiesContainer}>
          {teams?.map((item: Teams) => {
            return (
              <div key={item.id} className={classes.specialtiesSubcontainer}>
                <Typography>{item?.industry}</Typography>
                <Typography>
                  {item?.specialty} {item?.subspecialty && `> ${item?.subspecialty}`}
                </Typography>
              </div>
            );
          })}
        </div>
      </When>
      <div className={classes.contactContainer}>
        <div className={classes.emailContainer}>
          <Typography onClick={() => onCopyClick(recruiterEmail)} className={classes.emailText}>
            {recruiterEmail}
          </Typography>
          <CopyIcon
            fill={colors.navLink}
            size={14}
            className={clsx(classes.copyButton, 'copyButton')}
            title="Copy to Clipboard"
            onClick={() => onCopyClick(recruiterEmail)}
          />
        </div>
        <span>Ext. {recruiterExtension} </span>
      </div>
    </div>
  );
};

// NOTE: default props set for display the design please replace with according data

DigPopover.defaultProps = {
  titleName: '',
  initials: '',
  coach: '',
  backgroundColor: '',
  recruiterExtension: '',
  recruiterEmail: ''
};

export default DigPopover;
