// @flow
import * as React from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { showAlert } from 'actions/app';
import clsx from 'clsx';
import copy from 'copy-to-clipboard';
import type { LinkConfigProps } from 'types/app';
import FPAvatar from 'UI/components/atoms/FPAvatar';
import FPChip from 'UI/components/atoms/FPChip';
import FPLink from 'UI/components/atoms/FPLink';
import { colors, CopyIcon } from 'UI/res';

import { styles, useStyles } from './styles';

export const InfoLabelModes = Object.freeze({
  TEXT: 'text',
  LOCAL_LINK: 'localLink',
  EXTERNAL_LINK: 'externalLink',
  RING_CENTRAL: 'ringCentral',
  CHIP: 'chip'
});

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

type InfoLabelProps = {
  title: string,
  description: string,
  cropped?: boolean,
  colorIndicator: any,
  isLoading: boolean | null,
  linkProps: LinkConfigProps,
  mode: 'text' | 'localLink' | 'externalLink' | 'ringCentral' | 'chip',
  customValueRender?: any,
  canCopy: boolean,
  className?: string,
  avatarProps: Object,
  itemId: string,
  variant: string,
  textProps?: Object,
  secondaryText?: string,
  Icon?: React.Node
};

const InfoLabel = ({
  title,
  description,
  canCopy,
  cropped,
  colorIndicator,
  isLoading,
  linkProps,
  customValueRender,
  mode,
  avatarProps,
  className,
  itemId,
  variant,
  textProps,
  secondaryText,
  Icon
}: InfoLabelProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const elementToCopy = mode === InfoLabelModes.EXTERNAL_LINK ? linkProps?.url : description;

  const handleCopyClick = e => {
    e.preventDefault();
    e.stopPropagation();
    copy(elementToCopy);
    dispatch(
      showAlert({
        severity: 'success',
        title: 'Awesome',
        body: 'Copied to Clipboard',
        autoHideDuration: 1000
      })
    );
  };

  const renderCopyButton = ({ color }) =>
    canCopy &&
    elementToCopy && (
      <CopyIcon
        fill={color}
        size={16}
        className={clsx(classes.copyButton, 'copyButton')}
        title="Copy to Clipboard"
        onClick={handleCopyClick}
      />
    );

  const isEmailValid = email => EMAIL_REGEX.test(email);
  const externalLinkUrl = isEmailValid(linkProps?.url)
    ? { href: `mailto:${linkProps?.url}` }
    : null;

  const infoLabelTypes = {
    text: (
      <Typography
        role="definition"
        component="dd"
        variant={variant}
        title={description}
        className={clsx(classes.infoLabelText, classes.infoLabel)}
        noWrap={cropped}
      >
        {description}
        {renderCopyButton({ color: colors.completeBlack })}
      </Typography>
    ),
    localLink: description ? (
      <div className={classes.linkContainer}>
        {avatarProps && (
          <div className={classes.avatarLink}>
            <FPAvatar {...avatarProps} />
          </div>
        )}

        <FPLink
          linkProps={{ type: 'local', ...linkProps }}
          className={clsx(classes.infoLabel, className)}
          itemId={itemId}
        >
          {description}
          {renderCopyButton({ color: colors.navLink })}
        </FPLink>
      </div>
    ) : null,
    externalLink: (
      <div className={classes.linkContainer}>
        {linkProps?.icon && <div className={classes.emailValidationIcon}>{linkProps.icon}</div>}
        <FPLink
          linkProps={{ type: 'external', target: '_blank', ...linkProps }}
          {...externalLinkUrl}
        />

        {linkProps?.url ? renderCopyButton({ color: colors.navLink }) : null}
      </div>
    ),
    ringCentral: (
      <>
        <div className={classes.linkContainer}>
          <FPLink linkProps={{ type: 'ringCentral', ...linkProps }} />
          <span>{description}</span>
        </div>
        {secondaryText && (
          <Typography role="term" title={secondaryText} variant="subtitle2">
            {secondaryText}
          </Typography>
        )}
      </>
    ),
    chip: Array.isArray(description) ? (
      description.map(item => (
        <div className={classes.chip}>
          <FPChip
            key={item.label}
            label={item.label}
            color={colorIndicator && colorIndicator}
            size="small"
          />
        </div>
      ))
    ) : (
      <FPChip label={description} color={colorIndicator && colorIndicator} size="small" />
    )
  };

  /* IMPORTANT: this atom is being rendering excecively into the DOM (specially in the tabs page)
     Use the least amount possible of components/tags in order to prevent slow deficient rendering. */
  return (
    <>
      {isLoading ? (
        <Skeleton style={styles.titleSkeleton} />
      ) : (
        <Typography
          component="dt"
          noWrap
          role="term"
          title={title}
          variant="overline"
          {...textProps}
        >
          {title} {Icon && <Icon size={16} />}
        </Typography>
      )}

      {isLoading ? (
        <Skeleton style={styles.descriptionSkeleton} />
      ) : (
        customValueRender || infoLabelTypes[mode]
      )}
    </>
  );
};

InfoLabel.defaultProps = {
  cropped: true,
  colorIndicator: undefined,
  description: '',
  isLoading: false,
  mode: 'text',
  linkProps: undefined,
  canCopy: false,
  className: null,
  customValueRender: null,
  avatarProps: undefined,
  itemId: '',
  variant: 'subtitle1',
  textProps: null,
  secondaryText: null,
  Icon: null
};

export default InfoLabel;
