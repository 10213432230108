// @flow
import React from 'react';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';
import HistoryItem from 'UI/components/molecules/HistoryItem';
import { TypeHistoryItem } from 'UI/constants/defaults';

type FeeAgreementLogsProps = {
  items: any[]
};

const FeeAgreementLogs = ({ items }: FeeAgreementLogsProps) =>
  items && items.length > 0 ? (
    <>
      <Typography variant="subtitle1">{strings.feeAgreements.details.eventHistory}</Typography>
      <List dense>
        {items.map(({ id: itemId, created_at: date, event, log_text: logText }) => (
          <HistoryItem
            key={itemId}
            date={date}
            type={TypeHistoryItem.Custom}
            action={logText || event.name}
          />
        ))}
      </List>
    </>
  ) : null;

FeeAgreementLogs.defaultProps = {
  items: []
};

export default FeeAgreementLogs;
