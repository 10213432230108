// @flow
import { JSON_SCHEMA_CLIENT } from 'services/API';
import { Endpoints } from 'UI/constants/endpoints';

export const saveCandidateDataSheet = async dataSheet => {
  return JSON_SCHEMA_CLIENT.put(`/${Endpoints.CandidateDataSheetSave}`, dataSheet);
};
export const getDataSheetByCandidateId = (candidateId: string) => {
  return JSON_SCHEMA_CLIENT.get(
    `/${Endpoints.CandidateDataSheetByCandidateId.replace(':id', candidateId)}`
  );
};
