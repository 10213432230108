// @flow
import { makeRequest } from 'services/utils';
import { Endpoints } from 'UI/constants/endpoints';

export const getOutlookQuota = () =>
  makeRequest({
    method: 'get',
    url: Endpoints.BulkEmailOutlookQuota,
    apiVersion: 2
  });
