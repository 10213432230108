// @flow
import React, { useEffect } from 'react';
import { FieldValues, FormContextValues } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { CancelCheckBox } from 'UI/res/icons/custom';

const checkSize = 20;
const OutlineCheck = () => {
  return <CheckBoxOutlineBlankIcon style={{ width: checkSize, height: checkSize }} />;
};

interface CheckboxListProps {
  checklist: string[];
  form: FormContextValues<FieldValues>;
  name: string;
  customValidation: field => any;
  required: true;
  variant: 'normal' | 'remove';
}

const CheckboxList = ({
  form,
  checklist,
  name,
  customValidation,
  required,
  variant
}: CheckboxListProps) => {
  const { register, unregister, watch, setValue, errors } = form;

  useEffect(() => {
    register(
      { name },
      { required: required && 'select at least one option', validate: customValidation }
    );
    return () => unregister(name);
  }, [customValidation, name, register, required, unregister]);

  const selectedFields = watch(name, []);
  const handleCheckChange = (event: any) => {
    const { name: nameValue, checked } = event.target;
    const fields = checked
      ? [...selectedFields, nameValue]
      : selectedFields.filter(field => field !== nameValue);
    setValue(name, [...new Set(fields)], true);
  };

  const getTypeCheckbox = currentName => {
    switch (variant) {
      case 'remove':
        return (
          <Checkbox
            name={currentName}
            icon={<OutlineCheck />}
            checkedIcon={<CancelCheckBox size={checkSize} />}
          />
        );
      default:
        return <Checkbox name={currentName} />;
    }
  };

  return (
    <FormControl component="fieldset" error={!!errors[name]}>
      <FormGroup aria-label={name} name={name} onChange={handleCheckChange}>
        {checklist &&
          checklist.map(each => (
            <FormControlLabel key={each} label={each} value="end" control={getTypeCheckbox(each)} />
          ))}
        <FormHelperText>{errors[name]?.message}</FormHelperText>
      </FormGroup>
    </FormControl>
  );
};

CheckboxList.defaultProps = {
  required: true,
  variant: 'normal',
  checklist: []
};

export default CheckboxList;
