// @flow
import React from 'react';
import FPAvatar from 'UI/components/atoms/FPAvatar';
import Text from 'UI/components/atoms/Text';

import { styles, useStyles } from './styles';

type ItemChipsProps = {
  initials: string,
  mainText: string,
  secondaryText: string,
  userEmail?: string
};

const ItemChip = ({ initials, mainText, secondaryText, userEmail }: ItemChipsProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <FPAvatar
          role="img"
          aria-label="avatar"
          style={styles.marginContent}
          mode="user"
          userEmail={userEmail}
          size="small"
        >
          {initials}
        </FPAvatar>
        <Text className={classes.mainText} variant="body2" text={mainText} />
      </div>
      <Text
        className={classes.secondaryText}
        aria-label="secondaryText"
        variant="body2"
        text={secondaryText}
      />
    </div>
  );
};

ItemChip.defaultProps = {
  initials: '',
  mainText: '',
  secondaryText: '',
  userEmail: ''
};

export default ItemChip;
