// @flow
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Skeleton from '@material-ui/lab/Skeleton';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import type { Filters } from 'types/app';
import {
  AllFiltersDefinition,
  FilterDefinition
} from 'UI/components/templates/SideFiltersLayout/filters';

import { useStyles } from './styles';

type ActiveFiltersProps = {
  filters: Filters,
  isLoading: boolean,
  onFilterRemove: any => any,
  shouldDisplayLabel?: boolean,
  extraFiltersConfig?: FilterDefinition[],
  onReset: () => void
};

const flattenFilters = (filters: Filters) => {
  if (!filters) return [];

  const flattenedFilters = [];

  Object.keys(filters)
    .map(key => {
      const value = !isNil(filters[key]?.value) ? filters[key]?.value : filters[key];
      return {
        id: key,
        values: isArray(value) ? value : [value],
        displayLabel: filters[key]?.displayLabel
      };
    })
    .forEach(({ id, values, displayLabel }) =>
      values.forEach(value => flattenedFilters.push({ id, value, displayLabel }))
    );

  return flattenedFilters.filter(({ value }) => !isNil(value) && value !== '');
};

const getDisplayLabel = (filter: Filters, extraFiltersConfig = []) => {
  if (!filter || isNil(filter.value)) return '';

  const extraFilterNames = extraFiltersConfig.map(filterItem => filterItem.name);
  const cleanedFilters = AllFiltersDefinition.filter(
    filterConfig => !extraFilterNames.includes(filterConfig.name)
  );

  const finalFilters = [...cleanedFilters, ...extraFiltersConfig];

  const filterDef = finalFilters.find(({ name }) => name === filter.id);

  const renderer = filterDef?.getOptionLabel;
  const title =
    (renderer && renderer(filter.value)) ||
    filter.value.compound_title ||
    filter.value.title ||
    filter.value.full_name ||
    filter.value;

  const displayLabelPrefix = filter.displayLabel ? `${filter.displayLabel}: ` : '';
  return displayLabelPrefix + title;
};

const ActiveFilters = (props: ActiveFiltersProps) => {
  const {
    filters,
    isLoading,
    onFilterRemove,
    extraFiltersConfig,
    shouldDisplayLabel = true,
    onReset
  } = props;
  const classes = useStyles();

  const handleDeleteClick = (filterId: string, value: any) => {
    onFilterRemove && onFilterRemove(filterId, value);
  };

  const activeFilters = flattenFilters(filters);

  const filtersSkeleton = (numberOfFilters: number) => {
    return Array.from({ length: numberOfFilters + 1 })
      .map((_, index) => index)
      .map(index => <Skeleton key={`filter-${index}`} width="10%" height={32} radius={16} />);
  };

  return (
    activeFilters.length > 0 && (
      <div className={classes.root}>
        {isLoading && filtersSkeleton(activeFilters.length)}
        {!isLoading && (
          <>
            {shouldDisplayLabel && <b>Filters: </b>}
            {activeFilters.map(filter => {
              const filterValue = filter.value?.id || filter.value;
              return (
                <Chip
                  key={`${filter.id}-${filterValue}`}
                  label={getDisplayLabel(filter, extraFiltersConfig)}
                  onDelete={() => {
                    handleDeleteClick(filter.idKey ?? filter.id, filterValue);
                  }}
                  color="primary"
                  className={classes.chip}
                />
              );
            })}
            {activeFilters.length > 1 && (
              <Chip label="Reset all filters" onDelete={onReset} className={classes.chip} />
            )}
          </>
        )}
      </div>
    )
  );
};

export default ActiveFilters;
