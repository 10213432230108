import { THEME } from 'GlobalStyles';

export const fakePromise = async response => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(response);
    }, 2000);
  });
};

export const CHART_COLORS = {
  needle: THEME.palette.grey[600],
  green: THEME.palette.success.main,
  red: THEME.palette.error.main,
  yellow: THEME.palette.warning.main,
  transparent: 'rgba(0, 0, 0, 0)'
};
