// @flow
import React from 'react';
import { Grid } from '@material-ui/core';
import type { FPActionButtonProps, TreeViewLoaderPropTypes } from 'types/app';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { When } from 'UI/components/atoms/When';

import { useStyles } from './styles';
import TreeViewLoader from './TreeViewLoader';

type TreeViewListPropTypes = {
  buttonsList?: Array<FPActionButtonProps>,
  TreeViewLoaderProps?: TreeViewLoaderPropTypes
};

const TreeViewList = ({ buttonsList = null, TreeViewLoaderProps = {} }: TreeViewListPropTypes) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.treeViewContainer} wrap="nowrap">
      <When condition={buttonsList}>
        <Grid item className={classes.buttonsContainer}>
          {buttonsList.map(buttonProps => (
            <Grid key={buttonProps.text} item xs={12 / buttonsList.length}>
              <FPActionButton {...buttonProps} variant="text" className={classes.actionButton} />
            </Grid>
          ))}
        </Grid>
      </When>
      <Grid item className={classes.treeViewLoader}>
        <TreeViewLoader {...TreeViewLoaderProps} />
      </Grid>
    </Grid>
  );
};

export default TreeViewList;
