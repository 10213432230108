import moment from 'moment';
import { BulkBlockOptions } from 'UI/constants/defaults';

const getBulkTypeArrayTrueValue = arr => (arr?.length > 0 ? arr : null);
const scheduleDateInboundAccesor = ({ sendDate }) => (sendDate ? moment(sendDate) : null);

export const FormFieldsMap = {
  AllowMarketingCandidates: {
    key: 'allowMarketingCandidates',
    inboundAccesor: 'emailConfig.marketing.allowMarketingCandidates'
  },
  AllowRecruitHirings: {
    key: 'allowRecruitHirings',
    inboundAccesor: 'emailConfig.recruiting.allowRecruitHirings'
  },
  BlockCompanies: {
    key: 'blockCompanies',
    inboundAccesor: 'emailConfig.marketing.blockCompanies'
  },
  BlockResend: {
    key: 'blockResend',
    inboundAccesor: ({ emailConfig }) => String(emailConfig?.blockResend)
  },
  BlockSigned: {
    key: 'blockSigned',
    inboundAccesor: 'emailConfig.marketing.blockClientCompanies'
  },
  BulkQuota: {
    key: 'bulkQuota'
  },
  BulkScope: {
    key: 'bulkScope',
    inboundAccesor: 'emailConfig.bulkType'
  },
  EmailBody: {
    key: 'emailBody',
    inboundAccesor: 'emailBody.html'
  },
  GeneralBulkCategory: {
    key: 'generalBulkCategory',
    inboundAccesor: 'emailConfig.generalCategory'
  },
  JobOrderRecruitment: {
    key: 'jobOrderRecruitment',
    inboundAccesor: ({ emailConfig }) =>
      getBulkTypeArrayTrueValue(emailConfig?.recruiting.recruitingJobOrders)
  },
  MarketCandidates: {
    key: 'marketCandidates',
    inboundAccesor: ({ emailConfig }) =>
      getBulkTypeArrayTrueValue(emailConfig?.marketing.marketingCandidates)
  },
  RemovedAttachments: {
    key: 'removedAttachments'
  },
  ResendTime: {
    key: 'resendTime',
    inboundAccesor: ({ emailConfig }) =>
      BulkBlockOptions.intervals.find(interval => interval.id === `${emailConfig?.blockDays},days`)
  },
  ScheduleDate: {
    key: 'scheduleDate',
    inboundAccesor: scheduleDateInboundAccesor
  },
  ScheduleTime: {
    key: 'scheduleTime',
    inboundAccesor: scheduleDateInboundAccesor
  },
  SendViaOutlook: {
    key: 'sendViaOutlook',
    inboundAccesor: 'emailConfig.sendViaOutlook'
  },
  SmartTags: {
    key: 'smartTags'
  },
  Subject: {
    key: 'subject',
    inboundAccesor: 'emailBody.subject'
  },
  Template: {
    key: 'template',
    inboundAccesor: ({ emailConfig }) => ({
      id: emailConfig?.template.id,
      name: emailConfig?.template.name
    })
  },
  To: {
    key: 'to',
    paramKey: 'searchProject'
  }
};

export const scheduleKeys = [FormFieldsMap.ScheduleDate.key, FormFieldsMap.ScheduleTime.key];
