// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import groupBy from 'lodash/groupBy';
import strings from 'strings';
import type { FileExplorerSection } from 'types/app';
import { When } from 'UI/components/atoms/When';
import FileUploader from 'UI/components/molecules/FileUploader';

import { useStyles } from '../styles';
import {
  EntityTypesByCategoryId,
  FileKeyPrefix,
  FileUploaderMode,
  getfileExplorerSectionByCategory,
  MAX_NUMBER_OF_FILES,
  SendoutFileCategoriesWithSendout
} from '../utils';

export type DebriefFilesSectionProps = {
  categories: Array,
  disabled?: boolean,
  endpoint: string,
  fileExplorerSections?: FileExplorerSection[],
  files?: Array,
  onSelectedFiles: () => {}
};

const DebriefFilesSection = ({
  categories,
  disabled,
  endpoint,
  fileExplorerSections,
  files,
  onSelectedFiles
}: DebriefFilesSectionProps) => {
  const classes = useStyles();

  const groupedFiles = groupBy(files, 'file_type_id');

  const handleFileChange = (value: any, name?: string) => onSelectedFiles(name, value);

  return (
    <Grid container>
      <Typography className={classes.secondaryTitle}>
        {strings.sendouts.attachments.noFilesSend}
      </Typography>

      <When condition={categories.length > 0}>
        <Grid item xs={12}>
          {categories.map(category => {
            const newCategory = {
              ...category,
              id: SendoutFileCategoriesWithSendout[category.id]
            };

            const filesByCategory = groupedFiles[newCategory.id];

            return (
              <FileUploader
                category={newCategory}
                defaultEntityType={EntityTypesByCategoryId[category.id]}
                disabled={disabled}
                endpoint={endpoint}
                fileExplorerSections={getfileExplorerSectionByCategory(
                  fileExplorerSections,
                  newCategory.id
                )}
                files={filesByCategory}
                key={category.id}
                maxNumberOfFiles={MAX_NUMBER_OF_FILES}
                mode={FileUploaderMode.CategoryField}
                name={`${FileKeyPrefix}${category.id}`}
                onAttachmentsChanged={handleFileChange}
                shouldAppendCategory
                softDeleteForExistingItems={filesByCategory}
              />
            );
          })}
        </Grid>
      </When>
    </Grid>
  );
};

DebriefFilesSection.defaultProps = {
  categories: [],
  files: []
};

export default DebriefFilesSection;
