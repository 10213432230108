// @flow
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormHelperText } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import type { TreeViewLoaderPropTypes } from 'types/app';
import TreeViewLoader from 'UI/components/organisms/TreeViewList/TreeViewLoader';
import { TREE_VIEW_ENTITY_TYPES } from 'UI/constants/defaults';

import { useStyles } from './styles';

const NO_OPTION_SELECTED = { name: 'NoOption' };
const validateValueIsNoOption = value => value === NO_OPTION_SELECTED.name;

type TreeViewSelectorPropTypes = {
  defaultValue?: Object,
  enableNoOption?: boolean,
  formFieldName: string,
  placeholder?: string,
  requiredMessage?: string,
  shouldRegisterWithin?: boolean,
  TreeViewLoaderProps?: TreeViewLoaderPropTypes
};

const TreeViewSelector = ({
  defaultValue = null,
  enableNoOption = false,
  formFieldName,
  placeholder = null,
  requiredMessage = null,
  shouldRegisterWithin = false,
  TreeViewLoaderProps = {}
}: TreeViewSelectorPropTypes) => {
  const { errors, getValues, register, setValue, unregister, watch } = useFormContext();
  const menuList = useRef(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const currentFormValue = watch(formFieldName);
  const classes = useStyles({ itemId: currentFormValue?.id });

  useEffect(() => {
    if (shouldRegisterWithin) {
      register({ name: formFieldName }, { required: requiredMessage });
      defaultValue && setValue(formFieldName, defaultValue);
    }
    return () => {
      shouldRegisterWithin && unregister(formFieldName);
    };
  }, [
    defaultValue,
    formFieldName,
    getValues,
    register,
    requiredMessage,
    setValue,
    shouldRegisterWithin,
    unregister
  ]);

  const toggleDropdown = isOpen => () => setIsDropdownOpen(isOpen);

  const handleChange = ({ target }) => {
    if (validateValueIsNoOption(target.value?.name)) setValue(formFieldName, null);
    toggleDropdown(false)();
  };

  const handleOutsideClick = ({ target }) => {
    if (validateValueIsNoOption(target?.value?.name) || !menuList.current.contains(target)) {
      toggleDropdown(false)();
    }
  };

  const handleItemSelection = async selectedItem => {
    if (selectedItem.entity !== TREE_VIEW_ENTITY_TYPES.item) return;
    setValue(formFieldName, selectedItem, true);
    toggleDropdown(false)();
  };

  const handleFolderSelection = selectedFolder => {
    setValue(
      formFieldName,
      {
        ...selectedFolder,
        entity: TREE_VIEW_ENTITY_TYPES.folder
      },
      true
    );
    toggleDropdown(false)();
  };

  const handleOnClickItem = TreeViewLoaderProps.onlyShowFolders
    ? handleFolderSelection
    : handleItemSelection;
  const selectedValue = !currentFormValue && enableNoOption ? NO_OPTION_SELECTED : currentFormValue;

  return (
    <Grid className={classes.root}>
      <FormControl error={!!errors[formFieldName]} className={classes.formControl}>
        <Select
          name={formFieldName}
          variant="outlined"
          value={selectedValue || {}}
          onOpen={toggleDropdown(true)}
          renderValue={value =>
            validateValueIsNoOption(value?.name) || !value?.name ? placeholder : value.name
          }
          open={isDropdownOpen}
          onChange={handleChange}
          className={classes.select}
          data-testid="grouped-select"
          MenuProps={{
            MenuListProps: {
              ref: menuList
            },
            onClick: handleOutsideClick,
            getContentAnchorEl: null,
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            className: classes.selectMenu
          }}
        >
          {enableNoOption && <MenuItem value={NO_OPTION_SELECTED}>No Template</MenuItem>}
          <TreeViewLoader {...TreeViewLoaderProps} onClickTreeItem={handleOnClickItem} />
        </Select>
        <FormHelperText className={classes.helperText}>
          {errors[formFieldName]?.message}
        </FormHelperText>
      </FormControl>
    </Grid>
  );
};

export default TreeViewSelector;
