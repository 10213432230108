import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { SimplifiedTable } from 'features/command-center/';
import { ENDPOINTS, TABLES_KEYS } from 'features/command-center/constants';
import strings from 'strings';
import SendoutManager from 'UI/components/organisms/sendouts';
import { RowActions } from 'UI/constants/defaults';

import { createPhonesColumns } from '../../utils';

import { COLUMNS, ORDER_BY_OPTIONS } from './UnconvertedSendovers.constants';
import { formatResponse } from './UnconvertedSendovers.utils';

const TABLE_KEY = TABLES_KEYS.followUpTasksUnconvertedSendovers;

export const UnconvertedSendovers = ({ filters }) => {
  const [selectedSendoverId, setSelectedSendoverId] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  const openDetailsDrawer = id => setSelectedSendoverId(id);

  const closeDetailsDrawer = () => {
    setSelectedSendoverId(null);
    setShouldRefresh(true);
  };

  const handleDrawerActionSubmit = data => {
    if (data) {
      setShouldRefresh(false);

      closeDetailsDrawer();
    }
  };

  return (
    <>
      <SimplifiedTable
        columns={COLUMNS}
        endpoint={ENDPOINTS.unconvertedSendovers}
        header={strings.commandCenter.dominateSheet.unconvertedSendovers.title}
        orderByOptions={ORDER_BY_OPTIONS}
        apiVersion={2}
        filters={filters}
        formatResponse={formatResponse}
        createExtraColumns={createPhonesColumns}
        createExtraColumnsConfig={{
          includeHiringAuthorityPhones: true,
          includeCandidatePhones: false
        }}
        rowActions={[
          {
            name: RowActions.View,
            customAction: 'rowClickBuilder',
            onClick: openDetailsDrawer
          },
          {
            action: 'viewContact',
            name: 'viewContact',
            value: 'contactData'
          }
        ]}
        shouldRefresh={shouldRefresh}
        pageKey={TABLE_KEY}
      />
      {!!selectedSendoverId && (
        <Drawer open={!!selectedSendoverId} onClose={closeDetailsDrawer}>
          <div role="presentation">
            <SendoutManager
              onEdit={handleDrawerActionSubmit}
              onDelete={handleDrawerActionSubmit}
              onClose={closeDetailsDrawer}
              id={selectedSendoverId}
            />
          </div>
        </Drawer>
      )}
    </>
  );
};
