import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';

export const useStyles = makeStyles(() => ({
  primaryButton: {
    width: '100% !important;'
  },
  spinnerContainer: {
    marginTop: -100
  },
  confirmChecklist: {
    fontWeight: 100
  },
  providerHelper: {
    fontSize: '0.9rem'
  }
}));

export const styles = {
  changesLabel: {
    marginBottom: THEME.spacing(1)
  }
};
