import GpacGeneratorForm from '@gogpac/form-generator';
import { makeStyles, styled } from '@material-ui/core';

export const useDataSheetDrawerStyles = makeStyles(theme => ({
  drawerLarge: {
    width: 980,
    overflowY: 'hidden',
    [theme.breakpoints.down(1024)]: {
      width: '80%'
    }
  },
  drawerContent: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: theme.spacing(3),
    paddingTop: 0
  }
}));

export const StyledGpacGeneratorForm = styled(GpacGeneratorForm)(({ theme }) => ({
  marginTop: theme.spacing(1),
  '& > div > div > div > div.MuiGrid-root.MuiGrid-item:first-of-type': {
    marginBottom: 'unset !important'
  },
  '& > div > div > div > div.MuiGrid-root.MuiGrid-item:nth-child(2)': {
    marginTop: `${theme.spacing(1.5)}px !important`
  },
  '& .MuiGrid-item': {
    padding: 0
  },
  '& .MuiTypography-root.MuiLink-root': {
    fontWeight: '400 !important'
  }
}));
