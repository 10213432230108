// @flow
import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import useDatatable, { getColumnPreferences } from 'hooks/datatable';
import usePageTitle from 'hooks/usePageTitle';
import strings from 'strings';
import { PilIndustries } from 'UI/components/organisms/PilIndustries';
import type { NodeSelection } from 'UI/components/organisms/PilIndustries/pilIndustries.types';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { backNavigateListConfig } from 'UI/constants/config';
import { PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';

import { getNodeSelectedParams } from '../Pil/pil.utils';

import { ColumnsDefinitions, FiltersGroups } from './columns';
import TeamsTable from './TeamsTable';

const PAGE_KEY = 'teams-list';

const orderByOptions = {
  column: 'id',
  direction: 'asc'
};

const Teams = () => {
  usePageTitle({ title: PageTitles.Teams });

  const [selectedNode, setSelectedNode] = useState(null);
  const [enableFetchData, setEnableFetchData] = useState(false);

  const initialPreferences = getColumnPreferences(
    PAGE_KEY,
    0,
    orderByOptions,
    ColumnsDefinitions,
    0
  );

  useEffect(() => {
    if (selectedNode) setEnableFetchData(true);
  }, [selectedNode]);

  const extendParams = useCallback(
    () =>
      selectedNode?.id && {
        [`${selectedNode.type}Id`]: selectedNode.id
      },
    [selectedNode]
  );

  const {
    handleFiltersApply,
    handleFiltersChange,
    handleKeywordChange,
    handleColumnSortChange,
    handlePerPageChange,
    handlePageChange,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleFiltersToggle,
    listState,
    columnPreferences,
    filters,
    data,
    count
  } = useDatatable({
    key: PAGE_KEY,
    columnsDefinitions: ColumnsDefinitions,
    enableFetchData,
    endpoint: Endpoints.Pil,
    entityName: 'Teams',
    initialPreferences,
    orderByOptions,
    paramsExtender: extendParams
  });

  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  const { isLoading, showWholeSkeleton, isSideMenuOpen, page, perPage, keyword } = listState;

  const handleNodeSelected = (node: NodeSelection | null) => {
    if (!node) {
      setSelectedNode(null);
      return;
    }

    const nodeSelectedParams = getNodeSelectedParams(node);
    setSelectedNode(nodeSelectedParams);
    handlePerPageChange(perPage);
  };

  return (
    <FiltersLayout
      title={strings.teams.title}
      section={PAGE_KEY}
      filters={filters}
      disableDateRange
      isSideMenuOpen={isSideMenuOpen}
      isLoading={enableFetchData && showWholeSkeleton}
      groups={FiltersGroups}
      enableStore={false}
      onSearch={handleFiltersApply}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      titleLabelProps={backNavigateListConfig}
    >
      <Grid container spacing={2}>
        <Grid item sm={5} xs={12}>
          <PilIndustries onNodeSelected={handleNodeSelected} filters={filters} showSearchFilter />
        </Grid>

        <Grid item sm={7} xs={12}>
          <TeamsTable
            columnOrder={columnOrder}
            columns={columns}
            count={count}
            data={data}
            direction={direction}
            isLoading={isLoading}
            keyword={keyword}
            onColumnDisplayChange={handleColumnDisplayChange}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnSortChange={handleColumnSortChange}
            onFiltersToggle={handleFiltersToggle}
            onKeywordChange={handleKeywordChange}
            onPageChange={handlePageChange}
            onPerPageChange={handlePerPageChange}
            orderBy={orderBy}
            page={page}
            perPage={perPage}
            selectedNode={selectedNode}
            showWholeSkeleton={showWholeSkeleton}
          />
        </Grid>
      </Grid>
    </FiltersLayout>
  );
};

export default Teams;
