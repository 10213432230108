// @flow
import React, { useEffect, useMemo, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { THEME as theme } from 'GlobalStyles';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import strings from 'strings';
import type { TemplatesTreeViewPropTypes } from 'types/app';
import { MY_TEMPLATES_FOLDER_ID, TEMPLATE_ENTITY_TYPES } from 'UI/constants/defaults';
import { UIStatus } from 'UI/constants/status';
import SvgFile from 'UI/res/icons/filled/FIle';
import SvgFolder from 'UI/res/icons/filled/Folder';
import { nestTernary } from 'UI/utils';

import { useStyles } from './styles';
import {
  buildGetTemplatesEndpoint,
  buildTreeViewItemId,
  filterArchiveFromTemplatesResponse,
  getChildren,
  isFolder,
  nodeObjectMapper
} from './utils';

const NodeConfiguration = {
  [TEMPLATE_ENTITY_TYPES.Root]: {
    Key: TEMPLATE_ENTITY_TYPES.Root
  },
  [TEMPLATE_ENTITY_TYPES.Folder]: {
    Key: TEMPLATE_ENTITY_TYPES.Folder
  }
};
const TEMPLATE_ENTITIES_WITH_POSSIBLE_CHILDREN = [
  TEMPLATE_ENTITY_TYPES.Root,
  TEMPLATE_ENTITY_TYPES.Folder
];

const TemplatesTreeView = ({
  enableIconClick = false,
  folderIdToExclude = null,
  foldersIdToRefresh = null,
  onClickItem,
  onFolderRefresh = null,
  onlyShowFolders = false,
  preselectedFolderId = null,
  selectedTreeItemId = null,
  shouldHideArchiveFolder = false
}: TemplatesTreeViewPropTypes) => {
  const classes = useStyles();
  const [nodesFetching, setNodesFetching] = useState([]);
  const [expandedIds, setExpandedIds] = useState([]);
  const [tree, setTree] = useState(null);
  const [selectedNodeId, setSelectedNodeId] = useState(null);

  const currentSelectedNodeId = selectedTreeItemId ?? selectedNodeId;

  const shouldShowTreeItemLoader = ({ id, entity }) =>
    nodesFetching.includes(id) && TEMPLATE_ENTITIES_WITH_POSSIBLE_CHILDREN.includes(entity);

  const commonGetTemplatesQueryParams = useMemo(
    () => ({
      excludeAllTemplates: onlyShowFolders,
      excludeFolderIds: folderIdToExclude
    }),
    [folderIdToExclude, onlyShowFolders]
  );

  const { state: templatesState, Status: TemplatesStatus } = useFetchWithStatus(
    buildGetTemplatesEndpoint({
      parentFolderId: preselectedFolderId,
      ...commonGetTemplatesQueryParams
    }),
    null,
    null,
    2
  );

  /** Effect to set templates as top-level in the tree */
  useEffect(() => {
    if (!templatesState.results) return;

    const { data: filteredTemplates } = filterArchiveFromTemplatesResponse(
      {
        data: templatesState.results
      },
      shouldHideArchiveFolder
    );
    const templates = filteredTemplates.map(
      ({ id, name, belongsToUser, is_archived, folder_type_id, parentFolderId }) => ({
        belongsToUser,
        entity: NodeConfiguration[TEMPLATE_ENTITY_TYPES.Root].Key,
        folderTypeId: folder_type_id,
        id: buildTreeViewItemId(NodeConfiguration[TEMPLATE_ENTITY_TYPES.Root].Key, id),
        isArchived: is_archived,
        name: name === MY_TEMPLATES_FOLDER_ID ? 'My Folders' : name,
        parentFolder: {
          id: parentFolderId
        }
      })
    );

    setTree({ root: templates });
  }, [templatesState.results, shouldHideArchiveFolder]);

  const updateTree = (nodeId, response) => {
    setTree(prevState => ({
      ...prevState,
      [nodeId]: response.data.map(
        ({ id, is_archived, name, entity, belongsToUser, folder_type_id, parentFolderId }) => ({
          belongsToUser,
          entity,
          folderTypeId: folder_type_id,
          id: buildTreeViewItemId(entity, id),
          isArchived: is_archived,
          name,
          parentFolder: {
            id: parentFolderId
          }
        })
      )
    }));
  };

  const expandFolder = async (event, nodeId, parentFolderId) => {
    setSelectedNodeId(nodeId);
    setNodesFetching(prev => [...prev, nodeId]);
    const response = await getChildren({
      shouldHideArchiveFolder,
      queryParams: {
        parentFolderId,
        ...commonGetTemplatesQueryParams
      }
    });
    setNodesFetching(prev => prev.filter(node => node !== nodeId));
    response.status === HTTPStatusCodes.Ok && updateTree(nodeId, response);
  };

  const handleSelectFormMode = async (event, node) => {
    const mappedNode = nodeObjectMapper(node);
    event === 'labelClick' && onClickItem(mappedNode);

    if (currentSelectedNodeId === node.id) return;

    if (isFolder(node.entity)) await expandFolder(event, node.id, mappedNode.id);
  };

  const handleSelectTreeMode = async (event, node) => {
    if (currentSelectedNodeId === node.id) return;

    const mappedNode = nodeObjectMapper(node);

    if (isFolder(node.entity)) await expandFolder(event, node.id, mappedNode.id);

    setSelectedNodeId(node.id);
    onClickItem(mappedNode);
  };

  const handleNodeSelect = (event, selectedNode) => () => {
    enableIconClick
      ? handleSelectFormMode(event, selectedNode)
      : handleSelectTreeMode(event, selectedNode);
  };

  useEffect(() => {
    if (!foldersIdToRefresh || foldersIdToRefresh.length === 0) return;
    const refreshBranch = async id => {
      const response = await getChildren({
        shouldHideArchiveFolder,
        queryParams: {
          parentFolderId: id,
          ...commonGetTemplatesQueryParams
        }
      });
      if (response.status === HTTPStatusCodes.Ok) {
        const itemId = buildTreeViewItemId(NodeConfiguration[TEMPLATE_ENTITY_TYPES.Folder].Key, id);
        updateTree(itemId, response);
      }
    };
    foldersIdToRefresh.forEach(folderId => {
      refreshBranch(folderId);
    });

    onFolderRefresh && onFolderRefresh();
  }, [foldersIdToRefresh, shouldHideArchiveFolder, commonGetTemplatesQueryParams, onFolderRefresh]);

  const handleToggle = async (event, openIds) => setExpandedIds(openIds);
  const renderTree = branches =>
    branches?.map(branch => {
      const isTemplate = branch.entity === TEMPLATE_ENTITY_TYPES.Template;
      const childrenNodes =
        tree[branch.id]?.length > 0
          ? renderTree(tree[branch.id])
          : nestTernary(isTemplate, null, [null]); // An array of one null is needed to tell TreeView to render the right chevron, otherwise it thinks there are no items to load

      const IconComponent = isTemplate ? SvgFile : SvgFolder;

      return (
        <TreeItem
          key={branch.id}
          nodeId={branch.id}
          className={classes.treeItem}
          classes={{
            content: classes.treeItemContent,
            label: classes.treeItemLabel
          }}
          onIconClick={handleNodeSelect('iconClick', branch)}
          onLabelClick={handleNodeSelect('labelClick', branch)}
          label={
            <div className={classes.labelContainer}>
              <IconComponent
                size={24}
                className={classes.labelIcon}
                fill={theme.palette.grey[600]}
              />
              <Typography variant="body2" className={classes.labelText}>
                {branch.name}
              </Typography>
              {shouldShowTreeItemLoader(branch) && <CircularProgress size={20} />}
            </div>
          }
        >
          {childrenNodes}
        </TreeItem>
      );
    });

  const Loader = () => (
    <div className={classes.initialLoader}>
      <CircularProgress size={32} />
    </div>
  );

  return (
    <>
      <TemplatesStatus
        loading={<Loader />}
        empty={
          <Typography paragraph gutterBottom>
            {strings.shared.emptyState.title}
          </Typography>
        }
        error={error => (
          <Typography paragraph gutterBottom>
            {error}
          </Typography>
        )}
        success={() => null}
      />
      {templatesState.status === UIStatus.Success && tree && (
        <TreeView
          className={classes.treeContainer}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          expanded={expandedIds}
          onNodeToggle={handleToggle}
          selected={currentSelectedNodeId}
        >
          {renderTree(tree.root)}
        </TreeView>
      )}
    </>
  );
};

export default TemplatesTreeView;
