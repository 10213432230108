// @flow
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { setSelectedTemplateToInitial, startSettingSelectedTemplateData } from 'actions/bulkEmail';
import {
  archiveTemplate,
  archiveTemplateFolder,
  getTemplateById,
  restoreTemplate,
  restoreTemplateFolder
} from 'services/bulkEmail';
import strings from 'strings';
import { buildTreeViewItemId } from 'UI/components/organisms/BulkEmail/BulkEmailTemplates/TemplateTreeView/utils';
import { TEMPLATE_ENTITY_TYPES } from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { capitalizeFirstLetter, hasFeatureFlag, objectToArray } from 'UI/utils';

const ARCHIVE_RETENTION_DAYS = {
  TEMPLATE:
    window.GPAC_ENV?.TEMPLATE_ARCHIVE_RETENTION_DAYS ||
    process.env.REACT_APP_TEMPLATE_ARCHIVE_RETENTION_DAYS ||
    30,
  FOLDER:
    window.GPAC_ENV?.TEMPLATE_FOLDER_ARCHIVE_RETENTION_DAYS ||
    process.env.REACT_APP_TEMPLATE_FOLDER_ARCHIVE_RETENTION_DAYS ||
    30
};

const {
  content: { template: templateCopies },
  dialogs: dialogsCopies
} = strings.bulkEmails;

export const useBulkTemplates = () => {
  const dispatch = useDispatch();
  const [templatesTreeItem, setTemplatesTreeItem] = useState({});
  const [foldersIdToRefresh, setFoldersIdToRefresh] = useState([]);
  const [selectedTreeItemId, setSelectedTreeItemId] = useState(null);
  const [uiState, setUiState] = useState({
    isArchiveDialogOpen: false,
    isDiscardTemplateDialogOpen: false,
    isFolderBeingEdited: false,
    isFolderDrawerOpen: false,
    isRestoreDialogOpen: false,
    isRestoreDrawerOpen: false,
    isTemplateBeingEdited: false,
    isTemplateDrawerOpen: false,
    loading: false,
    loadingPreview: false
  });

  /**
   * @typedef {Object} GetTemplateOptionsParam
   * @property {number} id
   * @property {boolean} belongsToUser
   */

  /**
   * @param {GetTemplateOptionsParam} options
   */
  const getTemplateContent = async ({ id, belongsToUser }) => {
    setUiState(prev => ({ ...prev, loadingPreview: true }));
    const { alert, data, success } = await getTemplateById(id);

    if (success) {
      const { entity, emailBody, emailTemplateFolder, is_archived: isArchived, name } = data;

      const mappedParentFolderData = {
        id: emailTemplateFolder.id,
        name: emailTemplateFolder.name
      };

      setTemplatesTreeItem({
        belongsToUser,
        emailBody,
        entity,
        id,
        isArchived,
        name,
        parentFolder: mappedParentFolderData
      });

      dispatch(
        startSettingSelectedTemplateData({
          id,
          data: { name, emailBody, parentFolder: mappedParentFolderData }
        })
      );
    } else {
      dispatch(showAlert(alert));
    }

    setUiState(prev => ({ ...prev, loadingPreview: false }));
  };

  const templateTogglers = {
    archiveDialog:
      ({ open }) =>
      () =>
        setUiState(prev => ({ ...prev, isArchiveDialogOpen: open })),
    restoreDialog:
      ({ open }) =>
      () =>
        setUiState(prev => ({ ...prev, isRestoreDialogOpen: open })),
    restoreDrawer:
      ({ open }) =>
      () =>
        setUiState(prev => ({ ...prev, isRestoreDrawerOpen: open })),
    folderDrawer:
      ({ open, edit }) =>
      () =>
        setUiState(prev => ({ ...prev, isFolderDrawerOpen: open, isFolderBeingEdited: edit })),
    templateDrawer:
      ({ open, edit }) =>
      () =>
        setUiState(prev => ({ ...prev, isTemplateDrawerOpen: open, isTemplateBeingEdited: edit })),
    discardTemplateDialog:
      ({ open }) =>
      () =>
        setUiState(prev => ({ ...prev, isDiscardTemplateDialogOpen: open }))
  };

  const cleanTemplateState = () => {
    setTemplatesTreeItem({});
    dispatch(setSelectedTemplateToInitial());
  };

  const executeDialogRequest = async ({ serviceRequest, dialogToggler }) => {
    setUiState(prev => ({ ...prev, loading: true }));
    setSelectedTreeItemId('');
    const { alert, success, data } = await serviceRequest(templatesTreeItem.id);
    dispatch(showAlert(alert));

    if (success) {
      const { destinationFolder } = data;
      const { id: parentFolderId } = templatesTreeItem.parentFolder;
      dialogToggler({ open: false })();
      cleanTemplateState();
      setFoldersIdToRefresh([destinationFolder.id, parentFolderId]);
    }
    setUiState(prev => ({ ...prev, loading: false }));
  };

  const restoreTogglerAction = hasFeatureFlag(FeatureFlags.BulkTemplatesRestoreSelectingFolder)
    ? templateTogglers.restoreDrawer
    : templateTogglers.restoreDialog;

  const templateHandlers = {
    archiveTemplate: () => {
      executeDialogRequest({
        serviceRequest: archiveTemplate,
        dialogToggler: templateTogglers.archiveDialog
      });
    },
    restoreTemplate: () => {
      executeDialogRequest({
        serviceRequest: restoreTemplate,
        dialogToggler: restoreTogglerAction
      });
    },
    archiveTemplateFolder: () => {
      executeDialogRequest({
        serviceRequest: archiveTemplateFolder,
        dialogToggler: templateTogglers.archiveDialog
      });
    },
    restoreTemplateFolder: () => {
      executeDialogRequest({
        serviceRequest: restoreTemplateFolder,
        dialogToggler: restoreTogglerAction
      });
    },
    restoreTemplateTreeItem: ({ previousFolderId, destinationFolderId }) => {
      setSelectedTreeItemId('');
      setFoldersIdToRefresh([previousFolderId, destinationFolderId]);
      setTemplatesTreeItem({});
    },
    submitFolder: newFolderData => {
      const { name, idsToRefresh, id } = newFolderData;
      setSelectedTreeItemId(buildTreeViewItemId(TEMPLATE_ENTITY_TYPES.Folder, id));
      setUiState(prevState => ({
        ...prevState,
        isFolderDrawerOpen: false,
        isFolderBeingEdited: false
      }));
      setTemplatesTreeItem({
        belongsToUser: true,
        entity: TEMPLATE_ENTITY_TYPES.Folder,
        folderTypeId: null,
        id,
        name,
        isArchived: false,
        parentFolder: { id: idsToRefresh.destinationFolderId }
      });
      setFoldersIdToRefresh(objectToArray(idsToRefresh));
    },
    selectTreeItem: treeItem => {
      const { belongsToUser, entity, folderTypeId, id, isArchived, name, parentFolder } = treeItem;
      setSelectedTreeItemId(buildTreeViewItemId(entity, id));
      if (entity === TEMPLATE_ENTITY_TYPES.Template) {
        getTemplateContent({ id, belongsToUser });
      } else {
        setTemplatesTreeItem({
          belongsToUser,
          entity,
          folderTypeId,
          id,
          name,
          isArchived,
          parentFolder
        });
      }
    },
    submitTemplate: newTemplateData => {
      const { destinationFolderId, id, previousFolderId } = newTemplateData;

      setSelectedTreeItemId(buildTreeViewItemId(TEMPLATE_ENTITY_TYPES.Template, id));
      setUiState(prevState => ({
        ...prevState,
        isTemplateDrawerOpen: false,
        isTemplateBeingEdited: false
      }));

      const idsToRefresh = [destinationFolderId];
      if (previousFolderId) idsToRefresh.push(previousFolderId);

      getTemplateContent({ id, belongsToUser: true });
      setFoldersIdToRefresh(idsToRefresh);
    },
    folderRefresh: () => setFoldersIdToRefresh([])
  };

  const getDialogCopies = (copies, retentionDays, entity) => ({
    ...copies,
    title: strings.formatString(copies.title, {
      entity: capitalizeFirstLetter(entity)
    }),
    confirmButtonText: strings.formatString(copies.confirmButtonText, {
      entity
    }),
    message: strings.formatString(copies.message, {
      entity,
      retentionDays
    })
  });

  const archiveDialogProps = {
    template: {
      ...getDialogCopies(
        dialogsCopies.archiveTemplates,
        ARCHIVE_RETENTION_DAYS.TEMPLATE,
        TEMPLATE_ENTITY_TYPES.Template
      ),
      onConfirm: templateHandlers.archiveTemplate
    },
    folder: {
      ...getDialogCopies(
        dialogsCopies.archiveTemplates,
        ARCHIVE_RETENTION_DAYS.FOLDER,
        TEMPLATE_ENTITY_TYPES.Folder
      ),
      onConfirm: templateHandlers.archiveTemplateFolder
    }
  };

  const restoreDialogProps = {
    template: {
      ...getDialogCopies(
        dialogsCopies.restoreTemplates.copies,
        ARCHIVE_RETENTION_DAYS.TEMPLATE,
        TEMPLATE_ENTITY_TYPES.Template
      ),
      onConfirm: templateHandlers.restoreTemplate
    },
    folder: {
      ...getDialogCopies(
        dialogsCopies.restoreTemplates.copies,
        ARCHIVE_RETENTION_DAYS.FOLDER,
        TEMPLATE_ENTITY_TYPES.Folder
      ),
      onConfirm: templateHandlers.restoreTemplateFolder
    }
  };

  const actionsByTemplateEntity = {
    [TEMPLATE_ENTITY_TYPES.Template]: {
      default: {
        menuItems: [
          {
            action: templateTogglers.templateDrawer({ open: true, edit: true }),
            title: templateCopies.menuItemsTitles.edit,
            visible: true
          },
          {
            action: templateTogglers.archiveDialog({ open: true }),
            title: templateCopies.menuItemsTitles.archive,
            visible: hasFeatureFlag(FeatureFlags.BulkEmailArchiveTemplates)
          }
        ]
      },
      archived: {
        actionButtonProps: {
          text: 'Restore',
          onClick: restoreTogglerAction({ open: true })
        }
      }
    },
    [TEMPLATE_ENTITY_TYPES.Folder]: {
      default: {
        menuItems: [
          {
            action: templateTogglers.folderDrawer({ open: true, edit: true }),
            title: 'Edit',
            visible: hasFeatureFlag(FeatureFlags.BulkEmailEditTemplatesFolders)
          },
          {
            action: templateTogglers.archiveDialog({ open: true }),
            title: templateCopies.menuItemsTitles.archive,
            visible: hasFeatureFlag(FeatureFlags.BulkEmailArchiveTemplatesFolders)
          }
        ]
      },
      archived: {
        actionButtonProps: {
          text: 'Restore',
          onClick: restoreTogglerAction({ open: true })
        }
      }
    }
  };

  const getTemplatesActions = () => {
    if (uiState.loadingPreview || templatesTreeItem.folderTypeId) return {};

    const actionsToReturn = actionsByTemplateEntity[templatesTreeItem?.entity] || {};

    if (templatesTreeItem?.isArchived) {
      return actionsToReturn?.archived;
    }

    return actionsToReturn?.default;
  };

  return {
    foldersIdToRefresh,
    getTemplatesActions,
    templateHandlers,
    templatesTreeItem,
    templatesUiState: uiState,
    templateTogglers,
    templateArchiveDialogProps: archiveDialogProps[templatesTreeItem?.entity],
    templateRestoreDialogProps: restoreDialogProps[templatesTreeItem?.entity],
    selectedTreeItemId
  };
};
