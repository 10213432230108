import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';
import { colors } from 'UI/res/colors/index';

export const styles = {
  emptyDiv: {
    display: 'none'
  },
  buttonResponsive: {
    display: 'flex',
    justifyContent: 'center'
  }
};

const { primary, secondary, grey } = THEME.palette;

const sizeSmallStyles = {
  height: 28,
  fontSize: '13px !important',
  whiteSpace: 'nowrap'
};

const labelSpacing = THEME.spacing(0, 0.5);

export const useStyles = makeStyles(theme => ({
  root: {
    border: 0,
    ...theme.button,
    boxShadow: 'unset',
    '&:hover': {
      boxShadow: '0px 2px 2px -4px rgba(3, 13, 40, 0.08)'
    }
  },
  containedPrimary: {
    color: colors.white,
    backgroundColor: primary.main,
    '&:hover': {
      backgroundColor: primary.dark
    },
    '&:focus': {
      backgroundColor: primary.FP400
    }
  },
  containedSecondary: {
    color: secondary.FP700,
    backgroundColor: secondary.main,
    '&:hover': {
      backgroundColor: secondary.dark
    },
    '&:focus': {
      backgroundColor: secondary.FP400
    }
  },
  outlinedPrimary: {
    backgroundColor: 'unset',
    color: primary.light,
    border: `1px solid ${primary.light} !important`,
    '&:hover': {
      backgroundColor: secondary.main
    }
  },
  outlinedSecondary: {
    backgroundColor: 'unset',
    border: `1px solid ${secondary.FP700} !important`,
    '&:hover': {
      backgroundColor: secondary.main
    },
    '&:focus': {
      backgroundColor: secondary.FP300
    }
  },
  textSecondary: {
    color: secondary.FP700
  },
  disabled: ({ variant }) => ({
    backgroundColor: variant === 'contained' ? `${colors.inactiveSideBarTab} !important` : 'unset',
    border: variant === 'contained' ? 'unset' : `1px solid ${grey[500]} !important`
  }),
  text: {
    width: 'unset !important',
    minWidth: 'unset !important'
  },
  sizeSmall: sizeSmallStyles,
  label: ({ variant }) => ({
    padding: variant === 'text' && labelSpacing
  })
}));

export const useCellStyles = makeStyles(theme => ({
  root: {
    border: 0,
    ...theme.button,
    minWidth: 'unset'
  },
  contained: {
    '&:hover': {
      backgroundColor: primary.main
    }
  },
  outlined: {
    backgroundColor: 'unset',
    color: primary.success,
    border: `1px solid ${primary.light} !important`
  },
  sizeSmall: sizeSmallStyles,
  label: {
    padding: labelSpacing
  },
  containedSecondary: {
    color: secondary.FP700,
    backgroundColor: secondary.main,
    '&:hover': {
      backgroundColor: secondary.dark
    },
    '&:focus': {
      backgroundColor: secondary.FP400
    }
  }
}));
