// @flow
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { setBulkEmailStatus } from 'actions/bulkEmail';
import { getOutlookQuota } from 'services/bulkEmail/outlookQuota';
import { getQuotaRestriction } from 'UI/components/organisms/BulkEmailDrawer/utils';

type BulkQuotaRestrictionType = {
  searchProjectSize: number
};

export const useBulkQuotaRestriction = ({ searchProjectSize }: BulkQuotaRestrictionType) => {
  const [bulkQuota, setBulkQuota] = useState(null);

  const dispatch = useDispatch();

  const remainingQuota = bulkQuota?.remainingQuota;

  const bulkQuotaRestriction = getQuotaRestriction({ remainingQuota, searchProjectSize });

  const notifyBulkQuotaRestriction = () => {
    if (!bulkQuotaRestriction) return;
    dispatch(
      setBulkEmailStatus({
        completed: false,
        sending: false,
        loadLast: false,
        loading: false,
        open: false,
        bulkRestriction: bulkQuotaRestriction
      })
    );
  };

  useEffect(() => {
    (async () => {
      const { data, success, alert: outlookAlert } = await getOutlookQuota();
      success ? setBulkQuota(data) : dispatch(showAlert(outlookAlert));
    })();
  }, [dispatch]);

  return {
    bulkQuota,
    remainingQuota,
    bulkQuotaRestriction,
    notifyBulkQuotaRestriction
  };
};
