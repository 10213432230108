// @flow
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { getCurrentUser } from 'services/Authentication';
import { FilterDefinition } from 'UI/components/templates/SideFiltersLayout/filters';
import { HideMode } from 'UI/constants/defaults';
import { loadFromLocalStorage } from 'UI/utils';

export const formatFilters = (
  filters: {
    [string]: {
      type: string,
      value: any
    }
  },
  config: FilterDefinition[]
) => {
  return Object.keys(filters).reduce((accumulator, key) => {
    const filter = filters[key];

    if (isNil(filter?.value)) return accumulator;

    const filterConfig = config.find(f => f.name === key);

    return {
      ...accumulator,
      [key]: {
        ...filterConfig,
        value: filterConfig?.outboundTransformer
          ? filterConfig.outboundTransformer(filter.value)
          : filter.value
      }
    };
  }, {});
};

export class PersistenceFiltersManager {
  currentFilters = {};

  constructor({ persistenceKey }) {
    this.persistenceKey = persistenceKey;
  }

  persistFilters = (filtersToPersist = {}) => {
    if (!this.persistenceKey) return filtersToPersist;
    const previousFilters = this.currentFilters;
    if (isEqual(previousFilters, filtersToPersist)) return filtersToPersist;

    localStorage.setItem(this.persistenceKey, JSON.stringify(filtersToPersist));
    this.currentFilters = filtersToPersist;
    return filtersToPersist;
  };

  loadPersistedFilters = () => {
    return loadFromLocalStorage(this.persistenceKey, {});
  };

  resetFilters = () => {
    this.persistFilters({});
  };
}

export const ensureIfFilterIsActive = (filter, filters) => {
  const user = getCurrentUser();
  const isVisible = filter.showWhen !== undefined ? filter.showWhen(filters, user) : true;

  if (!isVisible && (!filter.hideMode || filter.hideMode === HideMode.Unmounted)) {
    return null;
  }

  return isVisible;
};
