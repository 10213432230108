import React from 'react';
import Grid from '@material-ui/core/Grid';
import { ActivityTable } from 'features/command-center/components/activity/ActivityTable';
import { EntityType } from 'UI/constants/entityTypes';

export const Activity = ({ recruiterId }) => {
  return (
    <Grid container spacing={4}>
      <Grid item container xs={12}>
        <ActivityTable entity={EntityType.Candidate} recruiterId={recruiterId} />
      </Grid>

      <Grid item container xs={12}>
        <ActivityTable entity={EntityType.HiringAuthority} recruiterId={recruiterId} />
      </Grid>

      <Grid item container xs={12}>
        <ActivityTable entity={EntityType.Contact} recruiterId={recruiterId} />
      </Grid>

      <Grid item container xs={12}>
        <ActivityTable entity={EntityType.Company} recruiterId={recruiterId} />
      </Grid>
    </Grid>
  );
};
