export const ColumnsDefinitions = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'recruiter',
    label: 'Recruiter',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'coach',
    label: 'Team',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'industry',
    label: 'Industry',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'specialty',
    label: 'Specialty',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'subspecialty',
    label: 'Subspecialty',
    options: {
      sort: true,
      display: true
    }
  }
];

export const FiltersGroups = [
  { id: 'team', name: 'Team', filters: ['coaches', 'recruiters'] },
  {
    id: 'location',
    name: 'Location',
    filters: ['states', 'searchSpecificPILStates', 'cities', 'searchSpecificPILCities']
  }
];
