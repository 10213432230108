// @flow
import * as React from 'react';
import Typography from '@material-ui/core/Typography';

type TabPanelProps = {
  children: React.Node,
  index: any,
  value: any,
  classes: any
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, classes, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      classes={classes}
      {...other}
    >
      {value === index && children}
    </Typography>
  );
};

TabPanel.defaultProps = {
  children: undefined
};

export default TabPanel;
