import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  emptyImage: {
    marginTop: theme.spacing(2)
  },
  viewImage: { width: '100%', height: '100%', objectFit: 'contain' },
  footerContainer: {
    ...flexAlignCenterJustifyCenter,
    flexDirection: 'column'
  },
  subheader: {
    lineHeight: `${theme.spacing(4)}px`,
    fontSize: '1rem'
  }
}));
