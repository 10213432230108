import * as React from "react";

const SvgCalendar = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M21 2.571h-2.143a.429.429 0 01-.428-.428v-.857a1.286 1.286 0 00-2.572 0v.857a.429.429 0 01-.428.428H8.57a.429.429 0 01-.428-.428v-.857a1.286 1.286 0 00-2.572 0v.857a.429.429 0 01-.428.428H3a3.017 3.017 0 00-3 3V21a3.017 3.017 0 003 3h18a3.017 3.017 0 003-3V5.571a3.017 3.017 0 00-3-3zm-18.429 3A.429.429 0 013 5.143h2.143a.429.429 0 01.428.428v.858a1.286 1.286 0 002.572 0V5.57a.429.429 0 01.428-.428h6.858a.429.429 0 01.428.428v.858a1.286 1.286 0 002.572 0V5.57a.429.429 0 01.428-.428H21a.429.429 0 01.429.428V9a.429.429 0 01-.429.429H3A.429.429 0 012.571 9z" />
    </svg>
  );
};

export default SvgCalendar;
