// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { setBulkEmailStatus, startSettingBulkPreview } from 'actions/bulkEmail';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { BulkMenuItems } from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { SvgArrowDown, SvgArrowUp } from 'UI/res';
import { dateFormatter, hasFeatureFlag } from 'UI/utils';

import PreviewBulkMetaRows from './PreviewBulkMetaRows';
import { useStyles } from './styles';
import { getMailMeta } from './utils';

const {
  preview: { mailMeta: MAIL_META_STRINGS }
} = strings.bulkEmails;

export const buildDateToRender = (selectedMenuItem, createdAt, sendDate) => {
  const date = selectedMenuItem === BulkMenuItems.Drafts ? createdAt : sendDate;
  return dateFormatter(date, ({ MonthDayYearTime }) => MonthDayYearTime);
};

const PreviewBulkEmail = () => {
  const classes = useStyles();
  const [shouldShowMoreData, setShouldShowMoreData] = useState(false);

  const dispatch = useDispatch();
  const bulkInPreviewData = useSelector(store => store.bulkEmail.domain.bulkEmailInPreview.data);
  const {
    selectedMenuItem,
    status: { reloadEmailList }
  } = useSelector(store => store.bulkEmail.ui);
  const {
    id: bulkId,
    emailBody: { html: emailBody, signature, subject }
  } = bulkInPreviewData;

  const { mainMailMeta, secondaryMailMeta } = getMailMeta({ bulkInPreviewData, selectedMenuItem });
  const getBodyContent = () => {
    if (!emailBody) {
      return MAIL_META_STRINGS.emailBody.defaultValue;
    }
    return signature && hasFeatureFlag(FeatureFlags.BulkEmailEnableSignatureOnSchedules)
      ? `${emailBody}${signature}`
      : emailBody;
  };

  useEffect(() => {
    if (reloadEmailList && bulkId) {
      dispatch(startSettingBulkPreview(bulkId));
      dispatch(setBulkEmailStatus({ reloadEmailList: false }));
    }
  }, [bulkId, dispatch, reloadEmailList]);

  return (
    <div className={classes.main}>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h6">{subject || MAIL_META_STRINGS.subject.defaultValue}</Typography>
        </Grid>
        <PreviewBulkMetaRows rowsToRender={mainMailMeta} />
        <Grid item className={classes.buttonRow}>
          <FPActionButton
            size="small"
            onClick={() => setShouldShowMoreData(!shouldShowMoreData)}
            text={strings.formatString(MAIL_META_STRINGS.showButton.text, {
              moreLess: shouldShowMoreData ? 'less' : 'more'
            })}
            variant="text"
            icon={shouldShowMoreData ? SvgArrowUp : SvgArrowDown}
            iconPosition="right"
            iconProps={{ size: 12 }}
          />
        </Grid>
        <Collapse in={shouldShowMoreData}>
          <PreviewBulkMetaRows rowsToRender={secondaryMailMeta} />
        </Collapse>
      </Grid>
      <hr className={classes.separation} />
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: getBodyContent() }} className={classes.content} />
    </div>
  );
};

export default PreviewBulkEmail;
