// @flow
import type { OperationResult } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';
import { getErrorMessage } from 'UI/utils';

import API from './API';

const BASE_ALERT = {
  isNotification: false,
  isOpen: false,
  autoHideDuration: 5000,
  onClick: undefined,
  onDismissClick: undefined
};

const ALERT_TITLE = 'Company';

const buildAlert = (id: number, body: string) => {
  return {
    ...BASE_ALERT,
    key: id,
    severity: 'error',
    title: ALERT_TITLE,
    body
  };
};

const getJobOrdersByCompanyId = async (id: number): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const url = `${Endpoints.Companies}/${id}/${Endpoints.JobOrders}`;
    const response = await API.get(`${url}`);
    if (response?.status === 200) {
      result.success = true;
      result.data = response.data;
    } else {
      result.alert = buildAlert(id, response.statusText);
    }
  } catch (error) {
    result.alert = buildAlert(id, getErrorMessage(error));
  }
  return result;
};

export { getJobOrdersByCompanyId };
