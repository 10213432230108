import * as React from 'react';

const SvgArrowLeft = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M4.5 12c0-.7.3-1.3.8-1.7L16.5.5c.8-.7 1.9-.6 2.6.2.6.8.6 1.9-.2 2.5l-9.8 8.6c-.1.1-.1.2 0 .3l9.8 8.6c.8.7.9 1.8.2 2.6s-1.8.9-2.6.2l-.1-.1-11.1-9.7c-.5-.4-.8-1.1-.8-1.7z" />
    </svg>
  );
};

export default SvgArrowLeft;
