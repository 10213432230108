import * as React from 'react';

const SvgArtificialIntelligence = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      fill={fill}
      {...props}
    >
      <g fill="currentColor">
        <path d="M20.94 11.42V11a2 2 0 00-2-2H13V5.48a2.77 2.77 0 10-2 0V9H5.06a2 2 0 00-2 2v.41zM23.67 14.21l-1.73-.6v6.23l1.73-.6a.5.5 0 00.33-.47v-4.09a.5.5 0 00-.33-.47zM2.06 13.61l-1.73.6a.5.5 0 00-.33.47v4.09a.5.5 0 00.33.47l1.73.6zM3.06 12.92v9a2 2 0 002 2h13.88a2 2 0 002-2v-9zm2.85 3.8a2 2 0 112 2 2 2 0 01-2-2zm3.17 5.63l.78-2.11h4.28l.78 2.11zm7-3.63a2 2 0 112-2 2 2 0 01-1.99 2z" />
      </g>
    </svg>
  );
};
export default SvgArtificialIntelligence;
