import { makeStyles } from '@material-ui/core/styles';

export const useScheduleDialogStyles = makeStyles(theme => ({
  timezoneInfoContainer: {
    marginTop: theme.spacing(1)
  },
  infoIcon: {
    marginRight: theme.spacing(2)
  }
}));
