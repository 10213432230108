// @flow
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import strings from 'strings';
import EmailItemCard from 'UI/components/atoms/EmailItemCard';
import { When } from 'UI/components/atoms/When';
import { BulkMenuItems } from 'UI/constants/defaults';
import { getRemainingTimeToDate } from 'UI/utils';

import { useStyles } from './styles';

const SentSkelleton = ({ classes }) => (
  <Grid container className={classes.skelleton}>
    <Grid container justify="space-between">
      <Skeleton variant="text" width="50%" />
      <Skeleton variant="text" width="15%" />
    </Grid>
    <Grid container justify="space-between">
      <Skeleton variant="text" width="60%" />
      <Skeleton variant="circle" width={20} height={20} />
    </Grid>
    <Skeleton variant="text" width="75%" />
  </Grid>
);

const { emptyStates: emptyStatesStrings } = strings.bulkEmails;

export const dateFormatters = {
  default: date => {
    const strFormat = 'YYYYMMDD';
    const now = moment().format(strFormat);
    const yesterday = moment().subtract(1, 'days').format(strFormat);
    const sentDate = moment(date);
    let format = '';

    if (now === sentDate.format(strFormat)) {
      format = sentDate.format('hh:mm a');
    } else if (yesterday === sentDate.format(strFormat)) {
      format = 'Yesterday';
    } else {
      format = sentDate.format('MM/DD/YYYY');
    }
    return format;
  },
  daysRemaining: date => {
    const remainingTime = getRemainingTimeToDate(date);
    return remainingTime ? `${remainingTime?.quantity} ${remainingTime?.unitOfTime} remaining` : '';
  }
};

const bulkModeDateFormatterMap = {
  [BulkMenuItems.Sent]: dateFormatters.default,
  [BulkMenuItems.Drafts]: dateFormatters.default,
  [BulkMenuItems.Schedule]: dateFormatters.daysRemaining
};

type BulkEmailsListPropTypes = {
  emailInPreviewId?: number,
  emailsList?: Array,
  isLastEmailLoaded?: boolean,
  loading?: boolean,
  onLoadMoreEmails: () => void,
  onSelectEmail: () => void,
  selectedMenuItem: string
};

const BulkEmailsList = ({
  emailInPreviewId = null,
  emailsList = [],
  isLastEmailLoaded = true,
  loading = false,
  onLoadMoreEmails,
  onSelectEmail,
  selectedMenuItem
}: BulkEmailsListPropTypes) => {
  const classes = useStyles();
  const handleSelectEmail = selectedMailId => onSelectEmail && onSelectEmail(selectedMailId);
  const handleLoadMoreEmails = visible =>
    visible && !loading && onLoadMoreEmails && onLoadMoreEmails();

  return (
    <Grid className={classes.root}>
      {emailsList.map(email => (
        /**
         * TODO: remove EmailItemCard component and handle directly on this component
         * EmailItemCard component is not useful anymore and everything chan be
         * handled perfectly within EmailsList component
         */
        <EmailItemCard
          key={email.key}
          bulkId={email?.id}
          searchProjectName={
            email.searchProject?.name || emptyStatesStrings.emailItems.searchProjectName
          }
          subject={email.emailBody?.subject || emptyStatesStrings.emailItems.subject}
          previewText={email.emailBody?.text}
          formattedDate={bulkModeDateFormatterMap[selectedMenuItem](
            email?.send_date || email?.created_at
          )}
          isActiveCard={emailInPreviewId === email.id}
          /* eslint-disable-next-line no-underscore-dangle */
          hasAttachments={email.emailBody?.__meta__?.attachments_count > 0}
          onSelectEmail={handleSelectEmail}
          warning={email?.warning}
        />
      ))}
      <When condition={!isLastEmailLoaded && !loading}>
        <div data-testid="emails-loader-sensor">
          <VisibilitySensor onChange={handleLoadMoreEmails}>
            <SentSkelleton classes={{ skelleton: classes.skelleton }} />
          </VisibilitySensor>
        </div>
      </When>
    </Grid>
  );
};

export default BulkEmailsList;
