import { makeStyles } from '@material-ui/core';

export const useDrawerStyles = makeStyles(theme => ({
  drawerLarge: {
    width: 1100,
    overflowY: 'hidden',
    [theme.breakpoints.down(1024)]: {
      width: '80%'
    }
  },
  drawerContent: {
    padding: 0,
    flexGrow: 1,
    overflowY: 'auto'
  }
}));
