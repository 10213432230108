// @flow
import React from 'react';

import Content from './Content';

const HotSheet = props => {
  return <Content {...props} />;
};

export default HotSheet;
