// @flow
import { makeRequest } from 'services/utils';
import strings from 'strings';
import type { OperationResult } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';

const { success: successCopies, error: errorCopies } = strings.searchProjects.splits.services;

export const SplitSearchProject = ({
  partsName,
  partsQuantity,
  searchProjectId
}): Promise<OperationResult> =>
  makeRequest({
    url: Endpoints.SearchProjectSplit.replace(/:id/, searchProjectId),
    method: 'post',
    data: { partsQuantity, partsName },
    alertConfig: {
      success: {
        title: successCopies.splitSearchProject.title,
        body: strings.formatString(successCopies.splitSearchProject.body, { partsQuantity })
      },
      error: errorCopies.splitSearchProject
    }
  });
