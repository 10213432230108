// @flow
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import type { HintProps } from 'types/app';
import FPIcon from 'UI/components/atoms/FPIcon';
import {
  colors,
  SvgAlertCircle,
  SvgCheck,
  SvgInformationCircle,
  SvgQuestionCircle,
  SvgWarning
} from 'UI/res';

import { hintDefinitions, useStyles } from './styles';

const FPHint = ({
  hint,
  variant,
  size,
  iconProps,
  description,
  typographyProps,
  disabled,
  containerClassName,
  position,
  ...rest
}: HintProps) => {
  const getIconColor = (d = disabled, color) => {
    return d ? colors.grey : color;
  };

  const getIconProps = (props, color) => ({
    color: getIconColor(disabled, hintDefinitions.colors[color]),
    size: hintDefinitions.hint[size],
    ...props
  });

  const DefaultIcons = {
    help: props => <FPIcon component={SvgQuestionCircle} {...getIconProps(props, 'help')} />,
    info: props => <FPIcon component={SvgInformationCircle} {...getIconProps(props, 'info')} />,
    danger: props => <FPIcon component={SvgAlertCircle} {...getIconProps(props, 'danger')} />,
    warning: props => (
      <FPIcon family="mini" component={SvgWarning} {...getIconProps(props, 'warning')} />
    ),
    success: props => <FPIcon component={SvgCheck} {...getIconProps(props, 'success')} />
  };

  const classes = useStyles({
    hintColor: variant,
    size,
    hint,
    disabled,
    variant,
    description
  });

  const iconDefinition = DefaultIcons[variant]({ ...iconProps });

  return hint && !description ? (
    <Tooltip title={hint} placement={position} arrow {...rest}>
      {/* wrapped on double span first to have a inline element, second to preserve 1:1 ratio despite other elements and their display configuration */}
      <span className={classes.hintWrapper}> {iconDefinition} </span>
    </Tooltip>
  ) : (
    <div className={clsx(classes.hintContainer, containerClassName)}>
      {iconDefinition}
      <Typography variant="body1" className={classes.text} {...typographyProps}>
        {description}
      </Typography>
    </div>
  );
};

FPHint.defaultProps = {
  hint: '',
  variant: 'info',
  size: 'sm',
  iconProps: undefined,
  typographyProps: null,
  description: null,
  disabled: false,
  containerClassName: null,
  position: 'right'
};

export default FPHint;
