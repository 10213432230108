// @flow

import React from 'react';
import Typography from '@material-ui/core/Typography';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import { DateFormats } from 'UI/constants/defaults';
import { toLocalTime } from 'UI/utils';

export type PlacementAssignmentDataSheetViewProps = {
  registeredDate?: string,
  title: string,
  url: string
};

const PlacementDataSheetView = ({
  registeredDate,
  title,
  url
}: PlacementAssignmentDataSheetViewProps) => {
  if (!registeredDate) return null;
  const formattedRegisteredDate = toLocalTime(registeredDate).format(DateFormats.SimpleDateTime); // Using update date since could be draft first

  if (!url || !formattedRegisteredDate) return null;

  return (
    <NumberedForm.Item title={title}>
      <Typography>
        Registered on: <b>{formattedRegisteredDate}</b>&nbsp;
        <FPActionButton href={url} variant="text" target="_blank" text="View" />
      </Typography>
    </NumberedForm.Item>
  );
};

export default PlacementDataSheetView;
