// @flow
import React, { useEffect, useRef, useState } from 'react';
import CardActionArea from '@material-ui/core/CardActionArea';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';
import API from 'services/API';
import { deleteTemporaryAttachment, uploadTemporaryAttachment } from 'services/bulkEmail';
import FPIcon from 'UI/components/atoms/FPIcon';
import Text from 'UI/components/atoms/Text';
import { Endpoints } from 'UI/constants/endpoints';
import { colors, SvgUploadButton } from 'UI/res/';
import { getErrorMessage } from 'UI/utils';

import { useStyles } from './styles';

type FileExt =
  | 'image/png'
  | 'image/jpg'
  | 'image/jpeg'
  | '.pdf'
  | '.docx'
  | '.pptx'
  | '.xlsx'
  | '.csv';

type Attachments = {
  id: number,
  name: string,
  type: 'temporal' | 'template'
};

type UnsavedDismountFiles = {
  unsaved: (files: Array) => void
};

type FileUploaderOutlineType = {
  label: string,
  name: string,
  placeholder: string,
  maxFiles?: number,
  maxFileSize?: number,
  sizeFormat?: string, // mb or kb
  onFilesChange?: (fileData: Object, error?: Object) => void,
  onRemoveAttachment?: (fileId: number) => void,
  onUnsavedDismount?: typeof React.useCallback<UnsavedDismountFiles>,
  acceptFiles?: Array<FileExt>,
  attachments?: Array<Attachments>,
  error?: boolean,
  errorMessage?: string,
  variant?: 'default' | 'bulk'
};

const FileUploaderOutline = ({
  label,
  name,
  placeholder,
  maxFiles,
  maxFileSize,
  sizeFormat,
  onFilesChange,
  onRemoveAttachment,
  onUnsavedDismount,
  acceptFiles,
  attachments,
  error,
  errorMessage,
  variant
}: FileUploaderOutlineType) => {
  const fileRef = useRef(null);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [uploadedAttachments, setUploadedAttachments] = useState({
    data: [],
    alert: null
  });
  const sizeFormats = {
    mb: 10 ** 6,
    kb: 1
  };

  useEffect(() => {
    if (uploadedAttachments.data.length > 0 && onUnsavedDismount) {
      onUnsavedDismount(uploadedAttachments.data);
    }
  }, [onUnsavedDismount, uploadedAttachments.data]);

  useEffect(() => {
    setUploadedAttachments(prev => ({
      ...prev,
      data: attachments
    }));
  }, [attachments]);

  const handleCardClick = () => fileRef.current.click();

  const uploadAttachments = async (
    formData: FormData,
    fileName: string,
    last: boolean
  ): Promise => {
    const buildResponse = (success, id) =>
      success ? { success: { id, name: fileName, type: 'temporal' } } : { alert };
    if (variant === 'bulk') {
      const response = await uploadTemporaryAttachment(formData);
      last && setLoading(false);
      return buildResponse(response.success, response.data.id, response.alert);
    }

    try {
      const response = await API.post(Endpoints.Files, formData);
      const { status, data } = response;
      return buildResponse(status === 201, data.id);
    } catch (err) {
      const alert = {
        severity: 'error',
        title: 'Error while uploading file',
        body: getErrorMessage(err),
        autoHideDuration: 8000
      };
      return buildResponse(null, null, alert);
    }
  };

  const handleSelectedFiles = async e => {
    const { files } = e.target;
    let result = {
      data: [],
      alert: null
    };
    if (
      uploadedAttachments.data.length <= maxFiles &&
      files.length <= maxFiles - uploadedAttachments.data.length
    ) {
      if (files.length === 0) {
        return;
      }
      setLoading(true);
      const filesToUpload = Array.from(files);
      await Promise.all(
        filesToUpload.map(async (file, i) => {
          if (file.size / sizeFormats[sizeFormat] < maxFileSize) {
            const attachmentFormData = new FormData();
            attachmentFormData.append('file', file);
            const response = await uploadAttachments(
              attachmentFormData,
              file.name,
              i === filesToUpload.length - 1
            );
            result = {
              data: [...result.data, { ...response.success, size: file.size }],
              alert: response.alert
            };
          } else {
            result = {
              data: [],
              alert: {
                severity: 'error',
                title: 'File exceeded limit size',
                body: `File ${file.name} is greater than ${maxFileSize}${sizeFormat}, and won't be added, select another file`,
                autoHideDuration: 8000
              }
            };
          }
        })
      );
    } else {
      result = {
        data: [],
        alert: {
          severity: 'warning',
          title: 'Maximum files exceeded',
          body: `You can only upload up to ${maxFiles} files`
        }
      };
    }
    setLoading(false);
    setUploadedAttachments(prev => ({
      data: [...prev.data, ...result.data],
      alert: result.alert
    }));
    onFilesChange && onFilesChange(result.data, result.alert);
  };

  const handleDelete = chipKey => async () => {
    let result = {};
    setLoading(true);
    setUploadedAttachments(prev => ({
      ...prev,
      data: prev.data.filter(file => file.id !== chipKey)
    }));
    const indexToRemove = uploadedAttachments.data.findIndex(
      attachment => attachment.id === chipKey
    );
    switch (uploadedAttachments.data[indexToRemove].type) {
      case 'temporal':
        await deleteTemporaryAttachment(chipKey)
          .then(res => {
            result = {
              remove: { type: 'id', value: chipKey },
              alert: res.alert
            };
          })
          .catch(err => {
            result = { alert: err.alert };
          })
          .finally(() => setLoading(false));
        break;
      case 'template':
        result = {
          remove: { type: 'id', value: chipKey },
          alert: {
            severity: 'success',
            title: 'File Removed',
            body: 'The file was removed successfully'
          }
        };
        setTimeout(() => setLoading(false), 1000);
        break;
      default:
        result = {
          alert: {
            severity: 'error',
            title: 'File Missing Type',
            body: `The file you're trying to remove doesn't have type. Allowed types (temporal, template)`,
            autoHideduration: 10000
          }
        };
    }
    onRemoveAttachment && onRemoveAttachment(result);
  };

  return (
    <FormControl className={classes.root} component="fieldset" error={error}>
      {label && <Text variant="body2" text={label} className={classes.label} />}
      <CardActionArea onClick={handleCardClick} className={classes.actionBorder}>
        <input
          ref={fileRef}
          onChange={handleSelectedFiles}
          accept={acceptFiles.join(',')}
          name={name}
          type="file"
          hidden
          multiple={maxFiles > 1}
        />
        <div
          className={clsx(
            classes.outline,
            uploadedAttachments.data.length > 0 && classes.withChips
          )}
        >
          {uploadedAttachments.data.length === 0 && placeholder && (
            <Text variant="body2" text={placeholder} className={classes.placeholder} />
          )}
          <div className={classes.chipsContainer}>
            {uploadedAttachments.data.map(file => (
              <Chip
                key={file.name}
                label={file.name}
                size="small"
                onDelete={handleDelete(file.id)}
              />
            ))}
          </div>
          <div className={classes.loader}>
            {loading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              <FPIcon
                component={SvgUploadButton}
                size={20}
                className={classes.iconAlignment}
                color={colors.success}
              />
            )}
          </div>
        </div>
      </CardActionArea>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

FileUploaderOutline.defaultProps = {
  label: null,
  name: null,
  placeholder: null,
  maxFiles: 4,
  maxFileSize: 5,
  sizeFormat: 'mb',
  acceptFiles: ['image/png', 'image/jpg', 'image/jpeg', '.pdf', '.docx', '.pptx', '.xlsx', '.csv'],
  onFilesChange: undefined,
  onRemoveAttachment: undefined,
  onUnsavedDismount: undefined,
  attachments: [],
  error: false,
  errorMessage: null,
  variant: 'default'
};

export default FileUploaderOutline;
