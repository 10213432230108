import { EntityRoutes } from 'routes/constants';
import { DateFormats } from 'UI/constants/defaults';
import { PlacementTabs } from 'UI/constants/status';

const dateRendererConfig = { id: 'dateBuilder', config: { format: DateFormats.SimpleDate } };
const timestampRendererConfig = {
  id: 'dateBuilder',
  config: { format: DateFormats.SimpleDateTime }
};

export const ColumnsDefinitions = [
  {
    name: 'id',
    label: 'Nº',
    options: {
      sort: false,
      display: false
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'statusBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'placement-status'
        }
      }
    }
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'candidate',
    label: 'Candidate',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'id',
          textKey: 'full_name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'industry',
    label: 'Industry',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'specialty',
    label: 'Specialty',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'subspecialty',
    label: 'Subspecialty',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'fee_amount',
    label: 'Fee Amount',
    options: {
      sort: true,
      display: true,
      renderer: 'currency'
    }
  },
  {
    name: 'recruiters',
    label: 'Recruiters',
    options: {
      sort: false,
      display: true
    }
  },
  {
    name: 'start_date',
    label: 'Start Date',
    options: {
      sort: true,
      display: true,
      renderer: dateRendererConfig
    }
  },
  {
    name: 'created_at',
    label: 'Created Date',
    options: {
      display: ({ activeSection }) => activeSection === PlacementTabs.PendingValidations,
      sort: true,
      renderer: timestampRendererConfig
    }
  },
  {
    name: 'invoice_date',
    label: 'Invoicing Date',
    options: {
      display: ({ activeSection }) => activeSection === PlacementTabs.Invoiced,
      sort: true,
      renderer: timestampRendererConfig
    }
  },
  {
    name: 'approved_date',
    label: 'Approved Date',
    options: {
      display: ({ activeSection }) => activeSection === PlacementTabs.Estimates,
      sort: true,
      renderer: timestampRendererConfig
    }
  },
  {
    name: 'coach',
    label: 'Team',
    options: {
      sort: true
    }
  },
  {
    name: 'fall_off_date',
    label: 'Fall Off Date',
    options: {
      display: ({ activeSection }) => activeSection === PlacementTabs.FallOff,
      sort: true,
      renderer: timestampRendererConfig
    }
  },
  {
    name: 'billing_company',
    label: 'Bill to Company',
    options: {
      sort: false,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'candidate_source_type',
    label: 'Candidate Source',
    options: {
      sort: false,
      display: false
    }
  },
  {
    name: 'job_order_source_type',
    label: 'Job Order Source',
    options: {
      sort: false,
      display: false
    }
  },
  {
    name: 'hiringAuthority',
    label: 'Hiring Authority',
    options: {
      sort: false,
      display: false,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.HiringAuthorityProfile,
          idKey: 'id',
          textKey: 'fullName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'estimate_creation_date',
    label: 'Estimate Creation On',
    options: {
      display: ({ activeSection }) => activeSection === PlacementTabs.Estimates,
      sort: true,
      renderer: timestampRendererConfig
    }
  },
  {
    name: 'estimate_approval_date',
    label: 'Estimate Approval On',
    options: {
      display: false,
      sort: true,
      renderer: timestampRendererConfig
    }
  },
  {
    name: 'invoices',
    label: 'Invoices',
    options: {
      display: false,
      sort: false
    }
  },
  {
    name: 'feeAgreementPaymentSchemeTitle',
    label: 'Agreement Type',
    options: {
      display: false,
      sort: true
    }
  },
  {
    name: 'state',
    label: 'State',
    options: {
      display: false,
      sort: true
    }
  },
  {
    name: 'city',
    label: 'City',
    options: {
      display: false,
      sort: true
    }
  }
];

export const FiltersGroups = [
  {
    id: 'general',
    name: 'Placement',
    filters: [
      'statuses',
      'feeAgreementPaymentScheme',
      'companies',
      'candidates',
      'candidateSourceTypes',
      'jobOrderSourceTypes'
    ]
  },
  {
    id: 'industries',
    name: 'Industries',
    filters: ['industries', 'specialties', 'subspecialties']
  },
  { id: 'team', name: 'Team', filters: ['coaches', 'recruiters'] },
  {
    id: 'location',
    name: 'Location',
    filters: ['countries', 'states', 'cities', 'cityRadius', 'zips', 'radius']
  }
];
