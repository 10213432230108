// @flow
import { useState } from 'react';
import { handleAssignEntityRole } from 'UI/components/organisms/inventoryProfiles/FPQuickView/utils';

import { getItemsToNavigate } from './useQuickViewPreview';

export const useProfileQuickView = ({ quickViewEntityRoleProps = {} }) => {
  const [entityId, setEntityId] = useState();
  const [quickViewEntity, setQuickViewEntity] = useState();
  const [itemsToNavigate, setItemsToNavigate] = useState(null);

  const [uiState, setUiState] = useState({
    isQuickViewOpen: false
  });

  const handleQuickViewPreview = (item, entity, tableData) => {
    setEntityId(item?.id ?? null);
    !quickViewEntityRoleProps && setQuickViewEntity(entity);

    quickViewEntityRoleProps &&
      handleAssignEntityRole({
        item,
        quickViewEntityRoleProps,
        onSetCurrentEntity: setQuickViewEntity
      });

    const data = getItemsToNavigate(tableData, quickViewEntityRoleProps);

    setItemsToNavigate(data);
    toggleQuickViewPreview();
  };

  const toggleQuickViewPreview = () =>
    setUiState(prev => ({ prev, isQuickViewOpen: !uiState.isQuickViewOpen }));

  return {
    handleQuickViewPreview,
    itemsToNavigate,
    quickViewEntity,
    entityId,
    quickViewState: uiState,
    setEntityId,
    toggleQuickViewPreview
  };
};
