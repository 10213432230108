export const CANDIDATE_DATA_SHEET_QUESTIONS_PATHS = {
  'establishingUrgency.whatIsTheReason': [],
  'establishingUrgency.howLongHaveYouBeenLooking': [],
  'establishingUrgency.idealTimeToStart': [],
  'establishingUrgency.onAScaleOf1To10.question': [],
  'establishingUrgency.onAScaleOf1To10.whatKeepsYouFromBeingA10': [],
  'establishingUrgency.onAScaleOf1To10.haveYouMadeSomething.question': [],
  'establishingUrgency.onAScaleOf1To10.haveYouMadeSomething.whatStepsHaveYouTaken': [],
  'establishingUrgency.onAScaleOf1To10.haveYouMadeSomething.whatCompaniesHaveYouAppliedTo': [],
  'establishingUrgency.isThereAnythingPersonal': [],
  'establishingUrgency.ifACompanyOffersYou': [],
  'candidateBackground.employmentHistoryItems': [],
  'professionalInsights.achievements': [],
  'workPreferences.whatDoYouLikeMost': [],
  'workPreferences.preferredNextTitle': [],
  'workPreferences.relocation.areYouWillingToRelocate': [],
  'workPreferences.relocation.relocationDestinations': [],
  'workPreferences.workPreference': [],
  'workPreferences.salary': [],
  'workPreferences.salaryRange': ['lowSalary', 'idealSalary', 'highSalary'],
  'qualification.candidateType': [],
  'qualification.candidateStatus': []
};

export const EditCandidateDataSheetStrings = {
  decisionDialog: {
    title: 'You Have Unsaved Changes In The Data Sheet',
    message:
      'If you exit without saving, you may lose your progress. Do you want to save your changes before exiting?',
    buttons: {
      cancel: 'Cancel',
      exitWithoutSaving: 'Exit Without Saving',
      saveAndExit: 'Save And Exit'
    }
  }
};

export const ANSWER = {
  POSITIVE: 'Yes',
  NEGATIVE: 'No'
};

export const MINIMUM_WORD_LENGTH = 3;

export const PHRASES_TO_IGNORE = [
  'see cv',
  'see resume',
  'tbd resume',
  'on resume',
  'see attached',
  'see above',
  'see cds',
  'see notes',
  'cds'
];
