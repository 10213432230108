import React from 'react';
import { Typography } from '@material-ui/core';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { When } from 'UI/components/atoms/When';
import { SvgArrowRight } from 'UI/res';

import LiItem from './TimelineItem.styled';

const TimelineItem = ({ item }) => {
  return (
    <LiItem>
      <Typography variant="subtitle1">{item.action}</Typography>
      <Typography variant="caption">{item.time}</Typography>
      <When condition={item.hasDetails}>
        <div>
          <FPActionButton
            href="#"
            variant="text"
            text="View Details"
            size="small"
            icon={SvgArrowRight}
            iconPosition="right"
            iconProps={{ size: 12 }}
          />
        </div>
      </When>
    </LiItem>
  );
};

export default TimelineItem;
