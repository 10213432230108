import { makeStyles } from '@material-ui/core';
import { flexAlignCenterSpaceBetween } from 'UI/utils/styles';

const maxWithSelectExistingContact = 580;

export const useStyles = makeStyles(
  theme => ({
    hr: {
      marginBottom: 0
    },
    button: {
      textTransform: 'inherit',
      textDecoration: 'underline',
      bottom: theme.spacing(0.1)
    },
    saveDraftBtn: {
      textTransform: 'none'
    },
    dateText: {
      color: theme.palette.customColors.grey
    },
    savedDateText: {
      color: theme.palette.customColors.grey,
      paddingBottom: theme.spacing(2)
    },
    deleteButton: {
      backgroundColor: theme.palette.error.main,
      textTransform: 'none',
      margin: theme.spacing(2),
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    },
    showMoreIconContainer: {
      textAlign: 'center',
      borderTop: `1px solid ${theme.palette.customColors.lightgrey}`
    },
    tableClasses: {
      '& .MuiTableHead-root': {
        '& .MuiTableCell-root': {
          backgroundColor: 'transparent !important'
        }
      },
      '& .MuiCardContent-root': {
        padding: '14px !important',
        backgroundColor: 'black'
      }
    },
    draftsCard: {
      padding: 'unset !important'
    },
    selectContactContainer: {
      ...flexAlignCenterSpaceBetween,
      width: '100%',
      fontSize: 16,
      marginBottom: theme.spacing(2),
      paddingRight: theme.spacing(0.5)
    },
    chooseExistingContact: {
      maxWidth: maxWithSelectExistingContact
    },
    alertButton: {
      textTransform: 'initial',
      padding: theme.spacing(0, 1),
      cursor: 'pointer'
    },
    resetButton: {
      whiteSpace: 'nowrap'
    }
  }),
  { classNamePrefix: 'newCandidates' }
);
