import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  {
    cardContainer: {
      position: 'sticky',
      top: 15
    },
    teamTableContainer: {
      height: '78vh'
    }
  },
  { classNamePrefix: 'teams-page' }
);
