// @flow
import React from 'react';
import type { InnerHtmlProps } from 'types/app';

const FPHtmlViewer = ({ content, ...rest }: InnerHtmlProps) => {
  return (
    <div
      data-testid="content-viewer"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: content
      }}
      {...rest}
    />
  );
};

FPHtmlViewer.defaultProps = {};

export default FPHtmlViewer;
