// @flow
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { FormHelperText } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { showAlert } from 'actions/app';
import {
  setSelectedTemplate,
  startRemovingSelectedTemplate,
  startSettingSelectedTemplateData
} from 'actions/bulkEmail';
import { getTemplateById } from 'services/bulkEmail';
import type { TemplateDropDownProps } from 'types/app';
import TemplatesTreeView from 'UI/components/organisms/BulkEmail/BulkEmailTemplates/TemplateTreeView';
import { TEMPLATE_ENTITY_TYPES } from 'UI/constants/defaults';

import { useStyles } from './styles';

const noTemplateSelected = { name: 'NoTemplate' };
const validateValueIsNoTemplateOption = value => value === noTemplateSelected.name;

const TemplateDropDown = ({
  enableIconClick = false,
  enableNoTemplateOption = false,
  folderIdToExclude = null,
  formFieldName,
  formNamesToSet = [],
  onlyShowFolders = false,
  placeholder = null,
  preselectedFolderId = null,
  requiredMessage = null,
  shouldRegisterWithin = false
}: TemplateDropDownProps) => {
  const dispatch = useDispatch();
  const { errors, getValues, register, setValue, unregister } = useFormContext();
  const menuList = useRef(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const templateItemFormValue = getValues(formFieldName);
  const classes = useStyles({ itemId: templateItemFormValue?.id });

  useEffect(() => {
    shouldRegisterWithin && register({ name: formFieldName }, { required: requiredMessage });
    return () => {
      shouldRegisterWithin && unregister(formFieldName);
    };
  }, [formFieldName, getValues, register, requiredMessage, shouldRegisterWithin, unregister]);

  const handleChange = ({ target }) => {
    if (validateValueIsNoTemplateOption(target.value?.name)) {
      dispatch(startRemovingSelectedTemplate());
      setValue(formFieldName, null);
    }
    toggleOpenDropdown(false)();
  };

  const toggleOpenDropdown = isOpen => () => setIsDropdownOpen(isOpen);

  const handleOutsideClick = ({ target }) => {
    if (
      validateValueIsNoTemplateOption(target?.value?.name) ||
      !menuList.current.contains(target)
    ) {
      toggleOpenDropdown(false)();
    }
  };

  const handleTemplateSelect = async ({ id, entity }) => {
    if (entity !== TEMPLATE_ENTITY_TYPES.Template) return;
    const { success, data, alert } = await getTemplateById(id);
    if (success) {
      dispatch(
        startSettingSelectedTemplateData({
          id,
          data: {
            ...data,
            parentFolder: { id: data.emailTemplateFolder.id, name: data.emailTemplateFolder.name }
          }
        })
      );
      setValue(formFieldName, { id, name: data.name, entity }, true);
      formNamesToSet.forEach(({ key, valueKey }) => {
        setValue(key, data?.emailBody[valueKey]);
      });
    } else {
      dispatch(showAlert(alert));
    }
    toggleOpenDropdown(false)();
  };

  const handleFolderSelect = ({ id, name }) => {
    dispatch(setSelectedTemplate({ parentFolder: { id, name } }));
    setValue(
      formFieldName,
      {
        id: parseInt(id, 10),
        name,
        entity: TEMPLATE_ENTITY_TYPES.Folder
      },
      true
    );
    toggleOpenDropdown(false)();
  };

  const handleOnClickItem = onlyShowFolders ? handleFolderSelect : handleTemplateSelect;
  const selectedValue =
    !templateItemFormValue && enableNoTemplateOption ? noTemplateSelected : templateItemFormValue;

  return (
    <Grid className={classes.root}>
      <FormControl error={!!errors[formFieldName]} className={classes.formControl}>
        <Select
          name={formFieldName}
          variant="outlined"
          value={selectedValue || {}}
          onOpen={toggleOpenDropdown(true)}
          renderValue={value =>
            validateValueIsNoTemplateOption(value?.name) || !value?.name ? placeholder : value.name
          }
          open={isDropdownOpen}
          onChange={handleChange}
          className={classes.select}
          data-testid="grouped-select"
          MenuProps={{
            MenuListProps: {
              ref: menuList
            },
            onClick: handleOutsideClick,
            getContentAnchorEl: null,
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            className: classes.selectMenu
          }}
        >
          {enableNoTemplateOption && <MenuItem value={noTemplateSelected}>No Template</MenuItem>}
          <TemplatesTreeView
            folderIdToExclude={folderIdToExclude}
            onClickItem={handleOnClickItem}
            preselectedFolderId={preselectedFolderId}
            onlyShowFolders={onlyShowFolders}
            enableIconClick={enableIconClick}
            shouldHideArchiveFolder
          />
        </Select>
        <FormHelperText>{errors[formFieldName]?.message}</FormHelperText>
      </FormControl>
    </Grid>
  );
};

export default TemplateDropDown;
