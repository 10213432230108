import { THEME } from 'GlobalStyles';

export const DEFAULT_OPTIONS = {
  annotations: {
    alwaysOutside: true,
    textStyle: {
      fontSize: 12,
      auraColor: 'none',
      color: THEME.palette.grey[700]
    }
  },
  chartArea: { width: '90%', height: '90%' },
  legend: { position: 'none' },
  series: {
    0: { lineWidth: 2 },
    1: { lineDashStyle: [4, 4], color: 'black' } // Average Line
  },
  vAxis: {
    minValue: 0
  }
};
