import { makeStyles } from '@material-ui/core';
import { avatarMeasures } from 'UI/constants/dimensions';
import { flexAlignCenter } from 'UI/utils/styles';

export const useStyles = makeStyles(
  theme => ({
    root: {
      ...flexAlignCenter,
      width: 'fit-content',
      '& > *': {
        marginRight: theme.spacing(2)
      },
      '& > :last-child': {
        marginRight: 0
      }
    },
    addButton: {
      border: '1px dashed'
    },
    groupedAvatars: {
      marginLeft: theme.spacing(1),
      '& > div': {
        marginLeft: theme.spacing(-1)
      },
      '&:first-child': {
        marginLeft: 0
      }
    }
  }),
  { classNamePrefix: 'avatarList' }
);

export const useGroupStyles = makeStyles({
  avatar: ({ size }) => {
    const avatarSize = avatarMeasures[size];
    return {
      width: avatarSize,
      height: avatarSize,
      fontSize: avatarSize / avatarMeasures.fontRatio,
      '& > div': {
        width: avatarSize,
        height: avatarSize,
        fontSize: avatarSize / avatarMeasures.fontRatio
      }
    };
  }
});
