// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { globalStyles } from 'GlobalStyles';
import TitleLabel from 'UI/components/atoms/TitleLabel';
import ActiveFilters from 'UI/components/molecules/ActiveFilters';

import { useStyles } from './styles';

/** Atoms, Components and Styles */

/** API / EntityRoutes / Endpoints / EntityType */

const ListPageLayoutTitle = props => {
  const { needsBackNavigation, title, titleToolbar, loading } = props;
  const responsiveOptions = titleToolbar
    ? {
        xs: 6,
        sm: 8,
        lg: 9
      }
    : {
        xs: 12
      };
  return (
    <>
      <Grid item {...responsiveOptions}>
        <TitleLabel backNavigation={needsBackNavigation} text={title} loading={loading} />
      </Grid>
      {titleToolbar && !loading && (
        <Grid item xs={6} sm={4} lg={3}>
          {titleToolbar}
        </Grid>
      )}
    </>
  );
};

const ListPageLayoutSelector = props => {
  const { loading, selector } = props;
  const classes = useStyles(props);

  return selector ? (
    <Grid item xs={12}>
      <div className={classes.selectorContainer}>
        {loading ? <Skeleton style={globalStyles.skeletonItem} color="A200" /> : selector}
      </div>
    </Grid>
  ) : null;
};

type ListPageLayoutProps = {
  filters: any,
  loading: boolean,
  mode: 'template' | 'contained' | 'onPage',
  needsBackNavigation?: boolean,
  onFilterRemove: any => any,
  onFiltersReset: () => void,
  selector?: any,
  title: string,
  titleToolbar: any
};

const ListPageLayout = ({
  children,
  filters,
  loading,
  mode,
  needsBackNavigation,
  onFilterRemove,
  onFiltersReset,
  selector,
  title,
  titleToolbar
}: ListPageLayoutProps) => {
  const classes = useStyles();

  // To do optimize and refactor this section

  const listModes = {
    template: 'template',
    contained: 'contained',
    onPage: 'onPage'
  };

  const layoutClasses = {
    template: classes.paddingTemplate,
    contained: classes.paddingContained,
    onPage: classes.paddingOnPage
  };

  const gridClasses = {
    template: classes.filtersTemplate,
    contained: classes.filtersContained,
    onPage: classes.filtersOnPage
  };

  return (
    <div className={layoutClasses[mode]} mode={mode}>
      <Grid className={mode === listModes.onPage ? classes.containerOnPage : null} container>
        {mode === listModes.template && (
          <ListPageLayoutTitle
            needsBackNavigation={needsBackNavigation}
            title={title}
            loading={loading}
            titleToolbar={titleToolbar}
          />
        )}
        {mode !== listModes.onPage && (
          <ListPageLayoutSelector selector={selector} loading={loading} />
        )}

        <Grid className={gridClasses[mode]} item xs={12}>
          <ActiveFilters
            filters={filters}
            isLoading={loading}
            onFilterRemove={onFilterRemove}
            onReset={onFiltersReset}
          />
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

ListPageLayout.defaultProps = {
  filters: null,
  loading: null,
  onFilterRemove: () => {},
  onFiltersReset: () => {},
  title: '',
  mode: 'template',
  needsBackNavigation: false,
  selector: undefined,
  titleToolbar: undefined
};

export default ListPageLayout;
