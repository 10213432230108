import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenterSpaceAround } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  list: ({ isSmallViewPort }) => ({
    display: 'grid',
    gridTemplateColumns: isSmallViewPort ? '1fr 1fr' : '1fr',
    gridGap: theme.spacing(0, 1),
    gridTemplateAreas:
      isSmallViewPort &&
      `
        'firstPlace sixthPlace'
        'secondPlace seventhPlace'
        'thirdPlace eighthPlace'
        'fourthPlace ninethPlace'
        'fifthPlace tenthPlace'
      `
  }),
  circularProgress: {
    marginTop: theme.spacing(5)
  },
  loadingStateContainer: {
    flex: 1,
    flexDirection: 'column',
    textAlign: 'center',
    height: 300,
    ...flexAlignCenterSpaceAround
  },
  titleContentLabel: {
    fontSize: 11,
    fontWeight: '500 !important',
    letterSpacing: '1.5px',
    lineHeight: '16px',
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase'
  },
  footerContainer: {
    marginBottom: 0
  },
  homeCard: {
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  }
}));
