import React, { useState } from 'react';
import { Chart } from 'react-google-charts';
import { Widget } from 'features/command-center';
import { getProductionAndCashInByMonth } from 'features/command-center/services';
import moment from 'moment';
import strings from 'strings';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { DateFormats } from 'UI/constants/defaults';
import { createCustomStaticRanges, getYearToDate } from 'UI/utils';

import { DateRange } from '../../shared/InlineFilters/components/DateRange';
import { ChartLegend } from '../ChartLegend';

import {
  createSeries,
  DEFAULT_OPTIONS,
  MAIN_COLOR,
  refactorData,
  SECONDARY_COLOR
} from './ProductionCashInByMonth.utils';

const STRINGS = strings.commandCenter.finance.productionCashInByMonth;

export const ProductionCashInByMonth = ({ enableDateFilter, requestParams = {} }) => {
  const [dateRange, setDateRange] = useState(() => getYearToDate());
  const queryDateRange = {
    startDate: moment(dateRange.startDate).format(DateFormats.QueryFormat),
    endDate: moment(dateRange.endDate).format(DateFormats.QueryFormat)
  };

  const handleDateRangeChange = selectedDateRange => setDateRange(selectedDateRange);

  const renderChart = ([results]) => {
    const series = createSeries(results);
    const chartData = refactorData(series);

    return (
      <>
        {enableDateFilter && (
          <DateRange
            defaultRange={dateRange}
            DateRangerPickerProps={{
              staticRanges: createCustomStaticRanges(additionalPeriodRanges)
            }}
            onPeriodChange={handleDateRangeChange}
          />
        )}

        <ChartLegend
          mainTitle="Production"
          mainColor={MAIN_COLOR}
          secondaryTitle="Cash In"
          secondaryColor={SECONDARY_COLOR}
        />

        <Chart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={chartData}
          options={DEFAULT_OPTIONS}
        />
      </>
    );
  };

  return (
    <Widget
      header={STRINGS.title}
      subheader={STRINGS.subtitle}
      promises={[
        () =>
          getProductionAndCashInByMonth({
            ...queryDateRange,
            ...requestParams
          })
      ]}
    >
      {renderChart}
    </Widget>
  );
};
