import React, { useContext } from 'react';
import GpacGeneratorForm from '@gogpac/form-generator';
import { CircularProgress } from '@material-ui/core';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { useGlobalStyles } from 'GlobalStyles';
import useProfile from 'hooks/profileActions';
import useFetchCatalogs from 'hooks/useFetchCatalogs';
import useFetchJsonSchema from 'hooks/useFetchJsonSchema';
import usePageTitle from 'hooks/usePageTitle';
import { UserProviderContext } from 'providers/UserProvider';
import { EntityRoutes } from 'routes/constants';
import { canUserOrStaffEditEntity } from 'services/Authorization';
import { JobOrderIncludes } from 'services/includes';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { FPCard } from 'UI/components/atoms/FPCard';
import { DataSheetFooter } from 'UI/components/molecules/DataSheetFooter';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { PageTitles } from 'UI/constants/defaults';
import { EntityType, FormSchema } from 'UI/constants/entityTypes';
import { RequestStatus, UIStatus } from 'UI/constants/status';
import ErrorPage from 'UI/pages/ErrorPage';
import { SvgNoJobOrders } from 'UI/res';

import { SAVING_DRAFT_STATUS } from './hooks/useDataSheetSchema';
import {
  AnimationContainer,
  EditDataSheetCardContent,
  MainContainer
} from './EditDataSheet.styled';
import { useDataSheetSchema, useFetchDataSheet } from './hooks';

const HEADER_PROPS = {
  title: strings.inventory.jobOrders.editDataSheet.title,
  subheader: strings.inventory.jobOrders.editDataSheet.subheader,
  variant: 'section',
  actionMode: 'noAction'
};

const { contentPageTitle } = strings.inventory.jobOrders.editDataSheet;

const EditDataSheet = ({ match, history }) => {
  usePageTitle({ title: PageTitles.EditDataSheet, isLoading: false });
  const jobOrderId = match.params.id;
  const [currentUser] = useContext(UserProviderContext);
  const { status: schemaStatus, schemaData } = useFetchJsonSchema(FormSchema.JobOrderDataSheet);
  const { status: dataSheetStatus, results } = useProfile({
    entity: EntityType.Joborder,
    profileId: jobOrderId,
    includes: JobOrderIncludes
  });

  const { id, title, whiteSheet, company, status } = results || {};
  const canUserEdit = canUserOrStaffEditEntity(currentUser, results) || !results;
  const { status: dataSheetFormStatus, dataSheet } = useFetchDataSheet(jobOrderId);
  const { catalogs, uiState: catalogsStatus } = useFetchCatalogs({ companyId: company?.id });
  const [dataSheetSchemaState, dataSheetSchemaActions] = useDataSheetSchema({
    jobOrder: {
      id,
      title
    },
    companyName: company?.name || '',
    canUserEdit,
    whiteSheet,
    whiteSheetStatus: status,
    dataSheet,
    catalogs,
    schemaData,
    user: currentUser
  });

  const globalClasses = useGlobalStyles();

  const handleClose = () => {
    history.push(EntityRoutes.JoborderProfile.replace(':id', jobOrderId));
  };

  if (!canUserEdit) {
    return <ErrorPage error={HTTPStatusCodes.Unauthorized} />;
  }

  if (
    [dataSheetStatus, catalogsStatus, schemaStatus, dataSheetFormStatus].includes(
      RequestStatus.Loading
    )
  ) {
    return (
      <ContentPageLayout
        mode="mainLayout"
        text={contentPageTitle}
        titleLabelProps={{ backNavigation: true }}
      >
        <AnimationContainer>
          <CircularProgress />
        </AnimationContainer>
      </ContentPageLayout>
    );
  }

  if ([dataSheetStatus, catalogsStatus, schemaStatus].includes(RequestStatus.Error)) {
    return (
      <ContentPageLayout>
        <EmptyPlaceholder
          {...strings.shared.errors.serverError}
          customEmptyState={<SvgNoJobOrders height={400} />}
        >
          <FPActionButton text="Try again" onClick={() => window.location.reload()} />
        </EmptyPlaceholder>
      </ContentPageLayout>
    );
  }

  if (
    [dataSheetStatus, catalogsStatus, schemaStatus, dataSheetFormStatus].includes(
      RequestStatus.Idle
    )
  ) {
    return null;
  }

  return (
    <ContentPageLayout
      mode="mainLayout"
      text={contentPageTitle}
      titleLabelProps={{ backNavigation: true }}
    >
      <MainContainer className={globalClasses.itemCreationDataWrapper}>
        <FPCard>
          <EditDataSheetCardContent variant="relaxed" headerProps={HEADER_PROPS}>
            <GpacGeneratorForm
              schema={dataSheetSchemaState.schemas.schema}
              uiSchema={dataSheetSchemaState.schemas.uiSchema}
              onSubmit={dataSheetSchemaActions.handleOnSubmit}
              onChange={dataSheetSchemaActions.handleOnChange}
              formData={dataSheetSchemaState.formData}
              onError={dataSheetSchemaActions.handleOnError}
              customValidate={dataSheetSchemaActions.handleCustomValidate}
              {...dataSheetSchemaState.commonStyledFormProps}
            />
          </EditDataSheetCardContent>
        </FPCard>
      </MainContainer>
      <DataSheetFooter
        isSaving={dataSheetSchemaState.status === UIStatus.Saving}
        isSavingDraft={dataSheetSchemaState.status === SAVING_DRAFT_STATUS}
        canSave={dataSheetSchemaState.canSave}
        canSaveDraft={!dataSheetSchemaState.isDataSheetComplete}
        onSave={dataSheetSchemaActions.handleOnSave}
        onSaveDraft={dataSheetSchemaActions.handleOnSaveDraft}
        onClose={handleClose}
      />
    </ContentPageLayout>
  );
};

export default EditDataSheet;
