// @flow

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { confirm, showAlert, showFile } from 'actions/app';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import { deleteInvoice, updateInvoice } from 'services/Placements';
import TextBox from 'UI/components/atoms/TextBox';
import HistoryItem from 'UI/components/molecules/HistoryItem';
import InlineEditField from 'UI/components/molecules/InlineEditField';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { TypeHistoryItem } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { UIStatus } from 'UI/constants/status';
import { EmptyPlacements } from 'UI/res';
import { REQUIRED_VALIDATION } from 'UI/utils';

export type PlacementInvoicesFormProps = {
  id: number,
  readOnly: boolean,
  onInvoicesChanged: (remainOpen: boolean) => any
};

const PlacementInvoicesForm = ({ id, readOnly, onInvoicesChanged }: PlacementInvoicesFormProps) => {
  const { register, errors } = useFormContext();
  const endpoint = `${Endpoints.PlacementInvoices.replace(':id', id)}`;
  const { Status, setState } = useFetchWithStatus(endpoint);
  const dispatch = useDispatch();

  const removeInvoiceLocally = (invoiceID: number) =>
    setState(prevState => ({
      ...prevState,
      results: prevState.results.filter(each => each.id !== invoiceID)
    }));

  const updateInvoiceLocally = (invoiceID: number, newProps: any) =>
    setState(prevState => ({
      ...prevState,
      results: prevState.results.map(each =>
        each.id !== invoiceID ? each : { ...each, ...newProps }
      )
    }));

  const handleDeleteClick = item => () => {
    dispatch(
      confirm({
        severity: 'warning',
        title: 'Please confirm',
        confirmButtonText: 'Delete Invoice',
        message: `Are you sure you want to delete invoice '${item.number}'?`,
        onConfirm: async ok => {
          if (!ok) return;

          updateInvoiceLocally(item.id, { status: UIStatus.Loading });
          const result = await deleteInvoice(id, item.id);
          if (result.success) {
            removeInvoiceLocally(item.id);
            onInvoicesChanged(true);
          }
          result.alert && result.alert.body && dispatch(showAlert(result.alert));
        }
      })
    );
  };

  const update = async (itemId, data) => {
    const result = await updateInvoice(id, itemId, data);
    result.alert && result.alert.body && dispatch(showAlert(result.alert));
    return result;
  };

  const handleEditClick = item => () => updateInvoiceLocally(item.id, { status: UIStatus.Editing });
  const handleEditionCanceled = itemId =>
    updateInvoiceLocally(itemId, { status: UIStatus.Default });
  const handleEditionCompleted = (itemId, value) => {
    updateInvoiceLocally(itemId, { status: UIStatus.Default, number: value });
    onInvoicesChanged(true);
  };
  const handleShowFile = url => () => {
    dispatch(
      showFile({
        url,
        useProxy: true
      })
    );
  };

  const getMenuOptions = (innerId, number, url) => [
    {
      title: 'Edit',
      action: handleEditClick({ id: innerId }),
      visible: !readOnly
    },
    {
      title: 'Delete',
      action: handleDeleteClick({ id: innerId, number }),
      visible: !readOnly
    },
    {
      title: 'View Invoice',
      action: handleShowFile(url),
      visible: !!url
    }
  ];

  return (
    <>
      {!readOnly && (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Add an invoice
          </Typography>
          <Box mb={2}>
            <TextBox
              name="invoice_number"
              label="Invoice Number *"
              inputRef={register({ required: REQUIRED_VALIDATION.required })}
              error={!!errors.invoice_number}
              errorText={errors?.invoice_number?.message}
            />
          </Box>
        </>
      )}

      <Typography variant="subtitle1" gutterBottom>
        Invoice history:
      </Typography>
      <Status
        loading={
          <List dense>
            <HistoryItem isLoading />
          </List>
        }
        empty={
          <Typography paragraph gutterBottom>
            No invoices yet
          </Typography>
        }
        error={error => (
          <EmptyPlaceholder title="Oops" subtitle={error} customEmptyState={<EmptyPlacements />} />
        )}
        success={results => (
          <List dense>
            {results.map(({ id: innerId, user, number, created_at, status, url }) =>
              status !== UIStatus.Editing ? (
                <HistoryItem
                  key={innerId}
                  date={created_at}
                  type={TypeHistoryItem.Custom}
                  action={
                    <>
                      <b>{user?.personalInformation?.full_name || user?.email}</b> added the invoice{' '}
                      <b>{number}</b>
                    </>
                  }
                  menuOptions={getMenuOptions(innerId, number, url)}
                  isLoading={status === UIStatus.Loading}
                />
              ) : (
                <InlineEditField
                  itemId={innerId}
                  name="invoice_number"
                  label="Edit invoice number"
                  defaultValue={number}
                  onCancel={handleEditionCanceled}
                  onUpdate={handleEditionCompleted}
                  updateAction={update}
                />
              )
            )}
          </List>
        )}
      />
    </>
  );
};

PlacementInvoicesForm.defaultProps = {
  onInvoicesChanged: () => {}
};

export default PlacementInvoicesForm;
