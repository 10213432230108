// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { DateFormats } from 'UI/constants/defaults';
import { toLocalTime } from 'UI/utils';

import { useStyles } from '../styles';

type ReferenceReleaseViewProps = {
  email: any
};

const ReferenceReleaseView = ({ email }: ReferenceReleaseViewProps) => {
  const classes = useStyles();
  const sentDate = toLocalTime(email?.created_at).format(DateFormats.SimpleDateTime);

  const emailFields = [
    { content: `From: ${email.user.email}`, visible: true },
    { content: `Date: ${sentDate}`, visible: true },
    { content: `To: ${email.to?.join(', ')}`, visible: email.to && email.to.length > 0 },
    { content: `Cc: ${email.cc?.join(', ')}`, visible: email.cc && email.cc.length > 0 },
    { content: `Bcc: ${email.bcc?.join(', ')}`, visible: email.bcc && email.bcc.length > 0 },
    { content: `Subject: ${email.subject}`, visible: true }
  ];

  return (
    <>
      <div className={classes.emailHeader}>
        {emailFields.map(
          ({ content, visible }) =>
            visible && (
              <Typography key={content} gutterBottom>
                {content}
              </Typography>
            )
        )}
      </div>
      <div
        className={classes.emailBody}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: email.body
        }}
      />
    </>
  );
};

ReferenceReleaseView.defaultProps = {};

export default ReferenceReleaseView;
