import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import strings from 'strings';

import { FORM_FIELDS_MAP } from '../fields';

const { extractCompaniesContacts: extractCompaniesContactsCopies } =
  strings.searchProjects.forms.searchProject.sections;

const SearchProjectExtractOptions = () => {
  const { errors, getValues, setValue, watch, register, unregister } = useFormContext();
  const formValues = watch();

  useEffect(() => {
    register({ name: FORM_FIELDS_MAP.addEmployees.key, value: false });
    register({ name: FORM_FIELDS_MAP.addHiringAuthorities.key, value: false });
    register(
      { name: FORM_FIELDS_MAP.extractContacts.key, value: false },
      {
        validate: {
          atLeastOnePositive: value =>
            value || extractCompaniesContactsCopies.atLeastOnePositiveMessage
        }
      }
    );

    return () => {
      unregister(FORM_FIELDS_MAP.addEmployees.key);
      unregister(FORM_FIELDS_MAP.addHiringAuthorities.key);
      unregister(FORM_FIELDS_MAP.extractContacts.key);
    };
  }, [register, unregister]);

  const handleCheckChange = ({ target }) => {
    setValue(target.name, target.checked);

    const extractContacts =
      target.checked ||
      getValues(FORM_FIELDS_MAP.addEmployees.key) ||
      getValues(FORM_FIELDS_MAP.addHiringAuthorities.key);
    setValue(FORM_FIELDS_MAP.extractContacts.key, extractContacts, true);
  };

  return (
    <Grid container direction="column">
      <FormControlLabel
        control={
          <Checkbox
            name={FORM_FIELDS_MAP.addEmployees.key}
            checked={!!formValues[FORM_FIELDS_MAP.addEmployees.key]}
            onChange={handleCheckChange}
            color="primary"
            disableRipple
          />
        }
        label={extractCompaniesContactsCopies.options.addEmployees}
      />
      <FormControlLabel
        control={
          <Checkbox
            name={FORM_FIELDS_MAP.addHiringAuthorities.key}
            checked={!!formValues[FORM_FIELDS_MAP.addHiringAuthorities.key]}
            onChange={handleCheckChange}
            color="primary"
            disableRipple
          />
        }
        label={extractCompaniesContactsCopies.options.addHiringAuthorities}
      />
      <FormHelperText error={!!errors[FORM_FIELDS_MAP.extractContacts.key]}>
        {errors[FORM_FIELDS_MAP.extractContacts.key]?.message}
      </FormHelperText>
    </Grid>
  );
};

export default SearchProjectExtractOptions;
