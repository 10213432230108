export const notesColumns = [
  {
    name: 'id',
    options: {
      display: 'excluded'
    }
  },
  {
    name: 'created_by',
    label: 'Created by',
    options: {
      display: true,
      sort: false,
      renderer: 'default'
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      display: true,
      sort: false,
      renderer: 'default'
    }
  },
  {
    name: 'text',
    label: 'Description',
    options: {
      display: true,
      sort: false,
      renderer: 'default'
    }
  },
  {
    name: 'date',
    label: 'Created at',
    options: {
      display: true,
      sort: false,
      renderer: 'date'
    }
  }
];
