// @flow
import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';

type LogType = {
  label: string,
  logs?: Array<LogType>
};

type ChangeLogsDifferentiatedProps = {
  logs: Array<LogType>
};

const ChangeLogsDifferentiated = ({ logs }: ChangeLogsDifferentiatedProps) => {
  if (!logs) return null;

  return logs?.length === 0 ? (
    <Typography gutterBottom>
      {strings.inventoryProfiles.sections.tabs.changeLogs.drawer.empty}
    </Typography>
  ) : (
    recursiveLogs(logs)
  );
};

export const recursiveLogs = (logs: Array<LogType>) => {
  if (!logs) return null;

  return (
    <ul>
      {logs.map(log => (
        <Fragment key={log.label}>
          <li>
            <Typography gutterBottom>{log.label}</Typography>
            {log?.logs?.length > 0 && recursiveLogs(log.logs)}
          </li>
        </Fragment>
      ))}
    </ul>
  );
};

export default ChangeLogsDifferentiated;
