// eslint-disable-next-line import/no-cycle
import jwt from 'jsonwebtoken';
import API from 'services/API';
import { Endpoints } from 'UI/constants/endpoints';

const loginWithAuthCode = async authCode => {
  const response = await API.post(Endpoints.Users, { code: authCode });

  if (!response?.data?.token || response?.status !== 200) {
    throw new Error(response);
  }

  const { token } = response.data;
  const decodedToken = jwt.decode(token.token);
  localStorage.setItem('access', JSON.stringify(token));
  API.defaults.headers.Authorization = `Bearer ${token.token}`;
  return decodedToken.data.user;
};

const getAccess = () => {
  const access = localStorage.getItem('access');
  return access && JSON.parse(access);
};

const getToken = () => {
  const access = getAccess();
  return access && access.token;
};

const getRefreshToken = () => {
  const access = getAccess();
  return access && access.refreshToken;
};

const getCurrentUser = () => {
  const access = getAccess();

  const decodedToken = access && jwt.decode(access.token);
  return decodedToken && decodedToken.data.user;
};

const isAuthenticated = () => {
  return getToken() !== null;
};

const deleteCrossDomainAuthCookie = () => {
  const authCookieKey = window.GPAC_ENV?.AUTH_COOKIE_KEY || process.env.REACT_APP_AUTH_COOKIE_KEY;
  if (!authCookieKey) {
    return;
  }

  const authDomain = window.location.hostname.split('.').slice(-2).join('.');
  document.cookie = `${authCookieKey}=; path=/; domain=.${authDomain}; Secure; SameSite=None; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

const logout = async () => {
  await API.get('users/logout');

  cleanLocalStorage();
  logoutMicrosoft();
  deleteCrossDomainAuthCookie();
};

const logoutMicrosoft = () => {
  const postLogoutUrl = `${
    (window.GPAC_ENV && window.GPAC_ENV.LOGOUT_REDIRECT_URL) || process.env.REACT_APP_REDIRECT_URL
  }`;

  window.location.replace(
    `${process.env.REACT_APP_MICROSOFT_URL}${process.env.REACT_APP_TENANT_URL}/oauth2/v2.0/logout?post_logout_redirect_uri=${postLogoutUrl}`
  );
};

const cleanLocalStorage = () => {
  localStorage.removeItem('notificationToken');
  localStorage.removeItem('access');
  sessionStorage.clear();
};

const getRedirectPage = () => {
  return localStorage.getItem('redirectPage');
};

export {
  cleanLocalStorage,
  deleteCrossDomainAuthCookie,
  getCurrentUser,
  getRedirectPage,
  getRefreshToken,
  getToken,
  isAuthenticated,
  loginWithAuthCode,
  logout
};
