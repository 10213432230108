// @flow
import React from 'react';
import { StatsItemsCard } from 'features/command-center/components/shared';
import { ENDPOINTS } from 'features/command-center/constants';
import queryString from 'query-string';
import { filtersToParams } from 'selectors/app';
import API from 'services/API';
import strings from 'strings';

import { formatResponseData } from './HotInventoryStatsSummary.utils';

const fetchData = async (endpoint, httpOptions) => {
  const response = await API.get(endpoint, httpOptions);
  return formatResponseData(response?.data);
};

export const HotInventoryStatsSummary = ({ filters }) => {
  const filtersParams = filtersToParams(filters);
  const queryParams = queryString.stringify(filtersParams, { arrayFormat: 'comma' });
  const endpoint = `${ENDPOINTS.hotInventoryStatsSummary}?${queryParams}&metrics=jobOrder,candidate`;

  return (
    <StatsItemsCard
      header={strings.commandCenter.dominateSheet.hotInventoryStatsSummary.title}
      promise={httpOptions => fetchData(endpoint, httpOptions)}
      subheader={strings.commandCenter.dominateSheet.hotInventoryStatsSummary.subtitle}
      textAlign="center"
    />
  );
};
