// @flow
import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

import { useStyles } from './styles';

type SkeletonItemProps = {
  rows: number
};

const SkeletonList = (props: SkeletonItemProps) => {
  const { rows } = props;

  const classes = useStyles();
  const cardRows = 6;

  const circleSkeletonProps = {
    variant: 'circle',
    width: 24,
    height: 24
  };

  return (
    <>
      <div className={classes.skeletonBar} role="progressbar">
        <div className={classes.dotBarContainer}>
          <Skeleton {...circleSkeletonProps} />
          <Box mx={2}>
            <Skeleton {...circleSkeletonProps} />
          </Box>
          <Skeleton {...circleSkeletonProps} />
        </div>
        <div className={classes.headerItemsBar} />
      </div>

      <div className={classes.rowsContainer} role="progressbar">
        {Array.from(Array(rows)).map((each, i) => (
          <div key={i.toString()} className={classes.skeletonRow}>
            {Array.from(Array(5)).map((e, j) => (
              <div key={j.toString()} className={classes.skeletonItemContainer}>
                <Skeleton height={10} className={classes.skeletonItem} />
              </div>
            ))}
          </div>
        ))}
      </div>
      {rows >= cardRows && (
        <div className={classes.footerSkeletonBar}>
          <Skeleton width="100%" height={56} color="A200" />
        </div>
      )}
    </>
  );
};

SkeletonList.defaultProps = {
  rows: 10
};

export default SkeletonList;
