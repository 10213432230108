// @flow
import { useEffect, useState } from 'react';
import { getJsonSchema } from 'services/JsonSchema';

type UseFetchCandidateDataSheetParams = {
  schemaName: string
};
const useFetchCandidateDataSheet = ({ schemaName }: UseFetchCandidateDataSheetParams) => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!schemaName) return;
      if (data) return;

      setIsFetching(true);
      setError(null);

      try {
        const response = await getJsonSchema(schemaName);
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setIsFetching(false);
      }
    };

    fetchData();

    return () => {
      setIsFetching(false);
    };
  }, [schemaName, data]);

  return [
    {
      isFetching,
      error,
      data
    }
  ];
};

export default useFetchCandidateDataSheet;
