import * as React from "react";

const SvgSubtractCircle = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M12 2.293a10 10 0 1010 10 10.011 10.011 0 00-10-10zm5 11H7v-2h10z" />
    </svg>
  );
};

export default SvgSubtractCircle;
