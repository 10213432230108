import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export const UrgencyVerification = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  fontWeight: 600,
  '&.MuiTypography-body1': {
    fontSize: 18
  }
}));
