import React from 'react';
import { Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowRight } from '@material-ui/icons';
import { TreeView } from '@material-ui/lab';
import strings from 'strings';

import FPTreeItemList from './FPTreeItemList';

const FPTreeView = ({ tree, title = strings.pil.title }) => {
  if (!tree) {
    return <Typography>No {title}</Typography>;
  }

  return (
    <>
      <Typography variant="overline">
        {title} ({tree.length})
      </Typography>
      <TreeView defaultCollapseIcon={<ArrowDropDown />} defaultExpandIcon={<ArrowRight />}>
        <FPTreeItemList tree={tree} />
      </TreeView>
    </>
  );
};

export default FPTreeView;
