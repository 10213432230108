// @flow
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { EntityType } from 'UI/constants/entityTypes';
import { UIStatus } from 'UI/constants/status';
import { preloadFromBackend, prepareFormToSubmit } from 'UI/utils/forms';

import { VERIFY_URGENCY_FIELDS } from './VerifyUrgencyDialog.constants';
import {
  getActivityLogTypes,
  getCandidateUrgencyVerification,
  getJobOrderUrgencyVerification,
  saveCandidateUrgencyVerification,
  saveJobOrderUrgencyVerification
} from './VerifyUrgencyDialog.services';

type VerifyUrgencyHookParams = {
  entityType: EntityType,
  entityId: string
};

type UrgencyVerificationForm = {
  isVerified: boolean,
  actionsTaken: Array<number>,
  moreDetails: string
};

const URGENCY_DEFAULT_VALUES = {
  [VERIFY_URGENCY_FIELDS.IsVerified.key]: false,
  [VERIFY_URGENCY_FIELDS.ActionsTaken.key]: [],
  [VERIFY_URGENCY_FIELDS.MoreDetails.key]: ''
};

const WRITE_SERVICES = {
  [EntityType.Candidate]: saveCandidateUrgencyVerification,
  [EntityType.Joborder]: saveJobOrderUrgencyVerification
};

const READ_SERVICES = {
  [EntityType.Candidate]: getCandidateUrgencyVerification,
  [EntityType.Joborder]: getJobOrderUrgencyVerification
};

const useVerifyUrgency = ({ entityType, entityId, onSaveSucceeded }: VerifyUrgencyHookParams) => {
  const [actionsTakenOptions, setActionsTakenOptions] = useState(false);
  const [verification, setVerification] = useState(null);
  const [uiState, setUiState] = useState(UIStatus.Default);

  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: URGENCY_DEFAULT_VALUES
  });

  const { reset } = form;

  useEffect(() => {
    const fetchActionsTakenOptions = async () => {
      const options = await getActivityLogTypes();
      setActionsTakenOptions(options);
    };

    fetchActionsTakenOptions();

    return () => {
      setActionsTakenOptions([]);
    };
  }, []);

  useEffect(() => {
    const fetchVerification = async () => {
      const service = READ_SERVICES[entityType];

      setUiState(UIStatus.Loading);
      const result = await service(entityId);
      setUiState(UIStatus.Default);

      if (result) {
        const dataToPreload = preloadFromBackend(result, VERIFY_URGENCY_FIELDS);
        reset(dataToPreload);
        setVerification(result);
      }
      setUiState(UIStatus.Success);
    };

    fetchVerification();
  }, [entityId, entityType, reset]);

  const handleSave = async (data: UrgencyVerificationForm) => {
    if (!WRITE_SERVICES[entityType]) {
      throw new Error(`Entity type ${entityType} is not supported`);
    }

    const transformedData = prepareFormToSubmit(data, VERIFY_URGENCY_FIELDS);

    setUiState(UIStatus.Saving);
    const response = await WRITE_SERVICES[entityType](entityId, transformedData);
    setUiState(UIStatus.Default);

    response.alert && dispatch(showAlert(response.alert));
    response.success && onSaveSucceeded(response);
  };

  return {
    actionsTakenOptions,
    form,
    uiState,
    verification,
    handleSave
  };
};

export default useVerifyUrgency;
