// @flow
import { BulkMenuItems } from 'UI/constants/defaults';

const intialState = {
  app: {
    ui: {
      alerts: [],
      showVersionBar: false,
      isSidebarOpen: true,
      isTableExpanded: false
    },
    domain: null
  },
  notification: {
    ui: {
      isLoading: true,
      hasError: false,
      hasMore: false
    },
    domain: {
      params: {},
      notifications: [],
      totalUnread: 0,
      totalFilterUnread: 0,
      totalFilterRead: 0,
      filterTotal: 0,
      activeVisibility: null,
      activeType: null
    }
  },
  map: {
    ui: {
      activeTab: 0,
      isSideMenuOpen: true,
      isLoading: false,
      hasLoaded: false,
      selectedRecruiter: null,
      selectedEntity: null,
      lastError: null
    },
    domain: {
      filters: {},
      recruiters: [],
      markers: [],
      markersCount: 0
    }
  },
  dashboard: {
    ui: {
      isSideMenuOpen: false,
      isLoading: false,
      hasLoaded: false
    },
    domain: {
      filters: {},
      recruiters: [],
      markers: []
    }
  },
  dashboardPhone: {
    ui: {
      isSideMenuOpen: false,
      isLoading: false,
      hasLoaded: false
    },
    domain: {
      filters: {},
      recruiters: [],
      markers: []
    }
  },
  bulkEmail: {
    ui: {
      selectedMenuItem: BulkMenuItems.Sent,
      attachments: {
        uploading: false
      },
      preview: {
        loading: false
      },
      templates: {
        open: false
      },
      status: {
        completed: false,
        loading: false,
        loadLast: false,
        open: false,
        reloadEmailList: false,
        sending: false,
        bulkRestriction: {
          rule: null,
          reason: ''
        }
      },
      stats: {
        open: false
      }
    },
    domain: {
      template: {
        id: null,
        name: '',
        emailExcludedAttachments: [],
        parentFolder: null
      },
      emailBody: {
        id: null,
        subject: '',
        text: '',
        html: ''
      },
      attachments: [],
      sent: {
        preview: {
          id: null,
          to: '',
          subject: '',
          html: '',
          text: '',
          sent_date: null,
          attachments: [],
          config: {
            bulkTypeId: null,
            searchProjectId: null,
            template: '',
            marketing: {
              candidates: []
            },
            recruiting: {
              jobOrders: []
            }
          }
        }
      },
      bulkEmailInPreview: {
        edit: false,
        data: {}
      }
    }
  },
  optIn: {
    ui: {
      isLoading: true,
      hasError: false
    },
    domain: {
      params: {},
      optins: [],
      total: 0
    }
  },
  jobOrder: {
    referenceRelease: null
  },
  ringCentral: {
    trackedContacts: []
  }
};

export default intialState;
