// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextBox from 'UI/components/atoms/TextBox';
import NumberedForm from 'UI/components/molecules/NumberedForm';

import { useStyles } from '../styles';

export type PlacementCandidateSectionProps = {
  candidate: any,
  copyPaste: boolean
};

const PlacementCandidateSection = (props: PlacementCandidateSectionProps) => {
  const classes = useStyles();
  const { candidate, copyPaste } = props;

  return (
    <NumberedForm.Item title="Candidate Details" showTitleGutter>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextBox
            name="candidate"
            label="Candidate"
            value={candidate?.personalInformation?.full_name || ''}
            className={classes.input}
            outPutValue
            enableCopyButton={copyPaste}
          />
        </Grid>
        <Grid item xs={6}>
          <TextBox
            name="source"
            label="Source"
            value={candidate?.sourceType?.title}
            className={classes.input}
            outPutValue
            enableCopyButton={copyPaste}
          />
        </Grid>
      </Grid>
    </NumberedForm.Item>
  );
};

PlacementCandidateSection.defaultProps = {
  candidate: null
};

export default PlacementCandidateSection;
