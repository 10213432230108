import React from 'react';
import {
  OVERALL_PERFORMANCE_COLUMNS,
  OVERALL_PERFORMANCE_ORDER_BY_OPTIONS,
  SimplifiedTable
} from 'features/command-center/components/shared';
import { TABLES_KEYS } from 'features/command-center/constants';
import { SECONDARY_API_CLIENT } from 'services/API';

import { generateFinalEndpoint } from './HealthDistributionDrawerContent.utils';
import { formatResponse } from './utils';

export const HealthDistributionDrawerContent = ({ endpoint = '' }) => {
  const finalEndpoint = generateFinalEndpoint(endpoint);

  return (
    <SimplifiedTable
      apiInstance={SECONDARY_API_CLIENT}
      columns={OVERALL_PERFORMANCE_COLUMNS}
      endpoint={finalEndpoint}
      formatResponse={formatResponse}
      orderByOptions={OVERALL_PERFORMANCE_ORDER_BY_OPTIONS}
      pageKey={TABLES_KEYS.healthDistributionStats}
      shouldUseSavedPagination={false}
      showWrapper={false}
    />
  );
};
