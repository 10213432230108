import * as React from 'react';

const SvgChatBubbles = props => {
  const { size = 24, fill } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill={fill} height={size} width={size} {...props}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M9.455 0A9.455 9.455 0 000 9.455a9.343 9.343 0 001.58 5.227L.316 18.063c-.14.373.18.757.573.686l4.428-.796c.44.214.894.393 1.359.537a9.89 9.89 0 01-.247-2.204c0-5.444 4.413-9.857 9.857-9.857.76 0 1.501.086 2.213.249A9.451 9.451 0 009.455 0zM21.74 10.83a7.715 7.715 0 00-8.807-1.494 7.714 7.714 0 106.728 13.883l3.45.621a.514.514 0 00.572-.686l-.972-2.603A7.623 7.623 0 0024 16.286a7.714 7.714 0 00-2.26-5.455z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgChatBubbles;
