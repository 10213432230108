// @flow
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import strings from 'strings';
import type { BulkEmailContentBodyPropTypes } from 'types/app';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { BulkMenuItems } from 'UI/constants/defaults';
import { SvgNoBulkDrafts, SvgNoBulkTemplates, SvgNoScheduledBulks, SvgNoSentBulks } from 'UI/res';

import { useStyles } from './styles';

const EmptyImages = {
  [BulkMenuItems.Sent]: SvgNoSentBulks,
  [BulkMenuItems.Drafts]: SvgNoBulkDrafts,
  [BulkMenuItems.Schedule]: SvgNoScheduledBulks,
  [BulkMenuItems.Template]: SvgNoBulkTemplates
};

const { emptyStates: emptyStatesStrings } = strings.bulkEmails;

const LoadingContent = ({ children, isLoading, copies }) =>
  isLoading ? (
    <CircularProgress size={34} />
  ) : (
    <EmptyPlaceholder {...copies} customEmptyState={children} />
  );

const BulkEmailContentBody = ({
  isListWithContent = false,
  isPreviewActive = false,
  isResponsive = false,
  ListComponent,
  loadingList = false,
  loadingPreview = false,
  PreviewComponent,
  selectedMenuItem
}: BulkEmailContentBodyPropTypes) => {
  const { 400: grey400 } = useTheme().palette.grey;
  const classes = useStyles();
  const emptyStateSize = isResponsive ? 200 : 400;

  const EmptyImageToLoad = EmptyImages[selectedMenuItem] || null;
  const isPreviewLoaded = isPreviewActive && !loadingPreview;

  return (
    <Grid container className={classes.mainContainer}>
      <Grid data-testid="bulk-list-section" item className={classes.listContainer}>
        {isListWithContent ? (
          ListComponent
        ) : (
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            className={clsx(classes.emptyStateBox, !loadingList && classes.emptyStateRoot)}
          >
            <LoadingContent
              isLoading={loadingList}
              copies={emptyStatesStrings[selectedMenuItem]?.list}
            >
              <div className={classes.emptyImage}>
                <EmptyImageToLoad fill={grey400} size={200} />
              </div>
            </LoadingContent>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        item
        data-testid="bulk-preview-section"
        alignItems={isPreviewLoaded ? 'flex-start' : 'center'}
        justify="center"
        className={classes.previewContainer}
      >
        {isPreviewLoaded ? (
          PreviewComponent
        ) : (
          <LoadingContent
            isLoading={loadingPreview}
            copies={emptyStatesStrings[selectedMenuItem]?.preview}
          >
            <EmptyImageToLoad fill={grey400} size={emptyStateSize} />
          </LoadingContent>
        )}
      </Grid>
    </Grid>
  );
};

export default BulkEmailContentBody;
