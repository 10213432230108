import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { SimplifiedTable } from 'features/command-center/components/shared';
import { TABLES_KEYS } from 'features/command-center/constants';
import { SECONDARY_API_CLIENT } from 'services/API';
import SendoutManager from 'UI/components/organisms/sendouts';
import { RowActions } from 'UI/constants/defaults';
import { EntityType } from 'UI/constants/entityTypes';

import {
  ORDER_BY_OPTIONS,
  SENDOUTS_COLUMNS,
  SENDOVERS_COLUMNS,
  VIRTUAL_PROPS
} from './SendoutsDrawerContent.constants';

export const SendoutsDrawerContent = ({ endpoint = '', entity }) => {
  const [selectedSendoutId, setSelectedSendoutId] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const openDetailsDrawer = id => setSelectedSendoutId(id);
  const finalColumns = entity === EntityType.Sendout ? SENDOUTS_COLUMNS : SENDOVERS_COLUMNS;
  const pageKey =
    entity === EntityType.Sendout ? TABLES_KEYS.drawerSendouts : TABLES_KEYS.drawerSendovers;

  const closeDetailsDrawer = () => {
    setSelectedSendoutId(null);
    setShouldRefresh(true);
  };
  const handleDrawerActionSubmit = data => {
    if (data) {
      setShouldRefresh(false);

      closeDetailsDrawer();
    }
  };

  return (
    <>
      <SimplifiedTable
        apiInstance={SECONDARY_API_CLIENT}
        columns={finalColumns}
        endpoint={endpoint}
        pageKey={pageKey}
        orderByOptions={ORDER_BY_OPTIONS}
        rowActions={[
          {
            name: RowActions.View,
            customAction: 'rowClickBuilder',
            onClick: openDetailsDrawer
          }
        ]}
        shouldRefresh={shouldRefresh}
        shouldUseSavedPagination={false}
        showWrapper={false}
        virtualProps={VIRTUAL_PROPS}
      />
      {!!selectedSendoutId && (
        <Drawer open={!!selectedSendoutId} onClose={closeDetailsDrawer}>
          <div role="presentation">
            <SendoutManager
              onEdit={handleDrawerActionSubmit}
              onDelete={handleDrawerActionSubmit}
              onClose={closeDetailsDrawer}
              id={selectedSendoutId}
            />
          </div>
        </Drawer>
      )}
    </>
  );
};
