// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Text from 'UI/components/atoms/Text';

import { useStyles } from './styles';

type DialogBodyDetailsType = {
  title: string,
  detail: string,
  visible?: boolean
};

type DialogDetailsBodyTypes = {
  details: Array<DialogBodyDetailsType>
};

const DialogDetailsBody = ({ details }: DialogDetailsBodyTypes) => {
  const classes = useStyles();
  const showRow = visible => typeof visible === 'undefined' || visible;

  return (
    <Grid container className={classes.root}>
      {details.map(
        ({ title, detail, visible }) =>
          showRow(visible) && (
            <Grid key={title} container item alignItems="flex-start" wrap="nowrap">
              <Text text={title} className={classes.title} />
              <Text text={detail} fontSize={16} />
            </Grid>
          )
      )}
    </Grid>
  );
};

export default DialogDetailsBody;
