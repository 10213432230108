import { THEME } from 'GlobalStyles';
import { EntityType } from 'UI/constants/entityTypes';

export const METRICS = {
  [EntityType.Candidate]: 'total,alpha,poejo,cantHelp',
  [EntityType.Company]: 'total,signed,client',
  [EntityType.Contact]: 'total,hiringAuthorityProspect,candidateProspect,undefinedProspect',
  [EntityType.HiringAuthority]: 'total,signed,client,notSigned',
  [EntityType.Joborder]: 'total,poejo,searchAssignment,cantHelp'
};

export const GROUP_BY = {
  [EntityType.Candidate]: 'day',
  [EntityType.Company]: 'day',
  [EntityType.Contact]: 'day',
  [EntityType.HiringAuthority]: 'day_created_at',
  [EntityType.Joborder]: 'day'
};

export const ROUTE_PARAM = {
  [EntityType.Candidate]: 'candidates',
  [EntityType.Company]: 'companies',
  [EntityType.Contact]: 'names',
  [EntityType.HiringAuthority]: 'hiring-authorities',
  [EntityType.Joborder]: 'job-orders'
};

export const CHART_DATA_CONFIG_BY_ENTITY = {
  [EntityType.Candidate]: {
    headers: ['Day', 'Total Candidates', 'Alphas', 'POEJOs', "Can't Help"],
    dateLabel: 'Day',
    categories: ['Total Candidates', 'Alpha', 'POEJO', "Can't Help"],
    dataMappings: {
      'Total Candidates': 'total',
      Alpha: 'alpha',
      POEJO: 'poejo',
      "Can't Help": 'cantHelp'
    }
  },
  [EntityType.Company]: {
    headers: ['Day', 'Total Companies', 'Signed', 'Clients'],
    dateLabel: 'Day',
    categories: ['Total Companies', 'Signed', 'Client'],
    dataMappings: {
      'Total Companies': 'total',
      Signed: 'signed',
      POEJO: 'client'
    }
  },
  [EntityType.Contact]: {
    headers: [
      'Day',
      'Total Prospects',
      'Hiring Authority Prospects',
      'Candidate Prospects',
      'Undefined Prospects'
    ],
    dateLabel: 'Day',
    categories: [
      'Total Prospects',
      'Hiring Authority Prospects',
      'Candidate Prospects',
      'Undefined Prospects'
    ],
    dataMappings: {
      'Total Prospects': 'total',
      'Hiring Authority Prospects': 'hiringAuthorityProspect',
      'Candidate Prospects': 'candidateProspect',
      'Undefined Prospects': 'undefinedProspect'
    }
  },
  [EntityType.HiringAuthority]: {
    headers: [
      'Day',
      'Total Hiring Authorities',
      'Hiring A. from Not Signed',
      'Hiring A. from Signed',
      'Hiring A. from Client'
    ],
    dateLabel: 'Day',
    categories: [
      'Total Hiring Authorities',
      'Hiring A. from Not Signed',
      'Hiring A. from Signed',
      'Hiring A. from Client'
    ],
    dataMappings: {
      'Total Hiring Authorities': 'total',
      'Hiring A. from Not Signed': 'notSigned',
      'Hiring A. from Signed': 'signed',
      'Hiring A. from Client': 'client'
    }
  },
  [EntityType.Joborder]: {
    headers: ['Day', 'Total Job Orders', 'Search Assignments', 'POEJOs', "Can't Help"],
    dateLabel: 'Day',
    categories: ['Total Job Orders', 'Search Assignment', 'POEJO', "Can't Help"],
    dataMappings: {
      'Total Job Orders': 'total',
      'Search Assignment': 'searchAssignment',
      POEJO: 'poejo',
      "Can't Help": 'cantHelp'
    }
  }
};

const FONT = THEME.typography.fontFamily;

export const PIL_GROWTH_CHART_DEFAULT_OPTIONS = {
  chartArea: { width: '80%' },
  hAxis: {
    title: '',
    titleTextStyle: { fontFamily: FONT, fontStyle: 'normal !important' }
  },
  vAxis: {
    format: '#',
    viewWindow: { min: 0 },
    minValue: 0,
    baseline: 0,
    ticks: [0, 5, 10, 15, 20, 25]
  },
  fontName: FONT,
  animation: {
    startup: true,
    easing: 'inAndOut',
    duration: 1500
  },

  tooltip: { isHtml: true },
  legend: { position: 'top' },
  colors: [
    THEME.palette.common.black,
    THEME.palette.primary.main,
    THEME.palette.success.main,
    THEME.palette.warning.main,
    THEME.palette.error.main
  ],
  pointSize: 5
};
