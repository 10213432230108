import strings from 'strings';
import { formatRatio } from 'UI/utils';

import { calculatePercentage } from '../activity.utils';

export const formatResponse = data => {
  if (!data || !data?.inMailsGoalsReaching) return [];

  const { count, goal, recruitersOnGoal, headcount } = data.inMailsGoalsReaching;

  const percentage = calculatePercentage({ total: recruitersOnGoal, goal: headcount });

  return [
    {
      title: strings.commandCenter.activity.inMailsGauge.chart.goal,
      value: formatRatio(count ?? 0, goal ?? 0, 'scalar', {
        notation: 'standard'
      }),
      percentage,
      goal,
      valuePosition: 'top'
    },
    {
      title: strings.commandCenter.activity.inMailsGauge.chart.members,
      value: formatRatio(recruitersOnGoal ?? 0, headcount),
      valuePosition: 'top'
    }
  ];
};
