import { Endpoints } from 'UI/constants/endpoints';

export const NODE_NAMES = {
  Industry: 'industry',
  Specialty: 'specialty',
  Subspecialty: 'subspecialty'
};

export const NODE_CONFIGURATION = {
  [NODE_NAMES.Industry]: {
    Key: NODE_NAMES.Industry,
    ChildrenEndpoint: `${Endpoints.Specialties}?industryId=:id`,
    ChildrenKey: NODE_NAMES.Specialty
  },
  [NODE_NAMES.Specialty]: {
    Key: NODE_NAMES.Specialty,
    ChildrenEndpoint: Endpoints.Subspecialties,
    ChildrenKey: NODE_NAMES.Subspecialty
  },
  [NODE_NAMES.Subspecialty]: {
    Key: NODE_NAMES.Subspecialty
  }
};
