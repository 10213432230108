// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { showFile } from 'actions/app';
import { extractFilenameFromUrl } from 'UI/utils';

import { useStyles } from './InterviewStatus.styles';

interface InterviewStatusProps {
  status: string;
  fileUrl: string;
}

export const InterviewStatus = ({ status, fileUrl }: InterviewStatusProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleOnClick = () => {
    const fileName = extractFilenameFromUrl(fileUrl);
    dispatch(
      showFile({
        url: fileUrl,
        explicitFileName: fileName,
        useProxy: true
      })
    );
  };

  return (
    <div className={classes.container}>
      <span>{status}</span>
      {status === 'Done' && fileUrl && (
        <IconButton onClick={handleOnClick} aria-label="View Attachment" size="small">
          <AttachFileIcon className={classes.icon} />
        </IconButton>
      )}
    </div>
  );
};
