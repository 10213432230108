// @flow
import React from 'react';
import { useHistory } from 'react-router';
import { useGlobalStyles } from 'GlobalStyles';
import { EntityRoutes } from 'routes/constants';
import { CandidateIncludes } from 'services/includes';
import FPToggleButton from 'UI/components/molecules/FPToggleButton';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';

type ToggleProfileQuickViewProps = {
  isQuickViewMode: boolean,
  onQuickViewOptionClick: (string, string) => Promise<void>
};

type ToggleProfileModeButtonProps = {
  mode: 'candidate' | 'hiringAuthority',
  results: Object,
  size: 'small' | 'medium' | 'large',
  toggleProfileQuickViewProps?: ToggleProfileQuickViewProps
};

const ToggleProfileModeButton = ({
  mode,
  results,
  size,
  toggleProfileQuickViewProps: { isQuickViewMode, onQuickViewOptionClick }
}: ToggleProfileModeButtonProps) => {
  const history = useHistory();
  const globalClasses = useGlobalStyles();

  const profileId = results?.id;

  const isCandidateMode = mode === EntityType.Candidate;

  const relations = {
    candidate: results?.relation?.candidate_id,
    hiringAuthority: results?.relation?.hiring_authority_id
  };

  const profileModes = {
    candidate: {
      ButtonMode: EntityType.HiringAuthority,
      ProfileRoute: EntityRoutes.HiringAuthorityProfile,
      ProfileValues: isCandidateMode ? profileId : relations.candidate,
      endpoint: Endpoints.HiringAuthorities,
      includes: ['optOutStatus']
    },
    hiringAuthority: {
      ButtonMode: EntityType.Candidate,
      ProfileRoute: EntityRoutes.CandidateProfile,
      ProfileValues: !isCandidateMode ? profileId : relations.hiringAuthority,
      endpoint: Endpoints.Candidates,
      includes: CandidateIncludes
    }
  };

  const toggleOptionsProfile = [
    { title: 'Candidate', value: profileModes.candidate.ProfileValues, disabled: isCandidateMode },
    {
      title: 'Hiring Authority',
      value: profileModes.hiringAuthority.ProfileValues,
      disabled: !isCandidateMode
    }
  ];

  const relationId = isCandidateMode ? relations.hiringAuthority : relations.candidate;

  const handleToggleProfile = value =>
    history.push(profileModes[mode].ProfileRoute.replace(':id', value));

  const { ButtonMode } = profileModes?.[mode];

  const handleToggleQuickView = () =>
    onQuickViewOptionClick && onQuickViewOptionClick(relationId, ButtonMode);

  const onOptionClick = isQuickViewMode ? handleToggleQuickView : handleToggleProfile;

  return relationId ? (
    <FPToggleButton
      className={globalClasses.toggleButtonLayoutV2}
      onChange={onOptionClick}
      options={toggleOptionsProfile}
      size={size}
    />
  ) : null;
};

ToggleProfileModeButton.defaultProps = {
  mode: 'candidate',
  size: 'medium',
  toggleProfileQuickViewProps: {
    isQuickViewMode: false,
    onQuickViewOptionClick: () => {}
  }
};

export default ToggleProfileModeButton;
