import { makeStyles } from '@material-ui/core';
import { THEME } from 'GlobalStyles';
import { colors } from 'UI/res';
import { flexAlignCenterFlexEnd, whiteSpace } from 'UI/utils/styles';

export const styles = {
  root: {
    height: '100%'
  },
  actionButtonList: {
    ...flexAlignCenterFlexEnd,
    height: 100,
    padding: THEME.spacing(0, 5),
    backgroundColor: colors.sideBar
  },
  minActionButton: {
    width: 110,
    height: 28,
    padding: 0,
    fontSize: 14
  },
  autocompleteFilters: {
    width: '220px'
  },
  title: {
    ...whiteSpace,
    width: 250,
    display: 'block'
  }
};

export const useDialogBodyStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 8),
    '& > *': {
      margin: theme.spacing(1, 0),
      alignItems: 'baseline'
    },
    '& > * > :first-child': {
      width: '30%'
    }
  },
  warning: {
    marginTop: theme.spacing(3),
    '& :first-child': {
      width: 'auto',
      marginRight: theme.spacing(1)
    }
  }
}));
