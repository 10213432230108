import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'UI/res/colors';

const styles = {
  title: {
    fontSize: 18,
    fontWeight: '500 !important'
  }
};

export const useStyles = makeStyles(theme => ({
  list: {
    paddingLeft: 'inherit'
  },
  item: {
    '&::marker': styles.title,
    margin: theme.spacing(2, 0),
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: colors.success
    }
  },
  itemTitle: {
    ...styles.title,
    flex: 1,
    '&.MuiTypography-gutterBottom': {
      marginBottom: theme.spacing(1.5)
    }
  }
}));
