// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import { useSearchProject } from 'hooks/searchProject';
import queryString from 'query-string';
import { filtersToParams } from 'selectors/app';
import Text from 'UI/components/atoms/Text';
import LazyRenderWrapper from 'UI/components/molecules/LazyRenderWrapper';
import MapInventoryCard from 'UI/components/molecules/MapInventoryItem/MapInventoryItem';
/** Material Assets and Components */
/** Atoms, Components and Styles */
import SideFiltersLayoutConnected from 'UI/components/templates/SideFiltersLayout';
import { entityTypes } from 'UI/constants/entityTypes';
import { SearchResultsNotFound } from 'UI/res';
import { numberFormatter } from 'UI/utils';

import { FiltersGroups } from './columns';
import { useStyles } from './styles';

const defaultFilters = { entityType: entityTypes[0] };

type EntityResultsListProps = {
  results: Array<Object>,
  entity: string
};

const EntityResultsList = ({ results, entity }: EntityResultsListProps) => {
  const classes = useStyles();

  return (
    <List className={classes.UserContainer}>
      {results.map(item => {
        const specialtyText = `${item.industry}:  ${item.specialty}  ${
          item.subspecialty ? '·' : ''
        }  ${item.subspecialty ? `${item.subspecialty}` : ''}`;
        const entityProps = {
          candidate: {
            title: item.full_name,
            subtitle: item.title,
            description: specialtyText,
            type: item.type
          },
          joborder: {
            title: item.title,
            subtitle: item.company_name,
            description: specialtyText,
            type: item.type
          },
          company: {
            title: item.name,
            subtitle: specialtyText,
            description: item.recruiter,
            type: item.type
          }
        };

        return (
          <LazyRenderWrapper key={item.id}>
            <MapInventoryCard entityProps={entityProps[entity]} entity={entity} info={item} />
          </LazyRenderWrapper>
        );
      })}
    </List>
  );
};

const MapInventoryTab = () => {
  const classes = useStyles();
  const { ui, domain } = useSelector(({ map }) => map);
  const { hasLoaded } = ui;
  const { filters, markers: results, markersCount } = domain;

  const entity = filters?.entityType?.value.id;
  const formattedCount = numberFormatter(markersCount);
  const filtersObj = filtersToParams(filters);

  const filteredItems = {
    type: 'exclude',
    data: [],
    count: markersCount
  };
  const paramsStringified = queryString.stringify(filtersObj, { arrayFormat: 'comma' });
  const queryParams = {
    params: paramsStringified,
    type: 'map'
  };

  const { SearchProjectAction, SearchProjectForms } = useSearchProject({
    filteredItems,
    queryParams
  });

  const itemsCount = results.length || 0;
  const hasResults = itemsCount > 0;

  const renderMarkersCount = () => {
    const markersCountText =
      markersCount > itemsCount
        ? `Here's an overall sample of <strong>${formattedCount}</strong> results. Move through the map to get more specific results.`
        : `Showing <strong>${formattedCount}</strong> results.`;

    return <Text text={markersCountText} variant="body2" />;
  };

  return (
    <SideFiltersLayoutConnected
      enableCollapsibleForm
      section="inventory"
      groups={FiltersGroups}
      defaultFilters={defaultFilters}
      resultsFound={hasResults}
      showGoToTop={itemsCount > 10}
      emptyContent={
        hasLoaded &&
        !itemsCount && (
          <Box p={3} textAlign="center">
            <Box fontWeight="fontWeightBold">
              We couldn&apos;t find what you&apos;re looking for!
            </Box>
            <Box mb={3}>Maybe try changing your search filters</Box>
            <SearchResultsNotFound width={150} height={185} />
          </Box>
        )
      }
    >
      <SearchProjectForms />
      {hasResults && (
        <>
          {itemsCount > 0 && (
            <>
              <div className={classes.spActionWrapper}>
                <SearchProjectAction tooltipText="Add results to Search Project" />
              </div>
              {renderMarkersCount()}
            </>
          )}
          <EntityResultsList results={results} entity={entity} />
        </>
      )}
    </SideFiltersLayoutConnected>
  );
};

export default MapInventoryTab;
