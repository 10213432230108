// @flow
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import strings from 'strings';
import ColorIndicator from 'UI/components/atoms/ColorIndicator';
import { DateFormats, MarkerCompanyClass, MarkerTypeClass } from 'UI/constants/defaults';
import { EntityType } from 'UI/constants/entityTypes';
import { nestTernary } from 'UI/utils';

import InfoLabel from '../InfoLabel';

import { useStyles } from './styles';

type EntityProps = {
  description: string,
  title: string,
  subtitle: string,
  type: string
};

type LastActivity = {
  title: string,
  date: string,
  isLoading: boolean
};

type InventoryInfoProps = {
  markerType: number,
  entity: string,
  entityProps: EntityProps,
  lastActivity?: LastActivity
};

const InventoryInfo = ({ markerType, entity, entityProps, lastActivity }: InventoryInfoProps) => {
  const classes = useStyles();
  const { description, title, subtitle, type } = entityProps;

  const isCompany = entity === EntityType.Company;

  const mapMarkerClass = !isCompany ? MarkerTypeClass[markerType] : MarkerCompanyClass[markerType];

  const renderStatus = (
    <>
      <ColorIndicator customClass={`map-marker-sm ${mapMarkerClass}`} />
      <span className={classes.itemType}>{type}</span>
    </>
  );

  const substitleLabel = (
    <>
      {isCompany && description && strings.map.inventory.popover.recruiterLabel}
      {!isCompany && strings.map.inventory.popover.industrySpecialityLabel}
    </>
  );

  const descriptionText = <span className={classes.itemDescription}>{description}</span>;

  const lastActivityInfo = lastActivity?.isLoading ? (
    <CircularProgress size={18} />
  ) : (
    nestTernary(
      !!lastActivity?.title,
      <span className={classes.itemDescription}>
        {lastActivity?.title} -{' '}
        {lastActivity?.date ? moment(lastActivity?.date).format(DateFormats.SimpleDate) : ''}
      </span>,
      <span className={classes.itemDescription}>No recently</span>
    )
  );

  const textProps = { className: classes.overlineLabel };

  return (
    <ListItemText
      primary={title}
      primaryTypographyProps={{ className: classes.itemTitle }}
      className={classes.itemContent}
      secondary={
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <span className={classes.itemSubtitle}>{subtitle}</span>
          </Grid>

          <Grid item xs={12}>
            <InfoLabel title={substitleLabel} description={descriptionText} textProps={textProps} />
          </Grid>
          <Grid item xs={12}>
            <InfoLabel title="Status" description={renderStatus} textProps={textProps} />
          </Grid>
          {lastActivity && (
            <Grid item xs={12}>
              <InfoLabel
                title="Last activity"
                description={lastActivityInfo}
                textProps={textProps}
              />
            </Grid>
          )}
        </Grid>
      }
    />
  );
};

InventoryInfo.defaultProps = {
  lastActivity: null
};

export default InventoryInfo;
