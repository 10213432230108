// @flow
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useContactConversion } from 'hooks/useContactConversion';
import { EntityRoutes } from 'routes/constants';
import { When } from 'UI/components/atoms/When';
import ProfileUiStateManager from 'UI/components/templates/ProfileUiStateManager';
import { ContactEntity } from 'UI/constants/entityTypes';

import ContactProfileContent from './ContactProfileContent';
import { useStyles } from './styles';

type FPContactProfileProps = {
  enableUrlNavigation: boolean,
  id: number,
  layoutClassName: Object,
  match: any
};

export const determineRouteForConvertedContact = conversionStatus => {
  const convertedProfileRoute = conversionStatus.candidate
    ? EntityRoutes.CandidateProfile.replace(':id', conversionStatus.candidate.id)
    : EntityRoutes.HiringAuthorityProfile.replace(':id', conversionStatus.hiringAuthority.id);
  return convertedProfileRoute;
};

export const redirectToConvertedEntity = (conversionStatus, history) => {
  const targetRoute = determineRouteForConvertedContact(conversionStatus);
  history.replace(targetRoute);
};

const FPContactProfile = ({
  enableUrlNavigation,
  id,
  layoutClassName,
  match
}: FPContactProfileProps) => {
  const nameId = id ?? match?.params?.id;
  const history = useHistory();
  const classes = useStyles();

  const {
    isLoading,
    shouldRenderProfileContent,
    entityConversionState,
    enableConvertedContacts,
    isErrorResponse,
    status
  } = useContactConversion({
    nameId
  });

  useEffect(() => {
    if (entityConversionState?.results && entityConversionState?.results.converted) {
      redirectToConvertedEntity(entityConversionState.results, history);
    }
  }, [entityConversionState, history]);

  return (
    <>
      <When condition={shouldRenderProfileContent}>
        <ContactProfileContent
          id={nameId}
          enableUrlNavigation={enableUrlNavigation}
          layoutClassName={layoutClassName}
        />
      </When>
      <When condition={isLoading && enableConvertedContacts}>
        <div className={classes.loadingConverted}>
          <CircularProgress />
        </div>
      </When>
      <When condition={isErrorResponse}>
        <ProfileUiStateManager
          entity={ContactEntity}
          entityRoute={EntityRoutes.Contacts}
          responseStatusCode={entityConversionState.responseStatusCode}
          status={status}
          profileStatusMode={{ loading: null }}
        />
      </When>
    </>
  );
};

export default FPContactProfile;
