// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { resetFilters, saveFilters, toggleMenu } from 'actions/dashboardPhone';
import { useActivityUpdater } from 'hooks/activityUpdater';
import moment from 'moment';
import queryString from 'query-string';
import { filtersToParams } from 'selectors/app';
import API from 'services/API';
import strings from 'strings';
import type { DashboardPeriod, Filters } from 'types/app';
import { FPCard as Card, FPCardContent as CardContent } from 'UI/components/atoms/FPCard';
import LineChart from 'UI/components/molecules/Dashboard/LineChart';
import SummaryTable from 'UI/components/molecules/Dashboard/SummaryTable';
import SummaryTotals from 'UI/components/molecules/Dashboard/SummaryTotals';
import DailyStatsFooter from 'UI/components/molecules/home/DailyStats/DailyStatsFooter';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import TabsView from 'UI/components/templates/TabsView';
import { backNavigateListConfig } from 'UI/constants/config';
import {
  DateFormats,
  PageTitles,
  RingCentralLogTypes,
  WebSocketNamespaces
} from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { NoCallActivityIcon, NoSMSActivityIcon } from 'UI/res';
import { createCustomStaticRanges, getRangePeriod } from 'UI/utils';

import { useStyles } from '../styles';

const FiltersGroups = [{ id: 'team', name: 'Team', filters: ['coach', 'recruiter'] }];
const {
  title,
  sections: {
    noPhoneActivity: {
      noNewCalls: noNewCallsLabel,
      noNewTexts: noNewTextsLabel,
      title: noPhoneActivityTitle
    },
    totalCalls,
    callTrends,
    calls,
    totalTexts,
    textTrends,
    texts
  }
} = strings.phoneDashboard;

type DashboardPhoneProps = {
  filters: Filters,
  isSideMenuOpen: boolean,
  onFiltersReset: () => any,
  onFiltersChange: filters => any,
  onMenuToggle: () => any
};

const determineScope = (filters: Filters) => {
  const options = {
    option1: 'gpac All',
    option2: 'All Coaches'
  };

  if (!filters.coach && !filters.recruiter && !filters.regional) return options;
  if (filters.recruiter && filters.recruiter?.value) {
    const recruiterScope = filters.recruiter.value.full_name;
    return {
      option1: recruiterScope,
      option2: recruiterScope
    };
  }
  if (filters.coach?.value) {
    const coachScope = `Team ${filters.coach.value.full_name}`;
    return {
      option1: coachScope,
      option2: coachScope
    };
  }

  if (filters.regional?.value) {
    const regionScope = `Region ${filters.regional.value.full_name}`;
    return {
      option1: regionScope,
      option2: regionScope
    };
  }
  return {};
};

const TABS = {
  calls: 0,
  texts: 1
};

const DashboardPhone = ({
  filters,
  isSideMenuOpen,
  onFiltersReset,
  onFiltersChange,
  onMenuToggle
}: DashboardPhoneProps) => {
  const classes = useStyles();

  const defaultPeriod = getRangePeriod([1, 'week']);
  const [selectedPeriod, setSelectedPeriod] = useState<DashboardPeriod>(defaultPeriod);
  const [selectedTab, setSelectedTab] = useState(0);
  const [callLastUpdate, setCallLastUpdate] = useState();
  const [messageLastUpdate, setMessageLastUpdate] = useState();

  useEffect(() => {
    document.title = PageTitles.DashboardPhone;
    getLastUpdates();
  }, []);

  const handlePeriodChange = (period: DashboardPeriod) => setSelectedPeriod(period);
  const handleTabChange = (event, newValue = 0) => setSelectedTab(newValue);

  const filtersParams = filtersToParams(filters);
  const queryParams = queryString.stringify({
    startDate: moment(selectedPeriod.startDate).format(DateFormats.QueryShortFormat),
    endDate: moment(selectedPeriod.endDate).format(DateFormats.QueryShortFormat),
    ...filtersParams
  });

  const scope = determineScope(filters);

  const getLastUpdates = async () => {
    const { data: lastUpdates } = await API.get(`${Endpoints.Phone}/logs-last-update`);
    const getDate = logType =>
      lastUpdates.find(update => update.rcLogTypeId === logType?.id)?.dateLastUpdate;

    setCallLastUpdate(getDate(RingCentralLogTypes.Call));
    setMessageLastUpdate(getDate(RingCentralLogTypes.Text));
  };

  const handleMessageSocket = useCallback(async message => {
    if (!message) return;
    getLastUpdates();
  }, []);

  useActivityUpdater(WebSocketNamespaces.DashboardPhoneLoading, handleMessageSocket);

  const noPhoneActivityTabsProp = [
    {
      label: noNewCallsLabel,
      icon: <NoCallActivityIcon />,
      view: (
        <SummaryTable
          url={`${Endpoints.Phone}/calls-inventory-list?${queryParams}&withoutItems=true`}
          downloadFileName={`No-Inventory-${scope.option2}`}
          section="dashboardPhone"
          lastUpdate={callLastUpdate}
        />
      )
    },
    {
      label: noNewTextsLabel,
      icon: <NoSMSActivityIcon />,
      view: (
        <SummaryTable
          url={`${Endpoints.Phone}/sms-inventory-list?${queryParams}&withoutItems=true`}
          downloadFileName={`No-Activity-${scope.option2}`}
          section="dashboardPhone"
          lastUpdate={messageLastUpdate}
        />
      )
    }
  ];

  return !callLastUpdate && !messageLastUpdate ? (
    <ContentPageLayout>
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    </ContentPageLayout>
  ) : (
    <FiltersLayout
      dateRangerPickerProps={{
        staticRanges: createCustomStaticRanges(additionalPeriodRanges)
      }}
      defaultRange={selectedPeriod}
      disableTimeRange
      filters={filters}
      groups={FiltersGroups}
      isSideMenuOpen={isSideMenuOpen}
      onFiltersChange={onFiltersChange}
      onFiltersReset={onFiltersReset}
      onMenuToggle={onMenuToggle}
      onPeriodChange={handlePeriodChange}
      section="dashboardPhone"
      title={title}
      titleLabelProps={backNavigateListConfig}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent
              headerProps={{
                title: totalCalls.title,
                subheader: scope.option1,
                className: classes.headerAction
              }}
              footerProps={{
                CustomFooter: <DailyStatsFooter callLastUpdate={callLastUpdate} type="dashboard" />
              }}
            >
              <SummaryTotals
                url={`${Endpoints.Phone}/total-calls-inventory?${queryParams}`}
                lastUpdate={callLastUpdate}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent
              headerProps={{
                title: callTrends.title,
                subheader: scope.option1,
                className: classes.headerAction
              }}
              footerProps={{
                CustomFooter: <DailyStatsFooter callLastUpdate={callLastUpdate} type="dashboard" />
              }}
            >
              <LineChart
                url={`${Endpoints.Phone}/calls-activity-in-time?${queryParams}`}
                columns={[]}
                lastUpdate={callLastUpdate}
              />
            </CardContent>
          </Card>
        </Grid>

        {!filters.recruiter && (
          <Grid item xs={12}>
            <Card>
              <CardContent
                variant="tight"
                headerProps={{
                  title: calls.title,
                  subheader: scope.option2,
                  className: classes.headerAction
                }}
                footerProps={{
                  CustomFooter: <DailyStatsFooter callLastUpdate={callLastUpdate} type="table" />
                }}
              >
                <SummaryTable
                  url={`${Endpoints.Phone}/calls-inventory-list?${queryParams}`}
                  downloadFileName={`Inventory-CallsRC-${scope.option2}`}
                  section="dashboardPhone"
                  lastUpdate={callLastUpdate}
                />
              </CardContent>
            </Card>
          </Grid>
        )}

        {filters.recruiter && (
          <Grid item xs={12}>
            <Card>
              <CardContent
                headerProps={{
                  title: calls.title,
                  subheader: scope.option2,
                  className: classes.headerAction
                }}
                footerProps={{
                  CustomFooter: (
                    <DailyStatsFooter callLastUpdate={callLastUpdate} type="dashboard" />
                  )
                }}
              >
                <SummaryTotals
                  url={`${Endpoints.Phone}/calls-inventory-list-by-recruiter?${queryParams}`}
                  lastUpdate={callLastUpdate}
                />
              </CardContent>
            </Card>
          </Grid>
        )}

        <Grid item xs={12}>
          <Card>
            <CardContent
              headerProps={{
                title: totalTexts.title,
                subheader: scope.option1,
                className: classes.headerAction
              }}
              footerProps={{
                CustomFooter: (
                  <DailyStatsFooter textLastUpdate={messageLastUpdate} type="dashboard" />
                )
              }}
            >
              <SummaryTotals
                url={`${Endpoints.Phone}/total-sms-inventory?${queryParams}`}
                lastUpdate={messageLastUpdate}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent
              headerProps={{
                title: textTrends.title,
                subheader: scope.option1,
                className: classes.headerAction
              }}
              footerProps={{
                CustomFooter: (
                  <DailyStatsFooter textLastUpdate={messageLastUpdate} type="dashboard" />
                )
              }}
            >
              <LineChart
                url={`${Endpoints.Phone}/sms-activity-in-time?${queryParams}`}
                columns={[]}
                lastUpdate={messageLastUpdate}
              />
            </CardContent>
          </Card>
        </Grid>

        {!filters.recruiter && (
          <>
            <Grid item xs={12}>
              <Card>
                <CardContent
                  variant="tight"
                  headerProps={{
                    title: texts.title,
                    subheader: scope.option2,
                    className: classes.headerAction
                  }}
                  footerProps={{
                    CustomFooter: (
                      <DailyStatsFooter textLastUpdate={messageLastUpdate} type="table" />
                    )
                  }}
                >
                  <SummaryTable
                    url={`${Endpoints.Phone}/sms-inventory-list?${queryParams}`}
                    downloadFileName={`Inventory-SMSRC-${scope.option2}`}
                    section="dashboardPhone"
                    lastUpdate={messageLastUpdate}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent
                  variant="tight"
                  headerProps={{
                    title: noPhoneActivityTitle,
                    subheader: scope.option2,
                    className: classes.headerAction
                  }}
                  footerProps={{
                    CustomFooter: (
                      <DailyStatsFooter
                        callLastUpdate={selectedTab === TABS.calls && callLastUpdate}
                        textLastUpdate={selectedTab === TABS.texts && messageLastUpdate}
                        type="table"
                      />
                    )
                  }}
                >
                  <TabsView
                    selectedTab={selectedTab}
                    onChangeTabIndex={handleTabChange}
                    tabs={noPhoneActivityTabsProp}
                    panelHeight="unset"
                  />
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </FiltersLayout>
  );
};

const mapStateToProps = ({ dashboardPhone }) => {
  return {
    filters: dashboardPhone.domain.filters,
    isSideMenuOpen: dashboardPhone.ui.isSideMenuOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFiltersReset: () => dispatch(resetFilters()),
    onFiltersChange: filters => dispatch(saveFilters(filters)),
    onMenuToggle: () => dispatch(toggleMenu())
  };
};

const DashboardPhoneConnected = connect(mapStateToProps, mapDispatchToProps)(DashboardPhone);

export default DashboardPhoneConnected;
