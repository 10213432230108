// @flow
import { useEffect } from 'react';

type UseCandidateScrollToSection = {
  success: boolean,
  loading: boolean
};

export const useCandidateScrollToSection = ({ success, loading }: UseCandidateScrollToSection) => {
  useEffect(() => {
    if (!window.location.hash) return;
    if (!success && !loading) return;

    const element = document.getElementById(window.location.hash.slice(1));

    if (!element) return;
    element.scrollIntoView({ behavior: 'smooth' });
  }, [success, loading]);
};
