// @flow
import React, { useEffect, useState } from 'react';
import { FormContext, useForm, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { showAlert } from 'actions/app';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import strings from 'strings';
import FPLink from 'UI/components/atoms/FPLink';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { Endpoints } from 'UI/constants/endpoints';
import { getErrorMessage } from 'UI/utils';
import { OptionRenderers, Selectors } from 'UI/utils/renderers';

import { useStyles } from './styles';

const EMPLOYEE_CONFIG = {
  types: {
    contact: 'Contact',
    candidate: 'Candidate'
  },
  routes: {
    contact: EntityRoutes.ContactProfile,
    candidate: EntityRoutes.CandidateProfile
  }
};

type EntitySelectFormProps = {
  profile: Object,
  selectedItem: Object,
  onSelectItem: () => void
};

const EntitySelectForm = ({ profile, selectedItem, onSelectItem }: EntitySelectFormProps) => {
  const classes = useStyles();
  const isName = selectedItem?.is_name;
  const { register, errors, setValue } = useFormContext();

  useEffect(() => {
    register({ name: 'employee_id', required: true });
  }, [register]);

  const employeeInventoryType = isName
    ? EMPLOYEE_CONFIG.types.contact
    : EMPLOYEE_CONFIG.types.candidate;
  const employeeRoute = isName ? EMPLOYEE_CONFIG.routes.contact : EMPLOYEE_CONFIG.routes.candidate;

  const handleComboChange = (name?: string, value: Object) => {
    onSelectItem(value);
    setValue(name, value, true);
  };

  return (
    <div>
      <AutocompleteSelect
        name="employee_id"
        selectedValue={selectedItem}
        error={!!errors.employee_id}
        errorText={errors.employee_id && errors.employee_id.message}
        typeahead
        typeaheadParams={{
          company_id: profile?.id
        }}
        placeholder={strings.inventoryProfiles.sections.tabs.employees.search}
        url={`${Endpoints.Companies}/${Endpoints.PossibleEmployees}`}
        noOptionsText={strings.inventoryProfiles.sections.tabs.employees.failedSearch}
        onSelect={handleComboChange}
        getOptionLabel={option => option?.full_name ?? ''}
        getOptionSelected={Selectors.byId}
        renderOption={OptionRenderers.default}
      />
      {selectedItem?.id && (
        <>
          <div className={classes.employeesInfoRoute}>
            <Typography variant="overline">{selectedItem.full_name}</Typography>
            <Typography variant="body1" color="textSecondary">
              {`${selectedItem?.email ? ` / ${selectedItem.email}` : null}`}
            </Typography>
          </div>
          <FPLink
            linkProps={{
              type: 'local',
              target: '_blank',
              url: employeeRoute.replace(':id', selectedItem?.id)
            }}
          >
            {`View ${employeeInventoryType}`}
          </FPLink>
        </>
      )}
    </div>
  );
};

type EmployeesManagerDrawerProps = {
  onEmployeeLinked: () => void,
  onToggleDrawerOpen: () => void,
  profile: Object
};

const EmployeesManagerDrawer = ({
  onEmployeeLinked,
  onToggleDrawerOpen,
  profile
}: EmployeesManagerDrawerProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedItem, onSelectItem] = useState(undefined);
  const [uiState, setUiState] = useState({
    isName: false,
    isSaving: false,
    isSuccess: false,
    isFormDisabled: false,
    isReadOnly: false
  });

  const form = useForm();
  const { handleSubmit } = form;

  const handleEmployeeAddition = async () => {
    await onEmployeeLinked();
    onToggleDrawerOpen();
  };

  const handleLinkEmployee = async formData => {
    const { employee_id } = formData;

    try {
      setUiState(prevState => ({ ...prevState, isSaving: true }));

      const endpointKey = employee_id?.is_name ? Endpoints.Names : Endpoints.Candidates;
      const response = await API.post(`${endpointKey}/${employee_id?.id}/employer-company`, {
        company_id: profile?.id
      });

      setUiState(prevState => ({
        ...prevState,
        isSaving: false
      }));

      if (response.data && response.status === 201) {
        dispatch(
          showAlert({
            severity: 'success',
            title: 'Awesome',
            body: `Employee matched successfully`
          })
        );

        handleEmployeeAddition();
      }
    } catch (err) {
      dispatch(
        showAlert({
          severity: 'error',
          title: '',
          body: getErrorMessage(err)
        })
      );
      setUiState(prevState => ({
        ...prevState,
        isSaving: false,
        isSuccess: false
      }));
    }
  };

  return (
    <DrawerContentLayout
      drawerProps={{ open: true, onClose: onToggleDrawerOpen }}
      onClose={onToggleDrawerOpen}
      onSubmit={handleSubmit(handleLinkEmployee)}
      primaryProps={{ disabled: !selectedItem || uiState.isSaving }}
      title="Add Employee"
      uiState={uiState}
    >
      <div className={classes.containerForm}>
        <FormContext {...form}>
          <EntitySelectForm
            profile={profile}
            selectedItem={selectedItem}
            onSelectItem={onSelectItem}
          />
        </FormContext>
      </div>
    </DrawerContentLayout>
  );
};

export default EmployeesManagerDrawer;
