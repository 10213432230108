import { EntityRoutes } from 'routes/constants';

const sharedOptions = {
  display: true,
  filter: true,
  sort: true
};

export const ColumnsDefinitions = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'name',
    label: 'Search Project Name',
    options: {
      ...sharedOptions,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.SearchProjectPreview,
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'total_candidates',
    label: 'Candidates',
    options: {
      ...sharedOptions,
      sort: false
    }
  },
  {
    name: 'total_hiring_authorities',
    label: 'Hiring Authorities',
    options: {
      ...sharedOptions,
      sort: false
    }
  },
  {
    name: 'total_undefined_names',
    label: 'Names',
    options: {
      ...sharedOptions,
      sort: false
    }
  },
  {
    name: 'total_companies',
    label: 'Companies',
    options: {
      ...sharedOptions,
      sort: false
    }
  },
  {
    name: 'is_private',
    label: 'Visibility',
    options: {
      ...sharedOptions,
      renderer: 'visibility'
    }
  },
  {
    name: 'last_bulk_send_date',
    label: 'Last Bulk on',
    options: {
      ...sharedOptions,
      renderer: 'date'
    }
  },
  {
    name: 'last_bulk_recruiter',
    label: 'Last Bulk by',
    options: {
      ...sharedOptions,
      sort: false
    }
  },
  {
    name: 'updated_at',
    label: 'Last Modification',
    options: {
      ...sharedOptions,
      renderer: 'date'
    }
  },
  {
    name: 'created_at',
    label: 'Added Date',
    options: {
      ...sharedOptions,
      renderer: 'date'
    }
  },
  {
    name: 'created_by_full_name',
    label: 'Added by',
    options: {
      ...sharedOptions
    }
  }
];

export const ArchiveSearchProjectColumnsDefinitions = [
  {
    name: 'archived_at',
    label: 'Archived Date',
    options: {
      ...sharedOptions,
      renderer: 'date'
    }
  },
  {
    name: 'daysForDeletion',
    label: 'Days Before Deletion',
    options: {
      display: true,
      filter: false,
      sort: false
    }
  }
];

export const FiltersGroups = [
  { id: 'general', name: 'Search Projects', filters: ['addedByIds', 'isPrivate'] }
];

export const VirtualProps = [
  {
    propName: 'name',
    attributes: ['id', 'name']
  }
];
