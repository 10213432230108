import { useState } from 'react';

export const useSearchContacts = () => {
  const [contact, setContact] = useState(null);

  const handleSelectValue = async value => {
    if (!value) {
      setContact(null);
      return;
    }

    setContact({
      ...value,
      fullName: value.full_name,
      phone: value.phone,
      companyAndRole: `${value.industry} / ${value.role}`,
      email: value.email
    });
  };

  return {
    contact,
    handleSelectValue
  };
};
