// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import strings from 'strings';
import FPAvatar from 'UI/components/atoms/FPAvatar';
import { StatsCard } from 'UI/components/molecules/cardContents/StatsCard';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { currencyFormatter, getFeatureFlags } from 'UI/utils';

import { useStyles } from './styles';

const STAT_TYPES = {
  financial: 'financial',
  recruiter: 'recruiter',
  regional: 'regional',
  team: 'team'
};

const COMMON_CARD_ITEMS = ['SENDOUTS', 'SENDOVERS', 'SENT_AGREEMENTS', 'BULKS', 'NEW_ITEMS'];

const TEAM_CARD_ITEMS = [
  'SENDOUTS',
  'SENT_AGREEMENTS',
  'PLACEMENTS',
  'CALLS',
  'NEW_CANDIDATES',
  'NEW_JOB_ORDERS'
];

const FINANCIAL_CARD_ITEMS = ['DRAW_BALANCE', 'PLACEMENTS_YTD', 'FALL_OFFS_YTD'];

const RECRUITER_CARD_ITEMS = [...COMMON_CARD_ITEMS, 'CALLS', 'TEXTS'];

const REGIONAL_CARD_ITEMS = [...COMMON_CARD_ITEMS, 'CALLS'];

const CARDS_BY_TYPE = {
  financial: FINANCIAL_CARD_ITEMS,
  recruiter: RECRUITER_CARD_ITEMS,
  regional: REGIONAL_CARD_ITEMS,
  team: TEAM_CARD_ITEMS
};

type DailyStatsProps = {
  phonePerformancePercentage: Object,
  totalAgreements: Object,
  totalBulks: Object,
  totalCalls: Object,
  totalDrawBalance: number,
  totalFallOffs: number,
  totalNewCandidates: number,
  totalNewJorOrders: number,
  totalPlacements: number,
  totalSendouts: Object,
  totalSendovers: Object,
  totalTeamInfo: Object,
  totalTexts: number,
  type: 'team' | 'recruiter' | 'regional' | 'financial'
};

const DailyStats = ({
  phonePerformancePercentage,
  totalAgreements,
  totalBulks,
  totalCalls,
  totalDrawBalance,
  totalFallOffs,
  totalNewCandidates,
  totalNewJorOrders,
  totalPlacements,
  totalSendouts,
  totalSendovers,
  totalTeamInfo,
  totalTexts,
  type
}: DailyStatsProps) => {
  const isTypeRegional = type === STAT_TYPES.regional;
  const shouldShowTeamStats = type === STAT_TYPES.team || isTypeRegional;
  const classes = useStyles({ shouldShowTeamStats, totalCards: CARDS_BY_TYPE[type].length });

  const shouldEnableDashboardMetrics = getFeatureFlags().includes(
    FeatureFlags.DashboardSendoutMetrics
  );

  const displayMetrics = shouldEnableDashboardMetrics
    ? {
        regional: `${totalSendouts.goal} Weekly Goal, ${totalSendouts.adjusted} Adjusted`,
        general: `, ${totalSendouts.goal} Goal, ${totalSendouts.adjusted} Adjusted`
      }
    : {
        regional: '',
        general: ''
      };

  const cardItems = [
    {
      key: 'SENDOUTS',
      title: strings.home.sections.dailyStats.sendouts,
      subtitle: isTypeRegional
        ? displayMetrics.regional
        : `${totalSendouts.weekToDate} Sent This Week ${displayMetrics.general}`,
      value: totalSendouts.total
    },
    {
      key: 'SENDOVERS',
      title: strings.home.sections.dailyStats.sendovers,
      subtitle: isTypeRegional
        ? `${totalSendovers.converted} Converted`
        : `${totalSendovers.weekToDate} Sent This Week`,
      value: totalSendovers.total
    },
    {
      key: 'SENT_AGREEMENTS',
      title: strings.home.sections.dailyStats.sentAgreements,
      subtitle: isTypeRegional
        ? `${totalAgreements.signed} Signed`
        : `${totalAgreements.weekToDate} Sent This Week, ${totalAgreements.signed} Signed Today`,
      value: totalAgreements.total
    },
    {
      key: 'BULKS',
      title: strings.home.sections.dailyStats.bulks,
      subtitle: !isTypeRegional && `${totalBulks.total_reached} Reached`,
      value: totalBulks.total
    },
    {
      key: 'PLACEMENTS',
      title: strings.home.sections.dailyStats.placements,
      value: totalPlacements
    },
    {
      key: 'CALLS',
      title: strings.home.sections.dailyStats.calls,
      subtitle: `${totalCalls.totalTime} Minutes`,
      value: totalCalls.total,
      customClass: phonePerformancePercentage?.performance
    },
    {
      key: 'TEXTS',
      title: strings.home.sections.dailyStats.texts,
      value: totalTexts
    },
    {
      key: 'NEW_CANDIDATES',
      title: strings.home.sections.dailyStats.newCandidates,
      value: totalNewCandidates
    },
    {
      key: 'NEW_JOB_ORDERS',
      title: strings.home.sections.dailyStats.newJobOrders,
      value: totalNewJorOrders
    },
    {
      key: 'NEW_ITEMS',
      title: strings.home.sections.dailyStats.newItems,
      subtitle: `${totalNewCandidates} Candidate(s), ${totalNewJorOrders} Job Order(s)`,
      value: totalNewCandidates + totalNewJorOrders
    },
    {
      key: 'DRAW_BALANCE',
      title: strings.home.sections.dailyStats.drawBalance,
      subtitle: strings.home.sections.dailyStats.drawBalancePeriod,
      value: currencyFormatter(totalDrawBalance, 2)
    },
    {
      key: 'PLACEMENTS_YTD',
      title: strings.home.sections.dailyStats.placementsYTD,
      value: totalPlacements
    },
    {
      key: 'FALL_OFFS_YTD',
      title: strings.home.sections.dailyStats.fallOffsYTD,
      value: totalFallOffs
    }
  ];
  const cardsToShow = CARDS_BY_TYPE[type];
  const finalCardItems = cardItems.filter(cardItem => cardsToShow.includes(cardItem.key));

  return (
    <Grid container className={classes.container}>
      <Grid
        container
        item
        sm={shouldShowTeamStats ? 10 : 12}
        xs={shouldShowTeamStats ? 9 : 12}
        className={classes.cardContainer}
      >
        {finalCardItems.map(cardItem => (
          <div
            key={cardItem.key}
            className={clsx(
              classes.cardContent,
              cardItem.customClass && classes[cardItem.customClass]
            )}
          >
            <StatsCard {...cardItem} hasCustomClass={!!cardItem.customClass} />
          </div>
        ))}
      </Grid>

      {shouldShowTeamStats && (
        <Grid item container sm={2} xs={3} direction="column" className={classes.teamInfoContainer}>
          {!isTypeRegional && (
            <Grid item container>
              <Grid item sm={10} xs={12} className={classes.labelNewMembersContainer}>
                <Typography className={classes.labelNewMembers}>
                  {strings.home.sections.dailyStats.newMembers}
                </Typography>
              </Grid>
              <Grid item sm={2} xs={12} className={classes.totalNewMembersContainer}>
                <FPAvatar mode="user" classes={{ root: classes.totalNewMembersAvatar }}>
                  <Typography className={classes.totalNewMembers}>
                    {totalTeamInfo.newMembers}
                  </Typography>
                </FPAvatar>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Typography className={classes.totalCurrentMembers}>{totalTeamInfo.total}</Typography>
            <Typography className={classes.labelCurrentMembers}>
              {strings.home.sections.dailyStats.currentTeamMembers}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

DailyStats.defaultProps = {
  totalAgreements: { total: 0, signed: 0 },
  totalBulks: { total: 0, total_reached: 0 },
  totalCalls: { total: 0, totalTime: 0 },
  totalNewCandidates: 0,
  totalNewJorOrders: 0,
  totalPlacements: 0,
  totalSendouts: { total: 0, weekToDate: 0, goal: 0, adjusted: 0 },
  totalSendovers: { total: 0, weekToDate: 0 },
  totalTeamInfo: { newMembers: 0, total: 0 },
  totalTexts: 0,
  totalDrawBalance: 0,
  totalFallOffs: 0,
  type: STAT_TYPES.team
};

export default DailyStats;
