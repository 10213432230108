import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'UI/res';
import { flexAlignCenterFlexStart } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  UserContainer: {
    overflowY: 'auto',
    padding: '0 2px',
    height: 'max-content',
    position: 'relative'
  },
  goToTopButton: {
    width: '100%'
  },
  buttonContainer: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: colors.white,
    width: '100%',
    height: 50
  },
  filtersContainer: {
    padding: '8px 24px'
  },
  mapContainer: {
    cursor: 'pointer',
    margin: '20px auto'
  },
  mapContent: {
    ...flexAlignCenterFlexStart
  },
  textArea: {
    marginLeft: theme.spacing(2)
  },
  cardNameText: {
    color: colors.grey,
    fontSize: 18
  },
  cardIndustryText: {
    color: colors.grey,
    fontSize: 14
  },
  noResultsContainer: {
    textAlign: 'center',
    padding: '24px'
  },
  noResultsBoldTxt: {
    fontWeight: '700 !important'
  },
  noResultsSubtitleTxt: {
    marginBottom: '24px'
  }
}));
