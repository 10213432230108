// @flow
import React from 'react';

import ContactsList from './Content';

const Contacts = props => {
  return <ContactsList {...props} />;
};

export default Contacts;
