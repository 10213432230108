// @flow

/** Custom hook to handle the different status when uploading files. Useful for chips and list uploader */

import { useEffect, useState } from 'react';
import { RequestStatus } from 'UI/constants/status';

const useUploadStatus = (initialState: any, loading: boolean, error: boolean) => {
  const [status, setStatus] = useState(RequestStatus.Finished);
  useEffect(() => {
    if (loading) {
      setStatus(RequestStatus.Loading);
    } else if (status !== RequestStatus.Finished) {
      setStatus(error ? RequestStatus.Error : RequestStatus.Success);
    }
  }, [status, loading, error]);

  useEffect(() => {
    if (status === RequestStatus.Success) {
      const timer = setTimeout(() => {
        setStatus(RequestStatus.Finished);
      }, 1500);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [status]);

  return status;
};

export default useUploadStatus;
