import { EntityRoutes } from 'routes/constants';

export const COMPANIES_COLUMNS = [
  {
    name: 'activityLogTypeTitle',
    label: 'Type',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'createdAt',
    label: 'Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'companyName',
    label: 'Company Reached',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'companyTypeTitle',
    label: 'Company Type',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'industryTitle',
    label: 'Industry',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'specialtyTitle',
    label: 'Specialty',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'subspecialtyTitle',
    label: 'Subspecialty',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'countrySlug',
    label: 'Country',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'stateTitle',
    label: 'State',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'cityTitle',
    label: 'City',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'companyNumberOfContacts',
    label: 'Number of Contacts',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'activityOutcome',
    label: 'Outcome',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'activityOutcomeComments',
    label: 'Comments',
    options: {
      sort: true,
      display: true
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'createdAt',
  direction: 'desc'
};
