// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { showAlert } from 'actions/app';
import type { FileExplorerSection } from 'types/app';
import FileItem from 'UI/components/molecules/FileItem';
import FileUploader from 'UI/components/molecules/FileUploader';
import FPModal from 'UI/components/templates/FPModal';
import { FileStatus } from 'UI/constants/status';
import { trimFileNameWithExtension } from 'UI/utils';

import { useStyles } from './styles';

type FileExplorerProps = {
  /** Title to show in modal */
  title: string,
  /** File Entity type for new uploaded files */
  defaultEntityType: string,
  /** File'sections to show inside the explorer */
  multiple?: boolean,
  /** File'sections to show inside the explorer */
  sections: FileExplorerSection[],
  /** Callback invoked when a file is selected or upladed */
  onSelect: (file: any) => void,
  /** Callback invoked when the explorer is closed */
  onClose: () => void
};

const FileExplorer = (props: FileExplorerProps) => {
  const {
    category,
    defaultEntityType,
    endpoint,
    multiple,
    onClose,
    onSelect,
    sections,
    selectedFiles,
    shouldAppendCategory,
    title
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSelect = (item: any) => {
    onSelect && onSelect([item]);
    onClose();
  };

  const handleAttachmentsSucceeded = files => {
    const fileNameField = 'file_name';
    const filesArray = Array.from(files);
    const duplicateFile = filesArray.find(file =>
      selectedFiles.map(eachFile => eachFile[fileNameField]).includes(file.file_name)
    );
    if (duplicateFile) {
      dispatch(
        showAlert({
          severity: 'warning',
          title: 'Attachments',
          body: `You've already selected ${trimFileNameWithExtension(duplicateFile.file_name)}`
        })
      );
      return;
    }

    const newFiles = files.map(file => ({
      ...file,
      type: FileStatus.New,
      entity: defaultEntityType
    }));
    onSelect && onSelect(newFiles);
    onClose();
  };

  const doesFileExist = fileName =>
    selectedFiles?.some(attachment => attachment.file_name === fileName);

  return (
    <FPModal
      open
      title={title}
      onClose={onClose}
      hasFooterActions
      contentVariant="tight"
      footerClassName={classes.footerContainer}
      footer={
        <>
          <p>Haven&apos;t upload the file yet?</p>
          <FileUploader
            category={category}
            endpoint={endpoint}
            fileNameField="file_name"
            files={[]}
            maxNumberOfFiles={10}
            mode="button"
            multiple={multiple}
            onAttachmentsChanged={handleAttachmentsSucceeded}
            selectedFiles={selectedFiles}
            shouldAppendCategory={shouldAppendCategory}
            text={multiple ? 'Upload files' : 'Upload file'}
            waitForAllToUpload={multiple}
          />
        </>
      }
    >
      <List>
        {sections.map(
          section =>
            section.files?.length > 0 && (
              <React.Fragment key={section.id}>
                <ListSubheader key={section.id} className={classes.subheader}>
                  {section.title}
                </ListSubheader>
                {section.files.map(file => (
                  <FileItem
                    key={file.id}
                    file={{ ...file, entity: section.id, type: FileStatus.Existing }}
                    fileName={file.file_name}
                    loading={false}
                    showDate
                    showTextButtons
                    viewOnClick={false}
                    onFileSelect={handleSelect}
                    dense
                    readOnly={doesFileExist(file.file_name)}
                  />
                ))}
              </React.Fragment>
            )
        )}
      </List>
    </FPModal>
  );
};

FileExplorer.defaultProps = {
  title: 'File Explorer',
  multiple: false
};

export default FileExplorer;
