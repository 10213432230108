import { makeStyles } from '@material-ui/core/styles';
import { THEME as theme } from 'GlobalStyles';

export const styles = {
  root: {
    margin: theme.spacing(1, 0)
  },
  radio: {
    padding: theme.spacing(1)
  },
  label: {},
  controlLabel: {
    marginBottom: 0
  },
  helperLabel: {
    marginTop: 0
  },
  spacing: {
    marginRight: '1ch'
  }
};

export const useStyles = makeStyles({
  adviceText: {
    alignSelf: 'center',
    fontStyle: 'italic',
    fontSize: 14
  }
});
