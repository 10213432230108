// @flow
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import HelloSign from 'hellosign-embedded';
import queryString from 'query-string';
import strings from 'strings';
import type { OperationResult } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';
import { getErrorMessage } from 'UI/utils';

import API from './API';

const BASE_ALERT = {
  isNotification: false,
  isOpen: false,
  onClick: undefined,
  onDismissClick: undefined
};

const AlertTitle = strings.feeAgreements.singular;

const sendReminder = async (feeId: number): Promise<OperationResult> => {
  const result = await sendRequest(
    feeId,
    `${Endpoints.FeeAgreement}/${Endpoints.FeeAgreementSendReminder.replace(':id', feeId)}`,
    'post',
    null,
    strings.feeAgreements.messages.reminderOk
  );
  return result;
};

const validateByCoach = async (feeId: number): Promise<OperationResult> => {
  const result = await sendRequest(
    feeId,
    `${Endpoints.FeeAgreement}/${Endpoints.FeeAgreementValidationByCoach.replace(':id', feeId)}`,
    'post',
    null,
    strings.feeAgreements.messages.approvedOk
  );

  return result;
};

const createManagedServiceAgreement = async (
  companyId: number,
  data: any
): Promise<OperationResult> => {
  const result = await sendRequest(
    companyId,
    `${Endpoints.Companies}/${companyId}/${Endpoints.FeeAgreement}`,
    'post',
    data,
    strings.feeAgreements.messages.createdOk
  );

  return result;
};

const createUnmanagedServiceAgreement = async (
  companyId: number,
  data: any
): Promise<OperationResult> => {
  const result = await sendRequest(
    companyId,
    `${Endpoints.Companies}/${companyId}/${Endpoints.FeeAgreementUnmanaged}`,
    'post',
    data,
    strings.feeAgreements.messages.createdOk
  );

  return result;
};

const validateByOps = async (feeId: number): Promise<OperationResult> => {
  const result = await sendRequest(
    feeId,
    `${Endpoints.FeeAgreement}/${Endpoints.FeeAgreementValidationByOperations.replace(
      ':id',
      feeId
    )}`,
    'post',
    null,
    strings.feeAgreements.messages.approvedOk
  );

  return result;
};

const createPreview = async (feeId: number, data: any): Promise<OperationResult> => {
  const result = await sendRequest(
    feeId,
    `${Endpoints.FeeAgreement}/${Endpoints.FeeAgreementCreateSignatureRequestPreview.replace(
      ':id',
      feeId
    )}`,
    'post',
    data,
    null
  );

  return result;
};

const sendRevalidationToCoach = async (feeId: number, data: any): Promise<OperationResult> => {
  const result = await sendRequest(
    feeId,
    `${Endpoints.FeeAgreement}/${Endpoints.FeeAgreementRevalidationToCoach.replace(':id', feeId)}`,
    'put',
    data,
    strings.feeAgreements.messages.submittedOk
  );

  return result;
};

const sendRevalidationToOps = async (feeId: number, data: any): Promise<OperationResult> => {
  const result = await sendRequest(
    feeId,
    `${Endpoints.FeeAgreement}/${Endpoints.FeeAgreementRevalidationToOperations.replace(
      ':id',
      feeId
    )}`,
    'put',
    data,
    strings.feeAgreements.messages.submittedOk
  );

  return result;
};

const declineByCoach = async (feeId: number, data: any): Promise<OperationResult> => {
  const result = await sendRequest(
    feeId,
    `${Endpoints.FeeAgreement}/${Endpoints.FeeAgreementDeclinationByCoach.replace(':id', feeId)}`,
    'post',
    data,
    strings.feeAgreements.messages.declinedOk
  );

  return result;
};

const declineByOps = async (feeId: number, data: any): Promise<OperationResult> => {
  const result = await sendRequest(
    feeId,
    `${Endpoints.FeeAgreement}/${Endpoints.FeeAgreementDeclinationByOperations.replace(
      ':id',
      feeId
    )}`,
    'post',
    data,
    strings.feeAgreements.messages.declinedOk
  );

  return result;
};

const validateByOpsForUnmanaged = async (feeId: number, data: any): Promise<OperationResult> => {
  const result = await sendRequest(
    feeId,
    `${Endpoints.FeeAgreement}/${Endpoints.FeeAgreementUnmanagedValidationByOperations.replace(
      ':id',
      feeId
    )}`,
    'post',
    data,
    strings.feeAgreements.messages.savedOk
  );

  return result;
};

const voidServiceAgreement = async (feeId: number): Promise<OperationResult> => {
  const result = await sendRequest(
    feeId,
    `${Endpoints.FeeAgreement}/${feeId}/${Endpoints.FeeAgreementVoidContract}`,
    'put',
    null,
    strings.feeAgreements.messages.voidedOk
  );

  return result;
};

const cancelServiceAgreementRequest = async (feeId: number): Promise<OperationResult> => {
  const result = await sendRequest(
    feeId,
    `${Endpoints.FeeAgreement}/${feeId}/${Endpoints.FeeAgreementCancelValidationRequest}`,
    'put',
    null,
    strings.feeAgreements.messages.canceledOk
  );

  return result;
};

const updateSignersEmail = async (feeId: number, data: any): Promise<OperationResult> => {
  const result = await sendRequest(
    feeId,
    `${Endpoints.FeeAgreement}/${Endpoints.FeeAgreementUpdateEmails.replace(':id', feeId)}`,
    'put',
    data,
    strings.feeAgreements.messages.emailsOk
  );

  return result;
};

const switchSigningProvider = async (feeId: number): Promise<OperationResult> => {
  const result = await sendRequest(
    feeId,
    `${Endpoints.FeeAgreement}/${feeId}/${Endpoints.FeeAgreementSwitchProvider}`,
    'put',
    null,
    strings.feeAgreements.messages.swithServiceOk
  );

  return result;
};

const reactivateServiceAgreement = async (feeId: number): Promise<OperationResult> => {
  const result = await sendRequest(
    feeId,
    `${Endpoints.FeeAgreement}/${feeId}/${Endpoints.FeeAgreementRestoreExpired}`,
    'put',
    null,
    strings.feeAgreements.messages.reactivatedOk
  );

  return result;
};

export const sendRequest = async (
  feeId: number,
  url: string,
  method: string,
  data: any = null,
  successMessage: string
): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const response = await API({ method, url, data });
    if (response?.status === 200 || response?.status === 201) {
      result.success = true;
      result.data = response.data;
      result.alert = {
        severity: 'success',
        body: successMessage,
        key: feeId,
        ...BASE_ALERT
      };
    }
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: AlertTitle,
      body: getErrorMessage(error),
      autoHideDuration: 5000,
      key: feeId,
      ...BASE_ALERT
    };
  }
  return result;
};

const getAgreementsByCompanyId = async (companyId: number): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const queryParams = queryString.stringify({
      companyId
    });

    const url = `${Endpoints.FeeAgreementsByCompany}`;
    const response = await API.get(`${url}?${queryParams}`);
    if (response?.status === 200) {
      result.success = true;
      result.data = response.data;
    }
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: AlertTitle,
      body: getErrorMessage(error),
      autoHideDuration: 5000,
      key: companyId,
      ...BASE_ALERT
    };
  }
  return result;
};

const getFeeAgreementById = async (id: number): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const url = `${Endpoints.FeeAgreement}/${id}`;
    const response = await API.get(url);
    if (response?.status === 200) {
      result.success = true;
      result.data = response.data;
    }
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: AlertTitle,
      body: getErrorMessage(error),
      autoHideDuration: 5000,
      key: id,
      ...BASE_ALERT
    };
  }
  return result;
};

const useFetchGuaranteeOptions = (shouldFetch = true) => {
  const [options, setOptions] = useState({});

  useEffect(() => {
    shouldFetch &&
      (async () => {
        const response = await API.get(Endpoints.GuaranteeDaysGrouped);
        response.status === 200 && setOptions(response.data);
      })();
  }, [shouldFetch]);

  return options;
};

const useFetchTemplates = (shouldFetch = true) => {
  const [templates, setTemplates] = useState(null);

  useEffect(() => {
    shouldFetch &&
      (async () => {
        const response = await API.get(
          `${Endpoints.FeeAgreement}/${Endpoints.FeeAgreementTemplates}`
        );
        response.status === 200 && setTemplates(response.data);
      })();
  }, [shouldFetch]);

  return templates;
};

const useHelloSign = ({ onSend, onTemplateCreated, onTemplateCanceled }) => {
  const dispatch = useDispatch();

  const helloSignConfig = {
    clientId: window.GPAC_ENV?.HELLO_SIGN_CLIENT_ID || process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
    mode: window.GPAC_ENV?.HELLO_SIGN_MODE || process.env.REACT_APP_HELLO_SIGN_MODE
  };
  const isHelloSignInProduction = helloSignConfig.mode === 'prod';
  const helloSignOpenConfig = {
    allowCancel: true,
    testMode: !isHelloSignInProduction,
    debug: !isHelloSignInProduction,
    skipDomainVerification: !isHelloSignInProduction
  };

  const helloSignClient = new HelloSign({
    clientId: helloSignConfig.clientId
  });

  helloSignClient.on('send', () => {
    dispatch(
      showAlert({
        severity: 'success',
        title: AlertTitle,
        body: strings.feeAgreements.messages.sentOk,
        autoHideDuration: 8000
      })
    );
    onSend && onSend();
  });

  helloSignClient.on('cancel', () => {
    dispatch(
      showAlert({
        severity: 'warning',
        title: AlertTitle,
        body: strings.feeAgreements.messages.templateCanceled
      })
    );
    onTemplateCanceled && onTemplateCanceled();
  });

  helloSignClient.on('createTemplate', templateData => {
    if (templateData) {
      dispatch(
        showAlert({
          severity: 'success',
          title: AlertTitle,
          body: strings.feeAgreements.messages.templateOk
        })
      );
      onTemplateCreated && onTemplateCreated(templateData);
    }
  });

  const handleTemplateUploaded = ({ edit_url }) =>
    helloSignClient.open(edit_url, helloSignOpenConfig);

  const handlePreviewCreated = ({ claim_url }) =>
    helloSignClient.open(claim_url, helloSignOpenConfig);

  return { helloSignClient, handleTemplateUploaded, handlePreviewCreated };
};

export {
  cancelServiceAgreementRequest,
  createManagedServiceAgreement,
  createPreview,
  createUnmanagedServiceAgreement,
  declineByCoach,
  declineByOps,
  getAgreementsByCompanyId,
  getFeeAgreementById,
  reactivateServiceAgreement,
  sendReminder,
  sendRevalidationToCoach,
  sendRevalidationToOps,
  switchSigningProvider,
  updateSignersEmail,
  useFetchGuaranteeOptions,
  useFetchTemplates,
  useHelloSign,
  validateByCoach,
  validateByOps,
  validateByOpsForUnmanaged,
  voidServiceAgreement
};
