export const CompanyIncludes = [
  'type,specialty,subspecialty,contact,city,recruiter,createdBy,specialties,subspecialties,phones,hiringAuthorities'
];

export const CandidateIncludes = [
  'recruiter,createdBy,additionalRecruiters,blueSheets,personalInformation,specialty,subspecialty,position,status,sourceType,relation,optOutStatus,phones,emailValidation,personalEmailValidation'
];

export const JobOrderIncludes = [
  'recruiter,createdBy,additionalRecruiters,position,specialty,subspecialty,address,status,whiteSheet,sourceType,hiringAuthorities'
];

export const ContactIncludes = [
  'status,personalInformation,createdBy,specialty,subspecialty,position,sourceType,phones,emailValidation,personalEmailValidation'
];

export const HiringAuthorityIncludes = [
  'specialty,subspecialty,position,company,lastActivityLog,status,relation,createdBy,hiringAuthorityStatus,optOutStatus,emailValidation,phones,personalEmailValidation'
];
