import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';
import { container } from 'UI/constants/dimensions';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const cardStyles = {
  root: {
    boxShadow: container.boxShadow,
    position: 'relative',
    zIndex: 0,
    borderRadius: 12
  }
};

const fontFamily = THEME.typography.fontFamilyHeader;

const sharedHeaderStyles = {
  root: {
    padding: THEME.spacing(2),
    backgroundColor: THEME.palette.customColors.sideBar
  },
  title: {
    fontFamily,
    fontWeight: `${THEME.typography.headerWeights.bold} !important`,
    fontSize: 22,
    marginRight: THEME.spacing(0.5)
  }
};

const sharedSubHeaderStyles = {
  color: THEME.palette.customColors.inactive,
  fontWeight: '200 !important'
};

export const headerStyles = {
  regular: {
    root: {
      backgroundColor: THEME.palette.customColors.sideBar
    },
    title: {
      fontWeight: `${THEME.typography.headerWeights.bold} !important`,
      fontSize: 20,
      fontFamily
    }
  },
  hero: {
    subheader: {
      fontSize: '18px !important',
      paddingTop: THEME.spacing(0.5),
      ...sharedSubHeaderStyles
    },
    ...sharedHeaderStyles
  },
  section: {
    subheader: {
      fontSize: '16px !important',
      ...sharedSubHeaderStyles
    },
    ...sharedHeaderStyles
  }
};

export const useHeaderStyles = ({ variant }) => makeStyles(() => headerStyles[variant]);

export const useActionStyles = makeStyles({
  actionAndExpandContainer: {
    alignItems: 'center'
  }
});

const contentStyles = {
  tight: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  regular: {
    padding: THEME.spacing(2)
  },
  relaxed: {
    padding: THEME.spacing(3)
  }
};

export const useContentStyles = makeStyles({
  root: ({ variant }) => ({ ...contentStyles[variant] })
});

export const useFooterStyles = makeStyles(theme => ({
  buttonContainer: {
    margin: theme.spacing(2, 0),
    ...flexAlignCenterJustifyCenter
  }
}));
