// @flow
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { debounce } from 'lodash';
import { useUserContext } from 'providers/UserProvider';
import { getCancelToken } from 'services/API';
import strings from 'strings';
import { FormSchema } from 'UI/constants/entityTypes';
import {
  createDataSheetFormData,
  sanitizeFormData
} from 'UI/pages/EditCandidateDataSheet/EditCandidateDataSheet.utils';
import { getErrorMessage } from 'UI/utils';

import {
  saveCandidateDataSheet,
  saveCandidateDataSheetDraft
} from '../CandidateDataSheet.services';
import {
  CANDIDATE_DATA_SHEET_FULL_QUESTIONS_PATHS,
  CANDIDATE_DATA_SHEET_FULL_QUESTIONS_PATHS_REQUIRED
} from '../CandidateDataSheetDrawer.constants';
import {
  convertDraftToBlueSheetFormat,
  formatDataSheetDataForAPI,
  getDataSheetCount
} from '../CandidateDataSheetDrawer.utils';

import useCandidateDataSheetSchema from './useCandidateDataSheetSchema';
import useFetchCandidateDataSheetData from './useFetchCandidateDataSheetData';

const { uiMessages, title } = strings.candidates.editDataSheet;

type UseCandidateDataSheetParams = {
  candidateDataSheetId: string,
  draftData: Object,
  onSaveCandidateDataSheet: (
    candidateDataSheetId: string,
    progress: {
      answered: number,
      total: number
    }
  ) => void,
  onClose: () => void
};

const MILLISECONDS_TO_WAIT = 500;

const useCandidateDataSheet = ({
  candidateDataSheetId,
  draftData,
  onSaveCandidateDataSheet,
  onClose
}: UseCandidateDataSheetParams) => {
  const saveButtonRef = useRef(null);
  const [user] = useUserContext();
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const formDataRef = useRef(null);
  const cancelToken = useRef(null);

  const {
    data: candidateDataSheetData,
    error: errorData,
    isFetching: isFetchingCandidateDataSheetData
  } = useFetchCandidateDataSheetData({
    candidateDataSheetId: formDataRef.current ? '' : candidateDataSheetId
  });
  const [schemaState] = useCandidateDataSheetSchema();

  useEffect(() => {
    if (!errorData && candidateDataSheetData?.schema.key) {
      formDataRef.current = sanitizeFormData(candidateDataSheetData.data);
      return;
    }

    if (draftData && Object.keys(draftData).length > 0) {
      const blueSheetVersion = convertDraftToBlueSheetFormat(draftData);
      formDataRef.current = createDataSheetFormData(formDataRef.current, blueSheetVersion);
    }
  }, [candidateDataSheetData, errorData, draftData]);

  const onSaveClick = () => {
    if (!saveButtonRef.current) return;
    saveButtonRef.current.click();
  };

  const handleSaveDataSheet = useCallback(
    async (form, { shouldDisplayLoading = true, isDraft = false, shouldClose = true }) => {
      const sanitizedFormData = sanitizeFormData(form.formData);
      formDataRef.current = sanitizedFormData;

      const schemaName = FormSchema.CandidateDataSheetFull;

      const progressWithRequiredQuestions = getDataSheetCount(
        sanitizedFormData,
        CANDIDATE_DATA_SHEET_FULL_QUESTIONS_PATHS_REQUIRED,
        schemaName
      );

      const progress = getDataSheetCount(
        sanitizedFormData,
        CANDIDATE_DATA_SHEET_FULL_QUESTIONS_PATHS,
        schemaName
      );

      const data = formatDataSheetDataForAPI({
        ...(candidateDataSheetId && { id: candidateDataSheetId }),
        formData: sanitizedFormData,
        schemaName,
        user,
        progress,
        schemaVersion: schemaState.data.version
      });

      cancelToken.current && cancelToken.current.cancel(strings.shared.requests.cancelTokenMessage);
      cancelToken.current = getCancelToken();

      try {
        shouldDisplayLoading && setIsSaving(true);

        const response = await (isDraft ? saveCandidateDataSheetDraft : saveCandidateDataSheet)(
          data,
          {
            cancelToken: cancelToken.current.token
          }
        );

        if ([HTTPStatusCodes.Ok, HTTPStatusCodes.Created].includes(response.status)) {
          response.data.id &&
            onSaveCandidateDataSheet(response.data.id, progressWithRequiredQuestions);

          const finalMessage =
            progress.answered === progress.total
              ? uiMessages.complete.success
              : uiMessages.draft.success;

          shouldDisplayLoading &&
            dispatch(
              showAlert({
                severity: 'success',
                title: 'Success',
                body: finalMessage
              })
            );

          shouldDisplayLoading && setIsSaving(false);
          shouldClose && onClose();

          return;
        }

        const errorMessage =
          progress.answered === progress.total ? uiMessages.complete.error : uiMessages.draft.error;

        shouldDisplayLoading &&
          dispatch(
            showAlert({
              severity: 'error',
              title,
              body: errorMessage
            })
          );

        shouldDisplayLoading && setIsSaving(false);
      } catch (error) {
        shouldDisplayLoading &&
          dispatch(
            showAlert({
              severity: 'error',
              title,
              body: getErrorMessage(error)
            })
          );

        shouldDisplayLoading && setIsSaving(false);
      }
    },
    [candidateDataSheetId, dispatch, schemaState, onSaveCandidateDataSheet, user, onClose]
  );

  const debouncedSaveDataSheet = useCallback(debounce(handleSaveDataSheet, MILLISECONDS_TO_WAIT), [
    handleSaveDataSheet
  ]);

  const handleOnChange = useCallback(
    form => {
      formDataRef.current = sanitizeFormData(form.formData);

      debouncedSaveDataSheet(form, {
        shouldDisplayLoading: false,
        isDraft: true,
        shouldClose: false
      });
    },
    [debouncedSaveDataSheet]
  );

  return [
    {
      saveButtonRef,
      isSaving,
      formData: formDataRef.current,
      candidateDataSheetData,
      isFetchingCandidateDataSheetData,
      errorCandidateDataSheetData: errorData,
      primaryButtonName: candidateDataSheetId ? strings.shared.ui.update : strings.shared.ui.save,
      ...schemaState
    },
    {
      onSaveClick,
      save: handleSaveDataSheet,
      handleOnChange
    }
  ];
};

export default useCandidateDataSheet;
