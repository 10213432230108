import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import strings from 'strings';
import Text from 'UI/components/atoms/Text';
import { DateFormats } from 'UI/constants/defaults';
import { colors, OutlinedWarningIcon, SvgCalendar } from 'UI/res';

import { useStyles } from './styles';

const SendReminderConfirmationDialog = ({ lastActivity = {} }) => {
  const classes = useStyles();

  const { date = null, type = '' } = lastActivity;
  const formattedDate = date && moment(date).format(DateFormats.SimpleDateTime);
  const { details } = strings.feeAgreements;
  return (
    <>
      <div className={clsx(classes.row, classes.center)}>
        <Text className={classes.eventHistoryLabel} text={details.eventHistory} />

        <Text className={classes.secondaryText} text={details.lastActivity} />
      </div>

      {date ? (
        <>
          <div className={clsx(classes.row, classes.container, classes.center)}>
            <SvgCalendar size={16} fill={colors.black} />
            <Text className={classes.lastActivityDateLabel} text={formattedDate} />
          </div>

          <Text className={classes.lastActivityInfoLabel} text={type} />
        </>
      ) : (
        <div className={classes.container}>
          <Text className={classes.noReminderLabel} text={details.noReminder} />
        </div>
      )}

      <div className={clsx(classes.row, classes.container)}>
        <OutlinedWarningIcon size={28} fill={colors.orange} />
        <Text className={classes.confirmationLabel} text={details.confirmationSendReminder} />
      </div>
    </>
  );
};

export default {
  SendReminderConfirmationDialog
};
