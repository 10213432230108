// @flow

import { DomainTypes as DT, UiTypes as UIT } from 'actions/bulkEmail';
import get from 'lodash/get';
import set from 'lodash/set';
import { combineReducers } from 'redux';
import InitialState from 'stores/initial';
import type { BulkEmailDomainState, BulkEmailUiState } from 'types/bulkEmail';
import type { Action } from 'types/redux';

const { template: initialTemplate, emailBody: initialEmailBody } = InitialState.bulkEmail.domain;
const { domain: initialDomain, ui: initialUi } = InitialState.bulkEmail;

/**
 * @param {Array} props - Array of name props
 * @param {Object} initState - Initial state to set
 * @param {Object} currentState - Current state to use in case there are
 * dontReset props, these will remain the same
 *
 * @returns {Object}
 */
const dontResetProps = (props, initState, currentState) => {
  const dontReset = {};
  if (props?.length > 0) {
    props.forEach(key => {
      const valueToSave = get(currentState, key);
      set(dontReset, key, valueToSave);
    });
    return { ...initState, ...dontReset };
  }
  return initState;
};

const domainReducer = (
  state: BulkEmailDomainState = {},
  action: Action = { type: '', payload: {} }
) => {
  const { type, payload } = action;
  switch (type) {
    case DT.SET_BULK_TO_INITIAL:
      return dontResetProps(payload?.dontReset, initialDomain, state);
    case DT.SET_TEMPLATE_TO_INITIAL:
      return {
        ...state,
        template: initialDomain.template
      };
    case DT.SET_TEMPLATE:
      return {
        ...state,
        template: {
          ...state.template,
          ...payload
        }
      };
    case DT.SET_EXCLUDED_TEMPLATE_ATTACHMENTS:
      return {
        ...state,
        template: {
          ...state.template,
          emailExcludedAttachments: [...state.template.emailExcludedAttachments, payload]
        }
      };
    case DT.REMOVE_TEMPLATE:
      return {
        ...state,
        template: {
          ...initialTemplate
        }
      };
    case DT.SET_ATTACHMENT:
      return {
        ...state,
        attachments: [...state.attachments, payload]
      };
    case DT.REMOVE_ATTACHMENT:
      return {
        ...state,
        attachments:
          state.attachments.filter(attachment => attachment[payload.type] !== payload.value) || []
      };
    case DT.REMOVE_ALL_ATTACHMENTS:
      return {
        ...state,
        attachments: []
      };
    case DT.SET_EMAIL_BODY:
      return {
        ...state,
        emailBody: {
          ...state.emailBody,
          ...payload
        }
      };
    case DT.REMOVE_EMAIL_BODY:
      return {
        ...state,
        emailBody: {
          ...initialEmailBody
        }
      };
    case DT.SET_BULK_PREVIEW:
      return {
        ...state,
        sent: {
          ...state.sent,
          preview: {
            ...state.sent.preview,
            ...payload
          }
        }
      };
    case DT.SET_BULK_PREVIEW_TO_INITIAL:
      return {
        ...state,
        sent: initialDomain.sent,
        bulkEmailInPreview: initialDomain.bulkEmailInPreview
      };
    case DT.SET_BULK_EMAIL_IN_PREVIEW:
      return {
        ...state,
        bulkEmailInPreview: {
          ...state.bulkEmailInPreview,
          ...payload
        }
      };
    case DT.SET_BULK_EMAIL_IN_PREVIEW_ID:
      return {
        ...state,
        bulkEmailInPreview: {
          ...state.bulkEmailInPreview,
          data: {
            id: payload
          }
        }
      };
    default:
      return state;
  }
};

const uiReducer = (state: BulkEmailUiState = {}, action: Action = { type: '', payload: {} }) => {
  const { type, payload } = action;
  switch (type) {
    case UIT.SET_SELECTED_MENU_ITEM:
      return {
        ...state,
        selectedMenuItem: payload
      };
    case UIT.SET_ATTACHMENT_LOADING:
      return {
        ...state,
        attachments: {
          loading: payload
        }
      };
    case UIT.SET_BULK_EMAIL_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          ...payload
        }
      };
    case UIT.SET_STATS_OPEN:
      return {
        ...state,
        stats: {
          ...state.stats,
          open: payload
        }
      };
    case UIT.SET_BULK_EMAIL_STATUS_TO_INITIAL:
      return {
        ...state,
        status: {
          ...dontResetProps(payload?.dontReset, initialUi.status, state.status)
        }
      };
    case UIT.SET_BULK_EMAIL_PREVIEW_LOADING:
      return {
        ...state,
        preview: {
          loading: payload
        }
      };
    case UIT.SET_BULK_RESTRICTION_TO_INITIAL:
      return {
        ...state,
        status: {
          ...state.status,
          bulkRestriction: initialUi.status.bulkRestriction
        }
      };
    default:
      return state;
  }
};

const bulkEmailReducer = combineReducers({ domain: domainReducer, ui: uiReducer });

export default bulkEmailReducer;
