import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  formSectionTitle: {
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightBold
  },
  fieldsContainer: {
    margin: theme.spacing(2, 0, 4)
  },
  checkboxReminder: {
    padding: theme.spacing(0.5, 1.5, 2)
  },
  fieldsSubcontainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
}));
