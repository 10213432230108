// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useMap } from 'hooks/useMap';
import FPMap from 'UI/components/atoms/FPMap';

import { CountriesContainer, MapContainer, PILMapContainer } from './PILMap.styled';
import PILMapCountry from './PILMapCountry';

const DEFAULT_ZOOM = 2.3;

const PILMap = ({ countries, markers }) => {
  const { handleClosePopUp, handleMarkerEnter, handleViewportChange, popupInfo, viewport } = useMap(
    { markers, zoom: DEFAULT_ZOOM }
  );

  const statesCount = countries.reduce((acc, country) => {
    return acc + country.states.length;
  }, 0);

  return (
    <PILMapContainer>
      <Typography variant="overline" noWrap title="States">
        States ({statesCount})
      </Typography>
      <CountriesContainer>
        {countries.map(country => (
          <PILMapCountry key={country?.name} country={country} />
        ))}
      </CountriesContainer>
      <MapContainer>
        <FPMap
          enableNavigationControls
          isDigActive
          markers={markers}
          onMapClick={handleClosePopUp}
          onMarkerEnter={handleMarkerEnter}
          onClosePopup={handleClosePopUp}
          onViewportChange={handleViewportChange}
          viewport={viewport}
          popupInfo={popupInfo}
          renderPopupContent={() => (
            <>
              <Typography variant="body1" gutterBottom>
                <strong>{popupInfo.state}</strong>
              </Typography>
              <Typography variant="body2" gutterBottom>
                Industry: <strong>{popupInfo.name}</strong>
              </Typography>
              <Typography variant="body2" gutterBottom>
                Specialties:
              </Typography>
              <ul style={{ margin: '0px' }}>
                <Typography variant="body2" gutterBottom>
                  <li>{popupInfo.specialties.join(', ')}</li>
                </Typography>
              </ul>
            </>
          )}
        />
      </MapContainer>
    </PILMapContainer>
  );
};

export default PILMap;
