// @flow
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import type { Bulk, OperationResult } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';
import { getErrorMessage } from 'UI/utils';

import API from '../API';

const { BulkEmails } = Endpoints;

export const sendBulkEmail = async (bulk: Bulk): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const response = await API.post(BulkEmails, bulk);
    if (response.status === HTTPStatusCodes.Created) {
      result.success = true;
      result.data = { ...response.data };
      result.alert = {
        severity: 'success',
        title: 'Bulk sent',
        body: 'Your bulk has been succesfully sent.',
        autoHideDuration: 8000
      };
      if (bulk.is_draft) {
        result.alert = {
          severity: 'success',
          title: 'Draft saved successfully',
          body: 'Saved in the drafts folder located in the Bulks section.',
          autoHideDuration: 8000
        };
      }
    } else {
      result.alert = {
        severity: 'error',
        title: 'Bulk Email Was Not Sent',
        body: response.data.message,
        autoHideDuration: 8000
      };
    }
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: 'Problem Sending Bulk Email',
      body: getErrorMessage(error),
      autoHideDuration: 8000
    };
    if (error?.response?.data) result.error = error.response.data;
  }
  return result;
};
