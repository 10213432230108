import { makeStyles } from '@material-ui/core/styles';

import { avatarColors } from './definitions';

export const defaulAvatarClasses = ({ mode }) =>
  makeStyles({
    colorDefault: {
      backgroundColor: avatarColors.backgrounds[mode]
    }
  });

export const avatarColorCollection = {
  primary: 'primary',
  red: 'red',
  green: 'green',
  purple: 'purple',
  cyan: 'cyan',
  teal: 'teal',
  orange: 'orange',
  blueGrey: 'blueGrey'
};

export const useAvatarUserColor = ({ variant }) => avatarColorCollection[variant];

export const AvatarUserColors = Object.keys(avatarColorCollection);
