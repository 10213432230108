// @flow
import { EntityRoutes } from 'routes/constants';
import { DateFormats, defaultProfileColumnsConfig } from 'UI/constants/defaults';

const dateRenderer = {
  id: 'dateBuilder',
  config: { format: DateFormats.SimpleDateTime }
};

export const virtualProps = [
  {
    propName: 'searchProjectName',
    attributes: ['searchProjectName', 'searchProjectId']
  }
];

export const columns = [
  {
    name: 'searchProjectId',
    options: {
      display: 'excluded'
    }
  },
  {
    name: 'searchProjectName',
    label: 'Search Project',
    options: {
      display: true,
      sort: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: `${EntityRoutes.SearchProjectPreview}`,
          idKey: 'searchProjectId',
          textKey: 'searchProjectName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'addedDate',
    label: 'Addition Date',
    options: {
      display: true,
      sort: true,
      renderer: dateRenderer
    }
  },
  {
    name: 'searchProjectCreatedAt',
    label: 'Search Project Creation Date',
    options: {
      display: true,
      sort: true,
      renderer: dateRenderer
    }
  },
  {
    name: 'visibility',
    label: 'Visibility',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'visibility'
    }
  },
  {
    name: 'lastBulkOn',
    label: 'Last Bulk Date',
    options: {
      display: true,
      sort: true,
      renderer: dateRenderer
    }
  },
  {
    name: 'recruitersNames',
    label: 'Owner Name',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'arrayJoiner',
        config: {
          separator: ' / '
        }
      }
    }
  },
  {
    name: 'recruitersInitials',
    label: 'Owner Initials',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'arrayJoiner',
        config: {
          separator: ' / '
        }
      }
    }
  },
  {
    name: 'last_bulk_recruiter',
    label: 'Last Bulk Sender',
    options: {
      renderer: 'default',
      display: true
    }
  },
  {
    name: 'updated_at',
    label: 'Last Modification',
    options: {
      renderer: 'date',
      display: false
    }
  }
];
