// @flow
import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { getId } from 'UI/utils';

import { useStyles } from './styles';

interface FPToggleButtonProps {
  options?: any;
  onChange: () => any;
}

const FPToggleButton = ({ options, onChange, ...rest }: FPToggleButtonProps) => {
  const classes = useStyles();

  const handleToggle = (event, value) => onChange?.(value);

  return (
    <ToggleButtonGroup exclusive onChange={handleToggle} {...rest}>
      {options?.map(option => (
        <ToggleButton
          key={getId()}
          value={option.value}
          disabled={option.disabled}
          classes={classes}
        >
          <span>{option?.icon && option.icon}</span> {option.title}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

FPToggleButton.defaultProps = {
  options: null
};

export default FPToggleButton;
