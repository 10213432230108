// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';

import { usePreviewBulkMetaRowsStyles } from './styles';

type RowToRenderItemType = {
  enabled: boolean,
  RowProps?: Object,
  title: string,
  render: Node
};

type PreviewBulkMetaRowsType = {
  rowsToRender?: Array<RowToRenderItemType>
};

const PreviewBulkMetaRows = ({ rowsToRender = [] }: PreviewBulkMetaRowsType) => {
  const classes = usePreviewBulkMetaRowsStyles();
  const filteredRows = rowsToRender.filter(row => row.enabled);

  return filteredRows.map(row => (
    <Grid
      data-testid="bulk-meta-row"
      key={row.title}
      container
      item
      alignItems="center"
      wrap="nowrap"
      className={classes.previewBulkRow}
      {...row.RowProps}
    >
      <Grid item className={classes.title}>
        {row.title}:
      </Grid>
      <Grid item className={classes.renderValue}>
        {row.render}
      </Grid>
    </Grid>
  ));
};

export default PreviewBulkMetaRows;
