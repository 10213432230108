// @flow
import React from 'react';
import strings from 'strings';
import FPButtonMenu from 'UI/components/molecules/FPButtonMenu';
import SvgEdit from 'UI/res/icons/filled/Edit';

const { personalData } = strings.candidates.profile.dataSheet.editButton;

type EditCandidateDataSheetButtonProps = {
  text: string,
  className?: string,
  onWriteUpClick: () => void,
  onEditPersonalData?: () => void
};
const EditCandidateDataSheetButton = ({
  className,
  text,
  onWriteUpClick,
  onEditPersonalData
}: EditCandidateDataSheetButtonProps) => {
  if (!onWriteUpClick) return null;

  const menuItems = [
    {
      title: strings.candidates.editDataSheet.contentPageTitle,
      action: onWriteUpClick,
      visible: true
    },
    {
      title: personalData,
      action: onEditPersonalData,
      visible: true
    }
  ];

  return (
    <div className={`dataSheetButton ${className ?? ''}`}>
      <FPButtonMenu
        menuItems={menuItems}
        title={text}
        text={text}
        variant="outlined"
        size="medium"
        icon={SvgEdit}
      />
    </div>
  );
};

export default EditCandidateDataSheetButton;
