// @flow
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import useDrawer from 'hooks/drawerHandler';
import { EntityRoutes } from 'routes/constants';
import { useHelloSign } from 'services/FeeAgreement';
import strings from 'strings';
import type { ProfileDataTableProps } from 'types/app';
import { componentDimensions } from 'UI/constants/dimensions';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType, TabKeys } from 'UI/constants/entityTypes';
import { SvgNoFeeAgreements } from 'UI/res/icons/milano';
import { updateUiState } from 'UI/utils/uiHelpers';

import FeeAgreementCreationForm from '../../feeagreements/FeeAgreementCreationForm';
import FeeAgreementDetails from '../../feeagreements/FeeAgreementDetails';
import ProfileTableLayout from '../ProfileTableLayout';

import { feeAgreementColumns } from './columns';

const { emptyState } = componentDimensions;

const {
  inventoryProfiles: {
    emptyStates: { feeAgreements: feeAgreementsEmptyStrings }
  }
} = strings;

type FeeAgreementTabProps = {
  endpoint: string,
  profile: Array<Object>,
  companyId: number,
  profileModule: string,
  DataTableProps: ProfileDataTableProps,
  paramsBuilder: Array<Object>
};

const FeeAgreementTab = ({
  endpoint,
  profile,
  profileModule,
  DataTableProps,
  paramsBuilder,
  ...rest
}: FeeAgreementTabProps) => {
  const history = useHistory();
  const { location } = history;

  const editionDrawer = useDrawer(location, history);

  const [uiState, setUiState] = useState({
    additionalData: null,
    isFeeAgreementOpen: false,
    isFeeAgreementSummaryOpen: false,
    selectedFeeAgreementId: null,
    shouldRefreshTableData: false
  });

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   F E E  A G R E E M E N T   C R E A T I O N
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */
  const refreshFeeAgreements = () =>
    updateUiState(
      {
        shouldRefreshTableData: true,
        isFeeAgreementSummaryOpen: false,
        selectedFeeAgreementId: null
      },
      setUiState
    );

  const handleTemplateCreated = templateData =>
    updateUiState({ additionalData: { template: templateData.templateId } }, setUiState);

  const handleTemplateCanceled = () => updateUiState({ additionalData: null }, setUiState);

  const { handleTemplateUploaded, handlePreviewCreated } = useHelloSign({
    onSend: refreshFeeAgreements,
    onTemplateCreated: handleTemplateCreated,
    onTemplateCanceled: handleTemplateCanceled
  });

  const handleNewAgreementClick = () =>
    updateUiState({ isFeeAgreementOpen: true, shouldRefreshTableData: false }, setUiState);
  const handleNewAgreementClose = () =>
    updateUiState({ isFeeAgreementOpen: false, shouldRefreshTableData: false }, setUiState);

  const handleNewAgreementCompleted = () =>
    updateUiState({ isFeeAgreementOpen: false, shouldRefreshTableData: true }, setUiState);

  const handleNewJOClick = () =>
    history.push(`${EntityRoutes.JobOrderCreate}?companyId=${profile.id}`);

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   F E E  A G R E E M E N T   D E T A I L S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const handleFeeAgreementClick = (feeAgreementId: number) => {
    updateUiState(
      {
        isFeeAgreementSummaryOpen: true,
        selectedFeeAgreementId: feeAgreementId,
        shouldRefreshTableData: false
      },
      setUiState
    );
  };

  const handleAgreementClosed = () =>
    updateUiState(
      {
        isFeeAgreementSummaryOpen: false,
        selectedFeeAgreementId: null,
        shouldRefreshTableData: false
      },
      setUiState
    );

  const finalActions = [
    {
      name: 'view',
      callback: handleFeeAgreementClick,
      pickId: true
    }
  ];

  const isHiringAuthorityProfile = profileModule === EntityType.HiringAuthority;

  return (
    <>
      <ProfileTableLayout
        actionText="Create Fee Agreement"
        DataTableProps={DataTableProps}
        finalActions={finalActions}
        initialColumns={feeAgreementColumns}
        onNewItemClick={!isHiringAuthorityProfile && handleNewAgreementClick}
        tabKey={TabKeys.FeeAgreements}
        customEmptyState={<SvgNoFeeAgreements size={emptyState.width} />}
        shouldRefresh={uiState.shouldRefreshTableData}
        hasProfileLoaded={profile?.id}
        profileModule={profileModule}
        paramsBuilder={paramsBuilder}
        {...rest}
        {...feeAgreementsEmptyStrings}
      />
      {profile?.id && (
        <Drawer
          open={uiState.isFeeAgreementOpen}
          onClose={editionDrawer.toggleDrawer('isFeeAgreementOpen', false)}
        >
          <div role="presentation">
            <FeeAgreementCreationForm
              company={profile}
              hiringAuthorities={profile?.hiringAuthorities}
              onFAClosed={handleNewAgreementClose}
              onNewJOClick={handleNewJOClick}
              onFACompleted={handleNewAgreementCompleted}
              endpoint={`${endpoint}/${Endpoints.FeeAgreement}`}
            />
          </div>
        </Drawer>
      )}
      {uiState.selectedFeeAgreementId && uiState.isFeeAgreementSummaryOpen && (
        <Drawer open onClose={handleAgreementClosed}>
          <div role="presentation">
            <FeeAgreementDetails
              feeAgreementId={uiState.selectedFeeAgreementId}
              additionalData={uiState.additionalData}
              onTemplateUploaded={handleTemplateUploaded}
              onPreviewCreated={handlePreviewCreated}
              onUpdate={refreshFeeAgreements}
              onClose={handleAgreementClosed}
            />
          </div>
        </Drawer>
      )}
    </>
  );
};

export default FeeAgreementTab;
