import { makeStyles } from '@material-ui/core/styles';
import { globalStyles } from 'GlobalStyles';
import { mainLayoutPadding, sideBarWidth, sideBarWidthCollapsed } from 'UI/constants/dimensions';
import { colors } from 'UI/res';

const fullWidthCardStyles = {
  width: '100%',
  backgroundColor: colors.sideBar
};

export const useStyles = makeStyles(theme => ({
  wrapperPageContainer: {
    width: '100%',
    position: 'relative',
    overflowX: 'auto',
    justifyContent: 'center',
    height: '100%',
    transition: `margin ${globalStyles.transition}`,
    marginLeft: sideBarWidthCollapsed
  },
  marginLeftLargeView: {
    marginLeft: ({ isSidebarOpen }) => (isSidebarOpen ? sideBarWidth : sideBarWidthCollapsed)
  },
  backNavigation: {
    alignSelf: 'baseline',
    margin: theme.spacing(0, 0.5)
  },
  mainLayout: {
    width: '100%',
    padding: theme.spacing(mainLayoutPadding, mainLayoutPadding, 8),
    height: ({ height }) => height
  },
  fullWidthCard: {
    ...fullWidthCardStyles,
    padding: theme.spacing(mainLayoutPadding, mainLayoutPadding, 1)
  },
  sectionName: ({ isWithoutBackNavigation }) => ({
    marginLeft: isWithoutBackNavigation ? 0 : theme.spacing(6.5),
    position: 'absolute',
    top: theme.spacing(-2),
    whiteSpace: 'nowrap'
  }),
  sectionNameWrapper: {
    position: 'relative'
  }
}));
