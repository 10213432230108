// @flow

import { userHasRoles } from 'services/Authorization';

const useOwnershipAndPermissions = ({ isLoading, results, userId }) => {
  const allowedRoles =
    !isLoading && results?.roles.length && results?.roles.map(item => item.role_id);

  const hasFullPermissions = userHasRoles(allowedRoles);

  const ownershipValidationId = Number(results.id);
  const isUserOwnership = !isLoading && ownershipValidationId === userId;

  const isRelatedUserByPil =
    !isLoading &&
    results.pil.length &&
    results.pil.some(item => item?.id === ownershipValidationId);

  const hasOwnership = isUserOwnership || isRelatedUserByPil;
  const hasOwnershipAndPermissions = hasFullPermissions || hasOwnership;

  return { hasOwnershipAndPermissions, isUserOwnership, isRelatedUserByPil, hasOwnership };
};

export default useOwnershipAndPermissions;
