// @flow
import React from 'react';
import { SimplifiedTable } from 'features/command-center/';
import strings from 'strings';
import { Endpoints } from 'UI/constants/endpoints';

import { COLUMNS, ORDER_BY_OPTIONS, VIRTUAL_PROPS } from './ClosingCalls.constants';

const COPIES = strings.commandCenter.dominateSheet.closingCalls;
const ENDPOINT = Endpoints.JobOrders; // TODO: adjust this endpoint
const TABLE_KEY = 'preparation-calls-list';

export const ClosingCalls = () => {
  return (
    <SimplifiedTable
      columns={COLUMNS}
      endpoint={ENDPOINT}
      header={COPIES.title}
      orderByOptions={ORDER_BY_OPTIONS}
      pageKey={TABLE_KEY}
      virtualProps={VIRTUAL_PROPS}
    />
  );
};
