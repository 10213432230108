import { SendoutStatus, SendoverStatus } from 'UI/constants/status';

const otherReason = 'Other Reason';
const declineOfferList = [
  'Counteroffer',
  'Accepted another position (through us)',
  'Accepted another position (not through us)',
  'Company filled position (through us)',
  'Offer was too low',
  'Doesn’t want to make a change anymore',
  'Not a good fit',
  otherReason
];
export const DeclineFormNames = {
  declinedFields: { name: 'declined_fields', required: true },
  declinedNotes: { name: 'declination_notes', required: true }
};

const textboxGeneric = {
  name: DeclineFormNames.declinedNotes.name,
  type: 'textbox',
  label: 'Other reasons or comments',
  required: false,
  title: `If there's another reason or a comment you want to add, please type it below`,
  customValidation: (value, restForm) => {
    const isOtherReason = restForm[DeclineFormNames.declinedFields.name]?.some(
      field => field === otherReason
    );

    return !isOtherReason || !!value || 'Please fill the reason why';
  }
};

export const DeclineSchema = [
  {
    name: DeclineFormNames.declinedFields.name,
    type: 'checkbox',
    options: {
      data: declineOfferList
    },
    title: 'Select the reason(s) why this offer was declined',
    required: true,
    customValidation: fields => {
      return (fields && fields.length > 0) || 'Select at least one option';
    }
  },
  textboxGeneric
];

export const noOfferList = [
  'Candidate skillset does not match',
  'Culture and personality does not match',
  'Salary expectation too high',
  'Company filled position (not through us)',
  'Company filled position (through us)',
  'Accepted another position (through us)',
  'Accepted another position (not through us)',
  otherReason
];

export const NoOfferSchema = [
  {
    name: DeclineFormNames.declinedFields.name,
    type: 'checkbox',
    options: {
      data: noOfferList
    },
    title: `Select the reason(s) why there's no offer`,
    required: true,
    customValidation: fields => {
      return (fields && fields.length > 0) || 'Select at least one option';
    }
  },
  textboxGeneric
];

export const NoOfferStatus = [SendoverStatus.NoOffer, SendoutStatus.NoOffer];
export const DeclinedStatus = [SendoverStatus.Declined, SendoutStatus.Declined];
