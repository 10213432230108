// @flow
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import pick from 'lodash/pick';
import { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';
import { DateFormats } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { UIStatus } from 'UI/constants/status';
import { preloadFromBackend, prepareFormToSubmit } from 'UI/utils/forms';

import {
  BoolEnum,
  FormFields,
  FormFieldsMap,
  getMapForSpecificFields
} from '../Collections.fields';

const getErrorMessage = exception => {
  const detail = exception.response?.data?.detail;

  if (!detail) return strings.shared.errors.serverError.subtitle;

  const message = Array.isArray(detail) ? detail.map(({ msg }) => `∙ ${msg}\n`).join('') : detail;

  return message;
};

const useCollectionsForm = ({
  commitDate,
  commitAmount,
  commitToPay,
  id,
  notes,
  onCancel,
  onSubmitSucceed,
  onViewClick,
  placementId,
  rowIndex
}) => {
  const [saveState, setSaveState] = useState({ status: UIStatus.Default, error: null });
  const dispatch = useDispatch();

  const defaultValues = preloadFromBackend(
    {
      [FormFieldsMap.CommitDate.key]: commitDate,
      [FormFieldsMap.CommitAmount.key]: commitAmount,
      [FormFieldsMap.CommitToPay.key]: commitToPay,
      [FormFieldsMap.Notes.key]: notes
    },
    FormFieldsMap
  );

  const form = useForm({ defaultValues });
  const {
    formState: { dirtyFields, dirty },
    handleSubmit,
    register,
    setValue,
    unregister,
    watch
  } = form;
  const formValues = watch();

  useEffect(() => {
    FormFields.forEach(field => register(field));

    return () => {
      FormFields.forEach(field => unregister(field));
    };
  }, [register, unregister]);

  const handleFieldChange = (fieldName: string, newValue: any) => setValue(fieldName, newValue);
  /**
   * Toggles the value of the `CommitToPay` field in the form.
   * @param {Event} event - The event object triggered by the checkbox toggle.
   */
  const handleCheckToggle = event => {
    const { value } = event.target;
    const newValue = value === BoolEnum.True ? BoolEnum.False : BoolEnum.True;
    setValue(FormFieldsMap.CommitToPay.key, newValue);
  };
  const handleCancel = () => onCancel && onCancel(id, rowIndex);
  const handleView = () => onViewClick && onViewClick({ id, placementId, rowIndex });

  /**
   * Handles the submission of form data to a PATCH endpoint, updating only the fields that have changed.
   * @param {Object} formData - The form data submitted by the user.
   */
  const onSubmit = async formData => {
    const fieldKeys = [...dirtyFields];

    if (fieldKeys.length === 0) {
      onCancel(id, rowIndex);
      return;
    }

    const mutatedFields = pick(formData, fieldKeys);
    const fieldsToSend = prepareFormToSubmit(
      mutatedFields,
      getMapForSpecificFields(FormFieldsMap, fieldKeys)
    );

    try {
      setSaveState({ status: UIStatus.Saving });

      const response = await SECONDARY_API_CLIENT.patch(
        `${Endpoints.CollectionsInvoices}/${id}`,
        fieldsToSend
      );

      if (response.status === HTTPStatusCodes.Ok) {
        setSaveState({ status: UIStatus.Success });

        const mutatedCommittedDate = mutatedFields[FormFieldsMap.CommitDate.key];
        if (mutatedCommittedDate) {
          mutatedFields[FormFieldsMap.CommitDate.key] = mutatedCommittedDate.format(
            DateFormats.SimpleDate
          );
        }

        dispatch(
          showAlert({
            severity: 'success',
            body: strings.collections.messages.recordUpdated,
            autoHideDuration: 3000
          })
        );

        onSubmitSucceed && onSubmitSucceed({ id, rowIndex, mutatedFields });
      }
    } catch (e) {
      setSaveState({ status: UIStatus.Error, error: getErrorMessage(e) });
    }
  };

  return {
    dirty,
    formValues,
    handleView,
    handleCancel,
    handleCheckToggle,
    handleFieldChange,
    handleSubmit,
    onSubmit,
    saveState
  };
};

export default useCollectionsForm;
