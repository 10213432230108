// @flow
import { useState } from 'react';
import strings from 'strings';
import { Endpoints } from 'UI/constants/endpoints';
import { UIStatus } from 'UI/constants/status';
import { nestTernary } from 'UI/utils';

import useFetchWithStatus from './fetchWithStatus';

type AssociatedCompaniesProps = {
  profileId?: number
};

export const useAssociatedCompanies = (profileId = null): AssociatedCompaniesProps => {
  const endpoint = profileId ? `${Endpoints.Companies}/${profileId}` : '';

  const [associatedCompanies, setAssociatedCompanies] = useState({
    parentCompany: null,
    siblingCompanies: null,
    childCompanies: null
  });

  const {
    state: { results, status },
    refreshData
  } = useFetchWithStatus(endpoint, null, null, 2);

  const isLoading = status === UIStatus.Loading;

  const linkedParentId = results?.parent_company_id;

  const currentParentCompanyId = associatedCompanies?.parentCompany?.id;

  const associatedCompaniesActions = {
    associateParent: profileId ? !linkedParentId : true,
    associateChild: true,
    associateSibling: profileId ? !!linkedParentId : !!currentParentCompanyId
  };

  const childCompaniesIds = extractIds(associatedCompanies?.childCompanies);
  const siblingCompaniesIds = extractIds(associatedCompanies?.siblingCompanies);

  const isChildParentMixed = childCompaniesIds?.includes(currentParentCompanyId);
  const isChildSiblingMixed = childCompaniesIds?.some(item => siblingCompaniesIds?.includes(item));
  const isSiblingParentMixed = siblingCompaniesIds?.includes(currentParentCompanyId);

  const mixedRelationshipsMessage = nestTernary(
    isChildParentMixed,
    strings.companies.creation.associated.warnings.mixedCompanies.childParent,
    nestTernary(
      isChildSiblingMixed,
      strings.companies.creation.associated.warnings.mixedCompanies.childSibling,
      nestTernary(
        isSiblingParentMixed,
        strings.companies.creation.associated.warnings.mixedCompanies.siblingParent,
        null
      )
    )
  );

  return {
    associatedCompanies,
    setAssociatedCompanies,
    associatedCompaniesActions,
    refreshData,
    isLoading,
    linkedParentId,
    mixedRelationshipsMessage
  };
};

const extractIds = companies => (companies?.length ? companies?.map(({ id }) => id) : null);

export const formatLinkedCompanies = associatedCompanies => {
  const { parentCompany, childCompanies, siblingCompanies } = associatedCompanies;

  return {
    parent_company_id: parentCompany?.id ? parentCompany.id : null,
    child_companies_ids: extractIds(childCompanies),
    sister_companies_ids: extractIds(siblingCompanies)
  };
};
