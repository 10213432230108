import { createQueryParams } from 'features/command-center/services/utils';
import { SECONDARY_API_CLIENT } from 'services/API';

import { ENDPOINTS } from '../constants';

export const getChats = async (params = {}) => {
  const queryParams = createQueryParams(params);

  return SECONDARY_API_CLIENT.get(`${ENDPOINTS.messages}?${queryParams}`)
    .then(({ data }) => data)
    .catch(error => {
      throw error.response;
    });
};

export const searchChats = async (params = {}) => {
  const queryParams = createQueryParams(params);

  return SECONDARY_API_CLIENT.get(`${ENDPOINTS.searchByKeyword}?${queryParams}`).then(
    ({ data }) => data
  );
};

export const getConversation = async (params = {}) => {
  const queryParams = createQueryParams(params);

  return SECONDARY_API_CLIENT.get(`${ENDPOINTS.conversations}?${queryParams}`).then(
    ({ data }) => data
  );
};

export const sendMessageToServer = async message => {
  const { to, from, message: body, attachment, contact } = message;
  const formattedMessage = {
    to,
    from,
    body,
    attachmentUrl: attachment.url,
    attachmentSize: attachment.size,
    contact
  };

  return SECONDARY_API_CLIENT.post(ENDPOINTS.messages, formattedMessage).then(({ data }) => data);
};
