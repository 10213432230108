// @flow
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {
  confirm as confirmAction,
  showAlert as showAlertAction,
  showFile as showFileAction,
  showFileExplorer as showFileExplorerAction
} from 'actions/app';
import clsx from 'clsx';
import { globalStyles } from 'GlobalStyles';
import useStateWithCallback from 'hooks/stateWithCallback';
import sumBy from 'lodash/sumBy';
import API from 'services/API';
import strings from 'strings';
import type { AttachmentCategory, FileExplorerSection } from 'types/app';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import FPIcon from 'UI/components/atoms/FPIcon';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import SaveButton from 'UI/components/atoms/SaveButton';
import TextBox from 'UI/components/atoms/TextBox';
import FileChip from 'UI/components/molecules/FileChip';
import FileItem from 'UI/components/molecules/FileItem';
import FileSelectorButton from 'UI/components/molecules/FileSelectorButton';
import FileSelector from 'UI/components/organisms/FileSelector';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { MaxNumberOfAttachments } from 'UI/constants/defaults';
import { FileStatus } from 'UI/constants/status';
import { colors, EmptyFiles, SvgUploadButton } from 'UI/res';
import { formatBytes, getErrorMessage, nestTernary, trimFileNameWithExtension } from 'UI/utils';

import { styles, useStyles } from './styles';

export const FileUploaderMode = {
  Button: 'button',
  CategoryField: 'categoryField',
  Chips: 'chips',
  ChipsForm: 'chipsForm',
  List: 'list',
  ListReadonly: 'listReadonly',
  ListSelectable: 'listSelectable'
};

const {
  shared: {
    ui: { keepRemoveButtonsCopies }
  }
} = strings;

const MAX_FILE_NAME_LENGTH = 45;

type Attachment = {
  hasError?: boolean,
  id?: number,
  isLoading: boolean,
  message?: string,
  name?: string,
  type?: 'new' | 'existing' | undefined
};

type FileUploaderProps = {
  /** List of accepted file types */
  acceptFiles: string[],
  /** Wheter to append or always replace the same file */
  alwaysReplace?: boolean,
  /** List of categories files to be show */
  categories: any,
  /** The related category of the file */
  category: AttachmentCategory,
  /** Additional class names  */
  className: string,
  /** Default entity type assgined to  new files */
  defaultEntityType: string,
  /** Wheter to disable the uploader */
  disabled: boolean,
  /** Endpoint to remove the uploaded file. If no specified, by default uses endpoint */
  deleteEndpoint?: string,
  /** Endpoint to where files are uploaded */
  endpoint: string,
  /** Error text from validation form */
  errorText?: string,
  /** Name of the propery which contains the file name */
  fileNameField: string,
  /** List of files to be shown */
  files?: Attachment[],
  /** Sections to show in File Explorer */
  fileExplorerSections: FileExplorerSection[],
  /** Whether to make a request to get items */
  shouldFetchItems?: boolean,
  /** Wheter the file list is loading */
  loading: boolean,
  /** Max number of files allowed */
  maxNumberOfFiles: number,
  /** Max file size */
  maxFileSize: number,
  /** Wheter to allow selecting multiple files at once */
  multiple?: boolean,
  /** The name of the field when categorized field is used */
  name: string,
  /** Variant of the uploader */
  mode?:
    | 'chips'
    | 'list'
    | 'button'
    | 'chipsForm'
    | 'listReadonly'
    | 'categoryField'
    | 'listSelectable',
  /** Mode extra Props for especific mode needs */
  modeExtraProps: any,
  /** Event fired when files change */
  onAttachmentsChanged: any,
  /** Event fired when upload starts */
  onFileUploadStarted: (any, string) => any,
  /** Event fired when upload succeeds */
  onFileUploadedSuccesfully: any => any,
  /** Class name to apply to file selector */
  selectorClassName?: string,
  showAlert: any => void,
  showConfirm: any => void,
  /** Wheter to show the original icon buttons */
  showIconButtons?: boolean,
  /** Wheter to show file date on list mode */
  showItemDate?: boolean,
  /** Wheter to show file options as a menu */
  showItemMenu?: boolean,
  /** Wheter to only remove a local file reference or completely remove the file from the server   */
  softDeleteForExistingItems?: boolean,
  /** add new append file_type_id to send params */
  shouldAppendCategory?: boolean,
  /** Text for upload button */
  text: string,
  /** Wheter to view file on item click for list mode */
  viewItemOnClick?: boolean,
  /** Wheter to wait for all attachment to be uploaded before notifying */
  waitForAllToUpload?: boolean
};

const FileUploader = ({
  acceptFiles,
  alwaysReplace,
  categories,
  category,
  className,
  defaultEntityType,
  deleteEndpoint,
  disabled,
  endpoint,
  errorText,
  fileExplorerSections,
  fileNameField,
  files: previousFiles,
  loading,
  maxFileSize,
  maxNumberOfFiles = MaxNumberOfAttachments,
  mode,
  modeExtraProps,
  multiple,
  name: fieldName,
  onAttachmentsChanged,
  onFileUploadedSuccesfully,
  onFileUploadStarted,
  selectorClassName,
  shouldFetchItems,
  showAlert,
  showConfirm,
  showFile,
  showFileExplorer,
  showIconButtons,
  showItemDate,
  showItemMenu,
  softDeleteForExistingItems,
  shouldAppendCategory,
  text,
  viewItemOnClick,
  waitForAllToUpload
}: FileUploaderProps) => {
  const fileRef = useRef(null);
  const [attachments, setAttachments] = useStateWithCallback<Attachment[]>(previousFiles || []);
  const classes = useStyles({
    multiple,
    hasFiles: attachments.length > 0
  });
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      setIsFetching(true);
      try {
        const response = await API.get(endpoint);
        response.status === 200 && setAttachments(response.data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
      setIsFetching(false);
    };

    if (shouldFetchItems && endpoint) loadData();
  }, [shouldFetchItems, endpoint, setAttachments]);

  const canUploadMoreFiles = files => {
    const filesWithoutError = attachments.filter(({ hasError }: Attachment) => !hasError).length;
    if (filesWithoutError + files.length > maxNumberOfFiles && !alwaysReplace) {
      showAlert({
        severity: 'warning',
        title: 'Attachments',
        body: `Only ${maxNumberOfFiles} ${
          maxNumberOfFiles > 1 ? 'files are allowed' : 'file is allowed'
        }`
      });
      return false;
    }

    const totalFileSize = sumBy(attachments, 'size');
    if (maxFileSize && totalFileSize > maxFileSize) {
      showAlert({
        severity: 'warning',
        title: 'Attachments',
        body: `You exceeded the max file's size of ${formatBytes(maxFileSize)}`
      });
      return false;
    }
    return true;
  };

  const handleFileUpload = async e => {
    const { files } = e.target;

    if (!canUploadMoreFiles(files)) {
      e.target.value = '';
      return;
    }

    const filesArray = Array.from(files);

    const duplicateFile = filesArray.find(file =>
      attachments.map(eachFile => eachFile[fileNameField]).includes(file.name)
    );
    if (duplicateFile) {
      showAlert({
        severity: 'warning',
        title: 'Attachments',
        body: `You've already selected ${duplicateFile.name}`
      });
      e.target.value = '';
      return;
    }

    if (files.length > 0) {
      const results = await Promise.all(
        filesArray.map(async file => {
          const result = await uploadFile(file);
          return result;
        })
      );

      multiple &&
        waitForAllToUpload &&
        onAttachmentsChanged &&
        onAttachmentsChanged(
          results.filter(({ hasError }) => !hasError),
          fieldName
        );
    }
    fileRef?.current && (fileRef.current.value = '');
  };

  const uploadFile = async file => {
    const data = new FormData();
    data.append('file', file);

    const fileInfo = {
      [fileNameField]: file.name,
      isLoading: true,
      type: FileStatus.New,
      size: file.size
    };

    if (shouldAppendCategory && category?.id) {
      data.append('file_type_id', category?.id);
    }

    setAttachments(prevState => (alwaysReplace ? [fileInfo] : [...prevState, fileInfo]));

    onFileUploadStarted && onFileUploadStarted(file, fieldName);
    let fileResult = null;
    try {
      const uploadResponse = await API.post(endpoint, data);

      fileResult = processUploadResponse(
        uploadResponse.data
          ? {
              ...uploadResponse.data,
              type: FileStatus.New,
              file_type_id: category?.id || uploadResponse.data.file_type_id,
              entity: defaultEntityType,
              size: file.size
            }
          : { ...fileInfo, hasError: true }
      );
      !multiple &&
        !waitForAllToUpload &&
        onFileUploadedSuccesfully &&
        uploadResponse?.data &&
        onFileUploadedSuccesfully(uploadResponse?.data);
    } catch (error) {
      showAlert({
        severity: 'error',
        title: 'Attachments',
        body: getErrorMessage(error)
      });
      fileResult = processUploadResponse({
        ...fileInfo,
        message: getErrorMessage(error),
        hasError: true
      });
    }
    return fileResult;
  };

  const processUploadResponse = (newFile: Attachment) => {
    const finalFile = { ...newFile, isLoading: false };
    setAttachments(
      prevState =>
        prevState.map((att: Attachment) => {
          return att[fileNameField] === newFile[fileNameField] ||
            att[fileNameField] === newFile.original_name
            ? finalFile
            : att;
        }),
      newState =>
        !newFile.hasError &&
        !waitForAllToUpload &&
        onAttachmentsChanged &&
        onAttachmentsChanged(
          newState.filter(({ hasError }) => !hasError),
          fieldName
        )
    );
    return finalFile;
  };

  const handleDeleteAttachment = async (attachment: Attachment) => {
    if (!attachment) {
      return;
    }
    const fileName = attachment[fileNameField] || attachment.original_name || attachment.file_name;

    if (attachment.hasError) {
      deleteFileWithError(fileName);
      return;
    }

    showConfirm({
      severity: 'warning',
      title: 'Please confirm',
      message: `Are you sure you want to delete '${trimFileNameWithExtension(
        fileName,
        MAX_FILE_NAME_LENGTH
      )}'?`,
      ...keepRemoveButtonsCopies,
      onConfirm: async ok => {
        try {
          if (!ok) return;
          if (
            (softDeleteForExistingItems && attachment.type !== FileStatus.New) ||
            attachment.type === FileStatus.Existing
          ) {
            deleteFileSoftly(fileName);
            return;
          }

          if (attachment.type === FileStatus.New || !softDeleteForExistingItems) {
            await deleteRemoteFile(attachment);
          } else {
            deleteFileSoftly(fileName);
          }
        } catch (err) {
          showAlert({
            severity: 'error',
            title: 'Attachments',
            body: getErrorMessage(err)
          });
        }
      }
    });
  };

  const deleteFileWithError = fileName =>
    setAttachments(prevState => prevState.filter(item => item[fileNameField] !== fileName));

  const deleteFileSoftly = fileName =>
    setAttachments(
      prevState => prevState.filter(item => item[fileNameField] !== fileName),
      newAttachments => onAttachmentsChanged && onAttachmentsChanged(newAttachments, fieldName)
    );

  const deleteRemoteFile = async (file: Attachment) => {
    try {
      if (!file.id) return;

      updateAttachmentLoadingStatus('id', file, true);
      const finalEndpoint = deleteEndpoint || endpoint;
      const response = await API.delete(`${finalEndpoint}/${file.id}`);
      if (response.status === 200) {
        showAlert({
          severity: 'success',
          title: 'Attachments',
          body: 'The file was deleted successfully'
        });

        const newAttachments = attachments
          .filter(item => item.id !== file.id)
          .filter(({ hasError }) => !hasError);
        setAttachments(
          newAttachments,
          () => onAttachmentsChanged && onAttachmentsChanged(newAttachments, fieldName)
        );
      }
    } catch (err) {
      updateAttachmentLoadingStatus('id', file, false);
      showAlert({
        severity: 'error',
        title: 'Attachments',
        body: getErrorMessage(err)
      });
    }
  };

  const handleFilesSelect = selectedFiles => {
    const filesWithCategory = selectedFiles.map(eachFile => ({
      ...eachFile,
      file_type_id: category.id
    }));
    setAttachments(
      prevState => [...prevState, ...filesWithCategory],
      newState =>
        onAttachmentsChanged &&
        onAttachmentsChanged(
          newState.filter(({ hasError }) => !hasError),
          fieldName
        )
    );
  };

  const selectOrUploadFile = () => {
    if (disabled || !canUploadMoreFiles(1)) return;

    if (canUseFileExplorer) {
      showFileExplorer({
        category,
        defaultEntityType,
        endpoint,
        multiple,
        onSelect: handleFilesSelect,
        sections: fileExplorerSections,
        selectedFiles: attachments,
        shouldAppendCategory,
        title: `${category?.title} selection`
      });
    } else {
      fileRef.current.click();
    }
  };

  const handleInputClick = () => {
    selectOrUploadFile();
  };

  const handleUploadClick = async e => {
    e.stopPropagation();
    selectOrUploadFile();
  };

  const handleChipClick = chipFile => e => {
    e.stopPropagation();
    previewFile(chipFile);
  };

  const handleChipDeleteClick = chipFile => async e => {
    e.stopPropagation();
    await handleDeleteAttachment(chipFile);
  };

  const previewFile = fileToShow => {
    fileToShow?.url &&
      showFile({
        url: fileToShow.url,
        explicitFileName:
          fileToShow?.original_name || fileToShow?.file_name || fileToShow[fileNameField],
        useProxy: true
      });
  };

  const updateAttachmentLoadingStatus = (
    nameProp: string,
    attachment: Attachment,
    isLoading: boolean
  ) => {
    setAttachments(prevState =>
      prevState.map((att: Attachment) =>
        att[nameProp] !== attachment[nameProp] ? att : { ...attachment, isLoading }
      )
    );
  };

  const canUseFileExplorer =
    fileExplorerSections &&
    fileExplorerSections.length > 0 &&
    fileExplorerSections.some(section => section.files?.length > 0);

  const isFirstAttachmentLoading = attachments.some(file => file.isLoading);
  const iconColor = !disabled ? colors.oxford : colors.lightgray;
  const isButtonDisabled = isFirstAttachmentLoading || disabled;

  const ComponentsMap = {
    [FileUploaderMode.Chips]: (
      <div className={classes.fileUploader}>
        <FileSelectorButton onFileChange={handleFileUpload} multiple={multiple}>
          <FPActionButton
            component="span"
            text="Upload File"
            icon={SvgUploadButton}
            iconMode="streamline"
            iconPosition="left"
          />
        </FileSelectorButton>
        {attachments.map((attachment: Attachment) => (
          <div key={attachment[fileNameField]}>
            <FileChip
              file={attachment}
              fileName={attachment[fileNameField]}
              loading={!!attachment.isLoading}
              error={!!attachment.hasError}
              message={attachment.message}
              onFileDelete={handleDeleteAttachment}
            />
          </div>
        ))}
      </div>
    ),
    [FileUploaderMode.ChipsForm]: (
      <div className={classes.wrapperFPChipsForm}>
        {loading || isFetching ? (
          <CircularProgress color="inherit" className={classes.leftAuto} size={25} />
        ) : (
          nestTernary(
            disabled,
            <div className={classes.boxesFpChips}>
              <p className={classes.marginZero}>You can not upload files</p>
            </div>,
            <>
              <div className={classes.boxesFpChips}>
                {attachments.length > 0 ? (
                  attachments.map((attachment: Attachment) => (
                    <FileChip
                      key={attachment[fileNameField]}
                      file={attachment}
                      fileName={attachment[fileNameField]}
                      loading={!!attachment.isLoading}
                      error={!!attachment.hasError}
                      message={attachment.message}
                      onFileDelete={handleDeleteAttachment}
                    />
                  ))
                ) : (
                  <p className={classes.marginZero}>Attachments</p>
                )}
              </div>
              <div className={classes.boxBtnUpload}>
                <FileSelectorButton onFileChange={handleFileUpload} multiple={multiple}>
                  <FPIconButton
                    backgroundColor="transparent"
                    className={classes.btnChipUpload}
                    component="span"
                    text={text}
                    iconPosition="left"
                    icon={SvgUploadButton}
                    iconProps={{ color: colors.success }}
                  />
                </FileSelectorButton>
              </div>
            </>
          )
        )}
      </div>
    ),
    [FileUploaderMode.List]: (
      <>
        {loading || isFetching ? (
          <div style={styles.emptyStateContainer}>
            <EmptyPlaceholder title="Loading content." subtitle="Please hang on." />
            <CircularProgress />
          </div>
        ) : (
          <>
            {attachments.length === 0 ? (
              <EmptyPlaceholder
                title="No files uploaded yet"
                subtitle="To upload a file, click on the button below me."
                customEmptyState={<EmptyFiles width="20vh" style={styles.emptyImage} />}
              >
                <FileSelectorButton onFileChange={handleFileUpload} multiple={multiple}>
                  <FPActionButton
                    component="span"
                    text="Upload File"
                    icon={SvgUploadButton}
                    iconPosition="left"
                  />
                </FileSelectorButton>
              </EmptyPlaceholder>
            ) : (
              <Box className={classes.fileListContainer}>
                <List>
                  {attachments.map(attachment => (
                    <FileItem
                      key={attachment[fileNameField]}
                      file={attachment}
                      fileName={attachment[fileNameField]}
                      loading={!!attachment.isLoading}
                      error={!!attachment.hasError}
                      message={attachment.message}
                      onFileDelete={handleDeleteAttachment}
                      showMenu={showItemMenu}
                      showIconButtons={showIconButtons}
                      showDate={showItemDate}
                      viewOnClick={viewItemOnClick}
                    />
                  ))}
                </List>
                <div style={globalStyles.floatingButtonContainer}>
                  <FileSelectorButton onFileChange={handleFileUpload} multiple={multiple}>
                    <Tooltip title="UPLOAD FILES" placement="left">
                      <Fab
                        size="medium"
                        component="span"
                        color="primary"
                        variant="round"
                        aria-label="add"
                      >
                        <FPIcon component={SvgUploadButton} color={colors.white} size={24} />
                      </Fab>
                    </Tooltip>
                  </FileSelectorButton>
                </div>
              </Box>
            )}
          </>
        )}
      </>
    ),
    [FileUploaderMode.Button]: (
      <FileSelectorButton
        onFileChange={handleFileUpload}
        className={selectorClassName}
        disabled={isButtonDisabled}
        multiple={multiple}
      >
        <SaveButton
          isSaving={isFirstAttachmentLoading}
          isSuccess={false}
          disabled={isButtonDisabled}
          initialText={text}
          onProgressText="Uploading ..."
          onSuccessText="Done"
          type="button"
          component="span"
          fullWidth
        />
      </FileSelectorButton>
    ),
    [FileUploaderMode.ListReadonly]:
      attachments.length === 0 ? (
        <Typography variant="subtitle2">No files uploaded</Typography>
      ) : (
        <List>
          {attachments.map(attachment => (
            <FileItem
              key={attachment[fileNameField]}
              disableGutters
              error={!!attachment.hasError}
              file={attachment}
              fileName={attachment[fileNameField]}
              loading={!!attachment.isLoading}
              maxFileNameLength={MAX_FILE_NAME_LENGTH}
              message={attachment.message}
              readOnly={modeExtraProps?.readOnly}
              showMenu={showItemMenu}
              showIconButtons={showIconButtons}
              showDate={showItemDate}
              viewOnClick={false}
              onFileDelete={handleDeleteAttachment}
              {...modeExtraProps}
            />
          ))}
        </List>
      ),
    [FileUploaderMode.CategoryField]: (
      <>
        <TextBox
          name={fieldName}
          label={`${category?.title} ${category?.required ? '*' : ''}`}
          placeholder={attachments.length === 0 && !disabled ? 'Select a file' : null}
          labelAsPlaceholder={false}
          error={!!errorText}
          errorText={errorText}
          onClick={handleInputClick}
          className={clsx(classes.categoryField, className)}
          aria-label={`${category?.title} File Selector`}
          InputProps={{
            readOnly: true,
            startAdornment: attachments.map(eachFile => (
              <Chip
                key={eachFile.id || eachFile.file_name}
                label={trimFileNameWithExtension(eachFile.file_name, MAX_FILE_NAME_LENGTH)}
                onDelete={!disabled ? handleChipDeleteClick(eachFile) : undefined}
                onClick={handleChipClick(eachFile)}
                disabled={eachFile.isLoading}
                deleteIcon={
                  eachFile.isLoading ? <CircularProgress color="inherit" size={16} /> : undefined
                }
                className={eachFile.hasError ? classes.chipError : null}
              />
            )),
            endAdornment: !disabled && (
              <div className="MuiAutocomplete-endAdornment">
                <FPIconButton
                  onClick={handleUploadClick}
                  icon={SvgUploadButton}
                  iconProps={{ color: iconColor }}
                  disabled={disabled}
                  tooltipProps={{ title: 'Select a file' }}
                />
              </div>
            )
          }}
          disabled={disabled}
        />
        <input
          ref={fileRef}
          type="file"
          accept={acceptFiles.join(',')}
          hidden
          multiple={multiple}
          onChange={handleFileUpload}
        />
      </>
    ),
    [FileUploaderMode.ListSelectable]: (
      <FileSelector
        maxNumberOfFiles={maxNumberOfFiles}
        maxFileSize={maxFileSize}
        endpoint={endpoint}
        name={fieldName}
        disabled={disabled}
        categories={categories}
        files={attachments}
        onFileDelete={handleDeleteAttachment}
        modeExtraProps={modeExtraProps}
      >
        <FileSelectorButton
          onFileChange={handleFileUpload}
          className={selectorClassName}
          disabled={isButtonDisabled}
          multiple={multiple}
        >
          <SaveButton
            isSaving={isFirstAttachmentLoading}
            isSuccess={false}
            disabled={isButtonDisabled}
            initialText={text}
            onProgressText="Uploading ..."
            onSuccessText="Done"
            type="button"
            component="span"
            fullWidth
          />
        </FileSelectorButton>
      </FileSelector>
    )
  };

  return ComponentsMap[mode] || null;
};

FileUploader.defaultProps = {
  acceptFiles: [
    'image/png',
    'image/jpg',
    'image/jpeg',
    '.pdf',
    '.docx',
    '.doc',
    '.pptx',
    '.ppt',
    '.xlsx',
    '.xls',
    '.csv',
    '.zip'
  ],
  alwaysReplace: false,
  shouldAppendCategory: false,
  className: null,
  deleteEndpoint: null,
  disabled: false,
  errorText: null,
  fileNameField: 'file_name',
  files: [],
  fileExplorerSections: [],
  shouldFetchItems: false,
  loading: false,
  mode: 'chips',
  maxNumberOfFiles: undefined,
  multiple: false,
  onAttachmentsChanged: undefined,
  selectorClassName: null,
  showIconButtons: false,
  showItemDate: false,
  showItemMenu: false,
  softDeleteForExistingItems: false,
  viewItemOnClick: false,
  waitForAllToUpload: false
};

const mapDispatchToProps = dispatch => {
  return {
    showAlert: alert => dispatch(showAlertAction(alert)),
    showConfirm: confirmation => dispatch(confirmAction(confirmation)),
    showFile: pdf => dispatch(showFileAction(pdf)),
    showFileExplorer: options => dispatch(showFileExplorerAction(options))
  };
};

const FileUploaderConnected = connect(null, mapDispatchToProps)(FileUploader);

export default FileUploaderConnected;
