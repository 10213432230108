import React from 'react';
import Typography from '@material-ui/core/Typography';
import FPIcon from 'UI/components/atoms/FPIcon';
import { SvgInformationCircle } from 'UI/res';

import { useStyles } from './styles';

export const IconLabel = ({ icon, label }) => {
  const styles = useStyles();

  return (
    <div className={styles.labelContainer}>
      <FPIcon
        role="img"
        aria-label="Info"
        component={icon ?? SvgInformationCircle}
        onSetColor={palette => palette.info.main}
        size={16}
      />
      <Typography className={styles.label} variant="body2">
        {label}
      </Typography>
    </div>
  );
};
