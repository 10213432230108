import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'UI/res';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%',
      display: 'flex'
    },
    textContainer: {
      paddingRight: theme.spacing(4)
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '1.3em',
      height: 'auto',
      textTransform: 'capitalize'
    },
    body: {
      fontWeight: theme.typography.fontWeightLight,
      lineHeight: '1.3em',
      height: 'auto'
    },
    indicator: {
      width: 8,
      height: 85,
      borderRadius: '4px 0px 0px 4px',
      position: 'absolute',
      color: ({ color }) => color
    },
    closeButton: {
      position: 'absolute',
      right: 5,
      top: 5
    },
    buttonContainer: {
      padding: 0
    }
  }),
  { classNamePrefix: 'snackBar' }
);

const snackbarRoot = {
  width: 500,
  ...flexAlignCenterJustifyCenter,
  borderRadius: '4px',
  margin: 0,
  fontSize: 16
};

export const useAlertStyles = makeStyles(theme => ({
  root: {
    ...snackbarRoot,
    padding: theme.spacing(2)
  },
  message: {
    flex: 1,
    padding: 0
  },
  icon: {
    ...flexAlignCenterJustifyCenter,
    alignSelf: 'baseline'
  }
}));

export const useNotificationStyles = makeStyles(
  theme => ({
    root: {
      ...snackbarRoot,
      padding: theme.spacing(2)
    },
    filledWarning: {
      backgroundColor: colors.white,
      color: colors.black
    },
    filledSuccess: {
      backgroundColor: colors.white,
      color: colors.black
    },
    message: {
      flex: 1
    },
    icon: {
      ...flexAlignCenterJustifyCenter
    }
  }),
  { classNamePrefix: 'notificationStyles' }
);
