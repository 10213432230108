// @flow
import React from 'react';
import { FPCard, FPCardContent } from 'UI/components/atoms/FPCard';

import { useStyles } from './styles';

type InventoryOverviewLayoutProps = {
  loading: boolean,
  items: Array<Object>
};

const InventoryOverviewLayout = ({ loading, items }: InventoryOverviewLayoutProps) => {
  const classes = useStyles();

  return items.map(({ cardProps = null, Component, componentProps, ...rest }) =>
    !cardProps ? (
      <Component loading={loading} key={items.indexOf} {...componentProps} />
    ) : (
      <FPCard
        key={cardProps.headerProps.title}
        title={cardProps.headerProps.title}
        className={classes.profileCardContainer}
        {...rest}
      >
        <FPCardContent {...cardProps}>
          <Component loading={loading} {...componentProps} />
        </FPCardContent>
      </FPCard>
    )
  );
};

InventoryOverviewLayout.defaultProps = {
  items: [],
  loading: false
};

export default InventoryOverviewLayout;
