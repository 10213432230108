// @flow
import React from 'react';
import { CardActionArea } from '@material-ui/core';
import { MicrosoftLoginButtonIcon } from 'UI/res';

import { styles } from './styles';

type LoginButtonProps = {
  tenantUrl: string,
  clientId: string,
  responseType: string,
  redirectUri: string,
  scopes: string
};

const LoginButton = (props: LoginButtonProps) => {
  const { tenantUrl, clientId, responseType, redirectUri, scopes } = props;
  const loginUrl = `${tenantUrl}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&response_mode=query&scope=${scopes}`;

  return (
    <CardActionArea style={styles.buttonContainer}>
      <a href={loginUrl}>
        <MicrosoftLoginButtonIcon />
      </a>
    </CardActionArea>
  );
};

export default LoginButton;
