import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {
  CallsGauge,
  Drawers,
  HealthDistribution,
  InMailsGauge,
  LayoutSkeleton,
  OverallPerformance,
  PeopleReachedGauge,
  PhoneTimeGauge
} from 'features/command-center';
import { DrawerManagerProvider } from 'features/command-center/contexts';
import { useFetchWithStatusV2 } from 'hooks/fetchWithStatus';
import usePageTitle from 'hooks/usePageTitle';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import strings from 'strings';
import { PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { UIStatus } from 'UI/constants/status';

const COPIES = strings.commandCenter.activity;

export const Overview = () => {
  usePageTitle({ title: PageTitles.CommandCenterActivity });
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();
  const { state: weeksCatalogState } = useFetchWithStatusV2({
    endpoint: Endpoints.ToolsUsageReportWeeks
  });

  if (weeksCatalogState.status === UIStatus.Loading) {
    return <LayoutSkeleton />;
  }

  const weeks = weeksCatalogState?.results;
  const canDoDrillDown = checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterDrillDown);

  return (
    <DrawerManagerProvider>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <HealthDistribution
            hasDrillDown={canDoDrillDown}
            requestParams={canDoDrillDown && { version: 1 }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <PeopleReachedGauge
            weeks={weeks}
            footerLabel={COPIES.peopleReachedGauge.chart.footerLabel}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InMailsGauge weeks={weeks} footerLabel={COPIES.inMailsGauge.chart.footerLabel} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CallsGauge weeks={weeks} footerLabel={COPIES.callsGauge.chart.footerLabel} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <PhoneTimeGauge weeks={weeks} footerLabel={COPIES.phoneTimeGauge.chart.footerLabel} />
        </Grid>

        <Divider />

        <Grid item xs={12}>
          <OverallPerformance />
        </Grid>
      </Grid>
      <Drawers />
    </DrawerManagerProvider>
  );
};
