import { colors } from 'UI/res';

export const styles = {
  container: {
    height: '100%'
  },
  TabContent: {
    padding: 0,
    backgroundColor: colors.sideBar
  },
  TabInventory: {
    height: 'calc(100vh - 138px)' /** TODO Check layout */,
    backgroundColor: colors.sideBar
  }
};
