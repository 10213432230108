// @flow
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { confirm as confirmAction, showAlert, showFile as showFileAction } from 'actions/app';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { getCurrentUser } from 'services/Authentication';
import { FeeAgreementRoleHierarchy, getHighestUserRoleInHierarchy } from 'services/Authorization';
import { getJobOrdersByCompanyId } from 'services/Company';
import {
  createManagedServiceAgreement,
  createUnmanagedServiceAgreement,
  useFetchTemplates
} from 'services/FeeAgreement';
import strings from 'strings';
import type { DisplayedFile, DrawerUiState } from 'types/app';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { FeeAgreementDefaultValues } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { FeeAgreementProcess, FeeAgreementProcesses, PaymentMode } from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { FeeAgreementFields } from 'UI/constants/status';
import { EmptyJobOrders, SvgAdd } from 'UI/res';
import { nestTernary } from 'UI/utils/index';

import { getPaymentPeriodsFromForm } from '../FeeAgreementPaymentTerms';

import ManagedFeeAgreement from './managed';
import { styles } from './style';
import UnmanagedFeeAgreement from './unmanaged';

type FeeAgreementCreationFormProps = {
  onFAClosed: () => void,
  onFACompleted: any => any,
  onNewJOClick: () => void,
  hiringAuthorities: Array<Object>,
  onShowAlert: any => void,
  showConfirm: any => void,
  endpoint: string,
  company: Object,
  feeAgreement: any,
  showFile: DisplayedFile => void
};

const FeeAgreementCreationForm = (props: FeeAgreementCreationFormProps) => {
  const {
    onFAClosed,
    onFACompleted,
    onNewJOClick,
    hiringAuthorities,
    onShowAlert,
    showConfirm,
    showFile,
    endpoint,
    company
  } = props;
  const [uiState, setUiState] = useState<DrawerUiState>({
    isLoading: false,
    isSaving: false,
    isSuccess: false,
    isFormDisabled: false,
    isReadOnly: false,
    processType: FeeAgreementProcess.Fortpac
  });
  const currentUser = getCurrentUser();
  const highestRole = getHighestUserRoleInHierarchy(currentUser, FeeAgreementRoleHierarchy);
  const templates = useFetchTemplates();

  const defaultValues = {
    fee_agreement_payment_scheme_id: PaymentMode.Standard,
    fee_percentage: FeeAgreementDefaultValues.FeePercent,
    guarantee_days: FeeAgreementDefaultValues.GuaranteeDays,
    flat_fee_amount: null,
    notes: '',
    verbiage_changes: '',
    verbiage_changes_requested: false,
    fee_percentage_change_requested: false,
    guarantee_days_change_requested: false,
    cc_emails: [],
    job_orders: [],
    signed_date: null,
    split_payment_requested: false,
    number_of_payments: null,
    company_legal_name: company?.legal_name
  };
  const { id: companyId } = company;

  const [jobOrders, setJobOrders] = useState([]);
  const form = useForm({ defaultValues });
  const { handleSubmit, watch, reset } = form;

  useEffect(() => {
    const loadJobOrders = async () => {
      setUiState(prevState => ({ ...prevState, isLoading: true }));
      const result = await getJobOrdersByCompanyId(companyId);
      result.alert && onShowAlert(result.alert);
      if (result.data) {
        const jos = reverse(sortBy(result.data, jo => jo?.whiteSheet?.company_fee_agreement_id));
        setJobOrders(jos);
      }
      setUiState(prevState => ({ ...prevState, isLoading: false }));
    };
    loadJobOrders();
  }, [companyId, onShowAlert]);

  const formValues = watch();
  const { fee_agreement_payment_scheme_id: paymentMode } = formValues;
  const processType = uiState.processType || FeeAgreementProcess.Fortpac;

  const feePercentage = Number.parseFloat(formValues.fee_percentage);

  const handleRadioChange = ({ target }) => {
    reset(defaultValues);
    setUiState(prevState => ({ ...prevState, processType: target.value }));
  };
  const performAsyncMethod = async (method, formData = null) => {
    setUiState(prevState => ({ ...prevState, isSaving: true }));

    const result = await method(companyId, formData);

    setUiState(prevState => ({ ...prevState, isSaving: false }));
    result.alert?.body && onShowAlert(result.alert);
    result.success && onFACompleted && result.data && onFACompleted(result.data);

    return result;
  };

  const isUserOps = highestRole?.id === Roles.Operations;
  const requiresLaterTemplateCreation = isUserOps && formValues.verbiage_changes_requested;

  const requiresApproval =
    !isUserOps &&
    (formValues.verbiage_changes_requested ||
      formValues.guarantee_days_change_requested ||
      formValues.split_payment_requested ||
      paymentMode === PaymentMode.Flat ||
      (paymentMode === PaymentMode.BaseSalary && highestRole?.id === Roles.Recruiter) ||
      (formValues.fee_percentage_change_requested &&
        feePercentage <= FeeAgreementDefaultValues.FeePercent) ||
      processType === FeeAgreementProcess.External);
  const primaryButtonText = requiresApproval
    ? strings.feeAgreements.creation.requestApproval
    : nestTernary(
        processType === FeeAgreementProcess.Fortpac,
        nestTernary(
          !requiresLaterTemplateCreation,
          strings.feeAgreements.creation.sendToSign,
          strings.shared.ui.save
        ),
        strings.shared.ui.save
      );

  async function onSubmit(formData) {
    if (!endpoint) return;

    if (processType === FeeAgreementProcess.Fortpac) {
      const splitPaymentRequest = formData.split_payment_requested;

      const overwrites =
        paymentMode === PaymentMode.Flat
          ? {
              fee_percentage_change_requested: false,
              fee_percentage: FeeAgreementDefaultValues.FeePercent
            }
          : nestTernary(
              paymentMode === PaymentMode.Conversion,
              { guarantee_days_change_requested: false, flat_fee_amount: null },
              { flat_fee_amount: null }
            );
      if (splitPaymentRequest) {
        overwrites.guarantee_days_change_requested = false;
        overwrites.guarantee_days = 0;
      }

      const finalFeeAgreement = {
        ...formData,
        ...overwrites,
        hiringAuthority: null,
        hiring_authority_email: formData.hiringAuthority.work_email,
        cc_emails: formData.cc_emails.map(each => each.work_email),
        job_orders: formData.job_orders.map(each => each.id),
        overridden_user_id: formData.overridden_user_id?.id,
        [FeeAgreementFields.PaymentDays]: getPaymentPeriodsFromForm(formData)
      };
      showConfirm({
        severity: 'warning',
        title: strings.feeAgreements.creation.creationTitle,
        message: requiresApproval
          ? strings.feeAgreements.creation.confirmApproval
          : nestTernary(
              requiresLaterTemplateCreation,
              strings.feeAgreements.creation.confirmCreationWhenVerbiage,
              strings.formatString(strings.feeAgreements.creation.confirmSendToSign, {
                hiringAuthority: formData?.hiringAuthority?.full_name
              })
            ),
        confirmButtonText: requiresApproval
          ? strings.feeAgreements.creation.requestApproval
          : nestTernary(
              requiresLaterTemplateCreation,
              strings.shared.ui.save,
              strings.feeAgreements.creation.sendToSign
            ),
        cancelButtonText: strings.shared.ui.cancel,
        onConfirm: async ok => {
          if (!ok) {
            return;
          }
          await performAsyncMethod(createManagedServiceAgreement, finalFeeAgreement);
        }
      });
    } else {
      const finalOverwrites =
        paymentMode === PaymentMode.Flat
          ? { fee_percentage: null }
          : nestTernary(
              paymentMode === PaymentMode.Conversion,
              { guarantee_days: 0, flat_fee_amount: null },
              { flat_fee_amount: null }
            );
      const finalFeeAgreement = {
        ...formData,
        ...finalOverwrites,
        fee_agreement_file: formData.fee_agreement_file
          ? {
              id: formData.fee_agreement_file.id,
              entity: formData.fee_agreement_file.entity
            }
          : null,
        overridden_user_id: formData.overridden_user_id?.id
      };
      showConfirm({
        severity: 'warning',
        title: strings.shared.ui.confirm,
        message: requiresApproval
          ? strings.feeAgreements.creation.confirmOpsApproval
          : strings.feeAgreements.creation.confirmCreation,
        confirmButtonText: requiresApproval
          ? strings.feeAgreements.creation.sendToOps
          : strings.shared.ui.save,
        cancelButtonText: strings.shared.ui.cancel,
        onConfirm: async ok => {
          if (!ok) {
            return;
          }
          await performAsyncMethod(createUnmanagedServiceAgreement, finalFeeAgreement);
        }
      });
    }
    setUiState(prevState => ({
      ...prevState,
      isSuccess: false,
      isSaving: false
    }));
  }

  const moreActions = [
    {
      title: strings.feeAgreements.creation.viewTemplate,
      action: () => {
        const templateKey =
          paymentMode !== PaymentMode.Flat
            ? paymentMode
            : nestTernary(
                formValues.guarantee_days,
                'flat_with_guarantee',
                'flat_without_guarantee'
              );
        const templateId = templates[templateKey];
        templateId &&
          showFile({
            url: `${Endpoints.FeeAgreement}/${Endpoints.FeeAgreementTemplates}/${templateId}`,
            explicitFileName: `template_${templateKey}.pdf`
          });
      },
      visible: !!templates && processType === FeeAgreementProcess.Fortpac
    }
  ];

  const jobOrdersExist = !uiState.isLoading && jobOrders.length > 0;
  const jobOrdersReadyToSign =
    jobOrdersExist &&
    jobOrders.some(
      jo =>
        jo.whiteSheet?.company_prepared_to_sign_service_agreement ||
        jo.whiteSheet?.discussing_agreement_complete
    );

  const hiringAuthoritiesExists = hiringAuthorities?.length > 0;

  return (
    <DrawerContentLayout
      onSubmit={handleSubmit(onSubmit)}
      initialText={primaryButtonText}
      onClose={onFAClosed}
      variant="borderless"
      uiState={uiState}
      title={strings.feeAgreements.create}
      isTopToolbarNeeded
      isBottomToolbarNeeded={jobOrdersReadyToSign || processType !== FeeAgreementProcess.Fortpac}
      onSecondaryButtonClick={onFAClosed}
      moreActions={moreActions}
    >
      {uiState.isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <CircularProgress color="inherit" size={40} />
        </Box>
      ) : (
        <>
          {isUserOps && (
            <FPRadioGroup
              name="process_type"
              label={strings.feeAgreements.creation.chooseProcess}
              labelVariant="subtitle1"
              options={FeeAgreementProcesses}
              value={processType}
              onChange={handleRadioChange}
            />
          )}

          <FormContext {...form}>
            {processType === FeeAgreementProcess.Fortpac ? (
              <>
                {jobOrdersReadyToSign ? (
                  <>
                    {hiringAuthoritiesExists ? (
                      <ManagedFeeAgreement
                        hiringAuthorities={hiringAuthorities}
                        jobOrders={jobOrders}
                        company={company}
                        defaultValues={defaultValues}
                      />
                    ) : (
                      <EmptyPlaceholder
                        subtitle={strings.feeAgreements.creation.noHiringAuthorities}
                        height="100%"
                        customEmptyState={<EmptyJobOrders style={styles.emptyStateImage} />}
                      />
                    )}
                  </>
                ) : (
                  <EmptyPlaceholder
                    subtitle={strings.feeAgreements.creation.blankState}
                    height="100%"
                    customEmptyState={<EmptyJobOrders style={styles.emptyStateImage} />}
                  >
                    <FPActionButton
                      text={strings.feeAgreements.creation.blankCTA}
                      type="button"
                      onClick={onNewJOClick}
                      icon={SvgAdd}
                      iconProps={{ family: 'mini', size: 'sm' }}
                    />
                  </EmptyPlaceholder>
                )}
              </>
            ) : (
              <UnmanagedFeeAgreement defaultValues={defaultValues} companyFiles={company.files} />
            )}
          </FormContext>
        </>
      )}
    </DrawerContentLayout>
  );
};

FeeAgreementCreationForm.defaultProps = {
  company: undefined,
  feeAgreement: undefined,
  onNewJOClick: () => {}
};

const mapDispatchToProps = dispatch => {
  return {
    onShowAlert: alert => dispatch(showAlert(alert)),
    showConfirm: confirmation => dispatch(confirmAction(confirmation)),
    showFile: file => dispatch(showFileAction(file))
  };
};

export default connect(null, mapDispatchToProps)(FeeAgreementCreationForm);
