import React from 'react';
import Chip from '@material-ui/core/Chip';

import { useStyles } from './HealthStatus.styles';

const HealthStatusChip = ({ label }) => {
  const classes = useStyles();
  const mapStatusClass = {
    'At Risk': classes.risk,
    Behind: classes.behind,
    'In Training': classes.training,
    'On Track': classes.onTrack,
    'Under 3 Months': classes.underThreeMonths
  };
  return <Chip size="small" label={label} className={mapStatusClass[label]} />;
};

export default HealthStatusChip;
