// @flow
import isNil from 'lodash/isNil';
import type { Filters } from 'types/app';
import { nestTernary } from 'UI/utils';

const defaultKey = 'id';
/** This functions transforms a filters object like { industry: { paramName: industryId, value: {id: 1, title: 'An industry'}}}
 *  into another object ready to populate ui components like { industry: { id: 1, title: 'An industry'}} }
 */
export const filtersToUi = (filters: Filters) => {
  const transformedFilters = {};
  if (!filters) return transformedFilters;
  Object.keys(filters).forEach(key => {
    transformedFilters[key] = filters[key]?.value;
  });

  return transformedFilters;
};

/** This functions transforms a filters object like { industry: { paramName: industryId, value: {id: 1, title: 'An industry'}}}
 *  into another object ready to be sent as query parameters like { industryId: 1 }
 */
export const filtersToParams = (filters: Filters) => {
  const transformedFilters = {};
  Object.keys(filters).forEach(key => {
    const { value, idKey, paramName, outboundValue } = filters[key];
    const filterAccessKey = idKey || defaultKey;

    transformedFilters[paramName] = Array.isArray(value)
      ? value.map(each => (!isNil(each[filterAccessKey]) ? each[filterAccessKey] : each))
      : nestTernary(!isNil(value[filterAccessKey]), value[filterAccessKey], outboundValue || value);
  });
  return transformedFilters;
};
