// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import strings from 'strings';
import CustomDatePicker from 'UI/components/atoms/CustomDatePicker';
import { isDatetimeInTimezoneAfter } from 'UI/utils';

import 'moment-timezone';

import { SharedKeyboardTimePickerProps } from '../utils';

type InterviewRangeHourProps = {
  index: number,
  disabled: boolean,
  interviewName: string,
  interviewDateFieldName: string,
  interviewRangeToFieldName: string,
  interviewRangeFromFieldName: string,
  interviewTimezoneFieldName: string,
  withTime: boolean,
  autoOk: boolean
};

const InterviewRangeHour = ({
  index,
  interviewName,
  interviewDateFieldName,
  interviewRangeToFieldName,
  interviewRangeFromFieldName,
  interviewTimezoneFieldName,
  disabled
}: InterviewRangeHourProps) => {
  const form = useFormContext();
  const { errors, setValue, register, unregister, watch, getValues, setError, clearError } = form;
  const watcher = watch([interviewRangeToFieldName, interviewRangeFromFieldName]);
  const isError = target =>
    errors[interviewName] && errors[interviewName]?.[index]?.interview_range?.[target];

  useEffect(() => {
    register({ name: interviewRangeToFieldName }, { required: 'To Hour is required' });
    register({ name: interviewRangeFromFieldName }, { required: 'From Hour is required' });
    return () => unregister([interviewRangeToFieldName, interviewRangeFromFieldName]);
  }, [interviewRangeFromFieldName, register, interviewRangeToFieldName, unregister]);

  const setTimeInputErrors = (isValid, fieldInputName) => {
    if (isValid) return errors.interviews?.[index].hour_fixed && clearError(fieldInputName);

    setValue(fieldInputName, null);
    return setError(
      fieldInputName,
      strings.sendouts.interviews.timeRequired.type,
      strings.sendouts.interviews.timeRequired.message
    );
  };

  const handleTimeChange = (name, time) => {
    const interviewDateValue = getValues(interviewDateFieldName);
    const interviewTimezoneValue = getValues(interviewTimezoneFieldName);

    if (!interviewDateValue || !interviewTimezoneValue) {
      setValue(name, time, true);
      return;
    }

    const isValidDateWithTimezone = isDatetimeInTimezoneAfter(
      interviewTimezoneValue?.name,
      interviewDateValue,
      time
    );
    setTimeInputErrors(isValidDateWithTimezone, name);
    if (isValidDateWithTimezone) setValue(name, time, true);
  };

  const defaultProps = SharedKeyboardTimePickerProps;

  return (
    <>
      <CustomDatePicker
        {...defaultProps}
        autoOk
        label="From Hour *"
        name={interviewRangeFromFieldName}
        disabled={disabled}
        value={watcher[interviewRangeFromFieldName] || null}
        error={!!isError('from')}
        helperText={!!isError('from') && isError('from').message}
        onDateChange={handleTimeChange}
        autoComplete="off"
      />
      <CustomDatePicker
        {...defaultProps}
        autoOk
        label="To Hour *"
        name={interviewRangeToFieldName}
        disabled={disabled}
        value={watcher[interviewRangeToFieldName] || null}
        error={!!isError('to')}
        helperText={!!isError('to') && isError('to').message}
        onDateChange={handleTimeChange}
        autoComplete="off"
      />
    </>
  );
};

InterviewRangeHour.defaultProps = {
  withTime: true,
  autoOk: false
};

export default InterviewRangeHour;
