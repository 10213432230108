// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { EmptyReferenceReleasePlacements } from 'UI/res';

import { useStyles } from '../styles';

const ReferenceReleaseCallToAction = ({ canSend, onSendClick, onContinueClick }) => {
  const classes = useStyles();

  return (
    <>
      <EmptyPlaceholder
        title=""
        subtitle={
          canSend ? (
            strings.placements.referenceRelease.prompt
          ) : (
            <>
              <p>{strings.placements.referenceRelease.helper}</p>
              {strings.placements.referenceRelease.secondaryHelper}
            </>
          )
        }
        customEmptyState={<EmptyReferenceReleasePlacements width={300} height={300} />}
      >
        <>
          {canSend && (
            <p>
              <FPActionButton
                text={strings.placements.referenceRelease.send}
                type="button"
                onClick={onSendClick}
              />
            </p>
          )}
          <Typography>
            {strings.placements.referenceRelease.alreadySent}
            <FPActionButton
              variant="text"
              text={strings.placements.referenceRelease.continue}
              onClick={onContinueClick}
              className={classes.noTextTransform}
            />
          </Typography>
        </>
      </EmptyPlaceholder>
    </>
  );
};

export default ReferenceReleaseCallToAction;
