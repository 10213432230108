import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  pdfBox: {
    height: '100%',
    margin: '0 auto',
    ...flexAlignCenterJustifyCenter /** To allow centering of the spinner */
  },
  iframeStyle: {
    border: 'unset'
  },
  emptyImage: {
    marginTop: theme.spacing(2)
  },
  viewImage: { width: '100%', height: '100%', objectFit: 'contain' }
}));
