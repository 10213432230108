import * as React from 'react';

const SvgFolder = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path
        d="M22 22H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h6.5a2 2 0 0 1 1.79 1.1l.81 1.62a.5.5 0 0 0 .45.28H22a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2ZM8.19 4H2.5a.5.5 0 0 0-.5.5v15a.5.5 0 0 0 .5.5h19a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5H11.24a2 2 0 0 1-1.79-1.11l-.81-1.61A.51.51 0 0 0 8.19 4Z"
        fill={fill}
      />
    </svg>
  );
};

export default SvgFolder;
