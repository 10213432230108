const When = ({ condition, children }) => {
  if (!children) return null;

  const conditionResult = Boolean(typeof condition === 'function' ? condition() : condition);

  if (!conditionResult) return null;

  if (typeof children === 'function') {
    return children();
  }

  return children;
};

export default When;
