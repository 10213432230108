// @flow
import React from 'react';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';

import { Header } from './components';
import { MainLayoutContainer } from './Layout.styled';

interface LayoutProps {
  path: React.ReactNode;
  title: string | React.ReactNode;
  children?: React.ReactNode;
  subtitle?: React.ReactNode;
  extraActions?: React.ReactNode;
  isBackNavigationEnabled?: boolean;
  isLoading?: boolean;
  onRefresh?: () => void;
}

export const Layout: React.FC<LayoutProps> = ({
  path,
  title,
  subtitle,
  extraActions,
  isBackNavigationEnabled = false,
  isLoading = false,
  children,
  onRefresh
}) => {
  return (
    <ContentPageLayout mode="customLayout">
      <MainLayoutContainer>
        <Header
          isBackNavigationEnabled={isBackNavigationEnabled}
          isLoading={isLoading}
          path={path}
          title={title}
          subtitle={subtitle}
          extraActions={extraActions}
          onRefresh={onRefresh}
        />
        {children}
      </MainLayoutContainer>
    </ContentPageLayout>
  );
};
