import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';
import { navBarHeight } from 'UI/constants/dimensions';
import { colors } from 'UI/res';

const BORDER_STYLE = `1px solid ${THEME.palette.customColors.borderColor}`;

export const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: colors.sideBar,
    borderRight: BORDER_STYLE,
    height: `calc(100vh - ${navBarHeight}px)`,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  headerContainer: {
    padding: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  messageContainer: {
    borderTop: BORDER_STYLE
  },
  boldText: {
    fontWeight: 700,
    fontSize: 16,
    marginRight: theme.spacing(1)
  },
  messageText: {
    whiteSpace: 'pre-wrap'
  },
  listContainer: {
    flexGrow: 1,
    overflowY: 'auto',
    paddingBottom: theme.spacing(3)
  }
}));
