// @flow

const sharedOptions = {
  display: true,
  filter: false,
  sort: true
};

export const ColumnsDefinitions = [
  {
    name: 'id',
    options: {
      filter: false,
      sort: false,
      print: false,
      display: 'excluded'
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: sharedOptions
  },
  {
    name: 'email',
    label: 'Email',
    options: sharedOptions
  },
  {
    name: 'full_name',
    label: 'Name',
    options: sharedOptions
  },
  {
    name: 'originDate',
    label: 'Event Date',
    options: {
      ...sharedOptions,
      renderer: 'date'
    }
  },
  {
    name: 'reason',
    label: 'Reason',
    options: sharedOptions
  },
  {
    name: 'event',
    options: {
      display: 'excluded'
    }
  }
];

export const getColumnsDefinitionByStatType = (statKey: string) => {
  if (statKey.includes('fixable')) {
    const columsDef = ColumnsDefinitions.filter(item => item.name !== 'originDate');
    return columsDef;
  }
  return ColumnsDefinitions;
};
