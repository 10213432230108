// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import isEmpty from 'lodash/isEmpty';
import strings from 'strings';
import { StatsCard } from 'UI/components/molecules/cardContents/';
import DailyStats from 'UI/components/molecules/home/DailyStats';
import DailyStatsFooter from 'UI/components/molecules/home/DailyStats/DailyStatsFooter';
import DailyStatsHeaderActions from 'UI/components/molecules/home/DailyStats/DailyStatsHeaderActions';
import FPBanner from 'UI/components/molecules/home/FPBanner';
import ItemInterview from 'UI/components/molecules/home/ItemInterview';
import PhonePerformanceStats from 'UI/components/molecules/home/PhonePerformance/PhonePerformanceStats';
import SearchProjectRow from 'UI/components/molecules/home/SearchProjectRow';
import StatCardsRow from 'UI/components/molecules/home/StatCardsRow';
import WeeklyStats from 'UI/components/molecules/home/WeeklyStats';
import TabsView from 'UI/components/templates/TabsView';
import { componentDimensions } from 'UI/constants/dimensions';
import {
  SvgChatBubblesFilled,
  SvgNoUpdatedSendoutsFilled,
  SvgNoUpdatedSendoversFilled,
  SvgPendingAgreementsFilled,
  SvgSvgEmptyWorkingCharts
} from 'UI/res';
import { WidgetRenderers } from 'UI/utils/renderers/widgetRenderers';

import { useStyles } from './styles';

const { emptyState: EMPTY_STATE_DIMENSIONS } = componentDimensions;

const TotalInterviewTitle = ({ section }) => {
  const classes = useStyles();
  return (
    <div className={classes.containerTotalList}>
      <Typography aria-label="card-value" className={classes.totalListValue}>
        {section.total}
      </Typography>
      <Typography className={classes.totalListTitle}>{section.totalOptions.totalLabel}</Typography>
    </div>
  );
};

export const HomeRenderers = {
  ...WidgetRenderers,
  dailyStats: ({
    totalBulkStats = {},
    totalFeeAgreements = {},
    totalNewCandidates = {},
    totalNewJobOrders = {},
    totalNewPlacements = {},
    totalPhoneStats = {},
    totalSendoutStats = {},
    totalSendoverStats = {},
    totalTeamMembers = {},
    totalPlacements,
    totalFallOffs,
    totalDrawBalance,
    type,
    section
  }) => {
    const {
      sendout_adjusted,
      sendout_goal,
      total_daily_sendouts,
      total_weekly_sendouts,
      total_sendouts
    } = totalSendoutStats;
    const { total_daily_sendovers, total_sendovers, total_weekly_sendovers, total_converted } =
      totalSendoverStats;
    const { total_bulk_people_reached, total_bulks } = totalBulkStats;
    const {
      phone_performance_percentage: phonePerformancePercentage,
      total_call_time,
      total_calls,
      total_external_calls,
      total_texts
    } = totalPhoneStats;
    const { total_sent_agreements, total_signed_agreements, total_weekly_sent_agreements } =
      totalFeeAgreements;
    const { total_new_candidates } = totalNewCandidates;
    const { total_new_job_orders } = totalNewJobOrders;
    const { total_new_placements } = totalNewPlacements;
    const { total_current_members, total_new_members } = totalTeamMembers;

    const totalAgreements = {
      total: total_sent_agreements || 0,
      signed: total_signed_agreements || 0,
      weekToDate: total_weekly_sent_agreements || 0
    };
    const totalBulks = {
      total: total_bulks || 0,
      total_reached: total_bulk_people_reached || 0
    };
    const totalCalls = {
      total: total_external_calls?.value || total_calls || 0,
      totalTime: typeof total_call_time === 'object' ? total_call_time?.value : total_call_time || 0
    };
    const totalSendouts = {
      adjusted: sendout_adjusted || 0,
      total: total_daily_sendouts || total_sendouts || 0,
      weekToDate: total_weekly_sendouts || 0,
      goal: sendout_goal || 0
    };
    const totalSendovers = {
      total: total_daily_sendovers || total_sendovers || 0,
      converted: total_converted || 0,
      weekToDate: total_weekly_sendovers || 0
    };
    const totalTeamInfo = {
      newMembers: total_new_members || 0,
      total: total_current_members || 0
    };

    return (
      <DailyStats
        phonePerformancePercentage={phonePerformancePercentage}
        totalAgreements={totalAgreements}
        totalBulks={totalBulks}
        totalCalls={totalCalls}
        totalNewCandidates={total_new_candidates}
        totalNewJorOrders={total_new_job_orders}
        totalPlacements={total_new_placements || totalPlacements}
        totalSendouts={totalSendouts}
        totalSendovers={totalSendovers}
        totalTeamInfo={totalTeamInfo}
        totalTexts={total_texts}
        totalFallOffs={totalFallOffs}
        totalDrawBalance={totalDrawBalance}
        type={section.variant || type}
      />
    );
  },
  gpacUBanner: ({ section }) => <FPBanner {...section} />,
  itemInterview: ({
    ca_accountable,
    candidate,
    handleClick,
    hiring_authority,
    id,
    index,
    interview,
    jo_accountable,
    section
  }) => (
    <div key={id}>
      {index === 0 && section.showTotal && <TotalInterviewTitle section={section} />}
      <ItemInterview
        interviewData={interview}
        candidate={candidate}
        hiringAuthority={hiring_authority}
        sendoutId={id}
        onSelectItem={handleClick}
        candidateAccountable={ca_accountable}
        jobOrderAccountable={jo_accountable}
        {...section.rendererOptions}
      />
    </div>
  ),
  searchProjectRow: ({
    handleClick,
    id,
    is_private,
    name,
    total_candidates,
    total_hiring_authorities
  }) => (
    <SearchProjectRow
      key={id}
      mainText={name}
      isPrivate={is_private}
      totalCandidates={total_candidates}
      totalHiringAuthorities={total_hiring_authorities}
      searchProjectId={id}
      onSelectItem={handleClick}
    />
  ),
  statsCard: ({ subtitle, text, title, value }) => (
    <StatsCard
      key={title || text}
      title={title}
      subtitle={subtitle || text}
      value={value}
      variant="inline"
    />
  ),
  noActivityTotals: ({
    total_candidates,
    total_candidates_unassigned,
    total_search_assignments,
    total_search_assignments_unassigned
  }) => {
    const noActivityStrings = strings.home.sections.noActivityTotals;
    const statItems = [
      {
        title: noActivityStrings.candidates,
        value: total_candidates || 0
      },
      {
        title: noActivityStrings.searchAssignments,
        value: total_search_assignments || 0
      },
      {
        title: noActivityStrings.candidatesUnassigned,
        value: total_candidates_unassigned || 0
      },
      {
        title: noActivityStrings.searchAssignmentsUnassigned,
        value: total_search_assignments_unassigned || 0
      }
    ];

    return <StatCardsRow statItems={statItems} />;
  },
  noUpdatedTotals: ({
    total_sendouts: totalSendouts,
    total_sendovers: totalSendovers,
    total_agreements: totalAgreements,
    total_daily_interviews: totalDailyInterviews
  }) => {
    const noUpdatedStrings = strings.home.sections.noUpdatedTotals;
    const statItems = [
      {
        Icon: SvgChatBubblesFilled,
        title: noUpdatedStrings.dailyInterviews,
        value: totalDailyInterviews || 0,
        variant: 'icon'
      },
      {
        Icon: SvgNoUpdatedSendoutsFilled,
        title: noUpdatedStrings.sendouts,
        value: totalSendouts || 0,
        variant: 'icon'
      },
      {
        Icon: SvgNoUpdatedSendoversFilled,
        title: noUpdatedStrings.sendovers,
        value: totalSendovers || 0,
        variant: 'icon'
      },
      {
        Icon: SvgPendingAgreementsFilled,
        title: noUpdatedStrings.feeAgreements,
        value: totalAgreements || 0,
        variant: 'icon'
      }
    ];

    return <StatCardsRow statItems={statItems} />;
  },
  phonePerformanceStats: ({
    id,
    phone_performance_percentage: phonePerformancePercentage,
    total_external_calls: totalCalls,
    total_call_time: totalCallTime
  }) => (
    <PhonePerformanceStats
      key={id}
      phonePerformancePercentage={phonePerformancePercentage}
      totalCalls={totalCalls}
      totalCallTime={totalCallTime}
    />
  ),
  weeklyStats: ({
    bulkStats,
    id,
    sendoutStats,
    sendoverStats,
    total_new_candidates,
    total_new_job_orders
  }) => (
    <WeeklyStats
      bulksValues={bulkStats}
      key={id}
      newCandidates={total_new_candidates}
      newJobOrders={total_new_job_orders}
      sendoutsValues={sendoutStats}
      sendoversValues={sendoverStats}
    />
  )
};

export const CustomFooterRenderers = {
  dailyStats: ({ data, section }) =>
    !isEmpty(data) && (
      <DailyStatsFooter
        bulksLastUpdate={data?.bulksLastUpdate}
        callLastUpdate={data?.callLastUpdate}
        externalToolsLastUpdate={data?.externalToolsLastUpdate}
        shouldShowTitleLabel
        textLastUpdate={data?.textLastUpdate}
        type={section.type}
        footerActionLink={section.footerActionLink}
      />
    ),
  dailyStatsOverview: ({ data, section }) =>
    !isEmpty(data) && (
      <DailyStatsFooter
        bulksLastUpdate={data?.bulksLastUpdate}
        callLastUpdate={data?.callLastUpdate}
        externalToolsLastUpdate={data?.externalToolsLastUpdate}
        shouldShowExternalToolsLabel
        shouldShowTitleLabel
        textLastUpdate={data?.textLastUpdate}
        type={section.type}
        footerActionLink={section.footerActionLink}
      />
    )
};

export const CustomEmptyStateRenderers = {
  hiresTerms: () => <SvgSvgEmptyWorkingCharts size={EMPTY_STATE_DIMENSIONS.width} />
};

export const HeaderActionRenderers = {
  dailyStatsOverview: ({ handleChange, state }) => {
    const { areNewMembers } = state;
    return <DailyStatsHeaderActions areNewMembers={areNewMembers} onChange={handleChange} />;
  }
};

export const WrapperTabsRenderers = {
  dateRangeTabs: (Child, selectedTab, handleTabChange) => {
    const tabs = [
      {
        label: strings.home.shared.dateRange.today,
        view: Child
      },
      {
        label: strings.home.shared.dateRange.week,
        view: Child
      },
      {
        label: strings.home.shared.dateRange.month,
        view: Child
      },
      {
        label: strings.home.shared.dateRange.yearToDate,
        view: Child
      }
    ];

    return (
      <TabsView
        selectedTab={selectedTab}
        onChangeTabIndex={handleTabChange}
        tabs={tabs}
        panelHeight="unset"
      />
    );
  },
  regionOverviewTabs: (Child, selectedTab, handleTabChange) => {
    const tabs = [
      {
        label: strings.home.sections.regionalOverview.tabs.todayActivity,
        view: Child
      },
      {
        label: strings.home.sections.regionalOverview.tabs.placementsAndFallOffs,
        view: Child
      },
      {
        label: strings.home.sections.regionalOverview.tabs.ratios,
        view: Child
      },
      {
        label: strings.home.sections.regionalOverview.tabs.hireAndTerms,
        view: Child
      }
    ];

    return (
      <TabsView
        selectedTab={selectedTab}
        onChangeTabIndex={handleTabChange}
        tabs={tabs}
        panelHeight="unset"
      />
    );
  }
};
