const jobOrderProfile = '/joborders/profile/:id';

export const EntityRoutes = {
  Login: '/login',

  Home: '/',

  Map: '/map',

  Candidates: '/candidates',
  CandidateProfile: '/candidates/profile/:id',
  CandidateCreate: '/candidates/create',
  FPCandidateProfile: '/candidate/profile/:id',
  CandidateDataSheet: '/candidates/profile/:id/data-sheet',

  Companies: '/companies',
  CompanyProfile: '/companies/profile/:id',
  CompanyCreate: '/companies/create',
  FPCompanyProfile: '/company/profile/:id',

  JobOrders: '/joborders',
  JobOrderProfile: jobOrderProfile,
  JoborderProfile: jobOrderProfile, // PLEASE do not delete. It's required for the map to work, as the links to profiles are dynamically generated
  JobOrderCreate: '/joborders/create',
  JobOrderEdit: '/joborders/edit/:id',
  FPJobOrderProfile: '/joborder/profile/:id',

  Dashboard: '/dashboard',
  DashboardOverview: '/dashboard/overview',
  DashboardActivity: '/dashboard/activity',
  DashboardPhone: '/dashboard/phone',
  DashboardBulkEmail: '/dashboard/bulkemail',
  DashboardSendout: '/dashboard/sendouts',
  DashboardSendoutLeaders: '/dashboard/leaderboard',
  DashboardFeeAgreements: '/dashboard/feeagreements',
  DashboardToolsUsageReport: '/dashboard/tools-usage-report',
  DashboardToolsUsage: '/dashboard/tools-usage-dashboard',

  CommandCenter: '/command-center',
  CommandCenterDominateSheet: '/command-center/dominate-sheet',
  CommandCenterActivity: '/command-center/activity',
  CommandCenterActivityTeam: '/command-center/activity/team/:id',
  CommandCenterActivityRecruiter: '/command-center/activity/recruiter/:id',
  CommandCenterOverview: '/command-center/overview',

  Contacts: '/contacts',
  ContactProfile: '/contacts/profile/:id',
  FPContactsProfile: '/contact/profile/:id',
  ContactCreate: '/contacts/create',
  FPContactProfile: '/contact/profile/:id',
  HiringAuthorityCreate: '/hiringauthority/create',
  HiringAuthorityProfile: '/hiringauthority/profile/:id',
  FPHiringAuthorityProfile: '/hiringauthority/profile/v2/:id',

  SearchProject: '/searchproject',
  SearchProjectPreview: '/searchproject/preview/:id',
  InventoryProfile: '/candidate/profile/:id',

  // Activity
  BulkEmail: '/bulkemail',
  Email: '/email',
  FeeAgreements: '/feeagreements',

  Calls: '/calls',
  RecruiterProfile: '/roster/profile/:id',
  Sendouts: '/sendouts',
  SendoutsFromChannelPartner: '/channel-partner-sendouts',
  SendoutProfile: '/sendouts/profile/:id',
  SendoutCreate: '/sendouts/create',
  Phone: '/phone',
  Roster: '/roster',
  Placements: '/placements',
  Industries: '/industries',
  Teams: '/teams',
  Preview: '/preview',
  Collections: '/collections',
  TextMessages: '/text-messages'
};
