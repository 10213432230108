// @flow
import React, { lazy, ReactNode, Suspense } from 'react';
import { NavigationControl, Popup } from 'react-map-gl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { usCenterCoordinates } from 'UI/constants/defaults';

import MapMarker from '../MapMarker';
import { When } from '../When';

import { useStyles } from './FPMap.styled';
import MarkerList from './MarkerList';

const MapGL = lazy(() => import('react-map-gl'));

const MAP_SIZE = '100%';
const MAPBOX_STYLE = window.GPAC_ENV?.MAP_STYLE || process.env.REACT_APP_MAP_STYLE;
const MAP_TOKEN = window.GPAC_ENV?.MAPBOX_TOKEN || process.env.REACT_APP_MAPBOX_TOKEN;

type FPMapProps = {
  children: ReactNode,
  entityType: string,
  isDigActive: boolean,
  mapRef: React.Ref<typeof Component>,
  markers: Array<Object>,
  onClosePopup: () => void,
  onMapClick: () => void,
  onMarkerEnter: () => void,
  onViewportChange: () => void,
  popupInfo: Object,
  renderPopupContent: () => void,
  viewport: string
};

const defaultViewport = {
  latitude: usCenterCoordinates.latitude,
  longitude: usCenterCoordinates.longitude,
  zoom: 4,
  width: '100%',
  height: '100vh'
};

const FPMap = ({
  children,
  entityType,
  enableNavigationControls = true,
  isDigActive,
  mapRef,
  markers,
  popupInfo,
  viewport = defaultViewport,
  onClosePopup,
  onMapClick,
  onMarkerEnter,
  onViewportChange,
  renderPopupContent
}: FPMapProps) => {
  const classes = useStyles();
  const handleMarkerClick = marker => onMarkerEnter && onMarkerEnter(marker);

  const popupCoordinates = {
    longitude: popupInfo?.city_longitude ?? popupInfo?.longitude,
    latitude: popupInfo?.city_latitude ?? popupInfo?.latitude
  };

  return (
    <Suspense
      fallback={
        <div className={classes.circularProgress}>
          <CircularProgress />
        </div>
      }
    >
      <MapGL
        {...viewport}
        mapStyle={MAPBOX_STYLE}
        width={MAP_SIZE}
        height={MAP_SIZE}
        onViewportChange={onViewportChange}
        onClick={onMapClick}
        mapboxApiAccessToken={MAP_TOKEN}
        ref={mapRef}
      >
        {children}
        <When condition={enableNavigationControls}>
          <div className={classes.navigationControl}>
            <NavigationControl onViewportChange={onViewportChange} />
          </div>
        </When>
        <MarkerList
          markers={markers}
          isDigActive={isDigActive}
          renderMapMarker={marker => (
            <MapMarker
              info={marker}
              isDigActive={isDigActive}
              entityType={entityType}
              onClick={handleMarkerClick}
            />
          )}
        />
        {popupInfo && (
          <Popup
            tipSize={5}
            anchor="bottom"
            offsetLeft={0}
            offsetTop={-10}
            longitude={popupCoordinates?.longitude}
            latitude={popupCoordinates?.latitude}
            closeOnClick={false}
            onClose={onClosePopup}
          >
            {renderPopupContent && renderPopupContent()}
          </Popup>
        )}
      </MapGL>
    </Suspense>
  );
};

export default FPMap;
