// @flow
import { SchemaStatuses } from 'constants/schemaStatuses';
import { THEME } from 'GlobalStyles';

export const STATUSES_INFO = {
  [SchemaStatuses.Completed]: {
    color: THEME.palette.success.main,
    label: 'Completed'
  },
  [SchemaStatuses.InProgress]: {
    color: THEME.palette.warning.main,
    label: 'In Progress'
  },
  [SchemaStatuses.Pending]: {
    color: THEME.palette.grey[600],
    label: 'Pending'
  }
};

export type FPFormStatusType = $Keys<typeof SchemaStatuses>;
