import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

export const FieldContainer = withStyles(() => ({
  root: {
    marginBottom: 24,
    '& > *:first-child': {
      marginBottom: 12
    }
  }
}))(Box);
