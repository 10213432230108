import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { SchemaStatuses } from 'constants/schemaStatuses';
import { Permissions } from 'services/Authorization';
import strings from 'strings';
import { PaymentMode } from 'UI/constants/entityTypes';
import { UIStatus } from 'UI/constants/status';

import { DATA_SHEET_QUESTIONS_PATHS } from '../EditDataSheet.constants';
import { saveDataSheet, saveDataSheetDraft } from '../EditDataSheet.services';
import {
  addFeeAgreementValuesToFormData,
  addFeePercent,
  canChangeFeeAgreementField,
  createDataSheetFormData,
  createDataToSend,
  customValidate,
  formatDataSheetSchema,
  formatDataSheetUiSchema,
  getDataSheetCount,
  removeFeePercentFromSchema
} from '../EditDataSheet.utils';

const {
  complete: { success: completeSuccess, error: completeError },
  draft: { success: draftSuccess, error: draftError },
  warning: fieldsWarning,
  error: fieldsError
} = strings.inventory.jobOrders.editDataSheet.uiMessages;

export const SAVING_DRAFT_STATUS = 'savingDraft';
const useDataSheetSchema = ({
  whiteSheet,
  whiteSheetStatus,
  dataSheet,
  catalogs,
  schemaData,
  user,
  jobOrder,
  companyName,
  canUserEdit
}) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(UIStatus.IDLE);
  const [canSave, setCanSave] = useState(false);
  const saveButtonRef = useRef(null);
  const isFormDataFormatted = useRef(false);
  const wasCompleted = useRef(false);
  const questionsCount = useRef({
    total: 0,
    answered: 0
  });
  const [schemas, setSchemas] = useState({
    schema: undefined,
    uiSchema: undefined,
    version: undefined
  });
  const [formData, setFormData] = useState(undefined);
  const commonStyledFormProps = {
    showErrorList: false,
    noHtml5Validate: true,
    submitRef: saveButtonRef,
    focusOnFirstError: true
  };

  const updateCounts = useCallback(
    form => {
      const count = getDataSheetCount(
        form,
        DATA_SHEET_QUESTIONS_PATHS,
        catalogs?.feeAgreements.data.length > 0
      );
      setCanSave(count.answered === count.total);
      questionsCount.current = count;
    },
    [catalogs]
  );

  const updateSchema = useCallback(
    formInfo => {
      if (!formInfo) return;
      const selectedFeeAgreement = catalogs.feeAgreements.data.find(
        feeAgreement => feeAgreement.id === formInfo.section1.feeAgreement
      );

      if (selectedFeeAgreement?.fee_agreement_payment_scheme_id === PaymentMode.Flat) {
        setSchemas(prevSchemas => ({
          ...prevSchemas,
          schema: removeFeePercentFromSchema(prevSchemas.schema)
        }));

        return;
      }

      setSchemas(prevSchemas => ({
        ...prevSchemas,
        schema: addFeePercent(prevSchemas.schema)
      }));
    },
    [catalogs]
  );

  useEffect(() => {
    const notValidScenarios = [
      !catalogs,
      !schemas.schema,
      !schemas.uiSchema,
      !whiteSheet,
      isFormDataFormatted.current
    ];

    if (notValidScenarios.some(scenario => scenario)) return;

    setFormData(prevFormData => {
      if (dataSheet) {
        const newFormData = dataSheet.data;
        isFormDataFormatted.current = true;
        updateSchema(newFormData);
        return newFormData;
      }

      const newFormData = createDataSheetFormData(
        prevFormData,
        { ...whiteSheet, status: whiteSheetStatus },
        catalogs?.feeAgreements.data.length > 0
      );

      if (!newFormData) return prevFormData;
      isFormDataFormatted.current = true;

      updateSchema(newFormData);
      return newFormData;
    });
  }, [
    catalogs,
    whiteSheet,
    schemas.schema,
    schemas.uiSchema,
    dataSheet,
    whiteSheetStatus,
    updateSchema
  ]);

  const isThereFeeAgreement = !!formData?.section1?.feeAgreement;

  useEffect(() => {
    if (!formData) return;
    updateSchema(formData);
  }, [formData, updateSchema]);

  useEffect(() => {
    wasCompleted.current = dataSheet?.progress.key === SchemaStatuses.Completed;
    if (!catalogs || !schemaData) return;

    const canModifyFeeGuarantee = canChangeFeeAgreementField(
      canUserEdit,
      whiteSheet,
      Permissions.FeeAgreements.ModifyGuarantee
    );

    const canModifyFeePercentage = canChangeFeeAgreementField(
      canUserEdit,
      whiteSheet,
      Permissions.FeeAgreements.ModifyPercentage
    );

    const formattedSchema = formatDataSheetSchema(
      dataSheet ? dataSheet.schema.structure : schemaData.structure,
      catalogs,
      whiteSheet
    );

    if (!formattedSchema) return;

    setSchemas(prevSchemas => ({
      ...prevSchemas,
      schema: formattedSchema,
      uiSchema: formatDataSheetUiSchema(dataSheet ? dataSheet.schema.ui : schemaData.ui, {
        canModifyFeeGuarantee,
        canModifyFeePercentage,
        isThereFeeAgreement
      }),
      version: dataSheet ? dataSheet.schema.version : schemaData.version
    }));
  }, [canUserEdit, catalogs, dataSheet, isThereFeeAgreement, schemaData, whiteSheet]);

  const toastBody = `${jobOrder.title} - ${companyName}`;

  const handleOnSave = () => {
    saveButtonRef.current.click();
    if (questionsCount.current.total !== questionsCount.current.answered) {
      dispatch(
        showAlert({
          severity: 'warning',
          title: fieldsWarning,
          body: toastBody
        })
      );
    }
  };

  const handleOnChange = ({ formData: newFormData }) => {
    const formattedData =
      !catalogs || !whiteSheet
        ? newFormData
        : addFeeAgreementValuesToFormData(newFormData, catalogs, whiteSheet);

    updateSchema(formattedData);
    setFormData(formattedData);
  };

  useEffect(() => {
    if (!formData) return;
    updateCounts(formData);
    setCanSave(questionsCount.current.answered === questionsCount.current.total);
  }, [formData, updateCounts]);

  const handleOnSubmit = ({ formData: newFormData }) => {
    setStatus(UIStatus.Saving);
    setFormData(newFormData);
    const dataToSend = createDataToSend({
      formData: newFormData,
      jobOrder,
      schemaData,
      user,
      whiteSheetId: whiteSheet.id,
      count: questionsCount.current,
      ...(dataSheet && { dataSheetId: dataSheet.id })
    });

    saveDataSheet(dataToSend)
      .then(response => {
        setStatus(UIStatus.IDLE);

        if ([HTTPStatusCodes.BadRequest, HTTPStatusCodes.Unauthorized].includes(response.status)) {
          dispatch(
            showAlert({
              severity: UIStatus.Error,
              title: completeError,
              body: toastBody
            })
          );
          return;
        }

        wasCompleted.current = response.data.progress.key === SchemaStatuses.Completed;

        dispatch(
          showAlert({
            severity: UIStatus.Success,
            title: completeSuccess,
            body: toastBody
          })
        );
      })
      .catch(() => {
        setStatus(UIStatus.Error);
        dispatch(
          showAlert({
            severity: UIStatus.Error,
            title: completeError,
            body: toastBody
          })
        );
      });
  };

  const handleOnError = errors => {
    if (errors && errors.length === 0) return;

    dispatch(
      showAlert({
        severity: UIStatus.Error,
        title: fieldsError,
        body: toastBody
      })
    );
  };

  const handleOnSaveDraft = () => {
    setStatus(SAVING_DRAFT_STATUS);

    const dataToSend = createDataToSend({
      formData,
      jobOrder,
      schemaData,
      user,
      whiteSheetId: whiteSheet.id,
      count: questionsCount.current,
      ...(dataSheet && { dataSheetId: dataSheet.id })
    });

    saveDataSheetDraft(dataToSend)
      .then(response => {
        setStatus(UIStatus.IDLE);

        if ([HTTPStatusCodes.BadRequest, HTTPStatusCodes.Unauthorized].includes(response.status)) {
          dispatch(
            showAlert({
              severity: UIStatus.Error,
              title: draftError,
              body: toastBody
            })
          );
          return;
        }

        dispatch(
          showAlert({
            severity: UIStatus.Success,
            title: draftSuccess,
            body: toastBody
          })
        );
      })
      .catch(() => {
        setStatus(UIStatus.Error);
        dispatch(
          showAlert({
            severity: UIStatus.Error,
            title: draftError,
            body: toastBody
          })
        );
      });
  };

  return [
    {
      commonStyledFormProps,
      formData,
      schemas,
      canSave,
      status,
      isDataSheetComplete:
        dataSheet?.progress.key === SchemaStatuses.Completed || wasCompleted.current
    },
    {
      handleOnSubmit,
      handleOnSave,
      handleOnChange,
      handleOnSaveDraft,
      handleOnError,
      handleCustomValidate: customValidate
    }
  ];
};

export default useDataSheetSchema;
