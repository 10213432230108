export const titles = {
  common: {
    from: 'From',
    to: 'To'
  },
  education: {
    school: 'School',
    degree: 'Degree',
    major: 'Major'
  },
  company: {
    company: 'Company',
    title: 'Title',
    duties: 'Duties and responsibilities',
    accomplishments: 'Accomplishments'
  },
  references: 'References',
  licenses: {
    licenses: 'Licenses & Certification',
    issuing: 'Issuing organization',
    credential: 'Credential ID',
    credentialUrl: 'Credential URL',
    issueDate: 'Issue date',
    expirationDate: 'Expiration date'
  },
  achievement: 'Achievement',
  skills: 'Skills',
  experience: 'Experience Summary',
  salary: {
    currentCompany: 'What do you like most about your current company/position',
    nextTitle: 'Preferred next title',
    relocate: 'Willing to relocate',
    relocationDestinations: 'Relocation destinations',
    workPreference: 'Work preference',
    compensationRange: 'Desired compensation range*',
    lowSalary: 'Low salary',
    idealSalary: 'Ideal salary',
    highSalary: 'High salary',
    desiredBenefits: 'Desired benefits',
    companiesYouLike: 'Companies you would like to work for',
    companiesYouDontLike: `Companies you wouldn't like to work for`
  },
  urgency: {
    reasonForLooking: 'What is the reason you are looking?',
    howLongYouBeenLooking: 'How long have you been looking?',
    startNewPosition: 'Ideal Time To Start New position',
    lookingDetails: 'Reason for looking details',
    questionHappyAtJob:
      'On a scale of 1 to 10 (1 Being completely happy where you are, 10 being you are about the door for the right opportunity) Where are you at?',
    keepsAt10: 'What keeps you from being a 10?',
    somethingToMakeAChange: 'Have you done anything on your own so far to make a change?',
    stepsToMakeAChange: 'What steps have you taken so far to make a change?',
    companiesYouApplied: 'What companies have you applied to?',
    professionalToMakeAChange:
      'Is there anything personal/professional that could keep you from making a change?',
    companyOffersPosition:
      'If a company offers you the position after an interview, what would you do?',
    interviews: 'Available date interviews'
  }
};
