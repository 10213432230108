import { useEffect } from 'react';

export const useResetEditorToolbar = () => {
  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (
          mutation.type === 'attributes' &&
          mutation.target.classList.contains('tox-tinymce-aux') &&
          mutation.target.getAttribute('aria-hidden') === 'true'
        ) {
          mutation.target.removeAttribute('aria-hidden');
        }
      });
    });

    const observerOptions = {
      attributes: true,
      attributeFilter: ['class', 'aria-hidden'],
      subtree: true
    };

    observer.observe(document.body, observerOptions);
    return () => {
      observer.disconnect();
      observer.takeRecords();
    };
  }, []);
};
