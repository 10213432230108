// @flow

import { Endpoints } from './endpoints';

export type EntityFilter = {
  id: number,
  title: string,
  order: number
};

export const CandidateEntity = {
  id: 'candidate',
  title: 'Candidates',
  singular: 'Candidate',
  isActive: true,
  searchParam: 'candidate',
  module: 'candidate',
  showInGlobalSearch: true,
  trackingUrl: Endpoints.CandidateRecentlyViewed
};

export const CompanyEntity = {
  id: 'company',
  title: 'Companies',
  singular: 'Company',
  plural: 'Companies',
  isActive: true,
  searchParam: 'company',
  module: 'company',
  showInGlobalSearch: true,
  trackingUrl: Endpoints.CompanyRecentlyViewed
};

export const ContactEntity = {
  id: 'name',
  title: 'Contacts',
  singular: 'Contact',
  isActive: false,
  searchParam: 'hiringAuthorityOrName',
  module: 'name',
  showInGlobalSearch: true,
  trackingUrl: Endpoints.NameRecentlyViewed
};

export const HiringAuthorityEntity = {
  id: 'hiringAuthority',
  title: 'Hiring Authorities',
  singular: 'Hiring Authority',
  isActive: false,
  searchParam: 'hiringAuthorityOrName',
  module: 'hiringAuthority',
  trackingUrl: Endpoints.HiringAuthorityRecentlyViewed
};

export const JobOrderEntity = {
  id: 'joborder',
  title: 'Job Orders',
  singular: 'Job Order',
  isActive: true,
  searchParam: 'joborder',
  module: 'jobOrder',
  endpoint: 'JobOrders',
  showInGlobalSearch: true,
  trackingUrl: Endpoints.JobOrderRecentlyViewed
};

export const entityTypes = [
  CandidateEntity,
  CompanyEntity,
  ContactEntity,
  JobOrderEntity,
  HiringAuthorityEntity
];

export const EntityType = {
  Candidate: 'candidate',
  Company: 'company',
  Contact: 'name',
  ContactType: { Inventory: 'inventory', Contact: 'contact' },
  FeeAgreement: 'feeagreement',
  HiringAuthority: 'hiringAuthority',
  Joborder: 'joborder',
  Placement: 'placement',
  Roster: 'roster',
  SearchProjectPreview: 'SearchProjectPreview',
  SearchProjects: 'searchProjects',
  Sendout: 'sendout',
  Sendouts: 'sendouts',
  Interview: 'interview'
};

export const ListingFilters = {
  Mine: 0,
  MyDig: 1,
  MyTeam: 2,
  All: 3,
  MyCollaborations: 4,
  FreeGame: 5
};

export const entityTypesFilters = (type: string, exclude: number[]): EntityFilter[] => {
  const options = [
    { id: 0, title: `My ${type}`, order: 0 },
    { id: 1, title: `${type} in the industries I work`, order: 1 },
    { id: 2, title: `My Team ${type}`, order: 2 },
    { id: 3, title: `All ${type}`, order: 5 }
  ];
  return exclude?.length ? options.filter(({ id }) => !exclude.includes(id)) : options;
};

export const accountabilityFilters = (type: string): EntityFilter[] => {
  return [
    ...entityTypesFilters(type),
    { id: 4, title: `My collaborations`, order: 3 },
    { id: 5, title: `No activity ${type}`, order: 4 }
  ].sort((a: EntityFilter, b: EntityFilter) => a.order - b.order);
};

export type IdTitle = {
  id: string,
  title: string,
  extraInfo: string
};

export const PaymentMode = {
  Standard: 'standard',
  Flat: 'flat',
  Conversion: 'conversion',
  BaseSalary: 'basesalary'
};

export const PaymentTermsMode = {
  DueOn30: 'dueOnDay30',
  Split: 'splitPayments'
};

export const PaymentModes: IdTitle[] = [
  { id: PaymentMode.Standard, title: 'Standard' },
  { id: PaymentMode.Flat, title: 'Flat' },
  { id: PaymentMode.Conversion, title: 'Conversion' },
  { id: PaymentMode.BaseSalary, title: 'Base Salary' }
];

export const PaymentTermsModes: IdTitle[] = [
  { id: PaymentTermsMode.DueOn30, title: 'Due on day 30' },
  { id: PaymentTermsMode.Split, title: 'Split Payments' }
];

export const NumberOfPaymentsOptions: IdTitle[] = [
  {
    id: 2,
    title: '2 Payments',
    allowedDistributions: [
      [0, 30],
      [0, 45],
      [30, 60]
    ]
  },
  {
    id: 3,
    title: '3 Payments',
    allowedDistributions: [
      [0, 30, 45],
      [0, 30, 60],
      [0, 45, 90],
      [30, 60, 90]
    ]
  }
];

export const FeeAgreementProcess = {
  Fortpac: 'fortPacManaged',
  External: 'externalUnmanaged'
};

export const FeeAgreementProcesses: IdTitle[] = [
  { id: FeeAgreementProcess.Fortpac, title: 'New agreement' },
  { id: FeeAgreementProcess.External, title: 'Existing agreement' }
];

export const FeeAgreementProcessesForFilters: IdTitle[] = [
  { id: FeeAgreementProcess.Fortpac, title: 'Signed using Fortpac' },
  { id: FeeAgreementProcess.External, title: 'Signed outside Fortpac' }
];

export const getDefaultDirectoryTemplates = ({ gpac, mine, shared }) => {
  if (gpac.length < 1 && mine.length < 1) return [];

  return [
    {
      id: 'gpac',
      name: 'Gpac',
      children: gpac
    },
    {
      id: 'shared',
      name: 'Shared',
      children: shared
    },
    {
      id: 'mine',
      name: 'Mine',
      children: mine
    }
  ];
};

export const CompanyType = {
  Vendor: 0,
  Client: 1,
  NotSigned: 2
};

export const ContactRole = {
  Name: 0,
  Candidate: 1,
  HiringAuthority: 2
};

export const ContactRoleStrings = {
  0: 'Contact',
  1: 'Candidate',
  2: 'Hiring Authority'
};

export const ContactEntityMapping = {
  [ContactRole.Name]: EntityType.Contact,
  [ContactRole.Candidate]: EntityType.Candidate,
  [ContactRole.HiringAuthority]: EntityType.HiringAuthority
};

export const ContactMapByEntityType = {
  [EntityType.Contact]: ContactRole.Name,
  [EntityType.Candidate]: ContactRole.Candidate,
  [EntityType.HiringAuthority]: ContactRole.HiringAuthority
};

export const SearchProjectItemType = {
  Candidate: '1',
  HiringAuthority: '2',
  Name: '3',
  NameCandidate: '4',
  NameHA: '5',
  Company: '6'
};

export const SplitType = {
  Company: 'company',
  Candidate: 'candidate'
};

export type interviewData = {
  id?: Number,
  interview_type: string | undefined,
  interview_datetime: String | undefined,
  interview_timezone: String | undefined
};

export const searchProjectItems = {
  Candidate: {
    id: 1,
    entity: 'candidates'
  },
  'Hiring Authority': {
    id: 2,
    entity: 'hiring_authorities'
  },
  Name: {
    id: 3,
    entity: 'names'
  }
};

export const SearchProjectEntities = {
  Candidate: 'Candidate',
  HiringAuthority: 'Hiring Authority',
  Contact: 'Name',
  Company: 'Company',
  JobOrder: 'Job Order',
  CandidateLead: 'Candidate Lead',
  HiringAuthorityLead: 'Hiring Authority Lead'
};

export const TabKeys = {
  ActivityLogs: 'activityLogs',
  AssociatedCompanies: 'associatedCompanies',
  Candidates: 'candidates',
  ChangeLogs: 'changelogs',
  Companies: 'companies',
  Employees: 'employees',
  FeeAgreements: 'feeagreements',
  Files: 'files',
  HiringAuthorities: 'hiringauthorities',
  JobOrders: 'joborders',
  Notes: 'notes',
  Overview: 'overview',
  Placements: 'placements',
  SearchProjects: 'searchProjects',
  Sendouts: 'sendouts',
  ReferenceCheck: 'referenceCheck'
};

export const FormSchema = {
  ReferenceCheck: 'REFERENCE_CHECK_SCHEMA',
  JobOrderDataSheet: 'JOB_ORDER_DATA_SHEET',
  CandidateDataSheetFull: 'CANDIDATE_DATA_SHEET_FULL',
  CandidateDataSheetPlacement: 'CANDIDATE_DATA_SHEET_PLACEMENT',
  CandidateDataSheetQuick: 'CANDIDATE_DATA_SHEET_QUICK'
};
