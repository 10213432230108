// @flow
import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { TreeItem } from '@material-ui/lab';
import { THEME as theme } from 'GlobalStyles';
import { TREE_VIEW_ENTITY_TYPES } from 'UI/constants/defaults';
import { SvgFolder } from 'UI/res';
import SvgFile from 'UI/res/icons/filled/FIle';
import { nestTernary } from 'UI/utils';

import { useTreeRendererStyles } from './styles';
import { isFolder, nodeObjectMapper } from './utils';

const CLICK_EVENTS = {
  label: 'labelClick',
  icon: 'iconClick'
};

const TreeViewChildrenRenderer = ({
  branchIdToRender,
  currentSelectedNodeId,
  enableIconClick,
  expandedIds,
  onClickTreeItem = () => {},
  onSelectNode,
  onUpdateTreeItems,
  queryParams = {},
  serviceToExecute = null,
  tree
}) => {
  const classes = useTreeRendererStyles();
  const [nodesFetching, setNodesFetching] = useState([]);

  const shouldShowTreeItemLoader = ({ id, entity }) =>
    nodesFetching.includes(id) && entity === TREE_VIEW_ENTITY_TYPES.folder;

  const expandFolder = async ({ nodeId, parentFolderId }) => {
    onSelectNode(nodeId);
    if (expandedIds.includes(nodeId)) return;

    setNodesFetching(prev => [...prev, nodeId]);
    const { success, data } = await serviceToExecute({ ...queryParams, parentId: parentFolderId });
    setNodesFetching(prev => prev.filter(node => node !== nodeId));

    if (success) {
      onUpdateTreeItems(nodeId, data);
    }
  };

  const formModeSelectHandler = async (event, node) => {
    const mappedNode = nodeObjectMapper(node);
    event === CLICK_EVENTS.label && onClickTreeItem(mappedNode);

    if (currentSelectedNodeId === node.id || event === CLICK_EVENTS.label) return;

    if (isFolder(node.entity))
      await expandFolder({ nodeId: node.id, parentFolderId: mappedNode.id });
  };

  const treeModeSelectHandler = async (event, node) => {
    if (currentSelectedNodeId === node.id) return;

    const mappedNode = nodeObjectMapper(node);

    if (isFolder(node.entity))
      await expandFolder({ nodeId: node.id, parentFolderId: mappedNode.id });

    onSelectNode(node.id);
    onClickTreeItem(mappedNode);
  };

  const handleNodeSelect = (event, selectedNode) => () => {
    enableIconClick
      ? formModeSelectHandler(event, selectedNode)
      : treeModeSelectHandler(event, selectedNode);
  };

  return tree[branchIdToRender].map(branch => {
    const childrenNodes =
      tree[branch.id]?.length > 0 ? (
        <TreeViewChildrenRenderer
          branchIdToRender={branch.id}
          enableIconClick={enableIconClick}
          expandedIds={expandedIds}
          onClickTreeItem={onClickTreeItem}
          onSelectNode={onSelectNode}
          onUpdateTreeItems={onUpdateTreeItems}
          queryParams={queryParams}
          serviceToExecute={serviceToExecute}
          tree={tree}
        />
      ) : (
        nestTernary(isFolder(branch.entity), [null], null)
      );
    // An array of one null is needed to tell TreeView to render the right chevron, otherwise it thinks there are no items to load

    const IconComponent = isFolder(branch.entity) ? SvgFolder : SvgFile;

    return (
      <TreeItem
        key={branch.id}
        nodeId={branch.id}
        className={classes.treeItem}
        classes={{
          content: classes.treeItemContent,
          label: classes.treeItemLabel
        }}
        onIconClick={handleNodeSelect(CLICK_EVENTS.icon, branch)}
        onLabelClick={handleNodeSelect(CLICK_EVENTS.label, branch)}
        label={
          <div className={classes.labelContainer}>
            <IconComponent size={24} className={classes.labelIcon} fill={theme.palette.grey[600]} />
            <Typography variant="body2" className={classes.labelText}>
              {branch.name}
            </Typography>
            {shouldShowTreeItemLoader(branch) && <CircularProgress size={20} />}
          </div>
        }
      >
        {childrenNodes}
      </TreeItem>
    );
  });
};

export default TreeViewChildrenRenderer;
