// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { COMPANY_RELATIONSHIP_KEY } from 'UI/constants/defaults';
import { SvgAdd, SvgEdit } from 'UI/res';

import RelationshipOverview from './RelationshipOverview';

type AssociatedCompaniesOptionsProps = {
  direction: 'column' | 'row',
  options: Record[],
  associatedCompanies: Record,
  isLoading: boolean
};

const SKELETON_BUTTONS_SIZES = {
  width: 200,
  height: 40
};

const AssociatedCompaniesOptions = ({
  associatedCompanies,
  direction,
  options,
  isLoading
}: AssociatedCompaniesOptionsProps) => {
  const isColumn = direction === 'column';

  const transformedOptions = options.map(opt => {
    const buttonText = `${opt.actionText} ${opt.text}`;
    const buttonIcon = opt.actionText === 'Add' ? SvgAdd : SvgEdit;
    const companyData =
      opt.id === COMPANY_RELATIONSHIP_KEY.parentCompany
        ? associatedCompanies.parentCompany
        : associatedCompanies[opt.id];
    const data = [companyData].flat();
    return {
      buttonText,
      buttonIcon,
      data,
      ...opt
    };
  });

  return (
    <Grid
      container
      direction={direction}
      spacing={5}
      justify={isColumn ? 'flex-start' : 'flex-end'}
    >
      {transformedOptions.map(opt => {
        return (
          <Grid item key={opt.id}>
            {isColumn && (
              <RelationshipOverview
                direction="row"
                data={opt.data}
                title={opt.text}
                type={opt.id}
              />
            )}
            {isLoading ? (
              <Skeleton
                variant="rect"
                animation="pulse"
                width={SKELETON_BUTTONS_SIZES.width}
                height={SKELETON_BUTTONS_SIZES.height}
              />
            ) : (
              <FPActionButton
                icon={opt.buttonIcon}
                iconProps={{ family: 'streamline', size: 'sm' }}
                onClick={opt.onClick}
                text={opt.buttonText}
                disabled={!opt.enabled}
              />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

AssociatedCompaniesOptions.defaultProps = {
  direction: 'row',
  options: null,
  associatedCompanies: {
    parentCompany: null,
    siblingCompanies: null,
    childCompanies: null
  }
};

export default AssociatedCompaniesOptions;
