// @flow

import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { showAlert as showAlertAction } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import API from 'services/API';
import NameForm from 'UI/components/organisms/NameForm';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { Endpoints } from 'UI/constants/endpoints';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getErrorMessage, getFeatureFlags, hasFeatureFlag } from 'UI/utils';
import { preloadFromBackend, prepareFormToSubmit } from 'UI/utils/forms';

import { FormFieldsMap } from '../NameForm/fields';
import { formatPhoneNumbers, PHONE_FORMAT_MODES } from '../PhoneNumbersForm/utils';

type NameEditProps = {
  name: any,
  onEditCompleted: (profile: any) => void,
  onEditClosed: () => void,
  showAlert: any => void
};

const NameEdit = ({ name, onEditCompleted, onEditClosed, showAlert }: NameEditProps) => {
  const defaultValues = preloadFromBackend(name, FormFieldsMap);

  const hasMultiplePhones =
    defaultValues.phones.length > 0 &&
    getFeatureFlags().includes(FeatureFlags.ContactsMultiplePhones);

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false,
    isFormDisabled: false,
    isReadOnly: false
  });

  const form = useForm(name?.id ? { defaultValues } : {});

  const isMultiplePhonesEnabled = hasFeatureFlag(FeatureFlags.ContactsMultiplePhones);

  const onSubmit = async formData => {
    try {
      setUiState(prevState => ({ ...prevState, isSaving: true }));
      const preparedNameData = prepareFormToSubmit(formData, FormFieldsMap);
      const preparedPhones = isMultiplePhonesEnabled
        ? formatPhoneNumbers(formData, PHONE_FORMAT_MODES.submit)
        : null;
      const dataToSend = {
        ...preparedNameData,
        phones: preparedPhones
      };
      const response = await API.put(`${Endpoints.Names}/${name.id}`, dataToSend, {
        apiVersion: 2
      });
      if (response.data && response.status === HTTPStatusCodes.Ok) {
        showAlert({
          severity: 'success',
          title: 'Awesome',
          body: 'The contact was updated successfully'
        });

        onEditCompleted();
      }
    } catch (err) {
      showAlert({
        severity: 'error',
        title: 'Contact',
        body: getErrorMessage(err)
      });
    }
    setUiState(prevState => ({
      ...prevState,
      isSaving: false,
      isSuccess: false
    }));
  };

  return (
    <FormContext {...form}>
      <DrawerContentLayout
        title="Editing Contact"
        onSubmit={form.handleSubmit(onSubmit)}
        onClose={onEditClosed}
        uiState={uiState}
      >
        <NameForm isEditing hasMultiplePhones={hasMultiplePhones} />
      </DrawerContentLayout>
    </FormContext>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    showAlert: alert => dispatch(showAlertAction(alert))
  };
};

const NameEditConnected = connect(null, mapDispatchToProps)(NameEdit);

export default NameEditConnected;
