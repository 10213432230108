// @flow
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import groupBy from 'lodash/groupBy';
import strings from 'strings';
import FileUploaderConnected from 'UI/components/molecules/FileUploader';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { nestTernary } from 'UI/utils';

import { useStyles } from '../styles';
import { AttachmentNameField, FileUploaderMode, formNames, MenuItemsToDisableFile } from '../utils';

const maxNumberOfFiles = 4;
const maxSizeOfFiles = 26214400; // 25MB
const fileNameField = 'file_name';

interface SendoutFileManageSectionProps {
  onChangeFiles: any => void;
  disabled: Boolean;
  readOnly: boolean;
  categories: array[];
  files: array[];
  loading: Boolean;
  selectedCandidate: any;
}

const SendoutFileManageSection = ({
  categories,
  files,
  readOnly,
  loading,
  disabled,
  selectedCandidate
}: SendoutFileManageSectionProps) => {
  const classes = useStyles();

  const { watch } = useFormContext();
  const watcher = watch([AttachmentNameField, formNames.candidate.name]);

  const isFileLimitReached = watcher[AttachmentNameField]?.length >= maxNumberOfFiles;
  const filesByCategory = groupBy(files, 'file_type_id');

  const renderReadOnly =
    files?.length === 0 ? (
      <Typography variant="body1">{strings.sendouts.attachments.noFilesAdded}</Typography>
    ) : (
      categories?.map(category => (
        <React.Fragment key={category.id}>
          <Typography className={classes.categoryTitle}>{category.title}</Typography>
          {filesByCategory[category.id]?.map(attachment => (
            <FileUploaderConnected
              key={attachment.id}
              files={[attachment]}
              text=""
              fileNameField={fileNameField}
              mode={FileUploaderMode.ListReadonly}
              showItemMenu
              softDeleteForExistingItems
              modeExtraProps={{ readOnly }}
            />
          ))}
        </React.Fragment>
      ))
    );

  const renderLoading = (
    <Box justifyContent="center" alignItems="center" display="flex">
      <CircularProgress />
    </Box>
  );

  const renderNormal = (
    <>
      <Typography variant="body1">{strings.sendouts.attachments.description}</Typography>
      {loading ? (
        renderLoading
      ) : (
        <FileUploaderConnected
          categories={categories}
          files={files}
          disabled={disabled || isFileLimitReached}
          text="UPLOAD FILE"
          endpoint={
            watcher[formNames.candidate.name] &&
            `${Endpoints.Candidates}/${watcher[formNames.candidate.name].id}/${Endpoints.Files}`
          }
          name={AttachmentNameField}
          fileNameField={fileNameField}
          mode={FileUploaderMode.ListSelectable}
          showItemMenu
          maxNumberOfFiles={maxNumberOfFiles}
          maxFilesSize={maxSizeOfFiles}
          modeExtraProps={{
            menuItemsToDisable: MenuItemsToDisableFile.Creation
          }}
          defaultEntityType={EntityType.Candidate}
        />
      )}
    </>
  );

  const renderNoCandidate = (
    <>
      <Typography variant="body1">{strings.sendouts.attachments.description}</Typography>
      <Typography variant="h6" className={classes.textNoAttachments}>
        {strings.sendouts.attachments.fileSelectorDefault}
      </Typography>
      <FileUploaderConnected
        files={[]}
        disabled
        text="UPLOAD FILE"
        endpoint=""
        name={AttachmentNameField}
        fileNameField={fileNameField}
        mode={FileUploaderMode.ListSelectable}
        showItemMenu
        modeExtraProps={{ menuItemsToDisable: MenuItemsToDisableFile.Creation }}
      />
    </>
  );

  const renderCounter = (
    <Typography>
      {watcher[AttachmentNameField]?.length || 0}/{maxNumberOfFiles}
    </Typography>
  );

  return (
    <NumberedForm.Item title="Attachments" action={!readOnly && renderCounter}>
      {readOnly ? renderReadOnly : nestTernary(selectedCandidate, renderNormal, renderNoCandidate)}
    </NumberedForm.Item>
  );
};

export default SendoutFileManageSection;
