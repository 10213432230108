// @flow
import React, { Component } from 'react';

export class AppInsightsErrorBoundary extends Component<AppInsightsErrorBoundaryProps> {
  constructor(props: AppInsightsErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error) {
    this.setState({ hasError: true, error });
  }

  render() {
    const { children } = this.props;
    const { hasError, error } = this.state;
    if (hasError) {
      const { onError } = this.props;
      return React.createElement(onError, { error });
    }

    return children;
  }
}
