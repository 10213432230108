const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles(theme => ({
  dialogSections: {
    padding: theme.spacing(0, 7.5),
    '& > *': {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(0, 1)
    }
  }
}));
