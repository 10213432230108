import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  formSectionTitle: {
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightBold
  },
  fieldTitle: {
    marginBottom: theme.spacing(1)
  },
  fieldsContainer: {
    marginTop: theme.spacing(1.5)
  }
}));
