// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FileField from 'UI/components/molecules/FileField';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';

import { ScheduleCalls } from '../shared';

import { DebriefTypes, FormFieldsMap } from './DebriefingCalls.constants';
import { useStyles } from './DebriefingCalls.styles';

export const DebriefingCalls = () => {
  const classes = useStyles();
  const { errors, register, unregister, setValue, clearError, watch } = useFormContext();

  useEffect(() => {
    DebriefTypes.forEach(type => {
      register(FormFieldsMap[type.key].key);
    });
    register(FormFieldsMap.ExistingCandidateDebriefId.key);
    register(FormFieldsMap.ExistingEmployerDebriefId.key);

    return () => {
      DebriefTypes.forEach(type => {
        unregister(FormFieldsMap[type.key].key);
      });
      unregister(FormFieldsMap.ExistingCandidateDebriefId.key);
      unregister(FormFieldsMap.ExistingEmployerDebriefId.key);
    };
  }, [register, unregister]);

  const handleUploadStarted = (name: string) => clearError(name);

  const handleFileChange = (name: string, value: File | null) => {
    setValue(name, value, true);
  };

  const candidateDebrief = watch(FormFieldsMap.CandidateDebrief.key);
  const employerDebrief = watch(FormFieldsMap.EmployerDebrief.key);

  return (
    <div>
      <ScheduleCalls />
      <Typography className={classes.formSectionTitle}>Upload Debriefs</Typography>
      <Grid container spacing={2} className={classes.fieldsContainer}>
        {DebriefTypes.map(({ key, label }) => (
          <Grid container item xs={12} key={key}>
            <Typography variant="subtitle1" className={classes.fieldTitle}>
              {label}
            </Typography>
            <FileField
              name={FormFieldsMap[key].key}
              category={{
                module: EntityType.Sendout,
                required: false,
                title: 'Select File'
              }}
              acceptFiles={['.pdf']}
              defaultEntityType={EntityType.Sendout}
              endpoint={Endpoints.Files}
              errorText={errors?.[FormFieldsMap[key].key]?.message}
              onChange={handleFileChange}
              onUploadStarted={handleUploadStarted}
              softDeleteForExistingItems
              initialValue={key === 'CandidateDebrief' ? candidateDebrief : employerDebrief}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
