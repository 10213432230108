import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  theme => ({
    container: {
      marginTop: theme.spacing(1)
    },

    rangeContainer: {
      display: 'flex',
      gap: theme.spacing(1)
    }
  }),
  { classNamePrefix: 'InputRange' }
);
