// @flow
import * as React from 'react';
import Modal, { ModalProps } from '@material-ui/core/Modal';
import clsx from 'clsx';
import { FPCardContent, FPCardHeader } from 'UI/components/atoms/FPCard';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import TitleLabel from 'UI/components/atoms/TitleLabel';
import FooterActionsControlsLegacy from 'UI/components/molecules/FooterActionsControlsLegacy';
import { CloseIcon, colors } from 'UI/res';

import { useStyles } from './styles';

type HeaderActionsProps = {
  additionalHeaderActions: React.Node,
  onClose: () => any
};

const HeaderActions = ({ additionalHeaderActions, onClose }: HeaderActionsProps) => {
  return (
    <>
      {additionalHeaderActions}
      <FPIconButton onClick={onClose} tooltipProps={{ placement: 'bottom', title: 'Close' }}>
        <CloseIcon fill={colors.completeBlack} />
      </FPIconButton>
    </>
  );
};

type SpacingVariant = 'tight' | 'regular' | 'relaxed';

type FPModalProps = ModalProps & {
  onClose: () => any,
  title: string,
  primaryProps: Object,
  titleProps: Object,
  headerProps: Object,
  additionalHeaderActions: React.Node,
  hasFooterActions: boolean,
  secondaryProps: Object,
  contentProps: Object,
  fitContent: boolean,
  footer: React.Node,
  contentVariant?: SpacingVariant,
  footerVariant?: SpacingVariant,
  customClasses: Object,
  footerClassName?: string,
  saveAction: boolean
};

const FPModal = ({
  title,
  children,
  onClose,
  primaryProps,
  titleProps,
  headerProps,
  additionalHeaderActions,
  hasFooterActions,
  contentProps,
  fitContent,
  footer,
  contentVariant,
  footerVariant,
  footerClassName,
  customClasses,
  secondaryProps,
  saveAction,
  ...rest
}: FPModalProps) => {
  const classes = useStyles({ fitContent });
  return (
    <Modal onClose={onClose} {...rest}>
      <div className={clsx(classes.modalContainer, customClasses)}>
        <FPCardHeader
          title={<TitleLabel fontSize={26} text={title} {...titleProps} />}
          action={
            <HeaderActions additionalHeaderActions={additionalHeaderActions} onClose={onClose} />
          }
          className={titleProps.backNavigation ? classes.headerWithArrow : classes.header}
          {...headerProps}
        />
        <FPCardContent
          variant={contentVariant}
          className={classes.contentContainer}
          {...contentProps}
        >
          {children}
        </FPCardContent>
        {hasFooterActions && (
          <FPCardContent
            variant={footerVariant}
            className={clsx(classes.footerContainer, footerClassName)}
          >
            {footer || (
              <FooterActionsControlsLegacy
                secondaryAction={onClose}
                primaryProps={primaryProps}
                secondaryProps={secondaryProps}
                saveAction={saveAction}
              />
            )}
          </FPCardContent>
        )}
      </div>
    </Modal>
  );
};

FPModal.defaultProps = {
  title: '',
  saveAction: true,
  titleProps: {},
  secondaryProps: {},
  primaryProps: undefined,
  additionalHeaderActions: null,
  headerProps: {},
  fitContent: false,
  hasFooterActions: true,
  contentVariant: 'regular',
  footerVariant: 'relaxed',
  footerClassName: null,
  footer: null,
  customClasses: undefined
};

export default FPModal;
