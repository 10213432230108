import React from 'react';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { EntityRoutes } from 'routes/constants';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { RequestStatus } from 'UI/constants/status';

import { ErrorTemplate } from '../../shared/Widget/ErrorTemplate';
import { RegionalDirectorSummaryCard } from '../RegionalDirectorSummaryCard';

import { SkeletonCards } from './components';
import { useRegionalDirectorCards } from './RegionalDirectorCards.hooks';
import { CardWrapper } from './RegionalDirectorCards.styled';

export const RegionalDirectorCards = () => {
  const { cardsState, getData, canAccessActivity } = useRegionalDirectorCards();
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();
  const shouldShowPhoto = checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterTeamPhotos);

  if (cardsState.status === RequestStatus.Idle) {
    return null;
  }

  if (cardsState.status === RequestStatus.Loading) {
    return (
      <CardWrapper>
        <SkeletonCards shouldShowPhoto={shouldShowPhoto} />
      </CardWrapper>
    );
  }

  if (cardsState.status === RequestStatus.Error) {
    return <ErrorTemplate onReset={getData} />;
  }

  return (
    <CardWrapper>
      {cardsState.data.map(card => (
        <RegionalDirectorSummaryCard
          key={card.id}
          detailsUrl={
            canAccessActivity && EntityRoutes.CommandCenterActivityTeam.replace(':id', card.id)
          }
          healthStats={card.healthStats}
          financeStats={card.financeSummary}
          urlPhoto={card.imgURL}
          title={card.title}
          shouldShowPhoto={shouldShowPhoto}
        />
      ))}
    </CardWrapper>
  );
};
