import { EntityRoutes } from 'routes/constants';
import { PhoneColumns } from 'UI/constants/sharedColumns';

export const jobOrderProfileHAColumns = [
  {
    name: 'id',
    options: {
      filter: false,
      sort: false,
      print: false,
      display: 'excluded'
    }
  },
  {
    name: 'full_name',
    label: 'Name',
    options: {
      display: true,
      sort: false,
      renderer: {
        id: 'hiringAuthority',
        config: {
          route: EntityRoutes.HiringAuthorityProfile,
          idKey: 'id',
          textKey: 'full_name'
        }
      }
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      display: true,
      renderer: 'default'
    }
  },
  PhoneColumns.WORK_PHONE_LEGACY,
  PhoneColumns.PERSONAL_PHONE_LEGACY,
  {
    name: 'work_email',
    label: 'Email',
    options: {
      display: true,
      sort: false,
      renderer: 'email'
    }
  },
  {
    name: 'personal_email',
    label: 'Other Email',
    options: {
      display: true,
      sort: false,
      renderer: 'email'
    }
  },
  {
    name: 'position.title',
    label: 'Functional Title',
    options: {
      display: false,
      sort: false,
      renderer: 'default'
    }
  },
  {
    name: 'specialty.industry.title',
    label: 'Industry',
    options: {
      display: false,
      sort: false,
      renderer: 'default'
    }
  },
  {
    name: 'specialty.title',
    label: 'Specialty',
    options: {
      display: false,
      sort: false,
      renderer: 'default'
    }
  },
  {
    name: 'subspecialty.title',
    label: 'Subspecialty',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'lastActivityLog.title',
    label: 'Last Activity',
    options: {
      display: true,
      sort: false,
      renderer: 'default'
    }
  },
  {
    name: 'lastActivityLog.created_at',
    label: 'Last Activity Date',
    options: {
      display: true,
      sort: false,
      renderer: 'date'
    }
  },
  {
    name: 'lastActivityLog.user_name',
    label: 'Last Activity by',
    options: {
      display: false,
      sort: false,
      renderer: 'default'
    }
  }
];

export const HiringAuthorityVirtualProps = [
  {
    propName: 'full_name',
    attributes: ['id', 'full_name', 'position']
  }
];
