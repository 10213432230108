import { makeStyles } from '@material-ui/core/styles';
import { mainLayoutPadding } from 'UI/constants/dimensions';

export const useStyles = makeStyles(theme => ({
  fullWidthCard: {
    width: '100%',
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(mainLayoutPadding, 4, 1)
  },
  overline: {
    lineHeight: 2
  }
}));
