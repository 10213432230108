// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import HomeItemsCreation from 'UI/components/templates/HomeItemsCreation';
import { getId } from 'UI/utils';

type ColumnParam = {
  items: Array<Object>,
  size: number,
  xsSize?: number
};

type HomeItemsRendererProps = {
  columnsParam: Array<ColumnParam>,
  coachId?: number,
  regionalId?: number
};

const HomeItemsRenderer = ({ columnsParam, coachId, regionalId }: HomeItemsRendererProps) => {
  if (!columnsParam) return null;

  return (
    <Grid container direction="row" spacing={2} role="grid">
      {columnsParam.map(column => (
        <Grid item sm={column.size} xs={column.xsSize} key={getId()}>
          <Grid container direction="column" spacing={2} role="list">
            {column.items &&
              column.items.map(item => {
                return (
                  <Grid item xs={12} key={item.name}>
                    <HomeItemsCreation item={{ ...item, coachId, regionalId }} role="listitem" />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default HomeItemsRenderer;
