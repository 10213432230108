import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { extractObjectFromDataTable, getColumnsToRender } from 'hooks/datatable';
import { userHasRoles } from 'services/Authorization';
import FPLink from 'UI/components/atoms/FPLink';
import DataTable from 'UI/components/organisms/DataTable';
import PlacementEditForm from 'UI/components/organisms/placements/PlacementEditForm';
import { Roles } from 'UI/constants/roles';

import { ColumnsDefinitions } from './Collections.columns';
import { BoolDescription, FieldKeys, FormFields } from './Collections.fields';
import Form from './Collections.Form';
import { useTableStyles } from './Collections.styles';

const extraRenderers = {
  phoneLink: phoneNumber => <FPLink linkProps={{ type: 'ringCentral', url: phoneNumber }} />,
  booleanDescription: value => BoolDescription[value]
};

const CollectionsListing = ({
  closeDrawer,
  dataTable,
  enableCustomFilters,
  expandedRows,
  finalData,
  onExpansion,
  onFormCancel,
  onSubmitSucceed,
  onViewClick,
  uiState
}) => {
  const styles = useTableStyles();

  const hasSuperiorRole = userHasRoles([
    Roles.Coach,
    Roles.RegionalDirector,
    Roles.ProductionDirector,
    Roles.Operations,
    Roles.Finance,
    Roles.Leadership,
    Roles.AssistantRegionalDirector
  ]);

  const {
    columnPreferences,
    count,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersToggle,
    handleFiltersChange,
    handlePeriodChange,
    handleKeywordChange,
    handlePageChange,
    handlePerPageChange,
    listState
  } = dataTable;

  const { isLoading, showWholeSkeleton, page, perPage, keyword } = listState;
  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  const finalColumns = getColumnsToRender(columns, extraRenderers);

  return (
    <>
      <DataTable
        className={styles.denseCell}
        columnOrder={columnOrder?.length ? columnOrder : undefined}
        columns={finalColumns}
        count={count}
        data={finalData}
        draggableColumns={{ enabled: true }}
        enableCustomFilters={enableCustomFilters}
        expandableRows={hasSuperiorRole}
        expandableRowsHeader={false}
        filter={false}
        isExpandable
        isRowExpandable={() => true}
        loading={showWholeSkeleton}
        onColumnDisplayClick={handleColumnDisplayChange}
        onColumnOrderChange={handleColumnOrderChange}
        onColumnSortChange={handleColumnSortChange}
        onFiltersChange={handleFiltersChange}
        onMenuToggle={handleFiltersToggle}
        onPageClick={handlePageChange}
        onPeriodChange={handlePeriodChange}
        onPerPageClick={handlePerPageChange}
        onRowExpansionChange={onExpansion}
        onSearchTextChange={handleKeywordChange}
        onToggleFilters={handleFiltersToggle}
        page={page}
        refreshing={isLoading}
        rowsExpanded={expandedRows}
        rowsPerPage={perPage}
        searchText={keyword}
        selectableRows="none"
        sortOrder={{ name: orderBy, direction }}
        renderExpandableRow={(rowData, rowMeta) => {
          const colSpan = rowData.length + 1;
          const item = extractObjectFromDataTable(
            ColumnsDefinitions,
            [FieldKeys.Id, FieldKeys.PlacementId, ...FormFields],
            rowData
          );

          return hasSuperiorRole ? (
            <TableRow aria-label="collection actions">
              <TableCell colSpan={colSpan}>
                <Form
                  id={item.id}
                  commitAmount={item.commit_amount}
                  commitDate={item.commit_date}
                  commitToPay={item.commit_to_pay}
                  notes={item.collections_notes}
                  onCancel={onFormCancel}
                  onSubmitSucceed={onSubmitSucceed}
                  onViewClick={onViewClick}
                  placementId={item.otherrefnum}
                  rowIndex={rowMeta.rowIndex}
                />
              </TableCell>
            </TableRow>
          ) : null;
        }}
      />

      {uiState.isOpen && uiState.selectedId && (
        <Drawer aria-label="Placement Info" open onClose={closeDrawer}>
          <PlacementEditForm
            placementId={uiState.selectedId}
            onClose={closeDrawer}
            onCompleted={closeDrawer}
          />
        </Drawer>
      )}
    </>
  );
};

export default CollectionsListing;
