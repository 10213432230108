/**
 * The function `getWeekRange` takes a week object as input and returns an object containing the start
 * and end dates of the week.
 * @returns The function `getWeekRange` returns an object with two properties: `startDate` and
 * `endDate`.
 */
export const getWeekRange = week => {
  if (!week) return {};

  const daysToAdd = 6;

  const startDate = week.date;
  const start = new Date(startDate);
  const end = new Date(start);
  end.setDate(end.getDate() + daysToAdd);

  const endDate = end.toISOString().split('T')[0];

  return {
    startDate,
    endDate
  };
};

export const capitalizeName = name => {
  if (!name) return null;
  return name
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
