import { THEME } from 'GlobalStyles';

export const METRICS_MAP = Object.freeze({
  Headcount: 'headcount',
  OnTrack: 'onTrack',
  AtRisk: 'atRisk',
  Behind: 'behind',
  Under3Months: 'under3Months',
  InTraining: 'inTraining'
});

export const METRICS_TITLE = Object.freeze({
  [METRICS_MAP.Headcount]: 'Recruiters Headcount',
  [METRICS_MAP.OnTrack]: 'On Track',
  [METRICS_MAP.AtRisk]: 'At Risk',
  [METRICS_MAP.Behind]: 'Behind',
  [METRICS_MAP.Under3Months]: 'Under 3 Months',
  [METRICS_MAP.InTraining]: 'In Training'
});

export const METRICS = Object.freeze({
  [METRICS_MAP.Headcount]: {
    paramName: 'headcount',
    color: THEME.palette.grey[700]
  },
  [METRICS_MAP.OnTrack]: {
    paramName: 'onTrack',
    color: THEME.palette.success.main
  },
  [METRICS_MAP.AtRisk]: {
    paramName: 'atRisk',
    color: THEME.palette.error.main
  },
  [METRICS_MAP.Behind]: {
    paramName: 'behind',
    color: THEME.palette.warning.main
  },
  [METRICS_MAP.Under3Months]: {
    paramName: 'under3Months',
    color: THEME.palette.grey[600]
  },
  [METRICS_MAP.InTraining]: {
    paramName: 'inTraining',
    color: THEME.palette.info.main
  }
});
