// @flow
import { useEffect, useState } from 'react';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import API from 'services/API';
import { getErrorMessage } from 'UI/utils';

export const useFetch = (endpoint: string, initialValue: any) => {
  const [fetchState, setFetchState] = useState({
    result: initialValue,
    error: null
  });

  useEffect(() => {
    (async () => {
      if (!endpoint) return;
      try {
        const response = await API.get(endpoint);
        response.status === HTTPStatusCodes.Ok &&
          setFetchState(prev => ({ ...prev, result: response.data }));
      } catch (ex) {
        setFetchState(prev => ({ ...prev, error: getErrorMessage(ex) }));
      }
    })();
  }, [endpoint]);

  return [fetchState.result, fetchState.error];
};
