// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import uniqBy from 'lodash/uniqBy';
import strings from 'strings';
import FileItem from 'UI/components/molecules/FileItem';
import { FileStatus } from 'UI/constants/status';
import { formatBytes } from 'UI/utils';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

import { useStyles } from './styles';

interface FileSelectorProps {
  /** Validation to select max number of files */
  maxNumberOfFiles?: number;
  /** Validation to select max File sizes */
  maxFilesSize?: number;
  onFileChange: files => void;
  onFileDelete: file => void;
  onFileUpload: file => void;
  /** Name to handle with Form rules */
  name: String;
  disabled?: boolean;
  categories: Array;
  files?: Array;
  /** By default you want all checkbox selecteds  */
  allSelected: Boolean;
  modeExtraProps: Object;
}

const FileSelector = ({
  maxNumberOfFiles,
  maxFilesSize,
  categories,
  files,
  name,
  onFileDelete,
  children,
  allSelected,
  modeExtraProps
}: FileSelectorProps) => {
  const classes = useStyles();
  const [firstRender, setFirstRender] = useState(false);

  const { register, unregister, errors, getValues, setValue, watch } = useFormContext();
  const selectedFiles = watch(name);

  const totalFilesSize = selectedFiles ? sumBy(selectedFiles, 'size') : 0;

  useEffect(() => {
    register(name, {
      validate: value => {
        if (value && maxNumberOfFiles && value.length > maxNumberOfFiles) {
          return `Please select up to ${maxNumberOfFiles} file(s).`;
        }

        const totalSizeFiles = value ? sumBy(value, 'size') : 0;
        if (value && maxFilesSize && totalSizeFiles > maxFilesSize) {
          return `The selected files exceed the size limit to send (${formatBytes(
            totalSizeFiles
          )} / ${formatBytes(maxFilesSize)})`;
        }
        return null;
      }
    });
    return () => unregister(name);
  }, [name, register, unregister, maxNumberOfFiles, maxFilesSize]);

  const handleFileDispatch = useCallback(() => {
    if (allSelected && !firstRender) {
      setValue(name, files);
      setFirstRender(true);
      return;
    }

    const lastValue = files[files.length - 1];
    const allValues = getValues(name);
    if (!lastValue || lastValue?.isLoading || !allValues) return;

    const isSelectedValue = allValues.some(file => file?.file_name === lastValue.file_name);
    const shouldAppend = !isSelectedValue && lastValue.type === FileStatus.New;
    if (shouldAppend) setValue(name, [...allValues, lastValue]);
  }, [files, name, setValue, getValues, firstRender, allSelected]);

  useEffect(() => {
    handleFileDispatch();
  }, [handleFileDispatch]);

  const handleSelectFile = event => {
    const { name: checkFileName, checked } = event.target || event;
    const currentValues = getValues(name) || [];
    const selectedFile = files.find(file => file.file_name === checkFileName);
    if (!selectedFile || !currentValues) return;

    const selectedMethod = checked ? appendFileToArray : removeFileFromArray;

    const newFiles = selectedMethod(currentValues, selectedFile);
    setValue(name, newFiles, true);
  };

  const removeFileFromArray = (currentFiles, oldFile) =>
    currentFiles.filter(file => file.file_name !== oldFile.file_name);

  const appendFileToArray = (currentFiles, newFile) =>
    !currentFiles.some(file => file.file_name === newFile.file_name) && [...currentFiles, newFile];

  const handleDeleteFile = file => {
    handleSelectFile({ name: file.file_name, checked: false });
    onFileDelete(file);
  };

  const isMaxFilesLimit =
    selectedFiles && maxNumberOfFiles && selectedFiles.length >= maxNumberOfFiles;
  const isMaxSizeLimit = totalFilesSize && maxFilesSize && totalFilesSize > maxFilesSize;

  const isLimitOfFiles = isMaxSizeLimit || isMaxFilesLimit;
  const filesByCategory = groupBy(files, 'file_type_id');

  const filterCategories = files.map(file => file.fileType || {});
  const uniqueFileTypes = uniqBy(filterCategories, 'id');
  const allCategories = categories && categories.length > 0 ? categories : uniqueFileTypes;

  return (
    <>
      <FormControl component="fieldset" error={!!errors[name]} className={classes.wrapperFileView}>
        <FormGroup aria-label={name} name={name}>
          <List>
            {allCategories.length > 0 &&
              allCategories?.map(
                category =>
                  category.id && (
                    <React.Fragment key={category.id}>
                      <ListSubheader className={classes.subheader} disableSticky>
                        {category.title}
                      </ListSubheader>
                      {filesByCategory[category.id]?.map(attachment => (
                        <FileItem
                          showMenu
                          showSelector
                          key={attachment.id || attachment.name}
                          fileName={attachment?.file_name || attachment?.name}
                          file={attachment}
                          error={!!attachment.hasError}
                          loading={!!attachment.isLoading}
                          message={attachment.message}
                          showFileSize
                          onFileDelete={onFileDelete && handleDeleteFile}
                          selectorProps={{
                            checked:
                              selectedFiles?.some(file => file.id === attachment.id) || false,
                            disabled:
                              isLimitOfFiles &&
                              !selectedFiles.some(file => file.id === attachment.id),
                            onChange: handleSelectFile
                          }}
                          {...modeExtraProps}
                        />
                      ))}
                    </React.Fragment>
                  )
              )}
          </List>
        </FormGroup>
        <FormHelperText>{errors[name]?.message}</FormHelperText>
      </FormControl>
      <Grid direction="column" style={{ ...flexAlignCenterJustifyCenter }} container>
        <Typography className={classes.textDontUploaded} variant="subtitle1">
          <b>{strings.shared.files.defaultSelectorDescription}</b>
        </Typography>
        {children}
      </Grid>
    </>
  );
};

FileSelector.defaultProps = {
  maxNumberOfFiles: null,
  maxFilesSize: null,
  onFileChange: () => {},
  onFileDelete: () => {},
  onFileUpload: () => {},
  categories: [],
  files: [],
  name: '',
  endpoint: '',
  allSelected: false
};

export default FileSelector;
