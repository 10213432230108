export const changeTypeAheadParams = ({ value, typeAheadParams }) => {
  const includesLetters = value.match(/[a-zA-Z]/);
  return !includesLetters
    ? {
        ...typeAheadParams,
        keyword: undefined,
        partialPhone: value
      }
    : typeAheadParams;
};
