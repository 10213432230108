export const formatResponse = response => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    companyName: {
      companyName: item.companyName,
      companyId: item.companyId
    },
    industrySpecilty: {
      industry: {
        title: item.industryTitle
      },
      specialty: item.specialtyTitle
    },
    hiringAuthorityPhones: {
      hiringAuthorityPhones: item.hiringAuthorityPhones,
      hiringAuthoriyPhones: item.hiringAuthorityPhone
    },
    statusTitle: {
      status: item.statusTitle,
      color: item.statusStyleClassName
    },
    contactValues: item.hiringAuthorityCommunicationActions?.email
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};
