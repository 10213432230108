// @flow
import * as React from 'react';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

type NumberedFormProps = {
  children: React.Node
};

const NumberedForm = ({ children }: NumberedFormProps) => {
  const classes = useStyles();

  return <ol className={classes.list}>{children}</ol>;
};

type NumberedFormItemProps = {
  title: string,
  showTitleGutter?: boolean,
  children: React.Node,
  action?: React.Node
};

const NumberedFormItem = ({ title, showTitleGutter, children, action }: NumberedFormItemProps) => {
  const classes = useStyles();

  return (
    <li className={classes.item}>
      <div className={classes.headerContainer}>
        <Typography component="h2" className={classes.itemTitle} gutterBottom={showTitleGutter}>
          {title}
        </Typography>
        {action && <div>{action}</div>}
      </div>
      <div className={classes.itemContent}>{children}</div>
    </li>
  );
};

NumberedFormItem.defaultProps = {
  showTitleGutter: true,
  action: undefined
};

NumberedForm.Item = NumberedFormItem;

const NumberedFormContainer = ({ children }: props) => {
  const classes = useStyles();

  return (
    <div className={classes.item}>
      <div className={classes.itemContent}>{children}</div>
    </div>
  );
};

NumberedForm.Container = NumberedFormContainer;

export default NumberedForm;
