// @flow
import React from 'react';
import Divider from '@material-ui/core/Divider';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import TextBox from 'UI/components/atoms/TextBox';
import { minWidthTextBox } from 'UI/constants/dimensions';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { DeleteIcon, SvgContentPaperEdit } from 'UI/res';
import { hasFeatureFlag } from 'UI/utils';

import { formatPhoneNumbers, PHONE_FORMAT_MODES } from '../PhoneNumbersForm/utils';

import { contentIcon, useStyles } from './styles';

type HiringAuthorityPreviewProps = {
  data: Array<any>,
  onHiringAuthorityEdit: (item: any) => void,
  onHiringAuthorityDelete: (item: any) => void
};

const HiringAuthorityPreview = ({
  data,
  onHiringAuthorityEdit,
  onHiringAuthorityDelete
}: HiringAuthorityPreviewProps) => {
  const classes = useStyles();
  const handleEditClick = item => {
    const itemWithPhones = {
      ...item,
      phones: formatPhoneNumbers(item, PHONE_FORMAT_MODES.preload)
    };
    onHiringAuthorityEdit && onHiringAuthorityEdit(itemWithPhones);
  };
  const handleDeleteClick = item => onHiringAuthorityDelete && onHiringAuthorityDelete(item);
  const isMultiplePhonesEnabled = hasFeatureFlag(FeatureFlags.HiringAuthoritiesMultiplePhones);

  if (data.length <= 0) return null;

  return (
    <div>
      {data.length > 0 &&
        data.map((item, index) => {
          const isExistingHA = item.id && item.hiring_authority_id;
          const phones =
            item.isContact || item.isContact === undefined
              ? formatPhoneNumbers(item, PHONE_FORMAT_MODES.preload)
              : item?.previewPhones;
          const shouldDisplayLegacyPhoneFields = !isMultiplePhonesEnabled;

          return (
            <div key={item.localId}>
              <div className={classes.actionsContainer}>
                <div style={contentIcon}>
                  {/* Edition is not allowed here for previously existing HA */}
                  {!isExistingHA && (
                    <FPIconButton
                      icon={SvgContentPaperEdit}
                      aria-label="edit"
                      onClick={() => handleEditClick(item)}
                    />
                  )}
                  <FPIconButton
                    icon={DeleteIcon}
                    iconMode="custom"
                    aria-label="delete"
                    onClick={() => handleDeleteClick(index)}
                  />
                </div>
              </div>
              <div className={classes.formContainer}>
                <TextBox
                  label="First Name"
                  width="100%"
                  minWidth={minWidthTextBox}
                  name="first_name"
                  disabled
                  value={item.first_name}
                />
                <TextBox
                  label="Last Name"
                  width="100%"
                  minWidth={minWidthTextBox}
                  name="last_name"
                  disabled
                  value={item.last_name}
                />
                <TextBox
                  label="Nickname"
                  width="100%"
                  minWidth={minWidthTextBox}
                  name="nickname"
                  disabled
                  value={item.nickname}
                />
                <TextBox
                  label="Industry: Specialty"
                  width="100%"
                  minWidth={minWidthTextBox}
                  name="specialty"
                  disabled
                  value={`${item.specialty?.industry_title || item.specialty?.industry?.title}: ${
                    item.specialty?.title
                  }`}
                />
                <TextBox
                  label="Subspecialty"
                  width="100%"
                  minWidth={minWidthTextBox}
                  name="subspecialty"
                  disabled
                  value={item.subspecialty?.title || ''}
                />
                <TextBox
                  label="Functional title"
                  width="100%"
                  minWidth={minWidthTextBox}
                  name="position"
                  disabled
                  value={item.position?.title}
                />
                <TextBox
                  label="Title"
                  width="100%"
                  minWidth={minWidthTextBox}
                  name="title"
                  disabled
                  value={item.title}
                />
                <TextBox
                  label="Email"
                  width="100%"
                  minWidth={minWidthTextBox}
                  name="work_email"
                  disabled
                  value={item.work_email}
                />
                <TextBox
                  label="Other Email"
                  width="100%"
                  minWidth={minWidthTextBox}
                  name="personal_email"
                  disabled
                  value={item.personal_email}
                />
                <TextBox
                  label="LinkedIn URL"
                  width="100%"
                  minWidth={minWidthTextBox}
                  name="link_profile"
                  disabled
                  value={item.link_profile}
                />
                {shouldDisplayLegacyPhoneFields && (
                  <>
                    <TextBox
                      label="Phone"
                      width="100%"
                      minWidth={minWidthTextBox}
                      name="work_phone"
                      disabled
                      value={item.work_phone}
                      inputType="phone"
                    />
                    <TextBox
                      name="ext"
                      label="Ext"
                      placeholder="999"
                      minWidth={minWidthTextBox}
                      value={item.ext}
                      disabled
                    />
                    <TextBox
                      label="Other Phone"
                      width="100%"
                      minWidth={minWidthTextBox}
                      name="personal_phone"
                      disabled
                      value={item.personal_phone}
                      inputType="phone"
                    />
                    <TextBox
                      name="other_ext"
                      label="Ext"
                      placeholder="999"
                      minWidth={minWidthTextBox}
                      value={item.other_ext}
                      disabled
                    />
                  </>
                )}
              </div>
              {phones.map(phone => {
                const countryCodeValue = `(${phone.countryCode.country_iso_2}) ${phone.countryCode.code}`;
                return (
                  <div key={phone.id} className={classes.phonesContainer}>
                    <TextBox
                      name="countryCode"
                      label="Country Code"
                      placeholder="Country Code"
                      minWidth={minWidthTextBox}
                      value={countryCodeValue}
                      disabled
                    />
                    <TextBox
                      name="phone"
                      label="Phone"
                      placeholder="Phone"
                      minWidth={minWidthTextBox}
                      value={phone?.phone}
                      inputType="phone"
                      disabled
                    />
                    <TextBox
                      name="ext"
                      label="Ext"
                      placeholder="Ext"
                      minWidth={minWidthTextBox}
                      value={phone?.ext}
                      disabled
                    />
                    <TextBox
                      name="use"
                      label="Use"
                      placeholder="Use"
                      minWidth={minWidthTextBox}
                      value={phone?.use?.title}
                      disabled
                    />
                    <TextBox
                      name="type"
                      label="Type"
                      placeholder="Type"
                      minWidth={minWidthTextBox}
                      value={phone?.type?.title}
                      disabled
                    />
                  </div>
                );
              })}
              <div className={classes.actionsContainer}>
                {data.length > 1 && index - 1 < data.length - 2 && <Divider />}
              </div>
            </div>
          );
        })}
    </div>
  );
};

HiringAuthorityPreview.defaultProps = {};

export default HiringAuthorityPreview;
