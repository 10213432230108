// @flow
import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import type { ContentPageLayoutProps } from 'types/app';
import { LAYOUT_BREAKPOINT } from 'UI/components/organisms/inventoryProfiles/commons';
import { SHARED_SKELETONS_PROPS } from 'UI/components/organisms/inventoryProfiles/SkeletonWrapper/constants';

import ContentPageLayout, { PageHeadline } from '../ContentPageLayout';

import ActionsBar from './ActionsBar';
import { SETTINGS_GRID_CONFIG } from './constants';
import { useStyles } from './styles';

const { buttons } = SHARED_SKELETONS_PROPS;

type FullWidthCardHeaderProps = {
  actionsBar: React.Node,
  additionalHeaderActions?: any,
  contentPageLayoutProps: ContentPageLayoutProps,
  headerCardProps?: HeaderCardProps,
  headerContent: React.Node,
  isLoading?: boolean,
  menuItems?: Array<Object>,
  pageContent: React.Node,
  statusBar: React.Node,
  statusBar: React.Node,
  subtitle: string,
  additionalCardClasses: Object
};

const FullWidthCardHeader = ({
  actionsBar,
  additionalHeaderActions,
  contentPageLayoutProps,
  headerCardProps: { sectionName, title, titleLabelProps },
  headerContent,
  isLoading,
  menuItems,
  pageContent,
  statusBar,
  subtitle,
  additionalCardClasses
}: FullWidthCardHeaderProps) => {
  const classes = useStyles();
  const isSmallViewPort = useMediaQuery(LAYOUT_BREAKPOINT);

  const actionsBarConfig = additionalHeaderActions
    ? SETTINGS_GRID_CONFIG.actionBar.headerActions
    : SETTINGS_GRID_CONFIG.actionBar.headerWithoutActions;

  const customTitleGridConfig = additionalHeaderActions
    ? SETTINGS_GRID_CONFIG.titleBar.headerActions
    : SETTINGS_GRID_CONFIG.titleBar.headerWithoutActions;

  return (
    <ContentPageLayout mode="customLayout" {...contentPageLayoutProps}>
      {statusBar}
      <div className={clsx(classes.fullWidthCard, additionalCardClasses)}>
        <PageHeadline
          actionsBarConfig={actionsBarConfig}
          customTitleGridConfig={customTitleGridConfig}
          pageHeadlineGrid={isSmallViewPort && { direction: 'column-reverse' }}
          sectionName={sectionName}
          text={title}
          titleLabelProps={{
            backNavigation: true,
            ...titleLabelProps,
            loading: isLoading,
            subtitle,
            subtitleProps: { variant: 'overline', className: classes.overline }
          }}
          actionsBar={
            <ActionsBar
              isLoading={isLoading}
              buttons={buttons}
              actionsBar={actionsBar}
              additionalHeaderActions={additionalHeaderActions}
              menuItems={menuItems}
            />
          }
        />
        {headerContent}
      </div>
      {pageContent}
    </ContentPageLayout>
  );
};

FullWidthCardHeader.defaultProps = {
  additionalHeaderActions: null,
  menuItems: [],
  headerCardProps: { sectionName: '', title: '', titleLabelProps: null },
  isLoading: false
};

export default FullWidthCardHeader;
