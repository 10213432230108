import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  center: {
    alignItems: 'center'
  },
  confirmationLabel: {
    fontSize: 14,
    fontWeight: '400 !important',
    lineHeight: `${theme.spacing(2.5)}px`,
    marginLeft: theme.spacing(1)
  },
  eventHistoryLabel: {
    fontSize: 14,
    fontWeight: '500 !important'
  },
  lastActivityDateLabel: {
    fontSize: 18,
    fontWeight: '400 !important',
    lineHeight: `${theme.spacing(2.5)}px`,
    marginLeft: theme.spacing(1)
  },
  lastActivityInfoLabel: {
    color: theme.palette.customColors.darkGrey,
    fontSize: 16,
    fontWeight: '400 !important',
    lineHeight: `${theme.spacing(3)}px`,
    marginLeft: theme.spacing(3)
  },
  noReminderLabel: {
    fontSize: 18,
    fontWeight: '400 !important'
  },
  row: {
    display: 'flex'
  },
  secondaryText: {
    color: theme.palette.grey[800],
    fontSize: 12,
    fontWeight: '400 !important',
    opacity: '0.60',
    marginLeft: theme.spacing(1.5)
  }
}));
