import React from 'react';
import Box from '@material-ui/core/Box';
import strings from 'strings';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import { Endpoints } from 'UI/constants/endpoints';
import { ContactRole, ContactRoleStrings } from 'UI/constants/entityTypes';
import { OptionRenderers } from 'UI/utils/renderers';

import { changeTypeAheadParams } from './SearchInput.utils';

export const SearchInput = ({ onSelectValue, contact }) => {
  const handleSelect = (name, value) => {
    onSelectValue(value);
  };

  const { globalSearchPerson } = OptionRenderers;

  const optionParams = {
    fallback: null,
    chipPropsBuilder: null,
    showOtherEmail: true
  };

  const handleRenderOption = option => {
    const createSubtitle = fromOption => {
      if (fromOption.role_id === ContactRole.Candidate) {
        return `${fromOption.title} / ${fromOption.location}`;
      }
      return `${fromOption.current_company} / ${fromOption.title}`;
    };

    const newOption = {
      ...option,
      role: ContactRoleStrings[option.role_id],
      subtitle: createSubtitle(option)
    };

    return globalSearchPerson(
      'full_name',
      optionParams.fallback,
      optionParams.chipPropsBuilder,
      optionParams.showOtherEmail
    )(newOption);
  };

  return (
    <AutocompleteSelect
      url={Endpoints.Names}
      label={strings.messageCenter.newConversation.searchInputLabel}
      apiVersion={2}
      displayKey="full_name"
      typeahead
      typeaheadLimit={50}
      typeaheadParams={{
        perPage: 50
      }}
      renderGroup={params => {
        const finalGroup = ContactRoleStrings[params.group];
        return (
          <div>
            <Box paddingX={1} fontWeight={600}>
              {finalGroup}
            </Box>
            {params.children}
          </div>
        );
      }}
      groupBy={option => option.role_id}
      renderOption={handleRenderOption}
      selectedValue={contact}
      onSelect={handleSelect}
      changeTypeAheadParams={changeTypeAheadParams}
    />
  );
};
