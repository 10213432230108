// @flow
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { FileDraggableProps as FileDraggablePropsType } from 'types/app';
import FileDraggable from 'UI/components/molecules/FileDraggable';
import FileDropzone from 'UI/components/molecules/FileDropzone';

import { AttachmentsByCategory, File } from '../fileManager.types';

import { useStyles } from './styles';

type CategoryCardProps = {
  attachments: Array<AttachmentsByCategory>,
  category: AttachmentsByCategory,
  FileDraggableProps: FileDraggablePropsType,
  fileNameField: string,
  layoutInColumns: boolean,
  onFileDelete: (file: File) => void,
  onFileMove: (file: File) => void,
  onUploadFiles: (files: Array<Files>, categoryId: number) => void,
  readOnly: boolean,
  showFileSize: boolean,
  showItemDate: boolean,
  showItemMenu: boolean,
  viewItemOnClick: boolean
};

const CategoryCard = ({
  attachments,
  category,
  FileDraggableProps,
  fileNameField,
  layoutInColumns,
  onFileDelete,
  onFileMove,
  onUploadFiles,
  readOnly,
  showFileSize,
  showItemDate,
  showItemMenu,
  viewItemOnClick
}: CategoryCardProps) => {
  const classes = useStyles();

  if (!category) return null;
  return (
    <Card
      className={layoutInColumns ? classes.columnContainer : classes.rowContainer}
      variant="outlined"
    >
      <CardContent>
        <div className={classes.headersContainer}>
          <Typography variant="h6">{category.title}</Typography>
          <Typography variant="body2">
            Count: {category.files.length}
            {!category.multiple && ` / ${category.maxFiles}`}
          </Typography>
        </div>
        <Droppable droppableId={category.id.toString()}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={snapshot.isDraggingOver ? classes.fileListDragging : classes.fileList}
            >
              <List>
                {category.files.map((file, index) => (
                  <FileDraggable
                    key={file.id === 0 ? file[fileNameField] : file.id}
                    file={file}
                    fileNameField={fileNameField}
                    index={index}
                    onFileDelete={onFileDelete}
                    onFileMove={onFileMove}
                    readOnly={readOnly}
                    showFileSize={showFileSize}
                    showItemDate={showItemDate}
                    showItemMenu={showItemMenu}
                    viewItemOnClick={viewItemOnClick}
                    {...FileDraggableProps}
                  />
                ))}
              </List>

              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {!readOnly && (
          <FileDropzone
            attachments={attachments}
            categoryId={category.id}
            fileNameField={fileNameField}
            filesCount={category.files.length}
            maxFiles={category.maxFiles}
            multiple={!!category.multiple}
            onUploadFiles={onUploadFiles}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default CategoryCard;
