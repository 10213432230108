// @flow

import differenceWith from 'lodash/differenceWith';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { EntityType } from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { FileStatus, PlacementSplitTypes } from 'UI/constants/status';
import { getId } from 'UI/utils';
import { removeQueryParams } from 'UI/utils/inventory';
import { updateUiState } from 'UI/utils/uiHelpers';

export const RecruiterKeyPrefix = 'split_recruiter_';
export const PercentKeyPrefix = 'split_percent_';
export const FileKeyPrefix = 'file_';
export const DefaultChannelPartnerPercentage = 5;
export const ReferenceCheckFileId = 7;
export const MinimumRequiredCompletedReferences = 1;
export const ExpectedCompletedReferences = 2;

export const PlacementRoleHierarchy = [
  Roles.Finance,
  Roles.ProductionDirector,
  Roles.Leadership,
  Roles.Operations,
  Roles.RegionalDirector,
  Roles.Coach,
  Roles.AssistantRegionalDirector,
  Roles.Recruiter
];

export const normalizeUserPersonalInformation = (user: any) => {
  return user
    ? {
        ...user,
        full_name: get(user, 'personalInformation.full_name')
      }
    : null;
};

/**
 * Backend returns recruiters and channel partners as a flat array. This method joins/groups each
 * direct recruiter in the split with their corresponding channel partners.
 */
export const joinSplitsWithChannelPartners = (splits: Split[]) => {
  const channelPartnersSplits = splits.filter(({ is_channel_partner }) => is_channel_partner);
  const directSplits = splits.filter(({ is_channel_partner }) => !is_channel_partner);

  return directSplits.map(split => {
    const nextChannelPartners = channelPartnersSplits.filter(({ id }) => id > split.id); // under assumption that splits are ordered by type and id
    const channelPartners = split.channelPartners
      ? split.channelPartners.map(channelPartner =>
          nextChannelPartners.find(ncp => ncp.user.id === channelPartner.id)
        )
      : [];
    return {
      ...split,
      channelPartners
    };
  });
};

export const getFilesThatChanged = (fileCategories: any[], oldFiles: any[], formData: any) => {
  const filesThatChanged = [];
  const newFiles = [];

  fileCategories.forEach(({ id: categoryId }) => {
    const newFilesForCategory = formData[`${FileKeyPrefix}${categoryId}`];
    newFilesForCategory && newFilesForCategory.forEach(eachFile => newFiles.push(eachFile));
  });

  const fileComparator = (a, b) => a.id === b.id && a.file_type_id && b.file_type_id;
  const filesToDelete = differenceWith(oldFiles, newFiles, fileComparator);
  const filesToAdd = differenceWith(newFiles, oldFiles, fileComparator);

  filesToDelete.forEach(({ id, entity, file_type_id: fileTypeId }) =>
    filesThatChanged.push({
      id,
      entity,
      type_id: fileTypeId,
      action: 'delete'
    })
  );

  filesToAdd.forEach(({ id, entity, file_type_id: fileTypeId }) =>
    filesThatChanged.push({
      id,
      entity,
      type_id: fileTypeId
    })
  );

  return filesThatChanged;
};

/**
 *
 * @param {*} splits Array of direct splits with corresponding channel partners on each item
 * @param {*} formData Data from the form
 * @returns Flat array of direct recruiter plus channel partners and proper references
 */

export const flattenSplits = (splits: Split[], formData: any) => {
  const orderedSplits = orderBy(splits, ['type'], ['desc']);
  const finalSplits = [];

  orderedSplits.forEach(({ id: splitId, type, channelPartners, coach_id }) => {
    const hasChannelPartners = channelPartners?.length > 0;

    const finalChannelPartners = hasChannelPartners
      ? channelPartners.map(channelPartner => ({
          id: formData[`${RecruiterKeyPrefix}${channelPartner.id}`]?.id,
          percent: Number.parseFloat(formData[`${PercentKeyPrefix}${channelPartner.id}`]),
          coach_id: channelPartner.coach_id
        }))
      : [];

    const directRecruiter = {
      type,
      user_id: formData[`${RecruiterKeyPrefix}${splitId}`]?.id,
      is_channel_partner: 0,
      percent: Number.parseFloat(formData[`${PercentKeyPrefix}${splitId}`]),
      channel_user_ids: finalChannelPartners.map(({ id }) => id),
      coach_id
    };
    finalSplits.push(directRecruiter);

    hasChannelPartners &&
      finalChannelPartners.forEach(channelPartner => {
        finalSplits.push({
          type,
          user_id: channelPartner.id,
          is_channel_partner: 1,
          percent: Number.parseFloat(channelPartner.percent),
          coach_id: channelPartner.coach_id
        });
      });
  });

  return finalSplits;
};

export const buildFileExplorerSections = (
  candidateFiles: any[],
  jobOrderFiles: any[],
  companyFiles: any[]
) => [
  { id: EntityType.Candidate, title: 'Candidate files', files: candidateFiles || [] },
  { id: EntityType.Joborder, title: 'Job Order files', files: jobOrderFiles || [] },
  { id: EntityType.Company, title: 'Company files', files: companyFiles || [] }
];

export const calculateSplitAmounts = (
  recruiterPercent: number,
  amount: number,
  channelPartnerPercent: number
) => {
  const wholeCommision = (amount * recruiterPercent) / 100;
  const amountForRecruiter = !channelPartnerPercent
    ? wholeCommision
    : wholeCommision - (wholeCommision * channelPartnerPercent) / 100;
  const amountForChannelPartner = channelPartnerPercent ? wholeCommision - amountForRecruiter : 0;
  return {
    recruiter: amountForRecruiter,
    channelPartner: amountForChannelPartner
  };
};

/**
 *
 * @param {*} recruiterPercent Split percentage for this recruiter
 * @param {*} invoiceAmount  Amount that is invoiced to the client
 * @param {*} channelPartners List of channel parteners, used to calculate the total percent for them
 * @returns An object with the whole comission for the split and the amount for recruiter minus the channels partners income
 */

export const calculateComissions = (
  recruiterPercent: number,
  invoiceAmount: number,
  channelPartners: any[]
) => {
  const channelPartnersTotalPercent =
    channelPartners?.length > 0
      ? channelPartners.reduce((accumulator, current) => accumulator + current.percent, 0)
      : 0;

  const wholeCommision = (invoiceAmount * recruiterPercent) / 100;
  const amountForRecruiter = !channelPartnersTotalPercent
    ? wholeCommision
    : wholeCommision - (wholeCommision * channelPartnersTotalPercent) / 100;

  return {
    whole: wholeCommision,
    forRecruiter: amountForRecruiter
  };
};

/**
 *
 * @param {any} user
 * @param {string} type
 * @param {percent} percent
 * @returns
 */

export const normalizeChannelPartner = ({ user, type, percent }) =>
  user
    ? {
        id: getId(),
        type,
        recruiter: normalizeUserPersonalInformation(user),
        isChannelPartner: true,
        percent
      }
    : null;

export const getPlacementActions = ({
  history,
  location,
  onRefreshPlacementTable,
  onTriggerTabRefresh,
  setUiState
}) => {
  const handlePlacementCompleted = () => {
    handlePlacementClosed();
    onTriggerTabRefresh('placements');
    onRefreshPlacementTable();
  };

  const handlePlacementClosed = () => {
    updateUiState(
      { isPlacementOpen: false, selectedPlacementId: null, selectedSendoutId: null },
      setUiState
    );
    history.replace({
      search: removeQueryParams(location.search, ['form', 'id']),
      hash: location.hash
    });
  };

  return {
    handlePlacementCompleted,
    handlePlacementClosed
  };
};

const normalizeFile = (file, entity) => ({ ...file, entity, type: FileStatus.Existing });

/** This method matches existing files from candidate or job order profiles
 * with corresponding placement categories based on a common property: parentFileTypeId
 */
export const matchFilesWithCategories = ({
  categories,
  candidateFiles,
  jobOrderFiles,
  interviewFiles = []
}) => {
  if (!categories || !candidateFiles || !jobOrderFiles) return null;

  const filesByCategory = {};
  const normalizedFiles = [
    ...candidateFiles.map(file => normalizeFile(file, EntityType.Candidate)),
    ...jobOrderFiles.map(file => normalizeFile(file, EntityType.Joborder)),
    ...interviewFiles.map(file => normalizeFile(file, EntityType.Interview))
  ];

  categories.forEach(({ id: categoryId, parent_file_type_id: parentFileTypeId }) => {
    filesByCategory[categoryId] = normalizedFiles.filter(
      file =>
        file.file_type_id === parentFileTypeId ||
        file?.fileType?.parent_file_type_id === parentFileTypeId
    );
  });

  return filesByCategory;
};

export const normalizeInitialSplits = ({
  companyRecruiter,
  companyChannelPartners,
  candidateRecruiter,
  candidateChannelPartners
}) => {
  const splits = [];
  const isSoloDeal = companyRecruiter?.id === candidateRecruiter?.id;

  companyRecruiter &&
    splits.push({
      recruiter: companyRecruiter,
      type: EntityType.Company,
      id: getId(),
      channelPartners:
        companyChannelPartners &&
        companyChannelPartners.map(companyChannelPartner =>
          normalizeChannelPartner({
            user: companyChannelPartner.referralUser,
            type: EntityType.Company,
            percent: companyChannelPartner.percent
          })
        )
    });

  !isSoloDeal &&
    candidateRecruiter &&
    splits.push({
      recruiter: candidateRecruiter,
      type: EntityType.Candidate,
      id: getId(),
      channelPartners:
        candidateChannelPartners &&
        candidateChannelPartners.map(candidateChannelPartner =>
          normalizeChannelPartner({
            user: candidateChannelPartner.referralUser,
            type: EntityType.Candidate,
            percent: candidateChannelPartner.percent
          })
        )
    });
  return splits;
};

export const EmailsSeparator = ', ';

export const getCompanyRecruitersEmailsFromSplits = (splits = []) => {
  if (!splits) return '';

  const companyRecruiters = splits.reduce((accumSplits, { type, recruiter }) => {
    return type === PlacementSplitTypes.Company && recruiter
      ? accumSplits.concat(recruiter.email)
      : accumSplits;
  }, []);

  return companyRecruiters.join(EmailsSeparator);
};
