// @flow

export const CANDIDATE_DATA_SHEET_FULL_QUESTIONS_PATHS = {
  'establishingUrgency.whatIsTheReason': [],
  'establishingUrgency.howLongHaveYouBeenLooking': [],
  'establishingUrgency.idealTimeToStart': [],
  'establishingUrgency.onAScaleOf1To10.question': [],
  'establishingUrgency.onAScaleOf1To10.whatKeepsYouFromBeingA10': [],
  'establishingUrgency.onAScaleOf1To10.haveYouMadeSomething.question': [],
  'establishingUrgency.onAScaleOf1To10.haveYouMadeSomething.whatStepsHaveYouTaken': [],
  'establishingUrgency.onAScaleOf1To10.haveYouMadeSomething.whatCompaniesHaveYouAppliedTo': [],
  'establishingUrgency.isThereAnythingPersonal': [],
  'establishingUrgency.ifACompanyOffersYou': [],
  'workPreferences.whatDoYouLikeMost': [],
  'workPreferences.preferredNextTitle': [],
  'workPreferences.relocation.areYouWillingToRelocate': [],
  'workPreferences.relocation.relocationDestinations': [],
  'workPreferences.workPreference': [],
  'workPreferences.salary': [],
  'workPreferences.salaryRange': ['lowSalary', 'idealSalary', 'highSalary'],
  'professionalInsights.achievements': [],
  'professionalInsights.skills': [],
  'qualification.candidateType': [],
  'qualification.candidateStatus': []
};

export const CANDIDATE_DATA_SHEET_FULL_QUESTIONS_PATHS_REQUIRED = {
  'establishingUrgency.whatIsTheReason': [],
  'establishingUrgency.howLongHaveYouBeenLooking': [],
  'establishingUrgency.idealTimeToStart': [],
  'workPreferences.whatDoYouLikeMost': [],
  'workPreferences.relocation.areYouWillingToRelocate': [],
  'workPreferences.relocation.relocationDestinations': [],
  'workPreferences.workPreference': [],
  'workPreferences.salary': [],
  'workPreferences.salaryRange': ['lowSalary', 'idealSalary', 'highSalary'],
  'professionalInsights.achievements': [],
  'professionalInsights.skills': [],
  'qualification.candidateType': [],
  'qualification.candidateStatus': []
};
