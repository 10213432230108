import { useEffect, useState } from 'react';
import API from 'services/API';
import { Endpoints } from 'UI/constants/endpoints';

export const useHomeData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [columns, setColumns] = useState(null);

  useEffect(() => {
    const buildColumns = async () => {
      const { data: oldData } = (await API.get(Endpoints.HomeConfig)).data;
      oldData && setColumns(oldData.columns);
    };

    const getConfig = async () => {
      setIsLoading(true);
      try {
        const { data } = (await API.get(Endpoints.HomeConfigTabs)).data;
        const isMultiSectionHome = Array.isArray(data);
        if (isMultiSectionHome) {
          const tabsConfig = data.map(tab => ({
            id: tab.config.id,
            config: tab.config,
            label: tab.config.title
          }));

          tabsConfig && setTabs(tabsConfig);
        } else {
          await buildColumns();
        }
      } catch (error) {
        await buildColumns();
      } finally {
        setIsLoading(false);
      }
    };

    getConfig();

    return () => {
      setIsLoading(false);
    };
  }, []);

  return { columns, isLoading, tabs };
};
