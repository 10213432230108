import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';
import { colors } from 'UI/res';

export const styles = {
  marginTop: THEME.spacing(8)
};

export const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: colors.white
  },
  text: { fontSize: theme.spacing(3), textAlign: 'center' },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  shadow: {
    ...theme.textShadow
  }
}));
