// @flow
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Grid } from '@material-ui/core';
import { confirm, showAlert } from 'actions/app';
import moment from 'moment';
import { EntityRoutes } from 'routes/constants';
import { SplitSearchProject } from 'services/searchProjects';
import strings from 'strings';
import Text from 'UI/components/atoms/Text';
import TextBox from 'UI/components/atoms/TextBox';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import InfoLabel from 'UI/components/molecules/InfoLabel';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { SearchProjectPrivacyScopes } from 'UI/constants/defaults';
import { dateFormatter, objectToArray } from 'UI/utils';

import { FORM_FIELDS_MAP } from './fields';
import { useInformationRendererStyles, useStyles } from './styles';
import { formatOptionsTitle } from './utils';

const MAX_NUMBER_OF_SPLITS = process.env.REACT_APP_SEARCH_PROJECT_MAX_SPLIT;
const MIN_NUMBER_OF_SPLITS = 2;

const {
  title: drawerTitle,
  confirmDialog: confirmDialogCopies,
  sections,
  splitInfoTitles,
  validations
} = strings.searchProjects.splits.form;

const InformationRenderer = ({ information }) => {
  const classes = useInformationRendererStyles();
  return (
    <Grid container>
      {information.map(item => (
        <Grid key={item.title} item xs={item.cols} className={classes.infoLabelSpacing}>
          <InfoLabel title={item.title} description={item.detail} />
        </Grid>
      ))}
    </Grid>
  );
};

const RADIO_OPTIONS = {
  two: { id: 2, title: formatOptionsTitle('two') },
  three: { id: 3, title: formatOptionsTitle('three') },
  more: { id: 'more', title: formatOptionsTitle('more') }
};

type SplitSearchProjectType = {
  isPrivate: boolean,
  originalTotalItems: number,
  searchProjectId: number,
  searchProjectName: string
};

type SplitsFormTypes = {
  onClose: () => void,
  SearchProject: SplitSearchProjectType
};

const SplitsForm = ({ onClose, SearchProject }: SplitsFormTypes) => {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const { searchProjectId, isPrivate, searchProjectName, originalTotalItems } = SearchProject;

  const [uiState, setUiState] = useState({ isSplitDialogFetching: false });

  const { errors, handleSubmit, register, setValue, unregister, watch } = useForm({
    defaultValues: { searchProjectName }
  });
  const watchers = watch([
    FORM_FIELDS_MAP.SplitOptions.key,
    FORM_FIELDS_MAP.SplitIntoParts.key,
    FORM_FIELDS_MAP.SearchProjectName.key
  ]);

  const splitName = strings.formatString(sections.preview.resume.name, {
    searchProjectName: watchers.searchProjectName,
    date: dateFormatter(moment(), ({ SearchProjectSplit }) => SearchProjectSplit)
  });
  const itemsPerSplit = useMemo(() => {
    const getTotalItemsString = totalItemsParam =>
      strings.formatString(sections.preview.resume.totalItems, { totalItems: totalItemsParam });
    if (errors.splitIntoParts || errors.splitOptions) {
      return getTotalItemsString(originalTotalItems);
    }

    const splitNumber = Number(
      Number.isNaN(Number(watchers.splitOptions)) ? watchers.splitIntoParts : watchers.splitOptions
    );
    const numberOfItemsPerSplit = Number.isNaN(splitNumber)
      ? originalTotalItems
      : Math.ceil(originalTotalItems / splitNumber);

    return getTotalItemsString(numberOfItemsPerSplit);
  }, [
    errors.splitIntoParts,
    errors.splitOptions,
    watchers.splitIntoParts,
    watchers.splitOptions,
    originalTotalItems
  ]);

  const getInformation = useCallback(
    shouldShowSplitInfo => [
      {
        title: splitInfoTitles.name,
        detail: shouldShowSplitInfo ? splitName : searchProjectName,
        cols: 12
      },
      {
        title: splitInfoTitles.totalItems,
        detail: shouldShowSplitInfo ? itemsPerSplit : originalTotalItems,
        cols: 6
      },
      {
        title: splitInfoTitles.visibility,
        detail: SearchProjectPrivacyScopes.getPrivacyState(isPrivate).title,
        cols: 6
      }
    ],
    [itemsPerSplit, splitName, originalTotalItems, isPrivate, searchProjectName]
  );

  useEffect(() => {
    register(
      { name: FORM_FIELDS_MAP.SplitOptions.key },
      {
        required: validations.splitOptions.required,
        validate: value =>
          value <= originalTotalItems ||
          Number.isNaN(Number(value)) ||
          validations.splitOptions.validate
      }
    );
    register(
      { name: FORM_FIELDS_MAP.SearchProjectName.key, value: searchProjectName },
      { required: validations.searchProjectName.required }
    );
  }, [register, searchProjectName, originalTotalItems]);

  const handleChangeSplitOptions = ({ target }) => {
    const hasNumberValue = Number.isInteger(Number(target.value));
    !hasNumberValue
      ? register(
          { name: FORM_FIELDS_MAP.SplitIntoParts.key },
          {
            required: validations.splitIntoParts.required,
            max: {
              value: MAX_NUMBER_OF_SPLITS,
              message: strings.formatString(validations.splitIntoParts.max)
            },
            min: {
              value: MIN_NUMBER_OF_SPLITS,
              message: strings.formatString(validations.splitIntoParts.min)
            },
            validate: value => {
              const valueToNumber = Number(value);
              if (!Number.isInteger(valueToNumber) || Number.isNaN(valueToNumber)) {
                return strings.formatString(validations.splitIntoParts.validate.integer);
              }

              return (
                valueToNumber <= originalTotalItems ||
                strings.formatString(validations.splitIntoParts.validate.greaterThanCurrentItems)
              );
            }
          }
        )
      : unregister('splitIntoParts');
    setValue('splitOptions', target.value, true);
  };

  const handleTextBoxChange = (name, value) => setValue(name, value, true);

  const handleConfirmSplit = async formData => {
    setUiState({ isSplitDialogFetching: true });
    const { success, alert } = await SplitSearchProject({
      partsName: formData?.searchProjectName.trim(),
      partsQuantity: formData?.splitIntoParts || formData.splitOptions,
      searchProjectId
    });

    setUiState({ isSplitDialogFetching: false });
    dispatch(showAlert(alert));
    success && history.push(EntityRoutes.SearchProject);
  };

  const onSubmit = async formData => {
    const parts = formData?.splitIntoParts || formData.splitOptions;

    dispatch(
      confirm({
        ...confirmDialogCopies,
        message: strings.formatString(confirmDialogCopies.message, {
          searchProjectName: watchers.searchProjectName,
          parts
        }),
        severity: 'warning',
        withButtons: 'YesNo',
        onConfirm: ok => ok && handleConfirmSplit(formData)
      })
    );
  };

  return (
    <DrawerContentLayout
      title={drawerTitle}
      drawerProps={{
        open: true
      }}
      uiState={{
        isSaving: uiState.isSplitDialogFetching
      }}
      onClose={() => onClose && onClose()}
      onSubmit={handleSubmit(onSubmit)}
    >
      <NumberedForm>
        <NumberedForm.Item title={sections.resume.title}>
          <InformationRenderer information={getInformation(false)} />
        </NumberedForm.Item>
        <NumberedForm.Item title={sections.partsSelection.title}>
          <FPRadioGroup
            column
            classes={{ root: classes.radioGroup }}
            options={objectToArray(RADIO_OPTIONS)}
            onChange={handleChangeSplitOptions}
            value={watchers?.splitOptions || null}
            error={!!errors.splitOptions}
            errorMessage={errors?.splitOptions?.message}
          />
          {watchers.splitOptions === RADIO_OPTIONS.more.id && (
            <TextBox
              name="splitIntoParts"
              placeholder="Number of Search Projects"
              type="number"
              onChange={handleTextBoxChange}
              error={!!errors.splitIntoParts}
              errorText={errors?.splitIntoParts?.message}
            />
          )}
        </NumberedForm.Item>
        <NumberedForm.Item title={sections.preview.title}>
          <Text text={sections.preview.subtitle} variant="body1" fontSize={16} />
          <TextBox
            className={classes.nameInput}
            name="searchProjectName"
            label="Search Project Name"
            onChange={handleTextBoxChange}
            value={watchers.searchProjectName}
            error={!!errors.searchProjectName}
            errorText={errors?.searchProjectName?.message}
          />
          <InformationRenderer information={getInformation(true)} />
        </NumberedForm.Item>
      </NumberedForm>
    </DrawerContentLayout>
  );
};

export default SplitsForm;
