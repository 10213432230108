// @flow
import { makeRequest } from 'services/utils';
import type { OperationResult } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';

const { BulkEmailSmartTags } = Endpoints;

export const getSmartTags = async (): Promise<OperationResult> =>
  makeRequest({
    url: BulkEmailSmartTags,
    method: 'get'
  });
