import React from 'react';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { styled } from '@material-ui/core/styles';

const LoaderContainer = styled(Box)({
  height: 200,
  width: '100%'
});

const StyledLinearProgress = styled(LinearProgress)({
  height: 6,
  width: '100%'
});

export const Loader = ({ alignItems = 'center' }) => {
  return (
    <LoaderContainer alignItems={alignItems} display="flex" justifyContent="center">
      <StyledLinearProgress />
    </LoaderContainer>
  );
};
