import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  content: {
    ...theme.modalContent
  },
  drawerRoot: {
    width: 980,
    [theme.breakpoints.down(1024)]: {
      width: '80%'
    }
  },
  primaryButton: {
    padding: theme.spacing(0.625, 1.875),
    height: 40
  },
  secondaryActionsButtons: {
    width: 'auto'
  },
  scheduleInfo: {
    marginBottom: theme.spacing(3)
  }
}));

export const usePreviewStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.brandColors.FPWhite,
    zIndex: 1350,
    '& > *': {
      margin: theme.spacing(5, 7)
    },
    '& > hr': {
      margin: 0
    }
  },
  details: {
    margin: theme.spacing(1, 0),
    '& > :last-child': {
      color: theme.palette.customColors.darkGrey
    }
  },
  title: {
    width: 130
  },
  skeletonTitle: {
    marginRight: theme.spacing(5)
  },
  bodyContainer: {
    height: '100%',
    overflowY: 'scroll'
  },
  body: {
    height: '100%',
    '& img': {
      maxWidth: '95%'
    }
  },
  attachmentChip: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0, 1, 0, 0)
    }
  },
  detailText: {
    fontSize: 16,
    fontWeight: 400
  }
}));
