import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  unsetBackground: {
    backgroundColor: 'unset'
  },
  overviewTab: {
    backgroundColor: 'unset',
    padding: theme.spacing(4)
  }
}));
