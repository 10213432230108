import * as React from 'react';

const SvgPaperWrite = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      fill={fill}
      {...props}
    >
      <g fill={fill}>
        <path d="M19 18.59a1 1 0 00-1 1v2.16a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.23v-17a.25.25 0 01.25-.25H3.5a1 1 0 000-2H2a2 2 0 00-2 2V22a2 2 0 002 2h16a2 2 0 002-2v-2.41a1 1 0 00-1-1zM16.5 4.5h1.25a.25.25 0 01.25.25v.75a1 1 0 002 0v-1a2 2 0 00-2-2h-1.5a1 1 0 000 2z" />
        <path d="M7 5.5h6A1.5 1.5 0 0014.5 4v-.5A1.5 1.5 0 0013 2h-1a2 2 0 00-4 0H7a1.5 1.5 0 00-1.5 1.5V4A1.5 1.5 0 007 5.5zM4 10a1 1 0 001 1h8a1 1 0 000-2H5a1 1 0 00-1 1zM5 14a1 1 0 000 2h4.5a1 1 0 000-2zM23.41 8.24a2 2 0 00-2.82 0l-7.87 7.87a.45.45 0 00-.11.17l-1.42 3.53a.51.51 0 00.11.54.49.49 0 00.54.11l3.54-1.41a.62.62 0 00.17-.11l7.86-7.87a2 2 0 000-2.83z" />
      </g>
    </svg>
  );
};

export default SvgPaperWrite;
