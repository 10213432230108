import { styled } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

export const HealthDistributionWrapper = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(0, 1.5),
  '& .MuiGrid-item': {
    padding: 0
  },
  '& svg > g > g.google-visualization-tooltip': {
    pointerEvents: 'none'
  }
}));

export const FooterWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(5)}px`
}));

export const FooterTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .label': {
    paddingLeft: theme.spacing(1)
  }
}));

export const FooterItems = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  paddingLeft: theme.spacing(6),
  margin: 0
}));
