import React from 'react';
import { Typography } from '@material-ui/core';
import strings from 'strings';
import { When } from 'UI/components/atoms/When';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';

import { useChatContext } from '../messageChat/ChatDrawerProvider';

import { SearchResults } from './components/SearchInput.styled';
import { SearchInput, SearchResult } from './components';
import { useSearchContacts } from './SearchContactsDrawer.hooks';

export const SearchContactsDrawer = ({ isDrawerOpen, onNewConversation, onClose }) => {
  const { openDrawer } = useChatContext();
  const { contact, handleSelectValue } = useSearchContacts();

  const handleNewConversation = ({ newMessage }) => {
    const recipient = {
      countryCode: newMessage?.countryCode ?? '+1',
      entityId: newMessage?.entityId,
      entityType: newMessage?.entityType,
      name: newMessage?.fullName,
      phone: newMessage?.phone
    };

    const onSendNewMessage = () => {
      onNewConversation({ externalNumer: newMessage?.externalNumber });
    };

    openDrawer({
      recipient,
      onSendNewMessage
    });

    onClose();
  };

  return (
    <DrawerContentLayout
      title={strings.messageCenter.newMessage}
      drawerProps={{ open: isDrawerOpen }}
      onClose={onClose}
      isBottomToolbarNeeded={false}
    >
      <Typography>{strings.messageCenter.newConversation.searchContact}</Typography>
      <SearchInput onSelectValue={handleSelectValue} contact={contact} />
      <When condition={contact}>
        <SearchResults>
          <SearchResult contact={contact} onNewConversation={handleNewConversation} />
        </SearchResults>
      </When>
    </DrawerContentLayout>
  );
};
