import { makeRequest } from 'services/utils';
import { Endpoints } from 'UI/constants/endpoints';

export const getBulkStats = bulkId =>
  makeRequest({
    endpointOptions: {
      path: Endpoints.BulkEmailMetrics,
      replaceId: bulkId
    },
    method: 'get',
    alertConfig: {
      error: {
        title: 'Error retrieving stats data'
      }
    }
  });
