import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'UI/res';
import { flexAlignCenterFlexEnd, flexAlignCenterSpaceBetween } from 'UI/utils/styles';

const avatarContainerHeight = 40;

export const useStyles = makeStyles(theme => ({
  actionsContainer: {
    height: '100%',
    ...flexAlignCenterFlexEnd,
    marginLeft: theme.spacing(2)
  },
  statusBar: {
    margin: theme.spacing(2, 0),
    ...flexAlignCenterSpaceBetween
  },
  editIconContainer: {
    margin: theme.spacing(0, 1.5)
  },
  chip: {
    margin: theme.spacing(0, 1, 0, 0)
  },
  activityRecord: {
    fontSize: 12,
    color: colors.grey,
    '& > span': {
      fontWeight: 700,
      color: colors.oxford
    }
  },
  unsetBackground: {
    backgroundColor: 'unset'
  },
  overviewTab: {
    backgroundColor: 'unset',
    padding: theme.spacing(4)
  },
  companyContainer: {
    marginLeft: theme.spacing(1)
  },
  buttonContainer: {
    ...flexAlignCenterFlexEnd
  },
  overline: {
    lineHeight: 2
  },
  middleBar: {
    margin: theme.spacing(1)
  },
  divider: {
    height: avatarContainerHeight,
    margin: theme.spacing(0, 2)
  },
  profileLabel: {
    textAlign: 'right'
  },
  accountableBar: {
    padding: theme.spacing(0.5)
  },
  profileInfoBar: {
    padding: theme.spacing(1, 0.5)
  },
  containedProfile: {
    padding: theme.spacing(2, 4, 1)
  }
}));
