import { RowActions } from 'UI/constants/defaults';
import { AdditionalRecruiterType } from 'UI/constants/status';

const getActionPermission = ({ action, additionalRecruiters, loggedUser }) => {
  return ({ rowIndex }, data) => {
    const item = data[rowIndex] || {};
    const { id: ownerId } = item.trace?.user || {};
    const isCurrentUserTheOwner = ownerId === loggedUser.id;

    const canTeamEdit = additionalRecruiters
      .filter(recruiter => recruiter.type === AdditionalRecruiterType.Collaborator)
      .some(({ recruiter_id: collaboratorId, recruiter_to_collaborate_id: accountableId }) => {
        const isOwnerInTeam = [accountableId, collaboratorId].includes(ownerId);
        const isLoggedUserInTeam = [accountableId, collaboratorId].includes(loggedUser.id);
        return isOwnerInTeam && isLoggedUserInTeam;
      });

    const actions = {
      [RowActions.Delete]: !item.usedInPlacement && isCurrentUserTheOwner,
      [RowActions.Edit]: isCurrentUserTheOwner || canTeamEdit,
      [RowActions.View]: true,
      default: false
    };

    return actions[action] || actions.default;
  };
};

export default getActionPermission;
