// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { useStyles } from './styles';

type PhonePerformancePercentageProps = {
  value: number,
  performance: string
};

const PhonePerformancePercentage = ({ value, performance }: PhonePerformancePercentageProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.percentageContainer, performance && classes[performance])}>
      <Typography className={classes.percentageLabel}>{`${value}%`}</Typography>
    </div>
  );
};

PhonePerformancePercentage.defaultProps = {
  value: 0
};

export default PhonePerformancePercentage;
