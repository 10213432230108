import { SECONDARY_API_CLIENT } from 'services/API';

import { COMMAND_CENTER_BASE_URL, ENDPOINTS } from '../constants';

import { createQueryParams } from './utils';

export const getProductivitySummary = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.productivitySummary}?${queryParams}`
  ).then(({ data }) => data);
};

export const getProductivityTeam = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.productivityTeam}?${queryParams}`
  ).then(({ data }) => data.shift());
};
export const getRecruiterInfo = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.productivityRecruiter}?${queryParams}`
  ).then(({ data }) => data.shift());
};

export const getWeekProductivity = async ({ groupBy = 'team', recruiter, team, version } = {}) => {
  const defaultParams = {
    groupBy,
    metrics: 'sendouts,sendovers,sentFeeAgreements,signedFeeAgreements',
    recruiter,
    team
  };

  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${version ? `v${version}/` : ''}${
      ENDPOINTS.productivityWeek
    }?${queryParams}`
  );
};
