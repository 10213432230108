import { INLINE_FILTERS_KEYS } from 'features/command-center/constants';
import {
  CandidateEntity,
  CompanyEntity,
  EntityType,
  HiringAuthorityEntity
} from 'UI/constants/entityTypes';

import { CANDIDATE_COLUMNS } from './columns/candidates';
import { COMPANIES_COLUMNS } from './columns/companies';
import { HIRING_AUTHORITIES_COLUMNS } from './columns/hiringAuthorities';
import { PROSPECTS_COLUMNS } from './columns/prospects';
import { formatResponse } from './ActivityTable.utils';

export const ORDER_BY_OPTIONS = {
  column: 'createdAt',
  direction: 'desc'
};

export const TABLE_CONFIG_BY_ENTITY_MAP = {
  [EntityType.Candidate]: {
    title: CandidateEntity.title,
    columns: CANDIDATE_COLUMNS,
    orderBy: ORDER_BY_OPTIONS,
    formatResponse: response =>
      formatResponse(response, {
        id: 'candidateId',
        name: 'candidateName',
        optedOut: 'isCandidateOptedOut'
      }),
    persistenceKey: INLINE_FILTERS_KEYS.activity.recruiterCandidates,
    filtersConfig: [
      'activityType',
      'candidateTypes',
      'candidateStatuses',
      'emailVerdicts',
      'industries',
      'specialties',
      'subspecialties',
      'countries',
      'state',
      'cities',
      'outcomes'
    ]
  },
  [EntityType.Company]: {
    title: CompanyEntity.title,
    columns: COMPANIES_COLUMNS,
    orderBy: ORDER_BY_OPTIONS,
    formatResponse: response =>
      formatResponse(response, {
        id: 'companyId',
        name: 'companyName',
        optedOut: null
      }),
    persistenceKey: INLINE_FILTERS_KEYS.activity.recruiterCompanies,
    filtersConfig: [
      'companyTypes',
      'numberOfContacts',
      'industries',
      'specialties',
      'subspecialties',
      'countries',
      'state',
      'cities',
      'outcomes'
    ]
  },
  [EntityType.HiringAuthority]: {
    title: HiringAuthorityEntity.title,
    columns: HIRING_AUTHORITIES_COLUMNS,
    orderBy: ORDER_BY_OPTIONS,
    formatResponse: response =>
      formatResponse(response, {
        id: 'hiringAuthorityId',
        name: 'hiringAuthorityName',
        optedOut: 'isHiringAuthorityOptedOut'
      }),
    persistenceKey: INLINE_FILTERS_KEYS.activity.recruiterHiringAuthorities,
    filtersConfig: [
      'activityType',
      'hiringAuthorityStatuses',
      'emailVerdicts',
      'companyTypes',
      'industries',
      'specialties',
      'subspecialties',
      'countries',
      'state',
      'cities',
      'outcomes'
    ]
  },
  [EntityType.Contact]: {
    title: 'Prospects',
    columns: PROSPECTS_COLUMNS,
    orderBy: ORDER_BY_OPTIONS,
    formatResponse: response =>
      formatResponse(response, {
        id: 'nameId',
        name: 'nameName',
        optedOut: 'isNameOptedOut'
      }),
    persistenceKey: INLINE_FILTERS_KEYS.activity.recruiterProspects,
    filtersConfig: [
      'activityType',
      'prospectStatuses',
      'emailVerdicts',
      'companyTypes',
      'industries',
      'specialties',
      'subspecialties',
      'countries',
      'state',
      'cities',
      'outcomes'
    ]
  }
};
