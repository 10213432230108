import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';
import { flexAlignCenterFlexEnd } from 'UI/utils/styles';

const sharedButtonStyles = {
  width: 'max-content',
  marginLeft: 'auto',
  display: 'grid',
  gridTemplateColumns: '1fr',
  columnGap: THEME.spacing(1.5)
};

const actionButtonArea = 104;

export const useStyles = makeStyles(
  theme => ({
    mainContainer: {
      paddingBottom: theme.spacing(4)
    },
    mainContainerInQuickView: {
      display: 'grid',
      gridTemplateRows: `${actionButtonArea}px 1fr`,
      height: '100%'
    },
    buttonsContainer: {
      ...sharedButtonStyles
    },
    buttonsContainerInQuickView: {
      ...sharedButtonStyles,
      padding: theme.spacing(4)
    },
    contentContainer: {
      padding: theme.spacing(2.5, 0)
    },
    contentContainerInQuickView: {
      overflow: 'auto',
      height: '100%'
    },
    controlsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(2, 0)
    },
    buttonTransition: {
      transition: theme.transitions.easing.easeInOut,
      transitionDuration: theme.transitions.duration.shorter
    },
    tableToolbar: {
      backgroundColor: theme.palette.primary.FP50,
      borderRadius: '8px 8px 0 0',
      padding: theme.spacing(1),
      ...flexAlignCenterFlexEnd
    }
  }),
  { classNamePrefix: 'profileTableLayout' }
);

export const styles = {
  leftButton: {
    borderRadius: '4px 0 0 4px'
  },
  rightButton: {
    borderRadius: '0 4px 4px 0'
  }
};
