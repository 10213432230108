// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { showAlert } from 'actions/app';
// ACTIONS
import { addOptOut } from 'actions/optin';
// SERVICES
import { addToOptOut, loadReasons } from 'services/bulkEmail';
import Text from 'UI/components/atoms/Text';
// MATERIAL
// COMPONENTS
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
// UTILS
import { Endpoints } from 'UI/constants/endpoints';
import { getId, VALIDATION_REGEXS } from 'UI/utils';
import { OptionRenderers } from 'UI/utils/renderers';

import { useStyles } from './styles';

// STYLES

// ENDPOINTS
const { BulkEmailsOptOuts } = Endpoints;

// TYPES
type AddEmailOptInFormProps = {
  onClosed: () => any
};

const AddEmailOptInForm = ({ onClosed }: AddEmailOptInFormProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const otherReasonId = '98';
  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false
  });
  const [reasons, setReasons] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const { register, unregister, handleSubmit, setValue, watch, setError, clearError, errors } =
    useForm();
  const selectedOptOutReason = watch('unsubscribe_reason_id', null);

  useEffect(() => {
    register({ name: 'item_id' }, { required: 'Email is required' });
    register({ name: 'email_opt_out_type_id' });
    register({ name: 'unsubscribe_reason_id' }, { required: 'Unsuscribe reasons is required' });
  }, [register]);

  const getReasons = useCallback(async () => {
    const response = await loadReasons();

    if (response.success) {
      setUiState(prevState => ({ ...prevState, isLoading: false }));
      setReasons(response.data);
    } else dispatch(showAlert(response.alert));
  }, [dispatch]);

  useEffect(() => {
    getReasons && getReasons();
  }, [getReasons]);

  // eslint-disable-next-line consistent-return
  const handleComboChange = (name, value) => {
    if (value?.createButton) {
      const { EMAIL: email } = VALIDATION_REGEXS;
      if (!email.test(value.keyword.toLowerCase()))
        return setError(name, { invalidEmail: 'Enter a valid email' });
    }
    !!errors[name] && clearError(name);
    setSelectedItem(value?.createButton ? { email: value.keyword.toLowerCase() } : value);
    setValue(name, value?.id || value);
    setValue('email_opt_out_type_id', Number(value?.email_opt_out_type_id) || value);
  };

  const handleRadioChange = ({ target }) => {
    const { value, name } = target;
    value === otherReasonId
      ? register({ name: 'custom_reason' }, { required: 'Other reason is required' })
      : unregister('custom_reason');
    setValue(name, value, true);
  };

  const handleTexBox = (name, value) => setValue(name, value, true);

  const onSubmit = async formData => {
    setUiState(prevState => ({ ...prevState, isSaving: true }));
    let filteredFormData = null;
    if (formData.item_id?.createButton) {
      const notInclude = ['item_id', 'email_opt_out_type_id'];
      filteredFormData = { manual_email: formData.item_id.keyword.toLowerCase() };
      Object.keys(formData).forEach(key => {
        if (!notInclude.includes(key)) {
          filteredFormData[key] = formData[key];
        }
      });
    }
    const response = await addToOptOut(filteredFormData || formData);
    if (response.success) {
      setUiState(prevState => ({
        ...prevState,
        isSaving: false,
        isSuccess: true
      }));
      dispatch(addOptOut(response.data));
      dispatch(
        showAlert({
          severity: 'success',
          title: 'Email Opted-Out Successfully',
          body: `${selectedItem?.email} will no longer receive bulks`
        })
      );
      onClosed && onClosed();
    } else {
      setUiState(prevState => ({
        ...prevState,
        isSaving: false,
        isSuccess: false
      }));
      dispatch(showAlert(response.alert));
    }
  };

  const detailsMap = [
    { title: 'Email', content: selectedItem?.email },
    { title: 'Name', content: selectedItem?.full_name || 'N/A' },
    { title: 'Company', content: selectedItem?.company_name || 'N/A' }
  ];

  const optOutRenderer = OptionRenderers.globalSearchPerson(
    'full_name',
    { secondary: 'company_name' },
    ({ exists }) => ({
      label: exists ? 'Opted-Out' : 'Opted-In',
      variant: 'outlined',
      color: exists ? 'grey' : 'green',
      style: { fontWeight: 500 }
    })
  );

  const createEmailButton = {
    key: 'full_name',
    Component: ({ keyword }) => (
      <div>
        Add <span style={{ color: 'blue' }}>{keyword}</span> to Opt-Out list
      </div>
    )
  };

  return (
    <DrawerContentLayout
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClosed}
      uiState={uiState}
      title="Opt-out email registration"
    >
      <Grid container spacing={1} className={classes.container}>
        <Grid item>
          <Text
            variant="h2"
            fontSize={18}
            text="Please enter the email that will no longer receive emails from gpac"
          />
          <AutocompleteSelect
            name="item_id"
            selectedValue={selectedItem}
            className={classes.select}
            placeholder="Search by name or email *"
            typeahead
            typeaheadLimit={15}
            typeaheadParams={{ entityType: 'keyword' }}
            url={`${BulkEmailsOptOuts}/search`}
            onSelect={handleComboChange}
            error={!!errors.item_id}
            errorText={errors.item_id?.message || errors.item_id?.types?.invalidEmail}
            renderOption={optOutRenderer}
            createButton={createEmailButton}
          />
        </Grid>
        {selectedItem && selectedItem.email && (
          <Grid container item className={classes.details}>
            {detailsMap.map(({ title, content }) => (
              <Grid key={getId()} container item wrap="nowrap" direction="column">
                <Text variant="subtitle2" fontSize={16} text={title} />
                <Text variant="h2" fontSize={16} text={content} cropped />
              </Grid>
            ))}
          </Grid>
        )}
        <Grid item>
          <Text variant="h2" fontSize={18} text="Reason to Opt Out *" />
          <FPRadioGroup
            name="unsubscribe_reason_id"
            value={selectedOptOutReason}
            options={reasons}
            column
            onChange={handleRadioChange}
            error={!!errors.unsubscribe_reason_id}
            errorMessage={errors.unsubscribe_reason_id?.message}
          />
          {selectedOptOutReason === otherReasonId && (
            <TextBox
              label="Reason to Opt Out *"
              name="custom_reason"
              onChange={handleTexBox}
              multiline
              rows={2}
              error={!!errors.custom_reason}
              errorText={errors.custom_reason?.message}
            />
          )}
        </Grid>
        <Grid item>
          <Text variant="h2" fontSize={18} text="Notes" />
          <TextBox
            label="Details"
            placeholder="Provide more details about this Opt-Out"
            name="notes"
            inputRef={register()}
            onChange={handleTexBox}
            multiline
            rows={6}
          />
        </Grid>
      </Grid>
    </DrawerContentLayout>
  );
};

export default AddEmailOptInForm;
