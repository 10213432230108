// @flow
import React, { forwardRef } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import MuiAlert from '@material-ui/lab/Alert';
import { THEME } from 'GlobalStyles';
import { useSnackbar } from 'notistack';
import ConditionalActionWrapper from 'UI/components/atoms/ConditionalWrapper';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { icon as iconDimensions } from 'UI/constants/dimensions';
import { notificationIcons, type notificationsType } from 'UI/constants/notifications';
import {
  AnnouncementIcon,
  CloseIcon,
  colors,
  OutlinedWarningIcon,
  SuccessIcon,
  SvgDelete
} from 'UI/res';

import { useAlertStyles, useNotificationStyles, useStyles } from './styles';

type CustomSnackbarProps = {
  id: number,
  severity: 'warning' | 'info' | 'success' | 'error',
  title: string,
  body: string,
  isNotification: boolean,
  color: string,
  icon: notificationsType,
  onClick: () => void
};

const { customColors } = THEME.palette;
const { white, black } = customColors;

const sharedProps = { fill: white };
const errorIconSize = 20;

const severityValues = {
  warning: {
    icon: <OutlinedWarningIcon fill={white} />,
    props: sharedProps
  },
  error: {
    icon: <SvgDelete />,
    props: { ...sharedProps, width: errorIconSize, height: errorIconSize }
  },
  info: {
    icon: <AnnouncementIcon fill={white} />,
    props: sharedProps
  },
  success: {
    icon: <SuccessIcon fill={white} />,
    props: sharedProps
  }
};

const CustomSnackbar = forwardRef<CustomSnackbarProps, any>(
  (props: CustomSnackbarProps, ref: any) => {
    const {
      severity = 'info',
      title,
      body = '',
      isNotification,
      color = colors.darkGrey,
      icon,
      onClick = () => {}
    } = props;

    const classes = useStyles({
      color
    });
    const { closeSnackbar } = useSnackbar();
    const alertClasses = useAlertStyles();
    const handleDismiss = () => closeSnackbar(props.id);
    const notificationClasses = useNotificationStyles();

    const notification = notificationIcons.find(n => {
      return n.key === icon;
    }) || { icon: <AnnouncementIcon /> };

    const Icon = () =>
      isNotification
        ? {
            ...notification?.icon,
            props: { fill: black, size: iconDimensions.size }
          }
        : { ...severityValues[severity].icon, props: severityValues[severity].props };

    return (
      <Card className={classes.root} ref={ref}>
        <ConditionalActionWrapper
          condition={isNotification}
          wrapper={children => <CardActionArea onClick={onClick}>{children}</CardActionArea>}
        >
          {isNotification && <div className={classes.indicator} />}
          <MuiAlert
            icon={<Icon />}
            elevation={6}
            variant="filled"
            classes={isNotification ? notificationClasses : alertClasses}
            severity={!isNotification ? severity : undefined}
          >
            <div className={classes.textContainer}>
              <div className={classes.title}>{title}</div>
              <div className={classes.body}>{body}</div>
            </div>
          </MuiAlert>
        </ConditionalActionWrapper>
        <CardActions className={classes.buttonContainer}>
          <FPIconButton className={classes.closeButton} size="md" onClick={handleDismiss}>
            <CloseIcon fill={isNotification ? black : white} />
          </FPIconButton>
        </CardActions>
      </Card>
    );
  }
);

export default CustomSnackbar;
