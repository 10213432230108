import * as React from 'react';

const SvgExpand = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path
        fill="#6A7381"
        d="M4 19.333a.667.667 0 0 0 .667.667H8a.666.666 0 0 0 .613-.413.667.667 0 0 0-.14-.727l-.96-.96a.159.159 0 0 1-.037-.18.159.159 0 0 1 .037-.053l2.407-2.414a.83.83 0 0 0-1.173-1.173l-2.414 2.407a.16.16 0 0 1-.233 0l-.96-.96a.667.667 0 0 0-.727-.14A.666.666 0 0 0 4 16v3.333ZM20 4.667A.667.667 0 0 0 19.333 4H16a.667.667 0 0 0-.613.413.666.666 0 0 0 .14.727l.96.96a.16.16 0 0 1 0 .233L14.08 8.747a.826.826 0 0 0 0 1.173.82.82 0 0 0 1.173 0l2.414-2.407a.159.159 0 0 1 .18-.037.16.16 0 0 1 .053.037l.96.96a.667.667 0 0 0 .945-.002A.667.667 0 0 0 20 8V4.667ZM4 8a.667.667 0 0 0 .413.613.667.667 0 0 0 .51.004.667.667 0 0 0 .217-.144l1.073-1.08L8.747 9.92a.82.82 0 0 0 1.173 0 .828.828 0 0 0 0-1.173L7.513 6.333a.16.16 0 0 1 0-.233l.96-.96a.667.667 0 0 0 .14-.727A.667.667 0 0 0 8 4H4.667A.667.667 0 0 0 4 4.667V8ZM20 16a.667.667 0 0 0-.413-.613.667.667 0 0 0-.727.14l-.96.96a.16.16 0 0 1-.233 0l-2.414-2.407a.83.83 0 1 0-1.173 1.173l2.407 2.414a.16.16 0 0 1 0 .233l-.96.96a.667.667 0 0 0-.14.727A.667.667 0 0 0 16 20h3.333a.667.667 0 0 0 .667-.667V16Z"
      />
    </svg>
  );
};

export default SvgExpand;
