// @flow
import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import { lookup } from 'mime-types';
import API from 'services/API';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import FPModal from 'UI/components/templates/FPModal';
import { Endpoints } from 'UI/constants/endpoints';
import { colors, DownloadIcon, FullScreenIcon, NotificationNotFoundImg } from 'UI/res';
import { extractFilenameFromUrl, getErrorMessage, getFileExtension, nestTernary } from 'UI/utils';

import { useStyles } from './styles';

type FileViewerProps = {
  /** onClose handler */
  onClose: any => any,
  /** url of the file to show */
  url: string,
  /** file name when download file is clicked. If not defined, it's taken from the url */
  explicitFileName?: string,
  /** Set this to true to avoid CORS issues and let the API download the file from blob storage  */
  useProxy: boolean
};

type ViewerUrls = {
  /** The URL from which the blob is downloaded when using the API Proxy  */
  proxyUrl?: string,
  /** The URL to display the file in the iframe or image source  */
  viewUrl?: string,
  /** The URL to open the file in a new window in fullscreen  */
  fullscreenUrl?: string,
  /** The URL to trigger the file download  */
  downloadUrl?: string
};

const OfficeViewerUrl = 'https://view.officeapps.live.com/op/view.aspx?src={URL}';

const SupportedTypes = {
  Pdf: 'pdf',
  Office: 'office',
  Image: 'image'
};

const SupportedExtensions = {
  [SupportedTypes.Pdf]: ['pdf'],
  [SupportedTypes.Office]: ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'csv'],
  [SupportedTypes.Image]: ['png', 'jpg', 'jpeg', 'gif']
};

const FileViewer = (props: FileViewerProps) => {
  const { onClose, url, useProxy, explicitFileName } = props;
  const fileName = explicitFileName || extractFilenameFromUrl(url);
  const fileExtension = getFileExtension(fileName);
  const mimeType = lookup(fileName);
  const supportedType = Object.keys(SupportedExtensions).find(key =>
    SupportedExtensions[key].includes(fileExtension)
  );

  const [urls, setUrls] = useState<ViewerUrls>({
    proxyUrl:
      useProxy && supportedType !== 'office' && url
        ? `${Endpoints.Files}/get-file?url=${encodeURIComponent(url)}`
        : url,
    viewUrl: '',
    fullscreenUrl: '',
    downloadUrl: ''
  });
  const classes = useStyles();
  const [uiState, setUiState] = useState({ loading: true, message: '' });

  const { proxyUrl, viewUrl, fullscreenUrl, downloadUrl } = urls;

  useEffect(() => {
    const getUrls = async () => {
      if (!supportedType) {
        setUiState(prevState => ({
          ...prevState,
          loading: false,
          message: 'File type not supported'
        }));

        return;
      }
      try {
        if (supportedType === SupportedTypes.Office) {
          const officeUrl = OfficeViewerUrl.replace('{URL}', encodeURIComponent(url));
          setUrls(prevState => ({
            ...prevState,
            viewUrl: `${officeUrl}&embedded=true`,
            fullscreenUrl: officeUrl
          }));
        } else {
          const response = await API.get(proxyUrl, {
            responseType: 'blob'
          });

          if (response?.data) {
            const blob = new Blob([response.data], { type: mimeType });
            const URL = window.URL || window.webkitURL;
            const blobUrl = URL.createObjectURL(blob);
            if (supportedType === SupportedTypes.Pdf) {
              setUrls(prevState => ({
                ...prevState,
                viewUrl: `${blobUrl}#toolbar=1&navpanes=0`,
                fullscreenUrl: `${blobUrl}#toolbar=1&navpanes=0`,
                downloadUrl: blobUrl
              }));
            } else {
              setUrls(prevState => ({
                ...prevState,
                viewUrl: url,
                fullscreenUrl: blobUrl,
                downloadUrl: blobUrl
              }));
            }
          }
        }
      } catch (e) {
        setUiState(prevState => ({ ...prevState, loading: false, message: getErrorMessage(e) }));
      } finally {
        setUiState(prevState => ({ ...prevState, loading: false }));
      }
    };
    getUrls();
  }, [url, proxyUrl, supportedType, mimeType]);

  const renderViewer = () =>
    supportedType !== SupportedTypes.Image ? (
      <iframe
        name={fileName}
        src={viewUrl}
        allowFullScreen
        className={classes.iframeStyle}
        id="inlineFrameExample"
        title="File Viewer"
        width="100%"
        height="100%"
      />
    ) : (
      <img src={url} alt={fileName} className={classes.viewImage} />
    );

  const { loading, message } = uiState;

  return (
    <FPModal
      open
      title="File viewer"
      onClose={onClose}
      additionalHeaderActions={
        <>
          {downloadUrl && (
            <Link download={fileName} component="a" href={downloadUrl}>
              <FPIconButton tooltipProps={{ title: 'Download' }}>
                <DownloadIcon fill={colors.black} />
              </FPIconButton>
            </Link>
          )}
          {fullscreenUrl && (
            <Link target="_blank" rel="noopener" href={fullscreenUrl}>
              <FPIconButton tooltipProps={{ title: 'Full Screen' }}>
                <FullScreenIcon fill={colors.black} size={20} />
              </FPIconButton>
            </Link>
          )}
        </>
      }
      hasFooterActions={false}
      contentVariant="tight"
    >
      <div id="fileViewer" className={classes.pdfBox}>
        {loading ? (
          <CircularProgress />
        ) : (
          nestTernary(
            viewUrl,
            renderViewer(),
            <EmptyPlaceholder
              title="Oops, something happened while retrieving the file"
              subtitle={message}
              customEmptyState={<NotificationNotFoundImg className={classes.emptyImage} />}
            />
          )
        )}
      </div>
    </FPModal>
  );
};

FileViewer.defaultProps = {
  useProxy: false,
  explicitFileName: null
};

export default FileViewer;
