import { EntityRoutes } from 'routes/constants';

export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'fullName',
    label: 'Full Name',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'id',
          textKey: 'fullName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'company_type',
    label: 'Company Type',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-company-type',
          fallback: {
            label: 'N/A',
            hint: 'No company is linked to this candidate. Add a company to start marketing it.',
            variant: 'danger'
          }
        }
      }
    }
  },
  {
    name: 'companyName',
    label: 'Company',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'companyId',
          textKey: 'companyName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'industry',
    label: 'Industry: Specialty',
    options: {
      sort: false,
      display: true
    }
  },
  {
    name: 'functionalTitle',
    label: 'Functional Title',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      sort: true,
      display: true
    }
  }
];

export const VIRTUAL_PROPS = [
  {
    propName: 'companyName',
    attributes: ['companyId', 'companyName']
  },
  {
    propName: 'fullName',
    attributes: ['id', 'fullName']
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'id',
  direction: 'asc'
};
