import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  input: {
    margin: 0
  },
  radioGroup: {
    margin: theme.spacing(1, 0)
  },
  adderContainer: {
    margin: theme.spacing(1, 0)
  },
  linkButton: {
    textDecoration: 'underline',
    fontSize: '1em',
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  splitFieldContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  splitContainer: {
    marginBottom: theme.spacing(2.5)
  },
  confirmChecklist: {
    fontWeight: 100
  },
  infoLabel: {
    marginBottom: theme.spacing(1)
  },
  withIndent: {
    paddingLeft: theme.spacing(3)
  },
  separator: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1)
  },
  estimate: {
    whiteSpace: 'pre-wrap'
  },
  noTextTransform: {
    textTransform: 'none'
  },
  regularBottomMargin: {
    marginBottom: theme.spacing(1)
  },
  title: {
    fontSize: 18,
    fontWeight: '500 !important'
  }
}));
