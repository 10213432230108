import React, { useMemo } from 'react';
import strings from 'strings';

import { useStyles } from '../styles';

import { SplitCell } from './FeeAgreementExpandableRow';
import FeeAgreementRow from './FeeAgreementRow';

const monthsLabels = Array.from({ length: 12 }, (_, index) => ({
  label: new Date(0, index).toLocaleString('default', { month: 'short' }),
  labelDown: 'Signed',
  labelBottom: 'Sent'
}));

const addGpacAllRowToEndOfTable = feeAgreements => [...feeAgreements, feeAgreements[0]];
const addLabelsAndFlattenData = data => {
  let feeAgreements = [];
  data.forEach(item => {
    if (item.team === strings.dashboard.shared.gpac)
      Object.assign(item, { coach: strings.dashboard.shared.gpac });
    if (item.team === strings.dashboard.shared.regional)
      Object.assign(item, { coach: item.regional });
    feeAgreements = [...feeAgreements, item, ...item.coaches];
  });
  return addGpacAllRowToEndOfTable(feeAgreements);
};

const FeeAgreementsDataTable = ({ items, week, year }) => {
  const classes = useStyles();
  const feeAgreements = useMemo(() => addLabelsAndFlattenData(items), [items]);
  const isYearView = !!year?.date;

  const daysLabels = [
    { label: 'M' },
    { label: 'T' },
    { label: 'W' },
    { label: 'TH' },
    { label: 'F' }
  ];

  const tableColumns = isYearView
    ? [
        { label: strings.dashboard.shared.team, class: classes.team },
        ...monthsLabels,
        { label: 'Total Signed', labelDown: 'Total Sent', class: classes.totalTitle }
      ]
    : [
        { label: strings.dashboard.shared.team, class: classes.team },
        ...daysLabels,
        { label: 'Total', class: classes.totalTitle },
        ...daysLabels,
        { label: 'Total', class: classes.totalTitle },
        { label: 'Conversion Rate', class: classes.rate }
      ];

  return (
    <table className={classes.board}>
      <thead className={classes.boardHead}>
        {!isYearView && (
          <tr>
            <th className={classes.emptyCategory} aria-label="Empty category" colSpan={2} />
            <th className={classes.category} colSpan={6}>
              Fee Agreements SENT
            </th>
            <th className={classes.category} colSpan={7}>
              Fee Agreements SIGNED
            </th>
          </tr>
        )}
        <tr className={classes.title}>
          <th aria-label="Empty action button" />
          {tableColumns.map(column => (
            <th className={column.class || ''}>
              <SplitCell column={column} header />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {feeAgreements.map(item => (
          <FeeAgreementRow item={item} week={week} year={year} />
        ))}
      </tbody>
    </table>
  );
};
export default FeeAgreementsDataTable;
