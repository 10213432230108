import { EntityType } from 'UI/constants/entityTypes';

import { getPhonesSortedByDefaultPhone } from '../PhoneNumbersForm/utils';

/**
 * This map is used to transform data before submission to the API and also to preload data from the API into a form
 * Entries can have the following props:
 * If only key is present no transformatios is applied in neither direction (to/from the API)
 * If paramKey is present, then paramKey is sent to the API
 * outboundAccesor is a key to extract a value from an object. For instance, an outboundAccesor = 'id' would evaluate to object['id'] and this value would be sent to the API
 * inboundAccesor is a property path used to preload data coming from the API into the form. It's useful for nested objects, for instance: 'city.state' or 'address.zip'. If a more custom object is needed, then inboundAccessor could be a function.
 */
export const FormFieldsMap = {
  ID: {
    key: 'id'
  },
  COMPANY: {
    key: 'company',
    paramKey: 'company_id',
    outboundAccesor: 'id',
    inboundAccesor: form =>
      form.company_id ? { id: form.company_id, name: form.current_company } : null
  },
  FIRST_NAME: {
    key: 'firstName',
    paramKey: 'first_name',
    inboundAccesor: 'personalInformation.first_name'
  },
  LAST_NAME: {
    key: 'lastName',
    paramKey: 'last_name',
    inboundAccesor: 'personalInformation.last_name'
  },
  NICKNAME: {
    key: 'nickname',
    paramKey: 'nickname',
    inboundAccesor: 'personalInformation.nickname'
  },
  SPECIALTY: {
    key: 'specialty',
    paramKey: 'specialty_id',
    inboundAccesor: 'specialty',
    outboundAccesor: 'id'
  },
  SUBSPECIALTY: {
    key: 'subspecialty',
    paramKey: 'subspecialty_id',
    inboundAccesor: 'subspecialty',
    outboundAccesor: 'id'
  },
  FUNCTIONAL_TITLE: {
    key: 'functionalTitle',
    paramKey: 'position_id',
    inboundAccesor: 'position',
    outboundAccesor: 'id'
  },
  TITLE: {
    key: 'title'
  },
  POSITION: {
    key: 'title'
  },
  PHONES: {
    key: 'phones',
    inboundAccesor: rawObject => getPhonesSortedByDefaultPhone(rawObject, EntityType.Contact)
  },
  PHONE: {
    key: 'phone',
    inboundAccesor: 'personalInformation.contact.phone'
  },
  EXT: {
    key: 'ext',
    inboundAccesor: 'personalInformation.contact.ext'
  },
  EMAIL: {
    key: 'email'
  },
  OTHER_PHONE: {
    key: 'mobile',
    inboundAccesor: 'personalInformation.contact.mobile'
  },
  OTHER_EMAIL: {
    key: 'personalEmail',
    paramKey: 'personal_email',
    inboundAccesor: 'personalInformation.contact.personal_email'
  },
  LINKEDIN_URL: {
    key: 'linkedinUrl',
    paramKey: 'link_profile'
  },
  SOURCE: {
    key: 'source',
    paramKey: 'source_type_id',
    outboundAccesor: 'id',
    inboundAccesor: 'sourceType'
  },
  STATE: {
    key: 'state_id',
    paramKey: 'state_id',
    outboundAccesor: 'id',
    inboundAccesor: 'personalInformation.address.city.state'
  },
  CITY: {
    key: 'city_id',
    paramKey: 'city_id',
    outboundAccesor: 'id',
    inboundAccesor: 'personalInformation.address.city'
  },
  ZIP: {
    key: 'zip',
    paramKey: 'zip',
    outboundAccesor: 'id',
    inboundAccesor: form => ({
      id: form.personalInformation?.address?.zip,
      title: form.personalInformation?.address?.zip
    })
  },
  RECRUITER: {
    key: 'recruiter',
    paramKey: 'recruiter_id',
    outboundAccesor: 'id',
    inboundAccesor: 'recruiter'
  },
  FROM_CONTACT: {
    key: 'fromContact',
    paramKey: 'hiringId',
    outboundAccesor: 'id'
  },
  STATUS: {
    key: 'status_id',
    paramKey: 'status_id'
  },
  NAME_STATUS: {
    key: 'name_status_id',
    paramKey: 'name_status_id',
    outboundAccesor: 'id',
    inboundAccesor: 'status'
  }
};
