import React, { useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { InlineFilters } from 'features/command-center';
import { ENDPOINTS } from 'features/command-center/constants';

import { SimplifiedTable } from '../../shared';

import { TABLE_CONFIG_BY_ENTITY_MAP } from './ActivityTable.constants';
import { processNumberOfContactsFilters } from './ActivityTable.utils';

export const ActivityTable = ({ entity, recruiterId }) => {
  const [filters, setFilters] = useState({});

  const entityConfig = useMemo(() => TABLE_CONFIG_BY_ENTITY_MAP[entity], [entity]);

  const handleFiltersChange = newFilters => {
    const processedFilters = processNumberOfContactsFilters(newFilters);
    setFilters({ ...processedFilters });
  };

  const endpoint = useMemo(
    () => `${ENDPOINTS.recruitersActivity}?entityType=${entity}`.replace(':userId', recruiterId),
    [entity, recruiterId]
  );

  return (
    <>
      <Grid item xs={12}>
        <InlineFilters
          persistenceKey={entityConfig.persistenceKey}
          filters={entityConfig.filtersConfig}
          onFiltersChange={handleFiltersChange}
        />
      </Grid>
      <Grid item xs={12}>
        <SimplifiedTable
          pageKey={entityConfig.persistenceKey}
          columns={entityConfig.columns}
          endpoint={endpoint}
          filters={filters}
          header={entityConfig.title}
          orderByOptions={entityConfig.orderBy}
          formatResponse={entityConfig.formatResponse}
        />
      </Grid>
    </>
  );
};
