import React from 'react';

import { ChatDrawerContent } from './ChatDrawerContent';
import { useChatContext } from './ChatDrawerProvider';

export const ChatDrawer = () => {
  const { isDrawerOpen, closeDrawer, recipient, recipientPhoneWithCode } = useChatContext();

  return (
    isDrawerOpen && (
      <ChatDrawerContent
        isDrawerOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
        recipient={recipient}
        recipientPhoneWithCode={recipientPhoneWithCode}
      />
    )
  );
};
