import * as React from 'react';

const SvgEdit = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M16.19 3.51a.51.51 0 00-.71 0L4.07 14.92a.5.5 0 000 .71l4.28 4.28a.48.48 0 00.7 0L20.47 8.5a.51.51 0 000-.71zM3 16.71a.51.51 0 00-.47-.13.48.48 0 00-.36.33L0 23.33a.49.49 0 00.12.51.5.5 0 00.54.16l6.42-2.14a.52.52 0 00.33-.36.49.49 0 00-.14-.5zM23 1a3.6 3.6 0 00-5 0l-.71.71a.5.5 0 000 .71l4.28 4.28a.51.51 0 00.7 0L23 6a3.53 3.53 0 000-5z" />
    </svg>
  );
};

export default SvgEdit;
