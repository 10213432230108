// @flow
import React, { useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouterProps } from 'react-router-dom';
import { showAlert } from 'actions/app';
/** API / EntityRoutes / Endpoints / EntityType */
import useDatatable, {
  addRingCentralContactToStore,
  buildMultiplePhonesActionsRenderer,
  buildRowActionsRendererDefinition,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import useMultipleSelection, { CustomTableToolbarSelect } from 'hooks/multipleSelection';
import { useSearchProject } from 'hooks/searchProject';
import { useUrlParamsUpdater } from 'hooks/urlParamsUpdater';
import usePageTitle from 'hooks/usePageTitle';
import { useQuickViewPreview } from 'hooks/useQuickViewPreview';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
/** Components */
import DataTable from 'UI/components/organisms/DataTable';
import FPQuickView from 'UI/components/organisms/inventoryProfiles/FPQuickView';
import { TableWithSearchProjects } from 'UI/components/organisms/TableWithSearchProjects';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { backNavigateListConfig } from 'UI/constants/config';
import { CommunicationActionPreset, PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { CandidateEntity, EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { createCustomStaticRanges, getFinalColumns, hasFeatureFlag } from 'UI/utils';

import {
  ColumnsDefinitions,
  DateFilters,
  FiltersGroups,
  PeriodColumnBySection,
  VirtualProps
} from './columns';

const pageKey = 'candidates-list';

const orderByOptions = {
  column: 'id',
  direction: 'asc'
};

const numberOfActionColumns = 1;

const Candidates = (props: RouterProps) => {
  const { history, location } = props;
  const dispatch = useDispatch();

  const multiplePhonesActionButtons = buildMultiplePhonesActionsRenderer({
    customName: 'phoneNumbers',
    handleAction,
    columns: getFinalColumns(ColumnsDefinitions),
    entityType: EntityType.Candidate
  });

  const CANDIDATE_COLUMNS = [
    ...getFinalColumns(ColumnsDefinitions),
    multiplePhonesActionButtons
  ].filter(column => !!column);

  const initialPreferences = getColumnPreferences(
    pageKey,
    0,
    orderByOptions,
    CANDIDATE_COLUMNS,
    numberOfActionColumns,
    location.search
  );

  const [dateTypeFilter, setDateTypeFilter] = useState(DateFilters[0]);

  const apiVersion = hasFeatureFlag(FeatureFlags.CandidatesMultiplePhones) ? 2 : 1;

  const {
    handleFiltersApply,
    handleFiltersChange,
    handleOneFilterChange,
    handleKeywordChange,
    handleColumnSortChange,
    handlePerPageChange,
    handlePageChange,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleFiltersToggle,
    handleSectionChange,
    handlePeriodChange,
    listState,
    columnPreferences,
    filters,
    data,
    getData,
    count,
    mainOptions,
    queryParams
  } = useDatatable({
    key: pageKey,
    endpoint: Endpoints.Candidates,
    apiVersion,
    entityName: 'Candidates',
    entityType: EntityType.Candidate,
    initialPreferences,
    columnsDefinitions: CANDIDATE_COLUMNS,
    orderByOptions,
    periodDefaultValue: null,
    periodFilterColumn: PeriodColumnBySection,
    sendDateWithTimezone: true,
    numberOfActionColumns,
    virtualProps: VirtualProps,
    shouldScrollOnNavigate: true
  });

  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  useUrlParamsUpdater({ history, location, orderBy });

  const { isLoading, showWholeSkeleton, isSideMenuOpen, page, perPage, keyword, userFilter } =
    listState;

  const dataForSelection = useMemo(
    () => data.map(({ id }) => ({ id, type_id: 1, type: 'Candidate' })),
    [data]
  );

  const {
    handleRowSelection,
    selectedIndexesInPage,
    totalSelected,
    filteredItems,
    multiSelectComponents
  } = useMultipleSelection({ data: dataForSelection, count, perPage });

  const { SearchProjectAction, SearchProjectForms } = useSearchProject({
    filteredItems,
    queryParams
  });

  usePageTitle({ title: PageTitles.Candidate, isLoading });

  const isSearchProjectRefactorActive = hasFeatureFlag(FeatureFlags.SearchProjectsRefactor);

  function handleAction({ rowData, origin }) {
    addRingCentralContactToStore({
      rowData,
      columns: finalColumns,
      dispatch,
      role: EntityType.Candidate,
      origin
    });
  }

  const { entityId, quickViewState, listViewPreset, quickViewEntity, toggleQuickViewPreview } =
    useQuickViewPreview({ columns, data, entityType: EntityType.Candidate });

  const phoneActionButtons = buildRowActionsRendererDefinition({
    actions: [...CommunicationActionPreset, listViewPreset],
    namespace: EntityType.Candidate,
    handleAction,
    columns: CANDIDATE_COLUMNS
  });

  const handleDateTypeFilterChange = (name, value) => {
    setDateTypeFilter(value);
    handleSectionChange && handleSectionChange(name, value.id);
  };

  const finalColumns = getColumnsToRender([...columns, phoneActionButtons]);

  const handleTabRefresh = async () => {
    await getData();
  };

  return (
    <FiltersLayout
      title="Candidates"
      section={EntityType.Candidate}
      listSelector={
        <AutocompleteSelect
          name="userFilter"
          placeholder="Candidates to show"
          selectedValue={userFilter || mainOptions[0]}
          onSelect={handleOneFilterChange}
          defaultOptions={mainOptions}
          disableClearable
        />
      }
      defaultRange={null}
      extraSelector={
        <AutocompleteSelect
          name="dateTypeFilter"
          placeholder="Filter By"
          selectedValue={dateTypeFilter}
          onSelect={handleDateTypeFilterChange}
          defaultOptions={DateFilters}
          disableClearable
        />
      }
      dateRangerPickerProps={{
        staticRanges: createCustomStaticRanges(additionalPeriodRanges)
      }}
      filters={filters}
      isSideMenuOpen={isSideMenuOpen}
      isLoading={showWholeSkeleton}
      groups={FiltersGroups}
      enableStore={false}
      onSearch={handleFiltersApply}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      titleLabelProps={backNavigateListConfig}
      onPeriodChange={handlePeriodChange}
    >
      {!isSearchProjectRefactorActive && (
        <>
          <DataTable
            isExpandable
            loading={showWholeSkeleton}
            refreshing={isLoading}
            data={data}
            columns={finalColumns}
            count={count}
            filter={false}
            page={page}
            rowsPerPage={perPage}
            searchText={keyword}
            sortOrder={{ name: orderBy, direction }}
            columnOrder={columnOrder?.length && columnOrder}
            draggableColumns={{
              enabled: true
            }}
            enableCustomFilters
            onToggleFilters={handleFiltersToggle}
            onSearchTextChange={handleKeywordChange}
            onColumnSortChange={handleColumnSortChange}
            onPerPageClick={handlePerPageChange}
            onPageClick={handlePageChange}
            onColumnDisplayClick={handleColumnDisplayChange}
            onColumnOrderChange={handleColumnOrderChange}
            onRowSelectionChange={handleRowSelection}
            rowsSelected={selectedIndexesInPage}
            selectToolbarPlacement="none"
            CustomToolbar={
              <CustomTableToolbarSelect total={totalSelected}>
                <SearchProjectAction />
              </CustomTableToolbarSelect>
            }
            components={multiSelectComponents}
          />
          <SearchProjectForms />
        </>
      )}
      {isSearchProjectRefactorActive && (
        <TableWithSearchProjects
          isExpandable
          loading={showWholeSkeleton}
          refreshing={isLoading}
          columns={finalColumns}
          filter={false}
          page={page}
          searchText={keyword}
          sortOrder={{ name: orderBy, direction }}
          columnOrder={columnOrder?.length && columnOrder}
          draggableColumns={{
            enabled: true
          }}
          enableCustomFiltersselectToolbarPlacement="none"
          data={data}
          count={count}
          perPage={perPage}
          entity={CandidateEntity.singular}
          queryParams={queryParams}
          onToggleFilters={handleFiltersToggle}
          onSearchTextChange={handleKeywordChange}
          onColumnSortChange={handleColumnSortChange}
          onPerPageClick={handlePerPageChange}
          onPageClick={handlePageChange}
          onColumnDisplayClick={handleColumnDisplayChange}
          onColumnOrderChange={handleColumnOrderChange}
        />
      )}
      <When condition={entityId && quickViewState.isQuickViewOpen}>
        <FPQuickView
          drawerProps={{
            open: quickViewState.isQuickViewOpen
          }}
          entityType={quickViewEntity}
          onClose={toggleQuickViewPreview}
          itemsToNavigate={data}
          id={entityId}
          onEditionCompleted={handleTabRefresh}
        />
      </When>
    </FiltersLayout>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onShowAlert: alert => dispatch(showAlert(alert))
  };
};

export default connect(null, mapDispatchToProps)(Candidates);
