// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import strings from 'strings';
import FPHint from 'UI/components/atoms/FPHint';
import TextBox from 'UI/components/atoms/TextBox';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import HistoryItem from 'UI/components/molecules/HistoryItem';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import { BinaryOptions } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { REQUIRED_VALIDATION } from 'UI/utils';

import { FormFieldsMap } from '../fields';

const otherOptionId = 7;

const fieldsConfig = [
  {
    name: FormFieldsMap.FallOffReason.key,
    validation: REQUIRED_VALIDATION
  },
  {
    name: FormFieldsMap.CandidateStillAvailable.key,
    validation: REQUIRED_VALIDATION
  },
  {
    name: FormFieldsMap.JobOrderStillOpen.key,
    validation: REQUIRED_VALIDATION
  }
];

const PlacementFallOff = () => {
  const { Status, state } = useFetchWithStatus(Endpoints.PlacementFallOffReasons);

  const { register, unregister, errors, watch, setValue } = useFormContext();
  const formValues = watch();

  useEffect(() => {
    fieldsConfig.forEach(({ name, validation }) => {
      register({ name }, validation);
    });

    return () => unregister(fieldsConfig.map(({ name }) => name));
  }, [register, unregister]);

  const handleRadioChange = ({ target }) => setValue(target.name, target.value, true);

  const {
    [FormFieldsMap.FallOffReason.key]: fallOffReasonId,
    [FormFieldsMap.CandidateStillAvailable.key]: candidateStillAvailable,
    [FormFieldsMap.JobOrderStillOpen.key]: jobStillOpen
  } = formValues;
  const enableOtherReason = Number(fallOffReasonId) === otherOptionId;

  return (
    <>
      <Status
        loading={
          <List dense>
            <HistoryItem isLoading />
          </List>
        }
        empty={
          <Typography paragraph gutterBottom>
            {strings.placements.fields.noFalloffReasons}
          </Typography>
        }
        error={error => (
          <Typography paragraph gutterBottom>
            {error}
          </Typography>
        )}
        /** react-hook-forms is not playing well with fetch hook. Moving form rendering outside Status  */
        success={() => null}
      />
      {state.results?.length > 0 && (
        <NumberedForm>
          <NumberedForm.Item title={strings.placements.fields.falloffReason}>
            <FPRadioGroup
              name={FormFieldsMap.FallOffReason.key}
              error={errors[FormFieldsMap.FallOffReason.key]}
              errorText={errors[FormFieldsMap.FallOffReason.key]?.message}
              value={fallOffReasonId}
              options={state.results}
              onChange={handleRadioChange}
              column
            />
            {enableOtherReason && (
              <TextBox
                name={FormFieldsMap.OtherFallOffReason.key}
                error={errors[FormFieldsMap.OtherFallOffReason.key]}
                errorText={errors[FormFieldsMap.OtherFallOffReason.key]?.message}
                label={strings.placements.fields.otherFalloffReason}
                inputRef={register(REQUIRED_VALIDATION)}
              />
            )}
          </NumberedForm.Item>
          <NumberedForm.Item title={strings.placements.fields.isJobOrderOpen}>
            <FPRadioGroup
              name={FormFieldsMap.JobOrderStillOpen.key}
              error={errors[FormFieldsMap.JobOrderStillOpen.key]}
              errorText={errors[FormFieldsMap.JobOrderStillOpen.key]?.message}
              value={jobStillOpen}
              options={BinaryOptions}
              onChange={handleRadioChange}
            />
            <FPHint
              variant="warning"
              size="md"
              description={strings.placements.fields.isJobOrderOpenHelper}
            />
          </NumberedForm.Item>
          <NumberedForm.Item title={strings.placements.fields.isCandidateOpen}>
            <FPRadioGroup
              name={FormFieldsMap.CandidateStillAvailable.key}
              error={errors[FormFieldsMap.CandidateStillAvailable.key]}
              errorText={errors[FormFieldsMap.CandidateStillAvailable.key]?.message}
              value={candidateStillAvailable}
              options={BinaryOptions}
              onChange={handleRadioChange}
            />
            <FPHint
              variant="warning"
              size="md"
              description={strings.placements.fields.isCandidateOpenHelper}
            />
          </NumberedForm.Item>
          <NumberedForm.Item title={strings.placements.fields.falloffNotes}>
            <TextBox
              name={FormFieldsMap.FallOffNotes.key}
              label={strings.placements.fields.notes}
              inputRef={register()}
              multiline
            />
          </NumberedForm.Item>
        </NumberedForm>
      )}
    </>
  );
};

PlacementFallOff.defaultProps = {};
export default PlacementFallOff;
