import strings from 'strings';

import { CompaniesDrawerContent } from './CompaniesDrawerContent';

const { companies } = strings.commandCenter.drawers;

export const CompaniesConfig = {
  title: companies.title,
  component: CompaniesDrawerContent
};
