export const industries = [
  {
    id: 1,
    created_at: null,
    created_by: null,
    description: 'This is the industry description ',
    email: 'agriculture@gogpac.com',
    title: 'Agriculture',
    updated_at: '2023-11-17 15:41:25',
    updated_by: 309,
    type: 'industry'
  },
  {
    id: 147,
    title: 'Architecture',
    type: 'industry'
  },
  {
    id: 148,
    title: 'Aviation',
    type: 'industry'
  },
  {
    id: 149,
    title: 'Business',
    type: 'industry'
  }
];

export const pilIndustries = [
  {
    id: 'industry-1',
    title: 'Agriculture',
    type: 'industry'
  },
  {
    id: 'industry-147',
    title: 'Architecture',
    type: 'industry'
  },
  {
    id: 'industry-148',
    title: 'Aviation',
    type: 'industry'
  },
  {
    id: 'industry-149',
    title: 'Business',
    type: 'industry'
  }
];

export const specialties = [
  {
    id: 11,
    title: 'Animal Health',
    industry_id: 1,
    industry_title: 'Agriculture',
    description: 'This is the specialty description',
    type: 'specialty'
  },
  {
    id: 5,
    title: 'Ag Equipment',
    industry_id: 1,
    industry_title: 'Agriculture',
    type: 'specialty'
  },
  {
    id: 57,
    title: 'Cattle Production',
    industry_id: 1,
    industry_title: 'Agriculture',
    type: 'specialty'
  },
  {
    id: 6,
    title: 'Constructions',
    industry_id: 1,
    industry_title: 'Agriculture',
    type: 'specialty'
  }
];

export const pilSpecialties = [
  {
    id: 'specialty-11',
    title: 'Animal Health',
    type: 'specialty'
  },
  {
    id: 'specialty-5',
    title: 'Ag Equipment',
    type: 'specialty'
  },
  {
    id: 'specialty-57',
    title: 'Cattle Production',
    type: 'specialty'
  },
  {
    id: 'specialty-6',
    title: 'Constructions',
    type: 'specialty'
  }
];

export const subspecialties = [
  {
    id: 125,
    title: 'Equipmentv2',
    industry_id: 1,
    industry_title: 'Agriculture',
    specialty_id: 11,
    specialty_title: 'Animal Health',
    description: 'This is the subspecialty description',
    type: 'subspecialty'
  },
  {
    id: 111,
    title: 'Constructions SS1',
    industry_id: 1,
    industry_title: 'Agriculture',
    specialty_id: 11,
    specialty_title: 'Animal Health',
    type: 'subspecialty'
  },
  {
    id: 1111,
    title: 'Constructions SS2',
    industry_id: 1,
    industry_title: 'Agriculture',
    specialty_id: 11,
    specialty_title: 'Animal Health',
    type: 'subspecialty'
  },
  {
    id: 571,
    title: 'Constructions SS3',
    industry_id: 1,
    industry_title: 'Agriculture',
    specialty_id: 11,
    specialty_title: 'Animal Health',
    type: 'subspecialty'
  }
];

export const pilSubspecialties = [
  {
    id: 'subspecialty-125',
    title: 'Equipmentv2',
    type: 'subspecialty'
  },
  {
    id: 'subspecialty-111',
    title: 'Constructions SS1',
    type: 'subspecialty'
  },
  {
    id: 'subspecialty-1111',
    title: 'Constructions SS2',
    type: 'subspecialty'
  },
  {
    id: 'subspecialty-571',
    title: 'Constructions SS3',
    type: 'subspecialty'
  }
];

export const positions = [
  {
    id: 3019,
    title: 'Manager',
    specialty_id: 11,
    specialty_title: 'Veterinary Clinics',
    industry_id: 1,
    industry_title: 'Veterinary Services',
    type: 'position'
  },
  {
    id: 3044,
    title: 'General Manager',
    specialty_id: 79,
    specialty_title: 'Pharmaceutical and Testing Lab',
    industry_id: 1336,
    industry_title: 'Veterinary Services',
    type: 'position'
  },
  {
    id: 3395,
    title: 'Advisor',
    specialty_id: 6,
    specialty_title: 'Constructions',
    industry_id: 1,
    industry_title: 'Agriculture',
    type: 'position'
  },
  {
    id: 3396,
    title: 'ab',
    specialty_id: 45,
    specialty_title: 'Metals',
    industry_id: 156,
    industry_title: 'Manufacturing',
    type: 'position'
  },
  {
    id: 20056,
    title: 'administrator',
    specialty_id: 28,
    specialty_title: 'Structural',
    industry_id: 152,
    industry_title: 'Engineering',
    type: 'position'
  },
  {
    id: 20057,
    title: 'Master',
    specialty_id: 33,
    specialty_title: 'Pharmaceuticals',
    industry_id: null,
    industry_title: 'Life Sciences',
    type: 'position'
  }
];

export const treeView = {
  root: pilIndustries,
  'industry-1': pilSpecialties,
  'specialty-11': pilSubspecialties
};
