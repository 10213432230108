import * as React from 'react';

const SvgNoConnectedCallFilled = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      id="noConnectedCallFilled_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path
        fill={fill}
        d="M14.8 24c-1 0-2-.3-2.8-.8-4.4-3-8.1-6.7-11.1-11.1-1.3-2.1-1-4.8.7-6.5l.6-.6c.4-.4 1-.7 1.6-.7.6 0 1.2.2 1.6.7L8 7.5c.4.4.7 1 .7 1.6 0 .6-.2 1.2-.7 1.6-.3.3-.3.8 0 1.1l4.2 4.2c.1.1.3.2.5.2s.4-.1.5-.2c.4-.4 1-.7 1.6-.7s1.2.2 1.6.7l2.6 2.6c.4.4.7 1 .7 1.6s-.2 1.2-.7 1.6l-.6.6c-.9 1.1-2.2 1.6-3.6 1.6zM3.7 5.7c-.2 0-.4.1-.5.2l-.6.7c-1.3 1.3-1.5 3.2-.5 4.7C5 15.5 8.6 19.1 12.8 22c.6.4 1.3.6 2 .6 1 0 1.9-.4 2.7-1.1l.6-.6c.1-.1.2-.3.2-.5s-.1-.4-.2-.5l-2.7-2.7c-.1-.1-.3-.2-.5-.2s-.4.1-.5.2c-.4.4-1 .7-1.6.7-.6 0-1.2-.2-1.6-.7L7 13c-1-1-1-2.5-.1-3.4.1-.1.2-.3.2-.5s0-.4-.2-.5L4.3 5.9c-.2-.1-.4-.2-.6-.2zM18 12c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6zm0-10.5c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5 4.5-2 4.5-4.5-2-4.5-4.5-4.5z"
      />
      <path
        fill={fill}
        d="M15.7 6.8c-.4 0-.7-.4-.7-.8s.3-.8.8-.8h4.5c.3.1.7.4.7.8s-.3.8-.8.8h-4.5z"
      />
    </svg>
  );
};

export default SvgNoConnectedCallFilled;
