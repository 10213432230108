import React, { useCallback } from 'react';
import { DRAWER_KEYS, StatsItemsCard } from 'features/command-center/components/shared';
import { COMMAND_CENTER_BASE_URL } from 'features/command-center/constants';
import { useDrawerManager } from 'features/command-center/contexts';
import {
  getYTDActivityPlacementsSummary,
  getYTDSendoutsAndSignedAgreementsSummary
} from 'features/command-center/services';
import strings from 'strings';

import { formatStats } from './ProductivityStatsSummary.utils';

export const ProductivityStatsSummary = ({ requestParams, hasDrillDown }) => {
  const { openDrawer } = useDrawerManager();

  const cachedPromises = useCallback(() => {
    const drawerHandlers = hasDrillDown
      ? {
          totalPlacements: ({ url }) => {
            openDrawer({
              activeDrawer: DRAWER_KEYS.Placements,
              contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
            });
          },
          totalFallOffs: ({ url }) => {
            openDrawer({
              activeDrawer: DRAWER_KEYS.FallOffs,
              contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
            });
          },
          sendouts: ({ url }) => {
            openDrawer({
              activeDrawer: DRAWER_KEYS.Sendouts,
              contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
            });
          },
          feeAgreementsSigned: ({ url }) => {
            openDrawer({
              activeDrawer: DRAWER_KEYS.SignedAgreements,
              contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
            });
          }
        }
      : null;

    const { recruiter } = requestParams;
    const groupBy = recruiter ? 'recruiter' : 'team';

    return Promise.all([
      getYTDActivityPlacementsSummary({ ...requestParams, groupBy }),
      getYTDSendoutsAndSignedAgreementsSummary({ ...requestParams, groupBy })
    ]).then(([placementsData, sendoutsAndSignedAgreements]) => {
      const combinedData = {
        ...placementsData.data.data,
        ...sendoutsAndSignedAgreements.data.data
      };

      return formatStats({ data: combinedData, drawerHandlers });
    });
  }, [hasDrillDown, openDrawer, requestParams]);

  return (
    <StatsItemsCard
      header={strings.commandCenter.activity.productivityStatsSummary.title}
      promise={cachedPromises}
      textAlign="center"
    />
  );
};
