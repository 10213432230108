import { useCallback, useState } from 'react';

export const useRefreshStats = () => {
  const [refreshKey, setRefreshKey] = useState(0);

  const refreshData = useCallback(() => {
    setRefreshKey(prevKey => prevKey + 1);
  }, []);

  return {
    refreshKey,
    refreshData
  };
};
