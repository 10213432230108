// @flow
import React from 'react';

import Content from './Content';

const DashboardPhone = props => {
  return <Content {...props} />;
};

export default DashboardPhone;
