import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomDatePicker from 'UI/components/atoms/CustomDatePicker';
import TextBox from 'UI/components/atoms/TextBox';
import ActiveFilters from 'UI/components/molecules/ActiveFilters';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { FilterType } from 'UI/constants/defaults';
import { createCustomStaticRanges } from 'UI/utils';

import { DateRange } from './components/DateRange';
import { FilterAutoComplete } from './components/FilterAutoComplete';
import { InlineFiltersConfig } from './InlineFilters.constants';
import { useInlineFilters } from './InlineFilters.hooks';
import { InlineFiltersWrapper } from './InlineFilters.styled';
import { ensureIfFilterIsActive } from './InlineFilters.utils';

export const InlineFilters = ({ filters, onFiltersChange, extraFilters, persistenceKey }) => {
  const {
    activeFilters,
    filterValues,
    filtersConfig,
    handleFilterChange,
    handleDateRangeChange,
    handleCheckToggle,
    handleFilterRemove,
    handleReset
  } = useInlineFilters({
    filters,
    extraFilters,
    persistenceKey,
    onFiltersChange
  });

  const getFilterComponent = filter => {
    const isVisible = ensureIfFilterIsActive(filter, activeFilters);

    if (!isVisible) return null;

    const filtersMap = {
      [FilterType.Text]: () => <TextBox {...filter} />,
      [FilterType.Autocomplete]: () => (
        <FilterAutoComplete
          key={filter.name}
          filter={filter}
          onFilterChange={handleFilterChange(FilterType.Autocomplete)}
          value={filterValues[filter.name]?.value || filter?.defaultValue}
          defaultOptions={filter?.options}
        />
      ),
      [FilterType.DateRange]: () => (
        <DateRange
          key={filter.name}
          DateRangerPickerProps={{
            staticRanges: createCustomStaticRanges(additionalPeriodRanges)
          }}
          onPeriodChange={handleDateRangeChange(filter.name)}
          {...filter}
          defaultRange={filterValues[filter.name]?.value || filter?.defaultRange}
        />
      ),
      [FilterType.Date]: () => (
        <CustomDatePicker
          key={filter.name}
          name={filter.name}
          label={filter.title}
          value={filterValues[filter.name]?.value || null}
          onDateChange={handleFilterChange(FilterType.Date)}
        />
      ),
      [FilterType.Switch]: () => (
        <FormControlLabel
          label={filter.title}
          key={filter.name}
          control={
            <Checkbox
              checked={filterValues[filter.name]?.value === filter.title}
              color="primary"
              name={filter.name}
              title={filter.title}
              value={filterValues[filter.name]?.value}
              onChange={handleCheckToggle}
              inputProps={{
                title: filter.title
              }}
            />
          }
        />
      )
    };

    if (!Object.keys(filtersMap).includes(filter.type)) {
      throw new Error(`Filter type ${filter.type} is not supported`);
    }

    return filtersMap[filter.type]();
  };

  return (
    <>
      <InlineFiltersWrapper>
        {filtersConfig.map(filter => getFilterComponent(filter))}
      </InlineFiltersWrapper>
      <ActiveFilters
        shouldDisplayLabel={false}
        filters={activeFilters}
        extraFiltersConfig={InlineFiltersConfig}
        isLoading={false}
        onFilterRemove={handleFilterRemove}
        onReset={handleReset}
      />
    </>
  );
};
