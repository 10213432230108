import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    cursor: 'pointer',
    paddingTop: theme.spacing(2),
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.secondary.FP200
    }
  },
  content: {
    padding: theme.spacing(0, 1)
  },
  divider: {
    height: '0.5px',
    margin: theme.spacing(2, 0, 0, 0)
  },
  mainText: {
    color: theme.palette.primary.light,
    fontSize: 14,
    fontWeight: '500 !important',
    lineHeight: '16px'
  },
  primaryContent: {
    alignItems: ({ isSmallViewPort }) => isSmallViewPort && 'center',
    display: ({ isSmallViewPort }) => isSmallViewPort && 'flex',
    width: '100%'
  },
  secondaryContent: {
    alignItems: ({ isSmallViewPort }) => isSmallViewPort && 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  secondaryText: {
    color: theme.palette.grey[800],
    fontSize: 10,
    fontWeight: '500 !important',
    letterSpacing: '1.5px',
    lineHeight: '16px',
    opacity: '0.60',
    textTransform: 'uppercase'
  },
  subtitle: {
    color: theme.palette.grey[800],
    fontSize: 12,
    fontWeight: '500 !important',
    letterSpacing: '0.4px',
    lineHeight: '20px',
    opacity: '0.60'
  },
  subtitleContent: {
    alignItems: ({ isSmallViewPort }) => isSmallViewPort && 'center',
    marginLeft: ({ isSmallViewPort }) => isSmallViewPort && theme.spacing(2.25),
    marginTop: ({ isSmallViewPort }) => !isSmallViewPort && theme.spacing(0.5)
  }
}));
