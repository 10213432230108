import { makeStyles } from '@material-ui/core/styles';
import { ExpandedDrawerWidths } from 'UI/constants/dimensions';

export const useStyles = makeStyles(theme => ({
  resume: {
    paddingRight: theme.spacing(3),
    '& > *': {
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3),
      width: '100%',
      backgroundColor: theme.palette.secondary.FP100,
      boxShadow:
        '0px 1px 4px rgba(3, 13, 40, 0.12), 0px 2px 2px -4px rgba(3, 13, 40, 0.08), 0px 1px 3px rgba(3, 13, 40, 0.03)',
      borderRadius: 4
    },
    '&:last-child': {
      marginBottom: 0
    }
  },
  details: {
    '& > *': {
      marginBottom: theme.spacing(2)
    },
    '& > :last-child': {
      marginBottom: 0
    }
  },
  detailValue: {
    fontWeight: '500 !important'
  },
  searchProjectLink: {
    width: 'fit-content'
  },
  chart: {
    overflow: 'hidden'
  },
  drawerRoot: {
    width: ExpandedDrawerWidths.largeViewports,
    overflowY: 'hidden',
    [theme.breakpoints.down(ExpandedDrawerWidths.largeViewports)]: {
      width: ExpandedDrawerWidths.smallViewports
    }
  },
  infoSnackbar: {
    marginBottom: theme.spacing(2.5)
  },
  actionButtons: {
    display: 'flex'
  },
  statsAlertAction: {
    whiteSpace: 'nowrap'
  }
}));
