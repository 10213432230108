import { No, Yes } from 'UI/constants/defaults';

export const InterviewDatePrefix = 'preset_interview_dates';
/**
 * This map is used to transform data before submission to the API and also to preload data from the API into a form
 * Entries can have the following props:
 * If only key is present no transformatios is applied in neither direction (to/from the API)
 * If paramKey is present, then paramKey is sent to the API
 * outboundAccesor is a key to extract a value from an object. For instance, an outboundAccesor = 'id' would evaluate to object['id'] and this value would be sent to the API
 * inboundAccesor is a property path used to preload data coming from the API into the form. It's useful for nested objects, for instance: 'city.state' or 'address.zip'. If a more custom object is needed, then inboundAccessor could be a function.
 */

export const FormFieldsMap = {
  Id: {
    key: 'id'
  },
  DiscussingAgreementComplete: {
    key: 'discussing_agreement_complete',
    inboundAccesor: ({ discussing_agreement_complete }) =>
      discussing_agreement_complete === 1 ? Yes.id : No.id
  },
  PreparedToSignServiceAgreement: {
    key: 'company_prepared_to_sign_service_agreement',
    paramKey: 'company_prepared_to_sign_service_agreement',
    inboundAccesor: ({ company_prepared_to_sign_service_agreement }) =>
      company_prepared_to_sign_service_agreement ? Yes.id : No.id
  },
  PreparedToInterview: {
    key: 'company_prepared_to_interview_asap',
    inboundAccesor: ({ company_prepared_to_interview_asap }) =>
      company_prepared_to_interview_asap ? Yes.id : No.id
  },
  CompanyFeeAgreement: {
    key: 'companyFeeAgreement',
    paramKey: 'company_fee_agreement_id',
    outboundAccesor: 'id'
  },
  FeeAgreeementPercent: {
    key: 'fee_agreement_percent',
    paramKey: 'fee_agreement_percent'
  },
  FeeAgreementPaymentSchemeId: {
    key: 'fee_agreement_payment_scheme_id',
    paramKey: 'fee_agreement_payment_scheme_id',
    inboundAccesor: ({ companyFeeAgreement }) =>
      companyFeeAgreement?.fee_agreement_payment_scheme_id || null
  },
  Guarantee: {
    key: 'warranty_time_in_days',
    paramKey: 'warranty_time_in_days'
  },
  TimePositionOpen: {
    key: 'time_position_open',
    paramKey: 'time_position_open'
  },
  PositionFilled: {
    key: 'position_filled',
    paramKey: 'position_filled'
  },
  MaximumSalary: {
    key: 'maximum_compensation',
    paramKey: 'maximum_compensation'
  },
  MinimumSalary: {
    key: 'minimum_compensation',
    paramKey: 'minimum_compensation'
  },
  IdealSalary: {
    key: 'good_compensation',
    paramKey: 'good_compensation'
  },
  Benefits: {
    key: 'benefits'
  },
  Background: {
    key: 'background_requirements'
  },
  WorkType: {
    key: 'workTypeOption',
    paramKey: 'work_type_option_id',
    outboundAccesor: 'id'
  },
  Type: {
    key: 'jobOrderType',
    paramKey: 'job_order_type_id',
    outboundAccesor: 'id'
  },
  Status: {
    key: 'status',
    paramKey: 'status_id',
    outboundAccesor: 'id',
    inboundAccesor: (object, context) => context.status
  },
  Notes: {
    key: 'notes'
  },
  Interview: {
    key: InterviewDatePrefix
  }
};
