// @flow

import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { globalStyles } from 'GlobalStyles';
import TextBox from 'UI/components/atoms/TextBox';
import EmailPicker from 'UI/components/molecules/EmailPicker';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { FeeAgreementMaxCopies } from 'UI/constants/status';
import { VALIDATION_REGEXS } from 'UI/utils';

type FeeAgreementEmailsFormProps = {
  currentEmail: string,
  ccEmails: Object[]
};

const FeeAgreementEmailsForm = (props: FeeAgreementEmailsFormProps) => {
  const { ccEmails, currentEmail } = props;

  const { register, unregister, errors, setValue, watch, reset, control } = useFormContext();

  useEffect(() => {
    register({ name: 'cc_emails' });

    return () => unregister('cc_emails');
  }, [register, unregister]);

  useEffect(() => {
    if (!ccEmails) {
      return;
    }
    const initialEmails = ccEmails.map(each =>
      typeof each === 'string' ? { work_email: each } : each
    );

    reset({ cc_emails: initialEmails, email: currentEmail, update_ha: false });
  }, [ccEmails, currentEmail, reset]);

  const handleCcChange = (name?: string, values: Object[]) => {
    setValue(name, values, true);
  };

  const ccs = watch('cc_emails');
  return (
    <>
      <Typography gutterBottom>Change the email of the signer:</Typography>
      <TextBox
        name="email"
        label="Signer's email"
        inputRef={register({
          required: 'The email is required',
          pattern: {
            value: VALIDATION_REGEXS.EMAIL,
            message: 'The email is not valid'
          }
        })}
        error={!!errors.email}
        errorText={errors.email && errors.email.message}
      />
      <FormControlLabel
        control={
          <Controller
            name="update_ha"
            control={control}
            as={<Checkbox color="primary" size="small" />}
            defaultValue={false}
          />
        }
        label="Also make this as the Hiring Authority's default email"
      />
      <Divider style={globalStyles.mediumDivider} />
      <Typography gutterBottom>Add CCs to other people involved in the signing process</Typography>
      <EmailPicker
        name="cc_emails"
        placeholder="CCs"
        selectedValue={ccs}
        error={!!errors.cc_emails}
        errorText={errors.cc_emails && errors.cc_emails.message}
        endpoint={Endpoints.Search}
        typeaheadParams={{
          entityType: EntityType.HiringAuthority,
          inColumns: ['ha.work_email']
        }}
        maxItems={FeeAgreementMaxCopies}
        allowNewItems
        onSelect={handleCcChange}
      />
    </>
  );
};

FeeAgreementEmailsForm.defaultProps = {
  ccEmails: []
};

export default FeeAgreementEmailsForm;
