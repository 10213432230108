export const FORM_FIELDS_MAP = {
  SearchProjectName: {
    key: 'searchProjectName'
  },
  SplitOptions: {
    key: 'splitOptions'
  },
  SplitIntoParts: {
    key: 'splitIntoParts'
  }
};
