export const formatResponse = response => {
  if (!response || response.length === 0) {
    return [
      ['Month', 'Count'],
      ['', 0]
    ];
  }

  const formattedData = [
    ['Month', 'Count'],
    ...response.map(item => [`${item.month} ${item.year}`, item.total])
  ];

  return formattedData;
};
