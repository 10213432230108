// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import { showAlert } from 'actions/app';
import { getChannelPartnersByUserId, useFetchUsers } from 'services/Placements';
import type { Split } from 'types/app';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import { SplitType } from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { colors, DeleteIcon } from 'UI/res';
import { getId, SPLIT_PERCENT_VALIDATION } from 'UI/utils';

import { useStyles } from '../styles';
import { DefaultChannelPartnerPercentage, PercentKeyPrefix, RecruiterKeyPrefix } from '../utils';

export type PlacementSplitsSectionProps = {
  copyPaste?: boolean,
  initialSplits: Split[],
  onSplitsChange: (splits: Splits[]) => void,
  readOnly?: boolean,
  canChangeChannelPartner?: boolean
};

const PlacementSplitsSection = (props: PlacementSplitsSectionProps) => {
  const { copyPaste, initialSplits, onSplitsChange, readOnly, canChangeChannelPartner } = props;
  const [splits, setSplits] = useState<Split[]>([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, unregister, errors, setValue, getValues, triggerValidation, watch } =
    useFormContext();
  const formValues = watch();

  const users = useFetchUsers([Roles.Recruiter, Roles.Coach]);

  const registerSplitItem = useCallback(
    (split: Split) => {
      if (!split) return;

      register({ name: `${RecruiterKeyPrefix}${split.id}` }, { required: true });
      register(
        { name: `${PercentKeyPrefix}${split.id}` },
        { required: true, ...SPLIT_PERCENT_VALIDATION }
      );
      setValue(`${RecruiterKeyPrefix}${split.id}`, split.recruiter);
      split.percent && setValue(`${PercentKeyPrefix}${split.id}`, split.percent);
    },
    [register, setValue]
  );

  const registerSplit = useCallback(
    split => {
      if (!split) return;

      registerSplitItem(split);

      const { channelPartners } = split;

      channelPartners?.length > 0 &&
        channelPartners.forEach(channelPartner => registerSplitItem(channelPartner));

      setSplits(prevState => [...prevState, split]);
    },
    [registerSplitItem]
  );

  useEffect(() => {
    return () => {
      const values = getValues();
      const splitFields = Object.keys(values).filter(
        field => field.startsWith(PercentKeyPrefix) || field.startsWith(RecruiterKeyPrefix)
      );

      splitFields.forEach(field => unregister(field));
    };
  }, [getValues, unregister]);

  useEffect(() => {
    initialSplits.forEach(split => registerSplit(split));
  }, [initialSplits, registerSplit]);

  useEffect(() => {
    onSplitsChange && onSplitsChange(splits);
  }, [splits, onSplitsChange]);

  useEffect(() => {
    register(
      { name: 'splits_valid' },
      {
        validate() {
          const values = getValues();
          const splitFields = splits.map(({ id }) => `${PercentKeyPrefix}${id}`);
          const sum = splitFields.reduce((accumulator, currentField) => {
            const percentage = Number.parseFloat(values[currentField]);
            return accumulator + (Number.isNaN(percentage) ? 0 : percentage);
          }, 0);
          return sum === 100.0 || 'The splits percentages should sum up 100%';
        }
      }
    );
    return () => unregister('splits_valid');
  }, [register, unregister, getValues, splits]);

  const handleTextChange = (field: string, value: any) => {
    setValue(field, value, true);
    field.startsWith(PercentKeyPrefix) && triggerValidation('splits_valid');
  };

  const updateSplit = (split: Split) =>
    setSplits(prevState => prevState.map(each => (each.id !== split.id ? each : split)));

  const addSplit = (type: string) => () => registerSplit({ type, id: getId() });

  const unregisterSplitItem = (id: number) => {
    unregister(`${RecruiterKeyPrefix}${id}`);
    unregister(`${PercentKeyPrefix}${id}`);
  };

  const removeSplit = (id: number) => {
    const split = splits.find(each => each.id === id);

    unregisterSplitItem(id);

    split.channelPartners?.length > 0 &&
      split.channelPartners.forEach(channelPartner => unregisterSplitItem(channelPartner.id));

    setSplits(prevState => prevState.filter(each => each.id !== id));

    triggerValidation('splits_valid');
  };

  const handleRecruiterChange = async (field: string, value: any) => {
    const splitId = parseInt(field.split('_').pop(), 10);
    const split: Split = splits.find(each => each.id === splitId);

    const isRecruiterAlreadySelected =
      value &&
      splits.find(({ type, recruiter }) => type === split.type && recruiter?.id === value.id);

    if (isRecruiterAlreadySelected) {
      dispatch(
        showAlert({
          severity: 'warning',
          title: 'Placement',
          body: 'The recruiter is already in the split'
        })
      );
      return;
    }

    setValue(field, value, true);
    split.recruiter = value;
    const recruiterId = value.id;

    updateSplit({ ...split, isFetchingPartner: true });
    const channelPartnersResult = await getChannelPartnersByUserId(recruiterId);
    updateSplit({ ...split, isFetchingPartner: false });

    const newChannelPartners =
      channelPartnersResult.data && channelPartnersResult.data.length > 0
        ? channelPartnersResult.data.map(cp => ({
            id: cp.user_referral_id,
            full_name: cp.user_referral_full_name,
            initials: cp.user_referral_initials,
            percent: cp.percent
          }))
        : null;

    split.channelPartners?.length > 0 &&
      split.channelPartners.map(cp => unregisterSplitItem(cp.id));

    split.channelPartners = newChannelPartners
      ? newChannelPartners.map(newCp => ({
          id: getId(),
          isChannelPartner: true,
          type: split.type,
          recruiter: newCp,
          percent: newCp.percent
        }))
      : null;
    split.channelPartners && split.channelPartners.forEach(cp => registerSplitItem(cp));
    updateSplit(split);
  };

  const handleChannelPartnerChange = async (field: string, value: any) => {
    const splitId = parseInt(field.split('_').pop(), 10);
    const split: Split = splits.find(each =>
      each.channelPartners?.some(({ id }) => id === splitId)
    );
    if (!split) return;

    setValue(field, value, true);
    split.channelPartners = split.channelPartners.map(channelPartner =>
      channelPartner.id !== splitId
        ? channelPartner
        : {
            ...channelPartner,
            recruiter: {
              ...value,
              percent: DefaultChannelPartnerPercentage / split.channelPartners.length
            }
          }
    );
    updateSplit(split);
  };

  const renderSplitRow = ({
    id,
    isChannelPartner,
    isFetchingPartner = false,
    isDeletable
  }: Split) => {
    const canDelete = isDeletable && !isChannelPartner && !readOnly;
    const recruiterKey = `${RecruiterKeyPrefix}${id}`;
    const percentKey = `${PercentKeyPrefix}${id}`;
    const isSplitReadOnly = (isChannelPartner && !canChangeChannelPartner) || readOnly;
    const recruiterPlaceholder = isChannelPartner ? 'Channel Partner' : 'Recruiter';
    return (
      <>
        <Grid item xs={6}>
          {isSplitReadOnly && copyPaste ? (
            <TextBox
              name={recruiterKey}
              placeholder={recruiterPlaceholder}
              value={formValues[recruiterKey]?.full_name || ''}
              className={classes.input}
              outPutValue
              enableCopyButton
            />
          ) : (
            <AutocompleteSelect
              name={recruiterKey}
              placeholder={recruiterPlaceholder}
              defaultOptions={users}
              selectedValue={formValues[recruiterKey]}
              displayKey="full_name"
              className={classes.input}
              onSelect={!isChannelPartner ? handleRecruiterChange : handleChannelPartnerChange}
              error={!!errors[recruiterKey]}
              errorText={errors[recruiterKey] && errors[recruiterKey].message}
              disableClearable
              disabled={isSplitReadOnly}
              fetching={isFetchingPartner}
            />
          )}
        </Grid>
        <Grid item xs={6} className={classes.splitFieldContainer}>
          {!isChannelPartner && (
            <TextBox
              name={percentKey}
              label="Split *"
              value={formValues[percentKey] || ''}
              error={!!errors[percentKey]}
              errorText={errors[percentKey] && errors[percentKey].message}
              onChange={handleTextChange}
              inputType="percentage"
              className={classes.input}
              outPutValue={isSplitReadOnly}
              enableCopyButton={copyPaste}
            />
          )}
          {canDelete && (
            <FPIconButton
              edge="end"
              onClick={() => {
                removeSplit(id);
              }}
              disabled={!canDelete}
            >
              <DeleteIcon fill={canDelete ? colors.oxford : colors.lightgray} />
            </FPIconButton>
          )}
        </Grid>
      </>
    );
  };

  const renderSplit = (split: Split) => {
    return (
      <>
        {renderSplitRow(split)}
        {Array.isArray(split.channelPartners) &&
          split.channelPartners.map(channelPartner => (
            <React.Fragment key={channelPartner.id}>
              {renderSplitRow(channelPartner)}
            </React.Fragment>
          ))}
      </>
    );
  };

  const renderSplitSection = (
    splitType: string,
    section: string,
    sectionSplits: Split[],
    isRequired: boolean
  ) => (
    <>
      <Typography variant="body2" paragraph>
        {section} Recruiter(s)
      </Typography>
      {sectionSplits.length === 0 && !readOnly && (
        <Typography variant="body1" paragraph color="textSecondary">
          <HelpIcon color="disabled" />
          Awesome, it appears this is a solo deal. If not, you should add the candidate recruiter.
        </Typography>
      )}
      {sectionSplits.map((split, index) => (
        <Grid key={split.id} container spacing={2} className={classes.splitContainer}>
          {renderSplit({ ...split, isDeletable: index > 0 || (index === 0 && !isRequired) })}
        </Grid>
      ))}
      {!readOnly && (
        <Typography className={classes.adderContainer}>
          Is there another Recruiter related to this {section}?
          <Button color="primary" className={classes.linkButton} onClick={addSplit(splitType)}>
            Add Recruiter
          </Button>
        </Typography>
      )}
    </>
  );

  const companySplits = splits.filter(({ type }) => type === SplitType.Company);
  const candidateSplits = splits.filter(({ type }) => type === SplitType.Candidate);

  return (
    <NumberedForm.Item title="Split Details">
      {renderSplitSection(SplitType.Company, 'Company', companySplits, true)}
      {renderSplitSection(SplitType.Candidate, 'Candidate', candidateSplits, false)}

      {!!errors.splits_valid && (
        <FormControl component="fieldset" error>
          <FormHelperText>{errors.splits_valid.message}</FormHelperText>
        </FormControl>
      )}
    </NumberedForm.Item>
  );
};

PlacementSplitsSection.defaultProps = {
  copyPaste: false,
  readOnly: false,
  canChangeChannelPartner: false
};

export default PlacementSplitsSection;
