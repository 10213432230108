// @flow
import React, { useEffect, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import type { TextProps } from 'types/app';

import { useStyles } from './styles';

export default function Text({
  text,
  cropped,
  fontSize,
  customStyle,
  className,
  loading,
  skeletonProps,
  ...rest
}: TextProps) {
  const classes = useStyles();

  const ref = useRef(null);

  useEffect(() => {
    if (!loading) {
      ref.current.innerHTML = text;
    }
  }, [ref, text, loading]);

  return loading ? (
    <Skeleton {...skeletonProps} />
  ) : (
    <Typography
      style={{ ...customStyle, fontSize }}
      className={clsx(classes.regular, className)}
      noWrap={cropped}
      innerRef={ref}
      {...rest}
    />
  );
}
Text.defaultProps = {
  fontSize: undefined,
  cropped: false,
  customStyle: {},
  className: null,
  skeletonProps: {},
  loading: false
};
