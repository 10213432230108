// @flow
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import strings from 'strings';
import type { DatePeriod } from 'types/app';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import {
  CoachFilterDef,
  RegionalFilterDef
} from 'UI/components/templates/SideFiltersLayout/filters';
import { backNavigateListConfig } from 'UI/constants/config';
import { DateFormats } from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getCurrentPeriod, hasFeatureFlag } from 'UI/utils';

import { useStyles } from '../styles';

const OPTION_ALL = 'All';
const prependOptionsAll = [{ full_name: OPTION_ALL }];

export const AutocompleteByRegional = ({ regional, onSelect, prependOptions }) => (
  <AutocompleteSelect
    value={regional}
    placeholder="Region"
    onSelect={onSelect}
    url={RegionalFilterDef.url}
    disableClearable
    displayKey="full_name"
    prependOptions={prependOptions}
  />
);

export const AutocompleteByCoach = ({ coach, onSelect, prependOptions, regional }) => {
  const areMultipleDivisionsEnable = hasFeatureFlag(FeatureFlags.HomeMultipleRegions);
  const regionalFullName =
    areMultipleDivisionsEnable && regional?.full_name ? regional?.full_name : null;
  return (
    <AutocompleteSelect
      disableClearable
      disabled={
        areMultipleDivisionsEnable && (regionalFullName === OPTION_ALL || !regionalFullName)
      }
      displayKey="full_name"
      onSelect={onSelect}
      placeholder="Team"
      prependOptions={prependOptions}
      url={
        areMultipleDivisionsEnable
          ? `${CoachFilterDef.url}&regionalIds=${regional?.id}`
          : CoachFilterDef.url
      }
      value={coach}
    />
  );
};

const PlotlyDashboard = ({ selectedPeriod, regional, coach }) => {
  const classes = useStyles();
  const PLOTLY_DASH_SITE_URL =
    window.GPAC_ENV?.PLOTLY_DASH_SITE_URL || process.env.REACT_APP_PLOTLY_DASH_SITE_URL;
  const startDate = moment(selectedPeriod.startDate).format(DateFormats.QueryShortFormat);
  const endDate = moment(selectedPeriod.endDate).format(DateFormats.QueryShortFormat);
  const endpoint = `${PLOTLY_DASH_SITE_URL}?regional=${regional?.full_name}&coach=${coach?.full_name}&start_date=${startDate}&end_date=${endDate}`;
  return (
    <iframe
      title={strings.dashboard.toolsUsageDashboard.title}
      src={endpoint}
      frameBorder={0}
      className={classes.plotlyIframe}
      allowTransparency
    />
  );
};

const getRegionalInitialState = data => {
  const option = data?.regional?.full_name;
  return { full_name: option || OPTION_ALL };
};

const getCoachInitialState = data => {
  const option = data?.coach?.full_name;
  return { full_name: option || OPTION_ALL };
};

const UserToolReports = ({ data }) => {
  const classes = useStyles();
  const [regional, setRegional] = useState(getRegionalInitialState(data));
  const [coach, setCoach] = useState(getCoachInitialState(data));
  const defaultPeriod = getCurrentPeriod('month');
  const [selectedPeriod, setSelectedPeriod] = useState<DatePeriod>(defaultPeriod);

  const onSelectForRegional = (event, newValue) => {
    setRegional(newValue);
    setCoach({ full_name: OPTION_ALL });
  };

  const onSelectForCoach = (event, newValue) => {
    setCoach(newValue);
  };

  const handlePeriodChange = (period: DatePeriod) => {
    setSelectedPeriod(period);
  };

  return (
    <FiltersLayout
      titleLabelProps={backNavigateListConfig}
      title={strings.dashboard.toolsUsageDashboard.title}
      onPeriodChange={handlePeriodChange}
      disableSideMenu
      defaultRange={selectedPeriod}
    >
      <Grid container justify="space-between">
        <Grid container item>
          <Grid item xs={3} className={classes.gridContainerItem}>
            <AutocompleteByRegional
              regional={regional}
              onSelect={onSelectForRegional}
              prependOptions={prependOptionsAll}
            />
          </Grid>
          <Grid item xs={3} className={classes.gridContainerItem}>
            <AutocompleteByCoach
              coach={coach}
              onSelect={onSelectForCoach}
              prependOptions={prependOptionsAll}
            />
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.iframeStyle}>
        <PlotlyDashboard selectedPeriod={selectedPeriod} regional={regional} coach={coach} />
      </div>
    </FiltersLayout>
  );
};

export default UserToolReports;
