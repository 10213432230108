import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: 'auto',
    display: 'flex',
    minWidth: 200,
    justifyContent: 'flex-start'
  },
  iconMargin: {
    marginRight: theme.spacing(1)
  },
  communicationAction: {
    color: 'currentcolor',
    '&:hover': {
      color: 'currentcolor',
      textDecoration: 'none'
    }
  },
  disabledItem: {
    cursor: 'default',
    color: '#abacae !important',
    pointerEvents: 'none'
  },
  startIcon: {
    '& .MuiButton-startIcon': {
      transformOrigin: 'center'
    }
  }
}));
