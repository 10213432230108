import { EntityRoutes } from 'routes/constants';
import strings from 'strings';

export const commonColumns = [
  {
    name: 'status',
    label: strings.shared.fields.status,
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'statusBuilder',
        config: {
          idKey: 'statusId',
          labelKey: 'status',
          baseClass: 'fa-status'
        }
      }
    }
  },
  {
    name: 'company',
    label: strings.shared.fields.company,
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: `${EntityRoutes.CompanyProfile}?tab=feeagreements`,
          idKey: 'companyId',
          textKey: 'company',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'id',
    label: 'Fee Nº',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'industry',
    label: strings.shared.fields.industry,
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'feePercentage',
    label: strings.feeAgreements.fields.fee,
    options: {
      sort: true,
      display: true,
      renderer: 'fee'
    }
  },
  {
    name: 'guaranteeDays',
    label: strings.feeAgreements.fields.guaranteeDays,
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'recruiterName',
    label: strings.shared.fields.recruiter,
    options: {
      sort: true,
      display: true
    }
  }
];

export const SENT_AGREEMENTS_COLUMNS = [
  ...commonColumns,
  {
    name: 'validatedDate',
    label: strings.feeAgreements.fields.sentAt,
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  }
];

export const SIGNED_AGREEMENTS_COLUMNS = [
  ...commonColumns,
  {
    name: 'hiringAuthoritySignDate',
    label: strings.feeAgreements.fields.haSignedAt,
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'signedDate',
    label: strings.feeAgreements.fields.gpacSignedAt,
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  }
];

export const VIRTUAL_PROPS = [
  {
    propName: 'status',
    attributes: ['statusId', 'status']
  },
  {
    propName: 'company',
    attributes: ['companyId', 'company']
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'id',
  direction: 'asc'
};
