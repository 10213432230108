export const FormFieldsMap = Object.freeze({
  AdditionalInvoiceRecipients: {
    key: 'additional_invoice_recipients'
  },
  PaymentScheme: {
    key: 'fee_agreement_payment_scheme_id'
  },
  PaymentTerms: {
    key: 'payment_terms_id'
  },
  FeeAgreement: {
    key: 'company_fee_agreement_id'
  },
  StartDate: {
    key: 'start_date'
  },
  GuaranteeDays: {
    key: 'guarantee_days'
  },
  FeePercentage: {
    key: 'fee_percentage'
  },
  FirstYearCompensation: {
    key: 'first_year_value'
  },
  ServiceMonths: {
    key: 'service_months'
  },
  MonthlyAmount: {
    key: 'monthly_amount'
  },
  FeeAmount: {
    key: 'fee_amount'
  },
  CandidateSourceType: {
    key: 'candidateSourceType'
  },
  JobOrderSourceType: {
    key: 'jobOrderSourceType'
  },
  BillToCompany: {
    key: 'billToCompany'
  },
  ShouldBillToOtherCompany: {
    key: 'shouldBillToOtherCompany'
  },
  HiringAuthority: {
    key: 'hiringAuthority'
  },
  FallOffReason: {
    key: 'placement_fall_off_reason_id'
  },
  OtherFallOffReason: {
    key: 'fall_off_reason'
  },
  CandidateStillAvailable: {
    key: 'candidate_still_available'
  },
  JobOrderStillOpen: {
    key: 'job_still_open'
  },
  Notes: {
    key: 'notes'
  },
  FallOffNotes: {
    key: 'fall_off_notes'
  },
  References: {
    key: 'reference_check_ids'
  },
  AreReferencesValid: {
    key: 'referenceCheckValidation'
  },
  CompanyPhone: {
    key: 'phone'
  },
  CompanyPhoneExtension: {
    key: 'ext'
  },
  ShouldRequestFeeChange: {
    key: 'shouldRequestFeeChange'
  },
  ShouldRequestGuaranteePeriodChange: {
    key: 'shouldRequestGuaranteePeriodChange'
  },
  ReasonForRequestChange: {
    key: 'reasonForRequestChange'
  }
});
