// @flow
import React from 'react';
import NumberFormat from 'react-number-format';
import { TextField, TextFieldProps } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { colors, CopyIcon } from 'UI/res';
import { nestTernary } from 'UI/utils/index';

import { useStyles } from './styles';

// Custom Number Format Masks for phone, currency or any number (including phone extensions)
type NumberFormatCustomProps = {
  inputRef: any,
  onChange: any => any,
  type: Object,
  name: string,
  disableDecimals?: boolean,
  disableNegatives?: boolean
};
export const NumberFormatCustom = (props: NumberFormatCustomProps) => {
  const { inputRef, onChange, type, disableDecimals, disableNegatives, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      name={name}
      aria-label={name}
      getInputRef={inputRef}
      onValueChange={values => {
        const { formattedValue, value } = values;
        onChange({
          target: {
            name,
            value,
            formattedValue
          }
        });
      }}
      thousandSeparator={type === 'currency' ? true : undefined}
      isNumericString={type === 'currency' ? true : undefined}
      format={type === 'phone' ? '(###)-###-####' : undefined}
      prefix={type === 'currency' ? '$' : undefined}
      suffix={type === 'percentage' ? '%' : undefined}
      allowNegative={!disableNegatives}
      decimalScale={disableDecimals ? 0 : undefined}
    />
  );
};

type TextBoxProps = TextFieldProps & {
  label: string, //
  onChange?: (name: string, value: any, formattedValue: any) => void, //
  value?: ?string, //
  name: string, //
  disabled?: boolean,
  errorText?: string, //
  width?: string,
  minWidth?: any, //
  rows: number,
  defaultValue: string,
  outPutValue: boolean,
  inputType: 'currency' | 'phone' | 'number' | 'text' | 'percentage',
  placeholder: string,
  enableCopyButton?: boolean,
  noMargin: boolean,
  labelAsPlaceholder?: boolean
};

const TextBox = ({
  label,
  errorText,
  onChange,
  value,
  name,
  width,
  disabled,
  minWidth,
  rows,
  defaultValue,
  inputType,
  outPutValue,
  placeholder,
  labelAsPlaceholder,
  enableCopyButton,
  noMargin,
  disableDecimals,
  disableNegatives,
  ...rest
}: TextBoxProps) => {
  const additionalProps =
    value !== null
      ? {
          value
        }
      : nestTernary(defaultValue !== null, { defaultValue }, {});

  const handleChange = event => {
    const { value: text, formattedValue } = event.target;
    onChange && onChange(name, text, formattedValue);
  };
  const handleCopyClick = () => copy(value || defaultValue || '');

  const classes = useStyles({ rows, outPutValue, noMargin, width, minWidth });

  const numberInputProps =
    inputType === 'number' ||
    inputType === 'percentage' ||
    inputType === 'currency' ||
    inputType === 'phone'
      ? {
          type: inputType,
          inputComponent: NumberFormatCustom,
          inputProps: {
            disableDecimals,
            disableNegatives
          }
        }
      : null;

  const inputProps = {
    ...numberInputProps,
    endAdornment: enableCopyButton ? (
      <FPIconButton
        tooltipProps={{ placement: 'bottom', title: 'Copy to clipboard' }}
        onClick={handleCopyClick}
      >
        <CopyIcon fill={colors.black} />
      </FPIconButton>
    ) : undefined
  };

  return (
    <TextField
      classes={classes}
      disabled={disabled || outPutValue}
      helperText={errorText}
      onChange={handleChange}
      name={name}
      label={label}
      placeholder={placeholder || (labelAsPlaceholder ? label : null)}
      variant="outlined"
      {...additionalProps}
      autoComplete="new-password"
      rows={rows}
      InputProps={inputProps}
      {...rest}
    />
  );
};

TextBox.defaultProps = {
  outPutValue: false,
  minWidth: 'unset',
  width: '100%',
  disabled: false,
  errorText: '',
  value: null,
  onChange: undefined,
  inputRef: undefined,
  rows: 5,
  defaultValue: '',
  label: '',
  inputType: 'text',
  placeholder: '',
  enableCopyButton: false,
  noMargin: false,
  labelAsPlaceholder: true
};

export default TextBox;
