import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  HotCandidates,
  HotInventoryStatsSummary,
  HotJobOrders,
  InlineFilters
} from 'features/command-center';
import { useInlineFiltersContext } from 'features/command-center/components/shared/InlineFilters/InlineFiltersProvider';
import { INLINE_FILTERS_KEYS } from 'features/command-center/constants';
import { userHasRoles } from 'services/Authorization';
import { Roles } from 'UI/constants/roles';

import { ALL_COMPANY_VISIBILITY_ROLES } from './constants';

export const HotInventory = () => {
  const { filters: contextFilters } = useInlineFiltersContext();
  const [filters, setFilters] = useState({});
  const finalFilters = { ...filters, ...contextFilters };

  const inlineFilters = userHasRoles([
    ...ALL_COMPANY_VISIBILITY_ROLES,
    Roles.Coach,
    Roles.RegionalDirector
  ])
    ? ['recruiters', 'industries', 'specialties', 'subspecialties', 'tenures']
    : ['industries', 'tenures'];

  const handleFiltersChange = selectedFilters => {
    setFilters(selectedFilters);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <InlineFilters
          filters={inlineFilters}
          onFiltersChange={handleFiltersChange}
          persistenceKey={INLINE_FILTERS_KEYS.dominate.hotInventory}
        />
      </Grid>

      <Grid item xs={12}>
        <HotInventoryStatsSummary filters={finalFilters} />
      </Grid>

      <Grid item xs={12}>
        <HotCandidates filters={finalFilters} />
      </Grid>

      <Grid item xs={12}>
        <HotJobOrders filters={finalFilters} />
      </Grid>
    </Grid>
  );
};
