// @flow

const formatReferenceCheckResponse = (response: any): any => {
  const formattedData = (response.data?.data ?? []).map(item => ({
    ...item,
    referenceStatus: {
      type: item.progress.key,
      totalAnsweredQuestions: item.progress.current,
      totalQuestions: item.progress.total
    }
  }));

  return {
    data: {
      ...response.data,
      data: formattedData
    }
  };
};

export default formatReferenceCheckResponse;
