import * as React from 'react';

const SvgHiringAuthorities = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M3 21h8a1 1 0 001-1v-1.5a1.5 1.5 0 00-1.5-1.5h-7A1.5 1.5 0 002 18.5V20a1 1 0 001 1zM23 18a1.61 1.61 0 000-2.33l-6.81-6.14a.28.28 0 01-.09-.18.25.25 0 01.08-.18l1.76-1.76a.26.26 0 01.33 0 1.48 1.48 0 00.9.3 1.53 1.53 0 001.06-.44 1.51 1.51 0 000-2.12L15.54.44a1.49 1.49 0 00-2.12 0 1.51 1.51 0 00-.14 2 .23.23 0 010 .32l-5.56 5.5a.23.23 0 01-.32 0 1.51 1.51 0 00-2 .14 1.49 1.49 0 000 2.12l4.68 4.69a1.509 1.509 0 002.26-2 .26.26 0 010-.33l1.76-1.76a.25.25 0 01.18-.08.21.21 0 01.18.09L20.67 18A1.61 1.61 0 0023 18zM12.5 22h-11a1 1 0 000 2h11a1 1 0 000-2z" />
    </svg>
  );
};

export default SvgHiringAuthorities;
