import { makeStyles } from '@material-ui/core';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  containerTotalList: {
    borderBottom: `1px solid ${theme.palette.primary.FP50}`,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
    ...flexAlignCenterJustifyCenter
  },
  totalListTitle: {
    color: theme.palette.grey[800],
    fontSize: 14,
    fontWeight: '500 !important',
    lineHeight: '16px',
    opacity: '0.60'
  },
  totalListValue: {
    color: theme.palette.primary.FP300,
    fontFamily: '"Kanit", sans-serif',
    fontSize: 24,
    fontWeight: '500 !important',
    lineHeight: '150%',
    marginRight: theme.spacing(1.5)
  }
}));
