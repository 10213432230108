// @flow

type ConditionalActionWrapperProps = {
  condition: boolean,
  wrapper: Object
};

const ConditionalActionWrapper = ({
  condition,
  wrapper,
  children
}: ConditionalActionWrapperProps) => (condition ? wrapper(children) : children);

export default ConditionalActionWrapper;
