// @flow
import React from 'react';
import { THEME } from 'GlobalStyles';
import { SvgBuilding1, SvgCandidates, SvgHiringAuthorities } from 'UI/res/icons/filled';

import FPIcon from '../FPIcon';

const { palette } = THEME;

type avatarSizeProp = {
  size: string | number
};

export const avatarColors = {
  backgrounds: {
    candidate: palette.customColors.candidate.background,
    company: palette.info.FP100,
    hiringAuthority: palette.warning.FP100
  },
  icons: {
    candidate: palette.customColors.candidate.icon,
    company: palette.info.main,
    hiringAuthority: palette.warning.main
  }
};

export const avatarDefinitions = {
  types: {
    candidate: 'candidate',
    company: 'company',
    hiringAuthority: 'hiringAuthority',
    user: 'user',
    default: 'default'
  },
  icons: {
    candidate: (size: avatarSizeProp) => (
      <FPIcon
        component={SvgCandidates}
        size={size}
        color={avatarColors.icons.candidate}
        family="mini"
      />
    ),
    company: (size: avatarSizeProp) => (
      <FPIcon component={SvgBuilding1} size={size} color={avatarColors.icons.company} />
    ),
    hiringAuthority: (size: avatarSizeProp) => (
      <FPIcon
        component={SvgHiringAuthorities}
        color={avatarColors.icons.hiringAuthority}
        family="mini"
        size={size}
      />
    )
  }
};
