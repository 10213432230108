// @flow

import React, { useState } from 'react';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import { VALIDATION_REGEXS } from 'UI/utils/index';

type EmailPickerProps = {
  allowNewItems: boolean,
  emailKey: string,
  error: boolean,
  errorText: string,
  endpoint: url,
  maxItems: number,
  name: string,
  placeholder: string,
  selectedValue: Object[],
  typeaheadLimit: number,
  onSelect: (name: string, values: Object[]) => void
};

const EmailPicker = (props: EmailPickerProps) => {
  const {
    allowNewItems,
    emailKey,
    error,
    errorText,
    endpoint,
    maxItems,
    name,
    placeholder,
    selectedValue = [],
    typeaheadLimit,
    onSelect,
    ...rest
  } = props;

  const [inputValue, setInputValue] = useState('');

  const handleSelect = (_: string, values: any, reason: string) => {
    const newValues = values && [...values];

    if (reason === 'create-option' && allowNewItems) {
      const lastValue = newValues.pop();
      const emails = transformInputToEmailArray(lastValue);
      newValues.push(...emails);
    }
    limitEmailsAndApplyValues(newValues);
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleClose = (event, reason) => {
    if (reason === 'blur' && inputValue) {
      const emails = transformInputToEmailArray(inputValue);
      limitEmailsAndApplyValues([...selectedValue, ...emails]);
    }
  };

  const limitEmailsAndApplyValues = newEmails => {
    const cappedValues = maxItems !== -1 ? newEmails.slice(0, maxItems) : newEmails;
    onSelect && onSelect(name, cappedValues);
  };

  const transformInputToEmailArray = (input: string) => {
    const tokens = input.toLowerCase().split(/[\s,;\t]+/);
    const validEmails = tokens.filter(each => VALIDATION_REGEXS.EMAIL.test(each));

    return validEmails.map(each => (emailKey ? { [emailKey]: each } : each));
  };

  return (
    <AutocompleteSelect
      freeSolo={allowNewItems}
      multiple
      typeahead
      typeaheadLimit={typeaheadLimit}
      name={name}
      placeholder={placeholder}
      selectedValue={selectedValue}
      error={error}
      errorText={errorText}
      url={endpoint}
      inputValue={inputValue}
      onSelect={handleSelect}
      onInputChange={handleInputChange}
      onTypeaheadClose={handleClose}
      getOptionLabel={option => (emailKey ? option[emailKey] : option)}
      getOptionSelected={(option, value) =>
        emailKey ? option[emailKey] === value[emailKey] : option === value
      }
      renderOption={option => (
        <div>
          <strong>{option.full_name}</strong>
          <br />
          <span>{emailKey ? option[emailKey] : option}</span>
        </div>
      )}
      {...rest}
    />
  );
};

EmailPicker.defaultProps = {
  emailKey: 'work_email',
  endpoint: '',
  maxItems: 2,
  typeaheadLimit: 25,
  allowNewItems: true,
  error: false,
  errorText: '',
  selectedValue: []
};

export default EmailPicker;
