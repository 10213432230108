// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';
import FPIcon from 'UI/components/atoms/FPIcon';
import { DateFormats } from 'UI/constants/defaults';
import { localTimeFormatter } from 'UI/utils';

import { useStyles } from './styles';

type SummaryContentProps = {
  creationDate: string,
  creationBy: string
};

type EntitySummaryProps = {
  summaryContentProps: SummaryContentProps,
  summaryLabels: Array<Object>
};

const EntitySummary = ({
  summaryContentProps: { creationDate, creationBy },
  summaryLabels
}: EntitySummaryProps) => {
  const classes = useStyles();

  const itemCreationDate = localTimeFormatter(creationDate, DateFormats.SimpleDateTime);

  const labels = [
    { title: strings.inventoryProfiles.common.summaryLabelDate, content: itemCreationDate },
    { title: strings.inventoryProfiles.common.summaryLabelCreationBy, content: creationBy },
    ...summaryLabels
  ];

  const labelsWithContent = labels.filter(item => item.content && item.title);

  return (
    <div className={classes.profileLabel}>
      {labelsWithContent.map(item => (
        <Typography
          className={classes.overline}
          color="textSecondary"
          component="span"
          variant="overline"
          key={item?.title}
        >
          <strong>
            {`${item.title}: `}
            {item?.icon && (
              <FPIcon
                component={item?.icon}
                size={14}
                color="inherit"
                className={classes.successIcon}
              />
            )}
          </strong>
          {item.content}
        </Typography>
      ))}
    </div>
  );
};

EntitySummary.defaultProps = {
  summaryLabels: []
};

export default EntitySummary;
