import React, { useCallback, useMemo, useState } from 'react';
import { Chart } from 'react-google-charts';
import { DRAWER_KEYS, Widget } from 'features/command-center';
import { COMMAND_CENTER_BASE_URL } from 'features/command-center/constants';
import { useDrawerManager } from 'features/command-center/contexts';
import { getInventorySummaryByType } from 'features/command-center/services';
import { getWeekRange } from 'features/command-center/utils';
import moment from 'moment';
import strings from 'strings';
import { AutocompleteByWeek } from 'UI/components/atoms/AutocompleteByWeek';
import { DateFormats } from 'UI/constants/defaults';

import {
  CHART_DATA_CONFIG_BY_ENTITY,
  GROUP_BY,
  METRICS,
  PIL_GROWTH_CHART_DEFAULT_OPTIONS,
  ROUTE_PARAM
} from './InventoryAdded.constants';
import {
  calculateVerticalAxisTicks,
  formatInventoryLineChartResponse
} from './InventoryAdded.utils';

const URL_INDEX_ACCORDING_VALUE = 1;

export const InventoryAdded = ({ requestParams = {}, weeks, entity, hasDrillDown }) => {
  const [week, setWeek] = useState(weeks?.[0] ?? null);
  const { openDrawer } = useDrawerManager();
  const { startDate, endDate } = getWeekRange(week);

  const queryDateRange = useMemo(
    () => ({
      startDate: moment(startDate).format(DateFormats.QueryFormat),
      endDate: moment(endDate).format(DateFormats.QueryFormat)
    }),
    [startDate, endDate]
  );

  const handleSelectWeek = (event, selectedWeek) => setWeek(selectedWeek);

  const title = strings.formatString(strings.commandCenter.activity.inventoryAdded, {
    entity: entity.title
  });

  const promises = useCallback(() => {
    return Promise.all([
      getInventorySummaryByType(
        {
          groupBy: GROUP_BY[entity.id],
          metrics: METRICS[entity.id],
          ...queryDateRange,
          ...requestParams
        },
        ROUTE_PARAM[entity.id]
      )
    ]).then(([data]) => data);
  }, [entity, queryDateRange, requestParams]);

  const cachedPromises = useMemo(() => [promises], [promises]);

  const renderChart = useCallback(
    ([response]) => {
      const data = formatInventoryLineChartResponse(
        response,
        CHART_DATA_CONFIG_BY_ENTITY[entity.id]
      );

      const handlePointClick = (clickedData, row, column) => {
        const url = clickedData[column + URL_INDEX_ACCORDING_VALUE];
        const drawerKey = entity?.title && entity.title.replace(' ', '');

        drawerKey &&
          openDrawer({
            activeDrawer: DRAWER_KEYS[drawerKey].Overall ?? DRAWER_KEYS[drawerKey].Total,
            contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
          });
      };

      const chartEvents = [
        {
          eventName: 'select',
          callback: ({ chartWrapper }) => {
            const selection = chartWrapper.getChart().getSelection();
            if (selection.length === 0) return;

            const [selectedItem] = selection;
            const { row, column } = selectedItem;

            if (row !== undefined && column !== undefined) {
              const clickedData = data[row + 1]; // +1 beacause the first row is headers
              handlePointClick(clickedData, row, column);
            }
          }
        }
      ];

      const vAxisThicks = calculateVerticalAxisTicks(data);

      const chartOptions = {
        ...PIL_GROWTH_CHART_DEFAULT_OPTIONS,
        vAxis: {
          ...PIL_GROWTH_CHART_DEFAULT_OPTIONS.vAxis,
          ticks: vAxisThicks
        }
      };

      return (
        <>
          <AutocompleteByWeek defaultOptions={weeks} onSelectWeek={handleSelectWeek} value={week} />
          <Chart
            chartEvents={hasDrillDown && chartEvents}
            chartType="LineChart"
            data={data}
            height="400px"
            options={chartOptions}
            width="100%"
          />
        </>
      );
    },
    [entity.id, entity.title, hasDrillDown, openDrawer, week, weeks]
  );

  return (
    <Widget header={title} promises={cachedPromises}>
      {renderChart}
    </Widget>
  );
};
