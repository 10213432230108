import React, { useMemo } from 'react';
import clsx from 'clsx';
import strings from 'strings';
import FPHint from 'UI/components/atoms/FPHint';
import FPHtmlViewer from 'UI/components/atoms/FPHtmlViewer';
import { dateFormatter, getId } from 'UI/utils';

import { useStyles } from '../styles';

import { Columns, isColumnToHide, SplitCell } from './ExpandableRow';
import Row from './Row';
import { statusAll } from './UserToolReport.constants';

const addGpacAllRowToEndOfTable = sendouts => {
  if (sendouts[0].coach === 'gpac') {
    return [...sendouts, sendouts[0]];
  }
  return [...sendouts];
};

const addLabelsAndFlattenData = data => {
  let sendouts = [];
  data.forEach(item => {
    if (item.team === 'gpac') Object.assign(item, { coach: 'gpac' });
    if (item.team === 'regional') Object.assign(item, { coach: item.regional });
    if (item.team === 'gpac' || item.team === 'regional') {
      sendouts = [...sendouts, item, ...item.coaches];
    } else {
      sendouts = [...sendouts, ...item.coaches];
    }
  });
  return addGpacAllRowToEndOfTable(sendouts);
};
const formatLastUpdateDate = lastUpdateDate => {
  return lastUpdateDate
    ? dateFormatter(lastUpdateDate, ({ MonthDayNumberYear }) => MonthDayNumberYear)
    : '-';
};

const formatLastUpdateTime = lastUpdateDate => {
  return lastUpdateDate ? dateFormatter(lastUpdateDate, ({ SimpleTime }) => SimpleTime) : '-- : --';
};

const getUpdatedParamsNamesByMinutes = (baseColumns, extraColumnsInfo, minutes) => {
  extraColumnsInfo.forEach(extraColumn => {
    if (extraColumn.updateFrecuencyInMinutes === minutes) {
      baseColumns.push(extraColumn.columnLabel);
    }
  });
  return String(baseColumns)
    .replaceAll(',', ', ')
    .replace(/,(?=[^,]+$)/, ' and');
};

const DataTable = ({ items, year, month, week, lastUpdates, extraColumns, status }) => {
  const classes = useStyles();
  const sendouts = useMemo(() => addLabelsAndFlattenData(items), [items]);

  const hasGpacOrRegionalCoach = sendouts.some(
    item => item.coach === 'gpac' || item.coach === 'regional'
  );

  const isYearView = !!year?.date;
  const toolsUsageTeamClassName = clsx({
    [classes.team]: true,
    [classes.toolsUsageHeight]: true
  });
  const finalExtraColumns = extraColumns?.extraColumnsInfo
    ? extraColumns.extraColumnsInfo.map(extraColumn => ({
        label: extraColumn.columnLabel,
        ...(extraColumn.labelDown && { labelDown: extraColumn.labelDown }),
        name: extraColumn.columnLabel
      }))
    : [];
  const tableColumns = isYearView
    ? [
        { label: 'Team', className: toolsUsageTeamClassName },
        { label: 'Jan' },
        { label: 'Feb' },
        { label: 'Mar' },
        { label: 'Apr' },
        { label: 'May' },
        { label: 'Jun' },
        { label: 'Jul' },
        { label: 'Aug' },
        { label: 'Sep' },
        { label: 'Oct' },
        { label: 'Nov' },
        { label: 'Dec' },
        { label: 'Total' }
      ]
    : [
        {
          label: 'Team',
          className: toolsUsageTeamClassName,
          labelDown: status?.title === statusAll ? 'Status' : null,
          name: Columns.Team
        },
        { label: 'Calls', name: Columns.Calls },
        { label: 'InMails', name: Columns.InMails },
        { label: 'Indeed', name: Columns.Indeed },
        { label: 'Live Posts', name: Columns.LivePost },
        { label: 'Gpac Careers', name: Columns.GpacCareer },
        { label: 'Bulks Mo', labelDown: 'Reached', name: Columns.BulksMo },
        { label: 'Bulks Tu', labelDown: 'Reached', name: Columns.BulksTu },
        { label: 'Bulks We', labelDown: 'Reached', name: Columns.BulksWe },
        { label: 'Bulks Th', labelDown: 'Reached', name: Columns.BulksTh },
        { label: 'Bulks Fr', labelDown: 'Reached', name: Columns.BulksFr },
        { label: 'Bulks Sa', labelDown: 'Reached', name: Columns.BulksSa },
        { label: 'Bulks Su', labelDown: 'Reached', name: Columns.BulksSu },
        { label: 'Bulks Total', labelDown: 'Reached', name: Columns.BulksTotal },
        ...finalExtraColumns
      ];

  return (
    <>
      <FPHint
        description={
          <div>
            Metrics update time is varied:
            <ul>
              <li>
                <FPHtmlViewer
                  content={strings.formatString(
                    strings.dashboard.toolsUsage.updateInfo.thirtyMinutesUpdate,
                    {
                      updatedFields: getUpdatedParamsNamesByMinutes(
                        ['Bulks', 'Calls'],
                        extraColumns.extraColumnsInfo,
                        30
                      ),
                      date: formatLastUpdateDate(lastUpdates.bulks),
                      time: formatLastUpdateTime(lastUpdates.bulks)
                    }
                  )}
                />
              </li>
              <li>
                <FPHtmlViewer
                  content={strings.formatString(strings.dashboard.toolsUsage.updateInfo.inMails, {
                    date: formatLastUpdateDate(lastUpdates.inmails),
                    time: formatLastUpdateTime(lastUpdates.inmails)
                  })}
                />
              </li>
              <li>
                <FPHtmlViewer
                  content={strings.formatString(strings.dashboard.toolsUsage.updateInfo.livePosts, {
                    date: formatLastUpdateDate(lastUpdates.live_posts),
                    time: formatLastUpdateTime(lastUpdates.live_posts)
                  })}
                />
              </li>
            </ul>
          </div>
        }
        size="md"
        containerClassName={classes.boxCopyWrapper}
      />

      <table className={classes.board}>
        <thead className={classes.boardHead}>
          <tr className={classes.title}>
            <th aria-label="Empty action button" />
            {tableColumns.map(
              column =>
                !isColumnToHide(column.name) && (
                  <th key={getId()} className={column.className || classes.toolsUsageHeight}>
                    <SplitCell column={column} header />
                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody>
          {sendouts.map(item => (
            <Row
              key={getId()}
              item={item}
              year={year}
              month={month}
              week={week}
              extraColumns={extraColumns}
              personStatus={status}
              hasGpacOrRegionalCoach={hasGpacOrRegionalCoach}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};
export default DataTable;
