import strings from 'strings';
import { formatRatio } from 'UI/utils';

import { calculatePercentage } from '../activity.utils';

export const formatResponse = data => {
  if (!data || !data?.phoneTimeGoalsReaching) return [];

  const { count, goal, recruitersOnGoal, headcount } = data.phoneTimeGoalsReaching;

  const countInHours = count / 3600;
  const goalInHours = goal / 60;
  const percentage = calculatePercentage({ total: recruitersOnGoal, goal: headcount });

  return [
    {
      title: strings.commandCenter.activity.phoneTimeGauge.chart.goal,
      value: formatRatio(countInHours ?? 0, goalInHours ?? 0, 'scalar', {
        notation: 'standard'
      }),
      percentage,
      goal: goalInHours,
      valuePosition: 'top'
    },
    {
      title: strings.commandCenter.activity.phoneTimeGauge.chart.members,
      value: formatRatio(recruitersOnGoal ?? 0, headcount),
      valuePosition: 'top'
    }
  ];
};
