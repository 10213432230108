import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  summaryContainer: {
    justifyContent: ({ manyItems }) => (manyItems ? 'space-between' : 'flex-start')
  },
  summaryItem: {
    display: 'flex',
    justifyContent: ({ manyItems }) => (manyItems ? 'center' : 'flex-start'),
    alignItems: 'center',
    marginLeft: ({ manyItems }) => !manyItems || theme.spacing(6),
    '& > *': {
      marginRight: theme.spacing(2.5)
    }
  },
  itemMultiline: {
    '&:nth-child(3n+3) > *': {
      borderRight: 'none !important'
    },
    marginBottom: theme.spacing(5),
    '&:nth-last-child(-n+3)': {
      marginBottom: 0
    }
  }
}));
