import * as React from "react";

const SvgCheck = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm6.93 8.2l-6.85 9.29a1 1 0 01-1.43.19l-4.89-3.91a1 1 0 01-.15-1.41A1 1 0 017 12.21l4.08 3.26L17.32 7a1 1 0 011.39-.21 1 1 0 01.22 1.41z" />
    </svg>
  );
};

export default SvgCheck;
