// @flow
import React, { useMemo, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import strings from 'strings';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import { Endpoints } from 'UI/constants/endpoints';
import { capitalizeFirstLetter } from 'UI/utils';

import {
  IndustryType,
  NodeSelection,
  PositionType,
  SpecialtyType,
  SubspecialtyType
} from './pilIndustries.types';
import {
  getExpandedIds,
  getOptionLabel,
  getPilOptions,
  getRenderOption,
  getSelectionIndustryId,
  getSelectionSpecialtyId,
  getTreeViewFormat
} from './pilIndustries.utils';
import PilTreeView from './PilTreeView';
import { useTreeItemStyles } from './styles';

const COPIES = strings.shared.searchGlobal;

type PilIndustriesProps = {
  onNodeSelected: (node: NodeSelection | null) => void,
  results: Array<IndustryType>,
  searchInPositions?: boolean,
  showSearchFilter?: boolean
};
const Industries = ({
  onNodeSelected,
  showSearchFilter,
  results = [],
  searchInPositions
}: PilIndustriesProps) => {
  const classes = useTreeItemStyles();
  const treeIndustries = useMemo(
    () => ({
      root: getTreeViewFormat(results)
    }),
    [results]
  );

  const [initialTree, setInitialTree] = useState(treeIndustries);
  const [initialParams, setInitialParams] = useState({
    currentNodeId: null,
    expandedIds: []
  });
  const [selectedPil, setSelectedPil] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectIndustry = async (
    name: string,
    value: IndustryType | SpecialtyType | SubspecialtyType | PositionType
  ) => {
    setSelectedPil(value);

    if (!value) {
      setInitialTree(treeIndustries);
      setInitialParams({
        currentNodeId: null,
        expandedIds: []
      });
      onNodeSelected(null);
      return;
    }

    setIsLoading(true);
    const isPositionSelected = value.type === 'position';
    const industryId = getSelectionIndustryId(value, results);
    const specialtyId = getSelectionSpecialtyId(value);

    const filteredIndustries = results.filter(
      (industry: IndustryType) => industry.id === industryId
    );

    const [specialties, subspecialties] = await Promise.all([
      getPilOptions({ id: industryId, type: 'industry' }),
      getPilOptions({ id: specialtyId, type: 'specialty' })
    ]);

    const updatedTree = {
      root: getTreeViewFormat(filteredIndustries),
      [`industry-${industryId}`]: specialties
    };

    if (specialtyId) {
      updatedTree[`specialty-${specialtyId}`] = subspecialties;
    }

    setInitialTree(updatedTree);

    setInitialParams({
      currentNodeId: `${isPositionSelected ? 'specialty' : value.type}-${
        isPositionSelected ? specialtyId : value.id
      }`,
      expandedIds: getExpandedIds(value, industryId)
    });

    onNodeSelected({
      id: isPositionSelected ? specialtyId : value.id,
      title: isPositionSelected ? value.specialty_title : value.title,
      type: isPositionSelected ? 'specialty' : value.type,
      specialtyId
    });
    setIsLoading(false);
  };

  return (
    <>
      <When condition={showSearchFilter}>
        <div className={classes.content}>
          <AutocompleteSelect
            name="searchPil"
            getOptionLabel={option => getOptionLabel(option.type, option)}
            getOptionSelected={(option, value) => option?.id === value?.id}
            groupBy={option => capitalizeFirstLetter(option.type)}
            onSelect={handleSelectIndustry}
            placeholder={searchInPositions ? COPIES.pilAndPosition : COPIES.pil}
            renderOption={option => getRenderOption(option.type, option)}
            selectedValue={selectedPil}
            typeahead
            typeaheadLimit={20}
            typeaheadParams={{ searchInPositions }}
            url={`${Endpoints.Industries}/search`}
          />
        </div>
      </When>

      {isLoading ? (
        <div className={classes.statusContainer}>
          <CircularProgress size={28} />
        </div>
      ) : (
        <PilTreeView
          key={selectedPil?.id}
          initialExpandedIds={initialParams.expandedIds}
          initialNodeId={initialParams.currentNodeId}
          initialTree={initialTree}
          onNodeSelected={onNodeSelected}
        />
      )}
    </>
  );
};

export default Industries;
