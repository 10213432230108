import isNil from 'lodash/isNil';

const getQuestionsCounts = (formData, questionsPaths) => {
  const answeredQuestions = Object.entries(questionsPaths).reduce(
    (count, [property, nestedProperties]) => {
      const value = property.split('.').reduce((acc, part) => acc?.[part], formData);

      if (Array.isArray(value) && value.length === 0) {
        return count;
      }

      if (Array.isArray(value) && value.length > 0) {
        if (value.every(item => typeof item === 'string' || typeof item === 'number')) {
          return count + 1;
        }

        if (
          value.every(
            item => typeof item === 'object' && !isNil(item) && Object.keys(item).length > 0
          )
        ) {
          return count + 1;
        }
        return count;
      }

      if (
        (value || value === 0) &&
        nestedProperties.every(
          key =>
            Object.prototype.hasOwnProperty.call(value, key) &&
            value[key] !== undefined &&
            value[key] !== ''
        )
      ) {
        return count + 1;
      }

      return count;
    },
    0
  );

  return {
    total: Object.keys(questionsPaths).length,
    answered: answeredQuestions
  };
};

export default getQuestionsCounts;
