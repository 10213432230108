import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0'
  },
  label: {
    margin: '0 5px'
  },
  sectionLabel: {
    marginTop: 20
  }
}));
