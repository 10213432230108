import { makeStyles } from '@material-ui/core';
import { flexAlignCenterJustifyCenter, flexJustifyEnd } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  circularProgressContainer: {
    ...flexAlignCenterJustifyCenter,
    minHeight: 100
  },
  downloadAll: {
    '&:hover > span > svg': {
      color: theme.palette.primary.main
    }
  },
  numberHeader: {
    '& > span': {
      ...flexJustifyEnd,
      '& > button': {
        margin: 0,
        padding: 0
      }
    }
  },
  emptyMessage: {
    padding: theme.spacing(2),
    textAlign: 'center'
  }
}));
