// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextBox from 'UI/components/atoms/TextBox';
import NumberedForm from 'UI/components/molecules/NumberedForm';

import { useStyles } from '../styles';

export type PlacementCompanySectionProps = {
  sendout: any,
  copyPaste: boolean
};

const PlacementCompanySection = (props: PlacementCompanySectionProps) => {
  const classes = useStyles();
  const { copyPaste, sendout } = props;
  const { joborder, hiringAuthorithies } = sendout || {};
  const { company } = joborder || {};
  const hiringAuthority = hiringAuthorithies && hiringAuthorithies[0]?.hiringAuthority;
  const address = company?.address ? `${company.address}, ${company.city?.title || ''}` : '';
  return (
    <>
      <NumberedForm.Item title="Company Details" showTitleGutter>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextBox
              name="company"
              label="Company"
              value={company?.name || ''}
              className={classes.input}
              outPutValue
              enableCopyButton={copyPaste}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              name="address"
              label="Address"
              value={address}
              className={classes.input}
              outPutValue
              enableCopyButton={copyPaste}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              name="hiringAuthority"
              label="Hiring Authority"
              value={hiringAuthority?.full_name || ''}
              className={classes.input}
              outPutValue
              enableCopyButton={copyPaste}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              name="email"
              label="Contact email"
              value={hiringAuthority?.work_email || ''}
              className={classes.input}
              outPutValue
              enableCopyButton={copyPaste}
            />
          </Grid>
        </Grid>
      </NumberedForm.Item>
      <NumberedForm.Item title="Job Order Details" showTitleGutter>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextBox
              name="joborder"
              label="Job Order"
              value={joborder?.title || ''}
              className={classes.input}
              outPutValue
              enableCopyButton={copyPaste}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              name="industry"
              label="Industry"
              value={
                company?.specialty
                  ? `${company.specialty.title}/ ${company.specialty.industry?.title}`
                  : ''
              }
              className={classes.input}
              outPutValue
              enableCopyButton={copyPaste}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              name="source"
              label="Job Order Source"
              value={joborder?.sourceType?.title || ''}
              className={classes.input}
              outPutValue
              enableCopyButton={copyPaste}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              name="source"
              label="Source Link"
              value={joborder?.source || ''}
              className={classes.input}
              outPutValue
              enableCopyButton={copyPaste}
            />
          </Grid>
        </Grid>
      </NumberedForm.Item>
    </>
  );
};

PlacementCompanySection.defaultProps = {
  sendout: null
};

export default PlacementCompanySection;
