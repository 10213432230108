import * as React from 'react';

const SvgCandidates = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <g>
        <path d="M12 8.5A5.51 5.51 0 006.5 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.25.23l.44 5.31a.5.5 0 00.5.46h4a.5.5 0 00.5-.46l.44-5.31a.25.25 0 01.25-.23H17a.5.5 0 00.5-.5V14A5.51 5.51 0 0012 8.5z" />
        <circle cx={12} cy={3.5} r={3.5} />
      </g>
    </svg>
  );
};

export default SvgCandidates;
