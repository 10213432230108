// @flow
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { showAlert } from 'actions/app';
import clsx from 'clsx';
import { useGlobalStyles } from 'GlobalStyles';
import { formatLinkedCompanies, useAssociatedCompanies } from 'hooks/associatedCompanies';
import { buildRowActionsRendererDefinition, getColumnsToRender } from 'hooks/datatable';
import { useQuickViewPreview } from 'hooks/useQuickViewPreview';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import { userHasRole, userHasRoles } from 'services/Authorization';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { FPCard, FPCardContent } from 'UI/components/atoms/FPCard';
import FPIcon from 'UI/components/atoms/FPIcon';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import FooterActionsControlsLegacy from 'UI/components/molecules/FooterActionsControlsLegacy';
import AssociatedCompanies from 'UI/components/organisms/AssociatedCompanies';
import CompanyForm from 'UI/components/organisms/CompanyForm';
import { FormFieldsMap } from 'UI/components/organisms/CompanyForm/fields';
import DataTable from 'UI/components/organisms/DataTable';
import HiringAuthorityDrawer from 'UI/components/organisms/HiringAuthorityDrawer';
import HiringAuthorityPreview from 'UI/components/organisms/HiringAuthorityPreview';
import FPQuickView from 'UI/components/organisms/inventoryProfiles/FPQuickView';
import {
  formatPhoneNumbers,
  PHONE_FORMAT_MODES
} from 'UI/components/organisms/PhoneNumbersForm/utils';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import { FORTPAC_SUPPORT_LINK, PageTitles, waitingTimeBeforeRedirect } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';
import { SeverityBySimilarity } from 'UI/constants/status';
import { useDataTableStyles } from 'UI/globalStyles/DataTableStyles';
import { ColumnsDefinitions } from 'UI/pages/NewCompany/columns';
import { SvgAdd, SvgWarning } from 'UI/res';
import { getErrorMessage, getFeatureFlags, getId } from 'UI/utils';
import { encryptId } from 'UI/utils/encrypt';
import { prepareFormToSubmit } from 'UI/utils/forms';

import { useStyles } from './styles';
import { hasValidHiringAuthoritiesSelected } from './utils';

type SimilarCompanies = {
  data: [],
  lastPage: number,
  page: number,
  perPage: number,
  status: string,
  total: number
};

const API_VERSION = 1;

const NewCompany = ({ history, location }: NewCompanyProps) => {
  const dispatch = useDispatch();
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const dataTableClasses = useDataTableStyles();
  const isOpsOrDc = userHasRoles([Roles.Operations, Roles.DataCoordinator]);
  const isUserCoach = userHasRole(Roles.Coach);

  const hasLinkCompaniesEnabled = getFeatureFlags().includes(FeatureFlags.LinkCompanies);

  const [hiringAuthorities, setHiringAuthorities] = useState([]);
  const [similarCompanies, setSimilarCompanies] = useState<SimilarCompanies>({
    data: [],
    lastPage: 0,
    page: 0,
    perPage: 10,
    status: SeverityBySimilarity.None,
    total: 0
  });
  const [uiState, setUiState] = useState({
    isHAEditingOpen: false,
    isHAOpen: false,
    isLoading: false,
    isSaving: false,
    isSuccess: false,
    selectedHA: null
  });

  const {
    associatedCompanies,
    setAssociatedCompanies,
    associatedCompaniesActions,
    mixedRelationshipsMessage
  } = useAssociatedCompanies();

  const { name: companyName } = queryString.parse(location.search);
  const defaultValues = {
    name: companyName || '',
    [FormFieldsMap.Specialties.key]: [],
    [FormFieldsMap.Subspecialties.key]: []
  };

  const hasDuplicateHiringAuthorities =
    hiringAuthorities.length > 0 && !hasValidHiringAuthoritiesSelected(hiringAuthorities);

  const form = useForm({ defaultValues });
  const { register, getValues, setValue, handleSubmit, watch } = form;

  const { name, state_id: state, city_id: city, zip } = watch();

  const selectedRecruiter = watch(FormFieldsMap.Recruiter.key);

  const currentCompany = {
    name: watch(FormFieldsMap.Name.key)
  };

  const { entityId, quickViewState, quickViewEntity, listViewPreset, toggleQuickViewPreview } =
    useQuickViewPreview({
      columns: ColumnsDefinitions,
      data: similarCompanies.data,
      entityType: EntityType.Company
    });

  const saveActive = uiState.isLoading || uiState.isSaving || uiState.isSuccess;
  const isSaveDisabled = isOpsOrDc
    ? saveActive
    : similarCompanies.status === SeverityBySimilarity.Exact || saveActive;

  useEffect(() => {
    document.title = PageTitles.CompanyCreate;
  }, []);

  useEffect(() => {
    if (isUserCoach) {
      register({ name: FormFieldsMap.Recruiter.key });
    }
  }, [isUserCoach, register]);

  useEffect(() => {
    setUiState(prev => ({ ...prev, isLoading: true }));
    setSimilarCompanies({
      data: [],
      lastPage: 0,
      page: 0,
      perPage: 10,
      status: SeverityBySimilarity.None,
      total: 0
    });

    if (!name || !state || !city || !zip)
      return setUiState(prev => ({ ...prev, isLoading: false }));

    const searchForDuplicates = setTimeout(async () => {
      const params = {
        countryIds: state.country_id,
        stateIds: state.id,
        cityIds: city.id,
        zips: zip.id,

        nameSimilar: {
          operator: 'similar',
          value: name,
          similarityThreshold: 1
        }
      };

      const response = await API.get(Endpoints.Companies, { apiVersion: API_VERSION, params });

      if (response.status === 200 && !isEmpty(response.data.data)) {
        const adjustCompanies = response.data.data.map(item => ({
          ...item,
          name: { id: item.id, name: item.name }
        }));
        setSimilarCompanies({
          ...response.data,
          data: adjustCompanies,
          status: SeverityBySimilarity.Exact
        });
        setUiState(prev => ({ ...prev, isLoading: false }));
        return;
      }

      const narrowParams = {
        stateIds: state.id,
        cityIds: city.id,

        nameSimilar: {
          operator: 'similar',
          value: name,
          useFullTextSearch: true
        }
      };

      const narrowResponse = await API.get(Endpoints.Companies, {
        apiVersion: API_VERSION,
        params: narrowParams
      });

      if (narrowResponse.status === 200 && !isEmpty(narrowResponse.data.data)) {
        const adjustCompanies = narrowResponse.data.data.map(item => ({
          ...item,
          name: { id: item.id, name: item.name }
        }));
        setSimilarCompanies({
          ...narrowResponse.data,
          data: adjustCompanies,
          status: SeverityBySimilarity.Similar
        });
      }
      setUiState(prev => ({ ...prev, isLoading: false }));
    }, 1000);

    return () => {
      clearTimeout(searchForDuplicates);
    };
  }, [city, name, state, zip]);

  const toggleDrawer = (drawer: string, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setUiState(prevState => ({ ...prevState, [drawer]: open }));
  };

  const handleNewHAClick = () => {
    const companyValues = getValues();

    setUiState(prevState => ({
      ...prevState,
      selectedHA: {
        specialty: companyValues?.specialty,
        specialty_id: companyValues?.specialty?.id,
        subspecialty: companyValues?.subspecialty,
        subspecialty_id: companyValues?.subspecialty?.id
      },
      isHAOpen: true,
      isHAEditingOpen: false
    }));
  };

  const handleHiringAuthorityOpenEdit = item =>
    setUiState(prevState => ({
      ...prevState,
      selectedHA: { ...item, id: item.itemId },
      isHAOpen: true,
      isHAEditingOpen: true
    }));

  const handleHiringAuthorityEdited = updatedItem => {
    const updatedHiringAuthorities = hiringAuthorities.map(currentItem =>
      currentItem.itemId === uiState.selectedHA.itemId ? updatedItem : currentItem
    );
    setHiringAuthorities(updatedHiringAuthorities);
  };

  const handleHiringAuthorityDeleted = haToDelete => {
    const newValues = [...hiringAuthorities];
    newValues.splice(haToDelete, 1);
    setHiringAuthorities(newValues);
  };

  const handleHiringAuthorityCompleted = newHiringAuthority => {
    setUiState(prevState => ({
      ...prevState,
      selectedHA: null,
      isHAOpen: false,
      isHAEditingOpen: false
    }));

    uiState.isHAEditingOpen
      ? handleHiringAuthorityEdited({
          ...newHiringAuthority,
          full_name: `${newHiringAuthority.first_name} ${newHiringAuthority.last_name}`
        })
      : setHiringAuthorities([
          ...hiringAuthorities,
          {
            ...newHiringAuthority,
            full_name: `${newHiringAuthority.first_name} ${newHiringAuthority.last_name}`,
            localId: getId()
          }
        ]);
  };

  const handleHiringAuthorityClosed = () =>
    setUiState(prevState => ({
      ...prevState,
      selectedHA: null,
      isHAOpen: false,
      isHAEditingOpen: false
    }));

  const handleCancelClick = () => history.push(EntityRoutes.Companies);

  const handleRecruiterSelect = (recruiterName?: string, value: any) =>
    setValue(recruiterName, value, true);

  const onSubmit = async formData => {
    if (hasDuplicateHiringAuthorities) return;
    try {
      setUiState(prevState => ({ ...prevState, isSaving: true }));
      const transformedData = prepareFormToSubmit(formData, FormFieldsMap);
      const transformedAssociatedCompanies = formatLinkedCompanies(associatedCompanies);
      const transformedPhones = formatPhoneNumbers(formData, PHONE_FORMAT_MODES.submit);
      const companyData = {
        ...transformedData,
        hiringAuthorities,
        ...transformedAssociatedCompanies,
        phones: transformedPhones
      };
      const response = await API.post(Endpoints.Companies, companyData);
      if (response.data && response.status === 201) {
        setUiState(prevState => ({
          ...prevState,
          isSuccess: true,
          isSaving: false
        }));
        dispatch(
          showAlert({
            severity: 'success',
            title: strings.companies.plural,
            body: strings.companies.creation.success
          })
        );
        setTimeout(() => {
          const companyId = response.data.data.id;
          const queryParams = queryString.stringify({
            company: encryptId(companyId),
            cName: formData.name
          });
          if (history?.location?.state?.prev) {
            history.push(`${history.location.state.prev}?${queryParams}`);
          } else {
            history.push(EntityRoutes.CompanyProfile.replace(':id', companyId));
          }
        }, waitingTimeBeforeRedirect);
      }
    } catch (err) {
      setUiState(prevState => ({
        ...prevState,
        isSuccess: false,
        isSaving: false
      }));
      dispatch(
        showAlert({
          severity: 'error',
          title: strings.companies.plural,
          autoHideDuration: 5000,
          body: getErrorMessage(err)
        })
      );
    }
  };

  const handleAssociatedCompaniesSubmit = newAssociatedCompanies => {
    setAssociatedCompanies(prev => ({ ...prev, ...newAssociatedCompanies }));
  };

  const actionButtons = buildRowActionsRendererDefinition({
    actions: [listViewPreset],
    namespace: EntityType.Company
  });

  const finalColumns = getColumnsToRender([...ColumnsDefinitions, actionButtons]);

  const similarCardHeaderTitles =
    similarCompanies.status === SeverityBySimilarity.Exact
      ? {
          title: strings.companies.creation.exactMatch.title,
          subheader: strings.companies.creation.exactMatch.subtitle
        }
      : {
          title: strings.companies.creation.similarCompanies.title,
          subheader: strings.companies.creation.similarCompanies.subtitle
        };

  const isSimilarCompany = similarCompanies.status === SeverityBySimilarity.Exact;

  return (
    <ContentPageLayout
      text={strings.companies.creation.title}
      titleLabelProps={{ backNavigation: true }}
    >
      <FormContext {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={globalClasses.itemCreationDataWrapper}>
            <div className={globalClasses.newItemsSection}>
              <FPCard>
                <FPCardContent
                  variant="relaxed"
                  headerProps={{
                    title: strings.companies.creation.mainSection,
                    subheader: strings.shared.fields.required,
                    variant: 'section'
                  }}
                >
                  {similarCompanies.status !== SeverityBySimilarity.None && (
                    <Alert
                      data-testid="duplicate-alert"
                      severity={similarCompanies.status}
                      action={
                        isSimilarCompany && (
                          <>
                            <Button
                              color="inherit"
                              href={FORTPAC_SUPPORT_LINK}
                              rel="noopener noreferrer"
                              target="_blank"
                              variant="outlined"
                              classes={{ root: globalClasses.alertActionButton }}
                            >
                              {strings.shared.app.support}
                            </Button>
                          </>
                        )
                      }
                      icon={
                        <FPIcon
                          component={SvgWarning}
                          onSetColor={palette => palette[similarCompanies.status].light}
                        />
                      }
                    >
                      {isSimilarCompany ? (
                        <>
                          {strings.formatString(strings.companies.creation.exactMatch.alert, {
                            name: `"${name}"`
                          })}
                        </>
                      ) : (
                        strings.companies.creation.similarCompanies.alert
                      )}
                    </Alert>
                  )}
                  <CompanyForm />
                </FPCardContent>
              </FPCard>
            </div>

            {similarCompanies.status !== SeverityBySimilarity.None && (
              <div className={globalClasses.newItemsSection}>
                <FPCard>
                  <FPCardContent
                    variant="tight"
                    headerProps={{
                      ...similarCardHeaderTitles,
                      variant: 'section'
                    }}
                  >
                    <DataTable
                      className={clsx(
                        globalClasses.tableToolbarCard,
                        dataTableClasses.withoutToolbar
                      )}
                      columns={finalColumns}
                      data={similarCompanies.data}
                      elevation={0}
                      enableNestedDataAccess="."
                      filter={false}
                      isServerSide={false}
                      pagination={false}
                      search={false}
                      selectableRows="none"
                      selectableRowsHeader={false}
                      sort
                    />
                  </FPCardContent>
                </FPCard>
              </div>
            )}

            <div className={globalClasses.newItemsSection}>
              <FPCard>
                <FPCardContent
                  variant="relaxed"
                  headerProps={{
                    title: strings.companies.creation.hiringAuthorities.title,
                    subheader: strings.companies.creation.hiringAuthorities.helper,
                    variant: 'section'
                  }}
                >
                  {hiringAuthorities.length > 0 && (
                    <HiringAuthorityPreview
                      data={hiringAuthorities}
                      onHiringAuthorityEdit={handleHiringAuthorityOpenEdit}
                      onHiringAuthorityDelete={handleHiringAuthorityDeleted}
                    />
                  )}
                  <When condition={hasDuplicateHiringAuthorities}>
                    <Alert severity="warning" className={classes.associatonWarning}>
                      <Typography>
                        {strings.companies.creation.duplicateHiringAuthorities.warning}
                      </Typography>
                    </Alert>
                  </When>
                  <FPActionButton
                    icon={SvgAdd}
                    iconProps={{ family: 'mini', size: 'sm' }}
                    onClick={handleNewHAClick}
                    text={strings.companies.creation.hiringAuthorities.add}
                  />
                </FPCardContent>
              </FPCard>
            </div>
            {hasLinkCompaniesEnabled && (
              <div className={globalClasses.newItemsSection}>
                <FPCard>
                  <FPCardContent
                    variant="relaxed"
                    headerProps={{
                      title: strings.companies.creation.associated.title,
                      subheader: strings.companies.creation.associated.subheader,
                      variant: 'section'
                    }}
                  >
                    {mixedRelationshipsMessage && (
                      <Alert severity="warning" className={classes.associatonWarning}>
                        {mixedRelationshipsMessage}&nbsp;
                        {strings.companies.creation.associated.warnings.mixedCompanies.edit}
                      </Alert>
                    )}
                    <AssociatedCompanies
                      associatedCompaniesActions={associatedCompaniesActions}
                      direction="column"
                      associatedCompanies={associatedCompanies}
                      onSubmit={handleAssociatedCompaniesSubmit}
                      company={currentCompany}
                    />
                  </FPCardContent>
                </FPCard>
              </div>
            )}

            {isUserCoach && (
              <div className={globalClasses.newItemsSection} data-testid="assign_recruiter">
                <FPCard>
                  <FPCardContent
                    variant="relaxed"
                    headerProps={{
                      title: strings.inventory.assignment.title,
                      subheader: strings.inventory.assignment.helper,
                      variant: 'section'
                    }}
                  >
                    <div className="inputContainer">
                      <AutocompleteSelect
                        name={FormFieldsMap.Recruiter.key}
                        selectedValue={selectedRecruiter}
                        placeholder={strings.inventory.assignment.placeholder}
                        displayKey="full_name"
                        url={`${Endpoints.Recruiters}/myTeam`}
                        onSelect={handleRecruiterSelect}
                      />
                    </div>
                  </FPCardContent>
                </FPCard>
              </div>
            )}
            <div className={globalClasses.newItemsActions}>
              <FooterActionsControlsLegacy
                secondaryAction={handleCancelClick}
                primaryProps={{
                  isSaving: uiState.isSaving,
                  isSuccess: uiState.isSuccess,
                  disabled: isSaveDisabled
                }}
              />
            </div>

            {uiState.isLoading && (
              <Grid container justify="flex-end">
                <CircularProgress size={30} />
              </Grid>
            )}
          </div>
        </form>
      </FormContext>

      {uiState.isHAOpen && (
        <Drawer open onClose={toggleDrawer('isHAOpen', false)}>
          <div role="presentation">
            <HiringAuthorityDrawer
              type={EntityType.Company}
              isEditing={uiState.isHAEditingOpen}
              hiringAuthority={uiState.selectedHA}
              onHACompleted={handleHiringAuthorityCompleted}
              onHAClosed={handleHiringAuthorityClosed}
            />
          </div>
        </Drawer>
      )}
      <When condition={entityId && quickViewState.isQuickViewOpen}>
        <FPQuickView
          drawerProps={{
            open: quickViewState.isQuickViewOpen
          }}
          entityType={quickViewEntity}
          onClose={toggleQuickViewPreview}
          id={entityId}
        />
      </When>
    </ContentPageLayout>
  );
};

export default NewCompany;
