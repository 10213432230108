import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';
import { colors } from 'UI/res';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const styles = {
  loading: {
    color: colors.darkGrey
  },
  finished: {
    color: colors.black
  },
  success: {
    color: colors.active
  },
  error: {
    color: colors.error
  },
  viewButton: {
    marginRight: THEME.spacing(2)
  }
};

export const useProgressStyles = makeStyles({
  root: {
    color: colors.darkGrey
  }
});

export const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiListItemText-secondary': {
      fontSize: '0.85rem'
    }
  },
  fileIcon: {
    minWidth: 'unset',
    marginRight: theme.spacing(2),
    ...flexAlignCenterJustifyCenter
  },
  ...styles
}));

export const useFooterActions = makeStyles({
  label: {
    height: 'max-content',
    alignSelf: 'stretch'
  }
});
