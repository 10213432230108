// @flow

import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';
import { FeeAgreementSigningProviders, FeeAgreementStatus } from 'UI/constants/status';
import { getFeatureFlags } from 'UI/utils';

const isUserAssistant = (user, assistants) =>
  Array.isArray(assistants) && assistants.includes(user.id);

const guards = {
  ownership: ({ agreement, user }) => agreement.creator.id === user.id,
  coachOwnership: ({ agreement, user }) => {
    const { activeOwners, coach } = agreement;
    const isOriginalCoach = coach?.id === user.id;
    const isActiveCoach = activeOwners?.coach_id === user.id;

    return (
      isOriginalCoach || isActiveCoach || isUserAssistant(user, activeOwners?.assistant_coches_ids)
    );
  },
  teamOwnership: ({ agreement, user }) => {
    const { activeOwners, creator, coach, regional } = agreement;

    const isFromOriginalTeam =
      creator.id === user.id || coach?.id === user.id || regional?.id === user.id;
    const isActiveCoach =
      activeOwners?.coach_id === user.id || activeOwners?.regional_id === user.id;

    return (
      isFromOriginalTeam ||
      isActiveCoach ||
      isUserAssistant(user, activeOwners?.assistant_coches_ids)
    );
  },

  isDeclining: ({ uiState }) => uiState.isDeclining,

  isUpdatingEmail: ({ uiState }) => uiState.isUpdatingEmail,
  hasSigningUrl: ({ agreement }) => !!agreement.sign_url,
  hasFileUrl: ({ agreement }) => !!agreement.pdf_url,
  canSwitchProvider: ({ agreement }) =>
    !agreement.verbiage_changes_requested &&
    agreement.electronic_signature_provider_id !== FeeAgreementSigningProviders.Backup &&
    getFeatureFlags().includes(FeatureFlags.SigningBackupService)
};

const commonActions = {
  forCoachAndRegionalWhenPendingHA: {
    primary: {
      id: 'resend',
      guard: guards.teamOwnership,
      alternate: { id: 'updatingEmail', guard: guards.isUpdatingEmail }
    },
    actions: [
      { id: 'view', guard: guards.hasFileUrl },
      'viewTemplate',
      { id: 'updateEmail', guard: [guards.teamOwnership] }
    ]
  },
  forCoachAndRegionalWhenPendingGpac: {
    primary: { id: 'resend', guard: guards.teamOwnership, fallback: 'view' },
    actions: [{ id: 'view', guard: guards.teamOwnership }]
  },
  forCoachAndRegionalWhenPendingCoach: {
    primary: { id: 'approve', alternate: { id: 'declining', guard: guards.isDeclining } },
    actions: ['decline', 'cancel', 'viewTemplate'],
    guard: guards.coachOwnership
  },
  forProductionDirectorWhenPendingCoach: {
    primary: { id: 'approve', alternate: { id: 'declining', guard: guards.isDeclining } },
    actions: ['decline', 'cancel', 'viewTemplate']
  },
  forCoachAndRegionalWhenPendingOps: {
    actions: [{ id: 'cancel', guard: guards.teamOwnership }, 'viewTemplate']
  },
  forCoachAndRegionalWhenDeclinedByCoach: {
    actions: [{ id: 'cancel', guard: guards.teamOwnership }, 'viewTemplate']
  },
  forCoachAndRegionalWhenDeclinedByOps: {
    primary: 'revalidate',
    guard: guards.ownership,
    actions: [{ id: 'cancel', guard: guards.coachOwnership }, 'viewTemplate']
  }
};

export const rules = {
  [FeeAgreementStatus.PendingHASignature]: {
    [Roles.Recruiter]: {
      primary: {
        id: 'resend',
        guard: guards.ownership,
        alternate: { id: 'updatingEmail', guard: guards.isUpdatingEmail }
      },
      actions: [
        { id: 'view', guard: guards.hasFileUrl },
        'viewTemplate',
        { id: 'updateEmail', guard: [guards.ownership] }
      ]
    },
    [Roles.Coach]: commonActions.forCoachAndRegionalWhenPendingHA,
    [Roles.AssistantRegionalDirector]: commonActions.forCoachAndRegionalWhenPendingHA,
    [Roles.RegionalDirector]: commonActions.forCoachAndRegionalWhenPendingHA,
    [Roles.Operations]: {
      primary: {
        id: 'resend',
        alternate: { id: 'updatingEmail', guard: guards.isUpdatingEmail }
      },
      actions: [
        { id: 'view', guard: guards.hasFileUrl },
        'viewTemplate',
        'updateEmail',
        { id: 'switchProvider', guard: [guards.canSwitchProvider] },
        'void'
      ]
    },
    [Roles.Leadership]: {
      primary: {
        id: 'resend',
        alternate: { id: 'updatingEmail', guard: guards.isUpdatingEmail }
      },
      actions: [{ id: 'view', guard: guards.hasFileUrl }, 'viewTemplate', 'updateEmail']
    }
  },
  [FeeAgreementStatus.PendingGpacSignature]: {
    [Roles.Recruiter]: {
      primary: { id: 'resend', guard: guards.ownership, fallback: 'view' },
      actions: [{ id: 'view', guard: guards.ownership }]
    },
    [Roles.Coach]: commonActions.forCoachAndRegionalWhenPendingGpac,
    [Roles.AssistantRegionalDirector]: commonActions.forCoachAndRegionalWhenPendingGpac,
    [Roles.RegionalDirector]: commonActions.forCoachAndRegionalWhenPendingGpac,
    [Roles.Operations]: { primary: 'resend', actions: ['view', 'void'] },
    [Roles.ProductionDirector]: { primary: 'sign', actions: ['view'] }
  },
  [FeeAgreementStatus.Signed]: 'view',
  [FeeAgreementStatus.PendingCoachValidation]: {
    [Roles.Recruiter]: {
      actions: [{ id: 'cancel', guard: guards.ownership }, 'viewTemplate']
    },
    [Roles.Coach]: commonActions.forCoachAndRegionalWhenPendingCoach,
    [Roles.AssistantRegionalDirector]: commonActions.forCoachAndRegionalWhenPendingCoach,
    [Roles.RegionalDirector]: commonActions.forCoachAndRegionalWhenPendingCoach,
    [Roles.Operations]: {
      primary: { id: 'approve', alternate: { id: 'declining', guard: guards.isDeclining } },
      actions: ['decline', 'cancel', 'viewTemplate']
    },
    [Roles.Leadership]: {
      primary: { id: 'approve', alternate: { id: 'declining', guard: guards.isDeclining } },
      actions: ['decline', 'cancel', 'viewTemplate']
    },
    [Roles.ProductionDirector]: commonActions.forProductionDirectorWhenPendingCoach
  },
  [FeeAgreementStatus.PendingOpsValidation]: {
    [Roles.Recruiter]: { actions: [{ id: 'cancel', guard: guards.ownership }, 'viewTemplate'] },
    [Roles.Coach]: commonActions.forCoachAndRegionalWhenPendingOps,
    [Roles.AssistantRegionalDirector]: commonActions.forCoachAndRegionalWhenPendingOps,
    [Roles.RegionalDirector]: commonActions.forCoachAndRegionalWhenPendingOps,
    [Roles.Operations]: {
      primary: { id: 'approve', alternate: { id: 'declining', guard: guards.isDeclining } },
      actions: ['decline', 'cancel', 'viewTemplate']
    }
  },
  [FeeAgreementStatus.DeclinedByCoach]: {
    [Roles.Recruiter]: {
      primary: 'revalidate',
      guard: guards.ownership,
      actions: ['cancel', 'viewTemplate']
    },
    [Roles.Coach]: commonActions.forCoachAndRegionalWhenDeclinedByCoach,
    [Roles.AssistantRegionalDirector]: commonActions.forCoachAndRegionalWhenPendingOps,
    [Roles.RegionalDirector]: commonActions.forCoachAndRegionalWhenDeclinedByCoach,
    [Roles.Operations]: { actions: ['cancel'] },
    [Roles.Leadership]: { actions: ['cancel'] },
    [Roles.ProductionDirector]: { primary: 'default' }
  },
  [FeeAgreementStatus.DeclinedByOperations]: {
    [Roles.Recruiter]: {
      primary: 'revalidate',
      guard: guards.ownership,
      actions: ['cancel', 'viewTemplate']
    },
    [Roles.Coach]: commonActions.forCoachAndRegionalWhenDeclinedByOps,
    [Roles.AssistantRegionalDirector]: commonActions.forCoachAndRegionalWhenDeclinedByOps,
    [Roles.RegionalDirector]: commonActions.forCoachAndRegionalWhenDeclinedByOps,
    [Roles.Operations]: { actions: ['cancel'] },
    [Roles.Leadership]: { actions: ['cancel'] }
  },
  [FeeAgreementStatus.Expired]: {
    [Roles.Recruiter]: {
      guard: guards.ownership,
      actions: ['reactivate']
    },
    [Roles.Coach]: {
      guard: guards.teamOwnership,
      actions: ['reactivate']
    },
    [Roles.AssistantRegionalDirector]: {
      guard: guards.teamOwnership,
      actions: ['reactivate']
    },
    [Roles.Operations]: { actions: ['reactivate'] },
    [Roles.Leadership]: { actions: ['reactivate'] }
  },
  [FeeAgreementStatus.Void]: 'default',
  [FeeAgreementStatus.Canceled]: 'default',
  [FeeAgreementStatus.PendingOpsTemplate]: {
    [Roles.Operations]: {
      primary: 'createPreview'
    }
  }
};

export const Rules = rules;
