// @flow
import React from 'react';
import strings from 'strings';
import type { MenuButtonItemsProps } from 'types/app';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import FPButtonMenu from 'UI/components/molecules/FPButtonMenu';

const { actions } = strings.searchProjects.preview;

type SearchProjectsHeaderActionPropTypes = {
  isSearchProjectArchived?: boolean,
  menuItems: Array<MenuButtonItemsProps>,
  onOpenRestoreDialog: () => void
};

const SearchProjectsHeaderAction = ({
  isSearchProjectArchived = false,
  menuItems,
  onOpenRestoreDialog
}: SearchProjectsHeaderActionPropTypes) => {
  if (isSearchProjectArchived)
    return (
      <FPActionButton text={actions.archivedSearchProject.title} onClick={onOpenRestoreDialog} />
    );

  return (
    <FPButtonMenu
      text={actions.moreOptions.title}
      showDefaultIcon
      isIconButton={false}
      variant="outlined"
      menuItems={menuItems}
    />
  );
};
export default SearchProjectsHeaderAction;
