// @flow
import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import HelpIcon from '@material-ui/icons/Help';
import differenceBy from 'lodash/differenceBy';
import uniqBy from 'lodash/uniqBy';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { Permissions, userHasPermission, userHasRoles } from 'services/Authorization';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import LocationForm from 'UI/components/organisms/LocationForm';
import PhoneNumbersForm from 'UI/components/organisms/PhoneNumbersForm';
import { GridItem, ItemCreationGridLayout } from 'UI/components/templates/ItemsCreation';
import { BinaryOptions } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { CompanyType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';
import {
  EXT_PHONE_VALIDATION,
  getFeatureFlags,
  idOptionSelected,
  industrySpecialtyOptionLabel,
  nestTernary,
  PHONE_VALIDATION,
  REQUIRED_VALIDATION,
  titleOptionLabel,
  URL_VALIDATION,
  VALIDATION_REGEXS
} from 'UI/utils';
import { buildBasePropsForField } from 'UI/utils/forms';
import { LabelRenderers, Selectors } from 'UI/utils/renderers';

import { DEFAULT_PHONE_ROW } from '../PhoneNumbersForm/utils';

import { FormFieldsMap, SubspecialtiesCatalogKey } from './fields';

const chainedSelects = {
  [FormFieldsMap.State.key]: [FormFieldsMap.City.key, FormFieldsMap.Zip.key],
  [FormFieldsMap.City.key]: [FormFieldsMap.Zip.key]
};

const idExtractor = ({ id }) => id;
const phoneProps = { 'aria-label': 'phone' };

type CompanyFormProps = {
  disabled?: boolean,
  disabledEditKeys?: array
};

const getPhoneInputType = phone =>
  phone && phone.length > PHONE_VALIDATION.maxLength.value ? 'text' : 'phone';

const CompanyForm = ({ disabled, disabledEditKeys }: CompanyFormProps) => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const canEditCompanyPriorityTarget =
    checkIfFeatureFlagEnabled(FeatureFlags.CompaniesTargetPriority) &&
    (userHasPermission(Permissions.Company.editPriorityTarget) ||
      userHasRoles([
        Roles.Operations,
        Roles.DataCoordinator,
        Roles.Leadership,
        Roles.ProductionDirector
      ]));

  const { register, errors, setValue, watch, getValues } = useFormContext();

  useEffect(() => {
    register({ name: FormFieldsMap.Id.key });

    register(
      { name: FormFieldsMap.Specialties.key },
      {
        validate(values) {
          return values?.length > 0 || REQUIRED_VALIDATION.required;
        }
      }
    );
    register({ name: FormFieldsMap.Phone.key }, { ...REQUIRED_VALIDATION, ...PHONE_VALIDATION });
    register({ name: FormFieldsMap.Type.key });
    register({ name: SubspecialtiesCatalogKey });
    register({
      name: FormFieldsMap.Phones.key,
      value: [DEFAULT_PHONE_ROW]
    });

    register({ name: FormFieldsMap.CompanyTarget.key });
  }, [register, canEditCompanyPriorityTarget]);

  useEffect(() => {
    register(
      { name: FormFieldsMap.Subspecialties.key },
      {
        validate(value) {
          const subspecialties = getValues(SubspecialtiesCatalogKey) || [];
          const selectedSubspecialties = value || [];
          const specialtiesWithRequiredSubspecialties = uniqBy(subspecialties, 'specialty_id');
          const missingSubspecialties = differenceBy(
            specialtiesWithRequiredSubspecialties,
            selectedSubspecialties,
            'specialty_id'
          );

          return (
            missingSubspecialties.length === 0 ||
            `A specialty for ${missingSubspecialties[0].specialty} is required`
          );
        }
      }
    );
  }, [register, getValues]);

  const handlePhoneChange = (name?: string, value: any) => setValue(name, value, true);

  const handleComboChange = (name?: string, value: any) => {
    setValue(name, value, true);

    if (name && chainedSelects[name]) {
      chainedSelects[name].forEach(chainedSelect => setValue(chainedSelect, null));
    }
  };

  const handleSpecialtyChange = (name?: string, value: any) => {
    const specialtyIds = value ? value.map(idExtractor) : [];
    const currentSubspecialties = formValues[FormFieldsMap.Subspecialties.key];
    const newSubspecialties = currentSubspecialties.filter(subspec =>
      specialtyIds.includes(subspec.specialty_id)
    );

    setValue(name, value, true);

    const shouldClearSomeSubspecialties = currentSubspecialties.length !== newSubspecialties.length;

    shouldClearSomeSubspecialties && setValue(FormFieldsMap.Subspecialties.key, newSubspecialties);
  };

  const handleSubspecialtiesLoaded = useCallback(
    (options?: any[]) => {
      setValue(SubspecialtiesCatalogKey, options);
    },
    [setValue]
  );

  const formValues = watch();
  const companyId = formValues[FormFieldsMap.Id.key];
  const typeId = formValues[FormFieldsMap.Type.key]?.id;

  const selectedSpecialties = formValues[FormFieldsMap.Specialties.key];
  const specialtyIds = selectedSpecialties.map(idExtractor);
  const availableSubspecialties = formValues[SubspecialtiesCatalogKey];
  const areSubspecialtiesRequired =
    selectedSpecialties?.length > 0 && availableSubspecialties?.length > 0;

  const canChangeType = userHasRoles([Roles.Operations, Roles.DataCoordinator]);
  const isEditing = !!companyId;

  const shouldDisplayLegacyPhoneFields = !getFeatureFlags().includes(
    FeatureFlags.CompaniesMultiplePhones
  );

  const isDisabled = (key: string) => {
    return disabled || (isEditing && disabledEditKeys.includes(key));
  };

  const priorityTargetRadioValue = nestTernary(
    formValues[FormFieldsMap.CompanyTarget.key] === null,
    null,
    formValues[FormFieldsMap.CompanyTarget.key] ? '1' : '0'
  );

  const handleChangeCheckbox = (event, value) => {
    const booleanValue = value === '1';

    setValue(FormFieldsMap.CompanyTarget.key, booleanValue, true);
  };

  return (
    <>
      <ItemCreationGridLayout>
        <GridItem editionDrawer={isEditing}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.Name.key, errors)}
            label={strings.companies.creation.fields[FormFieldsMap.Name.key]}
            inputRef={register(REQUIRED_VALIDATION)}
            disabled={isDisabled(FormFieldsMap.Name.key)}
          />
        </GridItem>
        <GridItem editionDrawer={isEditing}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.LegalName.key, errors)}
            label={strings.companies.creation.fields[FormFieldsMap.LegalName.key]}
            inputRef={register()}
            disabled={isDisabled(FormFieldsMap.LegalName.key)}
          />
        </GridItem>
        <GridItem editionDrawer={isEditing}>
          <AutocompleteSelect
            {...buildBasePropsForField(FormFieldsMap.Specialties.key, errors)}
            getOptionLabel={industrySpecialtyOptionLabel}
            getOptionSelected={idOptionSelected}
            groupBy={option => option.industry_title}
            multiple
            onSelect={handleSpecialtyChange}
            placeholder={strings.companies.creation.fields[FormFieldsMap.Specialty.key]}
            renderOption={titleOptionLabel}
            selectedValue={selectedSpecialties}
            url={Endpoints.Specialties}
            disabled={isDisabled(FormFieldsMap.Specialties.key)}
          />
        </GridItem>
        <GridItem editionDrawer={isEditing}>
          <AutocompleteSelect
            {...buildBasePropsForField(FormFieldsMap.Subspecialties.key, errors)}
            width="100%"
            selectedValue={formValues[FormFieldsMap.Subspecialties.key]}
            placeholder={`${strings.companies.creation.fields[FormFieldsMap.Subspecialty.key]}${
              areSubspecialtiesRequired ? ' *' : ''
            }`}
            url={
              specialtyIds.length > 0
                ? `${Endpoints.SubspecialtiesSearch}/by-specialty?specialtyIds=${specialtyIds.join(
                    ','
                  )}`
                : ''
            }
            multiple
            getOptionSelected={Selectors.byId}
            getOptionLabel={LabelRenderers.subspecialty}
            renderOption={LabelRenderers.title}
            groupBy={option => `${option.industry} › ${option.specialty}`}
            onSelect={handleComboChange}
            onOptionsLoaded={handleSubspecialtiesLoaded}
            disabled={isDisabled(FormFieldsMap.Subspecialties.key)}
          />
        </GridItem>
        <LocationForm
          state={formValues[FormFieldsMap.State.key]}
          city={formValues[FormFieldsMap.City.key]}
          zip={formValues[FormFieldsMap.Zip.key]}
          handleComboChange={handleComboChange}
          editionDrawer={isEditing}
          disabled={isDisabled(FormFieldsMap.State.key)}
        />
        {shouldDisplayLegacyPhoneFields && (
          <>
            <GridItem editionDrawer={isEditing}>
              <TextBox
                {...buildBasePropsForField(FormFieldsMap.Phone.key, errors)}
                label={strings.companies.creation.fields[FormFieldsMap.Phone.key].label}
                placeholder={strings.companies.creation.fields[FormFieldsMap.Phone.key].placeholder}
                onChange={handlePhoneChange}
                value={formValues[FormFieldsMap.Phone.key] || ''}
                inputType={getPhoneInputType(formValues[FormFieldsMap.Phone.key])}
                inputProps={phoneProps}
                disabled={isDisabled(FormFieldsMap.Phone.key)}
              />
            </GridItem>
            <GridItem editionDrawer={isEditing}>
              <TextBox
                {...buildBasePropsForField(FormFieldsMap.Ext.key, errors)}
                label={strings.companies.creation.fields[FormFieldsMap.Ext.key].label}
                placeholder={strings.companies.creation.fields[FormFieldsMap.Ext.key].placeholder}
                inputRef={register(EXT_PHONE_VALIDATION)}
                disabled={isDisabled(FormFieldsMap.Ext.key)}
              />
            </GridItem>
          </>
        )}
        <GridItem editionDrawer={isEditing}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.Email.key, errors)}
            label={strings.companies.creation.fields[FormFieldsMap.Email.key]}
            inputRef={register({
              pattern: {
                value: VALIDATION_REGEXS.EMAIL,
                message: strings.shared.validations.invalid
              }
            })}
            disabled={isDisabled(FormFieldsMap.Email.key)}
          />
        </GridItem>
        <GridItem editionDrawer={isEditing}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.Address.key, errors)}
            label={strings.companies.creation.fields[FormFieldsMap.Address.key]}
            inputRef={register(REQUIRED_VALIDATION)}
            disabled={isDisabled(FormFieldsMap.Address.key)}
          />
        </GridItem>

        <GridItem editionDrawer={isEditing}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.Website.key, errors)}
            label={strings.companies.creation.fields[FormFieldsMap.Website.key]}
            inputRef={register(URL_VALIDATION)}
            disabled={isDisabled(FormFieldsMap.Website.key)}
          />
        </GridItem>
        <GridItem editionDrawer={isEditing}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.LinkedinUrl.key, errors)}
            label={strings.companies.creation.fields[FormFieldsMap.LinkedinUrl.key]}
            inputRef={register(URL_VALIDATION)}
            disabled={isDisabled(FormFieldsMap.LinkedinUrl.key)}
          />
        </GridItem>
        <GridItem editionDrawer={isEditing}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.AccountsPayableEmail.key, errors)}
            label={strings.companies.creation.fields[FormFieldsMap.AccountsPayableEmail.key]}
            inputRef={register({
              pattern: {
                value: VALIDATION_REGEXS.EMAIL,
                message: strings.shared.validations.invalid
              }
            })}
            disabled={isDisabled(FormFieldsMap.AccountsPayableEmail.key)}
          />
        </GridItem>
        {isEditing && (
          <>
            <GridItem editionDrawer={isEditing}>
              <AutocompleteSelect
                {...buildBasePropsForField(FormFieldsMap.Type.key, errors)}
                width="100%"
                placeholder={strings.companies.creation.fields[FormFieldsMap.Type.key]}
                selectedValue={formValues[FormFieldsMap.Type.key]}
                url={`${Endpoints.Companies}/${Endpoints.Types}`}
                onSelect={handleComboChange}
                disabled={!canChangeType}
              />
            </GridItem>

            {typeId === CompanyType.NotSigned && !canChangeType && (
              <GridItem editionDrawer={isEditing}>
                <FormHelperText>
                  <HelpIcon color="disabled" />
                  {strings.companies.typeHelper}
                </FormHelperText>
              </GridItem>
            )}

            <When condition={canEditCompanyPriorityTarget}>
              <GridItem editionDrawer={isEditing} xs={12} sm={12} md={12} lg={12}>
                <FPRadioGroup
                  label={strings.companies.creation.targetCompany.label}
                  name={FormFieldsMap.CompanyTarget.key}
                  onChange={handleChangeCheckbox}
                  options={BinaryOptions}
                  value={priorityTargetRadioValue}
                />
                <FormHelperText>
                  {strings.companies.creation.targetCompany.helperText}
                </FormHelperText>
              </GridItem>
            </When>
          </>
        )}
      </ItemCreationGridLayout>
      {getFeatureFlags().includes(FeatureFlags.CompaniesMultiplePhones) && (
        <PhoneNumbersForm
          isEditing={isEditing}
          disabled={isDisabled(FormFieldsMap.Phones.key)}
          isRequired
        />
      )}
    </>
  );
};

CompanyForm.defaultProps = {
  disabled: false,
  disabledEditKeys: []
};

export default CompanyForm;
