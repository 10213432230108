// @flow
import React from 'react';
import FPButtonMenu from 'UI/components/molecules/FPButtonMenu';
import SkeletonWrapper from 'UI/components/organisms/inventoryProfiles/SkeletonWrapper';

const ActionsBar = ({ isLoading, buttons, actionsBar, additionalHeaderActions, menuItems }) => {
  return (
    <SkeletonWrapper
      gridProps={{ justify: 'flex-end' }}
      loading={isLoading}
      skeletonsCount={1}
      skeletonProps={buttons}
      spacing={2}
    >
      {actionsBar || (
        <>
          {additionalHeaderActions}
          <FPButtonMenu
            isIconButton
            tooltipProps={{ title: 'Menu Options' }}
            variant="outlinePrimary"
            menuItems={menuItems}
          />
        </>
      )}
    </SkeletonWrapper>
  );
};

export default ActionsBar;
