import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenter, flexAlignCenterFlexEnd } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  actions: {
    marginTop: 10
  },
  bodyContainer: {
    ...flexAlignCenter,
    flexGrow: 1
  },
  closeButtonContainer: flexAlignCenterFlexEnd,
  dialogPaper: {
    top: 'initial',
    borderBottom: ({ color }) => `10px solid ${color}`
  },
  button: {
    minWidth: 120,
    fontWeight: theme.typography.fontWeightMedium
  },
  buttonMargin: {
    margin: theme.spacing(0, 2)
  },
  text: { fontSize: 24, textAlign: 'center' },
  children: {
    margin: theme.spacing(2, 0)
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200
  },
  childrenContainer: {
    marginLeft: ({ showSeverityIcon }) => (showSeverityIcon ? theme.spacing(2) : 0),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  customActionsContainer: {
    '& > *': {
      margin: theme.spacing(0, 1)
    }
  }
}));
