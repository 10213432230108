/* eslint-disable no-prototype-builtins */
/* eslint-disable no-console */
// @flow
import { useContext } from 'react';
import { NotificationProviderContext } from 'providers/NotificationProvider';
import { messaging } from 'services/Firebase';
import { NOTIFICATIONS_PERMISSION_TYPES, NOTIFICATIONS_SERVICE_TYPES } from 'UI/utils';

const useNotification = () => {
  const [, setNotificationToken] = useContext(NotificationProviderContext);

  /**
   * If the browser supports the Notification API and the PushManager API, and the user has granted
   * permission to receive notifications, then get the current token. Otherwise, remove the token from
   * local storage and request permission
   */
  const verifyNotificationPermissions = () => {
    const isNotificationAPISupported = 'Notification' in window && 'PushManager' in window;
    if (
      isNotificationAPISupported &&
      messaging &&
      Notification.permission === NOTIFICATIONS_PERMISSION_TYPES.GRANTED
    ) {
      getNotificationToken(); // If Premission granted proceed towards token fetch
      return;
    }
    setNotificationToken(null);
    localStorage.removeItem(NOTIFICATIONS_SERVICE_TYPES.TOKEN_STORE_NAME);
    requestNotificationPermissions(); // If permission hasn’t been granted to our app, request user in requestPermission method.
  };

  /**
   * It requests permission from the user to send notifications
   */
  const requestNotificationPermissions = async () => {
    try {
      messaging && (await messaging.requestPermission());
      getNotificationToken();
    } catch (err) {
      if (err.hasOwnProperty('code') && err.code === 'messaging/permission-default') {
        localStorage.setItem(
          NOTIFICATIONS_SERVICE_TYPES.PERMISSION_STORE_NAME,
          NOTIFICATIONS_PERMISSION_TYPES.DEFAULT
        );
        console.log('You need to allow the site to send notifications');
      } else if (err.hasOwnProperty('code') && err.code === 'messaging/permission-blocked') {
        localStorage.setItem(
          NOTIFICATIONS_SERVICE_TYPES.PERMISSION_STORE_NAME,
          NOTIFICATIONS_PERMISSION_TYPES.DENIED
        );
        console.log(
          'Currently, the site is blocked from sending notifications. Please unblock the same in your browser settings'
        );
      } else console.log('Unable to subscribe you to notifications');
    }
  };

  /**
   * If the user has a notification token, set it in the state and return it. If the user doesn't have
   * a notification token, get one and set it in the state
   */
  const getNotificationToken = async () => {
    const currentToken = getCurrentNotificationToken();
    setNotificationToken(currentToken);
    if (!currentToken) {
      const newToken = messaging && (await messaging.getToken());
      if (newToken) {
        setNotificationToken(newToken);
        localStorage.setItem(NOTIFICATIONS_SERVICE_TYPES.TOKEN_STORE_NAME, newToken); // user has a device token
        localStorage.removeItem(NOTIFICATIONS_SERVICE_TYPES.PERMISSION_STORE_NAME);
      }
    }
  };

  /**
   * It gets the current notification token from local storage.
   */
  const getCurrentNotificationToken = () =>
    localStorage.getItem(NOTIFICATIONS_SERVICE_TYPES.TOKEN_STORE_NAME);

  /**
   * It gets the current notification permission from local storage.
   */
  const getCurrentNotificationPermission = () =>
    localStorage.getItem(NOTIFICATIONS_SERVICE_TYPES.PERMISSION_STORE_NAME);

  return {
    verifyNotificationPermissions,
    requestNotificationPermissions,
    getNotificationToken,
    getCurrentNotificationToken,
    getCurrentNotificationPermission
  };
};

export default useNotification;
