// @flow
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { confirm, showAlert } from 'actions/app';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import { EntityRoutes } from 'routes/constants';
import { LinkHiringAuthorityOrCandidate } from 'services/Candidates';
import strings from 'strings';
import type { DrawerUiState } from 'types/app';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { UIStatus } from 'UI/constants/status';
import { OptionRenderers, Renderers } from 'UI/utils/renderers';

import { useStyles } from '../../styles';

const fieldsConfig = [
  {
    name: 'hiringId',
    validation: { required: 'Hiring Authority is required' }
  }
];

type LinkHiringAuthorityProps = {
  candidateId: number,
  onClose: () => any,
  onCompleted: () => any
};

const candidateIncludes = 'personalInformation,specialty,subspecialty,position';

const LinkHiringAuthority = ({ candidateId, onClose, onCompleted }: LinkHiringAuthorityProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [uiState, setUiState] = useState<DrawerUiState>({
    isLoading: false,
    isSaving: false,
    isSuccess: false,
    navStack: []
  });

  const { Status, state } = useFetchWithStatus(
    candidateId ? `${Endpoints.Candidates}/${candidateId}?includes=${candidateIncludes}` : '',
    null,
    null,
    2
  );
  const candidate = state?.results;

  const form = useForm();
  const { handleSubmit, register, unregister, setValue, errors } = form;
  const [comboValues, setComboValues] = useState<Map>({});

  useEffect(() => {
    fieldsConfig.forEach(({ name, type, validation }) => {
      register({ name, type }, validation);
    });

    return () => unregister(fieldsConfig.map(({ name }) => name));
  }, [register, unregister]);

  const handleComboChange = (name?: string, value: any) => {
    setComboValues((prevState: Map): Map => ({ ...prevState, [name]: value }));
    setValue(name, value ? value.id : value, true);
  };

  const createHiringAuthority = async (formData = null) => {
    setUiState(prevState => ({ ...prevState, isSaving: true }));

    const { hiringId } = formData;
    const url = `${Endpoints.Candidates}/${candidateId}/hiring-authorities/${hiringId}/link`;
    const result = await LinkHiringAuthorityOrCandidate(url, formData, {
      successTitle: 'Hiring Authority Link Successfully',
      successBody: `${candidate?.personalInformation?.full_name} was linked from the Candidate Profile`
    });

    setUiState(prevState => ({ ...prevState, isSaving: false }));
    result.alert && result.alert.body && dispatch(showAlert(result.alert));
    result.success && onCompleted && onCompleted(result.data);
    result.success && onClose && onClose();
  };

  const onSubmit = async (formData: any) => {
    const candidateName = candidate?.personalInformation?.full_name;
    const hiringName = comboValues?.hiringId?.full_name;

    dispatch(
      confirm({
        severity: 'warning',
        title: strings.inventoryProfiles.sections.candidates.linkCandidateDialog.title,
        message: strings.formatString(
          strings.inventoryProfiles.sections.candidates.linkCandidateDialog.message,
          {
            candidateName,
            hiringName
          }
        ),
        confirmButtonText: 'Link profiles',
        cancelButtonText: 'Cancel',
        onConfirm: async ok => {
          if (!ok) return;
          await createHiringAuthority(formData);
        }
      })
    );
  };

  return (
    <DrawerContentLayout
      title="Link Candidate with Hiring Authority"
      variant="borderless"
      uiState={uiState}
      isTopToolbarNeeded
      isBottomToolbarNeeded={state?.status === UIStatus.Success}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      onSecondaryButtonClick={onClose}
    >
      <>
        <Status
          loading={
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
              <CircularProgress color="inherit" size={40} />
            </Box>
          }
          empty={
            <Typography paragraph gutterBottom>
              Candidate not found
            </Typography>
          }
          error={error => (
            <Typography paragraph gutterBottom>
              {error}
            </Typography>
          )}
          /** react-hook-forms is not playing well with fetch hook. Moving form rendering outside Status  */
          success={() => null}
        />
        {candidate && (
          <FormContext {...form}>
            <AutocompleteSelect
              name="hiringId"
              displayKey="full_name"
              selectedValue={comboValues.hiringId}
              error={!!errors.hiringId}
              errorText={errors.hiringId && errors.hiringId.message}
              placeholder="Hiring Authority *"
              typeahead
              typeaheadLimit={50}
              typeaheadParams={{ entityType: EntityType.HiringAuthority }}
              url={Endpoints.Search}
              noOptionsText="Hiring Authorities not found"
              onSelect={handleComboChange}
              renderOption={OptionRenderers.displayHiringAuthority}
            />
          </FormContext>
        )}
        {comboValues?.hiringId && (
          <div className={classes.details}>
            {Renderers.displayBasicInfoEntity(comboValues?.hiringId)}
            {Renderers.externalLink && (
              <div className={classes.link}>
                {Renderers.externalLink(
                  EntityRoutes.HiringAuthorityProfile.replace(':id', comboValues?.hiringId.id),
                  'View Hiring Authority'
                )}
              </div>
            )}
          </div>
        )}
      </>
    </DrawerContentLayout>
  );
};

LinkHiringAuthority.defaultProps = {
  candidateId: null
};

export default LinkHiringAuthority;
