// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { useGlobalStyles } from 'GlobalStyles';
import DraftsProvider from 'providers/DraftsProvider';
import queryString from 'query-string';
import strings from 'strings';
import { FormFieldsMap } from 'UI/components/organisms/CandidateForm/fields';
import DraftsManager from 'UI/components/organisms/DraftsManager';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import { PageTitles } from 'UI/constants/defaults';
import { EntityType } from 'UI/constants/entityTypes';

import NewCandidateContent from './NewCandidateContent';

const draftRequiredFields = [
  FormFieldsMap.FirstName.key,
  FormFieldsMap.LastName.key,
  FormFieldsMap.Email.key
];

export type NewCandidateProps = {
  history: any,
  location: any
};

const NewCandidate = (props: NewCandidateProps) => {
  const { location } = props;
  const dispatch = useDispatch();
  const { hiringId, contactId } = queryString.parse(location.search);
  const isFromProfile = !!hiringId || !!contactId;

  const [selectedDraft, setSelectedDraft] = useState(null);
  const globalClasses = useGlobalStyles();

  useEffect(() => {
    document.title = PageTitles.CandidateCreate;
  }, []);

  useEffect(() => {
    if (!selectedDraft?.formData) return;

    if (!selectedDraft?.formData?.email)
      dispatch(
        showAlert({
          severity: 'error',
          ...strings.candidates.creation.candidateWithoutEmail
        })
      );
  }, [dispatch, selectedDraft]);

  return (
    <ContentPageLayout
      text={
        isFromProfile
          ? strings.candidates.creation.titleFromDirectory
          : strings.candidates.creation.title
      }
      titleLabelProps={{ backNavigation: true }}
    >
      <div className={globalClasses.itemCreationDataWrapper}>
        <DraftsProvider type={EntityType.Candidate} draftValidationFields={draftRequiredFields}>
          {!isFromProfile && (
            <DraftsManager type={EntityType.Candidate} onDraftSelected={setSelectedDraft} />
          )}
          <NewCandidateContent
            key={selectedDraft?.id || 0}
            isFromProfile={isFromProfile}
            hiringId={hiringId}
            contactId={contactId}
            setSelectedDraft={setSelectedDraft}
            {...props}
          />
        </DraftsProvider>
      </div>
    </ContentPageLayout>
  );
};

export default NewCandidate;
