// @flow
import React from 'react';
import FPButtonMenu from 'UI/components/molecules/FPButtonMenu';
import { SvgSearchProjects } from 'UI/res';

type SearchProjectsActionProps = {
  text?: string,
  tooltipText?: string,
  menuItems: Array<{
    label: string,
    onClick: () => void
  }>
};

/**
 * @typedef {Object} MenuItem
 * @property {string} label - The label to display for the menu item.
 * @property {function} onClick - The function to call when the menu item is clicked.
 */

/**
 * @typedef {Object} SearchProjectsActionProps
 * @property {string} [text] - The text to display on the button.
 * @property {string} [tooltipText] - The tooltip text to display when hovering over the button.
 * @property {Array<MenuItem>} menuItems - An array of objects representing the menu items to display when the button is clicked.
 */

/**
 * A component that displays a button with a search icon and a dropdown menu of options.
 *
 * @param {SearchProjectsActionProps} props - The props for the component.
 * @returns {JSX.Element} - The rendered component.
 */
const SearchProjectsAction = ({ text, tooltipText, menuItems }: SearchProjectsActionProps) => {
  const buttonProps = {
    iconBtn: {
      isIconButton: true,
      icon: SvgSearchProjects,
      iconProps: {
        onSetColor: ({ primary }) => primary.main
      }
    },
    textBtn: {
      text,
      size: 'small',
      variant: 'outlined'
    }
  };

  const finalProps = text ? buttonProps.textBtn : buttonProps.iconBtn;

  return (
    <FPButtonMenu menuItems={menuItems} title={tooltipText || 'Search Project'} {...finalProps} />
  );
};

export default SearchProjectsAction;
