import * as React from 'react';

const SvgBulks = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M22 7.25H2a2 2 0 00-2 2v12a2 2 0 002 2h20a2 2 0 002-2v-12a2 2 0 00-2-2zm-1 3.5v4a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h4a.5.5 0 01.5.5zM4.5 17h9a.75.75 0 010 1.5h-9a.75.75 0 010-1.5zm-.75-2.75a.76.76 0 01.75-.75h2a.75.75 0 010 1.5h-2a.76.76 0 01-.75-.75zM3.5 5.75h17a1 1 0 000-2h-17a1 1 0 000 2zM5.5 2.75h13a1 1 0 000-2h-13a1 1 0 000 2z" />
    </svg>
  );
};

export default SvgBulks;
