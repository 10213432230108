import React from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextBox from 'UI/components/atoms/TextBox';

const MAX_WIDTH_MESSAGE = 320;

export const ChatContainer = styled('div')(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  padding: theme.spacing(2),
  position: 'relative',
  scrollBehavior: 'auto'
}));

export const MessageRow = styled(({ isOutbound: _isOutbound, ...other }) => <div {...other} />)(
  ({ isOutbound, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: isOutbound ? 'flex-end' : 'flex-start',
    marginBottom: theme.spacing(1)
  })
);

export const MessageContainer = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center'
});

export const MessageBubble = styled(({ isOutbound: _isOutbound, ...other }) => <div {...other} />)(
  ({ isOutbound, theme }) => ({
    backgroundColor: isOutbound ? theme.palette.primary.main : theme.palette.grey[300],
    borderRadius: theme.spacing(2, 2, isOutbound ? 0 : 2, isOutbound ? 2 : 0),
    margin: theme.spacing(0, 1),
    maxWidth: MAX_WIDTH_MESSAGE,
    padding: theme.spacing(2),
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    hyphens: 'auto',
    overflow: 'hidden'
  })
);

export const MessageText = styled(
  ({ isOutbound: _isOutbound, isHourText: _isHourText, ...other }) => <Typography {...other} />
)(({ isOutbound, isHourText, theme }) => ({
  color: isOutbound ? theme.palette.common.white : 'inherit',
  fontSize: isHourText ? 13 : 16,
  textAlign: isOutbound ? 'right' : 'left',
  whiteSpace: 'pre-wrap'
}));

export const DateTitle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  textAlign: 'center'
}));

export const MessageComposerContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1
}));

export const ComposerContainer = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'flex-end'
});

export const MessageInputWrapper = styled('div')({
  flexGrow: 1
});

export const MessageInput = styled(TextBox)({
  flexGrow: 1,
  '& > div': {
    '& > textarea': {
      width: '100%'
    }
  }
});

export const SendActionButtonContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 1)
}));

export const AttachmentActionButtonContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 1, 2, 0)
}));

export const LoaderContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 1.5, 1, 1)
}));

export const JumpToLatestButton = styled(({ showJumpButton: _showJumpButton, ...other }) => (
  <Button {...other} />
))(({ theme, showJumpButton }) => ({
  borderRadius: theme.spacing(8),
  opacity: showJumpButton ? 0.8 : 0,
  textTransform: 'none',
  position: 'absolute',
  left: '50%',
  bottom: 0,
  transform: 'translate(-50%, -50%)',
  visibility: showJumpButton ? 'visible' : 'hidden',
  transition: 'opacity 0.5s ease-in-out',
  '&:hover': {
    opacity: 1
  }
}));

export const AbsoluteContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(4),
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
  width: 'fit-content'
}));

export const AttachmentChip = styled(Chip)(({ theme }) => ({
  height: '36px',
  padding: theme.spacing(1, 1.5),
  borderRadius: '8px',
  backgroundColor: theme.palette.grey[200],

  '& .MuiChip-icon': {
    color: theme.palette.grey[600]
  },

  '& .MuiChip-deleteIcon': {
    color: theme.palette.grey[800]
  }
}));

export const AttachmentMessageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});

export const AttachmentMessageChip = styled(({ isOutbound: _isOutbound, ...other }) => (
  <Chip {...other} />
))(({ theme, isOutbound }) => {
  const fileLabelColor = isOutbound ? theme.palette.brandColors.FPWhite : theme.palette.grey[900];
  const chipHoverColor = isOutbound ? theme.palette.primary.light : theme.palette.grey[100];

  return {
    padding: theme.spacing(2.5, 1.5),
    borderRadius: '8px',
    backgroundColor: isOutbound ? theme.palette.primary.dark : theme.palette.grey[400],

    '& .MuiChip-label': {
      color: fileLabelColor,
      maxWidth: '240px'
    },

    '& .MuiChip-icon': {
      color: fileLabelColor
    },

    '&:hover': {
      backgroundColor: chipHoverColor
    },

    '&:focus': {
      backgroundColor: chipHoverColor
    }
  };
});

export const AttachmentSizeLabel = styled(({ isOutbound: _isOutbound, ...other }) => (
  <Typography {...other} />
))(({ theme, isOutbound }) => ({
  textAlign: isOutbound ? 'right' : 'left',
  color: isOutbound ? theme.palette.brandColors.FPWhite : theme.palette.grey[900],
  marginBottom: theme.spacing(1)
}));
