import { makeStyles } from '@material-ui/core';
import { flexAlignCenterJustifyCenter, flexAndWrap } from 'UI/utils/styles';

export const useStyles = makeStyles(
  theme => ({
    emptyStateContainer: {
      margin: theme.spacing(9),
      ...flexAlignCenterJustifyCenter,
      flexDirection: 'column'
    },
    columnContainer: { padding: theme.spacing(0) },
    rowContainer: {
      ...flexAndWrap,
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    },
    marginBottom: {
      marginBottom: theme.spacing(2)
    }
  }),
  { classNamePrefix: 'fileManager' }
);
