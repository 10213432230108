// @flow

import React from 'react';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import HistoryItem from 'UI/components/molecules/HistoryItem';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { TypeHistoryItem } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EmptyPlacements } from 'UI/res';

export type PlacementLogsProps = {
  id: number
};

const PlacementOperationsCopies = {
  create: 'created',
  update: 'updated',
  delete: 'deleted',
  approve: 'approved',
  'make-adjustment': 'approved',
  'request-fall-off': 'requested to fall off',
  'fall-off': 'marked as fall off',
  'request-revert-fall-off': 'requested to switch back',
  'revert-fall-off': 'switched back',
  'suggested-update': 'requested a change for'
};

const PlacementEntitiesDefinition = {
  placement: { name: 'Placement', article: 'a' },
  invoice: { name: 'Invoice', article: 'an' },
  payment: { name: 'Payment', article: 'a' },
  'suggested-update': { name: 'Change Request', article: 'a' },
  estimate: { name: 'Estimate', article: 'an' },
  creditMemo: { name: 'Credit Memo', article: 'a' }
};

const getCopyForOperation = (operationKey: string, entityKey: string) => {
  const action =
    operationKey in PlacementOperationsCopies
      ? PlacementOperationsCopies[operationKey]
      : operationKey;

  const entity =
    entityKey in PlacementEntitiesDefinition
      ? PlacementEntitiesDefinition[entityKey]
      : PlacementEntitiesDefinition.placement;
  return `${action} ${entity.article} ${entity.name}`;
};

const PlacementLogs = ({ id }: PlacementLogsProps) => {
  const endpoint = `${Endpoints.PlacementLogs.replace(':id', id)}`;
  const { Status } = useFetchWithStatus(endpoint);

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        Activity log:
      </Typography>
      <Status
        loading={
          <List dense>
            <HistoryItem isLoading />
          </List>
        }
        empty={
          <Typography paragraph gutterBottom>
            No activity yet
          </Typography>
        }
        error={error => (
          <EmptyPlaceholder title="Oops" subtitle={error} customEmptyState={<EmptyPlacements />} />
        )}
        success={results => (
          <List dense>
            {results.map(({ id: innerId, user, operation, entity, created_at }) => (
              <HistoryItem
                key={innerId}
                date={created_at}
                type={TypeHistoryItem.Custom}
                action={
                  <>
                    <b>{user?.personalInformation?.full_name || user?.email}</b>{' '}
                    {getCopyForOperation(operation, entity)}
                  </>
                }
              />
            ))}
          </List>
        )}
      />
    </>
  );
};

export default PlacementLogs;
