import { useCallback, useEffect, useRef } from 'react';

export const useInfiniteScroll = ({ onScrollEndTrigger }) => {
  const listEndRef = useRef(null);

  const handleObserver = useCallback(
    entries => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        onScrollEndTrigger();
      }
    },
    [onScrollEndTrigger]
  );

  useEffect(() => {
    const listRef = listEndRef.current;
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0 // Trigger when 100% of the element is visible
    });

    if (listRef) {
      observer.observe(listRef);
    }

    return () => {
      if (listRef) {
        observer.unobserve(listRef);
      }
    };
  }, [listEndRef, handleObserver]);

  return { listEndRef };
};
