// @flow
import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';
import ConditionalActionWrapper from 'UI/components/atoms/ConditionalWrapper';

import { useStyles } from './styles';

type ItemOption = {
  id: number,
  primaryText: string,
  secondaryText: string,
  isSelectable: boolean,
  disabledReason?: string
};

const DISABLED_MESSAGE_MAP = {
  HAS_ALREADY_A_PARENT:
    strings.companies.creation.associated.form.childSibling.disabledReasons.hasAlreadyParent,
  IS_A_SISTER: strings.companies.creation.associated.form.childSibling.disabledReasons.isASister,
  IS_CURRENT_PARENT:
    strings.companies.creation.associated.form.childSibling.disabledReasons.isCurrentParent
};

type OptionsPanelProps = {
  title: string,
  options: ItemOption[],
  checked: [],
  onToggleAll: () => void,
  numberOfChecked: number,
  onToggle: () => void
};

const OptionsPanel = ({
  title,
  options,
  checked,
  onToggleAll,
  numberOfChecked,
  onToggle
}: OptionsPanelProps) => {
  const classes = useStyles();
  const nodeTitle = (
    <Typography variant="body1" className={classes.title}>
      {title}
    </Typography>
  );
  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={onToggleAll(options)}
            checked={numberOfChecked(options) === options?.length && options.length !== 0}
            indeterminate={
              numberOfChecked(options) !== options?.length && numberOfChecked(options) !== 0
            }
            disabled={options?.length === 0}
            inputProps={{ 'aria-label': `select-all-${title}-checkbox` }}
            color="primary"
          />
        }
        title={nodeTitle}
        subheader={`${numberOfChecked(options)}/${options?.length} selected`}
      />
      <Divider className={classes.divider} />
      <List className={classes.list} dense component="div" role="list">
        {options?.map(item => {
          const labelId = `transfer-list-item-${item.id}-label`;

          const tooltipTitle = `${item.primaryText} ${
            strings.companies.creation.associated.form.childSibling.disabledReasons.cantBeSelected
          } ${DISABLED_MESSAGE_MAP[item.disabledReason]}`;

          return (
            <ConditionalActionWrapper
              key={item.id}
              condition={!item.isSelectable}
              wrapper={children => (
                <Tooltip title={tooltipTitle} placement="bottom" arrow>
                  <div>{children}</div>
                </Tooltip>
              )}
            >
              <ListItem
                key={item.id}
                className={classes.listItem}
                role="listitem"
                button
                onClick={onToggle(item)}
                disabled={!item.isSelectable}
                aria-label={`item-${item.id}`}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <Checkbox
                    checked={checked.indexOf(item) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  className={classes.listItemText}
                  classes={{
                    primary: classes.listItemPrimaryText,
                    secondary: classes.listItemSecondaryText
                  }}
                  primary={item.primaryText}
                  secondary={item.secondaryText}
                />
              </ListItem>
            </ConditionalActionWrapper>
          );
        })}
      </List>
    </Card>
  );
};

OptionsPanel.defaultProps = {
  title: null,
  options: null,
  checked: false,
  numberOfChecked: null
};

export default OptionsPanel;
