import { makeStyles } from '@material-ui/core';
import { input } from 'UI/constants/dimensions';
import { flexAlignCenter, flexJustifyEnd } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'block',
  },
  actionBorder: {
    borderRadius: 25
  },
  outline: {
    flexGrow: 1,
    borderRadius: input.borderRadius,
    border: '1px solid rgba(0,0,0,0.3)',
    minHeight: input.height,
    ...flexAlignCenter
  },
  withChips: {
    padding: theme.spacing(1, 2)
  },
  chipsContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    flexWrap: 'wrap'
  },
  loader: {
    ...flexJustifyEnd,
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  label: {
    margin: theme.spacing(0.5, 0)
  },
  placeholder: {
    margin: theme.spacing('auto', 0)
  },
  iconAlignment: {
    alignSelf: 'center'
  }
}));
