// @flow
import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { SvgDefaultEmptyState } from 'UI/res/icons/milano';

import { useStyles } from './styles';

type EmptyPlaceholderProps = {
  children: any,
  customEmptyState?: React.Node,
  height: string | number | null,
  subtitle?: string,
  subtitleClass: string,
  title?: string
};

const EmptyPlaceholder = ({
  children,
  customEmptyState,
  height,
  subtitle,
  subtitleClass,
  title,
  ...rest
}: EmptyPlaceholderProps) => {
  const classes = useStyles({ height });

  const defaultEmptyState = (
    <div className={classes.defaultEmptyState}>
      <SvgDefaultEmptyState width="100%" />
    </div>
  );

  return (
    <div className={classes.root} {...rest}>
      {customEmptyState ?? defaultEmptyState}
      <div className={classes.contentWrapper}>
        <Typography variant="h1" className={classes.title} component="div">
          {title}
        </Typography>
        <Typography
          variant="body2"
          className={clsx(classes.subtitle, subtitleClass)}
          color="textSecondary"
          component="div"
        >
          {subtitle}
        </Typography>
        <div className={classes.children}>{children}</div>
      </div>
    </div>
  );
};
EmptyPlaceholder.defaultProps = {
  children: undefined,
  customEmptyState: undefined,
  height: null,
  subtitle: '',
  subtitleClass: undefined,
  title: ''
};

export default EmptyPlaceholder;
