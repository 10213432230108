import { defaultProfileColumnsConfig } from 'UI/constants/defaults';

export const REFERENCE_CHECK_COLUMNS = [
  {
    name: 'id',
    options: {
      display: 'excluded'
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      ...defaultProfileColumnsConfig,
      sort: true
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: defaultProfileColumnsConfig
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'default'
    }
  },
  {
    name: 'relationship',
    label: 'Relationship',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'default'
    }
  },
  {
    name: 'telephone',
    label: 'Phone',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'phoneBuilderRC'
      }
    }
  },
  {
    name: 'referenceStatus',
    label: 'Reference Status',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'referenceCheckStatus'
    }
  },
  {
    name: 'created',
    label: 'Created Date',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'date',
      sort: true
    }
  },
  {
    name: 'updated',
    label: 'Last Updated',
    options: {
      display: true,
      renderer: 'date',
      sort: true
    }
  },
  {
    name: 'trace.user.fullName',
    label: 'Created by'
  }
];
