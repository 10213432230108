// @flow

import { useEffect, useState } from 'react';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { SchemaStatuses } from 'constants/schemaStatuses';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getDataSheetByCandidateId } from 'UI/pages/EditCandidateDataSheet/EditCandidateDataSheet.services';

export const useValidCandidateDataSheet = (candidateId?: number) => {
  const [candidateDataSheet, setCandidateDataSheet] = useState(null);
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  useEffect(() => {
    const isCandidateDataSheetEnabled = checkIfFeatureFlagEnabled(
      FeatureFlags.CandidateDataSheetFlow
    );

    const fetchData = async () => {
      if (!candidateId || !isCandidateDataSheetEnabled) return;

      try {
        const response = await getDataSheetByCandidateId(candidateId);

        if (response.status !== HTTPStatusCodes.Ok) return;
        if (response.data?.progress?.key !== SchemaStatuses.Completed) return;

        setCandidateDataSheet(response.data);
      } catch (error) {
        // Nothing to do here
      }
    };

    fetchData();

    return () => {
      setCandidateDataSheet(null);
    };
  }, [candidateId, checkIfFeatureFlagEnabled]);

  return {
    candidateDataSheet
  };
};
