import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenterSpaceAround } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  firstRowHighlight: {
    '& tbody .MuiTableRow-root:first-child, & tbody .MuiTableRow-root:first-child td:last-child, & tbody .MuiTableRow-root:first-child td:last-child .dataTableStickyColumn':
      {
        backgroundColor: theme.palette.primary.FP100
      },
    '& tbody .MuiTableRow-root:first-child .MuiTableCell-root > div': {
      fontWeight: 700
    }
  },
  checkBoxLowPerformance: {
    color: `${theme.palette.error.FP200} !important`
  },
  circularProgress: {
    marginTop: theme.spacing(5)
  },
  emptyStateButton: {
    textTransform: 'unset'
  },
  footerContainer: {
    marginBottom: 0
  },
  homeCard: {
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  labelHeaderActions: {
    color: theme.palette.grey[800],
    fontSize: 14,
    fontWeight: 400,
    opacity: 0.6
  },
  list: ({ isSmallViewPort }) => ({
    display: 'grid',
    gridTemplateColumns: isSmallViewPort ? '1fr 1fr' : '1fr',
    gridGap: theme.spacing(0, 1),
    gridTemplateAreas:
      isSmallViewPort &&
      `
      'firstPlace sixthPlace'
      'secondPlace seventhPlace'
      'thirdPlace eighthPlace'
      'fourthPlace ninethPlace'
      'fifthPlace tenthPlace'
    `
  }),
  loadingStateContainer: {
    flex: 1,
    flexDirection: 'column',
    textAlign: 'center',
    height: 300,
    ...flexAlignCenterSpaceAround
  },
  titleContentLabel: {
    fontSize: 11,
    fontWeight: '500 !important',
    letterSpacing: '1.5px',
    lineHeight: '16px',
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase'
  }
}));
