export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'fullName',
    label: 'Created By',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'recipientPhoneNumber',
    label: 'To',
    options: {
      sort: true,
      display: true,
      renderer: 'phone'
    }
  },
  {
    name: 'startTime',
    label: 'Start Time',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'direction',
    label: 'Direction',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'result',
    label: 'Call Result',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'duration',
    label: 'Duration',
    options: {
      sort: true,
      display: true,
      renderer: 'phoneTime'
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'id',
  direction: 'asc'
};
