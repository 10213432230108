// @flow
import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
  withStyles
} from '@material-ui/core';
import Text from 'UI/components/atoms/Text';
import type { IdTitle } from 'UI/constants/entityTypes';

import { styles, useStyles } from './styles';

type ExtraInfoProps = {
  display: 'hyphen' | 'parens',
  variant: string
};

type FPRadioGroupProps = {
  name: string,
  label: string,
  options: IdTitle[],
  value: string,
  labelVariant: string,
  onChange: (value: string) => any,
  disabled: boolean,
  loading?: boolean,
  error: boolean,
  errorMessage: string,
  column?: boolean,
  optionTextVariant?: string,
  extraInfoProps: ExtraInfoProps,
  classes: Object,
  adviceText: string,
  radioProps: Object,
  controlLabelProps: Object
};

const FPRadioGroup = ({
  adviceText,
  classes,
  column,
  controlLabelProps,
  disabled,
  error,
  errorMessage,
  extraInfoProps,
  label,
  labelVariant,
  loading,
  name,
  onChange,
  options,
  optionTextVariant,
  radioProps,
  value
}: FPRadioGroupProps) => {
  const customClasses = useStyles();

  return (
    <FormControl className={classes.root} component="fieldset" error={error}>
      {label && !loading && <Text variant={labelVariant} text={label} className={classes.label} />}
      <RadioGroup aria-label={name} name={name} value={value} onChange={onChange} row={!column}>
        {options.map(({ id, title, extraInfo }) => (
          <FormControlLabel
            key={id}
            value={String(id)}
            control={<Radio color="primary" className={classes.radio} {...radioProps} />}
            label={
              extraInfo ? (
                <>
                  <Typography
                    className={classes.spacing}
                    variant={optionTextVariant}
                    display="inline"
                  >
                    {title}
                  </Typography>
                  <Typography variant={extraInfoProps.variant} display="inline">
                    {extraInfoProps.display === 'hyphen' ? `- ${extraInfo}` : `(${extraInfo})`}
                  </Typography>
                </>
              ) : (
                <Typography variant={optionTextVariant}>{title}</Typography>
              )
            }
            disabled={disabled}
            className={classes.controlLabel}
            {...controlLabelProps}
          />
        ))}
        {!column && adviceText && <div className={customClasses.adviceText}>{adviceText}</div>}
      </RadioGroup>
      {error && errorMessage && (
        <FormHelperText className={classes.helperLabel}>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};

FPRadioGroup.defaultProps = {
  disabled: false,
  loading: false,
  error: false,
  label: '',
  labelVariant: 'body1',
  onChange: undefined,
  options: [],
  errorMessage: null,
  column: false,
  optionTextVariant: 'body1',
  adviceText: '',
  radioProps: undefined,
  controlLabelProps: undefined,
  extraInfoProps: {
    display: 'hyphen',
    variant: 'body2'
  },
  classes: {}
};

export default withStyles(styles)(FPRadioGroup);
