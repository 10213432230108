import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'UI/res';

export const useStyles = makeStyles(() => ({
  colorIndicator: {
    borderRadius: '50%',
    border: 'none',
    padding: 0,
    width: ({ size }) => size,
    height: ({ size }) => size,
    backgroundColor: ({ backgroundColor }) => backgroundColor
  }
}));

export const StatusColor = {
  standby: colors.orange,
  inactive: colors.red,
  active: colors.active,
  placement: colors.navyBlue,
  sendout: colors.success,
  offline: colors.inactive
};
