import { TabKeys } from 'UI/constants/entityTypes';
import { navigateToSendout } from 'UI/pages/Sendouts/utils';
import { updateUiState } from 'UI/utils/uiHelpers';

export const getSendoutActions = ({ setUiState, history, triggerTabRefresh }) => {
  const handleSendoutClick = ({ id }) =>
    updateUiState(
      {
        isSendoutOpen: true,
        selectedSendoutId: id
      },
      setUiState
    );

  const handleSendoutNavigation = navigateToSendout(history);

  const handleSendoutCompleted = () => {
    triggerTabRefresh(TabKeys.Sendouts);
    updateUiState(
      {
        shouldRefreshTableData: true
      },
      setUiState
    );
    handleSendoutClosed();
  };

  const handleSendoutClosed = () =>
    updateUiState(
      {
        isSendoutOpen: false,
        selectedSendoutId: null,
        shouldRefreshTableData: false
      },
      setUiState
    );

  return {
    handleSendoutClick,
    handleSendoutClosed,
    handleSendoutCompleted,
    handleSendoutNavigation
  };
};
