import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { showAlert } from 'actions/app';
import { SimplifiedTable } from 'features/command-center/';
import { ENDPOINTS, TABLES_KEYS } from 'features/command-center/constants';
import { sendReminder } from 'services/FeeAgreement';
import strings from 'strings';
import FeeAgreementDetails from 'UI/components/organisms/feeagreements/FeeAgreementDetails';
import { RowActions } from 'UI/constants/defaults';

import { createPhonesColumns } from '../../utils';

import { COLUMNS, ORDER_BY_OPTIONS } from './UnsignedAgreements.constants';
import { formatResponse } from './UnsignedAgreements.utils';

const TABLE_KEY = TABLES_KEYS.followUpTasksUnsignedAgreements;

export const UnsignedAgreements = ({ filters }) => {
  const [selectedFeeAgreementId, setSelectedFeeAgreementId] = useState(null);

  const dispatch = useDispatch();

  const openDetailsDrawer = id => setSelectedFeeAgreementId(id);
  const handleCloseDrawer = () => setSelectedFeeAgreementId(null);
  const handleSendReminder = async id => {
    const result = await sendReminder(id);
    result.alert && result.alert.body && dispatch(showAlert(result.alert));
  };

  return (
    <>
      <SimplifiedTable
        columns={COLUMNS}
        endpoint={ENDPOINTS.unsignedAgreements}
        header={strings.commandCenter.dominateSheet.unsignedAgreements.title}
        orderByOptions={ORDER_BY_OPTIONS}
        apiVersion={2}
        formatResponse={formatResponse}
        filters={filters}
        createExtraColumns={createPhonesColumns}
        createExtraColumnsConfig={{
          includeHiringAuthorityPhones: true,
          includeCandidatePhones: false
        }}
        pageKey={TABLE_KEY}
        rowActions={[
          {
            name: RowActions.View,
            customAction: 'rowClickBuilder',
            onClick: openDetailsDrawer
          },
          {
            name: RowActions.SendReminder,
            customAction: 'rowClickBuilder',
            onClick: handleSendReminder
          },
          {
            action: 'viewContact',
            name: 'viewContact',
            value: 'contactData'
          }
        ]}
      />
      {selectedFeeAgreementId && (
        <Drawer open onClose={handleCloseDrawer}>
          <div role="presentation">
            <FeeAgreementDetails
              feeAgreementId={selectedFeeAgreementId}
              onClose={handleCloseDrawer}
            />
          </div>
        </Drawer>
      )}
    </>
  );
};
