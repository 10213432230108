// @flow
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import OptionsPanel from './OptionsPanel';
import { useStyles } from './styles';

type ItemOption = {
  id: number,
  primaryText: string,
  secondaryText: string,
  isSelectable: boolean,
  disabledReason: string
};

type Panel = {
  options: ItemOption[],
  title: string
};

type TransferListProps = {
  leftPanel: Panel,
  rightPanel: Panel,
  setLeftPanel: () => void,
  setRightPanel: () => void
};

const not = (a, b) => a.filter(value => b.indexOf(value) === -1);

const intersection = (a, b) => a.filter(value => b.indexOf(value) !== -1);

const union = (a, b) => [...a, ...not(b, a)];

const TransferList = ({
  leftPanel,
  rightPanel,
  setLeftPanel,
  setRightPanel
}: TransferListProps) => {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);

  const leftChecked = intersection(checked, leftPanel.options);
  const rightChecked = intersection(checked, rightPanel.options);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = items => () => {
    const selectableItems = items.filter(item => item.isSelectable);
    if (numberOfChecked(selectableItems) === selectableItems.length) {
      setChecked(not(checked, selectableItems));
    } else {
      setChecked(union(checked, selectableItems));
    }
  };

  const handleCheckedRight = () => {
    setRightPanel(prev => ({ ...prev, options: rightPanel.options.concat(leftChecked) }));
    setLeftPanel(prev => ({ ...prev, options: not(leftPanel.options, leftChecked) }));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeftPanel(prev => ({ ...prev, options: leftPanel.options.concat(rightChecked) }));
    setRightPanel(prev => ({ ...prev, options: not(rightPanel.options, rightChecked) }));
    setChecked(not(checked, rightChecked));
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item className={classes.panel}>
        <OptionsPanel
          checked={checked}
          onToggle={handleToggle}
          onToggleAll={handleToggleAll}
          options={leftPanel.options}
          numberOfChecked={numberOfChecked}
          title={leftPanel.title}
        />
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item className={classes.panel}>
        <OptionsPanel
          checked={checked}
          onToggle={handleToggle}
          onToggleAll={handleToggleAll}
          options={rightPanel.options}
          numberOfChecked={numberOfChecked}
          title={rightPanel.title}
        />
      </Grid>
    </Grid>
  );
};

TransferList.defaultProps = {
  leftPanel: {
    options: null,
    title: null
  },
  rightPanel: {
    options: null,
    title: null
  }
};

export default TransferList;
