// @flow
import { combineReducers } from 'redux';

import appReducer from './app';
import bulkEmailReducer from './bulkEmail';
import dashboardReducer from './dashboard';
import dashboardPhoneReducer from './dashboardPhone';
import jobOrderReducer from './joborder';
import mapReducer from './map';
import notificationReducer from './notification';
import optInReducer from './optin';
import ringCentralReducer from './ringCentral';

const rootReducer = combineReducers({
  app: appReducer,
  map: mapReducer,
  dashboard: dashboardReducer,
  dashboardPhone: dashboardPhoneReducer,
  notification: notificationReducer,
  bulkEmail: bulkEmailReducer,
  optIn: optInReducer,
  jobOrder: jobOrderReducer,
  ringCentral: ringCentralReducer
});

/** Reducer that combines domain and app reducer */
export default rootReducer;
