// @flow
import React from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import strings from 'strings';
import type { TemplatesTreeViewPropTypes } from 'types/app';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { SvgCommonFileTextAdd, SvgNewFolderIcon } from 'UI/res';

import TemplatesTreeView from '../TemplateTreeView';

import { useStyles } from './styles';

const { tooltipText, actions } = strings.bulkEmails.templateActions;

type TemplateTreeViewListPropTypes = {
  isAllowedToCreate?: boolean,
  onOpenFolderDrawer: () => void,
  onOpenTemplateDrawer: () => void,
  TemplatesTreeViewProps?: TemplatesTreeViewPropTypes
};

const TemplateTreeViewList = ({
  isAllowedToCreate = false,
  onOpenFolderDrawer,
  onOpenTemplateDrawer,
  TemplatesTreeViewProps = {}
}: TemplateTreeViewListPropTypes) => {
  const classes = useStyles();

  const btnSharedProps = {
    className: classes.actionButton,
    variant: 'text',
    disabled: !isAllowedToCreate
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Tooltip title={!isAllowedToCreate ? tooltipText : ''} arrow placement="top">
        <Grid container item className={classes.buttonsWrapper}>
          <Grid item xs={6}>
            <FPActionButton
              {...btnSharedProps}
              text={actions.newFolder}
              onClick={onOpenFolderDrawer}
              icon={SvgNewFolderIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <FPActionButton
              {...btnSharedProps}
              text={actions.newTemplate}
              onClick={onOpenTemplateDrawer}
              icon={SvgCommonFileTextAdd}
            />
          </Grid>
        </Grid>
      </Tooltip>
      <Grid item className={classes.templatesTreeWrapper}>
        <TemplatesTreeView {...TemplatesTreeViewProps} />
      </Grid>
    </Grid>
  );
};

export default TemplateTreeViewList;
