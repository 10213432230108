// @flow
import React from 'react';

import Content from './Content';

const Candidates = props => {
  return <Content {...props} />;
};

export default Candidates;
