// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { showAlert } from 'actions/app';
import isEmpty from 'lodash/isEmpty';
import API from 'services/API';
import strings from 'strings';
import type { HomeItem } from 'types/app';
import FPCardWrapper from 'UI/components/molecules/cardComponents/FPCardWrapper';
import SendoutManager from 'UI/components/organisms/sendouts';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import HomeTableTemplate from 'UI/components/templates/HomeItemsCreation/HomeTableTemplate';
import {
  CustomEmptyStateRenderers,
  CustomFooterRenderers,
  HeaderActionRenderers,
  HomeRenderers,
  WrapperTabsRenderers
} from 'UI/components/templates/HomeItemsCreation/renderers';
import { getErrorMessage, nestTernary } from 'UI/utils';

import { useStyles } from './styles';
import { getSubheader } from './utils';

type HomeItemsCreationProps = {
  item: HomeItem
};

const ITEM_TYPES = {
  list: 'list',
  table: 'table',
  custom: 'custom'
};

const DEFAULT_UI_STATE = {
  selectedRowId: 0,
  isSendoutDetailsOpen: false
};

const DEFAULT_LIMIT = 5;

const HomeItemsCreation = ({ item }: HomeItemsCreationProps) => {
  const theme = useTheme();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles({ isSmallViewPort });

  const dispatch = useDispatch();
  const history = useHistory();

  const [data, setData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [extraData, setExtraData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasRedirect, setHasRedirect] = useState(
    item.type === ITEM_TYPES.table ? false : !!item.redirectTo || !!item?.footerActionType
  );
  const [uiState, setUiState] = useState(DEFAULT_UI_STATE);
  const [checkBoxActionsState, setCheckBoxActionsState] = useState({
    areNewMembers: false
  });
  const [footerActionsState, setFooterActionsState] = useState({
    showMore: false,
    isVisible: true
  });
  const limit = !footerActionsState.showMore ? DEFAULT_LIMIT : item?.maxLimit;

  const handleTabChange = (event, newValue = 0) => {
    setSelectedTab(newValue);
    item.wrapperOptions.configTabs.length > 0 &&
      getData(item.wrapperOptions.configTabs[newValue].url);
  };

  const shouldShowFooterActions = useCallback(
    count => {
      const isVisible =
        footerActionsState.showMore || !!item.customRendererOption || count >= limit;
      if (footerActionsState.isVisible === isVisible) return;
      setFooterActionsState({
        ...footerActionsState,
        isVisible
      });
    },
    [footerActionsState, item.customRendererOption, limit]
  );

  const getData = useCallback(
    async customUrl => {
      if (item.type !== ITEM_TYPES.table && item.url) {
        setIsLoading(true);
        try {
          const { data: response } = await API.get(customUrl || item.url, {
            params: {
              limit: item?.maxLimit ? limit : null,
              coachId: item?.coachId,
              regionalId: item?.regionalId || null
            }
          });
          if (response.data) {
            setData(response?.data || []);
            response?.total && shouldShowFooterActions(response.total);
            setHasRedirect(
              (item.redirectTo || item?.footerActionType) &&
                (item?.totalOptions?.totalProperty
                  ? !!response?.data[item.totalOptions.totalProperty]
                  : !isEmpty(response?.data))
            );
          }
        } catch (err) {
          dispatch(
            showAlert({
              severity: 'error',
              title: item.title,
              body: getErrorMessage(err)
            })
          );
        } finally {
          setIsLoading(false);
        }
      }

      // Data for custom footer right now - Disclaimer data
      if (item.extraDataUrl) {
        try {
          const { data: extraDataResponse } = await API.get(item.extraDataUrl);
          extraDataResponse?.data && setExtraData(extraDataResponse.data);
        } catch (err) {
          dispatch(
            showAlert({
              severity: 'error',
              title: item.title,
              body: getErrorMessage(err)
            })
          );
        }
      }
    },
    [dispatch, item, limit, shouldShowFooterActions]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  const toggleDrawer = (drawer: string, open: boolean) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;

    showOrHideDrawer(drawer, open);
  };

  const showOrHideDrawer = (drawer: string, open: boolean, additionalUi: any = {}) => {
    setUiState(prevState => ({ ...prevState, [drawer]: open, ...additionalUi }));
    !open && setUiState(prevState => ({ ...prevState, additionalData: null }));
  };

  const handleActionClick = action =>
    item?.footerActionType
      ? setFooterActionsState({ ...footerActionsState, showMore: !footerActionsState.showMore })
      : history.push(action);

  const handleChildClick = childId => {
    if (item?.action?.redirect) history.push(item.action.uri.replace(':id', childId));

    if (item?.action?.openDrawer)
      showOrHideDrawer(item.action.drawerName, true, { selectedRowId: childId });
  };

  const isTableEmpty = isDataEmpty =>
    setHasRedirect((item.redirectTo || item?.footerActionType) && !isDataEmpty);

  const getWrapper = Child => {
    const wrapperTabBuilder = WrapperTabsRenderers[item?.wrapperOptions?.renderer];

    return wrapperTabBuilder ? wrapperTabBuilder(Child, selectedTab, handleTabChange) : null;
  };

  const getCustomEmptyState = () => {
    const customEmptyStateRenderer =
      CustomEmptyStateRenderers[
        item?.wrapperOptions?.configTabs[selectedTab]?.customEmptyState || item.customEmptyState
      ];
    if (!customEmptyStateRenderer) return null;

    return customEmptyStateRenderer();
  };

  const getRenderer = () => {
    const rendererBuilder = HomeRenderers[item.renderer];

    if (item.type === ITEM_TYPES.table)
      return (
        <HomeTableTemplate
          item={item}
          url={
            item?.wrapperOptions?.configTabs
              ? item.wrapperOptions.configTabs[selectedTab].url
              : item.url
          }
          tableColumns={
            item?.wrapperOptions?.configTabs
              ? item.wrapperOptions.configTabs[selectedTab].tableColumns
              : item.tableColumns
          }
          customEmptyState={getCustomEmptyState()}
          emptyState={
            item?.wrapperOptions?.configTabs
              ? item.wrapperOptions.configTabs[selectedTab].emptyState
              : item?.emptyState
          }
          isTableEmpty={isTableEmpty}
          areNewMembers={checkBoxActionsState.areNewMembers}
          showMore={footerActionsState.showMore}
          shouldShowFooterActions={shouldShowFooterActions}
        />
      );

    if (!rendererBuilder) return null;

    if (item.type === ITEM_TYPES.list) {
      const isDataEmpty =
        data.length <= 0 || (data[item?.propertyToList] && data[item?.propertyToList] <= 0);

      if ((!data || isDataEmpty) && item.emptyState)
        return (
          <EmptyPlaceholder
            title={item.emptyState?.title || strings.shared.emptyState.title}
            subtitle={item.emptyState?.subtitle || strings.shared.emptyState.subtitle}
          />
        );

      const dataToList = item?.propertyToList ? data[item?.propertyToList] : data;
      const listOfItems = dataToList.map((each, index) => {
        return rendererBuilder({
          ...each,
          index,
          section: { ...item, total: item.showTotal && data[item?.totalOptions?.totalProperty] },
          handleClick: handleChildClick
        });
      });

      return listOfItems;
    }

    return rendererBuilder({ section: { ...item }, ...data });
  };

  const handleChange = event =>
    setCheckBoxActionsState({ ...checkBoxActionsState, [event.target.name]: event.target.checked });

  const getHeaderActions = action =>
    HeaderActionRenderers[action]?.({ handleChange, state: checkBoxActionsState }) || null;

  const getCustomFooter = action =>
    CustomFooterRenderers[action]?.({ data: extraData, section: item }) || null;

  const getFooterActionText = () => {
    if (!item?.footerActionType) return 'View All';
    if (footerActionsState.showMore) return 'Show Less';
    return 'Show More';
  };

  return (
    <>
      {item.type !== ITEM_TYPES.custom && (
        <FPCardWrapper
          content={
            <div
              className={
                item?.inSmallViewPort === 'split'
                  ? nestTernary(data?.length >= item.minItemsToSplit, classes.list, undefined)
                  : undefined
              }
            >
              {item.wrapperOptions ? getWrapper(getRenderer()) : getRenderer()}
              <Drawer
                open={!!uiState.isSendoutDetailsOpen && !!uiState.selectedRowId}
                onClose={toggleDrawer('isSendoutDetailsOpen', false)}
              >
                <div role="presentation">
                  <SendoutManager
                    onEdit={getData}
                    onDelete={getData}
                    onUpdate={getData}
                    onClose={toggleDrawer('isSendoutDetailsOpen', false)}
                    id={uiState.selectedRowId}
                  />
                </div>
              </Drawer>
            </div>
          }
          hasData={!!data}
          footerActionsState={footerActionsState}
          hasRedirect={hasRedirect}
          isLoading={isLoading}
          isSmallViewPort={isSmallViewPort}
          headerProps={{ action: getHeaderActions(item.customRendererOption) }}
          contentConfig={{
            type: item.type,
            headerAction: item.headerAction,
            hintText: item.hintText,
            title: item.title,
            subtitle: item.subtitle && !isEmpty(data) && getSubheader(item, data),
            titleContent: item.titleContent,
            withoutCardHeader: item.withoutCardHeader
          }}
          footerProps={{
            actionButtonProps: {
              onClick: () => handleActionClick(item.redirectTo)
            },
            footerActionText: getFooterActionText(),
            CustomFooter: getCustomFooter(item.customRendererOption)
          }}
          onActionClick={handleActionClick}
        />
      )}
      {item.type === ITEM_TYPES.custom && getRenderer()}
    </>
  );
};

HomeItemsCreation.defaultProps = {
  item: {}
};

export default HomeItemsCreation;
