import { makeStyles } from '@material-ui/core/styles';

const sharedLabelStyles = {
  letterSpacing: '0.4px',
  lineHeight: '20px'
};

const sharedFontSize = {
  fontSize: 12,
  fontWeight: '400 !important'
};

export const useStyles = makeStyles(theme => ({
  alignCenter: {
    alignItems: 'center',
    display: 'flex'
  },
  container: {
    listStyle: 'none',
    paddingTop: theme.spacing(1),
    width: '100%'
  },
  dateLabel: {
    fontSize: 12,
    fontWeight: '400 !important',
    marginLeft: theme.spacing(1),
    ...sharedLabelStyles,
    ...sharedFontSize
  },
  divider: {
    height: '1px',
    margin: 0
  },
  dividerMarginTop: {
    marginTop: theme.spacing(1.5)
  },
  emptyGrid: {
    paddingLeft: `${theme.spacing(5)}px !important`
  },
  link: {
    backgroundColor: 'transparent',
    marginLeft: theme.spacing(1),
    maxWidth: '100%',
    padding: 0,
    width: 'fit-content',
    ...sharedLabelStyles,
    ...sharedFontSize
  },
  listItemContainer: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.FP300
    }
  },
  listItemAvatar: {
    minWidth: 'fit-content',
    marginRight: theme.spacing(1)
  },
  maxHeight: {
    height: '100%',
    marginLeft: theme.spacing(1)
  },
  primaryContent: {
    backgroundColor: theme.palette.secondary.FP300,
    borderRadius: 94,
    color: theme.palette.secondary.FP700,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1.25, 2.25, 1.25, 3),
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.FP100,
      boxShadow: '0px 12px 36px 0px #030D281F'
    }
  },
  secondaryContent: {
    alignItems: 'center',
    display: 'flex',
    marginTop: ({ isSmallViewPort }) => !isSmallViewPort && theme.spacing(1.75),
    paddingLeft: ({ isSmallViewPort }) => (isSmallViewPort ? theme.spacing(4) : theme.spacing(2.5))
  },
  timeContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  timeIcon: {
    margin: (0, 8)
  },
  timezoneLabel: {
    marginLeft: theme.spacing(1),
    textTransform: 'uppercase',
    ...sharedLabelStyles,
    ...sharedFontSize
  },
  timeLabel: {
    color: theme.palette.secondary.FP700,
    ...sharedLabelStyles,
    ...sharedFontSize,
    fontSize: 12
  },
  timeLabelAccountable: {
    color: theme.palette.secondary.FP700,
    ...sharedLabelStyles,
    ...sharedFontSize,
    fontSize: 14
  }
}));
