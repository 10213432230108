import * as React from 'react';

const SvgContacts = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm5.88 17.41a.77.77 0 01-.63.34h-10a.77.77 0 01-.63-.34.75.75 0 01-.06-.71 6.31 6.31 0 015.69-3.95 6.31 6.31 0 015.69 3.95.75.75 0 01-.06.71zM13.45 7a6.12 6.12 0 01-3.56-1.16.22.22 0 01-.1-.18.22.22 0 01.08-.2 3.46 3.46 0 012.4-1 3.5 3.5 0 013.12 1.9.26.26 0 010 .21.25.25 0 01-.16.14 5.81 5.81 0 01-1.78.29zm2 1.15a.22.22 0 01.23.05.26.26 0 01.09.22 3.52 3.52 0 01-7-.45 2.92 2.92 0 01.09-.73.27.27 0 01.14-.15.26.26 0 01.24 0 7.34 7.34 0 004.22 1.33 7.69 7.69 0 001.97-.24z" />
    </svg>
  );
};

export default SvgContacts;
