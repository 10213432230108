import { userHasRoles } from 'services/Authorization';
import { Roles } from 'UI/constants/roles';
import { loadFromLocalStorage } from 'UI/utils';

import { PAGE_REGIONAL_KEY } from './DominateSheetLayout.constants';

export const getRegionalInitialState = user => {
  const isRegional = userHasRoles([Roles.RegionalDirector]);
  const isAssistantRegionalDirector = userHasRoles([Roles.AssistantRegionalDirector]);

  if (!isAssistantRegionalDirector && !isRegional)
    return loadFromLocalStorage(PAGE_REGIONAL_KEY, null);

  return {
    email: user.email,
    full_name: user.personalInformation.full_name,
    id: isRegional ? user.id : user?.teamRelation?.regionalDirectorId,
    initials: user.initials
  };
};
