import orderBy from 'lodash/orderBy';
import { nestTernary } from 'UI/utils';

const validateInput = data => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }
  return data;
};

/**
 * Base function to unify recruiters data.
 *
 * @param {Array<Object>} recruiters - Array of recruiter objects to be unified.
 * @param {boolean} [isFlat=false] - If true, processes data in flat structure; otherwise, processes nested structure.
 * @returns {Object} An object with unified recruiter data, keyed by recruiter_id.
 */
export const unifyRecruitersBase = (recruiters, isFlat = false) => {
  return recruiters.reduce((acc, recruiter) => {
    const {
      recruiter_id,
      id,
      coach,
      industry_title,
      specialty_title,
      subspecialty_title,
      states,
      cities,
      ...otherProps
    } = recruiter;

    if (!acc[recruiter_id]) {
      acc[recruiter_id] = {
        recruiter_id,
        ...otherProps,
        coach,
        teams: [],
        ...(isFlat ? { states: [], cities: [] } : {})
      };
    }

    acc[recruiter_id].teams.push({
      id,
      coach,
      industry: industry_title,
      specialty: specialty_title,
      subspecialty: subspecialty_title
    });

    if (isFlat) {
      acc[recruiter_id].states = states?.reduce((uniqueStates, state) => {
        if (!uniqueStates.some(s => s.state_id === state.state_id)) {
          uniqueStates.push(state);
        }
        return uniqueStates;
      }, acc[recruiter_id].states);

      acc[recruiter_id].cities = cities?.reduce((uniqueCities, city) => {
        if (!uniqueCities.some(c => c.city_id === city.city_id)) {
          uniqueCities.push(city);
        }
        return uniqueCities;
      }, acc[recruiter_id].cities);
    }

    return acc;
  }, {});
};

/**
 * Unifies recruiter data within a nested state and city structure.
 *
 * @param {Array<Object>} markers - Array of state objects containing recruiter data.
 * @returns {Array<Object>} Array of state objects with unified recruiter data.
 */
export const unifyRecruiters = markers => {
  const validMarkers = validateInput(markers);
  if (validMarkers.length === 0) return [];

  return validMarkers.map(state => ({
    ...state,
    values: Object.values(unifyRecruitersBase(state.values)),
    cities: state.cities.map(city => ({
      ...city,
      values: Object.values(unifyRecruitersBase(city.values))
    }))
  }));
};

/**
 * Transforms recruiter data for PIL map display.
 *
 * @param {Array<Object>} markers - Array of state objects containing recruiter data.
 * @returns {Array<Object>} Transformed array for map display, including flattened city data.
 */
export const transformPilMapData = markers => {
  const validMarkers = validateInput(markers);
  if (validMarkers.length === 0) return [];

  const formattedMarkers = unifyRecruiters(validMarkers);

  return formattedMarkers.flatMap(item =>
    item.cities?.length > 0
      ? [...item.cities, ...nestTernary(item.values?.length > 0, [item], [])]
      : item
  );
};

/**
 * Transforms and orders recruiter data for PIL list display.
 *
 * @param {Array<Object>} markers - Array of recruiter objects.
 * @returns {Array<Object>} Transformed and ordered array of unified recruiter data.
 */
export const transformPilListData = markers => {
  const validMarkers = validateInput(markers);
  if (validMarkers.length === 0) return [];

  const formattedData = Object.values(unifyRecruitersBase(validMarkers, true));

  return orderBy(formattedData, ['first_name'], 'asc');
};
