import { makeStyles } from '@material-ui/core/styles';

const DATE_SPACING = '85px';
const ATTACHMENT_SPACING = '30px';
const EMAIL_ITEM_HEIGHT = 100;

export const useStyles = makeStyles(theme => ({
  emailItem: {
    width: '100%',
    height: EMAIL_ITEM_HEIGHT,
    borderBottom: `1px solid ${theme.palette.customColors.lightgrey} `,
    padding: theme.spacing(0, 2.5),
    cursor: 'pointer',
    overflowX: 'hidden',
    '&:hover': {
      backgroundColor: theme.palette.customColors.lightButtonHover
    },
    '& > *': {
      maxWidth: '100%'
    }
  },
  activeCard: {
    backgroundColor: theme.palette.customColors.linkWater
  },
  emailSearchProjectName: {
    fontSize: 16,
    maxWidth: `calc(100% - ${DATE_SPACING})`,
    fontWeight: '700 !important',
    color: theme.palette.customColors.black
  },
  emailDate: {
    color: theme.palette.customColors.lightText
  },
  previewText: {
    fontSize: 14,
    fontWeight: 400,
    maxWidth: ({ hasAttachments }) => (hasAttachments ? `calc(100% - ${ATTACHMENT_SPACING})` : '')
  },
  warningContainer: {
    padding: theme.spacing(1, 0)
  },
  warningIcon: {
    marginRight: theme.spacing(1)
  }
}));
