// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import { EntityRoutes } from 'routes/constants';
import { Permissions, userHasPermission } from 'services/Authorization';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { When } from 'UI/components/atoms/When';
import InfoLabel from 'UI/components/molecules/InfoLabel';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import { Endpoints } from 'UI/constants/endpoints';
import { SendoutStatus, SendoverStatus } from 'UI/constants/status';
import { currencyFormatter, getId } from 'UI/utils';

import { SendoutFormStyle } from '../styles';
import { prepareArrayToDeclinationDetails } from '../utils';

const StatusesToShowPlacementInfo = [SendoutStatus.Placement, SendoutStatus.Active];

const REFUSED_REASON_TITLES = {
  [SendoutStatus.NoOffer]: 'NO OFFER REASONS',
  [SendoutStatus.Declined]: 'OFFER DECLINED REASONS',
  [SendoverStatus.Active]: 'SENDOVER REASONS',
  [SendoverStatus.NoOffer]: 'NO OFFER REASONS',
  [SendoverStatus.Declined]: 'OFFER DECLINED REASONS'
};

const MODES = {
  LINK: 'localLink'
};

const SendoutDetails = ({ sendout, profile, onNavigate, disabled, showConfirm }: any) => {
  const classes = SendoutFormStyle();

  const isPlacementActive =
    sendout &&
    StatusesToShowPlacementInfo.some(each => sendout?.status?.id === each) &&
    userHasPermission(Permissions.Placements.CanUseModule);

  const placementEndpoint = sendout && Endpoints.PlacementBySendout.replace(':id', sendout.id);
  const { Status, state } = useFetchWithStatus(placementEndpoint);
  const recentPlacement = state.results && state?.results?.length > 0 ? state.results[0] : [];

  const handleRequestNewPlacement = event => {
    event.preventDefault();
    onNavigate && openNavigationConfirm(event, { sendoutId: sendout?.id, sendout });
  };
  const handleRequestPlacementDetail = event => {
    event.preventDefault();
    onNavigate && onNavigate(event, { placementId: recentPlacement?.id, sendout });
  };

  const openNavigationConfirm = (...args) =>
    showConfirm({
      severity: 'info',
      title: strings.shared.ui.confirm,
      message: strings.sendouts.details.navigationPacementConfirm,
      onConfirm: ok => {
        ok && onNavigate(...args);
      }
    });

  /**
   * should render when:
   * status is valid to render it
   * when the form is not disabled/ that means no permissions
   * when is placement and don't have the placement
   */
  const shouldRenderNoPlacementValid =
    isPlacementActive &&
    !disabled &&
    !(sendout?.status.id === SendoutStatus.Placement && !recentPlacement);

  const noPlacementValidRender = (
    <div>
      <FPActionButton size="medium" onClick={handleRequestNewPlacement} text="Start Placement" />
    </div>
  );

  const skeletonRender = (
    <div>
      <Skeleton variant="text" />
      <Skeleton variant="text" />
    </div>
  );

  const declinationDetails =
    sendout?.declination_details && REFUSED_REASON_TITLES[sendout?.status?.id]
      ? prepareArrayToDeclinationDetails(sendout?.declination_details)
      : [];

  const detailRender = () => (
    <div>
      <InfoLabel
        title="Placement in Process"
        description={recentPlacement?.status?.title}
        mode={MODES.LINK}
        linkProps={{
          onClick: handleRequestPlacementDetail,
          url: '#'
        }}
      />
    </div>
  );

  const hiringAuthority =
    sendout?.hiringAuthority || sendout?.hiringAuthorithies?.[0]?.hiringAuthority;
  const candidate = sendout?.candidate;
  const feeAmount = sendout?.fee_amount;

  return (
    <NumberedForm.Container>
      <div className={clsx('grid-2x', classes.bottomSpacing)}>
        <div>
          <InfoLabel
            title="Company"
            description={sendout?.joborder?.company?.name || profile?.company?.name}
            mode={MODES.LINK}
            linkProps={{
              target: '_blank',
              url: EntityRoutes.CompanyProfile.replace(
                ':id',
                sendout?.joborder?.company?.id || profile?.company?.id
              )
            }}
          />
        </div>
        <div>
          <InfoLabel
            title="Job Order"
            description={sendout?.joborder?.title || profile?.title}
            mode={MODES.LINK}
            linkProps={{
              target: '_blank',
              url: EntityRoutes.JoborderProfile.replace(':id', sendout?.joborder?.id || profile?.id)
            }}
          />
        </div>

        <When condition={sendout}>
          <When condition={hiringAuthority}>
            <div>
              <InfoLabel
                title="Hiring Authority"
                description={hiringAuthority?.full_name}
                mode={MODES.LINK}
                linkProps={{
                  target: '_blank',
                  url: EntityRoutes.HiringAuthorityProfile.replace(':id', hiringAuthority?.id)
                }}
              />
            </div>
          </When>
          <When condition={candidate}>
            <div>
              <InfoLabel
                title="Candidate"
                description={candidate?.personalInformation?.full_name}
                mode={MODES.LINK}
                linkProps={{
                  target: '_blank',
                  url: EntityRoutes.CandidateProfile.replace(':id', candidate?.id)
                }}
              />
            </div>
          </When>
          <When condition={feeAmount}>
            <div>
              <InfoLabel title="Fee Amount" description={currencyFormatter(feeAmount, 2)} />
            </div>
          </When>
        </When>

        <Status
          loading={sendout ? skeletonRender : null}
          success={isPlacementActive ? detailRender : null}
          error={() => null}
          empty={shouldRenderNoPlacementValid ? noPlacementValidRender : null}
        />
      </div>

      <When condition={declinationDetails && declinationDetails.length > 0}>
        <div>
          <Typography variant="overline">{REFUSED_REASON_TITLES[sendout?.status?.id]}</Typography>
          <ul>
            {declinationDetails?.map(item => (
              <li key={getId()}>{item}</li>
            ))}
          </ul>
        </div>
      </When>
      <hr />
    </NumberedForm.Container>
  );
};

export default SendoutDetails;
