import * as React from 'react';

const SvgELearning = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M23.75 19.24l-2.38-4a.41.41 0 010-.34 2 2 0 00.16-.77v-10a2 2 0 00-2-2H4.5a2 2 0 00-2 2v10a2 2 0 00.16.77.41.41 0 010 .34l-2.38 4A1.7 1.7 0 00.23 21a1.77 1.77 0 001.54.9h20.46a1.77 1.77 0 001.54-.9 1.7 1.7 0 00-.02-1.76zm-9.25.64h-5a.75.75 0 010-1.5h5a.75.75 0 010 1.5zM19.35 14a.51.51 0 01-.35.15H5a.51.51 0 01-.5-.5v-9a.5.5 0 01.5-.5h14a.5.5 0 01.5.5v9a.47.47 0 01-.15.35z" />
      <path d="M14.34 9.89l-2.1.7a.79.79 0 01-.48 0l-2.1-.7a.48.48 0 00-.45.06.52.52 0 00-.21.41v.84a1 1 0 00.67.94l1.83.65a1.43 1.43 0 001 0l1.83-.65a1 1 0 00.67-.94v-.84a.52.52 0 00-.21-.41.48.48 0 00-.45-.06z" />
      <path d="M16.66 7.15l-4.5-1.5a.58.58 0 00-.32 0l-4.5 1.5a.51.51 0 00-.34.48v2.5a.5.5 0 101 0V8.67a.28.28 0 01.1-.21.27.27 0 01.23 0l3.51 1.14a.44.44 0 00.32 0l4.5-1.5a.5.5 0 000-.95z" />
    </svg>
  );
};

export default SvgELearning;
