import { useEffect, useState } from 'react';
import { JSON_SCHEMA_CLIENT } from 'services/API';
import { Endpoints } from 'UI/constants/endpoints';
import { RequestStatus } from 'UI/constants/status';

const useFetchJsonSchema = schemaName => {
  const [schemaData, setSchemaData] = useState(undefined);
  const [status, setStatus] = useState(RequestStatus.Idle);

  useEffect(() => {
    const fetchSchema = () => {
      setStatus(RequestStatus.Loading);
      JSON_SCHEMA_CLIENT.get(`${Endpoints.JSONSchema.replace(':schemaName', schemaName)}`)
        .then(({ data }) => {
          setSchemaData(data);
          setStatus(RequestStatus.Success);
        })
        .catch(() => {
          setStatus(RequestStatus.Error);
        });
    };
    fetchSchema();
  }, [schemaName]);

  return { schemaData, status };
};

export default useFetchJsonSchema;
