import { makeStyles } from '@material-ui/core/styles';

const styles = {
  title: {
    fontSize: 18,
    fontWeight: '500 !important'
  }
};

export const useStyles = makeStyles(theme => ({
  list: {
    paddingLeft: 'inherit'
  },
  item: {
    '&::marker': styles.title,
    marginBottom: theme.spacing(4)
  },
  itemTitle: {
    ...styles.title,
    flex: 1,
    '&.MuiTypography-gutterBottom': {
      marginBottom: theme.spacing(1.5)
    }
  },
  itemContent: {
    marginLeft: theme.spacing(-3)
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline'
  }
}));
