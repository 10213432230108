import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'scroll'
  },
  skelleton: {
    padding: theme.spacing(3)
  }
}));
