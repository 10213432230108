// @flow
import { useCallback, useState } from 'react';
import useDatatable, { getColumnPreferences } from 'hooks/datatable';
import moment from 'moment';
import { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';
import { Last12Months } from 'UI/components/templates/SideFiltersLayout/filters';
import { DateFormats } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';

import {
  CollectionDateFilters,
  CollectionsPeriodColumnBySection,
  ColumnsDefinitions
} from '../Collections.columns';

const orderByOptions = {
  column: 'duedate',
  direction: 'desc'
};

const dawnOfGpac = moment('1990-01-01').toDate();

export const DueDateInputRanges = [
  {
    label: strings.collections.filters.pastDueDate,
    range: value => {
      return {
        startDate: dawnOfGpac,
        endDate: moment()
          .add(Math.max(Number(value), 1) * -1, 'days')
          .toDate()
      };
    },
    getCurrentValue: range => {
      if (range.startDate !== dawnOfGpac) return '-';

      return moment().endOf('day').diff(moment(range.endDate), 'days');
    }
  }
];

const lastMonthsAndNextTwoWeeksRange = {
  label: strings.collections.filters.lastMonthsAndTwoWeeks,
  range: (): DatePeriod => ({
    startDate: moment().startOf('day').subtract(1, 'year').toDate(),
    endDate: moment().endOf('day').add(2, 'weeks').toDate()
  })
};

const nextTwoWeeksRange = {
  label: strings.collections.filters.next2weeks,
  range: (): DatePeriod => ({
    startDate: moment().startOf('day').toDate(),
    endDate: moment().endOf('day').add(2, 'weeks').toDate()
  })
};

export const AdditionalPeriodRanges = [
  Last12Months,
  lastMonthsAndNextTwoWeeksRange,
  nextTwoWeeksRange
];

const responseFormatter = response => {
  const responseData = response.data;
  return {
    data: responseData.items,
    total: responseData.totalResults,
    page: 1,
    perPage: responseData.count
  };
};

const mergeWithLocalChanges = ({ mutatedRows, rows }) => {
  const finalRows =
    mutatedRows && mutatedRows.length > 0
      ? rows.map(originalRow => {
          const mutatedRow = mutatedRows.find(item => originalRow.id === item.id);
          return mutatedRow ? { ...originalRow, ...mutatedRow.fields } : originalRow;
        })
      : rows;
  return finalRows;
};

export const updateMutatedRows = ({ prevRows, id, rowIndex, mutatedFields }) => {
  const rowWasChanged = prevRows.some(row => row.rowIndex === rowIndex);
  const finalRows = rowWasChanged
    ? prevRows.map(row =>
        row.rowIndex === rowIndex ? { ...row, fields: { ...row.fields, ...mutatedFields } } : row
      )
    : [...prevRows, { id, rowIndex, fields: mutatedFields }];

  return finalRows;
};

const initialUiState = {
  perPage: 20
};
const endpoint = Endpoints.Collections;

export const INITIAL_DATE_FILTER = CollectionDateFilters[0];

const useCollectionsListing = ({ pageKey = '' }) => {
  const [dateTypeFilter, setDateTypeFilter] = useState(INITIAL_DATE_FILTER);
  const [expandedRows, setExpandedRows] = useState([]);
  const [mutatedRows, setMutatedRows] = useState([]);
  const [uiState, setUiState] = useState({ isOpen: false, selectedId: null });

  const closeDrawer = () =>
    setUiState(prevState => ({ ...prevState, isOpen: false, selectedId: null }));

  const openDrawer = selectedId =>
    setUiState(prevState => ({ ...prevState, isOpen: true, selectedId }));

  const handleViewClick = ({ placementId }) => openDrawer(placementId);

  const handleExpansion = (curExpanded, allExpanded) => {
    setExpandedRows(allExpanded.map(row => row.index));
  };

  const handleFormCancel = (id, rowIndex) =>
    setExpandedRows(prevState => prevState.filter(row => row !== rowIndex));

  const handleSubmitSucceed = ({ id, rowIndex, mutatedFields }) => {
    setMutatedRows(prevRows => updateMutatedRows({ prevRows, id, rowIndex, mutatedFields }));
    setExpandedRows(prevState => prevState.filter(row => row !== rowIndex));
  };

  const initialPreferences = getColumnPreferences(
    pageKey,
    0,
    orderByOptions,
    ColumnsDefinitions,
    0
  );

  const defaultPeriod = lastMonthsAndNextTwoWeeksRange.range();

  const dataTable = useDatatable({
    key: pageKey,
    apiInstance: SECONDARY_API_CLIENT,
    columnsDefinitions: ColumnsDefinitions,
    endpoint,
    entityName: strings.collections.title,
    formatResponse: responseFormatter,
    initialPreferences,
    initialUiState,
    numberOfActionColumns: 0,
    orderByOptions,
    periodDefaultValue: defaultPeriod,
    periodFilterColumn: CollectionsPeriodColumnBySection,
    periodFormat: DateFormats.QueryShortFormat,
    shouldScrollOnNavigate: true
  });

  const { handleSectionChange } = dataTable;

  const handleDateTypeFilterChange = useCallback(
    (name, value) => {
      setDateTypeFilter(value);
      handleSectionChange && handleSectionChange(name, value.id);
    },
    [handleSectionChange]
  );

  const finalData = mergeWithLocalChanges({ rows: dataTable.data, mutatedRows });

  return {
    closeDrawer,
    dateTypeFilter,
    dataTable,
    defaultPeriod,
    uiState,
    finalData,
    handleExpansion,
    handleFormCancel,
    handleSubmitSucceed,
    handleViewClick,
    handleDateTypeFilterChange,
    expandedRows,
    setExpandedRows,
    setMutatedRows
  };
};

export default useCollectionsListing;
