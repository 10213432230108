import React from 'react';
import { StatsItemsCard } from 'features/command-center/components/shared';
import { getFinancialProductivityStatsSummary } from 'features/command-center/services';
import strings from 'strings';

export const FinancialProductivityStatsSummary = () => {
  return (
    <StatsItemsCard
      header={strings.commandCenter.finance.financialProductivityStatsSummary.title}
      promise={getFinancialProductivityStatsSummary}
      textAlign="center"
    />
  );
};
