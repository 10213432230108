// @flow
import React, { useEffect, useState } from 'react';
import { CircularProgress, IconButton, InputBase, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { input } from 'UI/constants/dimensions';
import { colors, SearchBarIcon } from 'UI/res';

import { useStyles } from './styles';

type SearchbarProps = {
  name: string,
  value?: ?string,
  width?: string,
  placeholder?: string,
  onChange: (name: string, value: any) => void,
  onSearch: (name: string, value: any) => any,
  onSearchAsync: () => any,
  paperProps: Record,
  showClearButton?: boolean
};

const Searchbar = ({
  name,
  onChange,
  onSearch,
  onSearchAsync,
  paperProps,
  placeholder,
  showClearButton,
  value,
  width
}: SearchbarProps) => {
  const customStyle = {
    width,
    height: input.height
  };

  const classes = useStyles();
  const [searching, setSearching] = useState(false);

  const showClear = showClearButton && !!value;

  useEffect(() => {
    const startSearch = setTimeout(() => {
      searching && onSearchAsync && onSearchAsync();
      setSearching(false);
    }, 500);
    return () => clearTimeout(startSearch);
  }, [searching, setSearching, onSearchAsync]);

  const handleSearchBoxTextChange = event => {
    const text = event.target.value;
    onChange && onChange(name, text);
    setSearching(true);
  };

  const handleSearchClick = () => {
    onSearch && onSearch(name, value);
  };

  const handleKeydown = event => {
    if (event.keyCode === 13) {
      onSearch && onSearch(name, value);
    }
  };

  const additionalProps = {};
  if (value !== null) {
    additionalProps.value = value;
  }

  const handleClearInput = () => {
    handleSearchBoxTextChange({ target: { value: '' } });
  };

  return (
    <Paper
      style={customStyle}
      className={classes.root}
      elevation={0}
      variant="outlined"
      {...paperProps}
    >
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        inputProps={{ name, 'aria-label': placeholder }}
        onChange={handleSearchBoxTextChange}
        onKeyDown={handleKeydown}
        {...additionalProps}
      />

      {showClear ? (
        <IconButton
          type="button"
          className={classes.iconButton}
          aria-label="Clear"
          onClick={handleClearInput}
        >
          <CloseIcon fill={colors.inactiveSideBarTab} />
        </IconButton>
      ) : (
        <IconButton
          type="button"
          className={classes.iconButton}
          aria-label="Search"
          onClick={handleSearchClick}
        >
          {searching ? (
            <CircularProgress size={22} />
          ) : (
            <SearchBarIcon fill={colors.inactiveSideBarTab} />
          )}
        </IconButton>
      )}
    </Paper>
  );
};

Searchbar.defaultProps = {
  width: '389px',
  value: null,
  placeholder: 'Search by',
  onChange: () => {},
  onSearch: () => {}
};

export default Searchbar;
