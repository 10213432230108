// @flow
import React from 'react';
import Divider from '@material-ui/core/Divider';
import { globalStyles } from 'GlobalStyles';
import moment from 'moment';
import strings from 'strings';
import Text from 'UI/components/atoms/Text';
import TextBox from 'UI/components/atoms/TextBox';
import EmailPicker from 'UI/components/molecules/EmailPicker';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import UserComments from 'UI/components/molecules/UserComments';
import { DateFormats } from 'UI/constants/defaults';
import { PaymentMode, PaymentModes } from 'UI/constants/entityTypes';
import { FeeAgreementFields } from 'UI/constants/status';

import FeeAgreementValidationInputs from '../FeeAgreementValidationInputs';

import { styles } from './style';

type FeeAgreementSummaryFieldsProps = {
  feeAgreement: Object,
  fields: string[]
};

const FeeAgreementSummaryFields = (props: FeeAgreementSummaryFieldsProps) => {
  const { feeAgreement, fields } = props;
  const {
    company: { name: companyName },
    company_legal_name: legalName,
    creator: {
      initials: recruiterInitials,
      personalInformation: { full_name: recruiterName }
    },
    cc_emails,
    created_at,
    notes,
    verbiage_changes,
    fee_agreement_payment_scheme_id,
    ha_email
  } = feeAgreement || {};
  const paymentMode = fee_agreement_payment_scheme_id || PaymentMode.Standard;
  const hiringAuthorityName = feeAgreement?.hiringAuthority?.full_name;
  const workEmail = feeAgreement?.hiringAuthority?.work_email;

  const emails = cc_emails
    ? cc_emails.map(each => (typeof each === 'string' ? { work_email: each } : each))
    : [];
  const areThereVerbiageChanges = !!(verbiage_changes && verbiage_changes.trim());
  const finalMode = areThereVerbiageChanges
    ? fields
    : fields.filter(each => each !== FeeAgreementFields.Verbiage);

  const haEmail = ha_email || workEmail;
  const hiringAuthority = hiringAuthorityName && `${hiringAuthorityName} / ${haEmail}`;

  return (
    <>
      <FPRadioGroup
        name="fee_agreement_payment_scheme_id"
        label={strings.feeAgreements.fields.type}
        labelVariant="subtitle1"
        options={PaymentModes}
        value={paymentMode}
        disabled
      />
      <FeeAgreementValidationInputs outPutValue fields={finalMode} paymentMode={paymentMode} />

      <>
        <TextBox
          outPutValue
          name="company"
          defaultValue={companyName}
          label={strings.shared.fields.company}
        />
        <TextBox
          outPutValue
          name="legalName"
          defaultValue={legalName}
          label={strings.shared.fields.legalName}
        />
        <div style={styles.inputSpacing}>
          <TextBox
            outPutValue
            name="hiring"
            defaultValue={hiringAuthority}
            label={strings.shared.fields.hiringAuthority}
          />
        </div>
        <EmailPicker
          name="cc_emails"
          placeholder={strings.feeAgreements.fields.ccs}
          selectedValue={emails}
          disabled
        />
        {notes && (
          <>
            <Divider style={globalStyles.mediumDivider} />

            <Text variant="subtitle1" text={strings.feeAgreements.fields.notes} />
            <UserComments
              avatarInitials={recruiterInitials}
              author={recruiterName}
              date={moment(created_at).format(DateFormats.DetailDate)}
              note={notes}
            />
          </>
        )}
      </>
    </>
  );
};

FeeAgreementSummaryFields.defaultProps = {};

export default FeeAgreementSummaryFields;
