import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenterJustifyCenter, flexAlignStart } from 'UI/utils/styles';

const CHANNEL_PARTNER_HEIGHT = 240;
const MILESTONE_ICON_SIZE = 40;

export const useStyles = makeStyles(theme => ({
  pilMapContainer: {
    ...flexAlignStart,
    gap: theme.spacing(1)
  },
  treeView: {
    padding: theme.spacing(0, 3, 0, 0)
  },
  detailsText: {
    color: theme.palette.primary.light,
    fontSize: 14,
    fontWeight: '700 !important'
  },
  linkContainer: {
    margin: theme.spacing(2, 0),
    ...flexAlignCenterJustifyCenter
  },
  arrow: {
    padding: theme.spacing(0, 0.5)
  },
  statsWrapper: {
    padding: theme.spacing(2)
  },
  statsTab: {
    overflow: 'hidden'
  },
  statsContainer: {
    height: '100%'
  },
  channelPartnerTitle: {
    width: '100%'
  },
  channelPartnerContainer: {
    width: '100%'
  },
  channelPartnerContainerOverflow: {
    maxHeight: CHANNEL_PARTNER_HEIGHT,
    overflow: 'auto'
  },
  itemSectionTitle: {
    fontWeight: '700 !important'
  },
  milestoneIconContainer: {
    width: MILESTONE_ICON_SIZE,
    height: MILESTONE_ICON_SIZE,
    borderRadius: '50%',
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(2),
    ...flexAlignCenterJustifyCenter
  }
}));
