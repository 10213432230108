import { addRingCentralContactToStore, buildMultiplePhonesActionsRenderer } from 'hooks/datatable';
import { EntityType } from 'UI/constants/entityTypes';

export const createPhonesColumns = (
  finalColumns,
  dispatch,
  config = { includeCandidatePhones: true, includeHiringAuthorityPhones: true }
) => {
  const handleCandidateAction = ({ rowData, origin }) => {
    addRingCentralContactToStore({
      rowData,
      columns: finalColumns,
      dispatch,
      role: EntityType.Candidate,
      origin
    });
  };

  const handleHiringAuthorityAction = ({ rowData, origin }) => {
    addRingCentralContactToStore({
      rowData,
      columns: finalColumns,
      dispatch,
      role: EntityType.HiringAuthority,
      origin
    });
  };

  const result = [];

  if (config.includeCandidatePhones) {
    const candidatePhonesColumn = buildMultiplePhonesActionsRenderer({
      customName: 'candidatePhones',
      handleAction: handleCandidateAction,
      label: 'Candidate Phones',
      phonesKey: 'candidatePhones',
      phoneKey: 'candidatePhone',
      columns: finalColumns,
      entityType: EntityType.Candidate
    });
    result.push({ column: candidatePhonesColumn, afterColumn: 'candidateName' });
  }

  if (config.includeHiringAuthorityPhones) {
    const hiringAuthorityPhonesColumn = buildMultiplePhonesActionsRenderer({
      customName: 'hiringAuthorityPhones',
      handleAction: handleHiringAuthorityAction,
      label: 'Hiring A. Phones',
      phonesKey: 'hiringAuthorityPhones',
      phoneKey: 'hiringAuthorityPhone',
      columns: finalColumns,
      entityType: EntityType.HiringAuthority
    });
    result.push({ column: hiringAuthorityPhonesColumn, afterColumn: 'hiringAuthorityName' });
  }

  return result;
};
