import { useEffect, useRef } from 'react';

const useEffectOnlyOnce = (callback, dependencies) => {
  const calledOnce = useRef(false);

  useEffect(() => {
    if (calledOnce.current) {
      return;
    }

    callback(dependencies);

    calledOnce.current = true;
  }, [callback, dependencies]);
};

export default useEffectOnlyOnce;
