import * as React from 'react';

const SvgMessageFilled = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M21.5 2h-19A1.5 1.5 0 001 3.5v13A1.5 1.5 0 002.5 18h4.25a.25.25 0 01.25.25v3.25a.5.5 0 00.86.35l3.78-3.78a.26.26 0 01.17-.07h9.69a1.5 1.5 0 001.5-1.5v-13A1.5 1.5 0 0021.5 2z" />
    </svg>
  );
};

export default SvgMessageFilled;
