import React from 'react';
import { Chart } from 'react-google-charts';
import { getFinanceSummary } from 'features/command-center/services';
import strings from 'strings';

import { Widget } from '../../shared';

import { DEFAULT_OPTIONS, formatResponse } from './PDAByTenure.utils';

export const PDAByTenure = ({ requestParams = {} }) => {
  return (
    <Widget
      header={strings.commandCenter.finance.PDAByTenure.title}
      promises={[
        () =>
          getFinanceSummary({
            metrics: 'perDeskAverage',
            groupBy: 'tenure',
            ...requestParams
          })
      ]}
    >
      {([response]) => {
        const data = formatResponse(response);

        return (
          <Chart
            chartType="ColumnChart"
            data={data}
            options={DEFAULT_OPTIONS}
            width="100%"
            height="400px"
            legendToggle
          />
        );
      }}
    </Widget>
  );
};
