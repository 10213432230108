// @flow
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { showAlert } from 'actions/app';
import useDebounce from 'hooks/debounce';
import API from 'services/API';
import strings from 'strings';
import Searchbar from 'UI/components/molecules/Searchbar';
import TransferList from 'UI/components/molecules/TransferList';
import { COMPANY_RELATIONSHIP_KEY } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { CompanyEntity } from 'UI/constants/entityTypes';
import { getErrorMessage } from 'UI/utils';
import { buildBasePropsForField } from 'UI/utils/forms';

import { FormFieldsMap } from '../fields';

import { useStyles } from './styles';

const NEW_COMPANY_URL = {
  siblingCompanies: Endpoints.PossibleSiblingCompanies,
  childCompanies: Endpoints.PossibleChildCompanies
};

type ChildSiblingFormProps = {
  linkType: string,
  companyId?: number
};

const ChildSiblingForm = ({ linkType, companyId }: ChildSiblingFormProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { errors, setValue, watch, register, unregister } = useFormContext();
  const formValues = watch();

  const parentCompanyId = formValues.parentCompany?.id || null;

  const debouncedKeyword = useDebounce(formValues[FormFieldsMap.Keyword.key], 500);

  const [leftPanel, setLeftPanel] = useState({
    options: [],
    title: strings.companies.creation.associated.form.childSibling.tranferList.results
  });
  const [rightPanel, setRightPanel] = useState({
    options: formValues[linkType],
    title: strings.companies.creation.associated.form.childSibling.tranferList.companiesSelected
  });

  const [keyword, setKeyword] = useState(null);

  const endpoint = companyId
    ? Endpoints.PossibleChildOrSiblingExistingCompany.replace(':id', companyId)
    : NEW_COMPANY_URL[linkType];

  useEffect(() => {
    register({ name: FormFieldsMap.ParentCompany.key });
    register({ name: linkType });
    register({ name: FormFieldsMap.CurrentCompany.key });
    return () => {
      unregister(FormFieldsMap.ParentCompany.key);
      unregister(linkType);
      unregister(FormFieldsMap.CurrentCompany.key);
    };
  }, [linkType, register, unregister]);

  const searchCompanies = async () => {
    try {
      if (!keyword) {
        setLeftPanel(prev => ({ ...prev, options: [] }));
        return;
      }

      const params = {
        parentCompanyId,
        keyword,
        perPage: 25,
        page: 1
      };

      const response = await API.get(endpoint, { apiVersion: 2, params });
      const formattedData = response.data.data.map(item => ({
        id: item.id,
        primaryText: item?.name,
        secondaryText: `${item?.specialty} / ${item?.city}, ${item?.state_slug}`,
        isSelectable: item?.can_be_added,
        disabledReason: item?.association_status || '',
        location: `${item?.country_slug}: ${item?.state}`,
        industry: `${item?.industry}: ${item?.specialty}`
      }));
      setLeftPanel(prev => ({ ...prev, options: formattedData }));
    } catch (error) {
      dispatch(
        showAlert({
          severity: 'error',
          title: CompanyEntity.title,
          autoHideDuration: 5000,
          body: getErrorMessage(error)
        })
      );
    }
  };

  const handleChange = (name, value) => setKeyword(value);
  const handleSearch = () => keyword && searchCompanies();
  const handleSearchAsync = () => searchCompanies();

  useEffect(() => {
    setValue(linkType, rightPanel.options, true);
  }, [linkType, rightPanel.options, setValue]);

  const relationshipText =
    linkType === COMPANY_RELATIONSHIP_KEY.siblingCompanies ? 'sibling' : 'child';

  return (
    <Grid container direction="column" spacing={5}>
      <Grid item>
        {formValues?.currentCompany?.name && (
          <>
            <Typography variant="subtitle1">
              Select the {relationshipText} companies of&nbsp;
              <span>
                <b>{formValues.currentCompany.name}</b>
              </span>
            </Typography>
            <br />
          </>
        )}
        <Searchbar
          {...buildBasePropsForField(FormFieldsMap.Keyword.key, errors)}
          value={debouncedKeyword}
          placeholder="Search for companies"
          width="auto"
          onChange={handleChange}
          onSearch={handleSearch}
          onSearchAsync={handleSearchAsync}
          paperProps={{ classes: { root: classes.searchbarBackground } }}
        />
      </Grid>
      <Grid item>
        <TransferList
          leftPanel={leftPanel}
          rightPanel={rightPanel}
          setLeftPanel={setLeftPanel}
          setRightPanel={setRightPanel}
        />
      </Grid>
    </Grid>
  );
};

ChildSiblingForm.defaultProps = {
  companyId: null
};

export default ChildSiblingForm;
