// @flow
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import { globalStyles } from 'GlobalStyles';
import { EntityRoutes } from 'routes/constants';
import { userHasRoles } from 'services/Authorization';
import strings from 'strings';
import CustomDatePicker from 'UI/components/atoms/CustomDatePicker';
import FPHint from 'UI/components/atoms/FPHint';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import { DateFormats, FeeAgreementDefaultValues } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { CompanyEntity, EntityType, PaymentMode, PaymentModes } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';
import { hasFeatureFlag, toLocalTime } from 'UI/utils';
import { buildBasePropsForField } from 'UI/utils/forms';
import { LabelRenderers, OptionRenderers, Selectors } from 'UI/utils/renderers';

import { FormFieldsMap } from '../fields';
import { useStyles } from '../styles';
import { PercentKeyPrefix } from '../utils';

import { buildFieldsConfig, calculateFeeAmounts } from './utils';

export type PlacementBasicSectionProps = {
  feeAgreements: any[],
  isReadOnly?: boolean,
  enableCopy?: boolean,
  sendout: any,
  isAmountEditable?: boolean,
  areAgreementChangesRequested?: boolean,
  onAgreementChange?: (agreement: any) => void
};

const companyDisplayKey = 'name';
const personDisplayKey = 'full_name';

const PlacementBasicSection = ({
  areAgreementChangesRequested,
  enableCopy,
  isReadOnly,
  feeAgreements,
  isAmountEditable,
  sendout,
  onAgreementChange
}: PlacementBasicSectionProps) => {
  const classes = useStyles();
  const location = useLocation();
  const agreement = feeAgreements?.length > 0 && feeAgreements[0];
  const hasRolesThatCanOverride = userHasRoles([
    Roles.Operations,
    Roles.RegionalDirector,
    Roles.Finance,
    Roles.Leadership
  ]);

  const { joborder, candidate } = sendout || {};
  const { company } = joborder || {};
  const { contact: companyContact = {} } = company;
  const isAmountAutomatic = !isAmountEditable;

  const { register, unregister, errors, watch, setValue, getValues, triggerValidation } =
    useFormContext();
  const formValues = watch();

  const { [FormFieldsMap.PaymentScheme.key]: paymentMode = PaymentMode.Standard } = formValues;

  const fieldsConfig = useMemo(() => buildFieldsConfig({ getFormValues: getValues }), [getValues]);

  useEffect(() => {
    fieldsConfig.forEach(({ name, type, validation }) => {
      register({ name, type }, validation);
    });

    return () => unregister(fieldsConfig.map(({ name }) => name));
  }, [register, unregister, fieldsConfig]);

  useEffect(() => {
    candidate?.sourceType && setValue(FormFieldsMap.CandidateSourceType.key, candidate.sourceType);
    joborder?.sourceType && setValue(FormFieldsMap.JobOrderSourceType.key, joborder.sourceType);
  }, [candidate, joborder, setValue]);

  useEffect(() => {
    companyContact?.phone && setValue(FormFieldsMap.CompanyPhone.key, companyContact.phone);
    companyContact?.ext && setValue(FormFieldsMap.CompanyPhoneExtension.key, companyContact.ext);
  }, [companyContact, setValue]);

  const setFeeValues = useCallback(
    (feeAgreement: any) => {
      setValue(
        FormFieldsMap.FeePercentage.key,
        feeAgreement?.fee_percentage ?? FeeAgreementDefaultValues.FeePercent
      );
      setValue(
        FormFieldsMap.GuaranteeDays.key,
        feeAgreement?.guarantee_days ?? FeeAgreementDefaultValues.GuaranteeDays
      );
      feeAgreement && setValue(FormFieldsMap.FeeAmount.key, feeAgreement.flat_fee_amount);
      feeAgreement &&
        setValue(FormFieldsMap.PaymentScheme.key, feeAgreement.fee_agreement_payment_scheme_id);
    },
    [setValue]
  );

  useEffect(() => {
    if (!agreement) {
      return;
    }
    setFeeValues(agreement);
    setValue(FormFieldsMap.FeeAgreement.key, agreement);
  }, [agreement, setFeeValues, setValue]);

  const handlePaymentChange = event => {
    const pymntMode = event.target.value;
    setValue(FormFieldsMap.PaymentScheme.key, pymntMode);
  };

  const handleFeeChange = (name?: string, value: any) => {
    if (!value) return;
    setValue(name, value);
    setFeeValues(value);
    onAgreementChange && value && onAgreementChange(value);
  };

  const handleComboChange = (name?: string, value: any) => setValue(name, value, true);

  const handleTextChange = (field: string, value: any) => {
    setValue(field, value, true);
    field.startsWith(PercentKeyPrefix) && triggerValidation('splits_valid');
  };

  const handleFeePercentChange = (field: string, value: any) => {
    setValue(field, value, true);

    if (!isAmountAutomatic) return;

    const amounts = calculateFeeAmounts({
      percent: value,
      compensation: formValues[FormFieldsMap.FirstYearCompensation.key],
      paymentMode
    });
    updateAmounts(amounts);
  };

  const handleCompensationChange = (field: string, value: any) => {
    setValue(field, value, true);

    if (!isAmountAutomatic) return;

    const amounts = calculateFeeAmounts({
      percent: formValues[[FormFieldsMap.FeePercentage.key]],
      compensation: value,
      paymentMode
    });
    updateAmounts(amounts);
  };

  const updateAmounts = ({ total, monthly }) => {
    setValue(FormFieldsMap.FeeAmount.key, total);
    setValue(FormFieldsMap.MonthlyAmount.key, monthly);
  };

  const handleSwitchToggle = (event: any, value?: any, key: string) => {
    const {
      target: { name }
    } = event;

    setValue(name, value);
    !value && setValue(key, null);
  };

  const formattedStartDate =
    formValues[FormFieldsMap.StartDate.key] &&
    toLocalTime(formValues[FormFieldsMap.StartDate.key]).format(DateFormats.SimpleDate);

  const firstYearConcept =
    paymentMode !== PaymentMode.BaseSalary
      ? strings.placements.fields.compensation
      : strings.placements.fields.salary;

  const showAgreementsSelector = feeAgreements && feeAgreements.length > 1;
  const shouldBillToOtherCompany = Boolean(formValues[FormFieldsMap.ShouldBillToOtherCompany.key]);
  const shouldRequestFeeChange = Boolean(formValues[FormFieldsMap.ShouldRequestFeeChange.key]);
  const shouldRequestGuaranteePeriodChange = Boolean(
    formValues[FormFieldsMap.ShouldRequestGuaranteePeriodChange.key]
  );

  const shouldDisableFeePercent =
    isReadOnly || (!hasRolesThatCanOverride && !shouldRequestFeeChange);
  const shouldDisableGuaranteDaysPeriod =
    isReadOnly || (!hasRolesThatCanOverride && !shouldRequestGuaranteePeriodChange);
  const shouldRenderReasonForChange =
    (shouldRequestFeeChange || shouldRequestGuaranteePeriodChange) && !hasRolesThatCanOverride;

  const shouldRenderSwitchsForChange =
    (hasFeatureFlag(FeatureFlags.PlacementRequestFeeOrGuaranteeChanges) &&
      !hasRolesThatCanOverride) ||
    (hasRolesThatCanOverride && areAgreementChangesRequested);

  const handlePhoneChange = (name: string, value: string) => setValue(name, value, true);

  return (
    <div id="plid">
      <NumberedForm.Item title="Placement Details" showTitleGutter={showAgreementsSelector}>
        {showAgreementsSelector && (
          <AutocompleteSelect
            name={FormFieldsMap.FeeAgreement.key}
            selectedValue={formValues[FormFieldsMap.FeeAgreement.key]}
            placeholder={strings.placements.fields.feeAgreement}
            getOptionLabel={LabelRenderers.feeAgreement}
            getOptionSelected={Selectors.byId}
            defaultOptions={feeAgreements}
            onSelect={handleFeeChange}
            className={classes.input}
            disabled={isReadOnly}
          />
        )}
        <FPRadioGroup
          name={FormFieldsMap.PaymentScheme.key}
          optionTextVariant="body2"
          options={PaymentModes}
          value={paymentMode}
          onChange={handlePaymentChange}
          classes={{
            root: classes.radioGroup
          }}
          disabled={isReadOnly}
        />
        <Grid container spacing={2}>
          {paymentMode !== PaymentMode.Flat && (
            <Grid item xs={12}>
              <TextBox
                {...buildBasePropsForField(FormFieldsMap.FirstYearCompensation.key, errors)}
                value={formValues[FormFieldsMap.FirstYearCompensation.key]}
                label={`${strings.placements.fields.firstYear} ${firstYearConcept} *`}
                onChange={handleCompensationChange}
                inputType="currency"
                className={classes.input}
                outPutValue={isReadOnly}
                enableCopyButton={enableCopy}
              />
            </Grid>
          )}
          {paymentMode !== PaymentMode.Flat && (
            <>
              {shouldRenderSwitchsForChange && (
                <Grid item xs={12}>
                  <FormGroup row>
                    <FormControlLabel
                      value="end"
                      control={
                        <Switch
                          id={FormFieldsMap.ShouldRequestFeeChange.key}
                          name={FormFieldsMap.ShouldRequestFeeChange.key}
                          onChange={(event, value) => {
                            handleSwitchToggle(
                              event,
                              value,
                              FormFieldsMap.ShouldRequestFeeChange.key
                            );
                            !value &&
                              setValue(
                                FormFieldsMap.FeePercentage.key,
                                agreement?.fee_percentage ?? FeeAgreementDefaultValues.FeePercent
                              );
                          }}
                          checked={shouldRequestFeeChange}
                          color="primary"
                          disabled={isReadOnly}
                        />
                      }
                      label={strings.placements.fields.requestFeeChangePrompt}
                      labelPlacement="end"
                    />
                  </FormGroup>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextBox
                  {...buildBasePropsForField(FormFieldsMap.FeePercentage.key, errors)}
                  label={`${strings.placements.fields.feePercent} *`}
                  value={formValues[FormFieldsMap.FeePercentage.key]}
                  onChange={handleFeePercentChange}
                  inputType="percentage"
                  className={classes.input}
                  outPutValue={shouldDisableFeePercent}
                  enableCopyButton={enableCopy}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <TextBox
              {...buildBasePropsForField(FormFieldsMap.FeeAmount.key, errors)}
              label={`${strings.placements.fields.fullFeeAmount} *`}
              value={formValues[FormFieldsMap.FeeAmount.key]}
              onChange={handleTextChange}
              inputType="currency"
              className={classes.input}
              outPutValue={paymentMode === PaymentMode.Flat ? isReadOnly : !isAmountEditable}
              enableCopyButton={enableCopy}
            />
          </Grid>
          {shouldRenderSwitchsForChange && (
            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  value="end"
                  control={
                    <Switch
                      id={FormFieldsMap.ShouldRequestGuaranteePeriodChange.key}
                      name={FormFieldsMap.ShouldRequestGuaranteePeriodChange.key}
                      onChange={(event, value) => {
                        handleSwitchToggle(
                          event,
                          value,
                          FormFieldsMap.ShouldRequestGuaranteePeriodChange.key
                        );
                        !value &&
                          setValue(
                            FormFieldsMap.GuaranteeDays.key,
                            agreement?.guarantee_days ?? FeeAgreementDefaultValues.GuaranteeDays
                          );
                      }}
                      checked={shouldRequestGuaranteePeriodChange}
                      color="primary"
                      disabled={isReadOnly}
                    />
                  }
                  label={strings.placements.fields.requestGuaranteeDaysChangePrompt}
                  labelPlacement="end"
                />
              </FormGroup>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextBox
              {...buildBasePropsForField(FormFieldsMap.GuaranteeDays.key, errors)}
              label={`${strings.placements.fields.guaranteeDays} *`}
              value={formValues[FormFieldsMap.GuaranteeDays.key]}
              onChange={handleTextChange}
              inputType="number"
              className={classes.input}
              outPutValue={shouldDisableGuaranteDaysPeriod}
              enableCopyButton={enableCopy}
            />
          </Grid>
          {shouldRenderReasonForChange && (
            <Grid item xs={12}>
              <TextBox
                {...buildBasePropsForField(FormFieldsMap.ReasonForRequestChange.key, errors)}
                label={`${strings.placements.fields.reasonForRequestChange} *`}
                value={formValues[FormFieldsMap.ReasonForRequestChange.key]}
                onChange={handleTextChange}
                className={classes.input}
                outPutValue={isReadOnly}
                multiline
              />
            </Grid>
          )}
          {shouldRenderSwitchsForChange && <Divider style={globalStyles.mediumDivider} />}
          {paymentMode === PaymentMode.Conversion && (
            <>
              <Grid item xs={6}>
                <TextBox
                  {...buildBasePropsForField(FormFieldsMap.MonthlyAmount.key, errors)}
                  label={`${strings.placements.fields.monthlyAmount} *`}
                  value={formValues[FormFieldsMap.MonthlyAmount.key]}
                  onChange={handleTextChange}
                  inputType="currency"
                  className={classes.input}
                  outPutValue={isReadOnly}
                  enableCopyButton={enableCopy}
                />
              </Grid>
              <Grid item xs={6}>
                <TextBox
                  name={FormFieldsMap.ServiceMonths.key}
                  label={strings.placements.fields.serviceMonths}
                  value={formValues[FormFieldsMap.ServiceMonths.key]}
                  onChange={handleTextChange}
                  inputType="number"
                  className={classes.input}
                  outPutValue={isReadOnly}
                  enableCopyButton={enableCopy}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            {isReadOnly && enableCopy ? (
              <TextBox
                name={FormFieldsMap.StartDate.key}
                label={`${strings.placements.fields.startDate} *`}
                value={formattedStartDate}
                className={classes.input}
                outPutValue
                enableCopyButton
              />
            ) : (
              <CustomDatePicker
                name={FormFieldsMap.StartDate.key}
                label={`${strings.placements.fields.startDate} *`}
                placeholder={`${strings.placements.fields.startDate} *`}
                value={formValues[FormFieldsMap.StartDate.key]}
                onDateChange={handleTextChange}
                error={!!errors.start_date}
                helperText={errors.start_date?.message}
                className={classes.input}
                disabled={isReadOnly}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <AutocompleteSelect
              {...buildBasePropsForField(FormFieldsMap.HiringAuthority.key, errors)}
              selectedValue={formValues[FormFieldsMap.HiringAuthority.key]}
              placeholder={`${strings.placements.fields.hiringAuthority} *`}
              displayKey={personDisplayKey}
              url={Endpoints.Search}
              typeahead
              typeaheadLimit={25}
              typeaheadParams={{
                entityType: EntityType.HiringAuthority
              }}
              getOptionSelected={Selectors.byId}
              renderOption={OptionRenderers.globalSearchPerson(personDisplayKey)}
              onSelect={handleComboChange}
              className={classes.input}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              label={strings.placements.fields.jobOrder}
              value={joborder?.title || ''}
              className={classes.input}
              outPutValue
              enableCopyButton={enableCopy}
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteSelect
              {...buildBasePropsForField(FormFieldsMap.JobOrderSourceType.key, errors)}
              selectedValue={formValues[FormFieldsMap.JobOrderSourceType.key]}
              placeholder={`${strings.placements.fields.jobOrderSource} *`}
              url={Endpoints.JobOrderSourceTypes}
              onSelect={handleComboChange}
              className={classes.input}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              label={strings.placements.fields.candidate}
              value={candidate?.personalInformation?.full_name || ''}
              className={classes.input}
              outPutValue
              enableCopyButton={enableCopy}
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteSelect
              {...buildBasePropsForField(FormFieldsMap.CandidateSourceType.key, errors)}
              selectedValue={formValues[FormFieldsMap.CandidateSourceType.key]}
              placeholder={`${strings.placements.fields.candidateDataSource} *`}
              url={Endpoints.CandidateSourceTypes}
              onSelect={handleComboChange}
              className={classes.input}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              label={strings.placements.fields.billToCompany}
              value={company?.name || ''}
              className={classes.input}
              outPutValue
              enableCopyButton={enableCopy}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              {...buildBasePropsForField(FormFieldsMap.CompanyPhone.key, errors)}
              value={formValues[FormFieldsMap.CompanyPhone.key]}
              label={`${strings.placements.fields.companyPhone} *`}
              placeholder="(999)-999-9999"
              onChange={handlePhoneChange}
              inputType="phone"
              className={classes.input}
              outPutValue={isReadOnly}
              enableCopyButton={enableCopy}
            />
          </Grid>
          <Grid item xs={6}>
            <TextBox
              {...buildBasePropsForField(FormFieldsMap.CompanyPhoneExtension.key, errors)}
              value={formValues[FormFieldsMap.CompanyPhoneExtension.key]}
              label={`${strings.placements.fields.companyPhoneExtension}`}
              placeholder="999"
              onChange={handlePhoneChange}
              className={classes.input}
              outPutValue={isReadOnly}
              enableCopyButton={enableCopy}
            />
          </Grid>
          <Grid item xs={12}>
            <FPHint
              variant="warning"
              size="md"
              description={strings.placements.fields.billToCompanyHint}
            />
            <FormGroup row>
              <FormControlLabel
                value="end"
                control={
                  <Switch
                    id={FormFieldsMap.ShouldBillToOtherCompany.key}
                    name={FormFieldsMap.ShouldBillToOtherCompany.key}
                    onChange={(event, value) => {
                      handleSwitchToggle(event, value, FormFieldsMap.BillToCompany.key);
                    }}
                    checked={shouldBillToOtherCompany}
                    color="primary"
                  />
                }
                label={strings.placements.fields.billToCompanyPrompt}
                labelPlacement="end"
              />
            </FormGroup>
          </Grid>
          {shouldBillToOtherCompany && (
            <Grid item xs={12}>
              <AutocompleteSelect
                {...buildBasePropsForField(FormFieldsMap.BillToCompany.key, errors)}
                selectedValue={formValues[FormFieldsMap.BillToCompany.key]}
                displayKey={companyDisplayKey}
                placeholder={strings.placements.fields.billToCompanyPlaceholder}
                renderOption={OptionRenderers.globalCompanySearch({
                  displayKey: companyDisplayKey,
                  chipBaseClass: 'chip-company-type'
                })}
                getOptionSelected={Selectors.byId}
                createButton={{
                  concatKeyword: true,
                  location,
                  openInNewTab: true,
                  text: strings.placements.fields.billToCompanyCreatePrompt,
                  url: EntityRoutes.CompanyCreate
                }}
                typeahead
                typeaheadLimit={50}
                typeaheadParams={{ entityType: CompanyEntity.id, isForConvertion: true }}
                url={Endpoints.Search}
                onSelect={handleComboChange}
                className={classes.input}
                disabled={isReadOnly}
              />
            </Grid>
          )}
        </Grid>
      </NumberedForm.Item>
    </div>
  );
};

PlacementBasicSection.defaultProps = {
  areAgreementChangesRequested: false,
  isReadOnly: false,
  enableCopy: false,
  isAmountEditable: false,
  onAgreementChange: undefined
};
export default PlacementBasicSection;
