// @flow

import { Roles } from 'UI/constants/roles';
import { FeeAgreementStatus } from 'UI/constants/status';

const guards = {
  ownership: ({ agreement, user }) => agreement.creator.id === user.id,
  coachOwnership: ({ agreement, user }) =>
    agreement.coach.id === user.id || agreement.regional?.id === user.id,
  teamOwnership: ({ agreement, user }) =>
    agreement.creator.id === user.id ||
    agreement.coach.id === user.id ||
    agreement.regional?.id === user.id,
  isDeclining: ({ uiState }) => uiState.isDeclining,
  hasSigningUrl: ({ agreement }) => !!agreement.sign_url,
  hasFileUrl: ({ agreement }) => !!agreement.pdf_url
};

const commonActions = {
  forCoachAndRegionalWhenPendingOps: {
    actions: [{ id: 'cancel', guard: guards.teamOwnership }]
  },
  forCoachAndRegionalWhenDeclinedByOps: {
    primary: 'revalidate',
    guard: guards.ownership,
    actions: [{ id: 'cancel', guard: guards.coachOwnership }]
  }
};

export const rules = {
  [FeeAgreementStatus.Signed]: {
    [Roles.Recruiter]: 'view',
    [Roles.Coach]: 'view',
    [Roles.RegionalDirector]: 'view',
    [Roles.ProductionDirector]: 'view',
    [Roles.Operations]: {
      primary: 'update',
      actions: ['view']
    },
    [Roles.DataCoordinator]: 'view',
    [Roles.Staff]: 'view',
    [Roles.Leadership]: 'view',
    [Roles.Finance]: 'view'
  },
  [FeeAgreementStatus.PendingOpsValidation]: {
    [Roles.Recruiter]: { actions: [{ id: 'cancel', guard: guards.ownership }] },
    [Roles.Coach]: commonActions.forCoachAndRegionalWhenPendingOps,
    [Roles.RegionalDirector]: commonActions.forCoachAndRegionalWhenPendingOps,
    [Roles.Operations]: {
      primary: { id: 'approve', alternate: { id: 'declining', guard: guards.isDeclining } },
      actions: ['decline', 'cancel']
    }
  },
  [FeeAgreementStatus.DeclinedByOperations]: {
    [Roles.Recruiter]: {
      primary: 'revalidate',
      guard: guards.ownership,
      actions: ['cancel']
    },
    [Roles.Coach]: commonActions.forCoachAndRegionalWhenDeclinedByOps,
    [Roles.RegionalDirector]: commonActions.forCoachAndRegionalWhenDeclinedByOps,
    [Roles.Operations]: { actions: ['cancel'] }
  }
};
