// @flow
import { DateFormats } from 'UI/constants/defaults';
import { currencyFormatter, formatPhoneNumber, toLocalTime } from 'UI/utils';

export const LogType = Object.freeze({
  Currency: 'currency',
  Date: 'date',
  DateList: 'dateList',
  Html: 'html',
  Percentage: 'percentage',
  Phone: 'phone',
  Text: 'text'
});

export const Operations = Object.freeze({
  Update: 'update'
});

export const formatText = {
  dateWithoutHours: (value: string) => toLocalTime(value).format(DateFormats.SimpleDate),
  date: (value: string) => toLocalTime(value).format(DateFormats.SimpleDateTime),
  percentage: (value: number) => `${value / 100}%`,
  currency: (value: number) => currencyFormatter(value, 2),
  phone: (value: string) => (value && value.trim() !== '' ? formatPhoneNumber(value) : ''),
  text: (value: string) => value
};
