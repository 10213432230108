import * as React from 'react';

const SvgVerticalMenu = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <g>
        <circle cx={12} cy={3.25} r={3.25} />
        <circle cx={12} cy={12} r={3.25} />
        <circle cx={12} cy={20.75} r={3.25} />
      </g>
    </svg>
  );
};

export default SvgVerticalMenu;
