// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { splitTextByDotAndUnderscore } from 'UI/utils';

import { useStyles } from '../styles';

type LabelProps = {
  label: string,
  split: boolean
};
const Label = ({ label, split }: LabelProps) => {
  const classes = useStyles();

  return label ? (
    <Typography variant="body1" classes={{ body1: classes.textLight }} gutterBottom>
      {split ? splitTextByDotAndUnderscore(label) : label}:&nbsp;
    </Typography>
  ) : null;
};

export default Label;
