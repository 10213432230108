// @flow

/**
 * Checks if the selected hiring authorities are valid.
 * To consider valid selections, items can not have the same full_name and same emails
 * @param {Object[]} array - The array of hirings to check.
 * @returns {boolean} - Returns true if valid items were selected, otherwise false.
 */

export const hasValidHiringAuthoritiesSelected = (hiringAuthorities: Object[]): boolean => {
  const hasDuplicateFullNames = hiringAuthorities
    .map(item => item.full_name)
    .some((fullName, index, array) => array.indexOf(fullName) !== index);

  if (hasDuplicateFullNames) {
    const hasDuplicateWorkEmails = hiringAuthorities
      .map(item => item.work_email)
      .some((workEmail, index, array) => array.indexOf(workEmail) !== index && workEmail !== '');

    const hasWorkEmailsInPersonalEmails = hiringAuthorities
      .map(item => item.work_email)
      .some(
        workEmail =>
          workEmail !== '' && hiringAuthorities.some(item => item.personal_email === workEmail)
      );

    const hasPersonalEmailsInWorkEmails = hiringAuthorities
      .map(item => item.personal_email)
      .some(
        personalEmail =>
          personalEmail !== '' && hiringAuthorities.some(item => item.work_email === personalEmail)
      );

    const hasDuplicatePersonalEmails = hiringAuthorities
      .map(item => item.personal_email)
      .some(
        (personalEmail, index, array) =>
          array.indexOf(personalEmail) !== index && personalEmail !== ''
      );

    if (
      hasDuplicateWorkEmails ||
      hasWorkEmailsInPersonalEmails ||
      hasPersonalEmailsInWorkEmails ||
      hasDuplicatePersonalEmails
    ) {
      // Duplicates found in work_email, work_email in personal_email, personal_email in work_email, or personal_email
      return false;
    }
    return true;
  }

  return true;
};
