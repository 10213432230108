import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  hvrPulse: {
    cursor: 'pointer',
    '&:hover': {
      animationName: 'hvr-pulse',
      animation: 'hvr-pulse',
      animationDuration: '1.75s',
      animationIterationCount: 'infinite',
      border: `2px solid ${theme.palette.customColors.white}`
    }
  }
}));
