import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    '& > *': {
      width: '100%'
    }
  },
  select: {
    marginTop: theme.spacing(2)
  },
  details: {
    '& > *': {
      width: '50%',
      paddingRight: theme.spacing(2),
      margin: theme.spacing(1, 0, 2)
    }
  },
  createButtonEmail: {
    color: theme.palette.primary.main
  }
}));
