import { makeStyles } from '@material-ui/core/styles';

export const useTableStyles = makeStyles(
  theme => ({
    denseCell: {
      '& .MuiTableCell-root': {
        padding: theme.spacing(1.5, 1)
      }
    }
  }),
  { classNamePrefix: 'collectionsList' }
);

export const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: theme.spacing(1),
    whiteSpace: 'pre-wrap'
  }
}));
