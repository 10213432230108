import isNil from 'lodash/isNil';
import moment from 'moment';
import strings from 'strings';
import { DateFormats } from 'UI/constants/defaults';

const CANDIDATES_KEY_ORDER = ['total', 'alpha', 'poejo', 'cantHelp'];

const JOB_ORDERS_KEY_ORDER = ['total', 'searchAssignment', 'poejo', 'cantHelp'];

const NAMES_KEY_ORDER = [
  'total',
  'candidateProspect',
  'hiringAuthorityProspect',
  'undefinedProspect'
];

const COMPANIES_KEY_ORDER = ['total', 'signed', 'client', 'notSigned'];

const HIRING_AUTHORITIES_KEY_ORDER = ['total', 'signed', 'client', 'notSigned'];

const sortByKeyOrder = (keyOrder, data) =>
  keyOrder.reduce((acc, key) => {
    if (!isNil(data[key])) {
      acc[key] = data[key];
    }
    return acc;
  }, {});

export const formatStats = ({ data, drawerHandlers }) => {
  const candidates = Object.keys(sortByKeyOrder(CANDIDATES_KEY_ORDER, data?.candidates ?? {})).map(
    key => {
      const value = data.candidates[key]?.total;
      if (isNil(value)) return null;
      return {
        title: strings.commandCenter.metricsTitles.candidates[key],
        value,
        ...(drawerHandlers.candidates && data.candidates[key]?.url
          ? {
              onClick: () => drawerHandlers.candidates[key]({ url: data.candidates[key]?.url })
            }
          : {})
      };
    }
  );

  const jobOrders = Object.keys(sortByKeyOrder(JOB_ORDERS_KEY_ORDER, data?.jobOrders ?? {})).map(
    key => {
      const value = data.jobOrders[key]?.total;
      if (isNil(value)) return null;
      return {
        title: strings.commandCenter.metricsTitles.jobOrders[key],
        value,
        ...(drawerHandlers.jobOrders && data.jobOrders[key]?.url
          ? {
              onClick: () => drawerHandlers.jobOrders[key]({ url: data.jobOrders[key]?.url })
            }
          : {})
      };
    }
  );

  const names = Object.keys(sortByKeyOrder(NAMES_KEY_ORDER, data?.names ?? {})).map(key => {
    const value = data.names[key]?.total;
    if (isNil(value)) return null;
    return {
      title: strings.commandCenter.metricsTitles.names[key],
      value,
      ...(drawerHandlers.names && data.names[key]?.url
        ? {
            onClick: () => drawerHandlers.names[key]({ url: data.names[key]?.url })
          }
        : {})
    };
  });

  const companies = Object.keys(sortByKeyOrder(COMPANIES_KEY_ORDER, data?.companies ?? {})).map(
    key => {
      const value = data.companies[key]?.total;
      if (isNil(value)) return null;
      return {
        title: strings.commandCenter.metricsTitles.companies[key],
        value,
        ...(drawerHandlers.companies && data.companies[key]?.url
          ? {
              onClick: () => drawerHandlers.companies[key]({ url: data.companies[key]?.url })
            }
          : {})
      };
    }
  );

  const hiringAuthorities = Object.keys(
    sortByKeyOrder(HIRING_AUTHORITIES_KEY_ORDER, data?.hiringAuthorities ?? {})
  ).map(key => {
    const value = data.hiringAuthorities[key]?.total;
    if (isNil(value)) return null;
    return {
      title: strings.commandCenter.metricsTitles.hiringAuthorities[key],
      value,
      ...(drawerHandlers.hiringAuthorities && data.hiringAuthorities[key]?.url
        ? {
            onClick: () =>
              drawerHandlers.hiringAuthorities[key]({ url: data.hiringAuthorities[key]?.url })
          }
        : {})
    };
  });

  return {
    jobOrders: jobOrders.filter(item => item?.title),
    candidates: candidates.filter(item => item?.title),
    companies: companies.filter(item => item?.title),
    hiringAuthorities: hiringAuthorities.filter(item => item?.title),
    names: names.filter(item => item?.title)
  };
};

export const getLastThirtyDays = () => {
  return {
    startDate: moment().subtract(30, 'day').startOf('day').format(DateFormats.QueryFormat),
    endDate: moment().endOf('day').format(DateFormats.QueryFormat)
  };
};
