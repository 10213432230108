import { useCallback, useEffect, useState } from 'react';
import { getConversation, sendMessageToServer } from 'features/message-center';
import moment from 'moment';
import { useUserContext } from 'providers/UserProvider';
import { DateFormats } from 'UI/constants/defaults';
import { UIStatus } from 'UI/constants/status';
import { getErrorMessage } from 'UI/utils';

import { MESSAGE_STATUS } from '../components/messageChat/chat.constants';
import {
  addMessageToChat,
  groupMessagesByDate,
  mergeMessages,
  updateMessageStatus
} from '../components/messageChat/chat.utils';

const DEFAULT_PARAMS = {
  limit: 20
};

export const useChat = ({ externalNumber = '' }) => {
  const [user] = useUserContext();
  const [conversation, setConversation] = useState(null);
  const [chatStatus, setChatStatus] = useState(UIStatus.Default);
  const [triggerScrollToBottom, setTriggerScrollToBottom] = useState(false);

  const internalNumber = `+1${user?.personalInformation?.contact?.phone}`;
  const hasMoreMessages = !!conversation?.lastItem?.date;

  const fetchInitialConversation = useCallback(async () => {
    if (!externalNumber) return;

    setChatStatus(UIStatus.Loading);
    setConversation(null);

    try {
      const response = await getConversation({
        ...DEFAULT_PARAMS,
        internalNumber,
        externalNumber
      });
      const formattedMessages = response.messages.map(item => ({
        ...item,
        attachment: { url: item?.attachments[0], size: item?.attachmentSize }
      }));
      const messages = groupMessagesByDate(formattedMessages);

      setConversation({ ...response, messages });
      setChatStatus(UIStatus.Success);
      setTriggerScrollToBottom(prev => !prev);
    } catch (error) {
      setChatStatus(UIStatus.Error);
    }
  }, [externalNumber, internalNumber]);

  useEffect(() => {
    fetchInitialConversation();

    return () => {
      setConversation(null);
      setChatStatus(UIStatus.Default);
      setTriggerScrollToBottom(false);
    };
  }, [fetchInitialConversation]);

  const updateStatus = (status, messageToUpdate, error) => {
    setConversation(prev => ({
      ...prev,
      messages: updateMessageStatus({ chat: prev.messages, messageToUpdate, status, error })
    }));
  };

  const buildErrorFromException = exception => {
    const statusCode = exception?.response?.status;
    const message = getErrorMessage(exception);
    const err = statusCode && message ? { statusCode, message } : null;
    return err;
  };

  const handleClickSend = async ({ message, attachment, from, to, contact }) => {
    const date = moment.utc().local();
    const day = date.format(DateFormats.SimpleDate);
    const newId = conversation?.messages?.[day] ? conversation.messages[day].length + 1 : 1;

    const newMessage = {
      id: newId,
      attachment,
      contact,
      date,
      day,
      from,
      isOutbound: true,
      message,
      status: MESSAGE_STATUS.sending,
      to
    };
    setConversation(prev => ({ ...prev, messages: addMessageToChat(prev.messages, newMessage) }));
    setTriggerScrollToBottom(prev => !prev);

    try {
      await sendMessageToServer(newMessage);
      updateStatus(MESSAGE_STATUS.success, newMessage);
    } catch (error) {
      const err = buildErrorFromException(error);
      updateStatus(MESSAGE_STATUS.error, newMessage, err);
    }
  };

  const handleClickRetry = async message => {
    try {
      updateStatus(MESSAGE_STATUS.sending, message);
      await sendMessageToServer(message);
      updateStatus(MESSAGE_STATUS.success, message);
    } catch (error) {
      const err = buildErrorFromException(error);
      updateStatus(MESSAGE_STATUS.error, message, err);
    }
  };

  const handleFetchMoreMessages = async () => {
    if (chatStatus === UIStatus.Loading || !conversation?.lastItem) {
      return;
    }

    try {
      setChatStatus(UIStatus.Loading);
      const params = {
        ...DEFAULT_PARAMS,
        internalNumber,
        externalNumber,
        lastItemDate: conversation.lastItem.date,
        lastItemInternalExternalNumbers: conversation.lastItem.internalExternalNumbers
      };

      const response = await getConversation(params);

      const messages = mergeMessages({
        originalChat: conversation.messages,
        messages: response.messages
      });
      setConversation(prev => ({
        ...prev,
        messages,
        lastItem: response?.lastItem ?? null
      }));
      setChatStatus(UIStatus.Success);
    } catch (error) {
      setChatStatus(UIStatus.Error);
    }
  };

  const handleRefreshConversation = async () => {
    await fetchInitialConversation();
  };

  return {
    chat: conversation?.messages ?? {},
    chatStatus,
    handleClickRetry,
    handleClickSend,
    handleFetchMoreMessages,
    handleRefreshConversation,
    hasMoreMessages,
    triggerScrollToBottom
  };
};
