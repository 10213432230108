import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  details: {
    margin: theme.spacing(0.5, 0, 0.5, 0)
  },
  link: {
    marginTop: theme.spacing(1)
  }
}));
