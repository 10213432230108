import React, { useMemo, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { THEME } from 'GlobalStyles';
import strings from 'strings';
import FPIcon from 'UI/components/atoms/FPIcon';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import PhoneActionRow from 'UI/components/atoms/PhoneNumbersDropdown/PhoneActionRow';
import { getPhonesWithId, PHONE_TYPE_MAP } from 'UI/components/organisms/PhoneNumbersForm/utils';
import { SvgMessageFilled } from 'UI/res';

const ICON_COLOR = THEME.palette.grey[600];
const DISABLED_COLOR = THEME.palette.grey[300];

export const PhoneNumbersMenu = ({ contact, onPhoneClick }) => {
  const phones = contact.phones || [];
  const phone = contact.phone || contact.mobile || null;

  const [anchorEl, setAnchorEl] = useState(null);

  const uniquePhonesWithIds = useMemo(() => getPhonesWithId(phones), [phones]);

  const mainPhone =
    uniquePhonesWithIds.length > 0
      ? uniquePhonesWithIds[0]
      : { phone, use: null, type_id: null, country_code: '+1' };

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleOnPhoneClick = phoneItem => {
    onPhoneClick({ contact, selectedPhone: phoneItem });
    handleClose();
  };

  return (
    <div>
      <FPIconButton
        tooltipProps={{ title: strings.messageCenter.newConversation.sendMessage }}
        onClick={handleClick}
        disabled={!mainPhone?.phone}
      >
        <FPIcon
          component={SvgMessageFilled}
          color={mainPhone?.phone ? ICON_COLOR : DISABLED_COLOR}
        />
      </FPIconButton>
      <Menu
        id="phones-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {uniquePhonesWithIds.map(item => (
          <MenuItem key={item.id}>
            <PhoneActionRow
              phone={item?.phone}
              type={PHONE_TYPE_MAP[item?.type_id]}
              isDefault={item?.is_default}
              use={item?.use}
              onPhoneClick={() => handleOnPhoneClick(item)}
              hidePhone
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
