import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenterSpaceBetween } from 'UI/utils/styles';

export const useStyles = makeStyles(
  theme => ({
    flexAlignCenterSpaceBetween,
    gaugeChartContainer: {
      position: 'relative',
      paddingTop: theme.spacing(1),
      margin: 'auto'
    },

    textCenter: {
      textAlign: 'center'
    }
  }),
  { classNamePrefix: 'gauge-stats' }
);
