// @flow
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useUserContext } from 'providers/UserProvider';
import type { Filters } from 'types/app';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag, loadFromLocalStorage } from 'UI/utils';

import {
  PAGE_COACH_KEY,
  PREPEND_OPTIONS_ALL
} from '../../layout/DominateSheetLayout/components/SelectTeamCard';
import { getRegionalInitialState } from '../../layout/DominateSheetLayout/DominateSheetLayout.utils';

interface InlineFiltersProviderProps {
  children: ReactNode;
}

interface InlineContextProps {
  filters: Filters[];
  getFilters: () => Filters[];
  handleFiltersChange: (filters: Filters[]) => void;
}

const InlineFiltersContext = createContext<InlineContextProps | null>(null);

export const useInlineFiltersContext = () => {
  const context = useContext(InlineFiltersContext);
  if (!context) {
    throw new Error('useFiltersContext must be used within a FiltersProvider');
  }
  return context;
};

export const InlineFiltersProvider = ({ children }: InlineFiltersProviderProps) => {
  const [currentUser] = useUserContext();
  const areMultipleDivisionsEnable = hasFeatureFlag(FeatureFlags.HomeMultipleRegions);
  const defaultCoachValue = loadFromLocalStorage(PAGE_COACH_KEY, null) || PREPEND_OPTIONS_ALL[0];

  const [filters, setFilters] = useState<Filters>({
    ...(areMultipleDivisionsEnable && getRegionalInitialState(currentUser)
      ? {
          regional: {
            paramName: 'regionalIds',
            value: getRegionalInitialState(currentUser)
          }
        }
      : {}),

    ...(defaultCoachValue?.id
      ? {
          coach: {
            paramName: 'coachIds',
            value: defaultCoachValue
          }
        }
      : {})
  });

  const contextValue = useMemo(() => {
    const handleFiltersChange = (newFilters: Filters) => {
      setFilters(newFilters);
    };

    const getFilters = () => filters;

    return {
      filters,
      getFilters,
      handleFiltersChange
    };
  }, [filters]);

  return (
    <InlineFiltersContext.Provider value={contextValue}>{children}</InlineFiltersContext.Provider>
  );
};
