import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';
import {
  flexAlignCenterFlexStart,
  flexAlignStart,
  flexAlignStartJustifyStart
} from 'UI/utils/styles';

export const useStyles = makeStyles(
  theme => ({
    recruiterAvatarsWrapper: {
      position: 'relative',
      gap: THEME.spacing(1),
      ...flexAlignStartJustifyStart
    },
    avatarsContainer: {
      gap: THEME.spacing(1),
      ...flexAlignCenterFlexStart
    },
    buttonPosition: {
      marginTop: theme.spacing(0.9)
    },
    recruiterDetailsCard: {
      position: 'absolute',
      opacity: 0.95,
      minWidth: 250,
      zIndex: theme.zIndex.profileRecruiterView,
      top: theme.spacing(6),
      backgroundColor: theme.palette.grey.black,
      color: theme.palette.brandColors.FPWhite,
      '& .MuiTypography-body2': {
        fontWeight: theme.typography.fontWeightRegular
      },
      '&  .MuiTypography-overline': {
        color: theme.palette.brandColors.FPWhite,
        fontFamily: theme.typography.fontFamilyContent
      }
    },
    recruiterWrapper: {
      ...flexAlignStart,
      gap: theme.spacing(1)
    },
    recruiterType: {
      marginLeft: theme.spacing(6)
    },
    recruiterContainer: {
      margin: theme.spacing(0, 0, 1)
    },
    comunicationActions: {
      '& > div': {
        justifyContent: 'flex-start',
        gap: theme.spacing(1)
      }
    },
    addButton: {
      margin: theme.spacing(0, 1),
      border: '1px dashed'
    }
  }),
  { classNamePrefix: 'recruitersCard' }
);
