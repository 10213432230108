import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(1.5),
    ...flexAlignCenterJustifyCenter
  },
  periodContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    ...flexAlignCenterJustifyCenter
  },
  periodText: {
    color: theme.palette.grey[600],
    fontSize: 10,
    fontWeight: '500 !important',
    textTransform: 'uppercase'
  },
  valueText: {
    color: ({ isAmountPositive }) =>
      isAmountPositive ? theme.palette.primary.light : theme.palette.error.light,
    fontFamily: '"Kanit", sans-serif',
    fontSize: 24,
    fontWeight: '500 !important'
  }
}));
