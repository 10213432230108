// @flow
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { UserProviderContext } from 'providers/UserProvider';
import { getCancelToken } from 'services/API';
import { getJsonSchema } from 'services/JsonSchema';
import strings from 'strings';
import { FormSchema } from 'UI/constants/entityTypes';
import { getErrorMessage } from 'UI/utils';

import { Mode } from '../ReferenceCheckDrawer.types';
import { DrawerMode, QUESTIONS_PATHS } from '../ReferenceCheckTabLayout.constants';
import {
  getSchemasByReferenceId,
  saveReference,
  updateReference
} from '../ReferenceCheckTabLayout.services';
import { getAnsweredQuestionsCount } from '../utils';

type ReturnValue = [
  {
    placeHolders: {
      referenceName: string,
      candidateName: string,
      recruiterName: string
    },
    formData: Object,
    saveButtonRef: Object,
    isFetching: boolean,
    errorMessage: string,
    fetchedSchemas: Object
  },
  {
    handleSubmit: (data: Object) => void,
    handleGetSchemas: (data: Object) => void,
    handleFormChange: (data: Object, id: string) => void,
    handleUpdate: (data: Object) => void,
    handleResetForm: () => void
  }
];

type Params = {
  candidateId: string,
  candidateName: string,
  recruiterName: string,
  referenceId: string,
  mode: Mode,
  onSaveSuccess: () => void,
  onSave: () => void,
  onSaveError: () => void
};

const REFERENCE_CHECK_ADDED_SUCCESSFULLY =
  strings.inventoryProfiles.sections.tabs.referenceCheck.drawer.added;
const REFERENCE_CHECK_UPDATED_SUCCESSFULLY =
  strings.inventoryProfiles.sections.tabs.referenceCheck.drawer.updated;

const getInitials = (fullName: string) => {
  const names = fullName.split(' ');
  return names.map(name => name.charAt(0).toUpperCase()).join('');
};

const useReferenceCheckForm = ({
  candidateId,
  referenceId,
  candidateName,
  recruiterName,
  mode,
  onSave,
  onSaveSuccess,
  onSaveError
}: Params): ReturnValue => {
  const fetchedSchemas = useRef(null);
  const schemaVersion = useRef(null);
  const formData = useRef({});
  const saveButtonRef = useRef(null);
  const [referenceName, setReferenceName] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [currentUser] = useContext(UserProviderContext);

  const dispatch = useDispatch();
  const cancelToken = useRef();

  const displayError = useCallback(
    error => {
      dispatch(
        showAlert({
          severity: 'error',
          title: 'Error',
          body: getErrorMessage(error)
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (mode === DrawerMode.Create || !mode) {
      formData.current = {};
      setReferenceName('');
    }

    const getReferenceCheckSchemas = () => {
      setIsFetching(true);
      getJsonSchema(FormSchema.ReferenceCheck)
        .then(response => {
          if (response.status === HTTPStatusCodes.Ok) {
            fetchedSchemas.current = response.data;
            schemaVersion.current = response.data.version;
          }
        })
        .catch(error => {
          displayError(error);
        })
        .finally(() => {
          setIsFetching(false);
        });
    };

    const getSchemasFromAPI = () => {
      if (!mode) return;
      if (mode === DrawerMode.Create) {
        getReferenceCheckSchemas();
        return;
      }

      if (!referenceId) return;

      cancelToken.current && cancelToken.current.cancel(strings.shared.requests.cancelTokenMessage);
      cancelToken.current = getCancelToken();

      setIsFetching(true);
      getSchemasByReferenceId(referenceId, {
        cancelToken: cancelToken.current.token
      })
        .then(response => {
          if (response.status === HTTPStatusCodes.Ok) {
            fetchedSchemas.current = response.data.schema;
            schemaVersion.current = response.data.schema.version;
            const { data } = response.data;
            formData.current = data;
            setReferenceName(data.section1.firstRow.name ?? '');
          }
        })
        .catch(error => {
          displayError(error);
        })
        .finally(() => {
          setIsFetching(false);
        });
    };

    getSchemasFromAPI();
  }, [mode, referenceId, displayError]);

  const getDataToSend = useCallback(
    (data: Object) => {
      return {
        data: data.formData,
        reference: {
          name: data.formData.section1?.firstRow?.name || '',
          title: data.formData.section1?.firstRow?.title || '',
          company: data.formData.section1?.secondRow?.company || '',
          telephone: data.formData.section1?.thirdRow?.telephone || '',
          relationship: data.formData.section1?.secondRow?.relationship || '',
          progress: {
            total: Object.keys(QUESTIONS_PATHS).length,
            current: getAnsweredQuestionsCount(data.formData, QUESTIONS_PATHS)
          }
        },
        candidate: {
          id: candidateId,
          name: candidateName
        },
        schema: {
          key: FormSchema.ReferenceCheck,
          version: schemaVersion.current
        },
        trace: {
          user: {
            id: currentUser.id,
            fullName: currentUser.personalInformation.full_name,
            email: currentUser.email,
            initials: getInitials(currentUser.personalInformation.full_name)
          }
        }
      };
    },
    [candidateId, candidateName, currentUser]
  );

  const actions = useMemo(
    () => ({
      handleSubmit: (data: Object) => {
        if (!schemaVersion.current) {
          return;
        }

        formData.current = data.formData;

        cancelToken.current &&
          cancelToken.current.cancel(strings.shared.requests.cancelTokenMessage);
        cancelToken.current = getCancelToken();

        const dataToSend = getDataToSend(data);

        onSave();
        saveReference(dataToSend, {
          cancelToken: cancelToken.current.token
        })
          .then(res => {
            if ([HTTPStatusCodes.Ok, HTTPStatusCodes.Created].includes(res.status)) {
              dispatch(
                showAlert({
                  severity: 'success',
                  title: REFERENCE_CHECK_ADDED_SUCCESSFULLY,
                  body: dataToSend.reference.name
                })
              );
              formData.current = {};
              onSaveSuccess();
            }
          })
          .catch(error => {
            displayError(error);
            onSaveError();
          });
      },
      handleUpdate: (data: Object) => {
        if (!schemaVersion.current) {
          return;
        }

        formData.current = data.formData;
        cancelToken.current &&
          cancelToken.current.cancel(strings.shared.requests.cancelTokenMessage);
        cancelToken.current = getCancelToken();

        const dataToSend = getDataToSend(data);

        onSave();
        updateReference(dataToSend, referenceId, {
          cancelToken: cancelToken.current.token
        })
          .then(res => {
            if ([HTTPStatusCodes.Ok, HTTPStatusCodes.Created].includes(res.status)) {
              dispatch(
                showAlert({
                  severity: 'success',
                  title: REFERENCE_CHECK_UPDATED_SUCCESSFULLY,
                  body: dataToSend.reference.name
                })
              );
              onSaveSuccess();
            }
          })
          .catch(error => {
            displayError(error);

            onSaveError();
          });
      },
      handleFormChange: (data: Object, id: string) => {
        if (mode === DrawerMode.View || mode === DrawerMode.Edit) return;
        formData.current = data.formData;
        if (!id?.includes('section1_firstRow_name')) {
          return;
        }
        setReferenceName(data.formData.section1.firstRow.name ?? '');
      },
      handleResetForm: () => {
        formData.current = {};
        setReferenceName('');
      }
    }),
    [
      formData,
      dispatch,
      onSave,
      onSaveSuccess,
      onSaveError,
      referenceId,
      getDataToSend,
      mode,
      displayError
    ]
  );

  return Object.freeze([
    {
      placeHolders: {
        referenceName: referenceName.replace(/"/g, '\\"'),
        candidateName: candidateName.replace(/"/g, '\\"'),
        recruiterName: recruiterName.replace(/"/g, '\\"')
      },
      isFetching,
      referenceName,
      formData: formData.current,
      saveButtonRef,
      fetchedSchemas: fetchedSchemas.current
    },
    actions
  ]);
};

export default useReferenceCheckForm;
