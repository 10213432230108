// @flow
import React, { useState } from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import moment from 'moment';
import FPAvatar from 'UI/components/atoms/FPAvatar';
import { FPCard, FPCardContent } from 'UI/components/atoms/FPCard';
import FPIcon from 'UI/components/atoms/FPIcon';
import Text from 'UI/components/atoms/Text';
import { DateFormats } from 'UI/constants/defaults';
import { SvgAwardTrophy } from 'UI/res';
import { currencyFormatter } from 'UI/utils';

import { useOverridedStyles, useStyles } from './styles';

const CARD_TYPES = {
  placement: 'placement',
  production: 'production'
};

type OutstandingPerformanceCardProps = {
  amount: number,
  date: string,
  productionPeriod: string,
  subtitle: string,
  type: 'placement' | 'production',
  users: Array<Object>
};

export const OutstandingPerformanceCard = ({
  amount,
  date,
  productionPeriod,
  subtitle,
  type,
  users
}: OutstandingPerformanceCardProps) => {
  const theme = useTheme();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles({ isSmallViewPort });
  const overridedClasses = useOverridedStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const maxUsers = 2;

  const usersWithoutChannerPartners = users.filter(user => !user.is_channel_partner);

  const handleOpenMenu = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const getFormattedDate = baseDate => moment(baseDate).format(DateFormats.MonthDayNumberYear);

  const getUserFirstName = userFullName => (userFullName ? userFullName.split(' ')[0] : '');

  return (
    <FPCard className={classes.container}>
      <FPCardContent className={classes.content}>
        <Grid container>
          <Grid container item sm={12} xs={5}>
            {type === CARD_TYPES.production && (
              <Grid item sm={12} xs={12} className={classes.centerItems}>
                <Typography
                  variant="body2"
                  className={clsx(classes.secondaryLabel, classes.marginBottom)}
                >
                  {users[0].name}
                </Typography>
              </Grid>
            )}

            <Grid container item sm={12} className={classes.centerItems}>
              <Text className={classes.amountLabel} text={currencyFormatter(amount)} />
              <FPIcon
                role="img"
                aria-label="avatar"
                component={SvgAwardTrophy}
                size={20}
                family="bold"
                color={theme.palette.warning.main}
              />
            </Grid>

            <Grid item sm={12} xs={12} className={classes.centerItems}>
              <Text
                className={classes.secondaryLabel}
                variant="body2"
                text={
                  type === CARD_TYPES.placement
                    ? `Placed on ${getFormattedDate(date)}`
                    : productionPeriod
                }
              />
            </Grid>

            <Grid item sm={12} xs={12} className={classes.centerItems}>
              <Text className={classes.subtitleLabel} cropped text={subtitle} variant="body2" />
            </Grid>

            {!isSmallViewPort && (
              <Grid item sm={12} className={classes.dividerContainer}>
                <Divider role="separator" className={classes.divider} />
              </Grid>
            )}
          </Grid>

          <Grid container item sm={12} xs={7} className={classes.usersInfoContainer}>
            {usersWithoutChannerPartners.map(
              (user, index) =>
                index <= maxUsers - 1 && (
                  <Grid
                    key={user.initials}
                    container
                    item
                    sm={12}
                    className={classes.recruitersInvolvedContainer}
                  >
                    {type === CARD_TYPES.placement && (
                      <Grid item sm={6} xs={6} className={classes.recruiterInfoContainer}>
                        <Text className={classes.recruiterLabel} cropped text={user.name} />
                      </Grid>
                    )}

                    <Grid
                      item
                      sm={type === CARD_TYPES.production ? 12 : 6}
                      className={clsx(
                        type === CARD_TYPES.production
                          ? classes.teamInfoContainerCenter
                          : classes.teamInfoContainer
                      )}
                    >
                      <Text
                        className={classes.teamLabel}
                        cropped
                        text={`${user.coachName ? `Team ${getUserFirstName(user.coachName)}` : ''}
                          ${
                            user.regionalName && type === CARD_TYPES.placement
                              ? ` | ${getUserFirstName(user.regionalName)}`
                              : ''
                          }`}
                      />
                    </Grid>
                  </Grid>
                )
            )}

            {usersWithoutChannerPartners.length > maxUsers && (
              <Grid item sm={12} xs={12} className={classes.extraUsersContainer}>
                <Text
                  onClick={handleOpenMenu}
                  className={classes.moreRecruitersLabel}
                  text="View more recruiters involved"
                />
                <Popover
                  role="menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  classes={overridedClasses}
                >
                  <div className={classes.floatingMenu}>
                    {usersWithoutChannerPartners.map(
                      (user, index) =>
                        index > maxUsers - 1 && (
                          <div key={user.initials} role="menuitem" className={classes.contentItem}>
                            <Grid container>
                              <Grid item sm={3}>
                                <FPAvatar
                                  role="img"
                                  aria-label="avatar"
                                  mode="user"
                                  userEmail={user.email}
                                >
                                  {user.initials}
                                </FPAvatar>
                              </Grid>

                              <Grid item sm={9} className={classes.surplusContainer}>
                                <Text
                                  className={classes.surplusRecruiterLabel}
                                  cropped
                                  text={user.name}
                                />
                                {user.coachName && (
                                  <Text
                                    className={classes.surplusTeamLabel}
                                    cropped
                                    text={`Team ${user.coachName}`}
                                  />
                                )}
                                {user.regionalName && (
                                  <Text
                                    className={classes.surplusTeamLabel}
                                    cropped
                                    text={user.regionalName}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        )
                    )}
                  </div>
                </Popover>
              </Grid>
            )}
          </Grid>
        </Grid>
      </FPCardContent>
    </FPCard>
  );
};

OutstandingPerformanceCard.defaultProps = {
  amount: 0,
  date: '',
  users: [],
  subtitle: null,
  type: CARD_TYPES.placement
};
