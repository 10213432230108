// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { showAlert } from 'actions/app';
import API from 'services/API';
import { userHasRoles } from 'services/Authorization';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import { backNavigateListConfig } from 'UI/constants/config';
import { PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { Roles } from 'UI/constants/roles';
import { SvgDownloadFilled } from 'UI/res/icons/filled';
import { downloadFile, getErrorMessage } from 'UI/utils';

import { useStyles } from '../styles';

import Loader from './Loader';
import { statusAll } from './UserToolReport.constants';

export const AutocompleteByYear = ({ year, onSelect, onOptionsLoaded }) => (
  <AutocompleteSelect
    value={year}
    name="weekFilter"
    placeholder="Year"
    onSelect={onSelect}
    onOptionsLoaded={onOptionsLoaded}
    url={Endpoints.SendoutDashboardYears}
    disableClearable
  />
);

export const AutocompleteByWeek = ({ week, month, year, onSelect, onOptionsLoaded }) => (
  <AutocompleteSelect
    value={week}
    name="weekFilter"
    placeholder="Week"
    onSelect={onSelect}
    onOptionsLoaded={onOptionsLoaded}
    groupBy={option => option.month}
    url={`${Endpoints.ToolsUsageReportWeeks}?year=${year?.date}&month=${month?.date}`}
    disableClearable
  />
);

export const AutocompleteByStatus = ({ status, onSelect, onOptionsLoaded }) => (
  <AutocompleteSelect
    value={status}
    name="statusFilter"
    placeholder="Employee Status"
    onSelect={onSelect}
    onOptionsLoaded={onOptionsLoaded}
    url={Endpoints.UserStatuses}
    disableClearable
  />
);

const CURRENT_ELEMENT_INDEX = 0;

const columnsToHideSetting = `${
  window.GPAC_ENV?.TOOLS_USAGE_REPORT_COLUMNS_HIDE ||
  process.env.REACT_APP_TOOLS_USAGE_REPORT_COLUMNS_HIDE
}`;

const columnsToHide = columnsToHideSetting
  ? columnsToHideSetting.replaceAll('-', ' ').split('|')
  : [];

const UserToolReports = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [week, setWeek] = useState(null);
  const [year, setYear] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    document.title = PageTitles.DashboardToolsUsage;
  }, []);

  const onSelectForWeek = (event, newValue) => {
    setWeek(newValue);
    setYear(null);
    setStatus(status);
  };

  const onSelectForStatus = (event, newValue) => {
    setStatus(newValue);
  };

  const onOptionsLoadedForWeek = useCallback((items?: any[]) => {
    setWeek(items[CURRENT_ELEMENT_INDEX]);
  }, []);

  const onOptionsLoadedForStatus = useCallback((items?: any[]) => {
    items.push({ id: 0, title: statusAll });
    setStatus(items[CURRENT_ELEMENT_INDEX]);
  }, []);

  const toolsUsageExtraColumns = [
    {
      rolesNeeded: [
        Roles.ProductionDirector,
        Roles.Operations,
        Roles.Leadership,
        Roles.Admin,
        Roles.DataCoordinator,
        Roles.Finance,
        Roles.Staff,
        Roles.Coach,
        Roles.RegionalDirector
      ],
      columnName: 'draw_balance',
      columnLabel: 'Draw Balance',
      updateFrecuencyInMinutes: 30
    }
  ];

  const getToolsUsageExtraColumns = () => {
    const extraColumnsInfo = [];
    const extraColumnsNames = [];

    toolsUsageExtraColumns.forEach(item => {
      if (!columnsToHide.includes(item.columnLabel) && userHasRoles(item.rolesNeeded)) {
        extraColumnsInfo.push({
          columnName: item.columnName,
          columnLabel: item.columnLabel,
          updateFrecuencyInMinutes: item.updateFrecuencyInMinutes
        });
        extraColumnsNames.push(item.columnName);
      }
    });

    return {
      extraColumnsInfo,
      extraColumnsNames: String(extraColumnsNames)
    };
  };

  const extraColumns = getToolsUsageExtraColumns();

  const handleDownload = async () => {
    const fileName = `tools-usage-report-${week?.date}`;

    try {
      const params = {
        week: week?.date,
        extraColumns: extraColumns?.extraColumnsNames,
        status: status?.title
      };
      const response = await API.get(Endpoints.ToolsUsageReportDownload, {
        responseType: 'blob',
        apiVersion: 1,
        params
      });
      downloadFile(response.data, 'text/csv', fileName);
    } catch (err) {
      dispatch(
        showAlert({
          severity: 'error',
          title: 'Oops',
          body: getErrorMessage(err)
        })
      );
    }
  };

  const AutocompleteViewFilters = (
    <>
      {userHasRoles([Roles.Operations, Roles.Leadership]) && (
        <Grid item xs={1} className={classes.downloadFile}>
          <FPIconButton
            icon={SvgDownloadFilled}
            onClick={handleDownload}
            tootlipProps={{ title: 'Download Report' }}
          />
        </Grid>
      )}
      <Grid item xs={3}>
        <AutocompleteByStatus
          status={status}
          onSelect={onSelectForStatus}
          onOptionsLoaded={onOptionsLoadedForStatus}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteByWeek
          week={week}
          year={year}
          onSelect={onSelectForWeek}
          onOptionsLoaded={onOptionsLoadedForWeek}
        />
      </Grid>
    </>
  );

  return (
    <ContentPageLayout
      titleLabelProps={backNavigateListConfig}
      customTitleGridConfig={{
        xs: 12,
        sm: 4,
        lg: 5
      }}
      text={strings.dashboard.toolsUsage.title}
      actionsBarConfig={{
        xs: 12,
        sm: 8,
        lg: 7,
        spacing: 2
      }}
      actionsBar={AutocompleteViewFilters}
    >
      <Loader
        endpoint={Endpoints.ToolsUsageReportData}
        week={week}
        year={year}
        extraColumns={extraColumns}
        status={status}
      />
    </ContentPageLayout>
  );
};

export default UserToolReports;
