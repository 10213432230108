import React from 'react';
import Grid from '@material-ui/core/Grid';
import { ARCollections, InlineFilters } from 'features/command-center';
import { INLINE_FILTERS_KEYS } from 'features/command-center/constants';
import { DueDateFilter } from 'UI/pages/Collections/Collections.columns';
import useCollectionsListing, {
  AdditionalPeriodRanges,
  DueDateInputRanges
} from 'UI/pages/Collections/hooks/useCollectionsListing';
import { createCustomStaticRanges } from 'UI/utils';

const INLINE_FILTERS = [
  'dueCommitCollectionsDate',
  'dateRange',
  'collectionsStatus',
  'collectionsCurrentTeam',
  'collectionsTeam',
  'collectionsCommitToPay',
  'collectionsShowTermCoaches',
  'collectionsShowSearchInSplit'
];

export const OutstandingBillings = () => {
  const useCollectionsInstance = useCollectionsListing({});
  const { dataTable, dateTypeFilter, handleDateTypeFilterChange } = useCollectionsInstance;
  const { handleFiltersChange, handlePeriodChange } = dataTable;

  const handleInlineFiltersChange = selectedFilters => {
    const { dueCommitCollectionsDate = null, dateRange, ...otherFilters } = selectedFilters;

    if (dueCommitCollectionsDate) {
      handleDateTypeFilterChange(dueCommitCollectionsDate.name, dueCommitCollectionsDate.value);
      handlePeriodChange(dateRange?.value);
    }

    handleFiltersChange(otherFilters);
  };

  const extraFilters = [
    {
      type: 'dateRange',
      name: 'dateRange',
      placeholder: 'Date Range',
      defaultRange: useCollectionsInstance.defaultPeriod,
      disableTimeRange: true,
      DateRangerPickerProps: {
        staticRanges: createCustomStaticRanges(AdditionalPeriodRanges),
        inputRanges: dateTypeFilter === DueDateFilter ? DueDateInputRanges : []
      }
    }
  ];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <InlineFilters
          filters={INLINE_FILTERS}
          onFiltersChange={handleInlineFiltersChange}
          extraFilters={extraFilters}
          persistenceKey={INLINE_FILTERS_KEYS.dominate.outstandingBillings}
        />
      </Grid>

      <Grid item xs={12}>
        <ARCollections useCollectionsInstance={useCollectionsInstance} />
      </Grid>
    </Grid>
  );
};
