// @flow

import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { showAlert as showAlertAction } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import API from 'services/API';
import strings from 'strings';
import CandidateForm from 'UI/components/organisms/CandidateForm';
import { FormFieldsMap } from 'UI/components/organisms/CandidateForm/fields';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { Endpoints } from 'UI/constants/endpoints';
import { getErrorMessage } from 'UI/utils';
import { preloadFromBackend, prepareFormToSubmit } from 'UI/utils/forms';

import { formatPhoneNumbers, PHONE_FORMAT_MODES } from '../PhoneNumbersForm/utils';

type CandidateEditProps = {
  candidate: any,
  onEditCompleted: (profile: any) => void,
  onEditClosed: () => void,
  showAlert: any => void
};

const CandidateEdit = ({
  candidate,
  onEditCompleted,
  onEditClosed,
  showAlert
}: CandidateEditProps) => {
  const defaultValues = preloadFromBackend(candidate, FormFieldsMap);

  const form = useForm({
    defaultValues
  });

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false,
    isFormDisabled: false,
    isReadOnly: false
  });

  const onSubmit = async formData => {
    try {
      setUiState(prevState => ({ ...prevState, isSaving: true }));
      const transformedData = prepareFormToSubmit(formData, FormFieldsMap);
      const preparedPhoneNumbers = formatPhoneNumbers(formData, PHONE_FORMAT_MODES.submit);
      const candidateData = {
        ...transformedData,
        phones: preparedPhoneNumbers
      };
      const response = await API.put(`${Endpoints.Candidates}/${candidate.id}`, candidateData);

      if (response.data && response.status === HTTPStatusCodes.Created) {
        showAlert({
          severity: 'success',
          title: strings.inventoryProfiles.sections.candidates.candidateEdit,
          body: response.data?.personalInformation?.full_name
        });

        const updatedProfile = response.data;
        onEditCompleted(updatedProfile);
      }
    } catch (err) {
      showAlert({
        severity: 'error',
        title: 'Candidate',
        body: getErrorMessage(err)
      });
    }
    setUiState(prevState => ({
      ...prevState,
      isSaving: false,
      isSuccess: false
    }));
  };

  return (
    <FormContext {...form}>
      <DrawerContentLayout
        title="Editing Candidate"
        onSubmit={form.handleSubmit(onSubmit)}
        onClose={onEditClosed}
        uiState={uiState}
      >
        <CandidateForm isEditing />
      </DrawerContentLayout>
    </FormContext>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    showAlert: alert => dispatch(showAlertAction(alert))
  };
};

const CandidateEditConnected = connect(null, mapDispatchToProps)(CandidateEdit);

export default CandidateEditConnected;
