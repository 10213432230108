// @flow
import { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import API from 'services/API';
import { UIStatus } from 'UI/constants/status';
import { getErrorMessage } from 'UI/utils';

// Each prop could be a react component
interface StatusProps {
  success?: any;
  error?: any;
  empty?: any;
  loading?: any;
}

/*
    Hook alternative to use named parameters
 */
export const useFetchWithStatusV2 = ({
  endpoint,
  initialState = null,
  initialStatus = UIStatus.Loading,
  apiVersion = 1,
  apiInstance,
  promise
}) => {
  return useFetchWithStatus(
    endpoint,
    initialState,
    initialStatus,
    apiVersion,
    promise,
    apiInstance
  );
};

export const useFetchWithStatus = (
  endpoint,
  initialState = null,
  initialStatus = UIStatus.Loading,
  apiVersion = 1,
  promise,
  apiInstance
) => {
  const api = apiInstance || API;
  const [state, setState] = useState({
    status: initialStatus || UIStatus.Loading,
    results: initialState,
    error: null,
    responseStatusCode: null
  });

  const refreshData = useCallback(
    async (enableLoading = true) => {
      if (!endpoint && !promise) return;
      try {
        setState(prev => ({
          ...prev,
          status: enableLoading ? UIStatus.Loading : UIStatus.Success,
          loading: true
        }));
        const response = promise ? await promise() : await api.get(endpoint, { apiVersion });
        setState(prev => ({
          ...prev,
          status: UIStatus.Success,
          results: response.data?.items || response.data || response.results
        }));
      } catch (ex) {
        setState(prev => ({
          ...prev,
          status: UIStatus.Error,
          error: getErrorMessage(ex),
          responseStatusCode: ex?.response?.status
        }));
      }
    },
    [endpoint, apiVersion, promise, api]
  );

  useEffect(() => {
    isEmpty(state.results) &&
      state.status === UIStatus.Success &&
      setState(prev => ({
        ...prev,
        status: UIStatus.Empty
      }));
  }, [state.results, state.status]);

  useEffect(() => {
    (async () => {
      await refreshData();
    })();
  }, [refreshData]);

  const Status = (props: StatusProps) => {
    const { status, error, results } = state;
    // eslint-disable-next-line react/destructuring-assignment
    const component = props[status];
    if (!component) return null;

    switch (status) {
      case UIStatus.Error:
        return component(error);
      case UIStatus.Success:
        return component(results);
      default:
        return component;
    }
  };

  return { Status, state, setState, refreshData };
};

export default useFetchWithStatus;
