// @flow
import React, { useEffect } from 'react';
import { FieldValues, FormContext, FormContextValues } from 'react-hook-form';
import { Typography } from '@material-ui/core';
import TextBox from 'UI/components/atoms/TextBox';
import CheckboxList from 'UI/components/molecules/CheckboxList';

import { useStyles } from './styles';

export interface ReasonItems {
  type: 'checkbox' | 'textbox';
  name: String;
  options?: {
    data?: string[],
    maxLength?: number
  };
  title?: String;
  label?: String;
  required: Boolean | true;
  customValidation?: value => void;
}

interface OptionalSelectorFormProps {
  form: FormContextValues<FieldValues>;
  schema: ReasonItems[];
}

const OptionalSelectorForm = ({ form, schema: reasons }: OptionalSelectorFormProps) => {
  const { register, unregister, setValue, errors, watch, getValues } = form;
  const classes = useStyles();

  useEffect(() => {
    reasons
      .filter(item => item.type !== 'checkbox')
      .forEach(reasonItem => {
        register(
          { name: reasonItem.name },
          {
            required: reasonItem.required && `This field is required`,
            validate: value =>
              reasonItem.customValidation && reasonItem.customValidation(value, getValues())
          }
        );
      });
    return () =>
      reasons
        .filter(item => item.type !== 'checkbox')
        .forEach(reasonItem => unregister(reasonItem.name));
  }, [reasons, register, unregister, getValues]);

  const buildDeclineForm = (reasonItem: ReasonItems) => {
    switch (reasonItem.type) {
      case 'checkbox':
        return (
          <div className={classes.item} key={reasonItem.name}>
            {reasonItem.title && (
              <Typography variant="h2" className={classes.itemTitle}>
                {reasonItem.title}
              </Typography>
            )}
            <div data-testid="checkbox-list-reason-not-proceed" className={classes.item}>
              <CheckboxList
                customValidation={reasonItem.customValidation}
                name={reasonItem.name}
                form={form}
                variant={reasonItem.options?.variant}
                required={reasonItem.required}
                checklist={reasonItem.options?.data}
              />
            </div>
          </div>
        );
      case 'textbox':
        return (
          <div className={classes.item} key={reasonItem.name}>
            {reasonItem.title && (
              <Typography variant="h2" className={classes.itemTitle}>
                {reasonItem.title}
              </Typography>
            )}
            <TextBox
              name={reasonItem.name}
              error={!!errors[reasonItem.name]}
              errorText={errors[reasonItem.name]?.message}
              label={reasonItem.label || ''}
              multiline
              value={watch(reasonItem.name) || ''}
              onChange={(e, value) => setValue(reasonItem.name, value)}
            />
            {}
          </div>
        );
      default:
        return <></>;
    }
  };

  const renderDeclineForm = () => reasons.map(buildDeclineForm);

  return <FormContext {...form}>{renderDeclineForm()}</FormContext>;
};

OptionalSelectorForm.defaultProps = {
  options: {
    data: [],
    variant: 'normal'
  }
};

export default OptionalSelectorForm;
