// @flow
import React from 'react';
import { Grid, Paper, Tooltip, Typography } from '@material-ui/core';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { colors } from 'UI/res/colors';
import { DownloadIcon } from 'UI/res/icons/custom';

import { useStyles } from './styles';

type AttachmentChipType = {
  fileSize: number,
  name: string,
  showDownloadButton?: boolean,
  url?: string
};

const AttachmentChip = ({ name, fileSize, showDownloadButton, url }: AttachmentChipType) => {
  const classes = useStyles({ showDownloadButton });
  const sizeFormats = {
    mb: 10 ** 6,
    kb: 10 ** 3
  };

  const sizeAdapter = () =>
    fileSize > sizeFormats.mb
      ? `${(fileSize / sizeFormats.mb).toFixed(2)} mb`
      : `${(fileSize / sizeFormats.kb).toFixed(2)} kb`;

  return (
    <Paper className={classes.root}>
      <Grid container justify="space-between" alignItems="center" wrap="nowrap">
        <Grid item>
          <Grid container direction="column">
            <Tooltip title={name} enterDelay={1000} placement="top">
              <Typography variant="body1" noWrap className={classes.fileName}>
                {name}
              </Typography>
            </Tooltip>
            <Typography className={classes.fileSize} variant="caption">
              {sizeAdapter()}
            </Typography>
          </Grid>
        </Grid>
        {showDownloadButton && (
          <Grid>
            <FPIconButton tooltipProps={{ title: 'Download File' }} href={url}>
              <DownloadIcon fill={colors.success} />
            </FPIconButton>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

AttachmentChip.defaultProps = {
  showDownloadButton: true,
  url: ''
};

export default AttachmentChip;
