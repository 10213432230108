import { THEME } from 'GlobalStyles';
import { colors } from 'UI/res';

export const styles = {
  reasonsContainer: {
    display: 'flex',
    margin: THEME.spacing(2, 0, 3)
  },
  date: {
    fontSize: 14,
    color: colors.grey,
    marginLeft: THEME.spacing(1)
  },
  reasonsText: {
    fontSize: 14,
    color: colors.darkGrey,
    fontWeight: 300,
    maxHeight: 105,
    overflow: 'auto'
  }
};
