import React from 'react';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import Text from 'UI/components/atoms/Text';
import { SvgAdd } from 'UI/res';

import { useStyles } from '../styles';

const skeletonButton = {
  width: 170,
  height: 40
};

const TableRecords = ({ isLoading, count, infoLabel }) => {
  const classes = useStyles();

  return (
    <div className={classes.controlsContainer}>
      {!isLoading && count ? (
        <Text text={`${count} Record(s) in total`} variant="body2" color="textSecondary" />
      ) : (
        ''
      )}
      {infoLabel}
    </div>
  );
};

const TableActions = ({
  actionButtonProps,
  actionText,
  buttonClasses,
  hasProfileLoaded,
  isLoading,
  onNewItemClick
}) => {
  return onNewItemClick ? (
    <Grid container justify="flex-end">
      <Grid className={buttonClasses}>
        {isLoading ? (
          <Skeleton variant="rect" {...skeletonButton} />
        ) : (
          <FPActionButton
            icon={SvgAdd}
            text={actionText}
            onClick={onNewItemClick}
            disabled={!hasProfileLoaded}
            iconProps={{ family: 'mini', size: '15px' }}
            {...actionButtonProps}
          />
        )}
      </Grid>
    </Grid>
  ) : null;
};

const ProfileStructure = ({
  profileTable,
  isLoading,
  TableActionsProps: { actionButtonProps, actionText, hasProfileLoaded, onNewItemClick },
  TableRecordsProps: { count, infoLabel, isQuickViewMode }
}) => {
  const classes = useStyles();

  const buttonClasses = isQuickViewMode
    ? classes.buttonsContainerInQuickView
    : classes.buttonsContainer;

  const mainContainerClasses = isQuickViewMode
    ? classes.mainContainerInQuickView
    : classes.mainContainer;

  return (
    <div className={mainContainerClasses}>
      <TableActions
        actionButtonProps={actionButtonProps}
        actionText={actionText}
        buttonClasses={buttonClasses}
        hasProfileLoaded={hasProfileLoaded}
        isLoading={isLoading}
        onNewItemClick={onNewItemClick}
      />
      <div
        className={isQuickViewMode ? classes.contentContainerInQuickView : classes.contentContainer}
      >
        <TableRecords count={count} infoLabel={infoLabel} isLoading={isLoading} />
        {profileTable}
      </div>
    </div>
  );
};

export default ProfileStructure;
