// @flow
import React, { useMemo, useState } from 'react';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';

import ReasonsForm from '../../OptionalSelectorForm';

import { DeclineFormNames, DeclineSchema, NoOfferSchema, NoOfferStatus } from './utils';

interface SendoutsDeclineFormProps {
  onClose: () => void;
  onBack: () => void;
  onSubmitData: () => void;
  form: FormContextValues<FieldValues>;
  type: any;
}

const SendoutsDeclineForm = ({
  onClose,
  onBack,
  form,
  onSubmitData,
  type
}: SendoutsDeclineFormProps) => {
  const { handleSubmit } = form;
  const [uiState, setUiState] = useState({
    isFormLoading: false,
    isError: false,
    isSaving: false,
    showBackNavigation: true
  });

  const getFormState = useMemo((): { schema: any[], title: string } => {
    const isNoOffer = NoOfferStatus.some(each => each === type?.id);
    if (isNoOffer)
      return {
        schema: NoOfferSchema,
        title: 'No Offer Reasons'
      };

    // default return..
    return {
      schema: DeclineSchema,
      title: 'Offer declined reasons'
    };
  }, [type]);

  const handleSubmitData = async data => {
    setUiState(prev => ({ ...prev, isSaving: true }));
    await onSubmitData({
      ...data,
      declination_details: {
        [DeclineFormNames.declinedFields.name]: data.declined_fields,
        [DeclineFormNames.declinedNotes.name]: data.declination_notes
      }
    });
    setUiState(prev => ({ ...prev, isSaving: false }));
  };

  return (
    <DrawerContentLayout
      title={getFormState.title}
      onBack={onBack}
      onClose={onClose}
      onSubmit={handleSubmit(handleSubmitData)}
      uiState={uiState}
    >
      <div data-testid="checkbox-list-reason-not-offer">
        <ReasonsForm form={form} schema={getFormState.schema} />
      </div>
    </DrawerContentLayout>
  );
};

export default SendoutsDeclineForm;
