// @flow
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { FormHelperText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';
import { showAlert } from 'actions/app';
import {
  removeAttachmentIds,
  setAttachments,
  setExcludedTemplateAttachments
} from 'actions/bulkEmail';
import clsx from 'clsx';
import { THEME } from 'GlobalStyles';
import { UserProviderContext } from 'providers/UserProvider';
import strings from 'strings';
import FPHint from 'UI/components/atoms/FPHint';
import FPIcon from 'UI/components/atoms/FPIcon';
import SelectBox from 'UI/components/atoms/SelectBox';
import Text from 'UI/components/atoms/Text';
import TextBox from 'UI/components/atoms/TextBox';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import FileUploaderOutlined from 'UI/components/molecules/FileUploaderOutlined';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import FPTextEditor from 'UI/components/molecules/FPTextEditor';
import TemplateDropDown from 'UI/components/molecules/TemplateDropDown';
import { tinymceBulkConfig } from 'UI/constants/config';
import {
  BulkBlockOptions,
  BulkMarketingOptions,
  BulkRecruitingOptions,
  BulkScopes
} from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { SvgArrowRight, SvgInformationCircle } from 'UI/res/icons/filled';
import { getFeatureFlags, idOptionSelected } from 'UI/utils';
import { LabelRenderers, OptionRenderers } from 'UI/utils/renderers';

import { FormFieldsMap } from './fields';
import { useStyles } from './styles';

type BulkTypeOptionsTypes = {
  className: string,
  onCheckOption: () => void,
  options: Array,
  watchers: Object
};

const getTextColor = err => (err ? 'error' : 'initial');

const BulkTypeOptions = ({ className, onCheckOption, options, watchers }: BulkTypeOptionsTypes) => (
  <FormGroup row className={className}>
    {options.map(({ name, label }) => (
      <FormControlLabel
        key={name}
        label={label}
        onClick={e => e.stopPropagation()}
        control={
          <Checkbox
            name={name}
            checked={watchers[name]}
            onChange={({ target }) => onCheckOption && onCheckOption(name, target.checked)}
            color="primary"
          />
        }
      />
    ))}
  </FormGroup>
);

const FORM_NAMES_MAP = [
  { key: FormFieldsMap.Subject.key, valueKey: 'subject' },
  { key: FormFieldsMap.EmailBody.key, valueKey: 'html' }
];

const {
  sendThroughOutlook: sendThroughOutlookCopies,
  fields: fieldsCopies,
  validations: validationsCopies,
  bulkQuotaWarning,
  bulkGeneralCategoryWarning,
  signatureInfo
} = strings.bulkEmails.forms;

const OUTLOOK_SUPPORT_URL =
  window.GPAC_ENV?.OUTLOOK_PROVIDER_SUPPORT_URL ||
  process.env.REACT_APP_OUTLOOK_PROVIDER_SUPPORT_URL;

const BULK_SERVICE_EXPLANATION =
  window.GPAC_ENV?.BULK_SERVICE_EXPLANATION ||
  process.env.REACT_APP_BULK_SERVICE_EXPLANATION ||
  bulkQuotaWarning;

const EMAIL_VARIANT_GENERATOR_URL =
  window.GPAC_ENV?.EMAIL_VARIANT_GENERATOR_URL || process.env.REACT_APP_EMAIL_VARIANT_GENERATOR_URL;

const getBulkableOptions = options => options.filter(opt => opt.bulkable_items !== 0);
const checkFeatureFlagIsActive = featureFlag => getFeatureFlags().includes(featureFlag);

const initialParams = {
  searchProjects: {
    keyword: '',
    createdBy: null,
    onlyMine: false,
    page: 1,
    perPage: 10,
    orderBy: 'created_by',
    direction: 'desc'
  }
};

const getBlockResendCopy = (blockResend, templateId) => {
  const { adviceTexts } = fieldsCopies.blockResend;
  if (!templateId) return adviceTexts.disabled;
  return blockResend === 'true' ? adviceTexts.on : adviceTexts.off;
};

const BulkEmailForm = ({ bulkQuota }) => {
  const { id: userId } = useContext(UserProviderContext);
  const { errors, register, setValue, triggerValidation, unregister, watch } = useFormContext();
  const watchers = watch([
    FormFieldsMap.AllowMarketingCandidates.key,
    FormFieldsMap.AllowRecruitHirings.key,
    FormFieldsMap.BlockCompanies.key,
    FormFieldsMap.BlockResend.key,
    FormFieldsMap.BlockSigned.key,
    FormFieldsMap.BulkQuota.key,
    FormFieldsMap.BulkScope.key,
    FormFieldsMap.EmailBody.key,
    FormFieldsMap.GeneralBulkCategory.key,
    FormFieldsMap.JobOrderRecruitment.key,
    FormFieldsMap.MarketCandidates.key,
    FormFieldsMap.RemovedAttachments.key,
    FormFieldsMap.ResendTime.key,
    FormFieldsMap.SendViaOutlook.key,
    FormFieldsMap.SmartTags.key,
    FormFieldsMap.Subject.key,
    FormFieldsMap.To.key
  ]);

  const editorRef = useRef(null);
  const [params, setParams] = useState(initialParams);
  const [uiState, setUiState] = useState({
    showForm: true
  });

  const classes = useStyles({ errors, showForm: uiState.showForm });

  const location = useLocation().pathname.split('/');
  const history = useHistory();
  const { emailBody, attachments, template } = useSelector(state => state.bulkEmail.domain);
  const dispatch = useDispatch();

  useEffect(() => {
    register(
      { name: FormFieldsMap.To.key },
      { required: validationsCopies.searchProject.required }
    );
    register({ name: FormFieldsMap.BlockResend.key, value: 'false' });
    register(
      { name: FormFieldsMap.BulkScope.key, value: null },
      { required: validationsCopies.bulkType.required }
    );
    register({ name: FormFieldsMap.SendViaOutlook.key, value: false });
    register({ name: FormFieldsMap.RemovedAttachments.key, value: [] });
  }, [register]);

  useEffect(() => {
    if (!template?.id) {
      unregister(FormFieldsMap.ResendTime.key);
      setValue(FormFieldsMap.BlockResend.key, 'false');
    }
  }, [setValue, template.id, unregister]);

  useEffect(() => {
    setValue(FormFieldsMap.Subject.key, emailBody.subject, !!template.id);
  }, [emailBody.subject, setValue, template.id]);

  useEffect(() => {
    setValue(FormFieldsMap.EmailBody.key, emailBody.html, !!template.id);
  }, [emailBody.html, setValue, template.id]);

  const handleCheckOption = (name, checked) => setValue(name, checked);

  const handleEditorChange = value => setValue(FormFieldsMap.EmailBody.key, value, true);
  const handleEditorFocus = () => {
    triggerValidation();
    toggleFormVisibility(false)();
  };

  const handleKeyDown = ({ target }) =>
    setParams({
      ...initialParams,
      searchProjects: {
        ...initialParams.searchProjects,
        keyword: target.value
      }
    });

  const handleSelectSearchProject = (name, value) => {
    setValue(
      name,
      {
        id: value?.id,
        name: value?.name,
        totalItems: value?.total_items
      },
      true
    );
  };

  const handleFilesChange = (filesData, alert) => {
    if (alert?.severity === 'error') {
      dispatch(showAlert(alert));
      return;
    }
    filesData.forEach(attachment => dispatch(setAttachments({ ...attachment })));
  };

  const handleRemoveAttachment = ({ remove, alert }) => {
    if (alert?.severity === 'error') {
      dispatch(showAlert(alert));
      return;
    }
    remove.type === 'id' &&
      setValue(FormFieldsMap.RemovedAttachments.key, [
        remove.value,
        ...watchers?.removedAttachments
      ]);
    dispatch(removeAttachmentIds(remove));
    dispatch(setExcludedTemplateAttachments(remove.value));
  };

  const handleResendChange = ({ target: { name, value } }) => {
    const resendTimeKey = FormFieldsMap.ResendTime.key;
    setValue(name, value);
    value === 'true'
      ? register({ name: resendTimeKey }, { required: validationsCopies.resendTime.required })
      : unregister(resendTimeKey);
  };
  const toggleFormVisibility = show => () => setUiState(prev => ({ ...prev, showForm: show }));
  const handleSubjectChange = (name, value) => setValue(name, value, true);
  const handleBulkTypeSelect = (name, value) => {
    const getOptionsNames = options => options.map(option => option.name);
    const registerOptions = options => {
      options.forEach(option => {
        register({ name: option.name, value: false });
      });
    };
    const typesRegistration = {
      [BulkScopes.Marketing]: () => {
        register(
          { name: FormFieldsMap.MarketCandidates.key },
          { required: validationsCopies.marketCandidates.required }
        );
        registerOptions(BulkMarketingOptions);
        unregister([
          FormFieldsMap.GeneralBulkCategory.key,
          FormFieldsMap.JobOrderRecruitment.key,
          ...getOptionsNames(BulkRecruitingOptions)
        ]);
      },
      [BulkScopes.Recruiting]: () => {
        register(
          { name: FormFieldsMap.JobOrderRecruitment.key },
          { required: validationsCopies.jobOrdersToRecruit.required }
        );
        registerOptions(BulkRecruitingOptions);
        unregister([
          FormFieldsMap.GeneralBulkCategory.key,
          FormFieldsMap.MarketCandidates.key,
          ...getOptionsNames(BulkMarketingOptions)
        ]);
      },
      [BulkScopes.General]: () => {
        register(
          { name: FormFieldsMap.GeneralBulkCategory.key },
          { required: validationsCopies.generalBulkCategory.required }
        );
        unregister([
          FormFieldsMap.MarketCandidates.key,
          FormFieldsMap.JobOrderRecruitment.key,
          ...getOptionsNames([...BulkMarketingOptions, ...BulkRecruitingOptions])
        ]);
      }
    };

    setValue(name, value, true);
    typesRegistration[value?.id] && typesRegistration[value?.id]();
  };
  const handleGeneralBulkCategorySelect = (name, value) => setValue(name, value, true);

  const handleSmartTagSelect = (_, value) => editorRef.current.insertContent(value[0].value);

  /**
   * @param {String} - Input name to watch
   * @param {String} - The separation tha will be used by Array.join function
   * in order to create one from given array
   * @param {String} - Message shown in case array has no values
   */
  const renderOptionsAsOnelineText = (input, joint, notItemsMessage) =>
    (watchers[input] && watchers[input].map(item => item.title).join(joint)) || notItemsMessage;

  const ListboxComponent = !location.some(val => val === 'searchproject')
    ? React.forwardRef(({ children, ...rest }, ref) => (
        <div>
          <ul ref={ref} {...rest}>
            {children}
          </ul>
          <div
            role="button"
            tabIndex={0}
            className={classes.listboxSPButton}
            onMouseDown={() => history.push('searchproject')}
          >
            <div>Go to Search Projects list</div>
            <FPIcon
              component={SvgArrowRight}
              size={14}
              family="mini"
              className={classes.spButtonIcon}
              onSetColor={palette => palette.primary.main}
            />
          </div>
        </div>
      ))
    : undefined;

  return (
    <>
      <Grid
        onClick={toggleFormVisibility(true)}
        className={clsx(!uiState.showForm && classes.itemsSpacing)}
      >
        <div className={classes.fieldContainer}>
          <div className={classes.labelContainer}>{fieldsCopies.searchProject.label}</div>
          {uiState.showForm ? (
            <AutocompleteSelect
              name={FormFieldsMap.To.key}
              placeholder={fieldsCopies.searchProject.placeholder}
              customClasses={{ groupLabel: classes.groupLabel }}
              placeholderAsLabel={false}
              getOptionLabel={option =>
                !!option.name && !!option.totalItems ? `${option.name} (${option.totalItems})` : ''
              }
              getDataOnFocus
              selectedValue={watchers.to}
              onSelect={handleSelectSearchProject}
              onKeyDown={handleKeyDown}
              typeahead
              typeaheadLimit={20}
              ListboxComponent={ListboxComponent}
              typeaheadParams={params.searchProjects}
              url={Endpoints.SearchProjects}
              error={!!errors.to}
              errorText={errors?.to?.message}
              groupBy={option =>
                option.created_by === userId
                  ? 'MY SEARCH PROJECTS'
                  : 'OTHER RECRUITERS PUBLIC SEARCH PROJECTS'
              }
              renderOption={OptionRenderers.searchProjects}
              filterOptions={getBulkableOptions}
              getOptionSelected={idOptionSelected}
              disabled={location.includes('searchproject') && location.includes('preview')}
            />
          ) : (
            <Text
              color={getTextColor(errors.to && !watchers.to?.name)}
              variant="body1"
              text={watchers.to?.name || fieldsCopies.searchProject.noValue}
            />
          )}
        </div>
        <div className={classes.fieldContainer}>
          <div className={classes.labelContainer}>
            <p className={classes.withHint}>{fieldsCopies.bulkType.label}</p>
            <FPHint hint={fieldsCopies.bulkType.hint} />
          </div>
          {uiState.showForm ? (
            <AutocompleteSelect
              name={FormFieldsMap.BulkScope.key}
              placeholder={fieldsCopies.bulkType.placeholder}
              placeholderAsLabel={false}
              onSelect={handleBulkTypeSelect}
              getOptionLabel={option =>
                fieldsCopies.bulkType.typeAndDescription[option.title] || ''
              }
              selectedValue={watchers.bulkScope}
              url={Endpoints.BulkEmailScope}
              error={!!errors.bulkScope}
              errorText={errors.bulkScope?.message}
            />
          ) : (
            <Text
              color={getTextColor(errors.bulkScope && !watchers.bulkScope?.title)}
              variant="body1"
              text={
                fieldsCopies.bulkType.typeAndDescription[watchers.bulkScope?.title] ||
                fieldsCopies.bulkType.noValue
              }
            />
          )}
        </div>
        {watchers.bulkScope?.id === BulkScopes.General && (
          <div className={uiState.showForm ? classes.flexStart : classes.fieldContainer}>
            <div className={classes.labelContainer}>
              <p className={classes.withHint}>{fieldsCopies.generalBulkCategory.label}</p>
            </div>
            {uiState.showForm ? (
              <div className={classes.flexColumn}>
                <AutocompleteSelect
                  name={FormFieldsMap.GeneralBulkCategory.key}
                  placeholder={fieldsCopies.generalBulkCategory.placeholder}
                  placeholderAsLabel={false}
                  onSelect={handleGeneralBulkCategorySelect}
                  getOptionLabel={option =>
                    `${option.title} ${option.description && `(${option.description})`}`
                  }
                  selectedValue={watchers[FormFieldsMap.GeneralBulkCategory.key]}
                  url={Endpoints.BulkEmailGeneralCategories}
                  error={!!errors[FormFieldsMap.GeneralBulkCategory.key]}
                  errorText={errors[FormFieldsMap.GeneralBulkCategory.key]?.message}
                />
                <Alert severity="warning">{bulkGeneralCategoryWarning}</Alert>
              </div>
            ) : (
              <Text
                color={getTextColor(
                  errors[FormFieldsMap.GeneralBulkCategory.key] &&
                    !watchers[FormFieldsMap.GeneralBulkCategory.key]
                )}
                variant="body1"
                text={
                  errors[FormFieldsMap.GeneralBulkCategory.key]?.message
                    ? fieldsCopies.generalBulkCategory.noValue
                    : `${watchers[FormFieldsMap.GeneralBulkCategory.key]?.title} (${
                        watchers[FormFieldsMap.GeneralBulkCategory.key]?.description
                      })`
                }
              />
            )}
          </div>
        )}
        {watchers.bulkScope?.id === BulkScopes.Marketing && (
          <div className={uiState.showForm ? classes.flexStart : classes.fieldContainer}>
            <div className={classes.labelContainer}>
              <p className={classes.withHint}>{fieldsCopies.candidatesToMarket.label}</p>
              <FPHint hint={fieldsCopies.candidatesToMarket.hint} />
            </div>
            {uiState.showForm ? (
              <div className={classes.flexColumn}>
                <AutocompleteSelect
                  name={FormFieldsMap.MarketCandidates.key}
                  placeholder={fieldsCopies.candidatesToMarket.placeholder}
                  multiple
                  typeahead
                  typeaheadLimit={25}
                  typeaheadParams={{
                    entityType: EntityType.Candidate,
                    excludeIds: (() => {
                      const candidates = watchers.marketCandidates;
                      return Array.isArray(candidates) ? candidates.map(cand => cand.id) : [];
                    })(),
                    hideNonEmployees: true
                  }}
                  url={Endpoints.Search}
                  error={!!errors.marketCandidates}
                  errorText={errors.marketCandidates?.message}
                  onSelect={(name, value) => setValue(name, value, true)}
                  selectedValue={watchers.marketCandidates || []}
                  getOptionLabel={LabelRenderers.marketCandidates || ''}
                  getOptionSelected={idOptionSelected}
                />
                <BulkTypeOptions
                  className={classes.checkboxesContainer}
                  onCheckOption={handleCheckOption}
                  options={BulkMarketingOptions}
                  watchers={watchers}
                />
              </div>
            ) : (
              <Text
                color={getTextColor(errors.marketCandidates && !watchers.marketCandidates)}
                variant="body1"
                text={renderOptionsAsOnelineText(
                  'marketCandidates',
                  ', ',
                  fieldsCopies.candidatesToMarket.noValue
                )}
              />
            )}
          </div>
        )}
        {watchers.bulkScope?.id === BulkScopes.Recruiting && (
          <div className={classes.fieldContainer}>
            <div className={classes.labelContainer}>
              <p className={classes.withHint}>{fieldsCopies.jobOrdersToRecruit.label}</p>
              <FPHint hint={fieldsCopies.jobOrdersToRecruit.hint} />
            </div>
            {uiState.showForm ? (
              <div className={classes.flexColumn}>
                <AutocompleteSelect
                  name={FormFieldsMap.JobOrderRecruitment.key}
                  placeholder={fieldsCopies.jobOrdersToRecruit.placeholder}
                  multiple
                  typeahead
                  typeaheadLimit={25}
                  typeaheadParams={{
                    entityType: EntityType.Joborder,
                    excludeIds: (() => {
                      const jos = watchers.jobOrderRecruitment;
                      return Array.isArray(jos) ? jos.map(jo => jo.id) : [];
                    })(),
                    hideNonEmployees: true
                  }}
                  url={Endpoints.Search}
                  error={!!errors.jobOrderRecruitment}
                  errorText={errors.jobOrderRecruitment?.message}
                  onSelect={(name, value) => setValue(name, value, true)}
                  selectedValue={watchers.jobOrderRecruitment || []}
                  getOptionLabel={LabelRenderers.jobOrders || ''}
                  getOptionSelected={idOptionSelected}
                />
                <BulkTypeOptions
                  className={classes.checkboxesContainer}
                  onCheckOption={handleCheckOption}
                  options={BulkRecruitingOptions}
                  watchers={watchers}
                />
              </div>
            ) : (
              <Text
                color={getTextColor(errors.jobOrderRecruitment && !watchers.jobOrderRecruitment)}
                variant="body1"
                text={renderOptionsAsOnelineText(
                  FormFieldsMap.JobOrderRecruitment.key,
                  ', ',
                  fieldsCopies.jobOrdersToRecruit.noValue
                )}
              />
            )}
          </div>
        )}
        <div className={classes.fieldContainer}>
          <div className={classes.labelContainer}>{fieldsCopies.template.label}</div>
          {uiState.showForm ? (
            <TemplateDropDown
              formFieldName={FormFieldsMap.Template.key}
              formNamesToSet={FORM_NAMES_MAP}
              placeholder={fieldsCopies.template.placeholder}
              enableNoTemplateOption
              shouldRegisterWithin
            />
          ) : (
            <Text variant="body1" text={template?.name || fieldsCopies.template.noValue} />
          )}
        </div>
        <div className={classes.fieldContainer}>
          <div className={classes.labelContainer}>
            <p className={classes.withHint}>{fieldsCopies.blockResend.label}</p>
            <FPHint hint={fieldsCopies.blockResend.hint} />
          </div>
          <FPRadioGroup
            name={FormFieldsMap.BlockResend.key}
            options={BulkBlockOptions.resend}
            adviceText={getBlockResendCopy(watchers?.blockResend, template?.id)}
            onChange={handleResendChange}
            value={watchers.blockResend || ''}
            disabled={!template.id}
            classes={{ root: classes.rootRadioGroup }}
          />
        </div>
        {watchers.blockResend === 'true' && template?.id && (
          <div className={classes.fieldContainer}>
            <div className={classes.labelContainer}>{fieldsCopies.resendTime.label}</div>
            <SelectBox
              name={FormFieldsMap.ResendTime.key}
              placeholder={fieldsCopies.resendTime.placeholder}
              error={errors.resendTime}
              options={BulkBlockOptions.intervals}
              value={watchers?.resendTime || ''}
              onSelect={(name, value) => setValue(name, value)}
            />
          </div>
        )}
        <Grid container direction="column">
          <Alert
            className={classes.quotaAlert}
            action={
              <Button
                color="inherit"
                size="small"
                className={classes.outlookAlertAction}
                href={OUTLOOK_SUPPORT_URL}
                target="_blank"
                variant="outlined"
              >
                {sendThroughOutlookCopies.alertAction}
              </Button>
            }
            severity="info"
          >
            {strings.formatString(BULK_SERVICE_EXPLANATION, {
              ...bulkQuota
            })}
          </Alert>
        </Grid>
        <div className={classes.fieldContainer}>
          <div className={classes.labelContainer}>{fieldsCopies.subject.label}</div>
          {uiState.showForm ? (
            <TextBox
              name={FormFieldsMap.Subject.key}
              placeholder={fieldsCopies.subject.placeholder}
              value={watchers.subject || ''}
              onChange={handleSubjectChange}
              error={!!errors.subject}
              errorText={errors.subject?.message}
            />
          ) : (
            <Text
              color={getTextColor(errors.subject)}
              variant="body1"
              text={`${watchers.subject || fieldsCopies.subject.noValue}${
                errors.subject ? ` (${errors.subject?.message})` : ''
              }`}
            />
          )}
        </div>
        {uiState.showForm && (
          <div className={classes.fieldContainer}>
            <div className={classes.labelContainer}>{fieldsCopies.attachFiles.label}</div>
            <FileUploaderOutlined
              attachments={attachments}
              onFilesChange={handleFilesChange}
              onRemoveAttachment={handleRemoveAttachment}
              variant="bulk"
            />
          </div>
        )}
      </Grid>
      <div className={classes.fieldContainer}>
        <div className={classes.labelContainer}>
          <p className={classes.withHint}>{fieldsCopies.smartTags.label}</p>
          <FPHint hint={fieldsCopies.smartTags.hint} />
        </div>
        <AutocompleteSelect
          name={FormFieldsMap.SmartTags.key}
          /**
           * DirtyHack: added props selectedValue=[] and multiple=true in order to avoid
           * autocomplete selected value console warnings and to keep current functionality
           * of selecting an option without setting the currently selected choice as input's
           * value.
           */
          multiple
          selectedValue={[]}
          placeholder={fieldsCopies.smartTags.placeholder}
          placeholderAsLabel={false}
          onSelect={handleSmartTagSelect}
          getOptionDisabled={option => option.value === signatureInfo.selectOption.value}
          defaultOptions={watchers[FormFieldsMap.SmartTags.key]}
          getOptionLabel={LabelRenderers.smartTags}
        />
      </div>
      <When condition={checkFeatureFlagIsActive(FeatureFlags.BulkShowEmailVariant)}>
        <Alert
          action={
            <Button
              color="inherit"
              size="small"
              className={classes.outlookAlertAction}
              href={EMAIL_VARIANT_GENERATOR_URL}
              target="_blank"
              variant="outlined"
            >
              {strings.bulkEmails.forms.emailVariants.action}
            </Button>
          }
          severity="warning"
        >
          {strings.bulkEmails.forms.emailVariants.body}
        </Alert>
      </When>
      <FPTextEditor
        onInit={(e, editor) => {
          editorRef.current = editor;
        }}
        config={tinymceBulkConfig}
        value={watchers.emailBody}
        error={!!errors.emailBody}
        errorText={errors?.emailBody?.message}
        onChange={handleEditorChange}
        onFocus={handleEditorFocus}
      />
      <Grid container alignItems="center">
        <FPIcon component={SvgInformationCircle} size="sm" color={THEME.palette.primary.main} />
        <FormHelperText className={classes.signatureHelperText}>
          {signatureInfo.helperText}
        </FormHelperText>
      </Grid>
    </>
  );
};

export default BulkEmailForm;
