import React, { useState } from 'react';
import { LineSteppedAreaChart, Widget } from 'features/command-center';
import { getActivityHistory } from 'features/command-center/services';
import { getWeekRange } from 'features/command-center/utils';
import strings from 'strings';
import { AutocompleteByWeek } from 'UI/components/atoms/AutocompleteByWeek';

import { formatTrendsResponse } from '../activity.utils';

const STRINGS = strings.commandCenter.activity.inMailsTrends;

export const InMailsTrends = ({ weeks, requestParams = {} }) => {
  const [week, setWeek] = useState(weeks?.[0] ?? null);
  const { startDate, endDate } = getWeekRange(week);

  const handleSelectWeek = (event, selectedWeek) => setWeek(selectedWeek);

  return (
    <Widget
      header={STRINGS.title}
      promises={[
        () =>
          getActivityHistory({
            ...requestParams,
            metrics: 'inMails',
            limit: 7,
            orderBy: 'date',
            direction: 'asc',
            startDate,
            endDate
          })
      ]}
    >
      {([response]) => {
        const data = formatTrendsResponse({
          response,
          key: 'inMails',
          goalKey: 'inMailsGoal',
          label: 'InMails'
        });

        return (
          <>
            <AutocompleteByWeek
              defaultOptions={weeks}
              onSelectWeek={handleSelectWeek}
              value={week}
            />

            <LineSteppedAreaChart data={data} />
          </>
        );
      }}
    </Widget>
  );
};
