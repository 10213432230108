// @flow
import { useContext, useEffect } from 'react';
import { SocketContext } from 'context/socket';

export const useActivityUpdater = (socketNamespace, handleMessageSocket) => {
  const socketService = useContext(SocketContext);

  useEffect(() => {
    const startSocket = namespace => {
      socketService.connect(namespace);
      socketService.setEventHandler(namespace, handleMessageSocket);

      return () => socketService.close(namespace);
    };

    return startSocket(socketNamespace);
  }, [socketService, socketNamespace, handleMessageSocket]);
};
