import { makeStyles } from '@material-ui/core/styles';

const FONT_SIZES = {
  totalItems: 30,
  description: 12,
  title: {
    item: 24,
    subItem: 22
  }
};

export const useStyles = makeStyles(theme => ({
  cardItem: {
    width: '100%',
    backgroundColor: theme.palette.secondary.FP200,
    cursor: 'pointer',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '& > button:hover': {
      backgroundColor: `${theme.palette.brandColors.FPWhite} !important`
    }
  },
  cardItemSpacing: {
    padding: theme.spacing(1.5, 2),
    margin: theme.spacing(2, 0, 0, 0)
  },
  totalItems: {
    marginRight: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: FONT_SIZES.totalItems
  },
  description: {
    fontWeight: 400,
    color: theme.palette.secondary.FP700,
    fontSize: FONT_SIZES.description
  },
  subItemSpacing: {
    padding: theme.spacing(0.5, 2, 1, 4),
    margin: theme.spacing(0)
  },
  title: ({ isSubItem }) => ({
    fontWeight: isSubItem ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    fontSize: isSubItem ? FONT_SIZES.title.subItem : FONT_SIZES.title.item
  })
}));
