// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTab } from 'actions/map';
/** Material Assets and Components */
/** Atoms, Components and Styles */
import MapDigTab from 'UI/components/organisms/MapDigTab';
import MapInventoryTab from 'UI/components/organisms/MapInventoryTab';
import TabsView from 'UI/components/templates/TabsView';

import { styles } from './styles';

const MapSideMenu = () => {
  const { activeTab } = useSelector(state => state.map.ui);
  const dispatch = useDispatch();

  const handleTabChange = (event, newValue = -1) => {
    dispatch(selectTab(newValue));
  };

  const tabsProp = [
    {
      label: 'PIL',
      view: <MapDigTab />
    },
    {
      label: 'Inventory',
      view: <MapInventoryTab />
    }
  ];

  return (
    <div style={styles.container}>
      <TabsView
        content={activeTab === 1 ? 'start' : 'block'}
        selectedTab={activeTab}
        onChangeTabIndex={handleTabChange}
        tabs={tabsProp}
      />
    </div>
  );
};

export default MapSideMenu;
