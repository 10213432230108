import * as React from 'react';

const SvgColumns = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path
        fill="#6A7381"
        d="M7.333 4H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h2.333a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1ZM13.167 4h-2.334a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h2.334a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1ZM19 4h-2.333a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1H19a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1Z"
      />
    </svg>
  );
};

export default SvgColumns;
