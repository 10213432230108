import { makeStyles } from '@material-ui/core';
import { THEME } from 'GlobalStyles';
import { colors } from 'UI/res';
import {
  flexAlignCenterJustifyCenter,
  flexAlignCenterSpaceBetween,
  rippleEffect
} from 'UI/utils/styles';

const sharedStyles = {
  ...flexAlignCenterJustifyCenter,
  padding: THEME.spacing(0.5, 1.2),
  borderRadius: '100%',
  width: '44px',
  height: '44px'
};

const { secondary, grey } = THEME.palette;
const iconSize = 20;

export const useStyles = makeStyles({
  darkAction: {
    ...rippleEffect(secondary.dark, secondary.FPlight),
    ...sharedStyles
  },
  action: {
    ...rippleEffect(colors.lightgrey, '#717172'),
    ...sharedStyles
  },
  container: {
    ...flexAlignCenterSpaceBetween
  },
  disabledIcon: {
    fill: '#abacae !important'
  },
  disabledLink: {
    pointerEvents: 'none',
    cursor: 'default'
  },
  icon: {
    fill: grey[600],
    width: iconSize
  },
  iconDarkMode: {
    fill: `${secondary.main} !important`,
    width: iconSize
  },
  infoCard: {
    position: 'relative'
  }
});
