// @flow
import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { THEME as theme } from 'GlobalStyles';
import API from 'services/API';
import { SvgFolder } from 'UI/res';
import { nestTernary } from 'UI/utils';

import { NODE_CONFIGURATION, NODE_NAMES } from './pilIndustries.constants';
import type { IndustryType, NodeSelection, NodeType } from './pilIndustries.types';
import { findNodeInTree, parseNodeId } from './pilIndustries.utils';
import { useTreeItemStyles } from './styles';

type PilTreeViewProps = {
  initialExpandedIds: Array<string>,
  initialNodeId: string | null,
  initialTree: { root: Array<IndustryType>, [selectedNode: string]: Array<NodeType> },
  onNodeSelected: (node: NodeSelection) => void
};

const PilTreeView = ({
  initialExpandedIds,
  initialNodeId,
  initialTree,
  onNodeSelected
}: PilTreeViewProps) => {
  const itemClasses = useTreeItemStyles();
  const [tree, setTree] = useState(initialTree);
  const [currentNodeId, setCurrentNodeId] = useState(initialNodeId);
  const [expandedIds, setExpandedIds] = useState(initialExpandedIds);
  const [nodesFetching, setNodesFetching] = useState([]);

  const handleSelect = async (event, selectedNodeId) => {
    if (currentNodeId === selectedNodeId) return;

    setCurrentNodeId(selectedNodeId);
    onNodeSelected && onNodeSelected(findNodeInTree(tree, selectedNodeId));

    const [nodeType, nodeId] = parseNodeId(selectedNodeId);

    const nodeConfig = NODE_CONFIGURATION[nodeType];
    if (!nodeConfig.ChildrenEndpoint) return;

    setNodesFetching(prev => [...prev, selectedNodeId]);

    const endpoint = nodeConfig.ChildrenEndpoint.replace(':id', nodeId);
    const response = await API.get(endpoint);
    setNodesFetching(prev => prev.filter(node => node !== selectedNodeId));

    response.status === 200 &&
      setTree(prevState => ({
        ...prevState,
        [selectedNodeId]: response.data.map(({ id, title, specialty_id = null }) => ({
          id: `${nodeConfig.ChildrenKey}-${id}`,
          title,
          type: nodeConfig.ChildrenKey,
          specialtyId: specialty_id
        }))
      }));
  };

  const handleToggle = async (event, openIds) => setExpandedIds(openIds);

  const renderTree = branches => {
    return branches.map(({ id, title, type }) => {
      const childrenNodes =
        tree[id]?.length > 0
          ? renderTree(tree[id])
          : nestTernary(type === NODE_NAMES.Subspecialty, null, [null]); // An array of one null is needed to tell TreeView to render the right chevron, otherwise it thinks there are no items to load

      return (
        <TreeItem
          key={id}
          nodeId={id}
          classes={{
            root: itemClasses.root,
            content: itemClasses.content,
            group: itemClasses.group,
            label: itemClasses.label
          }}
          label={
            <div className={itemClasses.labelRoot}>
              <SvgFolder
                size={24}
                className={itemClasses.labelIcon}
                fill={theme.palette.grey[600]}
              />
              <Typography variant="body2" className={itemClasses.labelText}>
                {title}
              </Typography>
              {nodesFetching.includes(id) && <CircularProgress size={20} />}
            </div>
          }
        >
          {childrenNodes}
        </TreeItem>
      );
    });
  };

  return (
    <>
      {tree && (
        <TreeView
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          expanded={expandedIds}
          selected={currentNodeId}
        >
          {renderTree(tree.root)}
        </TreeView>
      )}
    </>
  );
};

export default PilTreeView;
