import { makeStyles } from '@material-ui/core/styles';

export const useTreeItemStyles = makeStyles(
  theme => ({
    content: {
      padding: theme.spacing(1)
    },
    group: {
      marginLeft: theme.spacing(2),
      '& $content': {
        paddingLeft: theme.spacing(1)
      }
    },
    label: { color: 'inherit' },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0)
    },
    labelIcon: {
      marginRight: theme.spacing(2)
    },
    labelText: {
      flexGrow: 1
    },

    statusContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }
  }),
  { classNamePrefix: 'pil-tree-view' }
);
