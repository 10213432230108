// @flow
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import type { AttachmentCategory, OperationResult } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';

import API from './API';
import { sendRequest } from './utils';

const title = 'Placements';

export const getChannelPartnersByUserId = async (id: number): Promise<OperationResult> =>
  sendRequest(`${Endpoints.Users}/${id}/${Endpoints.ChannelPartner}`, 'get');

export const getPlacementById = async (
  id: number,
  scopes: string[] = [],
  relations: string[] = []
): Promise<OperationResult> => {
  const params = {
    scopes: scopes || [],
    relations: relations || []
  };
  const queryParams = queryString.stringify(params, { arrayFormat: 'comma' });
  const result = await sendRequest(`${Endpoints.Placements}/${id}?${queryParams}`, 'get');

  return result;
};

export const createPlacement = async (data: any): Promise<OperationResult> =>
  sendRequest(
    Endpoints.Placements,
    'post',
    data,
    `Waiting for Regional Director approval`,
    'Placement registered successfully'
  );

export const updatePlacement = async (
  id: number,
  data: any,
  options: any
): Promise<OperationResult> =>
  sendRequest(
    `${Endpoints.Placements}/${id}`,
    'put',
    data,
    options?.successBody || 'The placement was updated successfully',
    options?.successTitle || title
  );

export const createSuggestion = async (id: number, data: any): Promise<OperationResult> =>
  sendRequest(
    `${Endpoints.PlacementSuggestions.replace(':id', id)}`,
    'post',
    data,
    'The change request was created successfully',
    title
  );

export const createInvoice = async (id: number, data: any): Promise<OperationResult> =>
  sendRequest(
    `${Endpoints.PlacementInvoices.replace(':id', id)}`,
    'post',
    data,
    'The invoice was added successfully',
    title
  );

export const updateInvoice = async (
  placementId: number,
  invoiceId: number,
  data: any
): Promise<OperationResult> =>
  sendRequest(
    `${Endpoints.PlacementInvoices.replace(':id', placementId)}/${invoiceId}`,
    'patch',
    data,
    'The invoice was updated successfully',
    title
  );

export const getInvoices = async (id: number): Promise<OperationResult> =>
  sendRequest(`${Endpoints.PlacementInvoices.replace(':id', id)}`, 'get');

export const deleteInvoice = async (
  placementId: number,
  invoiceId: number
): Promise<OperationResult> =>
  sendRequest(`${Endpoints.PlacementInvoices.replace(':id', placementId)}/${invoiceId}`, 'delete');

export const createPayment = async (id: number, data: any): Promise<OperationResult> =>
  sendRequest(
    `${Endpoints.PlacementPayments.replace(':id', id)}`,
    'post',
    data,
    'The payment was added successfully',
    title
  );

export const updatePayment = async (
  placementId: number,
  paymentId: number,
  data: any
): Promise<OperationResult> =>
  sendRequest(
    `${Endpoints.PlacementPayments.replace(':id', placementId)}/${paymentId}`,
    'patch',
    data,
    'The payment was updated successfully',
    title
  );

export const deletePayment = async (
  placementId: number,
  paymentId: number
): Promise<OperationResult> =>
  sendRequest(`${Endpoints.PlacementPayments.replace(':id', placementId)}/${paymentId}`, 'delete');

export const putRequest = async (data: any, options: any): Promise<OperationResult> =>
  sendRequest(
    `${options.endpoint}`,
    'put',
    data,
    options.successBody || 'Your request was submitted successfully',
    options.successTitle || title
  );

export const requestFallOff = async (
  id: number,
  data: any,
  options: any
): Promise<OperationResult> =>
  putRequest(data, {
    ...options,
    endpoint: `${Endpoints.PlacementRequestMarkFallOff.replace(':id', id)}`
  });

export const requestRevertFallOff = async (
  id: number,
  data: any,
  options: any
): Promise<OperationResult> =>
  putRequest(data, {
    ...options,
    endpoint: `${Endpoints.PlacementRequestRevertFallOff.replace(':id', id)}`
  });

export const markAsFallOff = async (
  id: number,
  data: any,
  options: any
): Promise<OperationResult> =>
  sendRequest(
    `${Endpoints.PlacementMarkFallOff.replace(':id', id)}`,
    'put',
    data,
    options?.successBody || 'The placement was marked as fall off successfully',
    options?.successTitle || title
  );

export const revertFallOff = async (
  id: number,
  data: any,
  options: any
): Promise<OperationResult> =>
  sendRequest(
    `${Endpoints.PlacementRevertFallOff.replace(':id', id)}`,
    'put',
    data,
    options?.successBody || 'The fall off was reverted successfully',
    options?.successTitle || title
  );

export const requestZip = async (id: number, options: any): Promise<OperationResult> =>
  sendRequest(
    `${Endpoints.PlacementRequestZip.replace(':id', id)}`,
    'post',
    null,
    options?.successBody || `You'll receive an email in a few seconds with a download link`,
    options?.successTitle || title
  );

export const saveEstimate = async (id: number, data: any): Promise<OperationResult> =>
  sendRequest(
    `${Endpoints.PlacementEstimate.replace(':id', id)}`,
    'put',
    data,
    'The estimate was saved successfully',
    title
  );

export const approveEstimate = async (id: number): Promise<OperationResult> =>
  sendRequest(
    `${Endpoints.PlacementApproveEstimate.replace(':id', id)}`,
    'put',
    null,
    'The estimate was approved successfully',
    title
  );

export const useFetchFileCategories = (module: string): AttachmentCategory[] => {
  const [fileCategories, setFileCategories] = useState<AttachmentCategory>([]);

  useEffect(() => {
    (async () => {
      const response = await API.get(`${Endpoints.FileCategories}?module=${module}`);
      response.status === 200 && setFileCategories(response.data);
    })();
  }, [module]);

  return fileCategories;
};

export const useFetchUsers = (roles: number[] = []) => {
  const [users, setUsers] = useState([]);
  const rolesParam = roles.join(',');

  useEffect(() => {
    (async () => {
      const response = await API.get(`${Endpoints.Users}?role_id=${rolesParam}`);
      response.status === 200 && setUsers(response.data);
    })();
  }, [rolesParam]);

  return users;
};

export const recreateEstimate = async (
  id: number,
  data: any = null,
  options
): Promise<OperationResult> =>
  sendRequest(
    `${Endpoints.PlacementRecreateEstimate.replace(':id', id)}`,
    'put',
    data,
    options?.successBody,
    options?.successTitle || title
  );

export const deletePlacement = async (id: number, options): Promise<OperationResult> =>
  sendRequest(
    `${Endpoints.PlacementDeletePermanently.replace(':id', id)}`,
    'delete',
    {},
    options?.successBody,
    options?.successTitle || title
  );
