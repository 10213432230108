// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Grow from '@material-ui/core/Grow';
import { closeConfirmation } from 'actions/app';
import { THEME } from 'GlobalStyles';
import type { DecisionDialogProps } from 'types/app';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import FPIcon from 'UI/components/atoms/FPIcon';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import Text from 'UI/components/atoms/Text';
import { When } from 'UI/components/atoms/When';
import { CloseIcon, SvgDelete } from 'UI/res';
import { SvgCheck, SvgInformationCircle, SvgWarning } from 'UI/res/icons/filled';

import CustomActionsMapper from './CustomActionsMapper';
import { useStyles } from './styles';

const Transition = React.forwardRef((props, ref) => <Grow ref={ref} {...props} />);

const DecisionDialog = ({
  body,
  cancelButtonText,
  changeButtonsOrder,
  children,
  closeDialogOnConfirm,
  confirmButtonText,
  CustomActions,
  customIcon,
  disableConfirmButton,
  fetching,
  isHighLightActionOnLeft,
  isOpened,
  loadingContent,
  message,
  mustComplete,
  onClose,
  onConfirm,
  onError,
  severity,
  showCloseButton,
  showSeverityIcon,
  taskCompleted,
  title,
  withButtons,
  ...rest
}: DecisionDialogProps) => {
  const dispatch = useDispatch();
  const [completed, setCompleted] = useState(false);
  const [open, setOpen] = useState(true);
  const [isActionUnfinished, setIsActionUnfinished] = useState(false);

  const { palette } = THEME;

  const iconSize = 48;
  const severityValues = {
    warning: {
      icon: (
        <FPIcon component={SvgWarning} family="mini" size={iconSize} color={palette.warning.main} />
      ),
      color: palette.warning.main
    },
    error: {
      icon: <FPIcon component={SvgDelete} size={iconSize} color={palette.error.main} />,
      color: palette.error.main
    },
    success: {
      icon: <FPIcon component={SvgCheck} size={iconSize} color={palette.success.main} />,
      color: palette.success.main
    },
    info: {
      icon: (
        <FPIcon
          component={customIcon || SvgInformationCircle}
          size={iconSize}
          color={palette.primary.main}
        />
      ),
      color: palette.primary.main
    }
  };

  const { icon, color } = severityValues[severity];
  const classes = useStyles({ color, showSeverityIcon });

  const handleClose = useCallback(() => {
    onClose && onClose();
    dispatch(closeConfirmation());
    setOpen(false);
  }, [dispatch, onClose]);

  const handleConfirm = async () => {
    try {
      setIsActionUnfinished(true);
      if (onConfirm) mustComplete ? await onConfirm(true) : onConfirm(true);
      closeDialogOnConfirm && dispatch(closeConfirmation());
    } catch (ex) {
      onError && onError(ex);
    } finally {
      setIsActionUnfinished(false);
      typeof taskCompleted === 'undefined' && setCompleted(true);
    }
  };

  useEffect(() => {
    !closeDialogOnConfirm && (completed || taskCompleted) && handleClose();
  }, [taskCompleted, closeDialogOnConfirm, completed, handleClose]);

  const actionButtons = {
    primary: (
      <FPActionButton
        variant={isHighLightActionOnLeft ? 'outlined' : 'contained'}
        className={classes.button}
        onClick={handleConfirm}
        text={confirmButtonText}
        iconPosition="right"
        disabled={fetching || isActionUnfinished || disableConfirmButton}
        size="small"
      >
        {(fetching || isActionUnfinished) && <CircularProgress size={20} color="inherit" />}
      </FPActionButton>
    ),
    close: (
      <FPActionButton
        variant={isHighLightActionOnLeft ? 'contained' : 'outlined'}
        className={classes.button}
        size="small"
        onClick={handleClose}
        text={cancelButtonText}
        disabled={fetching}
      />
    )
  };

  const ActionButtons = () => {
    if (CustomActions) {
      const defaultProps = {
        onClose: handleClose,
        onConfirm: handleConfirm,
        confirmText: confirmButtonText,
        cancelText: cancelButtonText
      };
      return (
        <div className={classes.customActionsContainer}>
          <CustomActions defaultButtonsProps={defaultProps} ActionsMapper={CustomActionsMapper} />
        </div>
      );
    }

    const getOrderedButtons = (left, right) => (
      <>
        <div className={classes.buttonMargin}>{left}</div>
        {right}
      </>
    );
    const actions = {
      YesNo: changeButtonsOrder
        ? getOrderedButtons(actionButtons.primary, actionButtons.close)
        : getOrderedButtons(actionButtons.close, actionButtons.primary),
      Confirmation: actionButtons.primary
    };

    return actions[withButtons] || null;
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={typeof isOpened === 'boolean' ? isOpened : open}
        classes={{ paper: classes.dialogPaper }}
        TransitionComponent={Transition}
        onClose={!mustComplete ? handleClose : null}
        {...rest}
      >
        {showCloseButton && (
          <div className={classes.closeButtonContainer}>
            <FPIconButton onClick={handleClose} tooltipProps={{ title: 'Close' }}>
              <CloseIcon fill={THEME.palette.grey.black} />
            </FPIconButton>
          </div>
        )}
        <div className={classes.bodyContainer}>
          {showSeverityIcon && icon}
          <div className={classes.childrenContainer}>
            <Text variant="h5" text={title} />
            {!loadingContent ? (
              <>
                <Text variant="body2" text={message} />
                <When condition={!!children}>
                  <div className={classes.children}>{children}</div>
                </When>
              </>
            ) : (
              <div className={classes.loader}>
                <CircularProgress size={30} />
              </div>
            )}
          </div>
        </div>
        <When condition={!!body}>
          <div className={classes.children}>{body}</div>
        </When>
        <DialogActions className={classes.actions}>
          <ActionButtons />
        </DialogActions>
      </Dialog>
    </div>
  );
};

DecisionDialog.defaultProps = {
  body: null,
  cancelButtonText: 'No',
  changeButtonsOrder: false,
  closeDialogOnConfirm: true,
  confirmButtonText: 'yes',
  CustomActions: undefined,
  customIcon: undefined,
  disableConfirmButton: false,
  fetching: false,
  isHighLightActionOnLeft: false,
  isOpened: undefined,
  loadingContent: false,
  message: '',
  mustComplete: false,
  onClose: undefined,
  severity: 'warning',
  showCloseButton: true,
  showSeverityIcon: true,
  taskCompleted: undefined,
  withButtons: null
};

export default DecisionDialog;
