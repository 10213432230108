import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { confirm, showAlert, showFile, showFileExplorer } from 'actions/app';
import API from 'services/API';
import { FileStatus } from 'UI/constants/status';
import { getErrorMessage } from 'UI/utils';

const useFileUpload = ({
  category,
  defaultEntityType,
  deleteEndpoint = null,
  endpoint,
  fieldName,
  fileExplorerSections = [],
  initialValue = null,
  onChange = () => {},
  onUploadStarted = () => {},
  softDeleteForExistingItems = false
}) => {
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const [file, setFile] = useState(initialValue);

  const { title } = category;
  const { isUploading, uploadError } = file || {};
  const fileName = file?.original_name || file?.file_name || file?.fileName;
  const canUseFileExplorer =
    fileExplorerSections &&
    fileExplorerSections.length > 0 &&
    fileExplorerSections.some(section => section.files?.length > 0);

  const uploadFile = async att => {
    const data = new FormData();
    data.append('file', att);

    setFile({
      file_name: att.name,
      isUploading: true,
      uploadError: null,
      type: FileStatus.New
    });

    try {
      onUploadStarted && onUploadStarted(fieldName);
      const uploadResponse = await API.post(endpoint, data);
      const uploadedFile = uploadResponse?.data;

      if (uploadedFile) {
        setFile({ ...uploadedFile, isUploading: false, type: FileStatus.New });
        onChange(fieldName, {
          ...uploadedFile,
          file_type_id: category.id,
          entity: defaultEntityType
        });
      }
    } catch (error) {
      setFile(prevState => ({
        ...prevState,
        isUploading: false,
        uploadError: getErrorMessage(error)
      }));
    }
    fileRef?.current?.value && (fileRef.current.value = '');
  };

  const deleteRemoteFile = async () => {
    setFile(prevState => ({ ...prevState, isUploading: true }));
    try {
      const finalEndpoint = deleteEndpoint || endpoint;
      const response = await API.delete(`${finalEndpoint}/${file.id}`);
      if (response.status === 200) {
        setFile(null);
        onChange(fieldName, null);
      } else {
        setFile(prevState => ({
          ...prevState,
          isUploading: false,
          uploadError: 'The file could not be deleted'
        }));
      }
    } catch (err) {
      setFile(prevState => ({
        ...prevState,
        isUploading: false,
        uploadError: getErrorMessage(err)
      }));
    }
  };

  const confirmDeleteFile = async () => {
    if (!file) return;

    if (file.uploadError) {
      setFile(null);
      return;
    }

    if (
      (softDeleteForExistingItems && file.type !== FileStatus.New) ||
      file.type === FileStatus.Existing
    ) {
      setFile(null);
      onChange(fieldName, null);
      return;
    }

    dispatch(
      confirm({
        severity: 'warning',
        title: 'Please confirm',
        message: `Are you sure you want to delete '${fileName}'?`,
        onConfirm: async ok => {
          try {
            if (!ok) return;
            if (file.type === FileStatus.New) {
              await deleteRemoteFile();
            }
          } catch (err) {
            showAlert({
              severity: 'error',
              title: 'Attachments',
              body: getErrorMessage(err)
            });
          }
        }
      })
    );
  };

  const handleFileSelect = files => {
    const selectedFile = files?.length > 0 ? files[0] : null;
    setFile(selectedFile);
    onChange(fieldName, { ...selectedFile, file_type_id: category.id });
  };

  const selectOrUploadFile = () =>
    canUseFileExplorer
      ? dispatch(
          showFileExplorer({
            endpoint,
            defaultEntityType,
            title: `${title} selection`,
            sections: fileExplorerSections,
            onSelect: handleFileSelect
          })
        )
      : fileRef.current.click();

  const handleFileChange = e => {
    const { files } = e.target;
    files.length && uploadFile(files[0]);
  };

  const handleClick = async e => {
    e.stopPropagation();
    if (file) {
      await confirmDeleteFile();
    } else {
      selectOrUploadFile();
    }
  };

  const handleInputClick = async () => {
    file?.url
      ? dispatch(
          showFile({
            url: file.url,
            explicitFileName: fileName,
            useProxy: true
          })
        )
      : selectOrUploadFile();
  };

  return {
    file,
    fileRef,
    fileName,
    isUploading,
    uploadError,
    handleFileChange,
    handleClick,
    handleInputClick,
    selectOrUploadFile,
    canUseFileExplorer
  };
};

export default useFileUpload;
