// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';

import { useCollapsableStyles, useStyles } from './styles';

import './styles.css';

type CollapsiblePanelProps = {
  isSideMenuOpen: boolean,
  mode: 'overlay' | 'inline',
  onToggle: any => any,
  children: React.Node,
  contentStyles: Object
};

const CollapsiblePanel = (props: CollapsiblePanelProps) => {
  const { isSidebarOpen, isTableExpanded } = useSelector(({ app }) => app.ui);
  const { isSideMenuOpen, onToggle, mode, children, contentStyles, ...rest } = props;
  const classes = useStyles({ contentStyles });
  const collapsableClasses = useCollapsableStyles({ isSidebarOpen, isTableExpanded });

  const classMode = `${mode}${isSideMenuOpen ? 'ContainerOpened' : 'ContainerClosed'}`;

  return (
    <Paper
      elevation={1}
      square
      className={clsx(collapsableClasses.container, collapsableClasses[classMode])}
      {...rest}
    >
      <section className={classes.content}>{children}</section>
      <ButtonBase
        role="button"
        title="filters button"
        onClick={onToggle}
        className={collapsableClasses.togglerButton}
      >
        <div className={`collapser ${isSideMenuOpen ? 'is-active' : ''}`}>
          <div className="collapser-box">
            <div className="collapser-inner" />
          </div>
        </div>
      </ButtonBase>
    </Paper>
  );
};

CollapsiblePanel.defaultStyles = {
  contentStyles: undefined,
  mode: 'overlay'
};

export default CollapsiblePanel;
