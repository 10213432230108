// @flow
import React, { useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { confirm, showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { UserProviderContext } from 'providers/UserProvider';
import API from 'services/API';
import { SearchProjectCollaborationsRoles } from 'services/Authorization';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import Text from 'UI/components/atoms/Text';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import ProfileAvatar from 'UI/components/molecules/ProfileAvatar';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { Endpoints } from 'UI/constants/endpoints';
import { Roles } from 'UI/constants/roles';
import { SvgSubtractCircle } from 'UI/res';
import { getErrorMessage, replaceByMap } from 'UI/utils';

import { useStyles } from './styles';
import { collabsMapper, renderAvatarTitle } from './utils';

const { alerts: collabsAlerts, dialogs: collabsDialogs } = strings.searchProjects.collabsForm;

const CollaborationsForm = ({ initCollabs, onClose, onSetCollaborators, open, searchProject }) => {
  const classes = useStyles();
  const [currentUser] = useContext(UserProviderContext);
  const [collaborators, setCollaborators] = useState({
    owner: initCollabs.find(({ id }) => id === currentUser?.id),
    collabs: initCollabs,
    collabsToAdd: [],
    collabsToRemove: []
  });
  const [drawerUiState, setDrawerUiState] = useState({
    isSaving: false
  });
  const dispatch = useDispatch();
  const { id: searchProjectId, name: searchProjectName } = searchProject;

  const isOwner = !!collaborators.owner;
  const haveCollabsChanged = useMemo(
    () => collaborators.collabsToAdd.length !== 0 || collaborators.collabsToRemove.length !== 0,
    [collaborators.collabsToAdd, collaborators.collabsToRemove]
  );
  const currentCollaborations = useMemo(() => {
    const collabsIdsToRemove = collaborators.collabsToRemove.map(collabs => collabs.id);
    const currentCollabs = [...collaborators.collabs, ...collaborators.collabsToAdd].filter(
      collabs => !collabsIdsToRemove.includes(collabs.id)
    );
    const owners = currentCollabs.filter(collab => collab.isOwner);
    const onlyOneOwnerLeft = owners.length === 1;
    return onlyOneOwnerLeft
      ? currentCollabs.map(collab =>
          collab.isOwner ? { ...collab, disableRemoveButton: true } : collab
        )
      : currentCollabs;
  }, [collaborators.collabs, collaborators.collabsToAdd, collaborators.collabsToRemove]);

  const handleSelectCollaborator = (_, value) => {
    const { email, full_name: fullName, id: userId, initials } = value;
    const notCollaboratorYet = !currentCollaborations.some(collab => collab.id === userId);

    notCollaboratorYet
      ? setCollaborators(prev => ({
          ...prev,
          collabsToAdd: [
            ...prev.collabsToAdd,
            {
              detail: SearchProjectCollaborationsRoles.Collaborator,
              email,
              id: userId,
              initials,
              title: fullName
            }
          ]
        }))
      : dispatch(
          showAlert({
            severity: 'warning',
            ...collabsAlerts.userAlreadySelected,
            body: strings.formatString(collabsAlerts.userAlreadySelected.body, { name: fullName })
          })
        );
  };

  const handleRemoveCollaborator = collaborator => () =>
    setCollaborators(prev => ({
      ...prev,
      collabsToAdd: prev.collabsToAdd.filter(collabs => collabs.id !== collaborator.id),
      collabsToRemove: [...prev.collabsToRemove, collaborator]
    }));

  const handleDiscardCollabsChanges = () => {
    setCollaborators(prev => ({
      ...prev,
      collabsToAdd: [],
      collabsToRemove: []
    }));
    onClose();
  };

  const handleCloseForm = () => {
    haveCollabsChanged
      ? dispatch(
          confirm({
            severity: 'warning',
            ...collabsDialogs.discardCollabsChanges,
            changeButtonsOrder: true,
            isHighLightActionOnLeft: true,
            onConfirm: ok => {
              if (!ok) return;
              setTimeout(() => {
                handleManageCollaborators();
              }, 50);
            },
            onClose: handleDiscardCollabsChanges
          })
        )
      : onClose();
  };

  const manageCollaborationsActions = {
    add: async collaboratorsAux => {
      try {
        const endpoint = Endpoints.SearchProjectsAddCollaborator.replace(/:id/, searchProjectId);
        const payload = {
          userIds: collaboratorsAux.collabsToAdd.map(({ id }) => id)
        };
        const response = await API.post(endpoint, payload);

        if (response.status === HTTPStatusCodes.Created) {
          const { data: collabsList } = response;
          const currentCollabsIds = collaboratorsAux.collabs.map(({ id }) => id);
          const newCollabs = collabsList.filter(
            ({ user_id }) => !currentCollabsIds.includes(user_id)
          );
          const newCollabsMapped = newCollabs.map(collabsMapper);
          const updatedCollabs = [...collaboratorsAux.collabs, ...newCollabsMapped];

          dispatch(
            showAlert({
              severity: 'success',
              autoHideDuration: 8000,
              ...(newCollabsMapped.length > 1
                ? {
                    title: 'Collaborators Added Successfully',
                    body: `${newCollabsMapped.length} Collaborators added to ${searchProjectName}`
                  }
                : {
                    title: 'Collaborator Added Successfully',
                    body: `${newCollabsMapped[0].title} - ${searchProjectName}`
                  })
            })
          );

          return {
            ...collaboratorsAux,
            collabs: updatedCollabs,
            collabsToAdd: []
          };
        }
        return null;
      } catch (error) {
        dispatch(
          showAlert({
            severity: 'error',
            title: "Couldn't add collaborator",
            body: getErrorMessage(error)
          })
        );
        return null;
      }
    },
    remove: async collaboratorsAux => {
      try {
        const currentCollabsIds = collaboratorsAux.collabs.map(({ id }) => id);
        const collabsThatCanBeRemoved = collaboratorsAux.collabsToRemove.filter(({ id }) =>
          currentCollabsIds.includes(id)
        );
        const mappedCollabsIdsToRemove = collabsThatCanBeRemoved.map(
          ({ collaborationId }) => collaborationId
        );

        if (mappedCollabsIdsToRemove.length === 0) return null;

        const replaceMap = {
          ':id': searchProjectId,
          ':collabIds': mappedCollabsIdsToRemove.join(',')
        };
        const endpoint = replaceByMap(
          Endpoints.SearchProjectsRemoveCollaborator,
          /:id|:collabIds/gi,
          replaceMap
        );

        const response = await API.delete(endpoint);

        if (response.status === HTTPStatusCodes.Ok) {
          const removeIds = collaboratorsAux.collabsToRemove.map(({ id }) => id);
          const { length } = removeIds;
          const updatedCollabs = collaboratorsAux.collabs.filter(
            collab => !removeIds.includes(collab.id)
          );

          dispatch(
            showAlert({
              severity: 'success',
              ...collabsAlerts.collabsRemoved,
              body: strings.formatString(collabsAlerts.collabsRemoved.body, {
                collabsLength: length,
                noun: length > 1 ? 'Collaborators' : 'Collaborator',
                searchProjectName
              })
            })
          );

          return {
            ...collaboratorsAux,
            collabs: updatedCollabs,
            collabsToRemove: []
          };
        }
        return null;
      } catch (error) {
        dispatch(
          showAlert({
            severity: 'error',
            title: "Couldn't remove collaborator",
            body: getErrorMessage(error)
          })
        );
        return null;
      }
    }
  };

  const handleManageCollaborators = async e => {
    e && e.preventDefault();
    const { collabsToAdd, collabsToRemove } = collaborators;
    const { add, remove } = manageCollaborationsActions;
    const { dialog: saveChangesCopies, singular, plural } = collabsDialogs.saveCollabsChanges;
    const setCurrentCollabs = updatedCollabs => {
      onSetCollaborators && onSetCollaborators(updatedCollabs.collabs);
      setCollaborators(updatedCollabs);
      setDrawerUiState(prev => ({ ...prev, isSaving: false }));
      onClose();
    };

    if (collabsToRemove.length === 0) {
      setDrawerUiState(prev => ({ ...prev, isSaving: true }));
      const updatedCollabs = await add(collaborators);
      setCurrentCollabs(updatedCollabs);
    } else {
      dispatch(
        confirm({
          severity: 'warning',
          ...saveChangesCopies,
          message: strings.formatString(
            saveChangesCopies.message,
            collabsToRemove.length > 1 ? plural : singular
          ),
          onClose: () => {
            const currentUsersIds = collaborators.collabs.map(({ id }) => id);
            const collabsToKeep = collaborators.collabsToRemove.filter(
              ({ id }) => !currentUsersIds.includes(id)
            );
            setCollaborators(prev => ({
              ...prev,
              collabsToAdd: [...prev.collabsToAdd, ...collabsToKeep],
              collabsToRemove: []
            }));
          },
          onConfirm: async () => {
            setDrawerUiState(prev => ({ ...prev, isSaving: true }));
            const shouldAddCollaborators = collabsToAdd.length > 0;
            const shouldRemoveCollaborators = collabsToRemove.length > 0;

            if (shouldAddCollaborators && shouldRemoveCollaborators) {
              const updatedCollabsAfterAdding = await add(collaborators);
              setCurrentCollabs(await remove(updatedCollabsAfterAdding));
            } else if (shouldAddCollaborators) {
              setCurrentCollabs(await add(collaborators));
            } else if (shouldRemoveCollaborators) {
              setCurrentCollabs(await remove(collaborators));
            }
          },
          body: (
            <div className={classes.saveChangesAvatars}>
              {collabsToRemove.map(({ id, detail, email, initials, title }) => (
                <ProfileAvatar
                  key={id}
                  showDetails
                  detail={detail}
                  email={email}
                  initials={initials}
                  title={renderAvatarTitle({ title, initials })}
                  avatarProps={{
                    size: 'medium'
                  }}
                />
              ))}
            </div>
          )
        })
      );
    }
  };

  return (
    <DrawerContentLayout
      title="Search Project Collaborations"
      drawerProps={{ open }}
      onSubmit={handleManageCollaborators}
      onClose={handleCloseForm}
      primaryProps={{
        disabled: !haveCollabsChanged || drawerUiState.isSaving
      }}
      uiState={drawerUiState}
    >
      <Text
        aria-label="collabs-label"
        text="Select a Recruiter to add as Collaborator"
        variant="body1"
        fontSize={16}
      />
      <AutocompleteSelect
        name="selectedRecruiter"
        placeholder="Search a Recruiter by name or email *"
        typeahead
        typeaheadLimit={15}
        typeaheadParams={{ role_id: [Roles.Recruiter, Roles.Coach].join(',') }}
        url={Endpoints.Users}
        onSelect={handleSelectCollaborator}
        renderOption={({ full_name, initials }) =>
          renderAvatarTitle({ title: full_name, initials })
        }
      />
      <div className={classes.formCollabsContainer}>
        {isOwner && (
          <>
            <ProfileAvatar
              aria-label="owner-avatar"
              {...collaborators.owner}
              avatarProps={{ size: 'medium' }}
              showDetails
              title={renderAvatarTitle(collaborators.owner)}
            />
            {currentCollaborations.length > 1 && <Divider />}
          </>
        )}
        <List aria-label="collabs-list">
          {currentCollaborations.map(collaborator => {
            const { id, title, detail, initials, email, disableRemoveButton } = collaborator;
            return id !== currentUser?.id ? (
              <div key={id} aria-label={`avatar-li-${id}`} className={classes.formCollabs}>
                <ProfileAvatar
                  avatarProps={{ size: 'medium' }}
                  title={renderAvatarTitle({ title, initials })}
                  detail={detail}
                  initials={initials}
                  email={email}
                  showDetails
                />
                <FPIconButton
                  icon={SvgSubtractCircle}
                  disabled={!!disableRemoveButton}
                  onClick={handleRemoveCollaborator(collaborator)}
                  tooltipProps={{ placement: 'bottom', title: 'Remove Collaborator' }}
                />
              </div>
            ) : null;
          })}
        </List>
      </div>
    </DrawerContentLayout>
  );
};

export default CollaborationsForm;
