import React from 'react';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { SvgEdit } from 'UI/res';

const EditButton = ({ text, title, size, onClick }) => {
  return (
    <div className="dataSheetButton">
      <FPActionButton
        onClick={onClick}
        size={size}
        variant="outlined"
        text={text}
        icon={SvgEdit}
        title={title}
      />
    </div>
  );
};

export default EditButton;
