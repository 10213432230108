// @flow
import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormGroup, FormHelperText } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { DateFormats } from 'UI/constants/defaults';
import { UIStatus } from 'UI/constants/status';
import { REQUIRED_VALIDATION, toLocalTime } from 'UI/utils';
import { Selectors } from 'UI/utils/renderers';

import { VERIFY_URGENCY_FIELDS } from './VerifyUrgencyDialog.constants';
import useVerifyUrgency from './VerifyUrgencyDialog.hooks';
import { UrgencyVerification } from './VerifyUrgencyDialog.styled';

type VerifyUrgencyDialogProps = {
  open: boolean,
  entityType: string,
  entityId: string,
  onClose: () => void,
  onSaveSucceeded: data => void
};

const ITEMS_REQUIRED_VALIDATION = {
  validate(items) {
    return (items && items.length > 0) || REQUIRED_VALIDATION.required;
  }
};

export const VerifyUrgencyDialog = ({
  open,
  entityType,
  entityId,
  onClose,
  onSaveSucceeded
}: VerifyUrgencyDialogProps) => {
  const { actionsTakenOptions, handleSave, form, uiState, verification } = useVerifyUrgency({
    entityType,
    entityId,
    onSaveSucceeded
  });

  const { handleSubmit, control, setValue, errors } = form;

  const getVerificationDateAndUser = info =>
    `Updated at: ${toLocalTime(info.updated_at).format(DateFormats.SimpleDateTimeHyphen)} by ${
      info.user.name
    }`;

  return (
    <DrawerContentLayout
      isBottomToolbarNeeded={uiState !== UIStatus.Loading}
      onSubmit={handleSubmit(handleSave)}
      uiState={{
        isError: false,
        isFormDisabled: false,
        isFormLoading: uiState === UIStatus.Loading,
        isLoading: true,
        isReadOnly: true,
        isSaving: uiState === UIStatus.Saving,
        isSuccess: false
      }}
      drawerProps={{ open }}
      onClose={onClose}
      title={strings.inventory.urgencyVerification.title}
      variant="blue"
    >
      <Alert severity="info">{strings.inventory.urgencyVerification.description}</Alert>
      <UrgencyVerification variant="body1" gutterBottom>
        {strings.inventory.urgencyVerification.subtitle}:
      </UrgencyVerification>
      <FormGroup row>
        <Controller
          as={
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={e => {
                    setValue(VERIFY_URGENCY_FIELDS.IsVerified.key, e.target.checked, true);
                  }}
                />
              }
              label={strings.inventory.urgencyVerification.fields.verified}
            />
          }
          name={VERIFY_URGENCY_FIELDS.IsVerified.key}
          control={control}
          rules={REQUIRED_VALIDATION}
        />
      </FormGroup>
      <FormHelperText error margin="dense" className="MuiFormHelperText-contained">
        {errors[VERIFY_URGENCY_FIELDS.IsVerified.key] &&
          errors[VERIFY_URGENCY_FIELDS.IsVerified.key].message}
      </FormHelperText>
      <Controller
        as={
          <AutocompleteSelect
            placeholder={strings.inventory.urgencyVerification.fields.actions}
            label={strings.inventory.urgencyVerification.fields.actions}
            error={!!errors[VERIFY_URGENCY_FIELDS.ActionsTaken.key]}
            errorText={
              errors[VERIFY_URGENCY_FIELDS.ActionsTaken.key] &&
              errors[VERIFY_URGENCY_FIELDS.ActionsTaken.key].message
            }
            defaultOptions={actionsTakenOptions}
            multiple
            getOptionSelected={Selectors.byId}
          />
        }
        onChange={([_, value]) => value}
        name={VERIFY_URGENCY_FIELDS.ActionsTaken.key}
        onChangeName="onSelect"
        valueName="selectedValue"
        control={control}
        rules={ITEMS_REQUIRED_VALIDATION}
      />
      <Controller
        as={
          <TextBox
            label={strings.inventory.urgencyVerification.fields.details}
            name={VERIFY_URGENCY_FIELDS.MoreDetails.key}
            multiline
          />
        }
        control={control}
        onChange={([_, value]) => value}
        name={VERIFY_URGENCY_FIELDS.MoreDetails.key}
      />
      {verification && <FormHelperText>{getVerificationDateAndUser(verification)}</FormHelperText>}
    </DrawerContentLayout>
  );
};
