import { EntityRoutes } from 'routes/constants';
import { DateFormats } from 'UI/constants/defaults';

export const COLUMNS = [
  {
    name: 'id',
    label: 'Nº',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'statusBuilder',
        config: {
          idKey: 'statusId',
          labelKey: 'status',
          baseClass: 'placement-status'
        }
      }
    }
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'companyId',
          textKey: 'company',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'candidateFullName',
    label: 'Candidate',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'candidateId',
          textKey: 'candidateFullName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'feeAmount',
    label: 'Fee Amount',
    options: {
      sort: true,
      display: true,
      renderer: 'currency'
    }
  },
  {
    name: 'startDate',
    label: 'Start Date',
    options: {
      sort: true,
      display: true,
      renderer: { id: 'dateBuilder', config: { format: DateFormats.SimpleDate } }
    }
  },
  {
    name: 'createdAt',
    label: 'Created At',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'dateBuilder',
        config: {
          format: DateFormats.SimpleDateTime
        }
      }
    }
  }
];

export const VIRTUAL_PROPS = [
  {
    propName: 'status',
    attributes: ['statusId', 'status']
  },
  {
    propName: 'company',
    attributes: ['companyId', 'company']
  },
  {
    propName: 'candidateFullName',
    attributes: ['candidateId', 'candidateFullName']
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'id',
  direction: 'asc'
};
