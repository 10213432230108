// @flow

import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { unregister } from 'serviceWorker';
import { REQUIRED_VALIDATION } from 'UI/utils';

import { IndustryFormFieldsMap } from './fields';

const chainedSelects = {
  [IndustryFormFieldsMap.Specialty.key]: [
    IndustryFormFieldsMap.Subspecialty.key,
    IndustryFormFieldsMap.FunctionalTitle.key
  ]
};

type UseIndustryFieldsProps = {
  onValuesChanged: () => void
};

export const useIndustryFields = ({ onValuesChanged }: UseIndustryFieldsProps) => {
  const { register, errors, setValue, watch, getValues } = useFormContext();

  const formValues = watch();

  const handleOnSelect = (name?: string, value: any) => {
    if (value?.createButton) return;

    setValue(name, value, true);

    if (name && chainedSelects[name]) {
      chainedSelects[name].forEach(chainedSelect => {
        setValue(chainedSelect, null);
      });
    }
    onValuesChanged && onValuesChanged(getValues());
  };

  useEffect(() => {
    register({ name: IndustryFormFieldsMap.HasSubSpecialties.key });
    register({ name: IndustryFormFieldsMap.Specialty.key }, REQUIRED_VALIDATION);
    register(
      { name: IndustryFormFieldsMap.Subspecialty.key },
      {
        validate(selectedSubspecialty) {
          const HasSubSpecialties = getValues(IndustryFormFieldsMap.HasSubSpecialties.key);
          return (
            !HasSubSpecialties ||
            (HasSubSpecialties && selectedSubspecialty) ||
            REQUIRED_VALIDATION.required
          );
        }
      }
    );
    register({ name: IndustryFormFieldsMap.FunctionalTitle.key }, REQUIRED_VALIDATION);

    return () => {
      unregister(IndustryFormFieldsMap.Specialty.key);
      unregister(IndustryFormFieldsMap.Subspecialty.key);
      unregister(IndustryFormFieldsMap.FunctionalTitle.key);
    };
  }, [getValues, register]);

  const handleSubSpecialtiesLoaded = useCallback(
    (options?: any[]) => {
      setValue(IndustryFormFieldsMap.HasSubSpecialties.key, !!options?.length);
    },
    [setValue]
  );

  return {
    errors,
    formValues,
    handleSubSpecialtiesLoaded,
    handleOnSelect
  };
};
