// @flow
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { showAlert } from 'actions/app';
import API from 'services/API';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import { When } from 'UI/components/atoms/When';
import FPTextEditor from 'UI/components/molecules/FPTextEditor';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { tinymceConfig } from 'UI/constants/config';
import { getErrorMessage } from 'UI/utils';

import FeedbackNotes from './FeedbackNotes';
import { useStyles } from './styles';

type DrawerProps = {
  open: boolean,
  onClose: () => void
};

type OwnershipProps = {
  isRelatedUserByPil: boolean,
  isUserOwnership: boolean
};

type RecruiterNotesFormProps = {
  initialValues: Object,
  onNoteCompleted: () => void,
  endpoint: string,
  drawerProps: DrawerProps,
  ownershipStatus: OwnershipProps
};

const RecruiterNotesForm = ({
  initialValues,
  onNoteCompleted,
  endpoint,
  drawerProps: { open, onClose },
  ownershipStatus: { isRelatedUserByPil, isUserOwnership }
}: RecruiterNotesFormProps) => {
  const editorRef = useRef(null);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState();
  const isAllowedToGiveFeedBack = !isUserOwnership && isRelatedUserByPil;

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false,
    isEditing: false,
    isFormDisabled: false,
    isReadOnly: false
  });

  const [note, setNote] = useState();

  const { register, errors, handleSubmit, setValue, watch } = useForm({
    defaultValues: initialValues
  });

  useEffect(() => {
    register({ name: 'body' }, { required: 'The note is required' });
  }, [register]);

  const handleEditorChange = editorValue => setValue('body', editorValue, true);
  const watchers = watch(['body']);

  const initEditor = (e, editor) => {
    editorRef.current = editor;
  };

  const onSubmit = async (formData: any) => {
    setUiState(prevState => ({ ...prevState, isSaving: true }));
    await saveActivityNote(formData);
  };

  const saveActivityNote = async (activityNote: any) => {
    try {
      const response = uiState.isEditing
        ? await API.put(`${endpoint}/${selectedItem.id}`, activityNote)
        : await API.post(endpoint, activityNote);

      if (response.data) {
        dispatch(
          showAlert({
            severity: 'success',
            title: strings.recruiterProfile.noteAddition
          })
        );
        setSelectedItem(response.data.id);
        onNoteCompleted();
      }
    } catch (error) {
      dispatch(
        showAlert({
          severity: 'error',
          title: strings.shared.ui.error,
          body: getErrorMessage(error)
        })
      );
      setUiState(prevState => ({
        ...prevState,
        isSuccess: false,
        isSaving: false
      }));
    }
  };

  const togglePreview = () => setUiState(prev => ({ ...prev, isReadOnly: !prev.isReadOnly }));

  const handleFeedBackNotesPreview = currentNote => {
    togglePreview();
    setNote(currentNote);
  };

  const handleCloseRecruiterDrawer = () => {
    setUiState(prev => ({ ...prev, isReadOnly: false }));
    onClose();
  };

  return (
    <DrawerContentLayout
      title={strings.recruiterProfile.feedbackAction}
      primaryProps={{
        type: 'button',
        onClick: uiState.isReadOnly ? togglePreview : handleSubmit(onSubmit),
        text: uiState.isReadOnly ? strings.shared.ui.close : strings.shared.ui.save
      }}
      drawerProps={{ open, onClose: handleCloseRecruiterDrawer, classes: { paper: classes.paper } }}
      uiState={uiState}
      onClose={handleCloseRecruiterDrawer}
      contentProps={{ style: { padding: 0 } }}
      footerActionsProps={{
        hideSecondaryButton: uiState.isReadOnly
      }}
    >
      <When condition={!uiState.isReadOnly}>
        <When condition={isAllowedToGiveFeedBack}>
          <div className={classes.drawerContent} aria-label="Feedback Form">
            <TextBox
              name={strings.recruiterProfile.titleNameTextBox}
              label="Title *"
              error={!!errors.title}
              errorText={errors.title && errors.title.message}
              inputRef={register({ required: strings.recruiterProfile.noteTitleValidation })}
              disabled={uiState.isFormDisabled}
            />
            <FPTextEditor
              onInit={initEditor}
              config={tinymceConfig}
              value={watchers.body}
              onChange={handleEditorChange}
              disabled={uiState.isFormDisabled}
              error={!!errors.body}
              errorText={errors.body && errors.body.message}
              containerClassName={classes.formContainer}
            />
            <Divider />
          </div>
        </When>
        <div className={classes.notesTitle}>
          <Typography variant="h6">Comments</Typography>
        </div>
        <FeedbackNotes endpoint={endpoint} onClickPreview={handleFeedBackNotesPreview} />
      </When>
      <When condition={uiState.isReadOnly && note.title}>
        <div className={classes.noteContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">{note?.title}</Typography>
            </Grid>

            <Grid item>
              <Typography variant="body2">{note?.created_by}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{note?.date}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">{note?.text}</Typography>
            </Grid>
          </Grid>
        </div>
      </When>
    </DrawerContentLayout>
  );
};

export default RecruiterNotesForm;
