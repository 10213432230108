import strings from 'strings';

export const ColumnsDefinitions = [
  {
    name: 'id',
    label: strings.collections.columns.id,
    options: {
      display: false,
      sort: true
    }
  },
  {
    name: 'tranid',
    label: strings.collections.columns.invoiceNo,
    options: {
      display: true,
      sort: true
    }
  },
  {
    name: 'custbody_gpac_hqphone',
    label: strings.collections.columns.phone,
    options: {
      display: true,
      renderer: 'phoneLink'
    }
  },
  {
    name: 'commit_to_pay',
    label: strings.collections.columns.commitToPay,
    options: {
      display: true,
      renderer: 'booleanDescription',
      sort: true
    }
  },
  {
    name: 'commit_date',
    label: strings.collections.columns.commitDate,
    options: {
      display: true,
      sort: true
    }
  },
  {
    name: 'commit_amount',
    label: strings.collections.columns.commitAmount,
    options: {
      display: true,
      renderer: 'currency',
      sort: true
    }
  },
  {
    name: 'duedate',
    label: strings.collections.columns.dueDate,
    options: {
      display: true,
      sort: true
    }
  },
  {
    name: 'foreigntotal',
    label: strings.collections.columns.total,
    options: {
      display: true,
      renderer: 'currency',
      sort: true
    }
  },
  {
    name: 'foreignamountunpaid',
    label: strings.collections.columns.remaining,
    options: {
      display: true,
      renderer: 'currency'
    }
  },
  {
    name: 'companyname',
    label: strings.collections.columns.company,
    options: {
      display: true,
      sort: true
    }
  },
  {
    name: 'custbody_so_hiring_authority',
    label: strings.collections.columns.ha,
    options: {
      display: true,
      sort: true
    }
  },
  {
    name: 'custbody_so_candidate_name',
    label: strings.collections.columns.candidate,
    options: {
      display: true,
      sort: true
    }
  },
  {
    name: 'custbody_so_hire_position',
    label: strings.collections.columns.position,
    options: {
      display: false,
      sort: true
    }
  },
  {
    name: 'custbody_so_recruiter_pref_name',
    label: strings.collections.columns.recruiter,
    options: {
      display: true,
      sort: true
    }
  },
  {
    name: 'coach_name',
    label: strings.collections.columns.coach,
    options: {
      display: false,
      sort: true
    }
  },
  {
    name: 'current_coach',
    label: strings.collections.columns.currentCoach,
    options: {
      display: true,
      sort: true
    }
  },
  {
    name: 'memo',
    label: strings.collections.columns.memo,
    options: {
      display: false
    }
  },
  {
    name: 'otherrefnum',
    label: strings.collections.columns.placementId,
    options: {
      display: false
    }
  },
  {
    name: 'collections_notes',
    label: strings.collections.columns.notes,
    options: {
      display: true
    }
  },
  {
    name: 'team_for_collections',
    label: strings.collections.columns.assignedForCollections,
    options: {
      display: true
    }
  }
];

export const FiltersGroups = [
  {
    id: 'invoices',
    name: 'Invoices',
    filters: ['collectionsStatus', 'collectionsCommitToPay']
  },
  {
    id: 'team',
    filters: [
      'collectionsCurrentTeam',
      'collectionsTeam',
      'collectionsShowTermCoaches',
      'collectionsShowSearchInSplit'
    ]
  }
];

export const DueDateFilter = { id: 0, title: 'Due Date', column: 'dueDate' };
export const CommitDateFilter = { id: 1, title: 'Commit Date', column: 'commitDate' };

export const CollectionsPeriodColumnBySection = {
  [DueDateFilter.id]: [DueDateFilter.column],
  [CommitDateFilter.id]: [CommitDateFilter.column]
};

export const CollectionDateFilters = [DueDateFilter, CommitDateFilter];
