// @flow

import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ChatDrawer } from 'features/message-center';
import { UserProviderContext } from 'providers/UserProvider';
import { EntityRoutes } from 'routes/constants';
import PrivateRoute from 'routes/PrivateRoute';
import { Permissions } from 'services/Authorization';
import Notifier from 'UI/components/molecules/Notifier';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';
import BulkEmail from 'UI/pages/BulkEmail';
import CandidateProfile from 'UI/pages/CandidateProfile';
import Candidates from 'UI/pages/Candidates';
import Collections from 'UI/pages/Collections';
import { Activity } from 'UI/pages/CommandCenter/Activity';
import { DominateSheet } from 'UI/pages/CommandCenter/DominateSheet';
import { Overview } from 'UI/pages/CommandCenter/Overview';
import { Recruiter } from 'UI/pages/CommandCenter/Recruiter';
import { TeamOverview } from 'UI/pages/CommandCenter/Team';
import Companies from 'UI/pages/Companies';
import CompanyProfile from 'UI/pages/CompanyProfile';
import ContactProfile from 'UI/pages/ContactProfile';
import Contacts from 'UI/pages/Contacts';
import DashboardActivity from 'UI/pages/Dashboard/Activity';
import DashboardBulkEmail from 'UI/pages/Dashboard/BulkEmail';
import FeeAgreementsBoard from 'UI/pages/Dashboard/FeeAgreements';
import DashboardOverview from 'UI/pages/Dashboard/Overview';
import DashboardPhone from 'UI/pages/Dashboard/Phone';
import SendoutBoard from 'UI/pages/Dashboard/Sendout';
import SendoutLeadersBoard from 'UI/pages/Dashboard/SendoutLeaders';
import ToolsUsageDashboard from 'UI/pages/Dashboard/ToolsUsageDashboard';
import UserToolReports from 'UI/pages/Dashboard/UserToolReports';
import EditCandidateDataSheet from 'UI/pages/EditCandidateDataSheet';
import EditJobOrder from 'UI/pages/EditDataSheet';
import ErrorPage from 'UI/pages/ErrorPage';
import FeeAgreements from 'UI/pages/FeeAgreements';
import HiringAuthorityProfile from 'UI/pages/HiringAuthorityProfile';
import Home from 'UI/pages/Home';
import JobOrderProfile from 'UI/pages/JobOrderProfile';
import JobOrders from 'UI/pages/JobOrders';
import Login from 'UI/pages/Login';
import Map from 'UI/pages/Map';
import NewCandidate from 'UI/pages/NewCandidates';
import NewCompany from 'UI/pages/NewCompany';
import NewContact from 'UI/pages/NewContact';
import NewJobOrder from 'UI/pages/NewJobOrder';
import Pil from 'UI/pages/Pil';
import Placements from 'UI/pages/Placements';
import Preview from 'UI/pages/Preview';
import RecruiterProfile from 'UI/pages/RecruiterProfile';
import Roster from 'UI/pages/Roster';
import { SearchProjectFolders, SearchProjectMain } from 'UI/pages/SearchProject';
import SearchProjectPreview from 'UI/pages/SearchProjectPreview';
import Sendouts from 'UI/pages/Sendouts';
import SendoutsFromChannelPartner from 'UI/pages/SendoutsChannelPartner';
import Teams from 'UI/pages/Teams';
import { TextMessages } from 'UI/pages/TextMessages';
import { getFeatureFlags, hasFeatureFlag } from 'UI/utils';

const featureFlags = getFeatureFlags();

const Routes = () => {
  const [user] = useContext(UserProviderContext);
  const SearchProjectsPage = hasFeatureFlag(FeatureFlags.SearchProjectsFolders)
    ? SearchProjectFolders
    : SearchProjectMain;

  return (
    <>
      <Notifier />
      <ChatDrawer />
      <Switch>
        <Route
          exact
          path={EntityRoutes.Login}
          render={() => (user ? <Redirect to={EntityRoutes.Home} /> : <Login />)}
        />

        <Redirect exact from="/home" to={EntityRoutes.Home} />
        <PrivateRoute exact path="/" component={Home} />

        <PrivateRoute exact path={EntityRoutes.Map} component={Map} />

        <PrivateRoute exact path={EntityRoutes.Candidates} component={Candidates} />
        <PrivateRoute exact path={EntityRoutes.CandidateProfile} component={CandidateProfile} />
        <PrivateRoute exact path={EntityRoutes.CandidateCreate} component={NewCandidate} />
        <PrivateRoute
          exact
          path={EntityRoutes.CandidateDataSheet}
          component={EditCandidateDataSheet}
          enabled={featureFlags.includes(FeatureFlags.CandidateDataSheetFlow)}
        />

        <PrivateRoute exact path={EntityRoutes.Companies} component={Companies} />
        <PrivateRoute exact path={EntityRoutes.CompanyProfile} component={CompanyProfile} />
        <PrivateRoute exact path={EntityRoutes.CompanyCreate} component={NewCompany} />

        <PrivateRoute exact path={EntityRoutes.JobOrders} component={JobOrders} />
        <PrivateRoute exact path={EntityRoutes.JobOrderProfile} component={JobOrderProfile} />
        <PrivateRoute exact path={EntityRoutes.JobOrderCreate} component={NewJobOrder} />
        <PrivateRoute exact path={EntityRoutes.JobOrderEdit} component={EditJobOrder} />

        <PrivateRoute exact path={EntityRoutes.DashboardOverview} component={DashboardOverview} />
        <PrivateRoute exact path={EntityRoutes.DashboardActivity} component={DashboardActivity} />
        <PrivateRoute exact path={EntityRoutes.DashboardPhone} component={DashboardPhone} />
        <PrivateRoute exact path={EntityRoutes.DashboardBulkEmail} component={DashboardBulkEmail} />

        <PrivateRoute exact path={EntityRoutes.Roster} component={Roster} />
        <PrivateRoute exact path={EntityRoutes.Industries} component={Pil} />
        <PrivateRoute exact path={EntityRoutes.Teams} component={Teams} />

        <PrivateRoute exact path={EntityRoutes.FeeAgreements} component={FeeAgreements} />

        <PrivateRoute exact path={EntityRoutes.Contacts} component={Contacts} />

        <PrivateRoute exact path={EntityRoutes.ContactProfile} component={ContactProfile} />
        <PrivateRoute exact path={EntityRoutes.ContactCreate} component={NewContact} />
        <PrivateRoute
          exact
          path={EntityRoutes.HiringAuthorityProfile}
          component={HiringAuthorityProfile}
        />

        <PrivateRoute exact path={EntityRoutes.SearchProject} component={SearchProjectsPage} />
        <PrivateRoute
          exact
          path={EntityRoutes.SearchProjectPreview}
          component={SearchProjectPreview}
        />

        <PrivateRoute exact path={EntityRoutes.BulkEmail} component={BulkEmail} />
        <PrivateRoute
          exact
          path={EntityRoutes.Sendouts}
          component={Sendouts}
          permissions={[Permissions.Sendouts.CanUseModule]}
        />

        <PrivateRoute
          exact
          path={EntityRoutes.DashboardSendout}
          component={SendoutBoard}
          enabled={featureFlags.includes(FeatureFlags.DashboardSendouts)}
        />

        <PrivateRoute
          exact
          path={EntityRoutes.DashboardFeeAgreements}
          component={FeeAgreementsBoard}
          enabled={featureFlags.includes(FeatureFlags.DashboardFeeAgreements)}
        />

        <PrivateRoute
          exact
          path={EntityRoutes.DashboardToolsUsageReport}
          component={UserToolReports}
          enabled={featureFlags.includes(FeatureFlags.DashboardToolsUsageReport)}
        />

        <PrivateRoute
          exact
          path={EntityRoutes.DashboardToolsUsage}
          component={ToolsUsageDashboard}
          enabled={featureFlags.includes(FeatureFlags.DashboardToolsUsage)}
        />

        <PrivateRoute
          exact
          path={EntityRoutes.DashboardSendoutLeaders}
          component={SendoutLeadersBoard}
          enabled={featureFlags.includes(FeatureFlags.DashboardSendoutLeaders)}
        />

        <PrivateRoute
          exact
          path={EntityRoutes.Placements}
          component={Placements}
          permissions={[Permissions.Placements.CanUseModule]}
        />

        <PrivateRoute
          exact
          path={EntityRoutes.SendoutsFromChannelPartner}
          component={SendoutsFromChannelPartner}
          permissions={[Permissions.Sendouts.CanUseModule]}
          enabled={featureFlags.includes(FeatureFlags.SendoutsChannelPartner)}
        />

        <PrivateRoute
          exact
          path={EntityRoutes.RecruiterProfile}
          component={RecruiterProfile}
          enabled={featureFlags.includes(FeatureFlags.RecruiterProfile)}
        />

        <PrivateRoute
          exact
          path={EntityRoutes.Collections}
          component={Collections}
          roles={[Roles.Coach, Roles.Finance, Roles.Leadership, Roles.AssistantRegionalDirector]}
        />

        <PrivateRoute
          exact
          path={EntityRoutes.CommandCenterDominateSheet}
          component={DominateSheet}
          permissions={[Permissions.CommandCenter.DominateSheet.CanUseModule]}
        />
        <PrivateRoute
          exact
          path={EntityRoutes.CommandCenterOverview}
          component={Overview}
          permissions={[Permissions.CommandCenter.Overview.CanUseModule]}
        />
        <PrivateRoute
          exact
          path={EntityRoutes.CommandCenterActivity}
          permissions={[Permissions.CommandCenter.Activity.CanUseModule]}
          component={Activity}
        />
        <PrivateRoute
          path={EntityRoutes.CommandCenterActivityTeam}
          permissions={[Permissions.CommandCenter.Activity.CanUseModule]}
          component={TeamOverview}
        />
        <PrivateRoute
          path={EntityRoutes.CommandCenterActivityRecruiter}
          permissions={[Permissions.CommandCenter.Activity.CanUseModule]}
          component={Recruiter}
        />
        <PrivateRoute
          exact
          path={EntityRoutes.TextMessages}
          component={TextMessages}
          enabled={featureFlags.includes(FeatureFlags.MessageCenter)}
        />

        <PrivateRoute path={EntityRoutes.Preview} component={Preview} />
        <PrivateRoute path="/404" component={() => <ErrorPage error={404} />} />
        <PrivateRoute path="*" component={() => <ErrorPage error={404} shouldGoBack />} />
      </Switch>
    </>
  );
};

export default Routes;
