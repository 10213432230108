import React from 'react';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import strings from 'strings';

import { EmptyRender, LoadingRender } from '../utils';

import DataTable from './DataTable';

const Loader = ({ endpoint, week, month, year, extraColumns, status }) => {
  const finalEndpoint = `${endpoint}?year=${year?.date}&month=${month?.date}&week=${
    week?.date
  }&extraColumns=${extraColumns.extraColumnsNames ? extraColumns.extraColumnsNames : ''}&status=${
    status?.title
  }`;
  const { Status } = useFetchWithStatus(finalEndpoint);
  return (
    <Status
      loading={<LoadingRender />}
      success={data => (
        <DataTable
          items={data.report}
          year={year}
          month={month}
          week={week}
          lastUpdates={data.last_updates}
          extraColumns={extraColumns}
          status={status}
        />
      )}
      empty={
        <EmptyRender
          title={strings.feeAgreements.dashboard.title}
          subtitle={strings.feeAgreements.dashboard.subtitle}
        />
      }
    />
  );
};

export default Loader;
