import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative'
    },
    dateRangeContainer: {
      position: 'absolute',
      left: 0,
      top: -20,
      zIndex: theme.zIndex.tableDateRangeFilter,
      boxShadow: '1px 1px 81px rgba(41,60,74,.18)',
      backgroundColor: theme.palette.common.white,
      borderRadius: 5
    },
    dateRangeToolbar: {
      display: 'flex',
      justifyContent: 'flex-end',
      borderTop: '1px solid #eff2f7',
      padding: 15
    }
  }),
  { name: 'DateRange' }
);
