// @flow

const escapeRegExp = string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const highlightText = (text, textPieces) => {
  if (!text || !textPieces.length) return '';

  let highlightedText = text;

  textPieces.forEach(each => {
    if (each) {
      const regex = new RegExp(escapeRegExp(each), 'g');
      highlightedText = highlightedText.replace(regex, `<i>${each}</i>`);
    }
  });

  return highlightedText;
};
