// @flow
import React from 'react';
import strings from 'strings';

import StatsListItem from './StatsLists/StatsLists';

type StatsValuesProps = {
  week: number,
  teamValue: number,
  cashIn: number
};

type LeadBoardProps = {
  initials: string,
  userEmail: string,
  team: string,
  userName: string,
  statsValues: StatsValuesProps
};

const TOP_PERFORMANCE_CASH_IN = 50;

const LeadBoard = ({
  initials,
  userEmail,
  teamName,
  userName,
  statsValues: { week, team, cashInPosition }
}: LeadBoardProps) => {
  const sendoutsLabels = [
    {
      text: strings.recruiterProfile.statsWeek,
      value: week
    },
    {
      text: strings.formatString(strings.recruiterProfile.statsTeam, { teamName }),
      value: team
    }
  ];

  const isRecruiterTopPerformance = cashInPosition <= TOP_PERFORMANCE_CASH_IN;

  const topCashInPosition = isRecruiterTopPerformance ? cashInPosition : '';
  const cashInText = isRecruiterTopPerformance ? userName : strings.recruiterProfile.cashInPositon;

  const stats = [
    {
      title: strings.recruiterProfile.statsCashIn,
      labels: [{ text: cashInText, value: topCashInPosition }]
    },
    { title: strings.recruiterProfile.statsSendouts, labels: sendoutsLabels }
  ];

  return stats.map(item => (
    <StatsListItem
      statsTitle={item.title}
      key={item.title}
      labels={item.labels}
      initials={initials}
      userEmail={userEmail}
    />
  ));
};

LeadBoard.defaultProps = {
  team: '',
  statsValues: { week: '', teamValue: '', cashInPosition: '' },
  userName: ''
};

export default LeadBoard;
