import * as React from "react";

const SvgClockInterviewFilled = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm0 22a10 10 0 1110-10 10 10 0 01-10 10z" />
      <path d="M17.13 15.81l-4.63-4.25V6.5a1 1 0 00-2 0V12a1 1 0 00.32.74l5 4.54a1 1 0 001.42-.06 1 1 0 00-.11-1.41z" />
    </svg>
  );
};

export default SvgClockInterviewFilled;
