// @flow
import React from 'react';

import Content from './Content';

const Home = props => {
  return <Content {...props} />;
};

export default Home;
