// @flow

import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';
import { SvgGeneralUpdate } from 'UI/res';

const Container = styled(Box)(({ theme }) => ({
  height: 200,
  width: '100%',
  padding: theme.spacing(1),
  gap: theme.spacing(2)
}));

interface ErrorTemplateProps {
  onReset?: (param?: boolean) => Promise<void>;
}

export const ErrorTemplate = ({ onReset }: ErrorTemplateProps) => {
  const handleResetClick = async () => {
    onReset && (await onReset());
  };

  return (
    <Container
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
    >
      <Typography variant="h6">{strings.commandCenter.widget.errorTemplate.title}</Typography>
      <Typography variant="body1">{strings.commandCenter.widget.errorTemplate.subtitle}</Typography>
      <Button
        onClick={handleResetClick}
        startIcon={
          <SvgIcon>
            <SvgGeneralUpdate />
          </SvgIcon>
        }
        variant="outlined"
      >
        {strings.commandCenter.widget.errorTemplate.action}
      </Button>
    </Container>
  );
};
