import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  overline: {
    lineHeight: 2
  },
  profileLabel: {
    textAlign: 'right'
  },
  successIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(0.5)
  }
}));
