// @flow
import React from 'react';
import isNil from 'lodash/isNil';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import { nestTernary } from 'UI/utils';

const DEFAULT_VERSION = '1';

type FilterAutoCompleteProps = {
  filter: Object,
  value: Object,
  onFilterChange: (name: string, value: any) => void,
  defaultOptions?: Array<any>
};

export const FilterAutoComplete = ({
  filter,
  value,
  onFilterChange,
  defaultOptions
}: FilterAutoCompleteProps) => {
  return (
    <div key={`filter-${filter.name}`}>
      <AutocompleteSelect
        api={filter?.api}
        apiVersion={filter?.apiVersion || DEFAULT_VERSION}
        autocomplete="nothing"
        defaultOptions={defaultOptions}
        disableClearable={filter.disableClearable}
        disableListWrap={filter.disableListWrap}
        displayKey={filter.displayKey || 'title'}
        getOptionLabel={filter.getOptionLabel}
        getOptionSelected={filter.getOptionSelected}
        groupBy={filter.groupBy}
        label={filter.title}
        multiple={filter.multiple}
        name={filter.name}
        onSelect={onFilterChange}
        placeholder={`Filter by ${filter.title}`}
        renderOption={filter?.renderOption}
        renderTags={() => filter.renderTags(value, filter?.displayKey)}
        selectedValue={!isNil(value) ? value : nestTernary(filter.multiple, [], null)}
        startAdornment={
          value &&
          filter.startAdornment &&
          filter.startAdornment(value.style_class_name || value.style || value.color)
        }
        textFieldProps={{
          ...filter.textFieldProps,
          style: { minWidth: 250, maxWidth: 350 }
        }}
        typeahead={filter.typeahead}
        typeaheadLimit={filter.typeaheadLimit}
        typeaheadParams={filter.typeaheadParams}
        url={filter?.url || ''}
      />
    </div>
  );
};
