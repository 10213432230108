// @flow

/**
 * These functions are used to determine what actions are available in a drawer to users based on
 * their roles and the status of an entity. The actions are defined in a declarative way
 * to avoid handling specific actions with if statements.
 *
 * It determines a primary action and a set of additional actions based on role and status,
 * but they are checked against a set of configurable guards to determine when to show
 * given specific conditions
 *
 * It's used for Fee Agreement and Placements but can be configured for another flows
 */

export const parseAction = (action, ruleForRole, context) => {
  if (!action || !ruleForRole || !context) return undefined;

  const guard = action.guard || ruleForRole.guard;

  const isAllowed = isActionAllowed(guard, context);
  const fallback = action.fallback || ruleForRole.fallback;
  const alternate = Array.isArray(action.alternate)
    ? action.alternate.map(each => parseAction(each, ruleForRole, context)).find(each => each)
    : parseAction(action.alternate, ruleForRole, context);

  return isAllowed
    ? alternate || action.id || action
    : (action.ignoreGuardForAlternates && alternate) || fallback;
};

export const isActionAllowed = (guards, context) => {
  if (!guards) return true;

  const allGuards = Array.isArray(guards) ? guards : [guards];
  const isAllowed = allGuards.every(each => each(context));

  return isAllowed;
};

export const getActions = (statusId, highestRoleId, rules = {}, context) => {
  if (!statusId || !highestRoleId || !rules[statusId]) {
    return {
      primary: 'default',
      moreActions: []
    };
  }

  const rulesForStatus = rules[statusId];
  if (typeof rulesForStatus === 'string') {
    return {
      primary: rulesForStatus,
      moreActions: []
    };
  }

  const ruleForRole = rulesForStatus[highestRoleId];
  if (typeof ruleForRole === 'string') {
    return {
      primary: ruleForRole,
      moreActions: []
    };
  }

  const primaryAction = parseAction(ruleForRole?.primary, ruleForRole, context) || 'default';
  const moreActions = ruleForRole?.actions
    ? ruleForRole.actions
        .filter(action => parseAction(action, ruleForRole, context))
        .map(action => action.id || action)
    : [];

  return {
    primary: primaryAction,
    moreActions
  };
};
