// @flow

import React from 'react';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import { When } from 'UI/components/atoms/When';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { CANDIDATES_PROFILE_HASHES } from 'UI/constants/hashIds';
import { hasFeatureFlag } from 'UI/utils';

import PlacementAdditionalDetails from '../PlacementAdditionalDetails';
import PlacementBasicSection from '../PlacementBasicSection';
import PlacementDataSheetView from '../PlacementDataSheetView';
import PlacementFilesSection from '../PlacementFilesSection';
import PlacementReferenceChecks from '../PlacementReferenceChecks';
import PlacementReferenceReleaseView from '../PlacementReferenceReleaseView';
import PlacementSplitsSection from '../PlacementSplitsSection';
import { getCompanyRecruitersEmailsFromSplits } from '../utils';

type CreateFormProps = {
  assignmentDataSheet?: Object,
  agreementFile: any,
  agreementFiles: any[],
  candidateDataSheet?: Object,
  feeAgreements: any[],
  fileCategories: any[],
  fileExplorerSections: any[],
  groupedFiles: any[],
  initialSplits: any[],
  newestReferenceReleaseEmail: any,
  sendout: any,
  splits: any[],
  onAgreementChange: any => void,
  onSplitsChange: any => void
};

const CreateForm = ({
  assignmentDataSheet,
  agreementFile,
  agreementFiles,
  candidateDataSheet,
  feeAgreements,
  fileCategories,
  fileExplorerSections,
  groupedFiles,
  initialSplits,
  newestReferenceReleaseEmail,
  sendout,
  splits,
  onAgreementChange,
  onSplitsChange
}: CreateFormProps) => {
  const { candidate, joborder } = sendout || {};
  const { company } = joborder || {};
  const companyRecruitersEmails = getCompanyRecruitersEmailsFromSplits(splits);

  const assignmentDataSheetURL =
    assignmentDataSheet?.jobOrder?.id &&
    EntityRoutes.JobOrderEdit.replace(':id', assignmentDataSheet.jobOrder.id);

  const candidateDataSheetURL =
    candidateDataSheet?.candidate?.id &&
    EntityRoutes.CandidateProfile.replace(':id', candidateDataSheet.candidate.id);

  return (
    <NumberedForm>
      <PlacementBasicSection
        sendout={sendout}
        feeAgreements={feeAgreements}
        onAgreementChange={onAgreementChange}
      />
      {initialSplits?.length > 0 && (
        <PlacementSplitsSection initialSplits={initialSplits} onSplitsChange={onSplitsChange} />
      )}
      {candidate?.id && hasFeatureFlag(FeatureFlags.PlacementWithReferences) && (
        <PlacementReferenceChecks
          candidateId={candidate.id}
          candidateName={candidate?.personalInformation?.full_name}
        />
      )}
      <When condition={assignmentDataSheetURL}>
        <PlacementDataSheetView
          registeredDate={assignmentDataSheet?.updated_at}
          title={strings.placements.sections.assignmentDataSheet.title}
          url={assignmentDataSheetURL}
        />
      </When>
      <When condition={candidateDataSheetURL}>
        <PlacementDataSheetView
          registeredDate={candidateDataSheet?.updated_at}
          title={strings.candidates.profile.dataSheet.title}
          url={`${candidateDataSheetURL}#${CANDIDATES_PROFILE_HASHES.EstablishingUrgency}`}
        />
      </When>
      <PlacementFilesSection
        fileCategories={fileCategories}
        fileExplorerSections={fileExplorerSections}
        files={{ ...groupedFiles, ...agreementFiles }}
        agreement={agreementFile}
        softDeleteForExistingItems
      />
      {newestReferenceReleaseEmail && (
        <PlacementReferenceReleaseView
          email={newestReferenceReleaseEmail}
          candidateId={candidate?.id}
        />
      )}

      <PlacementAdditionalDetails fixedEmails={companyRecruitersEmails} company={company} />
    </NumberedForm>
  );
};

export default CreateForm;
