// @flow
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useFetch } from 'hooks/fetch';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import strings from 'strings';
import CustomDatePicker from 'UI/components/atoms/CustomDatePicker';
import FPHint from 'UI/components/atoms/FPHint';
import TextBox from 'UI/components/atoms/TextBox';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelectConnected from 'UI/components/molecules/AutocompleteSelect';
import EmailPicker from 'UI/components/molecules/EmailPicker';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { colors } from 'UI/res/colors';
import { CloseIcon } from 'UI/res/icons/custom';
import {
  getTimezones,
  isDatetimeInTimezoneAfter,
  mergeHourToDate,
  nestTernary,
  REQUIRED_VALIDATION,
  VALIDATION_REGEXS
} from 'UI/utils';

import 'moment-timezone';

import { InterviewDetails } from '../SendoutForm/components';

import InterviewFixedHour from './FixedHour';
import InterviewRangeHour from './RangeHour';
import { stylesClasses } from './styles';
import {
  interviewItemsStates,
  optionsRenderTimes,
  optionsTypeInterview,
  optionsTypeTimes
} from './utils';

/**
 * TODO: Change the structure of this component and handle the form state here -> DONE
 */

type InterviewScheduleProps = {
  dataSource: any[],
  maxOfInterviews: Number,
  disabled: Boolean,
  onReplaceTagChange: any => void,
  sendoutId: number
};

const timezonesToShow = ['alaska', 'central', 'eastern', 'hawaii', 'mountain', 'pacific'];
const longTimezones = getTimezones(timezonesToShow);

const defaultIdInterview = { id: 0 };
const interviewName = 'interviews';

const InterviewSchedule = ({
  dataSource,
  maxOfInterviews,
  disabled,
  onReplaceTagChange,
  sendoutId
}: InterviewScheduleProps) => {
  /** Form declaration. */
  const form = useFormContext();
  const { watch } = form;

  const watcherInterviews = watch(interviewName);
  const [interviews, setInterviews] = useState([]);
  const [lastInterviews, setLastInterviews] = useState({});
  const [interviewTypes] = useFetch(Endpoints.SendoutsInterviewTypes, []);

  const classes = stylesClasses();

  useEffect(() => {
    if (!isEqual(lastInterviews, watcherInterviews)) {
      onReplaceTagChange && onReplaceTagChange(watcherInterviews);
      setLastInterviews(watcherInterviews);
    }
  }, [watcherInterviews, onReplaceTagChange, lastInterviews]);

  useEffect(() => {
    if (dataSource) {
      if (dataSource.length === 0) {
        appendInterview(defaultIdInterview);
      } else {
        dataSource.map(item => appendInterview(item));
      }
    } else {
      appendInterview();
    }
  }, [dataSource]);

  const appendInterview = item => setInterviews(prev => [...prev, item || {}]);
  const addInterviews = () =>
    watcherInterviews
      ? nestTernary(watcherInterviews?.length >= maxOfInterviews, null, appendInterview())
      : appendInterview();

  const removeInterview = index =>
    setInterviews(prev => prev.filter((value, key) => key !== index));
  const handleDelete = index => removeInterview(index);

  const oldestIntervew = useMemo(() => {
    if (!dataSource) return false;

    if (dataSource.length === 0) return defaultIdInterview;

    const interviewSaved = dataSource.filter(each => each.id);
    if (interviewSaved.length === 0) return false;

    return interviewSaved.sort((a, b) => a.id - b.id)[0];
  }, [dataSource]);

  const isInterviewTheOldest = id => oldestIntervew && oldestIntervew.id === id;

  const getFormItemState = item =>
    isInterviewTheOldest(item.id) || !dataSource
      ? interviewItemsStates.firstInterview
      : interviewItemsStates.editableComplete;

  const handleUpdateInterviewTag = data => onReplaceTagChange && onReplaceTagChange(data);

  return (
    <div>
      {interviews
        .map((item, index) => ({ ...item, uid: index }))
        .map((item, index) => (
          <InterviewScheduleFormItem
            form={form}
            index={index}
            key={item.uid}
            field={item}
            disabled={disabled}
            onDelete={
              index === interviews?.length - 1 && interviews?.length > 1 && !item.id && handleDelete
            }
            status={getFormItemState(item)}
            prefix={`${interviewName}[${index}].`}
            interviewTypes={interviewTypes}
            onUpdateInterviewTag={handleUpdateInterviewTag}
            sendoutId={sendoutId}
            interviewId={item.id}
          />
        ))}

      {maxOfInterviews > watcherInterviews?.length && !disabled && (
        <div className={classes.rowSchedule}>
          {watcherInterviews?.length === 0 ? (
            <p className={classes.textSchedule}>No interview scheduled for this sendout, </p>
          ) : (
            <p className={classes.textSchedule}>If you want to add another interview, </p>
          )}
          <Button
            onClick={addInterviews}
            type="button"
            variant="text"
            className={classes.btnAddSchedule}
          >
            Schedule new Interview.
          </Button>
        </div>
      )}
    </div>
  );
};

const formItem = {
  typeInterview: 'interview_type_id',
  interviewDate: 'interview_date',
  interviewTimezone: 'interview_time_zone',
  interviewMail: 'cc_emails',
  typeTime: 'interview_type_time',
  hourFixed: 'hour_fixed',
  hourRangeFrom: 'interview_range.from',
  hourRangeTo: 'interview_range.to',
  ownerName: 'full_name',
  ownerEmail: 'email',
  interviewSchedule: 'interview_schedule',
  id: 'id'
};
const formItemList = Object.entries(formItem).map(entrie => entrie[1]);

const InterviewScheduleFormItem = (props: any) => {
  const {
    form,
    prefix,
    interviewTypes,
    index,
    disabled,
    onDelete,
    status,
    onUpdateInterviewTag,
    interviewId
  } = props;
  const { register, unregister, watch, errors, setValue, getValues, setError, clearError } = form;

  const classes = stylesClasses();

  const setStringTheIds = item => ({ ...item, id: item.id.toString() });
  const getPrefixedData = useCallback(name => prefix + name, [prefix]);
  const formItemListPrefixed = useMemo(
    () => formItemList.map(item => getPrefixedData(item)),
    [getPrefixedData]
  );

  const watcher = watch(formItemListPrefixed);
  const watcherInterviews = watch(interviewName);

  // I need reserver this update only when it needs to render
  const shouldUpdate = useMemo(
    () =>
      watcherInterviews &&
      !!(!watcherInterviews[index]?.[formItem.typeTime] && watcherInterviews[index]?.[formItem.id]),
    [index, watcherInterviews]
  );
  const [historycalData, setHistorycalData] = useState();
  const [isEditable, setIsEditable] = useState(
    !!watcher[getPrefixedData(formItem.interviewDate)] || false
  );
  const isError = target => errors[interviewName] && errors[interviewName][index]?.[target];

  useEffect(() => {
    register(getPrefixedData(formItem.typeTime), REQUIRED_VALIDATION);
    register(getPrefixedData(formItem.typeInterview), REQUIRED_VALIDATION);
    register(getPrefixedData(formItem.interviewDate), REQUIRED_VALIDATION);
    register(getPrefixedData(formItem.interviewTimezone), REQUIRED_VALIDATION);
    register(getPrefixedData(formItem.interviewSchedule));
    register(getPrefixedData(formItem.id));
    return () => unregister(formItemListPrefixed);
  }, [formItemListPrefixed, getPrefixedData, getValues, register, unregister]);

  useEffect(() => {
    (() => {
      if (!shouldUpdate || historycalData) return;
      const interviews = getValues(interviewName);

      const interviewDate = interviews[index]?.[formItem.interviewDate];
      const interviewRange = interviews[index]?.interview_range;
      const interviewTimeType = interviews[index]?.[formItem.typeTime];
      if (interviewTimeType) return;

      if (interviewDate)
        setValue(
          getPrefixedData(formItem.typeTime),
          interviewRange ? optionsTypeTimes[1] : optionsTypeTimes[0]
        );
    })();
  }, [getPrefixedData, getValues, index, setValue, shouldUpdate, historycalData]);

  const handleFixedHourChange = time => {
    watcherInterviews[index].hour_fixed = time;
    onUpdateInterviewTag && onUpdateInterviewTag(watcherInterviews);
  };

  const customRender = {
    [optionsRenderTimes.fixedHour]: (
      <InterviewFixedHour
        index={index}
        isNew={!watcher[getPrefixedData(formItem.id)]}
        disabled={disabled || isEditable}
        defaultDate={watcher[getPrefixedData(formItem.interviewDate)]}
        interviewName={interviewName}
        interviewDateFieldName={getPrefixedData(formItem.interviewDate)}
        interviewTimeFieldName={getPrefixedData(formItem.hourFixed)}
        interviewTimezoneFieldName={getPrefixedData(formItem.interviewTimezone)}
        onFixedHourChange={handleFixedHourChange}
      />
    ),
    [optionsRenderTimes.rangeHour]: (
      <InterviewRangeHour
        index={index}
        disabled={disabled || isEditable}
        interviewName={interviewName}
        interviewDateFieldName={getPrefixedData(formItem.interviewDate)}
        interviewRangeToFieldName={getPrefixedData(formItem.hourRangeTo)}
        interviewRangeFromFieldName={getPrefixedData(formItem.hourRangeFrom)}
        interviewTimezoneFieldName={getPrefixedData(formItem.interviewTimezone)}
      />
    )
  };

  const isTimezoneExist = (timezone: any) =>
    !longTimezones.find(value => value?.name === timezone?.name) && timezone;

  const toggleEditable = () => {
    getValues(getPrefixedData(formItem.id)) && setIsEditable(!isEditable);

    if (isEditable) {
      const interviewTypeTime = getValues(getPrefixedData(formItem.typeTime));
      const interviewHourFixed = getValues(getPrefixedData(formItem.hourFixed));
      const interviewDate = getValues(getPrefixedData(formItem.interviewDate));

      const interviewData = getValues(interviewName);

      interviewData[index][formItem.typeTime] = interviewTypeTime;
      interviewData[index][formItem.hourFixed] = moment(interviewHourFixed);
      interviewData[index][formItem.interviewDate] = interviewDate;

      setHistorycalData(interviewData);
      toggleEditProperty(true);
    } else {
      setValue(interviewName, historycalData);
      setHistorycalData(null);

      toggleEditProperty(false);
    }
  };

  const toggleEditProperty = actived => {
    const editedName = getPrefixedData('edited');
    if (!actived) {
      setValue(editedName, actived);
      return;
    }

    const isEdited = getValues(editedName);
    if (!isEdited) {
      register(editedName);
      setValue(editedName, true);
    }
  };

  const getEditButton = () => {
    if (!getValues(getPrefixedData(formItem.id))) return null;

    if (!isEditable)
      return (
        <Button
          onClick={toggleEditable}
          type="button"
          variant="text"
          className={classes.editInterviewBtn}
        >
          Cancel Editing
        </Button>
      );

    const localtz = moment.tz.guess();
    const interviewRangeTo = getValues(getPrefixedData(formItem.hourRangeFrom));
    const interviewDate = getValues(getPrefixedData(formItem.interviewDate));
    const interviewOffset = getValues(getPrefixedData(formItem.interviewTimezone))?.offset;
    const mergedDate =
      interviewRangeTo && mergeHourToDate(moment(interviewDate), moment(interviewRangeTo));
    const interviewTime = interviewRangeTo ? mergedDate : interviewDate;

    /** Need to add the current offset to format */
    const timeInterview = moment(interviewTime).utcOffset(interviewOffset, true);
    const timeInterviewLocal = moment(timeInterview).tz(localtz, false);

    if (moment().isBefore(timeInterviewLocal)) {
      return (
        <Button
          onClick={toggleEditable}
          type="button"
          variant="text"
          className={classes.editInterviewBtn}
        >
          Edit Interview
        </Button>
      );
    }

    return (
      <Button
        type="button"
        disabled
        variant="text"
        className={clsx([classes.editInterviewBtn, classes.btnUnderlineDisable])}
      >
        Interview Done
      </Button>
    );
  };

  const setTimeInputErrors = (isValid, fieldName) => {
    const fieldInputName = getPrefixedData(fieldName);
    if (isValid) return errors.interviews?.[index][fieldName] && clearError(fieldInputName);

    setValue(fieldInputName, null);
    return setError(
      fieldInputName,
      strings.sendouts.interviews.timeRequired.type,
      strings.sendouts.interviews.timeRequired.message
    );
  };

  const handleChangeTypeTime = (e, value) => {
    setValue(
      getPrefixedData(formItem.interviewSchedule),
      value?.id === optionsRenderTimes.rangeHour,
      true
    );
    setValue(getPrefixedData(formItem.typeTime), value, true);
  };

  const validInterviewDateWithTimezone = (timezone, interviewDateValue) => {
    const interviewTypeValue = getValues(getPrefixedData(formItem.typeTime))?.id;
    const interviewTimeValue = getValues(getPrefixedData(formItem.hourFixed));
    const interviewTimeRangeFromValue = getValues(getPrefixedData(formItem.hourRangeFrom));
    const interviewTimeRangeToValue = getValues(getPrefixedData(formItem.hourRangeTo));

    const hasDateAndTimezoneSelected = timezone && interviewDateValue;
    const hasTimeAndFixedHourSelected =
      hasDateAndTimezoneSelected &&
      interviewTimeValue &&
      interviewTypeValue === optionsRenderTimes.fixedHour;
    const hasTimeAndRangeHourSelected =
      hasDateAndTimezoneSelected &&
      interviewTimeRangeFromValue &&
      interviewTimeRangeToValue &&
      interviewTypeValue === optionsRenderTimes.rangeHour;

    if (hasTimeAndFixedHourSelected) {
      const isValidDateWithTimezone = isDatetimeInTimezoneAfter(
        timezone,
        interviewDateValue,
        interviewTimeValue
      );
      setTimeInputErrors(isValidDateWithTimezone, formItem.hourFixed);
    } else if (hasTimeAndRangeHourSelected) {
      const isHourRangeFromValid = isDatetimeInTimezoneAfter(
        timezone,
        interviewDateValue,
        interviewTimeRangeFromValue
      );
      setTimeInputErrors(isHourRangeFromValid, formItem.hourRangeFrom);

      const isHourRangeToValid = isDatetimeInTimezoneAfter(
        timezone,
        interviewDateValue,
        interviewTimeRangeToValue
      );
      setTimeInputErrors(isHourRangeToValid, formItem.hourRangeTo);
    }
  };

  const handleDateChange = (name, value) => {
    const interviewTimezoneValue = getValues(getPrefixedData(formItem.interviewTimezone));
    validInterviewDateWithTimezone(interviewTimezoneValue?.name, value);
    setValue(getPrefixedData(formItem.interviewDate), value ? moment(value) : null, true);
  };

  const handleTimezone = (e, value) => {
    const interviewDateValue = getValues(getPrefixedData(formItem.interviewDate));
    validInterviewDateWithTimezone(value?.name, interviewDateValue);
    setValue(getPrefixedData(formItem.interviewTimezone), value, true);
  };

  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const isInterviewDetailsEnabled = checkIfFeatureFlagEnabled(
    FeatureFlags.SendoutsInterviewDetailsActions
  );

  return (
    <div>
      {index > 0 && <hr className={classes.marginHr} />}

      <When condition={!!interviewId}>
        <div className={classes.interviewActionsContainer}>
          {!disabled && <div className={classes.editInterviewBtnContainer}>{getEditButton()}</div>}
          <When condition={isInterviewDetailsEnabled}>
            <InterviewDetails
              variant="interviewDetails"
              onEditInterviewClick={toggleEditable}
              interviewId={interviewId}
              isEditActionEnabled={false}
            />
          </When>
        </div>
      </When>

      <FPRadioGroup
        disabled={disabled || isEditable}
        error={!!isError(formItem.typeInterview)}
        errorMessage={!!isError(formItem.typeInterview) && `Type of Interview is required`}
        name={getPrefixedData(formItem.typeInterview)}
        options={interviewTypes.map(setStringTheIds) || optionsTypeInterview}
        value={
          watcher[getPrefixedData(formItem.typeInterview)]
            ? watcher[getPrefixedData(formItem.typeInterview)].toString()
            : ''
        }
        onChange={e => setValue(getPrefixedData(formItem.typeInterview), e.target.value, true)}
      />

      <div className="grid-2x">
        <CustomDatePicker
          disablePast
          disabled={disabled || isEditable}
          isClearable
          error={!!isError(formItem.interviewDate)}
          helperText={!!isError(formItem.interviewDate) && `Date is required`}
          label="Date *"
          name={getPrefixedData(formItem.interviewDate)}
          value={watcher[getPrefixedData(formItem.interviewDate)] || null}
          onDateChange={handleDateChange}
        />

        <AutocompleteSelectConnected
          disabled={disabled || isEditable}
          defaultOptions={optionsTypeTimes}
          placeholder="Time Frame *"
          error={!!isError(formItem.typeTime)}
          errorText={!!isError(formItem.typeTime) && `You must choose type of Time Frame`}
          onChange={handleChangeTypeTime}
          selectedValue={watcher[getPrefixedData(formItem.typeTime)]}
        />

        {/* Custom render by Time Frame */}
        {customRender[watcher[getPrefixedData(formItem.typeTime)]?.id]}

        {watcher[getPrefixedData(formItem.typeTime)] && (
          <AutocompleteSelectConnected
            disabled={disabled || isEditable || false}
            data-testid="autocomplete-select-timezone"
            name={formItem.interviewTimezone}
            placeholder="Select Timezone *"
            defaultOptions={
              isTimezoneExist(watcher[getPrefixedData(formItem.interviewTimezone)])
                ? [...longTimezones, watcher[getPrefixedData(formItem.interviewTimezone)]]
                : longTimezones
            }
            error={!!isError(formItem.interviewTimezone)}
            errorText={!!isError(formItem.interviewTimezone) && `Timezone is required`}
            renderOption={record => (
              <p className={classes.marginZero}>
                {record.title} <br />
                <Typography variant="caption" component="span" color="textSecondary">
                  ({record.offset_title})
                </Typography>
              </p>
            )}
            onChange={handleTimezone}
            selectedValue={watcher[getPrefixedData(formItem.interviewTimezone)]}
          />
        )}
      </div>
      {status !== interviewItemsStates.firstInterview && (
        <InterviewExtraCC {...props} disabled={disabled || isEditable} />
      )}

      {watcher[getPrefixedData(formItem.typeTime)]?.id === optionsRenderTimes.fixedHour && (
        <div className={classes.row}>
          <FormControlLabel
            disabled={disabled || isEditable}
            onChange={(event, checked) =>
              setValue(getPrefixedData(formItem.interviewSchedule), checked)
            }
            label="Don't send reminders"
            value={watcher[getPrefixedData(formItem.interviewSchedule)] || ''}
            labelPlacement="end"
            control={
              <Checkbox
                color="primary"
                checked={watcher[getPrefixedData(formItem.interviewSchedule)]}
              />
            }
          />
          <FPHint
            variant="info"
            disabled={disabled || isEditable}
            size="sm"
            hint={strings.sendouts.dontSendReminder}
          />
        </div>
      )}
      {!!onDelete && (
        <div className={classes.row}>
          <Button
            className={classes.btnRemove}
            onClick={() => onDelete(index)}
            startIcon={<CloseIcon width={20} height={20} fill={colors.error} />}
          >
            Click to remove
          </Button>
        </div>
      )}
    </div>
  );
};

const InterviewExtraCC = ({ form, index, disabled, prefix }: any) => {
  const classes = stylesClasses();
  const { register, watch, unregister, setValue, errors, getValues } = form;

  const getPrefixedData = useCallback(name => prefix + name, [prefix]);
  const watcher = watch([
    getPrefixedData(formItem.interviewMail),
    getPrefixedData(formItem.ownerEmail),
    getPrefixedData(formItem.ownerName)
  ]);

  const isError = target => errors[interviewName] && errors[interviewName][index]?.[target];

  const getRemoveEmailFormat = emails => emails && emails.map(email => email.work_email);
  const setEmailFormat = emails =>
    emails && emails.map(email => (typeof email === 'string' ? { work_email: email } : email));

  useEffect(() => {
    register(getPrefixedData(formItem.ownerEmail), {
      validate(val) {
        const isNotMail = !VALIDATION_REGEXS.EMAIL.test(val);
        if (isNotMail && val) return 'Wrong Email provided';

        return (
          !(getValues(getPrefixedData(formItem.ownerName)) && !val) || 'Please complete the field'
        );
      }
    });
    register(getPrefixedData(formItem.ownerName), {
      validate(val) {
        return (
          !(getValues(getPrefixedData(formItem.ownerEmail)) && !val) || 'Please complete the field'
        );
      }
    });
    register(getPrefixedData(formItem.interviewMail));
    return () =>
      unregister([
        getPrefixedData(formItem.interviewMail),
        getPrefixedData(formItem.ownerEmail),
        getPrefixedData(formItem.ownerName)
      ]);
  }, [getPrefixedData, getValues, register, unregister]);

  return (
    <>
      <div className={classes.row}>
        <Typography variant="body1" color={!disabled ? 'initial' : 'textSecondary'}>
          Other Interviewer (optional)
        </Typography>
        <FPHint disabled={disabled} hint={strings.sendouts.nextInterviewDiferent} />
      </div>
      <div className="grid-2x">
        <TextBox
          disabled={disabled}
          label="Full Name"
          name={getPrefixedData(formItem.ownerName)}
          value={watcher[getPrefixedData(formItem.ownerName)]}
          error={!!isError(formItem.ownerName)}
          errorText={!!isError(formItem.ownerName) && isError(formItem.ownerName).message}
          onChange={debounce(async (model, value) =>
            setValue(getPrefixedData(formItem.ownerName), value)
          )}
        />
        <TextBox
          disabled={disabled}
          label="Email"
          name={getPrefixedData(formItem.ownerEmail)}
          value={watcher[getPrefixedData(formItem.ownerEmail)]}
          error={!!isError(formItem.ownerEmail)}
          errorText={!!isError(formItem.ownerEmail) && isError(formItem.ownerEmail).message}
          onChange={debounce(async (model, value) =>
            setValue(getPrefixedData(formItem.ownerEmail), value)
          )}
        />
      </div>
      <EmailPicker
        disabled={disabled}
        label="CC"
        placeholder="CC Emails"
        endpoint={Endpoints.Search}
        typeaheadParams={{
          entityType: EntityType.HiringAuthority,
          inColumns: ['ha.work_email']
        }}
        maxItems={10}
        selectedValue={setEmailFormat(watcher[getPrefixedData(formItem.interviewMail)]) || []}
        onSelect={(name, values) =>
          setValue(getPrefixedData(formItem.interviewMail), getRemoveEmailFormat(values))
        }
        allowNewItems
      />
    </>
  );
};

InterviewSchedule.defaultProps = {
  maxOfInterviews: 5
};

export default InterviewSchedule;
