import React from 'react';
import Grid from '@material-ui/core/Grid';
import strings from 'strings';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { EmptySendouts } from 'UI/res/images';

import { sendoutStyles } from '../styles';

export const errorSendoutLoad = (
  <Grid container style={sendoutStyles.wrapperError}>
    <EmptyPlaceholder
      title={strings.shared.errors.loadFail}
      subtitle={strings.sendouts.errors.loading}
      customEmptyState={<EmptySendouts />}
    />
  </Grid>
);
