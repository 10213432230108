import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';
import { flexAlignCenterFlexEnd, flexAlignCenterJustifyCenter } from 'UI/utils/styles';

const { primary, secondary } = THEME.palette;

const sharedLabelStyles = {
  fontSize: 14,
  fontWeight: '400 !important',
  letterSpacing: '0.17px',
  lineHeight: '20px'
};

export const useStyles = makeStyles(theme => ({
  amountLabel: {
    color: primary.light,
    fontFamily: theme.typography.fontFamilyHeader,
    fontSize: 24,
    fontWeight: '500 !important',
    letterSpacing: '0.12px',
    lineHeight: '36px',
    marginRight: theme.spacing(1)
  },
  centerItems: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: ({ isSmallViewPort }) => (isSmallViewPort ? 'start' : 'center')
  },
  container: {
    backgroundColor: secondary.FP200,
    marginBottom: theme.spacing(1.5),
    width: '100%',
    '&:hover': {
      backgroundColor: secondary.main
    }
  },
  content: {
    padding: `${theme.spacing(2)}px !important`,
    width: '100%'
  },
  contentItem: {
    marginBottom: theme.spacing(3)
  },
  secondaryLabel: {
    ...sharedLabelStyles,
    color: secondary.FP700
  },
  divider: {
    height: '1px',
    margin: theme.spacing(0.5, 0, 1, 0)
  },
  dividerContainer: {
    margin: theme.spacing(0, 1, 0, 2)
  },
  extraUsersContainer: {
    display: 'flex',
    justifyContent: ({ isSmallViewPort }) => isSmallViewPort && 'flex-end'
  },
  floatingMenu: {
    minWidth: theme.spacing(31.25),
    padding: theme.spacing(2.75, 2.75, 0, 2.75)
  },
  subtitleLabel: {
    ...sharedLabelStyles,
    color: theme.palette.grey[800],
    marginTop: theme.spacing(0.5),
    opacity: '0.60'
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
  moreRecruitersLabel: {
    ...sharedLabelStyles,
    color: primary.light,
    fontWeight: '700 !important',
    marginTop: theme.spacing(0.5),

    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  popover: {
    borderRadius: theme.spacing(1.5),
    marginTop: theme.spacing(0.5)
  },
  recruiterInfoContainer: {
    display: 'flex',
    justifyContent: ({ isSmallViewPort }) => isSmallViewPort && 'flex-end'
  },
  recruitersInvolvedContainer: {
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  recruiterLabel: {
    ...sharedLabelStyles,
    color: primary.light,
    fontFamily: theme.typography.fontFamilyContent
  },
  surplusContainer: {
    paddingLeft: ({ isSmallViewPort }) => isSmallViewPort && theme.spacing(1.75)
  },
  surplusRecruiterLabel: {
    color: '#6E81F9',
    fontFamily: theme.typography.fontFamilyContent,
    fontSize: 14,
    fontWeight: '500 !important',
    letterSpacing: '0.11px',
    lineHeight: '22px',
    marginTop: theme.spacing(1),
    textTransform: 'capitalize'
  },
  surplusTeamLabel: {
    ...sharedLabelStyles,
    color: theme.palette.grey[800],
    fontFamily: theme.typography.fontFamilyContent,
    marginTop: theme.spacing(0.5),
    opacity: '0.38'
  },
  teamInfoContainer: {
    ...flexAlignCenterFlexEnd
  },
  teamInfoContainerCenter: {
    ...flexAlignCenterJustifyCenter
  },
  teamLabel: {
    color: theme.palette.grey[800],
    fontFamily: theme.typography.fontFamilyContent,
    fontSize: 10,
    fontWeight: '500',
    letterSpacing: '1.5px',
    lineHeight: '16px',
    opacity: '0.60',
    paddingLeft: theme.spacing(0.5),
    textTransform: 'uppercase'
  },
  usersInfoContainer: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: ({ isSmallViewPort }) => !isSmallViewPort && theme.spacing(1)
  }
}));

export const useOverridedStyles = makeStyles(theme => ({
  paper: {
    borderRadius: theme.spacing(1.5),
    marginTop: theme.spacing(0.5)
  }
}));
