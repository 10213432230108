// @flow
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { confirm, showAlert, showFile } from 'actions/app';
import copy from 'copy-to-clipboard';
import { globalStyles } from 'GlobalStyles';
import capitalize from 'lodash/capitalize';
import { getCurrentUser } from 'services/Authentication';
import { FeeAgreementRoleHierarchy, getHighestUserRoleInHierarchy } from 'services/Authorization';
import {
  cancelServiceAgreementRequest,
  createPreview,
  declineByCoach,
  declineByOps,
  reactivateServiceAgreement,
  sendReminder,
  sendRevalidationToCoach,
  sendRevalidationToOps,
  switchSigningProvider,
  updateSignersEmail,
  validateByCoach,
  validateByOps,
  voidServiceAgreement
} from 'services/FeeAgreement';
import strings from 'strings';
import type { DisplayedFile } from 'types/app';
import SaveButton from 'UI/components/atoms/SaveButton';
import Text from 'UI/components/atoms/Text';
import FileUploader from 'UI/components/molecules/FileUploader';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { Endpoints } from 'UI/constants/endpoints';
import { PaymentMode, PaymentTermsMode } from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { FeeAgreementFields, FeeAgreementStatus } from 'UI/constants/status';
import { getActions } from 'UI/utils/actions-engine';
import {
  getFeeAgreementFieldsByPayment,
  getFeeAgreementFieldsByPaymentWithVerbiage
} from 'UI/utils/feeagreement';

import FeeAgreementDeclineForm from '../FeeAgreementDeclineForm';
import FeeAgreementEmailsForm from '../FeeAgreementEmailsForm';
import FeeAgreementLogs from '../FeeAgreementLogs';
import { getPaymentPeriodsFromForm } from '../FeeAgreementPaymentTerms';
import FeeAgreementRevalidationForm from '../FeeAgreementRevalidationForm';
import FeeAgreementSummaryFields from '../FeeAgreementSummaryFields';
import FeeAgreementValidationInputs from '../FeeAgreementValidationInputs';

import { rules } from './rules';
import { styles, useStyles } from './style';

type FeeAgreementSummaryDrawerProps = {
  feeAgreement: any,
  additionalData: any,
  onClose: () => void,
  onUpdate: () => any,
  onTemplateUploaded: any => any,
  onPreviewCreated: any => any,
  showConfirm: any => void,
  showAlert: any => void,
  showFile: DisplayedFile => void
};

const FeeAgreementSummaryDrawer = (props: FeeAgreementSummaryDrawerProps) => {
  const { feeAgreement, additionalData, onClose, onUpdate, onTemplateUploaded, onPreviewCreated } =
    props;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const currentUser = getCurrentUser();
  const highestRole = getHighestUserRoleInHierarchy(currentUser, FeeAgreementRoleHierarchy);
  const form = useForm({
    defaultValues: {
      declined_fields: [],
      declination_notes: ''
    }
  });
  const { handleSubmit, reset } = form;

  const { feeAgreementStatus, fee_agreement_payment_scheme_id } = feeAgreement || {};
  const paymentMode = fee_agreement_payment_scheme_id || PaymentMode.Standard;
  const paymentTermsMode = feeAgreement[FeeAgreementFields.PaymentTermsMode];

  const performAsyncMethod = async (method, formData = null) => {
    setUiState(prevState => ({ ...prevState, isSaving: true }));

    const result = await method(feeAgreement.id, formData);
    setUiState(prevState => ({ ...prevState, isSaving: false }));
    result.alert && result.alert.body && dispatch(showAlert(result.alert));

    result.success && onUpdate && onUpdate();

    return result;
  };

  const actions = {
    default: { title: strings.shared.ui.ok, action: onClose },
    view: {
      title: strings.feeAgreements.details.viewPdf,
      action: () => {
        feeAgreement.pdf_url && dispatch(showFile({ url: feeAgreement.pdf_url, useProxy: true }));
        onClose();
      }
    },
    viewTemplate: {
      title: strings.feeAgreements.details.viewContract,
      action: () => {
        feeAgreement.id &&
          dispatch(
            showFile({
              url: `${Endpoints.FeeAgreement}/${feeAgreement.id}/${Endpoints.FeeAgreementTemplate}`,
              explicitFileName: `contract_template_${feeAgreement.id}.pdf`
            })
          );
        onClose();
      }
    },
    sign: {
      title: strings.feeAgreements.details.sign,
      action: () => {
        feeAgreement.sign_url && window.open(feeAgreement.sign_url, '_blank');
        onClose();
      }
    },
    void: {
      title: strings.feeAgreements.details.void,
      action: () => {
        dispatch(
          confirm({
            severity: 'warning',
            title: strings.shared.ui.confirm,
            message: strings.feeAgreements.details.confirmVoid,
            confirmButtonText: strings.feeAgreements.details.void,
            cancelButtonText: strings.shared.ui.cancel,
            onConfirm: async ok => {
              if (!ok) return;
              await performAsyncMethod(voidServiceAgreement);
            }
          })
        );
      }
    },
    approve: {
      title: strings.feeAgreements.details.validate,
      action: async () => {
        const method =
          feeAgreementStatus?.id === FeeAgreementStatus.PendingCoachValidation
            ? validateByCoach
            : validateByOps;
        dispatch(
          confirm({
            severity: 'warning',
            title: strings.shared.ui.confirm,
            message: strings.feeAgreements.details.confirmApprove,
            confirmButtonText: strings.feeAgreements.details.approve,
            cancelButtonText: strings.shared.ui.cancel,
            onConfirm: async ok => {
              if (!ok) return;
              await performAsyncMethod(method);
            }
          })
        );
      }
    },
    decline: {
      title: strings.feeAgreements.details.declineChange,
      action: () =>
        setUiState(prevState => ({ ...prevState, isDeclining: true, showBackNavigation: true }))
    },
    declining: {
      title: strings.feeAgreements.details.decline,
      type: 'submit',
      action: async formData => {
        const method =
          feeAgreementStatus?.id === FeeAgreementStatus.PendingCoachValidation
            ? declineByCoach
            : declineByOps;
        await performAsyncMethod(method, formData);
      },
      backAction: () => {
        setUiState(prevState => ({
          ...prevState,
          isDeclining: false,
          showBackNavigation: false
        }));
      }
    },
    resend: {
      title: strings.feeAgreements.details.sendReminder,
      action: async () => {
        await performAsyncMethod(sendReminder);
      }
    },
    cancel: {
      title: strings.feeAgreements.details.cancelRequest,
      action: async () => {
        dispatch(
          confirm({
            severity: 'warning',
            title: strings.shared.ui.confirm,
            message: strings.feeAgreements.details.confirmCancel,
            onConfirm: async ok => {
              if (!ok) return;
              await performAsyncMethod(cancelServiceAgreementRequest);
            }
          })
        );
      }
    },
    revalidate: {
      title: strings.feeAgreements.details.revalidate,
      type: 'submit',
      action: async formData => {
        const finalData = {
          ...formData,
          [FeeAgreementFields.PaymentDays]: getPaymentPeriodsFromForm(formData)
        };
        const method =
          feeAgreement.fee_agreement_status_id === FeeAgreementStatus.DeclinedByCoach
            ? sendRevalidationToCoach
            : sendRevalidationToOps;

        await performAsyncMethod(method, finalData);
      }
    },
    createPreview: {
      title: strings.feeAgreements.details.preview,
      action: async () => {
        const result = await performAsyncMethod(
          createPreview,
          {
            template_id: additionalData.template
          },
          false,
          false
        );
        if (result.success && result.data) {
          onPreviewCreated && onPreviewCreated(result.data);
          onClose();
        }
      }
    },
    copyLink: {
      title: strings.feeAgreements.details.copyLink,
      action: () => feeAgreement.sign_url && copy(feeAgreement.sign_url)
    },
    updatingEmail: {
      title: strings.feeAgreements.details.updateEmails,
      type: 'submit',
      action: async formData => {
        const pluckedEmails = formData.cc_emails.map(each => each.work_email);
        const finalData = {
          ...formData,
          cc_emails: pluckedEmails
        };
        await performAsyncMethod(updateSignersEmail, finalData, true, false);
      },
      backAction: () =>
        setUiState(prevState => ({
          ...prevState,
          isUpdatingEmail: false,
          showBackNavigation: false
        }))
    },
    updateEmail: {
      title: strings.feeAgreements.details.changeSigner,
      action: () =>
        setUiState(prevState => ({
          ...prevState,
          isUpdatingEmail: true,
          showBackNavigation: true
        }))
    },
    reactivate: {
      title: strings.feeAgreements.details.reactivateTitle,
      confirmButtonText: strings.feeAgreements.details.reactivateButton,
      action: async () =>
        dispatch(
          confirm({
            severity: 'warning',
            title: strings.shared.ui.confirm,
            message: strings.feeAgreements.details.confirmReactivate,
            onConfirm: async ok => {
              if (!ok) return;
              await performAsyncMethod(reactivateServiceAgreement);
            }
          })
        )
    },
    switchProvider: {
      title: strings.feeAgreements.details.switchServiceTitle,
      action: async () => {
        dispatch(
          confirm({
            severity: 'warning',
            title: strings.shared.ui.confirm,
            confirmButtonText: strings.feeAgreements.details.switchServiceButton,
            cancelButtonText: strings.shared.ui.close,
            message: strings.feeAgreements.details.confirmSwitch,
            content: (
              <ul className={classes.confirmChecklist}>
                <li>
                  Hiring Authority has added <b>noreply@mail.hellosign.com</b> to her contact list
                </li>
                <li>
                  Hiring Authority has looked for this email subject:
                  <b>
                    <i> Company Name</i> service agreement with gpac - Signature requested by gpac
                    Client Service
                  </b>{' '}
                  in her spam/junk folders
                </li>

                <li>Hiring Authority&apos;s email is correct and has not bounced</li>
              </ul>
            ),
            onConfirm: async ok => {
              if (!ok) return;
              await performAsyncMethod(switchSigningProvider);
            }
          })
        );
      }
    }
  };

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false,
    isFormDisabled: false,
    isReadOnly: false,
    isDeclining: false,
    isRevalidating: false
  });

  useEffect(() => {
    reset(feeAgreement);
  }, [feeAgreement, reset]);

  const onSubmit = async formData => {
    if (primaryAction.type !== 'submit') return;
    await primaryAction.action(formData);
  };

  const handleFileUploaded = uploadResponse =>
    onTemplateUploaded && onTemplateUploaded(uploadResponse);

  const feeAgreementFields = feeAgreement?.verbiage_changes
    ? getFeeAgreementFieldsByPaymentWithVerbiage(paymentMode, paymentTermsMode)
    : getFeeAgreementFieldsByPayment(paymentMode, paymentTermsMode);

  const shouldValidateVerbiage =
    feeAgreement?.verbiage_changes_requested &&
    (feeAgreementStatus?.id === FeeAgreementStatus.PendingOpsValidation ||
      feeAgreementStatus?.id === FeeAgreementStatus.PendingCoachValidation) &&
    highestRole?.id === Roles.Operations &&
    !additionalData?.template;

  const availableActions = getActions(
    !additionalData?.template ? feeAgreementStatus?.id : FeeAgreementStatus.PendingOpsTemplate,
    highestRole?.id,
    rules,
    {
      agreement: feeAgreement,
      user: currentUser,
      uiState
    }
  );
  const primaryAction = actions[availableActions.primary];

  const handleBack = () => {
    primaryAction.backAction && primaryAction.backAction();
  };

  const getFieldsToValidate = () => {
    const fields = [];
    feeAgreement.fee_percentage_change_requested && fields.push(FeeAgreementFields.Percentage);
    feeAgreement.guarantee_days_change_requested && fields.push(FeeAgreementFields.GuaranteeDays);
    feeAgreement.verbiage_changes_requested && fields.push(FeeAgreementFields.Verbiage);
    !!feeAgreement.flat_fee_amount && fields.push(FeeAgreementFields.FlatAmount);

    (paymentTermsMode === PaymentTermsMode.DueOn30 ||
      paymentTermsMode === PaymentTermsMode.Split) &&
      fields.push(FeeAgreementFields.PaymentTerms);

    return fields;
  };

  const renderForm = () => {
    if (availableActions.primary === 'declining') {
      return <FeeAgreementDeclineForm feeAgreement={feeAgreement} />;
    }
    if (availableActions.primary === 'revalidate') {
      return <FeeAgreementRevalidationForm feeAgreement={feeAgreement} />;
    }
    if (availableActions.primary === 'approve') {
      const fieldsToValidate = getFieldsToValidate();
      const summaryFields = feeAgreementFields.filter(field => !fieldsToValidate.includes(field));
      return (
        <>
          {fieldsToValidate && fieldsToValidate.length > 0 && (
            <>
              <Text
                variant="subtitle1"
                text={strings.feeAgreements.details.requestedChanges}
                customStyle={styles.changesLabel}
              />
              <FeeAgreementValidationInputs
                outPutValue
                paymentMode={paymentMode}
                initialValues={feeAgreement}
                fields={fieldsToValidate}
              />
              <Divider style={globalStyles.mediumDivider} />
            </>
          )}
          <FeeAgreementSummaryFields feeAgreement={feeAgreement} fields={summaryFields} />
        </>
      );
    }
    if (availableActions.primary === 'updatingEmail') {
      return (
        <FeeAgreementEmailsForm
          ccEmails={feeAgreement?.cc_emails || []}
          currentEmail={feeAgreement?.ha_email || feeAgreement?.hiringAuthority?.work_email}
        />
      );
    }
    return (
      <>
        <FeeAgreementSummaryFields
          feeAgreement={feeAgreement}
          fields={feeAgreementFields}
          isFullSummary
        />
      </>
    );
  };

  return (
    <DrawerContentLayout
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      onBack={handleBack}
      variant="borderless"
      uiState={uiState}
      title={strings.feeAgreements.details.title}
      isSaveButtonMode={false}
      isBottomToolbarNeeded={!uiState.isLoading}
      isTopToolbarNeeded
      customPrimaryButton={({ classes: flClasses }) =>
        shouldValidateVerbiage && availableActions.primary === 'approve' ? (
          <FileUploader
            maxNumberOfFiles={1}
            files={[]}
            endpoint={`${Endpoints.FeeAgreement}/${Endpoints.FeeAgreementCreateTemplateDraft}`}
            fileNameField="file_name"
            mode="button"
            text={strings.feeAgreements.details.createTemplate}
            alwaysReplace
            onFileUploadedSuccesfully={handleFileUploaded}
            selectorClassName={flClasses.primaryButton}
          />
        ) : (
          <SaveButton
            initialText={primaryAction.title}
            onProgressText={strings.shared.ui.working}
            type={primaryAction.type === 'submit' ? 'submit' : 'button'}
            isSaving={uiState.isSaving}
            isSuccess={uiState.isSuccess}
            disabled={uiState.isSaving}
            onClick={primaryAction.type !== 'submit' ? primaryAction.action : undefined}
            className={flClasses.primaryButton}
          />
        )
      }
      moreActions={availableActions.moreActions.map(each => ({
        title: actions[each].title,
        action: actions[each].action,
        visible: true
      }))}
    >
      {feeAgreement && (
        <FormContext {...form}>
          {renderForm()}

          <Divider style={globalStyles.mediumDivider} />
          {feeAgreement?.electronic_signature_provider_id && (
            <Typography
              variant="subtitle2"
              color="textSecondary"
              paragraph
              className={classes.providerHelper}
            >
              {strings.feeAgreements.details.poweredBy}{' '}
              <strong>
                {feeAgreement?.electronicSignatureProvider?.title ||
                  capitalize(feeAgreement.electronic_signature_provider_id)}
              </strong>
            </Typography>
          )}
          <FeeAgreementLogs items={feeAgreement?.eventLogs} />
        </FormContext>
      )}
    </DrawerContentLayout>
  );
};

FeeAgreementSummaryDrawer.defaultProps = {
  additionalData: null
};

export default FeeAgreementSummaryDrawer;
