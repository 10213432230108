// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toggleMenu } from 'actions/map';
import MapSideMenu from 'UI/components/organisms/MapSideMenu';
import CollapsiblePanel from 'UI/components/templates/CollapsiblePanel';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import MapBox from 'UI/components/templates/MapBox';
import { PageTitles } from 'UI/constants/defaults';

import { useStyles } from './styles';

type MapLayoutProps = {
  isSideMenuOpen: boolean,
  onMenuToggle: () => mixed
};

const MapLayout = ({ isSideMenuOpen, onMenuToggle }: MapLayoutProps) => {
  const classes = useStyles();

  useEffect(() => {
    document.title = PageTitles.Map;
  }, []);

  return (
    <ContentPageLayout mode="customLayout" className={classes.wrapper}>
      <CollapsiblePanel isSideMenuOpen={isSideMenuOpen} mode="inline" onToggle={onMenuToggle}>
        <MapSideMenu />
      </CollapsiblePanel>
      <MapBox />
    </ContentPageLayout>
  );
};

const mapStateToProps = ({ map }) => {
  return {
    isSideMenuOpen: map.ui.isSideMenuOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onMenuToggle: () => dispatch(toggleMenu())
  };
};

const MapLayoutConnected = connect(mapStateToProps, mapDispatchToProps)(MapLayout);

const Map = () => {
  return <MapLayoutConnected />;
};

export default Map;
