import { makeStyles } from '@material-ui/core';
import { flexJustifyEnd } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  dateText: {
    color: theme.palette.customColors.grey
  },
  savedDateText: {
    color: theme.palette.customColors.grey,
    paddingBottom: theme.spacing(2)
  },
  deleteBtnContainer: flexJustifyEnd,
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    textTransform: 'none',
    margin: theme.spacing(2, 3),
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  tableClasses: {
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        backgroundColor: 'transparent !important',
        paddingLeft: theme.spacing(3)
      }
    },
    '& .MuiCardContent-root': {
      padding: '14px !important',
      backgroundColor: 'black'
    },
    '& .MuiTableCell-body': {
      paddingLeft: theme.spacing(3)
    }
  },
  draftsCard: {
    padding: 'unset !important'
  }
}));
