// @flow
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import { PaymentMode, PaymentModes } from 'UI/constants/entityTypes';
import {
  POSITIVE_VALUES_VALIDATION,
  REGULAR_PERCENT_VALIDATION,
  REQUIRED_VALIDATION
} from 'UI/utils/index';

import { useStyles } from '../styles';

type UnmanagedFeeAgreementProps = {
  readOnly: boolean,
  required: boolean
};

const UnmanagedFeeAgreementBaseFields = (props: UnmanagedFeeAgreementProps) => {
  const { readOnly, required } = props;
  const classes = useStyles(props);

  const form = useFormContext();
  const { register, unregister, errors, setValue, watch, getValues, triggerValidation } = form;

  const fieldsConfig = useMemo(
    () => [
      {
        name: 'fee_agreement_payment_scheme_id',
        validation: required ? REQUIRED_VALIDATION : undefined
      },
      {
        name: 'guarantee_days',
        validation: required
          ? { ...REQUIRED_VALIDATION, ...POSITIVE_VALUES_VALIDATION }
          : POSITIVE_VALUES_VALIDATION
      },
      {
        name: 'fee_percentage',
        validation: {
          validate(val) {
            const payMode = getValues('fee_agreement_payment_scheme_id');
            const percentage = Number.parseFloat(val);
            const isPercentage = percentage > 0 && percentage <= 100;
            return (
              (!required && val === '') ||
              payMode === PaymentMode.Flat ||
              (payMode !== PaymentMode.Flat &&
                isPercentage &&
                percentage >= REGULAR_PERCENT_VALIDATION.min.value) ||
              REGULAR_PERCENT_VALIDATION.min.message
            );
          }
        }
      },
      {
        name: 'flat_fee_amount',
        type: 'custom',
        validation: {
          validate(val) {
            const payMode = getValues('fee_agreement_payment_scheme_id');
            const flatFee = Number.parseFloat(val);
            return (
              !required ||
              payMode !== PaymentMode.Flat ||
              (payMode === PaymentMode.Flat && flatFee) ||
              REQUIRED_VALIDATION.required
            );
          }
        }
      }
    ],
    [getValues, required]
  );

  useEffect(() => {
    fieldsConfig.forEach(({ name, type, validation }) => {
      register({ name, type }, validation);
    });

    return () => unregister(fieldsConfig.map(({ name }) => name));
  }, [register, unregister, fieldsConfig]);

  const formValues = watch();
  const { fee_agreement_payment_scheme_id: paymentMode } = formValues;

  const handleFieldChange = (field: string, value: any) => setValue(field, value, true);

  const handleRadioChange = ({ target }) => {
    setValue(target.name, target.value);
    triggerValidation();
  };
  const requiredMarker = required ? '*' : '';

  return (
    <>
      <FPRadioGroup
        name="fee_agreement_payment_scheme_id"
        label={strings.feeAgreements.fields.type}
        labelVariant="subtitle1"
        options={PaymentModes}
        value={paymentMode}
        onChange={handleRadioChange}
        disabled={readOnly}
      />
      {(paymentMode === PaymentMode.Standard ||
        paymentMode === PaymentMode.Conversion ||
        paymentMode === PaymentMode.BaseSalary) && (
        <Box className={classes.fieldGroup}>
          <TextBox
            name="fee_percentage"
            label={`${strings.feeAgreements.creation.feePercent} ${requiredMarker}`}
            value={formValues.fee_percentage}
            error={!!errors.fee_percentage}
            errorText={errors.fee_percentage && errors.fee_percentage.message}
            onChange={handleFieldChange}
            inputType="percentage"
            disabled={readOnly}
          />
        </Box>
      )}
      {paymentMode === PaymentMode.Flat && (
        <Box className={classes.fieldGroup}>
          <TextBox
            name="flat_fee_amount"
            label={`${strings.feeAgreements.creation.flatAmount} ${requiredMarker}`}
            value={formValues.flat_fee_amount}
            error={!!errors.flat_fee_amount}
            errorText={errors.flat_fee_amount && errors.flat_fee_amount.message}
            onChange={handleFieldChange}
            inputType="currency"
            disabled={readOnly}
          />
        </Box>
      )}
      {paymentMode !== PaymentMode.Conversion && (
        <Box className={classes.fieldGroup}>
          <TextBox
            name="guarantee_days"
            label={`${strings.feeAgreements.creation.guaranteeDays} ${requiredMarker}`}
            value={formValues.guarantee_days}
            error={!!errors.guarantee_days}
            errorText={errors.guarantee_days && errors.guarantee_days.message}
            onChange={handleFieldChange}
            inputType="number"
            disabled={readOnly}
          />
        </Box>
      )}
    </>
  );
};
UnmanagedFeeAgreementBaseFields.defaultProps = {
  readOnly: false,
  required: false
};

export default UnmanagedFeeAgreementBaseFields;
