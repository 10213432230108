// @flow
import { makeRequest } from 'services/utils';
import strings from 'strings';
import type { Bulk } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';

const { BulkEmailsDraftById } = Endpoints;
const {
  success: {
    updateBulkEmailDraft: updateDraftSuccessCopies,
    deleteBulkEmailDraft: deleteDraftSuccessCopies
  },
  errors: {
    updateBulkEmailDraft: updateDraftErrorCopies,
    deleteBulkEmailDraft: deleteDraftErrorCopies
  }
} = strings.bulkEmails;

export const updateBulkEmailDraft = (bulkParams: Bulk) =>
  makeRequest({
    url: BulkEmailsDraftById.replace(/:id/, bulkParams.bulk_id),
    method: 'put',
    data: bulkParams,
    alertConfig: {
      success: updateDraftSuccessCopies,
      error: updateDraftErrorCopies
    }
  });

export const deleteBulkEmailDraft = bulkId =>
  makeRequest({
    url: BulkEmailsDraftById.replace(/:id/, bulkId),
    method: 'delete',
    alertConfig: {
      success: deleteDraftSuccessCopies,
      error: deleteDraftErrorCopies
    }
  });
