import { userHasPermission, userHasRoles } from 'services/Authorization';

export const ensureUserHasAccess = ({ permissions, enabled, roles, user }) => {
  if (!enabled) {
    return false;
  }

  if (permissions) {
    return permissions.some(permission => userHasPermission(permission, user));
  }

  if (roles) {
    return userHasRoles(roles, user);
  }

  return true;
};

export const legacyEnsureUserHasAccess = ({ permissions, enabled, user }) => {
  if (!permissions && !enabled) {
    return false;
  }

  if (permissions && enabled) {
    return permissions.some(permission => userHasPermission(permission, user));
  }

  if (!permissions && enabled) {
    return true;
  }

  return false;
};
