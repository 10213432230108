// @flow
import React, { createContext, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { confirm as showConfirm, showAlert } from 'actions/app';
import strings from 'strings';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getFeatureFlags, getId, loadFromLocalStorage } from 'UI/utils';

type Props = {
  type: string,
  draftValidationFields: string[],
  children: React.Node
};

const hasDraftsEnabled = getFeatureFlags().includes(FeatureFlags.Drafts);

export const DraftsProviderContext = createContext();

export const InitialDraft = {
  formData: {},
  sheetData: {},
  attachments: [],
  isFromDirectory: false
};

export const milisecondsToSave = 300000;

const DraftsProvider = ({ type, draftValidationFields, children }: Props) => {
  const date = new Date();
  const [draft, setDraft] = useState({ ...InitialDraft, type });
  const [drafts, setDrafts] = useState(() => loadFromLocalStorage(`${type}-Drafts`, []));
  const history = useHistory();

  const dispatch = useDispatch();

  const hasRequiredFieldsForDraft = isDraftValid(draft.formData, draftValidationFields);
  const updateLocalStorageDrafts = useCallback(
    values => {
      localStorage.setItem(`${type}-Drafts`, JSON.stringify(values));
    },
    [type]
  );

  const entity = type === EntityType.Candidate ? 'Candidate' : 'Job Order';

  const informSuccess = () => {
    dispatch(
      showAlert({
        severity: 'success',
        ...strings.drafts.alert.success.save
      })
    );
  };

  const saveDraft = ({ shouldConfirm, values }) => {
    if (shouldConfirm) {
      dispatch(
        showConfirm({
          severity: 'warning',
          ...strings.drafts.confirmDialog.save,
          message: strings.formatString(strings.drafts.confirmDialog.save.message, {
            entity
          }),
          onConfirm: async ok => {
            if (!ok) return;
            values ? save(values) : save();
          }
        })
      );
    } else {
      save();
    }
  };

  const saveOnCancel = () => {
    dispatch(
      showConfirm({
        severity: 'warning',
        ...strings.drafts.confirmDialog.saveOnCancel,
        message: strings.formatString(strings.drafts.confirmDialog.saveOnCancel.message, {
          entity
        }),
        onConfirm: async () => {
          draft.id ? updateDraft({ ...draft }) : save();
          history.goBack();
        },
        onClose: async () => {
          setDraft(InitialDraft);
          history.goBack();
        }
      })
    );
  };

  const deleteDraft = draftId => {
    dispatch(
      showConfirm({
        severity: 'warning',
        ...strings.drafts.confirmDialog.delete,
        message: strings.formatString(strings.drafts.confirmDialog.delete.message, {
          entity
        }),
        onConfirm: async ok => {
          if (!ok) return;
          deleteDraftById(draftId);
          dispatch(
            showAlert({
              severity: 'success',
              ...strings.drafts.alert.success.delete
            })
          );
        }
      })
    );
  };

  const updateDraft = updatedDraft => {
    const displayAlert = () =>
      dispatch(
        showAlert({
          severity: 'success',
          ...strings.drafts.alert.success.save
        })
      );
    update(updatedDraft);
    displayAlert();
  };

  const save = (values?: Object) => {
    const newDraft = {
      id: getId(),
      savedOn: new Date(),
      ...draft
    };
    if (values) newDraft.formData = values;
    const newDrafts = [newDraft, ...drafts];
    setDrafts([...newDrafts]);
    updateLocalStorageDrafts(newDrafts);
    setDraft(newDraft);
    informSuccess();
  };

  const update = updatedDraft => {
    const updatedDrafts = drafts.map(item =>
      item.id === updatedDraft.id ? { ...updatedDraft, savedOn: date } : item
    );

    setDrafts(updatedDrafts);
    updateLocalStorageDrafts(updatedDrafts);
  };

  const setDraftWithCurrentFormValues = () => {
    setDraft({
      ...InitialDraft,
      formData: draft.formData,
      attachments: draft.attachments,
      sheetData: draft.sheetData
    });
  };

  const deleteDraftById = draftId => {
    const newDrafts = drafts.filter(item => item.id !== draftId);
    setDrafts([...newDrafts]);
    updateLocalStorageDrafts(newDrafts);
    if (draft?.id === draftId) setDraftWithCurrentFormValues();
  };

  const deleteAllDrafts = () => {
    dispatch(
      showConfirm({
        severity: 'warning',
        ...strings.drafts.confirmDialog.deleteAll,
        message: strings.formatString(strings.drafts.confirmDialog.deleteAll.message, {
          entity: type === EntityType.Candidate ? 'Candidates' : 'Job Orders'
        }),
        onConfirm: async ok => {
          if (!ok) return;
          setDrafts([]);
          updateLocalStorageDrafts([]);
          if (draft?.id) setDraftWithCurrentFormValues();
          dispatch(
            showAlert({
              severity: 'success',
              title: strings.drafts.alert.success.delete.title
            })
          );
        }
      })
    );
  };

  return (
    <DraftsProviderContext.Provider
      value={{
        deleteAllDrafts,
        deleteDraft,
        deleteDraftById,
        draft,
        drafts,
        hasRequiredFieldsForDraft,
        saveDraft,
        saveOnCancel,
        setDraft,
        updateDraft,
        updateLocalStorageDrafts
      }}
    >
      {children}
    </DraftsProviderContext.Provider>
  );
};

const isDraftValid = (values, draftValidationFields) => {
  return hasDraftsEnabled ? draftValidationFields.every(field => values[field]) : false;
};

export default DraftsProvider;
