// @flow

import { makeActionCreator } from './index';

export const TOGGLE_MENU_PHONE = 'TOGGLE_DASHBOARD_PHONE_FILTERS';
export const RESET_FILTERS_PHONE = 'RESET_DASHBOARD_PHONE_FILTERS';
export const SAVE_FILTERS_PHONE = 'SAVE_FILTERS_PHONE';
export const ADD_FILTER_PHONE = 'ADD_FILTER_PHONE';

export const resetFilters = makeActionCreator(RESET_FILTERS_PHONE);

/** Action creator for TOGGLE_MENU action */
export const toggleMenu = makeActionCreator(TOGGLE_MENU_PHONE);

export const saveFilters = makeActionCreator(SAVE_FILTERS_PHONE, 'payload');
export const addFilter = makeActionCreator(ADD_FILTER_PHONE, 'payload');
