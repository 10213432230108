// @flow
import React from 'react';

import { Content } from './Content';

interface DetailsOverviewProps {
  detailsOverviewFetchState: object;
}

export const DetailsOverview = ({ detailsOverviewFetchState }: DetailsOverviewProps) => {
  return <Content detailsOverview={detailsOverviewFetchState} />;
};
