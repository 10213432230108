import * as React from 'react';

const SvgEmailActionClock = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <g fill={fill}>
        <path d="M17.5 9.5a7.9 7.9 0 013.15.66.29.29 0 00.24 0 .26.26 0 00.11-.23V1.75a.18.18 0 00-.1-.15.15.15 0 00-.17 0L12.2 7.93a2.41 2.41 0 01-3.4 0L.27 1.64a.15.15 0 00-.17 0 .18.18 0 00-.1.15v10.5A1.75 1.75 0 001.75 14h8.41a.25.25 0 00.23-.14A8 8 0 0117.5 9.5z" />
        <path d="M9.73 7a1.08 1.08 0 001.54 0L19.75.76a.42.42 0 00.1-.45.42.42 0 00-.35-.31h-18a.45.45 0 00-.36.29.44.44 0 00.11.45zM17.5 11a6.5 6.5 0 106.5 6.5 6.51 6.51 0 00-6.5-6.5zm0 11a4.5 4.5 0 114.5-4.5 4.51 4.51 0 01-4.5 4.5z" />
        <path d="M19.5 16.75h-1a.25.25 0 01-.25-.25V15a.75.75 0 00-1.5 0v2.5a.76.76 0 00.75.75h2a.75.75 0 000-1.5z" />
      </g>
    </svg>
  );
};

export default SvgEmailActionClock;
