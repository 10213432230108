import { makeStyles } from '@material-ui/core/styles';
import { THEME as theme } from 'GlobalStyles';
import { layout } from 'UI/constants/dimensions';
import { colors } from 'UI/res';
import { flexAlignCenterFlexEnd } from 'UI/utils/styles';

const { borderRadius } = theme.shape;
const fullSize = `calc(100% - ${theme.spacing(5)}px)`;
const maxContentSize =
  alternativeSize =>
  ({ fitContent }) =>
    fitContent ? 'max-height' : alternativeSize;

export const useStyles = makeStyles({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(1200)]: {
      height: maxContentSize('70%'),
      maxHeight: '70%'
    },
    maxWidth: layout.modalMiddleSize.maxWidth,
    width: fullSize,
    height: maxContentSize(fullSize),
    backgroundColor: theme.palette.brandColors.FPWhite,
    borderRadius: theme.spacing(1.5),
    margin: 'auto',
    position: 'absolute',
    transform: `translate(-50%, -50%)`,
    top: '50%',
    left: '50%'
  },
  header: {
    borderRadius
  },
  headerWithArrow: {
    padding: `${theme.spacing(2, 2, 2, 1.5)} !important`,
    borderRadius
  },
  contentContainer: {
    flex: 2,
    overflow: 'auto'
  },
  footerContainer: {
    ...flexAlignCenterFlexEnd,
    backgroundColor: colors.sideBar,
    borderRadius
  }
});
