import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiListItemText-secondary': {
      fontSize: '0.9rem'
    },
    paddingLeft: theme.spacing(3)
  },
  fileIcon: {
    minWidth: 'unset',
    margin: theme.spacing(0, 2, 2, 0),
    position: 'absolute',
    top: '18px'
  },
  moreButton: {
    marginRight: theme.spacing(-2)
  }
}));
