import { getPreferences } from 'services/FiltersStorage';
import { BulkMenuItems, USER_FILTER_IDS } from 'UI/constants/defaults';
import {
  SvgCommonFileTextStar,
  SvgEmailActionClock,
  SvgPencilWriteAlternate,
  SvgSendFilled,
  SvgSingleNeutralActionsSetting
} from 'UI/res';
import { objectToArray } from 'UI/utils';

export const BULK_LAYOUT_PREFERENCES_KEY = 'bulkEmailPreferences';

export const ROLES_KEYS = {
  dc: 'dc',
  ops: 'ops',
  coach: 'coach',
  assistantRegionalDirector: 'assistantRegionalDirector'
};

export const BULK_USER_FILTERS = {
  mine: { id: USER_FILTER_IDS.Mine, title: 'My Bulks' },
  myTeam: { id: USER_FILTER_IDS.MyTeam, title: 'My Team Bulks' },
  myRegion: { id: USER_FILTER_IDS.MyRegion, title: 'My Region Bulks' }
};

export const BULK_FILTERS = {
  bulkTypes: 'bulkTypes',
  bulkTemplates: 'bulkTemplates',
  recruiters: 'recruiters',
  coaches: 'coaches'
};
export const COMMON_FILTERS = [BULK_FILTERS.bulkTypes, BULK_FILTERS.bulkTemplates];
export const SIDE_FILTERS = {
  all: [BULK_FILTERS.coaches, BULK_FILTERS.recruiters],
  [ROLES_KEYS.assistantRegionalDirector]: [BULK_FILTERS.recruiters],
  [ROLES_KEYS.coach]: [BULK_FILTERS.recruiters]
};

export const FIRST_PAGE = 1;
export const DEFAULT_EMAILS_TO_LOAD = 15;

export const ORDER_BULKS_OPTIONS = {
  [BulkMenuItems.Drafts]: {
    orderBy: 'createdDate',
    direction: 'desc'
  },
  [BulkMenuItems.Schedule]: {
    orderBy: 'sendDate',
    direction: 'asc'
  }
};

export const FILTERS_INITIAL = {
  user: BULK_USER_FILTERS.mine,
  side: {},
  dateRange: null
};

export const SEARCH_PARAMS_INITIAL = {
  pagination: 1,
  keyword: ''
};

const DATE_REQUEST_KEYS_BY_MODE = {
  [BulkMenuItems.Sent]: 'SendDate',
  [BulkMenuItems.Drafts]: 'UpdatedDate',
  [BulkMenuItems.Schedule]: 'SendDate'
};

export const getMappedFilters = (filters, mode) => {
  if (!filters || Object.keys(filters).length === 0)
    return { userFilter: BULK_USER_FILTERS.mine.id };

  const mappedFilters = { userFilter: filters.user.id };

  if (filters.dateRange) {
    const dateSuffix = DATE_REQUEST_KEYS_BY_MODE[mode];
    const dateKeyNames = {
      start: `start${dateSuffix}`,
      end: `end${dateSuffix}`
    };
    mappedFilters[dateKeyNames.start] = filters.dateRange.startDate;
    mappedFilters[dateKeyNames.end] = filters.dateRange.endDate;
  }

  objectToArray(filters.side).forEach(filter => {
    mappedFilters[filter.paramName] = filter.value.map(({ id }) => id);
  });

  return mappedFilters;
};

export const getFilters = () => {
  const savedFilters = getPreferences(BULK_LAYOUT_PREFERENCES_KEY);
  return Object.keys(savedFilters).length !== 0 ? savedFilters : FILTERS_INITIAL;
};

export const getMenuItemsLabels = userHasRoles => [
  {
    Icon: SvgSendFilled,
    item: BulkMenuItems.Sent,
    visible: true
  },
  {
    Icon: SvgCommonFileTextStar,
    item: BulkMenuItems.Template,
    visible: true
  },
  {
    Icon: SvgPencilWriteAlternate,
    item: BulkMenuItems.Drafts,
    visible: true
  },
  {
    Icon: SvgEmailActionClock,
    item: BulkMenuItems.Schedule,
    visible: true
  },
  {
    Icon: SvgSingleNeutralActionsSetting,
    item: BulkMenuItems.OptOut,
    visible: userHasRoles.ops
  }
];
