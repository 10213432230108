import React, { useCallback, useContext, useMemo, useState } from 'react';

const Context = React.createContext();

export const DrawerManagerProvider = ({ children }) => {
  const [drawerState, setDrawerState] = useState({
    activeDrawer: null,
    isOpen: false,
    contentProps: null
  });

  const closeDrawer = useCallback(() => {
    setDrawerState(prevState => ({
      ...prevState,
      activeDrawer: null,
      isOpen: false,
      contentProps: null
    }));
  }, []);

  const openDrawer = useCallback(({ activeDrawer, contentProps }) => {
    setDrawerState(prevState => ({
      ...prevState,
      isOpen: true,
      activeDrawer,
      contentProps: contentProps || null
    }));
  }, []);

  const value = useMemo(() => {
    return {
      drawerState,
      closeDrawer,
      openDrawer
    };
  }, [closeDrawer, drawerState, openDrawer]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useDrawerManager = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useDrawerManager must be used within a DrawerManagerProvider');
  }
  return context;
};
