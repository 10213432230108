// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { RouterProps } from 'react-router-dom';
/** API / EntityRoutes / Endpoints / EntityType */
import useDatatable, {
  buildRowActionsRendererDefinition,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import useMultipleSelection, { CustomTableToolbarSelect } from 'hooks/multipleSelection';
import { useSearchProject } from 'hooks/searchProject';
import { useUrlParamsUpdater } from 'hooks/urlParamsUpdater';
import { useQuickViewPreview } from 'hooks/useQuickViewPreview';
import LogRocket from 'logrocket';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
/** Tracking */
/** Atoms, Components and Styles */
/** Components */
import DataTable from 'UI/components/organisms/DataTable';
import FPQuickView from 'UI/components/organisms/inventoryProfiles/FPQuickView';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { backNavigateListConfig } from 'UI/constants/config';
import { PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { LogRocketCustomEvents } from 'UI/constants/LogRocketCustomEvents';
import { createCustomStaticRanges } from 'UI/utils';

import {
  ColumnsDefinitions,
  DateFilters,
  FiltersGroups,
  PeriodColumnBySection,
  VirtualProps
} from './columns';

const pageKey = 'joborders-list';
const numberOfActionColumns = 1;

const orderByOptions = {
  column: 'id',
  direction: 'desc'
};

const JobOrders = ({ history, location }: RouterProps) => {
  useEffect(() => {
    LogRocket.track(LogRocketCustomEvents.JobOrder.ListView);
  }, []);

  const initialPreferences = getColumnPreferences(
    pageKey,
    0,
    orderByOptions,
    ColumnsDefinitions,
    numberOfActionColumns,
    location.search
  );

  const [dateTypeFilter, setDateTypeFilter] = useState(DateFilters[0]);

  const {
    columnPreferences,
    count,
    data,
    filters,
    getData,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersApply,
    handleFiltersChange,
    handleFiltersToggle,
    handleKeywordChange,
    handleOneFilterChange,
    handlePageChange,
    handlePeriodChange,
    handlePerPageChange,
    handleSectionChange,
    listState,
    mainOptions,
    queryParams
  } = useDatatable({
    key: pageKey,
    endpoint: Endpoints.JobOrders,
    entityName: 'Job Orders',
    entityType: EntityType.Joborder,
    initialPreferences,
    columnsDefinitions: ColumnsDefinitions,
    orderByOptions,
    numberOfActionColumns,
    virtualProps: VirtualProps,
    periodDefaultValue: null,
    periodFilterColumn: PeriodColumnBySection,
    sendDateWithTimezone: true,
    shouldScrollOnNavigate: true
  });

  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  useUrlParamsUpdater({ history, location, orderBy });

  const { isLoading, showWholeSkeleton, isSideMenuOpen, page, perPage, keyword, userFilter } =
    listState;

  const dataForSelection = useMemo(
    () => data.map(item => ({ id: item.id, type: 'Job Order', type_id: 2 })),
    [data]
  );

  const {
    handleRowSelection,
    selectedIndexesInPage,
    multiSelectComponents,
    totalSelected,
    filteredItems
  } = useMultipleSelection({ data: dataForSelection, count, perPage });

  const { SearchProjectAction, SearchProjectForms } = useSearchProject({
    filteredItems,
    queryParams
  });

  useEffect(() => {
    document.title = PageTitles.JobOrder;
  }, []);

  const { entityId, quickViewState, listViewPreset, quickViewEntity, toggleQuickViewPreview } =
    useQuickViewPreview({ columns, data, entityType: EntityType.Joborder });

  const tableActions = buildRowActionsRendererDefinition({
    actions: [listViewPreset],
    namespace: EntityType.Joborder
  });

  const handleDateTypeFilterChange = (name, value) => {
    setDateTypeFilter(value);
    handleSectionChange && handleSectionChange(name, value.id);
  };

  const finalColumns = getColumnsToRender([...columns, tableActions]);

  const handleTabRefresh = async () => {
    await getData();
  };

  return (
    <FiltersLayout
      title="Job Orders"
      section={EntityType.Joborder}
      listSelector={
        <AutocompleteSelect
          name="userFilter"
          placeholder="Job Orders to show"
          selectedValue={userFilter || mainOptions[0]}
          onSelect={handleOneFilterChange}
          defaultOptions={mainOptions}
          disableClearable
        />
      }
      defaultRange={null}
      extraSelector={
        <AutocompleteSelect
          name="dateTypeFilter"
          placeholder="Filter By"
          selectedValue={dateTypeFilter}
          onSelect={handleDateTypeFilterChange}
          defaultOptions={DateFilters}
          disableClearable
        />
      }
      dateRangerPickerProps={{
        staticRanges: createCustomStaticRanges(additionalPeriodRanges)
      }}
      filters={filters}
      isSideMenuOpen={isSideMenuOpen}
      isLoading={showWholeSkeleton}
      groups={FiltersGroups}
      enableStore={false}
      onSearch={handleFiltersApply}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      titleLabelProps={backNavigateListConfig}
      onPeriodChange={handlePeriodChange}
    >
      <DataTable
        isExpandable
        loading={showWholeSkeleton}
        refreshing={isLoading}
        data={data}
        columns={finalColumns}
        count={count}
        filter={false}
        page={page}
        rowsPerPage={perPage}
        searchText={keyword}
        sortOrder={{ name: orderBy, direction }}
        columnOrder={columnOrder?.length ? columnOrder : undefined}
        draggableColumns={{
          enabled: true
        }}
        enableCustomFilters
        onToggleFilters={handleFiltersToggle}
        onSearchTextChange={handleKeywordChange}
        onColumnSortChange={handleColumnSortChange}
        onPerPageClick={handlePerPageChange}
        onPageClick={handlePageChange}
        onColumnDisplayClick={handleColumnDisplayChange}
        onColumnOrderChange={handleColumnOrderChange}
        onRowSelectionChange={handleRowSelection}
        rowsSelected={selectedIndexesInPage}
        selectToolbarPlacement="none"
        CustomToolbar={
          <CustomTableToolbarSelect total={totalSelected}>
            <SearchProjectAction />
          </CustomTableToolbarSelect>
        }
        components={multiSelectComponents}
      />
      <SearchProjectForms />

      <When condition={entityId && quickViewState.isQuickViewOpen}>
        <FPQuickView
          drawerProps={{
            open: quickViewState.isQuickViewOpen
          }}
          entityType={quickViewEntity}
          onClose={toggleQuickViewPreview}
          itemsToNavigate={data}
          onEditionCompleted={handleTabRefresh}
          id={entityId}
        />
      </When>
    </FiltersLayout>
  );
};
export default JobOrders;
