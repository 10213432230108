export const FormFieldsMap = {
  ParentCompany: {
    key: 'parentCompany'
  },
  CurrentCompany: {
    key: 'currentCompany'
  },
  Keyword: {
    key: 'keyword'
  },
  SiblingCompanies: {
    key: 'siblingCompanies'
  },
  ChildCompanies: {
    key: 'childCompanies'
  }
};
