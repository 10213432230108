// @flow
import { EntityRoutes } from 'routes/constants';
import { DateFormats } from 'UI/constants/defaults';

export const columnsSendouts = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-sendout-type'
        }
      }
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-sendout-status',
          variant: 'outlined'
        }
      }
    }
  },
  {
    name: 'trackingDate',
    label: 'Tracking Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      display: true,
      sort: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'id',
          textKey: 'name'
        }
      }
    }
  },
  {
    name: 'jobOrder',
    label: 'Job Order',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'id',
          textKey: 'name'
        }
      }
    }
  },
  {
    name: 'channelPartner',
    label: 'Channel Partner',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'channelPartnerPercent',
    label: '%',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'production',
    label: 'Production',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'industry',
    label: 'JO Industry',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'specialty',
    label: 'JO Specialty',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'functionalTitle',
    label: 'JO Functional Title',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'feeAmount',
    label: 'Total Fee',
    options: {
      sort: false,
      display: false,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'interviewDate',
    label: 'Interview Date',
    options: {
      sort: true,
      display: false,
      renderer: 'date'
    }
  },
  {
    name: 'placementDate',
    label: 'Placement Date',
    options: {
      sort: true,
      display: false,
      renderer: { id: 'dateBuilder', config: { format: DateFormats.SimpleDate } }
    }
  }
];

export const FiltersInclude = ['status'];

export const FiltersGroup = [
  { id: 'team', name: 'Team', filters: ['referrals', 'referred'] },
  {
    id: 'general',
    name: 'Sendout',
    filters: ['sendoutStatuses']
  },
  {
    id: 'industries',
    name: 'Job Order Industry',
    filters: ['industries', 'specialties', 'positions']
  }
];

export const SendoutSortColumnBySection = {
  0: 'tracking_date',
  1: 'interview_date',
  2: 'placement_date'
};

export const SendoutPeriodColumnBySection = {
  ...SendoutSortColumnBySection
};

export const SendoutTrackingDateFilter = { id: 0, title: 'Tracking Date', column: 'tracking_date' };

export const SendoutInterviewDateFilter = {
  id: 1,
  title: 'Interview Date',
  column: 'interview_date'
};

export const SendoutPlacementDateFilter = {
  id: 2,
  title: 'Placement Date',
  column: 'placement_date'
};

export const SendoutDateFilters = [
  SendoutTrackingDateFilter,
  SendoutInterviewDateFilter,
  SendoutPlacementDateFilter
];
