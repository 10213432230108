import React from 'react';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import strings from 'strings';

import { EmptyRender, LoadingRender } from '../utils';

import FeeAgreementsDataTable from './FeeAgreementsDataTable';

const FeeAgreementsLoader = ({ endpoint, week, year }) => {
  const { Status } = useFetchWithStatus(
    `${endpoint}?year=${year?.date ? year.date : ''}&week=${week?.date ? week.date : ''}`
  );
  return (
    <Status
      loading={<LoadingRender />}
      success={data => <FeeAgreementsDataTable items={data} year={year} week={week} />}
      empty={
        <EmptyRender
          title={strings.feeAgreements.dashboard.title}
          subtitle={strings.feeAgreements.dashboard.subtitle}
        />
      }
    />
  );
};

export default FeeAgreementsLoader;
