// @flow

import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { showAlert as showAlertAction } from 'actions/app';
import API from 'services/API';
import strings from 'strings';
import { FormFieldsMap } from 'UI/components/organisms/CompanyForm/fields';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { Endpoints } from 'UI/constants/endpoints';
import { getErrorMessage } from 'UI/utils';
import { preloadFromBackend, prepareFormToSubmit } from 'UI/utils/forms';

import { formatPhoneNumbers, PHONE_FORMAT_MODES } from '../PhoneNumbersForm/utils';

type CompanyEditProps = {
  company: any,
  onBeforeSubmit: data => void,
  onEditCompleted: (profile: any) => void,
  onEditClosed: () => void,
  showAlert: any => void,
  CompanyForm: any
};

const CompanyEdit = ({
  company,
  onEditCompleted,
  onEditClosed,
  onBeforeSubmit,
  showAlert,
  CompanyForm
}: CompanyEditProps) => {
  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false,
    isFormDisabled: false,
    isReadOnly: false
  });

  const defaultValues = preloadFromBackend(company, FormFieldsMap);

  const form = useForm(
    company
      ? {
          defaultValues
        }
      : {}
  );

  const { handleSubmit } = form;

  const onSubmit = async formData => {
    try {
      setUiState(prevState => ({ ...prevState, isSaving: true }));
      const transformedData = prepareFormToSubmit(formData, FormFieldsMap);
      const transformedPhones = formatPhoneNumbers(formData, PHONE_FORMAT_MODES.submit);
      const companyData = {
        ...transformedData,
        phones: transformedPhones
      };
      onBeforeSubmit && onBeforeSubmit(companyData);

      const response = await API.put(`${Endpoints.Companies}/${company.id}`, companyData, {
        apiVersion: 2
      });

      if (response.data && response.status === 201) {
        showAlert({
          severity: 'success',
          title: strings.formatString(strings.inventoryProfiles.common.edition, {
            inventoryItem: 'Company'
          })
        });

        const updatedProfile = response.data;
        onEditCompleted && onEditCompleted(updatedProfile);
      }
    } catch (err) {
      setUiState(prevState => ({
        ...prevState,
        isSaving: false,
        isSuccess: false
      }));
      showAlert({
        severity: 'error',
        title: 'Company',
        body: getErrorMessage(err)
      });
    }
  };

  return (
    <FormContext {...form}>
      <DrawerContentLayout
        title="Editing Company"
        onSubmit={handleSubmit(onSubmit)}
        onClose={onEditClosed}
        uiState={uiState}
      >
        {CompanyForm}
      </DrawerContentLayout>
    </FormContext>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    showAlert: alert => dispatch(showAlertAction(alert))
  };
};

const CompanyEditConnected = connect(null, mapDispatchToProps)(CompanyEdit);

export default CompanyEditConnected;
