import strings from 'strings';

import { SendoutsDrawerContent } from './SendoutsDrawerContent';

const { sendouts, sendovers } = strings.commandCenter.drawers;

export const SendoutsConfig = {
  title: sendouts.title,
  component: SendoutsDrawerContent
};

export const SendoversConfig = {
  title: sendovers.title,
  component: SendoutsDrawerContent
};
