// @flow
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import strings from 'strings';
import CustomDatePicker from 'UI/components/atoms/CustomDatePicker';
// TODO Remove FileField in next PR
import FileField from 'UI/components/molecules/FileField';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { REQUIRED_VALIDATION } from 'UI/utils/index';

import { useStyles } from '../styles';

type UnmanagedFeeAgreementExtendedFieldsProps = {
  companyFiles: any[],
  agreementFile: any,
  required: boolean,
  readOnly: boolean
};

const UnmanagedFeeAgreementExtendedFields = (props: UnmanagedFeeAgreementExtendedFieldsProps) => {
  const { companyFiles = [], required, readOnly, agreementFile } = props;
  const classes = useStyles(props);
  const fileExplorerSection = [
    {
      id: EntityType.Company,
      title: strings.feeAgreements.creation.fileExplorerSection,
      files: companyFiles
    }
  ];

  const form = useFormContext();
  const { register, unregister, errors, setValue, watch, clearError, getValues } = form;

  const fieldsConfig = useMemo(() => {
    const validation = required ? REQUIRED_VALIDATION : undefined;
    return [
      {
        name: 'signed_date',
        validation: {
          validate(val) {
            if (!val) return REQUIRED_VALIDATION.required;

            const sentMoment = moment(getValues('validated_date'));
            const signMoment = moment(val);

            return signMoment.isAfter(sentMoment) || strings.feeAgreements.creation.wrongDates;
          }
        }
      },
      {
        name: 'validated_date',
        validation
      },
      {
        name: 'fee_agreement_file',
        validation
      }
    ];
  }, [required, getValues]);

  useEffect(() => {
    fieldsConfig.forEach(({ name, type, validation }) => {
      register({ name, type }, validation);
    });

    return () => unregister(fieldsConfig.map(({ name }) => name));
  }, [register, unregister, fieldsConfig]);

  const formValues = watch();
  const handleFieldChange = (field: string, value: any) => setValue(field, value, true);
  const handleUploadStarted = (fieldName: string) => clearError(fieldName);

  return (
    <Box className={classes.fieldGroup}>
      <FileField
        name="fee_agreement_file"
        category={{
          module: EntityType.FeeAgreement,
          required,
          title: strings.feeAgreements.singular
        }}
        acceptFiles={['.pdf']}
        defaultEntityType={EntityType.FeeAgreement}
        endpoint={Endpoints.FeeAgreementTempFile}
        deleteEndpoint={Endpoints.Files}
        errorText={errors.fee_agreement_file?.message}
        onChange={handleFieldChange}
        onUploadStarted={handleUploadStarted}
        softDeleteForExistingItems
        fileExplorerSections={fileExplorerSection}
        className={classes.inputSpacing}
        disabled={readOnly}
        initialValue={agreementFile}
      />
      <CustomDatePicker
        name="validated_date"
        label={`${strings.feeAgreements.creation.sentDate} ${required ? '*' : ''}`}
        disableFuture
        withTime
        value={formValues.validated_date || null}
        onDateChange={handleFieldChange}
        error={!!errors.validated_date}
        helperText={errors?.validated_date?.message}
        disabled={readOnly}
        className={classes.inputSpacing}
      />
      <CustomDatePicker
        name="signed_date"
        label={`${strings.feeAgreements.creation.signingDate} ${required ? '*' : ''}`}
        disableFuture
        withTime
        value={formValues.signed_date || null}
        onDateChange={handleFieldChange}
        error={!!errors.signed_date}
        helperText={errors?.signed_date?.message}
        disabled={readOnly}
        className={classes.inputSpacing}
      />
    </Box>
  );
};

UnmanagedFeeAgreementExtendedFields.defaultProps = {
  required: false,
  readOnly: false,
  agreementFile: null
};

export default UnmanagedFeeAgreementExtendedFields;
