import { makeStyles } from '@material-ui/core/styles';
import { globalStyles } from 'GlobalStyles';
import { colors } from 'UI/res';
import { flexAlignCenterSpaceAround, flexAlignEnd } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  skeletonBar: {
    width: '100%',
    backgroundColor: theme.palette.grey[300],
    height: 132,
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    ...flexAlignCenterSpaceAround
  },
  dotBarContainer: {
    justifyContent: 'flex-end',
    width: '95%',
    flexGrow: 1,
    ...flexAlignEnd
  },
  headerItemsBar: {
    flexGrow: 2,
    ...globalStyles.skeletonContainer,
    margin: 0
  },
  rowsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1.25, 2.5),
    backgroundColor: colors.appBackgroundContrast
  },
  footerSkeletonBar: {
    marginTop: theme.spacing(2)
  },
  skeletonItemContainer: {
    width: '100%'
  },
  skeletonItem: {
    ...globalStyles.profileSkeletonItem
  },
  skeletonRow: {
    ...globalStyles.skeletonContainer
  }
}));
