import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  helper: {
    marginTop: theme.spacing(1)
  },
  emailBodyRenderer: {
    flexGrow: '1',
    width: '100%',
    border: `1px solid ${theme.palette.customColors.iconInactive}`,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5),
    overflowY: 'scroll',
    marginBottom: theme.spacing(1)
  },
  bulkEmailRow: {
    display: 'flex'
  },
  bulkEmailTitle: {
    minWidth: 150
  },
  bulkEmailBody: {
    marginBottom: theme.spacing(1)
  },
  activityAlert: {
    margin: theme.spacing(2, 0)
  }
}));
