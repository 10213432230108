import strings from 'strings';

const { splitOptions } = strings.searchProjects.splits.form;

/**
 * @param {'two' | 'three' | 'more'} quantity
 * @returns {String}
 */
export const formatOptionsTitle = quantity =>
  strings.formatString(splitOptions.title, splitOptions.quantity[quantity]);
