// @flow
import React from 'react';
import { ENDPOINTS } from 'features/command-center/constants';
import strings from 'strings';
import type { Filters } from 'types/app';

import { InterviewsTable } from '../InterviewsTable';

import { COLUMNS, ORDER_BY_OPTIONS, VIRTUAL_PROPS } from './PreparationCalls.constants';

const COPIES = strings.commandCenter.dominateSheet.preparationCalls;
const TABLE_KEY = 'preparation-calls-list';

interface PreparationCallsProps {
  filters: Filters[];
}

export const PreparationCalls = ({ filters }: PreparationCallsProps) => {
  return (
    <InterviewsTable
      tableKey={TABLE_KEY}
      endpoint={ENDPOINTS.preparationCalls}
      columns={COLUMNS}
      orderByOptions={ORDER_BY_OPTIONS}
      virtualProps={VIRTUAL_PROPS}
      filters={filters}
      title={COPIES.title}
      variant="preparation"
    />
  );
};
