// @flow
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import usePageTitle from 'hooks/usePageTitle';
import isEmpty from 'lodash/isEmpty';
import { UserProviderContext } from 'providers/UserProvider';
import { EntityRoutes } from 'routes/constants';
import { userHasRoles } from 'services/Authorization';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { FPCard, FPCardContent } from 'UI/components/atoms/FPCard';
import GlobalSearchbar from 'UI/components/molecules/GlobalSearchbar';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import HomeItemsCreation from 'UI/components/templates/HomeItemsCreation';
import TabsView from 'UI/components/templates/TabsView';
import { PageTitles } from 'UI/constants/defaults';
import { componentDimensions } from 'UI/constants/dimensions';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';
import {
  AutocompleteByCoach,
  AutocompleteByRegional
} from 'UI/pages/Dashboard/ToolsUsageDashboard/Content';
import { SvgAdd, SvgEmtpyStateCharts } from 'UI/res';
import { getId, hasFeatureFlag, loadFromLocalStorage, nestTernary } from 'UI/utils';

import { useHomeData } from './home.hooks';
import RendererChooser from './RendererChooser';
import { styles, useStyles } from './styles';

const PAGE_REGIONAL_KEY = 'home-selector-regional';
const PAGE_COACH_KEY = 'home-selector-coach';

const REQUIRED_SELECTORS = {
  regional: 'regional',
  coach: 'coach'
};

const OPTION_ALL = 'All';
const PREPEND_OPTIONS_ALL = [{ full_name: OPTION_ALL }];
const TEAM_DAILY_INFO_TAB_INDEX = 1;

const { emptyState } = componentDimensions;

const EmptyPlaceholderRenderer = ({ shouldSelectTeam = false }) => {
  const emptyStateStrings = strings.home.emptyState[shouldSelectTeam ? 'team' : 'recruiter'];

  return (
    <EmptyPlaceholder
      customEmptyState={shouldSelectTeam ? <SvgEmtpyStateCharts size={emptyState.width} /> : null}
      style={styles.emptyState}
      title={emptyStateStrings.title}
      subtitle={emptyStateStrings.subtitle}
    >
      <br />
      {!shouldSelectTeam && (
        <FPActionButton
          text="Refresh page"
          onClick={() => {
            window.location.reload();
          }}
        />
      )}
    </EmptyPlaceholder>
  );
};

const getRegionalInitialState = user => {
  const isRegional = userHasRoles([Roles.RegionalDirector]);
  const isAssistantRegionalDirector = userHasRoles([Roles.AssistantRegionalDirector]);

  if (!isAssistantRegionalDirector && !isRegional)
    return loadFromLocalStorage(PAGE_REGIONAL_KEY, null);

  return {
    email: user.email,
    full_name: user.personalInformation.full_name,
    id: isRegional ? user.id : user?.teamRelation?.regionalDirectorId,
    initials: user.initials
  };
};

const SHARED_PROPS = {
  icon: SvgAdd,
  style: styles.button,
  iconProps: {
    family: 'mini',
    size: 'sm'
  }
};

const checkSelectorAvailability = (tabConfig, requiredSelector) => {
  if (tabConfig?.required && Array.isArray(tabConfig?.required)) {
    return tabConfig.required.some(item => item === requiredSelector);
  }

  return tabConfig?.required === requiredSelector;
};

const checkIsCoachSelectorCompleted = (coachId, coachName) => {
  if (coachName === OPTION_ALL) return true;

  return !!coachId;
};

const ALL_COMPANY_VISIBILITY_ROLES = [
  Roles.ProductionDirector,
  Roles.Operations,
  Roles.DataCoordinator,
  Roles.Admin,
  Roles.Leadership,
  Roles.Finance
];

const Home = ({ history }) => {
  const classes = useStyles();

  const areMultipleDivisionsEnable = hasFeatureFlag(FeatureFlags.HomeMultipleRegions);
  const [currentUser] = useContext(UserProviderContext);
  const canSelectRegional =
    areMultipleDivisionsEnable && userHasRoles(ALL_COMPANY_VISIBILITY_ROLES);
  const canSelectCoach = areMultipleDivisionsEnable
    ? userHasRoles([Roles.RegionalDirector, Roles.AssistantRegionalDirector])
    : userHasRoles(ALL_COMPANY_VISIBILITY_ROLES);
  const isRecruiter =
    !canSelectCoach && !userHasRoles([Roles.Coach]) && userHasRoles([Roles.Recruiter]);
  const defaultCoachValue = loadFromLocalStorage(PAGE_COACH_KEY, null) || PREPEND_OPTIONS_ALL[0];
  const [selectedTab, setSelectedTab] = useState(0);
  const [regional, setRegional] = useState(
    areMultipleDivisionsEnable ? getRegionalInitialState(currentUser) : null
  );
  const [coach, setCoach] = useState(defaultCoachValue);
  const { first_name: userFirstName = '' } = currentUser?.personalInformation || {};
  const coachId = coach?.id;
  const regionalId = areMultipleDivisionsEnable ? regional?.id : null;
  const { columns, isLoading, tabs } = useHomeData();

  const isCoachRequiredCompleted = checkSelectorAvailability(
    tabs[selectedTab]?.config,
    REQUIRED_SELECTORS.coach
  )
    ? checkIsCoachSelectorCompleted(coachId, coach?.full_name)
    : true;

  const isRegionalRequiredCompleted =
    !areMultipleDivisionsEnable ||
    checkSelectorAvailability(tabs[selectedTab]?.config, REQUIRED_SELECTORS.regional)
      ? !!regionalId
      : true;

  const getHomeAvailability = () => {
    if (areMultipleDivisionsEnable)
      return (
        (userHasRoles([Roles.Recruiter, Roles.Coach]) && !canSelectCoach && !canSelectRegional) ||
        (isCoachRequiredCompleted &&
          isRegionalRequiredCompleted &&
          (canSelectCoach || canSelectRegional))
      );

    return (
      (userHasRoles([Roles.Recruiter, Roles.Coach]) && !canSelectCoach) ||
      (isCoachRequiredCompleted && canSelectCoach)
    );
  };

  const shouldShowHome = getHomeAvailability();

  usePageTitle({ title: PageTitles.Home, isLoading });

  const onSelectForRegional = (event, newValue) => {
    setRegional(newValue);
    setCoach(selectedTab !== TEAM_DAILY_INFO_TAB_INDEX ? PREPEND_OPTIONS_ALL[0] : null);
    localStorage.setItem(PAGE_REGIONAL_KEY, JSON.stringify(newValue));
    localStorage.removeItem(PAGE_COACH_KEY);
  };

  const onSelectForCoach = (event, newValue) => {
    setCoach(newValue);
    localStorage.setItem(PAGE_COACH_KEY, JSON.stringify(newValue));
  };

  const handleActionClick = action => history.push(action);

  const handleTabChange = (event, newValue = 0) => {
    setSelectedTab(newValue);
    if (!coach?.full_name || coach?.full_name === PREPEND_OPTIONS_ALL[0].full_name) {
      setTimeout(() => {
        setCoach(newValue !== TEAM_DAILY_INFO_TAB_INDEX ? PREPEND_OPTIONS_ALL[0] : null);
      }, 500);
    }
  };

  const getFormattedTabs = () => {
    if (!tabs) return [];
    const tabConfig = tabs.map(tab => {
      const { config } = tab;
      if (!config) return null;
      return {
        ...tab,
        view: (
          <RendererChooser
            canSelectCoach={canSelectCoach}
            canSelectRegional={canSelectRegional}
            columns={config.columns}
            EmptyPlaceholderRenderer={EmptyPlaceholderRenderer}
            selectedCoachId={coachId}
            selectedRegionalId={regionalId}
            shouldShowHome={shouldShowHome}
          />
        )
      };
    });

    return tabConfig;
  };

  return (
    <ContentPageLayout
      mode="mainLayout"
      text={!isRecruiter ? strings.home.title : `Hello, ${userFirstName}`}
      titleLabelProps={{
        fontSize: 48,
        subtitle: isRecruiter && strings.home.subtitle,
        subtitleProps: {
          className: classes.subtitle,
          color: 'textSecondary',
          variant: 'body1'
        }
      }}
    >
      <div className={classes.root}>
        {isRecruiter && (
          <>
            <Grid
              className={clsx(classes.container, classes.topSpacing)}
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <GlobalSearchbar />
            </Grid>
            <Grid
              className={classes.container}
              style={styles.gridContainer}
              container
              justify="center"
              alignItems="center"
            >
              <Grid
                className={classes.containerQuickActions}
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <FPActionButton
                  text="Candidate"
                  onClick={() => handleActionClick(EntityRoutes.CandidateCreate)}
                  {...SHARED_PROPS}
                />
                <FPActionButton
                  text="Job Order"
                  onClick={() => handleActionClick(EntityRoutes.JobOrderCreate)}
                  {...SHARED_PROPS}
                />
                <FPActionButton
                  text="Company"
                  onClick={() => handleActionClick(EntityRoutes.CompanyCreate)}
                  {...SHARED_PROPS}
                />
              </Grid>
            </Grid>
          </>
        )}

        <div className={classes.containerBody}>
          {(canSelectCoach || canSelectRegional) && (
            <FPCard className={classes.teamSelectorContainer}>
              <FPCardContent
                headerProps={{
                  title: strings.home.selectTeam
                }}
              >
                <Grid container justify="space-between">
                  <Grid container item spacing={1}>
                    {areMultipleDivisionsEnable && canSelectRegional && (
                      <Grid item xs={4}>
                        <AutocompleteByRegional
                          regional={regional}
                          onSelect={onSelectForRegional}
                        />
                      </Grid>
                    )}

                    {checkSelectorAvailability(
                      tabs[selectedTab]?.config,
                      REQUIRED_SELECTORS.coach
                    ) && (
                      <Grid item xs={4}>
                        <AutocompleteByCoach
                          coach={coach}
                          onSelect={onSelectForCoach}
                          prependOptions={
                            selectedTab !== TEAM_DAILY_INFO_TAB_INDEX && PREPEND_OPTIONS_ALL
                          }
                          regional={regional}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </FPCardContent>
            </FPCard>
          )}

          {isLoading ? (
            <div className={classes.loadingStateContainer}>
              <EmptyPlaceholder
                {...strings.shared.loading}
                customEmptyState={<CircularProgress className={classes.circularProgress} />}
              />
            </div>
          ) : (
            <>
              {!columns ? (
                <>
                  {!isEmpty(tabs) ? (
                    nestTernary(
                      tabs.length > 1,
                      <TabsView
                        selectedTab={selectedTab}
                        onChangeTabIndex={handleTabChange}
                        tabs={getFormattedTabs()}
                        padding={12}
                        className={classes.tabContainer}
                        tabPanelProps={{
                          className: classes.tab
                        }}
                      />,
                      <RendererChooser
                        canSelectCoach={canSelectCoach}
                        canSelectRegional={canSelectRegional}
                        columns={tabs[0].config.columns}
                        EmptyPlaceholderRenderer={EmptyPlaceholderRenderer}
                        selectedCoachId={coachId}
                        shouldShowHome={shouldShowHome}
                        selectedRegionalId={regionalId}
                      />
                    )
                  ) : (
                    <EmptyPlaceholderRenderer
                      shouldSelectTeam={canSelectCoach || canSelectRegional}
                    />
                  )}
                </>
              ) : (
                // Backward compatibility
                <Grid container direction="row" spacing={2}>
                  {columns ? (
                    columns.map(column => (
                      <Grid item sm={column.size} xs={column.xsSize} key={getId()}>
                        <Grid container direction="column" spacing={2}>
                          {column.items &&
                            column.items.map(item => {
                              return (
                                <Grid item xs={12} key={item.name}>
                                  <HomeItemsCreation item={item} />
                                </Grid>
                              );
                            })}
                        </Grid>
                      </Grid>
                    ))
                  ) : (
                    <EmptyPlaceholderRenderer />
                  )}
                </Grid>
              )}
            </>
          )}
        </div>
      </div>
    </ContentPageLayout>
  );
};

export default withRouter(Home);
