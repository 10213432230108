// @flow
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { confirm, showAlert } from 'actions/app';
import {
  CreatedFromVariants,
  removeItemsFromSearchProject,
  SEARCH_PARAMS_KEYS
} from 'services/searchProjects';
import strings from 'strings';
import FPButtonMenu from 'UI/components/molecules/FPButtonMenu';
import AddToSearchProjectDrawer from 'UI/components/organisms/AddToSearchProjectDrawer';
import SearchProjectFormDrawer from 'UI/components/organisms/SearchProjects/SearchProjectFormDrawer';
import { SeverityOptions } from 'UI/constants/defaults';
import { TabKeys } from 'UI/constants/entityTypes';
import { SvgSearchProjects } from 'UI/res';

type SearchProjectFormsProps = {
  bulkEmailId?: number,
  searchProject?: Object
};

const {
  menuItems: menuItemsCopies,
  profiles: {
    tabs: searchProjectsTabCopies,
    dialogs: { removeProfile: removeProfileDialogCopies }
  }
} = strings.searchProjects;

export const useSearchProject = ({
  createdFrom,
  filteredItems,
  queryParams,
  setSelectionToInitial,
  enableMoveActions,
  bulkId,
  onUpdateSearchProject,
  shouldRedirectToSearchProjectPreview = true,
  handleRefreshSearchProjectsTab
}) => {
  const dispatch = useDispatch();

  const [uiState, setUiState] = useState({
    isAddToSearchProjectFormOpen: false,
    isSearchProjectFormOpen: false,
    isSearchProjectFormBeingEdited: false,
    fetchingRemoveDialog: false,
    shouldExtractCompanyContacts: false,
    shouldMoveItemsFromSearchProject: false
  });
  const formCommonProps = useMemo(
    () => ({
      itemsToAdd: filteredItems,
      createdFrom,
      queryParams
    }),
    [queryParams, filteredItems, createdFrom]
  );

  const searchProjectTogglers = {
    searchProjectForm:
      ({ edit, moveItems, open, shouldExtractCompanyContacts }) =>
      () =>
        setUiState(prev => ({
          ...prev,
          isSearchProjectFormOpen: open,
          isSearchProjectFormBeingEdited: edit,
          shouldExtractCompanyContacts,
          shouldMoveItemsFromSearchProject: moveItems
        })),
    addToSearchProjectForm:
      ({ moveItems, open }) =>
      () =>
        setUiState(prev => ({
          ...prev,
          isAddToSearchProjectFormOpen: open,
          shouldMoveItemsFromSearchProject: moveItems
        }))
  };

  /**
   * @param {boolean} success - If the event was executed without errors 'success' will
   * @param {Object} [newSearchProjectData]
   * have a truthy value
   */
  const handleFormEvent = (success, newSearchProjectData) => {
    if (success) {
      if (uiState.isSearchProjectFormBeingEdited) {
        onUpdateSearchProject && onUpdateSearchProject(newSearchProjectData);
      }
      setSelectionToInitial && setSelectionToInitial();
      searchProjectTogglers.searchProjectForm({ open: false, edit: false })();
    }
  };

  const handleRemoveItemsFromSP =
    ({ searchProjectId, onRemove }) =>
    async ok => {
      if (!ok) return;
      const paramsType =
        createdFrom === CreatedFromVariants.BulkMetrics
          ? SEARCH_PARAMS_KEYS.MetricsParams
          : SEARCH_PARAMS_KEYS.SearchParams;
      const response = await removeItemsFromSearchProject({
        searchProjectId,
        itemsToRemove: filteredItems,
        queryParams,
        paramsType,
        bulkId
      });
      if (response.success) {
        onRemove && onRemove();
      } else {
        dispatch(showAlert(response.alert));
      }
    };

  const handleRemoveClick =
    ({ id: searchProjectId, dialogCopies, onRemove }) =>
    () =>
      dispatch(
        confirm({
          severity: SeverityOptions.warning,
          ...dialogCopies,
          mustComplete: true,
          closeDialogOnConfirm: false,
          onConfirm: handleRemoveItemsFromSP({ searchProjectId, onRemove })
        })
      );

  const menuItems = {
    add: {
      title: enableMoveActions ? menuItemsCopies.copy.toExisting.title : menuItemsCopies.add.title,
      action: searchProjectTogglers.addToSearchProjectForm({ open: true, moveItems: false }),
      visible: true
    },
    create: {
      title: enableMoveActions ? menuItemsCopies.copy.toNew.title : menuItemsCopies.create.title,
      action: searchProjectTogglers.searchProjectForm({
        open: true,
        edit: false,
        moveItems: false
      }),
      visible: true
    },
    remove: {
      title: menuItemsCopies.remove.title,
      action: handleRemoveClick,
      visible: true
    },
    move: {
      toExisting: {
        title: menuItemsCopies.move.toExisting.title,
        action: searchProjectTogglers.addToSearchProjectForm({ open: true, moveItems: true }),
        visible: true
      },
      toNew: {
        title: menuItemsCopies.move.toNew.title,
        action: searchProjectTogglers.searchProjectForm({ open: true, moveItems: true }),
        visible: true
      }
    }
  };

  const SearchProjectAction = ({ text, tooltipText }) => {
    const buttonProps = {
      iconBtn: {
        isIconButton: true,
        icon: SvgSearchProjects,
        iconProps: {
          onSetColor: ({ primary }) => primary.main
        },
        'data-testid': 'sp-action-btn'
      },
      textBtn: {
        text,
        size: 'small',
        variant: 'outlined'
      }
    };
    const defaultItems = [menuItems.add, menuItems.create];
    const allItems = [...defaultItems, menuItems.move.toExisting, menuItems.move.toNew];
    return (
      <FPButtonMenu
        menuItems={enableMoveActions ? allItems : defaultItems}
        IconButtonProps={{
          tooltipProps: {
            arrow: false,
            placement: 'bottom',
            title: tooltipText || 'Search Project'
          }
        }}
        {...(text ? buttonProps.textBtn : buttonProps.iconBtn)}
      />
    );
  };

  const handleAddItemsToSearchProject = success => {
    handleFormEvent(success);
    !shouldRedirectToSearchProjectPreview && handleRefreshSearchProjectsTab(true);
  };

  const SearchProjectForms = ({
    bulkEmailId = null,
    searchProject = null
  }: SearchProjectFormsProps) => (
    <>
      {uiState.isAddToSearchProjectFormOpen && (
        <AddToSearchProjectDrawer
          bulkId={bulkEmailId}
          currentSearchProjectId={searchProject?.id}
          open={uiState.isAddToSearchProjectFormOpen}
          onClose={searchProjectTogglers.addToSearchProjectForm({ open: false, moveItems: false })}
          onRefreshSearchProjectsTab={handleRefreshSearchProjectsTab}
          onAddItemsToSP={handleAddItemsToSearchProject}
          moveCopiedItems={uiState.shouldMoveItemsFromSearchProject}
          shouldRedirectToSearchProjectPreview={shouldRedirectToSearchProjectPreview}
          {...formCommonProps}
        />
      )}
      {uiState.isSearchProjectFormOpen && (
        <SearchProjectFormDrawer
          bulkId={bulkEmailId}
          isSearchProjectBeingEdited={uiState.isSearchProjectFormBeingEdited}
          onClose={searchProjectTogglers.searchProjectForm({
            open: false,
            edit: false
          })}
          onSubmitSearchProject={handleFormEvent}
          searchProject={searchProject}
          shouldExtractCompanyContacts={uiState.shouldExtractCompanyContacts}
          shouldMoveCopiedItems={uiState.shouldMoveItemsFromSearchProject}
          {...formCommonProps}
        />
      )}
    </>
  );

  return {
    handleRemoveFromSearchProject: handleRemoveClick,
    SearchProjectMenuItems: menuItems,
    SearchProjectAction,
    SearchProjectForms,
    searchProjectTogglers
  };
};

export const useSearchProjectInProfiles = ({
  endpoint,
  entity,
  profile,
  tabKey,
  triggerTabRefresh,
  shouldRedirectToSearchProjectPreview
}) => {
  const [searchProjectsUiState, setSearchProjectsUiState] = useState({
    shouldRefreshSearchProjectsTab: false
  });

  const handleRefreshSearchProjectsTab = refresh => {
    setSearchProjectsUiState(prev => ({
      ...prev,
      shouldRefreshSearchProjectsTab: refresh
    }));
  };

  const formattedSPTabCopies = {
    ...searchProjectsTabCopies,
    emptyPlaceholderSubtitle: strings.formatString(
      searchProjectsTabCopies.emptyPlaceholderSubtitle,
      { entity }
    )
  };

  const getRemoveDialogCopies = searchProjectName => ({
    ...removeProfileDialogCopies,
    message: strings.formatString(removeProfileDialogCopies.message, {
      entity,
      itemName: profile.name,
      spName: searchProjectName
    })
  });

  const {
    SearchProjectForms,
    handleRemoveFromSearchProject,
    SearchProjectMenuItems,
    searchProjectTogglers
  } = useSearchProject({
    shouldRedirectToSearchProjectPreview,
    handleRefreshSearchProjectsTab,
    filteredItems: {
      type: 'include',
      data: [
        {
          id: parseInt(profile.id, 10),
          type: entity
        }
      ]
    }
  });

  const handleRemoveNewProfileFromSP = ({ searchProjectId, copies }) =>
    handleRemoveFromSearchProject({
      id: searchProjectId,
      dialogCopies: copies.removeDialog,
      onRemove: () => triggerTabRefresh(TabKeys.SearchProjects)
    })();

  const searchProjectTabPropsDefinition = {
    id: TabKeys.SearchProjects,
    tabProps: {
      copies: {
        tab: {
          actionText: formattedSPTabCopies.addButtonText,
          title: formattedSPTabCopies.emptyPlaceholderTitle,
          subtitle: formattedSPTabCopies.emptyPlaceholderSubtitle
        },
        getRemoveDialogCopies
      },
      endpoint,
      key: tabKey,
      onOpenAddToSearchProjectForm: searchProjectTogglers.addToSearchProjectForm({
        open: true,
        moveItems: false
      }),
      onRemoveProfileFromSearchProject: handleRemoveNewProfileFromSP,
      profileId: profile?.id,
      shouldRefresh: searchProjectsUiState.shouldRefreshSearchProjectsTab
    }
  };

  const profileMenuItems = [
    {
      ...SearchProjectMenuItems.create,
      icon: null
    },
    {
      ...SearchProjectMenuItems.add,
      icon: null
    }
  ];

  return {
    SearchProjectForms,
    SearchProjectsMenuItems: profileMenuItems,
    searchProjectTabPropsDefinition
  };
};
