// @flow
import { EntityRoutes } from 'routes/constants';
import { DateFormats, defaultProfileColumnsConfig } from 'UI/constants/defaults';

export const SENDOUTS_RENDERERS = {
  interview: value => value?.[0]?.interviewType?.title,
  recruiter: value =>
    `${value?.candidateAccountable?.initials || 'NA'} / ${
      value?.jobOrderAccountable?.initials || 'NA'
    }`
};

export const SENDOUTS_COLUMNS = [
  {
    name: 'id',
    options: {
      display: 'excluded'
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-sendout-status',
          variant: 'outlined'
        }
      }
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-sendout-type',
          variant: 'default'
        }
      }
    }
  },
  {
    name: 'sent_on',
    label: 'Resume Sent On',
    options: {
      display: true,
      sort: true,
      renderer: {
        id: 'dateBuilder',
        config: { format: DateFormats.SimpleDateTime }
      }
    }
  },
  {
    name: 'tracking_date',
    label: 'Tracking Date',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'dateBuilder',
        config: { format: DateFormats.SimpleDateTime }
      }
    }
  },
  {
    name: 'joborder.company',
    label: 'Company',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'id',
          textKey: 'name'
        }
      }
    }
  },
  {
    name: 'joborder',
    label: 'Job Order',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'id',
          textKey: 'title'
        }
      }
    }
  },
  {
    name: 'candidate',
    label: 'Candidate',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'id',
          textKey: 'full_name'
        }
      }
    }
  },
  {
    name: 'candidateAccountable',
    label: 'Recruiters',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'recruiter'
    }
  },
  {
    name: 'interviews',
    label: 'Interview Type',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'interview'
    }
  },
  {
    name: 'interview_date',
    label: 'Interview Date & Time',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'dateBuilder',
        config: { format: DateFormats.SimpleDateTime }
      }
    }
  },
  {
    name: 'fee_amount',
    label: 'Fee Amount',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'currency'
    }
  },
  {
    name: 'hiringAuthorithies',
    label: 'Hiring Authority',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'hiringAuthority',
        config: {
          route: EntityRoutes.HiringAuthorityProfile,
          idKey: 'id',
          textKey: 'full_name'
        }
      }
    }
  }
];

export const SENDOUTS_VIRTUAL_PROPS = [
  {
    propName: 'candidateAccountable',
    attributes: ['jobOrderAccountable', 'candidateAccountable']
  }
];

export const COMPANY_PROFILE_SENDOUTS_COLUMNS = [
  {
    name: 'id',
    options: {
      display: 'excluded'
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-sendout-type',
          variant: 'default'
        }
      }
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-sendout-status',
          variant: 'outlined'
        }
      }
    }
  },

  {
    name: 'sent_on',
    label: 'Resume Sent On',
    options: {
      display: true,
      sort: true,
      renderer: {
        id: 'dateBuilder',
        config: { format: DateFormats.SimpleDateTime }
      }
    }
  },
  {
    name: 'tracking_date',
    label: 'Tracking Date',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'dateBuilder',
        config: { format: DateFormats.SimpleDateTime }
      }
    }
  },
  {
    name: 'jobOrder',
    label: 'Job Order',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'id',
          textKey: 'title'
        }
      }
    }
  },
  {
    name: 'candidate',
    label: 'Candidate',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'id',
          textKey: 'full_name'
        }
      }
    }
  },
  {
    name: 'jobOrder.position.title',
    label: 'JO Functional Title',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'jobOrder.specialty.industry.title',
    label: 'JO Industry',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'jobOrder.specialty.title',
    label: 'JO Specialty',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'candidateAccountable',
    label: 'Recruiters',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'recruiter'
    }
  },
  {
    name: 'jobOrder.address.city.state.title',
    label: 'State',
    options: {
      display: false,
      sort: false,
      renderer: 'default'
    }
  },
  {
    name: 'jobOrder.address.city.title',
    label: 'City',
    options: {
      display: false,
      renderer: 'default'
    }
  },
  {
    name: 'fee_amount',
    label: 'Estimated Total Fee',
    options: {
      display: false,
      renderer: 'currency'
    }
  },
  {
    name: 'interview_date',
    label: 'Upcoming Interview',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'dateBuilder',
        config: { format: DateFormats.SimpleDateTime }
      }
    }
  },
  {
    name: 'placement_date',
    label: 'Placement Date',
    options: {
      display: false,
      renderer: 'date'
    }
  }
];

export const HIRING_AUTHORITY_SENDOUTS_COLUMNS = [
  {
    name: 'id',
    options: {
      display: 'excluded'
    }
  },

  {
    name: 'status',
    label: 'Status',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-sendout-status',
          variant: 'outlined'
        }
      }
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-sendout-type',
          variant: 'default'
        }
      }
    }
  },
  {
    name: 'sentOn',
    label: 'Resume Sent Date',
    options: {
      display: false,
      renderer: {
        id: 'dateBuilder',
        config: { format: DateFormats.SimpleDateTime }
      }
    }
  },
  {
    name: 'trackingDate',
    label: 'Tracking Date',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'dateBuilder',
        config: { format: DateFormats.SimpleDateTime }
      }
    }
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'id',
          textKey: 'name'
        }
      }
    }
  },
  {
    name: 'jobOrder',
    label: 'Job Order',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'id',
          textKey: 'title'
        }
      }
    }
  },
  {
    name: 'candidate',
    label: 'Candidate',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'id',
          textKey: 'fullName'
        }
      }
    }
  },
  {
    name: 'candidateAccountable',
    label: 'Recruiters',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'recruiter'
    }
  },
  {
    name: 'interviews',
    label: 'Interview Type',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'interview'
    }
  },

  {
    name: 'interviewDate',
    label: 'Interview Timestamp',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: {
        id: 'dateBuilder',
        config: { format: DateFormats.SimpleDateTime }
      }
    }
  },
  {
    name: 'feeAmount',
    label: 'Estimated Total Fee',
    options: {
      display: true,
      renderer: 'currency'
    }
  }
];
