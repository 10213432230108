export const QUESTIONS_PATHS = {
  'section2.areasToImprove': ['answer'],
  'section2.compareResults': ['answer'],
  'section2.employmentDate': ['startDate', 'endDate'],
  'section2.handlePosition': ['answer'],
  'section2.interpersonalRelations': ['answer'],
  'section2.qualityAttendance': ['answer'],
  'section2.reemploy': ['answer'],
  'section2.supervisedPeople': ['answer'],
  'section2.strongPoints': ['answer'],
  'section2.somethingToImprove': ['answer'],
  'section2.leaveCompany': ['answer'],
  'section3.summary': ['answer']
};

export const DrawerMode = Object.freeze({
  Edit: 'edit',
  Create: 'create',
  View: 'view'
});
