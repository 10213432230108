import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useChat } from 'features/message-center/hooks';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { When } from 'UI/components/atoms/When';
import FPQuickView from 'UI/components/organisms/inventoryProfiles/FPQuickView';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { UIStatus } from 'UI/constants/status';
import { SvgDefaultEmptyState } from 'UI/res';
import { getEntityType } from 'UI/utils/inventory';

import { formatInternationalPhone } from '../messageChat/chat.utils';
import { Chat, MessageComposer } from '../messageChat/components';

import { useStyles } from './styles';

export const MessageContent = ({ message, onClickShowList }) => {
  const classes = useStyles();
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);

  const {
    chat,
    chatStatus,
    handleClickRetry,
    handleClickSend,
    handleFetchMoreMessages,
    handleRefreshConversation,
    hasMoreMessages,
    triggerScrollToBottom
  } = useChat({
    externalNumber: message?.externalNumber
  });

  const toggleQuickViewOpen = () => setIsQuickViewOpen(prev => !prev);

  if (!message)
    return (
      <EmptyPlaceholder
        subtitle={strings.messageCenter.emptyState.unselected}
        customEmptyState={<SvgDefaultEmptyState height={400} />}
      />
    );

  const { externalNumber = '', fullName = '', entityId = '', entityType = '' } = message;

  const phoneNumber = formatInternationalPhone(externalNumber);

  const isEntityTypeValid = !!getEntityType(entityType);

  return (
    <>
      <div className={classes.contentContainer}>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <FPIconButton aria-label="show/hide message list" onClick={onClickShowList}>
              <MenuIcon color="primary" />
            </FPIconButton>

            <div className={classes.title}>
              <Typography variant="h6">{fullName}</Typography>
              <Typography variant="body2"> {phoneNumber}</Typography>
            </div>
          </div>

          <div>
            <FPIconButton
              onClick={handleRefreshConversation}
              tooltipProps={{
                title: strings.messageCenter.chat.refresh
              }}
            >
              <RefreshIcon />
            </FPIconButton>
            <When condition={isEntityTypeValid}>
              <FPIconButton
                onClick={toggleQuickViewOpen}
                tooltipProps={{
                  title: strings.inventoryProfiles.quickView.viewProfile
                }}
              >
                <VisibilityIcon />
              </FPIconButton>
            </When>
          </div>
        </div>

        <div className={classes.chatContainer}>
          <Chat
            chat={chat}
            chatStatus={chatStatus}
            hasMoreMessages={hasMoreMessages}
            onClickRetry={handleClickRetry}
            onFetchMoreMessages={handleFetchMoreMessages}
            triggerScrollToBottom={triggerScrollToBottom}
          />
        </div>

        <div className={classes.messageComposerContainer}>
          <MessageComposer
            disabled={chatStatus !== UIStatus.Success}
            entityId={entityId}
            entityType={entityType}
            externalNumber={externalNumber}
            onClickSend={handleClickSend}
            recipientFullName={fullName}
          />
        </div>
      </div>

      <When condition={entityId && entityType && isQuickViewOpen}>
        <FPQuickView
          id={entityId}
          drawerProps={{
            open: isQuickViewOpen
          }}
          entityType={entityType}
          onClose={toggleQuickViewOpen}
        />
      </When>
    </>
  );
};
