// @flow
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { extractObjectFromDataTable } from 'hooks/datatable';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import LogRocket from 'logrocket';
import qs from 'query-string';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { updateUiState } from 'UI/utils/uiHelpers';

import usePageTitle from './usePageTitle';

export const rowClickBuilder =
  ({
    callback,
    columns,
    data,
    getData,
    idKey = 'id',
    pickId = false,
    enableTracking = false,
    logRocketEvent = null
  }) =>
  ({ rowData }) => {
    const extractedObject = extractObjectFromDataTable(columns, [idKey], rowData);
    const selectedItem = data.find(item => item[idKey] === extractedObject[idKey]);
    const isItemAnId = pickId ? extractedObject[idKey] : selectedItem;

    enableTracking && LogRocket.track(logRocketEvent);
    callback && callback(isItemAnId, getData, data);
  };

type useNavigationToProfileProps = {
  data: Array<Object>,
  history: any,
  route: string
};

export const useNavigationToProfile = ({ data, route }: useNavigationToProfileProps) => {
  const history = useHistory();

  const handleRowClick = (selectedItem: Object) => {
    const { id } = data[selectedItem.rowIndex] ?? '';
    const ID = selectedItem[0];
    history.push(route.replace(':id', id || ID));
  };

  return handleRowClick;
};

type useProfileProps = {
  entity: string,
  profileId: string,
  includes: string,
  apiVersion: number
};

export const useProfile = ({
  entity,
  profileId,
  includes,
  apiVersion = 2,
  customEndpoint = undefined,
  shouldLoadTitle
}: useProfileProps) => {
  const [uiState, setUiState] = useState({
    isEditOpen: false,
    isReassignOpen: false,
    isPlacementOpen: false,
    selectedPlacementId: null,
    selectedSendoutId: null,
    isCandidateOpen: false,
    isFeeAgreementSummaryOpen: false,
    additionalData: null,
    selectedFeeAgreementId: null,
    isFeeAgreementOpen: false,
    companyTypeOpen: false,
    opsVerificationOpen: false,
    isJobOrderOpen: false,
    isSendoutOpen: false,
    selectedSendout: null,
    isWriteUpReadOnly: false,
    isWriteUpOpen: false,
    isWriteUpLoading: true,
    isAddHiringAuthorityOpen: false,
    isLinkHiringAuthorityOpen: false,
    shouldRefreshTableData: false,
    isQuickViewOpen: false,
    shouldLoadProfile: true
  });

  const endpoints = {
    [EntityType.Contact]: Endpoints.Names,
    [EntityType.Candidate]: Endpoints.Candidates,
    [EntityType.HiringAuthority]: Endpoints.HiringAuthorities,
    [EntityType.Company]: Endpoints.Companies,
    [EntityType.Joborder]: Endpoints.JobOrders
  };

  const endpoint = `${endpoints[entity]}/${profileId}`;

  const myEndpoint = qs.stringifyUrl({
    url: customEndpoint ?? endpoint,
    query: { includes }
  });

  const { Status, state, setState, refreshData } = useFetchWithStatus(
    myEndpoint,
    [],
    null,
    apiVersion
  );

  const { status, results } = state;
  const isProfileLoading = status === 'loading';

  const isLoading = useMemo(
    () => (!uiState.shouldLoadProfile ? true : isProfileLoading),
    [isProfileLoading, uiState.shouldLoadProfile]
  );

  const sharedTitle = results?.personalInformation?.full_name;

  const pageTitles = {
    [EntityType.Contact]: sharedTitle,
    [EntityType.Candidate]: sharedTitle,
    [EntityType.HiringAuthority]: results?.full_name,
    [EntityType.Company]: results?.name,
    [EntityType.Joborder]: results?.title
  };

  const pageTitle = pageTitles[entity];

  usePageTitle({ title: pageTitle, isLoading, shouldLoadTitle });

  /** :::::::::::::::::::::::::::::::::::
   *
   *  P R O F I L E    U T I L I T I E S
   *
   * ::::::::::::::::::::::::::::::::::::::
   */

  const handleProfileStateUpdate = (updatedItem: Object) =>
    setState(prev => ({
      ...prev,
      results: {
        ...prev.results,
        ...updatedItem
      }
    }));

  const handleProfileUiState = ({ statesToHandle }) =>
    setUiState(prevState => ({ ...prevState, ...statesToHandle }));

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *  P R O F I L E    E D I T I O N    A C T I O N S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const handleEditClick = () => setUiState(prevState => ({ ...prevState, isEditOpen: true }));

  const handleEditCompleted = async () => {
    setUiState(prevState => ({ ...prevState, isEditOpen: false }));
    await refreshData(false);
  };

  const handleEditClosed = () => updateUiState({ isEditOpen: false }, setUiState);

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   C O L L A B O R A T I O N S   A C T I O N S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const handleReassignClick = () => updateUiState({ isReassignOpen: true }, setUiState);
  const handleReassignClosed = () => updateUiState({ isReassignOpen: false }, setUiState);

  const handleReassignCompleted = (mainRecruiter, updatedProfile = results) => {
    const { recruiter } = results;
    handleReassignClosed();
    updatedProfile &&
      setState(prev => ({
        ...prev,
        results: {
          ...prev.results,
          recruiter: mainRecruiter || recruiter,
          coach: updatedProfile.coach,
          additionalRecruiters: updatedProfile.additionalRecruiters,
          free_game: updatedProfile.free_game
        }
      }));
  };

  return {
    handleEditClick,
    handleEditClosed,
    handleEditCompleted,
    handleProfileStateUpdate,
    handleProfileUiState,
    handleReassignClick,
    handleReassignClosed,
    handleReassignCompleted,
    isLoading,
    refreshData,
    results,
    setUiState,
    state,
    status,
    Status,
    uiState
  };
};

export default useProfile;
