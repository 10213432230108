import { makeStyles } from '@material-ui/core/styles';
import { input } from 'UI/constants/dimensions';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& fieldset': {
      borderRadius: input.borderRadius,
      backgroundColor: 'unset !important'
    }
  },
  select: {
    color: ({ itemId }) => (!itemId ? theme.palette.grey[500] : theme.palette.grey.black),
    height: '100%',
    '& > div': {
      borderRadius: `${`${input.borderRadius}px`} !important`,
      minHeight: 40,
      padding: theme.spacing(0, 2),
      backgroundColor: 'unset !important',
      display: 'flex',
      alignItems: 'center'
    }
  },
  selectMenu: {
    maxHeight: 400
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    margin: 0,
    '& label': {
      transform: 'translate(0, 14px) scale(1)'
    }
  },
  button: {
    fontSize: 14,
    textTransform: 'capitalize'
  },
  firstMenu: {
    backgroundColor: 'unset !important  '
  }
}));
