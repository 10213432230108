import * as React from 'react';

const SvgTableSearch = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path
        fill="#6A7381"
        d="m19.75 18.554-4.438-4.439a6.22 6.22 0 0 0 1.291-3.8 6.301 6.301 0 1 0-2.488 5.003l4.44 4.44a.857.857 0 0 0 1.196 0 .85.85 0 0 0 0-1.204ZM5.728 10.315a4.589 4.589 0 1 1 4.588 4.589 4.595 4.595 0 0 1-4.588-4.589Z"
      />
    </svg>
  );
};

export default SvgTableSearch;
