// @flow

import React from 'react';
import type { FPActionButtonProps } from 'types/app';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { getId } from 'UI/utils';

type ActionButtonsTypes = FPActionButtonProps & {
  show?: boolean
};

type CustomActionsMapperTypes = {
  buttons?: Array<ActionButtonsTypes>
};

const CustomActionsMapper = ({ buttons }: CustomActionsMapperTypes) =>
  buttons.map(
    ({ show = true, ...rest }) => show && <FPActionButton key={getId()} size="small" {...rest} />
  );

CustomActionsMapper.defaultProps = {
  buttons: []
};

export default CustomActionsMapper;
