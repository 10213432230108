import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export const Title = styled(Typography)(({ theme }) => ({
  letterSpacing: '0.5px',
  lineHeight: `${theme.spacing(3.5)}px`,
  marginTop: theme.spacing(3.5),
  textTransform: 'uppercase',
  fontSize: 12
}));

export const HeaderContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(4)
}));

export const ActionsContainer = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end'
}));

export const RefreshButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  textTransform: 'capitalize',
  color: theme.palette.primary.light,
  padding: theme.spacing(1.25, 2, 1.25, 2),
  fontSize: 14
}));

export const GridItem = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(3, 0)
}));
