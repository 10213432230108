import { THEME } from 'GlobalStyles';
import { currencyFormatter } from 'UI/utils';

import { createCustomTooltip } from '../finance.utils';

export const DEFAULT_OPTIONS = {
  hAxis: {
    title: 'Months'
  },
  colors: [THEME.palette.primary.main],
  legend: { position: 'none' },
  chartArea: { width: '80%', height: '75%' },
  bar: { groupWidth: '30%' },
  vAxis: {
    gridlines: { count: 4 },
    format: 'currency'
  },
  tooltip: { isHtml: true }
};

export const formatResponse = data => {
  if (!data || data?.length === 0)
    return [
      ['Months Range', 'PDA'],
      ['', 0]
    ];

  const sortedData = data.sort((a, b) => {
    const groupA = a.tenureGroup.split('-');
    const groupB = b.tenureGroup.split('-');

    const minA = parseInt(groupA[0], 10);
    const minB = parseInt(groupB[0], 10);

    return minA - minB;
  });

  const formattedData = [
    ['Months Range', 'PDA', { role: 'tooltip', type: 'string', p: { html: true } }],
    ...sortedData.map(item => {
      const tooltip = createCustomTooltip({
        title: 'PDA',
        category: item.tenureGroup,
        value: currencyFormatter(item.perDeskAverage)
      });
      return [item.tenureGroup, item.perDeskAverage, tooltip];
    })
  ];

  return formattedData;
};
