// @flow

import type { OperationResult } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';
import { getErrorMessage } from 'UI/utils';

import API from '../API';

const { BulkUploadAttachment, FilesDelete } = Endpoints;

export const uploadTemporaryAttachment = async (
  attachmentFormData: FormData
): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const response = await API.post(BulkUploadAttachment, attachmentFormData);
    if (response.status === 201) {
      result.success = true;
      result.data = { ...response.data };
    } else {
      result.alert = {
        severity: 'error',
        title: 'Unhandled Error',
        body: 'Unhandled error on bulk temporal attachment upload'
      };
    }
  } catch (error) {
    result.alert = {
      severity: 'error',
      title: "Couldn't upload images to temp folder",
      body: getErrorMessage(error),
      autoHideDuration: 8000
    };
  }
  return result;
};

export const deleteTemporaryAttachment = async (id: number): Promise<OperationResult> => {
  const result: OperationResult = { success: false };
  try {
    const response = await API.delete(FilesDelete.replace(':id', id));
    if (response.status === 200) {
      result.success = true;
      result.alert = {
        key: response.status,
        severity: 'success',
        title: 'File Deleted',
        body: response.data.message
      };
    }
  } catch (error) {
    result.alert = {
      key: error.status,
      severity: 'error',
      title: 'Remove File',
      body: getErrorMessage(error)
    };
  }
  return result;
};
