import React, { useState } from 'react';
import { GaugeStats, IconLabel, Widget } from 'features/command-center';
import { getProductivitySummary } from 'features/command-center/services';
import { getWeekRange } from 'features/command-center/utils';
import strings from 'strings';

import { formatResponse } from './CallsGauge.utils';

const STRINGS = strings.commandCenter.activity.callsGauge;

export const CallsGauge = ({ requestParams = {}, weeks, footerLabel }) => {
  const [week, setWeek] = useState(weeks?.[0] ?? null);
  const { startDate, endDate } = getWeekRange(week);

  const handleSelectWeek = (event, selectedWeek) => setWeek(selectedWeek);

  return (
    <Widget
      header={STRINGS.title}
      promises={[
        () =>
          getProductivitySummary({
            metrics: 'calls',
            startDate,
            endDate,
            ...requestParams
          })
      ]}
    >
      {([response]) => {
        const stats = formatResponse(response);

        return (
          <>
            <GaugeStats
              id="calls"
              stats={stats}
              week={week}
              weeks={weeks}
              onSelectWeek={handleSelectWeek}
            />
            {footerLabel && <IconLabel label={footerLabel} />}
          </>
        );
      }}
    </Widget>
  );
};
