// @flow
import React from 'react';
import { ENDPOINTS } from 'features/command-center/constants';
import strings from 'strings';
import type { Filters } from 'types/app';

import { InterviewsTable } from '../InterviewsTable';

import { COLUMNS, ORDER_BY_OPTIONS, VIRTUAL_PROPS } from './DebriefingCalls.constants';

const COPIES = strings.commandCenter.dominateSheet.debriefingCalls;
const TABLE_KEY = 'debrief-calls-list';

interface DebriefingCallsProps {
  filters: Filters[];
}

export const DebriefingCalls = ({ filters }: DebriefingCallsProps) => {
  return (
    <InterviewsTable
      tableKey={TABLE_KEY}
      endpoint={ENDPOINTS.debriefingCalls}
      columns={COLUMNS}
      orderByOptions={ORDER_BY_OPTIONS}
      virtualProps={VIRTUAL_PROPS}
      filters={filters}
      title={COPIES.title}
      variant="debriefing"
    />
  );
};
