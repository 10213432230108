// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Divider from '@material-ui/core/Divider';
import { globalStyles } from 'GlobalStyles';
import moment from 'moment';
import strings from 'strings';
import Text from 'UI/components/atoms/Text';
import UserComments from 'UI/components/molecules/UserComments';
import { DateFormats } from 'UI/constants/defaults';
import { PaymentMode } from 'UI/constants/entityTypes';
import { FeeAgreementFields } from 'UI/constants/status';
import { getFeeAgreementFieldsByPaymentWithVerbiage } from 'UI/utils/feeagreement';

import FeeAgreementSummaryFields from '../FeeAgreementSummaryFields';
import FeeAgreementValidationInputs from '../FeeAgreementValidationInputs';

type FeeAgreementRevalidationFormProps = {
  feeAgreement: any
};

const FeeAgreementRevalidationForm = (props: FeeAgreementRevalidationFormProps) => {
  const { feeAgreement } = props;

  const {
    declination_details = {},
    created_at,
    currentDeclinator,
    fee_agreement_payment_scheme_id
  } = feeAgreement;
  const paymentMode = fee_agreement_payment_scheme_id || PaymentMode.Standard;
  const paymentTermsMode = feeAgreement[FeeAgreementFields.PaymentTermsMode];

  const declinedFields = declination_details?.declined_fields || [];

  const form = useFormContext();
  const { reset } = form;

  useEffect(() => {
    feeAgreement && reset(feeAgreement);
  }, [feeAgreement, reset]);

  const feeAgreementFields = getFeeAgreementFieldsByPaymentWithVerbiage(
    paymentMode,
    paymentTermsMode
  );

  const summaryFields = feeAgreementFields.filter(
    field => declinedFields && !declinedFields.includes(field)
  );

  return (
    <>
      <Text variant="subtitle1" text={strings.feeAgreements.validation.declinationReason} />
      <UserComments
        avatarInitials={currentDeclinator?.personalInformation.initials}
        author={currentDeclinator?.personalInformation.full_name}
        date={moment(created_at).format(DateFormats.DetailDate)}
        note={declination_details?.declination_notes}
      />
      {declinedFields && declinedFields.length > 0 && (
        <>
          <Divider style={globalStyles.mediumDivider} />
          <div style={globalStyles.feeDrawerslabel}>
            <Text variant="subtitle1" text={strings.feeAgreements.validation.modifyDeclined} />
          </div>
          <FeeAgreementValidationInputs fields={declinedFields} paymentMode={paymentMode} />
          <Divider style={globalStyles.mediumDivider} />
        </>
      )}

      <FeeAgreementSummaryFields fields={summaryFields} feeAgreement={feeAgreement} />
    </>
  );
};

export default FeeAgreementRevalidationForm;
