// @flow
import React, { useState } from 'react';
import strings from 'strings';
import { InterviewDetailsDrawer } from 'UI/components/organisms/InterviewDetailsDrawer';
import { interviewDetailsSubmitHandlers } from 'UI/components/organisms/InterviewDetailsDrawer/utils';

import InterviewDetailsOptions from './InterviewDetailsOptions';

const INTERVIEW_OPTIONS_KEYS = {
  editInterview: 'editInterview',
  preparation: 'preparation',
  debriefing: 'debriefing',
  closingCall: 'closingCall',
  notes: 'notes'
};

interface InterviewDetailsProps {
  variant: 'interviewDetails' | 'followUp';
  onEditInterviewClick?: () => void;
  interviewId: number;
  isEditActionEnabled: boolean;
}

const STRINGS = strings.sendouts.interviews.interviewDetails;

export const InterviewDetails = ({
  variant,
  interviewId,
  onEditInterviewClick,
  isEditActionEnabled
}: InterviewDetailsProps) => {
  const [drawerVariant, setDrawerVariant] = useState(null);
  const [isEditingInterview, setIsEditingInterview] = useState(false);

  const openDrawer = drawerId => () => setDrawerVariant(drawerId);

  const closeDrawer = () => setDrawerVariant(null);

  const handleEditInterviewClick = () => {
    if (!onEditInterviewClick) return;
    onEditInterviewClick();
    setIsEditingInterview(!isEditingInterview);
  };

  const editInterviewText = isEditingInterview ? STRINGS.actions.cancel : STRINGS.actions.edit;

  const options = {
    interviewDetails: [
      {
        id: INTERVIEW_OPTIONS_KEYS.editInterview,
        title: editInterviewText,
        onClick: () => handleEditInterviewClick(),
        onSubmit: () => null,
        isVisible: isEditActionEnabled
      },
      {
        id: INTERVIEW_OPTIONS_KEYS.preparation,
        title: 'Preparation',
        onClick: openDrawer(INTERVIEW_OPTIONS_KEYS.preparation),
        onSubmit: interviewDetailsSubmitHandlers.preparation,
        isVisible: !!interviewId
      },
      {
        id: INTERVIEW_OPTIONS_KEYS.debriefing,
        title: 'Debriefing',
        onClick: openDrawer(INTERVIEW_OPTIONS_KEYS.debriefing),
        onSubmit: interviewDetailsSubmitHandlers.debriefing,
        isVisible: !!interviewId
      }
    ],
    followUp: [
      {
        id: INTERVIEW_OPTIONS_KEYS.closingCall,
        title: 'Closing Call',
        onClick: openDrawer(INTERVIEW_OPTIONS_KEYS.closingCall),
        onSubmit: () => null,
        isVisible: true
      },
      {
        id: INTERVIEW_OPTIONS_KEYS.notes,
        title: 'Notes',
        onClick: openDrawer(INTERVIEW_OPTIONS_KEYS.notes),
        onSubmit: () => null,
        isVisible: true
      }
    ]
  };

  const nullOption = () => null;

  const onSubmitVariant = drawerVariant
    ? options[variant].filter(item => item.id === drawerVariant)[0].onSubmit
    : nullOption;

  return (
    <>
      <InterviewDetailsOptions options={options[variant]} variant={variant} />
      {drawerVariant && (
        <InterviewDetailsDrawer
          interviewId={interviewId}
          drawerVariant={drawerVariant}
          setDrawerVariant={setDrawerVariant}
          open={!!drawerVariant}
          onClose={closeDrawer}
          onSubmit={onSubmitVariant}
        />
      )}
    </>
  );
};
