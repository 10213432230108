import { makeStyles } from '@material-ui/core';
import { flexAlignCenterSpaceBetween } from 'UI/utils/styles';

const minHeightList = 28;
const categoryRowWidth = '49%';

export const useStyles = makeStyles(
  theme => ({
    columnContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      '&:last-child': {
        marginBottom: 0
      }
    },
    rowContainer: {
      width: categoryRowWidth,
      marginBottom: theme.spacing(3),
      '&:last-child': {
        marginBottom: 0
      }
    },
    headersContainer: flexAlignCenterSpaceBetween,
    fileList: {
      minHeight: minHeightList
    },
    fileListDragging: {
      minHeight: minHeightList,
      backgroundColor: theme.palette.primary.FP50
    }
  }),
  { classNamePrefix: 'categoryCard' }
);
