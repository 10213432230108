import * as React from 'react';

const SvgSingleNeutralActionsSetting = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      fill={fill}
      {...props}
    >
      <g fill={fill}>
        <path d="M0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.25.23L3 23.29a.5.5 0 00.5.46h4a.5.5 0 00.5-.46L8.44 18a.25.25 0 01.25-.23h1.61a.24.24 0 00.21-.12 3.5 3.5 0 01.37-.49.42.42 0 00.12-.3v-.12a.44.44 0 00-.12-.3 3.26 3.26 0 01-.43-3.88 2.7 2.7 0 01.22-.33.24.24 0 000-.23A5.5 5.5 0 000 13.75z" />
        <circle cx={5.5} cy={3.75} r={3.5} />
        <circle cx={17.75} cy={16.78} r={1.5} />
        <path d="M12 18.16a1.78 1.78 0 001.7 2.95l1.41-.33a.47.47 0 01.54.31l.42 1.4a1.78 1.78 0 003.4 0l.42-1.4a.46.46 0 01.53-.31l1.42.33a1.74 1.74 0 001.93-.84 1.77 1.77 0 00-.23-2.11l-1-1.07a.46.46 0 010-.62l1-1.07a1.76 1.76 0 00.23-2.11 1.74 1.74 0 00-1.93-.84l-1.42.33a.46.46 0 01-.53-.31l-.42-1.4a1.78 1.78 0 00-3.4 0l-.42 1.4a.47.47 0 01-.54.31l-1.41-.33a1.78 1.78 0 00-1.7 3l1 1.07a.46.46 0 010 .62zm5.77-4.38a3 3 0 11-3 3 3 3 0 012.98-3z" />
      </g>
    </svg>
  );
};

export default SvgSingleNeutralActionsSetting;
