// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { THEME } from 'GlobalStyles';
import { getColumnsToRender } from 'hooks/datatable';
import strings from 'strings';
import FPIcon from 'UI/components/atoms/FPIcon';
import FPLinearProgress from 'UI/components/atoms/FPLinearProgress';
import { When } from 'UI/components/atoms/When';
import { StatsCard } from 'UI/components/molecules/cardContents';
import CashStatsContent from 'UI/components/molecules/CashStatsContent';
import DataTable from 'UI/components/organisms/DataTable';
import { useDataTableStyles } from 'UI/globalStyles/DataTableStyles';
import { SvgLock, SvgPlane } from 'UI/res';
import SvgAlphaPac from 'UI/res/icons/milano/AlphaPac';
import SvgSuccess from 'UI/res/icons/milano/Success';
import { currencyFormatter } from 'UI/utils';

import TabsView from '../TabsView';

import PILMap from './PILMap/PILMap';
import { FPTreeView } from './FPTreeView';
import { useStyles } from './styles';

const CHANNEL_PARTNER_MAX_ITEMS = 10;
const MILESTONE_EMPTY_STATE_SIZE = 200;

export const PILMapContent = ({ tree, title, countries, markers }) => {
  const classes = useStyles();

  return (
    <div className={classes.pilMapContainer}>
      <div>
        <FPTreeView tree={tree} title={title} />
      </div>
      <PILMap countries={countries} markers={markers} />
    </div>
  );
};

export const ChannelPartner = ({ cashAmount, descriptionItems }) => {
  const classes = useStyles();

  const shouldOverflowContent = descriptionItems.length >= CHANNEL_PARTNER_MAX_ITEMS;

  return (
    <CashStatsContent
      title={strings.recruiterProfile.channelPartnerTotal}
      cashAmount={cashAmount ?? 0}
    >
      <Typography align="center" variant="overline" classes={{ root: classes.channelPartnerTitle }}>
        {strings.recruiterProfile.channelPartner}
      </Typography>
      <When condition={descriptionItems.length}>
        <div
          className={clsx(
            classes.channelPartnerContainer,
            shouldOverflowContent && classes.channelPartnerContainerOverflow
          )}
        >
          {descriptionItems.map(item => {
            const formattedAmount = currencyFormatter(item.amount, 2);

            return (
              <Grid container key={item.name} spacing={2} justify="space-between" xs={12}>
                <Grid item>
                  <Typography variant="body2">{item?.name}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{formattedAmount}</Typography>
                </Grid>
              </Grid>
            );
          })}
        </div>
      </When>
      <When condition={!descriptionItems.length}>
        <Typography align="center" variant="body2">
          {strings.recruiterProfile.emptyChannelPartner}
        </Typography>
      </When>
    </CashStatsContent>
  );
};

export const CashIn = ({ cashInAmount, summaryCashIn, lastMonth }) => {
  const classes = useStyles();

  const formattedCasInAmount = currencyFormatter(cashInAmount, 2);

  return (
    <CashStatsContent title={strings.recruiterProfile.cashInTitle} cashAmount={summaryCashIn}>
      <Grid item xs={12} justify="center" container className={classes.periodContainer}>
        <Typography variant="overline" className={classes.periodText}>
          Last Month
        </Typography>
      </Grid>
      <Grid container spacing={1} justify="center" xs={12}>
        <Grid item>
          <Typography variant="body2">{lastMonth}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{formattedCasInAmount}</Typography>
        </Grid>
      </Grid>
    </CashStatsContent>
  );
};

export const productionConfig = ({ value, monthValue, month }) => {
  const formattedValue = currencyFormatter(value, 2);
  const formattedMonthStats = currencyFormatter(monthValue, 2);
  const formattedCashStats = `${month} ${formattedMonthStats}`;

  const PlacementStrings =
    monthValue >= 2
      ? strings.recruiterProfile.placementCardPlural
      : strings.recruiterProfile.placementCardSingular;

  return [
    {
      title: strings.recruiterProfile.productionYearToDate,
      value: formattedValue,
      subtitle: formattedCashStats
    },
    {
      title: strings.recruiterProfile.cashInYearToDate,
      value: formattedValue,
      subtitle: formattedCashStats
    },
    {
      title: strings.recruiterProfile.placementsYearToDate,
      value,
      subtitle: `${month} ${monthValue} ${PlacementStrings}`
    },
    {
      title: strings.recruiterProfile.fallOffsYearToDate,
      value,
      subtitle: `${month} ${monthValue} ${strings.recruiterProfile.monthlyFallOffs}`
    }
  ];
};

export const RecruiterProduction = ({ productionData, month }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {productionData.map(({ value, monthValue }, index) => {
        const productionItems = productionConfig({ value, monthValue, month });

        const { title, value: yearToDateValue, subtitle: monthStats } = productionItems[index];

        return (
          <Grid item key={title} md={3} sm={3} xs={6}>
            <StatsCard
              containerSettingsProps={{
                customContainerClass: classes.statsContainer
              }}
              subtitle={monthStats}
              title={title}
              value={yearToDateValue}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export const LightWeightTable = ({ columns, data = [], isLoading }) => {
  const dataTableClasses = useDataTableStyles();
  const finalColumns = getColumnsToRender(columns);

  return (
    <DataTable
      className={dataTableClasses.withoutToolbar}
      selectableRows="none"
      data={data}
      columns={finalColumns}
      loading={isLoading}
    />
  );
};

export const StatsBuilder = ({ dataConfig }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} classes={{ root: classes.statsWrapper }}>
      {dataConfig?.map(item => (
        <Grid item key={`${item.title} ${item.value}`} md={3} sm={3} xs={6}>
          <StatsCard
            key={item.title}
            subtitle={item.subtitle}
            title={item.title}
            value={item.value}
            variant="regular"
            containerSettingsProps={{
              shouldAddBottomSpace: false,
              customContainerClass: classes.statsContainer
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

type TabWrapperProps = {
  data: Object,
  children: React.ReactNode,
  onChangeTabIndex: () => any,
  selectedTab: number
};

export const TabWrapper = ({ data, children, onChangeTabIndex, selectedTab }: TabWrapperProps) => {
  const classes = useStyles();

  const wrappedTabs = data?.wrappedOptions?.length
    ? data?.wrappedOptions.map(item => ({
        id: item.label,
        label: item.label,
        view: children
      }))
    : [];

  return (
    <TabsView
      selectedTab={selectedTab}
      onChangeTabIndex={onChangeTabIndex}
      tabPanelProps={{
        className: data?.renderer === 'statsBuilder' && classes.statsTab
      }}
      tabs={wrappedTabs}
      panelHeight="unset"
    />
  );
};

type MilestoneProps = {
  description: string,
  emptyState: React.Node,
  isGoalReached: boolean,
  icon: React.Node,
  target: number,
  title: string,
  value: number
};

const Milestone = ({
  description,
  emptyState,
  isGoalReached,
  icon,
  target,
  title,
  value
}: MilestoneProps) => {
  const classes = useStyles();

  return (
    <Grid key={title} container item md={12} xs={12} spacing={1}>
      <Grid item md={12} xs={12}>
        <Typography align="left" variant="body2" className={classes.itemSectionTitle}>
          {title}
        </Typography>
      </Grid>
      <When condition={!isGoalReached}>
        <Grid item md={12} xs={12}>
          <FPLinearProgress color={THEME.palette.primary.main} max={target} value={value} />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography align="center" variant="body2">
            {`${currencyFormatter(value)} / ${currencyFormatter(target)}`}
          </Typography>
        </Grid>
      </When>
      <When condition={isGoalReached}>
        <Grid item md={12} xs={12} container justify="center">
          {emptyState}
        </Grid>
      </When>
      <Grid
        container
        item
        md={12}
        xs={12}
        justify="left"
        spacing={2}
        alignItems="left"
        alignContent="center"
      >
        <Grid item container xs={2} md={2} justify="center">
          <div className={classes.milestoneIconContainer}>
            <FPIcon component={icon} size={16} onSetColor={palette => palette.grey[500]} />
          </div>
        </Grid>
        <Grid item md={10} xs={10}>
          <Typography align="left" variant="body2">
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const POSITION_LIMITS = {
  searchConsultant: 500000,
  seniorSearchConsultant: 1000000,
  director: 2000000,
  seniorDirector: Infinity
};

const POSITION_DESCRIPTIONS = [
  {
    limit: POSITION_LIMITS.searchConsultant,
    initial: strings.recruiterProfile.milestones.searchConsultant.initial,
    success: strings.recruiterProfile.milestones.searchConsultant.successSenior,
    title: strings.recruiterProfile.milestones.searchConsultant.searchConsultantTitle
  },
  {
    limit: POSITION_LIMITS.seniorSearchConsultant,
    initial: strings.recruiterProfile.milestones.searchConsultant.initialSenior,
    success: strings.recruiterProfile.milestones.searchConsultant.successDirector,
    title: 'Sr Search Consultant'
  },
  {
    limit: POSITION_LIMITS.director,
    initial: strings.recruiterProfile.milestones.searchConsultant.initialSeniorDirector,
    success: strings.recruiterProfile.milestones.searchConsultant.successSeniorDirector,
    title: 'Director'
  },
  {
    limit: POSITION_LIMITS.seniorDirector,
    title: 'Sr Director',
    success: strings.recruiterProfile.milestones.searchConsultant.successSeniorDirector
  }
];

const getCurrentRecruiterTitle = (production: number) => {
  const position = POSITION_DESCRIPTIONS.find(p => production <= p.limit);

  const title = POSITION_DESCRIPTIONS.find(p => production < p.limit);

  const description = production === position.limit ? position.success : position.initial;

  return position
    ? { title: title.title, limit: position.limit, description }
    : { title: POSITION_DESCRIPTIONS[POSITION_DESCRIPTIONS.length - 1].title, limit: Infinity };
};

type MilestonesProps = {
  yearProduction: number,
  historicProduction: number,
  alphaPacTarget: number
};

export const Milestones = ({
  yearProduction,
  historicProduction,
  alphaPacTarget
}: MilestonesProps) => {
  const { title, limit, description } = getCurrentRecruiterTitle(historicProduction);

  const isSeniorDirector = title === POSITION_DESCRIPTIONS[POSITION_DESCRIPTIONS.length - 1].title;
  const hasAlphaPacTrip = yearProduction >= alphaPacTarget;

  return (
    <When condition={yearProduction && historicProduction && alphaPacTarget}>
      <Grid container spacing={2}>
        <Milestone
          title={title}
          target={limit}
          isGoalReached={isSeniorDirector}
          value={historicProduction}
          emptyState={<SvgSuccess size={MILESTONE_EMPTY_STATE_SIZE} />}
          icon={SvgLock}
          description={description}
        />
        <Milestone
          title="Alpha Pac"
          isGoalReached={hasAlphaPacTrip}
          target={alphaPacTarget}
          value={yearProduction}
          emptyState={<SvgAlphaPac size={MILESTONE_EMPTY_STATE_SIZE} />}
          icon={SvgPlane}
          description={
            hasAlphaPacTrip
              ? strings.recruiterProfile.milestones.alphaPac.success
              : strings.recruiterProfile.milestones.alphaPac.progress
          }
        />
      </Grid>
    </When>
  );
};
