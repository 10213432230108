// @flow
import React, { useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import { UserProviderContext } from 'providers/UserProvider';
import strings from 'strings';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import { backNavigateListConfig } from 'UI/constants/config';
import { Endpoints } from 'UI/constants/endpoints';

import { useStyles } from '../styles';

import Content from './Content';

const ToolsUsageDashboard = props => {
  const classes = useStyles();
  const [currentUser] = useContext(UserProviderContext);
  const FIRST_ROLE = 0;

  const { Status } = useFetchWithStatus(
    `${Endpoints.DashboardUserLeaders.replace(':id', currentUser.id).replace(
      ':roleId',
      currentUser.roles[FIRST_ROLE].role_id
    )}`
  );

  return (
    <Status
      loading={
        <ContentPageLayout
          titleLabelProps={backNavigateListConfig}
          customTitleGridConfig={{
            xs: 12,
            sm: 7,
            lg: 7
          }}
          text={strings.dashboard.toolsUsageDashboard.title}
        >
          <div className={classes.spinnerContainer}>
            <CircularProgress color="inherit" size={40} />
          </div>
        </ContentPageLayout>
      }
      success={data => (data ? <Content {...props} data={data} /> : null)}
    />
  );
};

export default ToolsUsageDashboard;
