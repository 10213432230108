import React, { useState } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import strings from 'strings';

import { useStyles } from '../styles';

import FeeAgreementExpandableRow, { SplitCell } from './FeeAgreementExpandableRow';

const ExpandableRowButton = ({ item, status, onMouseDown }) => {
  const classes = useStyles();
  const isGpacOrRegionalRow = [
    strings.dashboard.shared.gpac,
    strings.dashboard.shared.regional
  ].includes(item.team);
  return (
    <>
      {!isGpacOrRegionalRow ? (
        <span
          role="button"
          tabIndex={0}
          className={classes.expandableRowButton}
          key={`${item.team}`}
          onMouseDown={onMouseDown}
        >
          {status.expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </span>
      ) : (
        ''
      )}
    </>
  );
};
const FeeAgreementRow = ({ item, week, year }) => {
  const [status, setStatus] = useState({
    coach: '',
    expanded: false
  });
  const classes = useStyles();

  const isYearView = !!year?.date;

  const tableColumns = isYearView
    ? [
        {
          label: item.team === strings.dashboard.shared.regional ? item.regional : item.team,
          class: classes.team
        },
        { label: item.january_total_s, labelDown: item.january_total },
        { label: item.february_total_s, labelDown: item.february_total },
        { label: item.march_total_s, labelDown: item.march_total },
        { label: item.april_total_s, labelDown: item.april_total },
        { label: item.may_total_s, labelDown: item.may_total },
        { label: item.june_total_s, labelDown: item.june_total },
        { label: item.july_total_s, labelDown: item.july_total },
        { label: item.august_total_s, labelDown: item.august_total },
        { label: item.september_total_s, labelDown: item.september_total },
        { label: item.october_total_s, labelDown: item.october_total },
        { label: item.november_total_s, labelDown: item.november_total },
        { label: item.december_total_s, labelDown: item.december_total },
        { label: item.total_s, labelDown: item.total }
      ]
    : [
        {
          label: item.team === strings.dashboard.shared.regional ? item.regional : item.team,
          class: classes.team
        },
        { label: item.m },
        { label: item.t },
        { label: item.w },
        { label: item.th },
        { label: item.f },
        { label: item.total, class: classes.total },
        { label: item.m_s },
        { label: item.t_s },
        { label: item.w_s },
        { label: item.th_s },
        { label: item.f_s },
        { label: item.total_s, class: classes.total },
        { label: item.percentage }
      ];

  return (
    <>
      <tr className={item.team !== '' ? classes[item.team] : ''}>
        <td className={classes.expandableButton}>
          <ExpandableRowButton
            item={item}
            status={status}
            onMouseDown={() =>
              setStatus({
                coach: item.team,
                expanded: !status.expanded
              })
            }
          />
        </td>

        {tableColumns.map(column => (
          <td className={column.class || classes.rowFontWeight}>
            <SplitCell column={column} header={false} />
          </td>
        ))}
      </tr>
      {status.expanded ? (
        <FeeAgreementExpandableRow coach={status.coach} week={week} year={year} />
      ) : (
        ''
      )}
    </>
  );
};

export default FeeAgreementRow;
