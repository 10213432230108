// @flow
import { useCallback, useEffect, useRef, useState } from 'react';
import { FlyToInterpolator, WebMercatorViewport } from 'react-map-gl';
import bbox from '@turf/bbox';
import multiPoint from 'turf-multipoint';
import { usCenterCoordinates } from 'UI/constants/defaults';
import { relDiff, roundDecimals } from 'UI/utils';

type MercatorViewport = {
  width?: number,
  height?: number,
  longitude: number,
  latitude: number,
  zoom?: number,
  pitch?: number,
  bearing?: number,
  altitude?: number,
  nearZMultiplier?: number,
  farZMultiplier?: number
};

/* MapboxGL crashes if we try to move the viewport to the same coordinates. This function is intended to avoid this behaviour.
 * Also it's necessary to ask for a relative difference because sometimes because of floating point operations the viewport comparisons
 * don't work with strict equality
 */
const isSameViewport = (viewportLat, viewportLong, lat, long) => {
  const numberOfDecimals = 7;
  return (
    relDiff(roundDecimals(lat, numberOfDecimals), roundDecimals(viewportLat, numberOfDecimals)) <
      0.01 &&
    relDiff(roundDecimals(long, numberOfDecimals), roundDecimals(viewportLong, numberOfDecimals)) <
      0.01
  );
};

const getPoints = markers => {
  if (!markers) return [];

  return markers.map(mkr => (mkr.longitude ? [mkr.longitude, mkr.latitude] : []));
};

export const useMap = ({ selectedRecruiter, markers, selectedEntity, zoom = 4, cleanUp }) => {
  const latestViewport = useRef<MercatorViewport>(usCenterCoordinates);
  const [shouldFitToCoordinates, setShouldFitToCoordinates] = useState(false);
  const [popupInfo, setPopupInfo] = useState(selectedEntity);
  const [visiblePoints, setVisiblePoints] = useState([]);
  const [viewport, setViewport] = useState({
    latitude: usCenterCoordinates.latitude,
    longitude: usCenterCoordinates.longitude,
    zoom,
    width: '100%',
    height: '100%'
  });

  const fitToCoordinates = useCallback(() => {
    if (!visiblePoints || !visiblePoints.length || !shouldFitToCoordinates) return;

    const newViewport = new WebMercatorViewport(latestViewport.current);
    const pointsFeature = multiPoint(visiblePoints);
    const [minLng, minLat, maxLng, maxLat] = bbox(pointsFeature);

    if (newViewport.width === 1) return;

    const {
      longitude: newLong,
      latitude: newLat,
      zoom: newZoom
    } = newViewport.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat]
      ],
      { padding: 50 }
    );

    if (
      !isSameViewport(
        latestViewport.current.latitude,
        latestViewport.current.longitude,
        newLat,
        newLong
      )
    ) {
      // the map crashes if move to same viewport
      handleViewportChange({
        longitude: newLong,
        latitude: newLat,
        zoom: newZoom > 19 ? 10 : newZoom,
        transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
        transitionDuration: 'auto'
      });
    }
  }, [visiblePoints, shouldFitToCoordinates]);

  useEffect(() => {
    fitToCoordinates();
  }, [visiblePoints, fitToCoordinates]);

  useEffect(() => {
    setVisiblePoints([]);

    return () => {
      cleanUp && cleanUp();
    };
  }, [cleanUp]);

  useEffect(() => {
    setPopupInfo(null);
    const points = getPoints(markers);
    setVisiblePoints(points);
  }, [markers]);

  useEffect(() => {
    if (!selectedRecruiter) return;

    const recruiterStates = selectedRecruiter.states;

    if (recruiterStates && recruiterStates.length === 1) {
      if (
        !isSameViewport(
          latestViewport.current.latitude,
          latestViewport.current.longitude,
          selectedRecruiter.latitude,
          selectedRecruiter.longitude
        )
      ) {
        handleViewportChange({
          longitude: selectedRecruiter.longitude,
          latitude: selectedRecruiter.latitude,
          zoom: 5,
          transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
          transitionDuration: 'auto'
        });
      }
    } else {
      const points = recruiterStates.map(state => [state.longitude, state.latitude]);
      setVisiblePoints(points);
    }

    setPopupInfo(selectedRecruiter);
  }, [selectedRecruiter]);

  useEffect(() => {
    if (!selectedEntity) return;
    setPopupInfo(selectedEntity);
  }, [selectedEntity]);

  const handleMarkerEnter = info => {
    setPopupInfo(info);
  };

  const handleViewportChange = vp => {
    latestViewport.current = vp;
    setViewport(vp);
    setShouldFitToCoordinates(true);
  };

  const handlePopupInfo = info => setPopupInfo(info);
  const handleClosePopUp = () => {
    setPopupInfo(null);
  };

  return {
    handleClosePopUp,
    handleMarkerEnter,
    handlePopupInfo,
    handleViewportChange,
    popupInfo,
    viewport
  };
};
