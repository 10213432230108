export const FormFieldsMap = {
  EmployerDebrief: {
    key: 'employerDebrief'
  },
  CandidateDebrief: {
    key: 'candidateDebrief'
  },
  ExistingCandidateDebriefId: {
    key: 'existingCandidateDebriefId'
  },
  ExistingEmployerDebriefId: {
    key: 'existingEmployerDebriefId'
  }
};

export const DebriefTypes = [
  { key: 'EmployerDebrief', label: 'Employer' },
  { key: 'CandidateDebrief', label: 'Candidate' }
];
