// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { displayVersionBar, updateVersion } from 'actions/app';
import axios from 'axios';
import { Socket, SocketContext } from 'context/socket';
import { ChatDrawerProvider } from 'features/message-center/components/messageChat/ChatDrawerProvider';
import { GlobalStyles, THEME, useGlobalStyles } from 'GlobalStyles';
import useInterval from 'hooks/interval';
import { SnackbarProvider } from 'notistack';
import FeatureFlagsProvider from 'providers/FeatureFlagsProvider';
import Routes from 'routes/Routes';

import NotificationProvider from './providers/NotificationProvider';
import UserProvider from './providers/UserProvider';

import 'App.css';

const App = () => {
  const dispatch = useDispatch();
  const globalClasses = useGlobalStyles();
  const [requiresReload, setRequiresReload] = useState(false);
  const socketService = new Socket();

  const clientVersion = document
    .querySelector("meta[name='client-version']")
    .getAttribute('content');

  useInterval(async () => {
    if (requiresReload || window.location.hostname === 'localhost') return;

    try {
      const response = await axios.get(`/version`);
      if (response.status !== 200) return;

      const lastVersion = response.data.version;

      if (clientVersion !== lastVersion) {
        dispatch(displayVersionBar(clientVersion));
        setRequiresReload(true);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, 120000);

  useEffect(() => {
    dispatch(updateVersion(clientVersion));
  }, [clientVersion, dispatch]);

  return (
    <CssBaseline>
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate
        classes={{ containerRoot: globalClasses.snackContainer }}
      >
        <FeatureFlagsProvider>
          <SocketContext.Provider value={socketService}>
            <MuiThemeProvider theme={THEME}>
              <GlobalStyles />
              <BrowserRouter>
                <UserProvider>
                  <NotificationProvider>
                    <ChatDrawerProvider>
                      <Routes />
                    </ChatDrawerProvider>
                  </NotificationProvider>
                </UserProvider>
              </BrowserRouter>
            </MuiThemeProvider>
          </SocketContext.Provider>
        </FeatureFlagsProvider>
      </SnackbarProvider>
    </CssBaseline>
  );
};

export default App;
