// @flow
import React from 'react';
import HomeItemsRenderer from 'UI/components/templates/HomeItemsCreation/HomeItemsRenderer';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag } from 'UI/utils';

type RendererChooserProps = {
  canSelectCoach: boolean,
  canSelectRegional: boolean,
  columns: any[],
  EmptyPlaceholderRenderer: React.ReactNode,
  selectedCoachId?: number | string,
  selectedRegionalId?: number | string,
  shouldShowHome: boolean
};

const RendererChooser = ({
  canSelectCoach,
  canSelectRegional,
  columns,
  EmptyPlaceholderRenderer,
  selectedCoachId,
  selectedRegionalId,
  shouldShowHome
}: RendererChooserProps) => {
  const areMultipleDivisionsEnable = hasFeatureFlag(FeatureFlags.HomeMultipleRegions);
  return shouldShowHome && columns ? (
    <HomeItemsRenderer
      columnsParam={columns}
      coachId={selectedCoachId}
      regionalId={areMultipleDivisionsEnable ? selectedRegionalId : null}
    />
  ) : (
    <EmptyPlaceholderRenderer
      shouldSelectTeam={
        areMultipleDivisionsEnable ? canSelectCoach || canSelectRegional : canSelectCoach
      }
    />
  );
};

export default RendererChooser;
