import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { FeatureFlags } from 'UI/constants/featureFlags';

const ChatContext = React.createContext();

export const ChatDrawerProvider = ({ children }) => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [recipient, setRecipient] = useState(null);
  const [onSendNewMessage, setOnSendNewMessage] = useState(null);

  const recipientPhone = recipient?.phone?.replace(/\D/g, '');
  const recipientCode = recipient?.countryCode ?? '+1';
  const recipientPhoneWithCode = recipient?.phone ? `${recipientCode}${recipientPhone}` : null;

  const openDrawer = useCallback(
    ({ recipient: newRecipient, onSendNewMessage: newOnSendNewMessage }) => {
      setRecipient(newRecipient);
      setOnSendNewMessage(() => newOnSendNewMessage);

      const canUseDrawerChat = checkIfFeatureFlagEnabled(FeatureFlags.MessageCenterDrawerChat);
      if (!canUseDrawerChat) return;

      setIsDrawerOpen(true);
    },
    [checkIfFeatureFlagEnabled]
  );

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
    onSendNewMessage && onSendNewMessage();
  }, [onSendNewMessage]);

  const value = useMemo(() => {
    return {
      recipient,
      isDrawerOpen,
      recipientPhoneWithCode,
      openDrawer,
      closeDrawer,
      onSendNewMessage
    };
  }, [recipient, isDrawerOpen, recipientPhoneWithCode, openDrawer, closeDrawer, onSendNewMessage]);

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
};
