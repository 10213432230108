// @flow
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import { buildRowActionsRendererDefinition, getColumnsToRender } from 'hooks/datatable';
import { rowClickBuilder } from 'hooks/profileActions';
import strings from 'strings';
import FPHtmlViewer from 'UI/components/atoms/FPHtmlViewer';
import FPIcon from 'UI/components/atoms/FPIcon';
import DataTable from 'UI/components/organisms/DataTable';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { useDataTableStyles } from 'UI/globalStyles/DataTableStyles';
import { SvgInformationCircle } from 'UI/res';

import { useStyles } from '../styles';
import { COLUMNS_DUPLICATE_TABLE } from '../utils';

const { shared: sharedCopies } = strings;

interface SendoutDuplicatesProps {
  data: Array;
  title: string;
  subtitle: string;
  onNavigation: () => void;
  onClose: () => void;
  onBack: () => void;
}

const SendoutDuplicates = ({
  data,
  title,
  subtitle,
  onNavigation,
  onClose,
  onBack
}: SendoutDuplicatesProps) => {
  const dataTableClasses = useDataTableStyles();
  const classes = useStyles();

  const [uiState] = useState({
    showBackNavigation: true
  });

  const handleAction = async itemId => {
    if (!itemId) return;
    onNavigation && onNavigation(itemId);
  };

  const actionButton = buildRowActionsRendererDefinition({
    actions: [
      {
        name: 'view',
        onClick: rowClickBuilder({
          columns: COLUMNS_DUPLICATE_TABLE,
          data,
          pickId: true,
          callback: handleAction
        })
      }
    ]
  });

  const finalColumns = getColumnsToRender([...COLUMNS_DUPLICATE_TABLE, actionButton]);

  return (
    <DrawerContentLayout
      title={title}
      onBack={onBack}
      onClose={onClose}
      uiState={uiState}
      isSaveButtonMode={false}
      footerActionsProps={{
        primaryProps: {
          text: sharedCopies.ui.goBack,
          onClick: onBack
        },
        secondaryProps: {
          text: sharedCopies.ui.close,
          onClick: onClose
        }
      }}
      contentProps={{
        className: classes.drawerContent
      }}
    >
      <Grid item className={classes.subtitleContainer} xs={12}>
        <Alert
          severity="info"
          icon={
            <FPIcon component={SvgInformationCircle} onSetColor={palette => palette.info.light} />
          }
        >
          <FPHtmlViewer content={subtitle} />
        </Alert>
      </Grid>

      <DataTable
        columns={finalColumns}
        data={data}
        sort
        isServerSide={false}
        filter={false}
        search={false}
        elevation={0}
        pagination={false}
        selectableRowsHeader={false}
        selectableRows="none"
        className={clsx(classes.tableClasses, dataTableClasses.withoutToolbar)}
      />
    </DrawerContentLayout>
  );
};

export default SendoutDuplicates;
