// @flow
import React from 'react';
import { OutstandingPerformanceCard } from 'UI/components/molecules/cardContents';
import DrawBalanceItem from 'UI/components/molecules/DrawBalanceItem';

export const WidgetRenderers = {
  drawBalance: ({ draw_balance: drawBalance, section }) => (
    <DrawBalanceItem drawBalanceAmount={drawBalance} link={section?.action?.uri} />
  ),
  placementCard: ({ company, created_at, id, fee_amount, splitUsers }) => (
    <OutstandingPerformanceCard
      amount={fee_amount}
      date={created_at}
      key={id}
      users={splitUsers}
      subtitle={company.industry}
    />
  )
};
