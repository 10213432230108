// @flow
const saveFilters = (name: string, data: any) => {
  localStorage.setItem(name, JSON.stringify(data));
};

const getFilters = (name: string): any => {
  const item = localStorage.getItem(name);
  if (item) {
    const { filters, params, userFilter } = JSON.parse(item);
    return { filters, params, userFilter };
  }
  return {
    filters: {},
    params: {}
  };
};

const savePreferences = (name: string, data: any) => {
  const previous = getPreferences(name);
  const newPreferences = {
    ...previous,
    ...data
  };
  localStorage.setItem(name, JSON.stringify(newPreferences));
};

const getPreferences = (name: string): any => {
  const item = localStorage.getItem(name);
  return item ? JSON.parse(item) : {};
};

export { getFilters, getPreferences, saveFilters, savePreferences };
