// @flow

import React from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { showReferenceReleaseComposer } from 'actions/joborder';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import { DateFormats } from 'UI/constants/defaults';
import { toLocalTime } from 'UI/utils';

export type PlacementReferenceReleaseViewProps = {
  candidateId: number,
  email: any
};

const PlacementReferenceReleaseView = ({
  candidateId,
  email
}: PlacementReferenceReleaseViewProps) => {
  const dispatch = useDispatch();

  const sentDate = toLocalTime(email?.created_at).format(DateFormats.SimpleDateTime);

  const handleClick = () =>
    dispatch(
      showReferenceReleaseComposer({
        candidateId,
        readOnly: true
      })
    );

  return email ? (
    <NumberedForm.Item title="Reference Release Email">
      <Typography>
        Sent on: <br />
        <b>{sentDate}</b> <FPActionButton variant="text" text="View Email" onClick={handleClick} />
      </Typography>
    </NumberedForm.Item>
  ) : null;
};

export default PlacementReferenceReleaseView;
