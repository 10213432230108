import React from 'react';
import { Widget } from 'features/command-center';
import { getPilById } from 'features/command-center/services';
import strings from 'strings';

import { formatResponse } from './PilMap.utils';
import PilMapContent from './PilMapContent';

const COPIES = strings.commandCenter.activity.pil;

export const PilMap = ({ requestParams = {} }) => {
  return (
    <Widget header={COPIES.title} promises={[() => getPilById(requestParams)]}>
      {([response]) => {
        const data = formatResponse(response);

        return <PilMapContent data={data} />;
      }}
    </Widget>
  );
};
