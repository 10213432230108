// @flow

export const ChangeLogsColumns = [
  {
    name: 'id',
    options: {
      display: 'excluded'
    }
  },
  {
    name: 'logTypeLabel',
    label: 'History',
    options: {
      display: true,
      renderer: 'default',
      sort: false
    }
  },
  {
    name: 'operationLabel',
    label: 'Action',
    options: {
      display: false,
      renderer: 'default',
      sort: false
    }
  },
  {
    name: 'created_at',
    label: 'Creation Date',
    options: {
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'updated_at',
    label: 'Updated at',
    options: {
      display: false,
      renderer: 'date'
    }
  },
  {
    name: 'vCreatedBy.initials',
    label: 'Initials',
    options: {
      display: true,
      renderer: 'default',
      sort: false
    }
  },
  {
    name: 'vCreatedBy.user_name',
    label: 'Created by',
    options: {
      display: true,
      renderer: 'contactName',
      sort: false
    }
  }
];
