// @flow
import API from 'services/API';
import { Endpoints } from 'UI/constants/endpoints';
import { getErrorMessage } from 'UI/utils';

const tinymceBaseConfig = {
  height: 400,
  selector: 'textarea',
  content_style: 'html, body { height: fit-content; min-height: 300px; }',
  menubar: false,
  contextmenu: false,
  image_description: false,
  toolbar_mode: 'wrap',
  browser_spellcheck: true
};

const tinymceBasicToolbar = `undo redo |
  bold italic underline |
  backcolor forecolor |
  alignleft aligncenter alignright alignjustify |
  bullist numlist |
  image link |
`;

const tinymceBasePlugins = [
  'advlist autolink lists charmap print preview anchor',
  'searchreplace visualblocks code fullscreen',
  'insertdatetime media table paste code help wordcount'
];

export const tinymceConfig = {
  ...tinymceBaseConfig,
  plugins: tinymceBasePlugins,
  toolbar: tinymceBasicToolbar
};

export const tinymceBulkConfig = {
  ...tinymceBaseConfig,
  plugins: [...tinymceBasePlugins, 'link image'],
  toolbar: tinymceBasicToolbar.replace(/underline /, 'underline fontsizeselect'),
  images_upload_handler: async (blob, success, failure) => {
    try {
      const formData = new FormData();
      formData.append('upload', blob.blob(), blob.filename());
      const response = await API.post(Endpoints.BulkEmailBodyImage, formData);
      if (response.status === 201) {
        const { url } = response.data;
        success(url);
      }
    } catch (e) {
      failure(getErrorMessage(e));
    }
  }
};

export const backNavigateListConfig = {
  backNavigation: true
};
