import * as React from 'react';

const SvgFile = props => {
  const { size, fill } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={fill} {...props}>
      <g fill={fill}>
        <path d="M21.71 5.71 16.29.29a1 1 0 0 0-.7-.29H4a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6.41a1 1 0 0 0-.29-.7ZM20 21.5a.5.5 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5v-19a.5.5 0 0 1 .5-.5h10.25a.25.25 0 0 1 .25.25V5a2 2 0 0 0 2 2h2.75a.25.25 0 0 1 .25.25Z" />
        <rect x={6.5} y={10.5} width={3} height={2} rx={0.5} />
        <rect x={6.5} y={14} width={3} height={2} rx={0.5} />
        <rect x={6.5} y={17.5} width={3} height={2} rx={0.5} />
        <rect x={11} y={10.5} width={6.5} height={2} rx={0.5} />
        <rect x={11} y={14} width={6.5} height={2} rx={0.5} />
        <rect x={11} y={17.5} width={6.5} height={2} rx={0.5} />
      </g>
    </svg>
  );
};

export default SvgFile;
