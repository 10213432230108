import * as React from 'react';

const SvgInformationCircle = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      fill={fill}
      {...props}
    >
      <path
        d="M12 0a12 12 0 1012 12A12 12 0 0012 0zm.25 5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm2.25 13.5h-4a1 1 0 010-2h.75a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-.75a1 1 0 010-2h1a2 2 0 012 2v4.75a.25.25 0 00.25.25h.75a1 1 0 010 2z"
        fill={fill}
      />
    </svg>
  );
};

export default SvgInformationCircle;
