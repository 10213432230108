import * as React from 'react';

const SvgStar = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path
        fill={fill}
        d="M19.81 10.12a1.54 1.54 0 0 0-1.22-1L15 8.57 13.34 5.3a1.5 1.5 0 0 0-2.68 0L9 8.57l-3.63.52a1.5 1.5 0 0 0-1.21 1 1.47 1.47 0 0 0 .38 1.54l2.66 2.56-.62 3.59a1.47 1.47 0 0 0 .6 1.46 1.49 1.49 0 0 0 1.57.12l3.25-1.7 3.25 1.7a1.5 1.5 0 0 0 2.17-1.59l-.62-3.58 2.62-2.54a1.49 1.49 0 0 0 .39-1.53Z"
      />
    </svg>
  );
};

export default SvgStar;
