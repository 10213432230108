// @flow
import moment from 'moment';
import strings from 'strings';
import { DateFormats } from 'UI/constants/defaults';

export const BoolEnum = {
  True: 'T',
  False: 'F'
};

export const BoolDescription = {
  [BoolEnum.True]: strings.shared.ui.yes,
  [BoolEnum.False]: strings.shared.ui.no
};

export const FieldKeys = {
  CommitAmount: 'commit_amount',
  CommitToPay: 'commit_to_pay',
  CommitDate: 'commit_date',
  Notes: 'collections_notes',
  Id: 'id',
  PlacementId: 'otherrefnum'
};

export const FormFieldsMap = {
  CommitAmount: {
    key: FieldKeys.CommitAmount,
    inboundAccesor: ({ commit_amount: commitAmount }) =>
      // eslint-disable-next-line no-useless-escape
      commitAmount ? commitAmount.replace(/[^0-9\.-]+/g, '') : '',
    outboundAccesor: commitAmount => (commitAmount !== '' ? commitAmount : null)
  },
  CommitToPay: {
    key: FieldKeys.CommitToPay,
    inboundAccesor: ({ commit_to_pay: commitToPay }) => commitToPay || '',
    outboundAccesor: commitToPay => commitToPay === BoolEnum.True
  },
  CommitDate: {
    key: FieldKeys.CommitDate,
    inboundAccesor: ({ commit_date: commitDate }) =>
      commitDate ? moment(commitDate, DateFormats.SimpleDate).toDate() : null,
    outboundAccesor: commitDate =>
      commitDate ? commitDate.format(DateFormats.QueryShortFormat) : null
  },
  Notes: {
    key: FieldKeys.Notes
  }
};

export const FormFields = [
  FormFieldsMap.CommitAmount.key,
  FormFieldsMap.CommitToPay.key,
  FormFieldsMap.CommitDate.key,
  FormFieldsMap.Notes.key
];

export const getMapForSpecificFields = (sourceFieldMap: Object, fields: Array) => {
  const finalMap = {};
  Object.keys(sourceFieldMap).forEach(keyName => {
    if (fields.includes(sourceFieldMap[keyName].key)) {
      finalMap[keyName] = sourceFieldMap[keyName];
    }
  });
  return finalMap;
};
