import * as React from 'react';

const SvgCandidate = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M.73 17.88H2.5a.25.25 0 01.25.22l.43 5.21a.49.49 0 00.49.45h3.92a.51.51 0 00.49-.45l.43-5.21a.25.25 0 01.24-.22h1.78a.49.49 0 00.49-.49V14A5.39 5.39 0 10.24 14v3.43a.49.49 0 00.49.45z" />
      <path d="M1.47 4.16A4.16 4.16 0 105.63 0a4.17 4.17 0 00-4.16 4.16zm4.16 2.21a2.21 2.21 0 01-2.21-2.21 2.1 2.1 0 01.16-.79.29.29 0 01.15-.14.26.26 0 01.21 0A5.29 5.29 0 006.71 4a5.26 5.26 0 00.89 0 .19.19 0 01.23.19 2.21 2.21 0 01-2.2 2.18zM23.53 13.17a.49.49 0 00-.47 0c-2.18 1-3 .37-3.52 0A4 4 0 0016 12.7l-1.24.42a.5.5 0 00-.33.47V23a1 1 0 002 0v-3.81a.24.24 0 01.19-.24 3 3 0 012.4.43 4 4 0 002.22.69 4.52 4.52 0 002.26-.65.48.48 0 00.29-.45v-5.38a.5.5 0 00-.26-.42z" />
    </svg>
  );
};

export default SvgCandidate;
