import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { EntityRoutes } from 'routes/constants';
import MainLayout from 'routes/MainLayout';
import { isAuthenticated as ensureUserIsAuthenticated } from 'services/Authentication';
import ErrorPage from 'UI/pages/ErrorPage';

import { ensureUserHasAccess } from './PrivateRoute.utils';

export const FinalComponent = ({ Component, isUserAuthenticated, hasUserAccess, ...rest }) => {
  if (!isUserAuthenticated) {
    return <Redirect to={EntityRoutes.Login} />;
  }

  if (hasUserAccess) {
    return (
      <MainLayout>
        <Component {...rest} />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <ErrorPage error={HTTPStatusCodes.Unauthorized} />
    </MainLayout>
  );
};

const PrivateRoute = ({
  component: Component,
  enabled = true,
  permissions = null,
  roles = null,
  ...rest
}) => {
  const hasUserAccess = ensureUserHasAccess({ permissions, enabled, roles });
  const isUserAuthenticated = ensureUserIsAuthenticated();

  useEffect(() => {
    localStorage.setItem('redirectPage', window.location.pathname + window.location.search);
  }, []);

  return (
    <Route
      render={props => (
        <FinalComponent
          Component={Component}
          isUserAuthenticated={isUserAuthenticated}
          hasUserAccess={hasUserAccess}
          {...props}
        />
      )}
      {...rest}
    />
  );
};

export default PrivateRoute;
