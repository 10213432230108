// @flow
import React from 'react';
import { ChipProps } from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { THEME } from 'GlobalStyles';
import strings from 'strings';
import type { InventoryTypeProps } from 'types/app';
import FPChip from 'UI/components/atoms/FPChip';
import FPIcon from 'UI/components/atoms/FPIcon';
import { When } from 'UI/components/atoms/When';
import EmailStatusChip from 'UI/components/molecules/EmailStatusChip';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { SvgDelete } from 'UI/res/icons/filled';
import { hasFeatureFlag } from 'UI/utils';

import { useStyles } from './styles';

type ItemStatusProps = {
  title: string,
  color: string
};

type ProfileStatusChipProps = {
  inventoryType: InventoryTypeProps,
  itemStatus: ItemStatusProps,
  optOutStatus: Object,
  inventoryTypeChipProps: ChipProps,
  emailValidationStatus?: string,
  otherEmailValidationStatus?: string
};

const ProfileStatusChip = ({
  inventoryType,
  itemStatus,
  optOutStatus,
  inventoryTypeChipProps,
  emailValidationStatus,
  otherEmailValidationStatus
}: ProfileStatusChipProps) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {itemStatus && (
        <Grid item xs="auto">
          <FPChip
            size="small"
            aria-label="status chip"
            label={itemStatus.title}
            textColor={success => success.main}
            variant="outlined"
            color={itemStatus.color}
          />
        </Grid>
      )}
      {inventoryType && (
        <Grid item xs="auto">
          <FPChip
            size="small"
            aria-label="inventory type chip"
            label={inventoryType.title}
            color={inventoryType.color || inventoryType.style_class_name}
            {...inventoryTypeChipProps}
          />
        </Grid>
      )}
      {optOutStatus?.optOut && (
        <Grid item xs="auto">
          <FPChip
            size="small"
            label={strings.inventoryProfiles.common.optOut.isOptOut}
            aria-label={strings.inventoryProfiles.common.optOut.isOptOut}
            variant="outlined"
            textColor={THEME.palette.grey[600]}
            classes={{ icon: classes.icon, root: classes.optOut }}
            icon={<FPIcon component={SvgDelete} />}
          />
        </Grid>
      )}

      <When condition={emailValidationStatus}>
        <Grid item xs="auto">
          <EmailStatusChip status={emailValidationStatus} origin="profile" emailType="primary" />
        </Grid>
      </When>
      <When
        condition={
          otherEmailValidationStatus && hasFeatureFlag(FeatureFlags.OtherEmailValidationProfiles)
        }
      >
        <Grid item xs="auto">
          <EmailStatusChip
            status={otherEmailValidationStatus}
            origin="profile"
            emailType="secondary"
          />
        </Grid>
      </When>
    </Grid>
  );
};

ProfileStatusChip.defaultProps = {
  optOutStatus: false,
  emailValidationStatus: null,
  otherEmailValidationStatus: null
};

export default ProfileStatusChip;
