// @flow
import { makeRequest } from 'services/utils';
import strings from 'strings';
import type { OperationResult } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';

const {
  BulkTemplateArchive,
  BulkEmailsFolders,
  BulkEmailsFolderById,
  BulkEmailsTemplates,
  BulkTemplateById,
  BulkTemplateRestore,
  BulkTemplateFolderArchive,
  BulkTemplateFolderRestore
} = Endpoints;

const {
  archiveBulkTemplate: archiveTemplateCopies,
  restoreBulkTemplate: restoreTemplateCopies,
  archiveBulkTemplateFolder: archiveFolderCopies,
  restoreBulkTemplateFolder: restoreFolderCopies
} = strings.bulkEmails.errors;

export const getTemplateById = (templateId): Promise<OperationResult> =>
  makeRequest({
    endpointOptions: {
      path: BulkTemplateById,
      replaceId: templateId
    },
    method: 'get',
    alertConfig: {
      error: {
        title: "Couldn't Load Template Content"
      }
    }
  });

export const createFolder = ({ name, parentFolderId }): Promise<OperationResult> =>
  makeRequest({
    url: BulkEmailsFolders,
    method: 'post',
    data: { name, parent_folder_id: parentFolderId },
    alertConfig: {
      success: {
        title: 'Folder Created Successfully'
      }
    }
  });

export const editFolder = (folderId, { name, parentFolderId }): Promise<OperationResult> =>
  makeRequest({
    endpointOptions: {
      path: BulkEmailsFolderById,
      replaceId: folderId
    },
    data: { name, parent_folder_id: parentFolderId },
    method: 'put',
    apiVersion: 2,
    alertConfig: {
      success: {
        title: 'Folder Updated Successfully'
      }
    }
  });

export const getFolderData = (folderId: number): Promise<OperationResult> =>
  makeRequest({
    endpointOptions: {
      path: BulkEmailsFolderById,
      replaceId: folderId,
      queryParams: {
        includes: 'parentFolder'
      }
    },
    method: 'get'
  });

export const createTemplate = (data): Promise<OperationResult> =>
  makeRequest({
    url: BulkEmailsTemplates,
    data,
    method: 'post',
    alertConfig: {
      success: {
        title: 'Template Created Successfully'
      }
    }
  });

export const editTemplate = (id, formData): Promise<OperationResult> =>
  makeRequest({
    endpointOptions: {
      path: BulkTemplateById,
      replaceId: id
    },
    method: 'put',
    data: formData,
    alertConfig: {
      success: {
        title: 'Template Updated Successfully'
      }
    }
  });

export const archiveTemplate = (templateId): Promise<OperationResult> =>
  makeRequest({
    url: BulkTemplateArchive.replace(/:id/, templateId),
    method: 'delete',
    alertConfig: {
      success: archiveTemplateCopies
    }
  });

export const restoreTemplate = (templateId, destinationFolderId): Promise<OperationResult> =>
  makeRequest({
    endpointOptions: {
      path: BulkTemplateRestore,
      replaceId: templateId
    },
    method: 'post',
    data: { destinationFolderId },
    alertConfig: {
      success: {
        title: restoreTemplateCopies.title,
        body: ({ template, destinationFolder }) =>
          strings.formatString(restoreTemplateCopies.body, {
            templateName: template.name,
            destinationFolderName: destinationFolder.name
          })
      }
    }
  });

export const archiveTemplateFolder = (folderId): Promise<OperationResult> =>
  makeRequest({
    endpointOptions: {
      path: BulkTemplateFolderArchive,
      replaceId: folderId
    },
    method: 'delete',
    alertConfig: {
      success: archiveFolderCopies
    }
  });

export const restoreTemplateFolder = (folderId, destinationFolderId): Promise<OperationResult> =>
  makeRequest({
    endpointOptions: {
      path: BulkTemplateFolderRestore,
      replaceId: folderId
    },
    method: 'post',
    data: { destinationFolderId },
    alertConfig: {
      success: {
        title: restoreFolderCopies.title,
        body: ({ folder, destinationFolder }) =>
          strings.formatString(restoreFolderCopies.body, {
            folderName: folder.name,
            destinationFolderName: destinationFolder.name
          })
      }
    }
  });
