// @flow
import React from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  confirm as confirmAction,
  showAlert as showAlertAction,
  showFile as showFileAction
} from 'actions/app';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import strings from 'strings';
import type { DisplayedFile } from 'types/app';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { Endpoints } from 'UI/constants/endpoints';
import { FeeAgreementProcess } from 'UI/constants/entityTypes';

import ManagedFeeAgreementDetails from './managed';
import { useStyles } from './style';
import UnmanagedFeeAgreementDetails from './unmanaged';

type FeeAgreementSummaryDrawerProps = {
  feeAgreementId: number,
  additionalData: any,
  onClose: () => void,
  onUpdate: () => any,
  onTemplateUploaded: any => any,
  onPreviewCreated: any => any,
  showConfirm: any => void,
  showAlert: any => void,
  showFile: DisplayedFile => void
};

const FeeAgreementSummaryDrawer = (props: FeeAgreementSummaryDrawerProps) => {
  const { feeAgreementId, onClose, ...rest } = props;
  const classes = useStyles(props);
  const { Status } = useFetchWithStatus(`${Endpoints.FeeAgreement}/${feeAgreementId}`);

  const uiState = {
    isSaving: false,
    isSuccess: false,
    isFormDisabled: false,
    isReadOnly: false,
    isDeclining: false,
    isRevalidating: false
  };

  return (
    <Status
      loading={
        <DrawerContentLayout
          onClose={onClose}
          variant="borderless"
          uiState={uiState}
          title={strings.feeAgreements.details.title}
          initialText=""
          isSaveButtonMode={false}
          isBottomToolbarNeeded={false}
          isTopToolbarNeeded
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            className={classes.spinnerContainer}
          >
            <CircularProgress color="inherit" size={40} />
          </Box>
        </DrawerContentLayout>
      }
      success={feeAgreement =>
        feeAgreement.signature_process_type_id !== FeeAgreementProcess.External ? (
          <ManagedFeeAgreementDetails feeAgreement={feeAgreement} onClose={onClose} {...rest} />
        ) : (
          <UnmanagedFeeAgreementDetails feeAgreement={feeAgreement} onClose={onClose} {...rest} />
        )
      }
    />
  );
};

FeeAgreementSummaryDrawer.defaultProps = {
  additionalData: null
};

const mapDispatchToProps = dispatch => {
  return {
    showAlert: alert => dispatch(showAlertAction(alert)),
    showConfirm: confirmation => dispatch(confirmAction(confirmation)),
    showFile: pdfUrl => dispatch(showFileAction(pdfUrl))
  };
};

export default connect(null, mapDispatchToProps)(FeeAgreementSummaryDrawer);
