import { EntityRoutes } from 'routes/constants';

export const ColumnsDefinitions = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-company-type'
        }
      }
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'specialty_title',
    label: 'Specialty: Subspecialty',
    options: {
      sort: true,
      display: true
    }
  },

  {
    name: 'industry',
    label: 'Industry',
    options: {
      sort: true,
      display: true
    }
  },

  {
    name: 'location',
    label: 'Location',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'zip',
    label: 'Zip',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'city',
    label: 'City',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'created_at',
    label: 'Added Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'last_activity_date',
    label: 'Last Activity',
    options: {
      sort: true,
      display: false,
      renderer: 'date'
    }
  },
  {
    name: 'coach_name',
    label: 'Team',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'recruiter_name',
    label: 'Recruiter',
    options: {
      sort: true,
      display: true
    }
  }
];

export const VirtualProps = [
  {
    propName: 'name',
    attributes: ['id', 'name']
  }
];
