import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  theme => ({
    container: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(3)
    },
    rowLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      flexWrap: 'wrap',
      marginBottom: theme.spacing(1.5)
    },
    cardContent: {
      backgroundColor: theme.palette.brandColors.FPWhite,
      padding: theme.spacing(1.5),
      borderRadius: theme.shape.borderRadius,
      marginBottom: theme.spacing(2)
    },
    textLine: {
      marginBottom: theme.spacing(1)
    },

    textBold: {
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      color: theme.palette.grey[700],
      fontWeight: '700 !important'
    },
    textLight: {
      color: theme.palette.grey[600]
    },
    mlContainer: {
      marginLeft: theme.spacing(2)
    }
  }),
  { classNamePrefix: 'change-logs' }
);
