import * as React from 'react';

const SvgBurgerMenu = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <g>
        <rect x={0.5} y={2.5} width={23} height={3} rx={1} />
        <rect x={0.5} y={10.5} width={23} height={3} rx={1} />
        <rect x={0.5} y={18.5} width={23} height={3} rx={1} />
      </g>
    </svg>
  );
};

export default SvgBurgerMenu;
