import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'UI/res';

const color = `${colors.white} !important`;

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    zIndex: 500,
    borderRadius: 0
  },
  standardSuccess: {
    backgroundColor: theme.palette.success.main,
    color
  },
  standardError: {
    backgroundColor: theme.palette.error.main,
    color
  },
  message: {
    fontWeight: 400,
    fontSize: 16
  },
  icon: {
    color
  },
  standardInfo: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.brandColors.FPWhite
  }
}));

export const useButtonStyle = makeStyles(theme => ({
  outlined: {
    ...theme.button,
    width: 'unset',
    fontSize: '0.8125rem',
    color: colors.white,
    border: `1px solid ${color}`,
    margin: theme.spacing(0, 1)
  }
}));
