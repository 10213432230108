// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import strings from 'strings';
import Dropzone from 'UI/components/atoms/Dropzone';
import { defaultMaxFileSize, defaultValidExtNames } from 'UI/constants/defaults';
import { formatBytes, nestTernary } from 'UI/utils';

import { FileDropzoneProps, ShowAlertValidationParams } from './fileDropzone.types';
import {
  areFileNamesValid,
  hasExceededSize,
  hasInvalidFileExtension,
  hasReachedMaxFiles,
  renameDuplicatedFiles
} from './fileDropzone.utils';
import { useStyles } from './styles';

const DisabledDrop = ({ maxFiles }) => {
  const classes = useStyles();
  return (
    <div className={classes.disableStyle}>
      <span>
        {strings.formatString(strings.fileManager.dropzone.disabled, {
          maxFiles
        })}
      </span>
    </div>
  );
};

const FileDropzone = ({
  allowedExt,
  attachments,
  categoryId,
  fileNameField,
  filesCount,
  maxFiles,
  maxFileSize,
  multiple,
  onUploadFiles
}: FileDropzoneProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const showAlertValidation = ({ severity = 'warning', title, body }: ShowAlertValidationParams) =>
    dispatch(
      showAlert({
        severity,
        title,
        body
      })
    );

  const onDropFinished = files => {
    if (!multiple && hasReachedMaxFiles({ files, maxFiles, filesCount })) {
      showAlertValidation({
        title: strings.fileManager.validQuantity.title,
        body: strings.formatString(strings.fileManager.validQuantity.body, {
          maxFiles
        })
      });
      return;
    }

    if (!areFileNamesValid(files)) {
      showAlertValidation({
        title: strings.fileManager.fileName.title,
        body: strings.fileManager.fileName.message,
        severity: 'error'
      });
      return;
    }

    if (hasInvalidFileExtension({ files, allowedExt })) {
      showAlertValidation({
        title: strings.fileManager.extFile.title,
        body: strings.fileManager.extFile.message,
        severity: 'error'
      });
      return;
    }

    if (hasExceededSize({ files, maxFileSize })) {
      const selectedLargeFile = files.find(file => file.size > maxFileSize);
      showAlertValidation({
        title: strings.fileManager.sizeFile.title,
        body: strings.formatString(strings.fileManager.sizeFile.message, {
          fileName: selectedLargeFile.name,
          maxFileSize: formatBytes(maxFileSize),
          severity: 'error'
        })
      });
      return;
    }

    const renamedFiles = renameDuplicatedFiles({
      attachments,
      selectedFiles: files,
      fileNameField
    });

    onUploadFiles(renamedFiles, categoryId);
  };

  const sharedProps = {
    onDropFinished,
    maxFileSize
  };

  return (
    <div className={classes.dropzoneContainer}>
      {multiple ? (
        <Dropzone {...sharedProps} />
      ) : (
        nestTernary(
          filesCount < maxFiles,
          <Dropzone {...sharedProps} />,
          <DisabledDrop maxFiles={maxFiles} />
        )
      )}
    </div>
  );
};

FileDropzone.defaultProps = {
  allowedExt: defaultValidExtNames,
  attachments: [],
  maxFileSize: process.env.REACT_APP_MAX_FILE_SIZE || defaultMaxFileSize
};

export default FileDropzone;
