import React from 'react';
import { styled } from '@material-ui/core';

export const TeamMembersHealthWrapper = styled('div')(({ theme }) => ({
  '& .MuiTable-root tbody tr td:nth-child(n+2)': {
    padding: 0
  },
  '& .MuiTable-root tbody tr td:first-child': {
    padding: theme.spacing(0, 1)
  },
  '& .MuiTable-root tbody tr': {
    height: 'auto',
    '& td.MuiTableCell-root': {
      borderBottom: 'none'
    }
  }
}));

export const MetricCell = styled(({ hexColor: _omit, ...props }) => <div {...props} />)(
  ({ hexColor, theme }) => ({
    backgroundColor: hexColor,
    padding: theme.spacing(1)
  })
);
