// @flow
import React from 'react';
import FPHtmlViewer from 'UI/components/atoms/FPHtmlViewer';

import { useStyles } from './styles';
import { highlightText } from './utils';

interface HighlightBoxProps {
  /** the strings values to be replaced inside text */
  highlightData: string[];
  /** text to be replaced */
  text: string;
}

const HighlightBox = ({ text, highlightData }: HighlightBoxProps) => {
  const classes = useStyles();
  const content = highlightText(text, highlightData);
  return <FPHtmlViewer className={classes.wrapperMarker} content={content} />;
};

HighlightBox.defaultProps = {
  text: null
};

export default HighlightBox;
