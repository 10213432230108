// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import RelationshipOverview from 'UI/components/organisms/AssociatedCompanies/RelationshipOverview';
import { COMPANY_RELATIONSHIP_KEY } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { OptionRenderers, Selectors } from 'UI/utils/renderers';

import { FormFieldsMap } from '../fields';

import { useStyles } from './styles';

type ParentFormProps = {
  companyId?: number
};

const ParentForm = ({ companyId }: ParentFormProps) => {
  const classes = useStyles();
  const { errors, setValue, watch, register, unregister } = useFormContext();

  const formValues = watch();

  const endpoint = companyId
    ? Endpoints.PossibleParentExistingCompany.replace(':id', companyId)
    : Endpoints.PossibleParentCompany;

  const isParentCompanySelected = !!formValues.parentCompany?.id;

  useEffect(() => {
    register({ name: FormFieldsMap.ParentCompany.key });
    register({ name: FormFieldsMap.CurrentCompany.key });

    return () => {
      unregister(FormFieldsMap.ParentCompany.key);
      unregister(FormFieldsMap.CurrentCompany.key);
    };
  }, [register, unregister]);

  const handleCompanySelect = (name: string, value: any) => {
    setValue(name, value, true);
  };

  return (
    <Grid container direction="column" spacing={3}>
      {formValues?.currentCompany?.name && (
        <Grid item>
          <Typography variant="subtitle1">
            {strings.companies.creation.associated.form.parent.description}&nbsp;
            <span className={classes.boldText}>{formValues.currentCompany.name}</span>
          </Typography>
        </Grid>
      )}
      <Grid item>
        <AutocompleteSelect
          name={FormFieldsMap.ParentCompany.key}
          error={!!errors.parentCompany}
          errorText={errors.parentCompany?.message}
          selectedValue={formValues.parentCompany}
          placeholder={strings.companies.creation.associated.form.parent.placeholder}
          url={endpoint}
          apiVersion={2}
          displayKey="name"
          typeahead
          typeaheadLimit={50}
          typeaheadParams={{ entityType: EntityType.Company }}
          onSelect={handleCompanySelect}
          getOptionSelected={Selectors.byId}
          renderOption={OptionRenderers.parentCompanySearch('name')}
          noMargin
        />
      </Grid>
      {isParentCompanySelected && (
        <Grid item>
          <RelationshipOverview
            direction="column"
            data={[formValues.parentCompany]}
            type={COMPANY_RELATIONSHIP_KEY.parentCompany}
            title={strings.companies.creation.associated.relationship.parent}
          />
        </Grid>
      )}
    </Grid>
  );
};

ParentForm.defaultProps = {
  companyId: null
};

export default ParentForm;
