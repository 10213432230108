import React, { useState } from 'react';
import { Chart } from 'react-google-charts';
import { Widget } from 'features/command-center/components/shared';
import { getLowHealthMembersByTeam } from 'features/command-center/services';
import { getWeekRange } from 'features/command-center/utils';
import strings from 'strings';
import { AutocompleteByWeek } from 'UI/components/atoms/AutocompleteByWeek';

import { ChartLegend } from '../ChartLegend';

import {
  DEFAULT_OPTIONS,
  formatResponse,
  MAIN_COLOR,
  SECONDARY_COLOR
} from './LowHealthMembersByTeam.utils';

const STRINGS = strings.commandCenter.finance.lowHealthMembersByTeam;

export const LowHealthMembersByTeam = ({ weeks }) => {
  const [week, setWeek] = useState(weeks?.[0] ?? null);

  const handleSelectWeek = (event, selectedWeek) => setWeek(selectedWeek);

  return (
    <Widget
      header={STRINGS.title}
      promises={[() => getLowHealthMembersByTeam({ ...getWeekRange(week) })]}
    >
      {([response]) => {
        const data = formatResponse(response);

        return (
          <>
            <AutocompleteByWeek
              onSelectWeek={handleSelectWeek}
              value={week}
              defaultOptions={weeks}
            />
            <ChartLegend
              mainTitle="At Risk"
              mainColor={MAIN_COLOR}
              secondaryTitle="Behind"
              secondaryColor={SECONDARY_COLOR}
            />
            <Chart
              chartType="ColumnChart"
              width="100%"
              height="400px"
              data={data}
              options={DEFAULT_OPTIONS}
            />
          </>
        );
      }}
    </Widget>
  );
};
