// @flow
import React from 'react';
import { THEME } from 'GlobalStyles';
import FPChip from 'UI/components/atoms/FPChip';

import { useStyles } from './styles';

const SkillsChips = ({ skills }) => {
  const classes = useStyles();

  return skills.map(item =>
    typeof item === 'string' && item.trim().length > 0 ? (
      <FPChip label={item} classes={{ root: classes.chip }} color={THEME.palette.grey[500]} />
    ) : null
  );
};

export default SkillsChips;
