import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';

const sharedFontSize = {
  fontSize: 16
};

export const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.FP200,
    borderRadius: theme.spacing(11.75),
    color: theme.palette.grey[600],
    display: 'flex',
    justifyContent: 'space-between',
    letterSpacing: '0.25px',
    marginTop: theme.spacing(0.75),
    padding: theme.spacing(1, 2.25, 1, 1.5),
    width: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  mainText: {
    lineHeight: '20px',
    fontWeight: '400 !important',
    ...sharedFontSize
  },
  secondaryText: {
    color: theme.palette.grey[900],
    fontWeight: '500 !important',
    ...sharedFontSize
  }
}));

export const styles = {
  marginContent: {
    marginRight: THEME.spacing(2.25)
  }
};
