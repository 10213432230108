// @flow
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { EntityRoutes } from 'routes/constants';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import TitleLabel from 'UI/components/atoms/TitleLabel';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import { PageTitles } from 'UI/constants/defaults';
import { Error401, Error404, Error500 } from 'UI/res';

import { styles, useStyles } from './styles';

type ErrorPageProps = {
  error?: 500 | 401 | 404,
  history: any,
  shouldGoBack: boolean
};

const ErrorPage = ({ error, history, shouldGoBack }: ErrorPageProps) => {
  const classes = useStyles();

  useEffect(() => {
    document.title = PageTitles.NotFound;
  }, []);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleGoHome = () => {
    history.push(EntityRoutes.Home);
  };

  const handleRefreshPage = () => {
    window.location.reload();
  };

  const errorType = {
    image: (
      <TitleLabel className={classes.shadow} text="Unkown ERROR" fontSize={120} color="error" />
    ),
    title: "We don't know what's going on.",
    firstRow: 'Please come back later!',
    secondRow: '',
    buttonText: 'Go Back',
    action: handleGoBack
  };

  switch (error) {
    case 401:
      errorType.image = <Error401 />;
      errorType.title = 'Unauthorized access';
      errorType.firstRow = `Sorry! You can't go beyond this point.`;
      errorType.secondRow = 'Please turn back.';
      errorType.buttonText = 'GO TO HOME';
      errorType.action = handleGoHome;
      break;
    case 404:
      errorType.image = <Error404 style={{ marginBottom: 75 }} />;
      errorType.title = `There's nothing here`;
      errorType.firstRow = `We couldn't find the page you're looking for.`;
      errorType.secondRow = `Let's head back `;
      errorType.buttonText = shouldGoBack ? 'Go Back' : 'GO TO HOME';
      errorType.action = shouldGoBack ? handleGoBack : handleGoHome;
      break;
    case 500:
      errorType.image = <Error500 />;
      errorType.title = 'Internal Server Error';
      errorType.firstRow = `Oops! We didn't see that coming.`;
      errorType.secondRow = `We're are working on fixing the problem as soon as possible!`;
      errorType.buttonText = 'NOTIFY SUPPORT';
      errorType.action = handleRefreshPage;
      break;
    default:
      // any other problem will be the default
      break;
  }

  return (
    <ContentPageLayout mode="customLayout">
      <Grid className={classes.wrapper}>
        {errorType.image}
        <TitleLabel className={classes.shadow} fontSize={48} text={errorType.title} />
        <Typography style={{ marginTop: 0, marginBottom: 0 }} component="div">
          <Box className={classes.text}>{errorType.firstRow}</Box>
          <Box className={classes.text}>
            {errorType.secondRow}
            {error === 404 && (
              <Button
                className={classes.text}
                style={{ textTransform: 'lowercase', marginBottom: 3 }}
                onClick={errorType.action}
                color="primary"
              >
                {errorType.buttonText}
              </Button>
            )}
          </Box>
        </Typography>
        {error !== 404 && (
          <FPActionButton text={errorType.buttonText} style={styles} onClick={errorType.action} />
        )}
      </Grid>
    </ContentPageLayout>
  );
};

ErrorPage.defaultProps = {
  error: 404,
  shouldGoBack: false
};

export default withRouter(ErrorPage);
