// @flow
import { EntityRoutes } from 'routes/constants';

export const associatedCompaniesColumns = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'name',
    label: 'Company Name',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'company_type',
    label: 'Type',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-company-type'
        }
      }
    }
  },
  {
    name: 'association_type',
    label: 'Relationship',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'association_label',
          baseClass: 'chip-company-link-type',
          idKey: 'association_type_class'
        }
      }
    }
  },
  {
    name: 'added_date',
    label: 'Relation Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'industry',
    label: 'Industry',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'location',
    label: 'Location',
    options: {
      sort: true,
      display: true
    }
  },

  {
    name: 'phone',
    label: 'Phone',
    options: {
      sort: false,
      display: false,
      renderer: 'phone'
    }
  }
];

export const VirtualProps = [
  {
    propName: 'name',
    attributes: ['name', 'id']
  },
  {
    propName: 'association_type',
    attributes: [
      'association_type_sorting_column',
      'association_type',
      'association_label',
      'association_type_class'
    ]
  }
];
