// @flow
import React, { useContext, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { useGlobalStyles } from 'GlobalStyles';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import useOwnershipAndPermissions from 'hooks/useOwnershipAndPermissions';
import { UserProviderContext } from 'providers/UserProvider';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { When } from 'UI/components/atoms/When';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import FullWidthCardHeader from 'UI/components/templates/FullWidthCardHeader';
import RecruiterHeaderContent from 'UI/components/templates/RecruiterProfile/RecruiterHeaderContent';
import { Endpoints } from 'UI/constants/endpoints';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { UIStatus } from 'UI/constants/status';
import { hasFeatureFlag } from 'UI/utils';

import RecruiterElementsBuilder from './RecruiterElementsBuilder';
import RecruiterNotesForm from './RecruiterNotesForm';
import { useStyles } from './styles';
import {
  filterDataByFeatureFlag,
  filterItemsByAreaName,
  sortElementsByPosition,
  sortRenderersAndAreas
} from './utils';

const RecruiterItemsCreation = ({ results, endpoint, isPrivateView }) => {
  const classes = useStyles();

  const { data } = filterDataByFeatureFlag({ results });

  const { sortedAreas, shouldSortRecruiterItems, filteredItemsByPrivateView } =
    sortRenderersAndAreas({
      results: data,
      isPrivateView
    });

  return (
    <div className={classes.recruiterLayoutContainer}>
      <Grid container spacing={1} justify="space-evenly">
        {results?.layout.length &&
          sortedAreas.map(grid => {
            const filteredItemsByArea = filterItemsByAreaName(filteredItemsByPrivateView, grid);

            const sortedElements = sortElementsByPosition(
              filteredItemsByArea,
              shouldSortRecruiterItems
            );

            return (
              <Grid key={grid.sectionPosition.areaName} {...grid.gridConfig}>
                {results?.sections?.length &&
                  sortedElements.map(item => (
                    <RecruiterElementsBuilder key={item.title} item={item} endpoint={endpoint} />
                  ))}
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

const SKELETON_HEIGHT = 150;
const SKELETON_ITEMS = 3;
const GRID_SPACING = 2;
const AVATAR_SIZE = 45;
const AVATAR_TEXT = 85;

export const HeaderSkeleton = () => {
  const classes = useStyles();
  return (
    <div className={classes.skeletonItems}>
      <Grid container spacing={GRID_SPACING}>
        {Array.from(Array(SKELETON_ITEMS).keys()).map(item => (
          <Grid item key={item}>
            <Skeleton variant="rect" width={SKELETON_HEIGHT} />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={GRID_SPACING} alignItems="center">
        <Grid item>
          <Skeleton variant="circle" width={AVATAR_SIZE} height={AVATAR_SIZE} />
        </Grid>
        <Grid item>
          <Skeleton variant="rect" width={AVATAR_TEXT} />
        </Grid>
      </Grid>
    </div>
  );
};

const API_VERSION = 1;

const RecruiterProfile = ({ match }) => {
  const recruiterId = match.params.id;

  const [isNotesDrawerOpen, setIsNotesDrawerOpen] = useState(false);
  const [user] = useContext(UserProviderContext);
  const classes = useGlobalStyles();

  const endpoint = `${Endpoints.Roster}/${recruiterId}`;
  const { state } = useFetchWithStatus(endpoint, [], null, API_VERSION);

  const { status, results } = state;

  const isLoading = status === UIStatus.Loading;
  const userId = !isLoading && user?.id;

  const { hasOwnership, isUserOwnership, isRelatedUserByPil } = useOwnershipAndPermissions({
    isLoading,
    results,
    userId
  });

  const toggleDrawerOpen = () => setIsNotesDrawerOpen(!isNotesDrawerOpen);

  const isRecruiterNotesEnabled = hasFeatureFlag(FeatureFlags.RecruiterProfileNotes);

  return (
    <FullWidthCardHeader
      actionsBar={
        <When condition={hasOwnership && isRecruiterNotesEnabled}>
          <FPActionButton
            text={strings.recruiterProfile.feedbackAction}
            variant="outlined"
            onClick={toggleDrawerOpen}
          />
        </When>
      }
      headerCardProps={{
        sectionName: 'Search Consultant',
        title: results?.full_name
      }}
      isLoading={isLoading}
      headerContent={
        <>
          <When condition={isLoading}>
            <HeaderSkeleton />
          </When>
          <When condition={!isLoading}>
            <RecruiterHeaderContent data={results} />
          </When>
        </>
      }
      pageContent={
        <>
          <When condition={isLoading}>
            <EmptyPlaceholder
              title={strings.shared.emptyState.cardWrapper.title}
              subtitle={strings.shared.emptyState.cardWrapper.subtitle}
              customEmptyState={<CircularProgress className={classes.circularProgress} />}
            />
          </When>
          <When condition={!isLoading}>
            <RecruiterItemsCreation
              results={results}
              isPrivateView={hasOwnership}
              isLoading={isLoading}
              endpoint={endpoint}
            />
            <RecruiterNotesForm
              endpoint={`lll${endpoint}/notes`}
              drawerProps={{
                open: isNotesDrawerOpen,
                onClose: toggleDrawerOpen
              }}
              onNoteCompleted={toggleDrawerOpen}
              ownershipStatus={{ isUserOwnership, isRelatedUserByPil }}
            />
          </When>
        </>
      }
    />
  );
};

export default RecruiterProfile;
