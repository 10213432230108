import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { TREE_VIEW_ENTITY_TYPES } from 'UI/constants/defaults';

import { buildTreeViewItemId } from '../utils';

export const useTreeViewLoader = ({
  foldersIdToRefresh,
  foldersIdToShrink,
  onFoldersRefresh,
  onFoldersShrink,
  onLoadTreeView,
  service
}) => {
  const dispatch = useDispatch();
  const [tree, setTree] = useState(null);
  const [expandedIds, setExpandedIds] = useState([]);
  const [uiState, setUiState] = useState({ isLoading: false, hasLoadedCorrectly: false });

  const updateTree = (nodeId, data) => {
    setTree(prevState => ({
      ...prevState,
      [nodeId]: data.map(({ id, entity, ...rest }) => ({
        ...rest,
        id: buildTreeViewItemId(entity, id),
        entity
      }))
    }));
  };

  const handleLoadTreeView = useCallback(
    newUiState => {
      onLoadTreeView && onLoadTreeView(newUiState);
      setUiState(newUiState);
    },
    [onLoadTreeView, setUiState]
  );

  const renderTreeRoot = async () => {
    if (tree || !service.method || uiState.isLoading || uiState.hasLoadedCorrectly) return;

    handleLoadTreeView({ isLoading: true, hasLoadedCorrectly: false });
    const { success, alert, data } = await service.method(service.queryParams);

    if (success) {
      const mappedData = data.map(({ id, ...rest }) => ({
        ...rest,
        id: buildTreeViewItemId(TREE_VIEW_ENTITY_TYPES.folder, id)
      }));
      setTree({ root: mappedData });
      handleLoadTreeView({ isLoading: false, hasLoadedCorrectly: true });
    } else {
      handleLoadTreeView({ isLoading: false, hasLoadedCorrectly: false });
      dispatch(showAlert(alert));
    }
  };

  const refreshBranchById = async parentId => {
    const nodeId = buildTreeViewItemId(TREE_VIEW_ENTITY_TYPES.folder, parentId);
    if (!expandedIds.includes(nodeId)) return;

    const { alert, data, success } = await service.method({ parentId });
    if (success) {
      updateTree(nodeId, data);
    } else dispatch(showAlert(alert));
  };

  const refreshFoldersById = () => {
    if (!foldersIdToRefresh || foldersIdToRefresh.length === 0) return;

    foldersIdToRefresh.forEach(folderId => {
      refreshBranchById(folderId);
    });

    onFoldersRefresh && onFoldersRefresh();
  };

  const shrinkFolders = () => {
    if (!foldersIdToShrink || foldersIdToShrink.length === 0) return;

    foldersIdToShrink.forEach(id => {
      const rootNodeIdToShrink = buildTreeViewItemId(TREE_VIEW_ENTITY_TYPES.folder, id);
      const nodeIdsToShrink = [];
      const treeKeys = Object.keys(tree);
      const recursiveFlusher = nodeIdAux => {
        if (treeKeys.includes(nodeIdAux)) {
          nodeIdsToShrink.push(nodeIdAux);
          tree[nodeIdAux].forEach(treeNode => {
            recursiveFlusher(treeNode.id);
          });
        }
      };

      recursiveFlusher(rootNodeIdToShrink);

      setExpandedIds(prev => prev.filter(expandedId => !nodeIdsToShrink.includes(expandedId)));

      setTree(prev => {
        const flushedNodes = {};
        treeKeys.forEach(nodeId => {
          if (nodeIdsToShrink.includes(nodeId)) {
            flushedNodes[nodeId] = [];
          }
        });
        return { ...prev, ...flushedNodes };
      });
    });

    onFoldersShrink && onFoldersShrink();
  };

  const nodeToggler = async (event, openIds) => setExpandedIds(openIds);

  return {
    expandedIds,
    nodeToggler,
    refreshFoldersById,
    renderTreeRoot,
    shrinkFolders,
    tree,
    uiState,
    updateTree
  };
};
