import React from 'react';
import { styled } from '@material-ui/core';

import SvgNoActivities from './SvgNoActivities';
import TimelineItem from './TimelineItem';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0
});

const Timeline = ({ items }) => {
  if (!items.length) {
    return <SvgNoActivities />;
  }

  return (
    <List>
      {items.map(item => (
        <TimelineItem key={item.id} item={item} />
      ))}
    </List>
  );
};

export default Timeline;
