// @flow
import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import strings from 'strings';
import FPHtmlViewer from 'UI/components/atoms/FPHtmlViewer';
import FPIcon from 'UI/components/atoms/FPIcon';
import { SvgInformationCircle } from 'UI/res';
import { dateFormatter } from 'UI/utils';

import { useStyles } from './styles';

const ITEM_TYPES = {
  dashboard: 'dashboard',
  list: 'list',
  table: 'table',
  custom: 'custom'
};

const DAILY_STATS_FOOTER_STRINGS = strings.home.sections.dailyStatsOverview.footer;

type DailyStatsFooterProps = {
  bulksLastUpdate: string,
  callLastUpdate: string,
  externalToolsLastUpdate: string,
  footerActionLink: string,
  shouldShowExternalToolsLabel: boolean,
  shouldShowTitleLabel: boolean,
  textLastUpdate: string,
  type: string
};

const getFormattedDate = dateLastUpdate => {
  return dateLastUpdate
    ? dateFormatter(dateLastUpdate, ({ MonthDayNumberYear }) => MonthDayNumberYear)
    : '-';
};

const getFormattedTime = dateLastUpdate => {
  return `${
    dateLastUpdate ? dateFormatter(dateLastUpdate, ({ SimpleTime }) => SimpleTime) : '-- : --'
  }`;
};

const DailyStatsFooter = ({
  bulksLastUpdate,
  callLastUpdate,
  externalToolsLastUpdate,
  footerActionLink,
  shouldShowExternalToolsLabel,
  shouldShowTitleLabel,
  textLastUpdate,
  type
}: DailyStatsFooterProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(type === ITEM_TYPES.table && classes.customFooterActionContainer)}>
      <Divider role="separator" className={classes.divider} />
      <div className={classes.customFooterTextContiner}>
        <Grid container spacing={2}>
          <Grid item>
            <FPIcon
              role="img"
              aria-label="Info"
              component={SvgInformationCircle}
              onSetColor={palette => palette.info.main}
              size={24}
            />
          </Grid>
          <Grid item>
            {shouldShowTitleLabel && (
              <FPHtmlViewer
                className={classes.customFooterActionText}
                content={DAILY_STATS_FOOTER_STRINGS.title}
              />
            )}
            <div className={classes.customFooterListItem}>
              {bulksLastUpdate && (
                <Typography className={classes.customFooterActionText}>
                  {strings.formatString(DAILY_STATS_FOOTER_STRINGS.bulks, {
                    date: getFormattedDate(bulksLastUpdate)
                  })}
                  <strong>{getFormattedTime(bulksLastUpdate)}</strong>
                </Typography>
              )}
              {callLastUpdate && (
                <Typography className={classes.customFooterActionText}>
                  {strings.formatString(DAILY_STATS_FOOTER_STRINGS.call, {
                    date: getFormattedDate(callLastUpdate)
                  })}
                  <strong>{getFormattedTime(callLastUpdate)}</strong>
                </Typography>
              )}
              {textLastUpdate && (
                <Typography className={classes.customFooterActionText}>
                  {strings.formatString(DAILY_STATS_FOOTER_STRINGS.text, {
                    date: getFormattedDate(textLastUpdate)
                  })}
                  <strong>{getFormattedTime(textLastUpdate)}</strong>
                </Typography>
              )}
              {shouldShowExternalToolsLabel && (
                <Typography className={classes.customFooterActionText}>
                  {strings.formatString(DAILY_STATS_FOOTER_STRINGS.externalTools, {
                    date: getFormattedDate(externalToolsLastUpdate)
                  })}
                  <strong>{getFormattedTime(externalToolsLastUpdate)}</strong>
                </Typography>
              )}
            </div>
            {shouldShowTitleLabel && (
              <FPHtmlViewer
                className={classes.customFooterActionText}
                content={DAILY_STATS_FOOTER_STRINGS.details}
              />
            )}
            {footerActionLink && (
              <div className={classes.footerLinkContainer}>
                <Link
                  href={footerActionLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.footerLink}
                >
                  {DAILY_STATS_FOOTER_STRINGS.phonePerformanceRules}
                </Link>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

DailyStatsFooter.defaultProps = {
  externalToolsLastUpdate: false,
  type: ITEM_TYPES.list
};

export default DailyStatsFooter;
