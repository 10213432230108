import * as React from "react";

const SvgFilePng = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M17.5 6h3.9a.25.25 0 00.17-.43L16.43.43A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM7 13.62h-.62a.26.26 0 00-.26.26v1.24a.26.26 0 00.26.26H7a.88.88 0 000-1.76z" />
      <path d="M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM6.87 16.62h-.49a.26.26 0 00-.26.26V19a.62.62 0 01-1.24 0v-6a.61.61 0 01.62-.62H7a2.12 2.12 0 012.11 2.34 2.2 2.2 0 01-2.24 1.9zM14.12 19a.62.62 0 01-.48.61h-.14a.64.64 0 01-.56-.34l-1.55-3.11c-.07-.13-.27-.18-.27 0V19a.62.62 0 11-1.24 0v-6a.62.62 0 01.48-.61.63.63 0 01.7.33l1.55 3.11c.07.12.27.16.27 0V13a.62.62 0 011.24 0zm5-4a.62.62 0 11-1.24 0v-.5a.88.88 0 00-1.76 0v3a.89.89 0 00.88.88.86.86 0 00.8-.54c0-.06.06-.22-.09-.22h-.21a.62.62 0 010-1.24h1a.62.62 0 01.62.62v.5a2.12 2.12 0 01-4.24 0v-3a2.12 2.12 0 014.24 0z" />
    </svg>
  );
};

export default SvgFilePng;
