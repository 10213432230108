import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenterFlexEnd } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  writeUpChips: {
    margin: theme.spacing(0.5, 0.25)
  },
  writeUpContainer: {
    ...flexAlignCenterFlexEnd,
    margin: theme.spacing(3.75)
  },
  achievementText: {
    display: 'block'
  },
  achievements: {
    margin: theme.spacing(2, 0),
    display: 'block'
  }
}));
