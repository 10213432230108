// @flow
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { FeatureFlags } from 'UI/constants/featureFlags';

type UseCandidateProfileFeatureFlagsReturnType = {
  canDisplayChangeLogs: boolean,
  canUseNavigationControls: boolean,
  canExecuteCandidateAssignmentSheet: boolean,
  canUseUrgencyVerification: boolean
};
export const useCandidateProfileFeatureFlags = (): UseCandidateProfileFeatureFlagsReturnType => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  return {
    canDisplayChangeLogs: checkIfFeatureFlagEnabled(FeatureFlags.ChangeLogCandidate),
    canUseNavigationControls: checkIfFeatureFlagEnabled(FeatureFlags.ProfileNavigationControls),
    canExecuteCandidateAssignmentSheet: checkIfFeatureFlagEnabled(
      FeatureFlags.CandidateDataSheetFlow
    ),
    canUseUrgencyVerification: checkIfFeatureFlagEnabled(FeatureFlags.UrgencyVerification)
  };
};
