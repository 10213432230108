const sharedOptions = {
  sort: true,
  display: true
};

export const ColumnsDefinitions = [
  {
    name: 'id',
    options: {
      display: 'excluded'
    }
  },
  {
    name: 'name',
    label: 'Search Project Name',
    options: sharedOptions
  },
  {
    name: 'total_items',
    label: 'Current Items',
    options: sharedOptions
  },
  {
    name: 'added',
    label: 'Added Items',
    options: sharedOptions
  },
  {
    name: 'repeated_items',
    label: 'Repeated Items',
    options: sharedOptions
  },
  {
    name: 'created_by_full_name',
    label: 'Created By',
    options: sharedOptions
  },
  {
    name: 'is_private',
    label: 'Visibility',
    options: {
      ...sharedOptions,
      renderer: 'visibility'
    }
  },
  {
    name: 'updated_at',
    label: 'Last Modification',
    options: {
      ...sharedOptions,
      renderer: 'date'
    }
  },
  {
    name: 'created_at',
    label: 'Added Date',
    options: {
      ...sharedOptions,
      renderer: 'date'
    },
  }
];