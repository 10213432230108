// @flow
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { confirm, showAlert } from 'actions/app';
import { useFetch } from 'hooks/fetch';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import isNil from 'lodash/isNil';
import { getCurrentUser } from 'services/Authentication';
import { canUserEditEntity } from 'services/Authorization';
import { sendReferenceReleaseEmail } from 'services/Candidates';
import strings from 'strings';
import type { DrawerUiState } from 'types/app';
import TextBox from 'UI/components/atoms/TextBox';
import EmailPicker from 'UI/components/molecules/EmailPicker';
import HistoryItem from 'UI/components/molecules/HistoryItem';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { TypeHistoryItem } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { UIStatus } from 'UI/constants/status';
import { REQUIRED_VALIDATION } from 'UI/utils';
import { OptionRenderers, Selectors } from 'UI/utils/renderers';

import { useStyles } from '../styles';

import ReferenceReleaseView from './view';

type ReferenceReleaseProps = {
  candidateId: number,
  onClose: () => any,
  onCompleted: () => any,
  readOnly: boolean
};

const fieldsConfig = [
  {
    name: 'to',
    validation: {
      validate(to) {
        return (to && Array.isArray(to) && to.length) || REQUIRED_VALIDATION.required;
      }
    }
  },
  {
    name: 'cc'
  },
  {
    name: 'bcc'
  },
  {
    name: 'subject',
    validation: REQUIRED_VALIDATION
  },
  {
    name: 'body',
    validation: REQUIRED_VALIDATION
  },
  {
    name: 'greeting',
    validation: REQUIRED_VALIDATION
  }
];

const buildDefaultGreeting = (name: string) => `Hi ${name}`;

const ReferenceRelease = ({
  candidateId,
  onClose,
  onCompleted,
  readOnly
}: ReferenceReleaseProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentUser = getCurrentUser();
  const [uiState, setUiState] = useState<DrawerUiState>({
    isLoading: false,
    isSaving: false,
    isSuccess: false,
    navStack: []
  });

  const { Status, state } = useFetchWithStatus(`${Endpoints.Candidates}/${candidateId}?mode=slim`);
  const candidate = state?.results;

  const form = useForm({
    defaultValues: {
      to: [],
      cc: [],
      bcc: [],
      subject: '',
      greeting: '',
      body: ''
    }
  });
  const { handleSubmit, watch, register, unregister, setValue, errors } = form;
  const formValues = watch();

  const { Status: HistoryStatus, state: historyState } = useFetchWithStatus(
    candidateId ? `${Endpoints.Candidates}/${candidateId}/${Endpoints.ReferenceReleases}` : ''
  );

  const [template] = useFetch(`${Endpoints.Candidates}/${Endpoints.ReferenceReleaseTemplate}`);
  const templateMessage = template?.message;

  const canEdit =
    !readOnly && canUserEditEntity(currentUser, candidate, { includeCollaborator: true });

  const { greeting } = formValues;

  useEffect(() => {
    if (!templateMessage || isNil(greeting)) return;
    const greetingWithBreakingSpaces = greeting.replace(/[\n\r]/g, '<br />');

    setValue('body', templateMessage.replace(/{greeting}/g, greetingWithBreakingSpaces));
  }, [greeting, templateMessage, setValue]);

  useEffect(() => {
    const preloadCandidate = () => {
      const candidateEmails = [];
      const personalEmail = candidate.personalInformation?.contact?.personal_email;
      candidate.email && candidateEmails.push(candidate.email);
      personalEmail && candidateEmails.push(personalEmail);
      setValue(
        'to',
        candidateEmails.map(email => ({ email }))
      );
      setValue('greeting', buildDefaultGreeting(candidate?.personalInformation?.first_name));
    };

    candidate && preloadCandidate();
  }, [candidate, setValue]);

  useEffect(() => {
    const preloadTemplateValues = () => {
      const innerSubject = template?.subject;
      innerSubject && setValue('subject', innerSubject);
    };

    template && preloadTemplateValues();
  }, [template, setValue]);

  useEffect(() => {
    fieldsConfig.forEach(({ name, type, validation }) => {
      register({ name, type }, validation);
    });

    return () => unregister(fieldsConfig.map(({ name }) => name));
  }, [register, unregister]);

  const handleValueChange = (name: string, value: string) => setValue(name, value, true);

  const sendEmail = async (formData = null) => {
    setUiState(prevState => ({ ...prevState, isSaving: true }));

    const result = await sendReferenceReleaseEmail(candidateId, formData, {
      successTitle: 'Reference Release sent successfully',
      successBody: `Email sent to ${candidate?.personalInformation?.full_name}`
    });

    setUiState(prevState => ({ ...prevState, isSaving: false }));
    result.alert && result.alert.body && dispatch(showAlert(result.alert));
    result.success && onCompleted && onCompleted(result.data);
    result.success && onClose && onClose();
  };

  const onSubmit = async (formData: any) => {
    dispatch(
      confirm({
        severity: 'warning',
        title: strings.inventoryProfiles.sections.candidates.referenceReleaseTitle,
        message: strings.formatString(
          strings.inventoryProfiles.sections.candidates.referenceReleaseMessage,
          { email: candidate?.email }
        ),
        confirmButtonText: 'Send Email',
        onConfirm: async ok => {
          if (!ok) return;
          const { to, cc, bcc } = formData;
          const emailMapper = ({ email }) => email;
          const finalData = {
            ...formData,
            to: to && to.map(emailMapper),
            cc: cc && cc.map(emailMapper),
            bcc: bcc && bcc.map(emailMapper)
          };
          await sendEmail(finalData);
        }
      })
    );
  };

  const emailBody = formValues.body;

  return (
    <DrawerContentLayout
      initialText={historyState?.results && historyState?.results.length ? 'Send Again' : 'Send'}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      variant="borderless"
      uiState={uiState}
      title="Reference Release Email"
      isTopToolbarNeeded
      isBottomToolbarNeeded={canEdit && state?.status === UIStatus.Success}
      onSecondaryButtonClick={onClose}
    >
      <>
        <Status
          loading={
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
              <CircularProgress color="inherit" size={40} />
            </Box>
          }
          empty={
            <Typography paragraph gutterBottom>
              Candidate not found
            </Typography>
          }
          error={error => (
            <Typography paragraph gutterBottom>
              {error}
            </Typography>
          )}
          /** react-hook-forms is not playing well with fetch hook. Moving form rendering outside Status  */
          success={() => null}
        />
        {candidate && canEdit && (
          <FormContext {...form}>
            <Typography>Candidate</Typography>
            <Typography paragraph>
              <b>{candidate?.personalInformation?.full_name}</b>
            </Typography>
            <EmailPicker
              name="to"
              placeholder="Email"
              selectedValue={formValues?.to}
              error={!!errors.to}
              errorText={errors.to && errors.to.message}
              emailKey="email"
              endpoint={Endpoints.Search}
              typeaheadParams={{
                entityType: EntityType.Candidate,
                inColumns: ['can.email']
              }}
              maxItems={10}
              allowNewItems
              onSelect={handleValueChange}
              renderOption={OptionRenderers.globalSearchPerson('title')}
              getOptionSelected={Selectors.byId}
              className={classes.fields}
              disabled={!canEdit}
            />
            <EmailPicker
              name="cc"
              placeholder="Cc"
              emailKey="email"
              selectedValue={formValues?.cc || []}
              allowNewItems
              onSelect={handleValueChange}
              className={classes.fields}
              disabled={!canEdit}
            />
            <EmailPicker
              name="bcc"
              placeholder="Bcc"
              emailKey="email"
              selectedValue={formValues?.bcc}
              allowNewItems
              onSelect={handleValueChange}
              className={classes.fields}
              disabled={!canEdit}
            />
            <TextBox
              name="subject"
              label="Subject"
              value={formValues.subject}
              onChange={handleValueChange}
              error={!!errors.subject}
              errorText={errors?.subject?.message}
              className={classes.fields}
              disabled={!canEdit}
            />
            <TextBox
              name="greeting"
              label="Intro"
              value={formValues.greeting}
              onChange={handleValueChange}
              error={!!errors.greeting}
              errorText={errors?.greeting?.message}
              className={classes.fields}
              disabled={!canEdit}
              multiline
              rows={4}
            />
            <div
              className={classes.emailBody}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: emailBody
              }}
            />
            <input type="hidden" name="body" value={emailBody} />
            <FormControl>
              <FormHelperText>{strings.email.aboutSignature}</FormHelperText>
            </FormControl>
            <FormControl component="fieldset" error={!!errors.body}>
              <FormHelperText>{errors.body?.message}</FormHelperText>
            </FormControl>
          </FormContext>
        )}
        {candidate && (
          <HistoryStatus
            loading={
              <List dense>
                <HistoryItem isLoading />
              </List>
            }
            empty={
              !canEdit && (
                <Typography paragraph gutterBottom>
                  No reference release email yet
                </Typography>
              )
            }
            error={error => (
              <Typography paragraph gutterBottom>
                {error}
              </Typography>
            )}
            success={allEmailHistory => {
              const [lastSentEmail, ...restEmails] = allEmailHistory;
              const emailHistory = canEdit ? allEmailHistory : restEmails;

              return (
                <>
                  {!canEdit && <ReferenceReleaseView email={lastSentEmail} />}
                  <List dense>
                    {emailHistory.map(({ id: innerId, user, created_at }) => (
                      <HistoryItem
                        key={innerId}
                        date={created_at}
                        type={TypeHistoryItem.Custom}
                        action={
                          <>
                            <b>{user?.personalInformation?.full_name || user?.email}</b> sent a
                            reference release email
                          </>
                        }
                      />
                    ))}
                  </List>
                </>
              );
            }}
          />
        )}
      </>
    </DrawerContentLayout>
  );
};

ReferenceRelease.defaultProps = {
  readOnly: false
};

export default ReferenceRelease;
