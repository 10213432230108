// @flow
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { THEME } from 'GlobalStyles';
import strings from 'strings';
import ConditionalActionWrapper from 'UI/components/atoms/ConditionalWrapper';
import { When } from 'UI/components/atoms/When';
import { SvgCheck, SvgDeleteTwo, SvgWarning } from 'UI/res';

import { useStyles } from './styles';

type EmailWithValidationProps = {
  email: string,
  verdict: string
};

const { invalid: invalidTooltipCopy, risky: riskyTooltipCopy } =
  strings.inventoryProfiles.common.emailValidationStatus.table.tooltip;

const VerdictConfig = {
  valid: {
    icon: <SvgCheck size={16} fill={THEME.palette.success.main} />,
    info: null
  },
  invalid: {
    icon: <SvgDeleteTwo size={16} fill={THEME.palette.error.main} />,
    info: invalidTooltipCopy
  },
  risky: {
    icon: <SvgWarning size={16} fill={THEME.palette.warning.main} />,
    info: riskyTooltipCopy
  }
};

const EmailWithValidation = ({ email = null, verdict = null }: EmailWithValidationProps) => {
  const classes = useStyles();
  if (!email) return null;

  const verdictIcon = VerdictConfig[verdict]?.icon;
  const tooltipInfo = VerdictConfig[verdict]?.info;

  return (
    <ConditionalActionWrapper
      condition={tooltipInfo}
      wrapper={children => (
        <Tooltip title={tooltipInfo} placement="bottom" arrow>
          {children}
        </Tooltip>
      )}
    >
      <div className={classes.container}>
        <When condition={verdict}>
          <div className={classes.icon} aria-label={verdict}>
            {verdictIcon}
          </div>
        </When>
        <Typography variant="body2" className={classes.emailValidationLabel}>
          {email}
        </Typography>
      </div>
    </ConditionalActionWrapper>
  );
};

export default EmailWithValidation;
