import { styled } from '@material-ui/core';
import { FPCardContent } from 'UI/components/atoms/FPCard';

export const MainContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(6)
}));

export const AnimationContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%'
});

export const EditDataSheetCardContent = styled(FPCardContent)(({ theme }) => ({
  maxWidth: theme.typography.pxToRem(700)
}));
