import React from 'react';
import Chart from 'react-google-charts';
import { Grid } from '@material-ui/core';
import { StatsItemContainer } from 'features/command-center/components/shared';
import { COMMAND_CENTER_BASE_URL } from 'features/command-center/constants';
import { METRICS, METRICS_TITLE } from 'features/command-center/constants/metrics';

import { DRAWER_KEYS } from '../../shared/Drawers';

import { DEFAULT_OPTIONS } from './HealthDistribution.constants';
import { HealthDistributionWrapper } from './HealthDistribution.styled';

const predefinedOrder = [
  METRICS.headcount.paramName,
  METRICS.onTrack.paramName,
  METRICS.atRisk.paramName,
  METRICS.behind.paramName,
  METRICS.inTraining.paramName,
  METRICS.under3Months.paramName
];

const sortStats = stats => {
  return predefinedOrder.reduce((acc, key) => {
    acc[key] = stats[key];
    return acc;
  }, {});
};

const formatStats = (stats, drawerHandlers) => {
  return Object.keys(stats).map(key => {
    return {
      title: METRICS_TITLE[key],
      value: `${stats[key].total} (${stats[key].percentage}%)`,
      valueColor: METRICS[key].color,
      ...(drawerHandlers &&
        stats[key].url &&
        stats[key].total > 0 && { onClick: () => drawerHandlers[key]({ url: stats[key].url }) })
    };
  });
};

const createDonutDataAndColors = value => {
  return Object.keys(value).reduce(
    (acc, key) => {
      if (key === METRICS.headcount.paramName) return acc;
      acc.donutData.push([METRICS_TITLE[key], value[key].total]);
      acc.colors.push(METRICS[key].color);
      return acc;
    },
    { donutData: [['Status', 'Value']], colors: [] }
  );
};

export const renderContent = ({ data, drawerHandlers }) => {
  const [stats] = data;

  const sortedStats = sortStats(stats);
  const formattedData = formatStats(sortedStats, drawerHandlers);
  const { donutData, colors } = createDonutDataAndColors(sortedStats);

  return (
    <HealthDistributionWrapper container spacing={2}>
      <Grid item sm={6} xs={12}>
        <StatsItemContainer items={formattedData} variant="stacked" />
      </Grid>

      <Grid item sm={6} xs={12}>
        <Chart
          chartType="PieChart"
          data={donutData}
          options={{ ...DEFAULT_OPTIONS, colors }}
          width="100%"
          height="250px"
          legendToggle
        />
      </Grid>
    </HealthDistributionWrapper>
  );
};

export const createDrawerHandlers = (openDrawer, metricsMap) => {
  return Object.entries(metricsMap).reduce((accumulatedHandlers, [key, value]) => {
    return {
      ...accumulatedHandlers,
      [value]: ({ url }) => {
        openDrawer({
          activeDrawer: DRAWER_KEYS[`HealthDistribution${key}`],
          contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
        });
      }
    };
  }, {});
};
