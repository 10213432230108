import { makeStyles } from '@material-ui/core';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  dropzoneContainer: {
    padding: theme.spacing(1)
  },
  disableStyle: {
    ...flexAlignCenterJustifyCenter,
    padding: theme.spacing(1),
    border: `1px dashed ${theme.palette.grey[500]}`,
    borderRadius: 4,
    color: theme.palette.grey[500],
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'no-drop'
  }
}));
