// @flow

// SERVICES
import { getOpOutList } from 'services/bulkEmail';
// REDUX - ACTIONS
import type { Dispatch, GetState } from 'types/redux';

import { showAlert } from './app';
import { makeActionCreator } from './index';

export const ADD_OPTIN = 'ADD_OPTIN';
export const REMOVE_OPTIN = 'REMOVE_OPTIN';
export const PARAMS_OPTIN = 'PARAMS_OPTIN';
export const FAILURE_OPTIN = 'FAILURE_OPTIN';
export const SUCCESS_OPTIN = 'SUCCESS_OPTIN';

const addOptIn = makeActionCreator(ADD_OPTIN, 'payload');
export const removeOptIn = makeActionCreator(REMOVE_OPTIN);

const paramsOptIn = makeActionCreator(PARAMS_OPTIN, 'payload');
const failureOptIn = makeActionCreator(FAILURE_OPTIN);
const successOptIn = makeActionCreator(SUCCESS_OPTIN, 'payload');

/** Async action to fetch get optins */
export const addOptOut = (data: any) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const optOuts = getState().optIn.domain.optins;
    optOuts.pop();
    dispatch(addOptIn([data, ...optOuts]));
  };
};

export const removeOptOut = (params: any) => {
  return (dispatch: Dispatch) => {
    dispatch(removeOptIn());
    dispatch(getOptOuts(params));
  };
};

export const getOptOuts = (params: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(paramsOptIn(params));
    const res = await getOpOutList(params);
    if (res.success) {
      dispatch(successOptIn(res.data));
    } else {
      dispatch(failureOptIn());
      dispatch(showAlert(res.alert));
    }
  };
};
