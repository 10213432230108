import * as React from "react";

const SvgNoUpdatedSendoversFilled = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M11.25 21.32H1.77a.27.27 0 01-.27-.27V1.77a.27.27 0 01.27-.27h16.82a.27.27 0 01.27.27V9.7a7.59 7.59 0 011.5.77v-8.7A1.77 1.77 0 0018.59 0H1.77A1.77 1.77 0 000 1.77v19.28a1.77 1.77 0 001.77 1.77h11.57a7.09 7.09 0 01-2.09-1.5z" />
      <path d="M23.78 22.72l-2.92-2.92A5.42 5.42 0 0022 16.5a5.48 5.48 0 10-5.5 5.5 5.42 5.42 0 003.3-1.11l2.92 2.92a.75.75 0 001.06-1.09zM12.52 16.5a4 4 0 114 4 4 4 0 01-4-4z" />
      <circle cx={6.74} cy={5.05} r={1.78} />
      <path d="M9.65 10.39a3 3 0 00-5.82 0M8.9 12.91H4.5a.75.75 0 000 1.5h4.4a.75.75 0 100-1.5zM8.9 16.87H4.5a.75.75 0 000 1.5h4.4a.75.75 0 100-1.5zM15.6 4.35h-2.13a.75.75 0 000 1.5h2.13a.75.75 0 000-1.5z" />
    </svg>
  );
};

export default SvgNoUpdatedSendoversFilled;
