// @flow
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import strings from 'strings';

import { useStyles } from './styles';

type DailyStatsHeaderActionsProps = {
  areNewMembers: boolean,
  onChange: () => void
};

const DailyStatsHeaderActions = ({ areNewMembers, onChange }: DailyStatsHeaderActionsProps) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={areNewMembers}
              onChange={onChange}
              name="areNewMembers"
            />
          }
          label={strings.home.headerActions.filterByNewMembers}
          classes={{ label: classes.labelHeaderActions }}
          onClick={e => e.stopPropagation()}
        />
      </Grid>
    </Grid>
  );
};

DailyStatsHeaderActions.defaultProps = {
  areNewMembers: false,
  onChange: () => {}
};

export default DailyStatsHeaderActions;
