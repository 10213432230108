import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';

export const useStyles = makeStyles(theme => ({
  switchContainer: {
    marginBottom: 0
  },
  fieldGroup: {
    marginBottom: theme.spacing(0.5)
  },
  notesContainer: {
    marginTop: theme.spacing(2)
  },
  inputSpacing: {
    marginBottom: theme.spacing(1)
  },
  divider: {
    height: 1,
    margin: theme.spacing(1.5, 0)
  },
  helperText: {
    marginTop: theme.spacing(-0.5),
    fontSize: 13
  }
}));

export const styles = {
  emptyStateImage: {
    margin: THEME.spacing(4, 0)
  },
  helperIcon: {
    marginRight: THEME.spacing(0.5)
  }
};
