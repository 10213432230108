// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import groupBy from 'lodash/groupBy';
import strings from 'strings';
import type { FileExplorerSection } from 'types/app';
import { When } from 'UI/components/atoms/When';
import FileUploader from 'UI/components/molecules/FileUploader';
import { EntityType } from 'UI/constants/entityTypes';

import { useStyles } from '../styles';
import {
  CandidateFileCategoriesWithSendout,
  ExcludeOtherFilesCandidate,
  FileKeyPrefix,
  FileUploaderMode,
  getfileExplorerSectionByCategory,
  MAX_NUMBER_OF_FILES
} from '../utils';

export type CandidateFilesSectionProps = {
  isEdition: boolean,
  endpoint: string,
  fileExplorerSections?: FileExplorerSection[],
  files?: Array,
  categories: Array,
  disabled?: boolean,
  softDeleteForExistingItems?: boolean,
  onSelectedFiles: () => {}
};

const CandidateFilesSection = ({
  isEdition,
  endpoint,
  fileExplorerSections,
  files,
  categories,
  disabled,
  softDeleteForExistingItems,
  onSelectedFiles
}: CandidateFilesSectionProps) => {
  const classes = useStyles();

  const filteredCategories = categories.filter(category =>
    files.map(file => file.file_type_id).includes(CandidateFileCategoriesWithSendout[category.id])
  );

  const groupedCategories = isEdition ? filteredCategories : categories;

  const groupedFiles = groupBy(
    isEdition ? files : ExcludeOtherFilesCandidate(files),
    'file_type_id'
  );

  const handleFileChange = (value: any, name?: string) => onSelectedFiles(name, value);

  return (
    <Grid container>
      <When condition={!isEdition && groupedCategories.length !== 0}>
        <Typography className={classes.secondaryTitle}>
          {strings.sendouts.attachments.removeFiles}
        </Typography>
      </When>

      <When condition={groupedCategories.length === 0}>
        <Typography variant="body1" className={classes.secondaryTitle}>
          {strings.sendouts.attachments.noFilesAdded}
        </Typography>
      </When>

      <When condition={groupedCategories.length > 0}>
        <Grid item xs={12}>
          {groupedCategories.map(category => {
            const newCategory = {
              ...category,
              id: CandidateFileCategoriesWithSendout[category.id]
            };

            return (
              <FileUploader
                category={newCategory}
                defaultEntityType={EntityType.Candidate}
                disabled={isEdition || disabled}
                endpoint={endpoint}
                fileExplorerSections={getfileExplorerSectionByCategory(
                  fileExplorerSections,
                  newCategory.id
                )}
                files={groupedFiles[newCategory.id]}
                key={category.id}
                maxNumberOfFiles={MAX_NUMBER_OF_FILES}
                mode={FileUploaderMode.CategoryField}
                name={`${FileKeyPrefix}${category.id}`}
                onAttachmentsChanged={handleFileChange}
                shouldAppendCategory
                softDeleteForExistingItems={softDeleteForExistingItems}
              />
            );
          })}
        </Grid>
      </When>
    </Grid>
  );
};

CandidateFilesSection.defaultProps = {
  fileExplorerSections: [],
  files: [],
  categories: [],
  disabled: false,
  softDeleteForExistingItems: false // Wheter to only remove a local file reference or completely remove the file from the server
};

export default CandidateFilesSection;
