// @flow
import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'UI/res/colors';

export const useStyles = makeStyles(theme => ({
  radioGroupRoot: {
    margin: theme.spacing(1, 0, 0, 0)
  },
  label: {
    paddingLeft: theme.spacing(1)
  },
  radioLabel: {
    margin: theme.spacing(0, 0, 2, 0)
  },
  // added to avoid console warning
  checked: {},
  ySpacing: {
    '& > *': {
      margin: theme.spacing(2, 0)
    }
  },
  companyData: {
    '&.header': {
      color: colors.completeBlack,
      fontSize: 16
    },
    '&.content': {
      fontSize: 14,
      fontWeight: 700
    }
  }
}));
