import * as React from 'react';

const SvgArrowRight = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M19.5 12c0 .7-.3 1.3-.8 1.7L7.5 23.6c-.8.7-2 .6-2.6-.2-.6-.8-.6-1.9.2-2.6l9.8-8.6c.1-.1.1-.2 0-.4L5.1 3.2c-.8-.7-.8-1.9-.1-2.6.7-.7 1.8-.8 2.6-.2l11.2 9.8c.4.5.7 1.1.7 1.8z" />
    </svg>
  );
};

export default SvgArrowRight;
