import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 980;

export const useStyles = makeStyles(theme => ({
  largeDrawer: {
    width: drawerWidth,
    overflowY: 'hidden',
    [theme.breakpoints.down(1024)]: {
      width: '80%'
    }
  }
}));
