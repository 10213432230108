import { EntityRoutes } from 'routes/constants';

export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'hiringAuthorityName',
    label: 'Hiring Authority',
    options: {
      display: true,
      sort: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.HiringAuthorityProfile,
          idKey: 'hiringAuthorityId',
          textKey: 'hiringAuthorityName'
        }
      }
    }
  },
  {
    name: 'companyName',
    label: 'Company',
    options: {
      display: true,
      sort: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'companyId',
          textKey: 'companyName'
        }
      }
    }
  },
  {
    name: 'jobOrderTitle',
    label: 'Job Order',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'jobOrderId',
          textKey: 'jobOrderTitle'
        }
      }
    }
  },
  {
    name: 'candidateName',
    label: 'Candidate',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'candidateId',
          textKey: 'candidateName'
        }
      }
    }
  },
  {
    name: 'recruitersName',
    label: 'Recruiters',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'jobOrderIndustryTitle',
    label: 'Industry',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'jobOrderSpecialtyTitle',
    label: 'Specialty',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'jobOrderSubspecialtyTitle',
    label: 'Subspecialty',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'createdAt',
    label: 'Sent on',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'dateBuilder',
        config: {
          format: 'MM/DD/YYYY hh:mm A'
        }
      }
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'hiringAuthorityName',
  direction: 'asc'
};
