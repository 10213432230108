// @flow
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import strings from 'strings';
import { FPCard, FPCardContent } from 'UI/components/atoms/FPCard';
import Text from 'UI/components/atoms/Text';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { SvgArrowDown, SvgArrowUp } from 'UI/res';

import { useStyles } from './styles';

type FooterActionsStateProps = {
  showMore: boolean,
  isVisible: boolean
};

type FPCardWrapperProps = {
  hasData: boolean,
  content: React.ReactNode,
  footerActionsState: FooterActionsStateProps,
  hasRedirect: boolean,
  isLoading: boolean,
  isSmallViewPort: boolean,
  footerProps: Object,
  canBeExpanded: boolean
};

const ITEM_TYPES = {
  list: 'list',
  table: 'table',
  custom: 'custom'
};

const FPCardWrapper = ({
  hasData,
  canBeExpanded,
  content,
  footerActionsState,
  hasRedirect,
  isLoading,
  isSmallViewPort,
  contentConfig: { type, headerAction, hintText, title, subtitle, titleContent, withoutCardHeader },
  footerProps,
  headerProps
}: FPCardWrapperProps) => {
  const classes = useStyles({ isSmallViewPort });

  const finalHeaderProps = !withoutCardHeader && {
    actionMode: headerAction ? 'actionAndExpand' : 'default',
    hintProps: hintText && {
      hint: hintText
    },
    title,
    subheader: subtitle,
    variant: 'hero',
    ...headerProps
  };

  const finalFooterProps =
    hasRedirect && footerActionsState.isVisible
      ? {
          footerActionIcon: footerActionsState.showMore ? SvgArrowUp : SvgArrowDown,
          customClass: type !== ITEM_TYPES.table && classes.footerContainer,
          ...footerProps,
          CustomFooter: footerProps.CustomFooter
        }
      : { CustomFooter: footerProps.CustomFooter };

  const finalContent = isLoading ? (
    <div className={classes.loadingStateContainer}>
      <EmptyPlaceholder
        {...strings.shared.loading}
        customEmptyState={<CircularProgress className={classes.circularProgress} />}
      />
    </div>
  ) : (
    content
  );

  return (
    <FPCard>
      <FPCardContent
        canBeExpanded={canBeExpanded}
        variant={type === ITEM_TYPES.table ? 'tight' : 'regular'}
        className={clsx(type !== ITEM_TYPES.table && classes.homeCard)}
        headerProps={finalHeaderProps}
        footerProps={finalFooterProps}
      >
        {hasData && titleContent && (
          <Text className={classes.titleContentLabel} text={titleContent} />
        )}
        {finalContent}
      </FPCardContent>
    </FPCard>
  );
};

FPCardWrapper.defaultProps = {
  footerProps: {
    CustomFooter: null
  },
  canBeExpanded: true
};

export default FPCardWrapper;
