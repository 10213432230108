// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioButton from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';
import FPIcon from 'UI/components/atoms/FPIcon';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import { GridItem, ItemCreationGridLayout } from 'UI/components/templates/ItemsCreation';
import { gridConfig } from 'UI/components/templates/ItemsCreation/style';
import { SvgDeleteFilled } from 'UI/res';
import {
  countryCodeOptionLabel,
  EXT_PHONE_VALIDATION,
  idOptionSelected,
  PHONE_VALIDATION,
  REQUIRED_VALIDATION,
  titleOptionLabel
} from 'UI/utils';
import { buildBasePropsForField } from 'UI/utils/forms';
import { Selectors } from 'UI/utils/renderers';

import { useStyles } from '../styles';
import { PHONE_ROW_LABELS, PHONE_ROW_PREFIX, PHONE_USE_MAP } from '../utils';

export type PhoneRow = {
  id: number,
  isDefault: true,
  phone: string | number,
  ext: number,
  use: any,
  type: any,
  phoneId?: number
};

const USE_OPTIONS = Object.values(PHONE_USE_MAP);

const getColumnSize = (isEditing: boolean, mdSize: number) => {
  const config = isEditing ? gridConfig.editionDrawer : { ...gridConfig.itemCreation, md: mdSize };
  return config;
};

const getFieldLabel = (label: string, required: boolean) => `${label} ${required ? '*' : ''}`;

type PhoneFieldsRowProps = {
  item: PhoneRow,
  isEditing?: boolean,
  rowsCount?: number,
  index?: number,
  onRemovePhoneRow: (item: any) => void,
  disabled?: boolean,
  isRequired?: boolean,
  catalogues: Object
};

const PhoneFieldsRow = ({
  item,
  rowsCount,
  index,
  isEditing,
  onRemovePhoneRow,
  disabled,
  isRequired,
  catalogues
}: PhoneFieldsRowProps) => {
  const classes = useStyles();
  const { register, unregister, errors, setValue, watch, getValues } = useFormContext();
  const formValues = watch();

  const handleOnChange = (name?, value) => {
    setValue(name, value, true);
  };

  useEffect(() => {
    const shouldValidate = isRequired ? REQUIRED_VALIDATION : { required: false };
    register({ name: `${PHONE_ROW_PREFIX.CountryCode}${item.id}` }, shouldValidate);
    register(
      { name: `${PHONE_ROW_PREFIX.Phone}${item.id}` },
      { ...shouldValidate, ...PHONE_VALIDATION }
    );
    register({ name: `${PHONE_ROW_PREFIX.Ext}${item.id}` }, EXT_PHONE_VALIDATION);

    setValue(`${PHONE_ROW_PREFIX.CountryCode}${item.id}`, item.countryCode);
    setValue(`${PHONE_ROW_PREFIX.Phone}${item.id}`, item.phone);
    setValue(`${PHONE_ROW_PREFIX.Ext}${item.id}`, item.ext);

    return () => {
      unregister(`${PHONE_ROW_PREFIX.CountryCode}${item.id}`);
      unregister(`${PHONE_ROW_PREFIX.Phone}${item.id}`);
      unregister(`${PHONE_ROW_PREFIX.Ext}${item.id}`);
    };
  }, [isRequired, item.countryCode, item.ext, item.id, item.phone, register, setValue, unregister]);

  const phoneFieldValue = getValues(`${PHONE_ROW_PREFIX.Phone}${item.id}`);
  const isPhoneFieldNotEmpty = phoneFieldValue !== '';

  useEffect(() => {
    if ((!isRequired && isPhoneFieldNotEmpty) || isRequired) {
      register({ name: `${PHONE_ROW_PREFIX.Use}${item.id}` }, REQUIRED_VALIDATION);
      register({ name: `${PHONE_ROW_PREFIX.Type}${item.id}` }, REQUIRED_VALIDATION);
    } else {
      register({ name: `${PHONE_ROW_PREFIX.Use}${item.id}` }, { required: false });
      register({ name: `${PHONE_ROW_PREFIX.Type}${item.id}` }, { required: false });
    }

    setValue(`${PHONE_ROW_PREFIX.Use}${item.id}`, item.use);
    setValue(`${PHONE_ROW_PREFIX.Type}${item.id}`, item.type);

    return () => {
      unregister(`${PHONE_ROW_PREFIX.Use}${item.id}`);
      unregister(`${PHONE_ROW_PREFIX.Type}${item.id}`);
    };
  }, [
    isPhoneFieldNotEmpty,
    isRequired,
    item.id,
    item.type,
    item.use,
    register,
    setValue,
    unregister
  ]);

  const shouldDisplayRemoveAction = isEditing ? !item.isDefault && !disabled : index;

  const inputType = item.phone.length <= PHONE_VALIDATION.maxLength.value ? 'phone' : 'text';

  return (
    <>
      <ItemCreationGridLayout>
        <GridItem {...getColumnSize(isEditing, 3)}>
          <AutocompleteSelect
            {...buildBasePropsForField(`${PHONE_ROW_PREFIX.CountryCode}${item.id}`, errors)}
            placeholder={getFieldLabel(PHONE_ROW_LABELS.CountryCode, isRequired)}
            defaultOptions={catalogues?.countryCodes}
            onSelect={handleOnChange}
            selectedValue={formValues[`${PHONE_ROW_PREFIX.CountryCode}${item.id}`] || null}
            renderOption={countryCodeOptionLabel}
            getOptionLabel={countryCodeOptionLabel}
            getOptionSelected={Selectors.byId}
            disabled={disabled}
          />
        </GridItem>
        <GridItem {...getColumnSize(isEditing, 3)}>
          <TextBox
            {...buildBasePropsForField(`${PHONE_ROW_PREFIX.Phone}${item.id}`, errors)}
            label={getFieldLabel(PHONE_ROW_LABELS.Phone, isRequired)}
            placeholder="(999)-999-9999"
            onChange={handleOnChange}
            value={formValues[`${PHONE_ROW_PREFIX.Phone}${item.id}`] || ''}
            inputType={inputType}
            disabled={disabled}
          />
        </GridItem>
        <GridItem {...getColumnSize(isEditing, 2)}>
          <TextBox
            {...buildBasePropsForField(`${PHONE_ROW_PREFIX.Ext}${item.id}`, errors)}
            label={PHONE_ROW_LABELS.Ext}
            placeholder="999"
            onChange={handleOnChange}
            value={formValues[`${PHONE_ROW_PREFIX.Ext}${item.id}`] || ''}
            disabled={disabled}
          />
        </GridItem>
        <GridItem {...getColumnSize(isEditing, 2)}>
          <AutocompleteSelect
            {...buildBasePropsForField(`${PHONE_ROW_PREFIX.Use}${item.id}`, errors)}
            onSelect={handleOnChange}
            selectedValue={formValues[`${PHONE_ROW_PREFIX.Use}${item.id}`] || null}
            placeholder={getFieldLabel(PHONE_ROW_LABELS.Use, isRequired)}
            defaultOptions={USE_OPTIONS}
            renderOption={titleOptionLabel}
            getOptionSelected={idOptionSelected}
            disabled={disabled}
          />
        </GridItem>
        <GridItem {...getColumnSize(isEditing, 2)}>
          <AutocompleteSelect
            {...buildBasePropsForField(`${PHONE_ROW_PREFIX.Type}${item.id}`, errors)}
            placeholder={getFieldLabel(PHONE_ROW_LABELS.Type, isRequired)}
            defaultOptions={catalogues?.phoneTypes}
            onSelect={handleOnChange}
            selectedValue={formValues[`${PHONE_ROW_PREFIX.Type}${item.id}`] || null}
            renderOption={titleOptionLabel}
            getOptionSelected={idOptionSelected}
            disabled={disabled}
          />
        </GridItem>
      </ItemCreationGridLayout>
      <div className={classes.actionRowContainer}>
        {rowsCount >= 2 && (
          <FormControlLabel
            name={`${PHONE_ROW_PREFIX.IsDefault}${item.isDefault}`}
            value={item.id}
            control={<RadioButton />}
            label={strings.inventory.phoneNumbers.component.defaultRadio}
            className={classes.radioText}
            disabled={disabled || !isPhoneFieldNotEmpty}
          />
        )}
        {shouldDisplayRemoveAction ? (
          <Button
            color="primary"
            className={classes.deleteRowContainer}
            onClick={() => onRemovePhoneRow(item)}
          >
            <FPIcon component={SvgDeleteFilled} aria-label="Remove Phone" size={20} />
            <Typography className={classes.removeText}>
              {strings.shared.ui.keepRemoveButtonsCopies.confirmButtonText}
            </Typography>
          </Button>
        ) : null}
      </div>
      {isEditing && <Divider />}
    </>
  );
};

PhoneFieldsRow.defaultProps = {
  isEditing: false,
  rowsCount: null,
  index: null,
  disabled: false,
  isRequired: false
};

export default PhoneFieldsRow;
