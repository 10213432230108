export const Roles = {
  Non: 0,
  Recruiter: 1,
  Coach: 2,
  Admin: 3,
  RegionalDirector: 4,
  ProductionDirector: 5,
  Operations: 6,
  DataCoordinator: 7,
  Finance: 8,
  Staff: 9,
  Leadership: 10,
  AssistantRegionalDirector: 11
};
