import * as React from 'react';

const SvgDownloadFilled = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M22.67 16.94a1.22 1.22 0 00-1.22 1.21V20a1.57 1.57 0 01-1.57 1.57H4.12A1.57 1.57 0 012.55 20v-1.85a1.22 1.22 0 00-2.43 0V20a4 4 0 004 4h15.76a4 4 0 004-4v-1.85a1.21 1.21 0 00-1.21-1.21z" />
      <path d="M12 0a1.94 1.94 0 00-1.94 1.94V11a.25.25 0 01-.25.25H7.15a1 1 0 00-.73 1.6l4.85 5.58a1 1 0 001.46 0l4.85-5.58a1 1 0 00-.73-1.6h-2.66a.25.25 0 01-.25-.25V1.94A1.94 1.94 0 0012 0z" />
    </svg>
  );
};

export default SvgDownloadFilled;
