import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'UI/res/index';

export const useStyles = makeStyles(theme => ({
  PopContainer: {
    backgroundColor: colors.white,
    width: 344,
    height: 208,
    boxShadow: '0px 1px 3px #00000033',
    padding: 20,
    boxSizing: 'border-box',
    margin: 5,
    position: 'relative',
    top: '-29px'
  },
  linkContainer: {
    display: 'flex'
  },
  previewButton: {
    position: 'relative',
    right: 0,
    marginLeft: 'auto',
    padding: '0 20px'
  },
  link: {
    color: colors.white,
    position: 'relative',
    right: 0,
    marginLeft: 'auto',
    padding: '0 20px'
  },
  initial: {
    whiteSpace: 'nowrap',
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  alpha: {
    color: colors.active
  },
  poejo: {
    color: colors.orange
  },
  cant: {
    color: colors.red,
    width: 'min-content'
  },
  search: {
    color: colors.active,
    width: 'min-content',
    whiteSpace: 'pre-line'
  },
  divider: {
    margin: theme.spacing(1, 0, 3, 0)
  },
  recruiterInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing(2)
  },
  recruiterName: {
    fontSize: 14,
    marginLeft: theme.spacing(2)
  },
  copyButton: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    display: 'inline',
    verticalAlign: 'middle'
  },
  emailContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  emailText: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  }
}));
