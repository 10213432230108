// @flow
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { showAlert } from 'actions/app';
import {
  setBulkEmailStatus,
  setBulkPreviewToInitial,
  setSelectedBulkMenuItem,
  startRemovingSelectedTemplate
} from 'actions/bulkEmail';
import clsx from 'clsx';
import { userHasRole } from 'services/Authorization';
import { getBulks } from 'services/bulkEmail';
import { savePreferences } from 'services/FiltersStorage';
import strings from 'strings';
import ConditionalActionWrapper from 'UI/components/atoms/ConditionalWrapper';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import BulkEmailContent from 'UI/components/organisms/BulkEmail/BulkEmailContent';
import { BulkMenuItems } from 'UI/constants/defaults';
import {
  responsiveHeightViewPort,
  responsiveWidthViewPort,
  verticalMenuLayout
} from 'UI/constants/dimensions';
import { Roles } from 'UI/constants/roles';
import { SvgAdd } from 'UI/res';
import { getId } from 'UI/utils';

import ContentPageLayout from '../ContentPageLayout';
import FiltersLayout from '../FiltersLayout';

import { useStyles } from './styles';
import {
  BULK_LAYOUT_PREFERENCES_KEY,
  BULK_USER_FILTERS,
  COMMON_FILTERS,
  DEFAULT_EMAILS_TO_LOAD,
  FILTERS_INITIAL,
  FIRST_PAGE,
  getFilters,
  getMappedFilters,
  getMenuItemsLabels,
  ORDER_BULKS_OPTIONS,
  ROLES_KEYS,
  SEARCH_PARAMS_INITIAL,
  SIDE_FILTERS
} from './utils';

const {
  title,
  layout: { userFiltersPlaceholder, menuItemsTitles }
} = strings.bulkEmails;

const IfElseContentWrapper = ({ children, condition, IfWrapper, ElseWrapper }) =>
  condition ? IfWrapper(children) : ElseWrapper(children);

const checkIfShouldGetEmailsList = selectedMenuItem =>
  [BulkMenuItems.Sent, BulkMenuItems.Drafts, BulkMenuItems.Schedule].includes(selectedMenuItem);

type BulkEmailLayoutProps = {
  onOpenBulkForm: () => void,
  onAddEmailToOptOut: () => void
};

const BulkEmailLayout = ({ onOpenBulkForm, onAddEmailToOptOut }: BulkEmailLayoutProps) => {
  const dispatch = useDispatch();
  const {
    selectedMenuItem: mode,
    status: { sending, loadLast, reloadEmailList }
  } = useSelector(state => state.bulkEmail.ui);
  const { template: selectedTemplate } = useSelector(state => state.bulkEmail.domain);

  const shouldWrapIntoFiltersLayout = checkIfShouldGetEmailsList(mode);
  const userHasRoles = {
    [ROLES_KEYS.dc]: userHasRole(Roles.DataCoordinator),
    [ROLES_KEYS.ops]: userHasRole(Roles.Operations),
    [ROLES_KEYS.coach]: userHasRole(Roles.Coach),
    [ROLES_KEYS.assistantRegionalDirector]: userHasRole(Roles.AssistantRegionalDirector)
  };
  const shouldShowUserFilters =
    userHasRoles.assistantRegionalDirector ||
    (userHasRoles.coach && !(userHasRoles.dc || userHasRoles.ops));

  const responsiveGridWidth = useMediaQuery(`(${responsiveWidthViewPort})`);
  const responsiveGridHeight = useMediaQuery(`(${responsiveHeightViewPort})`);
  const listItemIconSize = responsiveGridWidth ? 24 : 22;
  const layoutHeight = responsiveGridHeight
    ? verticalMenuLayout.maxContent
    : verticalMenuLayout.fixed;
  const classes = useStyles({
    responsiveGridWidth,
    responsiveGridHeight,
    shouldWrapIntoFiltersLayout
  });
  const { customColors } = useTheme().palette;

  const isListFirstLoaded = useRef(false);
  const [filters, setFilters] = useState(getFilters());
  const [searchParams, setSearchParams] = useState(SEARCH_PARAMS_INITIAL);
  const [emailsList, setEmailsList] = useState([]);
  const [uiState, setUiState] = useState({
    isLastEmailLoaded: false,
    isSideMenuOpen: false,
    loadingEmailsList: false
  });

  const userSideFilters = useMemo(() => {
    if (userHasRoles.dc || userHasRoles.ops) return [...SIDE_FILTERS.all, ...COMMON_FILTERS];
    if (userHasRoles.assistantRegionalDirector)
      return [...SIDE_FILTERS.assistantRegionalDirector, ...COMMON_FILTERS];
    if (userHasRoles.coach) return [...SIDE_FILTERS.coach, ...COMMON_FILTERS];
    return COMMON_FILTERS;
  }, [userHasRoles]);
  const menuItemsLabels = useMemo(() => getMenuItemsLabels(userHasRoles), [userHasRoles]);
  const userFilters = useMemo(() => {
    if (!shouldShowUserFilters) return [];

    const finalFilters = [BULK_USER_FILTERS.mine];
    if (userHasRoles.coach || userHasRoles.assistantRegionalDirector)
      finalFilters.push(BULK_USER_FILTERS.myTeam);
    return finalFilters;
  }, [shouldShowUserFilters, userHasRoles]);

  const getData = useCallback(
    /**
     * @param {Object} queryParams
     * @param {number} queryParams.emailsToLoad - Number of emails to load per page
     * @param {string} queryParams.keyword - Number of emails to load per page
     * @param {number} queryParams.pagination - Number of emails to load per page
     * @param {string} queryParams.mode
     * @param {Object} queryParams.filters
     * @param {'last' | 'infiniteScroll' | 'reloadList'} [type= 'infiniteScroll'] - Specifies
     * if emails are going to be loaded when scroll hits visibility sensor or if an email is sent, refresh
     * emails placing the last sent email at the beginning of sentEmails array, if an email is selected to
     * be sent now it will remove the email from list
     */
    async (queryParams = {}, type = 'infiniteScroll') => {
      const shouldGetEmailsList = checkIfShouldGetEmailsList(queryParams.mode);
      if (!shouldGetEmailsList) return;

      setUiState(prev => ({ ...prev, loadingEmailsList: true }));
      const mappedFilters = shouldGetEmailsList
        ? getMappedFilters(queryParams.filters, queryParams.mode)
        : {};
      const perPage = queryParams?.emailsToLoad || DEFAULT_EMAILS_TO_LOAD;
      const page = queryParams?.pagination || FIRST_PAGE;

      const { success, data, alert } = await getBulks(
        {
          keyword: queryParams.keyword,
          page,
          perPage,
          ...mappedFilters,
          ...(ORDER_BULKS_OPTIONS[queryParams.mode] || {})
        },
        queryParams.mode
      );
      const dataMapper = dataAux => dataAux.map(item => ({ ...item, key: getId() }));
      if (success) {
        const loaders = {
          last: () => {
            setEmailsList(prevData => {
              const filteredData = prevData.filter(each => each.id !== data[0]?.id);
              return [...dataMapper(data), ...filteredData];
            });
            dispatch(setBulkEmailStatus({ loadLast: false }));
          },
          infiniteScroll: () => {
            const loadNextSetOfEmails = data?.length === perPage;
            const loadLastFoundEmails = data?.length === 0 || data?.length < perPage;

            if (loadNextSetOfEmails) {
              const isPageLoadedFirstPage = page === FIRST_PAGE;

              isPageLoadedFirstPage
                ? setEmailsList(dataMapper(data))
                : setEmailsList(prevData => [...prevData, ...dataMapper(data)]);
              setUiState(prev => ({ ...prev, isLastEmailLoaded: false }));
            } else if (loadLastFoundEmails) {
              setEmailsList(prevData => [...prevData, ...dataMapper(data)]);
              setUiState(prev => ({ ...prev, isLastEmailLoaded: true }));
            }
          },
          reloadList: () => {
            setEmailsList(dataMapper(data));
            dispatch(setBulkEmailStatus({ loadLast: false, reloadEmailList: false }));
          }
        };

        loaders[type] && loaders[type]();
      } else dispatch(showAlert(alert));

      setUiState(prev => ({ ...prev, loadingEmailsList: false }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isListFirstLoaded.current) return;
    isListFirstLoaded.current = true;
    getData({
      filters: getFilters(),
      mode
    });
  }, [getData, mode]);

  useEffect(() => {
    if (!sending && (reloadEmailList || loadLast)) {
      if (reloadEmailList) getData({ mode, filters, keyword: searchParams.keyword }, 'reloadList');
      else if (loadLast)
        getData({ emailsToLoad: 1, mode, filters, keyword: searchParams.keyword }, 'last');
    }
  }, [filters, getData, loadLast, mode, reloadEmailList, searchParams.keyword, sending]);

  const resetBulkContentState = () => {
    setEmailsList([]);
    setSearchParams(prev => ({ ...prev, pagination: FIRST_PAGE }));
    dispatch(setBulkPreviewToInitial());
  };

  const handleBulkMenuItems = menuItemType => () => {
    dispatch(setSelectedBulkMenuItem(menuItemType));
    resetBulkContentState();
    getData({
      mode: menuItemType,
      filters
    });
  };

  const handleOpenBulkEmailForm = () => {
    selectedTemplate?.id && dispatch(startRemovingSelectedTemplate());
    onOpenBulkForm();
  };

  /**
   * @param {'user'|'side'|'dateRange'} key
   * @param {Object} newFilters
   */
  const setNewFilters = (key, newFilters) => {
    const mappedFilters = { ...filters, [key]: newFilters };
    resetBulkContentState();
    setFilters(mappedFilters);
    savePreferences(BULK_LAYOUT_PREFERENCES_KEY, mappedFilters);
    getData({ filters: mappedFilters, mode, keyword: searchParams.keyword });
  };

  const handleDateRangeChange = dateRange => setNewFilters('dateRange', dateRange);

  const handleSelectUserFilter = (_, newFilters) =>
    setNewFilters('user', newFilters || FILTERS_INITIAL.user);

  const handleFiltersChange = newFilters => setNewFilters('side', newFilters);

  const handleFiltersApply = (_, newFilters) => {
    setUiState(prev => ({ ...prev, isSideMenuOpen: false }));
    handleFiltersChange(newFilters);
  };

  const handleLoadMoreItems = () => {
    const newPage = searchParams.pagination + 1;
    setSearchParams(prev => ({ ...prev, pagination: newPage }));
    getData({ filters, mode, pagination: newPage, keyword: searchParams.keyword });
  };

  const handleKeywordChange = newSearchParams => {
    setSearchParams(newSearchParams);
    resetBulkContentState();
    getData({ filters, mode, keyword: newSearchParams.keyword }, 'reloadList');
  };

  return (
    <IfElseContentWrapper
      condition={shouldWrapIntoFiltersLayout}
      IfWrapper={children => (
        <FiltersLayout
          title={title}
          ContentPageLayoutProps={{ layoutClassName: classes.pageLayout }}
          enableStore={false}
          filters={filters.side}
          includeFilters={userSideFilters}
          isPeriodClearable
          defaultRange={filters.dateRange}
          disableTimeRange
          isSideMenuOpen={uiState.isSideMenuOpen}
          listSelector={
            shouldShowUserFilters ? (
              <AutocompleteSelect
                name="userFilter"
                placeholder={userFiltersPlaceholder}
                selectedValue={filters.user || userFilters[0]}
                onSelect={handleSelectUserFilter}
                defaultOptions={userFilters}
              />
            ) : null
          }
          onFiltersChange={handleFiltersChange}
          onMenuToggle={() =>
            setUiState(prev => ({ ...prev, isSideMenuOpen: !prev.isSideMenuOpen }))
          }
          onPeriodChange={handleDateRangeChange}
          onSearch={handleFiltersApply}
          titleLabelProps={{ backNavigation: true }}
        >
          {children}
        </FiltersLayout>
      )}
      ElseWrapper={children => (
        <ContentPageLayout
          text={title}
          titleLabelProps={{ backNavigation: true }}
          height={layoutHeight}
        >
          {children}
        </ContentPageLayout>
      )}
    >
      <Grid container className={classes.bulkLayout}>
        <Grid item xs={2} className={classes.controlsColumn}>
          <div className={classes.buttonContainer}>
            <FPActionButton
              className={classes.actionButton}
              text={!responsiveGridWidth ? title : ''}
              onClick={handleOpenBulkEmailForm}
              icon={SvgAdd}
              iconProps={{ size: 'sm' }}
            />
          </div>
          <List data-testid="bulkMenuItems" className={classes.menuItemsList}>
            {menuItemsLabels.map(({ item, Icon, visible }) => (
              <When key={item} condition={visible}>
                <ConditionalActionWrapper
                  condition={responsiveGridWidth}
                  wrapper={children => (
                    <Tooltip arrow placement="right" title={menuItemsTitles[item]}>
                      {children}
                    </Tooltip>
                  )}
                >
                  <ListItem
                    button
                    className={classes.menuListItem}
                    onClick={handleBulkMenuItems(item)}
                    selected={mode === item}
                  >
                    <ListItemIcon className={clsx(responsiveGridWidth && classes.menuListItemIcon)}>
                      <Icon fill={customColors.oxford} size={listItemIconSize} />
                    </ListItemIcon>
                    {!responsiveGridWidth && <ListItemText primary={menuItemsTitles[item]} />}
                  </ListItem>
                </ConditionalActionWrapper>
              </When>
            ))}
          </List>
        </Grid>
        <Grid item xs={10}>
          <BulkEmailContent
            BulkEmailsListProps={{
              emailsList,
              isLastEmailLoaded: uiState.isLastEmailLoaded,
              loading: uiState.loadingEmailsList,
              onLoadMoreEmails: handleLoadMoreItems
            }}
            isResponsive={responsiveGridWidth}
            onAddEmailToOptOut={onAddEmailToOptOut}
            onKeywordSearch={handleKeywordChange}
            onOpenBulkForm={onOpenBulkForm}
          />
        </Grid>
      </Grid>
    </IfElseContentWrapper>
  );
};

BulkEmailLayout.defaultProps = {
  onAddEmailToOptOut: null
};

export default BulkEmailLayout;
