// @flow
import React from 'react';

import Content from './Collections.Content';

const Collections = props => {
  return <Content {...props} />;
};

export default Collections;
