import React from 'react';
import { styled } from '@material-ui/core/styles';
import SaveButton from 'UI/components/atoms/SaveButton';
import { sideBarWidth, sideBarWidthCollapsed } from 'UI/constants/dimensions';

export const Footer = styled(({ needsExtraWidth: _needsExtraWidth, ...props }) => (
  <div {...props} />
))(({ needsExtraWidth, theme }) => ({
  position: 'fixed',
  display: 'flex',
  gap: theme.spacing(2),
  bottom: 0,
  left: needsExtraWidth ? sideBarWidth : sideBarWidthCollapsed,
  backgroundColor: theme.palette.common.white,
  padding: `${theme.spacing(3)}px ${theme.spacing(6)}px`,
  width: '100%',
  boxShadow: '0px 6px 22px 8px rgba(68, 68, 68, 0.08)',
  transition: 'left 0.5s cubic-bezier(.79,.14,.15,.86) 0s'
}));

export const ActionButton = styled(SaveButton)({
  textTransform: 'none'
});
