// @flow
import React, { useEffect, useRef, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { createTemplate, editTemplate, getSmartTags } from 'services/bulkEmail';
import strings from 'strings';
import type { BulkTemplatesDrawerPropTypes } from 'types/app';
import Text from 'UI/components/atoms/Text';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import FPTextEditor from 'UI/components/molecules/FPTextEditor';
import TemplateDropDown from 'UI/components/molecules/TemplateDropDown';
import { checkWellWrittenSmartTags } from 'UI/components/organisms/BulkEmailDrawer/utils';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { tinymceBulkConfig } from 'UI/constants/config';
import { TEMPLATE_ENTITY_TYPES } from 'UI/constants/defaults';
import { convertHtmlToPlainText } from 'UI/constants/tree';
import { LabelRenderers } from 'UI/utils/renderers';

import { FORM_FIELDS_MAP } from './fields';
import { useStyles } from './styles';

const { fields: fieldsCopies, validations: validationsCopies } = strings.bulkEmails.forms;

const createInitialValues = ({ isTemplateBeingEdited, templatesTreeItem }) =>
  isTemplateBeingEdited
    ? {
        [FORM_FIELDS_MAP.Name.key]: templatesTreeItem?.name,
        [FORM_FIELDS_MAP.Subject.key]: templatesTreeItem?.emailBody?.subject,
        [FORM_FIELDS_MAP.Html.key]: templatesTreeItem?.emailBody?.html,
        [FORM_FIELDS_MAP.ParentFolder.key]: templatesTreeItem?.parentFolder
      }
    : {
        [FORM_FIELDS_MAP.Name.key]: '',
        [FORM_FIELDS_MAP.Subject.key]: '',
        [FORM_FIELDS_MAP.Html.key]: '',
        [FORM_FIELDS_MAP.ParentFolder.key]:
          templatesTreeItem.entity === TEMPLATE_ENTITY_TYPES.Folder
            ? {
                id: templatesTreeItem.id,
                name: templatesTreeItem.name
              }
            : templatesTreeItem.parentFolder
      };

const BulkTemplatesDrawer = ({
  templatesTreeItem,
  isTemplateBeingEdited = false,
  onSubmitTemplate,
  onCloseDrawer
}: BulkTemplatesDrawerPropTypes) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false,
    isFormLoading: false
  });

  const initialValues = createInitialValues({ isTemplateBeingEdited, templatesTreeItem });

  const form = useForm({
    defaultValues: initialValues
  });
  const { register, errors, handleSubmit, watch, setValue } = form;
  const watchers = watch();

  const editorRef = useRef(null);

  useEffect(() => {
    setUiState(prev => ({ ...prev, isFormLoading: true }));
    (async () => {
      const response = await getSmartTags();

      if (response.success) {
        register(
          { name: FORM_FIELDS_MAP.Subject.key },
          {
            required: validationsCopies.subject.required,
            validate: {
              wellWrittenSmartTags: checkWellWrittenSmartTags('subject', response.data)
            }
          }
        );
        register(
          { name: FORM_FIELDS_MAP.Html.key },
          {
            required: validationsCopies.templateEmailBody.required,
            validate: {
              wellWrittenSmartTags: checkWellWrittenSmartTags('template body', response.data)
            }
          }
        );
        setValue(FORM_FIELDS_MAP.SmartTags.key, response.data);
      } else {
        dispatch(showAlert(response.alert));
      }
      setUiState(prev => ({ ...prev, isFormLoading: false }));
    })();
  }, [register, dispatch, setValue]);

  useEffect(() => {
    register(
      { name: FORM_FIELDS_MAP.Name.key },
      { required: validationsCopies.templateName.required }
    );
    register(
      { name: FORM_FIELDS_MAP.ParentFolder.key },
      { required: validationsCopies.templateParentFolder.required }
    );
    register({ name: FORM_FIELDS_MAP.SmartTags.key });
  }, [register]);

  const handleEditorChange = value => setValue(FORM_FIELDS_MAP.Html.key, value, true);

  const handleTextfieldChange = (name, value) => setValue(name, value, true);

  const onSubmit = async formData => {
    const html = formData[FORM_FIELDS_MAP.Html.key];
    const filteredData = {
      html,
      name: formData[FORM_FIELDS_MAP.Name.key],
      parent_folder_id: formData[FORM_FIELDS_MAP.ParentFolder.key].id,
      subject: formData[FORM_FIELDS_MAP.Subject.key],
      text: convertHtmlToPlainText(html) || 'This message has no text content'
    };

    setUiState(prevState => ({ ...prevState, isSaving: true }));
    const { alert, data, success } = isTemplateBeingEdited
      ? await editTemplate(templatesTreeItem.id, filteredData)
      : await createTemplate(filteredData);

    dispatch(showAlert(alert));
    if (success) {
      onSubmitTemplate &&
        onSubmitTemplate({
          id: data.id,
          previousFolderId: isTemplateBeingEdited ? templatesTreeItem?.parentFolder?.id : null,
          destinationFolderId: data.email_template_folder_id
        });
      setUiState(prevState => ({
        ...prevState,
        isSaving: false,
        isSuccess: true
      }));
    } else {
      setUiState(prevState => ({
        ...prevState,
        isSaving: false,
        isSuccess: false
      }));
    }
  };

  const handleSmartagSelect = (_, value) => editorRef.current.insertContent(value[0].value);

  const initEditor = (e, editor) => {
    editorRef.current = editor;
  };

  const handleOnClose = () => {
    const currentValues = omit(watchers, ['smartTags']);
    if (isTemplateBeingEdited) {
      currentValues.parentFolder = omit(currentValues.parentFolder, 'entity');
    }

    const hasBeenChanges = !isEqual(initialValues, currentValues);
    onCloseDrawer(hasBeenChanges);
  };

  return (
    <DrawerContentLayout
      onSubmit={handleSubmit(onSubmit)}
      onClose={handleOnClose}
      uiState={uiState}
      title={isTemplateBeingEdited ? 'Edit Template' : 'New Template'}
      drawerProps={{
        open: true
      }}
    >
      <FormContext {...form}>
        <div className={classes.formContainer}>
          <div className={classes.formInputContainer}>
            <Text variant="h2" text="1.- Template Info" />
            <TextBox
              name={FORM_FIELDS_MAP.Name.key}
              label="Name *"
              value={watchers[FORM_FIELDS_MAP.Name.key]}
              onChange={handleTextfieldChange}
              placeholder="Insert a Name *"
              error={!!errors.name}
              errorText={errors.name && errors.name.message}
              width="100%"
            />
          </div>
          <div className={classes.formInputContainer}>
            {isTemplateBeingEdited ? (
              <>
                <Text variant="h2" text="2.- Select Directory Folder *" />
                <TemplateDropDown
                  formFieldName={FORM_FIELDS_MAP.ParentFolder.key}
                  preselectedFolderId="Mine"
                  placeholder="Choose folder"
                  onlyShowFolders
                  enableIconClick
                />
              </>
            ) : (
              <>
                <Text variant="h2" text="2.- Directory Folder *" />
                <TextBox
                  name={FORM_FIELDS_MAP.ParentFolder.key}
                  label="Parent Folder *"
                  width="100%"
                  error={!!errors[FORM_FIELDS_MAP.ParentFolder.key]}
                  errorText={errors[FORM_FIELDS_MAP.ParentFolder.key]?.message}
                  value={watchers[FORM_FIELDS_MAP.ParentFolder.key]?.name}
                  disabled
                />
              </>
            )}
          </div>
          <div className={classes.formInputContainer}>
            <Text variant="h2" text="3.- Template Content" />
            <TextBox
              name={FORM_FIELDS_MAP.Subject.key}
              label="Subject *"
              placeholder="Insert a Subject *"
              value={watchers[FORM_FIELDS_MAP.Subject.key] || ''}
              onChange={handleTextfieldChange}
              error={!!errors[FORM_FIELDS_MAP.Subject.key]}
              errorText={errors[FORM_FIELDS_MAP.Subject.key]?.message}
              width="100%"
            />
            <AutocompleteSelect
              name={FORM_FIELDS_MAP.SmartTags.key}
              /**
               * DirtyHack: added props selectedValue=[] and multiple=true in order to avoid
               * autocomplete selected value console warnings and to keep current functionality
               * of selecting an option without setting the currently selected choice as input's
               * value.
               */
              multiple
              selectedValue={[]}
              placeholder={fieldsCopies.smartTags.placeholder}
              placeholderAsLabel={false}
              onSelect={handleSmartagSelect}
              defaultOptions={watchers[FORM_FIELDS_MAP.SmartTags.key]}
              getOptionLabel={LabelRenderers.smartTags}
            />
          </div>
          <div data-testid="text-editor-container" className={classes.formInputContainer}>
            <FPTextEditor
              onInit={initEditor}
              config={tinymceBulkConfig}
              value={watchers[FORM_FIELDS_MAP.Html.key]}
              error={!!errors[FORM_FIELDS_MAP.Html.key]}
              errorText={errors[FORM_FIELDS_MAP.Html.key]?.message}
              onChange={handleEditorChange}
            />
          </div>
        </div>
      </FormContext>
    </DrawerContentLayout>
  );
};

export default BulkTemplatesDrawer;
