import { EntityRoutes } from 'routes/constants';

export const COLUMNS = [
  {
    name: 'id',
    options: {
      sort: false,
      display: 'excluded'
    }
  },
  {
    name: 'recruiterName',
    label: 'Recruiter',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'coachName',
    label: 'Regional',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'hiringAuthorityName',
    label: 'Hiring Authority',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'companyName',
    label: 'Company',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          idKey: 'companyId',
          route: EntityRoutes.CompanyProfile,
          textKey: 'companyName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'statusTitle',
    label: 'Status',
    options: {
      sort: true,
      display: true,
      renderer: 'status'
    }
  },
  {
    name: 'validatedDate',
    label: 'Sent at',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'dateBuilder',
        config: {
          format: 'MM/DD/YYYY hh:mm A'
        }
      }
    }
  },
  {
    name: 'industryTitle',
    label: 'Industry',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'specialtyTitle',
    label: 'Specialty',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'subspecialtyTitle',
    label: 'Subspecialty',
    options: {
      sort: true,
      display: true
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'recruiterName',
  direction: 'asc'
};
