import React, { useMemo } from 'react';
import { getId } from 'UI/utils';

import { useStyles } from '../styles';

import SendoutRow from './SendoutRow';
import { isColumnToHide } from './utils';

const addGpacAllRowToEndOfTable = sendouts => [...sendouts, sendouts[0]];
const addLabelsAndFlattenData = data => {
  let sendouts = [];
  data.forEach(item => {
    if (item.team === 'gpac') Object.assign(item, { coach: 'gpac' });
    if (item.team === 'regional') Object.assign(item, { coach: item.regional });
    sendouts = [...sendouts, item, ...item.coaches];
  });
  return addGpacAllRowToEndOfTable(sendouts);
};

const SendoutsDataTable = ({ items, year, month, week }) => {
  const classes = useStyles();

  const sendouts = useMemo(() => addLabelsAndFlattenData(items), [items]);
  const isYearView = !!year?.date;
  const tableColumns = isYearView
    ? [
        { label: 'Team', class: classes.team },
        { label: 'Jan' },
        { label: 'Feb' },
        { label: 'Mar' },
        { label: 'Apr' },
        { label: 'May' },
        { label: 'Jun' },
        { label: 'Jul' },
        { label: 'Aug' },
        { label: 'Sep' },
        { label: 'Oct' },
        { label: 'Nov' },
        { label: 'Dec' },
        { label: 'Total' }
      ]
    : [
        { label: 'Team', class: classes.team },
        { label: 'Daily', key: 'daily' },
        { label: 'M', key: 'm' },
        { label: 'T', key: 't' },
        { label: 'W', key: 'w' },
        { label: 'TH', key: 'th' },
        { label: 'F', key: 'f' },
        { label: 'Goal', key: 'goal' },
        { label: 'Total', key: 'total' },
        { label: 'Adjusted', key: 'adjusted' },
        { label: 'Percentage', key: 'percentage' }
      ];

  return (
    <table className={classes.board}>
      <thead className={classes.boardHead}>
        <tr className={classes.title}>
          <th aria-label="Empty action button" />
          {tableColumns.map(
            column =>
              !isColumnToHide(column.key) && (
                <th key={getId()} className={column.class || ''}>
                  {column.label}
                </th>
              )
          )}
        </tr>
      </thead>
      <tbody>
        {sendouts.map(item => (
          <SendoutRow key={getId()} item={item} year={year} month={month} week={week} />
        ))}
      </tbody>
    </table>
  );
};
export default SendoutsDataTable;
