import * as React from 'react';

const SvgSendFilled = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M23 .64a.49.49 0 00-.17-.51.51.51 0 00-.54 0L1.78 10.82A1.45 1.45 0 001 12.05a1.48 1.48 0 00.68 1.3l4.56 2.88a.24.24 0 00.3 0L18.7 5.34a.251.251 0 01.37.34L9.35 17.86a.25.25 0 00-.06.2.3.3 0 00.12.17l6.43 4.07a1.48 1.48 0 001.32.11 1.43 1.43 0 00.87-1zM7.88 19.18a.25.25 0 00-.25 0 .26.26 0 00-.13.22V23a1 1 0 001 1 1 1 0 00.71-.3l2-2a.27.27 0 00.07-.21.25.25 0 00-.12-.18z" />
    </svg>
  );
};

export default SvgSendFilled;
