import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  navigationControl: {
    right: theme.spacing(4.5),
    top: theme.spacing(1),
    position: 'absolute',
    zIndex: 1
  },
  circularProgress: {
    width: '100%',
    height: '100%'
  }
}));
