import strings from 'strings';

import { HealthDistributionDrawerContent } from './HealthDistributionDrawerContent';

const { headcount, behind, atRisk, inTraining, onTrack, under3Months } =
  strings.commandCenter.healthDistributionDrawers;

export const HealthDistributionHeadCountConfig = {
  title: headcount.title,
  component: HealthDistributionDrawerContent
};

export const HealthDistributionBehindConfig = {
  title: behind.title,
  component: HealthDistributionDrawerContent
};

export const HealthDistributionAtRiskConfig = {
  title: atRisk.title,
  component: HealthDistributionDrawerContent
};

export const HealthDistributionInTrainingConfig = {
  title: inTraining.title,
  component: HealthDistributionDrawerContent
};

export const HealthDistributionOnTrackConfig = {
  title: onTrack.title,
  component: HealthDistributionDrawerContent
};

export const HealthDistributionUnder3MonthsConfig = {
  title: under3Months.title,
  component: HealthDistributionDrawerContent
};
