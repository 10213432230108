// @flow
import React from 'react';
import CollectionsListing from 'UI/pages/Collections/Collections.Listing';

interface ARCollectionsProps {
  useCollectionsInstance: object;
}

export const ARCollections = ({ useCollectionsInstance }: ARCollectionsProps) => {
  const {
    closeDrawer,
    dataTable,
    expandedRows,
    finalData,
    handleExpansion,
    handleFormCancel,
    handleSubmitSucceed,
    handleViewClick,
    uiState
  } = useCollectionsInstance;
  const { listState } = dataTable;

  return (
    <CollectionsListing
      closeDrawer={closeDrawer}
      dataTable={dataTable}
      enableCustomFilters
      expandedRows={expandedRows}
      finalData={finalData}
      listState={listState}
      onExpansion={handleExpansion}
      onFormCancel={handleFormCancel}
      onSubmitSucceed={handleSubmitSucceed}
      onViewClick={handleViewClick}
      uiState={uiState}
    />
  );
};
