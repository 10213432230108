import get from 'lodash/get';
import { SeverityBySimilarity } from 'UI/constants/status';
import { VALIDATION_REGEXS } from 'UI/utils';

/**
 * This functions prepare and transform compound form values (objects) into their corresponding id values
 * @param {object} formValues Raw form values as stored by react-hook--form
 * @param {object} fieldsMap A configuration object representing the data that needs preparation previous to submission
 * @return {object} Form data prepared and transformed prior submission
 */

export const prepareFormToSubmit = (formValues, fieldsMap) => {
  const valuesToTransform = {};

  Object.keys(fieldsMap)
    .map(fieldKey => fieldsMap[fieldKey])
    .forEach(field => {
      const extractedValue = field.outboundAccesor
        ? extractValueUsingAccesor(formValues[field.key], field.outboundAccesor, formValues)
        : formValues[field.key];
      valuesToTransform[field.paramKey || field.key] =
        extractedValue !== undefined ? extractedValue : null;
    });

  return valuesToTransform;
};

/**
 * This functions transform data coming fron the API into default values to preload a form
 * @param {object} object Raw response coming from the API
 * @param {object} fieldsMap A configuration object representing the data that needs preparation for pre-loading into form. Refer to inboundAccesor
* @param {object} context An additional context object to extract data from when preloading from backend

 * @return {object} Form data prepared to be pre-loaded into a form
 */
export const preloadFromBackend = (object, fieldsMap, context) => {
  const initialFormValues = {};

  Object.keys(fieldsMap)
    .map(fieldKey => fieldsMap[fieldKey])
    .forEach(field => {
      initialFormValues[field.key] = field.inboundAccesor
        ? extractValueUsingAccesor(object, field.inboundAccesor, context)
        : object[field.key] || object[field.paramKey];
    });
  return initialFormValues;
};

const extractValueUsingAccesor = (object, accessor, context) =>
  typeof accessor === 'function' ? accessor(object, context) : get(object, accessor);

export const buildBasePropsForField = (fieldName, errors, noMargin = true, disabled = false) => ({
  name: fieldName,
  error: !!errors[fieldName],
  errorText: errors[fieldName]?.message,
  noMargin,
  disabled
});

/**
 * It returns true if the email is valid, and false if it's not
 * @param {string} email - email to validate
 */
export const isValidEmail = email => VALIDATION_REGEXS.EMAIL.test(email);

/**
 * The function `areEmailsEqual` compares two emails and returns true if they are equal
 * (ignoring leading/trailing whitespace and case), and false otherwise.
 * @param firstEmail - The `firstEmail` parameter is the first email address that you want to compare.
 * @param secondEmail - The `secondEmail` parameter is the second email address that you want to
 * compare with the `firstEmail` parameter.
 * @returns a boolean value indicating whether the two email addresses are equal.
 */
export const areEmailsEqual = (firstEmail, secondEmail) => {
  if (!firstEmail && !secondEmail) return false;

  const trimmedEmail = firstEmail.trim().toLowerCase();
  const trimmedSecondEmail = secondEmail.trim().toLowerCase();

  return trimmedEmail === trimmedSecondEmail;
};

/**
 * The function `selectValidation` is used when it need to checks the validity and registration status of
 * two validations returned by the hook useCheckEmail.
 * It will returns the one with the highest severity level or the default data if both are invalid or
 * unregistered.
 * This is used with the useCheckEmail custom hook, mainly with duplicates.
 * @param emailValidation - The `email` parameter is an object that represents an email address. It has the
 * following properties:
 * @param otherEmailValidation - The `otherEmail` parameter is an object that represents another email address.
 * It likely has properties such as `isRegistered`, `isValidating`, `registeredData`, and `severity`.
 * @param defaultData - The `defaultData` parameter is a variable that represents the default data to
 * be used when the email is not registered or being validated. It could be any data structure or value
 * that is relevant to your application's logic.
 * @returns The function `selectValidation` returns an object with the properties `isRegistered`,
 * `isValidating`, `registeredData`, and `severity`.
 */
export const selectValidation = (emailValidation, otherEmailValidation, defaultData) => {
  if (emailValidation.severity === SeverityBySimilarity.Exact) return emailValidation;
  if (otherEmailValidation.severity === SeverityBySimilarity.Exact) return otherEmailValidation;

  if (emailValidation.isRegistered) return emailValidation;
  if (otherEmailValidation.isRegistered) return otherEmailValidation;

  if (emailValidation.isValidating || otherEmailValidation.isValidating)
    return {
      isRegistered: false,
      isValidating: true,
      registeredData: defaultData,
      severity: SeverityBySimilarity.None
    };

  return {
    isRegistered: false,
    isValidating: false,
    registeredData: defaultData,
    severity: SeverityBySimilarity.None
  };
};
