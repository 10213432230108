// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import InfoLabel from 'UI/components/molecules/InfoLabel';

import { useStyles } from '../styles';

export type LabelValueProps = {
  label: string,
  value: string,
  isFullWidth: boolean
};

const LabelValue = ({ label, value, isFullWidth = false, ...rest }: LabelValueProps) => {
  const classes = useStyles();

  return (
    <Grid sm={isFullWidth ? 12 : 6} xs={12} className={classes.infoLabel} item>
      <InfoLabel title={label} description={value} canCopy {...rest} />
    </Grid>
  );
};

export default LabelValue;
