import React, { useCallback } from 'react';
import { DRAWER_KEYS, StatsItemsCard } from 'features/command-center/components/shared';
import { COMMAND_CENTER_BASE_URL } from 'features/command-center/constants';
import { useDrawerManager } from 'features/command-center/contexts';
import { getWeekProductivity } from 'features/command-center/services';
import strings from 'strings';
import { EntityType } from 'UI/constants/entityTypes';

import { formatStats } from './WeeklyProductivityStatsSummary.utils';

export const WeeklyProductivityStatsSummary = ({ hasDrillDown, requestParams }) => {
  const { openDrawer } = useDrawerManager();

  const cachedPromises = useCallback(() => {
    const drawerHandlers = hasDrillDown
      ? {
          feeAgreementsSent: ({ url }) => {
            openDrawer({
              activeDrawer: DRAWER_KEYS.SentAgreements,
              contentProps: {
                endpoint: `${COMMAND_CENTER_BASE_URL}${url}`,
                entity: `sent${EntityType.FeeAgreement}`
              }
            });
          },
          feeAgreementsSigned: ({ url }) => {
            openDrawer({
              activeDrawer: DRAWER_KEYS.SignedAgreements,
              contentProps: {
                endpoint: `${COMMAND_CENTER_BASE_URL}${url}`,
                entity: `signed${EntityType.FeeAgreement}`
              }
            });
          },
          sendouts: ({ url }) => {
            openDrawer({
              activeDrawer: DRAWER_KEYS.Sendouts,
              contentProps: {
                endpoint: `${COMMAND_CENTER_BASE_URL}${url}`,
                entity: EntityType.Sendout
              }
            });
          },
          sendovers: ({ url }) => {
            openDrawer({
              activeDrawer: DRAWER_KEYS.Sendovers,
              contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` },
              entity: 'sendover'
            });
          }
        }
      : null;

    const { recruiter } = requestParams;
    const groupBy = recruiter ? 'recruiter' : 'team';
    return getWeekProductivity({ ...requestParams, groupBy }).then(({ data }) =>
      formatStats({ response: data, drawerHandlers })
    );
  }, [hasDrillDown, openDrawer, requestParams]);

  return (
    <StatsItemsCard
      header={strings.commandCenter.activity.weeklyProductivityStatsSummary.title}
      promise={cachedPromises}
      subheader={strings.commandCenter.activity.weeklyProductivityStatsSummary.subtitle}
      textAlign="center"
    />
  );
};
