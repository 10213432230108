import { makeStyles } from '@material-ui/core/styles';
import { inventorySectionHeader } from 'UI/constants/dimensions';

const searchBarWidths = {
  largeScreens: 490,
  smallScreens: 340
};

const dividerHeight = 40;

export const useStyles = makeStyles(theme => ({
  contentHeader: {
    height: inventorySectionHeader
  },
  divider: {
    height: dividerHeight,
    border: `1px solid ${theme.palette.customColors.borderColor}`
  },
  titleContainer: {
    width: `calc(100% - ${searchBarWidths.smallScreens + 5}px)`,
    padding: theme.spacing(0, 2.5),
    fontSize: 18,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${searchBarWidths.largeScreens + 5}px)`
    }
  },
  actionButton: {
    width: 110,
    height: 28,
    fontSize: 14,
    letterSpacing: '1.25px'
  },
  actionsFlex: {
    display: 'flex'
  },
  searchContainer: {
    flexGrow: 1,
    padding: theme.spacing(0, 2.5),
    maxWidth: searchBarWidths.smallScreens,
    [theme.breakpoints.up('lg')]: {
      maxWidth: searchBarWidths.largeScreens
    }
  },
  title: {
    maxWidth: ({ shouldShowActionButton }) =>
      shouldShowActionButton ? 'calc(100% - 140px)' : '85%',
    minWidth: 100,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}));
