import omit from 'lodash/omit';

export const formatResponse = (response, entityKeys) => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    [entityKeys.name]: {
      id: item[entityKeys.id],
      name: item[entityKeys.name]
    },
    companyName: {
      id: item.companyId,
      name: item.companyName
    },
    activityOutcome: item.activityOutcomeTypeTitle
      ? `${item.activityOutcomeTypeTitle} - ${item.activityOutcomeCategoryTitle}`
      : null,
    is_opted_out: item[entityKeys.optedOut] || false
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};

export const processNumberOfContactsFilters = newFilters => {
  const { numberOfContacts } = newFilters;
  if (!numberOfContacts) return newFilters;

  const newFiltersWithoutNumberOfContacts = omit(newFilters, 'numberOfContacts');

  const fromParamName = `${numberOfContacts.paramName}From`;
  const toParamName = `${numberOfContacts.paramName}To`;

  const fromToFilters = {
    [fromParamName]: {
      ...numberOfContacts,
      paramName: fromParamName,
      value: numberOfContacts.value.value.from
    },
    ...(numberOfContacts.value.value.to && {
      [toParamName]: {
        ...numberOfContacts,
        paramName: toParamName,
        value: numberOfContacts.value.value.to
      }
    })
  };

  return {
    ...newFiltersWithoutNumberOfContacts,
    ...fromToFilters
  };
};
