// @flow
import React from 'react';

import Content from './Content';

const SendoutBoard = props => {
  return <Content {...props} />;
};

export default SendoutBoard;
