// @flow
import { useState } from 'react';

import { getQueryStringValue, setQueryStringValue } from './queryString';

export const useTabsHandler = (
  tabsDefinition: Array<Object>,
  { enableUrlNavigation = true, useIndexes = false, initialValue = null, paramKey = 'tab' } = {}
) => {
  const paramValue = enableUrlNavigation
    ? getQueryStringValue(paramKey) || initialValue
    : initialValue;
  const initialTab = useIndexes ? Number(paramValue) : paramValue;
  const [selectedTab, setSelectedTab] = useState(initialTab || 0);

  const handleTabChange = (event: any, newValue: number) => {
    setSelectedTab(newValue);
    enableUrlNavigation && setQueryStringValue(paramKey, newValue);
  };

  return { handleTabChange, selectedTab };
};

export default useTabsHandler;
