import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: 18,
    height: 18
  }
});
