export const DATA_SHEET_QUESTIONS_PATHS = {
  'section1.feeAgreement': [],
  'section1.feeGuaranteeDays': [],
  'section1.feePercent': [],
  'section1.preparedToInterview': [],
  'section1.preparedToSign': [],
  'section1.signedFeeAgreement': [],
  'section2.companyReason': [],
  'section2.howLongPositionOpen': [],
  'section2.personInPlace': [],
  'section2.resultsYouHad': [],
  'section2.coverPosition': [],
  'section2.coverPositionDetails': [],
  'section3.idealCandidate': [],
  'section3.whatSkills': [],
  'section4.salaryRange': ['lowSalary', 'idealSalary', 'highSalary'],
  'section4.otherBenefits': [],
  'section5.workLocation': [],
  'section5.whatDuties': [],
  'section6.walkMeThrough': [],
  'section9.jobOrderType': []
};
