// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import IndustryFields from 'UI/components/molecules/IndustryFields';
import LocationForm from 'UI/components/organisms/LocationForm';
import {
  GridFormContainer,
  GridItem,
  ItemCreationGridLayout
} from 'UI/components/templates/ItemsCreation';
import { minWidthTextBox } from 'UI/constants/dimensions';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { REQUIRED_VALIDATION, URL_VALIDATION } from 'UI/utils';
import { buildBasePropsForField } from 'UI/utils/forms';
import { OptionRenderers } from 'UI/utils/renderers';

import { FormFieldsMap } from './fields';
import { gridStyles, StyledForm, styles } from './styles';

type JobOrderFormProps = {
  companyId: number | null,
  editionDrawer: boolean,
  onValuesChanged: () => void
};

const chainedSelects = {
  [FormFieldsMap.State.key]: [FormFieldsMap.City.key, FormFieldsMap.Zip.key],
  [FormFieldsMap.City.key]: [FormFieldsMap.Zip.key]
};

const JobOrderForm = ({ companyId, editionDrawer, onValuesChanged }: JobOrderFormProps) => {
  const classes = gridStyles();
  const location = useLocation();

  const { register, unregister, errors, setValue, reset, watch, getValues, formState } =
    useFormContext();
  const formValues = watch();
  const { touched: fieldsTouched, dirtyFields } = formState;
  const touchedLength = Object.keys(fieldsTouched).length;
  const dirtyFieldsLength = dirtyFields.size;
  const [differentLocation, setDifferentLocation] = useState(
    formValues[FormFieldsMap.Location.key] || false
  );
  const [uiState, setUiState] = useState({
    isLoadingCompany: false
  });

  useEffect(() => {
    onValuesChanged && onValuesChanged(getValues());
  }, [touchedLength, dirtyFieldsLength, onValuesChanged, getValues]);

  const loadCompany = useCallback(
    async id => {
      if (!id) return;
      setUiState(prevState => ({ ...prevState, isLoadingCompany: true }));
      try {
        const response = await API.get(`${Endpoints.Companies}/${id}?excludeListings=true`);
        const company = response.data;
        if (company) {
          reset({
            [FormFieldsMap.Company.key]: company,
            [FormFieldsMap.Specialty.key]: company.specialty,
            [FormFieldsMap.Subspecialty.key]: company.subspecialty
          });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
      setUiState(prevState => ({ ...prevState, isLoadingCompany: false }));
    },
    [reset]
  );

  useEffect(() => {
    companyId && loadCompany(companyId);
  }, [companyId, loadCompany]);

  useEffect(() => {
    register({ name: FormFieldsMap.Company.key }, REQUIRED_VALIDATION);
    register({ name: FormFieldsMap.SourceType.key }, REQUIRED_VALIDATION);

    register({ name: FormFieldsMap.Location.key }, REQUIRED_VALIDATION);
  }, [register]);

  useEffect(() => {
    if (differentLocation) {
      setValue(FormFieldsMap.Location.key, 1);
      register({ name: FormFieldsMap.State.key }, REQUIRED_VALIDATION);
      register({ name: FormFieldsMap.City.key }, REQUIRED_VALIDATION);
      register({ name: FormFieldsMap.Zip.key }, REQUIRED_VALIDATION);
    } else {
      setValue(FormFieldsMap.Location.key, 0);
      unregister({ name: FormFieldsMap.State.key });
      unregister({ name: FormFieldsMap.City.key });
      unregister({ name: FormFieldsMap.Zip.key });
    }
  }, [differentLocation, register, setValue, unregister]);

  const handleComboChange = (name?: string, value: any) => {
    setValue(name, value, true);

    if (name && chainedSelects[name]) {
      chainedSelects[name].forEach(chainedSelect => {
        setValue(chainedSelect, null);
      });
    }
    onValuesChanged && onValuesChanged(getValues());
  };

  const handleCompanySelect = async (name: string, value: any) => {
    reset({});
    setValue(name, value, true);

    if (!value) return;

    await loadCompany(value.id);
  };

  const handleCheckChange = e => {
    const { name, checked } = e.target;

    setValue(name, checked ? 1 : 0, checked);
    setDifferentLocation(checked);
  };

  const defaultOptionSelectedFn = (option, value) => option.id === value.id;
  return (
    <Box width="100%">
      <ItemCreationGridLayout>
        <GridItem editionDrawer={editionDrawer}>
          <AutocompleteSelect
            {...buildBasePropsForField(FormFieldsMap.Company.key, errors)}
            selectedValue={formValues[FormFieldsMap.Company.key]}
            placeholder="Company *"
            url={Endpoints.Search}
            displayKey="name"
            typeahead
            typeaheadLimit={50}
            typeaheadParams={{ entityType: EntityType.Company }}
            onSelect={handleCompanySelect}
            disabled={!!companyId}
            getOptionSelected={defaultOptionSelectedFn}
            renderOption={OptionRenderers.globalSearchDefault('name')}
            noMargin
            createButton={{
              text: 'Create company',
              concatKeyword: true,
              url: EntityRoutes.CompanyCreate,
              showAlways: true,
              location
            }}
          />
        </GridItem>
        <GridItem editionDrawer={editionDrawer}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.Position.key, errors)}
            label="Title *"
            inputRef={register(REQUIRED_VALIDATION)}
            minWidth={minWidthTextBox}
          />
        </GridItem>
        <IndustryFields
          isEditing={editionDrawer}
          onSelect={handleComboChange}
          onValuesChanged={onValuesChanged}
        />
        <GridItem editionDrawer={editionDrawer}>
          <AutocompleteSelect
            {...buildBasePropsForField(FormFieldsMap.SourceType.key, errors)}
            selectedValue={formValues[FormFieldsMap.SourceType.key]}
            placeholder="Source *"
            url={`${Endpoints.JobOrders}/${Endpoints.SourceTypes}`}
            onSelect={handleComboChange}
          />
        </GridItem>
        <GridItem editionDrawer={editionDrawer}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.Source.key, errors)}
            inputRef={register(URL_VALIDATION)}
            minWidth={minWidthTextBox}
            label="Job Board Source (URL)"
          />
        </GridItem>
        {uiState.isLoadingCompany && (
          <Grid flex="center">
            <CircularProgress size={24} />
          </Grid>
        )}
      </ItemCreationGridLayout>
      <Box>
        <hr style={styles.line} />
      </Box>
      <div className={classes.gridxs12}>
        <GridFormContainer>
          <Grid item xs={12}>
            <StyledForm
              style={styles.boxCheck}
              control={
                <Checkbox
                  style={styles.checkBox}
                  checked={differentLocation}
                  onChange={handleCheckChange}
                  name="different_location"
                />
              }
              label="Use a different location than the company's"
            />
          </Grid>
        </GridFormContainer>
      </div>
      {differentLocation && (
        <ItemCreationGridLayout>
          <LocationForm
            state={formValues[FormFieldsMap.State.key]}
            city={formValues[FormFieldsMap.City.key]}
            zip={formValues[FormFieldsMap.Zip.key]}
            handleComboChange={handleComboChange}
            editionDrawer={editionDrawer}
          />
        </ItemCreationGridLayout>
      )}
    </Box>
  );
};

JobOrderForm.defaultProps = {
  companyId: null,
  editionDrawer: false
};

export default JobOrderForm;
