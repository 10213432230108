import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenter, flexAlignCenterJustifyCenter } from 'UI/utils/styles';

const iconWidth = 24;
const getIconMargin = theme => theme.spacing(2);
const loaderWidth = 30;

export const useStyles = makeStyles(theme => ({
  treeItem: {
    '&:focus > .MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: 'transparent'
    }
  },
  treeItemContent: {
    padding: theme.spacing(0.25, 0),
    '& .MuiSvgIcon-root': {
      height: '32px'
    }
  },
  treeItemLabel: {
    color: 'inherit',
    padding: 0
  },
  labelContainer: {
    ...flexAlignCenter,
    padding: theme.spacing(0.25, 1),
    maxWidth: `calc(100% - ${getIconMargin(theme)}px)`
  },
  labelIcon: {
    minWidth: iconWidth,
    marginRight: getIconMargin(theme)
  },
  labelText: {
    width: `calc(100% - ${iconWidth + getIconMargin(theme) + loaderWidth}px)`,
    wordWrap: 'break-word !important',
    overflowWrap: 'break-word !important'
  },
  initialLoader: {
    ...flexAlignCenterJustifyCenter,
    alignSelf: 'center',
    marginTop: theme.spacing(5),
    width: '100%',
    height: '100%'
  },
  treeContainer: {
    width: '100%',
    padding: theme.spacing(0, 0.5)
  }
}));
