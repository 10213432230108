// @flow
import { useEffect } from 'react';
import API from 'services/API';

export const useCreateProfileView = ({ id, endpoint }) => {
  useEffect(() => {
    (async () => {
      try {
        if (!id || !endpoint) return;
        await API.post(endpoint.replace(':id', id));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    })();
  }, [endpoint, id]);
};
