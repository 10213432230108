export const formatResponse = response => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    hiringAuthorityName: {
      hiringAuthorityName: item.hiringAuthorityName,
      hiringAuthorityId: item.hiringAuthorityId
    },
    hiringAuthorityPhones: {
      hiringAuthorityPhones: item.hiringAuthorityPhones,
      hiringAuthoriyPhones: item.hiringAuthorityPhone
    },
    companyName: {
      companyName: item.companyName,
      companyId: item.companyId
    },
    jobOrderTitle: {
      jobOrderTitle: item.jobOrderTitle,
      jobOrderId: item.jobOrderId
    },
    candidateName: {
      candidateName: item.candidateName,
      candidateId: item.candidateId
    },
    hiringAuhtorityPhones: {
      hiringAuhtorityPhones: item.hiringAuhtorityPhones,
      hiringAuthorityPhone: item.hiringAuthorityPhone
    }
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};
