import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  risk: {
    backgroundColor: `${theme.palette.error.FP200} !important`
  },
  behind: {
    backgroundColor: `${theme.palette.warning.FP200} !important`
  },
  training: {
    backgroundColor: `${theme.palette.info.FP200} !important`
  },
  onTrack: {
    backgroundColor: `${theme.palette.success.FP200} !important`
  },
  underThreeMonths: {
    backgroundColor: `${theme.palette.grey[300]} !important`
  }
}));
