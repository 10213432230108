import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenter, flexAlignCenterJustifyCenter, flexAlignStart } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  groupLabel: {
    fontSize: 12,
    color: '#1F23298A',
    marginLeft: theme.spacing(1)
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  fieldContainer: {
    ...flexAlignCenter,
    minHeight: ({ showForm }) => (showForm ? 60 : 'auto')
  },
  flexStart: flexAlignStart,
  checkboxesContainer: {
    margin: theme.spacing(0, 0, 0.5, 0),
    '& *': {
      margin: theme.spacing(-0.25, 0)
    }
  },
  labelContainer: {
    ...flexAlignCenter,
    width: '100%',
    maxWidth: 135,
    '& > p': {
      margin: 0
    }
  },
  withHint: {
    width: '80%'
  },
  itemsSpacing: {
    '& > *': {
      marginBottom: theme.spacing(1.5)
    }
  },
  rootRadioGroup: {
    margin: theme.spacing(0, 0, 0, 2)
  },
  listboxSPButton: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1, 0),
    borderTop: '2px solid #EEE',
    cursor: 'pointer',
    fontWeight: 500,
    ...flexAlignCenterJustifyCenter
  },
  spButtonIcon: {
    marginLeft: theme.spacing(1)
  },
  outlookAlertAction: {
    whiteSpace: 'nowrap'
  },
  quotaAlert: {
    margin: theme.spacing(1, 0)
  },
  signatureHelperText: {
    marginLeft: theme.spacing(1)
  }
}));
