import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  followUpBtn: {
    minWidth: 280
  },
  interviewDetailsBtn: {
    margin: theme.spacing(0, 2),
    textTransform: 'capitalize'
  }
}));
