// @flow
import React from 'react';
import Card from '@material-ui/core/Card';
import Skeleton from '@material-ui/lab/Skeleton';
import { getId } from 'UI/utils';

import { styles, useStyles } from './styles';

type NotificationsSkeletonProps = {
  rows: number
};

const NotificationsSkeleton = (props: NotificationsSkeletonProps) => {
  const classes = useStyles();
  const { rows } = props;

  return (
    <>
      {Array.from(Array(rows)).map(() => (
        <Card className={classes.root} key={getId()}>
          <Skeleton variant="circle" width={20} height={20} style={styles.icon} />
          <div className={classes.textContainer}>
            {Array.from(Array(3)).map(() => (
              <div key={getId()} style={styles.title}>
                <Skeleton width="100%" height={10} />
              </div>
            ))}
          </div>
          <Skeleton variant="circle" width={12} height={12} style={styles.indicator} />
        </Card>
      ))}
    </>
  );
};

NotificationsSkeleton.defaultProps = {
  rows: 10
};

export default NotificationsSkeleton;
