// @flow
import React from 'react';
import strings from 'strings';
import FPHint from 'UI/components/atoms/FPHint';
import FPFormStatus from 'UI/components/molecules/FPFormStatus';

import {
  DataSheetCardHeading,
  DataSheetSection,
  DataSheetTitle
} from '../CandidateOverview.styled';
import { useStyles } from '../styles';

import EditCandidateDataSheetButton from './EditCandidateDataSheetButton';

const candidateDataSheetCopies = strings.candidates.profile.dataSheet;

type DataSheetWrapperProps = {
  canExecuteCandidateAssignmentSheet: boolean,
  progress: Object | null,
  onWriteUpClick: () => void,
  onEditClick?: () => void
};

const DataSheetWrapper = ({
  progress,
  canExecuteCandidateAssignmentSheet,
  onWriteUpClick,
  onEditClick
}: DataSheetWrapperProps) => {
  const classes = useStyles();

  if (!canExecuteCandidateAssignmentSheet) {
    return (
      <EditCandidateDataSheetButton
        onWriteUpClick={onWriteUpClick}
        className={classes.writeUpContainer}
        text={candidateDataSheetCopies.editButton.old}
      />
    );
  }

  return (
    <DataSheetSection>
      <div>
        <DataSheetCardHeading>
          <DataSheetTitle variant="h4">{candidateDataSheetCopies.title}</DataSheetTitle>
          {progress && (
            <FPFormStatus
              status={progress.key}
              totalQuestions={progress.total}
              totalAnsweredQuestions={progress.current}
            />
          )}
        </DataSheetCardHeading>
        <FPHint
          typographyProps={{ variant: 'subtitle1' }}
          hint={candidateDataSheetCopies.description}
          description={candidateDataSheetCopies.hint}
        />
      </div>
      {onEditClick && (
        <EditCandidateDataSheetButton
          onWriteUpClick={onWriteUpClick}
          onEditPersonalData={onEditClick}
          text={strings.shared.ui.edit}
        />
      )}
    </DataSheetSection>
  );
};

export default DataSheetWrapper;
