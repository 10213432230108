// @flow
import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import type { FPAvatarProps, RowActionsProps, TextProps } from 'types/app';
import ConditionalWrapper from 'UI/components/atoms/ConditionalWrapper';
import FPAvatar from 'UI/components/atoms/FPAvatar';
import InfoCard from 'UI/components/molecules/InfoCard';
import RowActions from 'UI/components/molecules/RowActions';

import { useStyles } from './styles';

type InfoCardProps = {
  actionsProps?: RowActionsProps,
  details: Array<TextProps>,
  footerProps?: Object,
  title: string,
  type: string
};

type ProfileAvatarProps = {
  avatarProps?: FPAvatarProps,
  detail?: string,
  email?: string,
  infoCardProps?: InfoCardProps,
  initials: string,
  showDetails?: boolean,
  showInfoCard?: boolean,
  title?: string
};

const ProfileAvatar = ({
  avatarProps,
  detail,
  email,
  infoCardProps,
  initials,
  showInfoCard,
  showDetails,
  title
}: ProfileAvatarProps) => {
  const classes = useStyles();
  const [uiState, setUiState] = useState({
    isInfoCardActive: false
  });

  const handleToggleInfoCard = active => () =>
    setUiState(prev => ({ ...prev, isInfoCardActive: active }));

  const Avatar = () => (
    <FPAvatar size="small" userEmail={email} {...avatarProps}>
      {initials}
    </FPAvatar>
  );
  const InfoCardWrapper = children => (
    <div
      aria-label="info-card-wrapper"
      className={classes.root}
      onMouseEnter={handleToggleInfoCard(true)}
      onMouseLeave={handleToggleInfoCard(false)}
    >
      {children}
      {uiState.isInfoCardActive && (
        <InfoCard
          ActionsComponent={RowActions}
          avatarProps={avatarProps}
          detail={detail}
          email={email}
          infoCardProps={infoCardProps}
          initials={initials}
          title={title}
        />
      )}
    </div>
  );

  return (
    <ConditionalWrapper condition={showInfoCard} wrapper={InfoCardWrapper}>
      {showDetails ? (
        <ListItem disableGutters>
          <ListItemAvatar classes={{ root: classes.listItemAvatar }}>
            <Avatar />
          </ListItemAvatar>
          <ListItemText aria-label="avatar-text" primary={title} secondary={detail} />
        </ListItem>
      ) : (
        <Avatar />
      )}
    </ConditionalWrapper>
  );
};

ProfileAvatar.defaultProps = {
  avatarProps: {},
  detail: '',
  email: '',
  infoCardProps: undefined,
  showDetails: false,
  showInfoCard: false,
  title: ''
};

export default ProfileAvatar;
