// @flow
import React from 'react';
import clsx from 'clsx';
import type { FooterActionsControlsProps } from 'types/app';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import SaveButton from 'UI/components/atoms/SaveButton';

import { useStyles } from './style';

type PrimaryButtonProps = {
  saveAction: boolean,
  primaryProps: Object
};

const PrimaryButton = ({ saveAction, primaryProps }: PrimaryButtonProps) => {
  return saveAction ? <SaveButton {...primaryProps} /> : <FPActionButton {...primaryProps} />;
};

const FooterActionsControlsLegacy = ({
  containerClassName,
  customPrimaryButton,
  hidePrimaryButton,
  hideSecondaryButton,
  primaryProps,
  saveAction,
  secondaryAction,
  secondaryContainerClassName,
  secondaryProps,
  customThirdButton
}: FooterActionsControlsProps) => {
  const classes = useStyles({ primaryProps, secondaryProps });

  const secondaryContainerClasses = clsx(classes.cancelBox, secondaryContainerClassName);

  return (
    <div className={clsx(classes.actionsContainer, containerClassName)}>
      {!hideSecondaryButton && (
        <div className={secondaryContainerClasses}>
          <FPActionButton
            text="Cancel"
            onClick={secondaryAction}
            variant="outlined"
            {...secondaryProps}
          />
        </div>
      )}
      {customThirdButton && <div className={secondaryContainerClasses}>{customThirdButton}</div>}
      {!hidePrimaryButton &&
        (customPrimaryButton || (
          <PrimaryButton saveAction={saveAction} primaryProps={primaryProps} />
        ))}
    </div>
  );
};

FooterActionsControlsLegacy.defaultProps = {
  containerClassName: undefined,
  customPrimaryButton: undefined,
  hidePrimaryButton: false,
  hideSecondaryButton: false,
  primaryProps: undefined,
  saveAction: true,
  secondaryContainerClassName: undefined,
  secondaryProps: undefined,
  customThirdButton: undefined
};

export default FooterActionsControlsLegacy;
