// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { hideReferenceReleaseComposer } from 'actions/joborder';
import { useGlobalStyles } from 'GlobalStyles';
import useDatatable, {
  buildButtonRendererDefinition,
  extractObjectFromDataTable,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import omit from 'lodash/omit';
import moment from 'moment';
import queryString from 'query-string';
import { getCurrentUser } from 'services/Authentication';
import { getHighestUserRoleInHierarchy } from 'services/Authorization';
import strings from 'strings';
import type { UserRole } from 'types/app';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import ReferenceReleaseComposer from 'UI/components/organisms/candidates/ReferenceRelease';
import DataTable, { CustomToolbar } from 'UI/components/organisms/DataTable';
import ListingSummary from 'UI/components/organisms/ListingSummary';
import PlacementEditForm from 'UI/components/organisms/placements/PlacementEditForm';
import { PlacementRoleHierarchy } from 'UI/components/organisms/placements/utils';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import TabsView from 'UI/components/templates/TabsView';
import { backNavigateListConfig } from 'UI/constants/config';
import { DateFormats, PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType, ListingFilters } from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import {
  PlacementPeriodColumnBySection,
  PlacementSortColumnBySection,
  PlacementStatusBySection,
  PlacementTabs
} from 'UI/constants/status';
import { useTableCard } from 'UI/globalStyles/DataTableStyles';
import { createCustomStaticRanges } from 'UI/utils';
import { removeQueryParams } from 'UI/utils/inventory';

import { ColumnsDefinitions, FiltersGroups } from './columns';

import 'UI/res/dateRangePicker.css';

const pageKey = 'placements-list';
const pluralName = strings.placements.title;

const ScopeFilterForRole = {
  [Roles.Recruiter]: ListingFilters.Mine,
  [Roles.Coach]: ListingFilters.MyTeam,
  [Roles.AssistantRegionalDirector]: ListingFilters.MyTeam
};

const getScopeFilterForRole = (role: UserRole) => {
  return role && ScopeFilterForRole[role.id] !== undefined
    ? ScopeFilterForRole[role.id]
    : ListingFilters.All;
};

const PlacementsList = (props: RouteComponentProps) => {
  const { location, history } = props;
  const globalClasses = useGlobalStyles();
  const tableClasses = useTableCard();
  const currentUser = getCurrentUser();
  const highestRole = getHighestUserRoleInHierarchy(currentUser, PlacementRoleHierarchy);

  const dispatch = useDispatch();
  const referenceRelease = useSelector(state => state.jobOrder.referenceRelease);
  const defaultScopeFilterId = getScopeFilterForRole(highestRole);
  const queryParameters = queryString.parse(location.search);
  const requestedTab = location.hash.replace('#', '');
  const isRequestTabValid = Object.values(PlacementTabs).some(each => each === requestedTab);

  const { id: placementIdToLoad } = queryParameters;

  const isUserFinance = highestRole?.id === Roles.Finance;
  const defaultTab = isUserFinance
    ? PlacementTabs.PendingInvoice
    : PlacementTabs.PendingValidations;
  const initialTab = isRequestTabValid ? requestedTab : defaultTab;

  const extendParams = useCallback(({ activeSection: section }, { statuses: selectedStatuses }) => {
    // If a status not valid for a tab is selected, then the default tab statuses are sent
    const statusesForSection = PlacementStatusBySection[section];
    const areThereSelectedStatuses = !!selectedStatuses?.value?.length;

    const extraParams = {
      statusIds: statusesForSection
    };

    if (areThereSelectedStatuses) {
      const selectedStatusesIds = selectedStatuses.value.map(({ id }) => id);
      const includesPredicate = statusId => selectedStatusesIds.includes(statusId);
      extraParams.statusIds = statusesForSection.some(includesPredicate)
        ? statusesForSection.filter(includesPredicate)
        : statusesForSection;
    }

    return extraParams;
  }, []);

  const orderByOptions = {
    column: PlacementSortColumnBySection,
    direction: 'desc'
  };
  const initialPreferences = getColumnPreferences(
    pageKey,
    initialTab,
    orderByOptions,
    ColumnsDefinitions
  );

  const {
    handleFiltersApply,
    handleFiltersChange,
    handleOneFilterChange,
    handleKeywordChange,
    handleColumnSortChange,
    handlePerPageChange,
    handlePageChange,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleFiltersToggle,
    handlePeriodChange,
    handleSectionChange,
    listState,
    columnPreferences,
    filters,
    data,
    count,
    getData,
    activeSection,
    selectedPeriod,
    mainOptions: scopeOptions
  } = useDatatable({
    key: pageKey,
    endpoint: Endpoints.Placements,
    entityName: pluralName,
    initialSection: initialTab,
    initialScopeFilter: defaultScopeFilterId,
    initialPreferences,
    columnsDefinitions: ColumnsDefinitions,
    orderByOptions,
    periodFilterColumn: PlacementPeriodColumnBySection,
    paramsExtender: extendParams,
    excludeUserFilters: [ListingFilters.MyDig],
    enableHashHistory: true,
    numberOfActionColumns: 1,
    shouldScrollOnNavigate: true
  });

  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  const {
    isLoading,
    showWholeSkeleton,
    isSideMenuOpen,
    page,
    perPage,
    keyword,
    userFilter: scopeFilter,
    filtersWithoutPeriod
  } = listState;

  const defaultUiState = {
    selectedRowId: null,
    isPlacementOpen: false,
    shouldRefreshSummary: false
  };

  const [uiState, setUiState] = useState(defaultUiState);

  useEffect(() => {
    document.title = PageTitles.Placements;
  }, []);

  const toggleDrawer = (drawer: string, open: boolean) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    showOrHideDrawer(drawer, open);
  };

  const showOrHideDrawer = useCallback(
    (drawer: string, open: boolean, additionalUi: any = {}) => {
      setUiState(prevState => ({ ...prevState, [drawer]: open, ...additionalUi }));
      !open &&
        history.replace({
          search: removeQueryParams(location.search, ['id']),
          hash: location.hash
        });
    },
    [history, location]
  );

  const openItemById = useCallback(
    (id: number) => showOrHideDrawer('isPlacementOpen', true, { selectedRowId: id }),
    [showOrHideDrawer]
  );

  const handleButtonClick = ({ rowData }) => {
    const item = extractObjectFromDataTable(ColumnsDefinitions, ['id'], rowData);
    openItemById(item.id);
  };

  const closePlacement = () => showOrHideDrawer('isPlacementOpen', false, { selectedRowId: null });

  const handlePlacementCompleted = async () => {
    showOrHideDrawer('isPlacementOpen', false, { selectedRowId: null, shouldRefreshSummary: true });
    await getData();
  };

  const triggerRefresh = async () => {
    setUiState(prev => ({
      ...prev,
      shouldRefreshSummary: true
    }));
    await getData();
  };

  const handleSummaryLoaded = useCallback(() => {
    setUiState(prev => ({
      ...prev,
      shouldRefreshSummary: false
    }));
  }, []);

  const hideReferenceRelease = () => dispatch(hideReferenceReleaseComposer());

  useEffect(
    () => placementIdToLoad && openItemById(placementIdToLoad),
    [openItemById, placementIdToLoad]
  );

  const actionButton = buildButtonRendererDefinition(
    globalClasses.smallRowActionButton,
    strings.shared.ui.view,
    handleButtonClick
  );

  const finalColumns = getColumnsToRender([...columns, actionButton]);

  const tableProps = {
    isExpandable: true,
    loading: showWholeSkeleton,
    refreshing: isLoading,
    filter: false,
    data,
    columns: finalColumns,
    selectableRows: 'none',
    count,
    page,
    rowsPerPage: perPage,
    searchText: keyword,
    sortOrder: { name: orderBy, direction },
    columnOrder: columnOrder?.length ? columnOrder : undefined,
    draggableColumns: {
      enabled: true
    },
    enableCustomFilters: true,
    viewColumns: true,
    rowHover: false,
    onToggleFilters: handleFiltersToggle,
    onSearchTextChange: handleKeywordChange,
    onColumnSortChange: handleColumnSortChange,
    onPerPageClick: handlePerPageChange,
    onPageClick: handlePageChange,
    onColumnDisplayClick: handleColumnDisplayChange,
    onColumnOrderChange: handleColumnOrderChange,
    className: tableClasses.tableRoot,
    components: {
      TableToolbar: CustomToolbar
    }
  };

  const tabsProp = [
    {
      id: PlacementTabs.PendingValidations,
      label: strings.placements.listing.validations.title,
      view: (
        <DataTable
          title={strings.placements.listing.validations.emptyTitle}
          subtitle={strings.placements.listing.validations.emptySubtitle}
          {...tableProps}
        />
      ),
      visible: !isUserFinance
    },
    {
      id: PlacementTabs.Estimates,
      label: strings.placements.listing.estimates.title,
      view: (
        <DataTable
          title={strings.placements.listing.estimates.emptyTitle}
          subtitle={strings.placements.listing.estimates.emptySubtitle}
          {...tableProps}
        />
      ),
      visible: true
    },
    {
      id: PlacementTabs.PendingInvoice,
      label: strings.placements.listing.pendingInvoice.title,
      view: (
        <DataTable
          title={strings.placements.listing.pendingInvoice.emptyTitle}
          subtitle={strings.placements.listing.pendingInvoice.emptySubtitle}
          {...tableProps}
        />
      )
    },
    {
      id: PlacementTabs.Invoiced,
      label: strings.placements.listing.invoiced.title,
      view: (
        <DataTable
          title={strings.placements.listing.invoiced.emptyTitle}
          subtitle={strings.placements.listing.invoiced.emptySubtitle}
          {...tableProps}
        />
      )
    },
    {
      id: PlacementTabs.FallOff,
      label: strings.placements.listing.falloff.title,
      view: (
        <DataTable
          title={strings.placements.listing.falloff.emptyTitle}
          subtitle={strings.placements.listing.falloff.emptySubtitle}
          {...tableProps}
        />
      )
    },
    {
      id: PlacementTabs.All,
      label: strings.placements.listing.all.title,
      view: (
        <DataTable
          title={strings.placements.listing.all.emptyTitle}
          subtitle={strings.placements.listing.all.emptySubtitle}
          {...tableProps}
        />
      )
    }
  ];

  const summaryFilters = filtersWithoutPeriod
    ? {
        ...omit(filtersWithoutPeriod, ['statusIds']),
        start_summary_date: moment(selectedPeriod.startDate).format(DateFormats.QueryFormat),
        end_summary_date: moment(selectedPeriod.endDate).format(DateFormats.QueryFormat)
      }
    : null;
  const paramsForSummary = queryString.stringify(summaryFilters, { arrayFormat: 'comma' });
  return (
    <FiltersLayout
      title={pluralName}
      section={EntityType.Placement}
      dateRangerPickerProps={{
        staticRanges: createCustomStaticRanges(additionalPeriodRanges)
      }}
      listSelector={
        !isUserFinance && (
          <AutocompleteSelect
            name="userFilter"
            placeholder={`${pluralName} to Show`}
            selectedValue={scopeFilter}
            onSelect={handleOneFilterChange}
            defaultOptions={scopeOptions}
            disableClearable
          />
        )
      }
      filters={filters}
      isSideMenuOpen={isSideMenuOpen}
      isLoading={showWholeSkeleton}
      groups={FiltersGroups}
      enableStore={false}
      onSearch={handleFiltersApply}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      onPeriodChange={handlePeriodChange}
      titleLabelProps={backNavigateListConfig}
    >
      <ListingSummary
        endpoint={Endpoints.PlacementSummary}
        queryParams={paramsForSummary}
        shouldRefresh={uiState.shouldRefreshSummary}
        onDataLoaded={handleSummaryLoaded}
        numberOfColumns={3}
      />
      <TabsView
        content="start"
        selectedTab={activeSection}
        useIndexes={false}
        onChangeTabIndex={handleSectionChange}
        tabs={tabsProp}
        panelHeight="100%"
      />
      {uiState.isPlacementOpen && uiState.selectedRowId && (
        <Drawer open onClose={closePlacement}>
          <PlacementEditForm
            placementId={uiState.selectedRowId}
            onClose={toggleDrawer('isPlacementOpen', false)}
            onCompleted={handlePlacementCompleted}
            onInvoicesChanged={triggerRefresh}
            onPaymentsChanged={triggerRefresh}
          />
        </Drawer>
      )}
      {referenceRelease?.candidateId && (
        <Drawer open onClose={hideReferenceRelease}>
          <ReferenceReleaseComposer
            candidateId={referenceRelease.candidateId}
            onClose={hideReferenceRelease}
            readOnly={referenceRelease.readOnly}
          />
        </Drawer>
      )}
    </FiltersLayout>
  );
};

export default PlacementsList;
