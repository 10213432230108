import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  main: {
    padding: theme.spacing(2)
  },
  subtitle: {
    fontSize: theme.typography.fontSize,
    width: 60,
    fontWeight: 500,
    alignSelf: 'center'
  },
  value: {
    fontSize: theme.typography.fontSize,
    maxWidth: 'calc(100% - 120px)'
  },
  emptyImage: flexAlignCenterJustifyCenter,
  bodyContainer: {
    '& > figure > img': {
      maxWidth: '100%'
    }
  },
  separation: {
    margin: theme.spacing(3, 0)
  }
}));
