// @flow
import React, { useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import qs from 'query-string';
import API from 'services/API';
import { getCurrentUser } from 'services/Authentication';
import Text from 'UI/components/atoms/Text';
import AttachmentChip from 'UI/components/molecules/AttachmentChip';
import { PreviewTypes } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { loadFromLocalStorage } from 'UI/utils';
import { decryptId } from 'UI/utils/encrypt';

import { usePreviewStyles } from './styles';

const BulkEmailPreview = () => {
  const classes = usePreviewStyles();
  const currentUser = getCurrentUser();
  const emailBodyRef = useRef(null);
  const [emailData, setEmailData] = useState({});
  const [uiState, setUiState] = useState({
    loading: true
  });

  useEffect(() => {
    (async () => {
      const {
        emailBody,
        queryParams,
        searchProjectId,
        selectedItems,
        subject,
        template,
        attachments
      } = loadFromLocalStorage(PreviewTypes.BulkEmail, false);

      const spId = decryptId(searchProjectId)[0];
      const previewParams = {
        subject,
        search_project_id: spId,
        body: emailBody
      };
      if (selectedItems) {
        const mappedItems = selectedItems.data.map(item => ({
          id: item.id,
          item_search_project_type: item.type_id
        }));
        const selectionParamsBuilder = {
          exclude: () => {
            previewParams.search_params = {
              query: qs.parse(queryParams.params),
              exclude: mappedItems
            };
          },
          include: () => {
            previewParams.search_project_selected_items = mappedItems;
          }
        };
        selectionParamsBuilder[selectedItems.type] && selectionParamsBuilder[selectedItems.type]();
      }
      const response = await API.post(Endpoints.BulkEmailsPreview, previewParams);
      if (response.status === 200) {
        const { body, subject: subjectPreview, to_name: toName, to_email: toEmail } = response.data;
        emailBodyRef.current.innerHTML = body;
        setEmailData({
          subject: subjectPreview,
          template,
          name: toName,
          email: toEmail,
          attachments
        });
        setUiState({ loading: false });
      }
    })();
  }, [classes.attachmentChip]);

  const details = [
    {
      title: 'To',
      detail: `${emailData.name} < ${emailData.email} >`,
      style: { fontWeight: '500 !important' }
    },
    {
      title: 'From',
      detail: `${currentUser.personalInformation.full_name} < ${currentUser.email} >`
    },
    { title: 'Template', detail: emailData.template, visible: !!emailData.template },
    { title: 'Subject', detail: emailData.subject },
    {
      title: 'Attachments',
      DetailComponent: () =>
        emailData.attachments.length > 0 && (
          <div className={classes.attachmentChip}>
            {emailData.attachments.map(attachment => (
              <AttachmentChip
                key={attachment.name}
                name={attachment.name}
                fileSize={attachment.size}
                showDownloadButton={false}
              />
            ))}
          </div>
        ),
      visible: emailData.attachments?.length > 0
    }
  ];

  return (
    <div className={classes.root}>
      <Grid container direction="column">
        {details.map(
          ({ title, detail, DetailComponent, visible }) =>
            (typeof visible === 'undefined' || visible) && (
              <Grid key={title} container item className={classes.details}>
                {uiState.loading ? (
                  <>
                    <Skeleton variant="text" width={75} className={classes.skeletonTitle} />
                    <Skeleton variant="text" width={200} />
                  </>
                ) : (
                  <>
                    <Text fontSize={16} text={`${title}:`} className={classes.title} />
                    {detail && <Text className={classes.detailText} text={detail} />}
                    {DetailComponent && <DetailComponent />}
                  </>
                )}
              </Grid>
            )
        )}
      </Grid>
      <hr />
      <div className={classes.bodyContainer}>
        {uiState.loading && <Skeleton variant="rectangle" width="75%" height="75%" />}
        <div ref={emailBodyRef} className={classes.body} />
      </div>
    </div>
  );
};

export default BulkEmailPreview;
