// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { FPCardContent } from 'UI/components/atoms/FPCard';

import { useStyles } from './styles';

const CARD_VARIANTS = {
  regular: 'regular',
  inline: 'inline'
};

const COLUMNS_FOR_REGULAR = 12;
const COLUMNS_FOR_INLINE_VALUE = 3;
const COLUMNS_FOR_INLINE_SUBTITLE = 9;

type ContainerSettingsProps = {
  shouldAddBottomSpace: boolean,
  customContainerClass: Object
};
type StatsCardProps = {
  hasCustomClass: boolean,
  subtitle: string,
  title: string,
  value: string | number,
  variant: 'regular' | 'inline',
  containerSettingsProps: ContainerSettingsProps
};

export const StatsCard = ({
  hasCustomClass,
  subtitle,
  title,
  value,
  variant,
  containerSettingsProps: { shouldAddBottomSpace = true, customContainerClass }
}: StatsCardProps) => {
  const classes = useStyles({ hasCustomClass });
  const valueContainerColums =
    variant === CARD_VARIANTS.regular ? COLUMNS_FOR_REGULAR : COLUMNS_FOR_INLINE_VALUE;
  const subtitleContainerColums =
    variant === CARD_VARIANTS.regular ? COLUMNS_FOR_REGULAR : COLUMNS_FOR_INLINE_SUBTITLE;

  const enableBottomSpace = !subtitle && shouldAddBottomSpace;

  return (
    <div
      key={title}
      data-testid="daily-stat-item"
      className={clsx(
        customContainerClass,
        classes.container,
        enableBottomSpace && classes.spacingBottom,
        classes[variant]
      )}
    >
      <FPCardContent variant="tight">
        <Grid container>
          {variant === CARD_VARIANTS.regular && (
            <Grid item xs={12} className={classes.card}>
              <Typography
                className={clsx(classes.titleCard, hasCustomClass && classes.customLabel)}
              >
                {title}
              </Typography>
            </Grid>
          )}

          <Grid
            item
            xs={valueContainerColums}
            className={clsx(
              classes.card,
              variant === CARD_VARIANTS.inline && classes.valueLeftStart
            )}
          >
            <Typography
              className={clsx(classes.valueCard, hasCustomClass && classes.customLabel)}
              noWrap
            >
              {value}
            </Typography>
          </Grid>

          {subtitle && (
            <Grid
              item
              xs={subtitleContainerColums}
              className={clsx(classes.card, variant === CARD_VARIANTS.inline && classes.leftStart)}
            >
              <Typography className={clsx(classes.subtitle, hasCustomClass && classes.customLabel)}>
                {subtitle}
              </Typography>
            </Grid>
          )}
        </Grid>
      </FPCardContent>
    </div>
  );
};

StatsCard.defaultProps = {
  hasCustomClass: false,
  title: null,
  value: 0,
  variant: 'regular',
  containerSettingsProps: {
    shouldAddBottomSpace: true,
    customContainerClass: {}
  }
};
