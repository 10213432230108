import React, { useState } from 'react';
import List from '@material-ui/core/List';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useInfiniteScroll } from 'features/message-center/hooks';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import TitleLabel from 'UI/components/atoms/TitleLabel';
import { When } from 'UI/components/atoms/When';
import Searchbar from 'UI/components/molecules/Searchbar';
import LoaderContent from 'UI/components/templates/DrawerContentLayout/LoaderContent';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { SMSEmptyState } from 'UI/res';

import { Message } from './Message';
import { useStyles } from './styles';

const COPIES = strings.messageCenter;

export const MessageList = ({
  isLoading,
  messages = [],
  onClickMessage,
  onClickNewMessage,
  onClickRefresh,
  onFetchMoreMessages,
  onSearch,
  selectedMessageId
}) => {
  const classes = useStyles();
  const { listEndRef } = useInfiniteScroll({ onScrollEndTrigger: onFetchMoreMessages });
  const [searchKeyword, setSearchKeyword] = useState('');

  const handleSearchChange = (_name, value) => setSearchKeyword(value);

  const handleSearch = () => onSearch(searchKeyword);

  const handleClickMessage = selectedMessage => onClickMessage(selectedMessage);

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          <TitleLabel text={strings.messageCenter.title} />

          <div>
            <FPIconButton
              onClick={onClickRefresh}
              tooltipProps={{
                title: strings.messageCenter.chat.refresh
              }}
            >
              <RefreshIcon />
            </FPIconButton>
            <FPIconButton
              size={24}
              onClick={onClickNewMessage}
              tooltipProps={{
                title: strings.messageCenter.newMessage
              }}
            >
              <AddCircleIcon color="primary" />
            </FPIconButton>
          </div>
        </div>

        <Searchbar
          name="keyword"
          value={searchKeyword}
          placeholder={strings.shared.ui.search}
          width="auto"
          onChange={handleSearchChange}
          onSearchAsync={handleSearch}
          showClearButton
        />
      </div>

      <div className={classes.listContainer}>
        <When condition={messages.length === 0 && !isLoading}>
          <EmptyPlaceholder
            title={
              searchKeyword ? COPIES.emptyState.search.title : COPIES.emptyState.conversations.title
            }
            subtitle={
              searchKeyword
                ? COPIES.emptyState.search.subtitle
                : COPIES.emptyState.conversations.subtitle
            }
            customEmptyState={<SMSEmptyState height={300} />}
          />
        </When>
        <List>
          {messages.map(message => (
            <Message
              key={message.id}
              isSelected={selectedMessageId === message.id}
              message={message}
              onClickMessage={handleClickMessage}
            />
          ))}
        </List>

        {isLoading && <LoaderContent color="primary" />}

        <div ref={listEndRef} />
      </div>
    </div>
  );
};
