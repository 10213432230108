import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    width: 'fit-content'
  },
  label: {
    display: 'inline-flex'
  },
  input: {
    display: 'none'
  }
}));
