import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  emailValidationLabel: {
    paddingLeft: theme.spacing(0.5)
  },
  icon: {
    paddingTop: theme.spacing(0.5)
  }
}));
