// @flow
import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import difference from 'lodash/difference';
import isNil from 'lodash/isNil';
import uniq from 'lodash/uniq';
import strings from 'strings';
import SelectBox from 'UI/components/atoms/SelectBox';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import { Endpoints } from 'UI/constants/endpoints';
import { PaymentTermsMode, PaymentTermsModes } from 'UI/constants/entityTypes';
import { FeeAgreementFields } from 'UI/constants/status';
import { REQUIRED_VALIDATION } from 'UI/utils';

const PaymentOrdinals = ['1st', '2nd', '3rd'];
export const PaymentKey = 'payment';

export const getAvailablePeriodsForPayment = (
  numberOfPeriod,
  allowedCombinations,
  previousPayments
) => {
  if (!allowedCombinations) return [];

  const availableCombinations = allowedCombinations.filter(combination =>
    previousPayments.every((prevPayment, index) => combination[index] === prevPayment)
  );

  const daysForPeriod = uniq(availableCombinations.map(combination => combination[numberOfPeriod]));

  const availablePeriods =
    numberOfPeriod === 0 ? daysForPeriod : difference(daysForPeriod, previousPayments || []);

  return availablePeriods;
};

export const getPaymentPeriodsWithValues = (
  numberOfPayments,
  formValues,
  allowedCombinations,
  readOnly
) => {
  if (!numberOfPayments || !formValues || !allowedCombinations) return [];

  const selectedPeriods = getPaymentPeriodsFromForm(formValues);

  const paymentPeriods = PaymentOrdinals.slice(0, numberOfPayments).map((ordinal, index) => {
    const key = `${PaymentKey}${index + 1}`;
    const previousPeriodKey = `${PaymentKey}${index}`;
    const value = !isNil(formValues[key]) ? formValues[key] : '';
    const previousPeriods = selectedPeriods.slice(0, index).filter(val => !isNil(val));

    return {
      fieldName: key,
      value,
      placeholder: `${ordinal} ${strings.feeAgreements.fields.payment}`,
      disabled: readOnly || (index !== 0 && isNil(formValues[previousPeriodKey])),
      options: getAvailablePeriodsForPayment(index, allowedCombinations, previousPeriods)
    };
  });

  return paymentPeriods;
};

export const getPaymentPeriodsFromForm = formValues => {
  if (!formValues) return [];

  const numberOfPayments = getNumberOfPaymentsFromForm(formValues);

  const selectedPeriods = Array.from({ length: numberOfPayments }).map(
    (_, index) => formValues[`${PaymentKey}${index + 1}`]
  );

  return selectedPeriods;
};

export const getNumberOfPaymentsFromForm = formValues => {
  if (!formValues) return null;

  const numberOfPayments = formValues[FeeAgreementFields.NumberOfPayments]
    ? Number(formValues[FeeAgreementFields.NumberOfPayments])
    : 0;
  const paymentDaysToPreload = formValues[FeeAgreementFields.PaymentDays];
  const numberOfPaymentDays = paymentDaysToPreload && paymentDaysToPreload.length;

  return numberOfPayments || numberOfPaymentDays || 0;
};

const FeeAgreementPaymentTerms = ({ readOnly = false }) => {
  const { state: paymentCombinationsResult, Status } = useFetchWithStatus(
    `${Endpoints.FeeAgreementPaymentCombinations}`
  );
  const paymentCombinationsOptions = paymentCombinationsResult?.results;

  const form = useFormContext();
  const { register, unregister, errors, setValue, watch } = form;
  const formValues = watch();

  const paymentTermsMode = formValues[FeeAgreementFields.PaymentTermsMode] || null;
  const numberOfPayments = getNumberOfPaymentsFromForm(formValues);
  const paymentOption = paymentCombinationsOptions?.find(option => option.id === numberOfPayments);
  const paymentDaysToPreload = formValues[FeeAgreementFields.PaymentDays];

  const paymentPeriods = getPaymentPeriodsWithValues(
    numberOfPayments,
    formValues,
    paymentOption?.allowedCombinations,
    readOnly
  );

  const unregisterPeriods = useCallback(() => {
    PaymentOrdinals.forEach((_, index) => unregister(`${PaymentKey}${index + 1}`));
  }, [unregister]);

  useEffect(() => {
    register({ name: FeeAgreementFields.PaymentTermsMode }, REQUIRED_VALIDATION);

    return () => unregister(FeeAgreementFields.PaymentTermsMode);
  }, [register, unregister]);

  useEffect(() => {
    if (paymentTermsMode === PaymentTermsMode.DueOn30) {
      unregister(FeeAgreementFields.NumberOfPayments);
      unregisterPeriods();
    } else if (paymentTermsMode === PaymentTermsMode.Split) {
      register({ name: FeeAgreementFields.NumberOfPayments }, REQUIRED_VALIDATION);
    }

    return () => unregister(FeeAgreementFields.NumberOfPayments);
  }, [register, unregister, paymentTermsMode, unregisterPeriods]);

  useEffect(() => {
    unregisterPeriods();

    for (let i = 0; i < numberOfPayments; i += 1) {
      register({ name: `${PaymentKey}${i + 1}` }, REQUIRED_VALIDATION);
      setValue(`${PaymentKey}${i + 1}`, null);
    }

    return unregisterPeriods;
  }, [register, setValue, unregisterPeriods, numberOfPayments]);

  useEffect(() => {
    if (paymentDaysToPreload && paymentDaysToPreload.length) {
      paymentDaysToPreload.forEach((paymentDay, i) =>
        setValue(`${PaymentKey}${i + 1}`, paymentDay)
      );
      setValue(FeeAgreementFields.NumberOfPayments, paymentDaysToPreload.length);
    }
  }, [register, setValue, paymentDaysToPreload]);

  const resetPeriodsAfterPayment = paymentNumber => {
    for (let i = paymentNumber; i < numberOfPayments; i += 1)
      setValue(`${PaymentKey}${i + 1}`, null);
  };

  const handlePeriodChange = index => (field: string, value: any) => {
    setValue(field, value, true);
    const areThereFuturePeriods = index < numberOfPayments - 1;
    areThereFuturePeriods && resetPeriodsAfterPayment(index + 1);
  };

  const handleRadioChange = ({ target }) => setValue(target.name, target.value, true);
  const handleModeChange = event => {
    handleRadioChange(event);
    setValue(FeeAgreementFields.NumberOfPayments, null);
    setValue(FeeAgreementFields.PaymentDays, null);
  };

  return (
    <>
      <Status
        loading={
          <div>
            <CircularProgress color="inherit" size={24} />
          </div>
        }
        empty={
          <Typography paragraph gutterBottom>
            {strings.feeAgreements.messages.paymentTermsNotFound}
          </Typography>
        }
        error={error => (
          <Typography paragraph gutterBottom>
            {error}
          </Typography>
        )}
        success={() => null}
      />
      {paymentCombinationsOptions && (
        <Box mb={1.5}>
          <FormGroup aria-label="position" row>
            <FPRadioGroup
              name={FeeAgreementFields.PaymentTermsMode}
              options={PaymentTermsModes}
              value={paymentTermsMode}
              onChange={handleModeChange}
              error={!!errors[FeeAgreementFields.PaymentTermsMode]}
              errorMessage={errors[FeeAgreementFields.PaymentTermsMode]?.message}
              disabled={readOnly}
            />
          </FormGroup>
          {paymentTermsMode === PaymentTermsMode.Split && (
            <>
              <FormGroup aria-label="position" row>
                <FPRadioGroup
                  name={FeeAgreementFields.NumberOfPayments}
                  options={paymentCombinationsOptions}
                  value={numberOfPayments ? `${numberOfPayments}` : null}
                  label={strings.feeAgreements.fields.numberOfPayments}
                  labelVariant="subtitle2"
                  onChange={handleRadioChange}
                  error={!!errors[FeeAgreementFields.NumberOfPayments]}
                  errorMessage={errors[FeeAgreementFields.NumberOfPayments]?.message}
                  disabled={readOnly}
                />
              </FormGroup>
              {numberOfPayments > 0 && (
                <Grid container spacing={3}>
                  {paymentPeriods.map(
                    ({ fieldName, value, placeholder, disabled, options }, index) => (
                      <Grid item sm={3} key={fieldName}>
                        <SelectBox
                          name={fieldName}
                          placeholder={placeholder}
                          error={errors[fieldName]}
                          value={value}
                          options={options}
                          onSelect={handlePeriodChange(index)}
                          disabled={disabled}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default FeeAgreementPaymentTerms;
