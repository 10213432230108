/* eslint-disable no-prototype-builtins */
/* eslint-disable no-console */
// @flow

import React, { createContext, useContext, useEffect, useState } from 'react';
import { UserProviderContext } from 'providers/UserProvider';
import API from 'services/API';
import { Endpoints } from 'UI/constants/endpoints';

export const NotificationProviderContext = createContext();

const NotificationProvider = ({ children }) => {
  const [notificationToken, setNotificationToken] = useState(null);
  const [currentUser] = useContext(UserProviderContext);

  useEffect(() => {
    const saveToken = async () => {
      await API.post(Endpoints.NotificationRegistration, { token: notificationToken });
    };
    if (currentUser && notificationToken) saveToken();
  }, [currentUser, notificationToken]);

  return (
    <NotificationProviderContext.Provider value={[notificationToken, setNotificationToken]}>
      {children}
    </NotificationProviderContext.Provider>
  );
};

export default NotificationProvider;
