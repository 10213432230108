// @flow
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import ChildSiblingForm from 'UI/components/organisms/AssociatedCompanies/ChildSiblingForm';
import ParentForm from 'UI/components/organisms/AssociatedCompanies/ParentForm';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { COMPANY_RELATIONSHIP_KEY, COMPANY_RELATIONSHIP_TYPES } from 'UI/constants/defaults';

import { useStyles } from './styles';

type CurrentCompany = {
  name: string,
  parentCompanyId: number
};

type AssociatedCompaniesDrawerProps = {
  open: boolean,
  onClose: () => void,
  onSubmit: (formValues, relationship) => void,
  companyId?: number,
  title: string,
  associatedCompaniesRelationship: string,
  currentCompany: CurrentCompany,
  associatedCompanies: Record
};

const titleLabelProps = { style: { textTransform: 'capitalize' } };

const RELATIONSHIP_KEY_MAP = {
  PARENT: 'parentCompany',
  CHILD: 'childCompanies',
  SIBLING: 'siblingCompanies'
};

const getFormToRender = (relationship, companyId) => {
  const CONTENT_MAP = {
    [COMPANY_RELATIONSHIP_TYPES.PARENT]: <ParentForm companyId={companyId} />,
    [COMPANY_RELATIONSHIP_TYPES.SIBLING]: (
      <ChildSiblingForm
        linkType={COMPANY_RELATIONSHIP_KEY.siblingCompanies}
        companyId={companyId}
      />
    ),
    [COMPANY_RELATIONSHIP_TYPES.CHILD]: (
      <ChildSiblingForm linkType={COMPANY_RELATIONSHIP_KEY.childCompanies} companyId={companyId} />
    ),
    DEFAULT: null
  };

  return CONTENT_MAP[relationship] || CONTENT_MAP.DEFAULT;
};

const getDefaultCompanies = companies => (companies?.length ? companies : []);

const AssociatedCompaniesDrawer = ({
  associatedCompanies,
  associatedCompaniesRelationship,
  currentCompany,
  onClose,
  onSubmit,
  companyId,
  open,
  title
}: AssociatedCompaniesDrawerProps) => {
  const classes = useStyles();
  const { parentCompany, siblingCompanies, childCompanies } = associatedCompanies;

  const defaultValues = {
    parentCompany: parentCompany || { id: currentCompany?.parentCompanyId },
    siblingCompanies: getDefaultCompanies(siblingCompanies),
    childCompanies: getDefaultCompanies(childCompanies),
    currentCompany
  };

  const [isSaving, setIsSaving] = useState(false);

  const form = useForm({ defaultValues });
  const { handleSubmit, watch } = form;
  const formValues = watch();

  const content = getFormToRender(associatedCompaniesRelationship, companyId);

  const handleSave = async () => {
    if (companyId) {
      setIsSaving(true);
      const successfulRequest = await onSubmit(formValues, associatedCompaniesRelationship);
      setIsSaving(false);
      if (successfulRequest) onClose();
      return;
    }

    if (!formValues.parentCompany) formValues.siblingCompanies = null;
    onSubmit(formValues, associatedCompaniesRelationship);
    onClose();
  };

  const isAddingParent = associatedCompaniesRelationship === COMPANY_RELATIONSHIP_TYPES.PARENT;

  const drawerStyle = !isAddingParent ? classes.largeDrawer : null;

  const hasParentItemSelected =
    formValues[RELATIONSHIP_KEY_MAP[associatedCompaniesRelationship]]?.id;

  const hasChildSiblingItemsSelected =
    formValues[RELATIONSHIP_KEY_MAP[associatedCompaniesRelationship]]?.length;

  const isSaveDisabled = isAddingParent ? !hasParentItemSelected : !hasChildSiblingItemsSelected;

  return (
    <DrawerContentLayout
      title={title}
      drawerProps={{ open, classes: { paper: drawerStyle } }}
      onClose={onClose}
      uiState={{ isSaving }}
      primaryProps={{
        type: 'button',
        onClick: handleSubmit(handleSave),
        disabled: (companyId && isSaveDisabled) || isSaving
      }}
      titleLabelProps={titleLabelProps}
    >
      <FormContext {...form}>{content}</FormContext>
    </DrawerContentLayout>
  );
};

AssociatedCompaniesDrawer.defaultProps = {
  open: false,
  title: null,
  associatedCompaniesRelationship: 'PARENT',
  currentCompany: null,
  onSubmit: null,
  companyId: null,
  associatedCompanies: {
    parentCompany: null,
    siblingCompanies: null,
    childCompanies: null
  }
};

export default AssociatedCompaniesDrawer;
