import { EntityRoutes } from 'routes/constants';

export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          idKey: 'statusId',
          labelKey: 'status',
          baseClass: 'chip-candidate-status',
          variant: 'outlined'
        }
      }
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          idKey: 'typeId',
          labelKey: 'type',
          baseClass: 'chip-candidate-type'
        }
      }
    }
  },
  {
    name: 'fullName',
    label: 'Full Name',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'id',
          textKey: 'fullName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'functionalTitle',
    label: 'Functional Title',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'companyName',
    label: 'Company',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'companyId',
          textKey: 'companyName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'industry',
    label: 'Industry',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'location',
    label: 'Location',
    options: {
      sort: false,
      display: true
    }
  },
  {
    name: 'createdAt',
    label: 'Added Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'recruiterName',
    label: 'Recruiter',
    options: {
      sort: true,
      display: true
    }
  }
];

export const VIRTUAL_PROPS = [
  {
    propName: 'status',
    attributes: ['statusId', 'status']
  },
  {
    propName: 'type',
    attributes: ['typeId', 'type']
  },
  {
    propName: 'companyName',
    attributes: ['companyId', 'companyName']
  },
  {
    propName: 'fullName',
    attributes: ['id', 'fullName']
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'id',
  direction: 'asc'
};
