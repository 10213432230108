import React, { createContext, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import LogRocket from 'logrocket';
import { getCurrentUser, isAuthenticated } from 'services/Authentication';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getFeatureFlags } from 'UI/utils';

export const UserProviderContext = createContext();

const featureFlags = getFeatureFlags();

const getLogRocketDomains = () => {
  const domainsSetting = `${
    window.GPAC_ENV?.LOG_ROCKET_DOMAINS || process.env.REACT_APP_LOG_ROCKET_DOMAINS
  }`;
  const domains = domainsSetting ? domainsSetting.split('|') : [];
  return {
    rootHostname: domains.shift(),
    childDomains: domains
  };
};

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const isLoggedIn = useMemo(() => !!user || isAuthenticated(), [user]);

  useEffect(() => {
    const initLogRocket = () => {
      if (isEmpty(user) || !featureFlags.includes(FeatureFlags.LogRocket)) return;

      const logRocketKey = window.GPAC_ENV?.LOG_ROCKET_KEY || process.env.REACT_APP_LOG_ROCKET_KEY;
      const domains = getLogRocketDomains();

      LogRocket.init(logRocketKey, {
        rootHostname: domains?.rootHostname,
        mergeIframes: true,
        childDomains: domains?.childDomains,
        parentDomain: domains?.rootHostname
      });

      LogRocket.identify(user?.id, {
        name: `${user?.personalInformation?.full_name}`,
        email: user?.email
      });
    };
    initLogRocket();
  }, [user]);

  useEffect(() => {
    const checkUserLogIn = async () => {
      const currentUser = getCurrentUser();
      setUser(currentUser);
    };
    checkUserLogIn();
  }, []);

  const getChildren = () => (!isLoggedIn || user ? children : null);

  return (
    <UserProviderContext.Provider value={[user, setUser]}>
      {getChildren()}
    </UserProviderContext.Provider>
  );
};

export const useUserContext = () => {
  const context = React.useContext(UserProviderContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};

export default UserProvider;
