// @flow
import React from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { selectRecruiter } from 'actions/map';
import strings from 'strings';
/** Material Assets and Components */
/** Atoms, Components and Styles */
import FPAvatar from 'UI/components/atoms/FPAvatar';
import { FPCard, FPCardContent } from 'UI/components/atoms/FPCard';
import Text from 'UI/components/atoms/Text';
import SideFiltersLayoutConnected from 'UI/components/templates/SideFiltersLayout';
import { SearchResultsNotFound } from 'UI/res';
import { getInitials } from 'UI/utils/getinitials';

import { FiltersGroups } from './columns';
import { useStyles } from './styles';
import { transformPilListData } from './utils';

type UserCardProps = {
  name: string,
  initials: string,
  info: any,
  color: string,
  onUserClick: (info: any) => any
};

const UserCard = ({ name, color, onUserClick, info, initials }: UserCardProps) => {
  const classes = useStyles();

  const initialsOrDefault = initials || getInitials(name);

  const handleClick = () => {
    onUserClick && onUserClick(info);
  };

  return (
    <FPCard className={classes.mapContainer}>
      <FPCardContent onClick={handleClick} className={classes.mapContent}>
        <FPAvatar backgroundColor={color}>{initialsOrDefault}</FPAvatar>
        <div className={classes.textArea}>
          <div className={classes.cardNameText}>{name}</div>
          <div className={classes.cardNameText}>Team: {info?.coach}</div>
          {info.teams.map(item => {
            return (
              <div key={item.id} className={classes.cardIndustryText}>
                {item?.industry}
                <Typography>
                  {item?.specialty} {item?.subspecialty && `> ${item?.subspecialty}`}
                </Typography>
              </div>
            );
          })}
        </div>
      </FPCardContent>
    </FPCard>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onUserClick: info => dispatch(selectRecruiter(info))
  };
};

const UserCardConnected = connect(null, mapDispatchToProps)(UserCard);

type UserCardContainerProps = {
  recruiters: Array<Object>
};

const UserCardContainer = (props: UserCardContainerProps) => {
  const { recruiters } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.UserContainer}>
      {recruiters.map(item => {
        return (
          <UserCardConnected
            key={item.recruiter_id}
            name={item.full_name}
            initials={item.initials}
            color={item.color}
            info={item}
          />
        );
      })}
    </Box>
  );
};

type MapDigTabProps = {
  recruiters: Array<Object>,
  hasLoaded: boolean,
  itemsCount: number
};

const MapDigTab = ({ recruiters, hasLoaded, itemsCount }: MapDigTabProps) => {
  const classes = useStyles();
  const hasResults = itemsCount > 0;
  return (
    <SideFiltersLayoutConnected
      enableCollapsibleForm
      section="dig"
      groups={FiltersGroups}
      defaultFilters={{}}
      resultsFound={hasResults}
      showGoToTop={itemsCount > 10}
      emptyContent={
        hasLoaded &&
        !itemsCount && (
          <div className={classes.noResultsContainer}>
            <Text text={strings.map.dig.noResults.title} className={classes.noResultsBoldTxt} />
            <Text
              text={strings.map.dig.noResults.subtitle}
              className={classes.noResultsSubtitleTxt}
            />
            <SearchResultsNotFound width={150} height={185} />
          </div>
        )
      }
    >
      {hasResults && (
        <>
          <Typography variant="body2" component="div">
            Showing <strong>{recruiters.length} </strong>
            results
          </Typography>
          <UserCardContainer recruiters={recruiters} />
        </>
      )}
    </SideFiltersLayoutConnected>
  );
};

const mapStateToProps = ({ map }) => ({
  recruiters: transformPilListData(map.domain.recruiters),
  itemsCount: map.domain.recruiters ? map.domain.recruiters.length : 0,
  list: map.domain.recruiters,
  isLoading: map.ui.isLoading,
  hasLoaded: map.ui.hasLoaded
});

const MapDigTabConnected = connect(mapStateToProps, null)(MapDigTab);

export default MapDigTabConnected;
