// @flow
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { confirm, showAlert } from 'actions/app';
import { showReferenceReleaseComposer } from 'actions/joborder';
import { useFetch } from 'hooks/fetch';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import { getCurrentUser } from 'services/Authentication';
import { canUserEditEntity } from 'services/Authorization';
import { createPlacement } from 'services/Placements';
import { getSendoutById } from 'services/Sendouts';
import strings from 'strings';
import type { DrawerUiState, Split } from 'types/app';
import { FeeAgreementDefaultValues, FeeAgreementFileCategoryId } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType, PaymentMode } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { FileStatus, SendoutStatus, SendoverStatus, UIStatus } from 'UI/constants/status';
import { getDataSheetByJobOrderId } from 'UI/pages/EditDataSheet/EditDataSheet.services';
import { extractFilenameFromUrl, hasFeatureFlag } from 'UI/utils';

import { FormFieldsMap } from '../../fields';
import {
  buildFileExplorerSections,
  EmailsSeparator,
  FileKeyPrefix,
  flattenSplits,
  getCompanyRecruitersEmailsFromSplits,
  matchFilesWithCategories,
  normalizeInitialSplits,
  normalizeUserPersonalInformation
} from '../../utils';

import { useValidCandidateDataSheet } from './useValidCandidateDataSheet';

const sendoutScopes = ['company'];
const sendoutRelations = [
  'candidate.files',
  'joborder.files',
  'candidate.sourceType',
  'joborder.sourceType',
  'candidateAccountable.personalInformation',
  'candidateAccountable.channelPartners.referralUser.personalInformation',
  'jobOrderAccountable.channelPartners.referralUser.personalInformation',
  'interviews.files.fileType'
];

const defaultValues = {
  [FormFieldsMap.PaymentScheme.key]: PaymentMode.Standard,
  [FormFieldsMap.FeePercentage.key]: FeeAgreementDefaultValues.FeePercent,
  [FormFieldsMap.ServiceMonths.key]: null,
  [FormFieldsMap.FirstYearCompensation.key]: null,
  [FormFieldsMap.FeeAmount.key]: null,
  [FormFieldsMap.GuaranteeDays.key]: FeeAgreementDefaultValues.GuaranteeDays,
  [FormFieldsMap.StartDate.key]: null
};

const buildAgreementFile = agreement => ({
  id: agreement.id,
  file_type_id: FeeAgreementFileCategoryId,
  entity: EntityType.FeeAgreement,
  type: FileStatus.Existing,
  file_name: extractFilenameFromUrl(agreement.pdf_url),
  url: agreement.pdf_url
});

const Views = {
  Default: 'default',
  Loading: 'loading',
  EmptySendouts: 'noSendouts'
};

const determineView = ({ isLoading, validSendouts }) => {
  if (isLoading) return Views.Loading;

  if (validSendouts && validSendouts.length === 0) return Views.EmptySendouts;

  return Views.Default;
};

type UsePlacementCreateManagerParams = {
  companyId: number,
  sendoutId: number,
  jobOrderId: number,
  onBeforeSubmit: any => void,
  onCompleted: any => any
};

const usePlacementCreateManager = ({
  companyId,
  sendoutId: preselectedSendoutId,
  jobOrderId,
  onBeforeSubmit,
  onCompleted
}: UsePlacementCreateManagerParams) => {
  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  const [uiState, setUiState] = useState<DrawerUiState>({
    isLoading: false,
    isSaving: false,
    isSuccess: false,
    isFormDisabled: false,
    isReadOnly: false,
    continueToForm: false
  });
  const [sendout, setSendout] = useState(null);
  const [selectedSendout, setSelectedSendout] = useState(null);
  const [splits, setSplits] = useState([]);
  const [initialSplits, setInitialSplits] = useState([]);
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [groupedFiles, setGroupedFiles] = useState(null);

  const { joborder, candidate } = sendout || {};
  const { company } = joborder || {};
  const { feeAgreements = [] } = company || {};
  const sendoutId = selectedSendout?.id;

  const form = useForm({ defaultValues });
  const { reset } = form;

  const { state: sendoutsState } = useFetchWithStatus(
    `${Endpoints.JobOrders}/${jobOrderId}/${Endpoints.Sendouts}`,
    []
  );

  const { candidateDataSheet } = useValidCandidateDataSheet(sendout?.candidate?.id);

  const [companyFiles] = useFetch(
    companyId ? `${Endpoints.Companies}/${companyId}/${Endpoints.Files}` : '',
    []
  );

  const { state: fileCategoriesState, refreshData: refreshFileCategories } = useFetchWithStatus(
    sendoutId ? `${Endpoints.FilesForPlacement.replace(':id', sendoutId)}` : ''
  );

  const fileCategories = fileCategoriesState?.results || [];
  const candidateFiles = candidate?.files;
  const jobOrderFiles = joborder?.files;
  const fileExplorerSections = buildFileExplorerSections(
    candidateFiles,
    jobOrderFiles,
    companyFiles
  );

  const agreementFiles = {
    [FeeAgreementFileCategoryId]: selectedAgreement ? [selectedAgreement] : []
  };

  const { state: referenceReleaseState, refreshData: refreshReferenceReleases } =
    useFetchWithStatus(
      candidate ? `${Endpoints.Candidates}/${candidate?.id}/${Endpoints.ReferenceReleases}` : '',
      null,
      'default'
    );

  const shouldLoadAssignmentDataSheet =
    hasFeatureFlag(FeatureFlags.PlacementWithAssignmentDataSheet) && !!joborder;

  const assignmentDataSheetRequest = useCallback(async () => {
    return getDataSheetByJobOrderId(joborder?.id);
  }, [joborder]);
  const { state: assignmentDataSheetState } = useFetchWithStatus(
    null,
    null,
    'default',
    null,
    shouldLoadAssignmentDataSheet ? assignmentDataSheetRequest : null
  );

  const sendouts = sendoutsState.results;

  const sendoutsForNoHireLetter = sendouts.filter(
    ({ status, no_hire_letter: isNoHireLetterSent }) =>
      status.id !== SendoutStatus.Placement && !isNoHireLetterSent
  );

  const validSendouts = useMemo(
    () =>
      sendouts.filter(
        ({ status }) => status.id === SendoutStatus.Active || status.id === SendoverStatus.Active
      ),
    [sendouts]
  );

  const companyRecruitersEmails = getCompanyRecruitersEmailsFromSplits(splits);

  useEffect(() => {
    if (!preselectedSendoutId && !validSendouts.length) return;

    const preselectedSendout = validSendouts.find(({ id }) => id === preselectedSendoutId);
    setSelectedSendout(
      preselectedSendout || (validSendouts.length === 1 ? validSendouts[0] : null)
    );
  }, [preselectedSendoutId, validSendouts]);

  useEffect(() => {
    const loadSendout = async () => {
      setSendout(null);
      setUiState(prevState => ({ ...prevState, isLoading: true }));
      const result = await getSendoutById(sendoutId, sendoutScopes, sendoutRelations);
      const sendoutData = result.data;
      if (sendoutData) {
        const companyRecruiter = normalizeUserPersonalInformation(sendoutData?.jobOrderAccountable);
        const companyChannelPartners = companyRecruiter?.channelPartners;
        const candidateRecruiter = normalizeUserPersonalInformation(
          sendoutData?.candidateAccountable
        );
        const candidateChannelPartners = candidateRecruiter?.channelPartners;

        const splts = normalizeInitialSplits({
          companyRecruiter,
          companyChannelPartners,
          candidateRecruiter,
          candidateChannelPartners
        });

        const agreements = sendoutData.joborder.company.feeAgreements;
        const defaultAgreement = agreements?.length && agreements[0];
        defaultAgreement && setSelectedAgreement(buildAgreementFile(defaultAgreement));

        setInitialSplits(splts);
        setSendout(sendoutData);
      }
      setUiState(prevState => ({ ...prevState, isLoading: false }));
    };
    sendoutId && loadSendout();
  }, [sendoutId]);

  useEffect(() => {
    const matchFiles = () => {
      const flattenedInterviewFiles = sendout?.interviews
        ? sendout?.interviews
            .map(({ files }) => files ?? [])
            .reduce((allFiles, files) => [...allFiles, ...files], [])
        : [];

      const allFiles = {
        categories: fileCategories,
        candidateFiles: sendout.candidate.files,
        jobOrderFiles: sendout.joborder.files,
        interviewFiles: flattenedInterviewFiles ?? []
      };

      const filesByCategory = matchFilesWithCategories(allFiles);

      const fileValues = {};
      Object.keys(filesByCategory).forEach(categoryId => {
        fileValues[`${FileKeyPrefix}${categoryId}`] = filesByCategory[categoryId];
      });

      setGroupedFiles(filesByCategory);
      reset({ ...defaultValues, ...fileValues, hiringAuthority: sendout.hiringAuthority });
    };
    sendout && fileCategories?.length > 0 && matchFiles();
  }, [sendout, fileCategories, reset]);

  const handleSplitsChange = useCallback((newSplits: Split[]) => setSplits(newSplits), []);

  const handleSendoutChange = (name, value) => {
    setSelectedSendout(value);
    reset(defaultValues);
  };

  const handleAgreementChange = agreement =>
    agreement && setSelectedAgreement(buildAgreementFile(agreement));

  const handleComposeReferenceRelease = () =>
    dispatch(
      showReferenceReleaseComposer({
        candidateId: candidate?.id,
        onReferenceReleaseComplete: async () => {
          setUiState(prevState => ({ ...prevState, isLoading: true }));
          await refreshFileCategories();
          await refreshReferenceReleases();
          setUiState(prevState => ({ ...prevState, isLoading: false }));
        }
      })
    );
  const handleContinueToForm = () =>
    setUiState(prevState => ({ ...prevState, continueToForm: true }));

  const savePlacement = async placementData => {
    setUiState(prevState => ({ ...prevState, isSaving: true }));
    const result = await createPlacement(placementData);
    setUiState(prevState => ({ ...prevState, isSaving: false }));
    result.alert && result.alert.body && dispatch(showAlert(result.alert));
    result.success && onCompleted(result.data);
  };

  async function onSubmit(formData) {
    dispatch(
      confirm({
        severity: 'warning',
        title: strings.shared.ui.confirm,
        message: strings.placements.confirmations.sendPlacement,
        confirmButtonText: strings.placements.ctas.sendPlacement,
        cancelButtonText: strings.shared.ui.cancel,
        onConfirm: async ok => {
          if (!ok) {
            return;
          }
          const finalSplits = flattenSplits(splits, formData);
          const additionalRecipients =
            formData[FormFieldsMap.AdditionalInvoiceRecipients.key] || [];
          const fixedCopies = companyRecruitersEmails.split(EmailsSeparator);

          const filesWithValuePredicate = ({ id: categoryId }) =>
            formData[`${FileKeyPrefix}${categoryId}`];

          const files = [];
          fileCategories.filter(filesWithValuePredicate).forEach(({ id: categoryId }) => {
            const categoryFiles = formData[`${FileKeyPrefix}${categoryId}`];
            categoryFiles.forEach(eachFile =>
              files.push({
                id: eachFile.id,
                type_id: categoryId,
                entity: eachFile.entity
              })
            );
          });

          const agreementId = formData[FormFieldsMap.FeeAgreement.key]?.id;
          const paymentTermsId = formData[FormFieldsMap.PaymentTerms.key]?.id;

          const {
            [FormFieldsMap.CandidateSourceType.key]: candidateSourceType,
            [FormFieldsMap.JobOrderSourceType.key]: jobOrderSourceType,
            [FormFieldsMap.BillToCompany.key]: billToCompany,
            [FormFieldsMap.HiringAuthority.key]: hiringAuthority,
            [FormFieldsMap.ShouldRequestFeeChange.key]: requestedFeeChange,
            [FormFieldsMap.ShouldRequestGuaranteePeriodChange.key]: requestedGuaranteeDaysChange,
            [FormFieldsMap.ReasonForRequestChange.key]: reasonForChange
          } = formData;

          const finalData = {
            ...formData,
            sendout_id: sendoutId,
            splits: finalSplits,
            files,
            company_fee_agreement_id: agreementId,
            source_type_id: candidateSourceType?.id,
            job_order_source_type_id: jobOrderSourceType?.id,
            payment_terms_id: paymentTermsId,
            fee_amount: Number.parseFloat(formData[FormFieldsMap.FeeAmount.key]),
            fee_percentage:
              formData[FormFieldsMap.PaymentScheme.key] !== PaymentMode.Flat
                ? formData[FormFieldsMap.FeePercentage.key]
                : null,
            additional_invoice_recipients: [...additionalRecipients, ...fixedCopies],
            billingCompanyId: billToCompany?.id,
            hiringAuthorityId: hiringAuthority?.id,
            assignmentDataSheetId: assignmentDataSheet?.id,
            candidateDataSheetId: candidateDataSheet?.id,
            requestedFeeChange,
            requestedGuaranteeDaysChange,
            reasonForChange:
              requestedFeeChange || requestedGuaranteeDaysChange ? reasonForChange : null
          };

          onBeforeSubmit && onBeforeSubmit(finalData);

          await savePlacement(finalData);
        }
      })
    );
    // TODO Delete??
    setUiState(prevState => ({
      ...prevState,
      isSuccess: false,
      isSaving: false
    }));
  }

  const canSendReferenceRelease = canUserEditEntity(currentUser, candidate, {
    includeCollaborator: true
  });
  const referenceReleaseExists =
    referenceReleaseState?.results && referenceReleaseState?.results.length > 0;
  const newestReferenceReleaseEmail = referenceReleaseExists && referenceReleaseState?.results[0];

  const isReferenceReleasedValid = sendout && (referenceReleaseExists || uiState.continueToForm);

  const assignmentDataSheetProgress =
    assignmentDataSheetState?.results && assignmentDataSheetState.results.progress;
  const isAssignmentDataSheetValid =
    assignmentDataSheetProgress &&
    assignmentDataSheetProgress.current === assignmentDataSheetProgress.total;
  const assignmentDataSheet = isAssignmentDataSheetValid && assignmentDataSheetState?.results;

  const { isLoading: isSendoutFetching } = uiState;
  const isLoading =
    sendoutsState.status === UIStatus.Loading ||
    referenceReleaseState.status === UIStatus.Loading ||
    uiState.isLoading;

  const showBottomToolbar = !isSendoutFetching && isReferenceReleasedValid;

  const currentView = determineView({ isLoading, validSendouts });

  return {
    agreementFiles,
    candidateDataSheet,
    canSendReferenceRelease,
    currentView,
    feeAgreements,
    fileCategories,
    fileExplorerSections,
    form,
    groupedFiles,
    handleAgreementChange,
    handleComposeReferenceRelease,
    handleContinueToForm,
    handleSendoutChange,
    handleSplitsChange,
    initialSplits,
    isReferenceReleasedValid,
    newestReferenceReleaseEmail,
    assignmentDataSheet,
    onSubmit,
    selectedAgreement,
    selectedSendout,
    sendoutsForNoHireLetter,
    showBottomToolbar,
    sendout,
    splits,
    uiState,
    validSendouts
  };
};

export default usePlacementCreateManager;
