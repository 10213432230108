// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { DateFormats } from 'UI/constants/defaults';
import { toLocalTime } from 'UI/utils';

import { useStyles } from '../styles';

type ChangeLogHeaderProps = {
  title: string,
  createdBy: string,
  createdAt: string
};

const ChangeLogHeader = ({ title, createdBy, createdAt }: ChangeLogHeaderProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      <div className={classes.rowLabel}>
        <Typography className={clsx(classes.textContainer, classes.textLight)}>by</Typography>
        <Typography className={clsx(classes.textContainer, classes.textBold)}>
          {createdBy}
        </Typography>
        <Typography className={clsx(classes.textContainer, classes.textLight)}>at</Typography>
        <Typography className={clsx(classes.textContainer, classes.textBold)}>
          {toLocalTime(createdAt).format(DateFormats.SimpleDateTime)}
        </Typography>
      </div>
    </div>
  );
};

export default ChangeLogHeader;
