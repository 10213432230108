const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles(theme => ({
  textNoAttachments: {
    fontSize: 18,
    margin: theme.spacing(3, 0)
  },
  textDontUploaded: {
    margin: theme.spacing(1, 0)
  },
  wrapperFileView: {
    width: '100%',
    '& label': {
      marginBottom: 0
    }
  }
}));
