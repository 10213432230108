// @flow
import { EntityRoutes } from 'routes/constants';

export const CustomCandidateRenders = {
  location: value =>
    `${value?.personalInformation?.address?.city?.state?.title}, ${value?.personalInformation?.address?.city?.state?.slug} `
};

export const candidateColumns = [
  {
    name: 'id',
    options: {
      filter: false,
      sort: false,
      print: false,
      display: 'excluded'
    }
  },
  {
    name: 'personalInformation',
    label: 'Full Name',
    options: {
      display: true,
      sort: false,
      renderer: {
        id: 'linkBuilder',
        config: {
          idKey: 'id',
          textKey: 'full_name',
          route: EntityRoutes.CandidateProfile
        }
      }
    }
  },
  {
    name: 'blueSheets',
    label: 'Compensation Range',
    options: {
      display: true,
      sort: false,
      renderer: 'compensationRange'
    }
  },
  {
    name: 'specialty.industry.title',
    label: 'Industry',
    options: {
      display: true,
      sort: false,
      renderer: 'default'
    }
  },
  {
    name: 'location',
    label: 'Location',
    options: {
      display: true,
      sort: false,
      renderer: 'location'
    }
  }
];

export const candidateVirtualProps = [
  {
    propName: 'personalInformation',
    attributes: ['id', 'personalInformation']
  },
  {
    propName: 'call',
    attributes: ['personalInformation']
  },
  {
    propName: 'phoneNumbers',
    attributes: ['phone', 'phones']
  },
  {
    propName: 'location',
    attributes: ['personalInformation']
  }
];
