import { useFetchWithStatusV2 } from 'hooks/fetchWithStatus';
import { Endpoints } from 'UI/constants/endpoints';

import { ENDPOINT_BY_VARIANT_MAP } from './InterviewDetailsDrawer.constants';

export const useInterviewDetailsHook = ({ drawerVariant, interviewId }) => {
  const endpoint = ENDPOINT_BY_VARIANT_MAP[drawerVariant].replace(':id', interviewId);

  const interviewDetailsEndpoint = Endpoints.SendoutInterviewDetails.replace(':id', interviewId);

  const { state: detailsOverviewFetchState, Status: DetailStatus } = useFetchWithStatusV2({
    endpoint: interviewDetailsEndpoint,
    apiVersion: 2
  });

  const { state: formFetchState } = useFetchWithStatusV2({
    endpoint,
    apiVersion: 2
  });

  const existingItemId = formFetchState?.results?.id || null;

  return {
    detailsOverviewFetchState,
    formFetchState,
    existingItemId,
    DetailStatus
  };
};
