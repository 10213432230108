import React from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextBox from 'UI/components/atoms/TextBox';
import { buildBasePropsForField } from 'UI/utils/forms';

import { ScheduleCalls } from '../shared';

import { FormFieldsMap } from './PreparationCalls.constants';
import { useStyles } from './PreparationCalls.styles';

export const PreparationCalls = () => {
  const classes = useStyles();

  const { errors, register } = useFormContext();

  return (
    <div>
      <ScheduleCalls />
      <Typography className={classes.formSectionTitle}>Preparation Insights</Typography>
      <Grid container spacing={2} className={classes.fieldsContainer}>
        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.fieldTitle}>
            Hiring Authority
          </Typography>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.HiringAuthorityInsights.key, errors)}
            label="HA Call Insights"
            inputRef={register}
            width="100%"
            multiline
          />
        </Grid>
        <Grid container item xs={12}>
          <Typography variant="subtitle1" className={classes.fieldTitle}>
            Candidate
          </Typography>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.CandidateInsights.key, errors)}
            label="Candidate Call Insights"
            inputRef={register}
            width="100%"
            multiline
          />
        </Grid>
      </Grid>
    </div>
  );
};
