import Link from '@material-ui/core/Link';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export const Path = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  letterSpacing: '0.5px',
  lineHeight: `${theme.spacing(3.5)}px`,
  marginTop: theme.spacing(3.5),
  textTransform: 'uppercase'
}));

export const Breadcrumb = styled(Link)({
  color: 'inherit',
  '&:hover': {
    textDecoration: 'underline'
  }
});
