import { useCallback, useEffect, useRef, useState } from 'react';

const SCROLL_UP_LIMIT = 500;
const NEAR_TOP_THRESHOLD = 0.15;

export const useScrollToBottom = ({ chat, triggerScrollToBottom, onFetchMoreMessages }) => {
  const [showJumpButton, setShowJumpButton] = useState(false);
  const chatContainerRef = useRef(null);
  const chatEndRef = useRef(null);
  const previousScrollHeightRef = useRef(0);
  const previousScrollOffsetRef = useRef(0);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      previousScrollHeightRef.current = 0;
      previousScrollOffsetRef.current = 0;

      chatEndRef.current.scrollIntoView({ behavior: 'instant' });
    }
  };

  const handleScrollUp = useCallback(() => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      const hasScrolledUp = scrollTop + clientHeight <= scrollHeight - SCROLL_UP_LIMIT;
      setShowJumpButton(hasScrolledUp);

      const nearTop = scrollTop / scrollHeight <= NEAR_TOP_THRESHOLD;

      if (nearTop) {
        previousScrollOffsetRef.current = scrollHeight - scrollTop;
        previousScrollHeightRef.current = scrollHeight;

        onFetchMoreMessages && onFetchMoreMessages();
      }
    }
  }, [onFetchMoreMessages]);

  useEffect(() => {
    scrollToBottom();
  }, [triggerScrollToBottom]);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScrollUp);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener('scroll', handleScrollUp);
      }
    };
  }, [chat, handleScrollUp]);

  useEffect(() => {
    // Effect to maintain scroll position after new messages load
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      const { scrollHeight } = chatContainer;

      // Adjust scroll position to keep the last view point consistent
      const heightDiff = scrollHeight - previousScrollHeightRef.current;
      if (heightDiff > 0) {
        // Restore the previous scroll position using the stored offset
        chatContainer.scrollTop = scrollHeight - previousScrollOffsetRef.current;
      }
    }
  }, [chat]);

  return { chatContainerRef, chatEndRef, showJumpButton, scrollToBottom };
};
