// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { List, ListItem } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { showAlert as showAlertAction } from 'actions/app';
import { globalStyles } from 'GlobalStyles';
import API from 'services/API';
import FPHint from 'UI/components/atoms/FPHint';
import { getErrorMessage } from 'UI/utils';

import { useStyles } from './styles';
import { ListBoxesProps, renderer } from './util';

const ListingSummary = ({
  endpoint,
  queryParams,
  shouldRefresh,
  onDataLoaded,
  grid,
  numberOfColumns,
  showAlert
}: ListBoxesProps) => {
  const [uiState, setUiState] = useState({
    isFirstLoad: true,
    isUpdatingData: false
  });
  const classes = useStyles();
  const [data, setData] = useState({});
  const gridSystem = {
    x2: 'grid-2x',
    x3: 'grid-3x'
  };
  const classWrapper = `${gridSystem[grid]} ${classes.boxWrapper}`;

  const fetchData = useCallback(async () => {
    setUiState(prev => ({ ...prev, isUpdatingData: true }));
    try {
      const service = await API(`${endpoint}?${queryParams}`);
      if (service.data) {
        setData(service.data);
        setUiState(prev => ({ ...prev, isFirstLoad: false, isUpdatingData: false }));
      }
    } catch (e) {
      showAlert({
        severity: 'error',
        title: 'Sendouts',
        body: getErrorMessage(e)
      });
    } finally {
      onDataLoaded && onDataLoaded();
    }
  }, [endpoint, queryParams, onDataLoaded, showAlert]);

  useEffect(() => {
    queryParams && fetchData();
  }, [queryParams, fetchData]);

  useEffect(() => {
    shouldRefresh && fetchData();
  }, [shouldRefresh, fetchData]);

  if (uiState.isFirstLoad)
    return <SkeletonBoxes numberOfColumns={numberOfColumns} classCustom={classWrapper} />;

  if (uiState.isUpdatingData) {
    return (
      <div className={classWrapper}>
        {Array.from(Array(numberOfColumns).keys()).map(i => (
          <List key={i}>
            <Skeleton style={globalStyles.skeletonCardItem} />
            <Skeleton style={globalStyles.skeletonCardItem} />
            <Skeleton style={globalStyles.skeletonCardItem} />
          </List>
        ))}
      </div>
    );
  }

  const MetricsCopy = () => {
    const copy = data.filter(({ key }) => key === 'metrics')[0]?.copy;
    return copy ? (
      <FPHint description={copy} size="md" containerClassName={classes.boxCopyWrapper} />
    ) : null;
  };
  const defaultValueRenderer = renderer.bold;
  const defaultLabelRenderer = renderer.normal;

  return (
    <>
      <MetricsCopy />
      <div className={classWrapper}>
        {data.map(({ key, rows }) => (
          <List dense key={key}>
            {rows.map(({ key: rowKey, label, style, type, value }) => {
              const labelRender = renderer[type] || defaultLabelRenderer;
              const valueRender = style?.formatter
                ? renderer[style.formatter] || defaultValueRenderer
                : defaultValueRenderer;

              return (
                <ListItem key={rowKey}>
                  {labelRender(label, style, classes)}
                  {valueRender(value)}
                </ListItem>
              );
            })}
          </List>
        ))}
      </div>
    </>
  );
};

const SkeletonBoxes = ({ numberOfColumns, classCustom }: any) => {
  return (
    <div className={classCustom}>
      {Array.from(Array(numberOfColumns).keys()).map(i => (
        <div key={i} style={globalStyles.skeletonCardContainer}>
          <Skeleton style={globalStyles.skeletonCardItem} />
          <Skeleton style={globalStyles.skeletonCardItem} />
          <Skeleton style={globalStyles.skeletonCardItem} />
        </div>
      ))}
    </div>
  );
};

ListingSummary.defaultProps = {
  grid: 'x3',
  shouldRefresh: false
};

const mapToDispatchProps = dispatch => {
  return {
    showAlert: alert => dispatch(showAlertAction(alert))
  };
};
const ListingSummaryConnected = connect(null, mapToDispatchProps)(ListingSummary);

export default ListingSummaryConnected;
