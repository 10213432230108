import { EntityRoutes } from 'routes/constants';
import { DateFormats } from 'UI/constants/defaults';

export const commonColumns = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },

  {
    name: 'type',
    label: 'Type',
    options: {
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          idKey: 'typeId',
          labelKey: 'type',
          baseClass: 'chip-sendout-type'
        }
      }
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          idKey: 'statusId',
          labelKey: 'status',
          baseClass: 'chip-sendout-status',
          variant: 'outlined'
        }
      }
    }
  },
  {
    name: 'companyName',
    label: 'Company',
    options: {
      display: true,
      sort: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'companyId',
          textKey: 'companyName'
        }
      }
    }
  },
  {
    name: 'jobOrderTitle',
    label: 'Job Order',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'jobOrderId',
          textKey: 'jobOrderTitle'
        }
      }
    }
  },
  {
    name: 'candidateName',
    label: 'Candidate',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'candidateId',
          textKey: 'candidateName'
        }
      }
    }
  }
];

export const SENDOUTS_COLUMNS = [
  ...commonColumns,
  {
    name: 'feeAmount',
    label: 'Total Fee',
    options: {
      sort: false,
      display: true,
      renderer: 'currency_fixed'
    }
  }
];

export const SENDOVERS_COLUMNS = [
  ...commonColumns,
  {
    name: 'trackingDate',
    label: 'Tracking Date',
    options: {
      sort: true,
      display: true,
      renderer: { id: 'dateBuilder', config: { format: DateFormats.SimpleDateTime } }
    }
  }
];

export const VIRTUAL_PROPS = [
  {
    propName: 'status',
    attributes: ['statusId', 'status']
  },
  {
    propName: 'type',
    attributes: ['typeId', 'type']
  },
  {
    propName: 'companyName',
    attributes: ['companyId', 'companyName']
  },
  {
    propName: 'candidateName',
    attributes: ['candidateId', 'candidateName']
  },
  {
    propName: 'jobOrderTitle',
    attributes: ['jobOrderId', 'jobOrderTitle']
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'id',
  direction: 'asc'
};
