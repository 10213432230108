import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  itemTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 18
  },
  itemSubtitle: {
    color: theme.palette.grey[600],
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular
  },
  itemDescription: {
    color: theme.palette.grey.black,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular
  },
  itemType: {
    color: theme.palette.grey.black,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    paddingLeft: theme.spacing(0.5)
  },
  itemContent: {
    padding: `${theme.spacing(0)} !important`
  },
  itemContainer: {
    borderRadius: 'unset',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    cursor: 'pointer',
    padding: theme.spacing(0, 1),
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  lastActivityText: {
    fontSize: 14
  },
  overlineLabel: {
    lineHeight: 2
  }
}));
