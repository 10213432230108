// @flow
import React from 'react';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { EmptyPlacements, SvgAdd } from 'UI/res';

import { styles } from './styles';

const CreateSendoutCallToAction = ({ onNewSendoutClick }) => (
  <EmptyPlaceholder
    subtitle={strings.placements.sendouts.requirement}
    customEmptyState={<EmptyPlacements style={styles.emptyImage} />}
  >
    <FPActionButton
      text={strings.placements.sendouts.create}
      type="button"
      onClick={onNewSendoutClick}
      icon={SvgAdd}
      iconProps={{ family: 'mini', size: 'sm' }}
    />
  </EmptyPlaceholder>
);

export default CreateSendoutCallToAction;
