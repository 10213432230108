import { userHasRole } from 'services/Authorization';
import strings from 'strings';
import {
  CoachFilterDef,
  RecruiterFilterDef,
  RegionalFilterDef
} from 'UI/components/templates/SideFiltersLayout/filters';
import { Roles } from 'UI/constants/roles';

/**
 * @param {{value: {full_name: string}}} filter
 * @param {function} [formatCallback] - Callback to set option formatting,
 * receives option param depending on filter, must return string
 * @returns {string}
 */
export const getScopeOptions = (filter, formatCallback) => {
  if (!filter || typeof filter?.value?.full_name !== 'string') return;
  const opt = filter.value.full_name;
  // eslint-disable-next-line consistent-return
  return formatCallback ? formatCallback(opt) : opt;
};

/**
 * Sets dashboard scope depending on selected filters
 * @param {Object} [filters] - filters selected in dashboard
 * @returns {Object.<{option1: string, option2: string}>}
 */
export const getScope = filters => {
  let opt = '';
  if (filters?.recruiter?.value) opt = getScopeOptions(filters.recruiter);
  else if (filters?.coach?.value)
    opt = getScopeOptions(filters.coach, option => `${strings.dashboard.shared.team} ${option}`);
  else if (filters?.regional?.value)
    opt = getScopeOptions(
      filters.regional,
      option => `${strings.dashboard.shared.region} ${option}`
    );
  return {
    option1: opt || strings.dashboard.shared.scope1,
    option2: opt || strings.dashboard.shared.scope2
  };
};

export const determineScope = filters => {
  const options = {
    option1: strings.dashboard.shared.scope1,
    option2: strings.dashboard.shared.scope2
  };

  if (!filters.coach && !filters.recruiter && !filters.regional) {
    return options;
  }
  if (filters.recruiter && filters.recruiter?.value) {
    const recruiterScope = filters.recruiter.value.full_name;
    return {
      option1: recruiterScope,
      option2: recruiterScope
    };
  }
  if (filters.coach?.value) {
    const coachScope = `${strings.dashboard.shared.team} ${filters.coach.value.full_name}`;
    return {
      option1: coachScope,
      option2: coachScope
    };
  }

  if (filters.regional?.value) {
    const regionScope = `${strings.dashboard.shared.region} ${filters.regional.value.full_name}`;
    return {
      option1: regionScope,
      option2: regionScope
    };
  }
  return {};
};

export const TeamFilters = ['coach', 'recruiter'];

const buildTeamFilter = (filterDefinition, user) => ({
  [filterDefinition.name]: {
    ...filterDefinition,
    value: { id: user.id, full_name: user.personalInformation.full_name, email: user.email }
  }
});

export const getDefaultFiltersForUser = (user, filters) => {
  const previousTeamFiltersExist = Object.keys(filters).some(filter =>
    TeamFilters.includes(filter)
  );
  if (previousTeamFiltersExist) return null;

  if (userHasRole(Roles.RegionalDirector, user)) return buildTeamFilter(RegionalFilterDef, user);

  if (userHasRole(Roles.Coach, user)) return buildTeamFilter(CoachFilterDef, user);

  if (userHasRole(Roles.Recruiter, user)) return buildTeamFilter(RecruiterFilterDef, user);

  return null;
};
