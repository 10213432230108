// @flow
import React from 'react';
import clsx from 'clsx';
import { useGlobalStyles } from 'GlobalStyles';
import { getColumnsToRender } from 'hooks/datatable';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import { TableToolbar } from 'mui-datatables';
import strings from 'strings';
import { FPCard, FPCardContent, FPCardHeader } from 'UI/components/atoms/FPCard';
import DataTable from 'UI/components/organisms/DataTable';
import type { NodeSelection } from 'UI/components/organisms/PilIndustries/pilIndustries.types';
import PilBreadcrumb from 'UI/components/organisms/PilInformation/PilBreadcrumb';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { UIStatus } from 'UI/constants/status';
import { useFixedDatatableStyles, useTableCard } from 'UI/globalStyles/DataTableStyles';

import { useStyles } from './styles';

type TeamsTableProps = {
  columnOrder: Array<number>,
  columns: Array<object>,
  count: number,
  data: any,
  direction: string,
  isLoading: boolean,
  keyword: string,
  onColumnDisplayChange: () => void,
  onColumnOrderChange: () => void,
  onColumnSortChange: () => void,
  onFiltersToggle: () => void,
  onKeywordChange: () => void,
  onPageChange: () => void,
  onPerPageChange: () => void,
  orderBy: string,
  page: number,
  perPage: number,
  selectedNode: NodeSelection | null,
  showWholeSkeleton: boolean
};

const TeamsTable = ({
  columnOrder,
  columns,
  count,
  data,
  direction,
  isLoading,
  keyword,
  onColumnDisplayChange,
  onColumnOrderChange,
  onColumnSortChange,
  onFiltersToggle,
  onKeywordChange,
  onPageChange,
  onPerPageChange,
  orderBy,
  page,
  perPage,
  selectedNode,
  showWholeSkeleton
}: TeamsTableProps) => {
  const { informationEndpoint = null, type, title = '' } = selectedNode ?? {};
  const fixedTableClasses = useFixedDatatableStyles();
  const tableClasses = useTableCard();
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const finalColumns = getColumnsToRender(columns);

  const {
    state: { status, results: pilResults }
  } = useFetchWithStatus(informationEndpoint, null, UIStatus.Default);

  const customTableToolbar = props => (
    <TableToolbar
      classes={{
        root: clsx(tableClasses.toolbar, globalClasses.tableToolbarCard),
        actions: tableClasses.actions
      }}
      {...props}
    />
  );

  return (
    <FPCard aria-label="Teams Table" className={classes.cardContainer}>
      <FPCardHeader
        title={title || strings.teams.title}
        subheader={
          <PilBreadcrumb
            type={type}
            isLoading={status === UIStatus.Loading}
            pilResults={pilResults}
          />
        }
      />

      <FPCardContent variant="tight">
        {title ? (
          <div className={classes.teamTableContainer}>
            <DataTable
              classes={{ responsiveBase: 'responsiveBase' }}
              className={clsx(tableClasses.tableRoot, fixedTableClasses.fixedList)}
              columnOrder={columnOrder?.length ? columnOrder : undefined}
              columns={finalColumns}
              components={{ TableToolbar: customTableToolbar }}
              count={count || 0}
              data={data}
              elevation={0}
              enableCustomFilters
              filter={false}
              loading={showWholeSkeleton}
              onColumnDisplayClick={onColumnDisplayChange}
              onColumnOrderChange={onColumnOrderChange}
              onColumnSortChange={onColumnSortChange}
              onPageClick={onPageChange}
              onPerPageClick={onPerPageChange}
              onSearchTextChange={onKeywordChange}
              onToggleFilters={onFiltersToggle}
              page={page}
              refreshing={isLoading}
              role="table"
              rowsPerPage={perPage}
              searchText={keyword}
              selectableRows="none"
              selectToolbarPlacement="none"
              sortOrder={{ name: orderBy, direction }}
            />
          </div>
        ) : (
          <EmptyPlaceholder subtitle={strings.teams.instructions} />
        )}
      </FPCardContent>
    </FPCard>
  );
};

export default TeamsTable;
