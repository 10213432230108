// @flow
import React from 'react';
import { Grid, GridProps } from '@material-ui/core';

import { gridConfig, GridItemBox, GridLayout, GridOnDrawer } from './style';

type GridItemProps = GridProps & {
  editionDrawer: boolean
};

export const GridFormContainer = (props: GridProps) => <Grid container spacing={4} {...props} />;
export const ItemCreationGridLayout = (props: GridProps) => (
  <GridLayout>
    <GridFormContainer {...props} />
  </GridLayout>
);

export const GridItem = ({ editionDrawer, ...rest }: GridItemProps) =>
  editionDrawer ? (
    <GridOnDrawer item {...gridConfig.editionDrawer} {...rest} />
  ) : (
    <GridItemBox item {...gridConfig.itemCreation} {...rest} />
  );
