// @flow
import React, { useCallback, useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SchemaStatuses as REFERENCE_STATUSES } from 'constants/schemaStatuses';
import { UserProviderContext } from 'providers/UserProvider';
import strings from 'strings';
import { When } from 'UI/components/atoms/When';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import {
  useReferenceCheck,
  useReferenceCheckTable
} from 'UI/components/organisms/inventoryProfiles/ReferenceCheckTabLayout/hooks';
import ReferenceCheckDrawer from 'UI/components/organisms/inventoryProfiles/ReferenceCheckTabLayout/ReferenceCheckDrawer';
import { DrawerMode } from 'UI/components/organisms/inventoryProfiles/ReferenceCheckTabLayout/ReferenceCheckTabLayout.constants';
import { UIStatus } from 'UI/constants/status';

import PlacementReferenceChecksList from './PlacementReferenceChecksList';

type PlacementReferenceChecksProps = {
  candidateId: number,
  candidateName: string,
  isReadOnly: boolean,
  preSelectedReferences: array,
  placementStatusId: number
};

const PlacementReferenceChecks = ({
  candidateId,
  candidateName,
  isReadOnly = false,
  preSelectedReferences,
  placementStatusId
}: PlacementReferenceChecksProps) => {
  const [uiState, dispatch] = useReferenceCheck();
  const [currentUser] = useContext(UserProviderContext);
  const {
    tableActions,
    activeItem,
    toggleDataRefresh,
    referencesState: referencesResult
  } = useReferenceCheckTable({
    candidateId,
    currentUser,
    additionalRecruiters: [],
    onEditItemClick: dispatch.onEditItemClick,
    onViewItemClick: dispatch.onViewItemClick,
    shouldFetch: true
  });
  const references = referencesResult?.results?.data || [];
  const completedReferences = references.filter(
    ({ progress }) => progress.key === REFERENCE_STATUSES.Completed
  );
  const shoudShowReferencesOnReadOnlyView =
    isReadOnly && preSelectedReferences?.length > 0 && completedReferences.length > 0;

  const referencesToLoad = shoudShowReferencesOnReadOnlyView
    ? completedReferences.filter(({ id }) => {
        const isReferenceSelected = preSelectedReferences && preSelectedReferences.includes(id);
        return isReferenceSelected;
      })
    : completedReferences;

  const viewAction = tableActions.find(({ name }) => name === DrawerMode.View);

  const handleOnSuccess = useCallback(() => {
    dispatch.onSaveSuccess(toggleDataRefresh(true));
  }, [dispatch, toggleDataRefresh]);

  return (
    <When condition={!isReadOnly || shoudShowReferencesOnReadOnlyView}>
      <NumberedForm.Item title={strings.placements.sections.referenceChecks.title}>
        {referencesResult?.status === UIStatus.Loading ? (
          <CircularProgress size={24} />
        ) : (
          <PlacementReferenceChecksList
            isReadOnly={isReadOnly}
            references={referencesToLoad}
            onViewClick={viewAction.callback}
            preSelectedReferences={preSelectedReferences}
            placementStatusId={placementStatusId}
          />
        )}
      </NumberedForm.Item>

      <ReferenceCheckDrawer
        mode={uiState.mode}
        referenceId={activeItem.id}
        isOpen={uiState.isDrawerOpen}
        candidateId={candidateId}
        candidateName={candidateName}
        status={activeItem.status}
        isSaving={uiState.isSaving}
        isSaved={uiState.isSaved}
        onSave={dispatch.onSave}
        onSaveSuccess={handleOnSuccess}
        onSaveError={dispatch.onSaveError}
        onClose={dispatch.closeDrawer}
      />
    </When>
  );
};

PlacementReferenceChecks.defaultProps = {
  preSelectedReferences: []
};

export default PlacementReferenceChecks;
