// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { currencyFormatter } from 'UI/utils';

import { useStyles } from '../styles';
import { calculateComissions } from '../utils';

import LabelValue from './LabelValue';

const DefaultNumberOfDecimals = 2;

type Split = {
  recruiter: any, // Main recruiter of the split
  channelPartners: any[], // Channel Partners of this recruiter
  percent: number // Percent of the split
};

type SplitsProps = {
  splits: Split[],
  invoiceAmount: number // Amount shown in invoice for this split side
};

const Splits = ({ splits, invoiceAmount }: SplitsProps) => {
  const classes = useStyles();
  return splits.map(({ recruiter, channelPartners, percent }, index) => {
    const comissions = calculateComissions(percent, invoiceAmount, channelPartners);

    return (
      <div key={recruiter.id} className={classes.splitContainer}>
        <Grid container spacing={1}>
          <LabelValue
            label={`Recruiter${splits.length > 1 ? `(${index + 1})` : ''}`}
            value={`${recruiter?.personalInformation?.full_name} ${
              recruiter?.initials ? `(${recruiter.initials})` : ''
            }`}
          />
          <LabelValue label="Email" value={recruiter?.email} />
          <LabelValue label="Split Percent" value={`${percent}%`} />
          <LabelValue
            label="Split Amount"
            value={currencyFormatter(comissions.forRecruiter, DefaultNumberOfDecimals)}
          />
        </Grid>
        {channelPartners?.length > 0 && (
          <>
            {channelPartners.map(channelPartner => (
              <Grid key={channelPartner.id} container spacing={1} className={classes.withIndent}>
                <LabelValue
                  label="Channel Partner"
                  value={`${channelPartner.recruiter?.personalInformation?.full_name} ${
                    channelPartner.recruiter?.initials
                      ? `(${channelPartner.recruiter.initials})`
                      : ''
                  }`}
                />
                <LabelValue
                  label="Split Amount"
                  value={currencyFormatter(
                    (comissions.whole * channelPartner.percent) / 100,
                    DefaultNumberOfDecimals
                  )}
                />
              </Grid>
            ))}
          </>
        )}
      </div>
    );
  });
};

export default Splits;
