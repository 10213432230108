// @flow

import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { useFetchWithStatusV2 } from 'hooks/fetchWithStatus';
import moment from 'moment';
import { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { DateFormats } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EmptyPlacements } from 'UI/res';
import { currencyFormatter } from 'UI/utils';

export type PlacementPaymentsFormProps = {
  id: number
};

const PlacementPaymentsV2 = ({ id }: PlacementPaymentsFormProps) => {
  const { Status } = useFetchWithStatusV2({
    endpoint: `${Endpoints.PlacementPaymentsV2}?placementId=${id}`,
    apiInstance: SECONDARY_API_CLIENT
  });

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        {strings.placements.payments.history}
      </Typography>
      <Status
        loading={
          <List dense>
            <LinearProgress />
          </List>
        }
        empty={
          <Typography paragraph gutterBottom>
            {strings.placements.payments.empty}
          </Typography>
        }
        error={error => (
          <EmptyPlaceholder
            title={strings.shared.ui.error}
            subtitle={error}
            customEmptyState={<EmptyPlacements />}
          />
        )}
        success={results => (
          <List dense>
            {results.map(({ id: innerId, amount, created_at: createdAt, status, tranid }) => (
              <ListItem key={innerId} disableGutters>
                <ListItemText
                  primary={`${tranid} · ${currencyFormatter(amount, 1)}USD`}
                  secondary={`${moment
                    .utc(createdAt)
                    .format(DateFormats.SimpleDateTime)} · ${status}`}
                />
              </ListItem>
            ))}
          </List>
        )}
      />
    </>
  );
};

export default PlacementPaymentsV2;
