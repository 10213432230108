import GpacGeneratorForm from '@gogpac/form-generator';
import { styled } from '@material-ui/core';

export const StyledForm = styled(GpacGeneratorForm)({
  '& > div > div > div > div:not(:first-of-type)': {
    marginTop: 'unset !important'
  },
  '& .MuiGrid-item': {
    padding: 0
  },
  '& .MuiTypography-body2': {
    fontWeight: '400 !important'
  },
  '& .MuiTypography-h5': {
    fontWeight: '600 !important'
  }
});
