import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  text: {
    fontWeight: '700 !important'
  },
  statsWrapper: {
    padding: theme.spacing(1, 0)
  },
  topText: {
    paddingTop: theme.spacing(1)
  }
}));
