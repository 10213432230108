import * as React from 'react';

const SvgCommonFileTextAdd = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      fill={fill}
      {...props}
    >
      <g fill={fill}>
        <path d="M20 7.5a1 1 0 00-1-1h-7a1 1 0 000 2h7a1 1 0 001-1zM12.5 11a1 1 0 000 2h4a1 1 0 000-2z" />
        <path d="M23.41 3L21 .59A2 2 0 0019.59 0H8a2 2 0 00-2 2v7.28a.26.26 0 00.24.25c.37 0 1 0 1.49.08A.25.25 0 008 9.36V2.5a.5.5 0 01.5-.5h10.88a.47.47 0 01.35.15l2.12 2.12a.47.47 0 01.15.35V18a.5.5 0 01-.5.5h-6.86a.25.25 0 00-.24.21 8.9 8.9 0 01-.4 1.46.24.24 0 00.23.33H22a2 2 0 002-2V4.41A2 2 0 0023.41 3z" />
        <path d="M6.5 11a6.5 6.5 0 106.5 6.5A6.51 6.51 0 006.5 11zM9 18.25H7.5a.25.25 0 00-.25.25V20a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25H4a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25H9a.75.75 0 010 1.5z" />
      </g>
    </svg>
  );
};

export default SvgCommonFileTextAdd;
