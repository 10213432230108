import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'UI/res';

const resposiveActionsHeight = 65;

const fontSize = 14;

export const useStyles = makeStyles(theme => ({
  main: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    flexGrow: 1,
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.brandColors.FPWhite
    },
    '& > div:first-child': {
      padding: theme.spacing(4, 2, 0, 2)
    }
  },
  dateColor: {
    color: colors.success
  },
  content: {
    fontSize,
    fontWeight: 300,
    paddingLeft: theme.spacing(2),
    '& > figure > img': {
      maxWidth: '100%'
    }
  },
  actionsResponsive: {
    height: resposiveActionsHeight,
    backgroundColor: colors.sideBar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 20px 0 0',
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%'
  },
  separation: {
    margin: theme.spacing(2, 2, 3, 2)
  },
  buttonRow: {
    padding: theme.spacing(0.5, 0)
  }
}));

const titleWidth = 180;

export const usePreviewBulkMetaRowsStyles = makeStyles(theme => ({
  previewBulkRow: {
    padding: theme.spacing(1)
  },
  title: {
    fontSize,
    minWidth: titleWidth,
    width: titleWidth,
    fontWeight: 500
  },
  renderValue: {
    fontSize
  }
}));

export const useClickableStyles = makeStyles(theme => ({
  clickable: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginRight: theme.spacing(0.5),
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));
