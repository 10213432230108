import strings from 'strings';

import { PlacementsDrawerContent } from './PlacementsDrawerContent';

const { fallOffs, placements } = strings.commandCenter.drawers;

export const PlacementsConfig = {
  title: placements.title,
  component: PlacementsDrawerContent
};

export const FallOffsConfig = {
  title: fallOffs.title,
  component: PlacementsDrawerContent
};
