import { useEffect } from 'react';
import moment from 'moment';

const startWorkingHours = moment(
  window.GPAC_ENV?.START_WORKING_HOURS || process.env.REACT_APP_START_WORKING_HOURS || '7:00am',
  'h:mma'
);

const endWorkingHours = moment(
  window.GPAC_ENV?.END_WORKING_HOURS || process.env.REACT_APP_END_WORKING_HOURS || '9:00pm',
  'h:mma'
);

const milisecondsToRefresh =
  window.GPAC_ENV?.SESSION_REFRESH_FREQUENCY ||
  process.env.REACT_APP_SESSION_REFRESH_FREQUENCY ||
  900000; // 15 min

const useKeepLogRocketSessionAlive = domElement => {
  useEffect(() => {
    let time;

    const triggerClickEvent = () => {
      const currentTime = moment();
      const areWorkHours =
        currentTime.isAfter(startWorkingHours) && currentTime.isBefore(endWorkingHours);
      if (areWorkHours && domElement !== null) {
        domElement.current.click();
      }
    };
    const resetTimer = () => {
      clearTimeout(time);
      time = setTimeout(triggerClickEvent, milisecondsToRefresh);
    };

    window.addEventListener('load', resetTimer);
    document.body.addEventListener('keypress', resetTimer);
    document.body.addEventListener('click', resetTimer);

    return () => {
      window.removeEventListener('load', resetTimer);
      document.body.removeEventListener('keypress', resetTimer);
      document.body.removeEventListener('click', resetTimer);
    };
  }, [domElement]);
};

export default useKeepLogRocketSessionAlive;
