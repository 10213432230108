import * as React from "react";

const SvgFileDoc = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M6.45 13.33c-.1-.05-.33-.05-.33.16v4c0 .2.23.25.33.21a2.39 2.39 0 001.43-2.2 2.36 2.36 0 00-1.43-2.17zM17.5 6h3.9a.25.25 0 00.17-.43L16.43.42A.26.26 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM12 13.12a.89.89 0 00-.88.88v3a.88.88 0 001.76 0v-3a.89.89 0 00-.88-.88z" />
      <path d="M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM5.5 19.12a.62.62 0 01-.62-.62v-6a.63.63 0 01.62-.63 3.63 3.63 0 010 7.25zM14.12 17a2.12 2.12 0 01-4.24 0v-3a2.12 2.12 0 114.24 0zm4.38.87a.63.63 0 010 1.25 3.63 3.63 0 010-7.25.63.63 0 010 1.25 2.38 2.38 0 100 4.75z" />
    </svg>
  );
};

export default SvgFileDoc;
