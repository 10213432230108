// @flow
import React from 'react';
import useDatatable, {
  buildRowActionsRendererDefinition,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import { rowClickBuilder } from 'hooks/profileActions';
import strings from 'strings';
import DataTable from 'UI/components/organisms/DataTable';
import { SvgNoNotes } from 'UI/res';

import { notesColumns } from './columns';
import { useStyles } from './styles';

const ORDER_BY_OPTIONS = {
  column: 'id',
  direction: 'desc'
};

const CONFIG = {
  PAGE_KEY: 'feedBackNotes',
  NOTES_ELEVATION: 0,
  NOTES_EMPTY_STATE_SIZE: 350
};

const FeedbackNotes = ({ endpoint, onClickPreview }) => {
  const classes = useStyles();

  const initialPreferences = getColumnPreferences(
    CONFIG.PAGE_KEY,
    0,
    ORDER_BY_OPTIONS,
    notesColumns
  );

  const {
    columnPreferences,
    count,
    data: tabData,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersToggle,
    handleKeywordChange,
    handlePageChange,
    handlePerPageChange,
    listState
  } = useDatatable({
    initialPreferences,
    columnsDefinitions: notesColumns,
    columnKey: CONFIG.PAGE_KEY,
    endpoint
  });

  const { columns, orderBy, direction } = columnPreferences;
  const { isLoading, showWholeSkeleton, page, perPage, keyword } = listState;

  const previewActions = [
    {
      name: 'view',
      onClick: rowClickBuilder({ columns, data: tabData, callback: onClickPreview })
    }
  ];

  const actions = buildRowActionsRendererDefinition({
    actions: previewActions
  });

  const finalColumns = getColumnsToRender([...columns, actions]);

  return (
    <div className={classes.accordionContainer}>
      <DataTable
        columns={finalColumns}
        count={count}
        data={tabData}
        download={false}
        elevation={CONFIG.NOTES_ELEVATION}
        filter={false}
        loading={showWholeSkeleton}
        onColumnDisplayClick={handleColumnDisplayChange}
        onColumnSortChange={handleColumnSortChange}
        onPageClick={handlePageChange}
        onPerPageClick={handlePerPageChange}
        onSearchTextChange={handleKeywordChange}
        onToggleFilters={handleFiltersToggle}
        onColumnOrderChange={handleColumnOrderChange}
        page={page}
        print={false}
        refreshing={isLoading}
        responsive="standard"
        rowsPerPage={perPage}
        searchText={keyword}
        selectableRows="none"
        selectToolbarPlacement="none"
        sortOrder={{ name: orderBy, direction }}
        customEmptyState={<SvgNoNotes size={CONFIG.NOTES_EMPTY_STATE_SIZE} />}
        title={strings.recruiterProfile.emptyStateNotes}
        draggableColumns={{
          enabled: true
        }}
      />
    </div>
  );
};

export default FeedbackNotes;
