// @flow
import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { confirm, showAlert } from 'actions/app';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import groupBy from 'lodash/groupBy';
import API from 'services/API';
import strings from 'strings';
import type { FileManagerProps } from 'types/app';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import DecisionDialog from 'UI/components/organisms/DecisionDialog';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { Endpoints } from 'UI/constants/endpoints';
import { UIStatus } from 'UI/constants/status';
import { formatBytes, getErrorMessage } from 'UI/utils';

import { CategoryCard } from './CategoryCard';
import { AttachmentsByCategory, File } from './fileManager.types';
import {
  hasReachedMaxFiles,
  moveFileIntoSelectedCategory,
  updateCategoryByEndpoint
} from './fileManager.utils';
import { useStyles } from './styles';

const FileManager = ({
  apiVersion,
  apiVersionForDeleting,
  defaultCategories,
  FileDraggableProps,
  fileNameField,
  filesEndpoint,
  initialFiles,
  layoutInColumns,
  module,
  onAttachmentsChanged,
  readOnly,
  showFileSize,
  showItemDate,
  showItemMenu,
  viewItemOnClick
}: FileManagerProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [attachments, setAttachments] = useState<AttachmentsByCategory[]>([]);
  const [categorySelected, setCategorySelected] = useState<AttachmentsByCategory | null>(null);
  const [categorySource, setCategorySource] = useState<AttachmentsByCategory | null>(null);
  const [fileSelected, setFileSelected] = useState<File>(undefined);
  const [uiState, setUiState] = useState({
    isFetching: false,
    isOpen: false
  });

  const { state: categoryResponse } = useFetchWithStatus(
    `${Endpoints.FileCategories}?module=${module}&sort=order:asc`
  );

  const { state: attachmentResponse } = useFetchWithStatus(
    !defaultCategories ? `${filesEndpoint}?direction=desc&includes=fileType,vCreatedBy` : null,
    initialFiles,
    UIStatus.Default,
    apiVersion
  );

  useEffect(() => {
    setUiState(prev => ({ ...prev, isFetching: true }));
    if (categoryResponse.status === UIStatus.Error || attachmentResponse.status === UIStatus.Error)
      return;
    if (
      categoryResponse.status === UIStatus.Loading ||
      attachmentResponse.status === UIStatus.Loading
    )
      return;

    const categoryResp = defaultCategories
      ? categoryResponse.results.filter(category =>
          defaultCategories.find(({ id }) => category.id === id)
        )
      : categoryResponse.results;

    const categories = categoryResp.map(
      ({ id, max_files: maxFiles, multiple, required, title }) => ({
        files: [],
        id,
        maxFiles,
        multiple,
        required,
        title
      })
    );

    const files = attachmentResponse.results.map(file => ({
      ...file,
      isLoading: false,
      message: '',
      type: 'existing'
    }));

    const filesByCategory = groupBy(files, 'fileType.id');

    const newAttachments = categories.map(category => ({
      ...category,
      files: filesByCategory[category.id] || []
    }));

    setAttachments(newAttachments);
    setUiState(prev => ({ ...prev, isFetching: false }));
  }, [attachmentResponse, categoryResponse, defaultCategories]);

  useEffect(() => {
    onAttachmentsChanged && onAttachmentsChanged(attachments);
  }, [attachments, onAttachmentsChanged]);

  const showError = error => {
    dispatch(
      showAlert({
        severity: 'error',
        title: error.response.data?.title ?? strings.fileManager.error.title,
        body: getErrorMessage(error, strings.shared.errors.serverError.subtitle)
      })
    );
  };

  const updateFileLoadingStatus = ({ fileId, loading, message = '' }) => {
    setAttachments(prev =>
      prev.map(category => ({
        ...category,
        files: category.files.map(file =>
          file.id === fileId ? { ...file, isLoading: loading, message } : file
        )
      }))
    );
  };

  const handleDragEnd = async result => {
    if (
      !result.destination ||
      result.source.droppableId === result.destination.droppableId ||
      readOnly
    )
      return;

    const { draggableId, destination, source } = result;
    const fileId = parseInt(draggableId, 10);
    const sourceId = parseInt(source.droppableId, 10);
    const sourceIndex = source.index;
    const destinationId = parseInt(destination.droppableId, 10);
    const destinationIndex = destination.index;

    if (hasReachedMaxFiles({ attachments, destinationId })) {
      dispatch(
        showAlert({
          severity: 'warning',
          title: strings.fileManager.validQuantity.title,
          body: strings.fileManager.validQuantity.message
        })
      );
      return;
    }

    const previousAttachments = attachments;
    const filesMoved = moveFileIntoSelectedCategory({
      attachments,
      fileId,
      sourceId,
      sourceIndex,
      destinationId,
      destinationIndex
    });
    setAttachments(filesMoved);

    updateCategoryByEndpoint({ endpoint: filesEndpoint, fileId, newCategoryId: destinationId })
      .then(() => {
        updateFileLoadingStatus({ fileId, loading: false });
      })
      .catch(error => {
        showError(error);
        setAttachments(previousAttachments);
        updateFileLoadingStatus({ fileId, loading: false });
      });
  };

  const handleFileMove = (category: number) => async (file: File) => {
    if (!category || !file) return;

    setCategorySource(category);
    setFileSelected(file);
    setUiState(prev => ({ ...prev, isOpen: true }));
  };

  const handleSelect = (name?: string, value: any) => setCategorySelected(value);

  const handleConfirm = () => {
    const result = {
      destination: {
        droppableId: categorySelected.id.toString(),
        index: 0
      },
      draggableId: fileSelected.id.toString(),

      source: {
        index: categorySource.files.findIndex(item => item.id === fileSelected.id),
        droppableId: categorySource.id.toString()
      }
    };
    handleCloseConfirm();

    handleDragEnd(result);
  };

  const handleCloseConfirm = () => {
    setUiState(prev => ({ ...prev, isOpen: false }));
    setCategorySelected(null);
    setCategorySource(null);
    setFileSelected(null);
  };

  const handleFileDelete = categorySourceId => async (file: File) => {
    if (!file) return;

    const fileName = file[fileNameField] || file.original_name || file.file_name;
    dispatch(
      confirm({
        severity: 'warning',
        title: strings.fileManager.deleteFile.title,
        message: strings.formatString(strings.fileManager.deleteFile.message, {
          fileName
        }),
        onConfirm: async ok => {
          try {
            if (!ok) return;

            updateFileLoadingStatus({
              fileId: file.id,
              loading: true,
              message: 'Deleting file...'
            });

            await deleteRemoteFile(file, categorySourceId);
          } catch (err) {
            showError(err);
          }
        }
      })
    );
  };

  const deleteRemoteFile = async (attachment: Attachment, categorySourceId: number) => {
    try {
      if (!attachment.id) return;

      const finalEndpoint = `${filesEndpoint}/${attachment.id}`;
      const response = await API.delete(finalEndpoint, { apiVersion: apiVersionForDeleting });
      if (response.status === 200) {
        dispatch(
          showAlert({
            severity: 'success',
            title: strings.fileManager.deleteFile.successTitle,
            body: strings.formatString(strings.fileManager.deleteFile.successMessage, {
              fileName: attachment[fileNameField]
            })
          })
        );

        const newAttachments = attachments.map(category =>
          category.id === categorySourceId
            ? {
                ...category,
                files:
                  category.files.length === 1
                    ? []
                    : category.files.filter(file => file.id !== attachment.id)
              }
            : category
        );

        setAttachments(newAttachments);
      }
    } catch (err) {
      showError(err);
      updateFileLoadingStatus({
        fileId: attachment.id,
        loading: false
      });
    }
  };

  const handleUploadFiles = async (files: Array<Files>, categoryId: number) =>
    Promise.all(await files.map(async file => uploadFile(file, categoryId)));

  const uploadFile = async (file, categoryId) => {
    const data = new FormData();
    data.append('file', file);
    data.append('file_type_id', categoryId);

    const fileInfo = {
      id: 0,
      [fileNameField]: file.name,
      isLoading: true,
      size: file.size,
      message: strings.formatString(strings.fileManager.dropzone.uploading, {
        fileSize: formatBytes(file.size)
      }),
      fileType: {
        id: categoryId
      }
    };

    setAttachments(prev =>
      prev.map(category =>
        category.id === categoryId
          ? {
              ...category,
              files: [...category.files, fileInfo]
            }
          : category
      )
    );

    try {
      const uploadResponse = await API.post(filesEndpoint, data);

      uploadResponse.status === 201 && processUploadResponse(uploadResponse, categoryId);
    } catch (error) {
      showError(error);
      setAttachments(prev =>
        prev.map(category =>
          category.id === categoryId
            ? {
                ...category,
                files: category.files.filter(att => att[fileNameField] !== fileInfo[fileNameField])
              }
            : category
        )
      );
    }
  };

  const processUploadResponse = (uploadResponse, categoryId) => {
    const { data } = uploadResponse;

    setAttachments(prev =>
      prev.map(category =>
        category.id === categoryId
          ? {
              ...category,
              files: category.files.map(file =>
                file[fileNameField] === data[fileNameField]
                  ? {
                      ...data,
                      isLoading: false,
                      message: '',
                      fileType: {
                        id: categoryId
                      }
                    }
                  : file
              )
            }
          : category
      )
    );
  };

  if (uiState.isFetching) {
    return (
      <div className={classes.emptyStateContainer}>
        <EmptyPlaceholder {...strings.shared.loading} />
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {!readOnly && (
        <div className={classes.marginBottom}>
          <Typography variant="body2">* {strings.fileManager.fileName.message}</Typography>
        </div>
      )}
      <div className={layoutInColumns ? classes.columnContainer : classes.rowContainer}>
        <DragDropContext onDragEnd={handleDragEnd}>
          {attachments.map(category => (
            <CategoryCard
              key={category.id}
              attachments={attachments}
              category={category}
              FileDraggableProps={FileDraggableProps}
              fileNameField={fileNameField}
              layoutInColumns={layoutInColumns}
              onFileDelete={handleFileDelete(category.id)}
              onFileMove={handleFileMove(category)}
              onUploadFiles={handleUploadFiles}
              readOnly={readOnly}
              showFileSize={showFileSize}
              showItemDate={showItemDate}
              showItemMenu={showItemMenu}
              viewItemOnClick={viewItemOnClick}
            />
          ))}
        </DragDropContext>
      </div>

      {uiState.isOpen && (
        <DecisionDialog
          isOpened={uiState.isOpen}
          title={strings.fileManager.moveFileConfirm.title}
          message={strings.formatString(strings.fileManager.moveFileConfirm.message, {
            fileName: fileSelected[fileNameField]
          })}
          withButtons="Confirmation"
          confirmButtonText="Move File"
          showSeverityIcon={false}
          onConfirm={handleConfirm}
          severity="info"
          disableConfirmButton={!categorySelected}
          onClose={handleCloseConfirm}
          onError={showError}
        >
          <AutocompleteSelect
            name="category"
            placeholder="Select section"
            options={attachments}
            selectedValue={categorySelected}
            onSelect={handleSelect}
            width="90%"
          />
        </DecisionDialog>
      )}
    </>
  );
};

FileManager.defaultProps = {
  apiVersion: 1,
  apiVersionForDeleting: 2,
  defaultCategories: null,
  FileDraggableProps: {},
  fileNameField: 'file_name',
  initialFiles: [],
  isNewEntity: false,
  layoutInColumns: false,
  onAttachmentsChanged: null,
  readOnly: false,
  showItemDate: false,
  showItemMenu: false,
  viewItemOnClick: false
};

export default FileManager;
