import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

import { PERFORMANCE_INDICATOR_BACKGROUND_COLORS, PERFORMANCE_INDICATOR_COLORS } from './utils';

export const useStyles = makeStyles(theme => ({
  percentageContainer: {
    backgroundColor: PERFORMANCE_INDICATOR_BACKGROUND_COLORS.default,
    borderRadius: 4,
    height: 22,
    ...flexAlignCenterJustifyCenter
  },
  percentageLabel: {
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: '400 !important'
  },
  phonePerformanceBarContainer: {
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.spacing(0.5)
  },
  progressBarContainer: {
    width: 65
  },
  progressLabel: {
    color: theme.palette.grey[800],
    fontSize: 10,
    fontWeight: '400 !important',
    opacity: 0.6
  },
  progressLabelContainer: {
    marginLeft: theme.spacing(1)
  },
  low: {
    backgroundColor: PERFORMANCE_INDICATOR_COLORS.low
  },
  regular: {
    backgroundColor: PERFORMANCE_INDICATOR_COLORS.regular
  },
  high: {
    backgroundColor: PERFORMANCE_INDICATOR_COLORS.high
  }
}));

export const usePhoneStatsStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(1),
    width: '100%'
  },
  content: {
    marginBottom: theme.spacing(1.5)
  },
  progressBar: {
    paddingRight: ({ isSmallViewPort }) => isSmallViewPort && theme.spacing(2.5)
  },
  progressBarContainer: {
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.spacing(0.5)
  },
  progressLabel: {
    color: theme.palette.secondary.FP700,
    fontSize: 10,
    fontWeight: '500 !important',
    marginLeft: ({ isSmallViewPort }) => !isSmallViewPort && theme.spacing(2.5)
  },
  title: {
    color: theme.palette.grey[800],
    fontSize: 10,
    fontWeight: '500 !important',
    letterSpacing: '1.5px',
    opacity: '0.60',
    lineHeight: '16px',
    textTransform: 'uppercase'
  }
}));
