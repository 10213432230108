import * as React from 'react';

const SvgDontContact = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M12 0a12 12 0 1012 12A12 12 0 0012 0zM5.29 5.29a9.63 9.63 0 0112.23-1 .26.26 0 010 .4L4.67 17.56a.27.27 0 01-.4 0 9.49 9.49 0 011-12.24zm13.46 13.47a9.53 9.53 0 01-12.23 1 .26.26 0 010-.4L19.37 6.49a.26.26 0 01.4 0 9.49 9.49 0 01-1 12.24z" />
    </svg>
  );
};
export default SvgDontContact;
