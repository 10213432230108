// @flow
import React from 'react';
import { DominateSheetLayout } from 'features/command-center';
import usePageTitle from 'hooks/usePageTitle';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import strings from 'strings';
import { PageTitles } from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';

import { FollowUpTasks } from './FollowUpTasks';
import { HotInventory } from './HotInventory';
import { Interviews } from './Interviews';
import { OutstandingBillings } from './OutstandingBillings';

const BASE_TABS = [
  {
    id: 'hotInventory',
    label: strings.commandCenter.dominateSheet.mainTabs.hotInventory,
    view: <HotInventory />
  },
  {
    id: 'interviews',
    label: strings.commandCenter.dominateSheet.mainTabs.interviews,
    view: <Interviews />
  },
  {
    id: 'outstandingBillings',
    label: strings.commandCenter.dominateSheet.mainTabs.outstandingBillings,
    view: <OutstandingBillings />
  }
];

export const DominateSheet = () => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const TABS = checkIfFeatureFlagEnabled(FeatureFlags.FollowUpTasks)
    ? [
        ...BASE_TABS,
        {
          id: 'followUpTasks',
          label: strings.commandCenter.dominateSheet.mainTabs.followUpTasks,
          view: <FollowUpTasks />
        }
      ]
    : BASE_TABS;

  usePageTitle({ title: PageTitles.CommandCenterDominateSheet });
  return <DominateSheetLayout tabs={TABS} />;
};
