// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { confirm, showAlert } from 'actions/app';
import { addHiringAuthority } from 'services/Candidates';
import strings from 'strings';
import type { DrawerUiState } from 'types/app';
import FPHint from 'UI/components/atoms/FPHint';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import PhoneNumbersForm from 'UI/components/organisms/PhoneNumbersForm';
import {
  DEFAULT_PHONE_ROW,
  formatPhoneNumbers,
  getPhonesSortedByDefaultPhone,
  PHONE_FORMAT_MODES
} from 'UI/components/organisms/PhoneNumbersForm/utils';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { minWidthTextBox } from 'UI/constants/dimensions';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import {
  getFeatureFlags,
  idOptionSelected,
  industrySpecialtyOptionLabel,
  PHONE_VALIDATION,
  REQUIRED_VALIDATION,
  titleOptionLabel,
  URL_VALIDATION,
  VALIDATION_REGEXS
} from 'UI/utils';
import { OptionRenderers, Selectors } from 'UI/utils/renderers';

import { useStyles } from '../../styles';

import { fieldsConfig } from './fieldsConfig';

type AddHiringAuthorityProps = {
  candidateId: number,
  candidate: object,
  onClose: () => any,
  onCompleted: () => any
};

const chainedSelects = {
  specialty_id: ['subspecialty_id', 'position_id']
};

const checkIfDuplicateEmail = (email, newEmail) => email === newEmail;

const AddHiringAuthorityForm = ({ candidate, onClose, onCompleted }: AddHiringAuthorityProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [uiState, setUiState] = useState<DrawerUiState>({
    isLoading: false,
    isSaving: false,
    isSuccess: false,
    navStack: []
  });

  const isMultiplePhonesEnabled = getFeatureFlags().includes(
    FeatureFlags.HiringAuthoritiesMultiplePhones
  );

  const shouldDisplayLegacyPhoneFields = !isMultiplePhonesEnabled;

  const currentCompany = candidate.company_id
    ? { id: Number(candidate.company_id), name: candidate.current_company }
    : null;

  const defaultValues = {
    candidateId: candidate?.id,
    company_id: currentCompany?.id,
    first_name: candidate?.personalInformation?.first_name,
    last_name: candidate?.personalInformation?.last_name,
    nickname: candidate?.personalInformation?.nickname,
    specialty_id: candidate?.specialty?.id,
    subspecialty_id: candidate?.subspecialty?.id,
    position_id: candidate?.position?.id,
    title: candidate?.title,
    work_email: candidate?.email,
    personal_email: candidate?.personalInformation?.contact?.personal_email,
    link_profile: candidate?.link_profile,
    personal_phone: candidate?.personalInformation?.contact?.mobile,
    other_ext: candidate?.personalInformation?.contact?.other_ext,
    work_phone: candidate?.personalInformation?.contact?.phone,
    ext: candidate?.personalInformation?.contact?.ext,
    phones: getPhonesSortedByDefaultPhone(candidate, EntityType.Candidate)
  };

  const initialComboValues = {
    company_id: currentCompany,
    specialty_id: candidate.specialty,
    subspecialty_id: candidate.subspecialty,
    position_id: candidate.position
  };

  const form = useForm({ defaultValues });
  const { handleSubmit, watch, register, unregister, setValue, errors } = form;
  const formValues = watch();
  const [comboValues, setComboValues] = useState<Map>(initialComboValues);
  const [hasSubspecialties, setHasSubspecialties] = useState(false);

  useEffect(() => {
    fieldsConfig.forEach(({ name, type, validation }) => {
      register({ name, type }, validation);
    });
    isMultiplePhonesEnabled &&
      register({
        name: 'phones',
        value: [DEFAULT_PHONE_ROW]
      });

    if (!isMultiplePhonesEnabled) {
      register(
        { name: 'work_phone' },
        {
          ...REQUIRED_VALIDATION,
          ...PHONE_VALIDATION
        }
      );
      register(
        { name: 'personal_phone' },
        {
          ...PHONE_VALIDATION
        }
      );
    }

    return () => unregister(fieldsConfig.map(({ name }) => name));
  }, [isMultiplePhonesEnabled, register, unregister]);

  useEffect(() => {
    const hasInitialSubspecialtyValue = comboValues?.subspecialty_id;
    if (hasInitialSubspecialtyValue) {
      register({ name: 'subspecialty_id' });
      setValue('subspecialty_id', comboValues.subspecialty_id?.id);
    } else {
      register(
        { name: 'subspecialty_id' },
        hasSubspecialties ? { required: 'Subspecialty is required' } : null
      );
    }
  }, [register, setValue, hasSubspecialties, comboValues]);

  const handleValueChange = (name: string, value: string) => setValue(name, value, true);

  const handleComboChange = (name?: string, value: any) => {
    setComboValues((prevState: Map): Map => ({ ...prevState, [name]: value }));
    setValue(name, value ? value.id : value, true);

    if (name && chainedSelects[name]) {
      chainedSelects[name].forEach(chainedSelect => {
        setComboValues((prevState: Map): Map => ({ ...prevState, [chainedSelect]: null }));
        setValue(chainedSelect, null);
        if (name === 'specialty_id' && value === null) setHasSubspecialties(false);
      });
    }
  };

  const handleSubspecialtiesLoaded = useCallback((options?: any[]) => {
    setHasSubspecialties(options && options.length > 0);
  }, []);

  const createHiringAuthority = async (formData = null) => {
    setUiState(prevState => ({ ...prevState, isSaving: true }));

    const result = await addHiringAuthority(formData.company_id, formData, {
      successTitle: 'Awesome',
      successBody: 'The hiring authority was added successfully'
    });

    setUiState(prevState => ({ ...prevState, isSaving: false }));
    result.alert && result.alert.body && dispatch(showAlert(result.alert));
    result.success && onCompleted && onCompleted(result.data);
    result.success && onClose && onClose();
  };

  const onSubmit = async (formData: any) => {
    const candidateName = candidate?.personalInformation?.full_name;
    const email = candidate?.email;
    const hasDuplicatedEmail = checkIfDuplicateEmail(email, formData.work_email);

    const data = {
      candidateId: candidate.id,
      ...formData,
      phones: formatPhoneNumbers(formData, PHONE_FORMAT_MODES.submit)
    };

    if (isMultiplePhonesEnabled) data.work_phone = formData.phone;

    if (hasDuplicatedEmail) {
      dispatch(
        confirm({
          severity: 'warning',
          title: strings.inventoryProfiles.sections.candidates.duplicatedCandidateDialog.title,
          message: strings.formatString(
            strings.inventoryProfiles.sections.candidates.duplicatedCandidateDialog.message,
            {
              candidateName,
              email
            }
          ),
          confirmButtonText: 'Continue',
          cancelButtonText: 'Keep Editing',
          onConfirm: async ok => {
            if (!ok) return;
            await createHiringAuthority(data);
          }
        })
      );
    } else {
      await createHiringAuthority(data);
    }
  };

  return (
    <DrawerContentLayout
      title="New Hiring Authority"
      variant="borderless"
      uiState={uiState}
      isTopToolbarNeeded
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      onSecondaryButtonClick={onClose}
    >
      <>
        {candidate && (
          <FormContext {...form}>
            <div className={classes.hiringAuthorityFormContainer}>
              <AutocompleteSelect
                name="company_id"
                selectedValue={comboValues?.company_id}
                displayKey="name"
                placeholder="Company *"
                getOptionSelected={Selectors.byId}
                error={!!errors.company_id}
                errorText={errors.company_id && errors.company_id.message}
                typeahead
                typeaheadLimit={50}
                typeaheadParams={{ entityType: 'company', isForConvertion: true }}
                url={Endpoints.Search}
                renderOption={OptionRenderers.globalSearchDefault('name')}
                onSelect={handleComboChange}
              />
              <TextBox
                name="first_name"
                label="First Name *"
                value={formValues.first_name || ''}
                onChange={handleValueChange}
                error={!!errors.first_name}
                errorText={errors?.first_name?.message}
                minWidth={minWidthTextBox}
              />
              <TextBox
                name="last_name"
                label="Last Name *"
                value={formValues.last_name || ''}
                onChange={handleValueChange}
                error={!!errors.last_name}
                errorText={errors?.last_name?.message}
                minWidth={minWidthTextBox}
              />
              <TextBox
                name="nickname"
                label="Nickname"
                value={formValues.nickname || ''}
                onChange={handleValueChange}
                error={!!errors.nickname}
                errorText={errors?.nickname?.message}
                minWidth={minWidthTextBox}
              />
              <AutocompleteSelect
                name="specialty_id"
                selectedValue={comboValues?.specialty_id}
                placeholder="Industry: Specialty *"
                error={!!errors.specialty_id}
                errorText={errors.specialty_id && errors.specialty_id.message}
                url={Endpoints.Specialties}
                groupBy={option => option.industry_title}
                getOptionLabel={industrySpecialtyOptionLabel}
                renderOption={titleOptionLabel}
                getOptionSelected={idOptionSelected}
                onSelect={handleComboChange}
              />
              <AutocompleteSelect
                name="subspecialty_id"
                selectedValue={comboValues?.subspecialty_id}
                placeholder={`Subspecialty ${hasSubspecialties ? '*' : ''}`}
                error={!!errors.subspecialty_id}
                errorText={errors.subspecialty_id && errors.subspecialty_id.message}
                url={
                  comboValues.specialty_id
                    ? `${Endpoints.Specialties}/${comboValues.specialty_id.id}/subspecialties`
                    : ''
                }
                onSelect={handleComboChange}
                onOptionsLoaded={handleSubspecialtiesLoaded}
              />
              <AutocompleteSelect
                name="position_id"
                selectedValue={comboValues?.position_id}
                placeholder="Functional title *"
                error={!!errors.position_id}
                errorText={errors.position_id && errors.position_id.message}
                url={
                  comboValues.specialty_id
                    ? `${Endpoints.Positions}?specialtyId=${comboValues.specialty_id.id}`
                    : ''
                }
                onSelect={handleComboChange}
              />
              <TextBox
                name="title"
                label="Title *"
                value={formValues.title || ''}
                onChange={handleValueChange}
                error={!!errors.title}
                errorText={errors.title && errors.title.message}
                minWidth={minWidthTextBox}
              />
              <div>
                <TextBox
                  name="work_email"
                  label="Email *"
                  value={formValues.work_email || ''}
                  onChange={handleValueChange}
                  error={!!errors.work_email}
                  errorText={errors.work_email && errors.work_email.message}
                  minWidth={minWidthTextBox}
                />
                <FPHint
                  description={strings.inventoryProfiles.sections.candidates.duplicatedEmailHint}
                  containerClassName={classes.tightMargin}
                />
              </div>
              <TextBox
                name="personal_email"
                label="Other Email"
                value={formValues.personal_email || ''}
                onChange={handleValueChange}
                error={!!errors.personal_email}
                errorText={errors.personal_email && errors.personal_email.message}
                minWidth={minWidthTextBox}
                inputRef={register({
                  pattern: {
                    value: VALIDATION_REGEXS.EMAIL,
                    message: 'Email must be valid'
                  }
                })}
              />
              {shouldDisplayLegacyPhoneFields && (
                <>
                  <TextBox
                    name="work_phone"
                    label="Phone *"
                    placeholder="(999)-999-9999"
                    value={formValues.work_phone || ''}
                    onChange={handleValueChange}
                    error={!!errors.work_phone}
                    errorText={errors.work_phone && errors.work_phone.message}
                    minWidth={minWidthTextBox}
                    inputType="phone"
                  />
                  <TextBox
                    name="ext"
                    label="Ext"
                    placeholder="999"
                    value={formValues.ext || ''}
                    onChange={handleValueChange}
                    error={!!errors.ext}
                    errorText={errors.ext && errors.ext.message}
                    minWidth={minWidthTextBox}
                  />
                  <TextBox
                    name="personal_phone"
                    label="Other Phone"
                    placeholder="(999)-999-9999"
                    value={formValues.personal_phone || ''}
                    onChange={handleValueChange}
                    error={!!errors.personal_phone}
                    errorText={errors.personal_phone && errors.personal_phone.message}
                    minWidth={minWidthTextBox}
                    inputType="phone"
                  />
                  <TextBox
                    name="other_ext"
                    label="Ext"
                    placeholder="999"
                    value={formValues.other_ext || ''}
                    onChange={handleValueChange}
                    error={!!errors.other_ext}
                    errorText={errors?.other_ext?.message}
                    minWidth={minWidthTextBox}
                  />
                </>
              )}
              <TextBox
                name="link_profile"
                label="LinkedIn"
                value={formValues.link_profile || ''}
                onChange={handleValueChange}
                error={!!errors.link_profile}
                errorText={errors?.link_profile?.message}
                minWidth={minWidthTextBox}
                inputRef={register(URL_VALIDATION)}
              />
            </div>
            {isMultiplePhonesEnabled && <PhoneNumbersForm isEditing isRequired />}
          </FormContext>
        )}
      </>
    </DrawerContentLayout>
  );
};

AddHiringAuthorityForm.defaultProps = {
  candidate: null
};

export default AddHiringAuthorityForm;
