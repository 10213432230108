// @flow
import React from 'react';
import Button from '@material-ui/core/Button';
import ChevronRightRounded from '@material-ui/icons/ChevronRightRounded';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { OptionsVariantsKeys } from 'UI/components/organisms/InterviewDetailsDrawer/InterviewDetailsDrawer.constants';
import { colors } from 'UI/res';

import { useStyles } from './OptionButton.styles';

interface OptionButtonProps {
  variant: 'interviewDetails' | 'followUp';
  option: {
    id: string,
    title: string,
    onClick: () => void
  };
}

export const OptionButton = ({ variant, option }: OptionButtonProps) => {
  const classes = useStyles();
  if (variant === OptionsVariantsKeys.FollowUp) {
    return (
      <FPActionButton
        text={option.title}
        variant="outlined"
        type="button"
        onClick={option.onClick}
        endIcon={<ChevronRightRounded width={20} height={20} fill={colors.blueStatus} />}
        className={classes.followUpBtn}
        data-testid="followUp-action"
      />
    );
  }
  return (
    <Button
      color="primary"
      onClick={option.onClick}
      className={classes.interviewDetailsBtn}
      data-testid="interviewDetails-action"
    >
      {option.title}
    </Button>
  );
};
