// @flow
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import {
  getAllSearchProjectsFolders,
  restoreSearchProjectFolder
} from 'services/searchProjects/Folders';
import strings from 'strings';
import type { SearchProjectRestoreDrawerPropTypes } from 'types/app';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import TreeViewSelector from 'UI/components/molecules/TreeViewSelector/TreeViewSelector';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { SEARCH_PROJECT_ROOT_FOLDERS } from 'UI/constants/defaults';

const { title, sections: sectionsCopies } = strings.searchProjects.forms.restoreFolder;

const FORM_FIELDS_MAP = {
  folder: {
    key: 'folder'
  },
  parentFolder: {
    key: 'parentFolder'
  }
};

const DEFAULT_EXCLUDED_IDS = ['MyCollaborations', 'AllSearchProjects', 'Archived'];

const RestoreSearchProjectFolderDrawer = ({
  folderToRestore,
  onClose,
  onRestoreFolder
}: SearchProjectRestoreDrawerPropTypes) => {
  const dispatch = useDispatch();
  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false
  });

  const form = useForm({
    defaultValues: {
      [FORM_FIELDS_MAP.folder.key]: folderToRestore
    }
  });
  const { handleSubmit, register } = form;

  useEffect(() => {
    register({ name: FORM_FIELDS_MAP.folder.key });
  }, [register]);

  const onSubmit = handleSubmit(async formData => {
    setUiState({ isSaving: true, isSuccess: false });
    const folder = formData[FORM_FIELDS_MAP.folder.key];
    const folderId = folder.id;
    const parentFolder = formData[FORM_FIELDS_MAP.parentFolder.key];
    const { success, alert } = await restoreSearchProjectFolder({
      folderId,
      parentId: parentFolder.id
    });
    dispatch(showAlert(alert));

    if (success) {
      setUiState({ isSaving: false, isSuccess: true });
      onRestoreFolder &&
        onRestoreFolder({
          folderId,
          parentFoldersId: { previous: folder.parent_id, current: parentFolder.id }
        });
      onClose();
    } else {
      setUiState({ isSaving: false, isSuccess: false });
    }
  });

  return (
    <DrawerContentLayout
      drawerProps={{ open: true }}
      title={title}
      onClose={onClose}
      onSubmit={onSubmit}
      uiState={uiState}
    >
      <FormContext {...form}>
        <NumberedForm>
          <NumberedForm.Item title={sectionsCopies.parentFolder.title}>
            <TreeViewSelector
              formFieldName={FORM_FIELDS_MAP.parentFolder.key}
              shouldRegisterWithin
              defaultValue={SEARCH_PROJECT_ROOT_FOLDERS.mine}
              placeholder={sectionsCopies.parentFolder.placeholder}
              TreeViewLoaderProps={{
                enableIconClick: true,
                onlyShowFolders: true,
                service: {
                  method: getAllSearchProjectsFolders,
                  queryParams: { excludeIds: DEFAULT_EXCLUDED_IDS }
                }
              }}
            />
          </NumberedForm.Item>
        </NumberedForm>
      </FormContext>
    </DrawerContentLayout>
  );
};

export default RestoreSearchProjectFolderDrawer;
