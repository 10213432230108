import { EntityRoutes } from 'routes/constants';

export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'recruiters',
    label: 'Recruiters',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'full_name',
    label: 'Candidate',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'id',
          textKey: 'full_name',
          enableInTabRedirect: true
        }
      }
    }
  },
  // TODO: add candidate phone columns
  {
    name: 'hiring_authority',
    label: 'Hiring Authority',
    options: {
      sort: true,
      display: true,
      renderer: 'contactLink'
    }
  },
  // TODO: add HA phone columns

  {
    name: 'title',
    label: 'Job Order',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'id',
          textKey: 'title',
          enableInTabRedirect: true
        }
      }
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'id',
  direction: 'desc'
};

export const VIRTUAL_PROPS = [
  {
    propName: 'full_name',
    attributes: ['id', 'full_name']
  },
  {
    propName: 'phoneNumbers',
    attributes: ['phone', 'phones']
  }
];
