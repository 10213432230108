// @flow
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { restoreTemplate, restoreTemplateFolder } from 'services/bulkEmail';
import TextBox from 'UI/components/atoms/TextBox';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import TemplateDropDown from 'UI/components/molecules/TemplateDropDown';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { MY_TEMPLATES_FOLDER_ID, TEMPLATE_ENTITY_TYPES } from 'UI/constants/defaults';
import { capitalizeFirstLetter } from 'UI/utils';

const FORM_FIELDS_MAP = {
  itemName: {
    key: 'itemName'
  },
  parentFolder: {
    key: 'parentFolder'
  }
};

type TemplateRestoreFormPropTypes = {
  itemToRestore: Object,
  onClose: () => void,
  onRestore: () => void
};

const TemplateRestoreForm = ({
  itemToRestore,
  onClose,
  onRestore
}: TemplateRestoreFormPropTypes) => {
  const capitalizedEntity = capitalizeFirstLetter(itemToRestore.entity);

  const dispatch = useDispatch();
  const form = useForm({
    defaultValues: {
      [FORM_FIELDS_MAP.itemName.key]: itemToRestore?.name
    }
  });
  const { handleSubmit, register, watch } = form;
  const formFieldsWatcher = watch();
  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false
  });

  useEffect(() => {
    register({ name: FORM_FIELDS_MAP.itemName.key });
  }, [register]);

  const onSubmit = async formFields => {
    setUiState({ isSaving: true, isSuccess: false });
    const itemId = itemToRestore.id;
    const destinationFolderId = formFields[FORM_FIELDS_MAP.parentFolder.key].id;

    const serviceToExecute =
      itemToRestore.entity === TEMPLATE_ENTITY_TYPES.Folder
        ? restoreTemplateFolder
        : restoreTemplate;
    const { success, alert } = await serviceToExecute(itemId, destinationFolderId);
    dispatch(showAlert(alert));

    if (success) {
      setUiState({ isSaving: false, isSuccess: true });
      onRestore({ previousFolderId: itemToRestore.parentFolder.id, destinationFolderId });
      onClose();
    } else setUiState({ isSaving: false, isSuccess: false });
  };

  return (
    <DrawerContentLayout
      title={`Restore ${capitalizedEntity}`}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      uiState={uiState}
      primaryProps={{ text: 'Restore' }}
      drawerProps={{
        open: true
      }}
    >
      <FormContext {...form}>
        <NumberedForm>
          <NumberedForm.Item title={`${capitalizedEntity} Name`}>
            <TextBox
              name={FORM_FIELDS_MAP.itemName.key}
              width="100%"
              value={formFieldsWatcher[FORM_FIELDS_MAP.itemName.key]}
              disabled
            />
          </NumberedForm.Item>
          <NumberedForm.Item title="Directory Folder">
            <TemplateDropDown
              preselectedFolderId={MY_TEMPLATES_FOLDER_ID}
              formFieldName={FORM_FIELDS_MAP.parentFolder.key}
              placeholder="Choose folder"
              onlyShowFolders
              enableIconClick
              requiredMessage="Directory Folder is Required"
              shouldRegisterWithin
            />
          </NumberedForm.Item>
        </NumberedForm>
      </FormContext>
    </DrawerContentLayout>
  );
};

export default TemplateRestoreForm;
