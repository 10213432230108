import React, { useCallback, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import { Widget } from 'features/command-center';
import { METRICS_MAP } from 'features/command-center/constants';
import { useDrawerManager } from 'features/command-center/contexts';
import { getFinanceHealthDistribution } from 'features/command-center/services';
import strings from 'strings';
import FPIcon from 'UI/components/atoms/FPIcon';
import { SvgInformationCircle } from 'UI/res';

import { FooterItems, FooterTitle, FooterWrapper } from './HealthDistribution.styled';
import { createDrawerHandlers, renderContent } from './HealthDistribution.utils';

const { onTrack, behind, atRisk } = strings.commandCenter.healthDistributionInfo;

export const Footer = () => {
  return (
    <FooterWrapper>
      <FooterTitle>
        <FPIcon
          role="img"
          aria-label="Info"
          component={SvgInformationCircle}
          onSetColor={palette => palette.info.main}
          size={18}
        />
        <Typography className="label">Health Status:</Typography>
      </FooterTitle>
      <FooterItems>
        <li>
          <Typography className="label">
            <strong>{atRisk.label}:</strong> {atRisk.value}
          </Typography>
        </li>
        <li>
          <Typography className="label">
            <strong>{behind.label}:</strong> {behind.value}
          </Typography>
        </li>
        <li>
          <Typography className="label">
            <strong>{onTrack.label}:</strong> {onTrack.value}
          </Typography>
        </li>
      </FooterItems>
    </FooterWrapper>
  );
};

export const HealthDistribution = ({ requestParams, hasDrillDown }) => {
  const drawerManager = useDrawerManager();

  const render = useCallback(
    ([{ data }]) => {
      const metricsData = hasDrillDown ? data.data : data;
      if (!hasDrillDown) return renderContent({ data: metricsData });

      const drawerHandlers = createDrawerHandlers(drawerManager.openDrawer, METRICS_MAP);
      return renderContent({ data: metricsData, drawerHandlers });
    },
    [drawerManager, hasDrillDown]
  );

  const cachedPromises = useMemo(
    () => [() => getFinanceHealthDistribution(requestParams)],
    [requestParams]
  );

  return (
    <Widget
      promises={cachedPromises}
      header={strings.commandCenter.finance.healthDistribution.title}
      subheader={strings.commandCenter.finance.healthDistribution.subtitle}
      footer={<Footer />}
    >
      {render}
    </Widget>
  );
};
