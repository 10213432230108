import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useChat } from 'features/message-center/hooks';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { UIStatus } from 'UI/constants/status';

import { formatInternationalPhone } from './chat.utils';
import { useStyles } from './ChatDrawer.styles';
import { Chat, MessageComposer } from './components';

export const ChatDrawerContent = ({
  isDrawerOpen,
  closeDrawer,
  recipient,
  recipientPhoneWithCode
}) => {
  const classes = useStyles();

  const {
    chat,
    chatStatus,
    handleClickRetry,
    handleClickSend,
    handleFetchMoreMessages,
    handleRefreshConversation,
    hasMoreMessages,
    triggerScrollToBottom
  } = useChat({
    externalNumber: recipientPhoneWithCode
  });

  const title = recipient?.name ?? recipient?.personalInformation?.full_name ?? '';
  const countryCode = recipient?.countryCode ?? '+1';
  const subheader = recipient?.phone
    ? formatInternationalPhone(`${countryCode}${recipient.phone}`)
    : '';

  return (
    <DrawerContentLayout
      title={title}
      subheader={subheader}
      additionalHeaderButtons={
        <FPIconButton
          onClick={handleRefreshConversation}
          tooltipProps={{
            title: strings.messageCenter.chat.refresh
          }}
        >
          <RefreshIcon />
        </FPIconButton>
      }
      CustomFooter={
        <MessageComposer disabled={chatStatus !== UIStatus.Success} onClickSend={handleClickSend} />
      }
      customDrawerToolbarClass={classes.customDrawerToolbar}
      drawerProps={{
        open: isDrawerOpen
      }}
      contentProps={{ className: classes.drawerContent }}
      onClose={closeDrawer}
    >
      <Chat
        chat={chat}
        chatStatus={chatStatus}
        hasMoreMessages={hasMoreMessages}
        onClickRetry={handleClickRetry}
        onFetchMoreMessages={handleFetchMoreMessages}
        triggerScrollToBottom={triggerScrollToBottom}
      />
    </DrawerContentLayout>
  );
};
