import { makeStyles } from '@material-ui/core';
import { layout } from 'UI/constants/dimensions';

export const useStyles = makeStyles(theme => ({
  searchBtnContainer: {
    display: 'flex',
    paddingTop: theme.spacing(2.5),
    justifyContent: 'center'
  },
  searchBtn: {
    zIndex: theme.zIndex.searchOnThisAreaBtn
  },
  navigationControl: {
    right: theme.spacing(3),
    bottom: theme.spacing(4.5),
    position: 'fixed'
  },
  mapContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    transition: 'left 0.5s cubic-bezier(.79,.14,.15,.86) 0s'
  },
  filtersClosed: {
    left: 0
  },
  filtersOpen: {
    left: layout.sideFiltersWidth
  }
}));
