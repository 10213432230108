// @flow
import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import TextBox from 'UI/components/atoms/TextBox';
import { UIStatus } from 'UI/constants/status';

import { useStyles } from './styles';

type InlineEditFieldProps = {
  itemId: number,
  label: string,
  name: string,
  defaultValue: string | number,
  onCancel: (id: number) => void,
  onUpdate: (id: number, value: any) => void,
  updateAction: (id: number, data: any) => void
};

const InlineEditField = ({
  itemId,
  name,
  label,
  defaultValue,
  onCancel,
  onUpdate,
  updateAction
}: InlineEditFieldProps) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);
  const [status, setStatus] = useState(UIStatus.Default);

  const update = async () => {
    if (!updateAction || !value) return;

    setStatus(UIStatus.Loading);
    const result = await updateAction(itemId, { [name]: value });
    setStatus(UIStatus.Default);
    result.success && onUpdate(itemId, value);
  };

  const handleInputChange = (_: string, newValue: any) => {
    setStatus(newValue ? UIStatus.Default : UIStatus.Error);
    setValue(newValue);
  };

  const handleCancel = () => onCancel(itemId);

  const handleKeyDown = async e => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      if (e.key === 'Enter') {
        await update();
      } else {
        onCancel(itemId);
      }
    }
  };

  const isLoading = status === UIStatus.Loading;
  return (
    <div className={classes.container}>
      <TextBox
        name={name}
        label={label}
        value={value}
        onChange={handleInputChange}
        autoFocus
        onKeyDown={handleKeyDown}
        disabled={isLoading}
        error={status === UIStatus.Error}
      />
      <div className={classes.buttonsContainer}>
        <FPIconButton onClick={update} disabled={isLoading}>
          {isLoading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <CheckCircleOutlinedIcon color="primary" />
          )}
        </FPIconButton>
        <FPIconButton onClick={handleCancel} disabled={isLoading}>
          <UndoOutlinedIcon />
        </FPIconButton>
      </div>
    </div>
  );
};

InlineEditField.defaultProps = {};

export default InlineEditField;
