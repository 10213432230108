// @flow

import { useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

/**
 * This hook replace
 * @param {string} template text that will be replaced by values
 * @param {object | null} tags key -> value object to manage the values of smartags, if your pass nothing, will be replaced by
 * @param {(string) => void} callback return method with the new template
 * current tag values
 */
export const useSmartTag = (template: string, tags: Object, callback: template => any) => {
  const [internalState, setInternalState] = useState({
    currentTags: { ...tags } || {},
    currentTemplate: template || '',
    baseTags: tags
  });

  const setTagsOnTemplate = useCallback((tagsToChange, shouldValidate) => {
    setInternalState(prev => {
      if (!prev.currentTags || !tagsToChange) return prev;

      if (shouldValidate) {
        const currentTagsBuffer = {};
        Object.entries(tagsToChange).entries(([key]) => {
          currentTagsBuffer[key] = prev.currentTags[key];
        });
        if (isEqual(currentTagsBuffer, tagsToChange)) return prev;
      }

      let replacedTemplate = prev.currentTemplate;
      const replacedTags = { ...prev.currentTags };
      const tagsToUse = tagsToChange || replacedTags;
      Object.entries(prev.currentTags).forEach(([key]) => {
        if (!tagsToUse[key]) return;
        replacedTags[key] = tagsToUse[key];

        if (!prev.currentTemplate || typeof prev.currentTemplate !== 'string') return;
        replacedTemplate = replacedTemplate
          .replaceAll(prev.currentTags[key], tagsToUse[key])
          .replaceAll(prev.baseTags[key], tagsToUse[key]);
      });
      return {
        ...prev,
        currentTags: tagsToChange ? replacedTags : prev.currentTags,
        currentTemplate: replacedTemplate
      };
    });
  }, []);

  const refreshTags = useCallback(() => {
    setInternalState(prev => {
      if (!prev.currentTags) return prev;

      let replacedTemplate = prev.currentTemplate;
      Object.entries(prev.currentTags).forEach(([key, value]) => {
        if (!prev.currentTemplate || typeof prev.currentTemplate !== 'string') return;
        replacedTemplate = replacedTemplate
          .replaceAll(prev.currentTags[key], value)
          .replaceAll(prev.baseTags[key], value);
      });
      return {
        ...prev,
        currentTemplate:
          replacedTemplate === prev.currentTemplate ? prev.currentTemplate : replacedTemplate
      };
    });
  }, []);

  const setNewTemplate = useCallback(newTemplate => {
    setInternalState(prev => ({
      ...prev,
      currentTemplate: newTemplate
    }));
  }, []);

  useEffect(() => {
    setInternalState(prev => ({
      ...prev,
      currentTags: tags,
      baseTags: tags
    }));
  }, [tags]);

  useEffect(() => {
    setInternalState(prev => ({ ...prev, currentTemplate: template }));
  }, [template]);

  useEffect(() => {
    callback && callback(internalState.currentTemplate);
  }, [callback, internalState.currentTemplate]);

  return {
    setTags: setTagsOnTemplate,
    refreshTags,
    setTemplate: setNewTemplate,
    currentTags: internalState.currentTags,
    currentTemplate: internalState.currentTemplate
  };
};
