// @flow
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import { showAlert as showAlertAction } from 'actions/app';
import API from 'services/API';
import strings from 'strings';
import JobOrderForm from 'UI/components/organisms/JobOrderForm';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { Endpoints } from 'UI/constants/endpoints';
import { getErrorMessage } from 'UI/utils';
import { prepareFormToSubmit } from 'UI/utils/forms';

import { FormFieldsMap } from '../JobOrderForm/fields';

type JobOrderEditProps = {
  joborder: any,
  onEditCompleted: (profile: any) => void,
  onEditClosed: () => void,
  showAlert: any => void
};

const JobOrderEdit = (props: JobOrderEditProps) => {
  const { joborder, onEditCompleted, onEditClosed, showAlert } = props;
  const {
    id,
    title,
    source,
    sourceType,
    different_location,
    address,
    company,
    specialty,
    subspecialty,
    position
  } = joborder;
  const zip = address?.zip;
  const city = address?.city;
  const state = address?.city?.state;

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false,
    isFormDisabled: false,
    isReadOnly: false
  });

  const form = useForm(
    joborder
      ? {
          defaultValues: {
            id,
            title,
            source,
            source_type: sourceType,
            company,
            specialty,
            subspecialty,
            functionalTitle: position,
            different_location,
            state_id: state,
            city_id: city,
            zip: { id: zip, title: zip }
          }
        }
      : {}
  );

  const onSubmit = async formData => {
    try {
      setUiState(prevState => ({ ...prevState, isSaving: true }));
      const prepareJobOrder = prepareFormToSubmit(formData, FormFieldsMap);
      const response = await API.put(`${Endpoints.JobOrders}/${id}`, prepareJobOrder);
      if (response.data && response.status === 201) {
        showAlert({
          severity: 'success',
          title: strings.inventoryProfiles.sections.jobOrders.updateProfile
        });

        setUiState(prevState => ({
          ...prevState,
          isSaving: false,
          isSuccess: true
        }));

        const updatedProfile = response.data;
        onEditCompleted(updatedProfile);
      }
    } catch (err) {
      setUiState(prevState => ({
        ...prevState,
        isSaving: false,
        isSuccess: false
      }));
      showAlert({
        severity: 'error',
        title: 'Job Order',
        body: getErrorMessage(err)
      });
    }
  };

  return (
    <FormContext {...form}>
      <DrawerContentLayout
        title="Editing Job Order"
        onSubmit={form.handleSubmit(onSubmit)}
        onClose={onEditClosed}
        uiState={uiState}
      >
        <Box>
          <JobOrderForm editionDrawer />
        </Box>
      </DrawerContentLayout>
    </FormContext>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    showAlert: alert => dispatch(showAlertAction(alert))
  };
};

const JobOrderEditConnected = connect(null, mapDispatchToProps)(JobOrderEdit);

export default JobOrderEditConnected;
