import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import strings from 'strings';
import FPIcon from 'UI/components/atoms/FPIcon';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { When } from 'UI/components/atoms/When';
import FPQuickView from 'UI/components/organisms/inventoryProfiles/FPQuickView';
import { ContactEntityMapping } from 'UI/constants/entityTypes';

import { formatInternationalPhone } from '../../messageChat/chat.utils';

import { PhoneNumbersMenu } from './PhoneNumbersMenu';
import { SearchResultWrapper } from './SearchResult.styled';

export const SearchResult = ({ contact, onNewConversation }) => {
  const roleId = contact.role_id;
  const itemRole = ContactEntityMapping[roleId];
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
  const toggleQuickViewOpen = () => setIsQuickViewOpen(prev => !prev);

  const handlePhoneClick = ({ contact: receivedContact, selectedPhone }) => {
    const newMessage = {
      id: `${receivedContact.id}-${selectedPhone.phone}`,
      attachment_size: '0',
      attachments: [],
      countryCode: selectedPhone?.country_code,
      entityId: receivedContact.id,
      entityType: itemRole,
      externalNumber: `${selectedPhone.country_code}${selectedPhone.phone}`,
      fullName: receivedContact.fullName,
      isOutbound: true,
      message: '',
      phone: selectedPhone.phone
    };
    onNewConversation({
      newMessage
    });
  };

  const mainPhone =
    contact.phones?.find(phone => phone.is_default)?.phone || contact.phone || contact.mobile;

  return (
    <SearchResultWrapper>
      <div>
        <Typography variant="body1" component="span">
          {contact.fullName}
        </Typography>
        &nbsp;
        <Typography variant="body2" component="span">
          {formatInternationalPhone(mainPhone)}
        </Typography>
        <Typography variant="body2">{contact.companyAndRole}</Typography>
        <Typography variant="body2">{contact.email}</Typography>
      </div>
      <div>
        <PhoneNumbersMenu contact={contact} onPhoneClick={handlePhoneClick} />
        <FPIconButton
          onClick={toggleQuickViewOpen}
          tooltipProps={{
            title: strings.inventoryProfiles.quickView.viewProfile
          }}
        >
          <FPIcon component={Visibility} />
        </FPIconButton>
      </div>
      <When condition={contact.id && itemRole && isQuickViewOpen}>
        <FPQuickView
          id={contact.id}
          drawerProps={{
            open: isQuickViewOpen
          }}
          entityType={itemRole}
          onClose={toggleQuickViewOpen}
        />
      </When>
    </SearchResultWrapper>
  );
};
