import React, { useCallback, useMemo, useState } from 'react';
import { DRAWER_KEYS, LineSteppedAreaChart, Widget } from 'features/command-center';
import { COMMAND_CENTER_BASE_URL } from 'features/command-center/constants';
import { useDrawerManager } from 'features/command-center/contexts';
import { getActivityHistory } from 'features/command-center/services';
import { getWeekRange } from 'features/command-center/utils';
import moment from 'moment';
import strings from 'strings';
import { AutocompleteByWeek } from 'UI/components/atoms/AutocompleteByWeek';
import { DateFormats } from 'UI/constants/defaults';

import { formatTrendsResponseWithUrl } from '../activity.utils';

const STRINGS = strings.commandCenter.activity.callsTrends;

const URL_INDEX_ACCORDING_VALUE = 1;

export const CallsTrends = ({ weeks, requestParams = {}, hasDrillDown }) => {
  const [week, setWeek] = useState(weeks?.[0] ?? null);
  const { startDate, endDate } = getWeekRange(week);
  const { openDrawer } = useDrawerManager();

  const queryDateRange = useMemo(
    () => ({
      startDate: moment(startDate).format(DateFormats.QueryShortFormat),
      endDate: moment(endDate).format(DateFormats.QueryShortFormat)
    }),
    [startDate, endDate]
  );

  const handleSelectWeek = (event, selectedWeek) => setWeek(selectedWeek);

  const promises = useCallback(() => {
    return Promise.all([
      getActivityHistory({
        ...requestParams,
        metrics: 'calls',
        limit: 7,
        orderBy: 'date',
        direction: 'asc',
        ...queryDateRange
      })
    ]).then(([data]) => data);
  }, [queryDateRange, requestParams]);

  const cachedPromises = useMemo(() => [promises], [promises]);

  return (
    <Widget header={STRINGS.title} promises={cachedPromises}>
      {([response]) => {
        const data = formatTrendsResponseWithUrl({
          response,
          key: 'calls',
          goalKey: 'callsGoal',
          label: 'Calls'
        });

        const handlePointClick = (clickedData, row, column) => {
          const url = clickedData[column + URL_INDEX_ACCORDING_VALUE];
          url &&
            typeof url === 'string' &&
            openDrawer({
              activeDrawer: DRAWER_KEYS.Calls,
              contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
            });
        };

        const chartEvents = [
          {
            eventName: 'select',
            callback: ({ chartWrapper }) => {
              const selection = chartWrapper.getChart().getSelection();
              if (selection.length === 0) return;

              const [selectedItem] = selection;
              const { row, column } = selectedItem;

              if (row !== undefined && column !== undefined) {
                const clickedData = data[row + 1]; // +1 beacause the first row is headers
                handlePointClick(clickedData, row, column);
              }
            }
          }
        ];

        return (
          <>
            <AutocompleteByWeek
              defaultOptions={weeks}
              onSelectWeek={handleSelectWeek}
              value={week}
            />

            <LineSteppedAreaChart chartEvents={hasDrillDown && chartEvents} data={data} />
          </>
        );
      }}
    </Widget>
  );
};
