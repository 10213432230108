// @flow
import { HIDE_REFERENCE_RELEASE_COMPOSER, SHOW_REFERENCE_RELEASE_COMPOSER } from 'actions/joborder';
import type { JobOrderProfileState } from 'types/joborder';
import type { Action } from 'types/redux';

const jobOrderReducer = (
  state: JobOrderProfileState = {},
  action: Action = { type: '', payload: {} }
): JobOrderProfileState => {
  const { type, payload } = action;
  switch (type) {
    case SHOW_REFERENCE_RELEASE_COMPOSER:
      return {
        ...state,
        referenceRelease: payload
      };
    case HIDE_REFERENCE_RELEASE_COMPOSER:
      return {
        ...state,
        referenceRelease: null
      };
    default:
      return state;
  }
};

export default jobOrderReducer;
