import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import find from 'lodash/find';
import API from 'services/API';
import { Endpoints } from 'UI/constants/endpoints';
import { CandidateEntity, ContactEntity, ContactRole } from 'UI/constants/entityTypes';
import { SeverityBySimilarity } from 'UI/constants/status';
import { isValidEmail } from 'UI/utils/forms';

const CANDIDATE_INCLUDES = 'status';

const initialData = {
  id: null,
  company: null,
  companyId: null,
  email: null,
  entity: null,
  fullName: null,
  otherEmail: null,
  recruiter: null,
  status: null,
  subtitle: null
};

const searchEmail = ({ endpoint, apiVersion, entity, emailToSearch, cancelToken }) => {
  return new Promise(resolve => {
    API.get(endpoint, {
      apiVersion,
      params: {
        entityType: entity,
        keyword: emailToSearch
      },
      cancelToken
    })
      .then(response => {
        const { data } = response;

        const emailFound =
          find(data, { email: emailToSearch }) || find(data, { personal_email: emailToSearch });

        resolve(emailFound);
      })
      .catch(() => {
        resolve({});
      });
  });
};

const getCompleteInfo = ({ endpoint, apiVersion, cancelToken }) => {
  return new Promise(resolve => {
    API.get(endpoint, {
      apiVersion,
      cancelToken
    })
      .then(info => {
        resolve(info.data);
      })
      .catch(() => {
        resolve({});
      });
  });
};

export const useCheckEmail = ({ email, shouldCheckContacts, hasEmail }) => {
  const cancelToken = useRef(null);
  const [validation, setValidation] = useState({
    registeredData: initialData,
    severity: SeverityBySimilarity.None,
    isRegistered: false,
    isValidating: true
  });

  const searchDuplicates = useCallback(
    async emailToSearch => {
      const candidateRegistered = await searchEmail({
        emailToSearch,
        endpoint: Endpoints.Search,
        apiVersion: 1,
        entity: CandidateEntity.searchParam,
        cancelToken: cancelToken.current.token
      });

      if (candidateRegistered?.id) {
        setValidation(prev => ({
          ...prev,
          severity: SeverityBySimilarity.Exact,
          isRegistered: true
        }));

        const candidate = await getCompleteInfo({
          endpoint: `${Endpoints.Candidates}/${candidateRegistered.id}?includes=${CANDIDATE_INCLUDES}`,
          apiVersion: 2,
          cancelToken: cancelToken.current.token
        });

        return setValidation(prev => ({
          ...prev,
          registeredData: {
            id: candidate.id,
            company: candidate.current_company,
            companyId: candidate.company_id,
            email: candidateRegistered.email,
            entity: ContactRole.Candidate,
            fullName: candidateRegistered.title,
            otherEmail: candidateRegistered.personal_email,
            recruiter: candidateRegistered.recruiter,
            status: candidate?.status,
            subtitle:
              candidateRegistered?.subtitle ||
              `${candidateRegistered.title} / ${candidateRegistered.location}`
          },
          isValidating: false
        }));
      }

      if (!shouldCheckContacts) return setValidation(prev => ({ ...prev, isValidating: false }));

      const emailRegistered = await searchEmail({
        emailToSearch,
        endpoint: Endpoints.Search,
        apiVersion: 1,
        entity: ContactEntity.searchParam,
        cancelToken: cancelToken.current.token
      });

      if (emailRegistered?.id) {
        setValidation(prev => ({
          ...prev,
          severity: SeverityBySimilarity.Similar,
          isRegistered: true
        }));

        const endpoint = `${
          emailRegistered.role_id === ContactRole.HiringAuthority
            ? Endpoints.HiringAuthorities
            : Endpoints.Names
        }/${emailRegistered.id}`;

        const apiVersion = emailRegistered.role_id === ContactRole.HiringAuthority ? 2 : 1;

        const name = await getCompleteInfo({
          endpoint,
          apiVersion,
          cancelToken: cancelToken.current.token
        });

        return setValidation(prev => ({
          ...prev,
          registeredData: {
            id: name.id,
            company: emailRegistered.company,
            companyId: name.company_id,
            email: emailRegistered.email,
            entity: emailRegistered.role_id,
            fullName: emailRegistered.full_name,
            otherEmail: emailRegistered.personal_email,
            recruiter: emailRegistered.recruiter_name,
            status: name?.status,
            subtitle: emailRegistered?.subtitle || emailRegistered.title
          },
          isValidating: false
        }));
      }

      return setValidation(prev => ({ ...prev, isValidating: false }));
    },
    [shouldCheckContacts]
  );

  useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    setValidation(prev => ({
      ...prev,
      registeredData: initialData,
      severity: SeverityBySimilarity.None,
      isRegistered: false,
      isValidating: true
    }));

    if (!email || !hasEmail || !isValidEmail(email))
      return setValidation(prev => ({ ...prev, isValidating: false }));

    searchDuplicates(email.toLowerCase());

    return () => {
      if (cancelToken.current.cancel) {
        cancelToken.current.cancel();
      }
    };
  }, [email, hasEmail, searchDuplicates]);

  return { validation, initialData };
};
