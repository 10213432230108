import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.customColors.white,
    borderRadius: theme.spacing(1.5),
    height: '100%',
    width: '100%'
  },
  cardContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  defaultTitle: {
    color: theme.palette.grey[800],
    fontSize: 12,
    lineHeight: '20px',
    opacity: '0.60',
    whiteSpace: 'pre-wrap'
  },
  iconContainer: {
    backgroundColor: theme.palette.primary.FP300,
    borderRadius: 6,
    padding: theme.spacing(1.5),
    ...flexAlignCenterJustifyCenter
  },
  iconTitle: {
    color: theme.palette.grey[800],
    fontSize: 14,
    fontWeight: '500 !important',
    lineHeight: '16px',
    opacity: '0.60',
    whiteSpace: 'pre-wrap'
  },
  value: {
    color: theme.palette.primary.FP300,
    fontFamily: '"Kanit", sans-serif',
    fontSize: 24,
    fontWeight: '500 !important',
    lineHeight: '150%'
  }
}));
