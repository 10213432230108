// @flow

import React from 'react';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { FeatureFlags } from 'UI/constants/featureFlags';

import PlacementPaymentsLegacy from './PlacementPaymentsLegacy';
import PlacementPaymentsV2 from './PlacementPaymentsV2';

export type PlacementPaymentsProps = {
  id: number,
  readOnly: boolean,
  onPaymentsChanged: (remainOpen: boolean) => any
};

export const PlacementPayments = ({ id, readOnly, onPaymentsChanged }: PlacementPaymentsProps) => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();
  const Component = checkIfFeatureFlagEnabled(FeatureFlags.PlacementPaymentsFromNetsuite)
    ? PlacementPaymentsV2
    : PlacementPaymentsLegacy;

  return <Component id={id} readOnly={readOnly} onPaymentsChanged={onPaymentsChanged} />;
};
