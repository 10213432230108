// @flow
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { EntityRoutes } from 'routes/constants';
import { CandidateIncludes } from 'services/includes';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import FPLink from 'UI/components/atoms/FPLink';
import { When } from 'UI/components/atoms/When';
import ProfileStatusChip from 'UI/components/molecules/ProfileStatusChip';
import CandidateOverview from 'UI/components/organisms/inventoryProfiles/CandidateOverview';
import ContactOverview from 'UI/components/organisms/inventoryProfiles/ContactOverview';
import FPQuickView from 'UI/components/organisms/inventoryProfiles/FPQuickView';
import HiringAuthorityOverview from 'UI/components/organisms/inventoryProfiles/HiringAuthoritiesOverview';
import { Endpoints } from 'UI/constants/endpoints';
import {
  CandidateEntity,
  ContactEntity,
  ContactRole,
  HiringAuthorityEntity
} from 'UI/constants/entityTypes';
import { candidateTabsDefinition } from 'UI/pages/CandidateProfile/tabs';
import { hiringAuthorityTabs } from 'UI/pages/HiringAuthorityProfile/tabs';
import { SvgViewFilled } from 'UI/res';

import { useStyles } from './styles';

const ENTITY_PROPS = {
  [ContactRole.Name]: {
    apiVersion: 1,
    endpoint: Endpoints.Names,
    entityType: ContactEntity.id,
    fileManagerModule: ContactEntity.module,
    overviewComponent: ContactOverview,
    profileMode: ContactEntity.singular,
    profileRoute: EntityRoutes.ContactProfile,
    singular: ContactEntity.singular,
    type: 'contact'
  },
  [ContactRole.Candidate]: {
    apiVersion: 2,
    endpoint: Endpoints.Candidates,
    entityType: CandidateEntity.id,
    fileManagerModule: CandidateEntity.module,
    includes: CandidateIncludes,
    overviewComponent: CandidateOverview,
    profileMode: CandidateEntity.singular,
    profileRoute: EntityRoutes.CandidateProfile,
    singular: CandidateEntity.singular,
    tabsDefinition: candidateTabsDefinition,
    overviewProps: (isBlueSheetLoading, profile) => ({
      blueSheet: profile?.blueSheets?.[0],
      isBlueSheetLoading
    })
  },
  [ContactRole.HiringAuthority]: {
    activityNoteTabProps: { apiVersion: 2 },
    apiVersion: 2,
    endpoint: Endpoints.HiringAuthorities,
    entityType: HiringAuthorityEntity.id,
    fileManagerModule: HiringAuthorityEntity.module,
    includes: ['optOutStatus'],
    overviewComponent: HiringAuthorityOverview,
    profileRoute: EntityRoutes.HiringAuthorityProfile,
    singular: HiringAuthorityEntity.singular,
    tabsDefinition: hiringAuthorityTabs,
    type: 'hiringAuthority',
    defaultTabsProps: {
      activityLogProps: {
        apiVersion: 2
      },
      notesProps: {
        apiVersion: 2
      }
    }
  }
};

type DuplicateCandidateOverviewProps = {
  id: string,
  handleClickButton: () => void,
  data: {
    id: string | number,
    company: string,
    companyId: string | number,
    email: string,
    entity: string,
    fullName: string,
    inventoryType: string,
    otherEmail: string,
    recruiter: string,
    status: string,
    subtitle: string
  }
};

const DuplicateCandidateOverview = ({
  data,
  handleClickButton
}: DuplicateCandidateOverviewProps) => {
  const {
    id,
    company,
    companyId,
    email,
    entity,
    fullName,
    inventoryType,
    otherEmail,
    recruiter,
    status,
    subtitle
  } = data;
  const classes = useStyles();
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);

  const toggleQuickView = () => setIsQuickViewOpen(!isQuickViewOpen);

  return (
    <>
      <div className={classes.similarCandidateContainer}>
        <div>
          <Grid container spacing={1} className={classes.container}>
            <Grid item>
              <FPLink
                itemId={id}
                aria-label="Name"
                linkProps={{
                  type: 'local',
                  url: ENTITY_PROPS[entity]?.profileRoute,
                  target: '_blank'
                }}
                noWrap
              >
                {fullName}
              </FPLink>
            </Grid>
            <Grid item>
              {companyId ? (
                <>
                  <span className={classes.infoSeparator}>{' / '}</span>
                  <FPLink
                    className={classes.link}
                    itemId={companyId}
                    aria-label="Company Name"
                    linkProps={{
                      type: 'local',
                      url: EntityRoutes.CompanyProfile,
                      target: '_blank'
                    }}
                    noWrap
                  >
                    {company}
                  </FPLink>
                </>
              ) : (
                company && (
                  <>
                    <span className={classes.infoSeparator}>{' / '}</span>
                    <span>{company}</span>
                  </>
                )
              )}
              <span className={classes.infoSeparator}>{' / '}</span>
            </Grid>
            <Grid item>
              <span>{ENTITY_PROPS[entity]?.singular}</span>
              <span className={classes.infoSeparator}>{' / '}</span>
            </Grid>
            <Grid item>
              <span>{subtitle}</span>
            </Grid>
            <Grid item>
              <span className={classes.infoSeparator}>{' / '}</span>
              <span>
                <strong>{email}</strong>
              </span>
            </Grid>
            {otherEmail && (
              <Grid item>
                <span className={classes.infoSeparator}>{' / '}</span>
                <span>
                  <strong>{otherEmail}</strong>
                </span>
              </Grid>
            )}

            {recruiter && (
              <Grid item>
                <span className={classes.infoSeparator}>{' / '}</span>
                <span>Recruiter: {recruiter}</span>
              </Grid>
            )}
          </Grid>

          <ProfileStatusChip inventoryType={inventoryType} itemStatus={status} />
        </div>
        <div className={classes.similarCandidateInfo}>
          {handleClickButton && entity !== ContactRole.Candidate && (
            <FPActionButton
              variant="outlined"
              size="small"
              text={`Create from ${ENTITY_PROPS[entity]?.singular}`}
              onClick={handleClickButton}
            />
          )}

          <FPIconButton
            className={classes.infoSeparator}
            icon={SvgViewFilled}
            onClick={toggleQuickView}
            tooltipProps={{ title: 'View details' }}
          />
        </div>
      </div>

      <When condition={id && isQuickViewOpen}>
        <FPQuickView
          drawerProps={{
            open: isQuickViewOpen
          }}
          entityType={ENTITY_PROPS[entity]?.entityType}
          onClose={toggleQuickView}
          id={id}
        />
      </When>
    </>
  );
};

export default DuplicateCandidateOverview;
