// @flow
import React, { useCallback, useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import useDatatable, {
  buildRowActionsRendererDefinition,
  extractObjectFromDataTable,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import { useUrlParamsUpdater } from 'hooks/urlParamsUpdater';
import last from 'lodash/last';
import { userHasPermission, userHasRoles } from 'services/Authorization';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import DataTable from 'UI/components/organisms/DataTable';
import ListingSummary from 'UI/components/organisms/ListingSummary';
import SendoutManager from 'UI/components/organisms/sendouts';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { additionalPeriodRanges } from 'UI/components/templates/SideFiltersLayout/filters';
import { backNavigateListConfig } from 'UI/constants/config';
import { PageTitles, RowActions } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { createCustomStaticRanges } from 'UI/utils';

import {
  columnsSendouts,
  CustomScopeFilters,
  FiltersGroup,
  FiltersInclude,
  SendoutDateFilters,
  SendoutPeriodColumnBySection,
  SendoutSortColumnBySection,
  SendoutTrackingDateFilter
} from './columns';
import { navigateToSendout } from './utils';

const sendoutKey = 'sendout';

const Sendouts = (props: RouterProps) => {
  const { history, location } = props;
  const initialDateFilter = SendoutTrackingDateFilter.id;

  const orderByOptions = {
    column: SendoutSortColumnBySection,
    direction: 'desc'
  };
  const initialPreferences = getColumnPreferences(
    sendoutKey,
    0,
    orderByOptions,
    columnsSendouts,
    1,
    location.search
  );

  const checkIsVisible = ({ roles = [], permissions = [] }) => {
    const hasRole = !roles || userHasRoles(roles);
    const hasPermission =
      !permissions || permissions?.some(permission => userHasPermission(permission));
    return hasRole || hasPermission;
  };

  const allowedScopeFilters = CustomScopeFilters.filter(scopeFilter => checkIsVisible(scopeFilter));

  const { id: defaultFilter } = last(allowedScopeFilters);

  const [dateTypeFilter, setDateTypeFilter] = useState(
    SendoutDateFilters.find(type => type.id === initialDateFilter)
  );

  const {
    data,
    getData,
    listState,
    count,
    columnPreferences,
    filters,
    queryParams,
    handleFiltersApply,
    handleOneFilterChange,
    handleFiltersChange,
    handleFiltersToggle,
    handleKeywordChange,
    handleColumnSortChange,
    handlePerPageChange,
    handlePageChange,
    handlePeriodChange,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleSectionChange
  } = useDatatable({
    key: sendoutKey,
    endpoint: Endpoints.Sendouts,
    columnsDefinitions: columnsSendouts,
    orderByOptions,
    initialPreferences,
    initialSection: initialDateFilter,
    initialScopeFilter: defaultFilter,
    customScopeFilters: allowedScopeFilters,
    periodFilterColumn: SendoutPeriodColumnBySection,
    entityType: EntityType.Sendouts,
    entityName: 'Sendouts',
    sharePreferencesBetweenSections: true,
    numberOfActionColumns: 1,
    shouldScrollOnNavigate: true
  });

  const { showWholeSkeleton, isLoading, isSideMenuOpen, page, perPage, userFilter, keyword } =
    listState;
  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  useUrlParamsUpdater({ history, location, orderBy });

  const [uiState, setUiState] = useState({
    shouldRefresh: false,
    sendoutId: null
  });

  useEffect(() => {
    document.title = PageTitles.Sendouts;
  }, []);

  const handleViewSendout = async ({ rowData }) => {
    if (!rowData) return;
    const item = extractObjectFromDataTable(columnsSendouts, ['id'], rowData);

    setUiState(prev => ({
      ...prev,
      sendoutId: item.id
    }));
  };

  const handleCloseSendout = () =>
    setUiState(prev => ({
      ...prev,
      sendoutId: null
    }));

  const handleSendoutUpdated = async () => {
    setUiState(prev => ({
      ...prev,
      shouldRefresh: true
    }));
    await getData();
  };

  const handleSummaryLoaded = useCallback(() => {
    setUiState(prev => ({
      ...prev,
      shouldRefresh: false
    }));
  }, []);

  const handleDateTypeFilterChange = (name, value) => {
    setDateTypeFilter(value);
    handleSectionChange && handleSectionChange(name, value.id);
  };

  const actionButton = buildRowActionsRendererDefinition({
    namespace: EntityType.Sendouts,
    actions: [{ name: RowActions.View, onClick: handleViewSendout }]
  });

  const finalColumns = getColumnsToRender([...columns, actionButton]);
  const isUserFilterEnabled = allowedScopeFilters.length > 1;
  const handleSendoutNavigation = navigateToSendout(history);

  return (
    <FiltersLayout
      filters={filters}
      title="Sendouts"
      section="sendouts"
      isSideMenuOpen={isSideMenuOpen}
      includeFilters={FiltersInclude}
      groups={FiltersGroup}
      extraSelector={
        <AutocompleteSelect
          name="dateTypeFilter"
          placeholder="Filter by"
          selectedValue={dateTypeFilter}
          onSelect={handleDateTypeFilterChange}
          defaultOptions={SendoutDateFilters}
          disableClearable
        />
      }
      dateRangerPickerProps={{
        staticRanges: createCustomStaticRanges(additionalPeriodRanges)
      }}
      listSelector={
        <AutocompleteSelect
          name="userFilter"
          placeholder="Sendouts to Show"
          disabled={!isUserFilterEnabled}
          selectedValue={userFilter}
          onSelect={handleOneFilterChange}
          defaultOptions={allowedScopeFilters}
          disableClearable
        />
      }
      onSearch={handleFiltersApply}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      onPeriodChange={handlePeriodChange}
      enableStore={false}
      titleLabelProps={backNavigateListConfig}
    >
      <ListingSummary
        endpoint={Endpoints.SendoutsSummary}
        queryParams={queryParams.params}
        shouldRefresh={uiState.shouldRefresh}
        onDataLoaded={handleSummaryLoaded}
        numberOfColumns={3}
      />
      <DataTable
        isExpandable
        columnOrder={columnOrder?.length ? columnOrder : undefined}
        columns={finalColumns}
        count={count}
        data={data}
        draggableColumns={{ enabled: true }}
        enableCustomFilters
        filter={false}
        loading={showWholeSkeleton}
        onColumnDisplayClick={handleColumnDisplayChange}
        onColumnOrderChange={handleColumnOrderChange}
        onColumnSortChange={handleColumnSortChange}
        onPageClick={handlePageChange}
        onPerPageClick={handlePerPageChange}
        onSearchTextChange={handleKeywordChange}
        onToggleFilters={handleFiltersToggle}
        page={page}
        refreshing={isLoading}
        rowsPerPage={perPage}
        searchText={keyword}
        selectableRows="none"
        sortOrder={{ name: orderBy, direction }}
      />

      <Drawer open={!!uiState.sendoutId} anchor="right" onClose={handleCloseSendout}>
        {uiState.sendoutId && (
          <SendoutManager
            onEdit={handleSendoutUpdated}
            onDelete={handleSendoutUpdated}
            onClose={handleCloseSendout}
            id={uiState.sendoutId}
            onNavigate={handleSendoutNavigation}
          />
        )}
      </Drawer>
    </FiltersLayout>
  );
};

export default Sendouts;
