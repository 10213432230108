// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import strings from 'strings';
import { FPCard, FPCardContent, FPCardHeader } from 'UI/components/atoms/FPCard';
import { When } from 'UI/components/atoms/When';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { UIStatus } from 'UI/constants/status';
import { capitalizeFirstLetter, getFeatureFlags } from 'UI/utils';

import FunctionalTitlesList from './FunctionalTitlesList/FunctionalTitlesList';
import PilBreadcrumb from './PilBreadcrumb';
import { useStyles } from './styles';

export type PilInformationProps = {
  selectionParams: {
    id: string | null,
    title?: string,
    type?: 'industry' | 'specialty' | 'subspecialty',
    informationEndpoint?: string,
    functionalTitlesEndpoint?: string,
    showFunctionalTitles?: boolean
  }
};

const PilInformation = ({
  selectionParams: {
    id,
    title,
    type = 'industry',
    informationEndpoint,
    functionalTitlesEndpoint,
    showFunctionalTitles
  }
}: PilInformationProps) => {
  const showPilDescription = getFeatureFlags().includes(FeatureFlags.PilDescriptions);
  const classes = useStyles();

  const {
    Status,
    state: { status, results: pilResults }
  } = useFetchWithStatus(informationEndpoint, null, UIStatus.Default);

  return (
    <FPCard className={classes.cardContainer}>
      <FPCardHeader
        title={title || strings.pil.secondTitle}
        subheader={
          <>
            {showPilDescription ? (
              <PilBreadcrumb
                type={type}
                isLoading={status === UIStatus.Loading}
                pilResults={pilResults}
              />
            ) : (
              capitalizeFirstLetter(type)
            )}
          </>
        }
      />
      <FPCardContent className={classes.contentContainer}>
        <Status
          loading={null}
          default={<EmptyPlaceholder subtitle={strings.pil.instructions} />}
          success={results => (
            <When condition={id && showPilDescription}>
              <PilDescription type={type} description={results?.description} />
            </When>
          )}
          error={error => (
            <When condition={showPilDescription}>
              <Typography paragraph gutterBottom>
                {error}
              </Typography>
            </When>
          )}
          empty={<EmptyPlaceholder subtitle={strings.pil.instructions} />}
        />

        <When condition={showFunctionalTitles}>
          <FunctionalTitlesList endpoint={functionalTitlesEndpoint} />
        </When>

        <When condition={!showFunctionalTitles && status !== UIStatus.Default}>
          <EmptyPlaceholder subtitle={strings.pil.functionalTitles.instructions} />
        </When>
      </FPCardContent>
    </FPCard>
  );
};

const PilDescription = ({ type, description }) => {
  const classes = useStyles();

  if (!description) return null;

  return (
    <div className={classes.descriptionContainer}>
      <Typography variant="h6" gutterBottom>
        {strings.formatString(strings.pil.description, {
          pil: capitalizeFirstLetter(type ?? '')
        })}
      </Typography>
      <Typography className={classes.descripton}>{description}</Typography>
    </div>
  );
};

export default PilInformation;
