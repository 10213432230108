// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { currencyFormatter } from 'UI/utils';

import { useStyles } from './styles';

type CashStatsContentProps = {
  children: React.Node,
  title: string,
  cashAmount: number
};

const CashStatsContent = ({ children, title, cashAmount }: CashStatsContentProps) => {
  const isAmountPositive = Number(cashAmount) >= 0;
  const classes = useStyles({ isAmountPositive });
  const formattedCashAmount = currencyFormatter(cashAmount, 2);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.periodContainer}>
        <Typography className={classes.periodText}>{title}</Typography>
      </Grid>

      <Grid item xs={12} className={classes.container}>
        <Typography className={classes.valueText}>{formattedCashAmount}</Typography>
      </Grid>

      {children}
    </Grid>
  );
};

CashStatsContent.defaultProps = {
  cashAmount: 0
};

export default CashStatsContent;
