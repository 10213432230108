// @flow
import React from 'react';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import LoaderContent from 'UI/components/templates/DrawerContentLayout/LoaderContent';

import ChangeLogs from './ChangeLogs';
import ChangeLogsDifferentiated from './ChangeLogsDifferentiated';
import { ChangeLogHeader } from './components';

type ChangeLogsContentProps = {
  endpoint: string
};

const ChangeLogsContent = ({ endpoint }: ChangeLogsContentProps) => {
  const { Status } = useFetchWithStatus(endpoint);

  return (
    <Status
      loading={<LoaderContent />}
      success={results => (
        <>
          <ChangeLogHeader
            title={results.logTypeLabel ?? 'Change Log'}
            createdBy={results?.vCreatedBy?.user_name ?? ''}
            createdAt={results?.created_at}
          />

          {results.payload?.isDifferentiated ? (
            <ChangeLogsDifferentiated logs={results?.payload?.logs ?? []} />
          ) : (
            <ChangeLogs logs={results} />
          )}
        </>
      )}
    />
  );
};

export default ChangeLogsContent;
