import moment from 'moment';
import strings from 'strings';

export const formatTenure = date => {
  const getPlural = number => (number > 1 ? 's' : '');

  const tenure = date
    ? {
        years: moment().diff(moment(date), 'years'),
        months: moment().diff(moment(date), 'months') % 12
      }
    : { years: 0, months: 0 };

  if (tenure.years > 0 && tenure.months > 0) {
    return `${tenure.years} Year${getPlural(tenure.years)} and ${tenure.months} Month${getPlural(
      tenure.months
    )}`;
  }

  if (tenure.years > 0) {
    return `${tenure.years} Year${getPlural(tenure.years)}`;
  }

  if (tenure.months > 0) {
    return `${tenure.months} Month${getPlural(tenure.months)}`;
  }

  return '0 Months';
};

export const getTeamName = fullName => {
  if (!fullName) return strings.commandCenter.teamPage.title;
  const [teamName] = fullName.split(' ');
  return `${strings.commandCenter.teamPage.title} ${teamName}`;
};
