import * as React from 'react';

const SvgFilters = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path
        fill="#6A7381"
        d="M19.928 4.367A.666.666 0 0 0 19.334 4H4.666a.667.667 0 0 0-.534 1.067L10 12.947v5.72a1.333 1.333 0 0 0 2.133 1.066l1.334-1A1.334 1.334 0 0 0 14 17.667v-4.72l5.868-7.88a.667.667 0 0 0 .06-.7Zm-12.315 1.1 2.627 3.5a.36.36 0 0 1 .066.2V10a.326.326 0 0 1-.226.313.32.32 0 0 1-.374-.113L6.412 5.867a.327.327 0 0 1-.033-.347.333.333 0 0 1 .287-.187h.667a.36.36 0 0 1 .28.134Z"
      />
    </svg>
  );
};

export default SvgFilters;
