// @flow
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { toggleSidebar } from 'actions/app';
import useLocalStorage from 'hooks/localStorage';
import useKeepLogRocketSessionAlive from 'hooks/refresh-logrocket-session';
import queryString from 'query-string';
import { EntityRoutes } from 'routes/constants';
import { getCurrentUser, isAuthenticated, logout } from 'services/Authentication';
import strings from 'strings';
import ConditionalActionWrapper from 'UI/components/atoms/ConditionalWrapper';
import FPAvatar from 'UI/components/atoms/FPAvatar';
import FPIcon from 'UI/components/atoms/FPIcon';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { When } from 'UI/components/atoms/When';
import GlobalSearchbar from 'UI/components/molecules/GlobalSearchbar';
import NotificationPreview from 'UI/components/organisms/NotificationPreview';
import { drawerAnchor, DrawerName } from 'UI/constants/defaults';
import { topBarIconSize } from 'UI/constants/dimensions';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { GpacLogo, SvgBurgerMenu, SvgELearning, SvgExtension } from 'UI/res';
import { SvgArrowDown, SvgArtificialIntelligence, SvgQuestionCircle } from 'UI/res/icons/filled';
import { getFeatureFlags } from 'UI/utils';

import NotificationButton from './NotificationButton';
import { styles, useStyles } from './styles';

const featureFlags = getFeatureFlags();
const chromeExtensionURL = `${
  (window.GPAC_ENV && window.GPAC_ENV.CHROME_EXTENSION_URL) ||
  process.env.REACT_APP_CHROME_EXTENSION_URL
}`;
const releaseNotesAccount =
  (window.GPAC_ENV && window.GPAC_ENV.RELEASE_NOTES_ACCOUNT) ||
  process.env.REACT_APP_RELEASE_NOTES_ACCOUNT;
const releaseNotesTag =
  (window.GPAC_ENV && window.GPAC_ENV.RELEASE_NOTES_TAGS) ||
  process.env.REACT_APP_RELEASE_NOTES_TAGS;

const IconList = ({ icons }) => {
  const classes = useStyles();
  return icons.map(icon => (
    <Link href={icon.href} key={icon.tooltipTitle} target="_blank" rel="noopener noreferrer">
      <ConditionalActionWrapper
        condition={!!icon.hasBadge}
        wrapper={c => (
          <Badge
            classes={{ dot: icon.badgeClasses }}
            invisible={icon.isInvisibleBadge}
            variant="dot"
            color="primary"
            overlap="circle"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {c}
          </Badge>
        )}
      >
        <FPIconButton
          key={icon.tooltipTitle}
          icon={icon.icon}
          iconProps={{ size: topBarIconSize }}
          className={classes.gpacUIcon}
          onClick={icon.onClick}
          tooltipProps={{ title: icon.tooltipTitle }}
        />
      </ConditionalActionWrapper>
    </Link>
  ));
};

const MenuItems = React.forwardRef(({ menuItems }, ref) => {
  return menuItems.map(menuItem => (
    <When key={menuItem.key} condition={menuItem.shouldRender}>
      <MenuItem onClick={menuItem.onClick} className={menuItem.className} ref={ref}>
        {menuItem.children}
      </MenuItem>
    </When>
  ));
});

const NavBar = () => {
  const { version, isSidebarOpen } = useSelector(({ app }) => app.ui);
  const dispatch = useDispatch();
  const isSmallViewPort = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'));
  const classes = useStyles({
    isSmallViewPort
  });

  const searchBarRef = useRef(null);

  const user = isAuthenticated() && getCurrentUser();

  const history = useHistory();
  const location = useLocation();
  const { drawer } = queryString.parse(location.search);

  const [anchorEl, setAnchorEl] = useState(null);
  const [extensionIsViewed, setExtensionIsViewed] = useLocalStorage('extensionIsViewed', false);
  const [uiState, setUiState] = useState({
    areNotificationsOpen: drawer === DrawerName.Notifications || drawer === DrawerName.FeeAgreements
  });

  const handleOpenReleaseNotes = useCallback(
    () =>
      window.initReleaseNotes &&
      window.initReleaseNotes(releaseNotesAccount, releaseNotesTag, version),
    [version]
  );

  useEffect(() => {
    if (!releaseNotesAccount && !releaseNotesTag) return;

    handleOpenReleaseNotes();
  }, [handleOpenReleaseNotes]);

  useEffect(() => {
    isSmallViewPort ? dispatch(toggleSidebar(false)) : dispatch(toggleSidebar(true));
  }, [dispatch, isSmallViewPort]);

  const handleOpenMenuClick = event => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => logout();

  const handleExtensionButton = () => setExtensionIsViewed(true);

  const toggleDrawer = (activeDrawer, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setUiState(prevState => ({ ...prevState, [activeDrawer]: open }));
  };

  const goHome = e => {
    e.preventDefault();
    history.push('/');
  };

  const handleToggleSidebar = () => dispatch(toggleSidebar(!isSidebarOpen));

  useKeepLogRocketSessionAlive(searchBarRef);

  const icons = [
    {
      href: 'https://gpac.freshservice.com/support/solutions/21000071629',
      icon: SvgQuestionCircle,
      tooltipTitle: strings.navBar.help
    },
    {
      href: 'https://robot.fortpac.com/',
      badgeClasses: '',
      isInvisibleBadge: true,
      icon: SvgArtificialIntelligence,
      tooltipTitle: strings.navBar.artificialIntelligenceButton
    },
    {
      hasBadge: true,
      href: chromeExtensionURL,
      badgeClasses: classes.fortPacExtension__dotBadge,
      isInvisibleBadge: extensionIsViewed,
      icon: SvgExtension,
      onClick: handleExtensionButton,
      tooltipTitle: strings.navBar.extensionButton
    },
    {
      href: 'https://training.gogpac.com/',
      icon: SvgELearning,
      tooltipTitle: strings.navBar.trainingButton
    }
  ];

  const handleGoProfile = () => {
    const recruiterProfile = EntityRoutes.RecruiterProfile.replace(':id', user.id);
    history.push(recruiterProfile);
    handleClose();
  };

  const menuItems = [
    {
      shouldRender: featureFlags.includes(FeatureFlags.RecruiterProfile),
      key: 'profile',
      children: 'My Profile',
      onClick: handleGoProfile
    },
    {
      shouldRender: !!releaseNotesAccount && !!releaseNotesTag,
      onClick: handleOpenReleaseNotes,
      className: 'release-notes',
      children: `What's New?`,
      key: 'notes'
    },
    {
      shouldRender: true,
      onClick: handleLogout,
      className: classes.menuLink,
      children: 'Log Out',
      key: 'logout'
    },
    {
      shouldRender: true,
      onClick: null,
      className: classes.fortpacVersion,
      children: (
        <>
          FortPac <span>v{version}</span>
        </>
      ),
      key: 'version'
    }
  ];

  return (
    <Fragment key={drawerAnchor}>
      <div className={classes.wrapper}>
        <div className={classes.leftContainer}>
          <div className={classes.burgerMenu}>
            <FPIconButton icon={SvgBurgerMenu} onClick={handleToggleSidebar} />
          </div>

          <Link href="/" onClick={goHome} className={classes.logoContainer}>
            <GpacLogo width={140} height={43} />
          </Link>
        </div>
        <div className={classes.middleContainer}>
          <div className={classes.searchbarWrapper}>
            <GlobalSearchbar
              isSmallViewPort={isSmallViewPort}
              trackingMeta={{ origin: 'navbar' }}
              searchBarRef={searchBarRef}
            />
          </div>
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.userCardWrapper}>
            <IconList icons={icons} />
            {featureFlags.includes(FeatureFlags.Notifications) && (
              <NotificationButton onDrawerOpen={toggleDrawer('areNotificationsOpen', true)} />
            )}
            <div className={classes.userInfoContainer}>
              <Button onClick={handleOpenMenuClick} color="primary" className={classes.userCard}>
                <FPAvatar mode="user" userEmail={user.email}>
                  {user?.initials}
                </FPAvatar>
                <div className={classes.name}>{user?.personalInformation?.first_name}</div>
                <FPIcon component={SvgArrowDown} size="sm" family="mini" />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorReference="none"
                PaperProps={{ style: styles.menuPaperStyles }}
                MenuListProps={{ style: styles.menuStyle }}
              >
                <MenuItems menuItems={menuItems} />
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        open={uiState.areNotificationsOpen}
        onClose={toggleDrawer('areNotificationsOpen', false)}
        PaperProps={{ style: { overflow: 'unset' } }}
      >
        <NotificationPreview onClose={toggleDrawer('areNotificationsOpen', false)} />
      </Drawer>
    </Fragment>
  );
};

export default NavBar;
