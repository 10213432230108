export const VERIFY_URGENCY_FIELDS = {
  IsVerified: {
    key: 'verified'
  },
  ActionsTaken: {
    key: 'actionsTaken',
    paramKey: 'activityLogIds',
    outboundAccesor: items => items.map(({ id }) => id),
    inboundAccesor: ({ activity_log_types: actionsTaken }) => actionsTaken
  },
  MoreDetails: {
    key: 'details'
  }
};
