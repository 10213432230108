import * as React from 'react';

const SvgSearchProjects = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M12.77 8.52a.75.75 0 00-.77-.75H6a.75.75 0 000 1.5h6a.76.76 0 00.77-.75z" />
      <path d="M10.05 19.17a.25.25 0 00-.23-.17h-6.3a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v3.88a.24.24 0 00.29.24 6.89 6.89 0 011.21-.1 6.78 6.78 0 011.2.1.24.24 0 00.3-.24V3.52A1.5 1.5 0 0016.52 2h-4a.24.24 0 01-.21-.13 3.84 3.84 0 00-6.59 0 .23.23 0 01-.21.13h-4A1.5 1.5 0 000 3.52v17A1.5 1.5 0 001.52 22h10a.25.25 0 00.18-.43 7 7 0 01-1.65-2.4z" />
      <path d="M10.69 12.66a7.13 7.13 0 011-1.16c.08-.08.12-.23-.26-.23H6a.75.75 0 000 1.5h4.47a.24.24 0 00.22-.11zM6 14.77a.75.75 0 000 1.5h3.3a.25.25 0 00.24-.27 6 6 0 01.14-1 .24.24 0 00-.24-.3zM23.69 22.28l-2.51-2.51a.24.24 0 010-.3 5.49 5.49 0 00-3.37-8.31 5.5 5.5 0 101.69 10 .24.24 0 01.3 0l2.51 2.51a1 1 0 001.41-1.41zM16.52 20a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z" />
    </svg>
  );
};

export default SvgSearchProjects;
