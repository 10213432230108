// @flow
import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import moment from 'moment';
import { EntityRoutes } from 'routes/constants';
import FPAvatar from 'UI/components/atoms/FPAvatar';
import FPIcon from 'UI/components/atoms/FPIcon';
import FPLink from 'UI/components/atoms/FPLink';
import Text from 'UI/components/atoms/Text';
import { DateFormats } from 'UI/constants/defaults';
import { EntityType } from 'UI/constants/entityTypes';
import { SvgCalendarClock, SvgCircleArrowRight, SvgClockInterviewFilled } from 'UI/res';
import { getId, getTimezoneByName } from 'UI/utils';

import { useStyles } from './styles';

type Interview = {
  date: string,
  id: Number,
  range: string,
  timezone: string
};

type RelatedItemInterview = {
  full_name: string,
  id: Number
};

type AccountableInterview = {
  email: string,
  full_name: string,
  id: Number,
  initials: string
};

type ItemInterviewProps = {
  candidate?: RelatedItemInterview,
  candidateAccountable?: AccountableInterview,
  hiringAuthority?: RelatedItemInterview,
  interviewData: Interview,
  jobOrderAccountable?: AccountableInterview,
  onSelectItem: () => void,
  sendoutId: Number,
  variant: 'default' | 'accountable'
};

const ItemInterview = ({
  candidate,
  candidateAccountable,
  hiringAuthority,
  interviewData,
  jobOrderAccountable,
  onSelectItem,
  sendoutId,
  variant
}: ItemInterviewProps) => {
  const theme = useTheme();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles({ isSmallViewPort });

  const getFormattedDate = baseDate =>
    moment(baseDate).utc(false).format(DateFormats.InterviewTemplateDate);
  const getFormattedTime = baseTime => moment(baseTime).format(DateFormats.SimpleTime);
  const getFormattedUtcTime = baseTime => moment.utc(baseTime).format(DateFormats.SimpleTime);

  const { date, range, timezone } = interviewData;
  const { initials: timezoneInitials } = getTimezoneByName(timezone);

  const startDate = range ? range?.from : date;
  const endDate = range && range?.to;
  const finalTime = endDate
    ? `${getFormattedTime(startDate)} - ${getFormattedTime(endDate)}`
    : getFormattedUtcTime(startDate);

  const handleClickInterview = () => onSelectItem && onSelectItem(sendoutId);

  const getUserAccountables = () => {
    const userAccountables = [];
    userAccountables.push(
      candidateAccountable
        ? {
            email: candidateAccountable.email,
            id: candidateAccountable.id,
            initials: candidateAccountable.initials,
            mode: 'user'
          }
        : {}
    );
    userAccountables.push(
      jobOrderAccountable
        ? {
            email: jobOrderAccountable.email,
            id: jobOrderAccountable.id,
            initials: jobOrderAccountable.initials,
            mode: 'user'
          }
        : {}
    );

    return userAccountables;
  };

  const usersInvolved =
    variant === 'default'
      ? [
          {
            id: candidate.id,
            mode: 'candidate',
            name: candidate.full_name
          },
          {
            id: hiringAuthority.id,
            mode: 'hiringAuthority',
            name: hiringAuthority.full_name
          }
        ]
      : getUserAccountables();

  const getLinkProps = (entity, id) => {
    let route = '';
    if (entity === EntityType.Candidate) {
      route = 'CandidateProfile';
    } else if (entity === EntityType.HiringAuthority) {
      route = 'HiringAuthorityProfile';
    }
    return {
      type: 'local',
      url: EntityRoutes[route].replace(':id', id)
    };
  };

  return (
    <div className={classes.container}>
      {variant === 'default' ? (
        <Grid container>
          <Grid
            container
            item
            sm={12}
            xs={6}
            className={classes.primaryContent}
            onClick={handleClickInterview}
          >
            <Grid item sm={5} xs={4}>
              <div className={classes.alignCenter}>
                <FPIcon
                  component={SvgCalendarClock}
                  size={14}
                  family="bold"
                  onSetColor={palette => palette.secondary.FP700}
                />
                <Text className={classes.dateLabel} cropped text={getFormattedDate(date)} />
              </div>
            </Grid>

            <Grid item sm={7} xs={8}>
              <div className={classes.timeContent}>
                <div className={classes.alignCenter}>
                  <Text className={classes.timeLabel} cropped text={finalTime} />

                  <Text className={classes.timezoneLabel} cropped text={`(${timezoneInitials})`} />
                </div>

                <div className={classes.alignCenter}>
                  <FPIcon
                    component={SvgCircleArrowRight}
                    size={16}
                    family="mini"
                    onSetColor={palette => palette.secondary.FP700}
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container item sm={12} xs={6} className={classes.secondaryContent}>
            {usersInvolved.map(user => (
              <Grid item xs={6} key={user.id}>
                <div className={classes.alignCenter}>
                  <FPAvatar mode={user.mode} size="small" />
                  <FPLink
                    className={classes.link}
                    linkProps={getLinkProps(user.mode, user.id)}
                    noWrap
                  >
                    {user.name}
                  </FPLink>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : (
        <ListItem
          role="listitem"
          classes={{ container: classes.listItemContainer }}
          onClick={handleClickInterview}
        >
          <ListItemAvatar>
            <Grid container spacing={2}>
              {usersInvolved.map(user =>
                user.id ? (
                  <Grid item xs={6} key={user.id}>
                    <FPAvatar
                      role="img"
                      aria-label="avatar"
                      mode={user.mode}
                      size="small"
                      userEmail={user.email}
                    >
                      {user.initials}
                    </FPAvatar>
                  </Grid>
                ) : (
                  <Grid item xs={6} key={getId()} className={classes.emptyGrid} />
                )
              )}
            </Grid>
          </ListItemAvatar>
          <ListItemText>
            <div className={clsx(classes.alignCenter, classes.maxHeight)}>
              <FPIcon
                className={classes.timeIcon}
                component={SvgClockInterviewFilled}
                size={18}
                onSetColor={palette => palette.secondary.FP700}
              />
              <Text className={classes.timeLabelAccountable} cropped text={finalTime} />
            </div>
          </ListItemText>
          <ListItemSecondaryAction role="button" onClick={handleClickInterview}>
            <FPIcon
              className={classes.alignCenter}
              component={SvgCircleArrowRight}
              size={16}
              family="mini"
              onSetColor={palette => palette.secondary.FP700}
            />
          </ListItemSecondaryAction>
        </ListItem>
      )}
      <Divider
        role="separator"
        className={clsx(classes.divider, variant !== 'accountable' && classes.dividerMarginTop)}
      />
    </div>
  );
};

ItemInterview.defaultProps = {
  candidate: {},
  candidateAccountable: {},
  hiringAuthority: {},
  interviewData: {},
  jobOrderAccountable: {},
  sendoutId: null,
  variant: 'default'
};

export default ItemInterview;
