// @flow
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { showAlert } from 'actions/app';
import clsx from 'clsx';
import API from 'services/API';
import { deleteTemporaryAttachment } from 'services/bulkEmail/attachedFiles';
import Text from 'UI/components/atoms/Text';
import FileUploaderOutlined from 'UI/components/molecules/FileUploaderOutlined';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import DecisionDialog from 'UI/components/organisms/DecisionDialog';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { SignTypes } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { replaceByMap } from 'UI/utils';

import { useStyles } from './styles';

type CompanySignStatusFormProps = {
  type: 'dialog' | 'drawer',
  companyId: number,
  company: Object,
  onUpdateCompany: () => any,
  followUpId?: number | string,
  onCloseDrawer: typeof useCallback,
  dialogProps: Object,
  reassure: Object
};

const CompanySignStatusForm = ({
  loading,
  type,
  companyId,
  company,
  onUpdateCompany,
  onCloseDrawer,
  followUpId,
  dialogProps,
  reassure
}: CompanySignStatusFormProps) => {
  const submitButton = useRef(null);
  const classes = useStyles();

  const companyData = [
    {
      header: 'Company Name',
      content: company?.name,
      component: null
    },
    {
      header: 'Industry: Specialty',
      content: `${company?.specialty?.industry?.title}: ${company?.specialty?.title}` || '',
      component: null
    },
    {
      header: 'Location',
      content: company?.city ? `${company?.city?.title}, ${company?.city?.state?.title}` : '',
      component: null
    },
    {
      header: 'Phone',
      content: company?.contact?.phone,
      component: (phone, classNames) => (
        <NumberFormat
          value={phone}
          displayType="text"
          format="(###)-###-####"
          className={classNames}
        />
      )
    }
  ];

  const [unsavedFiles, setUnsavedFiles] = useState([]);
  const [signTypes, setSignTypes] = useState({
    types: [],
    attachment: null
  });
  const [advice, setAdvice] = useState({
    requestor: null,
    request: null
  });
  const [uiState, setUiState] = useState({
    loadingRequestor: false,
    loadingTypes: false,
    taskCompleted: false,
    drawerCompleted: false,
    fetchingType: false,
    showFileUploader: false,
    showDrawer: false
  });

  const dispatch = useDispatch();
  const { register, unregister, handleSubmit, setValue, watch, errors } = useForm(
    reassure
      ? {
          defaultValues: {
            signTypes: String(reassure.companyTypeId)
          }
        }
      : {}
  );

  const watchers = watch(['signTypes']);

  const getReassurePendingEndpoint = useCallback(
    referenceId =>
      replaceByMap(Endpoints.CompanyTypeReassurePending, /:id|:referenceId/g, {
        ':id': companyId,
        ':referenceId': referenceId
      }),
    [companyId]
  );

  /**
   * Register radio buttons options
   */
  useEffect(() => {
    register({ name: 'signTypes' }, { required: 'You must select an option' });
  }, [register]);

  /**
   * Loads RCR type change request and candidate data
   * Sets default form values
   */

  useEffect(() => {
    if (!followUpId || !companyId) return;
    let mounted = true;
    setUiState(prev => ({ ...prev, loadingRequestor: true }));
    (async () => {
      try {
        const response = await API.get(getReassurePendingEndpoint(followUpId));
        if (response.status === 200 && mounted) {
          setAdvice({
            requestor: response.data.requestor.personalInformation.full_name,
            request: response.data.request.title
          });
          setValue('signTypes', String(response.data.request.id));
          setUiState(prev => ({ ...prev, loadingRequestor: false, showDrawer: true }));
        }
      } catch (error) {
        if (error.response?.status === 409) onCloseDrawer && onCloseDrawer();
      }
    })();
    /* eslint-disable consistent-return */
    return () => {
      mounted = false;
    };
  }, [followUpId, companyId, setValue, onCloseDrawer, getReassurePendingEndpoint]);

  /**
   * Displays and register file uploader if vendor or signed are selected
   * Hides file uploader if unsigned is selected
   * Only visible for OPS
   */
  useEffect(() => {
    if (!watchers.signTypes || !followUpId) return;
    if (watchers.signTypes !== String(SignTypes.notSigned)) {
      register({ name: 'attachFiles' }, { required: 'Fee Agreement file is required' });
      setUiState(prev => ({ ...prev, showFileUploader: true }));
    } else {
      unregister('attachFiles');
      setUiState(prev => ({ ...prev, showFileUploader: false }));
    }
  }, [register, unregister, watchers.signTypes, followUpId]);

  /**
   * Loads custom radio button types
   */
  useEffect(() => {
    setUiState(prev => ({ ...prev, loadingTypes: true }));
    (async () => {
      const response = await API.get(Endpoints.CompanyTypes);
      if (response.status === 200) {
        const sortedTypes = response.data
          .map(signType => ({ ...signType, extraInfo: signType.description }))
          .sort((a, b) => a.order - b.order);
        setSignTypes(prev => ({
          ...prev,
          types: sortedTypes
        }));
        setUiState(prev => ({ ...prev, loadingTypes: false }));
      }
    })();
  }, []);

  /**
   * Handles drawer close after data was submitted
   * and only if response was successful
   */
  useEffect(() => {
    if (uiState.drawerCompleted) onCloseDrawer && onCloseDrawer();
  }, [uiState.drawerCompleted, onCloseDrawer]);

  const handleCloseDrawer = () => {
    onCloseDrawer && onCloseDrawer();
    unsavedFiles.length > 0 &&
      unsavedFiles.forEach(file => {
        deleteTemporaryAttachment(file.id);
      });
  };

  const handleCompanySignStatus = () => submitButton.current.click();
  const handleRadioGroupChange = e => setValue('signTypes', e.target.value);

  const handleFilesChange = (filesData, error) => {
    if (!error) {
      setValue('attachFiles', filesData[0].name);
      setSignTypes(prev => ({ ...prev, attachment: { ...filesData[0] } }));
    } else {
      dispatch(showAlert(error));
    }
  };

  const handleUnsavedDismountFiles = useCallback(files => setUnsavedFiles([...files]), []);
  const getReassureEndpoint = () =>
    Endpoints[followUpId ? 'CompanyTypeReassureOps' : 'CompanyTypeReassure'].replace(
      /:id/,
      companyId
    );

  const onSubmit = async formData => {
    setUiState(prev => ({ ...prev, fetchingType: true }));
    const reassureType = { company_type_id: Number(formData.signTypes) };
    signTypes.attachment && (reassureType.file_id = signTypes.attachment.id);
    followUpId && (reassureType.company_type_reassure_id = followUpId);
    const endpoint = reassure
      ? getReassurePendingEndpoint(reassure.reassureId)
      : getReassureEndpoint();
    const response = await API[reassure ? 'patch' : 'post'](endpoint, reassureType);
    /**
     * To update company type id with the last selected type id
     * when the company type change is in progress
     */
    if (reassure) {
      response.data = {
        companyInformation: {
          companyTypeReassure: {
            inProgress: {
              ...reassure,
              companyTypeId: formData.signTypes
            },
            recruiterCanRequest: true
          }
        }
      };
    }
    if (response.status === 200) {
      const { companyInformation: ci } = response.data;
      if (followUpId) {
        onUpdateCompany &&
          onUpdateCompany({
            companyTypeReassure: ci.companyTypeReassure,
            company_type_id: ci.company_type_id,
            type: { ...ci.type },
            files: [...ci.files]
          });
        setUiState(prev => ({
          ...prev,
          drawerCompleted: true,
          fetchingType: false
        }));
      } else {
        onUpdateCompany &&
          onUpdateCompany({
            companyTypeReassure: ci.companyTypeReassure
          });
        dispatch(
          showAlert({
            severity: 'success',
            title: 'Company Updated Successfully',
            body: 'Changes will be sent to validate to the Ops team'
          })
        );
        setUiState(prev => ({
          ...prev,
          taskCompleted: true,
          fetchingType: false
        }));
      }
    }
  };

  const renderForm = () => (
    <>
      {type === 'drawer' && <Text variant="h2" text="Company Summary" />}
      <Grid container justify="center" className={classes.ySpacing}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          companyData.map(item => (
            <Grid key={item.header} item xs={6}>
              <Text
                text={item.header}
                variant="subtitle2"
                className={clsx(classes.companyData, 'header')}
              />
              {item.component ? (
                item.component(item.content, clsx(classes.companyData, 'content'))
              ) : (
                <Text
                  text={item.content}
                  variant="subtitle1"
                  className={clsx(classes.companyData, 'content')}
                />
              )}
            </Grid>
          ))
        )}
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FPRadioGroup
          name="signTypes"
          label={
            !advice?.requestor
              ? 'Please select one:'
              : `${advice.requestor} changed this company's status from ${company?.type?.title} to ${advice.request}; please verify this change and upload the corresponding Fee Agreement.`
          }
          loading={uiState.loadingRequestor}
          value={watchers.signTypes}
          options={signTypes.types}
          column
          extraInfoProps={{ display: 'parens' }}
          onChange={handleRadioGroupChange}
          error={!!errors.signTypes}
          errorMessage={errors?.signTypes?.message}
          controlLabelProps={{
            classes: { label: classes.label }
          }}
          classes={{
            root: classes.radioGroupRoot,
            label: classes.radioLabel
          }}
        />
        {uiState.showFileUploader && (
          <FileUploaderOutlined
            name="attachFiles"
            placeholder="Fee Agreement File *"
            maxFiles={1}
            acceptFiles={['.pdf']}
            onFilesChange={handleFilesChange}
            onUnsavedDismount={handleUnsavedDismountFiles}
            error={!!errors.attachFiles}
            errorMessage={errors?.attachFiles?.message}
          />
        )}
        <input ref={submitButton} type="submit" hidden />
      </form>
    </>
  );

  return type === 'dialog' ? (
    <DecisionDialog
      title="Update Company Type"
      message={
        Object.keys(dialogProps).length > 0
          ? `${company.name} is currently ${
              SignTypes.ById[company?.company_type_id]
            }. If you update this company's type, it must be validated by the Ops team and will also send a notification to your Regional Director.`
          : `All companies must have their type updated. This update must be validated by the Ops team and will also send a notification to your lead.`
      }
      withButtons="Confirmation"
      confirmButtonText="Request Update"
      disableConfirmButton={reassure && reassure.companyTypeId.toString() === watchers.signTypes}
      showSeverityIcon={false}
      showCloseButton={false}
      onConfirm={handleCompanySignStatus}
      mustComplete
      taskCompleted={uiState.taskCompleted}
      loadingContent={uiState.loadingTypes}
      closeDialogOnConfirm={false}
      fetching={uiState.fetchingType}
      {...dialogProps}
    >
      {renderForm()}
    </DecisionDialog>
  ) : (
    uiState.showDrawer && (
      <DrawerContentLayout
        title="Confirm Company Type"
        containerComponent="div"
        variant="borderless"
        uiState={{
          isSaving: uiState.fetchingType
        }}
        isSaveButtonMode={false}
        primaryProps={{
          text: 'Save',
          onClick: handleCompanySignStatus,
          iconPosition: 'right',
          children: uiState.fetchingType && <CircularProgress size={20} color="inherit" />
        }}
        onClose={handleCloseDrawer}
      >
        {renderForm()}
      </DrawerContentLayout>
    )
  );
};

CompanySignStatusForm.defaultProps = {
  reassure: undefined,
  dialogProps: undefined,
  followUpId: null
};

export default CompanySignStatusForm;
