import { makeStyles } from '@material-ui/core/styles';
import { commonStyles } from 'UI/constants/styles';
import { colors } from 'UI/res';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

const sharedResetStyles = {
  margin: '0 0 0 auto',
  padding: 0,
  color: colors.middlegrey,
  textTransform: 'capitalize',
  textDecoration: 'underline'
};

export const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  filtersContent: {
    padding: theme.spacing(1, 2, 2),
    flexGrow: 1,
    overflowY: 'auto',
    'scroll-behavior': 'auto'
  },
  filterButton: {
    padding: theme.spacing(2.5, 3),
    boxShadow: commonStyles.drawerFooter.boxShadow
  },
  group: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${colors.borderColor}`,
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  topBarContainer: {
    flexShrink: 0,
    zIndex: 2,
    padding: theme.spacing(1, 2)
  },
  topBar: {
    display: 'flex',
    width: '100%'
  },
  topBarShadow: {
    boxShadow: '-3px 2px 3px #00000017'
  },
  hideShowButton: {
    marginRight: 'auto'
  },
  resetButton: {
    ...sharedResetStyles
  },
  resetActive: {
    ...sharedResetStyles,
    color: colors.success
  },
  goToTopButton: {
    width: '100%'
  },
  circularProgress: {
    color: colors.white
  },
  hiddenForm: {
    display: 'none'
  },
  shownForm: {
    display: 'block'
  },
  circularProgressFormContainer: {
    ...flexAlignCenterJustifyCenter,
    alignItems: 'center',
    height: '100%'
  }
}));
