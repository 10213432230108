// @flow
import React, { useMemo } from 'react';
import { AvatarGroup } from '@material-ui/lab';
import type { ProfileAvatarsListTypes } from 'types/app';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import ProfileAvatar from 'UI/components/molecules/ProfileAvatar';
import { SvgAdd } from 'UI/res';

import { useGroupStyles, useStyles } from './styles';

const ProfileAvatarsList = ({
  addButtonProps = {},
  options = [],
  optionsToShow = 2,
  showAddButton = false,
  size = 'small'
}: ProfileAvatarsListTypes) => {
  const classes = useStyles();
  const avatarGroupClasses = useGroupStyles({ size });

  const avatarCollections = useMemo(
    () => ({
      complete: options.slice(0, optionsToShow <= 1 ? 1 : optionsToShow),
      grouped: options.slice(optionsToShow, options.length)
    }),
    [options, optionsToShow]
  );

  return (
    <div className={classes.root}>
      {avatarCollections.complete.map(
        ({ id, title, detail, initials, email, profileAvatarProps = {} }) => (
          <ProfileAvatar
            key={id}
            aria-label="detailed-avatar"
            title={title}
            detail={detail}
            initials={initials}
            email={email}
            {...profileAvatarProps}
            showDetails
          />
        )
      )}
      {avatarCollections.grouped.length > 0 && (
        <AvatarGroup
          aria-label="avatar-list"
          spacing="small"
          classes={avatarGroupClasses}
          className={classes.groupedAvatars}
          max={4}
        >
          {avatarCollections.grouped.map(
            ({ id, initials, email, title, detail, profileAvatarProps = {} }) => (
              <ProfileAvatar
                key={id}
                aria-label="grouped-avatar"
                title={title}
                detail={detail}
                initials={initials}
                email={email}
                {...profileAvatarProps}
                showDetails={false}
              />
            )
          )}
        </AvatarGroup>
      )}
      {showAddButton && (
        <FPIconButton icon={SvgAdd} size="sm" className={classes.addButton} {...addButtonProps} />
      )}
    </div>
  );
};

export default ProfileAvatarsList;
