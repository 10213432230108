// @flow
import {
  ADD_NOTIFICATION,
  APPLY_NOTIFICATION_PARAMS,
  CHANGE_NOTIFICATION_TYPE,
  CHANGE_NOTIFICATION_VISIBILITY,
  FETCH_NOTIFICATION_FAILURE,
  FETCH_NOTIFICATION_REQUEST,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_TOTAL_SUCCESS,
  MARK_NOTIFICATION_READ,
  RESET_NOTIFICATIONS
} from 'actions/notification';
import { combineReducers } from 'redux';
import type { NotificationDomainState, NotificationUiState } from 'types/notification';
import type { Action } from 'types/redux';
import { NotificationsVisibilityOptions } from 'UI/constants/defaults';
import { nestTernary } from 'UI/utils';

const uiReducer = (
  state: NotificationUiState = {},
  action: Action = { type: '', payload: null }
): NotificationUiState => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_NOTIFICATION_REQUEST:
      return { ...state, isLoading: true, hasError: false, hasMore: false };
    case FETCH_NOTIFICATION_FAILURE:
      return { ...state, isLoading: false, hasError: true, hasMore: false };
    case FETCH_NOTIFICATION_SUCCESS:
      return { ...state, isLoading: false, hasError: false, hasMore: payload.data.length > 0 };
    default:
      return state;
  }
};

const domainReducer = (
  state: NotificationDomainState = {},
  action: Action = { type: '', payload: {} }
): NotificationDomainState => {
  const { type, payload } = action;

  switch (type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications:
          state.params.visibility !== NotificationsVisibilityOptions.Read &&
          state.params.type === payload.type
            ? [payload, ...state.notifications]
            : nestTernary(
                state.params.visibility !== NotificationsVisibilityOptions.Read &&
                  !state.params.type,
                [payload, ...state.notifications],
                [...state.notifications]
              ),
        totalUnread: Number(state.totalUnread) + 1,
        totalFilterUnread:
          state.params.visibility !== NotificationsVisibilityOptions.Read &&
          state.params.type === payload.type
            ? Number(state.totalFilterUnread) + 1
            : state.totalFilterUnread
      };
    case MARK_NOTIFICATION_READ:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          notification.id !== payload.id
            ? notification
            : { ...notification, read_on: new Date().toISOString() }
        ),
        totalUnread: Number(state.totalUnread) - 1,
        totalFilterUnread: Number(state.totalFilterUnread) - 1
      };
    case RESET_NOTIFICATIONS:
      return { ...state, params: { page: 1 }, notifications: [] };
    case CHANGE_NOTIFICATION_VISIBILITY:
      return { ...state, activeVisibility: payload, params: { page: 1 }, notifications: [] };
    case CHANGE_NOTIFICATION_TYPE:
      return { ...state, activeType: payload, params: { page: 1 }, notifications: [] };
    case APPLY_NOTIFICATION_PARAMS:
      return { ...state, params: payload };
    case FETCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: [...state.notifications, ...payload.data],
        totalUnread: payload.totalUnread,
        totalFilterUnread: payload.totalFilterUnread,
        totalFilterRead: payload.totalFilterRead,
        filterTotal: payload.filterTotal
      };
    case FETCH_TOTAL_SUCCESS:
      return {
        ...state,
        totalUnread: payload.totalUnread,
        totalFilterUnread: 0
      };
    default:
      return state;
  }
};
const notificationReducer = combineReducers({ domain: domainReducer, ui: uiReducer });

export default notificationReducer;
