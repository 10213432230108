// @flow
import React, { useState } from 'react';
import { Calendar } from 'react-date-plus-time-range';
import moment from 'moment';
import TextBox from 'UI/components/atoms/TextBox';
import FooterActionsControlsLegacy from 'UI/components/molecules/FooterActionsControlsLegacy';
import { DateFormats } from 'UI/constants/defaults';
import { dateFormatter } from 'UI/utils';

import { styles, useStyles } from './styles';

interface DatePickerProps {
  defaultDate: Date;
  onApplyClick: () => any;
}

const DatePicker = ({ defaultDate, onApplyClick }: DatePickerProps) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(moment(defaultDate).format(DateFormats.SimpleDate));

  const handleOpen = () => setIsOpen(true);

  const handleChange = newDate => setDate(dateFormatter(newDate, formats => formats.SimpleDate));

  const handleCancel = () => {
    setIsOpen(false);
    setDate(dateFormatter(defaultDate, formats => formats.SimpleDate));
  };

  const handleApply = () => {
    setIsOpen(false);
    onApplyClick && onApplyClick(dateFormatter(date, formats => formats.SimpleDateHyphen));
  };

  return (
    <>
      <TextBox
        name="datePicker"
        label="Date Picker"
        value={date}
        onFocus={handleOpen}
        inputProps={{
          style: styles.datePickerField
        }}
      />
      {isOpen && (
        <div className={classes.datePickerContainer}>
          <div className={classes.calendarContainer}>
            <Calendar
              date={date}
              showSelectionPreview
              direction="horizontal"
              onChange={handleChange}
            />
            <div className={classes.calendarToolbar}>
              <FooterActionsControlsLegacy
                secondaryAction={handleCancel}
                saveAction={false}
                secondaryProps={{ size: 'small' }}
                primaryProps={{
                  onClick: handleApply,
                  text: 'Apply filters',
                  size: 'small'
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DatePicker;
