// @flow
import React from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { useStyles } from './ChartLegend.styles';

interface ChartLegendProps {
  mainColor: string;
  mainTitle: string;
  secondaryColor: string;
  secondaryTitle: string;
}

export const ChartLegend = ({
  mainColor,
  mainTitle,
  secondaryColor,
  secondaryTitle
}: ChartLegendProps) => {
  const styles = useStyles({
    mainColor,
    secondaryColor
  });
  return (
    <div aria-label="chart-legend" className={styles.flexAlignCenter}>
      <div className={styles.legendContainer}>
        <div className={clsx(styles.legendColor, styles.mainColor)} />
        <Typography>{mainTitle}</Typography>
      </div>

      <div className={styles.legendContainer}>
        <div className={clsx(styles.legendColor, styles.secondaryColor)} />
        <Typography>{secondaryTitle}</Typography>
      </div>
    </div>
  );
};
