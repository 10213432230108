// @flow
import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InfoLabel, { InfoLabelModes } from 'UI/components/molecules/InfoLabel';

import { gridValues, useInfoLabelClasses } from './styles';

type InfoLabelsGridProps = {
  labels: Array<Object>,
  loading: boolean,
  isFlexibleGrid: boolean
};

const InfoLabelsGrid = ({ labels, loading, isFlexibleGrid }: InfoLabelsGridProps) => {
  const classes = useInfoLabelClasses();
  const fixedGrid = { ...gridValues, className: classes.infoLabel };
  const flexibleGrid = { ...gridValues };

  const defaultLayoutProps = isFlexibleGrid ? flexibleGrid : fixedGrid;

  return (
    <Grid container spacing={3}>
      {labels.map(items => {
        const itemsConfig = Array.isArray(items) ? items : [items];

        return itemsConfig.map(item => {
          return item !== undefined ? (
            <React.Fragment key={`${item.title}-${item.description}`}>
              <Grid
                aria-label={`${item.title} container`}
                component="dl"
                item
                role="group"
                {...defaultLayoutProps}
                {...item.config}
              >
                <InfoLabel
                  canCopy={item.mode === InfoLabelModes.EXTERNAL_LINK}
                  cropped={false}
                  isLoading={loading}
                  variant="body1"
                  {...item}
                />
              </Grid>
              {item.shouldAddDivider && <Divider />}
            </React.Fragment>
          ) : (
            []
          );
        });
      })}
    </Grid>
  );
};

InfoLabelsGrid.defaultPros = {
  labels: [],
  loading: false,
  isFlexibleGrid: true
};

export default InfoLabelsGrid;
