// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getRecruiterInfo } from 'features/command-center/services';
import { capitalizeName } from 'features/command-center/utils';
import usePageTitle from 'hooks/usePageTitle';
import moment from 'moment';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import TitleLabel from 'UI/components/atoms/TitleLabel';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { AppName, DateFormats } from 'UI/constants/defaults';
import { SvgNoCandidates } from 'UI/res';

import { LayoutSkeleton } from '../../shared';
import { Layout } from '../Layout';

import { HealthStatusChip } from './components';
import { Breadcrumb, Path } from './RecruiterLayout.styled';
import { formatTenure, getTeamName } from './RecruiterLayout.utils';

export const RecruiterLayout = ({ children, onRefresh, recruiterId, onRecruiterLoaded }) => {
  const [recruiterData, setRecruiterData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const startDate = moment(recruiterData?.hireDate).format(DateFormats.SimpleDate);

  usePageTitle({
    title: `${AppName} | ${capitalizeName(recruiterData?.recruiterName)}`,
    isLoading
  });

  const getData = useCallback(async () => {
    const requestParams = { employeeIds: recruiterId };
    try {
      setIsLoading(true);
      const response = await getRecruiterInfo(requestParams);
      setRecruiterData(response?.data ?? response);
      onRecruiterLoaded && onRecruiterLoaded(response?.data ?? response);
    } finally {
      setIsLoading(false);
    }
  }, [recruiterId, onRecruiterLoaded]);

  useEffect(() => {
    getData();
  }, [getData]);

  const title = recruiterData ? (
    <Grid alignItems="center" container direction="row" spacing={1}>
      <Grid item>
        <TitleLabel text={capitalizeName(recruiterData?.recruiterName)} backNavigation />
      </Grid>
      <Grid item>
        {recruiterData?.healthStatus && (
          <HealthStatusChip healthStatus={recruiterData.healthStatus} />
        )}
      </Grid>
    </Grid>
  ) : null;

  const subtitle = recruiterData ? (
    <Grid container item direction="row">
      <Typography variant="body1" color="textSecondary">
        <strong>Start Date:</strong> {startDate} ({formatTenure(recruiterData?.hireDate)}) /{' '}
        <strong>Location:</strong> {recruiterData?.location}
      </Typography>
    </Grid>
  ) : null;

  const renderEmptyState = (
    <EmptyPlaceholder
      subtitle={strings.commandCenter.activityPage.notFound.subtitle}
      title={strings.commandCenter.activityPage.notFound.title}
      customEmptyState={<SvgNoCandidates height={400} />}
    />
  );

  return (
    <Layout
      isBackNavigationEnabled
      isLoading={isLoading}
      onRefresh={recruiterData && onRefresh}
      path={
        recruiterData && (
          <Path>
            {strings.commandCenter.title} /{' '}
            <Breadcrumb component={Link} to={EntityRoutes.CommandCenterOverview}>
              {strings.commandCenter.teamPage.path}
            </Breadcrumb>
            {' / '}
            <Breadcrumb
              component={Link}
              to={EntityRoutes.CommandCenterActivityTeam.replace(
                ':id',
                recruiterData?.managerEmpNo
              )}
            >
              {getTeamName(recruiterData?.managerName)}
            </Breadcrumb>
          </Path>
        )
      }
      subtitle={subtitle}
      title={title}
    >
      {isLoading ? (
        <LayoutSkeleton />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {recruiterData ? children : renderEmptyState}
          </Grid>
        </Grid>
      )}
    </Layout>
  );
};
