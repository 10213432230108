import { colors } from 'UI/res';

export const CompanyStatus = {
  unsigned: {
    style_class_name: colors.inactive,
    title: 'Not Signed'
  },
  signed: {
    style_class_name: colors.active,
    title: 'Signed'
  }
};

export const FeeAgreementStatusGroup = {
  PendingSignature: 1,
  Signed: 2,
  PendingValidation: 3,
  Declined: 4,
  Void: 5,
  Expired: 6,
  Canceled: 7
};

export const FeeAgreementStatus = {
  PendingHASignature: 1,
  PendingGpacSignature: 2,
  Signed: 3,
  PendingCoachValidation: 4,
  PendingOpsValidation: 5,
  DeclinedByCoach: 6,
  DeclinedByOperations: 7,
  Expired: 8,
  Void: 9,
  Canceled: 10,
  PendingOpsTemplate: 100
};

export const FeeAgreementSigningProviders = {
  Primary: 'helloSign',
  Backup: 'docuSign'
};

const statusesForPendingValidations = [
  FeeAgreementStatus.PendingCoachValidation,
  FeeAgreementStatus.PendingOpsValidation,
  FeeAgreementStatus.DeclinedByCoach,
  FeeAgreementStatus.DeclinedByOperations
];

const statusesForPendingSignatures = [
  FeeAgreementStatus.PendingHASignature,
  FeeAgreementStatus.PendingGpacSignature,
  FeeAgreementStatus.Void,
  FeeAgreementStatus.Expired
];

const statusesForSigned = [FeeAgreementStatus.Signed];

export const FeeAgreementStatusBySection = {
  PendingValidations: statusesForPendingValidations,
  PendingSignatures: statusesForPendingSignatures,
  Signed: statusesForSigned
};

export const FeeAgreementStatusBySectionForFilters = {
  ...FeeAgreementStatusBySection,
  PendingValidations: [...statusesForPendingValidations, FeeAgreementStatus.Canceled]
};

export const FeeAgreementTabs = {
  PendingValidations: 0,
  PendingSignatures: 1,
  Signed: 2
};

export const FeeAgreementSortColumnBySection = {
  [FeeAgreementTabs.PendingValidations]: 'created_at',
  [FeeAgreementTabs.PendingSignatures]: 'validated_date',
  [FeeAgreementTabs.Signed]: 'signed_date'
};

export const FeeAgreementPeriodColumnBySection = {
  [FeeAgreementTabs.PendingValidations]: 'created_at',
  [FeeAgreementTabs.PendingSignatures]: 'validated_date',
  [FeeAgreementTabs.Signed]: 'signed_date'
};

export const AdditionalRecruiterType = {
  Collaborator: 'collaborator',
  Accountable: 'accountable',
  Main: 'main'
};

export const AdditionalRecruiterStatus = {
  Requested: 'requested',
  Approved: 'approved',
  Declined: 'declined',
  Removed: 'removed'
};

export const FeeAgreementFields = {
  Percentage: 'fee_percentage',
  GuaranteeDays: 'guarantee_days',
  Verbiage: 'verbiage_changes',
  FlatAmount: 'flat_fee_amount',
  NumberOfPayments: 'number_of_payments',
  PaymentTermsMode: 'payment_term_id',
  PaymentDays: 'payment_days',
  PaymentTerms: 'payment_schedule_terms'
};

export const FeeAgreementMaxCopies = 3;

export const CallModes = {
  Inbound: 'inbound',
  OutBound: 'outBound',
  MissedCall: 'missedCall'
};

export const SendoutsTypes = {
  Sendout: 1,
  Sendover: 2
};

export const SendoutStatus = {
  Active: 1,
  Placement: 2,
  NoOffer: 3,
  Declined: 4,
  FallOff: 8
};

export const SendoverStatus = {
  Active: 5,
  NoOffer: 6,
  Declined: 7
};

export const RequestStatus = {
  Idle: 'idle',
  Loading: 'loading',
  Error: 'error',
  Finished: 'finished',
  Success: 'success'
};

export const PlacementStatus = {
  PendingValidation: 1,
  PendingUpdate: 2,
  PendingInvoice: 3,
  Invoiced: 4,
  FallOff: 5,
  PendingRegionalValidation: 6,
  PendingFallOff: 7,
  PendingRevertFallOff: 8,
  PendingEstimateCreation: 9,
  PendingEstimateApproval: 10,
  FullyPaid: 11,
  PendingFinanceValidation: 12
};

export const PlacementTabs = {
  PendingValidations: 'pendingValidations',
  Estimates: 'estimates',
  PendingInvoice: 'pendingInvoice',
  Invoiced: 'invoiced',
  FallOff: 'falloff',
  All: 'all'
};

export const PlacementStatusBySection = {
  [PlacementTabs.PendingValidations]: [
    PlacementStatus.PendingValidation,
    PlacementStatus.PendingRegionalValidation,
    PlacementStatus.PendingUpdate
  ],
  [PlacementTabs.PendingInvoice]: [PlacementStatus.PendingInvoice],
  [PlacementTabs.Invoiced]: [PlacementStatus.Invoiced, PlacementStatus.FullyPaid],
  [PlacementTabs.FallOff]: [
    PlacementStatus.FallOff,
    PlacementStatus.PendingFallOff,
    PlacementStatus.PendingRevertFallOff
  ],
  [PlacementTabs.Estimates]: [
    PlacementStatus.PendingEstimateCreation,
    PlacementStatus.PendingEstimateApproval,
    PlacementStatus.PendingFinanceValidation
  ],
  [PlacementTabs.All]: [
    PlacementStatus.PendingValidation,
    PlacementStatus.PendingRegionalValidation,
    PlacementStatus.PendingUpdate,
    PlacementStatus.PendingEstimateCreation,
    PlacementStatus.PendingEstimateApproval,
    PlacementStatus.PendingFinanceValidation,
    PlacementStatus.PendingInvoice,
    PlacementStatus.Invoiced,
    PlacementStatus.FullyPaid,
    PlacementStatus.FallOff,
    PlacementStatus.PendingFallOff,
    PlacementStatus.PendingRevertFallOff
  ]
};

export const PlacementSplitTypes = {
  Company: 'company',
  Candidate: 'candidate'
};

export const PlacementSortColumnBySection = {
  [PlacementTabs.PendingValidations]: 'created_at',
  [PlacementTabs.PendingInvoice]: 'estimate_approval_date',
  [PlacementTabs.Invoiced]: 'invoiced_date',
  [PlacementTabs.FallOff]: 'fall_off_date',
  [PlacementTabs.Estimates]: 'estimate_creation_date',
  [PlacementTabs.All]: 'created_at'
};

export const PlacementPeriodColumnBySection = {
  [PlacementTabs.PendingValidations]: 'created_at_date',
  [PlacementTabs.PendingInvoice]: 'estimate_approval_date',
  [PlacementTabs.Invoiced]: 'invoice_date',
  [PlacementTabs.FallOff]: 'fall_off_date',
  [PlacementTabs.Estimates]: 'estimate_date',
  [PlacementTabs.All]: 'created_at_date'
};

export const FileStatus = {
  New: 'new',
  Existing: 'existing'
};

export const UIStatus = {
  Loading: 'loading',
  Error: 'error',
  Empty: 'empty',
  Success: 'success',
  Editing: 'editing',
  Saving: 'saving',
  Default: 'default'
};

export const SeverityBySimilarity = {
  Exact: 'error',
  None: 'none',
  Similar: 'warning'
};
