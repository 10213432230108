import React from 'react';
import Grid from '@material-ui/core/Grid';
import Text from 'UI/components/atoms/Text';
import { getId } from 'UI/utils';

import { useDialogBodyStyles } from './style';

const DialogBody = ({ itemToRestore }) => {
  const classes = useDialogBodyStyles();
  const fontSizes = {
    details: 16,
    extraInfo: 14
  };

  const details = [
    { field: 'Email', prop: 'email' },
    { field: 'Full Name', prop: 'item_full_name' },
    { field: 'Company', prop: 'company_name' }
  ];

  return (
    <Grid container direction="column" className={classes.root}>
      {details.map(({ field, prop }) => (
        <Grid key={getId()} container item wrap="nowrap">
          <Text variant="h2" fontSize={fontSizes.details} text={field} />
          <Text
            variant="body1"
            fontSize={fontSizes.details}
            cropped
            text={itemToRestore[prop] || 'N/A'}
          />
        </Grid>
      ))}
      <Grid container item className={classes.warning}>
        <Text variant="h2" fontSize={fontSizes.extraInfo} text="IMPORTANT:" />
        <Text
          variant="subtitle2"
          fontSize={fontSizes.extraInfo}
          text="This email will receive bulks again."
        />
      </Grid>
    </Grid>
  );
};

export default DialogBody;
