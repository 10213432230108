// @flow
import { makeActionCreator } from './index';

export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const SIGNIN_USER = 'SIGNIN_USER';

export const SHOW_CONFIRMATION = 'SHOW_CONFIRMATION';
export const HIDE_CONFIRMATION = 'HIDE_CONFIRMATION';

export const SHOW_FILE = 'SHOW_FILE';
export const HIDE_FILE = 'HIDE_FILE';

export const SHOW_FILE_EXPLORER = 'SHOW_FILE_EXPLORER';
export const HIDE_FILE_EXPLORER = 'HIDE_FILE_EXPLORER';

export const DISPLAY_VERSION_BAR = 'DISPLAY_VERSION_BAR';
export const UPDATE_VERSION = 'UPDATE_VERSION';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const TOGGLE_SIDEBAR_PREFERENCE = 'TOGGLE_SIDEBAR_PREFERENCE';

export const TOGGLE_EXPAND_TABLE = 'TOGGLE_EXPAND_TABLE';

export const showAlert = makeActionCreator(SHOW_ALERT, 'payload');
export const hideAlert = makeActionCreator(HIDE_ALERT, 'payload');
export const signinUser = makeActionCreator(SIGNIN_USER, 'payload');

export const confirm = makeActionCreator(SHOW_CONFIRMATION, 'payload');
export const closeConfirmation = makeActionCreator(HIDE_CONFIRMATION);

export const showFile = makeActionCreator(SHOW_FILE, 'payload');
export const hideFile = makeActionCreator(HIDE_FILE);

export const showFileExplorer = makeActionCreator(SHOW_FILE_EXPLORER, 'payload');
export const hideFileExplorer = makeActionCreator(HIDE_FILE_EXPLORER);

export const displayVersionBar = makeActionCreator(DISPLAY_VERSION_BAR, 'payload');
export const updateVersion = makeActionCreator(UPDATE_VERSION, 'payload');

export const toggleSidebar = makeActionCreator(TOGGLE_SIDEBAR, 'payload');

export const toggleExpandTableAction = makeActionCreator(TOGGLE_EXPAND_TABLE, 'payload');
