// @flow
import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { confirm, showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import strings from 'strings';
import { RowActions } from 'UI/constants/defaults';
import formatReferenceCheckResponse from 'UI/pages/CandidateProfile/utils/format-reference-check-response';
import { getErrorMessage } from 'UI/utils';

import { deleteReference, getReferencesByCandidateId } from '../ReferenceCheckTabLayout.services';
import { getActionPermission } from '../utils';

const { deleted: CONFIRM_DELETED_SUCCESSFULLY, confirmDelete: CONFIRM_DELETE_MESSAGE } =
  strings.inventoryProfiles.sections.tabs.referenceCheck;
const CONFIRM_MESSAGE = strings.shared.ui.confirm;

const apiVersion = 2;

type Params = {
  candidateId: string,
  currentUser: Object,
  onEditItemClick: () => void,
  onViewItemClick: () => void,
  additionalRecruiters: Array<Object>,
  shouldFetch?: boolean,
  recruiterId: number
};

const useReferenceCheckTable = ({
  candidateId,
  recruiterId,
  currentUser,
  onEditItemClick,
  onViewItemClick,
  additionalRecruiters = [],
  shouldFetch = false
}: Params) => {
  const dispatch = useDispatch();
  const [shouldRefreshData, setShouldRefreshData] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [activeItem, setActiveItem] = useState({
    id: null,
    status: ''
  });

  const retrieveData = useCallback(async () => {
    const response = await getReferencesByCandidateId(candidateId);
    return formatReferenceCheckResponse(response);
  }, [candidateId]);

  const { state: referencesState } = useFetchWithStatus(
    null,
    null,
    null,
    apiVersion,
    shouldFetch && retrieveData
  );

  const paramsExtender = useCallback(
    () => ({
      id: candidateId,
      cache: false
    }),
    [candidateId]
  );

  const toggleDataRefresh = useCallback(
    (val: boolean) => () => {
      setShouldRefreshData(val);
    },
    []
  );

  const handleDelete = (itemId: string, callback: () => void) => {
    dispatch(
      confirm({
        title: CONFIRM_MESSAGE,
        message: CONFIRM_DELETE_MESSAGE,
        mustComplete: true,
        onConfirm: async ok => {
          if (!ok) return Promise.resolve();

          return deleteReference(itemId)
            .then(async response => {
              if (response.status === HTTPStatusCodes.Ok) {
                dispatch(
                  showAlert({
                    severity: 'success',
                    title: CONFIRM_DELETED_SUCCESSFULLY,
                    body: CONFIRM_DELETED_SUCCESSFULLY
                  })
                );

                callback && callback();
              }
            })
            .catch(error => {
              dispatch(
                showAlert({
                  severity: 'error',
                  title: 'Error',
                  body: getErrorMessage(error)
                })
              );
            });
        }
      })
    );
  };

  const tableActions = [
    {
      name: RowActions.View,
      callback: (item, _, data) => {
        if (data) {
          const currentIndex = data.findIndex(({ id }) => id === item.id);

          const isEditable = getActionPermission({
            action: RowActions.Edit,
            additionalRecruiters,
            loggedUser: currentUser
          })({ rowIndex: currentIndex }, data);

          setCanEdit(isEditable);
        }

        setActiveItem(prevState => ({
          ...prevState,
          id: item.id,
          status: item.referenceStatus.type
        }));
        onViewItemClick();
      },
      checkIfShouldBeEnabled: getActionPermission({
        action: RowActions.View,
        additionalRecruiters,
        loggedUser: currentUser
      })
    },
    {
      name: RowActions.Edit,
      callback: item => {
        setActiveItem(prevState => ({
          ...prevState,
          id: item.id,
          status: item.referenceStatus.type
        }));
        onEditItemClick();
      },
      checkIfShouldBeEnabled: getActionPermission({
        action: RowActions.Edit,
        additionalRecruiters,
        loggedUser: currentUser
      })
    },
    {
      name: RowActions.Delete,
      callback: (itemId: string) => {
        handleDelete(itemId, toggleDataRefresh(true));
      },
      pickId: true,
      checkIfShouldBeEnabled: getActionPermission({
        action: RowActions.Delete,
        additionalRecruiters,
        loggedUser: currentUser
      })
    }
  ];

  const customDataTableProps = useRef({
    paramsExtender,
    onFetchedData: toggleDataRefresh(false)
  });

  const isTheUserLoggedTheOwner = currentUser?.id === recruiterId;
  const isTheUserLoggedCollaborator = additionalRecruiters.some(
    ({ recruiter_id: collaboratorId }) => collaboratorId === currentUser.id
  );

  return {
    activeItem,
    shouldRefreshData,
    toggleDataRefresh,
    tableActions,
    customDataTableProps: customDataTableProps.current,
    referencesState,
    canEdit,
    isAddNewButtonEnabled: isTheUserLoggedTheOwner || isTheUserLoggedCollaborator
  };
};

export default useReferenceCheckTable;
