// @flow

import queryString from 'query-string';
import type { OperationResult } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';

import { sendRequest } from './utils';

export const getSendoutById = async (
  id: number,
  scopes: string[],
  relations: string[]
): Promise<OperationResult> => {
  const params = {
    scopes: scopes || [],
    relations: relations || []
  };

  const queryParams = queryString.stringify(params, { arrayFormat: 'comma' });

  return sendRequest(`${Endpoints.Sendouts}/${id}?${queryParams}`, 'get');
};

export const deleteSendoutById = async (id: number): Promise<OperationResult> =>
  sendRequest(`${Endpoints.Sendouts}/${id}`, 'delete');

export const fetchDuplicates = async (params: any, options: any): Promise<OperationResult> => {
  const queryParams = queryString.stringify(params, { arrayFormat: 'comma' });
  return sendRequest(
    `${Endpoints.SendoutDuplicates}?${queryParams}`,
    'get',
    [],
    options?.successBody,
    options?.successTitle
  );
};
