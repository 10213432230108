// @flow
import React from 'react';
import LeadBoard from 'UI/components/templates/RecruiterProfile/Leadboard';
import { WidgetRenderers } from 'UI/utils/renderers/widgetRenderers';

import {
  CashIn,
  ChannelPartner,
  LightWeightTable,
  Milestones,
  PILMapContent,
  RecruiterProduction,
  StatsBuilder
} from './componentRenderers';
import { Timeline } from './Timeline';

export const RecruiterProfileRenderers = {
  ...WidgetRenderers,
  leadBoard: ({
    initials,
    userEmail,
    teamName,
    userName,
    statsValues: { week, team, region, cashInPosition }
  }) => (
    <LeadBoard
      initials={initials}
      userEmail={userEmail}
      teamName={teamName}
      userName={userName}
      statsValues={{ week, team, region, cashInPosition }}
    />
  ),
  timeLine: ({ items }) => <Timeline items={items} />,
  pilMap: ({ tree, title, countries, markers }) => (
    <PILMapContent tree={tree} title={title} countries={countries} markers={markers} />
  ),
  channelPartner: ({ cashAmount, data }) => (
    <ChannelPartner cashAmount={cashAmount} descriptionItems={data} />
  ),
  cashIn: ({ lastMonth, summaryCashIn, cashInAmount }) => (
    <CashIn cashInAmount={cashInAmount} summaryCashIn={summaryCashIn} lastMonth={lastMonth} />
  ),
  production: ({ productionData, month }) => (
    <RecruiterProduction productionData={productionData} month={month} />
  ),
  statsBuilder: ({ dataConfig }) => <StatsBuilder dataConfig={dataConfig} />,
  table: ({ columns, data, isLoading }) => (
    <LightWeightTable columns={columns} data={data} isLoading={isLoading} />
  ),
  milestones: ({ yearProduction, historicProduction, alphaPacTarget }) => (
    <Milestones
      yearProduction={yearProduction}
      historicProduction={historicProduction}
      alphaPacTarget={alphaPacTarget}
    />
  )
};
