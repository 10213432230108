// @flow
import React from 'react';
import { colors } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { THEME as theme } from 'GlobalStyles';
import type { FPActionButtonProps } from 'types/app';

import FPIcon from '../FPIcon';

import { styles, useCellStyles, useStyles } from './styles';

const { primary } = theme.palette;

const FPActionButton = ({
  children,
  icon,
  iconMode,
  iconPosition,
  iconProps,
  onClick,
  size,
  style,
  text,
  variant,
  ...rest
}: FPActionButtonProps) => {
  const iconPositions = {
    right: 'right',
    left: 'left',
    none: 'none'
  };

  const classes = useStyles({
    variant,
    size
  });

  const iconDefinitions = {
    family: {
      custom: 'custom',
      streamline: 'streamline'
    },
    iconSize: {
      small: 16,
      medium: 20,
      large: 24
    }
  };

  const colorValue = type =>
    iconMode === iconDefinitions.family[type]
      ? `${variant === 'outlined' ? primary.success : colors.white}`
      : null;

  const sizeValue = iconDefinitions.iconSize[size];

  const iconToRender = (position: string) =>
    iconPosition === position ? (
      <>
        {children || (
          <FPIcon
            component={icon}
            fill={colorValue(iconDefinitions.family.custom)}
            color={colorValue(iconDefinitions.family.streamline)}
            style={!children && !icon ? styles.emptyDiv : null}
            size={sizeValue}
            {...iconProps}
          />
        )}
      </>
    ) : (
      variant !== 'text' && <FPIcon size={sizeValue} style={styles.emptyDiv} />
    );

  const getIcon = position => (icon || children ? iconToRender(position) : null);

  const handleClick = e => onClick && onClick(e);
  return (
    <Button
      variant={variant}
      classes={classes}
      onClick={handleClick}
      style={style}
      endIcon={getIcon(iconPositions.right)}
      startIcon={getIcon(iconPositions.left)}
      size={size}
      {...rest}
    >
      {text.trim()}
    </Button>
  );
};

/** Simplified version for use in listings with no performance issues */
export const FPCellButton = ({ text, onClick, color = 'primary', ...rest }) => {
  const classes = useCellStyles();

  return (
    <Button
      color={color}
      variant="contained"
      classes={classes}
      onClick={onClick}
      size="small"
      {...rest}
    >
      {text}
    </Button>
  );
};

FPActionButton.defaultProps = {
  children: undefined,
  color: 'primary',
  iconMode: 'custom',
  iconPosition: 'left',
  iconProps: null,
  onClick: () => {},
  size: 'medium',
  style: {},
  text: '',
  type: 'button',
  variant: 'contained'
};

export default FPActionButton;
