import React from 'react';
import {
  Drawers,
  FinancialProductivityStatsSummary,
  HealthDistribution,
  Layout,
  PDAByTenure,
  ProductionCashInByMonth,
  RegionalDirectorCards,
  TeamMembersHealth
} from 'features/command-center';
import { DrawerManagerProvider } from 'features/command-center/contexts';
import { useRefreshStats } from 'features/command-center/hooks';
import usePageTitle from 'hooks/usePageTitle';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import strings from 'strings';
import { PageTitles } from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';

import { ContentWrapper } from './Overview.styled';

export const Overview = () => {
  usePageTitle({ title: PageTitles.CommandCenterOverview });
  const { refreshData, refreshKey } = useRefreshStats();
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const canDoDrillDown = checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterDrillDown);

  return (
    <Layout
      title={strings.commandCenter.overview.title}
      path={strings.commandCenter.title}
      onRefresh={refreshData}
    >
      <DrawerManagerProvider key={refreshKey}>
        <ContentWrapper>
          <HealthDistribution
            hasDrillDown={canDoDrillDown}
            requestParams={canDoDrillDown && { version: 1 }}
          />
          {checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterPDA) && <PDAByTenure />}
          <TeamMembersHealth />
          <FinancialProductivityStatsSummary />
          <ProductionCashInByMonth />
          <RegionalDirectorCards />
          <Drawers />
        </ContentWrapper>
      </DrawerManagerProvider>
    </Layout>
  );
};
