import { styled } from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';

export const DefaultLevelTreeItem = styled(TreeItem)(({ theme }) => ({
  '&.MuiTreeItem-root > .MuiTreeItem-content': {
    padding: theme.spacing(0.5, 0)
  },
  '&.MuiTreeItem-root > .MuiTreeItem-content .MuiTreeItem-label': {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightMedium
  }
}));

export const CustomLevelTreeItem = styled(DefaultLevelTreeItem)(({ theme }) => ({
  '&.MuiTreeItem-root > .MuiTreeItem-content': {
    padding: theme.spacing(0.5, 0)
  },
  '&.MuiTreeItem-root > .MuiTreeItem-content .MuiTreeItem-label': {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightRegular
  }
}));
