import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import HighlightBox from 'UI/components/molecules/HighlightBox';

import { useStyles } from './styles';

const SendoutDialogVerification = ({ body, tags }) => (
  <HighlightBox text={body} highlightData={tags || []} />
);

const SendoutDialogConfirmation = props => {
  const { company, jobOrder, candidate, accountable, candidateRecruiter } = props || {};
  const labelsData = [
    { title: 'Company', value: company },
    { title: 'Job Order', value: jobOrder },
    { title: 'Job Order Recruiter', value: accountable },
    { title: 'Candidate', value: candidate },
    { title: 'Candidate Recruiter', value: candidateRecruiter }
  ];

  const classes = useStyles();

  return (
    <div>
      {labelsData.map(each => (
        <ConfirmationLabel {...each} key={each.title} />
      ))}

      <Typography component="span" variant="body2" className={classes.topSeparation}>
        <b>Sending notification to:</b> Operations, Sendouts Team, Recruiters Teams, Recruiters in
        the same inventory&apos;s industry and Recruiters Involved.
      </Typography>
    </div>
  );
};

const commonProps = {
  direction: 'row',
  justify: 'space-between'
};
const ConfirmationLabel = ({ title, value }) => {
  const classes = useStyles();
  return (
    <Grid {...commonProps} className={classes.labelWrapper} container>
      <Grid xs="4">
        <Typography variant="body1"> {title}:</Typography>
      </Grid>
      <Grid xs="8">
        <Typography variant="body2">{value || '---'}</Typography>
      </Grid>
    </Grid>
  );
};

export default {
  Verification: SendoutDialogVerification,
  Confirmation: SendoutDialogConfirmation
};
