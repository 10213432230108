import { makeStyles } from '@material-ui/core/styles';

const QuickViewDrawerWidth = 980;

export const useStyles = makeStyles(
  theme => ({
    layout: {
      margin: 0
    },
    drawerContainer: {
      padding: 0,
      overflowY: 'auto',
      flexGrow: 1
    },
    drawerPanel: {
      width: QuickViewDrawerWidth,
      [theme.breakpoints.down(1024)]: {
        width: '80%'
      }
    },
    gridContainer: {
      width: '80%'
    }
  }),
  { classNamePrefix: 'FPQuickview' }
);
