import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';
import { input } from 'UI/constants/dimensions';
import { colors } from 'UI/res';

export const useChipStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.componentFill,
    justifyContent: 'space-between',
    display: 'flex',
    margin: theme.spacing(0.25)
  }
}));

export const useAutocompleteStyles = makeStyles(theme => ({
  inputRoot: {
    borderColor: colors.inactiveInputText,
    borderRadius: input.borderRadius,
    minHeight: input.height,
    padding: '5px 60px 5px 5px !important',
    backgroundColor: colors.transparent
  },
  root: {
    ...theme.input,
    display: 'flex',
    alignItems: 'flex-start',
    margin: ({ noMargin }) => (noMargin ? 'unset' : theme.spacing(1.25, 0))
  },
  input: {
    flex: 1,
    display: 'flex',
    padding: '0px 0px 0px 6px !important'
  },
  groupLabel: {
    color: colors.black,
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '32px'
  },
  option: {
    fontWeight: 200
  },
  loading: {
    position: 'absolute',
    right: 42
  }
}));

export const useCreateButtonStyles = makeStyles(theme => ({
  createButton: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: theme.spacing(0.5, 0)
  },
  createButtonDivider: {
    borderBottom: `1px solid ${colors.grey}`
  },
  createButtonIcon: {
    marginRight: 8
  },
  createButtonText: {
    color: colors.success,
    margin: 0
  },
  retryButton: {
    margin: theme.spacing(0.25, 0, 0, 0.25)
  }
}));

export const useAutocompleteStylesDisabled = makeStyles(() => ({
  inputRoot: {
    borderRadius: input.borderRadius,
    maxHeight: `${input.height}`,
    padding: '0px !important',
    backgroundColor: colors.transparent
  },
  input: {
    display: 'none'
  },
  root: {
    '& fieldset': {
      border: `1px solid ${colors.black}`
    }
  }
}));

export const useChipStylesDisabled = makeStyles(theme => ({
  root: {
    backgroundColor: colors.appBackground,
    width: '27%',
    margin: 8,
    justifyContent: 'space-between',
    display: 'flex',
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 14
  }
}));

export const statusOption = {
  marginLeft: THEME.spacing(0.5)
};
