// @flow

import { defaultProfileColumnsConfig } from 'UI/constants/defaults';

export const extraNoteRenderers = {
  industry: value => value?.industry?.title,
  location: value => `${value?.address?.city?.state?.title}, ${value?.address?.city?.state?.slug} `
};

export const notesColumns = [
  {
    name: 'id',
    options: {
      display: 'excluded'
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'default'
    }
  },
  {
    name: 'user.initials',
    label: 'Created by',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'default'
    }
  },
  {
    name: 'body',
    label: 'Description',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'html'
    }
  },
  {
    name: 'created_at',
    label: 'Creation Date',
    options: {
      ...defaultProfileColumnsConfig,
      renderer: 'date'
    }
  },
  {
    name: 'updated_at',
    label: 'Last Updated',
    options: {
      display: true,
      renderer: 'date'
    }
  }
];
