import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  theme => ({
    cardContainer: {
      position: 'sticky',
      top: 20
    },
    contentContainer: {
      minHeight: 420,
      maxHeight: '70vh',
      overflow: 'auto'
    },
    descriptionContainer: {
      marginBottom: theme.spacing(6)
    },
    descripton: {
      whiteSpace: 'break-spaces',
      marginBottom: theme.spacing(1)
    },
    skeleton: {
      marginBottom: theme.spacing(1)
    }
  }),
  { classNamePrefix: 'pil-information' }
);
