// @flow
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import strings from 'strings';
import CustomDatePicker from 'UI/components/atoms/CustomDatePicker';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import SaveButton from 'UI/components/atoms/SaveButton';
import TextBox from 'UI/components/atoms/TextBox';
import { UIStatus } from 'UI/constants/status';

import useCollectionsForm from './hooks/useCollectionsForm';
import { BoolEnum, FormFieldsMap } from './Collections.fields';
import { useStyles } from './Collections.styles';

const Form = ({
  commitDate,
  commitAmount,
  commitToPay,
  id,
  notes,
  onCancel,
  onSubmitSucceed,
  onViewClick,
  placementId,
  rowIndex
}) => {
  const styles = useStyles();

  const {
    dirty,
    formValues,
    handleView,
    handleCancel,
    handleCheckToggle,
    handleFieldChange,
    handleSubmit,
    onSubmit,
    saveState
  } = useCollectionsForm({
    id,
    commitDate,
    commitAmount,
    commitToPay,
    notes,
    rowIndex,
    onCancel,
    onSubmitSucceed,
    onViewClick,
    placementId
  });

  const isSaving = saveState.status === UIStatus.Saving;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={7} sm={2} md={2} lg={2}>
          <FormControlLabel
            label={strings.collections.fields.committedToPay}
            control={
              <Checkbox
                checked={formValues[FormFieldsMap.CommitToPay.key] === BoolEnum.True}
                color="primary"
                name={FormFieldsMap.CommitToPay.key}
                onChange={handleCheckToggle}
                value={formValues[FormFieldsMap.CommitToPay.key]}
              />
            }
          />
        </Grid>
        <Grid item xs={7} sm={3} md={2} lg={2}>
          <CustomDatePicker
            isClearable
            label={strings.collections.fields.commitDate}
            name={FormFieldsMap.CommitDate.key}
            onDateChange={handleFieldChange}
            value={formValues[FormFieldsMap.CommitDate.key]}
          />
        </Grid>
        <Grid item xs={7} sm={2} md={2} lg={2}>
          <TextBox
            inputType="currency"
            label={strings.collections.fields.commitAmount}
            name={FormFieldsMap.CommitAmount.key}
            onChange={handleFieldChange}
            value={formValues[FormFieldsMap.CommitAmount.key]}
          />
        </Grid>
        <Grid item xs={7} sm={7} md={4} lg={4}>
          <TextBox
            label={strings.collections.fields.notes}
            name={FormFieldsMap.Notes.key}
            onChange={handleFieldChange}
            value={formValues[FormFieldsMap.Notes.key] ?? ''}
          />
        </Grid>
      </Grid>
      {saveState.status === UIStatus.Error && (
        <Grid container spacing={1}>
          <Grid item xs={7} sm={7} md={10}>
            <Alert className={styles.alert} severity="error">
              {saveState.error}
            </Alert>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        <Grid item>
          <SaveButton
            isSaving={isSaving}
            isSuccess={saveState.status === UIStatus.Success}
            disabled={isSaving || !dirty}
          />
        </Grid>
        <Grid item>
          <FPActionButton
            text={strings.shared.ui.cancel}
            variant="outlined"
            onClick={handleCancel}
          />
        </Grid>
        {placementId && (
          <Grid item>
            <FPActionButton
              text={strings.collections.ctas.view}
              variant="outlined"
              onClick={handleView}
            />
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default Form;
