// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { AccountTree, Toc } from '@material-ui/icons';
import { confirm as showConfirm, showAlert } from 'actions/app';
import { useGlobalStyles } from 'GlobalStyles';
import { formatLinkedCompanies, useAssociatedCompanies } from 'hooks/associatedCompanies';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import API from 'services/API';
import strings from 'strings';
import type { ProfileDataTableProps } from 'types/app';
import { When } from 'UI/components/atoms/When';
import FPToggleButton from 'UI/components/molecules/FPToggleButton';
import { COMPANY_RELATIONSHIP_KEY, COMPANY_RELATIONSHIP_TYPES } from 'UI/constants/defaults';
import { componentDimensions } from 'UI/constants/dimensions';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType, TabKeys } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { SvgNoJobOrders } from 'UI/res/icons/milano';
import { capitalizeFirstLetter, getErrorMessage, nestTernary } from 'UI/utils';

import AssociatedCompanies from '../../AssociatedCompanies';
import AssociatedCompaniesTree from '../../AssociatedCompanies/AssociatedCompaniesTree';
import ProfileTableLayout from '../ProfileTableLayout';

import { associatedCompaniesColumns } from './associatedCompaniesColumns';

export const TAB_INCLUDES = {
  company: 'specialty,position,address,whiteSheet,hiringAuthorities,status,vRecruiter',
  candidate: 'position,specialty,address,whiteSheet,company'
};
const { emptyState } = componentDimensions;

type AssociatedCompaniesTabProps = {
  profileId: number,
  DataTableProps: ProfileDataTableProps,
  itemName: string
};

const isParent = relationship => relationship === COMPANY_RELATIONSHIP_TYPES.PARENT;

const COMPANY_RELATIONSHIP_MAPPING = {
  [COMPANY_RELATIONSHIP_TYPES.CHILD]: COMPANY_RELATIONSHIP_KEY.childCompanies,
  [COMPANY_RELATIONSHIP_TYPES.SIBLING]: COMPANY_RELATIONSHIP_KEY.siblingCompanies
};

const VIEW_MODES = {
  Table: 'table',
  Tree: 'tree'
};

const AssociatedCompaniesTab = ({
  profileId,
  itemName,
  DataTableProps
}: AssociatedCompaniesTabProps) => {
  const globalClasses = useGlobalStyles();

  const dispatch = useDispatch();
  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false,
    shouldRefreshTableData: false,
    viewMode: VIEW_MODES.Table
  });

  const isTableView = uiState.viewMode === VIEW_MODES.Table;
  const isTreeView = uiState.viewMode === VIEW_MODES.Tree;

  const {
    associatedCompanies,
    setAssociatedCompanies,
    associatedCompaniesActions,
    refreshData,
    isLoading,
    linkedParentId
  } = useAssociatedCompanies(profileId);

  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const isCompaniesTreeEnabled = checkIfFeatureFlagEnabled(FeatureFlags.AssociatedCompaniesTree);

  const handleAssociatedCompaniesSubmit = async (newAssociatedCompanies, relationship) => {
    const childSiblingEndpoint =
      relationship === COMPANY_RELATIONSHIP_TYPES.CHILD
        ? Endpoints.ChildCompanies
        : Endpoints.SisterCompanies;

    const endpoint = nestTernary(
      isParent(relationship),
      Endpoints.ParentCompany,
      childSiblingEndpoint
    );

    const request = isParent(relationship) ? API.put : API.post;

    const relationshipKey = COMPANY_RELATIONSHIP_MAPPING[relationship];
    const updatedAssociationData = newAssociatedCompanies[relationshipKey];
    const capitalizedRelationName = capitalizeFirstLetter(relationship);
    const entityName =
      isParent(relationship) || updatedAssociationData.length === 1 ? 'Company' : 'Companies';

    try {
      setUiState(prevState => ({ ...prevState, isSaving: true }));
      const transformedAssociatedCompanies = formatLinkedCompanies(newAssociatedCompanies);

      const response = await request(
        endpoint.replace(':id', profileId),
        transformedAssociatedCompanies,
        {
          apiVersion: 2
        }
      );
      if (response.data && (response.status === 200 || response.status === 201))
        informSuccess('submit', capitalizedRelationName, entityName);
      return true;
    } catch (err) {
      setUiState(prevState => ({
        ...prevState,
        isSuccess: false,
        isSaving: false
      }));
      dispatch(
        showAlert({
          severity: 'error',
          title: 'Error',
          autoHideDuration: 5000,
          body: getErrorMessage(err)
        })
      );
      return false;
    }
  };

  const handleRemoveAssociation = async ({ id, association_type: { association_type } }) => {
    const relationship = association_type.toUpperCase();
    const childSiblingType =
      relationship === COMPANY_RELATIONSHIP_TYPES.SIBLING ? 'sister' : 'child';
    const childSiblingEndpoint = `companies/${profileId}/${childSiblingType}-companies?${childSiblingType}_companies_ids=${id}`;

    const endpoint = nestTernary(
      isParent(relationship),
      Endpoints.ParentCompany.replace(':id', profileId),
      childSiblingEndpoint
    );

    const config = {
      apiVersion: 2
    };
    try {
      setUiState(prevState => ({ ...prevState, isSaving: true }));
      if (isParent(relationship)) config.data = { parent_company_id: id };
      const response = await API.delete(endpoint, config);
      if (response.status === 204) informSuccess('remove', association_type, 'Company');
    } catch (err) {
      setUiState(prevState => ({
        ...prevState,
        isSuccess: false,
        isSaving: false
      }));
      dispatch(
        showAlert({
          severity: 'error',
          title: 'Error',
          autoHideDuration: 5000,
          body: getErrorMessage(err)
        })
      );
    }
  };

  const openConfirmDialog = itemData => {
    const {
      name: { name },
      association_type: { association_type }
    } = itemData;

    const associationCap = association_type.charAt(0).toUpperCase() + association_type.slice(1);

    dispatch(
      showConfirm({
        severity: 'warning',
        title: strings.companies.creation.associated.dialog.removeTitle,
        message: strings.formatString(strings.companies.creation.associated.dialog.removeMessage, {
          companyName: name,
          associationType: associationCap
        }),
        confirmButtonText: strings.companies.creation.associated.dialog.confirmButtonText,
        cancelButtonText: strings.companies.creation.associated.dialog.cancelButtonText,
        mustComplete: true,
        taskCompleted: uiState.isSuccess,
        onConfirm: async ok => {
          if (!ok) return;
          await handleRemoveAssociation(itemData);
        }
      })
    );
  };

  const informSuccess = (actionType, association, entity) => {
    const actionText =
      actionType === 'submit'
        ? strings.companies.creation.associated.tab.alert.add
        : strings.companies.creation.associated.tab.alert.remove;
    setUiState(prevState => ({
      ...prevState,
      isSuccess: true,
      isSaving: false,
      shouldRefreshTableData: true
    }));
    dispatch(
      showAlert({
        severity: 'success',
        title: strings.formatString(strings.companies.creation.associated.tab.alert.body, {
          actionText,
          association,
          entity
        }),
        body: ''
      })
    );
    setAssociatedCompanies({
      parentCompany: null,
      siblingCompanies: null,
      childCompanies: null
    });
    setUiState(prevState => ({
      ...prevState,
      shouldRefreshTableData: false
    }));
    refreshData();
  };

  const finalActions = [
    {
      name: 'remove',
      callback: openConfirmDialog,
      checkIfShouldBeEnabled: (currentRow, rows) => {
        return rows[currentRow.rowIndex]?.association_type?.association_type !== 'ultimateParent';
      }
    }
  ];

  const company = {
    name: itemName,
    parentCompanyId: linkedParentId
  };

  const toggleOptionsAssociatedCompanies = [
    {
      title: 'Table',
      value: VIEW_MODES.Table,
      disabled: isTableView,
      icon: <Toc />
    },
    {
      title: 'Tree',
      value: VIEW_MODES.Tree,
      disabled: isTreeView,
      icon: <AccountTree />
    }
  ];

  const onOptionClick = value => setUiState(prev => ({ ...prev, viewMode: value }));

  return (
    <>
      <Grid container>
        <Grid item xs={isCompaniesTreeEnabled ? 8 : 12} md={isCompaniesTreeEnabled ? 10 : 12}>
          <AssociatedCompanies
            associatedCompaniesActions={associatedCompaniesActions}
            direction="row"
            associatedCompanies={associatedCompanies}
            onSubmit={handleAssociatedCompaniesSubmit}
            companyId={profileId}
            company={company}
            isLoading={isLoading}
          />
        </Grid>
        <When condition={isCompaniesTreeEnabled}>
          <Grid item xs={isCompaniesTreeEnabled ? 4 : 12} md={isCompaniesTreeEnabled ? 2 : 12}>
            <FPToggleButton
              className={globalClasses.toggleButtonLayoutV2}
              onChange={onOptionClick}
              options={toggleOptionsAssociatedCompanies}
              size="small"
            />
          </Grid>
        </When>
      </Grid>
      <When condition={isTableView}>
        <ProfileTableLayout
          customEmptyState={<SvgNoJobOrders size={emptyState.width} />}
          DataTableProps={DataTableProps}
          enableQuickView
          finalActions={finalActions}
          hasProfileLoaded={profileId}
          initialColumns={associatedCompaniesColumns}
          profileModule={EntityType.Company}
          shouldRefresh={uiState.shouldRefreshTableData}
          tabKey={TabKeys.AssociatedCompanies}
        />
      </When>
      <When condition={isTreeView && isCompaniesTreeEnabled}>
        <AssociatedCompaniesTree
          companyId={profileId}
          companyName={company.name}
          shouldRefresh={uiState.shouldRefreshTableData}
        />
      </When>
    </>
  );
};

AssociatedCompaniesTab.defaultProps = {
  profileId: null
};

export default AssociatedCompaniesTab;
