import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  mainContainer: {
    padding: ({ isColumn }) => isColumn && theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  overviewContainer: {
    width: '100%'
  },
  boldText: {
    fontWeight: theme.typography.fontWeightMedium
  }
}));
