// @flow
import API from 'services/API';

import {
  AttachmentsByCategory,
  HasReachedMaxFiles,
  MoveFiles,
  UpdateCategory
} from './fileManager.types';

export const hasReachedMaxFiles = ({ attachments, destinationId }: HasReachedMaxFiles): boolean =>
  attachments.some(
    category =>
      category.id === destinationId &&
      !category.multiple &&
      category.files.length >= category.maxFiles
  );

export const moveFileIntoSelectedCategory = ({
  attachments,
  fileId,
  sourceId,
  sourceIndex,
  destinationId,
  destinationIndex
}: MoveFiles): Array<AttachmentsByCategory> => {
  const sourceFiles = [];
  const destinationFiles = [];
  let movingFile = [];

  attachments.forEach(category => {
    if (category.id === sourceId) {
      category.files.forEach(item => sourceFiles.push(item));
      movingFile = category.files.filter(att => att.id === fileId);
    }

    if (category.id === destinationId) {
      category.files.forEach(item => destinationFiles.push(item));
    }
  });

  movingFile[0].file_type_id = destinationId;
  movingFile[0].fileType = { id: destinationId };
  movingFile[0].isLoading = true;
  movingFile[0].message = 'Updating file...';

  sourceFiles.splice(sourceIndex, 1);
  destinationFiles.splice(destinationIndex, 0, movingFile[0]);

  const movedAttachments = attachments.map(category => {
    const { id, files } = category;

    const fileMapping = {
      [destinationId]: destinationFiles,
      [sourceId]: sourceFiles
    };
    return { ...category, files: fileMapping[id] || files };
  });

  return movedAttachments;
};

export const updateCategoryByEndpoint = ({
  endpoint,
  fileId,
  newCategoryId
}: UpdateCategory): Promise<void> => {
  const url = `${endpoint}/${fileId}`;

  return new Promise((resolve, reject) => {
    API.put(url, { file_type_id: newCategoryId })
      .then(() => {
        resolve();
      })
      .catch(error => {
        reject(error);
      });
  });
};
