import { makeStyles } from '@material-ui/core';
import { flexAlignCenter, flexAlignStart } from 'UI/utils/styles';

export const useStyles = makeStyles(
  theme => ({
    infoCard: {
      position: 'absolute',
      opacity: 0.95,
      minWidth: 250,
      padding: theme.spacing(3),
      zIndex: theme.zIndex.profileRecruiterView,
      top: '100%',
      backgroundColor: theme.palette.grey.black,
      color: theme.palette.brandColors?.FPWhite,
      '& > div': {
        padding: 0
      }
    },
    infoCardContent: {
      padding: '0 !important'
    },
    infoCardContainer: {
      display: 'grid',
      gridTemplateColumns: 'fit-content(40px) 200px',
      gridTemplateRows: 'auto auto auto',
      columnGap: theme.spacing(2)
    },
    infoCardType: {
      gridColumnStart: 2,
      gridColumnEnd: -1,
      color: theme.palette.brandColors?.FPWhite
    },
    infoCardAvatar: {
      ...flexAlignCenter,
      gridColumnStart: 1,
      gridColumnEnd: 2
    },
    infoCardTitle: {
      ...flexAlignCenter,
      gridColumnStart: 2,
      gridColumnEnd: -1,
      fontWeight: '500 !important',
      color: theme.palette.brandColors?.FPWhite
    },
    infoCardDetailsContainer: {
      ...flexAlignStart,
      gridColumnStart: 2,
      gridColumnEnd: -1
    },
    infoCardDetails: {
      maxWidth: 200
    },
    infoCardActions: {
      '& > div': {
        justifyContent: 'flex-start',
        gap: theme.spacing(1)
      }
    }
  }),
  { classNamePrefix: 'infoCard' }
);
