// @flow

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import moment from 'moment-timezone';
import strings from 'strings';
import { getTimeInputValidity } from 'UI/utils';

import { getQuotaRestriction } from '../../BulkEmailDrawer/utils';
import { FormFieldsMap, scheduleKeys } from '../../BulkEmailForm/fields';

const {
  schedule: { errors: errorsCopies }
} = strings.bulkEmails;

type ScheduleBulkDialogProps = {
  isBulkInPreviewBeingEdited: boolean,
  onSubmitBulkEmail: () => void,
  notifyBulkQuotaRestriction: () => void,
  sendDate: string,
  shouldUnregisterFieldsOnClose: boolean,
  remainingQuota: number,
  searchProjectSize: number
};

export const useScheduleBulkDialog = ({
  isBulkInPreviewBeingEdited,
  sendDate,
  shouldUnregisterFieldsOnClose,
  remainingQuota,
  searchProjectSize,
  onSubmitBulkEmail,
  notifyBulkQuotaRestriction
}: ScheduleBulkDialogProps) => {
  const {
    clearError,
    errors,
    getValues,
    handleSubmit,
    register,
    setError,
    setValue,
    triggerValidation,
    unregister,
    watch
  } = useFormContext();
  const formValues = watch(scheduleKeys);

  const bulkQuotaRestriction = getQuotaRestriction({ remainingQuota, searchProjectSize });
  const isCurrentDay = formValues[FormFieldsMap.ScheduleDate.key]?.isSame(moment(), 'day');

  useEffect(() => {
    register({ name: FormFieldsMap.ScheduleDate.key }, { required: 'Schedule Date is required' });
    register({ name: FormFieldsMap.ScheduleTime.key }, { required: 'Schedule Time is required' });
  }, [register, unregister]);

  useEffect(() => {
    return () => {
      if (shouldUnregisterFieldsOnClose) {
        unregister(scheduleKeys);
      }
    };
  }, [unregister, shouldUnregisterFieldsOnClose]);

  useEffect(() => {
    if (!isBulkInPreviewBeingEdited || !sendDate) return;
    const currentSendDate = moment(sendDate);
    scheduleKeys.forEach(key => {
      setValue(key, currentSendDate);
    });
  }, [isBulkInPreviewBeingEdited, sendDate, setValue]);

  const setTimeInputErrors = isValid => {
    if (isValid)
      return errors[FormFieldsMap.ScheduleTime.key] && clearError(FormFieldsMap.ScheduleTime.key);

    setValue(FormFieldsMap.ScheduleTime.key, null);
    return setError(
      FormFieldsMap.ScheduleTime.key,
      'isBeforeActualDateTime',
      errorsCopies.beforeActualDateTime
    );
  };

  const handleDateChange = (name, date) => {
    const isTimeInputValid = getTimeInputValidity(date, getValues(FormFieldsMap.ScheduleTime.key));
    setTimeInputErrors(isTimeInputValid);
    setValue(name, date, true);
  };

  const handleTimeChange = (name, time) => {
    const isTimeInputValid = getTimeInputValidity(getValues(FormFieldsMap.ScheduleDate.key), time);
    setTimeInputErrors(isTimeInputValid);
    isTimeInputValid && setValue(name, time, true);
  };

  const submitHandler = async ok => {
    await triggerValidation(scheduleKeys);
    const errorsIsEmpty = Object.keys(errors).length === 0;
    if (!errorsIsEmpty) return;

    if (remainingQuota === 0 && isCurrentDay) {
      notifyBulkQuotaRestriction && notifyBulkQuotaRestriction();
      return;
    }

    ok &&
      handleSubmit(formData =>
        onSubmitBulkEmail(formData, {
          saveAsSchedule: true
        })
      )();
  };

  return {
    formValues,
    errors,
    bulkQuotaRestriction,
    handleDateChange,
    handleTimeChange,
    submitHandler
  };
};
