import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    actionsRoot: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: theme.spacing(4),
      width: 250
    },
    input: {
      width: 75,
      '&.MuiInputBase-root': {
        color: 'red'
      }
    },
    pageBtns: {
      marginLeft: 0
    },
    inputTitle: {
      width: 80
    },
    totalPages: {
      marginLeft: theme.spacing(1),
      width: 60
    },
    totalResults: {
      color: 'black',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.43',
      letterSpacing: '0.01071em',
      display: 'block',
      marginBlockStart: '1.15em',
      marginBlockEnd: '1em',
      marginInlineStart: '0px',
      marginInlineEnd: '0px'
    }
  }),
  { classNamePrefix: 'dataTableFooterClassNames' }
);
