import strings from 'strings';
import { formatRatio } from 'UI/utils';

import { calculatePercentage } from '../activity.utils';

export const formatResponse = data => {
  if (!data || !data?.peopleReached) return [];

  const { members, performance } = data.peopleReached;

  const percentage = calculatePercentage({ total: members?.total, goal: members?.goal });

  return [
    {
      title: strings.commandCenter.activity.peopleReachedGauge.chart.goal,
      value: formatRatio(performance.total ?? 0, performance.goal ?? 0, 'scalar', {
        notation: 'standard'
      }),

      percentage,
      goal: performance.goal,
      valuePosition: 'top'
    },
    {
      title: strings.commandCenter.activity.peopleReachedGauge.chart.members,
      value: formatRatio(members.total ?? 0, members?.goal),
      valuePosition: 'top'
    }
  ];
};
