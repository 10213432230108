// @flow
import React from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import SaveButton from 'UI/components/atoms/SaveButton';
import FPButtonMenu from 'UI/components/molecules/FPButtonMenu';

type FPButtonBuilderProps = {
  buttonDefinitions: Array<Object>,
  GridContainerProps: GridProps
};

const FPButtonBuilder = ({ buttonDefinitions, GridContainerProps }: FPButtonBuilderProps) => {
  return (
    <Grid container {...GridContainerProps}>
      {buttonDefinitions.map(item => {
        const { props, mode = 'default', GridItemProps } = item;

        const buttonModes = {
          default: <FPActionButton {...props} />,
          save: <SaveButton {...props} />,
          menu: <FPButtonMenu {...props} />
        };

        return (
          <Grid
            component="dl"
            item
            key={buttonDefinitions.indexOf(item)}
            aria-label={`${item?.props?.text} container`}
            md
            role="group"
            {...GridItemProps}
          >
            {buttonModes[mode]}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FPButtonBuilder;
