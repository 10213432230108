// @flow
import React, { useState } from 'react';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';

import { styles } from './styles';

interface NotAvailableProps {
  title: string;
  description: string;
  ImageRender: JSX.Element;
  buttonTitle?: string;
  buttonAction: () => void;
  onClose: () => void;
}

const NotAvailable = ({
  title,
  description,
  ImageRender,
  drawerTitle,
  buttonTitle,
  buttonAction,
  onClose
}: NotAvailableProps) => {
  const [uiState] = useState({
    isLoading: false,
    isError: false
  });

  return (
    <DrawerContentLayout
      isBottomToolbarNeeded={false}
      uiState={uiState}
      onClose={onClose}
      title={drawerTitle}
    >
      <EmptyPlaceholder
        title={title}
        subtitle={description}
        customEmptyState={<ImageRender style={styles.spacing} />}
      >
        {buttonTitle && (
          <FPActionButton
            text={buttonTitle}
            type="button"
            style={{ wiudth: '100px' }}
            onClick={buttonAction}
          />
        )}
      </EmptyPlaceholder>
    </DrawerContentLayout>
  );
};

export default NotAvailable;
