import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenter } from 'UI/utils/styles';

export const useDataTableStyles = makeStyles(
  theme => ({
    withoutToolbar: {
      '& > .MuiToolbar-root': {
        display: 'none'
      },
      '& .MuiTableCell-root': {
        borderBottom: `1px solid ${theme.palette.primary.FP50}`
      },
      '& .MuiTableHead-root .MuiTableCell-head': {
        backgroundColor: `${theme.palette.grey[50]} !important`
      }
    }
  }),
  { classNamePrefix: 'containedTable' }
);

const fixedFooterHeight = 97;
const tableHeight = 160;
const emptyStateMarginArea = 66;

export const useFixedDatatableStyles = makeStyles(
  {
    fixedList: {
      '& > .responsiveBase': {
        height: `calc(100% - ${tableHeight}px) !important`
      },
      '& > .responsiveBase.emptyDataTable': {
        height: `calc(100% - ${emptyStateMarginArea}px) !important`
      },
      '& .MuiTableCell-footer': {
        height: fixedFooterHeight
      }
    }
  },
  { classNamePrefix: 'fixedList' }
);

export const useMultiselectTable = makeStyles(
  theme => ({
    toolBarRoot: {
      backgroundColor: theme.palette.customColors.linkWater,
      padding: 0,
      borderRadius: theme.spacing(0.5)
    },
    toolBarLeft: {
      display: 'none'
    },
    toolBarActions: {
      ...flexAlignCenter,
      width: '100%',
      '& > *': {
        display: 'none'
      },
      '& > div': {
        display: 'flex'
      }
    }
  }),
  { classNamePrefix: 'multiSelectTable' }
);

export const useTableCard = makeStyles(theme => {
  const defaultHeaderBackground = theme.palette.grey[50];
  return {
    toolbar: {
      backgroundColor: defaultHeaderBackground
    },
    actions: {
      backgroundColor: defaultHeaderBackground
    },
    tableRoot: {
      '& .MuiTableHead-root .MuiTableCell-head': {
        backgroundColor: defaultHeaderBackground
      }
    }
  };
});
