// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { globalStyles } from 'GlobalStyles';
import Text from 'UI/components/atoms/Text';
import TextBox from 'UI/components/atoms/TextBox';
import { PaymentMode, PaymentTermsMode } from 'UI/constants/entityTypes';
import { FeeAgreementFields } from 'UI/constants/status';
import { CancelCheckBox } from 'UI/res';
import { currencyFormatter } from 'UI/utils';
import { getFeeAgreementFieldsByPaymentWithVerbiage } from 'UI/utils/feeagreement';

import { useStyles } from './styles';

const CheckSize = 18;
const OutlineCheck = () => {
  const CheckOutlineSize = 20;
  return <CheckBoxOutlineBlankIcon style={{ width: CheckOutlineSize, height: CheckOutlineSize }} />;
};

type FeeAgreementDeclineFormProps = {
  feeAgreement: any,
  fieldsRequired: boolean
};

const FeeAgreementDeclineForm = (props: FeeAgreementDeclineFormProps) => {
  const { feeAgreement, fieldsRequired } = props;
  const classes = useStyles();
  const {
    verbiage_changes_requested: verbiageChangeRequested,
    fee_percentage_change_requested: feePercentChangeRequested,
    guarantee_days_change_requested: guaranteeDaysChangeRequested,
    fee_percentage: feePercentage,
    guarantee_days: guaranteeDays,
    flat_fee_amount: flatFeeAmount,
    fee_agreement_payment_scheme_id,
    [FeeAgreementFields.PaymentTermsMode]: paymentTermsMode
  } = feeAgreement;

  const paymentMode = fee_agreement_payment_scheme_id || PaymentMode.Standard;
  const availableFields = fieldsRequired
    ? getFeeAgreementFieldsByPaymentWithVerbiage(paymentMode, paymentTermsMode)
    : [];

  const deferredPaymentsRequested =
    paymentTermsMode === PaymentTermsMode.DueOn30 || paymentTermsMode === PaymentTermsMode.Split;
  const changesRequested =
    verbiageChangeRequested ||
    feePercentChangeRequested ||
    guaranteeDaysChangeRequested ||
    flatFeeAmount ||
    deferredPaymentsRequested;
  const { setValue, errors, register, watch, unregister } = useFormContext();

  useEffect(() => {
    register(
      { name: 'declined_fields' },
      {
        validate(fields) {
          return (
            !changesRequested ||
            !fieldsRequired ||
            (fields && fields.length > 0) ||
            'Select at least one change to decline'
          );
        }
      }
    );
    return () => unregister('declined_fields');
  }, [register, unregister, changesRequested, fieldsRequired]);

  const selectedFields = watch('declined_fields', []);

  const handleCheckChange = (event: any) => {
    const { name, checked } = event.target;
    const fields = checked
      ? [...selectedFields, name]
      : selectedFields.filter(field => field !== name);
    setValue('declined_fields', [...new Set(fields)], true);
  };

  return (
    <>
      {availableFields.length > 0 && (
        <>
          <div style={globalStyles.feeDrawerslabel}>
            <Text variant="subtitle1" text="Select the change(s) you decided to decline." />
          </div>
          <FormControl component="fieldset" error={!!errors.declined_fields}>
            <FormGroup
              aria-label="declined fields"
              name="declined_fields"
              onChange={handleCheckChange}
              row
            >
              <div className={classes.checkBoxContainer}>
                {availableFields.includes(FeeAgreementFields.Percentage) && (
                  <FormControlLabel
                    value="end"
                    className={classes.label}
                    control={
                      <Checkbox
                        checked={selectedFields.includes(FeeAgreementFields.Percentage)}
                        disabled={!feePercentChangeRequested}
                        icon={<OutlineCheck />}
                        checkedIcon={<CancelCheckBox size={CheckSize} />}
                        name="fee_percentage"
                        onChange={handleCheckChange}
                      />
                    }
                    label={`Fee ${feePercentage}%`}
                    labelPlacement="end"
                  />
                )}
                {availableFields.includes(FeeAgreementFields.FlatAmount) && (
                  <FormControlLabel
                    value="end"
                    className={classes.label}
                    control={
                      <Checkbox
                        checked={selectedFields.includes(FeeAgreementFields.FlatAmount)}
                        disabled={!flatFeeAmount}
                        icon={<OutlineCheck />}
                        checkedIcon={<CancelCheckBox size={CheckSize} />}
                        name="flat_fee_amount"
                        onChange={handleCheckChange}
                      />
                    }
                    label={`Flat Fee Amount of ${currencyFormatter(flatFeeAmount)} USD`}
                    labelPlacement="end"
                  />
                )}
                {availableFields.includes(FeeAgreementFields.GuaranteeDays) && (
                  <FormControlLabel
                    value="end"
                    className={classes.label}
                    control={
                      <Checkbox
                        checked={selectedFields.includes(FeeAgreementFields.GuaranteeDays)}
                        disabled={!guaranteeDaysChangeRequested}
                        icon={<OutlineCheck />}
                        checkedIcon={<CancelCheckBox size={CheckSize} />}
                        name="guarantee_days"
                        onChange={handleCheckChange}
                      />
                    }
                    label={`${guaranteeDays} days under guarantee`}
                    labelPlacement="end"
                  />
                )}
                {availableFields.includes(FeeAgreementFields.Verbiage) && (
                  <FormControlLabel
                    value="end"
                    className={classes.label}
                    control={
                      <Checkbox
                        checked={selectedFields.includes(FeeAgreementFields.Verbiage)}
                        disabled={!verbiageChangeRequested}
                        icon={<OutlineCheck />}
                        checkedIcon={<CancelCheckBox size={CheckSize} />}
                        name="verbiage_changes"
                        onChange={handleCheckChange}
                      />
                    }
                    label="Verbiage Change"
                    labelPlacement="end"
                  />
                )}
                {availableFields.includes(FeeAgreementFields.PaymentTerms) && (
                  <FormControlLabel
                    value="end"
                    className={classes.label}
                    control={
                      <Checkbox
                        checked={selectedFields.includes(FeeAgreementFields.PaymentTerms)}
                        disabled={!deferredPaymentsRequested}
                        icon={<OutlineCheck />}
                        checkedIcon={<CancelCheckBox size={CheckSize} />}
                        name={FeeAgreementFields.PaymentTerms}
                        onChange={handleCheckChange}
                      />
                    }
                    label="Deferred Payments"
                    labelPlacement="end"
                  />
                )}
                <FormHelperText>{errors?.declined_fields?.message}</FormHelperText>
              </div>
            </FormGroup>
          </FormControl>
        </>
      )}

      <div style={globalStyles.feeDrawerslabel}>
        <div className={classes.sectionLabel}>
          <Text
            variant="subtitle1"
            text="Please specify the reason of declination"
            label="Reason to decline"
          />
        </div>
      </div>
      <TextBox
        name="declination_notes"
        multiline
        label="Reason of declination *"
        inputRef={register({ required: 'Please specify the reason of declination' })}
        error={!!errors.declination_notes}
        errorText={errors?.declination_notes?.message}
      />
      <div />
    </>
  );
};

FeeAgreementDeclineForm.defaultProps = {
  fieldsRequired: true
};

export default FeeAgreementDeclineForm;
