import * as React from 'react';

const SvgEmailFilled = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M11.11 14.56a1.27 1.27 0 001.77 0l9.69-9.69a.5.5 0 00.12-.51.51.51 0 00-.4-.34L22 4H1.71a.5.5 0 00-.4.34.48.48 0 00.12.51z" />
      <path d="M23.89 5.83a.18.18 0 00-.2 0l-6.2 6.2a.24.24 0 000 .36l5 5a.75.75 0 01-1.06 1.06l-5-5a.25.25 0 00-.36 0l-2.13 2.13a2.76 2.76 0 01-3.89 0l-2.12-2.09a.24.24 0 00-.36 0l-5 5a.75.75 0 01-1.06-1.06l5-5a.24.24 0 000-.36l-6.2-6.2a.18.18 0 00-.2 0A.18.18 0 000 6v12a2 2 0 002 2h20a2 2 0 002-2V6a.18.18 0 00-.11-.17z" />
    </svg>
  );
};

export default SvgEmailFilled;
