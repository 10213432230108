import React from 'react';
import NumberFormat from 'react-number-format';
import CircularProgress from '@material-ui/core/CircularProgress';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import { Endpoints } from 'UI/constants/endpoints';

import { useStyles } from '../styles';

const isNumber = value => typeof value === 'number';

const InnerDataTableRender = ({ recruiters, year }) => {
  const classes = useStyles();
  const isYearView = !!year?.date;
  return recruiters.map(item => {
    const tableColumns = isYearView
      ? [
          { label: item.recruiter, class: classes.expandibleTeam },
          { label: item.january_total_s, labelDown: item.january_total },
          { label: item.february_total_s, labelDown: item.february_total },
          { label: item.march_total_s, labelDown: item.march_total },
          { label: item.april_total_s, labelDown: item.april_total },
          { label: item.may_total_s, labelDown: item.may_total },
          { label: item.june_total_s, labelDown: item.june_total },
          { label: item.july_total_s, labelDown: item.july_total },
          { label: item.august_total_s, labelDown: item.august_total },
          { label: item.september_total_s, labelDown: item.september_total },
          { label: item.october_total_s, labelDown: item.october_total },
          { label: item.november_total_s, labelDown: item.november_total },
          { label: item.december_total_s, labelDown: item.december_total },
          { label: item.total_s, labelDown: item.total }
        ]
      : [
          { label: item.recruiter, class: classes.expandibleTeam },
          { label: item.monday_sent },
          { label: item.tuesday_sent },
          { label: item.wednesday_sent },
          { label: item.thursday_sent },
          { label: item.friday_sent },
          { label: item.total_sent, class: classes.total },
          { label: item.monday_signed },
          { label: item.tuesday_signed },
          { label: item.wednesday_signed },
          { label: item.thursday_signed },
          { label: item.friday_signed },
          { label: item.total_signed, class: classes.total },
          { label: item.percentage }
        ];
    return (
      <tr>
        <td />
        {tableColumns.map(column => (
          <td className={column.class || ''}>
            <SplitCell column={column} header={false} />
          </td>
        ))}
      </tr>
    );
  });
};

const LoadingRender = () => {
  const classes = useStyles();
  return (
    <tr>
      <td colSpan={15} className={classes.expandableLoadingRender}>
        <div className={classes.defaultWrapper}>
          <CircularProgress />
        </div>
      </td>
    </tr>
  );
};

const FeeAgreementExpandableRow = ({ coach, week, year }) => {
  const { Status } = useFetchWithStatus(
    `${Endpoints.FeeAgreementDashboardRecruiters}?coach=${coach}&week=${
      week?.date ? week.date : ''
    }&year=${year?.date ? year.date : ''}`
  );
  return (
    <Status
      loading={<LoadingRender />}
      success={recruiters => <InnerDataTableRender recruiters={recruiters} year={year} />}
    />
  );
};

export const SplitCell = ({ column, header }) => {
  const classes = useStyles();
  const labelUp = isNumber(column.label) ? (
    <NumberFormat displayType="text" thousandSeparator value={column.label} />
  ) : (
    column.label
  );
  const labelDown = column.labelDown && (
    <div className={header ? classes.headerLabelDown : classes.labelDown}>
      {isNumber(column.labelDown) ? (
        <NumberFormat displayType="text" thousandSeparator value={column.labelDown} />
      ) : (
        column.labelDown
      )}
    </div>
  );

  const labelBottom = column.labelBottom && (
    <div className={header ? classes.headerLabelDown : classes.labelDown}>
      {isNumber(column.labelBottom) ? (
        <NumberFormat displayType="text" thousandSeparator value={column.labelBottom} />
      ) : (
        column.labelBottom
      )}
    </div>
  );
  return (
    <>
      {labelUp}
      {labelDown}
      {labelBottom}
    </>
  );
};

export default FeeAgreementExpandableRow;
