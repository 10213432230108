// @flow

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import {
  setBulkEmailStatus,
  setBulkEmailToInitial,
  setBulkStatsOpen as setAreBulkStatsOpen,
  startRemovingAllAttachments
} from 'actions/bulkEmail';
import usePageTitle from 'hooks/usePageTitle';
import AddEmailOptInForm from 'UI/components/organisms/AddEmailOptInForm';
import BulkEmailDrawer from 'UI/components/organisms/BulkEmailDrawer';
import BulkStatsDrawer from 'UI/components/organisms/BulkStatsDrawer';
import BulkEmailLayout from 'UI/components/templates/BulkEmailLayout';
import { PageTitles } from 'UI/constants/defaults';

const BulkEmail = () => {
  usePageTitle({ title: PageTitles.BulkEmail });
  const {
    ui: {
      stats: { open: openStatsDrawer }
    },
    domain: {
      sent: {
        preview: { id: emailId }
      }
    }
  } = useSelector(state => state.bulkEmail);
  const dispatch = useDispatch();

  const [uiState, setUiState] = useState({
    isOptOutDrawerOpen: false,
    isBulkEmailDrawerOpen: false
  });

  useEffect(
    () => () => {
      dispatch(setBulkEmailToInitial());
      dispatch(startRemovingAllAttachments());
      dispatch(setBulkEmailStatus({ loadLast: false, reloadEmailList: false }));
      dispatch(setAreBulkStatsOpen(false));
    },
    [dispatch]
  );

  const onAddEmailToOptOut = () =>
    setUiState(prevState => ({ ...prevState, isOptOutDrawerOpen: true }));
  const handleStatsModalClose = () => dispatch(setAreBulkStatsOpen(false));

  const toggleDrawer = (drawer: string, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setUiState(prevState => ({ ...prevState, [drawer]: open }));
  };

  const handleOpenBulkForm = () => setUiState(prev => ({ ...prev, isBulkEmailDrawerOpen: true }));

  return (
    <>
      <BulkEmailLayout
        isBulk
        onOpenBulkForm={handleOpenBulkForm}
        onAddEmailToOptOut={onAddEmailToOptOut}
      />
      {emailId && openStatsDrawer && <BulkStatsDrawer onClose={handleStatsModalClose} />}
      {uiState.isBulkEmailDrawerOpen && (
        <BulkEmailDrawer
          onCloseDrawer={() => setUiState(prev => ({ ...prev, isBulkEmailDrawerOpen: false }))}
          loadLastBulkEmail
        />
      )}
      <Drawer open={uiState.isOptOutDrawerOpen} onClose={toggleDrawer('isOptOutDrawerOpen', false)}>
        <div role="presentation">
          <AddEmailOptInForm onClosed={toggleDrawer('isOptOutDrawerOpen', false)} />
        </div>
      </Drawer>
    </>
  );
};

export default BulkEmail;
