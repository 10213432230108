import { makeStyles } from '@material-ui/core/styles';
import { input } from 'UI/constants/dimensions';
import { colors } from 'UI/res/colors';
import { flexAlignCenter, flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const sendoutStyles = {
  wrapperError: {
    height: '100%',
    ...flexAlignCenterJustifyCenter
  }
};

export const SendoutFormStyle = makeStyles(theme => ({
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    ...flexAlignCenter,
    '& > div': {
      marginRight: theme.spacing(1)
    },
    '& > label': {
      marginBottom: 0,
      marginRight: theme.spacing(1)
    }
  },
  wrapperError: {
    height: '100%',
    ...flexAlignCenterJustifyCenter
  },
  bottomSpacing: {
    '& > div': {
      marginBottom: theme.spacing(2)
    }
  },
  wrapperHistoryItem: {
    borderBottom: `1px solid ${colors.lightgray}`
  },
  btnUnderline: {
    border: 'none',
    backgroundColor: 'unset',
    color: colors.success,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 'bold',
    margin: theme.spacing(0, 0.5),
    fontSize: 16
  },
  rowForm: {
    overflow: 'auto',
    ...flexAlignCenterJustifyCenter,
    '& > p': {
      margin: 0,
      width: '120px'
    },
    '& .ck-editor': {
      width: '100%'
    }
  },
  paddingZero: { padding: theme.spacing(0) },
  marginZero: { margin: theme.spacing(0) },
  marginNormal: { margin: theme.spacing(0, 1) },
  fontWeightNormal: {
    fontWeight: 'normal !important',
    marginBottom: theme.spacing(0.5)
  },
  btnDetails: {
    padding: theme.spacing(0, 4),
    borderRadius: input.borderRadius
  },
  containerTemplateCircularProgress: {
    justifyContent: 'center',
    ...flexAlignCenter
  },
  previewEmailContainer: {
    margin: theme.spacing(1, 0, 1, 0)
  },
  previewEmailContent: {
    border: `1px solid ${theme.palette.grey.A200}`,
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.brandColors.FPWhite,
    padding: theme.spacing(2)
  }
}));

export const useStyles = makeStyles(theme => ({
  tableClasses: {
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        backgroundColor: 'transparent !important',
        paddingLeft: theme.spacing(3)
      }
    },
    '& .MuiCardContent-root': {
      padding: '14px !important',
      backgroundColor: 'black'
    },
    '& .MuiTableCell-body': {
      paddingLeft: theme.spacing(3)
    }
  },
  subtitleContainer: {
    margin: theme.spacing(2.5)
  },
  drawerContent: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: theme.spacing(0)
  },
  textNoAttachments: {
    textAlign: 'center',
    fontWeight: 'normal !important',
    marginTop: theme.spacing(4)
  },
  sectionTitle: {
    color: `${theme.palette.grey[600]} !important`,
    fontWeight: '500px !important',
    marginBottom: '4px'
  },
  secondaryTitle: {
    color: `${theme.palette.grey[600]} !important`,
    marginBottom: theme.spacing(2)
  },
  categoryTitle: {
    color: `${theme.palette.grey[600]} !important`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    lineHeight: '0'
  }
}));
