// @flow
import React from 'react';
import { useFormContext } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import TextBox from 'UI/components/atoms/TextBox';
import { REQUIRED_VALIDATION } from 'UI/utils';

const PlacementSuggestionForm = () => {
  const { register, errors } = useFormContext();

  return (
    <>
      <Typography gutterBottom>Request a change to your recruiter:</Typography>
      <TextBox
        name="description"
        multiline
        label="Changes"
        inputRef={register(REQUIRED_VALIDATION)}
        error={!!errors.description}
        errorText={errors?.description?.message}
      />
    </>
  );
};

export default PlacementSuggestionForm;
