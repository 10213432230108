import { useEffect, useState } from 'react';
import { RequestStatus } from 'UI/constants/status';

import { getDataSheetByJobOrderId } from '../EditDataSheet.services';

const useFetchDataSheet = jobOrderId => {
  const [status, setStatus] = useState(RequestStatus.Idle);
  const [dataSheet, setDataSheet] = useState(null);

  useEffect(() => {
    if (!jobOrderId) return;

    setStatus(RequestStatus.Loading);
    getDataSheetByJobOrderId(jobOrderId)
      .then(response => {
        setDataSheet(response.data);
        setStatus(RequestStatus.Success);
      })
      .catch(() => {
        setStatus(RequestStatus.Error);
      });
  }, [jobOrderId]);

  return {
    status,
    dataSheet
  };
};

export default useFetchDataSheet;
