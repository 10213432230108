// @flow
import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import { AlertTitle } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import strings from 'strings';
import { When } from 'UI/components/atoms/When';

import { useStyles } from './styles';

type ProfileAlertProps = {
  title: string,
  primaryText: string,
  secondaryText?: string,
  buttonUrl: string,
  complementaryCopies: Object[],
  loading: boolean
};

const ProfileAlert = ({
  title,
  primaryText,
  secondaryText = null,
  buttonUrl,
  severity,
  complementaryCopies,
  loading
}: ProfileAlertProps) => {
  const classes = useStyles();

  if (loading) return null;

  return (
    <Alert
      action={
        <Button
          color="inherit"
          size="small"
          className={classes.learnMoreBtn}
          href={buttonUrl}
          target="_blank"
          variant="outlined"
        >
          {strings.inventoryProfiles.common.emailValidationStatus.alert.learnMoreBtn}
        </Button>
      }
      severity={severity}
      icon={<WarningIcon />}
      className={classes.emailValidationWarning}
    >
      <AlertTitle>{title}</AlertTitle>
      <div
        className={classes.primaryText}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: primaryText
        }}
      />
      <Typography paragraph className={classes.secondaryText}>
        {secondaryText}
      </Typography>
      {complementaryCopies?.map(item => (
        <When key={item.id} condition={item.copy}>
          <Typography
            className={clsx(item.id === 'validationDate' && classes.secondaryText)}
            paragraph
          >
            {item.copy}
          </Typography>
        </When>
      ))}
    </Alert>
  );
};

export default ProfileAlert;
