import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';
import { flexAlignCenter } from 'UI/utils/styles';

const svgStyles = {
  '& svg.copyButton': {
    visibility: 'hidden'
  },
  '&:hover svg.copyButton': {
    display: 'inline',
    visibility: 'visible'
  }
};

export const useStyles = makeStyles(theme => ({
  infoLabel: {
    position: 'relative',
    maxWidth: 'max-content',
    paddingRight: theme.spacing(2.5),
    whiteSpace: 'unset',
    ...svgStyles
  },
  infoLabelText: {
    display: 'inline'
  },
  copyButton: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    display: 'inline',
    verticalAlign: 'middle'
  },
  linkContainer: {
    ...flexAlignCenter,
    ...svgStyles,
    '& > span': {
      padding: theme.spacing(0, 1)
    }
  },
  avatarLink: {
    marginRight: theme.spacing(1)
  },
  emailValidationIcon: {
    display: 'flex',
    paddingRight: theme.spacing(0.5)
  },
  chip: {
    padding: theme.spacing(1),
    display: 'inline'
  }
}));

const skeletonBase = {
  height: 16,
  borderRadius: 8
};

export const styles = {
  titleSkeleton: {
    ...skeletonBase,
    width: '40%'
  },
  descriptionSkeleton: {
    ...skeletonBase,
    width: '90%',
    marginTop: THEME.spacing(1)
  },
  colorIndicator: {
    margin: THEME.spacing(-0.25, 0.5, 0, 0),
    verticalAlign: 'middle'
  }
};
