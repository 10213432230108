// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { showAlert } from 'actions/app';
import { EntityRoutes } from 'routes/constants';
import {
  addToSearchProject,
  CreatedFromVariants,
  SEARCH_PARAMS_KEYS
} from 'services/searchProjects';
import strings from 'strings';
import type { AddToSearchProjectDrawerProps } from 'types/app';
import ProjectList from 'UI/components/organisms/ProjectList';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';

import { useStyles } from './styles';

const { addForm } = strings.searchProjects;

const AddToSearchProjectDrawer = ({
  bulkId,
  createdFrom,
  currentSearchProjectId,
  itemsToAdd,
  onAddItemsToSP,
  onClose,
  open,
  queryParams,
  moveCopiedItems,
  shouldRedirectToSearchProjectPreview,
  onRefreshSearchProjectsTab
}: AddToSearchProjectDrawerProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [selectedSearchProjectId, setSelectedSearchProjectId] = useState(null);
  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false
  });

  const handleAddToSearchProjectClick = searchProj => setSelectedSearchProjectId(searchProj);

  const handleSubmit = async e => {
    e.preventDefault();
    setUiState({ isSaving: true, isSuccess: false });
    const paramsType =
      createdFrom === CreatedFromVariants.BulkMetrics
        ? SEARCH_PARAMS_KEYS.MetricsParams
        : SEARCH_PARAMS_KEYS.SearchParams;
    const response = await addToSearchProject({
      currentSearchProjectId,
      selectedSearchProjectId,
      itemsToAdd,
      queryParams,
      createdFrom,
      moveCopiedItems,
      paramsType,
      bulkId
    });
    dispatch(showAlert(response.alert));
    setUiState({ isSaving: false, isSuccess: response.success });

    if (!response.success) return;
    onAddItemsToSP && onAddItemsToSP(true, 'add');
    !shouldRedirectToSearchProjectPreview &&
      onRefreshSearchProjectsTab &&
      onRefreshSearchProjectsTab(false);

    shouldRedirectToSearchProjectPreview &&
      setTimeout(() => {
        history.push(EntityRoutes.SearchProjectPreview.replace(':id', selectedSearchProjectId));
      }, 1);
  };

  return (
    <DrawerContentLayout
      title={addForm.title}
      drawerProps={{
        open,
        classes: { paper: classes.drawerRoot }
      }}
      onSubmit={handleSubmit}
      onClose={onClose}
      primaryProps={{
        disabled: !selectedSearchProjectId || uiState.isSaving
      }}
      uiState={uiState}
      contentProps={{
        className: classes.draweContent
      }}
    >
      <ProjectList
        itemsToAdd={itemsToAdd}
        queryParams={queryParams}
        onRowClick={handleAddToSearchProjectClick}
        createdFrom={createdFrom}
        searchProjectId={currentSearchProjectId}
      />
    </DrawerContentLayout>
  );
};

AddToSearchProjectDrawer.defaultProps = {
  createdFrom: 'other',
  onAddItemsToSP: undefined,
  shouldRedirectToSearchProjectPreview: true
};

export default AddToSearchProjectDrawer;
