// @flow
import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { nestTernary } from 'UI/utils';

type SaveButtonProps = {
  disabled: boolean,
  isSaving: boolean,
  isSuccess: boolean,
  initialText?: string,
  onProgressText: string,
  onSuccessText?: string,
  children: React.Node
};

const SaveButton = ({
  disabled,
  isSaving,
  isSuccess,
  initialText,
  onProgressText,
  onSuccessText,
  children,
  ...rest
}: SaveButtonProps) => {
  const textButton = isSaving ? onProgressText : nestTernary(isSuccess, onSuccessText, initialText);
  const disabledButton = disabled || isSaving || (!isSaving && isSuccess);

  return (
    <FPActionButton
      text={textButton}
      disabled={disabledButton}
      type="submit"
      startIcon={isSaving ? <CircularProgress color="inherit" size={20} /> : children}
      {...rest}
    />
  );
};

SaveButton.defaultProps = {
  initialText: 'Save',
  onProgressText: 'Saving',
  onSuccessText: 'Saved',
  children: undefined
};

export default SaveButton;
