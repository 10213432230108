// @flow

import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import FileField from 'UI/components/molecules/FileField';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { extractFilenameFromUrl, REQUIRED_VALIDATION } from 'UI/utils';

export type PlacementUploadEstimateProps = {
  placementId: int
};

const fieldsConfig = [
  {
    name: 'estimateFile',
    validation: REQUIRED_VALIDATION
  }
];

const PlacementUploadEstimate = (props: PlacementUploadEstimateProps) => {
  const { placementId } = props;
  const { register, unregister, errors, setValue, clearError, watch } = useFormContext();
  const initialUrl = watch('estimate_url');

  const initialValue = useMemo(
    () =>
      initialUrl
        ? {
            id: placementId,
            entity: EntityType.Placement,
            file_name: extractFilenameFromUrl(initialUrl),
            url: initialUrl
          }
        : null,
    [initialUrl, placementId]
  );

  useEffect(() => {
    fieldsConfig.forEach(({ name, validation }) => {
      register({ name }, validation);
    });

    return () => unregister(fieldsConfig.map(({ name }) => name));
  }, [register, unregister]);

  useEffect(() => {
    setValue('estimateFile', initialValue);
  }, [initialValue, setValue]);

  const handleUploadStarted = (name: string) => clearError(name);
  const handleFileChange = (name: string, value: any) => setValue(name, value, true);

  return (
    <FileField
      name="estimateFile"
      category={{
        module: EntityType.Placement,
        required: true,
        title: 'Estimate'
      }}
      acceptFiles={['.pdf']}
      defaultEntityType={EntityType.Placement}
      endpoint={Endpoints.Files}
      errorText={errors.estimateFile?.message}
      onChange={handleFileChange}
      onUploadStarted={handleUploadStarted}
      softDeleteForExistingItems
      initialValue={initialValue}
    />
  );
};

PlacementUploadEstimate.defaultProps = {};
export default PlacementUploadEstimate;
