import { EntityRoutes } from 'routes/constants';

export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'recruitersName',
    label: 'Recruiters',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'interviewDate',
    label: 'Interview Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'candidateName',
    label: 'Candidate',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CandidateProfile,
          idKey: 'candidateId',
          textKey: 'candidateName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'candidateCallStatus',
    label: 'Candidate Preparation Call Status',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'candidateCallTime',
    label: 'Candidate Call',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'companyId',
          textKey: 'companyName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'hiringAuthorityName',
    label: 'Hiring Authority',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.HiringAuthorityProfile,
          idKey: 'hiringAuthorityId',
          textKey: 'hiringAuthorityName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'hiringAuthorityCallStatus',
    label: 'Hiring A. Preparation Call Status',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'hiringAuthorityCallTime',
    label: 'Hiring A. Debrief Call',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'jobOrderTitle',
    label: 'Job Order',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'jobOrderId',
          textKey: 'jobOrderTitle',
          enableInTabRedirect: true
        }
      }
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'interviewDate',
  direction: 'asc'
};

export const VIRTUAL_PROPS = [
  {
    propName: 'candidateName',
    attributes: ['candidateId', 'candidateName']
  },
  {
    propName: 'hiringAuthorityName',
    attributes: ['hiringAuthorityId', 'hiringAuthorityName']
  },
  {
    propName: 'jobOrderTitle',
    attributes: ['jobOrderId', 'jobOrderTitle']
  },
  {
    propName: 'candidatePhones',
    attributes: ['candidatePhones', 'candidatePhone']
  },
  {
    propName: 'hiringAuthorityPhones',
    attributes: ['hiringAuthorityPhones', 'hiringAuthorityPhone']
  },
  {
    propName: 'company',
    attributes: ['companyId', 'companyName']
  }
];
