import { makeStyles } from '@material-ui/core/styles';

export const styles = {
  icon: {
    margin: '0 auto'
  },

  indicator: {
    margin: '0 auto'
  },
  title: {
    padding: 5
  }
};

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 90,
    backgroundColor: 'rgb(236, 239, 245)',
    boxShadow:
      '0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 1px rgba(0,0,0,0.12)',
    borderRadius: 0
  },
  textContainer: {
    width: 360
  }
}));
