import * as React from 'react';

const SvgGeneralUpdate = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M6.458 6.351A7.989 7.989 0 0120.107 12h2A9.987 9.987 0 005.045 4.938L3.107 3v6h6zm11.299 11.298A7.99 7.99 0 014.107 12h-2a9.987 9.987 0 0017.063 7.061L21.107 21v-6h-6z" />
    </svg>
  );
};

export default SvgGeneralUpdate;
