import {
  EXT_PHONE_VALIDATION,
  NAME_VALIDATION,
  NICKNAME_VALIDATION,
  REQUIRED_VALIDATION,
  TITLE_VALIDATION,
  VALIDATION_REGEXS
} from 'UI/utils';

export const fieldsConfig = [
  {
    name: 'candidateId'
  },
  {
    name: 'company_id',
    validation: { required: 'Company is required' }
  },
  {
    name: 'first_name',
    validation: { ...REQUIRED_VALIDATION, ...NAME_VALIDATION }
  },
  {
    name: 'last_name',
    validation: REQUIRED_VALIDATION
  },
  {
    name: 'nickname',
    validation: NICKNAME_VALIDATION
  },
  {
    name: 'specialty_id',
    validation: { required: 'Industry: Specialty is required' }
  },
  { name: 'position_id', validation: { required: 'Functional title is required' } },
  {
    name: 'title',
    validation: { ...REQUIRED_VALIDATION, ...TITLE_VALIDATION }
  },
  {
    name: 'work_email',
    validation: {
      ...REQUIRED_VALIDATION,
      pattern: {
        value: VALIDATION_REGEXS.EMAIL,
        message: 'Email must be valid'
      }
    }
  },
  {
    name: 'ext',
    validation: EXT_PHONE_VALIDATION
  },
  {
    name: 'other_ext',
    validation: EXT_PHONE_VALIDATION
  },
  {
    name: 'link_profile'
  }
];
