export const formatRows = rows => {
  return rows.reduce((formattedRows, row) => {
    const formattedRow = {};
    Object.entries(row).forEach(([key, value]) => {
      if (typeof value !== 'string' || value === '') {
        formattedRow[key] = value;
        return;
      }

      const numberValue = Number(value);
      formattedRow[key] = Number.isNaN(numberValue) ? value : numberValue;
    });
    formattedRows.push(formattedRow);
    return formattedRows;
  }, []);
};
