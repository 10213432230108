import { formatResponseBuilder } from 'UI/utils/inventory';

import {
  formatHiringAuthorityPhonesForProfileTables,
  getPhonesForProfileTable
} from '../PhoneNumbersForm/utils';

const formatHiringAuthoritiesBuilder = response => {
  const formattedData = response?.data?.data.map(item => {
    const formattedPhones = formatHiringAuthorityPhonesForProfileTables(item);
    const { mainPhone, secondaryPhone } = getPhonesForProfileTable({
      item,
      mainPhoneKey: 'work_phone',
      secondaryPhoneKey: 'personal_phone'
    });
    return {
      ...item,
      formattedPhones,
      work_phone: mainPhone,
      personal_phone: secondaryPhone
    };
  });

  return response
    ? {
        data: {
          ...response.data,
          data: formattedData
        }
      }
    : null;
};

const formatEmployeesBuilder = response => {
  const formattedData = response?.data?.data.map(item => {
    const { mainPhone, secondaryPhone } = getPhonesForProfileTable({
      item,
      mainPhoneKey: 'phone',
      secondaryPhoneKey: 'mobile'
    });
    return {
      ...item,
      roleId: item?.role?.id,
      roleTitle: item?.role?.title,
      phone: mainPhone,
      mobile: secondaryPhone
    };
  });

  return response
    ? {
        data: {
          ...response.data,
          data: formattedData
        }
      }
    : null;
};

const tabFieldMappings = {
  HiringAuthoritiesTab: [
    {
      entry: 'communication_actions',
      valuesToAttach: [
        {
          name: 'phone',
          value: 'work_phone'
        },
        {
          name: 'email',
          value: 'work_email'
        }
      ]
    },
    {
      entry: 'contactValues',
      valuesToAttach: [
        {
          name: 'phone',
          value: 'work_phone'
        },
        {
          name: 'phones',
          value: 'formattedPhones'
        }
      ]
    }
  ],
  EmployeesTab: [
    {
      entry: 'communication_actions',
      valuesToAttach: [
        {
          name: 'phone',
          value: 'phone'
        },
        {
          name: 'email',
          value: 'email'
        }
      ]
    },
    {
      entry: 'roleId',
      valuesToAttach: [{ path: 'role.id' }]
    }
  ]
};

export const handleHiringAuthorityResponse = response => {
  const responseFormattedWithPhones = formatHiringAuthoritiesBuilder(response);
  return formatResponseBuilder(responseFormattedWithPhones)(tabFieldMappings.HiringAuthoritiesTab);
};

export const handleEmployeesResponse = response => {
  const responseFormattedWithPhones = formatEmployeesBuilder(response);
  return formatResponseBuilder(responseFormattedWithPhones)(tabFieldMappings.EmployeesTab);
};
