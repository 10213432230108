import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  detailsText: {
    color: theme.palette.primary.light,
    fontSize: 14,
    fontWeight: '700 !important'
  },
  linkContainer: {
    margin: theme.spacing(2, 0),
    ...flexAlignCenterJustifyCenter
  }
}));
