import moment from 'moment-timezone';

export const CURRENT_YEAR = {
  id: 0,
  title: `${moment.tz(moment.tz.guess()).format('YYYY')} - YTD`,
  startDate: moment.tz(moment.tz.guess()).startOf('year').format(),
  endDate: moment.tz(moment.tz.guess()).endOf('year').format()
};

export const LAST_YEAR = {
  id: 1,
  title: moment.tz(moment.tz.guess()).subtract(1, 'year').format('YYYY'),
  startDate: moment.tz(moment.tz.guess()).subtract(1, 'year').startOf('year').format(),
  endDate: moment.tz(moment.tz.guess()).subtract(1, 'year').endOf('year').format()
};

export const TIME_FRAMES = [
  { id: 0, title: 'Yearly' },
  { id: 1, title: 'Weekly' }
];
export const DATE_RANGE_FILTERS = [CURRENT_YEAR, LAST_YEAR];
