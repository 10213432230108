// @flow
import React from 'react';
import strings from 'strings';
import DecisionDialog from 'UI/components/organisms/DecisionDialog';

type DiscardDialogProps = {
  onDiscard: () => void,
  onKeepEditing: () => void,
  onSaveAsDraft: () => void,
  isScheduleBeingEdited: boolean,
  isDraftBeingEdited: boolean,
  selectedMenuItem: string
};

const {
  bulkEmails: {
    dialogs: {
      cancel: {
        copies: { title, message, saveButton, cancelButtonText, confirmButtonText },
        refs
      }
    }
  }
} = strings;

const DiscardDialog = ({
  onDiscard,
  onKeepEditing,
  onSaveAsDraft,
  isScheduleBeingEdited,
  isDraftBeingEdited,
  selectedMenuItem
}: DiscardDialogProps) => {
  const refsByBulkType =
    refs[isDraftBeingEdited || isScheduleBeingEdited ? selectedMenuItem : 'default'];

  const handleOnClose = () => onKeepEditing && onKeepEditing();

  const handleOnConfirm = async ok => ok && onDiscard();

  const CustomDialogActions = ({ defaultButtonsProps, ActionsMapper }) => {
    const { onClose: closeDialog, onConfirm: rescheduleAction } = defaultButtonsProps;

    const buttons = [
      {
        variant: 'outlined',
        text: confirmButtonText,
        onClick: rescheduleAction,
        show: true
      },
      {
        variant: 'outlined',
        text: strings.formatString(saveButton, refsByBulkType),
        onClick: onSaveAsDraft,
        show: !isScheduleBeingEdited
      },
      {
        variant: 'contained',
        text: cancelButtonText,
        onClick: closeDialog,
        show: true
      }
    ];

    return <ActionsMapper buttons={buttons} />;
  };

  return (
    <DecisionDialog
      title={strings.formatString(title, refsByBulkType)}
      message={strings.formatString(message, refsByBulkType)}
      isOpened
      CustomActions={CustomDialogActions}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      severity="warning"
      showSeverityIcon
      withButtons="YesNo"
    />
  );
};

export default DiscardDialog;
