import * as React from 'react';

const SvgPlane = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <g fill="currentColor">
        <path d="M6.09 6.32L10 7.94a.48.48 0 00.39 0l4.15-1.8a.25.25 0 000-.46l-5.3-2.19a.92.92 0 00-.76 0l-2.39 1a1 1 0 000 1.84z" />
        <path d="M23.59 6.05a2.47 2.47 0 00-1.36-1.35 2.44 2.44 0 00-1.91 0L7.07 10.46a.48.48 0 01-.42 0l-2.38-1.2a1 1 0 00-.84 0l-2.6 1.1a1 1 0 00-.59.72 1 1 0 00.27.9l3.75 3.75a1 1 0 001.08.27L11 13.7a.26.26 0 01.27.05.27.27 0 01.06.27l-2 5.22a1 1 0 00.27 1.09 1 1 0 00.67.26.93.93 0 00.45-.11l2.71-1.35a1 1 0 00.45-.45l3.75-7.5a.46.46 0 01.27-.24l4.34-1.62a2.5 2.5 0 001.36-3.27z" />
      </g>
    </svg>
  );
};
export default SvgPlane;
