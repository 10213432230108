import { EntityType } from 'UI/constants/entityTypes';

const LogRocketCustomEvents = {
  JobOrder: {
    ListView: 'JobOrder.ListView',
    ProfileView: 'JobOrder.ProfileView'
  },
  Sendout: {
    InitialCreation: 'Sendout.InitialCreation',
    SuccessfulCreation: 'Sendout.SuccessfulCreation',
    FailedCreation: 'Sendout.FailedCreation',
    InitialEdition: 'Sendout.InitialEdition',
    SuccessfulEdition: 'Sendout.SuccessfulEdition',
    FailedEdition: 'Sendout.FailedEdition'
  },
  Sendover: {
    SuccessfulCreation: 'Sendover.SuccessfulCreation',
    FailedCreation: 'Sendover.FailedCreation',
    InitialEdition: 'Sendover.InitialEdition',
    SuccessfulEdition: 'Sendover.SuccessfulEdition',
    FailedEdition: 'Sendover.FailedEdition',
    SuccessfulConvertion: 'Sendover.SuccessfulConvertion',
    FailedConvertion: 'Sendover.FailedConvertion'
  },
  Profile: {
    NewVersionBar: 'Profile.NewVersionBar',
    OldVersionBar: 'Profile.OldVersionBar',
    QuickView: {
      Candidate: 'Profile.QuickView.Candidate',
      Joborder: 'Profile.QuickView.Joborder',
      Company: 'Profile.QuickView.Company',
      Contact: 'Profile.QuickView.Contact',
      HiringAuthority: 'Profile.QuickView.HiringAuthority'
    }
  }
};

export const getProfileQuickViewByEntity = entity => {
  return {
    [EntityType.Candidate]: LogRocketCustomEvents.Profile.QuickView.Candidate,
    [EntityType.Joborder]: LogRocketCustomEvents.Profile.QuickView.Joborder,
    [EntityType.Company]: LogRocketCustomEvents.Profile.QuickView.Company,
    [EntityType.Contact]: LogRocketCustomEvents.Profile.QuickView.Contact,
    [EntityType.HiringAuthority]: LogRocketCustomEvents.Profile.QuickView.HiringAuthority
  }[entity];
};

export { LogRocketCustomEvents };
