import { Grid } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';

export const gridConfig = {
  maxContainer: 1080,
  editionDrawer: {
    md: 6,
    sm: 6,
    xs: 6,
    lg: 6
  },
  itemCreation: {
    md: 3,
    sm: 6,
    xs: 12
  }
};

export const GridItemBox = styled(Grid)({
  padding: `${THEME.spacing(2, 2, 2, 0)} !important`
});

export const GridOnDrawer = styled(Grid)({
  padding: THEME.spacing(2)
});

export const GridLayout = styled('div')({
  maxWidth: gridConfig.maxContainer,
  width: '100%',
  padding: THEME.spacing(2)
});
