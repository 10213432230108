// @flow
import differenceBy from 'lodash/differenceBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { getCurrentUser } from 'services/Authentication';
import { Permissions, userHasPermission, userHasRoles } from 'services/Authorization';
import { DateFormats } from 'UI/constants/defaults';
import { EntityType } from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { SendoutStatus, SendoverStatus } from 'UI/constants/status';
import { mergeHourToDate, VALIDATION_REGEXS } from 'UI/utils';

/**
 * Permisions Sections.
 */
export const SendoutRolesFullPermissions = [
  Roles.DataCoordinator,
  Roles.Operations,
  Roles.RegionalDirector,
  Roles.Leadership
];

export const SendoutsStatusesFullEdit = [
  SendoutStatus.Active,
  SendoverStatus.Active,
  SendoverStatus.NoOffer,
  SendoverStatus.Declined,
  SendoutStatus.NoOffer,
  SendoutStatus.Declined
];

export const StatusToDeclinate = [
  SendoutStatus.Declined,
  SendoutStatus.NoOffer,
  SendoverStatus.NoOffer,
  SendoverStatus.Declined
];

export const SendoutActiveStatuses = [SendoutStatus.Active, SendoutStatus.Active];
export const SendoutPlacementStatuses = [SendoverStatus.Placement, SendoutStatus.Placement];

export const formNames = {
  typeSendout: {
    name: 'type_id',
    formalName: 'Sendout Types'
  },
  candidate: {
    name: 'candidate_id',
    formalName: 'Candidate',
    required: true
  },
  feeAmount: {
    name: 'fee_amount',
    formalName: 'Estimated fee amount',
    required: true,
    desactiveIf: 2
  },
  hiringAuthority: {
    name: 'hiring_authority',
    formalName: 'Hiring Authority',
    required: true
  },
  emailSubject: {
    name: 'subject',
    formalName: 'Subject',
    required: true
  },
  emailBody: {
    name: 'template',
    formalName: 'Email',
    required: true
  },
  bcc: {
    name: 'bcc_emails',
    formalName: 'BCC',
    required: true
  },
  cc: {
    name: 'cc_emails',
    formalName: 'CC',
    required: true
  },
  status: {
    name: 'status_id',
    formalName: 'Status',
    required: true
  },
  sendEmail: {
    name: 'send_email',
    required: false,
    formalName: `Don't send email to Hiring Authority`
  },
  candidateAccountable: {
    name: 'candidate_accountable_id',
    required: true,
    formalName: 'Candidate Recruiter'
  },
  jobOrderAccountable: {
    name: 'job_order_accountable_id',
    required: true,
    formalName: 'Accountable Recruiter'
  }
};

export const initialStatus = { title: 'Active', id: '1', color: '#27AE60' };

export const ButtonStates = {
  SaveAndSend: 'Save & Send',
  Update: 'Update',
  UpdateAndSend: 'Update & Send',
  ChangeToSendover: 'Change to Sendout',
  Save: 'Save',
  PreviewAndSend: 'Preview & Send'
};

export const SendoverReasons = [
  'Could not connect live',
  'Need to show resume to the decision maker',
  'Need to check schedule for a time to interview'
];

export const ReasonsSendover = [
  {
    name: 'sendover_decline',
    type: 'checkbox',
    title: 'Reason why sendout wasn’t arranged at time of presentation?',
    options: {
      data: SendoverReasons
    },
    required: true,
    customValidation: fields => {
      return (fields && fields.length > 0) || 'Select at least one option';
    }
  },
  {
    name: 'sendover_notes',
    type: 'textbox',
    label: 'Other reasons or comments',
    required: false
  }
];

export const SendoverSwitchTypes = {
  NoSendMail: 0,
  SendMail: 1
};

/**
 * Get the valid recruiter to use
 * @param {object} joRecruiter Recruiter Object
 * @param {object[]} joAdditionalRecruiter Aditional recruiters array
 * @returns recruiter object
 */
export const getCurrentJobOrderOwner = (joRecruiter: any, joAdditionalRecruiter: any[]) => {
  if (!joAdditionalRecruiter) return joRecruiter;
  const currentUser = getCurrentUser();

  const aditionalRecruiter = joAdditionalRecruiter.find(
    rcr => rcr?.recruiter?.id === currentUser?.id
  );
  return aditionalRecruiter || joRecruiter;
};

export const findAccountableOnJobOrder = (jobOrder: any, accountableId: number) => {
  if (!jobOrder || !jobOrder.additionalRecruiters) return null;
  const allRecruiters = [
    ...jobOrder.additionalRecruiters.map(rcr => rcr.recruiter),
    jobOrder.recruiter
  ];
  return allRecruiters.find(rcr => rcr.id === accountableId);
};

/** Build form schema */
export const buildRegisterSchema = formValue => {
  const isRequired = formValue.required ? { required: `${formValue.formalName} is required` } : {};

  const customValidation = formValue.customValidation || {};

  return {
    ref: { name: formValue.name },
    validation: { validate: customValidation, ...isRequired }
  };
};

/**
 * replace the text if match with regex expression
 * @param {string} text
 * @param {string} value
 * @returns text modify or not
 */
export const replaceSmartTags = (text: string, value: string) => {
  const regexPattern = new RegExp(VALIDATION_REGEXS.SMART_TAGS).test(text);
  return regexPattern ? text.replaceAll(VALIDATION_REGEXS.SMART_TAGS, value) : text;
};

export const getRangeMergedTemplate = (date, from, to) => {
  const formatedDate = moment(date).format(DateFormats.DayAndLargeDate);
  const formatedHourFrom = moment(from).format(DateFormats.SimpleTime);
  const formatedHourTo = moment(to).format(DateFormats.SimpleTime);

  return `${formatedDate}, ${formatedHourFrom} - ${formatedHourTo}`;
};

export const formatAttachmentstoSaveSendout = (attachments = [], savedAttachments) => {
  /**
   * When is saving data, you pass all candidate files selectioned to save into sendout
   * When are switching, you send the new candidate files to save into sendout data
   * but are cases when the file already exists and you want to send it by mail, so its sended in _toSend_ prop
   */
  const attachmentsToSend = {};
  attachmentsToSend.candidate = !savedAttachments
    ? attachments
    : differenceBy(attachments, savedAttachments, 'file_name');
  attachmentsToSend.sendout = [];
  attachmentsToSend.toSend = !savedAttachments
    ? attachments
    : attachments.filter(each =>
        savedAttachments.some(saved => saved.file_name === each.file_name)
      );
  return attachmentsToSend;
};

/** Shorthand to format recruiter object. */
const formatRecruiterObject = rcr => (rcr ? { ...rcr.personalInformation, ...rcr } : {});

/**
 * Method to get the candidate recruiters formated to use in Select.
 * @param {Object} [sendout.candidate] - Contains candidate's data
 * @param {Object?} [sendout.candidateAccountable] - Could contain accountable data
 * @returns Array of recruiters
 */
export const getAdditionalsRecruitersFormated = sendout => {
  if (!sendout) return [];

  const { candidate, candidateAccountable } = sendout;
  const { additionalRecruiters, recruiter } = candidate || {};

  const formatedAccountable = candidateAccountable && formatRecruiterObject(candidateAccountable);
  const formatedRecruiter = formatRecruiterObject(recruiter);

  /** Get list of all additionals recruiters formated if they exist  */
  const allRecruitersFormated =
    additionalRecruiters?.length > 0
      ? [
          ...additionalRecruiters.map(item => formatRecruiterObject(item.recruiter)),
          formatedRecruiter
        ]
      : [formatedRecruiter];

  if (userHasPermission(SendoutRolesFullPermissions))
    return [...allRecruitersFormated, formatedAccountable];

  const existInCandidateDig =
    candidateAccountable && allRecruitersFormated.some(rcr => rcr.id === formatedAccountable.id);
  return !existInCandidateDig && !!candidateAccountable
    ? [...allRecruitersFormated, formatedAccountable]
    : allRecruitersFormated;
};

export const candidateTeamHasPermission = (userId, candidate, accountableId) => {
  if (!candidate) return true;

  const idPermissions = []; // <- Here collects all users with permissions
  accountableId && idPermissions.push(accountableId);

  candidate?.coach?.id && idPermissions.push(candidate?.coach?.id);
  Array(candidate?.additionalRecruiters).forEach(rcr => {
    if (!accountableId) {
      idPermissions.push(rcr.id);
      return;
    }

    const collaboratorBelongsToAccountable = rcr.recruiter_to_collaborate_id === accountableId;
    collaboratorBelongsToAccountable && idPermissions.push(rcr.recruiter_to_collaborate_id);

    const isRecruiterAccountable = rcr.id === accountableId;
    isRecruiterAccountable && idPermissions.push(rcr.id);
  });

  return idPermissions.includes(userId);
};

const restrictedStatuses = [SendoutStatus.Placement, SendoutStatus.FallOff];

/**
 * > If the user has the roles of Data Coordinator or Operations and the permission to use the
 * Placements module, then show all statuses except for Placement and Fall Off
 * @returns A boolean value.
 */
export const applyRestrictionToStatuses = statuses => {
  const userHasPlacementModule = userHasPermission(Permissions.Placements.CanUseModule);
  const canUseAllStatuses =
    userHasRoles([Roles.DataCoordinator, Roles.Operations]) && userHasPlacementModule;

  return canUseAllStatuses
    ? statuses
    : statuses.filter(({ id: statusId }) => !restrictedStatuses.includes(statusId));
};

const sharedTags = {
  HAFullName: '{{hiring_authority_full_name}}',
  HAName: '{{hiring_authority_name}}',
  HAEmail: '{{hiring_authority_email}}',
  jobOrderTitle: '{{job_order_title}}',
  companyName: '{{company_name}}',
  candidateName: '{{candidate_full_name}}'
};

export const SendoutConfig = {
  maxInterviews: 10,
  defaultTypeOfSendout: 'Sendout',
  sendoutTemplateTags: {
    interviews: '{{interview_dates}}',
    candidatePhone: '{{candidate_phone}}',
    candidateFirstName: '{{candidate_name}}',
    candidateEmail: '{{candidate_email}}',
    ...sharedTags
  },
  sendoverTemplateTags: sharedTags
};

export const SwitchSendoverOptions = [
  {
    title: 'Switch and Send Mail',
    value: SendoverSwitchTypes.SendMail,
    visible: true
  },
  {
    title: 'Switch without Mail',
    value: SendoverSwitchTypes.NoSendMail,
    visible: true
  }
];

export const COLUMNS_DUPLICATE_TABLE = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-sendout-status',
          variant: 'outlined'
        }
      }
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          labelKey: 'title',
          baseClass: 'chip-sendout-type'
        }
      }
    }
  },
  {
    name: 'tracking_date',
    label: 'Tracking Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'sent_on',
    label: 'Resume Sent On',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'fee_amount',
    label: 'Total Fee',
    options: {
      sort: false,
      display: true,
      renderer: 'currency_fixed'
    }
  },

  {
    name: 'recruiters',
    label: 'Recruiters',
    options: {
      sort: false,
      display: true
    }
  }
];

export const TYPE_DISPLAY_SECTIONS = {
  DECLINED: 'showDeclinedSection',
  DUPLICATE: 'showDuplicateSection',
  VIEW_LOGS: 'showViewLogSection'
};

export const STATUS_DISPLAY_SECTIONS = {
  [TYPE_DISPLAY_SECTIONS.DECLINED]: { showDeclinedSection: false },
  [TYPE_DISPLAY_SECTIONS.DUPLICATE]: { showDuplicateSection: false },
  [TYPE_DISPLAY_SECTIONS.VIEW_LOGS]: { showViewLogSection: false }
};

export const CANDIDATE_INCLUDES = [
  'additionalRecruiters',
  'filesForSendouts',
  'recruiter',
  'personalInformation',
  'specialty',
  'phones'
];

export const SendoutState = {
  Choosing: 0,
  DetailSendout: 1,
  DetailSendover: 2,
  Create: 3,
  SwitchToSendout: 4
};

/**
 * It takes two objects, one with keys and one with values, and returns an object with the keys as
 * values and the values as keys
 * @param tags - {
    HAName: 'Cristopher',
    HAFullName: 'Cristopher Tovillado',
    HAEmail: 'fake.hiring@email.test',
    jobOrderTitle: 'Account Manager - Michigan',
    companyName: 'GPAC 320',
    candidateName: 'A J Scheidt',
    candidatePhone: '(734)-662-1794',
    candidateFirstName: 'A J',
    candidateEmail: 'aj.scheidt@hylant.com',
    interviews: 'Thursday, January 12, 03:00 PM CST'
  }
 * @param valueOfTags - an array of values for the tags
 * @returns An object with the tags as keys and the values as values.
 */
export const formattedTagsWithValues = (tags, valueOfTags) => {
  const tagsWithValues = {};
  Object.entries(tags).map(([propKey, propValue]) =>
    Object.assign(tagsWithValues, { [propValue]: valueOfTags[propKey] })
  );
  return tagsWithValues;
};

/**
 * It takes a template and a map of tags and values, and returns the template with the tags replaced
 * with the values
 * @param template - The template string that you want to replace the tags with values.
 * @param tagsWithValues - This is an object that contains the tags and their values.
 * @returns A function that takes a template and tagsWithValues and returns a template with the tags
 * replaced with the values.
 */
export const applyValueToTemplate = (template, tagsWithValues) => {
  let templateReplacedWithValues = template;
  Object.entries(tagsWithValues).forEach(([key, value]) => {
    const searchRegExp = new RegExp(key, 'gi');

    if (templateReplacedWithValues) {
      templateReplacedWithValues = templateReplacedWithValues.replace(searchRegExp, value || '');
    }
  });

  return templateReplacedWithValues;
};

/* The above code is defining a function called `buildFileExplorerSections` that takes an array of
`candidateFiles` as a parameter. It then returns an array of objects, where each object represents a
section in a file explorer. The section has an `id`, a `title`, and an array of `files`. The `id` is
set to `EntityType.Candidate` and the `title` is set to 'Candidate files'. The `files` array is set
to the `candidateFiles` parameter, or an empty array if `candidateFiles` is falsy. */
export const buildFileExplorerSections = (candidateFiles: any[], jobOrderFiles: any[]) => [
  { id: EntityType.Candidate, title: 'Candidate files', files: candidateFiles || [] },
  { id: EntityType.Joborder, title: 'Job Order files', files: jobOrderFiles || [] }
];

export const FILE_SECTION_ENTITY = {
  SENDOUT: 'SENDOUT',
  CANDIDATE: 'CANDIDATE'
};

export const FILE_SECTION_TITLE = {
  [FILE_SECTION_ENTITY.SENDOUT]: 'SENDOUT ATTACHMENTS',
  [FILE_SECTION_ENTITY.CANDIDATE]: 'CANDIDATE ATTACHMENTS'
};

export const MAX_NUMBER_OF_FILES = 10;

export const MenuItemsToDisableFile = {
  Creation: ['Move to', 'Delete'],
  Detail: ['Move to']
};

export const FileUploaderMode = {
  CategoryField: 'categoryField',
  ListReadonly: 'listReadonly',
  ListSelectable: 'listSelectable'
};

export const FileKeyPrefix = 'file_';
export const AttachmentNameField = 'attachments';
export const FileNameField = 'file_name';

export const ResumeFileCandidateCategoryId = 4;
export const PortfolioFileCandidateCategoryId = 15;
export const OtherFileCandidateCategoryId = 5;

export const CandidateDebriefFileCandidateCategoryId = 22;
export const EmployerDebriefFileCandidateCategoryId = 40;
export const OfferLetterFileCandidateCategoryId = 31;

export const ResumeFileCategoryId = 24;
export const PortfolioFileCategoryId = 25;
export const OtherFileCategoryId = 26;
export const CandidateDebrief = 37;
export const EmployerDebrief = 38;
export const OfferLetter = 39;

export const CandidateFileCategoriesWithSendout = {
  [ResumeFileCategoryId]: ResumeFileCandidateCategoryId,
  [PortfolioFileCategoryId]: PortfolioFileCandidateCategoryId,
  [OtherFileCategoryId]: OtherFileCandidateCategoryId
};

export const SendoutFileCategoriesWithSendout = {
  [CandidateDebrief]: CandidateDebriefFileCandidateCategoryId,
  [EmployerDebrief]: EmployerDebriefFileCandidateCategoryId,
  [OfferLetter]: OfferLetterFileCandidateCategoryId
};

export const OriginalCandidateFileCategories = [
  ResumeFileCandidateCategoryId,
  PortfolioFileCandidateCategoryId,
  OtherFileCandidateCategoryId
];

export const OriginalDebriefFileCategories = [
  CandidateDebriefFileCandidateCategoryId,
  EmployerDebriefFileCandidateCategoryId,
  OfferLetterFileCandidateCategoryId
];

export const ValidCategoriesForCandidateFiles = [
  ResumeFileCategoryId,
  PortfolioFileCategoryId,
  OtherFileCategoryId
];

export const ValidCategoriesForSendoutFiles = [CandidateDebrief, EmployerDebrief, OfferLetter];

export const EntityTypesByCategoryId = {
  [CandidateDebrief]: EntityType.Candidate,
  [EmployerDebrief]: EntityType.Joborder,
  [OfferLetter]: EntityType.Candidate
};

export const filesForSendout = files =>
  files?.filter(file => OriginalDebriefFileCategories.includes(file.file_type_id));

export const filesForCandidate = files =>
  files?.filter(file => OriginalCandidateFileCategories.includes(file.file_type_id));

export const ExcludeOtherFilesCandidate = files =>
  files?.filter(file => file.file_type_id !== OtherFileCandidateCategoryId);

/**
 * The function filters files in each section based on a given category ID.
 * @param sections - An array of sections, where each section represents a file explorer section.
 * @param categoryId - The `categoryId` parameter is the ID of the category that you want to filter the
 * files by.
 */
export const getfileExplorerSectionByCategory = (sections, categoryId) =>
  sections?.filter(section => section.files.some(file => file.file_type_id === categoryId));

/**
 * The function `getFullDisplayHA` takes a hiring authority object as input and returns a new object
 * with an additional property `full_display` that combines the full name and work email of the hiring
 * authority.
 */
export const getFullDisplayHA = hiringAuthority =>
  hiringAuthority
    ? {
        ...hiringAuthority,
        full_display: `${hiringAuthority.full_name} - ${hiringAuthority.work_email}`
      }
    : null;

/**
 * The function `getCategoriesFiles` takes an array of files and returns a sorted and unique array of
 * file types.
 */
export const getCategoriesFiles = files =>
  sortBy(
    uniqBy(
      files?.map(file => file.fileType),
      'id'
    ),
    'order'
  );

/**
 * The function prepares an array of declined fields for declination details, including an "Other
 * Reason" note if provided.
 * @returns an array that has been prepared for declination details.
 */
export const prepareArrayToDeclinationDetails = ({
  declined_fields: declinedFields,
  declination_notes: declinationNotes
}) => {
  if (!declinedFields) return null;

  const otherReasonTitle = 'Other Reason';
  const list = declinationNotes
    ? declinedFields.filter(item => item !== otherReasonTitle)
    : declinedFields;
  declinationNotes && list.push(`${otherReasonTitle}: ${declinationNotes}`);

  return list;
};

export const getMergedDateTime = interview => {
  return mergeHourToDate(interview?.interview_date, moment(interview?.hour_fixed)).format(
    DateFormats.SendoutTemplateDate
  );
};

export const getMergedDateTimeTemplate = (interview, isEdition, isConvertion) => {
  const existRanges = !!interview.interview_range?.from && !!interview.interview_range?.to;

  let hourRangeTemplate;
  if (existRanges) {
    hourRangeTemplate = getRangeMergedTemplate(
      interview.interview_date,
      interview.interview_range.from,
      interview.interview_range.to
    );
  }

  if (!isEdition || isConvertion || interview.edited) {
    const hourTemplate = getMergedDateTime(interview);

    return existRanges
      ? `${hourRangeTemplate} ${interview.interview_time_zone.initials}`
      : `${hourTemplate} ${interview.interview_time_zone.initials}`;
  }

  const hourTemplate = interview.id
    ? moment(interview?.interview_date).format(DateFormats.SendoutTemplateDate)
    : getMergedDateTime(interview);

  return existRanges
    ? `${hourRangeTemplate} ${interview.interview_time_zone.initials}`
    : `${hourTemplate} ${interview.interview_time_zone.initials}`;
};
