import { EntityRoutes } from 'routes/constants';

// TODO: adjust this columns, virtual props, options and filters according the endpoint info
export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'name',
    label: 'Company',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile, // TODO: adjust this route
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'feeAmount',
    label: 'Fee Amount',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'hiringAuthority',
    label: 'Hiring Authority',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile, // TODO: adjust this route
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'jobOrder',
    label: 'Job Order',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile, // TODO: adjust this route
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'candidate',
    label: 'Candidate',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile, // TODO: adjust this route
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'created_at',
    label: 'Created at',
    options: {
      display: true,
      sort: false,
      renderer: 'date'
    }
  },
  {
    name: 'joAccountable',
    label: 'JO Accountable',
    options: {
      sort: true,
      display: true,
      renderer: 'default'
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'full_name',
  direction: 'asc'
};

export const VIRTUAL_PROPS = [
  {
    propName: 'name',
    attributes: ['id', 'name']
  }
];

export const INLINE_FILTERS = ['dateRange', 'startDate', 'state', 'industry'];
