// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useInlineFiltersContext } from 'features/command-center/components/shared/InlineFilters/InlineFiltersProvider';
import { userHasRoles } from 'services/Authorization';
import strings from 'strings';
import { FPCard, FPCardContent } from 'UI/components/atoms/FPCard';
import { When } from 'UI/components/atoms/When';
import { FeatureFlags } from 'UI/constants/featureFlags';
import {
  AutocompleteByCoach,
  AutocompleteByRegional
} from 'UI/pages/Dashboard/ToolsUsageDashboard/Content';
import { hasFeatureFlag } from 'UI/utils';

import {
  ALL_COMPANY_VISIBILITY_ROLES,
  PAGE_COACH_KEY,
  PAGE_REGIONAL_KEY,
  PREPEND_OPTIONS_ALL
} from './SelectTeamCard.constants';

export const SelectTeamCard = () => {
  const { handleFiltersChange, filters } = useInlineFiltersContext();

  const areMultipleDivisionsEnable = hasFeatureFlag(FeatureFlags.HomeMultipleRegions);
  const canSelectRegional =
    areMultipleDivisionsEnable && userHasRoles(ALL_COMPANY_VISIBILITY_ROLES);
  const canSelectCoach = userHasRoles(ALL_COMPANY_VISIBILITY_ROLES);

  const onSelectForRegional = (event, newValue) => {
    localStorage.setItem(PAGE_REGIONAL_KEY, JSON.stringify(newValue));
    localStorage.removeItem(PAGE_COACH_KEY);
    handleFiltersChange({
      regional: {
        paramName: 'regionalIds',
        value: newValue
      }
    });
  };

  const onSelectForCoach = (event, newValue) => {
    localStorage.setItem(PAGE_COACH_KEY, JSON.stringify(newValue));

    const updatedFilters =
      'id' in newValue
        ? {
            ...filters,
            coach: {
              paramName: 'coachIds',
              value: newValue
            }
          }
        : {};

    if (filters?.regional?.value) {
      updatedFilters.regional = filters.regional;
    }

    handleFiltersChange(updatedFilters);
  };

  return (
    <When condition={canSelectRegional || canSelectCoach}>
      <FPCard>
        <FPCardContent
          headerProps={{
            title: strings.home.selectTeam
          }}
        >
          <Grid container justify="space-between">
            <Grid container item spacing={1}>
              {areMultipleDivisionsEnable && canSelectRegional && (
                <Grid item xs={4}>
                  <AutocompleteByRegional
                    regional={filters.regional?.value ?? PREPEND_OPTIONS_ALL[0]}
                    onSelect={onSelectForRegional}
                    prependOptions={PREPEND_OPTIONS_ALL}
                  />
                </Grid>
              )}

              {canSelectCoach && (
                <Grid item xs={4}>
                  <AutocompleteByCoach
                    coach={filters.coach?.value ?? PREPEND_OPTIONS_ALL[0]}
                    onSelect={onSelectForCoach}
                    prependOptions={PREPEND_OPTIONS_ALL}
                    regional={filters.regional?.value}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </FPCardContent>
      </FPCard>
    </When>
  );
};
