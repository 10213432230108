// @flow
import React from 'react';
import ProfileTableLayout from 'UI/components/organisms/inventoryProfiles/ProfileTableLayout';
import { componentDimensions } from 'UI/constants/dimensions';
import { Endpoints } from 'UI/constants/endpoints';
import { TabKeys } from 'UI/constants/entityTypes';
import { SvgNoSearchProjects } from 'UI/res/icons/milano';

import { columns, virtualProps } from './columns';

const { emptyState } = componentDimensions;

type SearchProjectsTabProps = {
  endpoint: string,
  onOpenAddToSearchProjectForm: () => any,
  onRemoveProfileFromSearchProject: () => {},
  copies: string,
  profileId: string,
  shouldRefresh: boolean
};

const SearchProjectsTab = ({
  copies,
  endpoint,
  onOpenAddToSearchProjectForm,
  onRemoveProfileFromSearchProject,
  profileId,
  shouldRefresh,
  ...rest
}: SearchProjectsTabProps) => {
  const finalActions = [
    {
      applyPermissions: true,
      callback: ({ searchProjectId, searchProjectName }) => {
        const searchProject = {
          searchProjectId,
          copies: {
            removeDialog: copies.getRemoveDialogCopies(searchProjectName.searchProjectName)
          }
        };
        onRemoveProfileFromSearchProject && onRemoveProfileFromSearchProject(searchProject);
      },
      idKey: 'searchProjectId',
      name: 'remove'
    }
  ];
  const DataTableProps = {
    endpoint: `${endpoint}/${Endpoints.SearchProjects}`,
    virtualProps
  };

  const handleOpenAddToSPForm = () =>
    onOpenAddToSearchProjectForm && onOpenAddToSearchProjectForm();
  const handleEnableItem = ({ rowIndex }, data) => data[rowIndex].isCollaborator;

  return (
    <ProfileTableLayout
      customEmptyState={<SvgNoSearchProjects size={emptyState.width} />}
      DataTableProps={DataTableProps}
      finalActions={finalActions}
      initialColumns={columns}
      onNewItemClick={handleOpenAddToSPForm}
      orderByOptions={{ column: 'addedDate', direction: 'desc' }}
      customRowPermissionResolver={handleEnableItem}
      tabKey={TabKeys.SearchProjects}
      hasProfileLoaded={profileId}
      shouldRefresh={shouldRefresh}
      {...rest}
      {...copies.tab}
    />
  );
};

SearchProjectsTab.defaultProps = {
  onOpenAddToSearchProjectForm: () => {},
  onRemoveProfileFromSearchProject: () => {},
  copies: ''
};

export default SearchProjectsTab;
