import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  MessageContent,
  MessageList,
  SearchContactsDrawer,
  useTextMessages
} from 'features/message-center';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { UIStatus } from 'UI/constants/status';
import { SvgNoResults } from 'UI/res';

export const TextMessages = () => {
  const {
    handleClickMessage,
    handleClickToggleShowList,
    handleCloseSearchContactsDrawer,
    handleFetchMoreMessages,
    handleOpenSearchContactsDrawer,
    handleRefresh,
    handleSearch,
    messages,
    selectedMessage,
    uiState
  } = useTextMessages();

  const handleAddNewConversation = ({ externalNumer }) => {
    handleRefresh(externalNumer);
  };

  return (
    <ContentPageLayout mode="customLayout">
      {uiState.status === UIStatus.Error ? (
        <EmptyPlaceholder
          title={uiState.error}
          subtitle={strings.shared.errors.serverError.subtitle}
          customEmptyState={<SvgNoResults height={400} />}
        >
          <FPActionButton text="Try again" onClick={handleRefresh} />
        </EmptyPlaceholder>
      ) : (
        <Grid container>
          {uiState.showList && (
            <Grid item xs={4}>
              <MessageList
                isLoading={uiState.status === UIStatus.Loading}
                messages={messages}
                onClickMessage={handleClickMessage}
                onClickNewMessage={handleOpenSearchContactsDrawer}
                onClickRefresh={handleRefresh}
                onFetchMoreMessages={handleFetchMoreMessages}
                onSearch={handleSearch}
                selectedMessageId={selectedMessage?.id}
              />
            </Grid>
          )}

          <Grid item xs={uiState.showList ? 8 : 12}>
            <MessageContent
              key={selectedMessage?.id}
              message={selectedMessage}
              onClickShowList={handleClickToggleShowList}
            />
          </Grid>
        </Grid>
      )}
      {uiState.isSearchContactsDrawerOpen && (
        <SearchContactsDrawer
          isDrawerOpen={uiState.isSearchContactsDrawerOpen}
          onClose={handleCloseSearchContactsDrawer}
          onNewConversation={handleAddNewConversation}
        />
      )}
    </ContentPageLayout>
  );
};
