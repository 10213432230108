import { makeStyles } from '@material-ui/core/styles';
import { globalStyles } from 'GlobalStyles';
import {
  addMenu,
  navBarHeight,
  sideBarWidth,
  sideBarWidthCollapsed,
  versionBarHeight
} from 'UI/constants/dimensions';
import { colors } from 'UI/res';

export const useStyles = makeStyles(theme => ({
  app: {
    backgroundColor: colors.appBackground,
    height: ({ showVersionBar }) =>
      showVersionBar ? `calc(100% - ${versionBarHeight}px)` : '100%',
    position: 'relative'
  },
  flexContentWrapper: {
    height: `calc(100% - ${navBarHeight}px)`,
    flex: 1,
    display: 'flex',
    position: 'relative'
  },
  sidebar: {
    backgroundColor: theme.sidebarMenu.backgroundColor,
    borderRight: `1px solid ${colors.middleGrey}`,
    bottom: 0,
    height: '100%',
    left: 0,
    margin: 0,
    padding: 0,
    position: 'absolute',
    top: 0,
    transition: `width ${globalStyles.transition}`,
    width: ({ isSidebarOpen }) => (isSidebarOpen ? sideBarWidth : sideBarWidthCollapsed),
    zIndex: 101
  },

  addButton: {
    height: addMenu.addButton.height,
    backgroundColor: theme.palette.grey.black,
    color: theme.palette.brandColors.FPWhite,
    '&:hover': {
      backgroundColor: theme.palette.grey.black
    }
  }
}));
