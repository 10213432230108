// @flow
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Alert from '@material-ui/lab/Alert';
import { confirm, showAlert } from 'actions/app';
import * as BulkEmailActions from 'actions/bulkEmail';
import { useBulkQuotaRestriction } from 'hooks/bulkQuotaRestriction';
import { EntityRoutes } from 'routes/constants';
import { getSmartTags } from 'services/bulkEmail/smartTags';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { When } from 'UI/components/atoms/When';
import DialogDetailsBody from 'UI/components/molecules/DialogDetailsBody';
import FPButtonMenu from 'UI/components/molecules/FPButtonMenu';
import ScheduleDialog from 'UI/components/organisms/BulkEmail/ScheduleDialog';
import BulkEmailForm from 'UI/components/organisms/BulkEmailForm';
import { FormFieldsMap } from 'UI/components/organisms/BulkEmailForm/fields';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { BulkMenuItems, BulkScopes, PreviewTypes, StringsBooleanMap } from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { convertEmailToPlainText, dateFormatter, getRemainingTimeToDate } from 'UI/utils';
import { getActionDataToDispatch } from 'UI/utils/bulkEmail';
import { encryptId } from 'UI/utils/encrypt';
import { preloadFromBackend } from 'UI/utils/forms';

import DiscardDialog from '../BulkEmail/DiscardBulkDialog/DiscardDialog';
import RestrictionDialog from '../BulkEmail/RestrictionDialog/RestrictionDialog';

import { useStyles } from './styles';
import { getBulkEmailCustomValidations } from './utils';

const {
  setBulkEmailStatus,
  setBulkEmailToInitial,
  setBulkRestrictionToInitial,
  startRemovingAllAttachments
} = BulkEmailActions;

type BulkEmailDrawerProps = {
  loadLastBulkEmail?: boolean,
  onCloseDrawer: () => void,
  onDiscardChanges?: () => void,
  onSetSelectionToInitial?: () => void,
  queryParams?: Object,
  searchProject?: Object,
  selectedItems?: Array
};

const {
  forms: { validations: validationsCopies, fields: fieldsCopies, signatureInfo },
  schedule: { bulkFormAlert },
  dialogs: { sendBulk: dialogSendCopies }
} = strings.bulkEmails;

const arrayIsTruthy = arr => Array.isArray(arr) && arr.length > 0;

type CustomPrimarySubmitButtonProps = {
  bulkType: String<BulkMenuItems>,
  classes: Object,
  disabled: boolean,
  isPreviewEditionEnabled: boolean,
  onSaveChanges: () => void,
  onSendBulk: () => void,
  onScheduleBulk: () => void
};

const CustomPrimarySubmitButton = ({
  bulkType,
  classes,
  disabled,
  isPreviewEditionEnabled,
  onSaveChanges,
  onSendBulk,
  onScheduleBulk
}: CustomPrimarySubmitButtonProps) => {
  const { loading } = useSelector(store => store.bulkEmail.ui.status);
  const schedulePreposition =
    isPreviewEditionEnabled && bulkType === BulkMenuItems.Schedule ? 'Res' : 'S';
  const SubmitBulkOptions = [
    {
      title: 'Send Bulk',
      action: onSendBulk,
      visible: true
    },
    {
      title: `${schedulePreposition}chedule Bulk`,
      action: onScheduleBulk,
      visible: true
    },
    {
      title: 'Save Changes',
      action: onSaveChanges,
      visible: isPreviewEditionEnabled
    }
  ];

  return (
    <FPButtonMenu
      className={classes.primaryButton}
      disabled={disabled || loading}
      icon={() => (loading ? <CircularProgress size={20} color="inherit" /> : <ArrowDropDown />)}
      iconPosition="right"
      menuItems={SubmitBulkOptions}
      menuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        classes: { paper: null }
      }}
      text={isPreviewEditionEnabled ? 'Save Changes' : 'Send Bulk'}
    />
  );
};

const ScheduleInfoAlert = ({ classes, onOpenScheduleDialog, sendDate }) => {
  const { quantity, unitOfTime } = getRemainingTimeToDate(sendDate) || {};
  const formattedDate = dateFormatter(sendDate, ({ MonthDayYearTime }) => MonthDayYearTime);
  const alertMessage =
    quantity && unitOfTime
      ? strings.formatString(bulkFormAlert.remainingTime, { quantity, unitOfTime, formattedDate })
      : bulkFormAlert.scheduledFailed;
  return (
    <Alert
      action={
        <FPActionButton
          size="small"
          onClick={onOpenScheduleDialog}
          text="Reschedule"
          variant="outlined"
        />
      }
      className={classes.scheduleInfo}
      severity={quantity && unitOfTime ? 'info' : 'warning'}
    >
      {alertMessage}
    </Alert>
  );
};

const isSmartTagsErrorActive = errors => {
  const smartTagsTypeError = FormFieldsMap.SmartTags.key;
  const areErrorsEmpty = Object.keys(errors).length === 0;
  return (
    !areErrorsEmpty ||
    errors?.subject?.type === smartTagsTypeError ||
    errors?.emailBody?.type === smartTagsTypeError
  );
};

const BulkEmailDrawer = ({
  loadLastBulkEmail,
  onCloseDrawer,
  onDiscardChanges,
  queryParams,
  searchProject,
  selectedItems,
  onSetSelectionToInitial
}: BulkEmailDrawerProps) => {
  const classes = useStyles();

  const [uiState, setUiState] = useState({
    isFormLoading: false,
    scheduleBulk: false,
    shouldShowDiscardDialog: false
  });
  const dispatch = useDispatch();
  // TODO: possibly, remove sent prop from bulk email domain store as it's being replaced by bulkEmailInPreview
  const {
    domain: {
      template: { id: templateId, emailExcludedAttachments, name: templateName },
      attachments,
      bulkEmailInPreview
    },
    ui: { selectedMenuItem, status: bulkEmailStatus }
  } = useSelector(state => state.bulkEmail);
  const { sendDate } = bulkEmailInPreview.data;
  const isScheduleBeingEdited =
    selectedMenuItem === BulkMenuItems.Schedule && bulkEmailInPreview.edit;
  const isDraftBeingEdited = selectedMenuItem === BulkMenuItems.Drafts && bulkEmailInPreview.edit;

  const bulkRestrictionRule = bulkEmailStatus.bulkRestriction?.rule;

  const defaultValues = useMemo(() => {
    if (searchProject) {
      return preloadFromBackend({ searchProject }, { to: FormFieldsMap.To });
    }

    return bulkEmailInPreview.edit
      ? preloadFromBackend(bulkEmailInPreview.data, FormFieldsMap)
      : {};
  }, [bulkEmailInPreview, searchProject]);
  const form = useForm({ defaultValues });
  const { errors, getValues, setValue, handleSubmit, register, reset, triggerValidation, watch } =
    form;
  const watchers = watch([
    FormFieldsMap.BulkScope.key,
    FormFieldsMap.EmailBody.key,
    FormFieldsMap.JobOrderRecruitment.key,
    FormFieldsMap.MarketCandidates.key,
    FormFieldsMap.Subject.key,
    FormFieldsMap.To.key,
    FormFieldsMap.BulkQuota.key
  ]);

  const searchProjectSize = watchers.to?.totalItems;

  const shouldDisablePreviewButton =
    !watchers.to?.id ||
    !watchers.subject ||
    !watchers.emailBody ||
    isSmartTagsErrorActive(errors) ||
    bulkEmailStatus.loading;

  const { bulkQuota, bulkQuotaRestriction, notifyBulkQuotaRestriction, remainingQuota } =
    useBulkQuotaRestriction({ searchProjectSize });

  useEffect(() => {
    return () => {
      reset({});
      localStorage.removeItem(PreviewTypes.BulkEmail);
    };
  }, [reset]);

  useEffect(() => {
    if (!bulkEmailInPreview.edit) return;

    const { emailConfig: config, sendDate: previewSendDate } = bulkEmailInPreview.data;

    if (!config) return;

    setUiState(prev => ({ ...prev, isFormLoading: true }));
    if (config.blockResend) {
      register(
        { name: FormFieldsMap.ResendTime.key },
        { required: validationsCopies.resendTime.required }
      );
    }
    if (previewSendDate) {
      register(
        { name: FormFieldsMap.ScheduleDate.key },
        { required: validationsCopies.scheduleDate.required }
      );
      register(
        { name: FormFieldsMap.ScheduleTime.key },
        { required: validationsCopies.scheduleTime.required }
      );
    }
    if (config.bulkType) {
      const registerByTypes = {
        [BulkScopes.Marketing]: () => {
          register(
            { name: FormFieldsMap.MarketCandidates.key },
            { required: validationsCopies.marketCandidates.required }
          );
          register({ name: FormFieldsMap.BlockCompanies.key });
          register({ name: FormFieldsMap.AllowMarketingCandidates.key });
          register({ name: FormFieldsMap.BlockSigned.key });
        },
        [BulkScopes.Recruiting]: () => {
          register(
            { name: FormFieldsMap.JobOrderRecruitment.key },
            { required: validationsCopies.jobOrdersToRecruit.required }
          );
          register({ name: FormFieldsMap.AllowRecruitHirings.key });
        },
        [BulkScopes.General]: () => {
          register(
            { name: FormFieldsMap.GeneralBulkCategory.key },
            { required: validationsCopies.generalBulkCategory.required }
          );
        }
      };
      registerByTypes[config.bulkType.id] && registerByTypes[config.bulkType.id]();
    }
    setUiState(prev => ({ ...prev, isFormLoading: false }));
  }, [bulkEmailInPreview, register, dispatch, triggerValidation]);

  useEffect(() => {
    setUiState(prev => ({ ...prev, isFormLoading: true }));
    (async () => {
      const response = await getSmartTags();

      if (response.success && !getValues(FormFieldsMap.SmartTags.key)) {
        register({
          name: FormFieldsMap.SmartTags.key,
          value: [...response.data, signatureInfo.selectOption]
        });
        register(
          { name: FormFieldsMap.Subject.key, value: '' },
          {
            required: validationsCopies.subject.required,
            ...getBulkEmailCustomValidations({
              fieldName: 'subject',
              featureFlag: FeatureFlags.BulkSmartTagOnSubject,
              smartTags: response.data
            })
          }
        );
        register(
          { name: FormFieldsMap.EmailBody.key },
          {
            required: validationsCopies.emailBody.required,
            ...getBulkEmailCustomValidations({
              fieldName: 'email body',
              featureFlag: FeatureFlags.BulkSmartTagOnEmailBody,
              smartTags: response.data
            })
          }
        );
      } else {
        dispatch(showAlert(response.alert));
      }

      setUiState(prev => ({ ...prev, isFormLoading: false }));
    })();
  }, [dispatch, getValues, register, setValue]);

  useEffect(() => {
    if (!bulkEmailStatus.loading && (bulkEmailStatus.completed || bulkEmailStatus.sending)) {
      dispatch(setBulkEmailStatus({ completed: false }));
    }
  }, [bulkEmailStatus.completed, bulkEmailStatus.loading, bulkEmailStatus.sending, dispatch]);

  useEffect(() => {
    if (bulkRestrictionRule) {
      setUiState({ scheduleBulk: false, shouldShowDiscardDialog: false });
    }
  }, [bulkRestrictionRule]);

  const handleSubmitBulkEmail = useCallback(
    /**
     * @param {Object} formData
     * @param {Object} [options]
     * @param {boolean} [options.saveAsDraft=false]
     * @param {boolean} [options.saveAsSchedule=false]
     * @param {boolean} [options.sendNow=false]
     * @param {boolean} [options.unregisterScheduleFields]
     */
    (formData, options) => {
      const plain = convertEmailToPlainText(formData.emailBody);
      const bulkId =
        bulkEmailInPreview.edit && bulkEmailInPreview.data?.id ? bulkEmailInPreview.data.id : null;
      const sendScheduledBulk =
        bulkEmailInPreview.edit &&
        selectedMenuItem === BulkMenuItems.Schedule &&
        !options?.saveAsSchedule &&
        bulkId;
      dispatch(setBulkEmailStatus({ loading: true }));

      const shouldLoadLastEmailOnList =
        loadLastBulkEmail &&
        (selectedMenuItem === BulkMenuItems.Drafts ||
          selectedMenuItem === BulkMenuItems.Schedule ||
          (selectedMenuItem === BulkMenuItems.Sent && !options?.saveAsDraft));

      const actionOptions = {
        loadLast: shouldLoadLastEmailOnList,
        closeBulkDrawer: onCloseDrawer,
        setSelectionToInitial: onSetSelectionToInitial
      };

      const actionData = getActionDataToDispatch(
        {
          ...formData,
          saveAsDraft: options?.saveAsDraft,
          saveAsSchedule: options?.saveAsSchedule,
          bulkId,
          text: plain ? plain.trim() : 'This message has no text content',
          blockResend: StringsBooleanMap[formData.blockResend],
          selectedItems,
          queryParams
        },
        {
          attachments,
          bulkEmailInPreview,
          emailExcludedAttachments,
          selectedMenuItem,
          sendScheduledBulk,
          templateId
        }
      );

      if (actionData?.alert) {
        dispatch(showAlert(actionData.alert));
        dispatch(BulkEmailActions.setBulkEmailStatusToInitial());
      } else {
        const actionToExecute = BulkEmailActions[actionData.name];
        dispatch(actionToExecute(actionData.bulkParams, actionOptions));
      }
    },
    [
      bulkEmailInPreview,
      selectedMenuItem,
      dispatch,
      loadLastBulkEmail,
      onCloseDrawer,
      onSetSelectionToInitial,
      selectedItems,
      queryParams,
      attachments,
      emailExcludedAttachments,
      templateId
    ]
  );

  const handleToggleDiscardDialog = shouldShow => () =>
    setUiState(prev => ({ ...prev, shouldShowDiscardDialog: shouldShow }));

  const joinRowDetails = items => {
    if (!items) return { visible: false };
    const itemsNames = items.map(item => item.title);
    return {
      detail: itemsNames.join(', '),
      visible: itemsNames.length > 0
    };
  };

  const sendDialogDetails = [
    { title: 'To:', detail: `${watchers?.to?.name} (${watchers?.to?.totalItems})` },
    {
      title: 'Bulk Type:',
      detail: fieldsCopies.bulkType.typeAndDescription[watchers.bulkScope?.title]
    },
    {
      title: 'Candidates to Market:',
      ...joinRowDetails(watchers.marketCandidates),
      visible: arrayIsTruthy(getValues(FormFieldsMap.MarketCandidates))
    },
    {
      title: 'Job Orders to Recruit:',
      ...joinRowDetails(watchers.jobOrderRecruitment),
      visible: arrayIsTruthy(getValues(FormFieldsMap.JobOrderRecruitment))
    },
    { title: 'Template:', detail: templateName, visible: !!templateName }
  ];

  const onSubmit = formData => {
    if (bulkQuotaRestriction) {
      notifyBulkQuotaRestriction();
      return;
    }
    dispatch(
      confirm({
        ...dialogSendCopies,
        severity: 'warning',
        withButtons: 'YesNo',
        body: <DialogDetailsBody details={sendDialogDetails} />,
        onConfirm: ok => ok && handleSubmitBulkEmail(formData)
      })
    );
  };

  const handlePreviewBulkEmail = () => {
    const emailData = {
      emailBody: watchers.emailBody,
      searchProjectId: encryptId(watchers.to.id),
      subject: watchers.subject,
      template: templateName,
      attachments,
      queryParams,
      selectedItems
    };
    localStorage.setItem(PreviewTypes.BulkEmail, JSON.stringify(emailData));
    window.open(
      `${window.location.origin}${EntityRoutes.Preview}?type=${PreviewTypes.BulkEmail}`,
      '_blank'
    );
  };

  const toggleScheduleDialog = open => () => setUiState(prev => ({ ...prev, scheduleBulk: open }));

  const handleSchedule = async () => {
    dispatch(setBulkRestrictionToInitial());
    await triggerValidation();
    const isErrorsEmpty = Object.keys(errors).length === 0;
    isErrorsEmpty && toggleScheduleDialog(true)();
  };

  const handlers = {
    onSaveChanges: async () => {
      if (selectedMenuItem !== BulkMenuItems.Drafts) {
        await triggerValidation();
        const isErrorsEmpty = Object.keys(errors).length === 0;
        if (!isErrorsEmpty) return;
      }

      handleSubmitBulkEmail(getValues(), {
        saveAsDraft: isDraftBeingEdited,
        saveAsSchedule: isScheduleBeingEdited
      });
    },
    onSend: handleSubmit(onSubmit),
    onSchedule: handleSchedule,
    onCancel: handleToggleDiscardDialog(true),
    onPreview: handlePreviewBulkEmail
  };

  const handleTimeRestrictionDialogReschedule = () => {
    dispatch(setBulkRestrictionToInitial());
    toggleScheduleDialog(true)();
  };

  const handleSaveDraftDialog = () => {
    dispatch(setBulkRestrictionToInitial());
    handleSubmitBulkEmail(getValues(), { saveAsDraft: true });
    setUiState(prev => ({ ...prev, shouldShowDiscardDialog: false }));
    onCloseDrawer && onCloseDrawer();
  };

  const handleDiscardBulk = () => {
    setUiState(prev => ({ ...prev, shouldShowDiscardDialog: false }));
    if (onDiscardChanges) {
      onDiscardChanges();
    } else {
      dispatch(setBulkEmailToInitial());
      dispatch(startRemovingAllAttachments());
    }
    onCloseDrawer && onCloseDrawer();
  };

  return (
    <FormContext {...form}>
      <DrawerContentLayout
        customPrimaryButton={props => (
          <CustomPrimarySubmitButton
            bulkType={selectedMenuItem}
            isPreviewEditionEnabled={bulkEmailInPreview.edit}
            onSaveChanges={handlers.onSaveChanges}
            onSendBulk={handlers.onSend}
            onScheduleBulk={handlers.onSchedule}
            {...props}
          />
        )}
        title="Bulk Email"
        drawerProps={{
          classes: { paper: classes.drawerRoot },
          open: true
        }}
        onClose={handlers.onCancel}
        initialText="Send Bulk"
        primaryProps={{
          className: classes.primaryButton
        }}
        uiState={{ isFormLoading: uiState.isFormLoading }}
        footerActionsProps={{
          secondaryProps: {
            text: 'Cancel',
            disabled: bulkEmailStatus.loading,
            fullWidth: true
          },
          secondaryContainerClassName: classes.secondaryActionsButtons,
          customThirdButton: (
            <FPActionButton
              text="Preview"
              variant="outlined"
              onClick={handlers.onPreview}
              disabled={shouldDisablePreviewButton}
            />
          )
        }}
      >
        <When condition={sendDate && bulkEmailInPreview.edit}>
          <ScheduleInfoAlert
            classes={{ scheduleInfo: classes.scheduleInfo }}
            onOpenScheduleDialog={handlers.onSchedule}
            sendDate={sendDate}
          />
        </When>
        <When condition={bulkRestrictionRule}>
          <RestrictionDialog
            onOpenScheduleDialog={handleTimeRestrictionDialogReschedule}
            onClose={() => dispatch(setBulkRestrictionToInitial())}
            onSaveAsDraft={handleSaveDraftDialog}
            onSubmitBulkEmail={handleSubmitBulkEmail}
            bulkRestriction={bulkEmailStatus.bulkRestriction}
            isSendScheduleNow={sendDate && bulkEmailInPreview.edit}
          />
        </When>
        <When condition={uiState.shouldShowDiscardDialog}>
          <DiscardDialog
            onDiscard={handleDiscardBulk}
            onKeepEditing={handleToggleDiscardDialog(false)}
            onSaveAsDraft={handleSaveDraftDialog}
            isScheduleBeingEdited={isScheduleBeingEdited}
            isDraftBeingEdited={isDraftBeingEdited}
            selectedMenuItem={selectedMenuItem}
          />
        </When>
        <BulkEmailForm bulkQuota={bulkQuota} />
        <When condition={uiState.scheduleBulk}>
          <ScheduleDialog
            shouldUnregisterFieldsOnClose={!isScheduleBeingEdited}
            onClose={toggleScheduleDialog(false)}
            onSubmitBulkEmail={handleSubmitBulkEmail}
            remainingQuota={remainingQuota}
            searchProjectSize={searchProjectSize}
            notifyBulkQuotaRestriction={notifyBulkQuotaRestriction}
          />
        </When>
      </DrawerContentLayout>
    </FormContext>
  );
};

BulkEmailDrawer.defaultProps = {
  loadLastBulkEmail: false,
  onDiscardChanges: null,
  onSetSelectionToInitial: () => {},
  queryParams: null,
  searchProject: null,
  selectedItems: null
};

export default BulkEmailDrawer;
