import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  customDrawerToolbar: {
    height: 'auto',
    padding: 0
  },
  drawerContent: {
    flexGrow: 1,
    overflowY: 'auto',
    position: 'relative'
  }
});
