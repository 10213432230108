// @flow
import React from 'react';
import useFetchWithStatus from 'hooks/fetchWithStatus';
import { Endpoints } from 'UI/constants/endpoints';

type PhoneItem = {
  id: number,
  isDefault: boolean,
  phone: string,
  ext: string,
  use: Object,
  type: Object
};

type Catalogues = {
  countryCodes: Object,
  phoneTypes: Object
};

type RenderRowProps = {
  index: number,
  item: PhoneItem,
  catalogues: Catalogues
};

type PhoneNumbersListingProps = {
  phoneNumbers: Object[],
  renderRow: (params: RenderRowProps) => React.ReactNode
};

const API_VERSION = 1;

const PhoneNumbersListing = ({ phoneNumbers, renderRow }: PhoneNumbersListingProps) => {
  const { state: countryCodesState } = useFetchWithStatus(
    Endpoints.CountryCodes,
    null,
    null,
    API_VERSION
  );

  const { state: phoneTypesState } = useFetchWithStatus(
    Endpoints.PhoneTypes,
    null,
    null,
    API_VERSION
  );

  const catalogues = {
    countryCodes: countryCodesState?.results,
    phoneTypes: phoneTypesState?.results
  };

  return (
    <>{phoneNumbers.map((item: PhoneItem, index) => renderRow({ index, item, catalogues }))}</>
  );
};

export default PhoneNumbersListing;
