// @flow

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import type { FPActionButtonProps, MenuButtonItemsProps } from 'types/app';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import { Endpoints } from 'UI/constants/endpoints';
import { nestTernary } from 'UI/utils';
import { OptionRenderers } from 'UI/utils/renderers';

import FPButtonMenu from '../FPButtonMenu';
import Searchbar from '../Searchbar';

import { useStyles } from './styles';

type BulkEmailContentHeaderProps = {
  actionButtonProps?: FPActionButtonProps,
  loading?: boolean,
  menuItems?: Array<MenuButtonItemsProps>,
  onChange?: () => any,
  onSelect?: (params: Object) => any,
  searchbarPlaceHolder: string,
  shouldShowActions?: boolean,
  title: string
};

const BulkEmailContentHeader = ({
  actionButtonProps = null,
  loading = false,
  menuItems = null,
  onChange = null,
  onSelect = null,
  searchbarPlaceHolder,
  shouldShowActions = false,
  title
}: BulkEmailContentHeaderProps) => {
  const shouldShowActionButton = shouldShowActions && !menuItems && actionButtonProps;
  const shouldShowMenuItemsButton =
    shouldShowActions && menuItems && !loading && !actionButtonProps;

  const classes = useStyles({ shouldShowActionButton });
  const [search, setSearch] = useState({});

  const searchData = () => onChange && onChange({ ...search, pagination: 1 });

  const handleChange = (name, value) => setSearch({ [name]: value });
  const handleSearch = () => search?.keyword && searchData();
  const handleSearchAsync = () => searchData();

  const onSelectHandler = (name, value) => {
    if (value) onSelect({ id: value.id, entity: value.entity });
    onSelect({ id: null, entity: null }); // autocomplete clear
  };

  return (
    <Grid container alignItems="center" className={classes.contentHeader}>
      <Grid item className={classes.searchContainer}>
        {nestTernary(
          !!onSelect,
          <AutocompleteSelect
            placeholder={searchbarPlaceHolder}
            url={Endpoints.BulkEmailsTemplatesSearch}
            apiVersion={2}
            displayKey="name"
            typeahead
            typeaheadLimit={null}
            onSelect={onSelectHandler}
            renderOption={OptionRenderers.templatesSearch('name')}
            noMargin
          />,
          <Searchbar
            name="keyword"
            value={search.keyword || ''}
            placeholder={searchbarPlaceHolder}
            width="auto"
            onChange={handleChange}
            onSearch={handleSearch}
            onSearchAsync={handleSearchAsync}
          />
        )}
      </Grid>
      <span className={classes.divider} />
      <Grid
        item
        container
        alignItems="center"
        justify="space-between"
        className={classes.titleContainer}
      >
        <Grid item className={classes.title}>
          <Tooltip title={title} aria-label={title} placement="top-start">
            <span aria-label={title}>{title}</span>
          </Tooltip>
        </Grid>
        <Grid item>
          <When condition={shouldShowActionButton}>
            <FPActionButton
              data-testid="bulk-content-header-action-button"
              type="button"
              size="small"
              disabled={loading}
              {...actionButtonProps}
            />
          </When>
          <When condition={shouldShowMenuItemsButton}>
            <FPButtonMenu
              data-testid="bulk-content-header-menu-button"
              isIconButton
              menuItems={menuItems}
            />
          </When>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BulkEmailContentHeader;
