// @flow

import React from 'react';
import { useHistory } from 'react-router-dom';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import ErrorDiagnosticsPanel from 'UI/components/organisms/ErrorDiagnosticsPanel';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';

const ErrorFallback = exception => {
  const history = useHistory();

  const handleBack = () => history.goBack();

  return (
    <ContentPageLayout text={strings.shared.ui.unknownError.title}>
      <ErrorDiagnosticsPanel error={exception?.error?.message} />
      <p>
        <FPActionButton text={strings.shared.ui.unknownError.action} onClick={handleBack} />
      </p>
    </ContentPageLayout>
  );
};

export default ErrorFallback;
