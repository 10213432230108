// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { showAlert } from 'actions/app';
import copy from 'copy-to-clipboard';
import strings from 'strings';
import { FPCard } from 'UI/components/atoms/FPCard';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';

import { useStyles } from './styles';

type FPBannerProps = {
  emptyState: Object,
  redirectTo: String,
  showMenu: boolean,
  srcBanner: Object
};

const FPBanner = ({ emptyState, redirectTo, srcBanner, showMenu }: FPBannerProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.only('xs'));

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  const handleRedirect = () => {
    handleClose();
    if (!redirectTo) return;

    window.open(redirectTo, '_blank', 'noopener,noreferrer');
  };

  const handleCopyClick = () => {
    handleClose();
    if (!redirectTo) return;

    copy(redirectTo || '');
    dispatch(
      showAlert({
        severity: 'success',
        title: 'Awesome',
        body: 'Copied to Clipboard'
      })
    );
  };

  const bannerToShow = (
    <img
      className={classes.banner}
      src={isSmallViewPort ? srcBanner?.sm : srcBanner?.lg}
      alt="Gpac U"
    />
  );

  return (
    <FPCard className={classes.mainCard}>
      <div className={classes.container}>
        {srcBanner ? (
          <>
            {showMenu ? (
              <>
                <Paper
                  role="menu"
                  aria-controls="basic-menu"
                  elevation={0}
                  onClick={handleClick}
                  className={classes.mainCard}
                >
                  {bannerToShow}
                </Paper>
                <Popover
                  className={classes.popover}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                >
                  <MenuItem role="menuitem" className={classes.menuItem} onClick={handleCopyClick}>
                    Copy link to clipboard
                  </MenuItem>
                  <Divider className={classes.divider} />
                  <MenuItem role="menuitem" className={classes.menuItem} onClick={handleRedirect}>
                    Go to link
                  </MenuItem>
                </Popover>
              </>
            ) : (
              <Link
                classes={{ underlineHover: classes.link }}
                href={redirectTo}
                rel="noopener noreferrer"
                target="_blank"
              >
                {bannerToShow}
              </Link>
            )}
          </>
        ) : (
          <EmptyPlaceholder
            title={emptyState?.title || strings.shared.emptyState.title}
            subtitle={emptyState?.subtitle || strings.shared.emptyState.subtitle}
          />
        )}
      </div>
    </FPCard>
  );
};

FPBanner.defaultProps = {
  emptyState: null,
  redirectTo: '',
  showMenu: false,
  srcBanner: null
};

export default FPBanner;
