// @flow
import { PaymentMode } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { FeeAgreementFields } from 'UI/constants/status';
import { hasFeatureFlag, nestTernary } from 'UI/utils/index';

export const getFeeAgreementFieldsByPayment = (
  paymentMode: string,
  paymentTerms: strings
): string[] => {
  const isStandardish = PaymentMode.Standard || paymentMode === PaymentMode.BaseSalary;
  const fields = isStandardish
    ? [FeeAgreementFields.Percentage, FeeAgreementFields.GuaranteeDays]
    : nestTernary(
        paymentMode === PaymentMode.Conversion,
        [FeeAgreementFields.Percentage],
        [FeeAgreementFields.FlatAmount, FeeAgreementFields.GuaranteeDays]
      );
  hasFeatureFlag(FeatureFlags.FeeAgreementsSplitPayments) &&
    isStandardish &&
    paymentTerms &&
    paymentTerms !== 'default' &&
    fields.push(FeeAgreementFields.PaymentTerms);
  return fields;
};

export const getFeeAgreementFieldsByPaymentWithVerbiage = (
  paymentMode: string,
  paymentTerms: strings
) => {
  return [
    ...getFeeAgreementFieldsByPayment(paymentMode, paymentTerms),
    FeeAgreementFields.Verbiage
  ];
};
