// @flow
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { Loader } from 'features/command-center/components/shared/Widget/Loader';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { UIStatus } from 'UI/constants/status';

import { DetailsOverview } from './components/shared';
import { ClosingCall, DebriefingCalls, Notes, PreparationCalls } from './components';
import { DRAWER_TITLES_MAP, DRAWER_VARIANTS_MAP } from './InterviewDetailsDrawer.constants';
import { useInterviewDetailsHook } from './InterviewDetailsDrawer.hook';
import type { DrawerVariant } from './InterviewDetailsDrawer.types';
import { formatDefaultValues } from './utils';

interface InterviewDetailsDrawerProps {
  open: boolean;
  onClose: () => void;
  drawerVariant: DrawerVariant;
  interviewId?: number;
  onSubmit: (formData: any) => void;
  setDrawerVariant: (variant: string | null) => void;
}

const getContentToRender = ({ drawerVariant }) => {
  const CONTENT_MAP = {
    [DRAWER_VARIANTS_MAP.preparation]: <PreparationCalls />,
    [DRAWER_VARIANTS_MAP.debriefing]: <DebriefingCalls />,
    [DRAWER_VARIANTS_MAP.closingCall]: <ClosingCall />,
    [DRAWER_VARIANTS_MAP.notes]: <Notes />,
    DEFAULT: null
  };

  return CONTENT_MAP[drawerVariant] || CONTENT_MAP.DEFAULT;
};

const titleLabelProps = { style: { textTransform: 'capitalize' } };

export const InterviewDetailsDrawer = ({
  open,
  onClose,
  drawerVariant,
  interviewId,
  onSubmit,
  setDrawerVariant
}: InterviewDetailsDrawerProps) => {
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();

  const { detailsOverviewFetchState, formFetchState, existingItemId, DetailStatus } =
    useInterviewDetailsHook({
      drawerVariant,
      interviewId
    });

  const ACTION_VERB = existingItemId ? 'updated' : 'created';

  const onSave = async formData => {
    setIsSaving(true);
    const response = await onSubmit(formData, interviewId, existingItemId);
    setIsSaving(false);
    if (response.status === HTTPStatusCodes.Ok || response.status === HTTPStatusCodes.Created) {
      dispatch(
        showAlert({
          severity: 'success',
          title: `${DRAWER_TITLES_MAP[drawerVariant]} Call`,
          body: `The ${DRAWER_TITLES_MAP[drawerVariant]} Call was ${ACTION_VERB} succesfully`
        })
      );
      setDrawerVariant(null);
    } else {
      dispatch(
        showAlert({
          severity: 'error',
          title: DRAWER_TITLES_MAP[drawerVariant],
          autoHideDuration: 5000,
          body: `Something went wrong!`
        })
      );
    }
  };

  const form = useForm();
  const { handleSubmit, reset, watch } = form;

  const isLoading =
    formFetchState.status === UIStatus.Loading ||
    detailsOverviewFetchState.status === UIStatus.Loading;

  useEffect(() => {
    if (formFetchState.results) {
      const defaultValues = formatDefaultValues({
        data: formFetchState.results
      });
      reset(defaultValues);
    }
  }, [reset, formFetchState.results]);

  const isFormPreloaded =
    formFetchState.status === UIStatus.Success && Object.keys(watch()).length > 1;
  const isFormReady = isFormPreloaded || formFetchState.status === UIStatus.Error; // backend throws 404 if no interviews has been created

  return (
    <DrawerContentLayout
      title={DRAWER_TITLES_MAP[drawerVariant]}
      drawerProps={{ open }}
      onClose={onClose}
      uiState={{ isSaving, isFormLoading: isLoading }}
      primaryProps={{
        type: 'button',
        onClick: handleSubmit(onSave),
        disabled: isSaving || isLoading
      }}
      titleLabelProps={titleLabelProps}
    >
      {isFormReady && (
        <>
          <DetailStatus
            loading={<Loader />}
            success={details => <DetailsOverview detailsOverviewFetchState={details} />}
          />
          <Divider />
          <FormContext {...form}>{getContentToRender({ drawerVariant })}</FormContext>
        </>
      )}
    </DrawerContentLayout>
  );
};
