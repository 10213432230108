import React, { useCallback, useMemo } from 'react';
import { DRAWER_KEYS, StatsItemContainer, Widget } from 'features/command-center/components/shared';
import { COMMAND_CENTER_BASE_URL } from 'features/command-center/constants';
import { useDrawerManager } from 'features/command-center/contexts';
import { getActiveInventory } from 'features/command-center/services';
import strings from 'strings';

import { formatStats, getLastThirtyDays } from './ActiveInventoryStatsSummary.utils';

export const ActiveInventoryStatsSummary = ({ requestParams, hasDrillDown }) => {
  const { openDrawer } = useDrawerManager();

  const promises = useCallback(() => {
    const drawerHandlers = hasDrillDown
      ? {
          candidates: {
            alpha: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.Candidates.Alpha,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            },
            cantHelp: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.Candidates.CantHelp,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            },
            total: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.Candidates.Total,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            },
            poejo: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.Candidates.Poejo,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            }
          },
          companies: {
            client: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.Companies.Client,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            },
            notSigned: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.Companies.NotSigned,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            },
            signed: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.Companies.Signed,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            },
            total: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.Companies.Total,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            }
          },
          hiringAuthorities: {
            client: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.HiringAuthorities.Client,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            },
            notSigned: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.HiringAuthorities.NotSigned,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            },
            signed: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.HiringAuthorities.Signed,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            },
            total: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.HiringAuthorities.Total,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            }
          },
          jobOrders: {
            cantHelp: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.JobOrders.CantHelp,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            },
            total: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.JobOrders.Total,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            },
            poejo: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.JobOrders.Poejo,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            },
            searchAssignment: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.JobOrders.SearchAssignment,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            }
          },
          names: {
            candidateProspect: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.Names.CandidateProspect,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            },
            hiringAuthorityProspect: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.Names.HiringAuthorityProspect,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            },
            total: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.Names.Total,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            },
            undefinedProspect: ({ url }) => {
              openDrawer({
                activeDrawer: DRAWER_KEYS.Names.UndefinedProspect,
                contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
              });
            }
          }
        }
      : null;

    const { endDate, startDate } = getLastThirtyDays();

    return Promise.all([
      getActiveInventory({
        ...requestParams,
        endLastActivityDate: endDate,
        startLastActivityDate: startDate
      })
    ]).then(([data]) => {
      return formatStats({ data, drawerHandlers });
    });
  }, [hasDrillDown, openDrawer, requestParams]);

  const formatStatsItems = stat => {
    return {
      ...stat,
      valueColor: stat.color
    };
  };

  const cachedPromises = useMemo(() => [promises], [promises]);

  return (
    <Widget
      header={strings.commandCenter.activity.activeInventory.title}
      promises={cachedPromises}
      subheader={strings.commandCenter.activity.activeInventory.subtitle}
    >
      {([response]) => {
        const data = response?.data ?? response;

        return (
          <>
            {Object.keys(data ?? {}).map(key => {
              const currentStats = data[key];
              if (!currentStats || !Array.isArray(currentStats)) return null;

              return (
                <StatsItemContainer
                  key={key}
                  items={currentStats.map(formatStatsItems)}
                  textAlign="center"
                />
              );
            })}
          </>
        );
      }}
    </Widget>
  );
};
