// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { confirm, showAlert } from 'actions/app';
import { useProfileQuickView } from 'hooks/useProfileQuickView';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import strings from 'strings';
import type { ProfileDataTableProps } from 'types/app';
import { When } from 'UI/components/atoms/When';
import ProfileTableLayout from 'UI/components/organisms/inventoryProfiles/ProfileTableLayout';
import LinkEntitiesForm from 'UI/components/organisms/LinkEntitiesForm';
import { componentDimensions } from 'UI/constants/dimensions';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType, entityTypes, TabKeys } from 'UI/constants/entityTypes';
import { SvgNoCompanies } from 'UI/res/icons/milano';
import { getErrorMessage } from 'UI/utils';
import { Renderers } from 'UI/utils/renderers';
import { updateUiState } from 'UI/utils/uiHelpers';

import FPQuickView from '../FPQuickView';

import { CompanyColumns } from './columns';

type CompaniesTabProps = {
  DataTableProps: ProfileDataTableProps,
  endpoint: string,
  onCompaniesChanged: any => void,
  profileId: string,
  viewPresset: Object,
  profileName: string
};

const {
  inventoryProfiles: {
    emptyStates: { company: companyEmptyStrings }
  },
  hiringAuthorities: { messages }
} = strings;

const { emptyState } = componentDimensions;

const CompaniesTab = ({
  endpoint,
  onCompaniesChanged,
  profileId,
  DataTableProps,
  profileName
}: CompaniesTabProps) => {
  const dispatch = useDispatch();

  const [uiState, setUiState] = useState({
    isCompanyOpen: false,
    shouldRefreshTableData: false,
    isQuickViewOpen: false
  });

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   C A N D I D A T E    A C T I O N S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const toogleCompanyOpen = () =>
    updateUiState(
      { isCompanyOpen: !uiState.isCompanyOpen, shouldRefreshTableData: false },
      setUiState
    );

  const handleCompanyAssignCompleted = () => {
    updateUiState({ shouldRefreshTableData: true }, setUiState);
    toogleCompanyOpen();
  };

  const handleRefreshData = shouldRefresh =>
    updateUiState({ shouldRefreshTableData: shouldRefresh }, setUiState);

  const handleProfileUpdate = async () => {
    handleRefreshData(true);
    await onCompaniesChanged();
  };

  const confirmAndPerformAction = ({
    actionTitle = 'Please Confirm',
    confirmMessage,
    successMessage,
    successTitle = 'Awesome',
    apiAction
  }) => {
    dispatch(
      confirm({
        severity: 'warning',
        title: actionTitle,
        message: confirmMessage,
        onConfirm: async ok => {
          if (!ok) return;

          const successfulStatuses = [200, 201, 204];
          try {
            const response = await apiAction();
            if (successfulStatuses.includes(response.status)) {
              handleProfileUpdate();
              handleRefreshData(false);

              dispatch(
                showAlert({
                  severity: 'success',
                  title: successTitle,
                  body: successMessage
                })
              );
            }
          } catch (error) {
            dispatch(
              showAlert({
                severity: 'error',
                title: 'Error',
                body: getErrorMessage(error)
              })
            );
          }
        }
      })
    );
  };

  const handleCompanyRemoveClick = (item: any) =>
    confirmAndPerformAction({
      actionTitle: strings.hiringAuthorities.removeCompanyTitle,
      confirmMessage: strings.formatString(messages.confirmRemoveCompany, {
        company: item?.name?.name,
        hiringAuthority: profileName
      }),
      successTitle: strings.hiringAuthorities.successCompanyRemoved,
      successMessage: strings.formatString(messages.successRemoveCompany, {
        company: item?.name?.name,
        hiringAuthority: profileName
      }),
      apiAction: async () => {
        return API.delete(`${endpoint}/${Endpoints.Companies}/${item.id}`);
      }
    });

  const handleSetMainCompanyClick = (item: any) =>
    confirmAndPerformAction({
      confirmMessage: messages.confirmMainCompany,
      successMessage: messages.successMainCompany,
      apiAction: async () => {
        return API.post(`${endpoint}/${Endpoints.Companies}`, { companyId: item.id, isMain: true });
      }
    });

  const finalActions = [
    { name: 'delete', callback: handleCompanyRemoveClick },
    { name: 'setMainCompany', callback: handleSetMainCompanyClick }
  ];

  const {
    handleQuickViewPreview,
    itemsToNavigate,
    quickViewEntity,
    entityId,
    quickViewState,
    toggleQuickViewPreview
  } = useProfileQuickView({ quickViewEntityRoleProps: null });

  const handleRefreshTab = async () => {
    await updateUiState({ shouldRefreshTableData: true }, setUiState);
  };

  const handleTableDataLoaded = () => async () => {
    await updateUiState({ shouldRefreshTableData: false }, setUiState);
  };

  return (
    <>
      <ProfileTableLayout
        actionText="Link Company"
        tabKey={TabKeys.Companies}
        initialColumns={CompanyColumns}
        profileModule={EntityType.Company}
        onNewItemClick={toogleCompanyOpen}
        DataTableProps={{
          onFetchedData: uiState.shouldRefreshTableData && handleTableDataLoaded,
          ...DataTableProps
        }}
        finalActions={finalActions}
        customEmptyState={<SvgNoCompanies size={emptyState.width} />}
        shouldRefresh={uiState.shouldRefreshTableData}
        hasProfileLoaded={profileId}
        onQuickViewClick={handleQuickViewPreview}
        {...companyEmptyStrings}
      />

      <When condition={entityId && quickViewState.isQuickViewOpen}>
        <FPQuickView
          drawerProps={{
            open: quickViewState.isQuickViewOpen
          }}
          onClose={toggleQuickViewPreview}
          entityType={quickViewEntity}
          itemsToNavigate={itemsToNavigate}
          id={entityId}
          onEditionCompleted={handleRefreshTab}
        />
      </When>

      {uiState.isCompanyOpen && (
        <Drawer open onClose={toogleCompanyOpen}>
          <div role="presentation">
            <LinkEntitiesForm
              title="Link Company"
              placeholder="Search a Company by Name"
              sourceEntity={entityTypes.find(each => each.id === EntityType.Company)}
              idFieldName="companyId"
              keyToSelectAsPlaceholder="name"
              endpointToSaveEntity={`${endpoint}/${Endpoints.Companies}`}
              onCompleted={handleCompanyAssignCompleted}
              onClosed={toogleCompanyOpen}
              displayTemplate={Renderers.displayBasicInfoEntity}
              profileName={profileName}
              externalLink={option =>
                Renderers.externalLink(
                  EntityRoutes.CompanyProfile.replace(':id', option.id),
                  'View Company'
                )
              }
            />
          </div>
        </Drawer>
      )}
    </>
  );
};

export default CompaniesTab;
