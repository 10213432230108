// @flow
import React from 'react';

import Content from './Content';

const HotSheetFromChannelPartners = props => {
  return <Content {...props} />;
};

export default HotSheetFromChannelPartners;
