import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  input: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0
    },
    '& .MuiInputBase-root input': {
      cursor: 'pointer'
    }
  }
}));
