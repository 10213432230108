import strings from 'strings';

import { CandidatesDrawerContent } from './CandidatesDrawerContent';

const { candidates } = strings.commandCenter.drawers;

export const CandidatesConfig = {
  title: candidates.title,
  component: CandidatesDrawerContent
};
