import * as React from 'react';

const SvgExternalLink1 = props => {
  const { size = 24, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={size}
      width={size}
      fill={fill}
      {...props}
    >
      <g fill={fill}>
        <path d="M23.25 0h-8a.74.74 0 00-.69.47.74.74 0 00.16.81L18 4.51l-9.45 9.3a1 1 0 000 1.41 1 1 0 00.71.3 1 1 0 00.7-.28l9.48-9.33 3.29 3.24a.74.74 0 00.53.22.72.72 0 00.29-.06.74.74 0 00.45-.69V.75a.76.76 0 00-.75-.75z" />
        <path d="M18.5 10.62a1 1 0 00-1 1V22H2V6.5h10.37a1 1 0 000-2H1.87A1.87 1.87 0 000 6.37v15.75A1.88 1.88 0 001.87 24h15.75a1.88 1.88 0 001.88-1.88v-10.5a1 1 0 00-1-1z" />
      </g>
    </svg>
  );
};

export default SvgExternalLink1;
