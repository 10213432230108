import * as React from 'react';

const SvgLock = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <path d="M19.5 9.5h-.75V6.75a6.75 6.75 0 00-13.5 0V9.5H4.5a2 2 0 00-2 2V22a2 2 0 002 2h15a2 2 0 002-2V11.5a2 2 0 00-2-2zm-9.5 6a2 2 0 014 0 2 2 0 01-1 1.72v2.28a1 1 0 01-2 0v-2.28a2 2 0 01-1-1.72zM7.75 6.75a4.25 4.25 0 018.5 0V9a.5.5 0 01-.5.5h-7.5a.5.5 0 01-.5-.5z" />
    </svg>
  );
};

export default SvgLock;
