// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import { backNavigateListConfig } from 'UI/constants/config';
import { PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';

import SendoutsLoader from './SendoutsLoader';

const TypeTitle = {
  Weekly: 'Weekly',
  Yearly: 'Yearly'
};

export const AutocompleteForView = ({ viewType, onSelect }) => {
  return (
    <AutocompleteSelect
      placeholder="View"
      value={viewType.value}
      options={[{ title: TypeTitle.Yearly }, { title: TypeTitle.Weekly }]}
      onSelect={onSelect}
      disableClearable
    />
  );
};

export const AutocompleteByYear = ({ year, onSelect, onOptionsLoaded }) => (
  <AutocompleteSelect
    value={year}
    name="weekFilter"
    placeholder="Year"
    onSelect={onSelect}
    onOptionsLoaded={onOptionsLoaded}
    url={Endpoints.SendoutDashboardYears}
    disableClearable
  />
);

export const AutocompleteByWeek = ({ week, month, year, onSelect, onOptionsLoaded }) => (
  <AutocompleteSelect
    value={week}
    name="weekFilter"
    placeholder="Week"
    onSelect={onSelect}
    onOptionsLoaded={onOptionsLoaded}
    groupBy={option => option.month}
    url={`${Endpoints.SendoutDashboardWeeksByMonth}?year=${year?.date ? year.date : ''}&month=${
      month?.date ? month.date : ''
    }`}
    disableClearable
  />
);

const SendoutBoard = () => {
  const [week, setWeek] = useState(null);
  const [year, setYear] = useState(null);
  const [viewType, setViewType] = useState({
    value: { title: TypeTitle.Weekly },
    isYear: false
  });

  useEffect(() => {
    document.title = PageTitles.DashboardSendoutBoard;
  }, []);

  const onSelectForView = (event, newValue) => {
    const isYear = newValue.title === TypeTitle.Yearly;
    setViewType({
      value: newValue,
      isYear
    });

    isYear && setYear(null);

    if (newValue.title === TypeTitle.Weekly) {
      setWeek(null);
      setYear(null);
    }
  };

  const onSelectForYear = (event, newValue) => {
    setYear(newValue);
  };

  const onSelectForWeek = (event, newValue) => {
    setWeek(newValue);
    setYear(null);
  };

  const CURRENT_ELEMENT_INDEX = 0;
  const onOptionsLoadedForYear = useCallback((items?: any[]) => {
    setYear(items[CURRENT_ELEMENT_INDEX]);
  }, []);

  const onOptionsLoadedForWeek = useCallback((items?: any[]) => {
    setWeek(items[CURRENT_ELEMENT_INDEX]);
  }, []);

  const AutocompleteViewFilters = (
    <>
      <Grid item xs={5} sm={5} md={5} lg={5}>
        <AutocompleteForView viewType={viewType} onSelect={onSelectForView} />
      </Grid>
      <Grid item xs={7} sm={7} md={7} lg={7}>
        {viewType.isYear ? (
          <AutocompleteByYear
            year={year}
            onSelect={onSelectForYear}
            onOptionsLoaded={onOptionsLoadedForYear}
          />
        ) : (
          <AutocompleteByWeek
            week={week}
            year={year}
            onSelect={onSelectForWeek}
            onOptionsLoaded={onOptionsLoadedForWeek}
          />
        )}
      </Grid>
    </>
  );

  return (
    <ContentPageLayout
      titleLabelProps={backNavigateListConfig}
      customTitleGridConfig={{
        xs: 12,
        sm: 7,
        lg: 7
      }}
      text="Sendouts Board"
      actionsBarConfig={{
        xs: 12,
        sm: 5,
        lg: 5,
        spacing: 2
      }}
      actionsBar={AutocompleteViewFilters}
    >
      <SendoutsLoader endpoint={Endpoints.SendoutDashBoard} week={week} year={year} />
    </ContentPageLayout>
  );
};

export default SendoutBoard;
