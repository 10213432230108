import { LabelRenderers } from 'UI/utils/renderers';

export const createReadOnlySchema = (schema, data) => {
  const { feeAgreement: _feeAgreement, ...finalSection1Properties } =
    schema.properties.section1.properties;
  return {
    ...schema,
    properties: {
      ...schema.properties,
      section1: {
        ...schema.properties.section1,
        properties: {
          ...finalSection1Properties,
          ...(data?.section1?.feeAgreement
            ? {
                feeAgreement: {
                  title: schema.properties.section1.properties.feeAgreement.title,
                  type: 'string'
                }
              }
            : {})
        }
      },
      section5: {
        ...schema.properties.section5,
        properties: {
          ...schema.properties.section5.properties,
          workLocation: {
            title: schema.properties.section5.properties.workLocation.title,
            type: 'string'
          }
        }
      },
      section9: {
        ...schema.properties.section9,
        properties: {
          ...schema.properties.section9.properties,
          jobOrderType: {
            title: schema.properties.section9.properties.jobOrderType.title,
            type: 'string'
          },
          jobOrderStatus: {
            title: schema.properties.section9.properties.jobOrderStatus.title,
            type: 'string'
          }
        }
      }
    }
  };
};

export const createReadOnlyFormData = (formData, catalogs) => {
  if (!catalogs && formData) return formData;

  if (!catalogs || !formData) return undefined;

  const feeAgreementObj = catalogs.feeAgreements.data.find(
    feeAgreement => feeAgreement.id === formData.section1?.feeAgreement
  );

  const feeAgreementText = feeAgreementObj ? LabelRenderers.feeAgreement(feeAgreementObj) : '';

  return {
    ...formData,
    section1: {
      ...formData.section1,
      feeAgreement: feeAgreementText
    },
    section5: {
      ...formData.section5,
      workLocation:
        catalogs.workTypes.data.find(workType => workType.id === formData.section5?.workLocation)
          ?.title ?? ''
    },
    section9: {
      ...formData.section9,
      jobOrderType:
        catalogs.jobOrderTypes.data.find(
          jobOrderType => jobOrderType.id === formData.section9?.jobOrderType
        )?.title ?? '',
      jobOrderStatus:
        catalogs.jobOrderStatuses.data.find(
          jobOrderStatus => jobOrderStatus.id === formData.section9?.jobOrderStatus
        )?.title ?? ''
    }
  };
};
