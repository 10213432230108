import { makeStyles } from '@material-ui/core';

const panelListHeight = 450;
const iconWidth = 45;

export const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '8px'
  },
  cardHeader: {
    padding: theme.spacing(2, 0.5),
    '& .MuiCardHeader-avatar': {
      marginRight: theme.spacing(0.5)
    }
  },
  title: {
    fontWeight: theme.typography.fontWeightBold
  },
  list: {
    width: '100%',
    height: panelListHeight,
    overflow: 'auto'
  },
  listItem: {
    padding: theme.spacing(0, 0.5)
  },
  listItemPrimaryText: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '16px'
  },
  listItemSecondaryText: {
    fontSize: '14px'
  },
  listItemIcon: {
    minWidth: iconWidth
  },
  divider: {
    margin: 0
  }
}));
