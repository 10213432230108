// @flow
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import queryString from 'query-string';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import strings from 'strings';
import Text from 'UI/components/atoms/Text';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import DecisionDialog from 'UI/components/organisms/DecisionDialog';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { capitalizeFirstLetter, getId } from 'UI/utils';
import { decryptId } from 'UI/utils/encrypt';
import { OptionRenderers } from 'UI/utils/renderers';

import { useStyles } from './styles';

const ENDPOINTS_MAP = {
  [EntityType.Candidate]: {
    suggestions: Endpoints.CandidateSuggestedCompanies,
    relationship: Endpoints.CandidateCompany
  },
  [EntityType.ContactType.Contact]: {
    suggestions: Endpoints.NamesSuggestedCompanies,
    relationship: Endpoints.NamesCompany
  }
};

const NONE = 'none';

type LinkCompanyDialogTypes = {
  entity: Object,
  onSetProfile: () => any,
  type: 'candidate' | 'contact'
};

const LinkCompanyDialog = ({ type, entity, onSetProfile }: LinkCompanyDialogTypes) => {
  const classes = useStyles();
  const submitButton = useRef(null);

  const entityId = entity.id;
  const endpointByType = ENDPOINTS_MAP[type];
  const history = useHistory();
  const location = useLocation();

  const { company: companyId, cName } = queryString.parse(location.search);
  const dispatch = useDispatch();

  const [suggestedCompanies, setSuggestedCompanies] = useState({
    companies: null,
    selected: null,
    company: {
      id: null,
      name: null
    }
  });
  const [uiState, setUiState] = useState({
    taskCompleted: false,
    loadingContent: false,
    fetchingCandComp: false
  });

  const hasSuggestedCompanies =
    !!suggestedCompanies.companies && suggestedCompanies.companies.length > 0;
  const showCompanySelect = suggestedCompanies.selected === NONE || !hasSuggestedCompanies;

  const { register, unregister, setValue, handleSubmit, errors, clearError } = useForm();
  const linkCopies = useMemo(() => {
    const copies = { capitalizedType: capitalizeFirstLetter(type) };
    const { linkCompany } = strings.shared;
    Object.keys(linkCompany).forEach(key => {
      copies[key] = strings.formatString(linkCompany[key], {
        type,
        capitalizedType: copies.capitalizedType
      });
    });
    return copies;
  }, [type]);

  useEffect(() => {
    if (
      (suggestedCompanies.selected === NONE || suggestedCompanies.companies?.length === 0) &&
      !companyId &&
      !cName
    ) {
      register({ name: 'companies' }, { required: 'Company must be selected' });
    }
    if (suggestedCompanies.companies?.length > 0 && !suggestedCompanies.selected) {
      register({ name: 'suggestions' }, { required: 'You must select an option' });
    }
  }, [register, suggestedCompanies.selected, suggestedCompanies.companies, cName, companyId]);

  useEffect(() => {
    (async () => {
      try {
        setUiState(prev => ({
          ...prev,
          loadingSuggestions: true
        }));
        const response = await API.get(
          `${endpointByType.suggestions.replace(':id', entityId)}?limit=3`
        );
        if (response.status === HTTPStatusCodes.Ok) {
          setSuggestedCompanies(prev => ({
            ...prev,
            companies: response.data.map(company => ({
              id: company.id,
              title: company.name,
              extraInfo: `${company.specialty?.industry?.title} / ${company.city?.title}, ${company.city?.state?.title}`
            }))
          }));
        }
      } catch {
        return;
      } finally {
        setUiState(prev => ({
          ...prev,
          loadingSuggestions: false
        }));
      }
    })();
  }, [dispatch, entityId, endpointByType.suggestions]);

  useEffect(() => {
    if (!companyId || !cName) return;
    try {
      const newCompany = { id: decryptId(companyId, true)[0], name: cName };
      register(
        { name: 'companies', value: newCompany.id },
        { required: 'Company must be selected' }
      );
      setSuggestedCompanies(prev => ({ ...prev, selected: NONE, company: newCompany }));
    } catch (error) {
      dispatch(
        showAlert({
          severity: 'error',
          title: 'Invalid Company Reference.',
          body: error.message
        })
      );
    }
  }, [companyId, cName, setValue, register, dispatch]);

  const handleAssignCompanyToCandidate = async () => submitButton.current.click();

  const onSubmit = async () => {
    setUiState(prev => ({ ...prev, fetchingCandComp: true }));
    const compId =
      !suggestedCompanies.selected || suggestedCompanies.selected === NONE
        ? suggestedCompanies.company.id
        : Number(suggestedCompanies.selected);
    const response = await API.post(endpointByType.relationship.replace(':id', entityId), {
      company_id: compId
    });
    if (response.status === HTTPStatusCodes.Created) {
      onSetProfile({
        current_company: response.data.current_company,
        company_id: response.data.company_id
      });
      setUiState(prev => ({
        ...prev,
        taskCompleted: true,
        fetchingCandComp: false
      }));
      dispatch(
        showAlert({
          severity: 'success',
          title: `${linkCopies.capitalizedType} matched successfully`,
          body: `The ${linkCopies.capitalizedType} was successfully matched with ${suggestedCompanies.company.name} company`
        })
      );
      history.replace(history.pathname);
    }
  };

  const handleRadioChange = e => {
    const selectedId = e.target.value;
    let companyName = '';
    if (selectedId !== NONE) {
      unregister('companies');
      companyName = suggestedCompanies.companies.find(
        company => company.id === parseInt(selectedId, 10)
      ).title;
    }
    setValue('suggestions', e.target.value);
    setSuggestedCompanies(prev => ({
      ...prev,
      company: {
        id: selectedId,
        name: companyName
      },
      selected: e.target.value
    }));
  };

  const handleCompanySelect = (name, value) => {
    setValue(name, value?.id);
    clearError('companies');
    setSuggestedCompanies(prev => ({
      ...prev,
      company: {
        id: value?.id,
        name: value?.name
      }
    }));
  };

  const entityDetails = [
    { title: 'Name', description: entity.personalInformation?.full_name },
    { title: 'Title', description: entity.title },
    {
      title: 'Industry: Specialty',
      description: `${entity.specialty?.industry?.title}: ${entity.specialty?.title}`
    },
    { title: 'Functional Title', description: entity.position?.title },
    { title: 'Email', description: entity.email },
    { title: 'Phone', description: entity.personalInformation?.contact?.phone }
  ];

  return (
    <DecisionDialog
      title={linkCopies.title}
      withButtons="Confirmation"
      confirmButtonText="Continue"
      showSeverityIcon={false}
      onConfirm={handleAssignCompanyToCandidate}
      mustComplete
      taskCompleted={uiState.taskCompleted}
      loadingContent={uiState.loadingSuggestions}
      closeDialogOnConfirm={false}
      fetching={uiState.fetchingCandComp}
    >
      <Text text={hasSuggestedCompanies ? linkCopies.addCompany : linkCopies.noCompany} />
      {entity && (
        <Grid container className={classes.entityDetailsRoot}>
          <Grid container item alignItems="flex-start" className={classes.entityDetails}>
            {entityDetails.map(({ title, description }) => (
              <Grid key={getId()} item>
                <Text variant="body2" text={title} />
                <Text
                  variant="subtitle1"
                  className={classes.descriptionText}
                  text={description || 'N/A'}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className={classes.formRoot}>
        {hasSuggestedCompanies && (
          <>
            <Text
              text={hasSuggestedCompanies ? linkCopies.suggestions : ''}
              className={classes.suggestionsText}
            />
            <FPRadioGroup
              name="suggestions"
              value={suggestedCompanies.selected}
              options={[
                ...suggestedCompanies?.companies,
                { id: NONE, title: 'None of the above', extraInfo: '' }
              ]}
              onChange={handleRadioChange}
              error={!!errors.suggestions}
              errorMessage={!!errors.suggestions && errors.suggestions.message}
              column
              optionTextVariant="subtitle1"
              classes={{
                root: classes.radioGroupRoot,
                radio: classes.radioGroupRadio
              }}
            />
          </>
        )}
        {showCompanySelect && (
          <div className={classes.findCompanyRoot}>
            <Text text={linkCopies.selectCompany} />
            <AutocompleteSelect
              name="companies"
              selectedValue={suggestedCompanies.company}
              displayKey="name"
              placeholder="Company *"
              error={!!errors.companies}
              errorText={errors?.companies?.message}
              renderOption={OptionRenderers.globalSearchDefault('name')}
              typeahead
              typeaheadLimit={50}
              typeaheadParams={{ entityType: 'company', isForConvertion: true }}
              url={Endpoints.Search}
              createButton={{
                text: 'Create company',
                concatKeyword: true,
                showAlways: true,
                url: EntityRoutes.CompanyCreate,
                location
              }}
              onSelect={handleCompanySelect}
            />
          </div>
        )}
        <input ref={submitButton} hidden type="submit" />
      </form>
    </DecisionDialog>
  );
};

export default LinkCompanyDialog;
