import { THEME } from 'GlobalStyles';

import { createCustomTooltip } from '../finance.utils';

const FONT = THEME.typography.fontFamily;
export const MAIN_COLOR = THEME.palette.error.main;
export const SECONDARY_COLOR = THEME.palette.warning.main;

export const DEFAULT_OPTIONS = {
  chartArea: { right: 0, width: '90%' },
  hAxis: {
    title: '',
    titleTextStyle: { fontFamily: FONT, fontStyle: 'normal !important' }
  },
  vAxis: {
    title: '',
    titleTextStyle: { fontFamily: FONT, fontStyle: 'normal' },
    format: 'short'
  },
  colors: [MAIN_COLOR, SECONDARY_COLOR],
  fontName: FONT,
  animation: {
    startup: true,
    easing: 'inAndOut',
    duration: 1500
  },
  legend: 'none',
  tooltip: { isHtml: true },
  isStacked: true
};

const refactorName = name => {
  const splittedName = name.trim().split(' ');
  const firstName = splittedName[0].toUpperCase();
  const lastName = splittedName[splittedName.length - 1].toUpperCase();
  const lastNameInitial = `${lastName.charAt(0)}.`;

  return `${firstName} ${lastNameInitial}`;
};

export const formatResponse = data => {
  if (!data || data.length === 0)
    return [
      ['Name', 'At Risk', 'Behind'],
      ['', 0, 0]
    ];

  return [
    // first elements are labels
    [
      'Name',
      'At Risk',
      { role: 'tooltip', type: 'string', p: { html: true } },
      'Behind',
      { role: 'tooltip', type: 'string', p: { html: true } }
    ],
    // now the refactored data

    ...data.map(manager => [
      refactorName(manager.managerName),
      manager.atRisk.total,
      createCustomTooltip({
        category: manager.managerName.toUpperCase(),
        title: 'At Risk',
        value: manager.atRisk.total
      }),
      manager.behind.total,
      createCustomTooltip({
        category: manager.managerName.toUpperCase(),
        title: 'Behind',
        value: manager.behind.total
      })
    ])
  ];
};
