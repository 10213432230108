import * as React from 'react';

const SvgCalendarClock = props => {
  const { size, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={fill}
      {...props}
    >
      <g fill={fill}>
        <path d="M3.45 16.05A.45.45 0 013 15.6V7.95a.45.45 0 01.45-.45H15.6a.45.45 0 01.45.45v1.863a6.462 6.462 0 011.8 0V3.9a.9.9 0 00-.9-.9H14.7a.225.225 0 01-.225-.225v-.9a.675.675 0 00-1.35 0V5.25a.675.675 0 01-1.35 0v-1.8a.45.45 0 00-.45-.45H7.5a.225.225 0 01-.225-.225v-.9a.675.675 0 00-1.35 0V5.25a.675.675 0 01-1.35 0v-1.8a.45.45 0 00-.45-.45H2.1a.9.9 0 00-.9.9v12.15a1.8 1.8 0 001.8 1.8h6.813a6.462 6.462 0 010-1.8z" />
        <path d="M16.95 11.1a5.85 5.85 0 105.85 5.85 5.859 5.859 0 00-5.85-5.85zm0 9.9A4.05 4.05 0 1121 16.95 4.059 4.059 0 0116.95 21z" />
        <path d="M18.75 16.275h-1.125V14.7a.675.675 0 00-1.35 0v2.25a.684.684 0 00.675.675h1.8a.675.675 0 000-1.35z" />
      </g>
    </svg>
  );
};

export default SvgCalendarClock;
