// @flow
import React from 'react';
import {
  CollaborationsIcon,
  colors,
  FeeAgreementNegativeIcon,
  FeeAgreementPositiveIcon,
  NewInventoryItemNotificationIcon,
  Operating10Icon,
  PlacementsIcon,
  StatusInventoryItemNotificationIcon
} from 'UI/res';

export type notificationsType =
  | 'feeAgreementPositive'
  | 'feeAgreementIcon'
  | 'feeAgreementDeclined'
  | 'feeAgreementSigned'
  | 'feeAgreementValidate'
  | 'newInventoryItemNotificationIcon'
  | 'statusInventoryItemNotificationIcon'
  | 'collaborations'
  | 'operating10'
  | 'placements';

export const notificationIcons = [
  {
    key: 'feeAgreementPositive',
    icon: <FeeAgreementPositiveIcon fill={colors.black} />
  },
  {
    key: 'feeAgreementNegative',
    icon: <FeeAgreementNegativeIcon fill={colors.black} />
  },
  {
    key: 'newInventoryItemNotificationIcon',
    icon: <NewInventoryItemNotificationIcon fill={colors.success} />
  },
  {
    key: 'statusInventoryItemNotificationIcon',
    icon: <StatusInventoryItemNotificationIcon fill={colors.success} />
  },
  {
    key: 'operating10',
    icon: <Operating10Icon fill={colors.success} />
  },
  {
    key: 'collaborations',
    icon: <CollaborationsIcon fill={colors.success} />
  },
  {
    key: 'placements',
    icon: <PlacementsIcon fill={colors.black} />
  }
];
