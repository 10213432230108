import { makeStyles } from '@material-ui/core';
import { THEME } from 'GlobalStyles';
import { colors } from 'UI/res/colors';
import { flexAlignCenterJustifyCenter, flexAndWrap } from 'UI/utils/styles';

export const styles = {
  emptyImage: { margin: THEME.spacing(3) }
};

const maxFileUploaderWidth = 1360;

export const useStyles = makeStyles(theme => ({
  fileUploader: {
    display: 'grid',
    gap: theme.spacing(1),
    gridTemplateColumns: 'auto repeat(3, 1fr)'
  },
  spacing: { margin: theme.spacing(3) },
  fileListContainer: { padding: 0, flex: 1, backgroundColor: 'white' },
  boxesFPChips: {
    width: '100%',
    maxWidth: maxFileUploaderWidth,
    alignItems: 'center',
    ...flexAndWrap
  },
  wrapperFPChipsForm: {
    border: '1px solid',
    borderColor: colors.grey,
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(3),
    display: 'flex',
    boxSizing: 'content-box'
  },
  btnChipUpload: {
    width: 'auto',
    minWidth: 'auto',
    boxShadow: 'none',
    '& > span:first-child > span:last-child': {
      display: 'none'
    },
    '& > span:first-child > span:first-child': {
      margin: 0
    }
  },
  boxBtnUpload: {
    '& > div': {
      width: 'auto',
      margin: 0,
      height: '100%',
      ...flexAlignCenterJustifyCenter
    },
    '& label': {
      margin: 0
    }
  },
  boxesFpChips: {
    width: '100%',
    maxWidth: 1360,
    alignItems: 'center',
    ...flexAndWrap,
    '& > div': {
      width: 'inherit',
      height: 'auto'
    }
  },
  marginZero: {
    margin: 0
  },
  leftAuto: {
    marginLeft: 'auto'
  },
  modalWrapper: {
    ...flexAlignCenterJustifyCenter
  },
  imageWrapper: {
    width: '60vw',
    '& > img': {
      width: '100%',
      objectFit: 'contain'
    }
  },
  input: {
    margin: 0,
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0
    }
  },
  categoryField: {
    '& .MuiOutlinedInput-root': {
      flexWrap: 'wrap',
      padding: ({ hasFiles }) =>
        hasFiles ? theme.spacing(0.75, 8, 0.75, 0.75) : theme.spacing(0, 6, 0, 1.5)
    },
    '& .MuiChip-root': {
      margin: theme.spacing(0.25),
      display: 'flex',
      justifyContent: 'space-between'
    },
    '& input': {
      display: ({ hasFiles }) => (hasFiles ? 'none' : 'block')
    },
    '& .MuiInputBase-root, & .MuiInputBase-root input ': {
      cursor: 'pointer'
    }
  },
  chipError: {
    backgroundColor: colors.silverGrey,
    borderWidth: 1,
    color: colors.black,
    border: `1px solid ${colors.error}`,
    marginBottom: 15
  }
}));
