import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative'
    },
    listItemAvatar: {
      minWidth: 'fit-content',
      marginRight: theme.spacing(1)
    }
  }),
  { classNamePrefix: 'profileAvatar' }
);
