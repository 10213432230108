import { makeStyles } from '@material-ui/core/styles';
import { THEME } from 'GlobalStyles';
import { componentDimensions } from 'UI/constants/dimensions';
import { colors } from 'UI/res';
import { flexAlignCenterJustifyCenter } from 'UI/utils/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    ...flexAlignCenterJustifyCenter,
    flexDirection: 'column',
    textAlign: 'center',
    width: '100%',
    flex: 1,
    height: ({ height }) => height || '90%',
    backgroundColor: colors.transparent,
    padding: theme.spacing(2),
    position: 'relative'
  },
  defaultEmptyState: {
    maxWidth: componentDimensions.emptyState.width
  },
  title: {
    fontFamily: THEME.typography.fontFamilyHeader,
    fontWeight: THEME.typography.headerWeights.bold,
    letterSpacing: 0,

    [theme.breakpoints.up('lg')]: {
      fontSize: 22
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 22
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    }
  },
  subtitle: {
    marginTop: theme.spacing(0.5),
    fontFamily: THEME.typography.fontFamilyContent,
    fontWeight: THEME.typography.headerWeights.regular,
    [theme.breakpoints.up('lg')]: {
      fontSize: 18
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  textContainer: {
    height: '100%',
    ...flexAlignCenterJustifyCenter
  },
  children: {
    margin: theme.spacing(1.5, 0, 0)
  },
  contentWrapper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));
