// @flow

import { useEffect, useState } from 'react';

import { getCandidateDataSheetById } from '../CandidateDataSheet.services';

type UseFetchCandidateDataSheetDataParams = {
  candidateDataSheetId: string
};
const useFetchCandidateDataSheetData = ({
  candidateDataSheetId
}: UseFetchCandidateDataSheetDataParams) => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!candidateDataSheetId) return;

      setIsFetching(true);
      setError(null);

      try {
        const response = await getCandidateDataSheetById(candidateDataSheetId);

        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setIsFetching(false);
      }
    };

    fetchData();

    return () => {
      setIsFetching(false);
    };
  }, [candidateDataSheetId]);

  return {
    isFetching,
    error,
    data
  };
};

export default useFetchCandidateDataSheetData;
