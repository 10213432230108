// @flow
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import { showAlert as showAlertAction } from 'actions/app';
import { globalStyles, useGlobalStyles } from 'GlobalStyles';
import moment from 'moment';
import API from 'services/API';
import { getCurrentUser } from 'services/Authentication';
import { canUserOrStaffEditEntity } from 'services/Authorization';
import strings from 'strings';
import CustomDatePicker from 'UI/components/atoms/CustomDatePicker';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect, {
  statusRenderOption,
  statusStartAdornment
} from 'UI/components/molecules/AutocompleteSelect';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { YesNo } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { SvgContentPaperEdit } from 'UI/res';
import {
  ACHIEVEMENT_VALIDATION,
  getErrorMessage,
  relocationsGetOptionLabel,
  relocationsGetOptionSelected,
  relocationsGroupBy,
  REQUIRED_VALIDATION
} from 'UI/utils';
import { buildBasePropsForField, preloadFromBackend, prepareFormToSubmit } from 'UI/utils/forms';

import { FormFieldsMap, InterviewDatePrefix } from './fields';
import { styles } from './styles';

type CandidateSheetProps = {
  bluesheet: any,
  drawerTitle: string,
  profile: any,
  candidateId?: number | string,
  isReadOnly: boolean,
  onBluesheetCompleted: (bluesheet: any) => void,
  onBluesheetClosed: () => void,
  onBluesheetChanged: (bluesheet: any) => void,
  showAlert: any => void
};

const NUMBER_OF_DATES = 3;
const datesIndexer = Array.from({ length: NUMBER_OF_DATES }).map((_, index) => index);

const getInitialValues = (bluesheet, context) => {
  const defaultValues = {
    [FormFieldsMap.ReasonLiving.key]: '',
    [FormFieldsMap.Relocation.key]: '',
    [FormFieldsMap.Relocations.key]: [],
    [FormFieldsMap.WorkType.key]: null,
    [FormFieldsMap.Achievement1.key]: '',
    [FormFieldsMap.Achievement2.key]: '',
    [FormFieldsMap.Achievement3.key]: '',
    [FormFieldsMap.Experience.key]: '',
    [FormFieldsMap.TimeLooking.key]: null,
    [FormFieldsMap.TimeToStart.key]: null,
    [FormFieldsMap.MinimumSalary.key]: '',
    [FormFieldsMap.GoodSalary.key]: '',
    [FormFieldsMap.NoBrainerSalary.key]: '',
    [InterviewDatePrefix]: [null, null, null],
    [FormFieldsMap.Type.key]: null,
    [FormFieldsMap.Status.key]: null,
    [FormFieldsMap.Notes.key]: ''
  };
  if (!bluesheet) return defaultValues;

  const isDraft = bluesheet && !bluesheet.id;
  return isDraft
    ? {
        ...defaultValues,
        ...bluesheet
      }
    : preloadFromBackend(bluesheet, FormFieldsMap, context);
};

const CandidateSheet = (props: CandidateSheetProps) => {
  const {
    bluesheet,
    drawerTitle,
    profile,
    onBluesheetCompleted,
    onBluesheetClosed,
    onBluesheetChanged,
    candidateId = '',
    isReadOnly,
    showAlert
  } = props;

  const globalClasses = useGlobalStyles();
  const currentUser = getCurrentUser();

  const { register, errors, handleSubmit, setValue, getValues, watch, formState } = useForm({
    defaultValues: getInitialValues(bluesheet, profile)
  });

  const { touched, dirtyFields } = formState;
  const touchedLength = Object.keys(touched).length;
  const formValues = watch();
  const userCanEdit = canUserOrStaffEditEntity(currentUser, profile) || !profile;

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false,
    isReadOnly,
    isFormDisabled: isReadOnly || (!userCanEdit && bluesheet)
  });

  const canRelocate = formValues[FormFieldsMap.Relocation.key];

  useEffect(() => {
    register(
      { name: FormFieldsMap.Relocations.key },
      {
        validate(values) {
          const relocate = getValues()[FormFieldsMap.Relocation.key];
          return (
            relocate !== YesNo.Yes ||
            (relocate === YesNo.Yes && values && values.length) ||
            'Select at least one city to relocate'
          );
        }
      }
    );
  }, [register, getValues]);

  useEffect(() => {
    register({ name: FormFieldsMap.Relocation.key }, REQUIRED_VALIDATION);
    register({ name: FormFieldsMap.TimeLooking.key }, REQUIRED_VALIDATION);
    register({ name: FormFieldsMap.WorkType.key }, REQUIRED_VALIDATION);
    register({ name: FormFieldsMap.TimeToStart.key }, REQUIRED_VALIDATION);
    register({ name: FormFieldsMap.GoodSalary.key }, REQUIRED_VALIDATION);
    register({ name: FormFieldsMap.MinimumSalary.key }, REQUIRED_VALIDATION);
    register({ name: FormFieldsMap.NoBrainerSalary.key }, REQUIRED_VALIDATION);
    register({ name: FormFieldsMap.Status.key }, REQUIRED_VALIDATION);
    register({ name: FormFieldsMap.Type.key }, REQUIRED_VALIDATION);
    register({ name: InterviewDatePrefix });
  }, [register]);

  useEffect(() => {
    onBluesheetChanged && onBluesheetChanged(getValues());
  }, [touchedLength, onBluesheetChanged, getValues]);

  useEffect(() => {
    onBluesheetChanged && onBluesheetChanged(getValues());
  }, [dirtyFields.size, onBluesheetChanged, getValues]);

  const handleRelocationChange = event => {
    const { value } = event.target;
    setValue(FormFieldsMap.Relocation.key, value, true);
    value === YesNo.No && setValue(FormFieldsMap.Relocations.key, []);
  };

  const handleValueChange = (name: string, value: any) => {
    setValue(name, value, true);
  };

  const handleDateChange = (name: string, value) => {
    const index = Number(name.replace(InterviewDatePrefix, ''));
    const interviewDates = formValues[InterviewDatePrefix];
    interviewDates[index] = value;
    setValue(InterviewDatePrefix, interviewDates);
  };

  const handleEditClick = () =>
    setUiState(prevState => ({
      ...prevState,
      isReadOnly: false,
      isFormDisabled: !userCanEdit && bluesheet
    }));

  async function onSubmit(formData) {
    if (bluesheet?.id) {
      const transformedData = prepareFormToSubmit(formData, FormFieldsMap);
      await updateExistingBluesheet(transformedData);
    } else {
      onBluesheetCompleted && onBluesheetCompleted(formData);
    }
  }

  const updateExistingBluesheet = async sheet => {
    try {
      setUiState(prevState => ({ ...prevState, isSaving: true }));

      const response = await API.put(
        `${Endpoints.Candidates}/${candidateId}/blueSheets/${sheet.id}`,
        sheet
      );
      if (response.data) {
        setUiState(prevState => ({
          ...prevState,
          isSuccess: false,
          isSaving: false
        }));

        showAlert({
          severity: 'success',
          title: strings.inventoryProfiles.sections.candidates.candidateSheetEdit,
          body: profile?.personalInformation?.full_name
        });

        onBluesheetCompleted && onBluesheetCompleted(response.data);
      }
    } catch (err) {
      showAlert({
        severity: 'error',
        title: 'Candidate',
        body: getErrorMessage(err)
      });
    }

    setUiState(prevState => ({
      ...prevState,
      isSuccess: false,
      isSaving: false
    }));
  };

  const timeLooking = formValues[FormFieldsMap.TimeLooking.key];
  const lookingFor = timeLooking ? moment(timeLooking).fromNow(true) : '---';
  const noMargin = false;

  return (
    <DrawerContentLayout
      onSubmit={handleSubmit(onSubmit)}
      onClose={onBluesheetClosed}
      uiState={uiState}
      title={drawerTitle}
      variant="blue"
      additionalHeaderButtons={
        bluesheet &&
        bluesheet.id &&
        uiState.isReadOnly &&
        userCanEdit && (
          <FPIconButton
            style={styles.headerIcon}
            icon={SvgContentPaperEdit}
            onClick={handleEditClick}
            tooltipProps={{ title: 'Edit' }}
          />
        )
      }
    >
      <Typography variant="h2">
        <div className={globalClasses.sheetQuestion}>
          <div> 1.- What is the reason for leaving? *</div>
          <div className={globalStyles.sheetInputContainer}>
            <TextBox
              {...buildBasePropsForField(
                FormFieldsMap.ReasonLiving.key,
                errors,
                noMargin,
                uiState.isFormDisabled
              )}
              label="Reason for leaving"
              inputRef={register(REQUIRED_VALIDATION)}
              multiline
            />
          </div>
        </div>

        <div className={globalClasses.sheetQuestion}>
          <div>2.- Are you open to relocate? *</div>
          <FormControl component="fieldset" error={!!errors[FormFieldsMap.Relocation.key]}>
            <RadioGroup
              aria-label="position"
              name={FormFieldsMap.Relocation.key}
              value={formValues[FormFieldsMap.Relocation.key]}
              onChange={handleRelocationChange}
              row
            >
              <FormControlLabel
                value={YesNo.Yes}
                control={<Radio color="primary" />}
                label="Yes"
                labelPlacement="end"
                disabled={uiState.isFormDisabled}
              />
              <FormControlLabel
                value={YesNo.No}
                control={<Radio color="primary" />}
                label="No"
                labelPlacement="end"
                disabled={uiState.isFormDisabled}
              />
            </RadioGroup>
            <FormHelperText>{errors[FormFieldsMap.Relocation.key]?.message}</FormHelperText>
          </FormControl>
          {canRelocate === YesNo.Yes && (
            <Box>
              <AutocompleteSelect
                {...buildBasePropsForField(
                  FormFieldsMap.Relocations.key,
                  errors,
                  noMargin,
                  uiState.isFormDisabled
                )}
                width="100%"
                multiple
                typeahead
                typeaheadLimit={125}
                selectedValue={formValues[FormFieldsMap.Relocations.key]}
                placeholder="Relocation Destinations"
                url={Endpoints.CitiesSearch}
                onSelect={handleValueChange}
                getOptionLabel={relocationsGetOptionLabel}
                getOptionSelected={relocationsGetOptionSelected}
                groupBy={relocationsGroupBy}
              />
            </Box>
          )}
        </div>
        <div className={globalClasses.sheetQuestion}>
          <div>3.- How would you prefer to work? *</div>
          <div className={globalStyles.sheetInputContainer}>
            <AutocompleteSelect
              width="100%"
              {...buildBasePropsForField(
                FormFieldsMap.WorkType.key,
                errors,
                noMargin,
                uiState.isFormDisabled
              )}
              selectedValue={formValues[FormFieldsMap.WorkType.key]}
              placeholder="Work preference *"
              url={Endpoints.WorkTypes}
              onSelect={handleValueChange}
            />
          </div>
        </div>
        <div className={globalClasses.sheetQuestion}>
          <div>4.- Top 3 Professional Achievements *</div>
          <div className={globalStyles.sheetInputContainer}>
            <TextBox
              {...buildBasePropsForField(
                FormFieldsMap.Achievement1.key,
                errors,
                noMargin,
                uiState.isFormDisabled
              )}
              label="Achievement 1*"
              inputRef={register({ ...REQUIRED_VALIDATION, ...ACHIEVEMENT_VALIDATION })}
              multiline
            />
            <TextBox
              {...buildBasePropsForField(
                FormFieldsMap.Achievement2.key,
                errors,
                noMargin,
                uiState.isFormDisabled
              )}
              label="Achievement 2 *"
              inputRef={register({ ...REQUIRED_VALIDATION, ...ACHIEVEMENT_VALIDATION })}
              disabled={uiState.isFormDisabled}
              multiline
            />
            <TextBox
              {...buildBasePropsForField(
                FormFieldsMap.Achievement3.key,
                errors,
                noMargin,
                uiState.isFormDisabled
              )}
              label="Achievement 3 *"
              inputRef={register({ ...REQUIRED_VALIDATION, ...ACHIEVEMENT_VALIDATION })}
              multiline
            />
          </div>
        </div>
        <div className={globalClasses.sheetQuestion}>
          <div>5.- Experience & Skills *</div>
          <div className={globalStyles.sheetInputContainer}>
            <TextBox
              {...buildBasePropsForField(
                FormFieldsMap.Experience.key,
                errors,
                noMargin,
                uiState.isFormDisabled
              )}
              label="Experience and skills *"
              inputRef={register(REQUIRED_VALIDATION)}
              multiline
            />
          </div>
        </div>
        <div className={globalClasses.sheetQuestion}>
          <div>6.- How long have you been looking? *</div>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
              <CustomDatePicker
                name={FormFieldsMap.TimeLooking.key}
                label="Looking since *"
                disableFuture
                value={timeLooking}
                onDateChange={handleValueChange}
                error={!!errors[FormFieldsMap.TimeLooking.key]}
                helperText={errors[FormFieldsMap.TimeLooking.key]?.message}
                disabled={uiState.isFormDisabled}
              />
            </Grid>
            <Grid item xs={4}>
              <div>
                Looking for <br />
                <b>{lookingFor}</b>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={globalClasses.sheetQuestion}>
          <div>7.- Ideal time to start new position? *</div>
          <div className={globalStyles.sheetInputContainer}>
            <AutocompleteSelect
              width="100%"
              {...buildBasePropsForField(
                FormFieldsMap.TimeToStart.key,
                errors,
                noMargin,
                uiState.isFormDisabled
              )}
              selectedValue={formValues[FormFieldsMap.TimeToStart.key]}
              placeholder="Ideal time *"
              url={Endpoints.StartTimes}
              onSelect={handleValueChange}
            />
          </div>
        </div>
        <div className={globalClasses.sheetQuestion}>
          <div>8.- Desired compensation range *</div>
          <div className={globalStyles.sheetInputContainer}>
            <TextBox
              {...buildBasePropsForField(
                FormFieldsMap.GoodSalary.key,
                errors,
                noMargin,
                uiState.isFormDisabled
              )}
              value={formValues[FormFieldsMap.GoodSalary.key] || ''}
              label="High salary *"
              onChange={handleValueChange}
              inputType="currency"
            />
            <TextBox
              {...buildBasePropsForField(
                FormFieldsMap.MinimumSalary.key,
                errors,
                noMargin,
                uiState.isFormDisabled
              )}
              value={formValues[FormFieldsMap.MinimumSalary.key] || ''}
              label="Low salary *"
              onChange={handleValueChange}
              inputType="currency"
            />
            <TextBox
              {...buildBasePropsForField(
                FormFieldsMap.NoBrainerSalary.key,
                errors,
                noMargin,
                uiState.isFormDisabled
              )}
              value={formValues[FormFieldsMap.NoBrainerSalary.key] || ''}
              label="Ideal salary *"
              onChange={handleValueChange}
              inputType="currency"
            />
          </div>
        </div>
        <div className={globalClasses.sheetQuestion}>
          <div>9.- Available Interview Dates</div>
          <div className={globalClasses.datepickerContainer}>
            {datesIndexer.map(index => {
              const interviewDate = formValues[`${InterviewDatePrefix}`][index];
              return (
                <CustomDatePicker
                  key={`${InterviewDatePrefix}${index}`}
                  name={`${InterviewDatePrefix}${index}`}
                  label="Date & Time"
                  value={interviewDate}
                  onDateChange={handleDateChange}
                  withTime
                  disablePast={!bluesheet?.id}
                  disabled={uiState.isFormDisabled}
                />
              );
            })}
            <input
              ref={register()}
              type="hidden"
              name={FormFieldsMap.Id.key}
              value={bluesheet?.id || ''}
            />
          </div>
        </div>

        <div className={globalClasses.sheetQuestion}>
          <div>10.- Candidate Type *</div>
          <div className={globalStyles.sheetInputContainer}>
            <AutocompleteSelect
              width="100%"
              {...buildBasePropsForField(
                FormFieldsMap.Type.key,
                errors,
                noMargin,
                uiState.isFormDisabled
              )}
              selectedValue={formValues[FormFieldsMap.Type.key]}
              placeholder="Type *"
              url={Endpoints.CandidateTypes}
              onSelect={handleValueChange}
              renderOption={statusRenderOption}
              startAdornment={
                formValues[FormFieldsMap.Type.key] &&
                statusStartAdornment(
                  formValues[FormFieldsMap.Type.key].color ||
                    formValues[FormFieldsMap.Type.key].style_class_name
                )
              }
            />
          </div>
          <div className={globalClasses.sheetQuestion}>
            <div>11.- Candidate Status *</div>
            <div className={globalStyles.sheetInputMargin}>
              <AutocompleteSelect
                {...buildBasePropsForField(
                  FormFieldsMap.Status.key,
                  errors,
                  noMargin,
                  uiState.isFormDisabled
                )}
                selectedValue={formValues[FormFieldsMap.Status.key]}
                placeholder="Status *"
                url={`${Endpoints.CandidateStatuses}?selectable=true`}
                onSelect={handleValueChange}
                renderOption={statusRenderOption}
                startAdornment={
                  formValues[FormFieldsMap.Status.key] &&
                  statusStartAdornment(
                    formValues[FormFieldsMap.Status.key].color ||
                      formValues[FormFieldsMap.Status.key].style
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className={globalClasses.sheetQuestion}>
          <div>Additional Details </div>
          <div className={globalStyles.sheetInputContainer}>
            <TextBox
              {...buildBasePropsForField(
                FormFieldsMap.Notes.key,
                errors,
                noMargin,
                uiState.isFormDisabled
              )}
              label="More Details"
              inputRef={register}
              width="100%"
              multiline
            />
          </div>
        </div>
      </Typography>
    </DrawerContentLayout>
  );
};

CandidateSheet.defaultProps = {
  bluesheet: null,
  profile: null,
  candidateId: ''
};

const mapDispatchToProps = dispatch => {
  return {
    showAlert: alert => dispatch(showAlertAction(alert))
  };
};

const CandidateSheetConnected = connect(null, mapDispatchToProps)(CandidateSheet);

export default CandidateSheetConnected;
