// @flow
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showAlert as showAlertAction } from 'actions/app';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import API from 'services/API';
import strings from 'strings';
import Text from 'UI/components/atoms/Text';
import HiringAuthorityForm from 'UI/components/organisms/HiringAuthorityForm';
import HiringAuthoritySelectForm from 'UI/components/organisms/HiringAuthoritySelectForm';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getErrorMessage, getFeatureFlags, getId } from 'UI/utils';

import { HIRING_AUTHORITY_STATUS_ID_MAP } from '../HiringAuthorityForm/utils';
import {
  formatPhoneNumbers,
  getPhonesSortedByDefaultPhone,
  PHONE_FORMAT_MODES
} from '../PhoneNumbersForm/utils';

import { useStyles } from './styles';

type HiringAuthorityDrawerProps = {
  hiringAuthority?: Object,
  endpoint: string,
  companyId: number,
  joborderId: number,
  onHACompleted: (hiringAuthority: any) => void,
  onHAClosed: () => void,
  showAlert: any => void,
  type: 'company' | 'joborder',
  isEditing: boolean,
  drawerProps: Object
};

const HiringAuthorityDrawer = ({
  hiringAuthority,
  onHACompleted,
  onHAClosed,
  showAlert,
  endpoint,
  companyId,
  joborderId,
  type,
  isEditing,
  drawerProps
}: HiringAuthorityDrawerProps) => {
  const classes = useStyles();

  const isMultiplePhonesEnabled = getFeatureFlags().includes(
    FeatureFlags.HiringAuthoritiesMultiplePhones
  );

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false,
    isFormDisabled: false,
    isReadOnly: false
  });

  const {
    company_id,
    created_at,
    ext,
    first_name,
    full_name,
    hiring_authority_status_id,
    id,
    last_name,
    other_ext,
    personal_email,
    personal_phone,
    position_id,
    specialty_id,
    specialty,
    position,
    subspecialty,
    title,
    updated_at,
    work_email,
    work_phone,
    phones
  } = hiringAuthority || {};

  const initialValuesHA = {
    specialty_id: specialty,
    subspecialty_id: subspecialty,
    position_id: position,
    hiring_authority_status_id: HIRING_AUTHORITY_STATUS_ID_MAP[hiring_authority_status_id]
  };

  const defaultPhones = endpoint
    ? getPhonesSortedByDefaultPhone(hiringAuthority, EntityType.HiringAuthority)
    : phones;

  const defaultValues = hiringAuthority?.id
    ? {
        defaultValues: {
          ...hiringAuthority,
          company_id,
          created_at,
          ext,
          first_name,
          full_name,
          hiring_authority_status_id,
          id,
          last_name,
          other_ext,
          personal_email,
          personal_phone,
          position_id,
          specialty_id,
          subspecialty_id: subspecialty?.id,
          title,
          updated_at,
          work_email,
          work_phone,
          phones: defaultPhones
        }
      }
    : {};
  const [isSelectingHA, setIsSelectingHA] = useState(!isEditing);

  const completeForm = useForm(defaultValues);
  const selectForm = useForm();
  const form = isSelectingHA ? selectForm : completeForm;

  const { handleSubmit, reset } = form;

  const handleCancelClick = () => {
    onHAClosed && onHAClosed();
    reset();
  };

  const onSubmit = async formData => {
    const isExistingName = formData.isContact;
    const phonesFormatted = formatPhoneNumbers(formData, PHONE_FORMAT_MODES.submit);

    if (endpoint) {
      const data = {
        ...formData,
        phones: formatPhoneNumbers(formData, PHONE_FORMAT_MODES.submit)
      };
      if (isMultiplePhonesEnabled) data.work_phone = formData.phone;
      await saveHiringAuthority(omit(data, ['existingHiringAuthority']));
    } else {
      const { existingHiringAuthority, ...formRest } = formData;
      const formWithoutNulls = omitBy(formRest, isNil);
      /* When an HA was selected but had no specialty, it's necessary to merge specialty/subspecialty combos with the existing HA */
      const entityType = isExistingName ? EntityType.Contact : EntityType.HiringAuthority;
      const existingNameData = {
        ...existingHiringAuthority,
        ...formWithoutNulls,
        phones: phonesFormatted,
        previewPhones: getPhonesSortedByDefaultPhone(formData.existingHiringAuthority, entityType)
      };
      const newHiringAuthorityData = {
        ...formData,
        phones: phonesFormatted,
        itemId: getId()
      };
      if (isMultiplePhonesEnabled) {
        newHiringAuthorityData.work_phone = formData.phone;
        if (isExistingName) existingNameData.work_phone = formData.phone;
      }
      onHACompleted &&
        onHACompleted(existingHiringAuthority ? existingNameData : newHiringAuthorityData);
    }
  };

  const hiringAuthorityEditionMode = isEditing
    ? strings.inventoryProfiles.common.editionModes.update
    : strings.inventoryProfiles.common.editionModes.add;

  const saveHiringAuthority = async ha => {
    try {
      setUiState(prevState => ({ ...prevState, isSaving: true }));
      let response;

      if (isSelectingHA) {
        response = ha.isContact
          ? await API.post(endpoint, {
              ...ha,
              company_id: companyId
            })
          : await API.put(
              `${endpoint}/${ha.hiring_authority_id}${
                type === EntityType.Company ? '/assign' : ''
              }`,
              {
                ...ha,
                company_id: companyId
              }
            );
      } else {
        response = isEditing
          ? await API.put(endpoint, { ...ha, company_id: companyId })
          : await API.post(endpoint, { ...ha, company_id: companyId });
      }

      setUiState(prevState => ({
        ...prevState,
        isSuccess: true,
        isSaving: false
      }));

      showAlert({
        severity: 'success',
        title: strings.formatString(
          strings.inventoryProfiles.sections.tabs.hiringAuthorities.update,
          { hiringAuthorityEditionMode }
        )
      });

      onHACompleted && response.data && onHACompleted(response.data);
    } catch (err) {
      setUiState(prevState => ({
        ...prevState,
        isSuccess: false,
        isSaving: false
      }));
      showAlert({
        severity: 'error',
        title: `Hiring authority ${isEditing ? 'not updated' : 'not added'} `,
        body: getErrorMessage(err)
      });
    }

    setUiState(prevState => ({
      ...prevState,
      isSuccess: false,
      isSaving: false
    }));
  };

  const titleDrawer = isEditing
    ? strings.inventoryProfiles.sections.tabs.hiringAuthorities.editTitle
    : strings.inventoryProfiles.sections.tabs.hiringAuthorities.addTitle;

  const isFromProfile = !!endpoint;

  return (
    <DrawerContentLayout
      onSubmit={handleSubmit(onSubmit)}
      onClose={handleCancelClick}
      uiState={uiState}
      title={titleDrawer}
      drawerProps={drawerProps}
    >
      <Text variant="body2" text="Required *" />
      <div className={classes.containerForm}>
        {isSelectingHA && !isEditing ? (
          <FormContext {...form}>
            <span>
              {strings.hiringAuthorities.form.existingHiringAuthority}
              <Button color="primary" onClick={() => setIsSelectingHA(false)}>
                Create a new one
              </Button>
            </span>
            <HiringAuthoritySelectForm
              joborderId={joborderId}
              type={type}
              isFromProfile={isFromProfile}
            />
          </FormContext>
        ) : (
          <FormContext {...form}>
            {!isSelectingHA && !isEditing && (
              <>
                <span>
                  {strings.hiringAuthorities.form.newHiringAuthority}
                  <Button color="primary" onClick={() => setIsSelectingHA(true)}>
                    {type === EntityType.Company
                      ? strings.hiringAuthorities.form.addedContacts
                      : strings.hiringAuthorities.form.addedCompany}
                  </Button>
                  instead.
                </span>
                {type === EntityType.Joborder && (
                  <p>{strings.hiringAuthorities.form.addedToCompany}</p>
                )}
              </>
            )}
            <HiringAuthorityForm
              initialValues={initialValuesHA}
              isStatusSelectionEnabled={isFromProfile && isEditing}
              isFromProfile={isFromProfile}
              defaultPhones={defaultPhones}
            />
          </FormContext>
        )}
      </div>
    </DrawerContentLayout>
  );
};

HiringAuthorityDrawer.defaultProps = {
  hiringAuthority: {},
  companyId: 0,
  joborderId: 0,
  endpoint: null,
  isEditing: false
};

const mapDispatchToProps = dispatch => {
  return {
    showAlert: alert => dispatch(showAlertAction(alert))
  };
};

const HiringAuthorityDrawerConnected = connect(null, mapDispatchToProps)(HiringAuthorityDrawer);

export default HiringAuthorityDrawerConnected;
